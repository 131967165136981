import React from 'react';
import { PageHeader } from '@ant-design/pro-layout';
import paypal from '../../../../../assets/images/paypal.png';

function Header() {
  return (
    <PageHeader
      className="mb-4 px-0"
      title="PayPal"
      avatar={{ src: paypal }}
    />
  );
}

export default Header;
