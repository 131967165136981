import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useRecoilState
} from 'recoil';
import moment from 'moment';
import {
  Tooltip,
  Avatar,
  Spin,
  notification
} from 'antd';
import { Comment } from '@ant-design/compatible';
import { PageHeader } from '@ant-design/pro-layout';
import MDEditor from '@uiw/react-md-editor';
import { selectedKnowledgebase } from '../../../../atoms/Atoms';
import Grocefy from '../../../../assets/images/grocefyLogoAlone.png';
import api from '../../../../api/api';

function Knowledgebase() {
  const { i18n, t } = useTranslation();
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedKb, setSelectedKb] = useRecoilState(selectedKnowledgebase);

  const showMessage = function (message) {
    notification.open({
      message: '',
      description: (
        <Comment
          author={<span>Grocefy</span>}
          avatar={<Avatar src={Grocefy} alt="grocefy" />}
          content={
            <p className="text-sm">
              {message}
            </p>
          }
          datetime={
            <Tooltip title={moment().format('YYYY-MM-DD HH:mm:ss')}>
              <span>{moment().fromNow()}</span>
            </Tooltip>
          }
        />
      ),
    });
  };

  const readFile = function () {
    setLoading(true);
    api
      .get(
        `utility/file/helpinfo/${selectedKb.file}`
      )
      .then((response) => {
        setLoading(false);
        if (response.data.error) {
          showMessage(response.data.error);
        } else {
          const temp = JSON.parse(response.data.data);
          setFile(temp);
        }
      }).catch((err) => {
        setLoading(false);
        showMessage(err.message);
      });
  };

  useEffect(() => {
    if (selectedKb) {
      setFile(null);
      readFile();
    }
  }, [selectedKb]);

  return (
    <Spin spinning={loading}>
      <PageHeader
        className="-mt-6 -ml-6"
        title={selectedKb.name}
        onBack={() => setSelectedKb(null)}
      />
      <MDEditor.Markdown source={i18n.language === 'en' ? file?.eng : file?.spa} />
    </Spin>
  );
}

export default Knowledgebase;
