import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import { notification, Modal, Avatar, Input, Button } from 'antd';
import { Comment } from '@ant-design/compatible';
import { PageHeader } from '@ant-design/pro-layout';
import { addLogAtom, selectedOrderAtom, reloadOrder } from '../../../atoms/Atoms';
import api from '../../../api/api';
import Grocefy from '../../../assets/images/grocefyLogoAlone.png';

function AddLog() {
  const [addLog, setAddLog] = useRecoilState(addLogAtom);
  const [selectedOrder, setSelectedOrder] = useRecoilState(selectedOrderAtom);
  const [reload, setReload] = useRecoilState(reloadOrder);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const { TextArea } = Input;
  const { t } = useTranslation();

  const onChangeMessage = function (obj) {
    setMessage(obj.target.value);
  };

  const closeTicketModal = function () {
    if (!loading) {
      setAddLog(false);
    }
  };

  const createLogClick = function () {
    setLoading(true);
    const data = {
      Note: message,
      OrderId: selectedOrder.id
    };
    api
      .post('orders/logs/note', data)
      .then((response) => {
        if (response && response.data.error) {
          notification.open({
            message: 'Alert!',
            description: (
              <Comment
                author={<span>Grocefy</span>}
                avatar={<Avatar src={Grocefy} alt="grocefy" />}
                content={
                  <p className="text-sm">{response.data.error}</p>
                }
              />
            ),
          });
        } else if (
          response &&
          response.data.success &&
          response.data.message
        ) {
          notification.open({
            message: 'Success!',
            description: (
              <Comment
                author={<span>Grocefy</span>}
                avatar={<Avatar src={Grocefy} alt="grocefy" />}
                content={
                  <p className="text-sm">{response.data.message}</p>
                }
              />
            ),
          });
        }
        setAddLog(false);
        setReload(true);
        setLoading(false);
        setMessage('');
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <>
      {addLog && (
        <Modal
          closable="true"
          centered="true"
          open={addLog}
          onCancel={() => { setAddLog(false); }}
          footer={[
            <Button
              key="create"
              disabled={loading}
              loading={loading}
              onClick={() => createLogClick()}
            >
              {t('add')}
            </Button>,
            <Button
              key="close"
              disabled={loading}
              onClick={() => closeTicketModal()}
            >
              {t('close')}
            </Button>
          ]}
        >
          <PageHeader title={t('create_log')} />
          <TextArea
            placeholder={t('message')}
            onChange={(obj) => onChangeMessage(obj)}
            rows={4}
            value={message}
          />
        </Modal>
      )}
    </>
  );
}

export default AddLog;
