import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import dayjs from 'dayjs';
import { useJsonToCsv } from 'react-json-csv';
import { useRecoilValue, useRecoilState, useRecoilValueLoadable } from 'recoil';
import {
  Input,
  Table,
  Select,
  DatePicker,
  Popover,
  Drawer,
  Collapse,
  Spin,
  Tag,
  Switch,
  Menu,
  Dropdown,
  Avatar,
  notification,
  Tooltip,
  Button,
  Card
} from 'antd';
import { Comment } from '@ant-design/compatible';
import { PageHeader } from '@ant-design/pro-layout';
import { CheckCircleOutlined, CloseCircleOutlined, SortAscendingOutlined, SortDescendingOutlined } from '@ant-design/icons';
import { useMount } from 'react-use';
import bookingApi from '../../../../api/bookingApi';
import api from '../../../../api/api';
import envConfig from '../../../../envConfig';
import {
  mapSubscriptionBenefitType,
  toCurrency,
  mapSubscriptionRecurrence,
  formatPhoneNumber,
  thousand,
  getRecurranceEndDate,
  getDateExtensionEndDate,
  durationBetweenTwoDates
} from '../../../utils/functions';
import Grocefy from '../../../../assets/images/grocefyLogoAlone.png';
import { timezoneAtom } from '../../../../atoms/Atoms';
import { configSelector } from '../../../../atoms/Selectors';
import { dateRangePresets } from '../../../utils/utils';

function SubscriptionsSalesReport() {
  const config = useRecoilValueLoadable(configSelector);
  const { RangePicker } = DatePicker;
  const { Option } = Select;
  const { t, i18n } = useTranslation();
  const [dates, setDates] = useState(null);
  const [locationFilter, setLocationFilter] = useState(null);
  const [locations, setLocations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingLocations, setLoadingLocations] = useState(false);
  const [reportData, setReportData] = useState(null);
  const [firstLoad, setFirstLoad] = useState(true);
  const [searchText, setSearchText] = useState('');

  function showMessage(message) {
    notification.open({
      message: '',
      description: (
        <Comment
          author={<span>Grocefy</span>}
          avatar={<Avatar src={Grocefy} alt="grocefy" />}
          content={
            <p className="text-sm">
              {message}
            </p>
          }
          datetime={
            <Tooltip title={moment().format('YYYY-MM-DD HH:mm:ss')}>
              <span>{moment().fromNow()}</span>
            </Tooltip>
          }
        />
      ),
    });
  }

  function getLocations() {
    setLoadingLocations(true);
    bookingApi
      .get('locations')
      .then((response) => {
        setLoadingLocations(false);
        setLocations(response.data.data);
      })
      .catch((error) => {
        setLoadingLocations(false);
        console.error(error);
      });
  }

  function getData(force, text) {
    if (!loading || force) {
      setSearchText(text);
      setLoading(true);
      const payload = {
        Query: text,
        LocationId: locationFilter,
        StartDate: moment(dates?.start).add(-1 * envConfig.REACT_APP_TIMEZONE_OFFSET, 'hours').format('YYYY-MM-DDTHH:mm:00.000'),
        EndDate: moment(dates?.end).add(-1 * envConfig.REACT_APP_TIMEZONE_OFFSET, 'hours').add(1, 'days').format('YYYY-MM-DDTHH:mm:00.000'),
      };
      bookingApi.post('admin/subscriptions/sales/report', payload)
        .then((response) => {
          setLoading(false);
          if (!response.data.success) {
            showMessage(response.data.error);
          } else {
            setReportData(response.data?.data);
          }
        })
        .catch((error) => {
          setLoading(false);
          showMessage(error.message);
        });
    }
  }

  useEffect(() => {
    if (firstLoad && dates) {
      setFirstLoad(false);
      getData(true, '');
    } else if (dates) {
      getData(false, searchText);
    }
  }, [dates, locationFilter]);

  useMount(() => {
    setDates({
      start: moment().startOf('month'),
      end: moment().endOf('month'),
    });
    getLocations();
  });

  return (
    <>
      <PageHeader
        className="mb-4 px-0"
        title={t('subscription_sales')}
      />
      <div className="flex space-x-2">
        <div>
          <strong>{t('location')}:</strong>
          <br />
          <Select
            loading={loadingLocations}
            disabled={loadingLocations || loading}
            style={{ width: '140px' }}
            value={locationFilter}
            onChange={(value) => setLocationFilter(value)}
          >
            <Option
              key="any"
              value={null}
              className="text-xs flex items-center"
            >
              {t('any')}
            </Option>
            {_.map(locations, (location) => (
              <Option
                key={location.id}
                value={location.id}
                className="text-xs flex items-center"
              >
                {i18n.language === 'en' ? location.nameEn : location.nameEs}
              </Option>
            ))}
          </Select>
        </div>
        <div>
          <strong>{t('dates')}:</strong>
          <RangePicker
            disabled={loading}
            format="MMM, DD YYYY"
            className="w-full"
            allowClear={false}
            presets={dateRangePresets(t)}
            value={[
              dayjs(dates?.start),
              dayjs(dates?.end)
            ]}
            onChange={(date, dateString) => {
              if (dateString[0].length > 0 && dateString[0].length > 0) {
                setDates({
                  start: dateString[0],
                  end: dateString[1]
                });
              }
            }}
          />
        </div>
        <Input.Search
          className="w-full mb-1 mt-5"
          key="userSubscriptionSearch"
          allowClear
          loading={loading}
          enterButton={t('search')}
          onSearch={(value) => {
            setSearchText(value);
            getData(false, value);
          }}
        />
      </div>
      <br />
      <div>
        <dl className="mt-5 grid grid-cols-1 md:grid-cols-2 gap-3 lg:grid-cols-3">
          <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
            <Spin spinning={loading}>
              <dt className="text-lg font-medium text-gray-900 truncate">{t('subscriptions')}</dt>
              <dd className="mt-1 text-4xl font-semibold text-gray-900">
                <div className="inline-flex items-baseline px-2.5 py-0.5 rounded-full text-2xl font-medium md:mt-2 lg:mt-0" style={{ float: 'right', marginTop: '30px' }}>
                  {reportData && thousand(reportData?.total)}
                </div>
              </dd>
            </Spin>
          </div>
          <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
            <Spin spinning={loading}>
              <dt className="text-lg font-medium text-gray-900 truncate">{t('total_paid')}</dt>
              <dd className="mt-1 text-4xl font-semibold text-gray-900">
                <div className="inline-flex items-baseline px-2.5 py-0.5 rounded-full text-2xl font-medium md:mt-2 lg:mt-0" style={{ float: 'right', marginTop: '30px' }}>
                  {reportData && thousand(reportData?.totalPaid)}
                </div>
              </dd>
            </Spin>
          </div>
          <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
            <Spin spinning={loading}>
              <dt className="text-lg font-medium text-gray-900 truncate">{t('revenue')}</dt>
              <dd className="mt-1 text-3xl font-semibold text-gray-900">
                <div className="inline-flex items-baseline px-2.5 py-0.5 rounded-full text-2xl font-medium md:mt-2 lg:mt-0" style={{ float: 'right', marginTop: '30px' }}>
                  {reportData && toCurrency(reportData?.totalRevenue)}
                </div>
              </dd>
            </Spin>
          </div>
          <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
            <Spin spinning={loading}>
              <dt className="text-lg font-medium text-gray-900 truncate">{t('users_subscribed')}</dt>
              <dd className="mt-1 text-3xl font-semibold text-gray-900">
                <div className="inline-flex items-baseline px-2.5 py-0.5 rounded-full text-2xl font-medium md:mt-2 lg:mt-0" style={{ float: 'right', marginTop: '30px' }}>
                  {reportData && thousand(reportData?.totalUsersSubscribed)}
                </div>
              </dd>
            </Spin>
          </div>
          <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
            <Spin spinning={loading}>
              <dt className="text-lg font-medium text-gray-900 truncate">{t('sponsored_subscriptions')}</dt>
              <dd className="mt-1 text-3xl font-semibold text-gray-900">
                <div className="inline-flex items-baseline px-2.5 py-0.5 rounded-full text-2xl font-medium md:mt-2 lg:mt-0" style={{ float: 'right', marginTop: '30px' }}>
                  {reportData && thousand(reportData?.totalSponsored)}
                </div>
              </dd>
            </Spin>
          </div>
          <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
            <Spin spinning={loading}>
              <dt className="text-lg font-medium text-gray-900 truncate">{t('manually_assigned')}</dt>
              <dd className="mt-1 text-3xl font-semibold text-gray-900">
                <div className="inline-flex items-baseline px-2.5 py-0.5 rounded-full text-2xl font-medium md:mt-2 lg:mt-0" style={{ float: 'right', marginTop: '30px' }}>
                  {reportData && thousand(reportData?.totalManuallyAssigned)}
                </div>
              </dd>
            </Spin>
          </div>
        </dl>
      </div>
      <br />
      <Table
        className="mt-2"
        bordered
        rowKey="id"
        loading={loading}
        pagination={false}
        dataSource={reportData?.subscriptions ?? []}
        columns={[
          {
            title: t('name'),
            key: 'name',
            align: 'center',
            className: 'text-xs',
            render: (row) => (
              <div className="flex flex-col">
                <span>{i18n.language === 'en' ? row.nameEn : row.nameEs}</span>
              </div>
            ),
          },
          {
            title: t('location'),
            key: 'location',
            align: 'center',
            className: 'text-xs',
            render: (row) => (
              <div className="flex flex-col">
                <span>{i18n.language === 'en' ? row.locationNameEn : row.locationNameEs}</span>
              </div>
            ),
          },
          {
            title: t('users_subscribed'),
            key: 'users_subscribed',
            align: 'center',
            dataIndex: 'totalUsersSubscribed',
            className: 'text-xs',
            render: (row) => (
              <div className="flex flex-col">
                <span>{thousand(row)}</span>
              </div>
            ),
          },
          {
            title: t('manually_assigned'),
            key: 'manually_assigned',
            align: 'center',
            dataIndex: 'totalManuallyAssigned',
            className: 'text-xs',
            render: (row) => (
              <div className="flex flex-col">
                <span>{thousand(row)}</span>
              </div>
            ),
          },
          {
            title: t('sponsored'),
            key: 'sponsored',
            align: 'center',
            dataIndex: 'totalSponsored',
            className: 'text-xs',
            render: (row) => (
              <div className="flex flex-col">
                <span>{thousand(row)}</span>
              </div>
            ),
          },
          {
            title: t('paid'),
            key: 'paid',
            align: 'center',
            dataIndex: 'totalPaid',
            className: 'text-xs',
            render: (row) => (
              <div className="flex flex-col">
                <span>{thousand(row)}</span>
              </div>
            ),
          },
          {
            title: t('total'),
            key: 'total',
            align: 'center',
            dataIndex: 'total',
            className: 'text-xs',
            render: (row) => (
              <div className="flex flex-col">
                <span>{thousand(row)}</span>
              </div>
            ),
          },
          {
            title: t('revenue'),
            key: 'revenue',
            align: 'center',
            dataIndex: 'totalRevenue',
            className: 'text-xs',
            render: (row) => (
              <div className="flex flex-col">
                <span>{toCurrency(row)}</span>
              </div>
            ),
          },
        ]}
      />
    </>
  );
}
export default SubscriptionsSalesReport;
