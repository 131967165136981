import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import MDEditor from '@uiw/react-md-editor';
import { useRecoilState, useRecoilValueLoadable } from 'recoil';
import {
  Table,
  Button,
  notification,
  Avatar,
  Drawer,
  Tooltip,
  Switch,
  Input,
  Tag,
  Popconfirm,
  Modal
} from 'antd';
import { Comment } from '@ant-design/compatible';
import { PageHeader } from '@ant-design/pro-layout';
import { reloadDocumentAcceptanceAtom } from '../../../atoms/Atoms';
import api from '../../../api/api';
import Grocefy from '../../../assets/images/grocefyLogoAlone.png';
import { configSelector } from '../../../atoms/Selectors';

function DocumentAcceptance() {
  const { t } = useTranslation();
  const config = useRecoilValueLoadable(configSelector);
  const [reloadDocumentAcceptance, setReloadDocumentAcceptanceAtom] = useRecoilState(reloadDocumentAcceptanceAtom);
  const [loading, setLoading] = useState(false);
  const [loadingUsers, setLoadingUsers] = useState(false);
  const [allDocumentAcceptance, setAllDocumentAcceptance] = useState([]);
  const [selectedAcceptanceDocument, setSelectedAcceptanceDocument] = useState(null);
  const [data, setData] = useState(null);
  const [enMD, setEnMD] = useState('');
  const [esMD, setEsMD] = useState('');
  const [manageUsers, setManageUsers] = useState(false);
  const [users, setUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [foundUsers, setFoundUsers] = useState([]);

  function showMessage(message) {
    notification.open({
      message: '',
      description: (
        <Comment
          author={<span>Grocefy</span>}
          avatar={<Avatar src={Grocefy} alt="grocefy" />}
          content={
            <p className="text-sm">
              {message}
            </p>
          }
          datetime={
            <Tooltip title={moment().format('YYYY-MM-DD HH:mm:ss')}>
              <span>{moment().fromNow()}</span>
            </Tooltip>
          }
        />
      ),
    });
  }

  function getUsers() {
    console.log(selectedAcceptanceDocument);
    setLoadingUsers(true);
    api.get(`admin/tos/${selectedAcceptanceDocument?.id}/users`)
      .then((response) => {
        setLoadingUsers(false);
        if (response.data.success) {
          setUsers(response.data.data.results);
        } else {
          showMessage(response.data.error);
        }
      })
      .catch((err) => {
        setLoadingUsers(false);
        console.log(err);
        showMessage(err.message);
      });
  }

  function addUser(userId, alert) {
    setLoadingUsers(true);
    api.post(`admin/tos/${selectedAcceptanceDocument?.id}/users`, {
      UserId: userId,
      AlertUser: alert
    })
      .then((response) => {
        setLoadingUsers(false);
        if (response.data.success) {
          if (response.data.message) {
            showMessage(response.data.error);
          }
          setFoundUsers([]);
          getUsers();
        } else {
          showMessage(response.data.error);
        }
      })
      .catch((err) => {
        setLoadingUsers(false);
        console.log(err);
        showMessage(err.message);
      });
  }

  function removeUser(userId) {
    setLoadingUsers(true);
    api.put(`admin/tos/${selectedAcceptanceDocument?.id}/users`, {
      UserId: userId
    })
      .then((response) => {
        setLoadingUsers(false);
        if (response.data.success) {
          if (response.data.message) {
            showMessage(response.data.error);
          }
          getUsers();
        } else {
          showMessage(response.data.error);
        }
      })
      .catch((err) => {
        setLoadingUsers(false);
        console.log(err);
        showMessage(err.message);
      });
  }

  function getDocuments() {
    setLoading(true);
    api.get('admin/tos')
      .then((response) => {
        setLoading(false);
        if (response.data.success) {
          setAllDocumentAcceptance(response.data.data.results);
        } else {
          showMessage(response.data.error);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        showMessage(err.message);
      });
  }

  function deleteDocument() {
    setLoading(true);
    api.delete(`admin/tos/${selectedAcceptanceDocument?.id}`)
      .then((response) => {
        setLoading(false);
        if (response.data.success) {
          setAllDocumentAcceptance(response.data.data.results);
        } else {
          showMessage(response.data.error);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        showMessage(err.message);
      });
  }

  function updateDocument() {
    setLoading(true);
    api.put('admin/tos', data)
      .then((response) => {
        setLoading(false);
        if (response.data.success) {
          setSelectedAcceptanceDocument(null);
          if (response.data.message) {
            showMessage(response.data.message);
          }
          getDocuments();
        } else {
          showMessage(response.data.error);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        showMessage(err.message);
      });
  }

  function createDocument() {
    api.post('admin/tos', data)
      .then((response) => {
        setLoading(false);
        if (response.data.success) {
          setSelectedAcceptanceDocument(null);
          if (response.data.message) {
            showMessage(response.data.message);
          }
          getDocuments();
        } else {
          showMessage(response.data.error);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        showMessage(err.message);
      });
  }

  function submit() {
    if (selectedAcceptanceDocument?.id) {
      updateDocument();
    } else {
      createDocument();
    }
  }

  useEffect(() => {
    getDocuments();
  }, []);

  useEffect(() => {
    setData(selectedAcceptanceDocument);
    if (selectedAcceptanceDocument?.markdownEn) {
      setEnMD(selectedAcceptanceDocument?.markdownEn);
    } else {
      setEnMD('');
    }
    if (selectedAcceptanceDocument?.markdownEs) {
      setEsMD(selectedAcceptanceDocument?.markdownEs);
    } else {
      setEsMD('');
    }
  }, [selectedAcceptanceDocument]);

  useEffect(() => {
    setData({
      ...data,
      markdownEn: enMD,
      markdownEs: esMD
    });
  }, [enMD, esMD]);

  useEffect(() => {
    if (reloadDocumentAcceptance) {
      setReloadDocumentAcceptanceAtom(false);
      getDocuments();
    }
  }, [reloadDocumentAcceptanceAtom]);

  useEffect(() => {
    setSearchTerm('');
    setFoundUsers([]);
    if (manageUsers) {
      getUsers();
    } else {
      setUsers([]);
    }
  }, [manageUsers]);

  return (
    <div>
      <PageHeader
        className="mb-4 px-0"
        title={t('document_acceptance')}
        extra={[
          <Button
            id="reload"
            type="primary"
            key="reload"
            size="small"
            onClick={() => getDocuments()}
          >
            {t('reload')}
          </Button>,
          <Button
            type="primary"
            key="create"
            size="small"
            onClick={() => {
              setSelectedAcceptanceDocument({ });
            }}
          >
            {t('create_new')}
          </Button>
        ]}
      />
      <Table
        pagination={false}
        size="small"
        bordered
        rowKey="id"
        loading={loading}
        columns={[
          {
            title: t('is_active'),
            key: 'is_active',
            align: 'center',
            dataIndex: 'isActive',
            className: 'text-xs',
            render: (row) => (
              <span>{row ? <Tag color="green">{t('yes')}</Tag> : <Tag color="red">{t('no')}</Tag>}</span>
            ),
          },
          {
            title: t('internal_name'),
            key: 'internal_name',
            align: 'center',
            dataIndex: 'internalName',
            className: 'text-xs',
            render: (row) => (
              <span>{row}</span>
            ),
          },
          {
            title: t('for_all_users'),
            key: 'for_all_users',
            align: 'center',
            dataIndex: 'isForAllUsers',
            className: 'text-xs',
            render: (row) => (
              <span>{row ? <Tag color="green">{t('yes')}</Tag> : <Tag color="red">{t('no')}</Tag>}</span>
            ),
          },
          {
            title: '',
            key: 'edit',
            align: 'center',
            className: 'text-xs',
            render: (text, record) => (
              <Button
                size="small"
                type="primary"
                onClick={() => {
                  setSelectedAcceptanceDocument(record);
                }}
              >
                {t('edit')}
              </Button>
            ),
          },
        ]}
        dataSource={allDocumentAcceptance}
      />
      <Drawer
        title={selectedAcceptanceDocument?.id ? t('edit_document') : t('create_document')}
        placement="right"
        closable={!loading}
        onClose={() => setSelectedAcceptanceDocument(null)}
        open={selectedAcceptanceDocument}
        width={450}
      >
        {selectedAcceptanceDocument?.id && (
          <div>
            <div
              className="float-right"
            >
              <Popconfirm
                key="delete"
                title={t('are_you_sure_delete_document')}
                okText={t('yes')}
                cancelText={t('no')}
                onConfirm={() => deleteDocument()}
              >
                <Button
                  type="primary"
                  danger
                  loading={loading}
                  onClick={() => { deleteDocument(); }}
                >
                  {t('delete')}
                </Button>
              </Popconfirm>
            </div>
            <br />
            <br />
          </div>
        )}
        <div className="mb-4">
          <strong>{t('is_active')}</strong>
          <Switch
            disabled={loading}
            style={{ float: 'right' }}
            checked={data?.isActive}
            onChange={(checked) => {
              setData({
                ...data,
                isActive: checked
              });
            }}
          />
        </div>
        <div className="mb-4">
          <strong>{t('for_all_users')}</strong>
          <Switch
            disabled={loading}
            style={{ float: 'right' }}
            checked={data?.isForAllUsers}
            onChange={(checked) => {
              setData({
                ...data,
                isForAllUsers: checked
              });
            }}
          />
        </div>
        {!data?.isForAllUsers && (
          <div className="mx-auto w-full">
            <Button
              className="mx-auto w-full"
              type="primary"
              loading={loading}
              onClick={() => { setManageUsers(true); }}
            >
              {t('manage_users')}
            </Button>
          </div>
        )}
        <div className="mb-4">
          <strong>{t('internal_name')}</strong>
          <Input
            disabled={loading}
            className="w-full"
            value={data?.internalName}
            onChange={(obj) => {
              setData({
                ...data,
                internalName: obj.target.value
              });
            }}
          />
        </div>
        <div className="mb-4">
          <strong>{t('name')} EN</strong>
          <Input
            disabled={loading}
            className="w-full"
            value={data?.nameEn}
            onChange={(obj) => {
              setData({
                ...data,
                nameEn: obj.target.value
              });
            }}
          />
        </div>
        <div className="mb-4">
          <strong>{t('name')} ES</strong>
          <Input
            disabled={loading}
            className="w-full"
            value={data?.nameEs}
            onChange={(obj) => {
              setData({
                ...data,
                nameEs: obj.target.value
              });
            }}
          />
        </div>
        <div className="mb-4">
          <strong>{t('markdown')} EN</strong>
          <MDEditor
            preview="edit"
            value={enMD}
            onChange={(obj) => setEnMD(obj)}
          />
        </div>
        <div className="mb-4">
          <strong>{t('markdown')} ES</strong>
          <MDEditor
            preview="edit"
            value={esMD}
            onChange={(obj) => setEsMD(obj)}
          />
        </div>
        <div className="mb-4">
          <Button
            type="primary"
            loading={loading}
            onClick={() => { submit(); }}
          >
            {t('submit')}
          </Button>
        </div>
      </Drawer>
      <Drawer
        title={t('manage_users')}
        placement="right"
        closable={!loadingUsers}
        onClose={() => setManageUsers(false)}
        open={manageUsers}
        width={400}
      >
        <Input.Search
          placeholder={t('user_search_placeholder')}
          allowClear
          className="mb-2"
          loading={loadingUsers}
          value={searchTerm}
          onChange={(obj) => setSearchTerm(obj.target.value)}
          enterButton={t('search')}
          onSearch={(value) => {
            setLoadingUsers(true);
            api.post('users/search', { SearchText: `${value}` })
              .then((response) => {
                setLoadingUsers(false);
                if (!response.data.success) {
                  showMessage(response.data.error);
                } else if (response.data.data.results.length > 1) {
                  setFoundUsers(response.data.data.results);
                } else if (response.data.data.results.length === 1) {
                  addUser(response.data.data.results[0].id, true);
                  setSearchTerm('');
                } else {
                  showMessage(t('no_user_found'));
                }
              })
              .catch((error) => {
                console.error(error);
                setLoadingUsers(false);
              });
          }}
        />
        <Table
          size="small"
          bordered
          loading={loadingUsers}
          columns={[{
            title: t('name'),
            key: 'completeName',
            align: 'left',
            className: 'text-sm',
            render: (row) => (
              <>
                {row.user.avatar && row.user.avatar.length === 0 && (
                  <Avatar
                    style={{ background: config?.contents?.data?.primaryColor }}
                  >
                    {`${row.user.firstName[0]}${row.user.lastName[0]}`}
                  </Avatar>
                )}
                {row.user.avatar && row.user.avatar.length > 0 && (
                  <Avatar
                    style={{ background: config?.contents?.data?.primaryColor }}
                    src={row.user.avatar}
                  />
                )}
                <span className="ml-2">{row.user.completeName}</span>
              </>
            ),
          },
          {
            title: t('accepted'),
            key: 'accepted',
            align: 'left',
            className: 'text-sm',
            render: (text) => <span>{text.acceptedOn ? <Tag color="green">{t('yes')}</Tag> : <Tag color="red">{t('no')}</Tag>}</span>,
          },
          {
            title: '',
            key: 'remove',
            align: 'center',
            className: 'text-xs',
            render: (row) => (
              <Popconfirm
                key="remove"
                title={t('are_you_sure_remove_user')}
                okText={t('yes')}
                cancelText={t('no')}
                onConfirm={() => removeUser(row.user.id)}
              >
                <Button
                  type="primary"
                  danger
                  disabled={loadingUsers}
                  loading={loadingUsers}
                  size="small"
                >
                  {t('remove')}
                </Button>
              </Popconfirm>
            ),
          }]}
          dataSource={users}
        />
      </Drawer>
      <Modal
        title={t('found')}
        open={foundUsers?.length > 0}
        onCancel={() => {
          setFoundUsers([]);
        }}
        footer={null}
      >
        <Table
          size="small"
          bordered
          columns={[{
            title: t('name'),
            key: 'completeName',
            align: 'left',
            className: 'text-sm',
            render: (row) => (
              <>
                {row.avatar && row.avatar.length === 0 && (
                  <Avatar
                    style={{ background: config?.contents?.data?.primaryColor }}
                  >
                    {`${row.firstName[0]}${row.lastName[0]}`}
                  </Avatar>
                )}
                {row.avatar && row.avatar.length > 0 && (
                  <Avatar
                    style={{ background: config?.contents?.data?.primaryColor }}
                    src={row.avatar}
                  />
                )}
                <span className="ml-2">{row.completeName}</span>
              </>
            ),
          },
          {
            title: t('email'),
            dataIndex: 'email',
            key: 'email',
            align: 'left',
            className: 'text-sm',
            render: (text) => <span>{text}</span>,
          },
          {
            title: '',
            key: 'add',
            align: 'center',
            className: 'text-xs',
            render: (row) => (
              <Button
                type="primary"
                size="small"
                disabled={loadingUsers}
                loading={loadingUsers}
                onClick={() => addUser(row.id, true)}
              >
                {t('add')}
              </Button>
            ),
          }]}
          dataSource={foundUsers}
        />
      </Modal>
    </div>
  );
}

export default DocumentAcceptance;
