import React from 'react';
import { useTranslation } from 'react-i18next';
import { Statistic, Card, Typography, Tag, Spin } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import { CheckCircleOutlined } from '@ant-design/icons';
import { useRecoilValueLoadable, useRecoilValue } from 'recoil';
import {
  todayLossSubsSelector,
  todayLossAnalyticsSelector,
  todayLossOosSelector,
  todayLossOverSubsSelector,
  todayUnderweightsSelector,
} from '../../../../atoms/Selectors';
import { businessAtom } from '../../../../atoms/Atoms';
import {
  calculateContribution,
  addCommas,
} from '../../../utils/functions';

function TodayHeader() {
  const { t } = useTranslation();
  const loss = useRecoilValueLoadable(todayLossAnalyticsSelector);
  const oos = useRecoilValueLoadable(todayLossOosSelector);
  const under = useRecoilValueLoadable(todayUnderweightsSelector);
  const subs = useRecoilValueLoadable(todayLossSubsSelector);
  const subsOver = useRecoilValueLoadable(todayLossOverSubsSelector);
  const business = useRecoilValue(businessAtom);

  return (
    <Spin spinning={loss.state === 'loading' ||
      oos.state === 'loading' ||
      under.state === 'loading' ||
      subs.state === 'loading' ||
      subsOver.state === 'loading'}
    >
      <PageHeader
        key="1"
        className="mb-4 px-0"
        title={t('today')}
        subTitle={t('lost_sales')}
        tags={
          <Tag
            color="#2db7f5"
            icon={<CheckCircleOutlined />}
          >
            {business ? business.name : t('global')}
          </Tag>
        }
      />
      <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5">
        {' '}
        <Card className="mr-10">
          <div>
            <Typography>
              <b>{t('total_loss')}</b>
            </Typography>
          </div>
          <Statistic
            title={t('orders')}
            value={`${
              loss?.contents?.data?.data?.length > 0
                ? loss.contents.data.data[0].orderCount
                : 0
            }`}
          />
          <Statistic
            title={t('final_products_total')}
            value={`${
              loss?.contents?.data?.data?.length > 0
                ? addCommas(loss.contents.data.data[0].finalTotal)
                : 0
            }`}
          />{' '}
          <Statistic
            title={t('original_products_total')}
            value={`${
              loss?.contents?.data?.data?.length > 0
                ? addCommas(loss.contents.data.data[0].originalTotal)
                : 0
            }`}
          />{' '}
          <Statistic
            title={t('loss')}
            value={`${
              loss?.contents?.data?.data?.length > 0
                ? addCommas(loss.contents.data.data[0].diff)
                : 0
            }`}
          />{' '}
          <Statistic
            title={`${t('loss')} %`}
            value={`${
              loss?.contents?.data?.data?.length > 0
                ? loss.contents.data.data[0].diffPct.toFixed(1)
                : 0
            }%`}
          />{' '}
        </Card>
        <Card className="mr-10">
          <div>
            <Typography>
              <b>{t('out_of_stock')}</b>
            </Typography>
          </div>
          <Statistic
            title={t('orders')}
            value={
              oos?.contents?.data?.data?.length > 0
                ? oos.contents.data.data[0].orderCount
                : 0
            }
          />
          <Statistic
            title={t('sales')}
            value={`${
              oos?.contents?.data?.data?.length > 0
                ? addCommas(oos.contents.data.data[0].ordersTotal)
                : 0
            }`}
          />{' '}
          <Statistic
            title={t('loss')}
            value={`$${
              oos?.contents?.data?.data?.length > 0
                ? oos.contents.data.data[0].total
                : 0
            }`}
          />
          <Statistic
            title={`${t('loss')} %`}
            value={
              (oos?.contents?.data?.data?.length > 0 &&
                loss?.contents?.data?.data?.length > 0) ?
                calculateContribution(
                  loss.contents.data.data[0].originalTotal,
                  oos.contents.data.data[0].total
                ) : 0
            }
          />
          <Statistic
            title={t('items')}
            value={`${
              oos?.contents?.data?.data?.length > 0
                ? oos.contents.data.data[0].itemCount
                : 0
            }`}
          />
        </Card>{' '}
        <Card className="mr-10">
          <div>
            <Typography>
              <b>{t('underweights')}</b>
            </Typography>
          </div>
          <Statistic
            title={t('orders')}
            value={
              under?.contents?.data?.data?.length > 0
                ? under.contents.data.data[0].orderCount
                : 0
            }
          />
          <Statistic
            title={t('sales')}
            value={`${
              under?.contents?.data?.data?.length > 0
                ? addCommas(under.contents.data.data[0].originalTotal)
                : 0
            }`}
          />{' '}
          <Statistic
            title={t('loss')}
            value={`${
              under?.contents?.data?.data?.length > 0
                ? addCommas(under.contents.data.data[0].diff)
                : 0
            }`}
          />
          <Statistic
            title={`${t('loss')} %`}
            value={
              (under?.contents?.data?.data?.length > 0 &&
                loss?.contents?.data?.data?.length > 0) ?
                calculateContribution(
                  loss.contents.data.data[0].originalTotal,
                  under.contents.data.data[0].diff
                ) : 0
            }
          />
          <Statistic
            title={t('items')}
            value={`${
              under?.contents?.data?.data?.length > 0
                ? under.contents.data.data[0].itemCount
                : 0
            }`}
          />
        </Card>{' '}
        <Card className="mr-10">
          <div>
            <Typography>
              <b>{t('substitutes')} ({t('under')})</b>
            </Typography>
          </div>
          <Statistic
            title={t('orders')}
            value={
              subs?.contents?.data?.data?.length > 0
                ? subs.contents.data.data[0].orderCount
                : 0
            }
          />
          <Statistic
            title={t('sales')}
            value={`${
              subs?.contents?.data?.data?.length > 0
                ? addCommas(subs.contents.data.data[0].originalTotal)
                : 0
            }`}
          />{' '}
          <Statistic
            title={t('loss')}
            value={`${
              subs?.contents?.data?.data?.length > 0
                ? addCommas(subs.contents.data.data[0].diff)
                : 0
            }`}
          />
          <Statistic
            title={`${t('loss')} %`}
            value={
              (subs?.contents?.data?.data?.length > 0 &&
                loss?.contents?.data?.data?.length > 0) ?
                calculateContribution(
                  loss.contents.data.data[0].originalTotal,
                  subs.contents.data.data[0].diff
                ) : 0
            }
          />
          <Statistic
            title={t('items')}
            value={`${
              subs?.contents?.data?.data?.length > 0
                ? subs.contents.data.data[0].itemCount
                : 0
            }`}
          />
        </Card>{' '}
        <Card className="mr-10">
          <div>
            <Typography>
              <b>{t('substitutes')} ({t('over')})</b>
            </Typography>
          </div>
          <Statistic
            title={t('orders')}
            value={
              subsOver?.contents?.data?.data?.length > 0
                ? subsOver.contents.data.data[0].orderCount
                : 0
            }
          />
          <Statistic
            title={t('sales')}
            value={`${
              subsOver?.contents?.data?.data?.length > 0
                ? addCommas(subsOver.contents.data.data[0].originalTotal)
                : 0
            }`}
          />{' '}
          <Statistic
            title={t('loss')}
            value={`${
              subsOver?.contents?.data?.data?.length > 0
                ? addCommas(subsOver.contents.data.data[0].diff)
                : 0
            }`}
          />
          <Statistic
            title={`${t('loss')} %`}
            value={
              (subsOver?.contents?.data?.data?.length > 0 &&
                loss?.contents?.data?.data?.length > 0) ?
                calculateContribution(
                  loss.contents.data.data[0].originalTotal,
                  subsOver.contents.data.data[0].diff
                ) : 0
            }
          />
          <Statistic
            title={t('items')}
            value={`${
              subsOver?.contents?.data?.data?.length > 0
                ? subsOver.contents.data.data[0].itemCount
                : 0
            }`}
          />
        </Card>
      </div>
    </Spin>
  );
}

export default TodayHeader;
