import { selector } from 'recoil';
import {
  pageAtom,
  rowsAtom,
  tokenAtom,
  businessAtom,
  financeFilterMonthAtom,
  financeFilterYearAtom,
  financeFilterDayAtom,
  pastLossAtom
} from './Atoms';
import api from '../api/api';

export const businessesSelector = selector({
  key: 'storesSelector',
  get: async ({ get }) => {
    const token = get(tokenAtom);
    // eslint-disable-next-line no-useless-catch
    try {
      const response = await api.get('businesses/assigned');
      return await response;
    } catch (error) {
      throw error;
    }
  },
});

export const salesDatesSelector = selector({
  key: 'sales-dates-selector',
  get: async ({ get }) => {
    const token = get(tokenAtom);
    const store = get(businessAtom);
    if (!store) return null;
    try {
      const response = await api.get(`reports/salesmonths/${store.id}`);
      return await response;
    } catch (error) {
      throw error;
    }
  },
});

export const dailySalesReportsSelector = selector({
  key: 'reports-daily',
  get: async ({ get }) => {
    const token = get(tokenAtom);
    const store = get(businessAtom);
    const filterYear = get(financeFilterYearAtom);
    const filterMonth = get(financeFilterMonthAtom);
    const filterDay = get(financeFilterDayAtom);
    try {
      const response = await api.get(`analytics/reporting/daily/${filterDay}/${filterMonth}/${filterYear}/${store.id}`);
      return await response;
    } catch (error) {
      throw error;
    }
  },
});

export const monthlySalesReportsSelector = selector({
  key: 'reports-monthly',
  get: async ({ get }) => {
    const token = get(tokenAtom);
    const store = get(businessAtom);
    const filterYear = get(financeFilterYearAtom);
    const filterMonth = get(financeFilterMonthAtom);
    try {
      const response = await api.get(`analytics/reporting/monthly/${filterMonth}/${filterYear}/${store.id}`);
      return await response;
    } catch (error) {
      throw error;
    }
  },
});

export const departmentSalesReportsSelector = selector({
  key: 'reports-departments',
  get: async ({ get }) => {
    const token = get(tokenAtom);
    const store = get(businessAtom);
    const filterYear = get(financeFilterYearAtom);
    const filterMonth = get(financeFilterMonthAtom);
    const filterDay = get(financeFilterDayAtom);
    try {
      const response = await api.post(`analytics/reporting/departmentsales/${filterDay}/${filterMonth}/${filterYear}/${store.id}`);
      return await response;
    } catch (error) {
      throw error;
    }
  },
});

export const monthlyDepartmentSalesReportsSelector = selector({
  key: 'reports-departments-monthly',
  get: async ({ get }) => {
    const token = get(tokenAtom);
    const store = get(businessAtom);
    const filterYear = get(financeFilterYearAtom);
    const filterMonth = get(financeFilterMonthAtom);
    try {
      const response = await api.post(`analytics/reporting/departmentsales/${filterMonth}/${filterYear}/${store.id}`);
      return await response;
    } catch (error) {
      throw error;
    }
  },
});

export const balancesSelector = selector({
  key: 'balances',
  get: async ({ get }) => {
    const token = get(tokenAtom);
    const business = get(businessAtom);
    // eslint-disable-next-line no-useless-catch
    try {
      const response = await api.get(`payments/balance/${business.id}`);
      return await response;
    } catch (error) {
      throw error;
    }
  },
});

export const configSelector = selector({
  key: 'config',
  get: async ({ get }) => {
    const token = get(tokenAtom);
    const response = await api.get('utility/file/read/web/config');
    return response;
  },
});

export const sortableCategoriesSelector = selector({
  key: 'sortable-categories=store',
  get: async ({ get }) => {
    const token = get(tokenAtom);
    const store = get(businessAtom);
    try {
      const response = await api.get(`businesses/${store.id}/sortingcategories`);
      return await response;
    } catch (error) {
      throw error;
    }
  },
});
export const couponsSelector = selector({
  key: 'coupons-selector',
  get: async ({ get }) => {
    const token = get(tokenAtom);
    const store = get(businessAtom);
    const page = get(pageAtom);
    const size = get(rowsAtom);

    try {
      const response = await api.get(`businesses/coupons/${store.id}/${page - 1}/${size}`);
      return await response;
    } catch (error) {
      throw error;
    }
  },
});
export const redemptionsSelector = selector({
  key: 'coupon-redemptions-selector',
  get: async ({ get }) => {
    const token = get(tokenAtom);
    const store = get(businessAtom);
    const page = get(pageAtom);
    const size = get(rowsAtom);

    try {
      const response = await api.get(`businesses/coupon/redemptions/${store.id}/${page - 1}/${size}`);
      return await response;
    } catch (error) {
      throw error;
    }
  },
});
export const paymentOptionsSelector = selector({
  key: 'payment-options',
  get: async ({ get }) => {
    const token = get(tokenAtom);
    const business = get(businessAtom);
    try {
      const response = await api.get(`admin/paymentOptions/${business.locations[0].id}`);
      return await response;
    } catch (error) {
      throw error;
    }
  },
});
export const businessOptionsSelector = selector({
  key: 'business-options',
  get: async ({ get }) => {
    const token = get(tokenAtom);
    const business = get(businessAtom);
    try {
      const response = await api.get(`businesses/options/${business.locations[0].id}`);
      return await response;
    } catch (error) {
      throw error;
    }
  },
});
export const productCategoriesSelector = selector({
  key: 'product-categories',
  get: async ({ get }) => {
    const token = get(tokenAtom);
    const business = get(businessAtom);
    try {
      const response = await api.get(`businesses/${business.id}/menusections/filtered`);
      return await response;
    } catch (error) {
      throw error;
    }
  },
});

export const currentOosLossAnalyticsSelector = selector({
  key: 'analytics-lostsales-current-oos-store',
  get: async ({ get }) => {
    const store = get(businessAtom);
    try {
      let url = 'analytics/lostsales/current/oos';
      if (store) {
        url = `analytics/lostsales/current/oos/${store.id}`;
      }
      const response = await api.get(url);
      return await response;
    } catch (error) {
      throw error;
    }
  },
});

export const currentOverweightsLossAnalyticsSelector = selector({
  key: 'analytics-lostsales-current-overweights-store',
  get: async ({ get }) => {
    const store = get(businessAtom);
    try {
      const response = await api.get(`analytics/lostsales/current/overweights/${store.id}`);
      return await response;
    } catch (error) {
      throw error;
    }
  },
});

export const currentSubsOverLossAnalyticsSelector = selector({
  key: 'analytics-lostsales-current-subs-over-store',
  get: async ({ get }) => {
    const store = get(businessAtom);
    try {
      let url = 'analytics/lostsales/current/subs/over';
      if (store) {
        url = `analytics/lostsales/current/subs/over/${store.id}`;
      }
      const response = await api.get(url);
      return await response;
    } catch (error) {
      throw error;
    }
  },
});

export const currentSubsUnderLossAnalyticsSelector = selector({
  key: 'analytics-lostsales-current-subs-store',
  get: async ({ get }) => {
    const store = get(businessAtom);
    try {
      let url = 'analytics/lostsales/current/subs';
      if (store) {
        url = `analytics/lostsales/current/subs/${store.id}`;
      }
      const response = await api.get(url);
      return await response;
    } catch (error) {
      throw error;
    }
  },
});

export const currentUnderweightsLossAnalyticsSelector = selector({
  key: 'analytics-lostsales-current-underweights-store',
  get: async ({ get }) => {
    const store = get(businessAtom);
    try {
      let url = 'analytics/lostsales/current/underweights';
      if (store) {
        url = `analytics/lostsales/current/underweights/${store.id}`;
      }
      const response = await api.get(url);
      return await response;
    } catch (error) {
      throw error;
    }
  },
});

export const lostSalesCurrentAnalyticsSelector = selector({
  key: 'analytics-lostsales-current-store',
  get: async ({ get }) => {
    const store = get(businessAtom);
    try {
      let url = 'analytics/lostsales/current';
      if (store) {
        url = `analytics/lostsales/current/${store.id}`;
      }
      const response = await api.get(url);
      return await response;
    } catch (error) {
      throw error;
    }
  },
});

export const lostSalesPastAnalyticsSelector = selector({
  key: 'analytics-lostsales-past-store',
  get: async ({ get }) => {
    const store = get(businessAtom);
    const days = get(pastLossAtom);
    let url = `analytics/lostsales/past/${days}`;
    if (store) {
      url = `analytics/lostsales/past/${store.id}/${days}`;
    }
    try {
      const response = await api.get(url);
      return await response;
    } catch (error) {
      throw error;
    }
  },
});

export const pastLossOosSelector = selector({
  key: 'lostsales-past-oss-store',
  get: async ({ get }) => {
    const store = get(businessAtom);
    try {
      let url = 'analytics/lostsales/past/oos/1';
      if (store) {
        url = `analytics/lostsales/past/oos/${store.id}/1`;
      }
      const response = await api.get(url);
      return await response;
    } catch (error) {
      throw error;
    }
  },
});

export const todayLossOosSelector = selector({
  key: 'lostsales-today-oss-store',
  get: async ({ get }) => {
    const store = get(businessAtom);
    try {
      let url = 'analytics/lostsales/today/oos';
      if (store) {
        url = `analytics/lostsales/today/oos/${store.id}`;
      }
      const response = await api.get(url);
      return await response;
    } catch (error) {
      throw error;
    }
  },
});

export const pastLossOverSubsSelector = selector({
  key: 'lostsales-past-subs-over-store',
  get: async ({ get }) => {
    const store = get(businessAtom);
    try {
      let url = 'analytics/lostsales/past/subs/over/1';
      if (store) {
        url = `analytics/lostsales/past/subs/over/${store.id}/1`;
      }
      const response = await api.get(url);
      return await response;
    } catch (error) {
      throw error;
    }
  },
});

export const todayLossOverSubsSelector = selector({
  key: 'lostsales-today-subs-over-store',
  get: async ({ get }) => {
    const store = get(businessAtom);
    let url = 'analytics/lostsales/today/subs/over';
    if (store) {
      url = `analytics/lostsales/today/subs/over/${store.id}`;
    }
    try {
      const response = await api.get(url);
      return await response;
    } catch (error) {
      throw error;
    }
  },
});

export const pastLossSubsSelector = selector({
  key: 'lostsales-past-subs-store',
  get: async ({ get }) => {
    const store = get(businessAtom);
    try {
      let url = 'analytics/lostsales/past/subs/1';
      if (store) {
        url = `analytics/lostsales/past/subs/${store.id}/1`;
      }
      const response = await api.get(url);
      return await response;
    } catch (error) {
      throw error;
    }
  },
});

export const todayLossSubsSelector = selector({
  key: 'lostsales-today-subs-store',
  get: async ({ get }) => {
    const store = get(businessAtom);
    try {
      let url = 'analytics/lostsales/today/subs';
      if (store) {
        url = `analytics/lostsales/today/subs/${store.id}`;
      }
      const response = await api.get(url);
      return await response;
    } catch (error) {
      throw error;
    }
  },
});

export const pastLossUnderweightsSelector = selector({
  key: 'lostsales-past-underweights-store',
  get: async ({ get }) => {
    const store = get(businessAtom);
    try {
      let url = 'analytics/lostsales/past/underweights/1';
      if (store) {
        url = `analytics/lostsales/past/underweights/${store.id}/1`;
      }
      const response = await api.get(url);
      return await response;
    } catch (error) {
      throw error;
    }
  },
});

export const todayUnderweightsSelector = selector({
  key: 'lostsales-today-underweights-store',
  get: async ({ get }) => {
    const store = get(businessAtom);
    try {
      let url = 'analytics/lostsales/today/underweights';
      if (store) {
        url = `analytics/lostsales/today/underweights/${store.id}`;
      }
      const response = await api.get(url);
      return await response;
    } catch (error) {
      throw error;
    }
  },
});

export const todayLossAnalyticsSelector = selector({
  key: 'today-loss-store',
  get: async ({ get }) => {
    const store = get(businessAtom);
    try {
      let url = 'analytics/lostsales/today';
      if (store) {
        url = `analytics/lostsales/today/${store.id}`;
      }
      const response = await api.get(url);
      return await response;
    } catch (error) {
      throw error;
    }
  },
});
