import React from 'react';
import Customer from './shared-components/Customers';

function CustomerSearch() {
  return (
    <>
      <Customer />
    </>
  );
}

export default CustomerSearch;
