import React, { useEffect, useState } from 'react';
import {
  useRecoilState,
  useRecoilValueLoadable
} from 'recoil';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import {
  Button,
  Table
} from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import moment from 'moment';
import {
  selectedCouponAtom,
  refreshCouponsAtom,
  profileAtom
} from '../../../atoms/Atoms';
// import { configSelector } from '../../../atoms/Selectors';
// import couponEngine from '../../utils/GopanzaCouponService';
import couponEngine from '../../utils/GopanzaCouponServiceTest';

function CouponCampaigns() {
  const [coupons, setCoupons] = useState([]);
  const [selectedCoupon, setSelectedCoupon] = useRecoilState(selectedCouponAtom);
  const [refreshCoupons, setRefreshCoupons] = useRecoilState(refreshCouponsAtom);
  const [loading, setLoading] = useState(false);
  const profile = useRecoilValueLoadable(profileAtom);
  const isAdmin =
    profile.contents && profile?.contents?.roles?.includes('SuperAdmin');
  const history = useHistory();

  const { t } = useTranslation();

  if (!isAdmin) {
    history.push('/');
    return null;
  }
  const parseCouponStatus = function (status) {
    if (status === 1) {
      return t('approved');
    }
    if (status === 2) {
      return t('rejected');
    }
    return t('pending_review');
  };

  const getCoupons = async function () {
    setLoading(true);
    const response = await couponEngine.getCoupons();
    setLoading(false);
    if (response.success) {
      setCoupons(response.data);
    }
  };

  useEffect(() => {
    couponEngine.initialize('couponEngineSubscriptionKey', 'clientId', 'userId');
    setSelectedCoupon(null);
    getCoupons();
  }, []);

  useEffect(() => {
    if (refreshCoupons) {
      setRefreshCoupons(false);
      getCoupons();
    }
  }, [refreshCoupons]);

  return (
    <>
      <PageHeader
        title={t('coupons')}
        extra={[
          <Button
            type="primary"
            size="small"
            key="create"
            href="coupon/new"
          >
            {t('create')}
          </Button>
        ]}
      />
      <Table
        size="small"
        bordered
        loading={loading}
        pagination={false}
        dataSource={coupons}
        columns={[
          {
            title: t('name'),
            align: 'center',
            className: 'text-xs',
            render: (row) => (
              <span>{row.name}</span>
            ),
          },
          {
            title: t('start_date'),
            dataIndex: 'startDate',
            align: 'center',
            className: 'text-xs',
            render: (text) => (
              <span>
                {moment(`${text}+0000`)
                  .format('MMMM Do YYYY, h:mm:ss a')}
              </span>
            ),
          },
          {
            title: t('end_date'),
            dataIndex: 'endDate',
            align: 'center',
            className: 'text-xs',
            render: (text) => (
              <span>
                {moment(`${text}+0000`)
                  .format('MMMM Do YYYY, h:mm:ss a')}
              </span>
            ),
          },
          {
            title: t('status'),
            dataIndex: 'status',
            align: 'center',
            className: 'text-xs',
            render: (text) => (
              <span>{parseCouponStatus(text)}</span>
            ),
          },
          {
            title: '',
            align: 'center',
            className: 'text-xs',
            render: (row) => (
              <div>
                <Button
                  size="small"
                  type="primary"
                  href={`/coupon/${row.id}`}
                  onClick={() => {
                    setSelectedCoupon(row);
                  }}
                >
                  {t('edit')}
                </Button>
              </div>
            ),
          }
        ]}
      />
    </>
  );
}

export default CouponCampaigns;
