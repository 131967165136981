import React, { useState, useEffect, useRef } from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Button, Table, Image, Input, Space } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import { useSetRecoilState, useRecoilState } from 'recoil';
import { useMount } from 'react-use';
import { CheckCircleOutlined, CloseCircleOutlined, SearchOutlined } from '@ant-design/icons';
import {
  editSubscriptionAtom,
  reloadSubscriptionsAtom,
  selectedSubscriptionAtom
} from '../../../../atoms/Atoms';
import EditSubscription from './shared-components/EditSubscription';
import Subscription from './shared-components/Subscription';
import bookingApi from '../../../../api/bookingApi';
import {
  toCurrency,
  mapSubscriptionRecurrence
} from '../../../utils/functions';

function Subscriptions() {
  const defaultSubscription = {
    imageUrl: '',
    descriptionEn: '',
    descriptionEs: '',
    internalName: '',
    nameEs: '',
    nameEn: '',
    recurrence: 0,
    ordering: 0,
    price: 0,
    locationId: null,
    isActive: false,
    promoPrice: null,
    promoStartDate: moment().startOf('day'),
    promoEndDate: moment().endOf('day'),
    dateExtensionType: 0,
    dateExtensionValue: 1,
    hasPromo: false,
    allowRenewal: true,
    hasLimit: false,
    limit: 1
  };
  const { t } = useTranslation();
  const setSelectedSubscription = useSetRecoilState(selectedSubscriptionAtom);
  const setEditSubscription = useSetRecoilState(editSubscriptionAtom);
  const [reload, setReload] = useRecoilState(reloadSubscriptionsAtom);
  const [loading, setLoading] = useState(false);
  const [subscriptions, setSubscriptions] = useState([]);
  const { Search } = Input;

  function getSubscriptions() {
    setLoading(true);
    bookingApi
      .get('subscriptions')
      .then((response) => {
        setLoading(false);
        setSubscriptions(_.map(response.data.data, (s) => ({
          ...s,
          hasLimit: s.limit > 0
        })));
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  }

  useMount(() => {
    getSubscriptions();
  });

  useEffect(() => {
    if (reload) {
      getSubscriptions();
      setReload(false);
    }
  }, [reload]);

  const uniqueLocationNames = Array.from(new Set(subscriptions.map((sub) => sub.location?.name)));
  const uniqueIsActiveValues = Array.from(new Set(subscriptions.map((sub) => sub.isActive)));
  const [nameSearch, setNameSearch] = useState('');
  const searchInput = useRef(null);

  return (
    <>
      <PageHeader
        className="mb-4 px-0"
        title={t('subscriptions')}
        extra={[
          <Button
            id="reload"
            type="primary"
            key="reload"
            size="small"
            onClick={() => setReload(true)}
          >
            {t('reload')}
          </Button>,
          <Button
            type="primary"
            key="create"
            size="small"
            onClick={() => {
              setSelectedSubscription(defaultSubscription);
              setEditSubscription(true);
            }}
          >
            {t('create_new')}
          </Button>
        ]}
      />
      <Table
        pagination={false}
        size="small"
        bordered
        rowKey="id"
        loading={loading}
        columns={[
          {
            title: t('location'),
            key: 'location',
            align: 'center',
            dataIndex: 'location',
            className: 'text-xs',
            render: (row, data) => (
              <span>{data?.location?.name}</span>
            ),
            // sorter: (a, b) => {
            //   const nameA = a.location?.name || '';
            //   const nameB = b.location?.name || '';
            //   return nameA.localeCompare(nameB);
            // },
            filters: uniqueLocationNames.map((name) => ({ text: name, value: name })),
            onFilter: (value, record) => record.location?.name.includes(value),
          },
          {
            title: t('name'),
            key: 'name',
            align: 'center',
            dataIndex: 'internalName',
            className: 'text-xs',
            render: (row) => (
              <span>{row}</span>
            ),
            // Add the search filter for the "name" column
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
              <div style={{ padding: 8 }}>
                <Input
                  ref={searchInput}
                  placeholder={t('name')}
                  value={selectedKeys[0]}
                  onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                  onPressEnter={() => confirm()}
                  style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Space>
                  <Button
                    type="primary"
                    onClick={() => confirm()}
                    size="small"
                    style={{ width: 90 }}
                  >
                    {t('search')}
                  </Button>
                  <Button onClick={() => clearFilters()} size="small" style={{ width: 90 }}>
                    Reset
                  </Button>
                </Space>
              </div>
            ),
            filterIcon: (filtered) => (
              <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
            ),
            onFilter: (value, record) => record.internalName.toLowerCase().includes(value.toLowerCase()),
            onFilterDropdownVisibleChange: (visible) => {
              if (visible) {
                setTimeout(() => searchInput.current.focus(), 0);
              }
            },
          },
          {
            title: t('is_active'),
            key: 'isactive',
            align: 'center',
            dataIndex: 'isActive',
            className: 'text-xs',
            render: (row) => (
              <div>{row ? <CheckCircleOutlined className="text-green-600" /> : <CloseCircleOutlined className="text-red-600" />}</div>
            ),
            // sorter: (a, b) => a.isActive - b.isActive,
            filters: uniqueIsActiveValues.map((value) => ({
              text: value ? t('active') : t('inactive'),
              value,
            })),
            onFilter: (value, record) => record.isActive === value,
          },
          {
            title: t('recurrence'),
            key: 'recurrence',
            align: 'center',
            dataIndex: 'recurrence',
            className: 'text-xs',
            render: (row) => (
              <span>{t(mapSubscriptionRecurrence(row))}</span>
            ),
          },
          {
            title: t('price'),
            key: 'price',
            align: 'center',
            dataIndex: 'price',
            className: 'text-xs',
            render: (row) => (
              <span>{toCurrency(row)}</span>
            ),
          },
          {
            title: t('is_on_promo'),
            key: 'isOnPromo',
            align: 'center',
            dataIndex: 'isOnPromo',
            className: 'text-xs',
            render: (row) => (
              <div>{row ? <CheckCircleOutlined className="text-green-600" /> : <CloseCircleOutlined className="text-red-600" />}</div>
            ),
          },
          {
            title: t('edit'),
            key: 'edit',
            align: 'center',
            className: 'text-xs',
            render: (text, record) => (
              <div className="flex">
                <Button
                  size="small"
                  className="ml-auto mr-2"
                  onClick={() => {
                    setSelectedSubscription(record);
                    setEditSubscription(true);
                  }}
                >
                  {t('edit')}
                </Button>
                <Button
                  size="small"
                  className="mr-auto"
                  type="primary"
                  onClick={() => {
                    setSelectedSubscription(record);
                  }}
                >
                  {t('view')}
                </Button>
              </div>
            ),
          }
        ]}
        dataSource={subscriptions}
      />
      <EditSubscription />
      <Subscription />
    </>
  );
}
export default Subscriptions;
