import React, { useState } from 'react';
import _ from 'lodash';
import { useRecoilValue } from 'recoil';
import { GoogleMap, Marker, Polygon, useJsApiLoader } from '@react-google-maps/api';
import {
  deliveryZonesAtom,
  businessAtom
} from '../../../../atoms/Atoms';
import envConfig from '../../../../envConfig';

function Map() {
  const business = useRecoilValue(businessAtom);
  const zones = useRecoilValue(deliveryZonesAtom);
  const [map, setMap] = useState(null);
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: envConfig.REACT_APP_GEO_API_KEY,
    language: 'es-US',
    libraries: ['places']
  });
  const apiHasLoaded = (tempMap) => {
    const bounds = new window.google.maps.LatLngBounds({
      lat: business?.locations[0]?.geo?.lat,
      lng: business?.locations[0]?.geo?.lon
    });
    tempMap.fitBounds(bounds);
    setMap(tempMap);
  };
  const onUnmount = React.useCallback((tempMap) => {
    setMap(null);
  }, []);
  return (
    <>
      {isLoaded && (
        <GoogleMap
          map={map}
          mapContainerStyle={{
            height: '500px',
            width: '100%'
          }}
          onLoad={apiHasLoaded}
          onUnmount={onUnmount}
          center={{
            lat: business?.locations[0]?.geo?.lat,
            lng: business?.locations[0]?.geo?.lon
          }}
          zoom={12}
        >
          <Marker
            position={{
              lat: business?.locations[0]?.geo?.lat,
              lng: business?.locations[0]?.geo?.lon
            }}
          />
          {_.map(_.filter(zones, (z) => z.checked), (zone, index) => (
            <>
              <Polygon
                path={_.map(zone?.points, (ll) => ({
                  lng: ll.lon,
                  lat: ll.lat
                }))}
                options={{
                  fillColor: `${zone.color}9F`,
                  fillOpacity: 1,
                  strokeColor: zone.color,
                  strokeOpacity: 1,
                  strokeWeight: 2,
                  clickable: false,
                  draggable: false,
                  editable: false,
                  geodesic: false,
                  paths: _.map(zone?.points, (ll) => ({
                    lng: ll.lon,
                    lat: ll.lat
                  })),
                  zIndex: 1,
                }}
              />
              <Marker
                label={zone.name}
                position={{ lat: zone.center?.lat, lng: zone.center?.lon }}
              />
            </>
          ))}
        </GoogleMap>
      )}
    </>
  );
}

export default Map;
