import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { TwitterPicker, ChromePicker } from 'react-color';
import { saveAs } from 'file-saver';
import { AppConfigurationClient } from '@azure/app-configuration';
import {
  useRecoilValueLoadable
} from 'recoil';
import { useTranslation } from 'react-i18next';
import {
  Table,
  Input,
  Button,
  Popconfirm,
  Spin,
  Modal,
  notification,
  Avatar,
  Tooltip
} from 'antd';
import { Comment } from '@ant-design/compatible';
import { PageHeader } from '@ant-design/pro-layout';
import Grocefy from '../../../../assets/images/grocefyLogoAlone.png';
import { profileAtom } from '../../../../atoms/Atoms';

function Configurations({ title, connectionString }) {
  const { t } = useTranslation();
  const profile = useRecoilValueLoadable(profileAtom);
  const isAdmin =
    profile.contents && profile.contents.roles.includes('SuperAdmin');
  const client = new AppConfigurationClient(connectionString);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [lastSearch, setLastSearch] = useState('');
  const [allSettings, setAllSettings] = useState([]);
  const [settings, setSettings] = useState([]);
  const [newSetting, setNewSetting] = useState({});

  function hexToRgb(hex) {
    const temp = hex.replace('#', '');
    let r = 0;
    let g = 0;
    let b = 0;
    let a = 1;
    if (temp.length === 6) {
      r = parseInt(temp.substring(0, 2), 16);
      g = parseInt(temp.substring(2, 4), 16);
      b = parseInt(temp.substring(4, 6), 16);
    } else {
      a = parseInt(temp.substring(0, 2), 16) / 255;
      r = parseInt(temp.substring(2, 4), 16);
      g = parseInt(temp.substring(4, 6), 16);
      b = parseInt(temp.substring(6, 8), 16);
    }
    return { r, g, b, a };
  }

  function componentToHex(c) {
    const hex = c.toString(16);
    return hex.length === 1 ? `0${hex}` : hex;
  }

  function rgbToHex(rgb) {
    if (rgb.a < 1) {
      return `#${componentToHex(parseInt((rgb.a * 255).toFixed(), 10))}${componentToHex(rgb.r)}${componentToHex(rgb.g)}${componentToHex(rgb.b)}`;
    }
    return `#${componentToHex(rgb.r)}${componentToHex(rgb.g)}${componentToHex(rgb.b)}`;
  }

  function showMessage(message) {
    notification.open({
      message: '',
      description: (
        <Comment
          author={<span>Grocefy</span>}
          avatar={<Avatar src={Grocefy} alt="grocefy" />}
          content={
            <p className="text-sm">
              {message}
            </p>
          }
          datetime={
            <Tooltip title={moment().format('YYYY-MM-DD HH:mm:ss')}>
              <span>{moment().fromNow()}</span>
            </Tooltip>
          }
        />
      ),
    });
  }

  function filterSettings(searchTerm) {
    if (searchTerm?.length > 0) {
      const filtered = _.filter(allSettings,
        (s) => s.key.toUpperCase().includes(searchTerm.toUpperCase()));
      setSettings(filtered);
    } else {
      setSettings(allSettings);
    }
  }

  async function getSettings() {
    setLoading(true);
    const tempSettings = [];
    const existingSettings = client.listConfigurationSettings();
    for await (const setting of existingSettings) {
      tempSettings.push(setting);
    }
    setAllSettings(tempSettings);
    setLoading(false);
  }

  async function updateSetting(setting) {
    const index = settings.findIndex((s) => s.etag === setting.etag);
    const temp = JSON.parse(JSON.stringify(settings));
    temp[index].loading = true;
    setSettings(temp);
    client.setConfigurationSetting({
      key: setting.key,
      value: setting.value
    }).then((response) => {
      const index2 = settings.findIndex((s) => s.etag === setting.etag);
      const index3 = allSettings.findIndex((s) => s.etag === setting.etag);
      const temp2 = JSON.parse(JSON.stringify(settings));
      const temp3 = JSON.parse(JSON.stringify(allSettings));
      temp2[index2].loading = false;
      temp3[index3].value = setting.value;
      setSettings(temp2);
      setAllSettings(temp3);
    });
  }

  async function removeAllSettings() {
    setLoading(true);
    for (let i = 0; i < allSettings.length; i++) {
      client.deleteConfigurationSetting(allSettings[i]);
    }
    getSettings();
  }

  async function deleteSetting(setting) {
    const index = settings.findIndex((s) => s.etag === setting.etag);
    const temp = JSON.parse(JSON.stringify(settings));
    temp[index].loading = true;
    setSettings(temp);
    client.deleteConfigurationSetting(setting).then((response) => {
      const newSettings = _.filter(allSettings, (s) => s.etag !== response.etag);
      // console.log(allSettings.length);
      // console.log(newSettings.length);
      setAllSettings(newSettings);
    });
  }

  async function createSetting() {
    const index = allSettings.findIndex((s) => s.key === newSetting.key);
    if (index > -1) {
      showMessage(t('setting_with_key_exists'));
    } else {
      setLoading(true);
      client.addConfigurationSetting(newSetting).then((response) => {
        setShowCreateModal(false);
        setAllSettings([...allSettings, response]);
        setLoading(false);
      });
    }
  }

  function exportSettings() {
    let data = '{\n';
    for (let i = 0; i < allSettings.length; i++) {
      data += `"${allSettings[i].key}": "${allSettings[i].value}"`;
      if (i !== allSettings.length - 1) {
        data += ',\n';
      }
    }
    data += '\n}';
    const file = new Blob([data], { type: 'application/json;charset=utf-8' });
    saveAs(file, 'settings.json');
  }

  useEffect(() => {
    setLastSearch('');
    getSettings();
  }, []);

  useEffect(() => {
    filterSettings(lastSearch);
  }, [allSettings]);

  useEffect(() => {
    if (showCreateModal) {
      setNewSetting({
        key: '',
        value: ''
      });
    }
  }, [showCreateModal]);

  return (
    <Spin spinning={loading}>
      <PageHeader
        title={title}
        extra={[
          <Button
            hidden={!isAdmin}
            key="create"
            size="small"
            type="primary"
            onClick={() => setShowCreateModal(true)}
          >
            {t('create')}
          </Button>,
          <Button
            hidden={!isAdmin}
            key="reload"
            size="small"
            type="primary"
            onClick={() => getSettings()}
          >
            {t('reload')}
          </Button>,
          <Popconfirm
            hidden={!isAdmin}
            key="removeall"
            title={t('remove_all_settings_prompt')}
            okText={t('yes')}
            onConfirm={() => removeAllSettings()}
          >
            <Button
              size="small"
              type="primary"
            >
              {t('remove_all')}
            </Button>
          </Popconfirm>,
          <Button
            hidden={!isAdmin}
            key="create"
            size="small"
            type="primary"
            onClick={() => exportSettings()}
          >
            {t('export')}
          </Button>
        ]}
      />
      {t('showing')}: {settings?.length}
      <Input
        placeholder={`${t('search')}...`}
        disabled={loading}
        onChange={(obj) => {
          setLastSearch(obj.target.value);
          filterSettings(obj.target.value);
        }}
      />
      <Table
        dataSource={settings}
        pagination={false}
        columns={[
          {
            title: t('key'),
            align: 'center',
            className: 'text-xs',
            render: (row) => (
              <span>{row.key}</span>
            ),
          },
          {
            title: t('value'),
            align: 'center',
            className: 'text-xs',
            render: (row) => (
              <div>
                {row && row.key.toLowerCase().includes('color') && (
                  <div className="flex">
                    <ChromePicker
                      color={hexToRgb(row?.value)}
                      onChangeComplete={(obj) => {
                        const index = settings.findIndex((s) => s.etag === row.etag);
                        const temp = JSON.parse(JSON.stringify(settings));
                        temp[index].value = rgbToHex(obj.rgb);
                        setSettings(temp);
                      }}
                    />
                  </div>
                )}
                {row && !row.key.toLowerCase().includes('color') && (
                  <>
                    <Input
                      value={row.value}
                      onChange={(obj) => {
                        const index = settings.findIndex((s) => s.etag === row.etag);
                        const temp = JSON.parse(JSON.stringify(settings));
                        temp[index].value = obj.target.value;
                        setSettings(temp);
                      }}
                    />
                  </>
                )}
              </div>
            ),
          },
          {
            title: '',
            align: 'center',
            className: 'text-xs',
            render: (row) => (
              <>
                <Popconfirm
                  title={t('update_setting_prompt')}
                  okText={t('yes')}
                  cancelText="No"
                  onConfirm={() => {
                    updateSetting(row);
                  }}
                >
                  <Button
                    type="primary"
                    size="small"
                    className="m-1"
                    loading={row.loading}
                  >
                    {t('update')}
                  </Button>
                </Popconfirm>
                <Popconfirm
                  title={t('delete_setting_prompt')}
                  okText={t('yes')}
                  cancelText="No"
                  onConfirm={() => {
                    deleteSetting(row);
                  }}
                >
                  <Button
                    type="primary"
                    danger
                    size="small"
                    className="m-1"
                    loading={row.loading}
                  >
                    {t('remove')}
                  </Button>
                </Popconfirm>
              </>
            ),
          },
        ]}
      />
      <Modal
        title={t('create_setting')}
        open={showCreateModal}
        onCancel={() => setShowCreateModal(false)}
        footer={[
          <Button
            key="close"
            type="primary"
            danger
            loading={loading}
            onClick={() => setShowCreateModal(false)}
          >
            {t('close')}
          </Button>,
          <Button
            key="ok"
            type="primary"
            disabled={!newSetting.key ||
                !newSetting.value ||
                !(newSetting.key?.length > 0) ||
                !(newSetting.value?.length > 0)}
            loading={loading}
            onClick={() => createSetting()}
          >
            {t('create')}
          </Button>
        ]}
      >
        <div className="mb-2">
          <strong>{t('key')}</strong>
          <Input
            disabled={loading}
            value={newSetting?.key}
            onChange={(obj) => {
              setNewSetting({
                ...newSetting,
                key: obj.target.value.trim()
              });
            }}
          />
        </div>
        <div className="mb-2">
          <strong>{t('value')}</strong>
          <Input
            disabled={loading}
            value={newSetting?.value}
            onChange={(obj) => {
              setNewSetting({
                ...newSetting,
                value: obj.target.value.trim()
              });
            }}
          />
        </div>
      </Modal>
    </Spin>
  );
}

export default Configurations;
