import React from 'react';
import { PageHeader } from '@ant-design/pro-layout';
import stripe from '../../../../../assets/images/stripe.svg';

function Header() {
  return (
    <PageHeader
      className="mb-4 px-0"
      title="Stripe"
      avatar={{ src: stripe }}
    />
  );
}

export default Header;
