import { Button, Tabs } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import React from 'react';
import { useRecoilState } from 'recoil';
import { SyncOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { loadingUserAnalyticsAtom, refreshUserAnalyticsAtom } from '../../../atoms/Atoms';
import Cards from './shared-components/Cards';
import Customers from './shared-components/Customers';
import Orders from './shared-components/Orders';
import Locations from './shared-components/Locations';

function userAnalytics() {
  const [refresh, setRefresh] = useRecoilState(refreshUserAnalyticsAtom);
  const [loading, setLoading] = useRecoilState(loadingUserAnalyticsAtom);
  const { t } = useTranslation();

  return (
    <>
      <PageHeader
        className="mb-4 px-0"
        title={t('customer_analytics')}
        extra={[
          <Button
            type="primary"
            key="refresh"
            size="small"
            icon={<SyncOutlined spin={loading} />}
            onClick={() => {
              setRefresh(!refresh);
            }}
          >
            {t('refresh')}
          </Button>
        ]}
      />
      <Tabs defaultActiveKey="1">
        <Tabs.TabPane tab={t('customers')} key="1">
          <Customers />
        </Tabs.TabPane>
        <Tabs.TabPane tab={t('orders')} key="2">
          <Orders />
        </Tabs.TabPane>
        <Tabs.TabPane tab={t('cards')} key="3">
          <Cards />
        </Tabs.TabPane>
        <Tabs.TabPane tab={t('locations')} key="4">
          <Locations />
        </Tabs.TabPane>
      </Tabs>
    </>
  );
}

export default userAnalytics;
