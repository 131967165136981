import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useRecoilState,
  useRecoilValue,
  useSetRecoilState
} from 'recoil'; // useRecoilState
import {
  Button,
  Table,
  Tag,
  Space,
  Divider,
  Popconfirm,
  Row,
  Col,
  Descriptions,
  message,
  Image,
} from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import _ from 'lodash';
import {
  BuildOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  SyncOutlined,
} from '@ant-design/icons';
import {
  tokenAtom,
  selectedPushAtom,
  reloadPushAtom,
  showPushCreateAtom,
  showPushEditAtom
} from '../../../atoms/Atoms';
import push from '../../../assets/images/push-icon.png';
import EditPushDrawer from './shared-components/EditPushDrawer';
import CreatePushDrawer from './shared-components/CreatePushDrawer';
import api from '../../../api/api';

function PushNotifications() {
  const token = useRecoilValue(tokenAtom);
  const [pns, setPns] = useState([]);
  const setShowCreate = useSetRecoilState(showPushCreateAtom);
  const setShowEdit = useSetRecoilState(showPushEditAtom);
  const setSelectedPush = useSetRecoilState(selectedPushAtom);
  const [reload, setReload] = useRecoilState(reloadPushAtom);
  const [loading, setLoading] = useState(null);
  const { t } = useTranslation();

  function convertStatus(status) {
    if (status === 0) return 'Pending';
    if (status === 1) return 'Approved';
    if (status === 2) return 'Sent';
    if (status === 3) return 'Failed';
    if (status === 4) return 'Denied';
    return 'Unknow';
  }

  function mapPNs(data) {
    return _.map(data, (pn) => ({
      key: pn.id,
      media: { eng: pn.mediaUrlEng, spa: pn.mediaUrl },
      name: pn.name,
      messageENG: pn.messageENG,
      messageSPA: pn.messageSPA,
      status: convertStatus(pn.status),
      publishDate: new Date(pn.deliveryDate).toLocaleString(),
      action: pn,
    }));
  }

  function getPushNotifications() {
    setLoading(true);
    api
      .get('marketing/push/all')
      .then((response) => {
        const mapped = mapPNs(response.data.data.results);
        setPns(mapped);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  function deletePn(id) {
    api.delete(`marketing/push/delete/${id}`)
      .then((response) => {
        if (response.data.success) {
          message.success(t('push_notification_deleted_message'));
        } else {
          message.error(
            t('push_notification_error_message')
          );
        }
        setReload(true);
      })
      .catch((error) => message.error(error.message));
  }

  function approvePn(id) {
    api.put(`marketing/push/approve/${id}`, { })
      .then((response) => {
        if (response.data.success) {
          message.success(t('push_notification_approved_message'));
        } else {
          message.error(
            t('push_notification_approved_error_message')
          );
        }
        setReload(true);
      })
      .catch((error) => message.error(error.message));
  }

  function cancelPn(id) {
    api.put(`marketing/push/cancel/${id}`, { })
      .then((response) => {
        if (response.data.success) {
          message.success(t('push_notification_canceled_message'));
        } else {
          message.error(
            t('push_noitification_cancel_error_message')
          );
        }
        setReload(true);
      })
      .catch((error) => message.error(error.message));
  }

  useEffect(() => {
    getPushNotifications();
  }, []);

  useEffect(() => {
    if (reload) {
      getPushNotifications();
      setReload(false);
    }
  }, [reload]);

  return (
    <>
      <PageHeader
        className="mb-4 px-0"
        title={t('push_notification')}
        avatar={{ src: push }}
        extra={[
          <Button
            key="create"
            type="primary"
            icon={<BuildOutlined />}
            size="small"
            onClick={() => {
              setShowCreate(true);
            }}
          >
            {t('create')}
          </Button>,
          <Button
            key="reload"
            type="primary"
            icon={<SyncOutlined />}
            size="small"
            loading={loading}
            onClick={() => {
              setReload(true);
            }}
          >
            {t('reload')}
          </Button>
        ]}
      />
      <Divider />
      <Table
        size="small"
        bordered
        loading={loading}
        rowKey="id"
        columns={[
          {
            title: t('name'),
            dataIndex: 'name',
            key: 'name',
            align: 'center',
            className: 'text-xs font-medium',
            render: (text) => <span>{text}</span>,
          },
          {
            title: t('publish_date'),
            dataIndex: 'publishDate',
            key: 'publishDate',
            align: 'center',
            className: 'text-xs font-medium',
            render: (text) => <span>{text}</span>,
          },
          {
            title: t('status'),
            dataIndex: 'status',
            key: 'status',
            align: 'center',
            className: 'text-xs font-medium',
            render: (text, record) => {
              if (record.status === 'Pending') {
                return (
                  <Tag
                    icon={<SyncOutlined spin />}
                    color="#33AFFF"
                    size="small"
                    className="mr-0"
                  >
                    {t('status')}
                  </Tag>
                );
              }
              if (record.status === 'Approved') {
                return (
                  <Tag
                    icon={<CheckCircleOutlined />}
                    color="#33AFFF"
                    size="small"
                    className="mr-0"
                  >
                    {t('approved')}
                  </Tag>
                );
              }
              if (record.status === 'Sent') {
                return (
                  <Tag
                    icon={<CheckCircleOutlined />}
                    color="#87d068"
                    size="small"
                    className="mr-0"
                  >
                    {t('sent')}
                  </Tag>
                );
              }
              if (record.status === 'Failed') {
                return (
                  <Tag
                    icon={<CloseCircleOutlined />}
                    color="error"
                    size="small"
                    className="mr-0"
                  >
                    {t('failed')}
                  </Tag>
                );
              }
              return (
                <Tag
                  icon={<CloseCircleOutlined />}
                  color="error"
                  size="small"
                  className="mr-0"
                >
                  {t('denied')}
                </Tag>
              );
            },
          },
          {
            title: t('actions'),
            dataIndex: 'action',
            key: 'action',
            align: 'center',
            className: 'text-xs',
            render: (pn) => (
              <Space>
                <Button
                  onClick={() => {
                    setSelectedPush(pn);
                    setShowEdit(true);
                  }}
                  type="primary"
                >
                  {t('edit')}
                </Button>
                {pn.status === 0 && (
                  <>
                    <Popconfirm
                      placement="topRight"
                      title={t('push_notification_confirmation_approval')}
                      onConfirm={() => approvePn(pn.id)}
                      okText={t('yes')}
                      cancelText="No"
                    >
                      <Button type="primary">Approve</Button>
                    </Popconfirm>
                    <Popconfirm
                      placement="topRight"
                      title={t('push_notification_confirmation_delete')}
                      onConfirm={() => deletePn(pn.id)}
                      okText={t('yes')}
                      cancelText="No"
                    >
                      <Button
                        type="primary"
                        danger
                      >
                        {t('delete')}
                      </Button>
                    </Popconfirm>
                  </>
                )}
                {pn.status === 1 && (
                  <>
                    <Popconfirm
                      placement="topRight"
                      title={t('push_notification_cancel_approval')}
                      onConfirm={() => cancelPn(pn.id)}
                      okText="Yes"
                      cancelText="No"
                    >
                      <Button
                        type="primary"
                        danger
                      >
                        {t('cancel')}
                      </Button>
                    </Popconfirm>
                  </>
                )}
              </Space>
            ),
          },
        ]}
        dataSource={pns}
        expandable={{
          expandedRowRender: (record) => (
            <div>
              <Row>
                <Col span={8}>
                  <Descriptions>
                    <Descriptions.Item label={t('english')} />
                    <br />
                    <Descriptions.Item>
                      <Col span={24}>
                        <span>{record.messageENG}</span>
                      </Col>
                    </Descriptions.Item>
                    <br />
                    <Descriptions.Item>
                      <Col span={24}>
                        <Image
                          alt="eng"
                          width={200}
                          src={record.media.eng}
                        />
                      </Col>
                    </Descriptions.Item>
                  </Descriptions>
                </Col>
                <Col span={8} offset={4}>
                  <Descriptions>
                    <Descriptions.Item label={t('spanish')} />
                    <br />
                    <Descriptions.Item>
                      <Col span={24}>
                        <span>{record.messageSPA}</span>
                      </Col>
                    </Descriptions.Item>
                    <br />
                    <Descriptions.Item>
                      <Col span={24}>
                        <Image
                          alt="spa"
                          width={200}
                          src={record.media.spa}
                        />
                      </Col>
                    </Descriptions.Item>
                  </Descriptions>
                </Col>
              </Row>
            </div>
          ),
          rowExpandable: (record) => record,
        }}
      />
      <EditPushDrawer />
      <CreatePushDrawer />
    </>
  );
}

export default PushNotifications;
