import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import {
  useRecoilState,
  useRecoilValueLoadable,
  useSetRecoilState
} from 'recoil';
import { useTranslation } from 'react-i18next';
import {
  Drawer,
  Avatar,
  Tooltip,
  DatePicker,
  Upload,
  InputNumber,
  Collapse,
  Image,
  Row,
  Col,
  Button,
  Select,
  Switch,
  Input,
  notification
} from 'antd';
import { Comment } from '@ant-design/compatible';
import {
  InboxOutlined,
  CloseOutlined
} from '@ant-design/icons';
import moment from 'moment';
import dayjs from 'dayjs';
import Grocefy from '../../../../assets/images/grocefyLogoAlone.png';
import {
  refreshInfoCardsAtom,
  selectedInfoCardAtom
} from '../../../../atoms/Atoms';
import {
  businessesSelector
} from '../../../../atoms/Selectors';
import api from '../../../../api/api';
import envConfig from '../../../../envConfig';

function EditInfoCardDrawer() {
  const { RangePicker } = DatePicker;
  const { Dragger } = Upload;
  const { Panel } = Collapse;
  const { TextArea } = Input;
  const { Option } = Select;
  const businesses = useRecoilValueLoadable(businessesSelector);
  const setRefreshInfoCards = useSetRecoilState(refreshInfoCardsAtom);
  const [selectedInfoCard, setSelectedInfoCard] = useRecoilState(selectedInfoCardAtom);
  const [loading, setLoading] = useState(false);
  const [infoCard, setInfoCard] = useState({});
  const [imageEnglishData, setImageEnglishData] = useState(null);
  const [imageSpanishData, setImageSpanishData] = useState(null);
  const { t } = useTranslation();

  const englishDraggerProps = {
    name: 'file',
    accept: 'image/*',
    multiple: false,
    showUploadList: false,
    beforeUpload: (file) => {
      setImageEnglishData(file);
      return false;
    }
  };
  const spanishDraggerProps = {
    name: 'file',
    accept: 'image/*',
    multiple: false,
    showUploadList: false,
    beforeUpload: (file) => {
      setImageSpanishData(file);
      return false;
    }
  };

  const showMessage = function (message) {
    notification.open({
      message: '',
      description: (
        <Comment
          author={<span>Grocefy</span>}
          avatar={<Avatar src={Grocefy} alt="grocefy" />}
          content={
            <p className="text-sm">
              {message}
            </p>
          }
          datetime={
            <Tooltip title={moment().format('YYYY-MM-DD HH:mm:ss')}>
              <span>{moment().fromNow()}</span>
            </Tooltip>
          }
        />
      ),
    });
  };

  const removeEnlgishImage = function () {
    const temp = JSON.parse(JSON.stringify(infoCard));
    temp.imageUrl = '';
    setInfoCard(temp);
  };

  const removeSpanishImage = function () {
    const temp = JSON.parse(JSON.stringify(infoCard));
    temp.imageUrl2 = '';
    setInfoCard(temp);
  };

  const onChangeItemName = function (obj) {
    const temp = JSON.parse(JSON.stringify(infoCard));
    temp.name = obj.target.value;
    setInfoCard(temp);
  };

  const onChangeBusiness = function (obj) {
    const temp = JSON.parse(JSON.stringify(infoCard));
    temp.businessId = obj;
    setInfoCard(temp);
  };

  const onChangeDate = function (date, dateString) {
    setInfoCard({
      ...infoCard,
      startDateTime: dateString[0],
      endDateTime: dateString[1]
    });
  };

  const onChangeEngTitle = function (obj) {
    const temp = JSON.parse(JSON.stringify(infoCard));
    temp.titleEng = obj.target.value;
    setInfoCard(temp);
  };

  const onChangeSpaTitle = function (obj) {
    const temp = JSON.parse(JSON.stringify(infoCard));
    temp.titleSpa = obj.target.value;
    setInfoCard(temp);
  };

  const onChangeEngBody = function (obj) {
    const temp = JSON.parse(JSON.stringify(infoCard));
    temp.bodyEng = obj.target.value;
    temp.bodyEng2 = obj.target.value;
    setInfoCard(temp);
  };

  const onChangeSpaBody = function (obj) {
    const temp = JSON.parse(JSON.stringify(infoCard));
    temp.bodySpa = obj.target.value;
    temp.bodySpa2 = obj.target.value;
    setInfoCard(temp);
  };

  const onChangeEngHtmlBody = function (obj) {
    const temp = JSON.parse(JSON.stringify(infoCard));
    temp.htmlBodyEng = obj.target.value;
    setInfoCard(temp);
  };

  const onChangeSpaHtmlBody = function (obj) {
    const temp = JSON.parse(JSON.stringify(infoCard));
    temp.htmlBodySpa = obj.target.value;
    setInfoCard(temp);
  };

  const onChangeAnction = function (obj) {
    const temp = JSON.parse(JSON.stringify(infoCard));
    temp.action = obj;
    setInfoCard(temp);
  };

  const onChangeAnctionEng = function (obj) {
    const temp = JSON.parse(JSON.stringify(infoCard));
    temp.actionEng = obj.target.value;
    setInfoCard(temp);
  };

  const onChangeAnctionSpa = function (obj) {
    const temp = JSON.parse(JSON.stringify(infoCard));
    temp.actionSpa = obj.target.value;
    setInfoCard(temp);
  };

  const onChangeAnctionLink = function (obj) {
    const temp = JSON.parse(JSON.stringify(infoCard));
    temp.actionUrl = obj.target.value;
    setInfoCard(temp);
  };

  const submitInfoCard = function () {
    setLoading(true);
    const temp = JSON.parse(JSON.stringify(infoCard));
    if (temp.action === '') {
      temp.actionUrl = '';
    } else if (temp.action !== '' && temp.actionUrl && temp.actionUrl?.length > 0) {
      temp.actionUrl = temp.action + temp.actionUrl;
    } else {
      temp.actionUrl = temp.action;
    }
    // temp.startDateTime = moment(temp.startDateTime).utc();
    // temp.endDateTime = moment(temp.endDateTime).utc();
    // if (temp.startDateTime.length < 17) {
    //   temp.startDateTime = `${temp.startDateTime.replace(' ', 'T')}:00.000`;
    //   temp.endDateTime = `${temp.endDateTime.replace(' ', 'T')}:00.000`;
    // }

    temp.startDateTime = moment(temp.startDateTime)
      .add(-1 * envConfig.REACT_APP_TIMEZONE_OFFSET, 'hour')
      .format('YYYY-MM-DDTHH:mm:ss');
    temp.endDateTime = moment(temp.endDateTime)
      .add(-1 * envConfig.REACT_APP_TIMEZONE_OFFSET, 'hour')
      .format('YYYY-MM-DDTHH:mm:ss');

    api.post(
      'marketing/infocard',
      temp
    )
      .then((response) => {
        setLoading(false);
        if (response.data.success) {
          setRefreshInfoCards(true);
          setSelectedInfoCard(null);
        } else {
          showMessage(response.data.error);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  const archiveClick = function () {
    setLoading(true);
    api.delete(`marketing/infocards/${infoCard.id}`)
      .then((response) => {
        setLoading(false);
        if (response.data.success) {
          setRefreshInfoCards(true);
          setSelectedInfoCard(null);
        } else {
          console.error(response.data.error);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  useEffect(() => {
    if (selectedInfoCard) {
      const temp = JSON.parse(JSON.stringify(selectedInfoCard));
      if (!temp.id) {
        temp.action = '';
        temp.ordering = 99;
        temp.isActive = false;
        temp.showInApp = false;
        temp.showInWeb = false;
        temp.useDefaultHeight = false;
        temp.height = 150;
      }
      if (temp.actionUrl) {
        if (temp.actionUrl.includes('openlink?url=')) {
          temp.action = 'openlink?url=';
          temp.actionUrl = temp.actionUrl.replace('openlink?url=', '');
        } else if (temp.actionUrl.includes('video?url=')) {
          temp.action = 'video?url=';
          temp.actionUrl = temp.actionUrl.replace('video?url=', '');
        } else if (temp.actionUrl.includes('action?url=enterstore')) {
          temp.action = 'action?url=enterstore';
          temp.actionUrl = temp.actionUrl.replace('action?url=enterstore', '');
        } else if (temp.actionUrl.includes('action?url=brandsearch:')) {
          temp.action = 'action?url=brandsearch:';
          temp.actionUrl = temp.actionUrl.replace('action?url=brandsearch:', '');
        } else if (temp.actionUrl.includes('action?url=deliverystores')) {
          temp.action = 'action?url=deliverystores';
          temp.actionUrl = temp.actionUrl.replace('action?url=deliverystores', '');
        } else if (temp.actionUrl.includes('action?url=pickupstores')) {
          temp.action = 'action?url=pickupstores';
          temp.actionUrl = temp.actionUrl.replace('action?url=pickupstores', '');
        } else if (temp.actionUrl.includes('action?url=')) {
          temp.action = 'action?url=';
          temp.actionUrl = temp.actionUrl.replace('action?url=', '');
        }
      } else {
        temp.action = '';
      }
      if (!temp.startDateTime || temp.startDateTime.length === 0) {
        temp.startDateTime = moment().startOf('day').format('YYYY-MM-DD hh:mm A');
      }
      if (!temp.endDateTime || temp.endDateTime.length === 0) {
        temp.endDateTime = moment().endOf('day').format('YYYY-MM-DD hh:mm A');
      }
      setInfoCard(temp);
    }
  }, [selectedInfoCard]);

  useEffect(() => {
    if (imageEnglishData) {
      const file = imageEnglishData;
      const boundary = Date.now();
      const data = new FormData();
      data.append('file', file);
      setLoading(true);
      api
        .post('utility/infocard/uploadfile', data)
        .then((response) => {
          setLoading(false);
          setImageEnglishData(null);
          if (response.data.success) {
            const temp = JSON.parse(JSON.stringify(infoCard));
            temp.imageUrl = response.data.data;
            setInfoCard(temp);
          } else {
            showMessage(response.data.error);
          }
        })
        .catch((error) => {
          setLoading(false);
          setImageEnglishData(null);
          console.error(error);
        });
    }
  }, [imageEnglishData]);

  useEffect(() => {
    if (imageSpanishData) {
      const file = imageSpanishData;
      const boundary = Date.now();
      const data = new FormData();
      data.append('file', file);
      setLoading(true);
      api
        .post('utility/infocard/uploadfile', data)
        .then((response) => {
          setLoading(false);
          setImageSpanishData(null);
          if (response.data.success) {
            const temp = JSON.parse(JSON.stringify(infoCard));
            temp.imageUrl2 = response.data.data;
            setInfoCard(temp);
          } else {
            showMessage(response.data.error);
          }
        })
        .catch((error) => {
          setLoading(false);
          setImageSpanishData(null);
          console.error(error);
        });
    }
  }, [imageSpanishData]);

  return (
    <Drawer
      title={`${infoCard && infoCard.id ? t('update') : t('create')} Info Card`}
      placement="right"
      closable
      onClose={() => setSelectedInfoCard(null)}
      open={selectedInfoCard}
      width={400}
    >
      {selectedInfoCard && (
        <>
          <div
            style={{
              float: 'right',
              marginBottom: '10px'
            }}
          >
            <Button
              type="primary"
              danger
              loading={loading}
              hidden={!infoCard.id}
              onClick={() => { archiveClick(); }}
            >
              {t('archive')}
            </Button>
          </div>
          <div style={{ marginBottom: 10 }}>
            <strong>{t('name')}:</strong>
            <Input
              disabled={loading}
              placeholder="Name"
              onChange={(obj) => onChangeItemName(obj)}
              value={infoCard.name}
            />
          </div>

          <Collapse defaultActiveKey={['1']}>
            <Panel header={t('general_data')} key="1">
              <div
                style={{ marginBottom: 10 }}
              >
                <strong>{t('is_active')}</strong>
                <Switch
                  style={{ float: 'right' }}
                  disabled={loading}
                  onChange={(obj) => {
                    const temp = JSON.parse(JSON.stringify(infoCard));
                    temp.isActive = obj;
                    setInfoCard(temp);
                  }}
                  checked={infoCard?.isActive}
                />
              </div>
              <div
                style={{ marginBottom: 10 }}
              >
                <strong>{t('show_in_app')}</strong>
                <Switch
                  style={{ float: 'right' }}
                  disabled={loading}
                  onChange={(obj) => {
                    const temp = JSON.parse(JSON.stringify(infoCard));
                    temp.showInApp = obj;
                    setInfoCard(temp);
                  }}
                  checked={infoCard?.showInApp}
                />
              </div>
              <div
                style={{ marginBottom: 10 }}
              >
                <strong>{t('show_in_web')}</strong>
                <Switch
                  style={{ float: 'right' }}
                  disabled={loading}
                  onChange={(obj) => {
                    const temp = JSON.parse(JSON.stringify(infoCard));
                    temp.showInWeb = obj;
                    setInfoCard(temp);
                  }}
                  checked={infoCard?.showInWeb}
                />
              </div>
              <div
                style={{ marginBottom: 10 }}
              >
                <strong>{t('use_default_height')}</strong>
                <Switch
                  style={{ float: 'right' }}
                  disabled={loading}
                  onChange={(obj) => {
                    const temp = JSON.parse(JSON.stringify(infoCard));
                    temp.useDefaultHeight = obj;
                    setInfoCard(temp);
                  }}
                  checked={infoCard?.useDefaultHeight}
                />
                <div hidden={infoCard?.useDefaultHeight}>
                  <InputNumber
                    disabled={loading}
                    onChange={(obj) => {
                      const temp = JSON.parse(JSON.stringify(infoCard));
                      temp.height = obj;
                      setInfoCard(temp);
                    }}
                    value={infoCard.height}
                  />
                </div>
              </div>
              <div
                style={{ marginBottom: 10 }}
              >
                <strong>{t('store')}: </strong>
                <Select
                  defaultValue={infoCard.businessId}
                  value={infoCard.businessId}
                  style={{ width: 120 }}
                  disabled={loading}
                  onChange={(obj) => onChangeBusiness(obj)}
                >
                  <Option value={null}>Global</Option>
                  {businesses.state === 'hasValue' && _.map(businesses.contents.data.data.results, (business) => (
                    <Option
                      value={business.id}
                      key={business.id}
                    >
                      {business.name}
                    </Option>
                  ))}
                </Select>
              </div>
              <div style={{ marginBottom: 10 }}>
                <strong>{t('english_title')}:</strong>
                <Input
                  disabled={loading}
                  placeholder={t('english_title')}
                  onChange={(obj) => onChangeEngTitle(obj)}
                  value={infoCard.titleEng}
                />
              </div>
              <div style={{ marginBottom: 10 }}>
                <strong>{t('spanish_title')}:</strong>
                <Input
                  disabled={loading}
                  placeholder={t('spanish_title')}
                  onChange={(obj) => onChangeSpaTitle(obj)}
                  value={infoCard.titleSpa}
                />
              </div>
              <div style={{ marginBottom: 10 }}>
                <strong>{t('english_body')}:</strong>
                <TextArea
                  disabled={loading}
                  rows={3}
                  placeholder={t('english_body')}
                  onChange={(obj) => onChangeEngBody(obj)}
                  value={infoCard.bodyEng}
                />
              </div>
              <div style={{ marginBottom: 10 }}>
                <strong>{t('spanish_body')}:</strong>
                <TextArea
                  rows={3}
                  disabled={loading}
                  placeholder={t('spanish_body')}
                  onChange={(obj) => onChangeSpaBody(obj)}
                  value={infoCard.bodySpa}
                />
              </div>
              <div style={{ marginBottom: 10 }}>
                <strong>{t('english_html_body')}:</strong>
                <Input
                  disabled={loading}
                  placeholder={t('english_html_body')}
                  onChange={(obj) => onChangeEngHtmlBody(obj)}
                  value={infoCard.htmlBodyEng}
                />
              </div>
              <div style={{ marginBottom: 10 }}>
                <strong>{t('spanish_html_body')}:</strong>
                <Input
                  disabled={loading}
                  placeholder={t('spanish_html_body')}
                  onChange={(obj) => onChangeSpaHtmlBody(obj)}
                  value={infoCard.htmlBodySpa}
                />
              </div>
              <div style={{ marginBottom: 10 }}>
                <strong>{t('ordering')}</strong>
                <br />
                <InputNumber
                  disabled={loading}
                  min={0}
                  onChange={(obj) => {
                    const temp = JSON.parse(JSON.stringify(infoCard));
                    temp.ordering = obj;
                    setInfoCard(temp);
                  }}
                  value={infoCard.ordering}
                />
              </div>
            </Panel>
            <Panel header={t('images')} key="2">
              <div style={{ marginBottom: 10 }}>
                <Tooltip title={t('image_requirements')}>
                  <strong>{t('english_image')}:</strong>
                </Tooltip>
                <Input
                  value={infoCard.imageUrl}
                  onChange={(obj) => {
                    const temp = JSON.parse(JSON.stringify(infoCard));
                    temp.imageUrl = obj.target.value;
                    setInfoCard(temp);
                  }}
                />
                <Dragger {...englishDraggerProps}>
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p
                    className="ant-upload-text"
                    style={{ margin: 10 }}
                  >
                    {t('click_or_drag_content')}
                  </p>
                </Dragger>
                {infoCard.imageUrl && (
                  <>
                    <Image
                      alt={infoCard.imageUrl}
                      src={infoCard.imageUrl}
                      height={75}
                    />
                    <Button
                      loading={loading}
                      type="primary"
                      danger
                      onClick={() => { removeEnlgishImage(); }}
                    >
                      <CloseOutlined />
                    </Button>
                  </>
                )}
              </div>
              <div style={{ marginBottom: 10 }}>
                <Tooltip title={t('image_requirements')}>
                  <strong>{t('spanish_image')}:</strong>
                </Tooltip>
                <Input
                  value={infoCard.imageUrl2}
                  onChange={(obj) => {
                    const temp = JSON.parse(JSON.stringify(infoCard));
                    temp.imageUrl2 = obj.target.value;
                    setInfoCard(temp);
                  }}
                />
                <Dragger {...spanishDraggerProps}>
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p
                    className="ant-upload-text"
                    style={{ margin: 10 }}
                  >
                    {t('click_or_drag_content')}
                  </p>
                </Dragger>
                {infoCard.imageUrl2 && (
                  <>
                    <Image
                      alt={infoCard.imageUrl2}
                      src={infoCard.imageUrl2}
                      height={75}
                    />
                    <Button
                      loading={loading}
                      type="primary"
                      danger
                      onClick={() => { removeSpanishImage(); }}
                    >
                      <CloseOutlined />
                    </Button>
                  </>
                )}
              </div>
            </Panel>
            <Panel header={t('dates')} key="3">
              <div style={{ marginBottom: 10 }}>
                {/* <strong>{'Start Date -> End Date:'}</strong> */}
                <RangePicker
                  format="YYYY-MM-DD hh:mm A"
                  showTime
                  value={[
                    dayjs(infoCard.startDateTime, 'YYYY-MM-DD hh:mm A'),
                    dayjs(infoCard.endDateTime, 'YYYY-MM-DD hh:mm A')
                  ]}
                  onChange={(date, dateString) => onChangeDate(date, dateString)}
                />
              </div>
            </Panel>
            <Panel header={t('actions')} key="4">
              <div style={{ marginBottom: 10 }}>
                <strong>{t('action')}:</strong>
                <Select
                  defaultValue={infoCard.action}
                  value={infoCard.action}
                  style={{ width: 150 }}
                  disabled={loading}
                  onChange={(obj) => onChangeAnction(obj)}
                >
                  <Option value="">None</Option>
                  <Option value="openlink?url=">Link</Option>
                  <Option value="video?url=">Video</Option>
                  <Option value="action?url=pickupstores">Pickup Stores</Option>
                  <Option value="action?url=deliverystores">Delivery Stores</Option>
                  <Option value="action?url=enterstore">Enter Store</Option>
                  <Option value="action?url=brandsearch:">Brand Search</Option>
                </Select>
                <Row
                  hidden={!infoCard.action || infoCard.length === 0 ||
                    infoCard.action === 'action?url=pickupstores' ||
                    infoCard.action === 'action?url=deliverystores'}
                >
                  <Col>
                    <div>
                      <strong>{t('eng_action_text')}:</strong>
                      <Input
                        disabled={loading}
                        placeholder={t('eng_action_text')}
                        onChange={(obj) => onChangeAnctionEng(obj)}
                        value={infoCard.actionEng}
                      />
                    </div>
                  </Col>
                  <Col>
                    <strong>{t('spa_action_text')}:</strong>
                    <Input
                      disabled={loading}
                      placeholder={t('spa_action_text')}
                      onChange={(obj) => onChangeAnctionSpa(obj)}
                      value={infoCard.actionSpa}
                    />
                  </Col>
                </Row>
                <div
                  style={{ marginBottom: 10 }}
                  hidden={!infoCard.action || infoCard.length === 0 ||
                    infoCard.action === 'action?url=pickupstores' ||
                    infoCard.action === 'action?url=deliverystores'}
                >
                  <strong>{t('action_url')}:</strong>
                  <Input
                    disabled={loading}
                    placeholder="Url"
                    onChange={(obj) => onChangeAnctionLink(obj)}
                    value={infoCard.actionUrl}
                  />
                </div>
              </div>
            </Panel>
          </Collapse>
          <div
            style={{
              float: 'right',
              marginTop: '10px'
            }}
          >
            <Button
              style={{
                marginLeft: '10px'
              }}
              loading={loading}
              type="primary"
              onClick={() => { submitInfoCard(); }}
            >
              {infoCard.id ? t('update') : t('create')}
            </Button>
          </div>
        </>
      )}
    </Drawer>
  );
}

export default EditInfoCardDrawer;
