import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import {
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
  useRecoilValueLoadable
} from 'recoil';
import { useTranslation } from 'react-i18next';
import {
  Drawer,
  Avatar,
  Tooltip,
  Input,
  InputNumber,
  Button,
  Checkbox,
  Switch,
  notification,
  Table,
  Row,
  Col,
  Popconfirm,
  Popover
} from 'antd';
import { Comment } from '@ant-design/compatible';
import {
  UpOutlined,
  DownOutlined
} from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import Grocefy from '../../../../assets/images/grocefyLogoAlone.png';
import {
  stateAtom,
  setSelectedMasterItemAtom,
  setSelectedBusinessItemAtom,
  showItemMediaAtom,
  showItemFiltersAtom,
  showItemGtinsAtom,
  otherBusinessItemsAtom,
  showItemRecommendationsAtom,
  manageRequirementsAtom,
  manageSidesAtom,
  showItemSubstitutesAtom,
  manageAddonsAtom,
  setSearchMetadataAtom,
  editBusinessItemReloadAtom,
  selectedItemForLogsAtom,
  profileAtom,
  timezoneAtom,
  reloadBusinessItemsAtom
} from '../../../../atoms/Atoms';
import { businessesSelector, configSelector } from '../../../../atoms/Selectors';
import api from '../../../../api/api';
import envConfig from '../../../../envConfig';
import { convertTaxRule, percent, renderDate, thousand, toCurrency } from '../../../utils/functions';

function EditItemDrawer() {
  const [searchMetadata, setSearchMetadata] = useRecoilState(setSearchMetadataAtom);
  const businessesSelect = useRecoilValueLoadable(businessesSelector);
  const config = useRecoilValueLoadable(configSelector);
  const currentState = useRecoilValue(stateAtom);
  const timezone = useRecoilValue(timezoneAtom);
  const setManageRequirements = useSetRecoilState(manageRequirementsAtom);
  const setManageSides = useSetRecoilState(manageSidesAtom);
  const setManageAddons = useSetRecoilState(manageAddonsAtom);
  const setSelectedBusinessItem = useSetRecoilState(setSelectedBusinessItemAtom);
  const setShowItemMedia = useSetRecoilState(showItemMediaAtom);
  const setShowItemGtins = useSetRecoilState(showItemGtinsAtom);
  const setShowItemFilters = useSetRecoilState(showItemFiltersAtom);
  const setShowItemRecommendations = useSetRecoilState(showItemRecommendationsAtom);
  const setShowItemSubstitutes = useSetRecoilState(showItemSubstitutesAtom);
  const setOtherBusinessItems = useSetRecoilState(otherBusinessItemsAtom);
  const setSelectedItemForLogs = useSetRecoilState(selectedItemForLogsAtom);
  const [reloadBusinessItems, setReloadBusinessItems] = useRecoilState(reloadBusinessItemsAtom);
  const [selectedMasterItem, setSelectedMasterItem] = useRecoilState(setSelectedMasterItemAtom);
  const [loading, setLoading] = useState(false);
  const [loadingBusinessItems, setLoadingBusinessItems] = useState(false);
  const [businessItems, setBusinessItems] = useState([]);
  const [missingBusiness, setMissingBusiness] = useState([]);
  const [showMissingStores, setShowMissingStores] = useState(false);
  const [itemData, setItemData] = useState(null);
  const { t, i18n } = useTranslation();
  const width = window.innerWidth;
  const [reload, setReload] = useState(false);
  const [reloadSearch, setReloadSearch] = useRecoilState(editBusinessItemReloadAtom);
  const profile = useRecoilValueLoadable(profileAtom);
  const isSuperAdmin =
    profile.contents && (profile?.contents?.roles?.includes('SuperAdmin'));

  const showMessage = function (message) {
    notification.open({
      message: '',
      description: (
        <Comment
          author={<span>Grocefy</span>}
          avatar={<Avatar src={Grocefy} alt="grocefy" />}
          content={
            <p className="text-sm">
              {message}
            </p>
          }
          datetime={
            <Tooltip title={moment().format('YYYY-MM-DD HH:mm:ss')}>
              <span>{moment().fromNow()}</span>
            </Tooltip>
          }
        />
      ),
    });
  };

  const getBusinessItems = () => {
    setLoadingBusinessItems(true);
    api
      .get(`businesses/catalog/${selectedMasterItem.id}/getassigned`)
      .then((response) => {
        if (response.data.success) {
          setLoadingBusinessItems(false);
          setBusinessItems(response.data.data.results);
        } else {
          showMessage(response.data.error);
        }
      })
      .catch((error) => {
        setLoadingBusinessItems(false);
        showMessage(error);
      });
  };

  const assignStoreClick = function (business) {
    setLoadingBusinessItems(true);
    api
      .post(
        `businesses/catalog/${business.id}/assign/${selectedMasterItem.id}`,
        { }
      )
      .then((response) => {
        if (response.data.success) {
          getBusinessItems();
        } else {
          setLoadingBusinessItems(false);
          showMessage(response.data.error);
        }
      })
      .catch((error) => {
        setLoadingBusinessItems(false);
        showMessage(error);
      });
  };

  const archiveItemClick = function () {
    setLoading(true);
    api
      .post(
        `businesses/catalog/archive/${selectedMasterItem.id}`,
        { }
      )
      .then((response) => {
        if (response.data.success) {
          setSelectedMasterItem(null);
        } else {
          showMessage(response.data.error);
        }
      })
      .catch((error) => {
        setLoading(false);
        showMessage(error);
      });
  };

  const forcePriceUpdate = function () {
    setLoading(true);
    setLoadingBusinessItems(true);
    api
      .post(
        `pos/price/force/${selectedMasterItem.id}`,
        { }
      )
      .then((response) => {
        setLoading(false);
        if (response.data.success) {
          getBusinessItems();
        } else {
          setLoadingBusinessItems(false);
          showMessage(response.data.error);
        }
      })
      .catch((error) => {
        setLoadingBusinessItems(false);
        setLoading(false);
        showMessage(error);
      });
  };

  const checkItemValid = function () {
    if (itemData.gtin.length === 0) {
      showMessage('Must enter a GTIN');
      return false;
    }
    if (itemData.name.length === 0) {
      showMessage('Must enter a Name');
      return false;
    }
    // if (itemData.description.length === 0) {
    //   showMessage('Must enter a Description');
    //   return false;
    // }
    return true;
  };

  const updateItemClick = function () {
    const isValid = checkItemValid();
    if (isValid) {
      setLoading(true);
      api.post(
        `businesses/catalog/${selectedMasterItem.id}/update`,
        itemData
      )
        .then((response) => {
          setLoading(false);
          if (!response.data.success) {
            showMessage(response.data.error);
          }
          setReloadSearch(!reloadSearch);
          setSelectedMasterItem(itemData);
        })
        .catch((error) => {
          setLoading(false);
          console.error(error);
        });
    }
  };

  const onChangeSearchTags = function (obj) {
    const temp = JSON.parse(JSON.stringify(itemData));
    temp.searchTags = obj.target.value;
    setItemData(temp);
  };

  const onChangeItemGtin = function (obj) {
    const temp = JSON.parse(JSON.stringify(itemData));
    temp.gtin = obj.target.value;
    setItemData(temp);
  };
  const onChangeItemInternalId = function (obj) {
    const temp = JSON.parse(JSON.stringify(itemData));
    temp.internalId = obj.target.value;
    setItemData(temp);
  };
  const onChangeItemBrand = function (obj) {
    const temp = JSON.parse(JSON.stringify(itemData));
    temp.brand = obj.target.value;
    setItemData(temp);
  };
  const onChangeItemName = function (obj) {
    const temp = JSON.parse(JSON.stringify(itemData));
    temp.name = obj.target.value;
    setItemData(temp);
  };
  const onChangeItemDescription = function (obj) {
    const temp = JSON.parse(JSON.stringify(itemData));
    temp.description = obj.target.value;
    setItemData(temp);
  };

  const onChangeIsVisible = function (businessItem) {
    setLoadingBusinessItems(true);
    api
      .post(
        `businesses/catalog/business/${businessItem.id}/update`,
        {
          IsVisible: !businessItem.isVisible
        }
      )
      .then((response) => {
        setLoadingBusinessItems(false);
        if (response.data.success) {
          const temp = JSON.parse(JSON.stringify(businessItems));
          for (let index = 0; index < temp.length; index++) {
            if (temp[index].id === businessItem.id) {
              temp[index].isVisible = !temp[index].isVisible;
            }
          }
          setBusinessItems(temp);
        } else {
          showMessage(response.data.error);
        }
      })
      .catch((error) => {
        setLoadingBusinessItems(false);
        showMessage(error);
      });
  };

  const onChangeIsAvailable = function (businessItem) {
    setLoadingBusinessItems(true);
    api
      .post(
        `businesses/catalog/business/${businessItem.id}/update`,
        {
          IsAvailable: !businessItem.isAvailable
        }
      )
      .then((response) => {
        setLoadingBusinessItems(false);
        if (response.data.success) {
          const temp = JSON.parse(JSON.stringify(businessItems));
          for (let index = 0; index < temp.length; index++) {
            if (temp[index].id === businessItem.id) {
              temp[index].isAvailable = !temp[index].isAvailable;
            }
          }
          setBusinessItems(temp);
        } else {
          showMessage(response.data.error);
        }
      })
      .catch((error) => {
        setLoadingBusinessItems(false);
        showMessage(error);
      });
  };

  useEffect(() => {
    if (reloadBusinessItems) {
      getBusinessItems();
      setReloadBusinessItems(false);
    }
  }, [reloadBusinessItems]);

  useEffect(() => {
    if (selectedMasterItem) {
      setItemData(selectedMasterItem);
      getBusinessItems();
    }
  }, [selectedMasterItem]);

  useEffect(() => {
    if (businessItems && businessesSelect.state === 'hasValue') {
      const businesses = businessesSelect.contents.data.data.results;
      const missingAssociations = [];
      for (let i = 0; i < businesses.length; i++) {
        let foundAssociation = false;
        for (let j = 0; j < businessItems.length; j++) {
          if (businessItems[j].businessId === businesses[i].id) {
            foundAssociation = true;
          }
        }
        if (!foundAssociation) {
          missingAssociations.push(businesses[i]);
        }
      }
      if (!missingAssociations) {
        setShowMissingStores(false);
      }
      setMissingBusiness(missingAssociations);
    }
  }, [businessesSelect, businessItems]);

  return (
    <Drawer
      title={t('edit_item')}
      placement="right"
      closable
      onClose={() => setSelectedMasterItem(null)}
      open={selectedMasterItem}
      width={width < 1024 ? width : 800}
    >
      {itemData && (
        <>
          <div className="float-right space-x-2 mb-10">
            {config?.contents?.data?.hasPriceIntegration && (
              <Button
                type="primary"
                loading={loading}
                onClick={() => { forcePriceUpdate(); }}
              >
                {t('force_price_update')}
              </Button>
            )}
            <Popconfirm
              title={t('archive_item_prompt')}
              okText={t('yes')}
              cancelText={t('no')}
              onConfirm={() => {
                archiveItemClick();
              }}
            >
              <Button
                type="primary"
                className="mt-2"
                danger
                loading={loading}
              >
                {t('archive')}
              </Button>
            </Popconfirm>
            <Button
              loading={loading}
              className="mt-2"
              type="primary"
              onClick={() => { updateItemClick(); }}
            >
              {t('update')}
            </Button>
            <Button
              loading={loading}
              type="primary"
              hidden={!envConfig.REACT_APP_IMAGES_URL}
              className="mt-2"
              onClick={() => {
                setSearchMetadata(selectedMasterItem);
              }}
            >
              {t('fetch_metadata')}
            </Button>
            {isSuperAdmin && (
              <Button
                loading={loading}
                type="primary"
                className="mt-2"
                onClick={() => {
                  setSelectedItemForLogs({
                    id: itemData?.id,
                    page: 0,
                    size: 20
                  });
                }}
              >
                {t('logs')}
              </Button>
            )}
            <Button
              loading={loading}
              className="mt-2"
              type="primary"
              onClick={() => { setReload(!reload); }}
            >
              {t('reload')}
            </Button>
          </div>
          <br />
          <br />
          <div
            style={{ marginBottom: '10px', marginTop: 10 }}
          >
            <p>
              <strong>{t('created_on')}: </strong>
              <span>{renderDate(itemData.createdAt, timezone)}</span>
            </p>
          </div>
          <div
            style={{ marginBottom: '10px' }}
          >
            <strong>{t('internal_id')}</strong>
            <Input
              disabled={loading}
              onChange={(obj) => onChangeItemInternalId(obj)}
              value={itemData.internalId}
            />
          </div>
          <div
            className="space-y-2"
            style={{ marginBottom: '10px' }}
          >
            <div className="mt-1">
              <strong>{t('primary_gtin')}:</strong> {itemData.gtin}
            </div>
            <Button
              loading={loading}
              type="primary"
              onClick={() => {
                setOtherBusinessItems(businessItems);
                setShowItemGtins(true);
              }}
            >
              {t('manage_gtins')} ({thousand(itemData.gtins.length)})
            </Button>
            {/* <Input
              placeholder="GTIN"
              disabled={loading}
              onChange={(obj) => onChangeItemGtin(obj)}
              value={itemData.gtin}
            /> */}
          </div>
          <div
            style={{ marginBottom: '10px' }}
          >
            <strong>{t('brand')}</strong>
            <Input
              disabled={loading}
              placeholder={t('brand')}
              onChange={(obj) => onChangeItemBrand(obj)}
              value={itemData.brand}
            />
          </div>
          <div
            className="flex space-x-2"
            style={{ marginBottom: '10px' }}
          >
            <div className="w-full">
              <strong>{t('name')}</strong>
              <Input
                disabled={loading}
                placeholder={t('name')}
                onChange={(obj) => onChangeItemName(obj)}
                value={itemData.name}
              />
            </div>
            <Popover content={t('copy_over')}>
              <Button
                className="mt-5"
                type="primary"
                onClick={() => setItemData({
                  ...itemData,
                  nameSpa: itemData.name
                })}
              >
                <FontAwesomeIcon icon={faArrowRight} />
              </Button>
            </Popover>
            <div className="w-full">
              <strong>{t('name_spa')}</strong>
              <Input
                disabled={loading}
                placeholder={t('name_spa')}
                onChange={(obj) => {
                  const temp = JSON.parse(JSON.stringify(itemData));
                  temp.nameSpa = obj.target.value;
                  setItemData(temp);
                }}
                value={itemData.nameSpa}
              />
            </div>
          </div>
          <div
            className="flex space-x-2"
            style={{ marginBottom: '10px' }}
          >
            <div className="w-full">
              <strong>{t('description')}</strong>
              <Input
                disabled={loading}
                placeholder={t('description')}
                onChange={(obj) => onChangeItemDescription(obj)}
                value={itemData.description}
              />
            </div>
            <Popover content={t('copy_over')}>
              <Button
                className="mt-5"
                type="primary"
                onClick={() => setItemData({
                  ...itemData,
                  descriptionSpa: itemData.description
                })}
              >
                <FontAwesomeIcon icon={faArrowRight} />
              </Button>
            </Popover>
            <div className="w-full">
              <strong>{t('description_spa')}</strong>
              <Input
                disabled={loading}
                placeholder={t('description_spa')}
                onChange={(obj) => {
                  const temp = JSON.parse(JSON.stringify(itemData));
                  temp.descriptionSpa = obj.target.value;
                  setItemData(temp);
                }}
                value={itemData.descriptionSpa}
              />
            </div>
          </div>
          <div style={{ marginBottom: '10px' }}>
            <strong>{t('division')}</strong>
            <Input
              disabled={loading}
              value={itemData?.suggestedDepartment}
              onChange={(obj) => {
                setItemData({
                  ...itemData,
                  suggestedDepartment: obj.target.value
                });
              }}
            />
          </div>
          <div style={{ marginBottom: '10px' }}>
            <strong className="mr-2">{t('rank')}</strong>
            <InputNumber
              disabled={loading}
              min={0}
              onChange={(obj) => {
                setItemData({
                  ...itemData,
                  internalRank: obj
                });
              }}
              value={itemData?.internalRank ? itemData.internalRank : 0}
            />
          </div>
          <div
            style={{ marginBottom: '10px' }}
          >
            <strong>{t('search_tags')}</strong> <small>{t('custom_search_tag_note')}</small>
            <Input
              disabled={loading}
              placeholder={t('search_tags')}
              onChange={(obj) => onChangeSearchTags(obj)}
              value={itemData.searchTags}
            />
          </div>
          <Row>
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={12}
            >
              <div
                style={{ margin: '5px' }}
              >
                <small>{t('package_sensitive')} </small>
                <Switch
                  disabled={loading}
                  style={{ float: 'right' }}
                  checked={itemData.isPackagingSensitive}
                  onChange={(checked) => {
                    const temp = JSON.parse(JSON.stringify(itemData));
                    temp.isPackagingSensitive = checked;
                    setItemData(temp);
                  }}
                />
              </div>
              <div
                style={{ margin: '5px' }}
              >
                <small>{t('is_plu_code')} </small>
                <Switch
                  disabled={loading}
                  style={{ float: 'right' }}
                  checked={itemData.hasPluCode}
                  onChange={(checked) => {
                    const temp = JSON.parse(JSON.stringify(itemData));
                    temp.hasPluCode = checked;
                    setItemData(temp);
                  }}
                />
              </div>
              <div
                style={{ margin: '5px' }}
              >
                <small>EBT </small>
                <Switch
                  disabled={loading}
                  style={{ float: 'right' }}
                  checked={itemData.isEbt}
                  onChange={(checked) => {
                    const temp = JSON.parse(JSON.stringify(itemData));
                    temp.isEbt = checked;
                    setItemData(temp);
                  }}
                />
              </div>
              <div
                style={{ margin: '5px' }}
              >
                <small>{t('id_required')} </small>
                <Switch
                  disabled={loading}
                  style={{ float: 'right' }}
                  checked={itemData.idRequired}
                  onChange={(checked) => {
                    const temp = JSON.parse(JSON.stringify(itemData));
                    temp.idRequired = checked;
                    setItemData(temp);
                  }}
                />
              </div>
            </Col>
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={12}
            >
              <div
                style={{ margin: '5px' }}
              >
                <small>{t('temp_sensitive')} </small>
                <Switch
                  disabled={loading}
                  style={{ float: 'right' }}
                  checked={itemData.isTemperatureSensitive}
                  onChange={(checked) => {
                    const temp = JSON.parse(JSON.stringify(itemData));
                    temp.isTemperatureSensitive = checked;
                    setItemData(temp);
                  }}
                />
              </div>
              <div
                style={{ margin: '5px' }}
              >
                <small>{t('alcoholic_beverage')} </small>
                <Switch
                  disabled={loading}
                  style={{ float: 'right' }}
                  checked={itemData.isAlcoholicBeverage}
                  onChange={(checked) => {
                    const temp = JSON.parse(JSON.stringify(itemData));
                    temp.isAlcoholicBeverage = checked;
                    setItemData(temp);
                  }}
                />
              </div>
              <div
                style={{ margin: '5px' }}
              >
                <small>{t('perishable')}</small>
                <Switch
                  disabled={loading}
                  style={{ float: 'right' }}
                  checked={itemData.isPerishable}
                  onChange={(checked) => {
                    const temp = JSON.parse(JSON.stringify(itemData));
                    temp.isPerishable = checked;
                    setItemData(temp);
                  }}
                />
              </div>
            </Col>
          </Row>
          <Row style={{ margin: '5px 0px' }}>
            <Col style={{ margin: '5px' }}>
              <Button
                loading={loading}
                onClick={() => { setShowItemMedia(true); }}
              >
                {t('manage_media')}
              </Button>
            </Col>
            <Col style={{ margin: '5px' }}>
              <Button
                loading={loading}
                onClick={() => { setShowItemFilters(true); }}
              >
                {t('manage_filters')}
              </Button>
            </Col>
            <Col style={{ margin: '5px' }}>
              <Button
                loading={loading}
                onClick={() => { setShowItemRecommendations(true); }}
              >
                {t('recommendations')}
              </Button>
            </Col>
            <Col style={{ margin: '5px' }}>
              <Button
                loading={loading}
                onClick={() => { setShowItemSubstitutes(true); }}
              >
                {t('substitutes')}
              </Button>
            </Col>
            {currentState && currentState.globalReqSideAddons
              && currentState.managesReqSideAddons && (
              <>
                <Col style={{ margin: '2px 5px' }}>
                  <Button
                    loading={loading}
                    onClick={() => { setManageRequirements(true); }}
                  >
                    {t('requirements')}
                  </Button>
                </Col>
                <Col style={{ margin: '2px 5px' }}>
                  <Button
                    loading={loading}
                    onClick={() => { setManageSides(true); }}
                  >
                    {t('sides')}
                  </Button>
                </Col>
                <Col style={{ margin: '2px 5px' }}>
                  <Button
                    loading={loading}
                    onClick={() => { setManageAddons(true); }}
                  >
                    {t('add-ons')}
                  </Button>
                </Col>
              </>
            )}
          </Row>
          {missingBusiness.length > 0 && (
            <div style={{
              textAlign: 'center',
              marginBottom: 10
            }}
            >
              <Button
                loading={loading || loadingBusinessItems}
                onClick={() => { setShowMissingStores(!showMissingStores); }}
              >
                {t('assign_to_store')}
                {!showMissingStores && (
                  <DownOutlined
                    style={{ marginLeft: 10 }}
                  />
                )}
                {showMissingStores && (
                  <UpOutlined
                    style={{ marginLeft: 10 }}
                  />
                )}
              </Button>
              {showMissingStores && (
                <Table
                  size="small"
                  bordered
                  loading={loadingBusinessItems}
                  pagination={false}
                  dataSource={missingBusiness}
                  columns={[
                    {
                      title: t('store'),
                      dataIndex: 'name',
                      align: 'center',
                      className: 'text-xs',
                      render: (text) => (
                        <span>{text}</span>
                      ),
                    },
                    {
                      title: '',
                      align: 'center',
                      className: 'text-xs',
                      render: (row) => (
                        <Button
                          size="small"
                          onClick={() => {
                            assignStoreClick(row);
                          }}
                        >
                          {t('assign')}
                        </Button>
                      ),
                    }
                  ]}
                />
              )}
            </div>
          )}
          <Table
            size="small"
            bordered
            loading={loadingBusinessItems}
            pagination={false}
            dataSource={businessItems}
            columns={[
              {
                title: t('store'),
                dataIndex: 'businessName',
                align: 'center',
                className: 'text-xs',
                render: (text) => (
                  <span>{text}</span>
                ),
              },
              {
                title: 'UPC',
                align: 'center',
                className: 'text-xs',
                render: (row) => (<span>{row.upc}</span>),
              },
              {
                title: t('visible'),
                align: 'center',
                className: 'text-xs',
                render: (row) => (
                  <Checkbox
                    checked={row.isVisible}
                    onChange={() => onChangeIsVisible(row)}
                  />
                ),
              },
              {
                title: t('available'),
                align: 'center',
                className: 'text-xs',
                render: (row) => (
                  <Checkbox
                    checked={row.isAvailable}
                    onChange={() => onChangeIsAvailable(row)}
                  />
                ),
              },
              {
                title: t('price'),
                align: 'center',
                className: 'text-xs',
                render: (row) => (
                  <span>
                    ${row.prices && row.prices.length > 0 ?
                    thousand(row.prices[0].activePrice.toFixed(2)) : ''}
                  </span>
                ),
              },
              {
                title: t('state_tax'),
                align: 'center',
                className: 'text-xs',
                render: (row) => (<span>{percent(row.stateTaxPct)}%</span>),
              },
              {
                title: t('municipal_tax'),
                align: 'center',
                className: 'text-xs',
                render: (row) => (<span>{percent(row.munTaxPct)}%</span>),
              },
              {
                title: t('tax_rule'),
                align: 'center',
                className: 'text-xs',
                render: (row) => (
                  <span>
                    {row.prices && row.prices.length > 0 ?
                      t(convertTaxRule(row.prices[0].taxRule)) : ''}
                  </span>
                ),
              },
              {
                title: t('category_department'),
                align: 'center',
                className: 'text-xs',
                render: (row) => (
                  <span>{i18n.language === 'en' ? row.sectionNameEn : row.sectionName}/{i18n.language === 'en' ? row.superSectionNameEn : row.superSectionName}</span>
                ),
              },
              {
                title: '',
                align: 'center',
                className: 'text-xs',
                render: (row) => (
                  <Button
                    size="small"
                    type="primary"
                    onClick={() => {
                      const temp = _.filter(businessItems, (b) => b.id !== row.id);
                      setOtherBusinessItems(temp);
                      setSelectedBusinessItem(row);
                    }}
                  >
                    {t('edit')}
                  </Button>
                ),
              }
            ]}
          />
        </>
      )}
    </Drawer>
  );
}

export default EditItemDrawer;
