import React from 'react';
import { useTranslation } from 'react-i18next';
import { Drawer, Image, List, Typography } from 'antd';
import { useRecoilState } from 'recoil';
import { openHelpDrawerAtom } from '../../../../atoms/Atoms';
import Add from '../../../../assets/images/add_drawer.png';
import Bing from '../../../../assets/images/bing.png';
import Upc from '../../../../assets/images/upc_page.png';

function HelpDrawer() {
  const [open, setOpen] = useRecoilState(openHelpDrawerAtom);
  const { t } = useTranslation();

  const data = [
    t('step01'),
    t('step02'),
    t('step03'),
    t('step04'),
    t('step05'),
    t('step06'),
    t('step07'),
    t('step08'),
    t('step09'),
    t('step10'),
  ];

  return (
    <div>
      <Drawer
        title={t('help')}
        placement="right"
        closable
        onClose={() => setOpen(false)}
        open={open}
        width={500}
      >
        <div>
          <Typography.Text className="font-medium text-blue-800">Steps to ensure proper metadata creation</Typography.Text>
        </div>
        <div>
          <List
            size="large"
            dataSource={data}
            renderItem={(item) => (
              <List.Item>
                {item}
              </List.Item>
            )}
          />
        </div>
        <br />
        <Image
          width={300}
          src={Add}
        />
        <br />
        <br />
        <hr />
        <div>
          <List
            size="large"
          >
            {t('step6')}
          </List>
        </div>
        <br />
        <Image
          src={Bing}
        />
        <br />
        <hr />
        <br />
        <div>
          <List
            size="large"
          >
            {t('step7')}
          </List>
        </div>
        <br />
        <Image
          src={Upc}
        />
        <br />
      </Drawer>
    </div>
  );
}

export default HelpDrawer;
