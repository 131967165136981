import React from 'react';
import { Layout, Button } from 'antd';
import _ from 'lodash';
import { MenuOutlined } from '@ant-design/icons';
import { useRecoilState, useRecoilValue, useRecoilValueLoadable } from 'recoil';
import { collapseAtom, profileAtom } from '../../../atoms/Atoms';
import { configSelector } from '../../../atoms/Selectors';
import UserDropdown from './UserDropdown';
import LanguageSwitcher from '../LanguageSwitcher';

function Header() {
  const [collapsed, setCollapsed] = useRecoilState(collapseAtom);
  const profile = useRecoilValue(profileAtom);
  const config = useRecoilValueLoadable(configSelector);

  return (
    <Layout.Header theme="dark" className="flex items-center py-0 px-4 h-16">
      <Button
        type="link"
        icon={<MenuOutlined />}
        onClick={() => setCollapsed(!collapsed)}
      />
      <div className="flex items-center flex-grow">
        <img
          alt="Logo"
          className="md:ml-3 h-6"
          src={
            config?.state === 'hasValue' &&
            config?.contents?.data?.adminHeaderLogoCombo
          }
        />
      </div>
      <LanguageSwitcher className="mr-2" small />
      {profile && <UserDropdown style={{ float: 'right' }} />}
    </Layout.Header>
  );
}

export default Header;
