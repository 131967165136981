import React from 'react';
import { useTranslation } from 'react-i18next';
import { Tag } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import { CheckCircleOutlined } from '@ant-design/icons';
import { useRecoilValue } from 'recoil';
import { businessAtom } from '../../../../atoms/Atoms';

function OosHeader() {
  const { t } = useTranslation();
  const business = useRecoilValue(businessAtom);
  return (
    <PageHeader
      className="mb-4 px-0"
      title={t('daily_loss')}
      subTitle={t('lost_sales')}
      tags={
        <Tag
          color="#2db7f5"
          icon={<CheckCircleOutlined />}
        >
          {business ? business.name : t('global')}
        </Tag>
      }
    />
  );
}

export default OosHeader;
