import React, { useEffect, useState } from 'react';
import moment from 'moment';
import dayjs from 'dayjs';
import {
  Tooltip,
  Avatar,
  notification,
  Table,
  Button,
  Drawer,
  Input,
  DatePicker
} from 'antd';
import { Comment } from '@ant-design/compatible';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { businessAtom } from '../../../../atoms/Atoms';
import api from '../../../../api/api';
import Grocefy from '../../../../assets/images/grocefyLogoAlone.png';

function Holidays() {
  const { t } = useTranslation();
  const business = useRecoilValue(businessAtom);
  const [holidays, setHolidays] = useState([]);
  const [reload, setReload] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showAddHoliday, setShowAddHoliday] = useState(false);
  const [loadingCreate, setLoadingCreate] = useState(false);
  const [holidayName, setHolidayName] = useState('');
  const [holidayDate, setHolidayDate] = useState(moment());

  function showMessage(message) {
    notification.open({
      message: '',
      description: (
        <Comment
          author={<span>Grocefy</span>}
          avatar={<Avatar src={Grocefy} alt="grocefy" />}
          content={
            <p className="text-sm">
              {message}
            </p>
          }
          datetime={
            <Tooltip title={moment().format('YYYY-MM-DD HH:mm:ss')}>
              <span>{moment().fromNow()}</span>
            </Tooltip>
          }
        />
      ),
    });
  }

  function deleteHoliday(holiday) {
    setLoading(true);
    api.delete(`businesses/holiday/${holiday.id}`)
      .then((response) => {
        setLoading(false);
        if (response.data.error) {
          showMessage(response.data.error);
        } else {
          setReload(true);
        }
      })
      .catch((error) => {
        setLoading(false);
        showMessage(error.message);
      });
  }

  function createHoliday() {
    setLoadingCreate(true);
    api.post(`businesses/holiday/${business.locations[0].id}`, {
      BusinessId: business.id,
      Name: holidayName,
      Date: holidayDate
    })
      .then((response) => {
        setLoadingCreate(false);
        if (response.data.error) {
          showMessage(response.data.error);
        } else {
          setShowAddHoliday(false);
          setReload(true);
        }
      })
      .catch((error) => {
        setLoadingCreate(false);
        showMessage(error.message);
      });
  }

  function reloadData() {
    if (business) {
      setLoading(true);
      api.get(`businesses/holidays/${business.locations[0].id}`)
        .then((response) => {
          setLoading(false);
          if (response.data.error) {
            showMessage(response.data.error);
          } else {
            setHolidays(response.data.data.results);
          }
        })
        .catch((error) => {
          setLoading(false);
          showMessage(error.message);
        });
    }
  }

  useEffect(() => {
    reloadData();
  }, []);

  useEffect(() => {
    reloadData();
  }, [business]);

  useEffect(() => {
    if (reload) {
      setReload(false);
      reloadData();
    }
  }, [reload]);

  return (
    <>
      <div className="my-2 float-right">
        <Button
          type="primary"
          className="mx-2"
          loading={loading}
          onClick={() => setShowAddHoliday(true)}
        >
          {t('add_holiday')}
        </Button>
        <Button
          type="primary"
          loading={loading}
          onClick={() => setReload(true)}
        >
          {t('reload')}
        </Button>
      </div>
      <Table
        dataSource={holidays}
        bordered
        loading={loading}
        rowKey="id"
        columns={[
          {
            title: t('name'),
            dataIndex: 'name',
            key: 'name',
            align: 'left',
            className: 'text-xs',
            render: (text) => <span>{text}</span>,
          },
          {
            title: t('date'),
            dataIndex: 'dateString',
            key: 'date',
            align: 'left',
            className: 'text-xs',
            render: (text) => <span>{text}</span>,
          },
          //   {
          //     title: t('date'),
          //     key: 'date',
          //     align: 'left',
          //     className: 'text-xs',
          //     render: (row) => <span>{row.date.split('T')[0]}</span>,
          //   },
          {
            title: t('actions'),
            key: 'actions',
            align: 'left',
            className: 'text-xs',
            render: (row) =>
              <Button
                size="small"
                type="primary"
                danger
                onClick={() => {
                  deleteHoliday(row);
                }}
              >
                {t('remove')}
              </Button>,
          },
        ]}
      />
      <Drawer
        title={t('add_holiday')}
        placement="right"
        closable={!loadingCreate}
        onClose={() => setShowAddHoliday(false)}
        open={showAddHoliday}
        width={300}
      >
        <div
          className="mb-4"
        >
          <strong>{t('name')}</strong>
          <Input
            disabled={loading}
            className="w-full"
            value={holidayName}
            onChange={(obj) => {
              setHolidayName(obj.target.value);
            }}
          />
        </div>
        <div style={{ marginBottom: 10 }}>
          <strong>{t('birth_date')}: </strong>
          <DatePicker
            onChange={(date, dateString) => {
              setHolidayDate(date);
            }}
            allowClear={false}
            value={dayjs(holidayDate)}
          />
        </div>
        <div
          className="float-right mt-4"
        >
          <Button
            type="primary"
            loading={loadingCreate}
            disabled={holidayName.length <= 0}
            onClick={() => { createHoliday(); }}
          >
            {t('create')}
          </Button>
        </div>
      </Drawer>
    </>
  );
}

export default Holidays;
