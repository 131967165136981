import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { Modal, Switch, Input, Button, Typography, Table } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import { addItemToCustomDeptAtom, openAddItemToCustomDeptAtom, businessAtom, businessProfileAtom, productToAddAtom } from '../../../atoms/Atoms';
import AddToCustomDept from '../drawers/AddToCustomDept';
import api from '../../../api/api';

function AddItemsToCustomDept() {
  const [openAddItem, setOpenAddItem] = useRecoilState(openAddItemToCustomDeptAtom);
  const [addItem, setAddItem] = useRecoilState(addItemToCustomDeptAtom);
  const store = useRecoilValue(businessAtom);
  const searchPageSize = 100;
  const searchPage = 0;
  const [current, setCurrent] = useState(1);
  const businessProfile = useRecoilValue(businessProfileAtom);
  const [rows, setRows] = useState(10);
  const [term, setTerm] = useState(null);
  const [item, setItem] = useState(null);
  const [loading, setLoading] = useState(false);
  const setProduct = useSetRecoilState(productToAddAtom);
  const [showAssigned, setShowAssigned] = useState(true);
  const { t } = useTranslation();

  function mapData(data) {
    return _.map(data, (p) => ({
      key: p.id,
      id: p.id,
      upc: p.upc,
      rank: p.ordering,
      completeName: `${p.brand} ${p.name} ${p.description}`,
      brand: p.brand,
      name: p.name,
      description: p.description,
      isAvailable: p.isAvailable,
      isVisible: p.isVisible,
      sectionId: p.sectionId,
      sectionName: p.sectionName,
      department: p.superSectionName,
      internalDepartment: p.department,
      aisle: p.aisle,
      cost: p.cost,
      ebt: p.isEBT,
      imageUrl: p.imageUrl,
      promoted: p.isPromoted,
      customDepartment01: p.customBooleanProperty01,
      customDepartment02: p.customBooleanProperty02,
      customDepartment03: p.customBooleanProperty03,
      customDepartment04: p.customBooleanProperty04,
      customDepartment05: p.customBooleanProperty05,
      customDepartment06: p.customBooleanProperty06,
      activePrice: p.activePrice
    }));
  }

  const onSearch = async (value) => {
    if (value === '') {
      setTerm(null);
      return;
    }
    setLoading(true);
    setTerm(value);
    const data = {
      Page: searchPage,
      Size: searchPageSize,
      IsAssigned: showAssigned,
      DepartmentId: '',
      SectionId: '',
      InternalDepartment: '',
      Query: `${value}`,
    };
    api
      .post(
        `businesses/catalog/${store?.id}/search`,
        data
      )
    // api
    //   .post(
    //     `businesses/catalog/search/${store?.id}/0/20`,
    //     {
    //       Page: searchPage,
    //       Size: searchPageSize,
    //       IsAssigned: showAssigned,
    //       DepartmentId: '',
    //       SectionId: '',
    //       Query: `${value}`,
    //     }
    //   )
      .then((response) => {
        const mapped = mapData(response.data.data.results);
        setItem(mapped);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  return (
    <>
      {openAddItem && (
        <Modal
          closable="true"
          centered="true"
          open={openAddItem}
          width={650}
          onCancel={() => { setOpenAddItem(false); }}
          footer={[
            <Button
              key="close"
              type="primary"
              danger
              disabled={loading}
              onClick={() => setOpenAddItem(false)}
            >
              {t('close')}
            </Button>
          ]}
        >
          <PageHeader title={t('assign_to_custom_dept')} />
          <Typography.Text>
            {t('assign_item')}
          </Typography.Text>
          <br />
          <br />
          <div>
            <Input.Search
              placeholder={t('custom_dept_search_placeholder')}
              allowClear
              enterButton={t('search')}
              size="large"
              onSearch={onSearch}
            />
          </div>
          <div className="mt-8">
            <Table
              size="small"
              loading={loading}
              bordered
              pagination={{
                current,
                hideOnSinglePage: true,
                showSizeChanger: true,
                size: 'small',
                pageSize: rows,
                total: item && item.length,
                pageSizeOptions: [10, 20, 30, 40, 50],
                showTotal: (total, range) =>
                  `${range[0]}-${range[1]} ${t('out_of')} ${total} ${t('items')}`,
                onShowSizeChange: (page, size) => {
                  setRows(size);
                },
                onChange: (page) => {
                  setCurrent(page);
                },
              }}
              columns={[
                {
                  title: 'Upc',
                  dataIndex: 'upc',
                  key: 'upc',
                  align: 'center',
                  className: 'text-xs',
                  render: (text) => (
                    <Typography.Text copyable>{text}</Typography.Text>
                  ),
                },
                {
                  title: t('name'),
                  dataIndex: 'completeName',
                  key: 'completeName',
                  align: 'left',
                  className: 'text-xs',
                  render: (text) => <span>{text}</span>,
                },
                {
                  title: t('category'),
                  dataIndex: 'sectionName',
                  key: 'sectionName',
                  align: 'left',
                  className: 'text-xs',
                  render: (text) => <span>{text}</span>,
                },
                {
                  title: t('price'),
                  dataIndex: 'activePrice',
                  key: 'activePrice',
                  align: 'left',
                  className: 'text-xs',
                  render: (text) => <span>${text ? text.toFixed(2) : '0.00'}</span>,
                },
                {
                  title: t('available'),
                  dataIndex: 'isAvailable',
                  key: 'isAvailable',
                  align: 'center',
                  className: 'text-xs',
                  render: (text) => (
                    <span>
                      <Switch size="small" disabled checked={text} />
                    </span>
                  ),
                },
                {
                  title: t('add'),
                  key: 'edit',
                  align: 'center',
                  className: 'text-xs',
                  render: (text, record) => (
                    <Button
                      size="small"
                      disabled={!businessProfile?.canEditMenuItems || record.sectionName === 'Unassigned'}
                      onClick={() => { setProduct(record); setAddItem(true); }}
                    >
                      {t('add')}
                    </Button>
                  ),
                },
              ]}
              dataSource={item && item.length > 0 ? item : null}
            />
          </div>
        </Modal>
      )}
      {addItem ? <AddToCustomDept /> : null}
    </>
  );
}

export default AddItemsToCustomDept;
