import React, { useState, useEffect } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
  Modal,
  Upload,
  notification,
  Row,
  Col,
  Avatar,
  Input,
  Image,
  Tooltip,
  Button
} from 'antd';
import { Comment } from '@ant-design/compatible';
import { PageHeader } from '@ant-design/pro-layout';
import { useTranslation } from 'react-i18next';
import ReactPlayer from 'react-player';
import {
  InboxOutlined
} from '@ant-design/icons';
import moment from 'moment';
import Grocefy from '../../../../assets/images/grocefyLogoAlone.png';
import {
  setSelectedMasterItemAtom,
  showAddItemMediaAtom,
  refreshItemMediaAtom
} from '../../../../atoms/Atoms';
import api from '../../../../api/api';

function AddItemMediaModal() {
  const { Dragger } = Upload;
  const selectedMasterItem = useRecoilValue(setSelectedMasterItemAtom);
  const setRefreshItemMedia = useSetRecoilState(refreshItemMediaAtom);
  const [showAddItemMedia, setShowAddItemMedia] = useRecoilState(showAddItemMediaAtom);
  const [loading, setLoading] = useState(false);
  const [imageData, setImageData] = useState(null);
  const [thumbnailData, setThumbnailData] = useState(null);
  const [media, setMedia] = useState(null);
  const { t } = useTranslation();

  const draggerProps = {
    name: 'file',
    accept: media && media.type === 1 ? 'video/*' : 'image/*',
    multiple: false,
    showUploadList: false,
    beforeUpload: (file) => {
      setImageData(file);
      return false;
    }
  };
  const thumbnailDraggerProps = {
    name: 'file',
    accept: 'image/*',
    multiple: false,
    showUploadList: false,
    beforeUpload: (file) => {
      setThumbnailData(file);
      return false;
    }
  };

  const showMessage = function (message) {
    notification.open({
      message: '',
      description: (
        <Comment
          author={<span>Grocefy</span>}
          avatar={<Avatar src={Grocefy} alt="grocefy" />}
          content={
            <p className="text-sm">
              {message}
            </p>
          }
          datetime={
            <Tooltip title={moment().format('YYYY-MM-DD HH:mm:ss')}>
              <span>{moment().fromNow()}</span>
            </Tooltip>
          }
        />
      ),
    });
  };

  const createClick = function () {
    if (media.url.length === 0) {
      showMessage(t('must_upload_image_content'));
    } else if (media.type === 1 && media.thumbnailUrl.length === 0) {
      showMessage(t('must_upload_image_content'));
    } else {
      setLoading(true);
      api
        .post('businesses/catalog/media',
          {
            Url: media.url,
            ThumbnailUrl: media.thumbnailUrl,
            Type: media.type,
            ItemId: selectedMasterItem.id,
            Ordering: 99
          })
        .then((response) => {
          setLoading(false);
          if (response.data.success) {
            setShowAddItemMedia(null);
            setRefreshItemMedia(true);
          } else {
            showMessage(response.data.error);
          }
        })
        .catch((error) => {
          setLoading(false);
          console.error(error);
        });
    }
  };

  const removeImage = function () {
    const temp = JSON.parse(JSON.stringify(media));
    temp.url = '';
    setMedia(temp);
  };

  const removeThumbnailImage = function () {
    const temp = JSON.parse(JSON.stringify(media));
    temp.thumbnailUrl = '';
    setMedia(temp);
  };

  useEffect(() => {
    if (showAddItemMedia) {
      setImageData(null);
      setThumbnailData(null);
      setMedia({
        type: showAddItemMedia.type
      });
    }
  }, [showAddItemMedia]);

  useEffect(() => {
    if (imageData) {
      const file = imageData;
      const boundary = Date.now();
      const data = new FormData();
      data.append('file', file);
      setLoading(true);
      api
        .post('businesses/media/upload/file', data)
        .then((response) => {
          setLoading(false);
          setImageData(null);
          if (response.data.success) {
            const temp = JSON.parse(JSON.stringify(media));
            temp.url = response.data.data;
            setMedia(temp);
          } else {
            showMessage(response.data.error);
          }
        })
        .catch((error) => {
          setLoading(false);
          setImageData(null);
          console.error(error);
        });
    }
  }, [imageData]);

  useEffect(() => {
    if (thumbnailData) {
      const file = thumbnailData;
      const boundary = Date.now();
      const data = new FormData();
      data.append('file', file);
      setLoading(true);
      api
        .post('businesses/media/upload/file', data)
        .then((response) => {
          setLoading(false);
          setThumbnailData(null);
          if (response.data.success) {
            const temp = JSON.parse(JSON.stringify(media));
            temp.thumbnailUrl = response.data.data;
            setMedia(temp);
          } else {
            showMessage(response.data.error);
          }
        })
        .catch((error) => {
          setLoading(false);
          setThumbnailData(null);
          console.error(error);
        });
    }
  }, [thumbnailData]);

  return (
    <Modal
      closable="true"
      centered="true"
      onCancel={() => setShowAddItemMedia(null)}
      open={showAddItemMedia}
      footer={[
        <Button
          key="createButton"
          disabled={loading}
          onClick={() => createClick()}
        >
          {t('create')}
        </Button>,
        <Button
          key="back"
          disabled={loading}
          onClick={() => setShowAddItemMedia(null)}
        >
          {t('close')}
        </Button>
      ]}
    >
      {showAddItemMedia && (
        <>
          {showAddItemMedia.type === 0 && (
            <PageHeader>Add Picture</PageHeader>
          )}
          {showAddItemMedia.type === 1 && (
            <PageHeader>Add Video</PageHeader>
          )}
          <Row>
            <Col
              style={{ margin: 'auto' }}
              xs={12}
              sm={12}
              md={showAddItemMedia.type === 0 ? 12 : 6}
              lg={showAddItemMedia.type === 0 ? 12 : 6}
              xl={showAddItemMedia.type === 0 ? 12 : 6}
              xxl={showAddItemMedia.type === 0 ? 12 : 6}
            >
              <strong>{t('content')}</strong>
              <Input
                className="w-full"
                disabled={loading}
                loading={loading}
                value={media?.url}
                onChange={(obj) => {
                  const temp = JSON.parse(JSON.stringify(media));
                  temp.url = obj.target.value;
                  setMedia(temp);
                }}
              />
              <Dragger {...draggerProps}>
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">
                  {t('click_or_drag_content')}
                </p>
              </Dragger>
            </Col>
            {showAddItemMedia.type === 1 && (
              <Col
                style={{ margin: 'auto' }}
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={6}
                xxl={6}
              >
                <strong>{t('thumbnail')}</strong>
                <Input
                  className="w-full"
                  disabled={loading}
                  loading={loading}
                  value={media?.thumbnailUrl}
                  onChange={(obj) => {
                    const temp = JSON.parse(JSON.stringify(media));
                    temp.thumbnailUrl = obj.target.value;
                    setMedia(temp);
                  }}
                />
                <Dragger {...thumbnailDraggerProps}>
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p className="ant-upload-text">
                    {t('click_or_drag_content')}
                  </p>
                </Dragger>
              </Col>
            )}
          </Row>
          {media && media.url && (
            <>
              <strong>
                {showAddItemMedia.type === 0 ? 'Image' : 'Video'}
              </strong>
              {showAddItemMedia.type === 0 && (
                <Image
                  alt={media.url}
                  src={media.url}
                  height={75}
                />
              )}
              {showAddItemMedia.type === 1 && (
                <ReactPlayer
                  controls
                  height={100}
                  width={100}
                  url={media.url}
                />
              )}
              <br />
              <Button onClick={() => removeImage()}>
                {t('delete')}
              </Button>
            </>
          )}
          {media && media.thumbnailUrl && (
            <>
              <strong>Thumbnail</strong>
              <Image
                alt={media.thumbnailUrl}
                src={media.thumbnailUrl}
                height={75}
              />
              <br />
              <Button onClick={() => removeThumbnailImage()}>
                {t('delete')}
              </Button>
            </>
          )}
        </>
      )}
    </Modal>
  );
}

export default AddItemMediaModal;
