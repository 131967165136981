import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import {
  Drawer,
  Tooltip,
  Avatar,
  Spin,
  notification
} from 'antd';
import { Comment } from '@ant-design/compatible';
import MDEditor from '@uiw/react-md-editor';
import { useRecoilState } from 'recoil';
import {
  showHelperInfoAtom
} from '../../../atoms/Atoms';
import Grocefy from '../../../assets/images/grocefyLogoAlone.png';
import api from '../../../api/api';

function HelperInfo() {
  const { i18n, t } = useTranslation();
  const [showHelperInfo, setShowHelperInfo] = useRecoilState(showHelperInfoAtom);
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);

  const showMessage = function (message) {
    notification.open({
      message: '',
      description: (
        <Comment
          author={<span>Grocefy</span>}
          avatar={<Avatar src={Grocefy} alt="grocefy" />}
          content={
            <p className="text-sm">
              {message}
            </p>
          }
          datetime={
            <Tooltip title={moment().format('YYYY-MM-DD HH:mm:ss')}>
              <span>{moment().fromNow()}</span>
            </Tooltip>
          }
        />
      ),
    });
  };

  const readFile = function () {
    setLoading(true);
    api
      .get(
        `utility/file/helpinfo/${showHelperInfo}`
      )
      .then((response) => {
        setLoading(false);
        if (response.data.error) {
          showMessage(response.data.error);
        } else {
          const temp = JSON.parse(response.data.data);
          setFile(temp);
        }
      }).catch((err) => {
        setLoading(false);
        showMessage(err.message);
      });
  };

  useEffect(() => {
    if (showHelperInfo) {
      setFile(null);
      readFile();
    }
  }, [showHelperInfo]);

  return (
    <div>
      <Drawer
        title={t('information')}
        placement="right"
        onClose={() => setShowHelperInfo(null)}
        closable
        open={showHelperInfo && showHelperInfo?.length > 0}
        width={400}
      >
        <Spin spinning={loading}>
          <MDEditor.Markdown source={i18n.language === 'en' ? file?.eng : file?.spa} />
        </Spin>
      </Drawer>
    </div>
  );
}

export default HelperInfo;
