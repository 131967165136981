import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import {
  Table,
  Button,
  Upload,
  Select,
  notification,
  Avatar,
  Modal,
} from 'antd';
import { Comment } from '@ant-design/compatible';
import { PageHeader } from '@ant-design/pro-layout';
import {
  InboxOutlined,
  CopyOutlined
} from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import {
  businessAtom
} from '../../../../atoms/Atoms';
import Grocefy from '../../../../assets/images/grocefyLogoAlone.png';
import api from '../../../../api/api';

function StoreDocuments() {
  const { Dragger } = Upload;
  const { Option } = Select;
  const business = useRecoilValue(businessAtom);
  const [loading, setLoading] = useState(false);
  const [showUpload, setShowUpload] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [filteredDocuments, setFilteredDocuments] = useState([]);
  const [filters, setFilters] = useState([]);
  const { t } = useTranslation();
  const [selectedFilter, setSelectedFilter] = useState('');
  const [fileData, setFileData] = useState(null);
  const history = useHistory();
  const draggerProps = {
    name: 'file',
    multiple: false,
    showUploadList: false,
    beforeUpload: (file) => {
      setFileData(file);
      return false;
    }
  };
  if (business == null || business.id === -1 || business.id === -2) {
    history.push('/');
    return null;
  }
  const getDocuments = function () {
    setLoading(true);
    api
      .get(`utility/documents/${business?.id}`)
      .then((response) => {
        setLoading(false);
        const temp = _.map(response.data.data.results, (u, index) => ({
          url: u.url,
          name: u.name,
          fileType: u.fileType,
          id: index
        }));
        const tempFilters = _.map(response.data.data.results, (u, index) => (u.fileType));
        setFilters(_.uniq(tempFilters));
        setDocuments(temp);
      }).catch((err) => {
        setLoading(false);
        console.error(err);
      });
  };
  const uploadFile = async function (temp) {
    const file = temp;
    const boundary = Date.now();
    const data = new FormData();
    data.append('file', file);
    setLoading(true);
    const response = await api
      .post(`utility/uploadocument/${business?.id}`, data);
    setLoading(false);
    if (response.data.success) {
      return response.data.data;
    }
    return null;
  };
  const filterDocuments = function () {
    if (selectedFilter === '') {
      setFilteredDocuments(documents);
    } else {
      const temp = _.filter(documents, (document) => document.fileType === selectedFilter);
      setFilteredDocuments(temp);
    }
  };
  useEffect(async () => {
    if (fileData) {
      const url = await uploadFile(fileData);
      setShowUpload(false);
      getDocuments();
    }
  }, [fileData]);
  useEffect(() => {
    if (business) {
      getDocuments();
    }
  }, [business]);
  useEffect(() => {
    if (documents) {
      filterDocuments();
    }
  }, [selectedFilter, documents]);
  useEffect(() => {
    if (business) {
      getDocuments();
    }
  }, []);

  return (
    <div>
      <PageHeader
        title={t('store_files')}
        extra={[
          <Select
            style={{ width: 100 }}
            loading={loading}
            key="filter"
            value={selectedFilter}
            onChange={(value) => setSelectedFilter(value)}
            defaultValue=""
          >
            <Option value="">All</Option>
            {_.map(filters, (filter) => (
              <Option value={filter}>&lsquo;.{filter}&rsquo;</Option>
            ))}
          </Select>,
          <Button
            type="primary"
            key="create"
            size="small"
            onClick={() => {
              setShowUpload(true);
            }}
          >
            {t('upload')}
          </Button>
        ]}
      />
      <Table
        bordered
        loading={loading}
        rowKey="id"
        dataSource={filteredDocuments}
        columns={[
          {
            title: t('name'),
            align: 'center',
            dataIndex: 'name',
            className: 'text-xs',
            render: (text) => <span>{text}</span>,
          },
          {
            title: t('file_type'),
            align: 'center',
            dataIndex: 'fileType',
            className: 'text-xs',
            render: (text) => <span>{text}</span>,
          },
          {
            title: t('action'),
            dataIndex: 'url',
            align: 'center',
            className: 'text-xs',
            render: (text) => (
              <>
                <Button
                  type="primary"
                  size="small"
                  style={{ marginRight: 10 }}
                  onClick={() => {
                    navigator.clipboard.writeText(text);
                    notification.open({
                      message: 'Alert!',
                      description: (
                        <Comment
                          author={<span>Grocefy</span>}
                          avatar={<Avatar src={Grocefy} alt="grocefy" />}
                          content={
                            <p className="text-sm">{t('url_copied_content')}</p>
                          }
                        />
                      ),
                    });
                  }}
                >
                  <CopyOutlined /> {t('copy_url')}
                </Button>
                <Button
                  type="primary"
                  size="small"
                  target="_blank"
                  href={text}
                >
                  {t('download')}
                </Button>
              </>
            ),
          }
        ]}
      />
      <Modal
        title={t('upload_file')}
        open={showUpload}
        onCancel={() => setShowUpload(false)}
        footer={[
          <Button
            key="ok"
            type="primary"
            loading={loading}
            onClick={() => setShowUpload(false)}
          >
            {t('close')}
          </Button>
        ]}
      >
        <Dragger {...draggerProps}>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p
            className="ant-upload-text"
            style={{ margin: 10 }}
          >
            {t('click_or_drag_content')}
          </p>
        </Dragger>
      </Modal>
    </div>
  );
}

export default StoreDocuments;
