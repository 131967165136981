import React from 'react';
import { useRecoilValue, useRecoilValueLoadable } from 'recoil';
import { PageHeader } from '@ant-design/pro-layout';
import { useHistory } from 'react-router-dom';
import {
  showEditSearchTermAtom,
  profileAtom,
} from '../../../atoms/Atoms';
import Search from './shared-components/Search';
import CopyToDrawer from './shared-components/CopyToDrawer';
import EditItemDrawer from './shared-components/EditItemDrawer';

function SearchOptimization() {
  const showEditSearchTerm = useRecoilValue(showEditSearchTermAtom);

  const userProfile = useRecoilValueLoadable(profileAtom);
  const isAdmin =
    userProfile.contents && userProfile?.contents?.roles?.includes('SuperAdmin');
  const isCustomerService =
    isAdmin || (userProfile?.contents && (userProfile?.contents?.roles?.includes('CustomerServiceAdmin') || userProfile?.contents?.roles?.includes('CustomerService')));
  const isStoreManager =
    isAdmin || isCustomerService || (userProfile?.contents && (userProfile?.contents?.roles?.includes('StoreManager') || userProfile?.contents?.roles?.includes('StoreAdmin')));
  const history = useHistory();

  if (!isAdmin && !isCustomerService && !isStoreManager) {
    history.push('/');
    return null;
  }
  return (
    <>
      <PageHeader
        className="mb-4 px-0"
        title="Product Search Optimization"
      />
      <Search />
      {showEditSearchTerm ? <EditItemDrawer /> : null}

      <CopyToDrawer />
    </>
  );
}
export default SearchOptimization;
