import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import {
  Table,
  Typography,
  Switch,
  Select,
  Avatar,
  notification,
  Button
} from 'antd';
import { Comment } from '@ant-design/compatible';
import { useRecoilValue } from 'recoil';
import { businessAtom } from '../../../../atoms/Atoms';
import Grocefy from '../../../../assets/images/grocefyLogoAlone.png';
import { weekday } from '../../../utils/functions';
import api from '../../../../api/api';

function Hours() {
  const { Option, OptGroup } = Select;
  const hourArray = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11,
    12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24];
  const store = useRecoilValue(businessAtom);
  const [hours, setHours] = useState(null);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  function tConvert(x) {
    let temp = '';
    if (x < 12) {
      temp = `${x}:00 AM`;
    } else if (x === 12) {
      temp = `${x}:00 PM`;
    } else if (x === 24) {
      temp = '12:00 AM';
    } else {
      temp = `${x - 12}:00 PM`;
    }
    return temp;
  }

  const setHour = function (hour) {
    const tempHours = JSON.parse(JSON.stringify(hours));
    for (let i = 0; i < hours.length; i++) {
      if (tempHours[i].id === hour.id) {
        tempHours[i].loading = hour.loading;
        tempHours[i].isBlocked = hour.isBlocked;
        tempHours[i].openHour = hour.openHour;
        tempHours[i].closeHour = hour.closeHour;
        tempHours[i].cutoffHour = hour.cutoffHour;
      }
    }
    setHours(tempHours);
  };

  const updateHour = function (hour) {
    const tempHour = JSON.parse(JSON.stringify(hour));
    tempHour.loading = true;
    setHour(tempHour);
    const temp = tempHour.cutoffHour;
    if (tempHour.cutoffHour?.hours === -1) {
      tempHour.cutoffHour = null;
    }
    api
      .put(
        `businesses/hours/${store.locations[0].id}/1`,
        tempHour
      )
      .then((response) => {
        setLoading(false);
        if (response.data.success) {
          tempHour.loading = false;
          tempHour.cutoffHour = temp;
          setHour(tempHour);
        } else {
          notification.open({
            message: 'Alert!',
            description: (
              <Comment
                author={<span>Grocefy</span>}
                avatar={<Avatar src={Grocefy} alt="grocefy" />}
                content={
                  <p className="text-sm">{response.data.error}</p>
                }
              />
            ),
          });
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  const getHours = function () {
    setLoading(true);
    api
      .get(`businesses/v2/${store.id}`)
      .then((response) => {
        setLoading(false);
        setHours(response.data.data.locations[0].hours);
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  useEffect(() => {
    if (store) {
      getHours();
    }
  }, [store]);

  useEffect(() => {
    if (store) {
      getHours();
    }
  }, []);

  return (
    <div className="p-4 border bg-gray-100 rounded-lg mb-4">
      <Table
        size="small"
        bordered
        pagination={false}
        loading={loading}
        columns={[
          {
            title: t('weekday'),
            dataIndex: 'weekDay',
            key: 'weekDay',
            align: 'center',
            render: (text) => <Typography.Text>{t(weekday(text))}</Typography.Text>,
          },
          {
            title: t('open_hour'),
            key: 'openHour',
            align: 'center',
            render: (row) => (
              <Select
                className={row.openHour.hours >= row.closeHour.hours ? ' border border-red-600 text-red-600' : ''}
                loading={row.loading}
                disabled={row.loading}
                defaultValue={
                  row.openHour.hours
                }
                onChange={(value) => {
                  const temp = JSON.parse(JSON.stringify(row));
                  temp.openHour.hours = value;
                  temp.openHour.totalHours = value;
                  setHour(temp);
                }}
              >
                {_.map(hourArray, (num) => (
                  <Option
                    key={num}
                    value={num}
                    className="text-xs flex items-center"
                  >
                    {tConvert(num)}
                  </Option>
                ))}
              </Select>
            )
          },
          {
            title: t('close_hour'),
            key: 'closeHour',
            align: 'center',
            render: (row) => (
              <Select
                className={row.closeHour.hours <= row.openHour.hours ? ' border border-red-600 text-red-600' : ''}
                loading={row.loading}
                disabled={row.loading}
                defaultValue={
                  row.closeHour.hours
                }
                onChange={(value) => {
                  const temp = JSON.parse(JSON.stringify(row));
                  temp.closeHour.hours = value;
                  temp.closeHour.totalHours = value;
                  setHour(temp);
                }}
              >
                {_.map(hourArray, (num) => (
                  <Option
                    key={num}
                    value={num}
                    className="text-xs flex items-center"
                  >
                    {tConvert(num)}
                  </Option>
                ))}
              </Select>
            )
          },
          {
            title: t('cutoff_hour'),
            key: 'cutoffHour',
            align: 'center',
            render: (row) => (
              <Select
                loading={row.loading}
                disabled={row.loading}
                defaultValue={-1}
                value={
                  row.cutoffHour?.hours
                }
                onChange={(value) => {
                  const temp = JSON.parse(JSON.stringify(row));
                  if (!temp.cutoffHour) {
                    temp.cutoffHour = { };
                  }
                  temp.cutoffHour.hours = value;
                  temp.cutoffHour.totalHours = value;
                  setHour(temp);
                }}
              >
                <Option
                  key={-1}
                  value={-1}
                  className="text-xs flex items-center"
                >
                  {t('no_cutoff')}
                </Option>
                {_.map(hourArray, (num) => (
                  <Option
                    key={num}
                    value={num}
                    className="text-xs flex items-center"
                  >
                    {tConvert(num)}
                  </Option>
                ))}
              </Select>
            )
          },
          {
            title: t('is_blocked'),
            key: 'isBlocked',
            align: 'center',
            className: 'text-xs',
            render: (row) => (
              <span>
                <Switch
                  size="small"
                  disabled={row.loading}
                  checked={row.isBlocked}
                  onChange={(obj) => {
                    const temp = JSON.parse(JSON.stringify(row));
                    temp.isBlocked = obj;
                    setHour(temp);
                  }}
                />
              </span>
            ),
          },
          {
            title: '',
            key: 'update',
            align: 'center',
            render: (row) => (
              <Button
                disabled={row.closeHour.hours <= row.openHour.hours
                  || row.openHour.hours >= row.closeHour.hours}
                loading={row.loading}
                type="primary"
                size="small"
                onClick={() => updateHour(row)}
              >
                {t('update')}
              </Button>
            )
          }
        ]}
        dataSource={hours && hours.length > 0 ? hours : null}
      />
    </div>
  );
}

export default Hours;
