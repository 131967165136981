import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import {
  useRecoilValue,
  useRecoilValueLoadable
} from 'recoil';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import {
  Button,
  Table,
  Typography,
  Spin,
  DatePicker,
  Select,
  Tabs
} from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import {
  WalletTwoTone,
  FileExcelOutlined,
  BankTwoTone,
  DatabaseTwoTone
} from '@ant-design/icons';
import { useJsonToCsv } from 'react-json-csv';
import moment from 'moment';
import { profileAtom, businessAtom, timezoneAtom } from '../../../atoms/Atoms';
import api from '../../../api/api';
import Panel from '../../shared-components/panel/Panel';
import { renderShortDateHour } from '../../utils/functions';

function DeliveryProviderReports() {
  const { Option } = Select;
  const { saveAsCsv } = useJsonToCsv();
  const timezone = useRecoilValue(timezoneAtom);
  const business = useRecoilValue(businessAtom);
  const profile = useRecoilValueLoadable(profileAtom);
  const [providers, setProviders] = useState([]);
  const [selectedProvider, setSelectedProvider] = useState(null);
  const [loadingProviders, setLoadingProviders] = useState(false);
  const [loadingWeek, setLoadingWeek] = useState(false);
  const [loadingMonthly, setLoadingMonthly] = useState(false);
  const [weeklyData, setWeeklyData] = useState(false);
  const [monthlyData, setMonthlyData] = useState(false);
  const [dateRange, setDateRange] = useState(null);
  const [pickerDate, setPickerDate] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const { t } = useTranslation();
  const fields = {
    businessName: t('store'),
    deliveryZoneName: t('delivery_zone'),
    confirmationCode: t('code'),
    customerName: t('customer'),
    productsTotal: t('products_total'),
    orderTotal: t('order_total'),
    deliveryTotal: t('delivery_total'),
    paymentName: t('payment'),
    desiredDateTime: t('desired_date'),
    dispatchDateTime: t('completed_on'),
    deliveryProviderRateTypeString: t('rate_type'),
    deliveryProviderRate: t('rate'),
    deliveryProviderDeliveryFeeRateTypeString: t('delivery_rate_type'),
    deliveryProviderDeliveryFeeRate: t('delivery_rate'),
    calculatedDeliveryProviderSubtotalRate: t('calculated_subtotal_rate'),
    calculatedDeliveryProviderDeliveryFeeRate: t('calculated_delivery_fee_rate'),
    calculatedDeliveryProviderRate: t('calculated_rate')
  };
  const months = [{
    id: 0,
    name: t('jan')
  }, {
    id: 1,
    name: t('feb')
  }, {
    id: 2,
    name: t('mar')
  }, {
    id: 3,
    name: t('aprl')
  }, {
    id: 4,
    name: t('may')
  }, {
    id: 5,
    name: t('jun')
  }, {
    id: 6,
    name: t('jul')
  }, {
    id: 7,
    name: t('aug')
  }, {
    id: 8,
    name: t('sep')
  }, {
    id: 9,
    name: t('oct')
  }, {
    id: 10,
    name: t('nov')
  }, {
    id: 11,
    name: t('dec')
  }];
  const tableColumns = [
    {
      title: t('store'),
      dataIndex: 'businessName',
      key: 'businessName',
      align: 'left',
      className: 'text-xs',
      render: (text) => (
        <Typography.Text>{text}</Typography.Text>
      ),
    },
    {
      title: t('code'),
      dataIndex: 'confirmationCode',
      key: 'confirmationCode',
      width: 132,
      align: 'left',
      className: 'text-xs',
      render: (text) => (
        <Typography.Text copyable>{text}</Typography.Text>
      ),
    },
    {
      title: t('customer'),
      dataIndex: 'customerName',
      key: 'customer',
      align: 'left',
      className: 'text-xs',
      render: (text) => <span>{text}</span>,
    },
    {
      title: t('delivery_zone'),
      dataIndex: 'deliveryZoneName',
      key: 'deliveryZoneName',
      align: 'left',
      className: 'text-xs',
      render: (text) => <span>{text}</span>,
    },
    {
      title: t('products_total'),
      dataIndex: 'productsTotal',
      key: 'total',
      align: 'right',
      className: 'text-xs',
      render: (text) => <span>${text ? text.toFixed(2) : '0.00'}</span>,
      sorter: (a, b) => a.productsTotal - b.productsTotal,
    },
    {
      title: t('order_total'),
      dataIndex: 'orderTotal',
      key: 'total',
      align: 'right',
      className: 'text-xs',
      render: (text) => <span>${text ? text.toFixed(2) : '0.00'}</span>,
      sorter: (a, b) => a.orderTotal - b.orderTotal,
    },
    {
      title: t('commission'),
      dataIndex: 'calculatedDeliveryProviderRate',
      key: 'total',
      align: 'right',
      className: 'text-xs',
      render: (text) => <span>${text ? text.toFixed(2) : '0.00'}</span>,
    },
    {
      title: t('fee'),
      dataIndex: 'deliveryTotal',
      key: 'total',
      align: 'right',
      className: 'text-xs',
      render: (text) => <span>${text ? text.toFixed(2) : '0.00'}</span>,
    },
    // {
    //   title: t('rate_type'),
    //   dataIndex: 'deliveryProviderRateTypeString',
    //   key: 'deliveryProviderRateTypeString',
    //   align: 'right',
    //   className: 'text-xs',
    //   render: (text) => <span>{text}</span>,
    // },
    // {
    //   title: t('rate'),
    //   dataIndex: 'deliveryProviderRate',
    //   key: 'deliveryProviderRate',
    //   align: 'right',
    //   className: 'text-xs',
    //   render: (text) => <span>{text}</span>,
    // },
    {
      title: t('payment'),
      dataIndex: 'paymentName',
      key: 'paymentName',
      align: 'center',
      className: 'text-xs hidden',
      render: (text) => <span>{text}</span>,
    },
    {
      title: t('expected'),
      key: 'desiredDate',
      align: 'left',
      className: 'text-xs',
      render: (row) => <span>{renderShortDateHour(row.desiredDateTime, timezone)}</span>,
    },
    {
      title: t('completed_on'),
      key: 'dispatchDateTime',
      align: 'left',
      className: 'text-xs',
      render: (row) => <span>{renderShortDateHour(row.dispatchDateTime, timezone)}</span>,
    }
  ];

  const getProviders = function () {
    setLoadingProviders(true);
    api.get(`tpd/providers/${profile?.contents?.id}`)
      .then((response) => {
        setLoadingProviders(false);
        if (response.data.success) {
          setProviders(response.data.data.results);
          setSelectedProvider(response.data.data.results[0]);
        } else {
          console.error(response.data.error);
        }
      })
      .catch((error) => {
        setLoadingProviders(false);
        console.error(error);
      });
  };

  const mapRateType = function (type) {
    if (type === 0) {
      return t('none');
    }
    if (type === 1) {
      return t('fixed');
    }
    return t('percentage');
  };

  const mapOrders = function (result) {
    const mapped = JSON.parse(JSON.stringify(result));
    for (let i = 0; i < mapped.orders.length; i++) {
      mapped.orders[i].deliveryProviderRateTypeString =
        mapRateType(mapped.orders[i].deliveryProviderRateType);
      mapped.orders[i].deliveryProviderDeliveryFeeRateTypeString =
        mapRateType(mapped.orders[i].deliveryProviderRateType);
    }
    return mapped;
  };

  const getWeeklyData = function () {
    if (selectedProvider && dateRange) {
      setLoadingWeek(true);
      const payload = {
        BusinessId: business?.id,
        DeliveryProviderId: selectedProvider?.id,
        StartDate: dateRange[0],
        EndDate: dateRange[1]
      };
      api.post('tpd/providers/range/report', payload)
        .then((response) => {
          setLoadingWeek(false);
          if (response.data.success) {
            // console.log(response.data.data);
            setWeeklyData(mapOrders(response.data.data));
          } else {
            console.error(response.data.error);
          }
        })
        .catch((error) => {
          setLoadingWeek(false);
          console.error(error);
        });
    }
  };

  const getMonthlyData = function () {
    if (selectedProvider && selectedMonth) {
      const month = selectedMonth + 1;
      setLoadingMonthly(true);
      const payload = {
        BusinessId: business?.id,
        DeliveryProviderId: selectedProvider?.id,
        Month: month
      };
      api.post('tpd/providers/monthly/report', payload)
        .then((response) => {
          setLoadingMonthly(false);
          if (response.data.success) {
            // console.log(response.data.data);
            setMonthlyData(mapOrders(response.data.data));
          } else {
            console.error(response.data.error);
          }
        })
        .catch((error) => {
          setLoadingMonthly(false);
          console.error(error);
        });
    }
  };

  useEffect(() => {
    if (selectedProvider) {
      const startDate = moment().startOf('week');
      const endDate = moment().startOf('week').add(7, 'days');
      setDateRange([startDate, endDate]);
      setPickerDate(startDate);
      setSelectedMonth(moment().month());
    }
  }, [selectedProvider]);

  useEffect(() => {
    if (selectedProvider && dateRange && business) {
      getWeeklyData();
    }
  }, [selectedProvider, dateRange, business]);

  useEffect(() => {
    if (selectedProvider && selectedMonth && business) {
      getMonthlyData();
    }
  }, [selectedProvider, selectedMonth, business]);

  useEffect(() => {
    getProviders();
  }, []);

  return (
    <>
      <PageHeader
        title={t('delivery_reports')}
        extra={[
          <Select
            hidden={providers.length < 2}
            value={selectedProvider?.id}
            style={{ width: 120 }}
            loading={loadingProviders}
            onChange={(obj) => {
              const filter = _.filter(providers, (b) => b.id === obj);
              setSelectedProvider(filter[0]);
            }}
          >
            {_.map(providers, (b) => (
              <Option
                value={b.id}
                key={b.id}
              >
                {b.name}
              </Option>
            ))}
          </Select>
        ]}
      />
      <div className="ml-6 mb-4 text-sm">
        {t('delivery_providers_description')}
      </div>
      <Tabs defaultActiveKey="1">
        <Tabs.TabPane tab={t('weekly')} key="1">
          <Spin spinning={loadingProviders}>
            <div style={{ margin: '10px 0' }}>
              <strong>{t('viewing_week_of')}: </strong>
              <DatePicker
                picker="week"
                allowClear={false}
                onChange={
                  (date, dateString) => {
                    const start = moment(dateString).startOf('week');
                    const end = moment(dateString).startOf('week').add(7, 'days');
                    setPickerDate(start);
                    setDateRange([start, end]);
                  }
                }
                value={dayjs(pickerDate)}
                format="YYYY-MM-DD"
              />
              <Button
                type="primary"
                key="refresh"
                size="small"
                className="float-right"
                onClick={() => {
                  getWeeklyData();
                }}
              >
                {t('refresh')}
              </Button>
              <Button
                type="primary"
                size="small"
                key="export"
                className="float-right mr-2"
                icon={<FileExcelOutlined />}
                onClick={() => {
                  saveAsCsv({
                    data: weeklyData?.orders,
                    fields,
                    filename: `delivery_report_week_${moment(dateRange[0]).format('MMM-D-YYYY')}`,
                  });
                }}
              >
                {t('export_orders')}
              </Button>
            </div>
            <Panel className="mb-2">
              <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-4 gap-4 pt-4">
                <Spin spinning={loadingWeek}>
                  <div className="shadow rounded-xl flex h-20">
                    <div className="flex flex-shrink-0 items-center justify-center text-white text-3xl bg-rose-500 w-1/4 rounded-l-xl">
                      <DatabaseTwoTone
                        twoToneColor="#eb2f96"
                        className="-ml-1 flex-shrink-0 self-center h-5 w-5"
                      />
                    </div>
                    <div className="flex flex-col text-3xl mt-2 justify-center p-2 text-xs text-gray-800">
                      <div>
                        {weeklyData && weeklyData?.ordersDeliveredCount}
                      </div>
                    </div>
                    <div className="flex flex-col justify-end p-4 text-md text-gray-800 font-semibold">
                      <div>
                        {t('orders')}
                      </div>
                    </div>
                  </div>
                </Spin>
                <Spin spinning={loadingWeek}>
                  <div className="shadow rounded-xl flex h-20">
                    <div className="flex flex-shrink-0 items-center justify-center text-white text-3xl bg-rose-500 w-1/4 rounded-l-xl">
                      <WalletTwoTone
                        className="-ml-1 flex-shrink-0 self-center h-5 w-5"
                      />
                    </div>
                    <div className="flex flex-col text-3xl mt-2 justify-center p-2 text-xs text-gray-800">
                      <div>
                        ${weeklyData && weeklyData?.accumalatedSubtotalRate.toFixed(2)}
                      </div>
                    </div>
                    <div className="flex flex-col justify-end p-4 text-md text-gray-800 font-semibold">
                      <div>
                        {t('commissions')}
                      </div>
                    </div>
                  </div>
                </Spin>
                <Spin spinning={loadingWeek}>
                  <div className="shadow rounded-xl flex h-20">
                    <div className="flex flex-shrink-0 items-center justify-center text-white text-3xl bg-rose-500 w-1/4 rounded-l-xl">
                      <WalletTwoTone
                        className="-ml-1 flex-shrink-0 self-center h-5 w-5"
                      />
                    </div>
                    <div className="flex flex-col text-3xl mt-2 justify-center p-2 text-xs text-gray-800">
                      <div>
                        ${weeklyData && weeklyData?.accumalatedDeliveryFeeRate.toFixed(2)}
                      </div>
                    </div>
                    <div className="flex flex-col justify-end p-4 text-md text-gray-800 font-semibold">
                      <div>
                        {t('fees')}
                      </div>
                    </div>
                  </div>
                </Spin>
                <Spin spinning={loadingWeek}>
                  <div className="shadow rounded-xl flex h-20">
                    <div className="flex flex-shrink-0 items-center justify-center text-white text-3xl bg-rose-500 w-1/4 rounded-l-xl">
                      <BankTwoTone
                        className="-ml-1 flex-shrink-0 self-center h-5 w-5"
                      />
                    </div>
                    <div className="flex flex-col text-3xl mt-2 justify-center p-2 text-xs text-gray-800">
                      <div>
                        ${weeklyData && weeklyData?.accumalatedRate.toFixed(2)}
                      </div>
                    </div>
                    <div className="flex flex-col justify-end p-4 text-md text-gray-800 font-semibold">
                      <div>
                        {t('total')}
                      </div>
                    </div>
                  </div>
                </Spin>
              </div>
            </Panel>
            <Table
              loading={loadingWeek}
              dataSource={weeklyData?.orders}
              bordered
              rowKey="id"
              columns={tableColumns}
            />
          </Spin>
        </Tabs.TabPane>
        <Tabs.TabPane tab={t('monthly')} key="2">
          <Spin spinning={loadingProviders}>
            <div style={{ margin: '10px 0' }}>
              <strong>{t('viewing_month_of')}: </strong>
              <Select
                value={selectedMonth}
                style={{ width: 120 }}
                onChange={(obj) => setSelectedMonth(obj)}
              >
                {_.map(months, (b) => (
                  <Option
                    value={b.id}
                    key={b.id}
                  >
                    {b.name}
                  </Option>
                ))}
              </Select>
              <Button
                type="primary"
                key="refresh"
                size="small"
                className="float-right"
                onClick={() => {
                  getMonthlyData();
                }}
              >
                {t('refresh')}
              </Button>
              <Button
                type="primary"
                size="small"
                key="export"
                className="float-right mr-2"
                icon={<FileExcelOutlined />}
                onClick={() => {
                  saveAsCsv({
                    data: monthlyData?.orders,
                    fields,
                    filename: `delivery_report_month_${months[selectedMonth].name}`,
                  });
                }}
              >
                {t('export_orders')}
              </Button>
            </div>
            <Panel className="mb-2">
              <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-4 gap-4 pt-4">
                <Spin spinning={loadingMonthly}>
                  <div className="shadow rounded-xl flex h-20">
                    <div className="flex flex-shrink-0 items-center justify-center text-white text-3xl bg-rose-500 w-1/4 rounded-l-xl">
                      <DatabaseTwoTone
                        twoToneColor="#eb2f96"
                        className="-ml-1 flex-shrink-0 self-center h-5 w-5"
                      />
                    </div>
                    <div className="flex flex-col text-3xl mt-2 justify-center p-2 text-xs text-gray-800">
                      <div>
                        {monthlyData && monthlyData?.ordersDeliveredCount}
                      </div>
                    </div>
                    <div className="flex flex-col justify-end p-4 text-md text-gray-800 font-semibold">
                      <div>
                        {t('orders')}
                      </div>
                    </div>
                  </div>
                </Spin>
                <Spin spinning={loadingMonthly}>
                  <div className="shadow rounded-xl flex h-20">
                    <div className="flex flex-shrink-0 items-center justify-center text-white text-3xl bg-rose-500 w-1/4 rounded-l-xl">
                      <WalletTwoTone
                        className="-ml-1 flex-shrink-0 self-center h-5 w-5"
                      />
                    </div>
                    <div className="flex flex-col text-3xl mt-2 justify-center p-2 text-xs text-gray-800">
                      <div>
                        ${monthlyData && monthlyData?.accumalatedSubtotalRate.toFixed(2)}
                      </div>
                    </div>
                    <div className="flex flex-col justify-end p-4 text-md text-gray-800 font-semibold">
                      <div>
                        {t('commissions')}
                      </div>
                    </div>
                  </div>
                </Spin>
                <Spin spinning={loadingMonthly}>
                  <div className="shadow rounded-xl flex h-20">
                    <div className="flex flex-shrink-0 items-center justify-center text-white text-3xl bg-rose-500 w-1/4 rounded-l-xl">
                      <WalletTwoTone
                        className="-ml-1 flex-shrink-0 self-center h-5 w-5"
                      />
                    </div>
                    <div className="flex flex-col text-3xl mt-2 justify-center p-2 text-xs text-gray-800">
                      <div>
                        ${monthlyData && monthlyData?.accumalatedDeliveryFeeRate.toFixed(2)}
                      </div>
                    </div>
                    <div className="flex flex-col justify-end p-4 text-md text-gray-800 font-semibold">
                      <div>
                        {t('fees')}
                      </div>
                    </div>
                  </div>
                </Spin>
                <Spin spinning={loadingWeek}>
                  <div className="shadow rounded-xl flex h-20">
                    <div className="flex flex-shrink-0 items-center justify-center text-white text-3xl bg-rose-500 w-1/4 rounded-l-xl">
                      <BankTwoTone
                        className="-ml-1 flex-shrink-0 self-center h-5 w-5"
                      />
                    </div>
                    <div className="flex flex-col text-3xl mt-2 justify-center p-2 text-xs text-gray-800">
                      <div>
                        ${monthlyData && monthlyData?.accumalatedRate.toFixed(2)}
                      </div>
                    </div>
                    <div className="flex flex-col justify-end p-4 text-md text-gray-800 font-semibold">
                      <div>
                        {t('total')}
                      </div>
                    </div>
                  </div>
                </Spin>
              </div>
            </Panel>
            <Table
              loading={loadingMonthly}
              dataSource={monthlyData?.orders}
              bordered
              rowKey="id"
              columns={tableColumns}
            />
          </Spin>
        </Tabs.TabPane>
      </Tabs>
    </>
  );
}

export default DeliveryProviderReports;
