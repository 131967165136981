import React from 'react';
import { useTranslation } from 'react-i18next';
import { Tag } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import { useRecoilValue } from 'recoil';
import { CheckCircleOutlined, SyncOutlined } from '@ant-design/icons';
import { businessAtom } from '../../../atoms/Atoms';
import Bin from './shared-components/Bin';

function Bins() {
  const business = useRecoilValue(businessAtom);
  const { t } = useTranslation();

  return (
    <>
      <PageHeader
        title={t('bins')}
        tags={
          <Tag
            color="#2db7f5"
            icon={business ? <CheckCircleOutlined /> : <SyncOutlined spin />}
          >
            {business ? business.name : `${t('loading')}...`}
          </Tag>
        }
      />
      <Bin />
    </>
  );
}

export default Bins;
