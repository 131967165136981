import React, { useState, useEffect } from 'react';
import { useMount } from 'react-use';
import {
  useRecoilValue,
  useRecoilValueLoadable,
  useSetRecoilState,
} from 'recoil';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import 'moment-timezone';
import _ from 'lodash';
import { useJsonToCsv } from 'react-json-csv';
import {
  Tag,
  Badge,
  Button,
  Table,
  Typography,
  Space,
  Select,
  Card,
  Popover,
  DatePicker,
  Divider,
  Switch,
} from 'antd';
import dayjs from 'dayjs';
import { PageHeader } from '@ant-design/pro-layout';
import {
  CheckCircleOutlined,
  CloudDownloadOutlined,
  FileOutlined,
  ReloadOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import {
  businessAtom,
  timezoneAtom,
  profileAtom,
  selectedTerminalDataAtom,
} from '../../../../atoms/Atoms';
import {
  mapOrderType,
  mapPaymentType,
  thousand,
  toCurrency,
} from '../../../utils/functions';
import api from '../../../../api/api';
import envConfig from '../../../../envConfig';
import { dateRangePresets } from '../../../utils/utils';

function EmployeeInStoreSummary() {
  const bottomRowGridStyle = { width: '50%', textAlign: 'center' };
  const { RangePicker } = DatePicker;
  const { t } = useTranslation();
  const { Option } = Select;
  const { saveAsCsv } = useJsonToCsv();
  const business = useRecoilValue(businessAtom);
  const timezone = useRecoilValue(timezoneAtom);
  const profile = useRecoilValueLoadable(profileAtom);
  const isAdmin =
    profile.contents && profile?.contents?.roles?.includes('SuperAdmin');
  const isStoreManager =
    isAdmin ||
    (profile?.contents &&
      (profile?.contents?.roles?.includes('StoreAdmin') ||
        profile?.contents?.roles?.includes('Owner')));
  const canViewFinance =
    isAdmin ||
    isStoreManager ||
    (profile.contents &&
      (profile.contents.roles.includes('Finance') ||
        profile.contents.roles.includes('TopAnalytics')));
  const [reportData, setReportData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [includeOrders, setIncludeOrders] = useState(false);
  const [loadingEmployees, setLoadingEmployees] = useState(null);
  const [selectedEmployees, setSelectedEmployees] = useState(['']);
  const [dateRange, setDateRange] = useState({
    start: moment().startOf('month').format('YYYY-MM-DD'),
    end: moment().endOf('month').format('YYYY-MM-DD'),
  });
  const [employees, setEmployees] = useState([]);
  const setSelectedTerminalData = useSetRecoilState(selectedTerminalDataAtom);

  const width = window.innerWidth;
  const allColumns = [
    {
      title: t('clerk'),
      dataIndex: 'dispatcher',
      key: 'dispatcher',
      align: 'center',
      className: 'text-xs',
      sorter: (a, b) => a.dispatcher.localeCompare(b.dispatcher),
      render: (text) => <span>{text}</span>,
    },
    {
      title: t('count'),
      dataIndex: 'count',
      key: 'count',
      align: 'right',
      className: 'text-xs',
      sorter: (a, b) => a.count - b.count,
      render: (text) => <span>{text}</span>,
    },
    {
      title: t('total'),
      dataIndex: 'orderTotal',
      key: 'orderTotal',
      align: 'right',
      className: 'text-xs',
      sorter: (a, b) => a.orderTotal - b.orderTotal,
      render: (text) => (
        <span>
          $
          {text
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
        </span>
      ),
    },
    {
      title: t('average_order'),
      dataIndex: 'averageOrderTotal',
      key: 'averageOrderTotal',
      align: 'right',
      className: 'text-xs',
      sorter: (a, b) => a.averageOrderTotal - b.averageOrderTotal,
      render: (text) => (
        <span>
          $
          {text
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
        </span>
      ),
    },
  ];
  const restrictedColumns = [
    {
      title: t('day'),
      dataIndex: 'date',
      key: 'date',
      align: 'center',
      className: 'text-xs',
      sorter: (a, b) => moment(a.datetime) - moment(b.datetime),
      render: (text) => <span>{text}</span>,
    },
    {
      title: t('clerk'),
      dataIndex: 'dispatcher',
      key: 'dispatcher',
      align: 'center',
      className: 'text-xs',
      sorter: (a, b) => a.dispatcher.localeCompare(b.dispatcher),
      render: (text) => <span>{text}</span>,
    },
    {
      title: t('pos_lane'),
      dataIndex: 'posLaneTag',
      key: 'posLaneTag',
      align: 'center',
      className: 'text-xs',
      sorter: (a, b) => a.posLaneTag.localeCompare(b.posLaneTag),
      render: (text) => <span>{text}</span>,
    },
    {
      title: t('count'),
      dataIndex: 'count',
      key: 'count',
      align: 'right',
      className: 'text-xs',
      sorter: (a, b) => a.count - b.count,
      render: (text) => <span>{text}</span>,
    },
    {
      title: t('total'),
      dataIndex: 'orderTotal',
      key: 'orderTotal',
      align: 'right',
      className: 'text-xs',
      sorter: (a, b) => a.orderTotal - b.orderTotal,
      render: (text) => (
        <span>
          $
          {text
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
        </span>
      ),
    },
    {
      title: t('average_order'),
      dataIndex: 'averageOrderTotal',
      key: 'averageOrderTotal',
      align: 'right',
      className: 'text-xs',
      sorter: (a, b) => a.averageOrderTotal - b.averageOrderTotal,
      render: (text) => (
        <span>
          $
          {text
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
        </span>
      ),
    },
  ];

  const allListFields = {
    clerk: t('clerk'),
    count: t('count'),
    orderTotal: t('total'),
  };
  const restrictedListFields = {
    date: t('day'),
    count: t('count'),
    orderTotal: t('total'),
  };

  function mapData(data) {
    const groupedData = _.groupBy(data, 'dispatcher');
    const mappedData = _.map(groupedData, (items, dispatcher) => {
      const count = _.sumBy(items, 'orderCount');
      const orderTotal = _.sumBy(items, 'orderTotal');
      const summary = {
        dispatcher,
        count,
        orderTotal,
        averageOrderTotal: orderTotal / count,
        // Add other summary fields if needed
      };
      return summary;
    });

    return _.orderBy(mappedData, ['count'], ['desc']);
  }

  function getData() {
    setLoading(true);

    const data = {
      StartDate: dayjs(dateRange.start).add(-1 * envConfig.REACT_APP_TIMEZONE_OFFSET, 'hour').format('YYYY-MM-DDTHH:mm:ss.000'),
      EndDate: dayjs(dateRange.end).add(-1 * envConfig.REACT_APP_TIMEZONE_OFFSET, 'hour').format('YYYY-MM-DDTHH:mm:ss.000'),
      BusinessId: business?.id,
      EmployeeIds: _.filter(selectedEmployees, (p) => p !== ''),
      IncludeOrderSummary: includeOrders
    };

    api.post('analytics/reporting/instore/sales/employees/search', data)
      .then((response) => {
        setLoading(false);
        setReportData({
          ...response.data.data,
          sales: mapData(response.data.data.sales)
        });
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  }

  function getEmployees() {
    setLoadingEmployees(true);
    api.get(`pos/employees${business ? (`/${business?.id}`) : ''}`)
      .then((response) => {
        setLoadingEmployees(false);
        setEmployees(_.sortBy(response.data.data.activeEmployees, (e) => e.completeName));
      })
      .catch((error) => {
        console.error(error);
        setLoadingEmployees(false);
      });
  }

  useEffect(() => {
    getEmployees();
    getData();
  }, [dateRange, business, selectedEmployees, includeOrders]);

  useEffect(() => {
    getEmployees();
    getData();
  }, [business]);

  return (
    <div>
      <PageHeader
        className="mb-4 px-0"
        title={t('summary')}
        tags={
          <Tag color="#2db7f5" icon={<CheckCircleOutlined />}>
            {business ? business.name : t('global')}
          </Tag>
        }
        extra={[
          <RangePicker
            format="YYYY-MM-DD"
            value={[dayjs(dateRange?.start), dayjs(dateRange?.end)]}
            presets={dateRangePresets(t)}
            onChange={(date, dateString) => {
              setDateRange({
                start: dateString[0],
                end: dateString[1],
              });
            }}
          />,
          <div>
            <br className={width < 1024 ? null : 'hidden'} />
            <Select
              mode="multiple"
              listHeight={500}
              loading={loadingEmployees}
              disabled={loadingEmployees}
              style={{ width: 150 }}
              onChange={(value) => {
                if (value[value.length - 1] === '') {
                  setSelectedEmployees(['']);
                } else {
                  const check = _.filter(value, (p) => p !== '');
                  if (value.length === 0) {
                    setSelectedEmployees(['']);
                  } else if (check.length > 0) {
                    setSelectedEmployees(check);
                  } else {
                    setSelectedEmployees(value);
                  }
                }
              }}
              value={selectedEmployees}
            >
              <Option key="all" value="">
                {t('all')}
              </Option>
              {_.map(employees, (p) => (
                <Option
                  key={p.id}
                  value={p.id}
                  className="text-xs flex items-center"
                >
                  {p.completeName}
                </Option>
              ))}
            </Select>
          </div>,
          <div className="space-x-2">
            <br className={width < 1024 ? null : 'hidden'} />
            <Button
              type="primary"
              size="small"
              icon={<CloudDownloadOutlined />}
              onClick={() => {
                const data = _.map(reportData?.sales, (p) => ({
                  clerk: p.dispatcher,
                  count: p.count,
                  orderTotal: p.orderTotal,
                  productsTotal: p.productsTotal,
                  municipalTax: p.municipalTax,
                  stateTax: p.stateTax,
                  taxTotal: p.taxTotal,
                  deliveryFee: p.deliveryFee,
                  pickupFee: p.pickupFee,
                  discountTotal: p.discountTotal,
                  returnTotal: p.returnTotal,
                  transferTotal: p.transferTotal,
                }));
                saveAsCsv({
                  data,
                  fields: canViewFinance ? allListFields : restrictedListFields,
                  filename: `reportData_${dateRange.start}_${dateRange.end}`,
                });
              }}
            >
              {t('export_list')}
            </Button>
            <Button
              type="primary"
              size="small"
              icon={<ReloadOutlined />}
              onClick={() => getData()}
            >
              {t('refresh')}
            </Button>
          </div>,
        ]}
      />
      <div className="divide-y space-y-4 mb-4">
        <dl className="mt-5 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-4">
          <Card
            type="inner"
            title={t('total')}
            className="rounded-xl bg-gradient-to-br from-blue-200 to-white"
            hoverable
            loading={loading}
          >
            <Card.Grid
              className="bg-white"
              hoverable={false}
              style={bottomRowGridStyle}
            >
              <span className="font-bold">{t('sales')}:</span>
              <span>
                {' '}
                {toCurrency(
                  reportData?.cashSalesTotal + reportData?.creditDebitSalesTotal
                )}
              </span>
            </Card.Grid>
            <Card.Grid
              className="bg-white"
              hoverable={false}
              style={bottomRowGridStyle}
            >
              <span className="font-bold">{t('orders')}:</span>
              <span>
                {' '}
                {thousand(
                  reportData?.cashSalesCount + reportData?.creditDebitSalesCount
                )}
              </span>
            </Card.Grid>
          </Card>
          <Card
            type="inner"
            title={t('debit')}
            className="rounded-xl bg-gradient-to-br from-blue-200 to-white"
            hoverable
            loading={loading}
          >
            <Card.Grid
              className="bg-white"
              hoverable={false}
              style={bottomRowGridStyle}
            >
              <span className="font-bold">{t('sales')}:</span>
              <span> {toCurrency(reportData?.debitSalesTotal)}</span>
            </Card.Grid>
            <Card.Grid
              className="bg-white"
              hoverable={false}
              style={bottomRowGridStyle}
            >
              <span className="font-bold">{t('orders')}:</span>
              <span> {thousand(reportData?.debitSalesCount)}</span>
            </Card.Grid>
          </Card>
          <Card
            type="inner"
            title={t('credit')}
            className="rounded-xl bg-gradient-to-br from-blue-200 to-white"
            hoverable
            loading={loading}
          >
            <Card.Grid
              className="bg-white"
              hoverable={false}
              style={bottomRowGridStyle}
            >
              <span className="font-bold">{t('sales')}:</span>
              <span> {toCurrency(reportData?.creditSalesTotal)}</span>
            </Card.Grid>
            <Card.Grid
              className="bg-white"
              hoverable={false}
              style={bottomRowGridStyle}
            >
              <span className="font-bold">{t('orders')}:</span>
              <span> {thousand(reportData?.creditSalesCount)}</span>
            </Card.Grid>
          </Card>
          <Card
            type="inner"
            title={t('cash')}
            className="rounded-xl bg-gradient-to-br from-blue-200 to-white"
            hoverable
            loading={loading}
          >
            <Card.Grid
              className="bg-white"
              hoverable={false}
              style={bottomRowGridStyle}
            >
              <span className="font-bold">{t('sales')}:</span>
              <span> {toCurrency(reportData?.cashSalesTotal)}</span>
            </Card.Grid>
            <Card.Grid
              className="bg-white"
              hoverable={false}
              style={bottomRowGridStyle}
            >
              <span className="font-bold">{t('orders')}:</span>
              <span> {thousand(reportData?.cashSalesCount)}</span>
            </Card.Grid>
          </Card>
        </dl>
      </div>
      <Table
        size="small"
        loading={loading}
        bordered
        scroll={{ x: 1000 }}
        pagination={false}
        columns={canViewFinance ? allColumns : restrictedColumns}
        dataSource={reportData?.sales}
      />
    </div>
  );
}

export default EmployeeInStoreSummary;
