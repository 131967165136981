import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import _ from 'lodash';
import { useJsonToCsv } from 'react-json-csv';
import { Button, Col, Row, Table, Typography, Tag } from 'antd';
import { CloudDownloadOutlined } from '@ant-design/icons';
import { useRecoilValue, useRecoilValueLoadable } from 'recoil';
import { lostSalesPastAnalyticsSelector } from '../../../../atoms/Selectors';
import {
  pastLossAtom,
  timezoneAtom,
} from '../../../../atoms/Atoms';

function OutOfStock() {
  const { t } = useTranslation();
  const showBeta = false;
  const [current, setCurrent] = useState(1);
  const { saveAsCsv } = useJsonToCsv();
  const timezone = useRecoilValue(timezoneAtom);
  const pastLossDays = useRecoilValue(pastLossAtom);
  const oos = useRecoilValueLoadable(lostSalesPastAnalyticsSelector);
  const [rows, setRows] = useState(10);

  function mapGroups(data) {
    return _.map(data, (p, index) => ({
      key: index,
      total: p.diff,
      count: p.orderCount,
      ordersTotal: p.finalTotal,
      lossPct: p.diffPct,
      itemCount: p.itemCount,
      date: moment(`${p.date}+0000`).tz(timezone).format('MMMM Do YYYY'),
      globalLossPct: p.globalAvg,
      orders: _.map(p.orders, (v) => ({
        key: v.upc,
        ...v,
      })),
    }));
  }

  const listFields = {
    count: t('orders'),
    lossPct: `${t('loss')} %`,
    total: `${t('loss')} $`,
    date: t('date'),
  };

  return (
    <div className="p-4 border bg-gray-100 rounded-lg mb-4">
      <Row align="middle" gutter={[8, 8]}>
        <Col xs={24} md={24}>
          <Typography.Title level={5}>
            {t('showing_loss_of_past')} {pastLossDays} {t('days').toLowerCase()}
          </Typography.Title>
          <Typography.Text type="secondary">
            {t('summary_of_loss_below')}
          </Typography.Text>
        </Col>
        <Col>
          {showBeta === false && (
            <Button
              size="small"
              icon={<CloudDownloadOutlined />}
              onClick={() => {
                const data = mapGroups(oos.contents.data.data);
                saveAsCsv({
                  data,
                  fields: listFields,
                  filename: `weekly_${new Date().toLocaleDateString()}`,
                });
              }}
            >
              {t('export_loss')}
            </Button>
          )}
        </Col>
      </Row>
      <Row />
      <Row>
        <Col xs={24}>
          <Table
            size="small"
            loading={oos.state === 'loading'}
            bordered
            scroll={{ x: 500 }}
            pagination={{
              current,
              hideOnSinglePage: true,
              showSizeChanger: true,
              size: 'small',
              pageSize: rows,
              total: oos.state === 'hasValue' && oos?.contents?.data?.data?.count,
              pageSizeOptions: [10, 20, 30, 40, 50],
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} ${t('out_of')} ${total} ${t('items')}`,
              onShowSizeChange: (page, size) => {
                setRows(size);
              },
              onChange: (page) => {
                setCurrent(page);
              },
            }}
            columns={[
              {
                title: t('date'),
                dataIndex: 'date',
                key: 'date',
                align: 'left',
                className: 'text-s',
                render: (text) => <span>{text}</span>,
              },
              {
                title: t('orders'),
                dataIndex: 'count',
                key: 'count',
                align: 'center',
                className: 'text-s',
                render: (text) => <span>{text}</span>,
              },
              {
                title: `${t('loss')} ($)`,
                dataIndex: 'total',
                key: 'total',
                align: 'center',
                className: 'text-s',
                render: (text) => (
                  <span>
                    {text.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  </span>
                ),
                sorter: (a, b) => a.total - b.total,
              },
              {
                title: `${t('products_total')} ($)`,
                dataIndex: 'ordersTotal',
                key: 'ordersTotal',
                align: 'center',
                className: 'text-s',
                render: (text) => (
                  <span>
                    {text.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  </span>
                ),
                sorter: (a, b) => a.ordersTotal - b.ordersTotal,
              },
              {
                title: `${t('loss')} %`,
                dataIndex: 'lossPct',
                key: 'lossPct',
                align: 'left',
                className: 'text-s font-medium',
                render: (text) => (
                  <Tag color={text > 5 ? 'red' : 'green'}>
                    {' '}
                    <span>{text.toFixed(2)}</span>
                  </Tag>
                ),
                sorter: (a, b) => a.lossPct - b.lossPct,
              },
              {
                title: `${t('global_loss')} %`,
                dataIndex: 'globalLossPct',
                key: 'globalLossPct',
                align: 'left',
                className: 'text-s font-medium',
                render: (text) => <span>{text.toFixed(2)}</span>,
              },
            ]}
            dataSource={
              oos.state === 'hasValue' && oos.contents.data
                ? mapGroups(oos.contents.data.data)
                : null
            }
          />
        </Col>
      </Row>
    </div>
  );
}

export default OutOfStock;
