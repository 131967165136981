import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import { notification, Modal, Avatar, Input, Button, Typography } from 'antd';
import { Comment } from '@ant-design/compatible';
import { PageHeader } from '@ant-design/pro-layout';
import envConfig from '../../../envConfig';
import { addLogAtom, selectedOrderAtom, reloadOrder, referralEmailAtom } from '../../../atoms/Atoms';
import api from '../../../api/api';
import Grocefy from '../../../assets/images/grocefyLogoAlone.png';

function ReferralEmail() {
  const [referralEmail, setReferralEmail] = useRecoilState(referralEmailAtom);
  const [reload, setReload] = useRecoilState(reloadOrder);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const { TextArea } = Input;
  const { t } = useTranslation();

  const onChangeMessage = function (obj) {
    setMessage(obj.target.value);
  };

  const closeTicketModal = function () {
    if (!loading) {
      setReferralEmail(false);
    }
  };

  const sendEmail = function () {
    setLoading(true);
    const data = {
      UserId: null,
      Email: message,
      Language: 0
    };
    api
      .post('marketing/referralemail', data)
      .then((response) => {
        if (response && response.data.error) {
          notification.open({
            message: 'Alert!',
            description: (
              <Comment
                author={<span>Grocefy</span>}
                avatar={<Avatar src={Grocefy} alt="grocefy" />}
                content={
                  <p className="text-sm">{response.data.error}</p>
                }
              />
            ),
          });
        } else if (
          response &&
          response.data.success &&
          response.data.message
        ) {
          notification.open({
            message: 'Success!',
            description: (
              <Comment
                author={<span>Grocefy</span>}
                avatar={<Avatar src={Grocefy} alt="grocefy" />}
                content={
                  <p className="text-sm">{response.data.message}</p>
                }
              />
            ),
          });
        }
        setReferralEmail(false);
        setReload(true);
        setLoading(false);
        setMessage('');
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <>
      {referralEmail && (
        <Modal
          closable="true"
          centered="true"
          open={referralEmail}
          onCancel={() => { setReferralEmail(false); }}
          footer={[
            <Button
              key="create"
              disabled={loading}
              loading={loading}
              onClick={() => sendEmail()}
            >
              {t('send')}
            </Button>,
            <Button
              key="close"
              disabled={loading}
              onClick={() => closeTicketModal()}
            >
              {t('close')}
            </Button>
          ]}
        >
          <PageHeader title={t('no_user_found')} />
          <Typography.Text>{t('referral_modal_message')}</Typography.Text>
          <br />
          <Input
            placeholder={t('email')}
            onChange={(obj) => onChangeMessage(obj)}
            value={message}
          />
        </Modal>
      )}
    </>
  );
}

export default ReferralEmail;
