import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  useRecoilValue,
  useRecoilState,
  useRecoilValueLoadable
} from 'recoil';
import {
  Button,
  Table,
  Tag,
  Input
} from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import moment from 'moment';
import {
  timezoneAtom,
  selectedPromotionAtom,
  refreshPromotionsAtom,
  profileAtom
} from '../../../atoms/Atoms';
import EditPromotion from './shared-components/EditPromotion';
import api from '../../../api/api';

function Promotions() {
  const { Search } = Input;
  const timezone = useRecoilValue(timezoneAtom);
  const [promotions, setPromotions] = useState([]);
  const [selectedPromotion, setSelectedPromotion] = useRecoilState(selectedPromotionAtom);
  const [refreshPromotions, setRefreshPromotions] = useRecoilState(refreshPromotionsAtom);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const userProfile = useRecoilValueLoadable(profileAtom);
  const isAdmin =
    userProfile.contents && userProfile?.contents?.roles?.includes('SuperAdmin');
  const isMarketing =
    isAdmin || (userProfile?.contents && userProfile?.contents?.roles?.includes('Marketing'));
  const history = useHistory();
  const { t } = useTranslation();

  if (!isAdmin && !isMarketing) {
    history.push('/');
    return null;
  }

  const getPromotions = function () {
    setLoading(true);
    api.post('marketing/search/promotions', {
      searchText: searchTerm
    })
      .then((response) => {
        setLoading(false);
        if (response.data.success) {
          for (let i = 0; i < response.data.data.results.length; i++) {
            let status = 'Not Active';
            const startDate = moment(`${response.data.data.results[i].startDate}+0000`);
            const endDate = moment(`${response.data.data.results[i].endDate}+0000`);
            const currentDate = moment();
            if (response.data.data.results[i].isActive &&
                currentDate >= startDate && currentDate <= endDate) {
              status = 'Active';
            } else if (response.data.data.results[i].isActive && currentDate > endDate) {
              status = 'Completed';
            }
            response.data.data.results[i].status = status;
            response.data.data.results[i].startDateTime = moment(startDate).tz(timezone);
            response.data.data.results[i].endDateTime = moment(endDate).tz(timezone);
          }
          setPromotions(response.data.data.results);
        } else {
          console.error(response.data.error);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  useEffect(() => {
    setSelectedPromotion(null);
    getPromotions();
  }, []);

  useEffect(() => {
    if (refreshPromotions) {
      setRefreshPromotions(false);
      getPromotions();
    }
  }, [refreshPromotions]);

  return (
    <>
      <PageHeader
        title={t('promotions')}
        extra={[
        //   <Button
        //     type="primary"
        //     size="small"
        //     key="create"
        //     onClick={() => {
        //       setShowCreateSponsorshipDrawer(true);
        //       setSelectedSponsorship({});
        //     }}
        //   >
        //     {t('create')}
        //   </Button>
        ]}
      />
      <Search
        placeholder={t('item_search_placeholder')}
        allowClear
        loading={loading}
        onChange={(obj) => {
          setSearchTerm(obj.target.value);
        }}
        onSearch={(term) => getPromotions()}
      />
      <Table
        size="small"
        bordered
        loading={loading}
        pagination={false}
        dataSource={promotions}
        columns={[
          {
            title: t('name'),
            align: 'center',
            className: 'text-xs',
            render: (row) => (
              <span>{row.name}</span>
            ),
          },
          {
            title: t('start_date'),
            dataIndex: 'startDate',
            align: 'center',
            className: 'text-xs',
            render: (text) => (
              <span>
                {moment(`${text}+0000`)
                  .format('MMMM Do YYYY, h:mm:ss a')}
              </span>
            ),
          },
          {
            title: t('end_date'),
            dataIndex: 'endDate',
            align: 'center',
            className: 'text-xs',
            render: (text) => (
              <span>
                {moment(`${text}+0000`)
                  .format('MMMM Do YYYY, h:mm:ss a')}
              </span>
            ),
          },
          {
            title: t('status'),
            dataIndex: 'status',
            align: 'center',
            className: 'text-xs',
            render: (text) => (
              <span>{text === 'Active' ? <Tag color="green">Yes</Tag> : <Tag color="red">No</Tag>}</span>
            ),
          },
          {
            title: t('items'),
            dataIndex: 'totalItems',
            align: 'center',
            className: 'text-xs',
            render: (text) => (
              <span>{text}</span>
            ),
          },
          {
            title: '',
            align: 'center',
            className: 'text-xs',
            render: (row) => (
              <div>
                <Button
                  size="small"
                  type="primary"
                  onClick={() => {
                    setSelectedPromotion(row);
                  }}
                >
                  {t('view')}
                </Button>
              </div>
            ),
          }
        ]}
      />
      {/* {selectedSponsorship && showCreateSponsorshipDrawer && (
        <EditSponsorship />
      )} */}
      <EditPromotion />
    </>
  );
}

export default Promotions;
