import React, { useState, useEffect } from 'react';
import {
  Input,
  InputNumber,
  Avatar,
  Tag,
  Tooltip,
  Spin,
  notification,
  Modal,
  Popconfirm,
  Drawer,
  Button,
  Select,
  Switch,
  Row,
  Col,
  Image,
  Table,
  Popover
} from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import { Comment } from '@ant-design/compatible';
import {
  PlusOutlined,
  InboxOutlined
} from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import {
  useRecoilValueLoadable,
  useSetRecoilState,
  useRecoilState
} from 'recoil';
import { useMount } from 'react-use';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import _ from 'lodash';
import {
  profileAtom,
  showItemRegistrationMediaAtom,
  showItemRegistrationGtinsAtom,
  reloadRegistrationItemDataAtom
} from '../../../atoms/Atoms';
import {
  businessesSelector
} from '../../../atoms/Selectors';
import Grocefy from '../../../assets/images/grocefyLogoAlone.png';
import { thousand } from '../../utils/functions';
import api from '../../../api/api';
import RegistrationItemGtinsDrawer from './shared-components/RegistrationItemGtinsDrawer';
import ManageRegistrationItemMediaDrawer from './shared-components/ManageRegistrationItemMediaDrawer';
import AddRegistrationItemMediaModal from './shared-components/AddRegistrationItemMediaModal';

function ItemRegistration() {
  const defaultRegistrationItem = {
    suggestedDivision: '',
    gtin: '',
    brand: '',
    name: '',
    nameSpa: '',
    description: '',
    descriptionSpa: '',
    searchTags: '',
    suggestedCategoryName: '',
    suggestedGroup: '',
    isPackagingSensitive: false,
    isTemperatureSensitive: false,
    isAlcoholicBeverage: false,
    isPerishable: false,
    isEbt: false,
    idRequired: false,
    hasPluCode: false,
    hasRandomWeight: false,
    pack: 1,
    taxRule: 3,
    munTaxPct: 0.01,
    stateTaxPct: 0.105
  };
  const defaultAssignItem = {
    suggestedDivision: '',
    gtin: '',
    brand: '',
    name: '',
    nameSpa: '',
    description: '',
    descriptionSpa: '',
    searchTags: '',
    suggestedCategoryName: '',
    suggestedGroup: '',
    isPackagingSensitive: false,
    isTemperatureSensitive: false,
    isAlcoholicBeverage: false,
    isPerishable: false,
    isEbt: false,
    idRequired: false,
    hasPluCode: false,
    hasRandomWeight: false,
    pack: 1,
    taxRule: 3,
    munTaxPct: 0.01,
    stateTaxPct: 0.105,
    cost: 0,
    price: 0
  };
  const { Option } = Select;
  const { t, i18n } = useTranslation();
  const businessesSelect = useRecoilValueLoadable(businessesSelector);
  const setShowItemMedia = useSetRecoilState(showItemRegistrationMediaAtom);
  const setShowItemGtins = useSetRecoilState(showItemRegistrationGtinsAtom);
  const [reloadRegistrationItemData, setReloadRegistrationItemData] = useRecoilState(reloadRegistrationItemDataAtom);
  const profile = useRecoilValueLoadable(profileAtom);
  const [loading, setLoading] = useState(null);
  const [loadingEdit, setLoadingEdit] = useState(null);
  const [loadingPrimaryGtin, setLoadingPrimaryGtin] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [size, setSize] = useState(20);
  const [items, setItems] = useState([]);
  const [totalItemsInSearch, setTotalItemsInSearch] = useState(0);
  const [searchText, setSearchText] = useState('');
  const [lastSearch, setLastSearch] = useState('');
  const [selectedItemForEdit, setSelectedItemForEdit] = useState(null);
  const [registrationItemData, setRegistrationItemData] = useState(null);
  const [selectedItemForAssign, setSelectedItemForAssign] = useState(null);
  const [assignItemData, setAssignItemData] = useState(null);
  const [loadingAssign, setLoadingAssign] = useState(false);
  const [assignDataNotValid, setAssignDataNotValid] = useState(false);
  const [noItemFound, setNoItemFound] = useState(false);
  const [applyToMultipleStores, setApplyToMultipleStores] = useState(false);
  const [selectedBusinessIds, setSelectedBusinessIds] = useState([]);

  const width = window.innerWidth;
  const isAdmin =
    profile.contents && (profile?.contents?.roles?.includes('SuperAdmin'));

  const showMessage = function (message) {
    notification.open({
      message: '',
      description: (
        <Comment
          author={<span>Grocefy</span>}
          avatar={<Avatar src={Grocefy} alt="grocefy" />}
          content={
            <p className="text-sm">
              {message}
            </p>
          }
          datetime={
            <Tooltip title={moment().format('YYYY-MM-DD HH:mm:ss')}>
              <span>{moment().fromNow()}</span>
            </Tooltip>
          }
        />
      ),
    });
  };

  function isNumeric(str) {
    if (typeof str !== 'string') return false;
    return !Number.isNaN(str) &&
           !Number.isNaN(parseFloat(str));
  }

  const searchProducts = function (query, page) {
    setLoading(true);
    setLastSearch(query);
    const payload = {
      SearchTerm: query,
      Page: page,
      Size: size,
      CheckIfAssigned: true
    };
    api
      .post(
        'itemregistration/search',
        payload
      )
      .then((response) => {
        setLoading(false);
        if (response.data.success) {
          setTotalItemsInSearch(response.data.data.total);
          setItems(response.data.data.items);
          if (response.data.data.total === 0) {
            setNoItemFound(true);
          }
        } else {
          showMessage(response.data.error);
        }
      })
      .catch((error) => {
        setLoading(false);
        showMessage(error.message);
      });
  };

  const onSearch = async (value) => {
    if (value?.length === 0) {
      return;
    }
    if (isNumeric(value)) {
      setSearchText('');
    }
    setLastSearch(value);
    setCurrentPage(0);
    searchProducts(value, 0);
  };

  const onTableChange = (page, pageSize) => {
    if (page - 1 !== currentPage) {
      setCurrentPage(page - 1);
      searchProducts(lastSearch, page - 1);
    } else {
      setSize(pageSize);
    }
  };

  function updateRegistrationItem() {
    setLoadingEdit(true);
    api.put(
      'itemregistration/items',
      registrationItemData
    )
      .then((response) => {
        setLoadingEdit(false);
        if (!response.data.success) {
          showMessage(response.data.error);
        }
        searchProducts(lastSearch, currentPage);
      })
      .catch((error) => {
        setLoadingEdit(false);
        showMessage(error.message);
      });
  }

  function createRegistrationItem() {
    setLoadingEdit(true);
    api.post(
      'itemregistration/items',
      registrationItemData
    )
      .then((response) => {
        setLoadingEdit(false);
        if (!response.data.success) {
          showMessage(response.data.error);
        }
        setRegistrationItemData(response.data.data);
      })
      .catch((error) => {
        setLoadingEdit(false);
        showMessage(error.message);
      });
  }

  function archiveItemClick() {
    setLoadingEdit(true);
    api.delete(`itemregistration/items/${registrationItemData.id}`)
      .then((response) => {
        setLoadingEdit(false);
        if (!response.data.success) {
          showMessage(response.data.error);
        }
        searchProducts(lastSearch, currentPage);
        setSelectedItemForEdit(null);
      })
      .catch((error) => {
        setLoadingEdit(false);
        showMessage(error.message);
      });
  }

  function getGtins() {
    setLoadingPrimaryGtin(true);
    api.get(`itemregistration/gtins/${registrationItemData.id}`)
      .then((response) => {
        setLoadingPrimaryGtin(false);
        if (response.data.success) {
          const filtered = _.filter(response.data.data, (g) => g.isPrimary);
          setRegistrationItemData({
            ...registrationItemData,
            gtin: filtered[0].gtin
          });
        }
      })
      .catch((error) => {
        setLoadingPrimaryGtin(false);
        showMessage(error.message);
      });
  }

  function assign() {
    setLoadingAssign(true);
    api.post(
      'itemregistration/assign',
      {
        ...assignItemData,
        BusinessIdsToAssign: applyToMultipleStores ? selectedBusinessIds : [],
        RegistrationItemId: assignItemData?.id
      }
    )
      .then((response) => {
        setLoadingAssign(false);
        if (!response.data.success) {
          showMessage(response.data.error);
        }
        setSelectedItemForAssign(null);
        searchProducts(lastSearch, currentPage);
      })
      .catch((error) => {
        setLoadingAssign(false);
        showMessage(error.message);
      });
  }

  useEffect(() => {
    setCurrentPage(0);
    searchProducts(lastSearch, 0);
  }, [size]);

  useEffect(() => {
    if (selectedItemForEdit) {
      setRegistrationItemData({
        ...selectedItemForEdit
      });
    } else {
      setRegistrationItemData(null);
    }
  }, [selectedItemForEdit]);

  useEffect(() => {
    setApplyToMultipleStores(false);
    setSelectedBusinessIds([]);
    if (selectedItemForAssign) {
      setAssignItemData({
        ...selectedItemForAssign
      });
    } else {
      setAssignItemData(null);
      setAssignDataNotValid(true);
    }
  }, [selectedItemForAssign]);

  useEffect(() => {
    if (reloadRegistrationItemData) {
      getGtins();
      setReloadRegistrationItemData(false);
    }
  }, [reloadRegistrationItemData]);

  useEffect(() => {
    if (assignItemData) {
      let isValid = true;
      isValid = isValid && assignItemData.gtin && assignItemData.gtin?.length > 0;
      isValid = isValid && assignItemData.gtin && assignItemData.gtin?.length > 0;
      isValid = isValid && assignItemData.gtin && assignItemData.gtin?.length > 0;
      isValid = isValid && assignItemData.gtin && assignItemData.gtin?.length > 0;
      isValid = isValid && assignItemData.gtin && assignItemData.gtin?.length > 0;
      isValid = isValid && assignItemData.gtin && assignItemData.gtin?.length > 0;
      isValid = isValid && assignItemData.taxRule && assignItemData.taxRule >= 0;
      isValid = isValid && assignItemData.price && assignItemData.price > 0;
      isValid = isValid && assignItemData.cost && assignItemData.cost > 0;
      setAssignDataNotValid(!isValid);
    }
  }, [assignItemData]);

  useEffect(() => {
    setCurrentPage(0);
    searchProducts('', 0);
  }, []);

  return (
    <>
      <PageHeader
        className="mb-4 px-0"
        title={t('item_registration')}
        subTitle={t('item_registration_description')}
        extra={
          [
            <Button
              hidden={!isAdmin}
              key="createItemButton"
              type="primary"
              onClick={() => { setSelectedItemForEdit(defaultRegistrationItem); }}
            >
              <PlusOutlined />
              {t('create')}
            </Button>
          ]
        }
      />
      <div>
        <Input.Search
          key="itemSearch"
          placeholder={t('item_search_placeholder')}
          allowClear
          value={searchText}
          onChange={(obj) => setSearchText(obj.target.value)}
          onPressEnter={() => onSearch(searchText)}
          enterButton={t('search')}
          onSearch={onSearch}
          loading={loading}
        />
        <div className="mx-2 mb-4">{t('last_search')}: {lastSearch}</div>
        <div className="mx-2 mt-4">{t('results')}: {thousand(totalItemsInSearch)}</div>
        <Table
          loading={loading}
          bordered
          pagination={{
            pageSize: size,
            showSizeChanger: true,
            defaultCurrent: 0,
            current: currentPage + 1,
            total: totalItemsInSearch,
            onChange: onTableChange
          }}
          columns={[
            {
              title: t('image'),
              key: 'image',
              width: 120,
              align: 'center',
              className: 'text-xs',
              render: (text) =>
                <Image
                  height={100}
                  src={text.itemImage}
                  alt={`${text.brand} ${text.name} ${text.description}`}
                />,
            },
            {
              title: 'GTIN',
              key: 'gtin',
              align: 'left',
              className: 'text-xs',
              render: (text) =>
                <div className="flex flex-col">
                  <span>{text.gtin}</span>
                </div>,
            },
            {
              title: t('product'),
              key: 'product',
              align: 'left',
              className: 'text-xs',
              render: (text) =>
                <div className="flex flex-col">
                  <span>{text.brand}</span>
                  <span>{i18n.language === 'en' ? text.name : text.nameSpa}</span>
                  <span>{i18n.language === 'en' ? text.description : text.descriptionSpa}</span>
                </div>,
            },
            {
              title: t('status'),
              key: 'status',
              align: 'center',
              className: 'text-xs',
              render: (row) => (
                <>
                  {(!row.isAlreadyAssigned) && (
                    <Tag>{t('not_assigned')}</Tag>
                  )}
                  {row.isAlreadyAssigned && (
                    <Tag color="green">{t('assigned')}</Tag>
                  )}
                </>
              ),
            },
            {
              title: t('actions'),
              key: 'update',
              align: 'center',
              className: 'text-xs',
              render: (text, item) => (
                <div className="space-x-2">
                  <Button
                    size="small"
                    type="primary"
                    disabled={item.isAlreadyAssigned}
                    onClick={() => setSelectedItemForAssign({
                      ...item,
                      cost: 0,
                      price: 0
                    })}
                  >
                    {t('assign')}
                  </Button>
                  {isAdmin && (
                    <Button
                      size="small"
                      type="primary"
                      onClick={() => setSelectedItemForEdit(item)}
                    >
                      {t('edit')}
                    </Button>
                  )}
                </div>
              ),
            },
          ]}
          dataSource={items}
        />
      </div>

      <Drawer
        title={registrationItemData?.id ? t('edit_registration_item') : t('create_registration_item')}
        placement="right"
        closable={!loadingEdit}
        onClose={() => setSelectedItemForEdit(null)}
        open={selectedItemForEdit}
        width={width < 1024 ? width : 550}
      >
        <div className="space-y-2 mb-4">
          {registrationItemData?.id && (
            <>
              <Popconfirm
                title={t('archive_item_prompt')}
                okText={t('yes')}
                cancelText={t('no')}
                onConfirm={() => {
                  archiveItemClick();
                }}
              >
                <Button
                  type="primary"
                  className="mt-2 float-right"
                  danger
                  loading={loading}
                >
                  {t('archive')}
                </Button>
              </Popconfirm>
              <Button
                disabled={loadingEdit}
                type="primary"
                onClick={() => {
                  setShowItemMedia(registrationItemData);
                }}
              >
                {t('manage_media')}
              </Button>
              <Spin spinning={loadingPrimaryGtin}>
                <div className="mt-1">
                  <strong>{t('primary_gtin')}:</strong> {registrationItemData.gtin}
                </div>
                <Button
                  disabled={loadingEdit}
                  type="primary"
                  onClick={() => {
                    setShowItemGtins(registrationItemData);
                  }}
                >
                  {t('manage_gtins')} ({thousand(registrationItemData.gtins.length)})
                </Button>
              </Spin>
            </>
          )}
          {!registrationItemData?.id && (
            <div>
              <strong>GTIN</strong>
              <Input
                placeholder="GTIN"
                disabled={loadingEdit}
                onChange={(obj) => setRegistrationItemData({
                  ...registrationItemData,
                  gtin: obj.target.value
                })}
                value={registrationItemData?.gtin}
              />
            </div>
          )}
          <div>
            <strong>{t('brand')}</strong>
            <Input
              disabled={loadingEdit}
              placeholder={t('brand')}
              onChange={(obj) => setRegistrationItemData({
                ...registrationItemData,
                brand: obj.target.value
              })}
              value={registrationItemData?.brand}
            />
          </div>
          <div className="flex space-x-2">
            <div className="w-full">
              <strong>{t('name')}</strong>
              <Input
                disabled={loadingEdit}
                placeholder={t('name')}
                onChange={(obj) => setRegistrationItemData({
                  ...registrationItemData,
                  name: obj.target.value
                })}
                value={registrationItemData?.name}
              />
            </div>
            <Popover content={t('copy_over')}>
              <Button
                className="mt-5"
                disabled={loadingEdit}
                type="primary"
                onClick={() => setRegistrationItemData({
                  ...registrationItemData,
                  nameSpa: registrationItemData?.name
                })}
              >
                <FontAwesomeIcon icon={faArrowRight} />
              </Button>
            </Popover>
            <div className="w-full">
              <strong>{t('name_spa')}</strong>
              <Input
                disabled={loadingEdit}
                placeholder={t('name_spa')}
                onChange={(obj) => setRegistrationItemData({
                  ...registrationItemData,
                  nameSpa: obj.target.value
                })}
                value={registrationItemData?.nameSpa}
              />
            </div>
          </div>
          <div className="flex space-x-2">
            <div className="w-full">
              <strong>{t('description')}</strong>
              <Input
                disabled={loadingEdit}
                placeholder={t('description')}
                onChange={(obj) => setRegistrationItemData({
                  ...registrationItemData,
                  description: obj.target.value
                })}
                value={registrationItemData?.description}
              />
            </div>
            <Popover content={t('copy_over')}>
              <Button
                className="mt-5"
                type="primary"
                disabled={loadingEdit}
                onClick={() => setRegistrationItemData({
                  ...registrationItemData,
                  nameSpa: registrationItemData?.description
                })}
              >
                <FontAwesomeIcon icon={faArrowRight} />
              </Button>
            </Popover>
            <div className="w-full">
              <strong>{t('description_spa')}</strong>
              <Input
                disabled={loadingEdit}
                placeholder={t('description_spa')}
                onChange={(obj) => setRegistrationItemData({
                  ...registrationItemData,
                  descriptionSpa: obj.target.value
                })}
                value={registrationItemData?.descriptionSpa}
              />
            </div>
          </div>
          <div>
            <strong>{t('division')}</strong>
            <Input
              disabled={loadingEdit}
              onChange={(obj) => setRegistrationItemData({
                ...registrationItemData,
                suggestedDivision: obj.target.value
              })}
              value={registrationItemData?.suggestedDivision}
            />
          </div>
          <div>
            <strong>{t('group')}</strong>
            <Input
              disabled={loadingEdit}
              onChange={(obj) => setRegistrationItemData({
                ...registrationItemData,
                suggestedGroup: obj.target.value
              })}
              value={registrationItemData?.suggestedGroup}
            />
          </div>
          <div>
            <strong>{t('suggested_category_name')}</strong>
            <Input
              disabled={loadingEdit}
              onChange={(obj) => setRegistrationItemData({
                ...registrationItemData,
                suggestedCategoryName: obj.target.value
              })}
              value={registrationItemData?.suggestedCategoryName}
            />
          </div>
          <div>
            <strong>{t('search_tags')}</strong> <small>{t('custom_search_tag_note')}</small>
            <Input
              disabled={loadingEdit}
              onChange={(obj) => setRegistrationItemData({
                ...registrationItemData,
                searchTags: obj.target.value
              })}
              value={registrationItemData?.searchTags}
            />
          </div>
          <Row>
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={12}
              className="space-y-2 pr-2"
            >
              <div>
                <small>{t('package_sensitive')} </small>
                <Switch
                  disabled={loadingEdit}
                  style={{ float: 'right' }}
                  checked={registrationItemData?.isPackagingSensitive}
                  onChange={(checked) => setRegistrationItemData({
                    ...registrationItemData,
                    isPackagingSensitive: checked
                  })}
                />
              </div>
              <div>
                <small>{t('is_plu_code')} </small>
                <Switch
                  disabled={loadingEdit}
                  style={{ float: 'right' }}
                  checked={registrationItemData?.hasPluCode}
                  onChange={(checked) => setRegistrationItemData({
                    ...registrationItemData,
                    hasPluCode: checked
                  })}
                />
              </div>
              <div>
                <small>EBT </small>
                <Switch
                  disabled={loadingEdit}
                  style={{ float: 'right' }}
                  checked={registrationItemData?.isEbt}
                  onChange={(checked) => setRegistrationItemData({
                    ...registrationItemData,
                    isEbt: checked
                  })}
                />
              </div>
              <div>
                <small>{t('id_required')} </small>
                <Switch
                  disabled={loadingEdit}
                  style={{ float: 'right' }}
                  checked={registrationItemData?.idRequired}
                  onChange={(checked) => setRegistrationItemData({
                    ...registrationItemData,
                    idRequired: checked
                  })}
                />
              </div>
            </Col>
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={12}
              className="space-y-2 pl-2"
            >
              <div>
                <small>{t('temp_sensitive')} </small>
                <Switch
                  disabled={loadingEdit}
                  style={{ float: 'right' }}
                  checked={registrationItemData?.isTemperatureSensitive}
                  onChange={(checked) => setRegistrationItemData({
                    ...registrationItemData,
                    isTemperatureSensitive: checked
                  })}
                />
              </div>
              <div>
                <small>{t('alcoholic_beverage')} </small>
                <Switch
                  disabled={loadingEdit}
                  style={{ float: 'right' }}
                  checked={registrationItemData?.isAlcoholicBeverage}
                  onChange={(checked) => setRegistrationItemData({
                    ...registrationItemData,
                    isAlcoholicBeverage: checked
                  })}
                />
              </div>
              <div>
                <small>{t('perishable')}</small>
                <Switch
                  disabled={loadingEdit}
                  style={{ float: 'right' }}
                  checked={registrationItemData?.isPerishable}
                  onChange={(checked) => setRegistrationItemData({
                    ...registrationItemData,
                    isPerishable: checked
                  })}
                />
              </div>
              <div>
                <small>{t('has_random_weight')}</small>
                <Switch
                  disabled={loadingEdit}
                  style={{ float: 'right' }}
                  checked={registrationItemData?.hasRandomWeight}
                  onChange={(checked) => setRegistrationItemData({
                    ...registrationItemData,
                    hasRandomWeight: checked
                  })}
                />
              </div>
            </Col>
          </Row>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-2">
            <div>
              <strong>{t('tax_rule')}</strong>
              <br />
              <Select
                value={registrationItemData?.taxRule}
                style={{ width: '100%' }}
                disabled={loadingEdit}
                onChange={(obj) => setRegistrationItemData({
                  ...registrationItemData,
                  taxRule: obj
                })}
              >
                <Option
                  key="taxnone"
                  value={0}
                >
                  {t('none')}
                </Option>
                <Option
                  key="taxmun"
                  value={1}
                >
                  {t('municipal')}
                </Option>
                <Option
                  key="taxstate"
                  value={2}
                >
                  {t('state')}
                </Option>
                <Option
                  key="taxboth"
                  value={3}
                >
                  {t('both')}
                </Option>
              </Select>
            </div>
            <div>
              <strong>{t('municipal_tax')}</strong>
              <br />
              <InputNumber
                disabled={loadingEdit}
                className="w-full"
                min={0}
                addonAfter="%"
                step="0.01"
                onChange={(obj) => setRegistrationItemData({
                  ...registrationItemData,
                  munTaxPct: obj
                })}
                value={registrationItemData?.munTaxPct}
              />
            </div>
            <div>
              <strong>{t('state_tax')}</strong>
              <br />
              <InputNumber
                disabled={loadingEdit}
                className="w-full"
                min={0}
                addonAfter="%"
                step="0.01"
                onChange={(obj) => setRegistrationItemData({
                  ...registrationItemData,
                  stateTaxPct: obj
                })}
                value={registrationItemData?.stateTaxPct}
              />
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-2">
            <div>
              <strong>{t('pack')}</strong>
              <br />
              <InputNumber
                disabled={loadingEdit}
                className="w-full"
                min={1}
                onChange={(obj) => setRegistrationItemData({
                  ...registrationItemData,
                  pack: obj
                })}
                value={registrationItemData?.pack}
              />
            </div>
          </div>
          <Button
            type="primary"
            className="float-right my-4"
            loading={loadingEdit}
            onClick={() => {
              if (registrationItemData?.id) {
                updateRegistrationItem();
              } else {
                createRegistrationItem();
              }
            }}
          >
            {t('update')}
          </Button>
        </div>
      </Drawer>
      <RegistrationItemGtinsDrawer />
      <ManageRegistrationItemMediaDrawer />
      <AddRegistrationItemMediaModal />
      <Modal
        title={t('item_not_found')}
        open={noItemFound}
        onCancel={() => setNoItemFound(false)}
        footer={[
          <Button
            key="close"
            type="primary"
            danger
            onClick={() => setNoItemFound(false)}
          >
            {t('no')}
          </Button>,
          <Button
            key="ok"
            type="primary"
            onClick={() => {
              setNoItemFound(false);
              setSelectedItemForAssign(defaultAssignItem);
            }}
          >
            {t('yes')}
          </Button>
        ]}
      >
        <h2>{t('item_not_found_desc')}</h2>
      </Modal>
      <Modal
        title={t('assign_item_to_catalog')}
        open={selectedItemForAssign}
        onCancel={() => setSelectedItemForAssign(null)}
        footer={[
          <Button
            key="close"
            type="primary"
            danger
            disabled={loadingAssign}
            onClick={() => setSelectedItemForAssign(null)}
          >
            {t('close')}
          </Button>,
          <Button
            key="ok"
            type="primary"
            disabled={assignDataNotValid}
            loading={loadingAssign}
            onClick={() => assign()}
          >
            {t('submit')}
          </Button>
        ]}
      >
        <div className="space-y-2 mb-4">
          <div>
            <strong>GTIN</strong>
            <Input
              placeholder="GTIN"
              disabled={loadingAssign}
              onChange={(obj) => setAssignItemData({
                ...assignItemData,
                gtin: obj.target.value
              })}
              value={assignItemData?.gtin}
            />
          </div>
          <div>
            <strong>{t('brand')}</strong>
            <Input
              disabled={loadingAssign}
              placeholder={t('brand')}
              onChange={(obj) => setAssignItemData({
                ...assignItemData,
                brand: obj.target.value
              })}
              value={assignItemData?.brand}
            />
          </div>
          <div className="flex space-x-2">
            <div className="w-full">
              <strong>{t('name')}</strong>
              <Input
                disabled={loadingAssign}
                placeholder={t('name')}
                onChange={(obj) => setAssignItemData({
                  ...assignItemData,
                  name: obj.target.value
                })}
                value={assignItemData?.name}
              />
            </div>
            <Popover content={t('copy_over')}>
              <Button
                className="mt-5"
                disabled={loadingAssign}
                type="primary"
                onClick={() => setAssignItemData({
                  ...assignItemData,
                  nameSpa: assignItemData?.name
                })}
              >
                <FontAwesomeIcon icon={faArrowRight} />
              </Button>
            </Popover>
            <div className="w-full">
              <strong>{t('name_spa')}</strong>
              <Input
                disabled={loadingAssign}
                placeholder={t('name_spa')}
                onChange={(obj) => setAssignItemData({
                  ...assignItemData,
                  nameSpa: obj.target.value
                })}
                value={assignItemData?.nameSpa}
              />
            </div>
          </div>
          <div className="flex space-x-2">
            <div className="w-full">
              <strong>{t('description')}</strong>
              <Input
                disabled={loadingAssign}
                placeholder={t('description')}
                onChange={(obj) => setAssignItemData({
                  ...assignItemData,
                  description: obj.target.value
                })}
                value={assignItemData?.description}
              />
            </div>
            <Popover content={t('copy_over')}>
              <Button
                className="mt-5"
                type="primary"
                disabled={loadingAssign}
                onClick={() => setAssignItemData({
                  ...assignItemData,
                  nameSpa: assignItemData?.description
                })}
              >
                <FontAwesomeIcon icon={faArrowRight} />
              </Button>
            </Popover>
            <div className="w-full">
              <strong>{t('description_spa')}</strong>
              <Input
                disabled={loadingAssign}
                placeholder={t('description_spa')}
                onChange={(obj) => setAssignItemData({
                  ...assignItemData,
                  descriptionSpa: obj.target.value
                })}
                value={assignItemData?.descriptionSpa}
              />
            </div>
          </div>
          <div>
            <strong>{t('division')}</strong>
            <Input
              disabled={loadingAssign}
              onChange={(obj) => setAssignItemData({
                ...assignItemData,
                suggestedDivision: obj.target.value
              })}
              value={assignItemData?.suggestedDivision}
            />
          </div>
          <div>
            <strong>{t('group')}</strong>
            <Input
              disabled={loadingAssign}
              onChange={(obj) => setAssignItemData({
                ...assignItemData,
                suggestedGroup: obj.target.value
              })}
              value={assignItemData?.suggestedGroup}
            />
          </div>
          <div>
            <strong>{t('suggested_category_name')}</strong>
            <Input
              disabled={loadingAssign}
              onChange={(obj) => setAssignItemData({
                ...assignItemData,
                suggestedCategoryName: obj.target.value
              })}
              value={assignItemData?.suggestedCategoryName}
            />
          </div>
          <div>
            <strong>{t('search_tags')}</strong> <small>{t('custom_search_tag_note')}</small>
            <Input
              disabled={loadingAssign}
              onChange={(obj) => setAssignItemData({
                ...assignItemData,
                searchTags: obj.target.value
              })}
              value={assignItemData?.searchTags}
            />
          </div>
          <Row>
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={12}
              className="space-y-2 pr-2"
            >
              <div>
                <small>{t('package_sensitive')} </small>
                <Switch
                  disabled={loadingAssign}
                  style={{ float: 'right' }}
                  checked={assignItemData?.isPackagingSensitive}
                  onChange={(checked) => setAssignItemData({
                    ...assignItemData,
                    isPackagingSensitive: checked
                  })}
                />
              </div>
              <div>
                <small>{t('is_plu_code')} </small>
                <Switch
                  disabled={loadingAssign}
                  style={{ float: 'right' }}
                  checked={assignItemData?.hasPluCode}
                  onChange={(checked) => setAssignItemData({
                    ...assignItemData,
                    hasPluCode: checked
                  })}
                />
              </div>
              <div>
                <small>EBT </small>
                <Switch
                  disabled={loadingAssign}
                  style={{ float: 'right' }}
                  checked={assignItemData?.isEbt}
                  onChange={(checked) => setAssignItemData({
                    ...assignItemData,
                    isEbt: checked
                  })}
                />
              </div>
              <div>
                <small>{t('id_required')} </small>
                <Switch
                  disabled={loadingAssign}
                  style={{ float: 'right' }}
                  checked={assignItemData?.idRequired}
                  onChange={(checked) => setAssignItemData({
                    ...assignItemData,
                    idRequired: checked
                  })}
                />
              </div>
            </Col>
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={12}
              className="space-y-2 pl-2"
            >
              <div>
                <small>{t('temp_sensitive')} </small>
                <Switch
                  disabled={loadingAssign}
                  style={{ float: 'right' }}
                  checked={assignItemData?.isTemperatureSensitive}
                  onChange={(checked) => setAssignItemData({
                    ...assignItemData,
                    isTemperatureSensitive: checked
                  })}
                />
              </div>
              <div>
                <small>{t('alcoholic_beverage')} </small>
                <Switch
                  disabled={loadingAssign}
                  style={{ float: 'right' }}
                  checked={assignItemData?.isAlcoholicBeverage}
                  onChange={(checked) => setAssignItemData({
                    ...assignItemData,
                    isAlcoholicBeverage: checked
                  })}
                />
              </div>
              <div>
                <small>{t('perishable')}</small>
                <Switch
                  disabled={loadingAssign}
                  style={{ float: 'right' }}
                  checked={assignItemData?.isPerishable}
                  onChange={(checked) => setAssignItemData({
                    ...assignItemData,
                    isPerishable: checked
                  })}
                />
              </div>
              <div>
                <small>{t('has_random_weight')}</small>
                <Switch
                  disabled={loadingAssign}
                  style={{ float: 'right' }}
                  checked={assignItemData?.hasRandomWeight}
                  onChange={(checked) => setAssignItemData({
                    ...assignItemData,
                    hasRandomWeight: checked
                  })}
                />
              </div>
            </Col>
          </Row>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-2">
            <div>
              <strong>{t('tax_rule')}</strong>
              <br />
              <Select
                value={assignItemData?.taxRule}
                style={{ width: '100%' }}
                disabled={loadingAssign}
                onChange={(obj) => setAssignItemData({
                  ...assignItemData,
                  taxRule: obj
                })}
              >
                <Option
                  key="taxnone"
                  value={0}
                >
                  {t('none')}
                </Option>
                <Option
                  key="taxmun"
                  value={1}
                >
                  {t('municipal')}
                </Option>
                <Option
                  key="taxstate"
                  value={2}
                >
                  {t('state')}
                </Option>
                <Option
                  key="taxboth"
                  value={3}
                >
                  {t('both')}
                </Option>
              </Select>
            </div>
            <div>
              <strong>{t('municipal_tax')}</strong>
              <br />
              <InputNumber
                disabled={loadingAssign}
                className="w-full"
                min={0}
                addonAfter="%"
                step="0.01"
                onChange={(obj) => setAssignItemData({
                  ...assignItemData,
                  munTaxPct: obj
                })}
                value={assignItemData?.munTaxPct}
              />
            </div>
            <div>
              <strong>{t('state_tax')}</strong>
              <br />
              <InputNumber
                disabled={loadingAssign}
                className="w-full"
                min={0}
                addonAfter="%"
                step="0.01"
                onChange={(obj) => setAssignItemData({
                  ...assignItemData,
                  stateTaxPct: obj
                })}
                value={assignItemData?.stateTaxPct}
              />
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-2">
            <div>
              <strong>{t('pack')}</strong>
              <br />
              <InputNumber
                disabled={loadingAssign}
                className="w-full"
                min={1}
                onChange={(obj) => setAssignItemData({
                  ...assignItemData,
                  pack: obj
                })}
                value={assignItemData?.pack}
              />
            </div>
            <div>
              <strong>{t('price')}</strong>
              <br />
              <InputNumber
                disabled={loadingAssign}
                className="w-full"
                addonBefore="$"
                min={0}
                onChange={(obj) => setAssignItemData({
                  ...assignItemData,
                  price: obj
                })}
                value={assignItemData?.price}
              />
            </div>
            <div>
              <strong>{t('cost')}</strong>
              <br />
              <InputNumber
                disabled={loadingAssign}
                className="w-full"
                addonBefore="$"
                min={0}
                onChange={(obj) => setAssignItemData({
                  ...assignItemData,
                  cost: obj
                })}
                value={assignItemData?.cost}
              />
            </div>

          </div>
          <div className="mt-4">
            <strong>{t('assign_to_multiple_stores')}</strong>
            <Switch
              className="ml-2"
              disabled={loading}
              checked={applyToMultipleStores}
              onChange={(checked) => {
                setSelectedBusinessIds([]);
                setApplyToMultipleStores(checked);
              }}
            />
          </div>
          <div
            hidden={!applyToMultipleStores}
          >
            <div className="mt-2 mb-1 space-x-2">
              <strong>{t('stores')}</strong>
              <Button
                size="small"
                type="primary"
                onClick={() => {
                  setSelectedBusinessIds(_.map(businessesSelect.contents.data.data.results, (b) => b.id));
                }}
              >
                {t('select_all')}
              </Button>
            </div>
            <Select
              mode="multiple"
              value={selectedBusinessIds}
              style={{ width: '100%' }}
              disabled={loading}
              onChange={(value) => {
                setSelectedBusinessIds(value);
              }}
            >
              {businessesSelect.state === 'hasValue' && _.map(businessesSelect.contents.data.data.results, (business) => (
                <Option
                  key={business.id}
                  value={business.id}
                >
                  {business.name}
                </Option>
              ))}
            </Select>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default ItemRegistration;
