import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import {
  useRecoilValue,
  useRecoilState
} from 'recoil';
import { useTranslation } from 'react-i18next';
import { Tabs, Select, Switch } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import {
  selectedBusinessAccountPayoutsAtom,
  refreshBusinessAcountsAtom,
} from '../../../atoms/Atoms';
import Stripe from './stripe/Stripe';
import Paypal from './paypal/Paypal';
import api from '../../../api/api';

function GlobalPayouts() {
  const { t } = useTranslation();
  const { Option } = Select;
  const refreshBusinessAccounts
    = useRecoilValue(refreshBusinessAcountsAtom);
  const [selectedBusinessAccount, setSelectedBusinessAccount]
    = useRecoilState(selectedBusinessAccountPayoutsAtom);
  const [allBusinessAccounts, setAllBusinessAccounts] = useState([]);
  const [businessAccounts, setBusinessAccounts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showOnlyGlobal, setShowOnlyGlobal] = useState(false);

  const getBusinessAccounts = function () {
    setLoading(true);
    api.get('businesses/accounts')
      .then((response) => {
        setLoading(false);
        if (response.data.success) {
          setAllBusinessAccounts(response.data.data.results);
        } else {
          console.error(response.data.error);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  useEffect(() => {
    setSelectedBusinessAccount(null);
    getBusinessAccounts();
  }, []);

  useEffect(() => {
    if (refreshBusinessAccounts) {
      getBusinessAccounts();
    }
  }, [refreshBusinessAccounts]);

  useEffect(() => {
    if (showOnlyGlobal) {
      setBusinessAccounts(_.filter(allBusinessAccounts, (b) => !b.businessId));
    } else {
      setBusinessAccounts(allBusinessAccounts);
    }
  }, [showOnlyGlobal]);

  return (
    <div>
      <PageHeader
        extra={[
          <Select
            style={{ width: 175 }}
            loading={loading}
            disabled={loading}
            value={selectedBusinessAccount}
            placeholder={t('select_business_account')}
            onChange={(id) => {
              setSelectedBusinessAccount(id);
            }}
            showSearch="true"
            optionFilterProp="children"
            filterOption={(input, option) => {
              if (option && option.children) {
                return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
              }
              return false;
            }}
          >
            <Option value={null}>{t('all')}</Option>
            {_.map(businessAccounts, (b) => (
              <Option value={b?.id}>{b?.businessName}</Option>
            ))}
          </Select>,
          <Switch
            disabled={loading}
            checkedChildren={t('show_global_only')}
            unCheckedChildren={t('show_global_only')}
            className="-mt-1 ml-2"
            onChange={(checked) => setShowOnlyGlobal(checked)}
            checked={showOnlyGlobal}
          />
        ]}
      />
      <div className="-mt-6">
        <Tabs defaultActiveKey="0">
          <Tabs.TabPane tab="Stripe" key="0">
            <Stripe />
          </Tabs.TabPane>
          <Tabs.TabPane tab="PayPal" key="1">
            <Paypal />
          </Tabs.TabPane>
        </Tabs>
      </div>
    </div>
  );
}

export default GlobalPayouts;
