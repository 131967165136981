import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useRecoilValueLoadable, useRecoilState, useRecoilValue } from 'recoil';
import { CheckCircleOutlined, CloseCircleOutlined, LineChartOutlined } from '@ant-design/icons';
import moment from 'moment';
import Barcode from 'react-barcode';
import {
  Divider,
  Switch,
  Table,
  Input,
  Avatar,
  Tooltip,
  notification,
  Tag,
  Row,
  Col,
  Typography,
  Button,
  Tabs,
  List,
  Modal,
  Popconfirm,
} from 'antd';
import { Comment } from '@ant-design/compatible';
import { businessAtom, pageAtom, profileAtom, rowsAtom, reloadStaff, selectedEmployeeAtom, openPerformanceDrawerAtom } from '../../../../atoms/Atoms';
import { configSelector } from '../../../../atoms/Selectors';
import Grocefy from '../../../../assets/images/grocefyLogoAlone.png';
import api from '../../../../api/api';
import EmployeePerformance from '../../../shared-components/drawers/EmployeePerformance';

function Team() {
  const config = useRecoilValueLoadable(configSelector);
  // const staff = useRecoilValueLoadable(staffSelector);
  const [staff, setStaff] = useState([]);
  const [hideInactive, setHideInactive] = useState(true);
  const [businessProfile, setBusinessProfile] = useState(null);
  const [current, setCurrent] = useRecoilState(pageAtom);
  const [rows, setRows] = useRecoilState(rowsAtom);
  const [loading, setLoading] = useState(false);
  const [key] = useState(null);
  const [reload, setReload] = useRecoilState(reloadStaff);
  const store = useRecoilValue(businessAtom);
  const profile = useRecoilValueLoadable(profileAtom);
  const isAdmin =
  profile.contents && (profile.contents.roles.includes('SuperAdmin') || profile.contents.roles.includes('StoreAdmin')
  || profile.contents.roles.includes('CustomerServiceAdmin'));
  const isStoreAdmin =
    isAdmin || (profile?.contents && profile?.contents?.roles?.includes('StoreAdmin'));
  const [query, setQuery] = useState('');
  const [searchingUser, setSearchingUser] = useState(false);
  const { t } = useTranslation();
  const [users, setUsers] = useState(false);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [userFilterText, setUserFilterText] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [gettingIdCode, setGettingIdCode] = useState(false);
  const [managerCode, setManagerCode] = useState(null);
  const [selectedEmployee, setSelectedEmployee] = useRecoilState(selectedEmployeeAtom);
  const [openPerformanceDrawer, setOpenPerformanceDrawer] = useRecoilState(openPerformanceDrawerAtom);
  const [loadingManagerCode, setLoadingManagerCode] = useState(false);

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  function filter() {
    if (userFilterText?.length === 0) {
      setFilteredUsers(staff);
    } else {
      const temp = _.filter(staff, (u) => {
        const allText = `${u.completeName} ${u.email} ${u.phoneNumber}`;
        const test = allText.toLowerCase().includes(userFilterText.toLowerCase());
        return test;
      });
      setFilteredUsers(temp);
    }
    // setFilteredUsers(staff);
  }

  const showMessage = function (message) {
    notification.open({
      message: '',
      description: (
        <Comment
          author={<span>Grocefy</span>}
          avatar={<Avatar src={Grocefy} alt="grocefy" />}
          content={
            <p className="text-sm">
              {message}
            </p>
          }
          datetime={
            <Tooltip title={moment().format('YYYY-MM-DD HH:mm:ss')}>
              <span>{moment().fromNow()}</span>
            </Tooltip>
          }
        />
      ),
    });
  };

  const getStaff = function () {
    setQuery('');
    setSearchingUser(false);
    setLoading(true);
    api
      .get(`businesses/${store?.id}/team`)
      .then((response) => {
        setStaff(_.sortBy(response.data.data.results, (s) => !s.isActive));
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const addStaff = function (userToAdd) {
    if (!loading) {
      api
        .post(
          `users/${userToAdd.id}/businessProfile/${store.id}`,
          { }
        )
        .then((addResponse) => {
          if (addResponse.data.success) {
            getStaff();
            setIsModalOpen(false);
            notification.open({
              message: 'Alert!',
              description: (
                <Comment
                  author={<span>Grocefy</span>}
                  avatar={<Avatar src={Grocefy} alt="grocefy" />}
                  content={
                    <p className="text-sm">{t('user_added_successfully')}</p>
                  }
                />
              ),
            });
          } else {
            setSearchingUser(false);
            notification.open({
              message: 'Alert!',
              description: (
                <Comment
                  author={<span>Grocefy</span>}
                  avatar={<Avatar src={Grocefy} alt="grocefy" />}
                  content={
                    <p className="text-sm">{addResponse.data.error}</p>
                  }
                />
              ),
            });
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  const onSearch = async (value) => {
    if (value === '' || value === null) {
      setUsers(null);
      return;
    }

    setQuery(value);
    setLoading(true);
    setSearchingUser(true);

    api
      .post(
        'users/search',
        {
          SearchText: `${value}`,
        }
      )
      .then((response) => {
        setUsers(response.data.data.results);
        setLoading(false);
        setSearchingUser(false);
        setIsModalOpen(true);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
        setSearchingUser(false);
      });
  };

  function getTemporaryManagerCode() {
    setLoadingManagerCode(true);
    api
      .post(`pos/tempmanagercode/${store.id}`, { })
      .then((response) => {
        setLoadingManagerCode(false);
        if (response.data.success) {
          showMessage(`${response.data.message}. (${t('copied_to_clipboard')})`);
          navigator.clipboard.writeText(response.data.message);
        } else {
          showMessage(response.data.message);
        }
      })
      .catch((error) => {
        setLoadingManagerCode(false);
        showMessage(error.message);
        setLoading(false);
      });
  }

  useEffect(() => {
    if (store) {
      setFilteredUsers([]);
      getStaff();
    }
  }, [reload, store]);

  useEffect(() => {
    if (staff?.length > 0) {
      if (userFilterText?.length > 0) {
        filter();
      } else {
        setFilteredUsers(staff);
      }
    }
  }, [userFilterText]);

  useEffect(() => {
    filter();
  }, [staff]);

  useEffect(() => {
    setFilteredUsers([]);
    if (store) {
      getStaff();
      for (let i = 0; i < profile?.contents?.businessProfiles?.length; i++) {
        if (store.id === profile?.contents?.businessProfiles[i].businessId) {
          setBusinessProfile(profile?.contents?.businessProfiles[i]);
        }
      }
    }
    setUserFilterText('');
  }, []);

  function onChange() {
    setHideInactive(!hideInactive);
  }

  function update(temp) {
    const data = {
      Id: temp.key,
      UserId: temp.userId,
      ImageUrl: temp.imageUrl,
      Email: temp.email,
      CompleteName: temp.completeName,
      PhoneNumber: temp.phoneNumber,
      BusinessId: store.id,
      IsAdmin: temp.isAdmin,
      IsActive: temp.isActive,
      DeletedAt: temp.deletedAt,
      CanEditPayments: temp.canEditPayments,
      CanViewAnalytics: temp.canViewAnalytics,
      CanCreateMenuItems: temp.canCreateMenuItems,
      CanEditMenuItems: temp.canEditMenuItems,
      CanEditPrice: temp.canEditPrice,
      CanEditStore: temp.canEditStore,
      CanEditOrders: temp.canEditOrders,
      CanEditLocation: temp.canEditLocation,
      CanCancelOrders: temp.canCancelOrders,
      CanProvideCredit: temp.canProvideCredit,
      CanActivateUsers: temp.canActivateUsers,
      CanEditUsers: temp.canEditUsers,
      ForceSMS: temp.forceSMS,
      GetNotifications: temp.getNotifications,
      GetPickUpNotifications: temp.getPickUpNotifications,
      GetDeliveryNotifications: temp.getDeliveryNotifications,
      GetOrderNotifications: temp.getOrderNotifications,
      GetSensitiveFinanceNotifications: temp.getSensitiveFinanceNotifications,
      GetSensitiveNotifications: temp.getSensitiveNotifications,
      Roles: temp.roles,
    };
    return api
      .put(
        `businesses/${temp.id}/businessprofile`,
        data
      )
      .then((response) => {
        setReload(!reload);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  return (
    staff && (
      <>
        <div className="flex flex-columns justify-between">
          <div
            className="m-2"
          >
            {t('add_staff_prompt')}
          </div>
        </div>
        <Input.Search
          placeholder={t('search_customer_placeholder')}
          allowClear
          loading={searchingUser}
          disabled={searchingUser || loading}
          enterButton={t('search')}
          size="large"
          onSearch={onSearch}
        />
        <div>
          <Modal
            title="Add staff to your store"
            open={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
            footer={[
              <Button
                key="close"
                type="primary"
                danger
                loading={loading}
                onClick={() => {
                  setIsModalOpen(false);
                }}
              >
                {t('close')}
              </Button>
            ]}
            width={1000}
          >
            <Table
              size="small"
              bordered
              rowKey="id"
              scroll={{ x: 1000 }}
              loading={loading}
              columns={[
                {
                  title: t('name'),
                  dataIndex: 'completeName',
                  key: 'completeName',
                  align: 'left',
                  className: 'text-sm',
                  render: (text) => <span>{text}</span>,
                },
                {
                  title: t('email'),
                  dataIndex: 'email',
                  key: 'email',
                  align: 'left',
                  className: 'text-sm',
                  render: (text) => <span>{text}</span>,
                },
                {
                  title: 'Add',
                  key: 'add',
                  align: 'center',
                  className: 'text-xs',
                  render: (text, record) => (
                    <Button type="primary" onClick={() => addStaff(record)}>Add</Button>
                  ),
                },
              ]}
              dataSource={users}
            />
          </Modal>
        </div>
        <Divider />
        <div className="flex">
          <div>
            {t('hide_inactive')}
            <Switch size="small" checked={hideInactive} className="m-4" onChange={onChange} />
          </div>
          <Popconfirm
            title={t('create_provisional_manager_code_for_store')}
            okText={t('yes')}
            cancelText={t('no')}
            onConfirm={() => getTemporaryManagerCode()}
          >
            <Button
              hidden={!isStoreAdmin}
              key="tempmanagercodebutton"
              type="primary"
              className="ml-auto"
              loading={loadingManagerCode}
            >
              {t('provisional_manager_code')}
            </Button>
          </Popconfirm>
        </div>
        <Table
          size="small"
          bordered
          rowKey="id"
          scroll={{ x: 1000 }}
          title={() => (
            <div>
              <Input
                placeholder={t('search_customer_placeholder')}
                allowClear
                loading={loading}
                disabled={searchingUser || loading}
                value={userFilterText}
                size="large"
                onChange={(obj) => setUserFilterText(obj.target.value)}
              />
            </div>
          )}
          loading={loading}
          pagination={{
            current,
            hideOnSinglePage: true,
            showSizeChanger: true,
            size: 'small',
            pageSize: rows,
            total:
              staff.state === 'hasValue' &&
              staff.contents &&
              staff.contents.data.data.results.length,
            pageSizeOptions: [10, 20, 30, 40, 50],
            showTotal: (total, range) =>
              `${range[0]}-${range[1]} ${t('out_of')} ${total} ${t('items')}`,
            onShowSizeChange: (page, size) => {
              setRows(size);
            },
            onChange: (page) => {
              setCurrent(page);
            },
          }}
          columns={[
            {
              title: '',
              key: 'imageUrl',
              align: 'left',
              width: 50,
              className: 'text-s',
              render: (row) => (
                <>
                  {row.imageUrl && row.imageUrl.length === 0 && (
                    <Avatar
                      style={{ background: config?.contents?.data?.primaryColor }}
                    >
                      {`${row.completeName.split(' ')[0]}${row.completeName.split(' ')[0]}`}
                    </Avatar>
                  )}
                  {row.imageUrl && row.imageUrl.length > 0 && (
                    <Avatar
                      style={{ background: config?.contents?.data?.primaryColor }}
                      src={row.imageUrl}
                    />
                  )}
                </>
              ),
            },
            {
              title: t('name'),
              dataIndex: 'completeName',
              key: 'completeName',
              align: 'left',
              className: 'text-s',
              render: (text) => <span>{text}</span>,
            },
            {
              title: t('phone'),
              dataIndex: 'phoneNumber',
              key: 'phoneNumber',
              align: 'left',
              className: 'text-s',
              render: (text) => <span>{text}</span>,
            },
            {
              title: t('email'),
              dataIndex: 'email',
              key: 'email',
              align: 'left',
              className: 'text-s',
              render: (text) => <span>{text}</span>,
            },
            {
              title: t('is_active'),
              dataIndex: 'isActive',
              key: 'isActive',
              align: 'center',
              className: 'text-s',
              render: (text, record) => {
                if (record.isActive === true) {
                  return (
                    <Tag
                      icon={<CheckCircleOutlined />}
                      color="#87d068"
                      size="small"
                      className="mr-0"
                    >
                      {t('active')}
                    </Tag>
                  );
                }
                return (
                  <Tag
                    icon={<CloseCircleOutlined />}
                    color="#ff0400"
                    size="small"
                    className="mr-0"
                  >
                    {t('inactive')}
                  </Tag>
                );
              },
            },
            {
              title: t('type'),
              dataIndex: 'isAdmin',
              key: 'isAdmin',
              align: 'center',
              className: 'text-s',
              render: (text, record) => {
                if (record.isAdmin) {
                  return (
                    <Tag
                      icon={<CheckCircleOutlined />}
                      color="green"
                      size="small"
                      className="mr-0"
                    >
                      Admin
                    </Tag>
                  );
                }
                return (
                  <Tag
                    icon={<CloseCircleOutlined />}
                    color="blue"
                    size="small"
                    className="mr-0"
                  >
                    Regular
                  </Tag>
                );
              },
            },
            {
              title: 'Performance',
              key: 'performance',
              align: 'center',
              className: 'text-xs',
              render: (text, record) => (
                <Button
                  type="primary"
                  onClick={() => { setSelectedEmployee(record); setOpenPerformanceDrawer(true); }}
                  icon={<LineChartOutlined />}
                />
              ),
            },
            // {
            //   title: 'Edit',
            //   key: 'edit',
            //   align: 'center',
            //   className: 'text-xs',
            //   render: (text, record) => (
            //     <Button onClick={() => setTeam(record)}>Edit</Button>
            //   ),
            // },
          ]}
          dataSource={filteredUsers && hideInactive ? _.filter(filteredUsers, (x) => x.isActive) : filteredUsers}
          expandable={{
            expandedRowRender: (record) => (
              <div className="p-4">
                <div className="flex float-right space-x-2">
                  <Button
                    size="small"
                    type="primary"
                    disabled={!isAdmin}
                    loading={record.loading || gettingIdCode}
                    onClick={() => {
                      setGettingIdCode(true);
                      api
                        .get(`admin/getmanagercode/${record.userId}`)
                        .then((response) => {
                          setGettingIdCode(false);
                          setManagerCode({
                            code: response.data.data,
                            name: record.completeName
                          });
                        })
                        .catch((error) => {
                          setGettingIdCode(false);
                          console.error(error);
                        });
                    }}
                  >
                    {t('get_manager_code')}
                  </Button>
                  <Button
                    size="small"
                    type="primary"
                    loading={record.loading}
                    onClick={() => {
                      let tempStaff = JSON.parse(JSON.stringify(staff));
                      for (let i = 0; i < tempStaff.length; i++) {
                        tempStaff[i].loading = false;
                        if (tempStaff[i].id === record.id) {
                          tempStaff[i].loading = true;
                        }
                      }
                      setStaff(tempStaff);
                      api.get(`cs/users/logincode/${record.userId}`)
                        .then((response) => {
                          tempStaff = JSON.parse(JSON.stringify(staff));
                          for (let i = 0; i < tempStaff.length; i++) {
                            tempStaff[i].loading = false;
                          }
                          setStaff(tempStaff);
                          if (response.data.error) {
                            showMessage(response.data.error);
                          } else if (response.data.message) {
                            showMessage(`${response.data.message}. (${t('copied_to_clipboard')})`);
                            navigator.clipboard.writeText(response.data.message);
                          } else {
                            showMessage(t('succeeded'));
                          }
                        })
                        .catch((err) => {
                          tempStaff = JSON.parse(JSON.stringify(staff));
                          for (let i = 0; i < tempStaff.length; i++) {
                            tempStaff[i].loading = false;
                          }
                          setStaff(tempStaff);
                          showMessage(err.message);
                        });
                    }}
                  >
                    {t('send_pin')}
                  </Button>
                </div>
                <Tabs>
                  <Tabs.TabPane tab={t('privileges')} key="1">
                    <Row>
                      <Col span={10}>
                        <span>
                          {isAdmin || businessProfile?.canEditUsers ? (
                            <Switch
                              size="small"
                              loading={loading && record.key === key}
                              checked={record.isActive}
                              onChange={(checked) => {
                                const temp = JSON.parse(JSON.stringify(record));
                                temp.isActive = checked;
                                update(temp);
                              }}
                            />
                          ) : (
                            <Switch
                              disabled
                              size="small"
                              loading={loading && record.key === key}
                              checked={record.isActive}
                            />
                          )}
                        </span>
                        <Typography.Text> {t('is_active')} </Typography.Text>
                      </Col>
                      <Col span={8} offset={2}>
                        <span>
                          {isAdmin || businessProfile?.canEditUsers ? (
                            <Switch
                              size="small"
                              loading={loading && record.key === key}
                              // checked={record.allowTransfers}
                              checked={record.canCancelOrders}
                              onChange={(checked) => {
                                const temp = JSON.parse(JSON.stringify(record));
                                temp.canCancelOrders = checked;
                                update(temp);
                              }}
                            />
                          ) : (
                            <Switch
                              disabled
                              size="small"
                              loading={loading && record.key === key}
                              // checked={record.allowTransfers}
                              checked={record.canCancelOrders}
                            />
                          )}
                        </span>
                        <Typography.Text> {t('cancel_orders')} </Typography.Text>
                      </Col>
                    </Row>
                    <br />
                    <Row>
                      <Col span={10}>
                        <span>
                          {(isAdmin || businessProfile?.canEditUsers) && record.isActive ? (
                            <Switch
                              size="small"
                              loading={loading && record.key === key}
                              // checked={record.allowedInDeliService}
                              checked={record.canCreateMenuItems}
                              onChange={(checked) => {
                                const temp = JSON.parse(JSON.stringify(record));
                                temp.canCreateMenuItems = checked;
                                update(temp);
                              }}
                            />
                          ) : (
                            <Switch
                              disabled
                              size="small"
                              loading={loading && record.key === key}
                              // checked={record.allowedInDeliService}
                              checked={record.canCreateMenuItems}
                            />
                          )}
                        </span>
                        <Typography.Text> {t('create_menu_items')} </Typography.Text>
                      </Col>
                      <Col span={8} offset={2}>
                        <span>
                          {(isAdmin || businessProfile?.canEditUsers) && record.isActive ? (
                            <Switch
                              size="small"
                              loading={loading && record.key === key}
                              // checked={record.allowedInRegularService}
                              checked={record.canEditLocation}
                              onChange={(checked) => {
                                const temp = JSON.parse(JSON.stringify(record));
                                temp.canEditLocation = checked;
                                update(temp);
                              }}
                            />
                          ) : (
                            <Switch
                              disabled
                              size="small"
                              loading={loading && record.key === key}
                              // checked={record.allowedInRegularService}
                              checked={record.canEditLocation}
                            />
                          )}
                        </span>
                        <Typography.Text> {t('edit_location')} </Typography.Text>
                      </Col>
                    </Row>
                    <br />
                    <Row>
                      <Col span={10}>
                        <span>
                          {isAdmin || businessProfile?.canEditUsers ? (
                            <Switch
                              size="small"
                              loading={loading && record.key === key}
                              // checked={record.allowedInSelfCheckoutService}
                              checked={record.canEditMenuItems}
                              onChange={(checked) => {
                                const temp = JSON.parse(JSON.stringify(record));
                                temp.canEditMenuItems = checked;
                                update(temp);
                              }}
                            />
                          ) : (
                            <Switch
                              disabled
                              size="small"
                              loading={loading && record.key === key}
                              // checked={record.allowedInSelfCheckoutService}
                              checked={record.canEditMenuItems}
                            />
                          )}
                        </span>
                        <Typography.Text> {t('edit_menu_items')} </Typography.Text>
                      </Col>
                      <Col span={8} offset={2}>
                        <span>
                          {isAdmin || businessProfile?.canEditUsers ? (
                            <Switch
                              size="small"
                              loading={loading && record.key === key}
                              // checked={record.allowedInSelfCheckoutService}
                              checked={record.canEditOrders}
                              onChange={(checked) => {
                                const temp = JSON.parse(JSON.stringify(record));
                                temp.canEditOrders = checked;
                                update(temp);
                              }}
                            />
                          ) : (
                            <Switch
                              disabled
                              size="small"
                              loading={loading && record.key === key}
                              // checked={record.allowedInSelfCheckoutService}
                              checked={record.canEditOrders}
                            />
                          )}
                        </span>
                        <Typography.Text> {t('edit_orders')} </Typography.Text>
                      </Col>
                    </Row>
                    <br />
                    <Row>
                      <Col span={10}>
                        <span>
                          {isAdmin || businessProfile?.canEditUsers ? (
                            <Switch
                              size="small"
                              loading={loading && record.key === key}
                              // checked={record.allowedInSelfCheckoutService}
                              checked={record.canEditPrice}
                              onChange={(checked) => {
                                const temp = JSON.parse(JSON.stringify(record));
                                temp.canEditPrice = checked;
                                update(temp);
                              }}
                            />
                          ) : (
                            <Switch
                              disabled
                              size="small"
                              loading={loading && record.key === key}
                              // checked={record.allowedInSelfCheckoutService}
                              checked={record.canEditPrice}
                            />
                          )}
                        </span>
                        <Typography.Text> {t('edit_price')} </Typography.Text>
                      </Col>
                      <Col span={8} offset={2}>
                        <span>
                          {isAdmin || businessProfile?.canEditUsers ? (
                            <Switch
                              size="small"
                              loading={loading && record.key === key}
                              // checked={record.allowedInSelfCheckoutService}
                              checked={record.canEditStore}
                              onChange={(checked) => {
                                const temp = JSON.parse(JSON.stringify(record));
                                temp.canEditStore = checked;
                                update(temp);
                              }}
                            />
                          ) : (
                            <Switch
                              disabled
                              size="small"
                              loading={loading && record.key === key}
                              // checked={record.allowedInSelfCheckoutService}
                              checked={record.canEditStore}
                            />
                          )}
                        </span>
                        <Typography.Text> {t('edit_store')} </Typography.Text>
                      </Col>
                    </Row>
                    <br />
                    <Row>
                      <Col span={10}>
                        <span>
                          {isAdmin || businessProfile?.canEditUsers ? (
                            <Switch
                              size="small"
                              loading={loading && record.key === key}
                              // checked={record.allowedInSelfCheckoutService}
                              checked={record.canEditUsers}
                              onChange={(checked) => {
                                const temp = JSON.parse(JSON.stringify(record));
                                temp.canEditUsers = checked;
                                update(temp);
                              }}
                            />
                          ) : (
                            <Switch
                              disabled
                              size="small"
                              loading={loading && record.key === key}
                              // checked={record.allowedInSelfCheckoutService}
                              checked={record.canEditUsers}
                            />
                          )}
                        </span>
                        <Typography.Text> {t('edit_users')} </Typography.Text>
                      </Col>
                      <Col span={8} offset={2}>
                        <span>
                          {isAdmin || businessProfile?.canEditUsers ? (
                            <Switch
                              size="small"
                              loading={loading && record.key === key}
                              // checked={record.allowedInSelfCheckoutService}
                              checked={record.canProvideCredit}
                              onChange={(checked) => {
                                const temp = JSON.parse(JSON.stringify(record));
                                temp.canProvideCredit = checked;
                                update(temp);
                              }}
                            />
                          ) : (
                            <Switch
                              disabled
                              size="small"
                              loading={loading && record.key === key}
                              // checked={record.allowedInSelfCheckoutService}
                              checked={record.canProvideCredit}
                            />
                          )}
                        </span>
                        <Typography.Text> {t('provide_credit')} </Typography.Text>
                      </Col>
                    </Row>
                    <br />
                    <Row>
                      <Col span={10}>
                        <span>
                          {isAdmin || businessProfile?.canEditUsers ? (
                            <Switch
                              size="small"
                              loading={loading && record.key === key}
                              // checked={record.allowedInSelfCheckoutService}
                              checked={record.canEditPayments}
                              onChange={(checked) => {
                                const temp = JSON.parse(JSON.stringify(record));
                                temp.canEditPayments = checked;
                                update(temp);
                              }}
                            />
                          ) : (
                            <Switch
                              disabled
                              size="small"
                              loading={loading && record.key === key}
                              // checked={record.allowedInSelfCheckoutService}
                              checked={record.canEditPayments}
                            />
                          )}
                        </span>
                        <Typography.Text> {t('edit_payment')} </Typography.Text>
                      </Col>
                    </Row>
                    <br />
                    <Row>
                      <Col span={10}>
                        <span>
                          {isAdmin || businessProfile?.canEditUsers ? (
                            <Switch
                              size="small"
                              loading={loading && record.key === key}
                              // checked={record.allowedInSelfCheckoutService}
                              checked={record.canViewAnalytics}
                              onChange={(checked) => {
                                const temp = JSON.parse(JSON.stringify(record));
                                temp.canViewAnalytics = checked;
                                update(temp);
                              }}
                            />
                          ) : (
                            <Switch
                              disabled
                              size="small"
                              loading={loading && record.key === key}
                              // checked={record.allowedInSelfCheckoutService}
                              checked={record.canViewAnalytics}
                            />
                          )}
                        </span>
                        <Typography.Text> {t('view_analytics')} </Typography.Text>
                      </Col>
                    </Row>
                  </Tabs.TabPane>
                  <Tabs.TabPane tab="Notifications" key="2">
                    <Row>
                      <Col span={10}>
                        <span>
                          {isAdmin || businessProfile?.canEditUsers ? (
                            <Switch
                              size="small"
                              loading={loading && record.key === key}
                              checked={record.forceSMS}
                              onChange={(checked) => {
                                const temp = JSON.parse(JSON.stringify(record));
                                temp.forceSMS = checked;
                                update(temp);
                              }}
                            />
                          ) : (
                            <Switch
                              disabled
                              size="small"
                              loading={loading && record.key === key}
                              checked={record.forceSMS}
                              onChange={onChange()}
                            />
                          )}
                        </span>
                        <Typography.Text> {t('force_sms')} </Typography.Text>
                      </Col>
                      <Col span={8} offset={2}>
                        <span>
                          {isAdmin || businessProfile?.canEditUsers ? (
                            <Switch
                              size="small"
                              loading={loading && record.key === key}
                              checked={record.getDeliveryNotifications}
                              onChange={(checked) => {
                                const temp = JSON.parse(JSON.stringify(record));
                                temp.getDeliveryNotifications = checked;
                                update(temp);
                              }}
                            />
                          ) : (
                            <Switch
                              disabled
                              size="small"
                              loading={loading && record.key === key}
                              checked={record.getDeliveryNotifications}
                            />
                          )}
                        </span>
                        <Typography.Text>
                          {' '}
                          {t('get_delivery_notifications')}{' '}
                        </Typography.Text>
                      </Col>
                    </Row>
                    <br />
                    <Row>
                      <Col span={10}>
                        <span>
                          {(isAdmin || businessProfile?.canEditUsers) && record.isActive ? (
                            <Switch
                              size="small"
                              loading={loading && record.key === key}
                              // checked={record.allowedInDeliService}
                              checked={record.getNotifications}
                              onChange={(checked) => {
                                const temp = JSON.parse(JSON.stringify(record));
                                temp.getNotifications = checked;
                                update(temp);
                              }}
                            />
                          ) : (
                            <Switch
                              disabled
                              size="small"
                              loading={loading && record.key === key}
                              // checked={record.allowedInDeliService}
                              checked={record.getNotifications}
                            />
                          )}
                        </span>
                        <Typography.Text> {t('get_notifications')} </Typography.Text>
                      </Col>
                      <Col span={8} offset={2}>
                        <span>
                          {(isAdmin || businessProfile?.canEditUsers) && record.isActive ? (
                            <Switch
                              size="small"
                              loading={loading && record.key === key}
                              // checked={record.allowedInRegularService}
                              checked={record.getOrderNotifications}
                              onChange={(checked) => {
                                const temp = JSON.parse(JSON.stringify(record));
                                temp.getOrderNotifications = checked;
                                update(temp);
                              }}
                            />
                          ) : (
                            <Switch
                              disabled
                              size="small"
                              loading={loading && record.key === key}
                              // checked={record.allowedInRegularService}
                              checked={record.getOrderNotifications}
                            />
                          )}
                        </span>
                        <Typography.Text>
                          {' '}
                          {t('get_order_notifications')}{' '}
                        </Typography.Text>
                      </Col>
                    </Row>
                    <br />
                    <Row>
                      <Col span={10}>
                        <span>
                          {(isAdmin || businessProfile?.canEditUsers) && record.isActive ? (
                            <Switch
                              size="small"
                              loading={loading && record.key === key}
                              checked={record.getPickUpNotifications}
                              onChange={(checked) => {
                                const temp = JSON.parse(JSON.stringify(record));
                                temp.getPickUpNotifications = checked;
                                update(temp);
                              }}
                            />
                          ) : (
                            <Switch
                              disabled
                              size="small"
                              loading={loading && record.key === key}
                              checked={record.getPickUpNotifications}
                            />
                          )}
                        </span>
                        <Typography.Text>
                          {' '}
                          {t('get_pickup_notifications')}{' '}
                        </Typography.Text>
                      </Col>
                      <Col span={8} offset={2}>
                        <span>
                          {(isAdmin || businessProfile?.canEditUsers) && record.isActive ? (
                            <Switch
                              size="small"
                              loading={loading && record.key === key}
                              checked={record.getSensitiveFinanceNotifications}
                              onChange={(checked) => {
                                const temp = JSON.parse(JSON.stringify(record));
                                temp.getSensitiveFinanceNotifications = checked;
                                update(temp);
                              }}
                            />
                          ) : (
                            <Switch
                              disabled
                              size="small"
                              loading={loading && record.key === key}
                              checked={record.getSensitiveFinanceNotifications}
                            />
                          )}
                        </span>
                        <Typography.Text>
                          {' '}
                          {t('get_sensitive_finance_notifications')}{' '}
                        </Typography.Text>
                      </Col>
                    </Row>
                    <br />
                    <Row>
                      <Col span={10}>
                        <span>
                          {(isAdmin || businessProfile?.canEditUsers) && record.isActive ? (
                            <Switch
                              size="small"
                              loading={loading && record.key === key}
                              // checked={record.allowedInDeliService}
                              checked={record.getSensitiveNotifications}
                              onChange={(checked) => {
                                const temp = JSON.parse(JSON.stringify(record));
                                temp.getSensitiveNotifications = checked;
                                update(temp);
                              }}
                            />
                          ) : (
                            <Switch
                              disabled
                              size="small"
                              loading={loading && record.key === key}
                              // checked={record.allowedInDeliService}
                              checked={record.getSensitiveNotifications}
                            />
                          )}
                        </span>
                        <Typography.Text>
                          {' '}
                          {t('get_sensitive_notifications')}{' '}
                        </Typography.Text>
                      </Col>
                    </Row>
                  </Tabs.TabPane>
                  <Tabs.TabPane tab="Roles" key="3">
                    <Row>
                      <Col span={10}>
                        <List>
                          <div>
                            {_.map(record.roles, (b) => (
                              <List.Item.Meta key={b.dataIndex} title={b} />
                            ))}
                          </div>
                        </List>
                      </Col>
                    </Row>
                  </Tabs.TabPane>
                </Tabs>
              </div>
            ),
            rowExpandable: (record) => record,
          }}
        />
        {openPerformanceDrawer ? <EmployeePerformance /> : null}
        {managerCode && (
          <Modal
            title={
              <div className="flex">
                <div>{t('manager_code')} - </div>
                <Typography.Text
                  copyable
                  className="ml-1"
                  style={{ marginTop: 2 }}
                >
                  {managerCode.code}
                </Typography.Text>
              </div>
            }
            open={managerCode}
            onClose={() => setManagerCode(null)}
            footer={[
              <Button
                key="close"
                type="primary"
                onClick={() => {
                  setManagerCode(null);
                }}
              >
                {t('close')}
              </Button>,
              <Button
                key="print"
                type="primary"
                onClick={() => {
                  const printContents = document.getElementById('divtoprint').innerHTML;
                  const pop = window.open();
                  pop.document.body.innerHTML = printContents;
                  pop.print();
                }}
              >
                {t('print')}
              </Button>
            ]}
          >
            <div
              id="divtoprint"
              className="mx-auto"
              style={{
                display: 'table'
              }}
            >
              <div style={{
                width: 210,
                textAlign: 'center',
                fontWeight: 'bold',
                padding: 0,
                margin: 0
              }}
              >
                {managerCode.name}
              </div>
              <Barcode
                width={2}
                value={managerCode.code}
                format="EAN13"
                displayValue={false}
              />
            </div>
          </Modal>
        )}
      </>
    )
  );
}
export default Team;
