import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Drawer,
  Typography,
  Switch,
} from 'antd';
import { useRecoilState, useRecoilValue } from 'recoil';
import { createDefaultPaymentOptionsAtom, businessAtom, reloadPaymentOptionsAtom } from '../../../../atoms/Atoms';
import api from '../../../../api/api';

function CreateDefaultPaymentOptions() {
  const { t } = useTranslation();
  const [createDefaults, setCreateDefaults] = useRecoilState(createDefaultPaymentOptionsAtom);
  const business = useRecoilValue(businessAtom);
  const [reload, setReload] = useRecoilState(reloadPaymentOptionsAtom);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});

  function create() {
    setLoading(true);
    api
      .post('admin/create/paymentoptions/default', {
        ...data,
        businessId: business?.id
      })
      .then((response) => {
        setLoading(false);
        if (response && response.data.error) {
          console.error('Error Message: ', response.data.error);
        } else {
          setCreateDefaults(false);
        }
        setReload(!reload);
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  }

  useEffect(() => {
    if (createDefaults) {
      setData({});
    }
  }, [createDefaults]);

  return (
    <Drawer
      title={t('create_defaults')}
      placement="right"
      closable={false}
      onClose={() => setCreateDefaults(false)}
      open={createDefaults}
      width={300}
    >
      <>
        <div className="mb-2">
          <Switch
            size="small"
            checked={data.webfrontCash}
            onChange={(checked) => {
              setData({
                ...data,
                webfrontCash: checked
              });
            }}
          />
          <Typography.Text> {t('cash')} ({t('webfront')}) </Typography.Text>
        </div>
        <div className="mb-2">
          <Switch
            size="small"
            checked={data.webfrontPayPal}
            onChange={(checked) => {
              setData({
                ...data,
                webfrontPayPal: checked
              });
            }}
          />
          <Typography.Text> PayPal ({t('webfront')}) </Typography.Text>
        </div>
        <div className="mb-2">
          <Switch
            size="small"
            checked={data.webfrontPayPalBillingAgreement}
            onChange={(checked) => {
              setData({
                ...data,
                webfrontPayPalBillingAgreement: checked
              });
            }}
          />
          <Typography.Text> {t('paypal_billing')} ({t('webfront')}) </Typography.Text>
        </div>
        <div className="mb-2">
          <Switch
            size="small"
            checked={data.webfrontAth}
            onChange={(checked) => {
              setData({
                ...data,
                webfrontAth: checked
              });
            }}
          />
          <Typography.Text> {t('ath')} ({t('webfront')}) </Typography.Text>
        </div>
        <div className="mb-2">
          <Switch
            size="small"
            checked={data.webfrontEbt}
            onChange={(checked) => {
              setData({
                ...data,
                webfrontEbt: checked
              });
            }}
          />
          <Typography.Text> EBT ({t('webfront')}) </Typography.Text>
        </div>
        <div className="mb-2">
          <Switch
            size="small"
            checked={data.webfrontTripleS}
            onChange={(checked) => {
              setData({
                ...data,
                webfrontTripleS: checked
              });
            }}
          />
          <Typography.Text> Triple-S ({t('webfront')}) </Typography.Text>
        </div>
        <div className="mb-2">
          <Switch
            size="small"
            checked={data.webfrontHumana}
            onChange={(checked) => {
              setData({
                ...data,
                webfrontHumana: checked
              });
            }}
          />
          <Typography.Text> Humana ({t('webfront')}) </Typography.Text>
        </div>
        <div className="mb-2">
          <Switch
            size="small"
            checked={data.webfrontMcs}
            onChange={(checked) => {
              setData({
                ...data,
                webfrontMcs: checked
              });
            }}
          />
          <Typography.Text> MCS ({t('webfront')}) </Typography.Text>
        </div>
        <div className="mb-2">
          <Switch
            size="small"
            checked={data.webfrontMmm}
            onChange={(checked) => {
              setData({
                ...data,
                webfrontMmm: checked
              });
            }}
          />
          <Typography.Text> MMM ({t('webfront')}) </Typography.Text>
        </div>
        <div className="mb-2">
          <Switch
            size="small"
            checked={data.posTerminalCash}
            onChange={(checked) => {
              setData({
                ...data,
                posTerminalCash: checked
              });
            }}
          />
          <Typography.Text> {t('terminal_cash')} (POS) </Typography.Text>
        </div>
        <div className="mb-2">
          <Switch
            size="small"
            checked={data.posTerminalCashOffline}
            onChange={(checked) => {
              setData({
                ...data,
                posTerminalCashOffline: checked
              });
            }}
          />
          <Typography.Text> {t('terminal_cash_offline')} (POS) </Typography.Text>
        </div>
        <div className="mb-2">
          <Switch
            size="small"
            checked={data.posTerminalCreditDebit}
            onChange={(checked) => {
              setData({
                ...data,
                posTerminalCreditDebit: checked
              });
            }}
          />
          <Typography.Text> {t('terminal_credit_debit')} (POS) </Typography.Text>
        </div>
        <div className="mb-2">
          <Switch
            size="small"
            checked={data.posTerminalEbt}
            onChange={(checked) => {
              setData({
                ...data,
                posTerminalEbt: checked
              });
            }}
          />
          <Typography.Text> {t('terminal_ebt')} (POS) </Typography.Text>
        </div>
        <div className="mb-2">
          <Switch
            size="small"
            checked={data.posTerminalEbtCash}
            onChange={(checked) => {
              setData({
                ...data,
                posTerminalEbtCash: checked
              });
            }}
          />
          <Typography.Text> {t('terminal_ebtcash')} (POS) </Typography.Text>
        </div>
        <div className="mb-2">
          <Switch
            size="small"
            checked={data.posTerminalFondo}
            onChange={(checked) => {
              setData({
                ...data,
                posTerminalFondo: checked
              });
            }}
          />
          <Typography.Text> {t('terminal_fondo')} (POS) </Typography.Text>
        </div>
        <div className="mb-2">
          <Switch
            size="small"
            checked={data.posTerminalUnica}
            onChange={(checked) => {
              setData({
                ...data,
                posTerminalUnica: checked
              });
            }}
          />
          <Typography.Text> {t('terminal_unica')} (POS) </Typography.Text>
        </div>
        <div className="mb-2">
          <Switch
            size="small"
            checked={data.selfCheckoutTerminalCreditDebit}
            onChange={(checked) => {
              setData({
                ...data,
                selfCheckoutTerminalCreditDebit: checked
              });
            }}
          />
          <Typography.Text> {t('terminal_credit_debit')} (Self-Checkout) </Typography.Text>
        </div>
        <div className="mb-2">
          <Switch
            size="small"
            checked={data.selfCheckoutTerminalEbt}
            onChange={(checked) => {
              setData({
                ...data,
                selfCheckoutTerminalEbt: checked
              });
            }}
          />
          <Typography.Text> {t('terminal_ebt')} (Self-Checkout) </Typography.Text>
        </div>
        <button
          className="w-full md:w-1/2 flex items-center justify-center text-center text-xs transition delay-150 duration-300 ease-in-out bg-blue-500 hover:bg-blue-600 text-white font-semibold px-6 py-3 rounded-full"
          type="submit"
          size="small"
          onClick={(e) => {
            create();
          }}
          disabled={loading}
          loading={loading}
        >
          {' '}
          {loading && (
            <div>
              <svg
                className="animate-spin -ml-1 mr-3 h-4 w-4 text-white"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                />
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                />
              </svg>
            </div>
          )}
          {t('create')}
        </button>
      </>
    </Drawer>
  );
}

export default CreateDefaultPaymentOptions;
