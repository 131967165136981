import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import _ from 'lodash';
import { useJsonToCsv } from 'react-json-csv';
import {
  Badge,
  Button,
  Col,
  Row,
  Table,
  Statistic,
  Typography,
  Space,
  Popover,
} from 'antd';
import { SearchOutlined, CloudDownloadOutlined } from '@ant-design/icons';
import { useRecoilValue, useRecoilValueLoadable } from 'recoil';
import {
  todayLossAnalyticsSelector,
  todayLossOosSelector,
} from '../../../../atoms/Selectors';
import { timezoneAtom } from '../../../../atoms/Atoms';
import {
  calculateContribution
} from '../../../utils/functions';

function TodayLossBody() {
  const { t } = useTranslation();
  const [current, setCurrent] = useState(1);
  const { saveAsCsv } = useJsonToCsv();
  const timezone = useRecoilValue(timezoneAtom);
  const [rows, setRows] = useState(10);
  const oos = useRecoilValueLoadable(todayLossOosSelector);
  const totals = useRecoilValueLoadable(todayLossAnalyticsSelector);

  function mapGroups(data) {
    return _.map(data, (p, index) => ({
      key: index,
      count: p.length,
      amount: p.amount,
      price: p[0].price,
      upc: p[0].upc,
      department: p[0].department,
      code: p.code,
      name: `${p[0].brand} ${p[0].name} ${p[0].description}`,
      createdAt: moment(`${p.createdAt}+0000`)
        .tz(timezone)
        .format('MMMM Do YYYY, h:mm:ss a'),
      orders: _.map(p, (v) => ({
        key: v.upc,
        ...v,
      })),
    }));
  }

  const listFields = {
    count: t('count'),
    upc: 'UPC',
    price: t('price'),
    name: t('name'),
    department: t('department'),
  };

  return (
    <div className="p-4 border bg-gray-100 rounded-lg mb-4">
      <Row align="middle" gutter={[12, 12]} justify="space-between">
        <Col>
          <Typography.Title level={5}>{t('out_of_stock')}</Typography.Title>
          <Typography.Text type="secondary">
            {t('out_of_stock_today_desc')}
          </Typography.Text>
        </Col>
        <Col>
          {totals.state === 'hasValue' && oos.state === 'hasValue' ? (
            <div className="flex flex-row">
              {' '}
              <div className="mr-10">
                <Statistic
                  title={t('orders')}
                  value={`${
                    oos.contents &&
                    oos.contents.data.data &&
                    oos.contents.data.data.length > 0
                      ? oos.contents.data.data[0].orderCount
                      : 0
                  }`}
                />
              </div>
              <div className="mr-10">
                <Statistic
                  title={t('items')}
                  value={`${
                    oos.contents && oos.contents.data.data.length > 0
                      ? oos.contents.data.data[0].itemCount
                      : 0
                  }`}
                />
              </div>
              <div className="mr-10">
                <Statistic
                  title={`${t('loss')} $`}
                  value={`$${
                    oos.contents && oos.contents.data.data.length > 0
                      ? oos.contents.data.data[0].total.toFixed(2)
                      : 0
                  }`}
                />
              </div>
              <div className="mr-10">
                {' '}
                <Statistic
                  title={`${t('loss')} %`}
                  value={
                    oos.contents && totals.contents
                      ? calculateContribution(totals?.contents?.data?.data[0]?.originalTotal,
                        oos?.contents?.data?.data[0]?.total)
                      : 0
                  }
                />
              </div>
            </div>
          ) : null}
        </Col>
        <Col>
          <Button
            size="small"
            icon={<CloudDownloadOutlined />}
            onClick={() => {
              const data = mapGroups(oos.contents.data.data[0].dtoGrouped);
              saveAsCsv({
                data,
                fields: listFields,
                filename: `oos_${new Date().toLocaleDateString()}`,
              });
            }}
          >
            {t('export_list')}
          </Button>
        </Col>
      </Row>
      <Row>
        <Col xs={24}>
          <Table
            size="small"
            loading={oos.state === 'loading'}
            bordered
            scroll={{ x: 500 }}
            pagination={{
              current,
              hideOnSinglePage: true,
              showSizeChanger: true,
              size: 'small',
              pageSize: rows,
              total:
                oos.state === 'hasValue' &&
                oos.contents &&
                oos.contents.data.data.length > 1 &&
                oos.contents.data.data[0].dtoGrouped &&
                oos.contents.data.data[0].dtoGrouped.length,
              pageSizeOptions: [10, 20, 30, 40, 50],
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} ${t('out_of')} ${total} ${t('items')}`,
              onShowSizeChange: (page, size) => {
                setRows(size);
              },
              onChange: (page) => {
                setCurrent(page);
              },
            }}
            columns={[
              {
                title: t('count'),
                dataIndex: 'count',
                key: 'count',
                align: 'center',
                className: 'text-xs',
                render: (text) => <span>{text}</span>,
                sorter: (a, b) => a.count - b.count,
              },
              {
                title: t('price'),
                dataIndex: 'price',
                key: 'price',
                align: 'center',
                className: 'text-xs',
                render: (text) => <span>${text.toFixed(2)}</span>,
                sorter: (a, b) => a.price - b.price,
              },
              {
                title: 'UPC',
                dataIndex: 'upc',
                key: 'upc',
                align: 'left',
                className: 'text-xs',
                render: (text) => (
                  <span>
                    {' '}
                    <Typography.Text copyable>{text}</Typography.Text>
                  </span>
                ),
              },
              {
                title: t('name'),
                dataIndex: 'name',
                key: 'name',
                align: 'left',
                className: 'text-xs font-medium',
                render: (text) => <span>{text}</span>,
              },
              {
                title: t('department'),
                dataIndex: 'department',
                key: 'department',
                align: 'left',
                className: 'text-xs font-medium',
                render: (text) => <span>{text}</span>,
              },
            ]}
            dataSource={
              oos.state === 'hasValue' && oos.contents.data.data[0]
                ? mapGroups(oos.contents.data.data[0].dtoGrouped)
                : null
            }
            expandable={{
              expandRowByClick: true,
              expandedRowRender: (record) => (
                <div className="p-4">
                  <Table
                    title={() => (
                      <div className="flex items-center justify-between">
                        <div>
                          <Badge
                            className="site-badge-count-109"
                            count={record.orders.length}
                            style={{ backgroundColor: '#52c41a' }}
                          />
                          <span className="font-light uppercase text-xs ml-2">
                            {t('orders')}
                          </span>
                        </div>
                      </div>
                    )}
                    bordered
                    pagination={false}
                    columns={[
                      {
                        title: t('amount'),
                        dataIndex: 'amount',
                        key: 'amount',
                        align: 'center',
                        className: 'text-xs font-medium',
                        render: (text) => (
                          <span>
                            {text
                              .toFixed()
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                          </span>
                        ),
                      },
                      {
                        title: t('order'),
                        dataIndex: 'code',
                        key: 'code',
                        align: 'center',
                        className: 'text-xs',
                        render: (text) => (
                          <Typography.Text copyable>{text}</Typography.Text>
                        ),
                      },
                      {
                        title: t('ticket_total'),
                        dataIndex: 'total',
                        key: 'total',
                        align: 'center',
                        className: 'text-xs font-medium',
                        render: (text) => (
                          <span>
                            $
                            {text
                              .toFixed(2)
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                          </span>
                        ),
                      },
                      {
                        title: t('percentage'),
                        dataIndex: 'pct',
                        key: 'pct',
                        align: 'center',
                        className: 'text-xs font-medium',
                        render: (text) => (
                          <span>
                            {text
                              .toFixed(1)
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            %
                          </span>
                        ),
                      },
                      {
                        title: t('date'),
                        dataIndex: 'date',
                        key: 'date',
                        align: 'center',
                        className: 'text-xs',
                        render: (text) => (
                          <span>
                            {moment(`${text}+0000`)
                              .tz(timezone)
                              .format('MMMM Do YYYY, h:mm:ss a')}
                          </span>
                        ),
                      },
                      {
                        title: '',
                        align: 'center',
                        className: 'text-xs',
                        render: (text, row) => (
                          <Space>
                            <Popover
                              title={t('details')}
                              placement="left"
                              content={
                                <div>
                                  <div className="text-xs">
                                    {t('code')}:{' '}
                                    <span className="font-bold">
                                      {row.code}
                                    </span>
                                  </div>
                                  <div className="text-xs">
                                    {t('Date')}:{' '}
                                    <span className="font-bold">
                                      {moment(`${row.date}+0000`)
                                        .tz(timezone)
                                        .format('MMMM Do YYYY, h:mm:ss a')}
                                    </span>
                                  </div>
                                  <div className="text-xs">
                                    {t('order_total')}: $
                                    <span className="font-bold">
                                      {row.total}
                                    </span>
                                  </div>
                                </div>
                              }
                            >
                              <Button
                                shape="circle"
                                size="small"
                                icon={<SearchOutlined />}
                                onClick={() => console.log(row)}
                              />
                            </Popover>
                          </Space>
                        ),
                      },
                    ]}
                    dataSource={record.orders}
                  />
                </div>
              ),
              rowExpandable: (record) => record.orders.length > 0,
            }}
          />
        </Col>
      </Row>
    </div>
  );
}

export default TodayLossBody;
