import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import {
  useRecoilValue,
  useRecoilState,
  useRecoilValueLoadable,
  useSetRecoilState
} from 'recoil';
import { Row, Button, Col, Card } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import moment from 'moment';
import BoardTeam from './shared-components/BoardTeam';
import EditBoardModal from './shared-components/EditBoardModal';
import { configSelector } from '../../../atoms/Selectors';
import {
  timezoneAtom,
  selectedTicketBoardAtom,
  editBoardAtom,
  refreshBoardsAtom,
  selectedTicketAtom } from '../../../atoms/Atoms';
import api from '../../../api/api';

function TicketBoards() {
  const config = useRecoilValueLoadable(configSelector);
  const timezone = useRecoilValue(timezoneAtom);
  const setEditBoard = useSetRecoilState(editBoardAtom);
  const setSelectedBoard = useSetRecoilState(selectedTicketBoardAtom);
  const setSelectedTicket = useSetRecoilState(selectedTicketAtom);
  const [boards, setBoards] = useState([]);
  const [refreshBoards, setRefreshBoards] = useRecoilState(refreshBoardsAtom);
  const { t } = useTranslation();

  const boardClick = function (clickedBoard) {
    setSelectedBoard(clickedBoard);
  };

  const getBoards = function () {
    api.get('ticketboards/boards')
      .then((response) => {
        if (response.data.success) {
          setBoards(response.data.data.results);
        } else {
          console.error(response.data.error);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    setSelectedBoard(null);
    setSelectedTicket(null);
    getBoards();
  }, []);

  useEffect(() => {
    if (refreshBoards) {
      setRefreshBoards(false);
      getBoards();
    }
  }, [refreshBoards]);

  return (
    <>
      <PageHeader
        title={t('ticket_boards')}
        extra={[
          <Button
            type="primary"
            size="small"
            key="createBoard"
            onClick={() => {
              setEditBoard({});
            }}
          >
            {t('create')}
          </Button>
        ]}
      />
      <Row>
        {boards && _.map(boards, (board) => (
          <Col
            key={board.id}
            className="board-spacing"
            xs={12}
            sm={12}
            md={6}
            lg={4}
          >
            <Link to={`/ticketboard/${board.id}`}>
              <Card
                hoverable
                title={board.name}
                onClick={() => { boardClick(board); }}
                cover={board.imageUrl && (<img alt={board.name} src={board.imageUrl} />)}
                headStyle={{ backgroundColor: `#${board.colorHex}`, border: 0 }}
              >
                <BoardTeam
                  defaultMaleAvatar={
                    config &&
                    config.contents &&
                    config.contents.data &&
                    config.contents.data.defaultMaleAvatar
                  }
                  defaultFemaleAvatar={
                    config &&
                    config.contents &&
                    config.contents.data &&
                    config.contents.data.defaultFemaleAvatar
                  }
                  primaryColor={
                    config &&
                    config.contents &&
                    config.contents.data &&
                    config.contents.data.primaryColor
                  }
                  secondaryColor={
                    config &&
                    config.contents &&
                    config.contents.data &&
                    config.contents.data.secondaryColor
                  }
                  members={board.members}
                />
                <br />
                <small>
                  <strong className="bold">{t('created_on')}:</strong>
                  {` ${moment(`${board.createdAt}+0000`)
                    .tz(timezone)
                    .format('MMM dd YYYY, h:mm:ss a')}`}
                </small>
              </Card>
            </Link>
          </Col>
        ))}
      </Row>
      <EditBoardModal />
    </>
  );
}

export default TicketBoards;
