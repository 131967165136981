import React from 'react';
import { useTranslation } from 'react-i18next';
import { Tabs } from 'antd';
import Sales from './insights/Sales';
import Customers from './insights/Customers';
import TodaySales from './insights/TodaySales';

function Insights() {
  const { t } = useTranslation();
  const width = window.innerWidth;

  return (
    <div>
      <Tabs defaultActiveKey="0" className={width < 1024 ? 'hidden' : null}>
        <Tabs.TabPane tab={t('day')} key="0">
          <TodaySales />
        </Tabs.TabPane>
        <Tabs.TabPane tab={t('month')} key="1">
          <Sales />
        </Tabs.TabPane>
        <Tabs.TabPane tab={t('customers')} key="2">
          <Customers />
        </Tabs.TabPane>
      </Tabs>
      <div className={width < 1024 ? null : 'hidden'}>
        <TodaySales />
      </div>
    </div>
  );
}

export default Insights;
