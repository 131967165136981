import React, { useEffect } from 'react';
import { Spin } from 'antd';
import { useHistory } from 'react-router-dom';
import { useRecoilValueLoadable, useRecoilState } from 'recoil';
import { profileAtom, businessAtom } from '../../../atoms/Atoms';
import { businessesSelector } from '../../../atoms/Selectors';

function RedirectHandler() {
  const history = useHistory();
  const businesses = useRecoilValueLoadable(businessesSelector);
  const profile = useRecoilValueLoadable(profileAtom);
  const [business, setBusiness] = useRecoilState(businessAtom);
  const isAdmin =
    profile?.contents && profile?.contents.roles.includes('SuperAdmin');
  const isTopAnalytics =
    isAdmin ||
    (profile?.contents &&
      (profile?.contents.roles.includes('TopAnalytics')));
  const isFinance =
      isAdmin ||
      (profile?.contents &&
        (profile?.contents.roles.includes('Finance')));
  const isStoreManager =
    isAdmin ||
    (profile?.contents &&
      (profile?.contents.roles.includes('StoreManager') ||
        profile?.contents.roles.includes('StoreAdmin')));
  const isDeliveryProvider =
    isAdmin ||
    (profile?.contents &&
      profile?.contents?.roles?.includes('DeliveryProvider'));
  const handleRedirect = function () {
    if (profile) {
      if (isAdmin || isTopAnalytics) {
        history.push('/dashboard');
      } else if (business && (isStoreManager || isFinance)) {
        history.push('/storedashboard');
      } else if (business && isDeliveryProvider) {
        history.push('/deliveries');
      } else if (business && business.id !== -2 && business.id !== -1) {
        history.push('/orders');
      } else if (!business && businesses?.contents?.data?.data?.results.length > 0) {
        setBusiness(businesses?.contents?.data?.data?.results[0]);
      }
    }
  };

  useEffect(() => {
    handleRedirect();
  }, [profile, business, businesses]);

  return (
    <Spin
      tip="Loading..."
      size="large"
      className="mt-40"
      style={{ height: '100%', width: '100%' }}
    />
  );
}

export default RedirectHandler;
