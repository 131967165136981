import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import {
  Drawer,
  Input,
  Upload,
  Avatar,
  notification,
  Tooltip,
  Button,
  Image
} from 'antd';
import { Comment } from '@ant-design/compatible';
import {
  InboxOutlined,
} from '@ant-design/icons';
import { useRecoilState, useRecoilValue, useSetRecoilState, } from 'recoil';
import {
  editBookingLocationAtom,
  selectedBookingLocationAtom,
  reloadBookingLocationAtom
} from '../../../../../atoms/Atoms';
import Grocefy from '../../../../../assets/images/grocefyLogoAlone.png';
import bookingApi from '../../../../../api/bookingApi';
import api from '../../../../../api/api';
import { isEmpty } from '../../../../utils/functions';

function EditLocation() {
  const { t } = useTranslation();
  const { Dragger } = Upload;
  const { TextArea } = Input;
  const setReload = useSetRecoilState(reloadBookingLocationAtom);
  const selectedLocation = useRecoilValue(selectedBookingLocationAtom);
  const [editLocation, setEditLocation] = useRecoilState(editBookingLocationAtom);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [imageData, setImageData] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const imageDraggerProps = {
    name: 'file',
    accept: 'image/*',
    multiple: false,
    showUploadList: false,
    beforeUpload: (file) => {
      setImageData(file);
      return false;
    }
  };

  async function uploadImage(tempImageData) {
    const file = tempImageData;
    const formdata = new FormData();
    formdata.append('file', file);
    setIsSubmitting(true);
    const response = await api
      .post('utility/bookinglocations/uploadfile', formdata);
    setIsSubmitting(false);
    if (response.data.success) {
      return response.data.data;
    }
    console.error(response.data.error);
    return null;
  }

  function showMessage(message) {
    notification.open({
      message: '',
      description: (
        <Comment
          author={<span>Grocefy</span>}
          avatar={<Avatar src={Grocefy} alt="grocefy" />}
          content={
            <p className="text-sm">
              {message}
            </p>
          }
          datetime={
            <Tooltip title={moment().format('YYYY-MM-DD HH:mm:ss')}>
              <span>{moment().fromNow()}</span>
            </Tooltip>
          }
        />
      ),
    });
  }

  function submit() {
    setLoading(true);
    if (selectedLocation.id) {
      bookingApi.put('locations', data)
        .then((response) => {
          setLoading(false);
          if (response.data.error) {
            showMessage(response.data.error);
          } else {
            setReload(true);
            setEditLocation(false);
          }
        })
        .catch((error) => {
          setLoading(false);
          showMessage(error.message);
        });
    } else {
      bookingApi.post('locations', data)
        .then((response) => {
          setLoading(false);
          if (response.data.error) {
            showMessage(response.data.error);
          } else {
            setReload(true);
            setEditLocation(false);
          }
        })
        .catch((error) => {
          setLoading(false);
          showMessage(error.message);
        });
    }
  }

  function remove() {
    setLoading(true);
    bookingApi.delete(`locations/${selectedLocation?.id}`)
      .then((response) => {
        setLoading(false);
        if (response.data.error) {
          showMessage(response.data.error);
        } else {
          setReload(true);
          setEditLocation(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        showMessage(error.message);
      });
  }

  useEffect(async () => {
    if (imageData) {
      const url = await uploadImage(imageData);
      if (url) {
        setImageData(null);
        setData({
          ...data,
          imageUrl: url
        });
      }
    }
  }, [imageData]);

  useEffect(() => {
    if (selectedLocation && editLocation) {
      if (selectedLocation.id) {
        setData(selectedLocation);
      } else {
        setData({
          imageUrl: '',
          description: '',
          descriptionEs: '',
          name: '',
          nameEs: '',
          nameEn: '',
        });
      }
    } else if (!editLocation) {
      setData(null);
    }
  }, [selectedLocation, editLocation]);

  return (
    <Drawer
      title={selectedLocation?.id ? t('edit_location') : t('create_location')}
      placement="right"
      closable={!loading}
      onClose={() => setEditLocation(false)}
      open={editLocation}
      width={300}
    >
      <div hidden={!data?.id}>
        <div
          className="float-right"
        >
          <Button
            type="primary"
            danger
            loading={loading}
            onClick={() => { remove(); }}
          >
            {t('delete')}
          </Button>
        </div>
        <br />
      </div>
      <div
        className="mb-4"
      >
        <strong>{t('internal_name')}</strong>
        <Input
          disabled={loading}
          className="w-full"
          value={data?.name}
          onChange={(obj) => {
            setData({
              ...data,
              name: obj.target.value
            });
          }}
        />
      </div>
      <div
        className="mb-4"
      >
        <strong>{t('name')} EN</strong>
        <Input
          disabled={loading}
          className="w-full"
          value={data?.nameEn}
          onChange={(obj) => {
            setData({
              ...data,
              nameEn: obj.target.value
            });
          }}
        />
      </div>
      <div
        className="mb-4"
      >
        <strong>{t('name')} ES</strong>
        <Input
          disabled={loading}
          className="w-full"
          value={data?.nameEs}
          onChange={(obj) => {
            setData({
              ...data,
              nameEs: obj.target.value
            });
          }}
        />
      </div>
      <div
        className="mb-4"
      >
        <strong>{t('description')} EN</strong>
        <TextArea
          rows={3}
          className="w-full"
          disabled={loading}
          value={data?.description}
          onChange={(obj) => {
            setData({
              ...data,
              description: obj.target.value
            });
          }}
        />
      </div>
      <div
        className="mb-4"
      >
        <strong>{t('description')} ES</strong>
        <TextArea
          rows={3}
          className="w-full"
          disabled={loading}
          value={data?.descriptionEs}
          onChange={(obj) => {
            setData({
              ...data,
              descriptionEs: obj.target.value
            });
          }}
        />
      </div>
      <div
        className="mb-4"
      >
        <strong>{t('image')}</strong>
        <Input
          className="w-full"
          disabled={loading}
          loading={isSubmitting}
          value={data?.imageUrl}
          onChange={(obj) => {
            setData({
              ...data,
              imageUrl: obj.target.value
            });
          }}
        />
        <Dragger {...imageDraggerProps}>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p
            className="ant-upload-text"
            style={{ margin: 10 }}
          >
            {t('click_or_drag_content')}
          </p>
        </Dragger>
        {data?.imageUrl && (
          <Image
            alt="locationimage"
            width={120}
            src={data?.imageUrl}
            className="cursor-pointer"
          />
        )}
      </div>
      <div
        className="float-right"
      >
        <Button
          type="primary"
          loading={loading}
          disabled={isEmpty(data?.name)}
          onClick={() => { submit(); }}
        >
          {data?.id ? t('update') : t('create')}
        </Button>
      </div>
    </Drawer>
  );
}
export default EditLocation;
