import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory } from 'react-router-dom';
import _ from 'lodash';
import {
  useRecoilValue,
  useRecoilState,
  useRecoilValueLoadable
} from 'recoil';
import {
  Button,
  Table,
  Avatar,
  Tooltip,
  Pagination,
  Input,
  notification,
  Divider,
  Collapse
} from 'antd';
import { Comment } from '@ant-design/compatible';
import { PageHeader } from '@ant-design/pro-layout';
import moment from 'moment';
import Grocefy from '../../../assets/images/grocefyLogoAlone.png';
import {
  timezoneAtom,
  selectedCampaignAtom,
  refreshCampaignsAtom,
  showEditCampaignDrawerAtom
} from '../../../atoms/Atoms';
import { businessesSelector, configSelector } from '../../../atoms/Selectors';
import EditProductCampaign from './shared-components/EditProductCampaign';
import api from '../../../api/api';

function ProductCampaign() {
  const history = useHistory();
  const { Panel } = Collapse;
  const config = useRecoilValueLoadable(configSelector);
  const businesses = useRecoilValueLoadable(businessesSelector);
  const timezone = useRecoilValue(timezoneAtom);
  const { campaignId } = useParams();
  const [showEditCampaignDrawer, setShowEditCampaignDrawer] =
    useRecoilState(showEditCampaignDrawerAtom);
  const [selectedCampaign, setSelectedCampaign] = useRecoilState(selectedCampaignAtom);
  const [refreshCampaigns, setRefreshCampaigns] = useRecoilState(refreshCampaignsAtom);
  const [loading, setLoading] = useState(false);
  const [loadingBusinesses, setLoadingBusinesses] = useState(false);
  const [loadingItems, setLoadingItems] = useState(false);
  const [selectedCampaignId, setSelectedCampaignId] = useState(null);
  const [activeCampaignBusinesses, setActiveCampaignBusinesses] = useState([]);
  const [campaignItems, setCampaignItems] = useState([]);
  const [campaignBusinesses, setCampaignBusinesses] = useState([]);
  const [searchItems, setSearchItems] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [lastSearch, setLastSearch] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const [size, setSize] = useState(20);
  const [totalItemsInSearch, setTotalItemsInSearch] = useState(0);
  const { t } = useTranslation();

  const showMessage = function (message) {
    notification.open({
      message: '',
      description: (
        <Comment
          author={<span>Grocefy</span>}
          avatar={<Avatar src={Grocefy} alt="grocefy" />}
          content={
            <p className="text-sm">
              {message}
            </p>
          }
          datetime={
            <Tooltip title={moment().format('YYYY-MM-DD HH:mm:ss')}>
              <span>{moment().fromNow()}</span>
            </Tooltip>
          }
        />
      ),
    });
  };

  const searchProducts = function (query, page) {
    setIsSearching(true);
    const payload = {
      Query: query,
      Page: page,
      Size: size
    };
    api
      .post(
        'businesses/catalog/search',
        payload
      )
      .then((response) => {
        if (response.data.success) {
          for (let i = 0; i < response.data.data.items.length; i++) {
            const index = _.findIndex(campaignItems, (b) => b.id
              === response.data.data.items[i].id);
            response.data.data.items[i].isAssigned = index >= 0;
          }
          setIsSearching(false);
          setTotalItemsInSearch(response.data.data.count);
          setSearchItems(response.data.data.items);
        } else {
          showMessage(response.data.error);
        }
      })
      .catch((error) => {
        setIsSearching(false);
        showMessage(error);
      });
  };

  const onTableChange = (page, pageSize) => {
    if (page - 1 !== currentPage) {
      setCurrentPage(page - 1);
      searchProducts(lastSearch, page - 1);
    } else {
      setSize(pageSize);
    }
  };

  const onSearch = async (value) => {
    setLastSearch(value);
    setCurrentPage(0);
    searchProducts(value, 0);
  };

  const getCampaignBusinesses = function () {
    if (selectedCampaignId && !loadingBusinesses) {
      setLoadingBusinesses(true);
      api.get(`marketing/campaign/businesses/${selectedCampaignId}`)
        .then((response) => {
          setLoadingBusinesses(false);
          if (response.data.success) {
            setActiveCampaignBusinesses(response.data.data.results);
          } else {
            showMessage(response.data.error);
          }
        })
        .catch((error) => {
          setLoadingBusinesses(false);
          console.error(error);
        });
    }
  };

  const getCampaignItems = function () {
    if (selectedCampaignId && !loadingItems) {
      setLoadingItems(true);
      api.get(`marketing/campaign/items/${selectedCampaignId}`)
        .then((response) => {
          setLoadingItems(false);
          if (response.data.success) {
            if (response.data.data) {
              setCampaignItems(response.data.data.results);
            }
          } else {
            showMessage(response.data.error);
          }
        })
        .catch((error) => {
          setLoadingItems(false);
          console.error(error);
        });
    }
  };

  const getCampaign = function () {
    setLoading(true);
    api.get('marketing/campaigns/all')
      .then((response) => {
        setLoading(false);
        if (response.data.success) {
          for (let i = 0; i < response.data.data.results.length; i++) {
            if (!response.data.data.results[i].businessId) {
              response.data.data.results[i].businessId = '';
            }
            let status = response.data.data.results[i].isActive ? 'Active' : 'Not Active';
            const startDate = moment(`${response.data.data.results[i].startDate}+0000`);
            const endDate = moment(`${response.data.data.results[i].endDate}+0000`);
            const currentDate = moment();
            if (currentDate >= startDate && currentDate <= endDate) {
              status = response.data.data.results[i].isActive ? 'Active' : 'Not Active';
            } else if (currentDate > endDate) {
              status = response.data.data.results[i].isActive ? 'Completed' : 'Passed';
            }
            response.data.data.results[i].status = status;
            response.data.data.results[i].startDateTime = moment(startDate).tz(timezone);
            response.data.data.results[i].endDateTime = moment(endDate).tz(timezone);
          }
          const [tempCampaign] = _.filter(response.data.data.results,
            (c) => c.id === selectedCampaignId);
          setSelectedCampaign(tempCampaign);
          getCampaignBusinesses();
          getCampaignItems();
        } else {
          showMessage(response.data.error);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  const assignBusiness = function (store) {
    const temp = JSON.parse(JSON.stringify(campaignBusinesses));
    const index = _.findIndex(temp, (b) => b.id === store.id);
    temp[index].loading = true;
    setCampaignBusinesses(temp);
    api.post(
      `marketing/campaign/business/${selectedCampaign.id}/${store.id}`,
      { }
    )
      .then((response) => {
        temp[index].loading = false;
        if (response.data.success) {
          temp[index].isAssigned = true;
        } else {
          showMessage(response.data.error);
        }
        const temp2 = JSON.parse(JSON.stringify(temp));
        setCampaignBusinesses(temp2);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const removeBusiness = function (store) {
    const temp = JSON.parse(JSON.stringify(campaignBusinesses));
    const index = _.findIndex(temp, (b) => b.id === store.id);
    if (index >= 0) {
      temp[index].loading = true;
    }
    setCampaignBusinesses(temp);
    api.delete(`marketing/campaign/business/${selectedCampaign.id}/${store.id}`)
      .then((response) => {
        temp[index].loading = false;
        if (response.data.success) {
          temp[index].isAssigned = false;
        } else {
          showMessage(response.data.error);
        }
        const temp2 = JSON.parse(JSON.stringify(temp));
        setCampaignBusinesses(temp2);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const removeItem = function (item) {
    const temp = JSON.parse(JSON.stringify(campaignItems));
    const index = _.findIndex(temp, (b) => b.id === item.id);
    if (index >= 0) {
      temp[index].loading = true;
    }
    setCampaignItems(temp);
    api.delete(`marketing/campaign/item/${selectedCampaign.id}/${item.id}`)
      .then((response) => {
        temp[index].loading = false;
        if (response.data.success) {
          const newItems = _.filter(temp, (i) => i.id !== temp[index].id);
          setCampaignItems(newItems);
          const tempSearchItems = JSON.parse(JSON.stringify(searchItems));
          for (let i = 0; i < tempSearchItems.length; i++) {
            const tempIndex = _.findIndex(newItems, (b) => b.id
              === tempSearchItems[i].id);
            tempSearchItems[i].isAssigned = tempIndex >= 0;
          }
          setSearchItems(tempSearchItems);
        } else {
          showMessage(response.data.error);
          const temp2 = JSON.parse(JSON.stringify(temp));
          setCampaignItems(temp2);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const addItem = function (item) {
    const temp = JSON.parse(JSON.stringify(searchItems));
    const index = _.findIndex(temp, (b) => b.id === item.id);
    if (index >= 0) {
      temp[index].loading = true;
    }
    setSearchItems(temp);
    api.post(
      `marketing/campaign/item/${selectedCampaign.id}/${item.id}`,
      { }
    )
      .then((response) => {
        temp[index].loading = false;
        if (response.data.success) {
          temp[index].isAssigned = true;
          setCampaignItems(response.data.data.results);
        } else {
          showMessage(response.data.error);
        }
        const temp2 = JSON.parse(JSON.stringify(temp));
        setSearchItems(temp2);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const deleteCampaign = function () {
    setLoading(true);
    api.delete(`marketing/campaign/${selectedCampaign.id}`)
      .then((response) => {
        setLoading(false);
        if (response.data.success) {
          setRefreshCampaigns(true);
          history.push('/productcampaigns');
        } else {
          showMessage(response.data.error);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  useEffect(() => {
    if (selectedCampaign) {
      setSelectedCampaignId(selectedCampaign.Id);
    } else {
      setSelectedCampaignId(campaignId);
    }
  }, [selectedCampaign]);

  useEffect(() => {
    if (selectedCampaignId) {
      if (!selectedCampaign || (selectedCampaign && selectedCampaignId !== selectedCampaign.id)) {
        getCampaign();
      }
    }
  }, [selectedCampaignId]);

  useEffect(() => {
    if (businesses && businesses.state === 'hasValue' && activeCampaignBusinesses) {
      const temp = JSON.parse(JSON.stringify(businesses.contents.data.data.results));
      for (let i = 0; i < activeCampaignBusinesses.length; i++) {
        const index = _.findIndex(temp, (b) => b.id === activeCampaignBusinesses[i].id);
        if (index >= 0) {
          temp[index].isAssigned = true;
        }
      }
      setCampaignBusinesses(temp);
    }
  }, [activeCampaignBusinesses, businesses]);

  return (
    <>
      <PageHeader
        onBack={() => window.history.back()}
        title={`Product Campaign / ${selectedCampaign ? selectedCampaign.title : ''}`}
        extra={[
          <Button
            type="primary"
            size="small"
            disabled={!selectedCampaign}
            key="edit"
            onClick={() => {
              setShowEditCampaignDrawer(true);
            }}
          >
            {t('edit')}
          </Button>,
          <Button
            type="primary"
            danger
            size="small"
            disabled={!selectedCampaign}
            key="delete"
            onClick={() => {
              deleteCampaign();
            }}
          >
            {t('delete')}
          </Button>,
          <Button
            size="small"
            hidden={!config?.contents?.data?.data?.productCampaignSite}
            key="preview"
            target="_blank"
            href={`${config?.contents?.data?.data?.productCampaignSite}/${selectedCampaign?.id}`}
          >
            {t('view')}
          </Button>
        ]}
      />
      {selectedCampaign && (
        <Collapse>
          <Panel header={t('businesses')} key="1">
            <Table
              size="small"
              bordered
              loading={loadingBusinesses}
              dataSource={campaignBusinesses}
              pagination={false}
              rowKey="id"
              columns={[
                {
                  title: t('name'),
                  align: 'center',
                  className: 'text-xs',
                  render: (row) => (
                    <span>{row.name}</span>
                  ),
                },
                {
                  title: '',
                  align: 'center',
                  className: 'text-xs',
                  render: (row) => (
                    <>
                      {!row.isAssigned && (
                        <Button
                          loading={row.loading}
                          size="small"
                          type="primary"
                          onClick={() => assignBusiness(row)}
                        >
                          {t('assign')}
                        </Button>
                      )}
                      {row.isAssigned && (
                        <Button
                          size="small"
                          type="primary"
                          danger
                          loading={row.loading}
                          onClick={() => removeBusiness(row)}
                        >
                          {t('delete')}
                        </Button>
                      )}
                    </>
                  ),
                },
              ]}
            />
          </Panel>
          <Panel header={t('items')} key="2">
            <>
              <Table
                size="small"
                bordered
                loading={loadingItems}
                dataSource={campaignItems}
                rowKey="id"
                columns={[
                  {
                    title: t('image'),
                    align: 'center',
                    className: 'text-xs',
                    render: (row) => (
                      <img
                        width={75}
                        src={row.itemImage}
                        alt={`${row.brand} ${row.name} ${row.description}`}
                      />
                    ),
                  },
                  {
                    title: 'GTIN',
                    align: 'center',
                    className: 'text-xs',
                    render: (row) => (
                      <span>{row.gtin}</span>
                    ),
                  },
                  {
                    title: t('brand'),
                    align: 'center',
                    className: 'text-xs',
                    render: (row) => (
                      <span>{row.brand}</span>
                    ),
                  },
                  {
                    title: t('name'),
                    align: 'center',
                    className: 'text-xs',
                    render: (row) => (
                      <span>{row.name}</span>
                    ),
                  },
                  {
                    title: t('description'),
                    align: 'center',
                    className: 'text-xs',
                    render: (row) => (
                      <span>{row.description}</span>
                    ),
                  },
                  {
                    title: '',
                    align: 'center',
                    className: 'text-xs',
                    render: (row) => (
                      <Button
                        size="small"
                        type="primary"
                        danger
                        loading={row.loading}
                        onClick={() => removeItem(row)}
                      >
                        {t('delete')}
                      </Button>
                    ),
                  },
                ]}
              />
              <Divider />
              <Input.Search
                key="itemSearch"
                placeholder={t('item_search_description')}
                allowClear
                loading={isSearching}
                enterButton={t('search')}
                size="small"
                onSearch={onSearch}
              />
              <Table
                size="small"
                bordered
                loading={isSearching}
                pagination={false}
                dataSource={searchItems}
                rowKey="id"
                columns={[
                  {
                    title: t('image'),
                    align: 'center',
                    className: 'text-xs',
                    render: (row) => (
                      <img
                        width={75}
                        src={row.itemImage}
                        alt={`${row.brand} ${row.name} ${row.description}`}
                      />
                    ),
                  },
                  {
                    title: 'GTIN',
                    align: 'center',
                    className: 'text-xs',
                    render: (row) => (
                      <span>{row.gtin}</span>
                    ),
                  },
                  {
                    title: t('brand'),
                    align: 'center',
                    className: 'text-xs',
                    render: (row) => (
                      <span>{row.brand}</span>
                    ),
                  },
                  {
                    title: t('name'),
                    align: 'center',
                    className: 'text-xs',
                    render: (row) => (
                      <span>{row.name}</span>
                    ),
                  },
                  {
                    title: t('description'),
                    align: 'center',
                    className: 'text-xs',
                    render: (row) => (
                      <span>{row.description}</span>
                    ),
                  },
                  {
                    title: '',
                    align: 'center',
                    className: 'text-xs',
                    render: (row) => (
                      <Button
                        size="small"
                        type="primary"
                        disabled={row.isAssigned}
                        loading={row.loading}
                        onClick={() => addItem(row)}
                      >
                        {t('add')}
                      </Button>
                    ),
                  },
                ]}
              />
              <Pagination
                pageSize={size}
                showSizeChanger
                defaultCurrent={0}
                current={currentPage + 1}
                total={totalItemsInSearch}
                onChange={onTableChange}
              />
            </>
          </Panel>
        </Collapse>
      )}
      {selectedCampaign && showEditCampaignDrawer && (
        <EditProductCampaign />
      )}
    </>
  );
}

export default ProductCampaign;
