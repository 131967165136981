import React from 'react';
import { useTranslation } from 'react-i18next';
import { Tag } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import {
  CheckCircleOutlined,
  SyncOutlined,
} from '@ant-design/icons';
import { useRecoilValue } from 'recoil';
import { businessAtom } from '../../../../atoms/Atoms';

function OrderHeader() {
  const business = useRecoilValue(businessAtom);
  const { t } = useTranslation();

  return (
    <PageHeader
      className="px-0 text-lg"
      title={t('order_search')}
      tags={
        <Tag
          color="#2db7f5"
          icon={<CheckCircleOutlined />}
        >
          {business ? business.name : 'No Store Selected'}
        </Tag>
      }
    />
  );
}

export default OrderHeader;
