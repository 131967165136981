import React, { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import {
  Modal,
  Spin,
  Button
} from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import {
  showRelatedItemPickAtom,
  selectedPickingItemAtom
} from '../../../../atoms/Atoms';
import Search from '../../searchoptimization/shared-components/Search';

function PickItemModal() {
  const [showRelatedItemPick, setShowRelatedItemPick] =
    useRecoilState(showRelatedItemPickAtom);
  const [selectedPickingItem, setSelectedPickingItem] = useRecoilState(selectedPickingItemAtom);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (selectedPickingItem) {
      setShowRelatedItemPick(false);
    }
  }, [selectedPickingItem]);

  useEffect(() => {
    setLoading(false);
    setSelectedPickingItem(null);
  }, []);

  return (
    <Modal
      closable="true"
      centered="true"
      onCancel={() => setShowRelatedItemPick(false)}
      open={showRelatedItemPick}
      footer={[
        <Button
          key="back"
          disabled={loading}
          onClick={() => setShowRelatedItemPick(false)}
        >
          {t('close')}
        </Button>
      ]}
    >
      <PageHeader>{t('pick_related_item')}</PageHeader>
      {!loading && (
        <Search
          itemPicking
          size="small"
        />
      )}
      {loading && (
        <div style={{ textAlign: 'center' }}>
          <Spin />
        </div>
      )}
    </Modal>
  );
}

export default PickItemModal;
