import { CheckCircleOutlined, SyncOutlined } from '@ant-design/icons';
import { Tag } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { businessAtom } from '../../../../atoms/Atoms';

function StoreOptions() {
  const business = useRecoilValue(businessAtom);
  const { t } = useTranslation();

  return (
    <PageHeader
      className="mb-4 px-0 text-lg"
      title={t('store_options')}
      tags={
        <Tag
          color="#2db7f5"
          icon={business ? <CheckCircleOutlined /> : <SyncOutlined spin />}
        >
          {business ? business.name : `${t('loading')}...`}
        </Tag>
    }
    />
  );
}
export default StoreOptions;
