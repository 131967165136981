import React from 'react';
import { PageHeader } from '@ant-design/pro-layout';
import { useTranslation } from 'react-i18next';
import OrderSearch from './shared-components/OrderSearch';
// import InfoCarousel from '../../shared-components/Carousel';

function Orders() {
  const { t } = useTranslation();

  return (
    <div className="mt-2 m-4">
      <PageHeader
        className="text-lg"
        title={t('orders')}
      />
      {/* <InfoCarousel /> */}
      <OrderSearch />
    </div>
  );
}

export default Orders;
