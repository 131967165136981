import React from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import {
  Tabs
} from 'antd';
import StorageDocuments from './shared-components/StorageDocuments';
import DbDocuments from './shared-components/DbDocuments';

function StoreDocuments() {
  const { t } = useTranslation();

  return (
    <div>
      <Tabs defaultActiveKey="0">
        <Tabs.TabPane tab={t('documents')} key="0">
          <DbDocuments />
        </Tabs.TabPane>
        <Tabs.TabPane tab={t('files')} key="1">
          <StorageDocuments />
        </Tabs.TabPane>
      </Tabs>
    </div>
  );
}

export default StoreDocuments;
