import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import {
  useRecoilState,
  useRecoilValue,
  useSetRecoilState
} from 'recoil';
import moment from 'moment';
import {
  Modal,
  Button,
  Tag,
  Image,
  Input,
  Avatar,
  Tooltip,
  notification,
  Divider
} from 'antd';
import { Comment } from '@ant-design/compatible';
import { PageHeader } from '@ant-design/pro-layout';
import MDEditor from '@uiw/react-md-editor';
import {
  CopyOutlined
} from '@ant-design/icons';
import Grocefy from '../../../../assets/images/grocefyLogoAlone.png';
import {
  timezoneAtom,
  showTicketLogsAtom,
  refreshTicketsAtom,
  showAddTicketLogAtom,
  selectedTicketAtom,
  editTicketAtom,
  selectedTicketBoardAtom
} from '../../../../atoms/Atoms';
import api from '../../../../api/api';

function TicketModal() {
  const { TextArea } = Input;
  const timezone = useRecoilValue(timezoneAtom);
  const setRefreshTickets = useSetRecoilState(refreshTicketsAtom);
  const setViewTicketLogs = useSetRecoilState(showTicketLogsAtom);
  const setEditTicket = useSetRecoilState(editTicketAtom);
  const setShowAddTicketLog = useSetRecoilState(showAddTicketLogAtom);
  const selectedBoard = useRecoilValue(selectedTicketBoardAtom);
  const [selectedTicket, setSelectedTicket] = useRecoilState(selectedTicketAtom);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const showMessage = function (errorMessage) {
    notification.open({
      message: '',
      description: (
        <Comment
          author={<span>Grocefy</span>}
          avatar={<Avatar src={Grocefy} alt="grocefy" />}
          content={
            <p className="text-sm">
              {errorMessage}
            </p>
          }
          datetime={
            <Tooltip title={moment().format('YYYY-MM-DD HH:mm:ss')}>
              <span>{moment().fromNow()}</span>
            </Tooltip>
          }
        />
      ),
    });
  };
  const getStatusColor = function (status) {
    let bgColor = '';
    let color = '';
    if (status === 0) {
      bgColor = '#dddddd';
      color = '#000000';
    } else if (status === 1) {
      bgColor = '#EED202';
      color = '#000000';
    } else if (status === 2) {
      bgColor = '#008000';
      color = '#ffffff';
    } else if (status === 3) {
      bgColor = '#ff0033';
      color = '#ffffff';
    }
    return { bg: bgColor, color };
  };
  const getStatusName = function (status) {
    let name = 'Pending';
    if (status === 1) {
      name = 'Started';
    } else if (status === 2) {
      name = 'Completed';
    } else if (status === 3) {
      name = 'Rejected';
    }
    return name;
  };
  const getPriorityName = function (priority) {
    let name = 'Low';
    if (priority === 1) {
      name = 'Medium';
    } else if (priority === 2) {
      name = 'High';
    } else if (priority === 3) {
      name = 'Critical';
    }
    return name;
  };
  const shareTicketClick = function () {
    const url = `${window.location.origin}/ticketboard/${selectedBoard.id}/${selectedTicket.id}`;
    navigator.clipboard.writeText(url);
    showMessage('Copied ticket URL to clipboard');
  };
  const closeTicketModel = function () {
    if (!loading) {
      setSelectedTicket(null);
    }
  };
  const updateTicketRequest = function (updatedTicket) {
    setLoading(true);
    api.put(
      `ticketboards/ticket/${updatedTicket.id}`,
      updatedTicket
    )
      .then((response) => {
        setLoading(false);
        if (response.data.success) {
          const newTicket = response.data.data;
          setSelectedTicket(newTicket);
          setRefreshTickets(true);
        } else {
          showMessage(response.data.error);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  const editTicketClick = function () {
    if (!loading) {
      setEditTicket(selectedTicket);
    }
  };

  const completeTicketClick = function () {
    updateTicketRequest({ id: selectedTicket.id, Status: 2 });
  };

  const startTicketClick = function () {
    updateTicketRequest({ id: selectedTicket.id, Status: 1 });
  };

  const rejectTicketClick = function () {
    updateTicketRequest({ id: selectedTicket.id, Status: 3 });
  };

  return (
    <>
      {selectedTicket && (
        <Modal
          closable="true"
          centered="true"
          open={selectedTicket}
          onCancel={() => closeTicketModel()}
          footer={[
            <Button
              key="addLog"
              type="primary"
              disabled={loading}
              onClick={() => setShowAddTicketLog(true)}
            >
              {t('add_log')}
            </Button>,
            <Button
              key="submit"
              type="primary"
              disabled={loading}
              onClick={() => editTicketClick()}
            >
              {t('edit')}
            </Button>,
            <Button
              hidden={selectedTicket.status !== 0}
              key="start"
              type="primary"
              loading={loading}
              onClick={() => startTicketClick()}
            >
              {t('start')}
            </Button>,
            <Button
              hidden={selectedTicket.status !== 0}
              key="reject"
              type="primary"
              loading={loading}
              onClick={() => rejectTicketClick()}
            >
              {t('reject')}
            </Button>,
            <Button
              hidden={selectedTicket.status !== 1}
              key="complete"
              loading={loading}
              onClick={() => completeTicketClick()}
            >
              {t('complete')}
            </Button>,
            <Button
              key="back"
              disabled={loading}
              onClick={() => closeTicketModel()}
            >
              {t('close')}
            </Button>
          ]}
        >
          <PageHeader
            title={selectedTicket ? selectedTicket.name : ''}
            extra={[
              <Tag
                key="selectedTicketModelStaus"
                color={getStatusColor(selectedTicket.status).bg}
                style={{ color: getStatusColor(selectedTicket.status).color }}
              >
                {getStatusName(selectedTicket.status)}
              </Tag>,
              <Tag
                key="logs"
                onClick={() => setViewTicketLogs(true)}
              >
                {t('logs')}
              </Tag>,
              <Tag
                key="shareTicket"
                onClick={() => shareTicketClick()}
              >
                <CopyOutlined />
                {t('share')}
              </Tag>,
            ]}
          />
          <PageHeader
            title=""
            extra={[
              <Tag
                hidden={selectedTicket.assignedPriority === null}
                key="assignedPriority"
              >
                {t('assigned')}: {getPriorityName(selectedTicket.assignedPriority)}
              </Tag>,
              <Tag
                key="suggestedPriority"
              >
                {t('suggested')}: {getPriorityName(selectedTicket.suggestedPriority)}
              </Tag>,
            ]}
          />
          {selectedTicket.imageUrl && (
            <>
              <Image
                alt={selectedTicket.name}
                src={selectedTicket.imageUrl}
                height={75}
              />
              <Divider />
            </>
          )}
          <MDEditor.Markdown source={selectedTicket.description} />
          <Divider />
          <div>
            <strong className="bold">{t('created_by')}: </strong>
            <Avatar
              src={
                <Image
                  src={
                    selectedTicket.createdBy.avatar ? selectedTicket.createdBy.avatar : null
                  }
                />
              }
              style={{ marginRight: '10px' }}
            >
              {selectedTicket.createdBy.firstName[0]}{selectedTicket.createdBy.lastName[0]}
            </Avatar>
            {selectedTicket.createdBy.completeName}
          </div>
          <br />
          {selectedTicket.dueDate && (
            <>
              <strong className="bold">{t('due_date')}:</strong>
              {` ${moment(`${selectedTicket.dueDate}+0000`)
                .tz(timezone)
                .format('MMM dd YYYY, h:mm:ss a')}`}
              <br />
            </>
          )}
          {selectedTicket.assignedTo && (
            <>
              <div>
                <strong className="bold">{t('assigned_to')}: </strong>
                <Avatar
                  src={
                    <Image
                      src={
                        selectedTicket.assignedTo.avatar ? selectedTicket.assignedTo.avatar : null
                      }
                    />
                  }
                  style={{ marginRight: '10px' }}
                >
                  {selectedTicket.assignedTo.firstName[0]}{selectedTicket.assignedTo.lastName[0]}
                </Avatar>
                {selectedTicket.assignedTo.completeName}
              </div>
            </>
          )}
          <small>
            <strong className="bold">{t('created_on')}:</strong>
            {` ${moment(`${selectedTicket.createdAt}+0000`)
              .tz(timezone)
              .format('MMM dd YYYY, h:mm:ss a')}`}
          </small>
        </Modal>
      )}
    </>
  );
}

export default TicketModal;
