import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import {
  useRecoilValueLoadable,
  useRecoilValue
} from 'recoil';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Select,
  Drawer,
  Avatar,
  notification,
  Tooltip,
  Modal,
  Input,
  Typography,
  Table,
  Popconfirm
} from 'antd';
import { Comment } from '@ant-design/compatible';
import { PageHeader } from '@ant-design/pro-layout';
import {
  profileAtom,
  stateAtom,
  businessAtom,
  timezoneAtom
} from '../../../atoms/Atoms';
import Grocefy from '../../../assets/images/grocefyLogoAlone.png';
import api from '../../../api/api';

function Merchandiser() {
  const { Option } = Select;
  const state = useRecoilValue(stateAtom);
  const timezone = useRecoilValue(timezoneAtom);
  const profile = useRecoilValueLoadable(profileAtom);
  const business = useRecoilValueLoadable(businessAtom);
  const isAdmin =
    profile.contents && profile?.contents?.roles?.includes('SuperAdmin');
  const isCustomerService =
    isAdmin ||
    (profile?.contents &&
      (profile?.contents?.roles?.includes('CustomerSupportAdmin') ||
        profile?.contents?.roles?.includes('CustomerSupport')));
  const isStoreManager =
    isAdmin ||
    isCustomerService ||
    (profile?.contents &&
      (profile?.contents?.roles?.includes('StoreManager') ||
        profile?.contents?.roles?.includes('StoreAdmin')));
  const merchandiser =
      isAdmin || isStoreManager ||
      (profile.contents &&
        profile.contents.roles.includes('Merchandiser'));
  const history = useHistory();

  const [submittingOrder, setSubmittingOrder] = useState(false);
  const [newOrder, setNewOrder] = useState(null);
  const { t } = useTranslation();

  const [searchItems, setSearchItems] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [lastSearch, setLastSearch] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const [size, setSize] = useState(20);
  const [totalItemsInSearch, setTotalItemsInSearch] = useState(0);

  const [orderItems, setOrderItems] = useState([]);
  const [orderItemCount, setOrderItemCount] = useState(0);
  const [orderSubtotal, setOrderSubtotal] = useState(0);
  const [municipalTax, setMunicipalTax] = useState(0);
  const [stateTax, setStateTax] = useState(0);
  const [serviceFee, setServiceFee] = useState(0);
  const [taxTotal, setTaxTotal] = useState(0);

  const [selectedService, setSelectedService] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedPayment, setSelectedPayment] = useState(null);
  const [selectedUserLocation, setSelectedUserLocation] = useState(null);
  const [selectedServiceHour, setSelectedServiceHour] = useState(null);

  const [day, setDay] = useState(null);
  const [time, setTime] = useState(null);
  const [days, setDays] = useState([]);
  const [times, setTimes] = useState([]);
  const [loadingHours, setLoadingHours] = useState(false);

  const [showCart, setShowCart] = useState(false);

  const [users, setUsers] = useState([]);
  const [loadingUsers, setLoadingUsers] = useState(false);
  const [showUserPicker, setShowUserPicker] = useState(false);

  const [payments, setPayments] = useState([]);
  const [loadingPayments, setLoadingPayments] = useState(false);
  const [showPaymentPicker, setShowPaymentPicker] = useState(false);

  const [locations, setLocations] = useState([]);
  const [loadingLocations, setLoadingLocations] = useState(false);
  const [showLocationPicker, setShowLocationPicker] = useState(false);

  const width = window.innerWidth;

  if (!business?.contents || (!isAdmin && !isStoreManager && !merchandiser && !isCustomerService)) {
    history.push('/');
    return null;
  }

  const showMessage = function (message) {
    notification.open({
      message: '',
      description: (
        <Comment
          author={<span>Grocefy</span>}
          avatar={<Avatar src={Grocefy} alt="grocefy" />}
          content={
            <p className="text-sm">
              {`${message}`}
            </p>
          }
          datetime={
            <Tooltip title={moment().format('YYYY-MM-DD HH:mm:ss')}>
              <span>{moment().fromNow()}</span>
            </Tooltip>
          }
        />
      ),
    });
  };

  const submitOrder = function () {
    const mappedItems = _.map(orderItems, (i) => ({
      amount: i.amount,
      businessItemId: i.id,
      itemId: i.ParentItemId,
      price: i.activePrice,
      acceptsSubstitute: true,
      brand: i.brand,
      name: i.name,
      description: i.description,
      hasRandomWeight: i.hasRandomWeight,
      isAlcoholicBeverage: i.isAlcoholicBeverage,
      isEBT: i.isEbt,
      itemImage: i.imageUrl,
      weightDescriptor: `${i.weightType}`,
      weightType: i.weightType
    }));
    setSubmittingOrder(true);
    const payload = {
      FromMerch: true,
      Items: mappedItems,
      CardId: selectedPayment.last4 ? selectedPayment.id : null,
      PaymentOptionId: !selectedPayment.last4 ? selectedPayment.id : null,
      PaymentType: !selectedPayment.last4 ? selectedPayment.paymentType : 0,
      DeliveryLocationId: selectedUserLocation?.id,
      LocationId: business.contents.locations[0].id,
      Type: selectedService,
      Date: time,
      FriendCode: selectedUser.friendCode,
      Status: 0,
      MarkPicked: false
    };
    api
      .post('orders/new', payload)
      .then((response) => {
        setSubmittingOrder(false);
        if (response.data.success) {
          setNewOrder(response.data.data);
        } else {
          showMessage(response.data.error);
        }
      })
      .catch((error) => {
        console.error(error);
        setSubmittingOrder(false);
      });
  };

  const calcTotals = function () {
    let sub = 0;
    let mun = 0;
    let stateT = 0;
    let sFee = 0;
    let count = 0;
    for (let i = 0; i < orderItems.length; i++) {
      if (orderItems[i].hasRandomWeight) {
        count += 1;
      } else {
        count += orderItems[i].amount;
      }
      const price = orderItems[i].amount * orderItems[i].activePrice;
      const tempMun = price * orderItems[i].munTaxPct;
      const tempState = price * orderItems[i].stateTaxPct;

      sub += price;
      if (!selectedPayment?.last4 && !selectedPayment?.taxExcluded) {
        if (orderItems[i].taxRule === 1) {
          mun += tempMun;
        } else if (orderItems[i].taxRule === 2) {
          stateT += tempState;
        } else if (orderItems[i].taxRule === 3) {
          mun += tempMun;
          stateT += tempState;
        }
      }
    }
    if (state.isOrderServiceFeeFixed) {
      sFee = state.orderServiceFee;
    } else {
      sFee = state.orderServiceFee * sub;
    }
    if (selectedService === 0) {
      if (business.contents.locations[0].pickupFeeType === 2) {
        sFee += (business.contents.locations[0].pickupFee * sub);
      } else if (business.contents.locations[0].pickupFeeType === 1) {
        sFee += business.contents.locations[0].pickupFee;
      }
    }
    const taxT = mun + stateT;
    setOrderItemCount(count);
    setServiceFee(sFee.toFixed(2));
    setOrderSubtotal(sub.toFixed(2));
    setMunicipalTax(mun.toFixed(2));
    setStateTax(stateT.toFixed(2));
    setTaxTotal(taxT.toFixed(2));
  };

  const reset = function () {
    setSubmittingOrder(false);
    setNewOrder(null);
    setOrderItems([]);
    setSelectedPayment(null);
    setSelectedUser(null);
    setSelectedUserLocation(null);
    setSearchItems([]);
    setIsSearching(false);
    setLastSearch('');
    setSize(20);
    setTotalItemsInSearch(0);
  };

  const setItemAmounts = function (itemsToModify) {
    const items = JSON.parse(JSON.stringify(itemsToModify));
    for (let j = 0; j < items.length; j++) {
      items[j].amount = 0;
    }
    for (let i = 0; i < orderItems.length; i++) {
      for (let j = 0; j < items.length; j++) {
        if (orderItems[i].id === items[j].id) {
          items[j].amount = orderItems[i].amount;
        }
      }
    }
    setSearchItems(items);
  };

  const searchProducts = function (query, page) {
    setIsSearching(true);
    const payload = {
      SearchString: query,
      Page: page,
      Size: size
    };
    api
      .post(
        `businesses/merch/${business?.contents.id}/${size}/${page}`,
        payload
      )
      .then((response) => {
        if (response.data.success) {
          setIsSearching(false);
          setTotalItemsInSearch(response.data.data.count);
          setItemAmounts(response.data.data.items);
        } else {
          showMessage(response.data.error);
        }
      })
      .catch((error) => {
        setIsSearching(false);
        showMessage(error);
      });
  };

  const onItemTableChange = (page, pageSize) => {
    if (page - 1 !== currentPage) {
      setCurrentPage(page - 1);
      searchProducts(lastSearch, page - 1);
    } else {
      setSize(pageSize);
    }
  };

  const onItemSearch = async (value) => {
    setLastSearch(value);
    setCurrentPage(0);
    searchProducts(value, 0);
  };

  const searchUsers = function (searchTerm) {
    setLoadingUsers(true);
    api
      .post(
        'users/search',
        {
          SearchText: `${searchTerm}`,
        }
      )
      .then((response) => {
        setUsers(response.data.data.results);
        setLoadingUsers(false);
      })
      .catch((error) => {
        console.error(error);
        setLoadingUsers(false);
      });
  };

  const getPayments = function () {
    setLoadingPayments(true);
    api
      .get(`businesses/payments/options/${selectedUser.id}/${business.contents.locations[0].id}/${selectedService}`)
      .then((response) => {
        const temp = [];
        for (let i = 0; i < response.data.data.paymentOptions.length; i++) {
          temp.push(response.data.data.paymentOptions[i]);
        }
        for (let i = 0; i < response.data.data.userCards.length; i++) {
          temp.push(response.data.data.userCards[i]);
        }
        setPayments(temp);
        setLoadingPayments(false);
      })
      .catch((error) => {
        console.error(error);
        setLoadingPayments(false);
      });
  };

  const getUserLocations = function () {
    setLoadingLocations(true);
    api
      .get(`orders/getuserlocationsforDelivery/${selectedUser.id}/${business.contents.locations[0].id}`)
      .then((response) => {
        setLocations(response.data.data.results);
        setLoadingLocations(false);
      })
      .catch((error) => {
        console.error(error);
        setLoadingLocations(false);
      });
  };

  const getServiceHours = function () {
    setLoadingHours(true);
    api
      .post(`businesses/hours/v2/${business.contents.locations[0].id}`, {
        OrderType: selectedService,
        DeliveryLocationId: selectedUserLocation?.id
      })
      .then((response) => {
        setDays(response.data.data.results);
        setTimes(response.data.data.results[0].times);
        setDay(response.data.data.results[0].date);
        const availTimes = _.filter(response.data.data.results[0].times, (x) => x.isAvailable);
        setTime(availTimes[0].endTime);
        setLoadingHours(false);
      })
      .catch((error) => {
        console.error(error);
        setLoadingHours(false);
      });
  };

  const addItem = function (item) {
    const temp = JSON.parse(JSON.stringify(orderItems));
    let found = false;
    for (let i = 0; i < temp.length; i++) {
      if (temp[i].id === item.id) {
        found = true;
        temp[i].amount += item.stepAmount;
        if (temp[i].amount > item.maxQuantity) {
          temp[i].amount = item.maxQuantity;
        }
      }
    }
    if (!found) {
      const temp2 = JSON.parse(JSON.stringify(item));
      temp2.amount = item.stepAmount;
      temp.push(temp2);
    }
    setOrderItems(temp);
  };

  const removeItem = function (item) {
    const temp = JSON.parse(JSON.stringify(orderItems));
    for (let i = 0; i < temp.length; i++) {
      if (temp[i].id === item.id) {
        temp[i].amount -= item.stepAmount;
        if (temp[i].amount < item.minQuantity) {
          temp[i].amount = 0;
        }
      }
    }
    const filtered = _.filter(temp, (i) => i.amount > 0);
    setOrderItems(filtered);
  };

  useEffect(() => {
    reset();
    if (business?.contents.locations[0]?.isPickUpAvailable) {
      setSelectedService(0);
    } else if (business?.contents.locations[0]?.isDeliveryAvailable) {
      setSelectedService(1);
    } else {
      setSelectedService(null);
    }
  }, []);

  useEffect(() => {
    reset();
    if (business?.contents.locations[0]?.isPickUpAvailable) {
      setSelectedService(0);
    } else if (business?.contents.locations[0]?.isDeliveryAvailable) {
      setSelectedService(1);
    } else {
      setSelectedService(null);
    }
  }, [business]);

  useEffect(() => {
    setSelectedUserLocation(null);
    calcTotals();
  }, [selectedService]);

  useEffect(() => {
    setUsers([]);
  }, [showUserPicker]);

  useEffect(() => {
    setPayments([]);
    if (showPaymentPicker) {
      getPayments();
    }
  }, [showPaymentPicker]);

  useEffect(() => {
    setLocations([]);
    if (showLocationPicker) {
      getUserLocations();
    }
  }, [showLocationPicker]);

  useEffect(() => {
    setDays([]);
    setTimes([]);
    setDay(null);
    setTimes(null);
    if (showCart) {
      getServiceHours();
    }
  }, [showCart]);

  useEffect(() => {
    if (selectedUser) {
      setSelectedPayment(null);
      setSelectedUserLocation(null);
    }
  }, [selectedUser]);

  useEffect(() => {
    if (day) {
      const filter = _.filter(days, (d) => d.date === day);
      if (filter.length > 0) {
        setTimes(filter[0].times);
      }
    }
  }, [day]);

  useEffect(() => {
    if (time) {
      const filter = _.filter(times, (x) => x.endTime === time);
      if (filter.length > 0) {
        setSelectedServiceHour(filter[0]);
      }
    } else {
      setSelectedServiceHour(null);
    }
  }, [time]);

  useEffect(() => {
    calcTotals();
    setItemAmounts(searchItems);
  }, [orderItems, selectedPayment]);

  return (
    <>
      <PageHeader
        title={t('create_order_on_behalf')}
        extra={[
          <Select style={{ width: 95 }} value={selectedService}>
            {business?.contents.locations &&
              business?.contents.locations[0]?.isPickUpAvailable && (
              <Option value={0}>Pickup</Option>
            )}
            {business?.contents.locations &&
              business?.contents.locations[0]?.isDeliveryAvailable && (
              <Option value={1}>Delivery</Option>
            )}
          </Select>,
          <Button
            key="selectuser"
            hidden={selectedService === null}
            type="primary"
            size="small"
            onClick={() => setShowUserPicker(true)}
          >
            {selectedUser ? selectedUser.completeName : t('select_user')}
          </Button>,
          <Button
            hidden={selectedService !== 1}
            key="selectlocation"
            type="primary"
            size="small"
            onClick={() => setShowLocationPicker(true)}
          >
            {selectedUserLocation ? selectedUserLocation.name : t('select_user_location')}
          </Button>,
          <Button
            key="selectpayment"
            hidden={selectedService === null}
            disabled={!selectedUser}
            type="primary"
            size="small"
            onClick={() => setShowPaymentPicker(true)}
          >
            {selectedPayment ? (selectedPayment.last4 ? `${selectedPayment.brand} x${selectedPayment.last4}` : selectedPayment.name) : t('select_payment')}
          </Button>,
          <div>
            <br />
            <Button
              key="cart"
              disabled={selectedService === null ||
              !selectedUser || !selectedPayment || (selectedService === 1 && !selectedUserLocation)}
              type="default"
              size="small"
              onClick={() => setShowCart(true)}
            >
              Cart ({orderItemCount})
            </Button>
          </div>,
        ]}
      />
      <Input.Search
        key="itemSearch"
        placeholder={t('item_search_placeholder')}
        allowClear
        loading={isSearching}
        disabled={selectedService === null ||
          !selectedUser || !selectedPayment || (selectedService === 1 && !selectedUserLocation)}
        enterButton={t('search')}
        size="small"
        onSearch={onItemSearch}
      />
      <Table
        size="small"
        bordered
        loading={isSearching}
        dataSource={searchItems}
        rowKey="id"
        pagination={{
          pageSize: size,
          showSizeChanger: true,
          defaultCurrent: 0,
          current: currentPage + 1,
          total: totalItemsInSearch,
          onChange: onItemTableChange
        }}
        columns={[
          {
            title: t('image'),
            align: 'center',
            className: 'text-xs',
            render: (row) => (
              <img
                width={75}
                src={row.imageUrl}
                alt={`${row.brand} ${row.name} ${row.description}`}
              />
            ),
          },
          {
            title: 'GTIN',
            align: 'center',
            className: 'text-xs',
            render: (row) => (
              <span>{row.gtin}</span>
            ),
          },
          {
            title: t('complete_name'),
            align: 'center',
            className: 'text-xs',
            render: (row) => (
              <span>{row.brand} {row.name} {row.description}</span>
            ),
          },
          {
            title: t('price'),
            align: 'center',
            className: 'text-xs',
            render: (row) => (
              <span>${row.activePrice}</span>
            ),
          },
          {
            title: '',
            align: 'center',
            className: 'text-xs',
            render: (row) => (
              <div style={{
                border: '1px solid #1890ff',
                borderRadius: '2px',
                display: 'inline-block'
              }}
              >
                <Button
                  size="small"
                  type="primary"
                  className="bg-red"
                  disabled={selectedService === null ||
                    !selectedUser || !selectedPayment ||
                    (selectedService === 1 && !selectedUserLocation)}
                  loading={row.loading}
                  onClick={() => removeItem(row)}
                >
                  -
                </Button>
                <span
                  style={{
                    margin: '0 5px',
                  }}
                >
                  {row.amount}
                </span>
                <Button
                  size="small"
                  type="primary"
                  className="bg-green"
                  disabled={selectedService === null ||
                    !selectedUser || !selectedPayment ||
                    (selectedService === 1 && !selectedUserLocation)}
                  loading={row.loading}
                  onClick={() => addItem(row)}
                >
                  +
                </Button>
              </div>
            ),
          },
        ]}
      />
      <Drawer
        title={t('select_user')}
        placement="right"
        closable
        onClose={() => setShowUserPicker(false)}
        open={showUserPicker}
        width={400}
      >
        <Input.Search
          placeholder={t('user_search_placeholder')}
          allowClear
          enterButton={t('search')}
          size="small"
          onSearch={(val) => searchUsers(val)}
        />
        <Table
          size="small"
          bordered
          loading={loadingUsers}
          dataSource={users}
          rowKey="id"
          columns={[
            {
              title: t('first_name'),
              dataIndex: 'firstName',
              key: 'firstName',
              align: 'center',
              className: 'text-xs',
              render: (text) => <Typography.Text>{text}</Typography.Text>,
            },
            {
              title: t('last_name'),
              dataIndex: 'lastName',
              key: 'lastName',
              align: 'center',
              className: 'text-xs',
              render: (text) => <Typography.Text>{text}</Typography.Text>,
            },
            {
              title: t('email'),
              dataIndex: 'email',
              key: 'email',
              align: 'center',
              className: 'text-xs',
              render: (text) => <Typography.Text>{text}</Typography.Text>,
            },
            {
              title: '',
              align: 'center',
              className: 'text-xs',
              render: (row) =>
                <Button
                  type="primary"
                  size="small"
                  onClick={() => {
                    setSelectedUser(row);
                    setShowUserPicker(false);
                  }}
                >
                  {t('select')}
                </Button>,
            }
          ]}
        />
      </Drawer>
      <Drawer
        title={t('select_payment')}
        placement="right"
        closable
        onClose={() => setShowPaymentPicker(false)}
        open={showPaymentPicker}
        width={400}
      >
        <Table
          size="small"
          bordered
          loading={loadingPayments}
          dataSource={payments}
          rowKey="id"
          columns={[
            {
              title: t('payment'),
              align: 'center',
              className: 'text-xs',
              render: (row) => (
                <div>
                  {row.last4 && (
                    <p>{row.brand} x{row.last4}</p>
                  )}
                  {!row.last4 && (
                    <p>{row.name}</p>
                  )}
                </div>
              ),
            },
            {
              title: '',
              align: 'center',
              className: 'text-xs',
              render: (row) =>
                <Button
                  type="primary"
                  disabled={row.deliveryCost <= 0}
                  size="small"
                  onClick={() => {
                    setSelectedPayment(row);
                    setShowPaymentPicker(false);
                  }}
                >
                  {t('select')}
                </Button>,
            }
          ]}
        />
      </Drawer>
      <Drawer
        title={t('select_user_location')}
        placement="right"
        closable
        onClose={() => setShowLocationPicker(false)}
        open={showLocationPicker}
        width={400}
      >
        <Table
          size="small"
          bordered
          loading={loadingLocations}
          dataSource={locations}
          rowKey="id"
          columns={[
            {
              title: t('location'),
              align: 'center',
              className: 'text-xs',
              render: (row) => (
                <div>
                  <strong>{row.name}</strong>
                  <p>{row.addressLine}</p>
                  <p>{row.city}</p>
                  <p>${row.deliveryCost}</p>
                </div>
              ),
            },
            {
              title: '',
              align: 'center',
              className: 'text-xs',
              render: (row) =>
                <Button
                  type="primary"
                  disabled={row.deliveryCost <= 0}
                  size="small"
                  onClick={() => {
                    setSelectedUserLocation(row);
                    setShowLocationPicker(false);
                  }}
                >
                  {t('select')}
                </Button>,
            }
          ]}
        />
      </Drawer>
      <Drawer
        title="Cart"
        placement="right"
        closable={!submittingOrder}
        onClose={() => setShowCart(false)}
        open={showCart}
        width={600}
      >
        <div className="mb-2">
          <strong>{t('selected_service_hour')}:</strong>
          <br />
          <Select
            className="mr-2"
            style={{ width: 140 }}
            value={day}
            loading={loadingHours}
            onChange={(d) => {
              setDay(d);
            }}
          >
            {_.map(days, (d) => (
              <Option key={d.date} value={d.date}>{moment(d.date).tz(timezone).format('MMM Do YYYY')}</Option>
            ))}
          </Select>
          <Select
            style={{ width: 140 }}
            value={time}
            onChange={(x) => {
              setTime(x);
            }}
            loading={loadingHours}
          >
            {_.map(times, (d) => (
              <Option
                key={d.endTime}
                value={d.endTime}
                disabled={!d.isAvailable}
              >
                {moment(d.endTime).tz(timezone).format('h:mm a')}
              </Option>
            ))}
          </Select>
        </div>
        <Table
          size="small"
          bordered
          dataSource={orderItems}
          rowKey="id"
          columns={[
            {
              title: t('image'),
              align: 'center',
              className: 'text-xs',
              render: (row) => (
                <img
                  width={50}
                  src={row.imageUrl}
                  alt={`${row.brand} ${row.name} ${row.description}`}
                />
              ),
            },
            {
              title: 'GTIN',
              align: 'center',
              className: 'text-xs',
              render: (row) => (
                <span>{row.gtin}</span>
              ),
            },
            {
              title: t('complete_name'),
              align: 'center',
              className: 'text-xs',
              render: (row) => (
                <span>{row.brand} {row.name} {row.description}</span>
              ),
            },
            {
              title: t('price'),
              align: 'center',
              className: 'text-xs',
              render: (row) => (
                <span>${row.activePrice}</span>
              ),
            },
            {
              title: '',
              align: 'center',
              className: 'text-xs',
              render: (row) => (
                <div style={{
                  border: '1px solid #1890ff',
                  borderRadius: '2px',
                  display: 'inline-block'
                }}
                >
                  <Button
                    size="small"
                    type="primary"
                    className="bg-red"
                    disabled={selectedService === null ||
                      submittingOrder ||
                      !selectedUser || !selectedPayment ||
                      (selectedService === 1 && !selectedUserLocation)}
                    loading={row.loading}
                    onClick={() => removeItem(row)}
                  >
                    -
                  </Button>
                  <span
                    style={{
                      margin: '0 5px',
                    }}
                  >
                    {row.amount}
                  </span>
                  <Button
                    size="small"
                    type="primary"
                    className="bg-green"
                    disabled={selectedService === null ||
                      submittingOrder ||
                      !selectedUser || !selectedPayment ||
                      (selectedService === 1 && !selectedUserLocation)}
                    loading={row.loading}
                    onClick={() => addItem(row)}
                  >
                    +
                  </Button>
                </div>
              ),
            },
          ]}
        />
        <div>
          <p>
            <strong>Subtotal:</strong>
            <span style={{ float: 'right' }}>${orderSubtotal}</span>
          </p>
          {selectedService === 1 && (
            <p>
              <strong>{t('delivery_fee')}:</strong>
              <span style={{ float: 'right' }}>${selectedServiceHour?.actualCost}</span>
            </p>
          )}
          <p>
            <strong>{t('service_fee')}:</strong>
            <span style={{ float: 'right' }}>${serviceFee}</span>
          </p>
          <p>
            <strong>Municipal Tax:</strong>
            <span style={{ float: 'right' }}>${municipalTax}</span>
          </p>
          <p>
            <strong>State Tax:</strong>
            <span style={{ float: 'right' }}>${stateTax}</span>
          </p>
          <p>
            <strong>Tax Total:</strong>
            <span style={{ float: 'right' }}>${taxTotal}</span>
          </p>
        </div>
        <Popconfirm
          okText={t('yes')}
          cancelText="No"
          title={t('order_submit_content')}
          onConfirm={async () => submitOrder()}
        >
          <Button
            loading={submittingOrder}
            className="w-full mt-2"
            type="primary"
          >
            {t('submit_order')}
          </Button>
        </Popconfirm>
      </Drawer>
      <Modal
        closable="true"
        centered="true"
        onCancel={() => reset()}
        open={newOrder}
        footer={[
          <Button
            key="back"
            onClick={() => reset()}
          >
            {t('close')}
          </Button>
        ]}
      >
        <PageHeader title={t('new_order')} />
        <div>
          <p>
            <strong>{t('confirmation_code')}:</strong>
            <span style={{ float: 'right' }}>{newOrder?.confirmationCode}</span>
          </p>
          <p className="mt-5">
            <strong>Subtotal:</strong>
            <span style={{ float: 'right' }}>${newOrder?.productsTotal}</span>
          </p>
          {selectedService === 1 && (
            <p>
              <strong>{t('delivery_fee')}:</strong>
              <span style={{ float: 'right' }}>${newOrder?.deliveryTotal}</span>
            </p>
          )}
          <p>
            <strong>{t('service_fee')}:</strong>
            <span style={{ float: 'right' }}>${newOrder?.processingFee}</span>
          </p>
          <p>
            <strong>Municipal Tax:</strong>
            <span style={{ float: 'right' }}>${newOrder?.municipalTax}</span>
          </p>
          <p>
            <strong>State Tax:</strong>
            <span style={{ float: 'right' }}>${newOrder?.stateTax}</span>
          </p>
          <p>
            <strong>Tax Total:</strong>
            <span style={{ float: 'right' }}>${newOrder?.taxTotal}</span>
          </p>
          <p>
            <strong>{t('order_total')}:</strong>
            <span style={{ float: 'right' }}>${newOrder?.orderTotal}</span>
          </p>
        </div>
      </Modal>
    </>
  );
}

export default Merchandiser;
