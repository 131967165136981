import axios from 'axios';
import envConfig from '../envConfig';

function newAbortSignal(timeout) {
  const abortController = new AbortController();
  setTimeout(() => abortController.abort(), timeout || 0);

  return abortController.signal;
}

const bookingApi = axios.create({
  baseURL: envConfig.REACT_APP_BOOKING_API_URL,
  headers: {
    'Ocp-Apim-Subscription-Key': envConfig.REACT_APP_BOOKING_API_KEY,
    'x-api-utility-key': envConfig.REACT_APP_UTILITY_KEY,
    Authorization: `Bearer ${window.localStorage.getItem(`${envConfig.REACT_APP_PREFIX}-admin-token`)}`
  },
  timeout: 2700000,
  signal: newAbortSignal(2700000)
});
bookingApi.interceptors.request.use(
  (request) => {
    request.headers['Accept-Language'] = `${window.localStorage.getItem(`${envConfig.REACT_APP_PREFIX}-lang`)}-US`.replaceAll('"', '');
    request.headers.Authorization = `Bearer ${window.localStorage.getItem(`${envConfig.REACT_APP_PREFIX}-admin-token`)}`;
    return request;
  }
);

bookingApi.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      window.localStorage.removeItem(`${envConfig.REACT_APP_PREFIX}-admin-currentstate`);
      window.localStorage.removeItem(`${envConfig.REACT_APP_PREFIX}-admin-token`);
      window.localStorage.removeItem(`${envConfig.REACT_APP_PREFIX}-admin-expire`);
      window.localStorage.removeItem(`${envConfig.REACT_APP_PREFIX}-admin-profile`);
      window.location = '/';
      return null;
    }
    return Promise.reject(error);
  }
);

export default bookingApi;
