import React from 'react';
import Header from './shared-components/Header';
import PaymentOptions from './shared-components/PaymentsOptions';

function Configurations() {
  return (
    <div>
      <Header />
      <PaymentOptions />
    </div>
  );
}

export default Configurations;
