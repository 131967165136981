import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useSetRecoilState } from 'recoil';
import MDEditor from '@uiw/react-md-editor';
import {
  Modal,
  Button,
  notification,
  Avatar,
  Tooltip
} from 'antd';
import { Comment } from '@ant-design/compatible';
import { PageHeader } from '@ant-design/pro-layout';
import { profileAtom, tokenAtom, impersonationAtom } from '../../../atoms/Atoms';
import api from '../../../api/api';
import envConfig from '../../../envConfig';
import LanguageSwitcher from '../LanguageSwitcher';
import Grocefy from '../../../assets/images/grocefyLogoAlone.png';

function TosAcceptenceModal() {
  const { t, i18n } = useTranslation();
  const setProfile = useSetRecoilState(profileAtom);
  const setToken = useSetRecoilState(tokenAtom);
  const setImpersonation = useSetRecoilState(impersonationAtom);
  const [loading, setLoading] = useState(false);
  const [pendingTos, setPendingTos] = useState([]);

  function showMessage(message) {
    notification.open({
      message: '',
      description: (
        <Comment
          author={<span>Grocefy</span>}
          avatar={<Avatar src={Grocefy} alt="grocefy" />}
          content={
            <p className="text-sm">
              {message}
            </p>
          }
          datetime={
            <Tooltip title={moment().format('YYYY-MM-DD HH:mm:ss')}>
              <span>{moment().fromNow()}</span>
            </Tooltip>
          }
        />
      ),
    });
  }

  function getPendingAcceptance() {
    api.get('admin/tos/pending')
      .then((response) => {
        setPendingTos(response.data.data.results);
      }).catch((err) => console.log(err));
  }

  function acceptTos(tosId) {
    setLoading(true);
    api.post(`admin/tos/accept/${tosId}`)
      .then((response) => {
        setLoading(false);
        if (response.data.success) {
          const newtos = _.filter(pendingTos, (tos) => tos.id !== tosId);
          setPendingTos(newtos);
        } else {
          showMessage(response.data.error);
        }
      }).catch((err) => {
        setLoading(false);
        console.log(err);
        showMessage(err.message);
      });
  }

  function signOut() {
    localStorage.removeItem(`${envConfig.REACT_APP_PREFIX}-admin-expire`);
    localStorage.removeItem(`${envConfig.REACT_APP_PREFIX}-admin-token`);
    localStorage.removeItem(`${envConfig.REACT_APP_PREFIX}-admin-profile`);
    setProfile(null);
    setToken(null);
    setImpersonation(null);
    window.location.href = '/';
  }

  useEffect(() => {
    getPendingAcceptance();
  }, []);

  return (
    <Modal
      open={pendingTos.length > 0}
      closable="false"
      centered="true"
      closeIcon={null}
      className="my-4"
      footer={[
        <Button
          key="reject"
          type="danger"
          className="bg-red-500 text-white"
          onClick={() => signOut()}
        >
          {t('reject')}
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={() => {
            acceptTos(pendingTos[0]?.id);
          }}
        >
          {t('accept')}
        </Button>
      ]}
    >
      <PageHeader
        title={i18n.language === 'en' ? pendingTos[0]?.nameEn : pendingTos[0]?.nameEs}
        extra={[<LanguageSwitcher small key="lang" />]}
      />
      <MDEditor.Markdown source={i18n.language === 'en' ? pendingTos[0]?.markdownEn : pendingTos[0]?.markdownEs} />
    </Modal>
  );
}

export default TosAcceptenceModal;
