import axios from 'axios';
import envConfig from '../envConfig';

const imagesApi = axios.create({
  baseURL: envConfig.REACT_APP_IMAGES_URL,
  headers: {
    'Ocp-Apim-Subscription-Key': envConfig.REACT_APP_IMAGES_KEY,
  },
});

export default imagesApi;
