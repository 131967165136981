import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Table, Image } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import { useSetRecoilState, useRecoilState } from 'recoil';
import { useMount } from 'react-use';
import {
  editBookingServiceTypeAtom,
  selectedBookingServiceTypeAtom,
  reloadBookingServiceTypeAtom
} from '../../../../atoms/Atoms';
import EditServiceType from './shared-components/EditServiceType';
import bookingApi from '../../../../api/bookingApi';

function ServiceTypes() {
  const { t } = useTranslation();
  const setSelectedServiceType = useSetRecoilState(selectedBookingServiceTypeAtom);
  const setEditServiceType = useSetRecoilState(editBookingServiceTypeAtom);
  const [reload, setReload] = useRecoilState(reloadBookingServiceTypeAtom);
  const [loading, setLoading] = useState(false);
  const [serviceTypes, setServiceTypes] = useState([]);

  function getServiceTypes() {
    setLoading(true);
    bookingApi
      .get('servicetypes/list')
      .then((response) => {
        setLoading(false);
        setServiceTypes(response.data.data);
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  }

  useMount(() => {
    getServiceTypes();
  });

  useEffect(() => {
    if (reload) {
      getServiceTypes();
      setReload(false);
    }
  }, [reload]);

  return (
    <>
      <PageHeader
        className="mb-4 px-0"
        title={t('service_types')}
        extra={[
          <Button
            id="reload"
            type="primary"
            key="reload"
            size="small"
            onClick={() => setReload(true)}
          >
            {t('reload')}
          </Button>,
          <Button
            type="primary"
            key="create"
            size="small"
            onClick={() => {
              setSelectedServiceType({});
              setEditServiceType(true);
            }}
          >
            {t('create_new')}
          </Button>
        ]}
      />
      <Table
        pagination={false}
        size="small"
        bordered
        rowKey="id"
        scroll={{ x: 500 }}
        loading={loading}
        columns={[
          {
            title: `${t('name')} (EN)`,
            key: 'nameEn',
            align: 'center',
            dataIndex: 'nameEn',
            className: 'text-xs',
            render: (row) => (
              <span>{row}</span>
            ),
          },
          {
            title: `${t('name')} (ES)`,
            key: 'nameEs',
            align: 'center',
            dataIndex: 'nameEs',
            className: 'text-xs',
            render: (row) => (
              <span>{row}</span>
            ),
          },
          {
            title: t('edit'),
            key: 'edit',
            align: 'center',
            className: 'text-xs',
            render: (text, record) => (
              <Button
                size="small"
                onClick={() => {
                  setSelectedServiceType(record);
                  setEditServiceType(true);
                }}
              >
                {t('edit')}
              </Button>
            ),
          },
        ]}
        dataSource={serviceTypes}
      />
      <EditServiceType />
    </>
  );
}
export default ServiceTypes;
