import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Tag, Button } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  SyncOutlined,
} from '@ant-design/icons';
import { useRecoilState, useRecoilValue, useRecoilValueLoadable } from 'recoil';
import { businessAtom, profileAtom, refreshStripePayoutsAtom } from '../../../../../atoms/Atoms';
import stripe from '../../../../../assets/images/stripe.svg';
import api from '../../../../../api/api';

function Header() {
  const business = useRecoilValue(businessAtom);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const profile = useRecoilValueLoadable(profileAtom);
  const isAdmin =
    profile.contents && profile?.contents?.roles?.includes('SuperAdmin');
  const { t } = useTranslation();
  const [refreshStripePayouts, setRefreshStripePayouts] = useRecoilState(refreshStripePayoutsAtom);

  const getData = function () {
    setLoading(true);
    api
      .get(`businesses/v2/${business.id}`)
      .then((response) => {
        setLoading(false);
        setData(response.data.data);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const updateStripeTransfers = function (isOn) {
    setLoading(true);
    api.put(
      `businesses/updatepaymentdailytransfer/${business?.locations[0].id}/${isOn}/0`,
      { }
    )
      .then((response) => {
        setLoading(false);
        const temp = JSON.parse(JSON.stringify(data));
        temp.stripeDailyTransfers = isOn;
        setData(temp);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const updateStripePayout = function (isOn) {
    setLoading(true);
    const temp = JSON.parse(JSON.stringify(data));
    temp.allowStripePayouts = isOn;
    api.put(
      `businesses/${business?.id}`,
      temp
    )
      .then((response) => {
        setLoading(false);
        const temp2 = JSON.parse(JSON.stringify(data));
        temp2.allowStripePayouts = isOn;
        setData(temp2);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (business) {
      getData();
    }
  }, [business]);

  useEffect(() => {
    if (business) {
      getData();
    }
  }, []);

  return (
    <PageHeader
      className="mb-4 px-0"
      title="Stripe"
      avatar={{ src: stripe }}
      tags={
        <Tag
          color="#2db7f5"
          icon={business ? <CheckCircleOutlined /> : <SyncOutlined spin />}
        >
          {business ? business.name : `${t('loading')}...`}
        </Tag>
      }
      extra={
        data && [
          <Button
            id="reload"
            type="primary"
            key="reload"
            size="small"
            onClick={() => setRefreshStripePayouts(true)}
          >
            {t('reload')}
          </Button>,
          <Button
            key="transfers"
            size="small"
            onClick={() => {
              if (isAdmin) {
                updateStripeTransfers(!data.stripeDailyTransfers);
              }
            }}
            loading={loading}
            style={{
              color: '#fff',
              border: 'none',
              backgroundColor: data?.stripeDailyTransfers ? '#87d068' : '#f50'
            }}
          >
            {data?.stripeDailyTransfers ? (
              <CheckCircleOutlined />
            ) : (
              <CloseCircleOutlined />
            )}
            Transfer Service{' '}
            {data?.stripeDailyTransfers ? 'Enabled' : 'Disabled'}
          </Button>,
          <Button
            key="payouts"
            size="small"
            onClick={() => {
              if (isAdmin) {
                updateStripePayout(!data.allowStripePayouts);
              }
            }}
            loading={loading}
            style={{
              color: '#fff',
              border: 'none',
              backgroundColor: data?.allowStripePayouts ? '#108ee9' : '#f50'
            }}
          >
            {data?.allowStripePayouts ? (
              <CheckCircleOutlined />
            ) : (
              <CloseCircleOutlined />
            )}
            Payouts Service{' '}
            {data?.allowStripePayouts ? 'Enabled' : 'Disabled'}
          </Button>,
        ]
      }
    />
  );
}

export default Header;
