import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  CreditCardOutlined,
  DownloadOutlined,
  SettingOutlined,
  ShoppingCartOutlined,
  LineChartOutlined,
  QuestionCircleOutlined,
  NotificationOutlined,
} from '@ant-design/icons';
import HomeHeader from '../../shared-components/header/HomeHeader';

function Home() {
  const { t } = useTranslation();

  const features = [
    {
      name: t('dashboard'),
      description: t('dashboard_description'),
      icon: LineChartOutlined,
    },
    {
      name: t('store'),
      description: t('store_description'),
      icon: ShoppingCartOutlined,
    },
    {
      name: t('marketing'),
      description: t('marketing_description'),
      icon: NotificationOutlined,
    },
    {
      name: t('payments'),
      description: t('payment_description'),
      icon: CreditCardOutlined,
    },
    {
      name: t('settings'),
      description: t('settings_description'),
      icon: SettingOutlined,
    },
    {
      name: t('downloads'),
      description: t('downloads_description'),
      icon: DownloadOutlined,
    },

    {
      name: t('help'),
      description: t('help_description'),
      icon: QuestionCircleOutlined,
    },
  ];

  return (
    <div>
      <HomeHeader />
      <div className="py-4 md:py-12 px-8 md:px-12 bg-white">
        <div className="max-w-7xl mx-auto md:px-4 sm:px-6 lg:px-8">
          <div className="lg:text-center">
            <p className="md:mt-2 text-2xl md:text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              Grocefy Admin
            </p>
            <p className="md:mt-4 max-w-2xl text-sm md:text-xl text-gray-500 lg:mx-auto">
              {t('admin_description')}
            </p>
          </div>

          <div className="mt-4 md:mt-10">
            <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10">
              {features.map((feature) => (
                <div key={feature.name} className="relative">
                  <dt>
                    <div className="absolute flex items-center justify-center h-10 w-10 rounded-md bg-blue-400 text-white">
                      <feature.icon
                        className="h-6 w-6 md:h-8 md:w-8 py-1"
                        style={{ fontSize: '18px' }}
                        aria-hidden="true"
                      />
                    </div>
                    <p className="ml-12 md:ml-16 text-md md:text-lg leading-6 font-medium text-gray-900">
                      {feature.name}
                    </p>
                  </dt>
                  <dd className="md:mt-2 ml-12 md:ml-16 text-xs md:text-base text-gray-600">
                    {feature.description}
                  </dd>
                </div>
              ))}
            </dl>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
