import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import {
  useRecoilState,
  useSetRecoilState,
  useRecoilValueLoadable
} from 'recoil';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import {
  Drawer,
  Avatar,
  Tooltip,
  DatePicker,
  Switch,
  InputNumber,
  Collapse,
  Row,
  Col,
  Button,
  Select,
  Input,
  notification
} from 'antd';
import { Comment } from '@ant-design/compatible';
import {
  FormOutlined,
  CloseCircleOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import Grocefy from '../../../../assets/images/grocefyLogoAlone.png';
import {
  refreshBusinessAcountsAtom,
  selectedBusinessAccountAtom
} from '../../../../atoms/Atoms';
import { businessesSelector } from '../../../../atoms/Selectors';
import api from '../../../../api/api';

function EditBusinessAccount() {
  const { Panel } = Collapse;
  const { Option } = Select;
  const businesses = useRecoilValueLoadable(businessesSelector);
  const setRefreshBusinessAccounts = useSetRecoilState(refreshBusinessAcountsAtom);
  const [selectedBusinessAccount, setSelectedBusinessAccount]
    = useRecoilState(selectedBusinessAccountAtom);
  const [loading, setLoading] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [businessAccount, setBusinessAccount] = useState({});
  const [showEditMinimumPayout, setShowEditMinimumPayout] = useState(false);
  const { t } = useTranslation();

  const businessTypes = [
    { id: 0, name: 'Antique Shops' },
    { id: 1, name: 'Artists Supply/Craft Shops' },
    { id: 2, name: 'Automotive Parts Stores' },
    { id: 3, name: 'Bicycle Shops' },
    { id: 4, name: 'Book Stores' },
    { id: 5, name: 'Camera Supply Stores' },
    { id: 6, name: 'Candy Stores' },
    { id: 7, name: 'Childrens/Infants Wear Stores' },
    { id: 8, name: 'Cosmetic Stores' },
    { id: 9, name: 'Department Stores' },
    { id: 10, name: 'Drug Stores/Pharmacies' },
    { id: 11, name: 'Electronics Stores' },
    { id: 12, name: 'Grocery Stores/Supermarkets' },
    { id: 13, name: 'Hardware Stores' },
    { id: 14, name: 'Jewelry Stores' },
    { id: 15, name: 'Clothing Stores' },
    { id: 16, name: 'Miscellaneous Food Stores/Convenience Stores' },
    { id: 17, name: 'Music Stores' },
    { id: 18, name: 'Restaurant' }
  ];

  const minimumPayoutOptions = [1, 5, 10, 25, 50, 100];

  const showMessage = function (message) {
    notification.open({
      message: '',
      description: (
        <Comment
          author={<span>Grocefy</span>}
          avatar={<Avatar src={Grocefy} alt="grocefy" />}
          content={
            <p className="text-sm">
              {message}
            </p>
          }
          datetime={
            <Tooltip title={moment().format('YYYY-MM-DD HH:mm:ss')}>
              <span>{moment().fromNow()}</span>
            </Tooltip>
          }
        />
      ),
    });
  };

  const archiveClick = function () {
    setLoading(true);
    api.delete(`businesses/accounts/update/${selectedBusinessAccount.id}`)
      .then((response) => {
        setLoading(false);
        if (response.data.success) {
          setRefreshBusinessAccounts(true);
          setSelectedBusinessAccount(null);
        } else {
          console.error(response.data.error);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  const submitBusinessAccount = function () {
    setLoading(true);
    const url = selectedBusinessAccount.id ? `businesses/accounts/update/${selectedBusinessAccount.id}`
      : 'businesses/accounts/create';
    const temp = JSON.parse(JSON.stringify(businessAccount));
    temp.birthDate = moment(temp.birthDate).utc();
    if (temp.birthDate.length < 17) {
      temp.birthDate = `${temp.birthDate.replace(' ', 'T')}:00.000`;
    }
    api.post(
      url,
      temp
    )
      .then((response) => {
        setLoading(false);
        if (response.data.success) {
          setRefreshBusinessAccounts(true);
          setSelectedBusinessAccount(null);
        } else {
          showMessage(response.data.error);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  const updatePaymentProvider = async function () {
    await setLoading(true);
    const ipResponse = await api.get('test/getip');
    api.post(
      `businesses/accounts/update/provider/${selectedBusinessAccount.id}`,
      { AcceptanceIp: ipResponse.data.data }
    )
      .then((response) => {
        setLoading(false);
        setLoading(false);
        if (response.data.success) {
          showMessage(t('updated_payment_provider'));
        } else {
          showMessage(response.data.error);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  const checkIfDataValid = function () {
    let valid = true;
    if (businessAccount) {
      valid = valid && businessAccount.businessName
        && businessAccount.businessName.length > 0;

      valid = valid && businessAccount.ein
        && businessAccount.ein.length > 0;

      valid = valid && businessAccount.address1
        && businessAccount.address1.length > 0;

      // valid = valid && businessAccount.address2
      //    && businessAccount.address2.length > 0;

      valid = valid && businessAccount.country
         && businessAccount.country.length > 0;

      valid = valid && businessAccount.state
        && businessAccount.state.length > 0;

      valid = valid && businessAccount.city
        && businessAccount.city.length > 0;

      valid = valid && businessAccount.postalCode
        && businessAccount.postalCode.length > 0;

      valid = valid && businessAccount.accountHolderName
        && businessAccount.accountHolderName.length > 0;

      valid = valid && businessAccount.accountName
        && businessAccount.accountName.length > 0;

      valid = valid && businessAccount.accountNumber
        && businessAccount.accountNumber.length > 0;

      valid = valid && businessAccount.routingNumber
        && businessAccount.routingNumber.length > 0;

      valid = valid && businessAccount.firstName
        && businessAccount.firstName.length > 0;

      valid = valid && businessAccount.lastName
        && businessAccount.lastName.length > 0;

      valid = valid && businessAccount.contactEmail
        && businessAccount.contactEmail.length > 0;

      valid = valid && businessAccount.contactPhone
        && businessAccount.contactPhone.length > 0;

      valid = valid && businessAccount.last4Ssn
        && businessAccount.last4Ssn.length > 0;
    } else {
      valid = false;
    }
    setIsValid(valid);
  };

  useEffect(() => {
    if (selectedBusinessAccount) {
      const temp = JSON.parse(JSON.stringify(selectedBusinessAccount));
      if (!temp.id) {
        const minOption = minimumPayoutOptions[0];
        temp.minimumPayoutAmount = minOption;
        temp.birthDate = moment();
        temp.country = 'US';
        temp.businessId = null;
        temp.state = 'PR';
        temp.accountHolderType = 1;
        temp.processorPercentage = 0;
        temp.serviceFeePercentage = 0;
        temp.subtotalPercentage = 0;
        temp.taxesPercentage = 0;
        temp.businessType = 0;
        temp.payPalClientId = null;
      }
      setBusinessAccount(temp);
    }
  }, [selectedBusinessAccount]);

  useEffect(() => {
    if (businessAccount) {
      checkIfDataValid();
    }
  }, [businessAccount]);

  return (
    <Drawer
      title={`${businessAccount && businessAccount.id ? t('update') : t('create')}`}
      placement="right"
      closable
      onClose={() => setSelectedBusinessAccount(null)}
      open={selectedBusinessAccount}
      width={400}
    >
      {selectedBusinessAccount && (
        <>
          <div hidden={!businessAccount.id}>
            <div
              style={{
                float: 'right',
                marginBottom: '10px'
              }}
            >
              <Button
                type="primary"
                danger
                loading={loading}
                onClick={() => { archiveClick(); }}
              >
                {t('archive')}
              </Button>
            </div>
            <br />
            <br />
          </div>
          <div
            hidden={!selectedBusinessAccount.id}
            style={{ marginBottom: '10px' }}
          >
            <strong>{t('is_active')}</strong>
            <Switch
              disabled={loading}
              style={{ float: 'right' }}
              checked={businessAccount.isActive}
              onChange={(checked) => {
                const temp = JSON.parse(JSON.stringify(businessAccount));
                temp.isActive = checked;
                setBusinessAccount(temp);
              }}
            />
          </div>
          <Collapse>
            <Panel header={t('business_info')} key="1">
              <div className="flex flex-col">
                <strong>{t('store')}: </strong>
                <Select
                  defaultValue={businessAccount.businessId}
                  value={businessAccount.businessId}
                  style={{ width: 300 }}
                  disabled={loading}
                  onChange={(obj) => {
                    const temp = JSON.parse(JSON.stringify(businessAccount));
                    temp.businessId = obj;
                    setBusinessAccount(temp);
                  }}
                >
                  <Option value={null}>{t('global')}</Option>
                  {businesses.state === 'hasValue' && _.map(_.sortBy(businesses.contents.data.data.results, (s) => s.name, 'asc'), (business) => (
                    <Option
                      value={business.id}
                      key={business.id}
                    >
                      {business.name}
                    </Option>
                  ))}
                </Select>
              </div>
              <div style={{ marginBottom: 10 }}>
                <strong>{t('business_name')}</strong>
                <Input
                  disabled={loading}
                  placeholder={t('business_name')}
                  onChange={(obj) => {
                    const temp = JSON.parse(JSON.stringify(businessAccount));
                    temp.businessName = obj.target.value;
                    setBusinessAccount(temp);
                  }}
                  value={businessAccount.businessName}
                />
              </div>
              <div className="flex flex-col">
                <strong>{t('business_type')}: </strong>
                <Select
                  defaultValue={businessAccount.businessType}
                  value={businessAccount.businessType}
                  style={{ width: 300 }}
                  disabled={loading}
                  onChange={(obj) => {
                    const temp = JSON.parse(JSON.stringify(businessAccount));
                    temp.businessType = obj;
                    setBusinessAccount(temp);
                  }}
                >
                  {_.map(businessTypes, (type) => (
                    <Option
                      value={type.id}
                      key={type.id}
                    >
                      {type.name}
                    </Option>
                  ))}
                </Select>
              </div>
              <div style={{ marginBottom: 10 }}>
                <strong>EIN:</strong>
                <Input
                  disabled={loading}
                  placeholder="EIN"
                  onChange={(obj) => {
                    const temp = JSON.parse(JSON.stringify(businessAccount));
                    temp.ein = obj.target.value;
                    setBusinessAccount(temp);
                  }}
                  value={businessAccount.ein}
                />
              </div>
              <div style={{ marginBottom: 10 }}>
                <strong>{t('address_line_1')}:</strong>
                <Input
                  disabled={loading}
                  placeholder={t('address_line_1')}
                  onChange={(obj) => {
                    const temp = JSON.parse(JSON.stringify(businessAccount));
                    temp.address1 = obj.target.value;
                    setBusinessAccount(temp);
                  }}
                  value={businessAccount.address1}
                />
              </div>
              <div style={{ marginBottom: 10 }}>
                <strong>{t('address_line_2')}:</strong>
                <Input
                  disabled={loading}
                  placeholder={t('address_line_2')}
                  onChange={(obj) => {
                    const temp = JSON.parse(JSON.stringify(businessAccount));
                    temp.address2 = obj.target.value;
                    setBusinessAccount(temp);
                  }}
                  value={businessAccount.address2}
                />
              </div>
              <div style={{ marginBottom: 10 }}>
                <strong>{t('country')}:</strong>
                <Input
                  disabled={loading}
                  placeholder={t('country')}
                  onChange={(obj) => {
                    const temp = JSON.parse(JSON.stringify(businessAccount));
                    temp.country = obj.target.value;
                    setBusinessAccount(temp);
                  }}
                  value={businessAccount.country}
                />
              </div>
              <div style={{ marginBottom: 10 }}>
                <strong>{t('state')}:</strong>
                <Input
                  disabled={loading}
                  placeholder={t('state')}
                  onChange={(obj) => {
                    const temp = JSON.parse(JSON.stringify(businessAccount));
                    temp.state = obj.target.value;
                    setBusinessAccount(temp);
                  }}
                  value={businessAccount.state}
                />
              </div>
              <div style={{ marginBottom: 10 }}>
                <strong>{t('city')}:</strong>
                <Input
                  disabled={loading}
                  placeholder={t('city')}
                  onChange={(obj) => {
                    const temp = JSON.parse(JSON.stringify(businessAccount));
                    temp.city = obj.target.value;
                    setBusinessAccount(temp);
                  }}
                  value={businessAccount.city}
                />
              </div>
              <div style={{ marginBottom: 10 }}>
                <strong>{t('postal_code')}:</strong>
                <Input
                  disabled={loading}
                  placeholder={t('postal_code')}
                  onChange={(obj) => {
                    const temp = JSON.parse(JSON.stringify(businessAccount));
                    temp.postalCode = obj.target.value;
                    setBusinessAccount(temp);
                  }}
                  value={businessAccount.postalCode}
                />
              </div>
              <div style={{ marginBottom: 10 }}>
                <Row>
                  <Col>
                    <div style={{ marginRight: 5 }}>
                      <strong>{t('processor_%')}:</strong>
                      <br />
                      <InputNumber
                        disabled={loading}
                        min={0}
                        max={1}
                        onChange={(obj) => {
                          const temp = JSON.parse(JSON.stringify(businessAccount));
                          temp.processorPercentage = obj;
                          setBusinessAccount(temp);
                        }}
                        value={businessAccount.processorPercentage}
                      />
                    </div>
                  </Col>
                  <Col>
                    <div style={{ marginLeft: 5 }}>
                      <strong>{t('service_fee_%')}:</strong>
                      <br />
                      <InputNumber
                        disabled={loading}
                        min={0}
                        max={1}
                        onChange={(obj) => {
                          const temp = JSON.parse(JSON.stringify(businessAccount));
                          temp.serviceFeePercentage = obj;
                          setBusinessAccount(temp);
                        }}
                        value={businessAccount.serviceFeePercentage}
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div style={{ marginRight: 5 }}>
                      <strong>{t('subtotal')} %:</strong>
                      <br />
                      <InputNumber
                        disabled={loading}
                        min={0}
                        max={1}
                        onChange={(obj) => {
                          const temp = JSON.parse(JSON.stringify(businessAccount));
                          temp.subtotalPercentage = parseFloat(obj, 10);
                          setBusinessAccount(temp);
                        }}
                        value={businessAccount.subtotalPercentage}
                      />
                    </div>
                  </Col>
                  <Col>
                    <div style={{ marginLeft: 5 }}>
                      <strong>{t('taxes')} %:</strong>
                      <br />
                      <InputNumber
                        disabled={loading}
                        min={0}
                        max={1}
                        onChange={(obj) => {
                          const temp = JSON.parse(JSON.stringify(businessAccount));
                          temp.taxesPercentage = obj;
                          setBusinessAccount(temp);
                        }}
                        value={businessAccount.taxesPercentage}
                      />
                    </div>
                  </Col>
                </Row>
              </div>
              <div style={{ marginBottom: 10 }}>
                <strong>{t('min_payout_amount')}:</strong>
                {!showEditMinimumPayout && (
                  <>
                    <Select
                      value={businessAccount?.minimumPayoutAmount}
                      style={{ width: '90%' }}
                      disabled={loading}
                      showSearch="true"
                      onChange={(value) => {
                        setBusinessAccount({
                          ...businessAccount,
                          minimumPayoutAmount: value
                        });
                      }}
                    >
                      {_.map(minimumPayoutOptions, (p) => <Option value={p}>{p}</Option>)}
                    </Select>
                    <FormOutlined
                      style={{ marginLeft: 10 }}
                      onClick={() => { setShowEditMinimumPayout(true); }}
                    />
                  </>
                )}
                {showEditMinimumPayout && (
                  <>
                    <InputNumber
                      disabled={loading}
                      min={0}
                      onChange={(obj) => {
                        setBusinessAccount({
                          ...businessAccount,
                          minimumPayoutAmount: obj
                        });
                      }}
                      value={businessAccount?.minimumPayoutAmount}
                    />
                    <CloseCircleOutlined
                      style={{ marginLeft: 10 }}
                      onClick={() => { setShowEditMinimumPayout(false); }}
                    />
                  </>
                )}
              </div>
            </Panel>
            <Panel header={t('personal_info')} key="2">
              <div style={{ marginBottom: 10 }}>
                <strong>{t('first_name')}:</strong>
                <Input
                  disabled={loading}
                  placeholder={t('first_name')}
                  onChange={(obj) => {
                    const temp = JSON.parse(JSON.stringify(businessAccount));
                    temp.firstName = obj.target.value;
                    setBusinessAccount(temp);
                  }}
                  value={businessAccount.firstName}
                />
              </div>
              <div style={{ marginBottom: 10 }}>
                <strong>{t('last_name')}:</strong>
                <Input
                  disabled={loading}
                  placeholder={t('last_name')}
                  onChange={(obj) => {
                    const temp = JSON.parse(JSON.stringify(businessAccount));
                    temp.lastName = obj.target.value;
                    setBusinessAccount(temp);
                  }}
                  value={businessAccount.lastName}
                />
              </div>
              <div style={{ marginBottom: 10 }}>
                <strong>{t('phone')}:</strong>
                <Input
                  disabled={loading}
                  placeholder={t('phone')}
                  onChange={(obj) => {
                    const temp = JSON.parse(JSON.stringify(businessAccount));
                    temp.contactPhone = obj.target.value;
                    setBusinessAccount(temp);
                  }}
                  value={businessAccount.contactPhone}
                />
              </div>
              <div style={{ marginBottom: 10 }}>
                <strong>{t('email')}:</strong>
                <Input
                  disabled={loading}
                  placeholder={t('email')}
                  onChange={(obj) => {
                    const temp = JSON.parse(JSON.stringify(businessAccount));
                    temp.contactEmail = obj.target.value;
                    setBusinessAccount(temp);
                  }}
                  value={businessAccount.contactEmail}
                />
              </div>
              <div style={{ marginBottom: 10 }}>
                <strong>{t('social_security_last_4')}:</strong>
                <Input
                  disabled={loading}
                  placeholder={t('social_security_last_4')}
                  onChange={(obj) => {
                    const temp = JSON.parse(JSON.stringify(businessAccount));
                    temp.last4Ssn = obj.target.value;
                    setBusinessAccount(temp);
                  }}
                  value={businessAccount.last4Ssn}
                />
              </div>
              <div style={{ marginBottom: 10 }}>
                <strong>{t('birth_date')}: </strong>
                <DatePicker
                  onChange={(date, dateString) => {
                    const temp = JSON.parse(JSON.stringify(businessAccount));
                    temp.birthDate = dateString;
                    setBusinessAccount(temp);
                  }}
                  allowClear={false}
                  defaultValue={dayjs(businessAccount.birthDate)}
                />
              </div>
            </Panel>
            <Panel header={t('bank_info')} key="3">
              <div style={{ marginBottom: 10 }}>
                <strong>{t('paypal_client_id')}:</strong>
                <Input
                  disabled={loading}
                  placeholder={t('paypal_client_id')}
                  onChange={(obj) => {
                    const temp = JSON.parse(JSON.stringify(businessAccount));
                    temp.payPalClientId = obj.target.value;
                    setBusinessAccount(temp);
                  }}
                  value={businessAccount.payPalClientId}
                />
              </div>
              <div style={{ marginBottom: 10 }}>
                <strong>{t('account_holder')}:</strong>
                <Input
                  disabled={loading}
                  placeholder={t('account_holder')}
                  onChange={(obj) => {
                    const temp = JSON.parse(JSON.stringify(businessAccount));
                    temp.accountHolderName = obj.target.value;
                    setBusinessAccount(temp);
                  }}
                  value={businessAccount.accountHolderName}
                />
              </div>
              <div style={{ marginBottom: 10 }}>
                <strong>{t('account_holder_type')}</strong>
                <Select
                  defaultValue={businessAccount.accountHolderType}
                  style={{ width: '100%' }}
                  disabled={loading}
                  optionFilterProp="children"
                  onChange={(value) => {
                    const temp = JSON.parse(JSON.stringify(businessAccount));
                    temp.accountHolderType = value;
                    setBusinessAccount(temp);
                  }}
                >
                  <Option value={0}>
                    {t('individual')}
                  </Option>
                  <Option value={1}>
                    {t('company')}
                  </Option>
                </Select>
              </div>
              <div style={{ marginBottom: 10 }}>
                <strong>{t('account_name')}:</strong>
                <Input
                  disabled={loading}
                  placeholder={t('account_name')}
                  onChange={(obj) => {
                    const temp = JSON.parse(JSON.stringify(businessAccount));
                    temp.accountName = obj.target.value;
                    setBusinessAccount(temp);
                  }}
                  value={businessAccount.accountName}
                />
              </div>
              <div style={{ marginBottom: 10 }}>
                <strong>{t('account_number')}:</strong>
                <Input
                  disabled={loading}
                  placeholder={t('account_number')}
                  onChange={(obj) => {
                    const temp = JSON.parse(JSON.stringify(businessAccount));
                    temp.accountNumber = obj.target.value;
                    setBusinessAccount(temp);
                  }}
                  value={businessAccount.accountNumber}
                />
              </div>
              <div style={{ marginBottom: 10 }}>
                <strong>{t('routing_number')}:</strong>
                <Input
                  disabled={loading}
                  placeholder={t('routing_number')}
                  onChange={(obj) => {
                    const temp = JSON.parse(JSON.stringify(businessAccount));
                    temp.routingNumber = obj.target.value;
                    setBusinessAccount(temp);
                  }}
                  value={businessAccount.routingNumber}
                />
              </div>
            </Panel>
          </Collapse>
          <div
            style={{
              float: 'right',
              margin: '10px 0'
            }}
          >
            <Button
              type="primary"
              loading={loading}
              disabled={!isValid}
              onClick={() => { submitBusinessAccount(); }}
            >
              {businessAccount.id ? t('update') : t('create')}
            </Button>
            {businessAccount?.id && (
              <Button
                className="ml-2"
                type="primary"
                loading={loading}
                onClick={() => { updatePaymentProvider(); }}
              >
                {t('update_provider')}
              </Button>
            )}
          </div>
        </>
      )}
    </Drawer>
  );
}

export default EditBusinessAccount;
