import React from 'react';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import is from 'is_js';
import { Drawer } from 'antd';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  businessAtom,
  createBin,
  reloadBin,
} from '../../../atoms/Atoms';
import api from '../../../api/api';

function Create() {
  const [bin, setBin] = useRecoilState(createBin);
  const business = useRecoilValue(businessAtom);
  const [reload, setReload] = useRecoilState(reloadBin);
  const { t } = useTranslation();

  function prepareForm() {
    return (
      <div>
        {' '}
        <Formik
          initialValues={{
            businessId: business.id,
            rowTag: '',
            bins: '',
            type: '',
          }}
          validate={(values) => {
            const errors = {};
            if (is.empty(values.rowTag)) {
              errors.rowTag = t('row_tag_required');
            }
            if (is.empty(values.bins)) {
              errors.bins = t('bins_required');
            }
            if (is.empty(values.type)) {
              errors.type = t('type_required');
            }
            return errors;
          }}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(true);
            const temp = {
              businessId: business.id,
              rowTag: values.rowTag,
              bins: values.bins,
              type: values.type,
            };
            api
              .post(
                'businesses/bins/create',
                {
                  BusinessId: business.id,
                  RowTag: values.rowTag,
                  Bins: values.bins,
                  Type: parseInt(values.type, 10),
                }
              )
              .then((response) => {
                if (response && response.data.error) {
                  console.error('Error Message: ', response.data.error);
                }
                setSubmitting(false);
                setBin(false);
                setReload(true);
              })
              .catch((error) => {
                console.error(error);
                setSubmitting(false);
                setReload(false);
              });
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            isSubmitting,
          }) => (
            <form noValidate onSubmit={handleSubmit} className="text-sm">
              <div className="mb-3">
                <div className="font-bold tracking-tight mb-1">
                  {t('bins')} <span className="text-cyan-500">*</span>
                </div>
                <input
                  type="number"
                  name="bins"
                  id="bins"
                  placeholder="10"
                  className="text-gray-900 text-xs rounded p-4 w-full appearance-none ring-0 focus:ring-0 border border-gray-200 focus:border-gray-300 outline-none focus:outline-none transition ease-in-out duration-500"
                  value={values.bins}
                  onChange={handleChange}
                />
                {errors.bins && touched.bins && (
                  <div className="w-full font-semibold text-xs text-red-400 mt-1">
                    {errors.bins}
                  </div>
                )}
              </div>
              <div className="mb-3">
                <div className="font-bold tracking-tight mb-1">
                  {t('row_tag')} <span className="text-cyan-500">*</span>
                </div>
                <input
                  type="text"
                  name="rowTag"
                  id="rowTag"
                  placeholder="A"
                  className="text-gray-900 text-xs rounded p-4 w-full appearance-none ring-0 focus:ring-0 border border-gray-200 focus:border-gray-300 outline-none focus:outline-none transition ease-in-out duration-500"
                  value={values.rowTag}
                  onChange={handleChange}
                />
                {errors.rowTag && touched.rowTag && (
                  <div className="w-full font-semibold text-xs text-red-400 mt-1">
                    {errors.rowTag}
                  </div>
                )}
              </div>
              <div className="mb-3">
                <div className="font-bold tracking-tight mb-1">
                  {t('type')} <span className="text-cyan-500">*</span>
                </div>
                <select
                  placeholder="Please select a type"
                  name="type"
                  id="type"
                  value={values.type}
                  onChange={handleChange}
                >
                  <option value={0} key={0}>
                    {t('dry')}
                  </option>
                  <option value={1} key={1}>
                    {t('cool')}
                  </option>
                  <option value={2} key={2}>
                    {t('ice_cream')}
                  </option>
                  <option value={3} key={3}>
                    {t('freezer')}
                  </option>
                </select>
                {errors.type && touched.type && (
                  <div className="w-full font-semibold text-xs text-red-400 mt-1">
                    {errors.type}
                  </div>
                )}
              </div>
              <div className="flex space-x-3 mt-10">
                <button
                  className="w-full md:w-1/2 text-xs transition delay-150 duration-300 ease-in-out border border-gray-700 hover:border-red-600 text-gray-500 hover:text-red-400 font-semibold px-6 py-3 rounded-full"
                  type="button"
                  onClick={(e) => {
                    e.preventDefault();
                    setBin(false);
                    setReload(false);
                  }}
                >
                  {t('cancel')}
                </button>
                <button
                  className="w-full md:w-1/2 flex items-center justify-center text-center text-xs transition delay-150 duration-300 ease-in-out bg-blue-500 hover:bg-blue-600 text-white font-semibold px-6 py-3 rounded-full"
                  type="submit"
                  disabled={isSubmitting}
                >
                  {' '}
                  {isSubmitting && (
                    <div>
                      <svg
                        className="animate-spin -ml-1 mr-3 h-4 w-4 text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        />
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        />
                      </svg>
                    </div>
                  )}
                  {t('save')}
                </button>
              </div>
            </form>
          )}
        </Formik>
      </div>
    );
  }

  return (
    <div>
      <Drawer
        title={t('create_bins')}
        placement="right"
        closable
        onClose={() => setBin(false)}
        open={createBin}
        width={300}
      >
        <div> </div>
        <div>{prepareForm()}</div>
      </Drawer>
    </div>
  );
}

export default Create;
