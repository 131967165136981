import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import {
  useRecoilState,
  useSetRecoilState
} from 'recoil';
import {
  Drawer,
  Avatar,
  Tooltip,
  DatePicker,
  Image,
  Input,
  notification,
  Table
} from 'antd';
import { Comment } from '@ant-design/compatible';
import moment from 'moment';
import Grocefy from '../../../../assets/images/grocefyLogoAlone.png';
import {
  selectedPromotionAtom,
  refreshPromotionsAtom
} from '../../../../atoms/Atoms';
import api from '../../../../api/api';

function EditPromotion() {
  const { Search } = Input;
  const { RangePicker } = DatePicker;
  const setRefreshPromotions = useSetRecoilState(refreshPromotionsAtom);
  const [selectedPromotion, setSelectedPromotion] = useRecoilState(selectedPromotionAtom);
  const [loadingItems, setLoadingItems] = useState(false);
  const [data, setData] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const { t } = useTranslation();

  const showMessage = function (message) {
    notification.open({
      message: '',
      description: (
        <Comment
          author={<span>Grocefy</span>}
          avatar={<Avatar src={Grocefy} alt="grocefy" />}
          content={
            <p className="text-sm">
              {message}
            </p>
          }
          datetime={
            <Tooltip title={moment().format('YYYY-MM-DD HH:mm:ss')}>
              <span>{moment().fromNow()}</span>
            </Tooltip>
          }
        />
      ),
    });
  };

  function mapData(items) {
    const mapped = _.map(items, (p, index) => ({
      key: index,
      imageUrl: p.imageUrl,
      name: `${p.brand && p.brand !== null ? p.brand : ''} ${p.name && p.name !== null ? p.name : ''} ${p.description && p.description !== null ? p.description : ''}`,
      promoPrice: p.promoPrice,
      promoMultiple: p.promoMultiple,
      promoPriceMethod: p.promoPriceMethod === 1 ? t('Split') : t('Base'),
      activePrice: p.promoPriceMethod === 1
        && p.promoMultiple !== 0 ? (p.promoPrice / p.promoMultiple) : p.promoPrice,
      gtin: p.gtin,
      searchField: `${p.gtin} ${p.brand && p.brand !== null ? p.brand : ''} ${p.name && p.name !== null ? p.name : ''} ${p.description && p.description !== null ? p.description : ''}`
    }));
    return mapped;
  }

  const getItems = function (promoId) {
    setLoadingItems(true);
    api.get(`marketing/promotion/items/${promoId}`)
      .then((response) => {
        setLoadingItems(false);
        if (response.data.success) {
          setData({
            ...data,
            items: mapData(response.data.data.results)
          });
        } else {
          showMessage(t('api_error'));
        }
      })
      .catch((error) => {
        setLoadingItems(false);
        showMessage(error.message);
        console.error(error);
      });
  };
  //   const submit = function () {
  //     setLoading(true);
  //     const temp = JSON.parse(JSON.stringify(data));
  //     temp.startDate = moment(temp.startDate).utc();
  //     temp.endDate = moment(temp.endDate).utc();
  //     if (temp.startDate.length < 17) {
  //       temp.startDate = `${temp.startDate.replace(' ', 'T')}:00.000`;
  //       temp.endDate = `${temp.endDate.replace(' ', 'T')}:00.000`;
  //     }
  //     api.post('marketing/sponsorships', temp)
  //       .then((response) => {
  //         setLoading(false);
  //         if (response.data.success) {
  //           setRefreshPromotions(true);
  //           setShowCreateSponsorshipDrawer(false);
  //           setShowEditSponsorshipDrawer(false);
  //           if (selectedPromotion.id) {
  //             const [updatedCampaign] = _.filter(response.data.data.results, (c) => c.id
  //               === selectedPromotion.id);
  //             setSelectedPromotion(updatedCampaign);
  //           }
  //         } else {
  //           showMessage(response.data.error);
  //         }
  //       })
  //       .catch((error) => {
  //         setLoading(false);
  //         console.error(error);
  //       });
  //   };

  useEffect(() => {
    if (selectedPromotion) {
      const temp = {
        ...selectedPromotion
      };
      // if (!temp.startDate || temp.startDate.length === 0) {
      //   temp.startDate = moment();
      // }
      // if (!temp.endDate || temp.endDate.length === 0) {
      //   temp.endDate = moment();
      // }
      setData(temp);
      if (temp.id) {
        getItems(temp.id);
      }
    }
  }, [selectedPromotion]);

  return (
    <Drawer
      title={t('promotions')}
      placement="right"
      closable
      onClose={() => {
        setSelectedPromotion(null);
      }}
      open={selectedPromotion}
      width={600}
    >
      {data && (
        <>
          <div style={{ marginBottom: 10 }}>
            <strong>{t('name')}: {selectedPromotion?.name}</strong>
            {/* <Input
              disabled={loadingItems || true}
              placeholder={t('name')}
              value={data?.name}
            /> */}
          </div>
          <div style={{ marginBottom: 10 }}>
            <strong className="mr-2">
              {`${t('start_date')} -> ${t('end_date')}:`}
            </strong>
            <br />
            <strong>
              {`${moment(`${selectedPromotion?.startDate}+0000`).format('MMMM Do YYYY, h:mm:ss a')} ->
              ${moment(`${selectedPromotion?.endDate}+0000`).format('MMMM Do YYYY, h:mm:ss a')}`}
            </strong>
            {/* <RangePicker
              disabled
              showTime={{ format: 'HH:mm' }}
              format="YYYY-MM-DD HH:mm"
              defaultValue={[
                moment(data?.startDate),
                moment(data?.endDate)
              ]}
            /> */}
          </div>
          <Search
            placeholder={`${t('search')}...`}
            onChange={(obj) => {
              setSearchTerm(obj.target.value.toLocaleLowerCase());
            }}
          />
          <Table
            size="small"
            bordered
            pagination={false}
            loading={loadingItems}
            dataSource={_.filter(data?.items,
              (i) => i.searchField.toLocaleLowerCase().includes(searchTerm))}
            columns={[
              {
                title: t('image'),
                align: 'center',
                className: 'text-xs',
                render: (row) => (
                  <Image
                    width={50}
                    src={row.imageUrl}
                    alt={`${row.brand} ${row.name} ${row.description}`}
                  />
                ),
              },
              // {
              //   title: 'GTIN',
              //   align: 'center',
              //   className: 'text-xs',
              //   render: (row) => (
              //     <span>{row.gtin}</span>
              //   ),
              // },
              // {
              //   title: t('name'),
              //   align: 'left',
              //   className: 'text-xs',
              //   render: (row) => (
              //     <span>{row.brand} {row.name} {row.description}</span>
              //   ),
              // },
              {
                title: t('product'),
                align: 'left',
                className: 'text-xs',
                render: (row) => (
                  <div className="flex flex-col">
                    <span>{row.gtin}</span>
                    <span>{row.brand} {row.name} {row.description}</span>
                  </div>
                ),
              },
              {
                title: t('active_price'),
                align: 'right',
                className: 'text-xs',
                render: (row) => (
                  <span>${row.activePrice.toFixed(2)}</span>
                ),
              },
              // {
              //   title: t('promo_price_method'),
              //   align: 'center',
              //   className: 'text-xs',
              //   render: (row) => (
              //     <span>{row.promoPriceMethod}</span>
              //   ),
              // },
              // {
              //   title: t('promo_multiple'),
              //   align: 'center',
              //   className: 'text-xs',
              //   render: (row) => (
              //     <span>{row.promoMultiple}</span>
              //   ),
              // },
              // {
              //   title: t('promo_price'),
              //   align: 'right',
              //   className: 'text-xs',
              //   render: (row) => (
              //     <span>${row.activePrice.toFixed(2)}</span>
              //   ),
              // },
              {
                title: t('promotion'),
                align: 'left',
                className: 'text-xs',
                width: 100,
                render: (row) => (
                  <div className="flex flex-col">
                    <span>Method: {row.promoPriceMethod}</span>
                    <span>Multiple: {row.promoMultiple}</span>
                    <span>Price: ${row.promoPrice.toFixed(2)}</span>
                  </div>
                ),
              },
            ]}
          />
          {/* <div
            style={{
              float: 'right',
              marginTop: '10px'
            }}
          >
            <Button
              style={{
                marginLeft: '10px'
              }}
              disabled={loading}
              type="primary"
              onClick={() => { submit(); }}
            >
              {data.id ? t('update') : t('create')}
            </Button>
          </div> */}
        </>
      )}
    </Drawer>
  );
}

export default EditPromotion;
