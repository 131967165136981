import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import {
  useRecoilState,
} from 'recoil';
import { useTranslation } from 'react-i18next';
import {
  Drawer,
  Avatar,
  Tooltip,
  Input,
  Button,
  Checkbox,
  notification,
  Spin,
  Divider,
  Tag,
  Popover,
  Typography
} from 'antd';
import { Comment } from '@ant-design/compatible';
import {
  SearchOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import Grocefy from '../../../../assets/images/grocefyLogoAlone.png';
import imagesApi from '../../../../api/imagesApi';
import envConfig from '../../../../envConfig';
import {
  setSelectedMasterItemAtom,
  setSearchMetadataAtom
} from '../../../../atoms/Atoms';
import api from '../../../../api/api';

function MetadataDrawer() {
  const { t } = useTranslation();
  const [images, setImages] = useState([]);
  const [selectedMasterItem, setSelectedMasterItem] = useRecoilState(setSelectedMasterItemAtom);
  const [searchMetadata, setSearchMetadata] = useRecoilState(setSearchMetadataAtom);
  const [loading, setLoading] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [itemData, setItemData] = useState(null);
  // const [searchStarted, setSearchStarted] = useState(false);
  const [useImageFlag, setUseImageFlag] = useState(false);
  const [useNameFlag, setUseNameFlag] = useState(false);
  const [useSearchTermsFlag, setUseSearchTermsFlag] = useState(false);

  const showMessage = function (message) {
    notification.open({
      message: '',
      description: (
        <Comment
          author={<span>Grocefy</span>}
          avatar={<Avatar src={Grocefy} alt="grocefy" />}
          content={
            <p className="text-sm">
              {message}
            </p>
          }
          datetime={
            <Tooltip title={moment().format('YYYY-MM-DD HH:mm:ss')}>
              <span>{moment().fromNow()}</span>
            </Tooltip>
          }
        />
      ),
    });
  };

  const checkItemValid = function () {
    if (itemData.gtin.length === 0) {
      showMessage('Must enter a GTIN');
      return false;
    }
    if (itemData.name.length === 0) {
      showMessage('Must enter a Name');
      return false;
    }
    return true;
  };

  function UpdateMetadataOnItemMaster(obj) {
    const isValid = checkItemValid();
    if (isValid) {
      setUpdating(true);
      api.post(
        `businesses/catalog/${selectedMasterItem.id}/update`,
        obj
      )
        .then((response) => {
          setUpdating(false);
          if (!response.data.success) {
            showMessage(response.data.error);
          }
          showMessage('Product Metadata was updates successfully!');
        })
        .catch((error) => {
          setLoading(false);
          console.error(error);
        });
    }
  }

  function UpdateProductMetadata(value) {
    if (!useSearchTermsFlag && !useImageFlag && !useNameFlag) {
      showMessage('Please select which data to copy to item.');
    }
    const temp = JSON.parse(JSON.stringify(itemData));
    if (useImageFlag) {
      const imageUrl = `${envConfig.REACT_APP_IMAGES_URL}/query/photos-sm/${value.id}?subscription-key=${envConfig.REACT_APP_IMAGES_KEY}`;
      api
        .post('businesses/catalog/media',
          {
            Url: imageUrl,
            ThumbnailUrl: imageUrl,
            Type: 0,
            ItemId: temp.id,
            Ordering: 99
          });
    }
    if (useNameFlag) {
      temp.name = value.itemName;
      temp.brand = value.itemBrand.toUpperCase();
      temp.description = value.itemDescription;
    }
    if (useSearchTermsFlag) {
      temp.searchTags = `${value.itemSearchTerms}`.toUpperCase();
    }
    if (useSearchTermsFlag || useImageFlag || useNameFlag) {
      UpdateMetadataOnItemMaster(temp);
      setSearchMetadata(null);
      setSelectedMasterItem(null);
    }
  }

  const search = function (value) {
    setLoading(true);
    let params = '';
    if (value?.length > 0) {
      params += `${params.length > 0 ? '&' : ''}searchTerm=${value}`;
    }
    imagesApi.get(`search?${params}`)
      .then((response) => {
        setLoading(false);
        // console.log('Response:', response.data);
        setImages(response.data);
        // console.log('Finished Loading:', images);
      })
      .catch((error) => {
        setLoading(false);
        showMessage(error.message);
        console.error(error);
      });
  };

  useEffect(() => {
    if (selectedMasterItem) {
      setItemData(selectedMasterItem);
      search(selectedMasterItem.gtin);
    }
  }, [selectedMasterItem]);

  return (
    <Drawer
      title={t('product_metadata')}
      placement="right"
      closable
      onClose={() => setSearchMetadata(null)}
      open={searchMetadata}
      width={450}
    >
      <>
        <Input.Search
          placeholder={t('item_search_placeholder')}
          allowClear
          loading={loading}
          disabled={updating}
          enterButton={t('search')}
          size="small"
          onSearch={(term) => search(term)}
        />
        {!loading && (
          <strong>{t('results')}: {images?.length}</strong>
        )}
        <div className="font-medium text-md mt-2">{t('choose_information_to_copy')}:</div>
        <div className="flex flex-col mb-4">
          <div><Checkbox onClick={() => { setUseImageFlag(!useImageFlag); }} checked={useImageFlag} key="UseImageChkbx">{t('image')}</Checkbox></div>
          <div><Checkbox onClick={() => { setUseNameFlag(!useNameFlag); }} checked={useNameFlag} key="UseNameChkbx">{t('name')}</Checkbox></div>
          <div><Checkbox onClick={() => { setUseSearchTermsFlag(!useSearchTermsFlag); }} checked={useSearchTermsFlag} key="UseSearchTermsChkbx">{t('search_tags')}</Checkbox></div>
        </div>
        <Divider />
        <Spin spinning={loading || updating}>
          {_.map(images, (i) => (
            <div className="my-2 border rounded rounded-xl">
              <div className="flex flex-cols-3 px-2 py-2w-full">
                <img
                  className="mt-4 mr-2"
                  width={80}
                  src={`${envConfig.REACT_APP_IMAGES_URL}/query/photos-sm/${i.id}?subscription-key=${envConfig.REACT_APP_IMAGES_KEY}`}
                  alt={`${i.itemBrand} ${i.itemName} ${i.itemDescription}`}
                />
                <div className="w-full">
                  <Typography.Text copyable>{i.itemGtin}</Typography.Text><br />
                  <Typography.Text className="font-medium">{t('brand')}:</Typography.Text> {i.itemBrand}<br />
                  <Typography.Text className="font-medium">{t('name')}:</Typography.Text> {i.itemName}<br />
                  <Typography.Text className="font-medium">{t('description')}:</Typography.Text> {i.itemDescription}<br />
                </div>
                <Button
                  className="mt-8"
                  onClick={() => { UpdateProductMetadata(i); }}
                  type="primary"
                >
                  {t('use')}
                </Button>
              </div>
              <Popover
                title={t('search_tags')}
                content={
                  <Typography.Paragraph>
                    {i.itemSearchTerms}
                  </Typography.Paragraph>
                }
              >
                <Tag className="w-5/6 ml-8 my-2 text-ellipsis overflow-hidden">
                  <Typography.Text>
                    <SearchOutlined className="mr-2" /> {i.itemSearchTerms}
                  </Typography.Text>
                </Tag>
              </Popover>
            </div>
          ))}
        </Spin>
      </>
    </Drawer>
  );
}

export default MetadataDrawer;
