import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import {
  Table,
  Button,
  Upload,
  Modal,
  notification,
  Avatar,
  Select,
  Popconfirm
} from 'antd';
import { Comment } from '@ant-design/compatible';
import { PageHeader } from '@ant-design/pro-layout';
import {
  InboxOutlined,
  CopyOutlined
} from '@ant-design/icons';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Grocefy from '../../../../assets/images/grocefyLogoAlone.png';
import api from '../../../../api/api';

function StorageDocuments() {
  const { Dragger } = Upload;
  const { Option } = Select;
  const containers = [
    { key: 'sharedassets', name: 'Shared Assets' },
    { key: 'documents', name: 'Documents' },
    { key: 'app', name: 'App' },
    { key: 'web', name: 'Web' },
    { key: 'backgroundimages', name: 'Backgrounds' },
    { key: 'gallery', name: 'Gallery' },
  ];
  const [loading, setLoading] = useState(false);
  const [showUpload, setShowUpload] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [fileData, setFileData] = useState(null);
  const [selectedContainer, setSelectedContainer] = useState('sharedassets');
  const [filteredDocuments, setFilteredDocuments] = useState([]);
  const [filters, setFilters] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState('');
  const { t } = useTranslation();

  const draggerProps = {
    name: 'file',
    multiple: false,
    showUploadList: false,
    beforeUpload: (file) => {
      setFileData(file);
      return false;
    }
  };
  const getDocuments = function () {
    setLoading(true);
    api
      .post(
        'utility/global/documents',
        {
          containerName: selectedContainer
        }
      )
      .then((response) => {
        setLoading(false);
        const temp = _.map(response.data.data.results, (u, index) => ({
          url: u.url,
          name: u.name,
          fileType: u.fileType,
          id: index
        }));
        const tempFilters = _.map(response.data.data.results, (u, index) => (u.fileType));
        setFilters(_.uniq(tempFilters));
        setSelectedFilter('all');
        setDocuments(temp);
      }).catch((err) => {
        setLoading(false);
        console.error(err);
      });
  };

  const deleteFile = function (fileName) {
    setLoading(true);
    api
      .delete(`utility/file/${selectedContainer}/${fileName}`)
      .then((response) => {
        setLoading(false);
        getDocuments();
      }).catch((err) => {
        setLoading(false);
        console.error(err);
      });
  };

  const uploadFile = async function (temp) {
    const file = temp;
    const boundary = Date.now();
    const data = new FormData();
    data.append('file', file);
    data.append('container', selectedContainer);
    setLoading(true);
    const response = await api
      .post(`utility/${selectedContainer}/uploadfile`, data);
    setLoading(false);
    if (response.data.success) {
      return response.data.data;
    }
    return null;
  };
  const filterDocuments = function () {
    if (selectedFilter === 'all') {
      setFilteredDocuments(documents);
    } else {
      const temp = _.filter(documents, (document) => document.fileType === selectedFilter);
      setFilteredDocuments(temp);
    }
  };
  useEffect(async () => {
    if (fileData) {
      const url = await uploadFile(fileData);
      setShowUpload(false);
      getDocuments();
    }
  }, [fileData]);
  useEffect(() => {
    if (documents) {
      filterDocuments();
    }
  }, [selectedFilter, documents]);
  useEffect(() => {
    getDocuments();
  }, []);
  useEffect(() => {
    getDocuments();
  }, [selectedContainer]);

  return (
    <div>
      <PageHeader
        title={t('files')}
        extra={[
          <Select
            style={{ width: 130 }}
            key="container"
            value={selectedContainer}
            onChange={(value) => setSelectedContainer(value)}
            defaultValue=""
          >
            {_.map(containers, (container) => (
              <Option value={container.key}>{container.name}</Option>
            ))}
          </Select>,
          <Select
            style={{ width: 100 }}
            loading={loading}
            key="filter"
            value={selectedFilter}
            onChange={(value) => setSelectedFilter(value)}
            defaultValue=""
          >
            <Option value="all">{t('all')}</Option>
            {_.map(filters, (filter) => (
              <Option value={filter}>&lsquo;.{filter}&rsquo;</Option>
            ))}
          </Select>,
          <Button
            type="primary"
            key="create"
            size="small"
            onClick={() => {
              setShowUpload(true);
            }}
          >
            {t('upload')}
          </Button>,
          <Button
            type="primary"
            key="reload"
            size="small"
            onClick={() => {
              getDocuments();
            }}
          >
            {t('reload')}
          </Button>
        ]}
      />
      <Table
        bordered
        loading={loading}
        rowKey="id"
        dataSource={filteredDocuments}
        columns={[
          {
            title: t('name'),
            align: 'center',
            dataIndex: 'name',
            className: 'text-xs',
            render: (text) => <span>{text}</span>,
          },
          {
            title: t('file_type'),
            align: 'center',
            dataIndex: 'fileType',
            className: 'text-xs',
            render: (text) => <span>{text}</span>,
          },
          {
            title: t('actions'),
            align: 'center',
            className: 'text-xs',
            render: (text) => (
              <>
                <Button
                  type="primary"
                  size="small"
                  className="mr-2"
                  onClick={() => {
                    navigator.clipboard.writeText(text.url);
                    notification.open({
                      message: 'Alert!',
                      description: (
                        <Comment
                          author={<span>Grocefy</span>}
                          avatar={<Avatar src={Grocefy} alt="grocefy" />}
                          content={
                            <p className="text-sm">{t('url_copied_content')}</p>
                          }
                        />
                      ),
                    });
                  }}
                >
                  <CopyOutlined /> {t('copy_url')}
                </Button>
                <Button
                  type="primary"
                  className="mr-2"
                  size="small"
                  target="_blank"
                  href={text.url}
                >
                  {t('download')}
                </Button>
                <Popconfirm
                  key="clone"
                  title={t('delete_file_prompt')}
                  okText={t('yes')}
                  onConfirm={() => deleteFile(text.name)}
                >
                  <Button
                    size="small"
                    type="primary"
                    danger
                    className="mr-2"
                  >
                    <FontAwesomeIcon icon={faTrash} className="text-xs" />
                  </Button>
                </Popconfirm>
              </>
            ),
          }
        ]}
      />
      <Modal
        title={t('upload_file')}
        open={showUpload}
        onCancel={() => setShowUpload(false)}
        footer={[
          <Button
            key="ok"
            type="primary"
            loading={loading}
            onClick={() => setShowUpload(false)}
          >
            {t('close')}
          </Button>
        ]}
      >
        <Dragger {...draggerProps}>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p
            className="ant-upload-text"
            style={{ margin: 10 }}
          >
            {t('click_or_drag_content')}
          </p>
        </Dragger>
      </Modal>
    </div>
  );
}

export default StorageDocuments;
