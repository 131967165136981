import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import {
  useRecoilValue,
  useRecoilState,
  useRecoilValueLoadable
} from 'recoil';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Input,
  Avatar,
  Table,
  notification,
  Tag,
  Tooltip
} from 'antd';
import { Comment } from '@ant-design/compatible';
import { PageHeader } from '@ant-design/pro-layout';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import {
  PlusOutlined
} from '@ant-design/icons';
import Grocefy from '../../../assets/images/grocefyLogoAlone.png';
import {
  businessAtom,
  selectedRequirementAtom,
  refreshRequirementsAtom,
  selectedOptionAtom,
  stateAtom,
  profileAtom
} from '../../../atoms/Atoms';
import EditItemRequirement from './shared-components/EditItemRequirement';
import EditRequirementOption from './shared-components/EditRequirementOption';
import api from '../../../api/api';

function ItemRequirements() {
  const { Search } = Input;
  const currentState = useRecoilValue(stateAtom);
  const store = useRecoilValue(businessAtom);
  const selectedOption = useRecoilValue(selectedOptionAtom);
  const [selectedRequirement, setSelectedRequirement] = useRecoilState(selectedRequirementAtom);
  const [refreshRequirements, setRefreshRequirements] = useRecoilState(refreshRequirementsAtom);
  const [loading, setLoading] = useState(false);
  const [requirements, setRequirements] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const [searchText, setSearchText] = useState('');
  const { t } = useTranslation();

  const userProfile = useRecoilValueLoadable(profileAtom);
  const isAdmin =
    userProfile.contents && userProfile?.contents?.roles?.includes('SuperAdmin');
  const isCustomerService =
    isAdmin || (userProfile?.contents && (userProfile?.contents?.roles?.includes('CustomerServiceAdmin') || userProfile?.contents?.roles?.includes('CustomerService')));
  const isStoreManager =
    isAdmin || isCustomerService || (userProfile?.contents && (userProfile?.contents?.roles?.includes('StoreManager') || userProfile?.contents?.roles?.includes('StoreAdmin')));

  const history = useHistory();

  if (!isAdmin && !isCustomerService && !isStoreManager) {
    history.push('/');
    return null;
  }

  const showMessage = function (message) {
    notification.open({
      message: '',
      description: (
        <Comment
          author={<span>Grocefy</span>}
          avatar={<Avatar src={Grocefy} alt="grocefy" />}
          content={
            <p className="text-sm">
              {message}
            </p>
          }
          datetime={
            <Tooltip title={moment().format('YYYY-MM-DD HH:mm:ss')}>
              <span>{moment().fromNow()}</span>
            </Tooltip>
          }
        />
      ),
    });
  };

  const getRequirements = function () {
    setLoading(true);
    let url = 'deli/requirements';
    if (!currentState.globalReqSideAddons && store) {
      url = `${store.id}/${url}`;
    }
    api
      .get(url)
      .then((response) => {
        if (response.data.success) {
          setLoading(false);
          setRefreshRequirements(false);
          setRequirements(response.data.data.results);
        } else {
          showMessage(response.data.error);
        }
      })
      .catch((error) => {
        setLoading(false);
        showMessage(error);
      });
  };

  const filter = function () {
    if (searchText.length > 0) {
      const temp = _.filter(requirements, (row) => row.name.toUpperCase()
        .includes(searchText.toUpperCase()) ||
        row.internalName.toUpperCase().includes(searchText.toUpperCase()));
      setFiltered(temp);
    } else {
      setFiltered(requirements);
    }
  };

  useEffect(() => {
    getRequirements();
  }, []);

  useEffect(() => {
    if (refreshRequirements) {
      getRequirements();
    }
  }, [refreshRequirements]);

  useEffect(() => {
    if (requirements) {
      filter();
    }
  }, [requirements, searchText]);

  return (
    <>
      <PageHeader
        title={t('requirements')}
        extra={[
          <Tag
            key="create"
            color="#1890ff"
            onClick={() => {
              setSelectedRequirement({});
            }}
          >
            <PlusOutlined /> Create
          </Tag>
        ]}
      />
      <Search
        placeholder={t('search_requirements')}
        allowClear
        onChange={(obj) => {
          setSearchText(obj.target.value);
        }}
        value={searchText}
      />
      <Table
        size="small"
        bordered
        loading={loading}
        pagination={false}
        columns={[
          {
            title: t('internal_name'),
            dataIndex: 'internalName',
            key: 'internalName',
            align: 'center',
            className: 'text-xs',
            render: (text) => (
              <span>{text}</span>
            ),
          },
          {
            title: t('name'),
            dataIndex: 'name',
            key: 'name',
            align: 'center',
            className: 'text-xs',
            render: (text) => (
              <span>{text}</span>
            ),
          },
          {
            title: t('minimum'),
            dataIndex: 'minRequired',
            key: 'minRequired',
            align: 'center',
            className: 'text-xs',
            render: (text) => (
              <span>{text}</span>
            ),
          },
          {
            title: t('maximum'),
            dataIndex: 'maxRequired',
            key: 'maxRequired',
            align: 'center',
            className: 'text-xs',
            render: (text) => (
              <span>{text}</span>
            ),
          },
          {
            title: '',
            align: 'center',
            className: 'text-xs',
            render: (row) => (
              <Button
                size="small"
                type="primary"
                onClick={() => {
                  setSelectedRequirement(row);
                }}
              >
                {t('edit')}
              </Button>
            ),
          }
        ]}
        rowKey="id"
        dataSource={filtered}
        expandable={{
          expandedRowRender: (record) => (
            <Table
              size="small"
              bordered
              dataSource={record.items}
              pagination={false}
              rowKey="id"
              columns={[
                {
                  title: t('image'),
                  align: 'center',
                  className: 'text-xs',
                  render: (row) => (
                    <>
                      {row.imageUrl && row.imageUrl.length > 0 && (
                        <img
                          style={{ margin: 'auto' }}
                          width={50}
                          src={row.imageUrl}
                          alt={row.name}
                        />
                      )}
                    </>
                  ),
                },
                {
                  title: t('name'),
                  dataIndex: 'name',
                  key: 'name',
                  align: 'center',
                  className: 'text-xs',
                  render: (text) => (
                    <span>{text}</span>
                  ),
                },
                {
                  title: t('description'),
                  dataIndex: 'description',
                  key: 'description',
                  align: 'center',
                  className: 'text-xs',
                  render: (text) => (
                    <span>{text}</span>
                  ),
                }
              ]}
            />
          ),
          rowExpandable: (record) => record.items,
        }}
      />
      {selectedRequirement && !selectedOption && (
        <EditItemRequirement />
      )}
      {selectedRequirement && selectedOption && (
        <EditRequirementOption />
      )}
    </>
  );
}

export default ItemRequirements;
