import React, { useEffect } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import Activities from './shared-components/Activities';
import Header from './shared-components/Header';
import {
  paypalPageAtom,
  paypalRowsAtom,
  selectedBusinessAccountPayoutsAtom,
  paypalPayoutsAtom,
  loadingPaypalPayoutsAtom,
  refreshPaypalPayoutsAtom
} from '../../../../atoms/Atoms';
import api from '../../../../api/api';

function Paypal() {
  const selectedBusinessAccountPayouts = useRecoilValue(selectedBusinessAccountPayoutsAtom);
  const [page, setPage] = useRecoilState(paypalPageAtom);
  const [rows, setRows] = useRecoilState(paypalRowsAtom);
  const setPaypalPayouts = useSetRecoilState(paypalPayoutsAtom);
  const setLoadingPaypalPayouts = useSetRecoilState(loadingPaypalPayoutsAtom);
  const [refreshPaypalPayouts, setRefreshPaypalPayouts] = useRecoilState(refreshPaypalPayoutsAtom);
  function getData() {
    setLoadingPaypalPayouts(true);
    setRefreshPaypalPayouts(false);
    let currentPage = page;
    if (currentPage === 0) {
      currentPage = 1;
    }
    let url = `payments/global/payoutswithcountbytype/1/${currentPage - 1}/${rows}`;
    if (selectedBusinessAccountPayouts) {
      url += `/${selectedBusinessAccountPayouts}`;
    }
    api
      .get(url)
      .then((response) => {
        setLoadingPaypalPayouts(false);
        if (response.data.success) {
          setPaypalPayouts(response.data.data);
        } else {
          setPaypalPayouts(null);
        }
      })
      .catch(() => {
        setLoadingPaypalPayouts(false);
      });
  }
  useEffect(async () => {
    await setPage(1);
    await setRows(10);
    getData();
  }, []);
  useEffect(() => {
    getData();
  }, [selectedBusinessAccountPayouts, page, refreshPaypalPayouts, rows]);
  useEffect(() => {
    if (refreshPaypalPayouts) {
      getData();
    }
  }, [refreshPaypalPayouts]);
  return (
    <>
      <Header />
      {/* <Balances /> */}
      {/* <Divider /> */}
      <Activities />
    </>
  );
}

export default Paypal;
