import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import {
  useRecoilValue,
  useRecoilState,
  useSetRecoilState,
  useRecoilValueLoadable
} from 'recoil';
import {
  Button,
  Table,
  Avatar,
  Tooltip,
  Input,
  Popconfirm,
  notification,
  Divider,
  Collapse
} from 'antd';
import { Comment } from '@ant-design/compatible';
import { PageHeader } from '@ant-design/pro-layout';
import moment from 'moment';
import Grocefy from '../../../assets/images/grocefyLogoAlone.png';
import {
  timezoneAtom,
  selectedSponsorshipAtom,
  refreshSponsorshipsAtom,
  showEditSponsorshipDrawerAtom
} from '../../../atoms/Atoms';
import { businessesSelector } from '../../../atoms/Selectors';
import EditSponsorship from './shared-components/EditSponsorship';
import api from '../../../api/api';

function Sponsorship() {
  const history = useHistory();
  const { Panel } = Collapse;
  const businesses = useRecoilValueLoadable(businessesSelector);
  const timezone = useRecoilValue(timezoneAtom);
  const { sponsorshipId } = useParams();
  const { t } = useTranslation();

  const [showEditSponsorshipDrawer, setShowEditSponsorshipDrawer] =
    useRecoilState(showEditSponsorshipDrawerAtom);
  const [selectedSponsorship, setSelectedSponsorship] = useRecoilState(selectedSponsorshipAtom);
  const setRefreshSponsorships = useSetRecoilState(refreshSponsorshipsAtom);
  const [loading, setLoading] = useState(false);
  const [loadingBusinesses, setLoadingBusinesses] = useState(false);
  const [loadingItems, setLoadingItems] = useState(false);
  const [selectedSponsorshipId, setSelectedSponsorshipId] = useState(null);
  const [activeSponsorshipBusinesses, setActiveSponsorshipBusinesses] = useState([]);
  const [sponsorshipItems, setSponsorshipItems] = useState([]);
  const [sponsorshipBusinesses, setSponsorshipBusinesses] = useState([]);
  const [searchItems, setSearchItems] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [lastSearch, setLastSearch] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const [size, setSize] = useState(20);
  const [totalItemsInSearch, setTotalItemsInSearch] = useState(0);

  const showMessage = function (message) {
    notification.open({
      message: '',
      description: (
        <Comment
          author={<span>Grocefy</span>}
          avatar={<Avatar src={Grocefy} alt="grocefy" />}
          content={
            <p className="text-sm">
              {message}
            </p>
          }
          datetime={
            <Tooltip title={moment().format('YYYY-MM-DD HH:mm:ss')}>
              <span>{moment().fromNow()}</span>
            </Tooltip>
          }
        />
      ),
    });
  };

  const searchProducts = function (query, page) {
    setIsSearching(true);
    const payload = {
      Query: query,
      Page: page,
      Size: size
    };
    api
      .post(
        'businesses/catalog/search',
        payload
      )
      .then((response) => {
        if (response.data.success) {
          for (let i = 0; i < response.data.data.items.length; i++) {
            const index = _.findIndex(sponsorshipItems, (b) => b.id
              === response.data.data.items[i].id);
            response.data.data.items[i].isAssigned = index >= 0;
          }
          setIsSearching(false);
          setTotalItemsInSearch(response.data.data.count);
          setSearchItems(response.data.data.items);
        } else {
          showMessage(response.data.error);
        }
      })
      .catch((error) => {
        setIsSearching(false);
        showMessage(error);
      });
  };

  const onTableChange = (page, pageSize) => {
    if (page - 1 !== currentPage) {
      setCurrentPage(page - 1);
      searchProducts(lastSearch, page - 1);
    } else {
      setSize(pageSize);
    }
  };

  const onSearch = async (value) => {
    setLastSearch(value);
    setCurrentPage(0);
    searchProducts(value, 0);
  };

  const getSponsorshipBusinesses = function () {
    if (selectedSponsorshipId && !loadingBusinesses) {
      setLoadingBusinesses(true);
      api.get(`marketing/sponsorship/businesses/${selectedSponsorshipId}`)
        .then((response) => {
          setLoadingBusinesses(false);
          if (response.data.success) {
            setActiveSponsorshipBusinesses(response.data.data.results);
          } else {
            showMessage(response.data.error);
          }
        })
        .catch((error) => {
          setLoadingBusinesses(false);
          console.error(error);
        });
    }
  };

  const getSponsorshipItems = function () {
    if (selectedSponsorshipId && !loadingItems) {
      setLoadingItems(true);
      api.get(`marketing/sponsorship/items/${selectedSponsorshipId}`)
        .then((response) => {
          setLoadingItems(false);
          if (response.data.success) {
            if (response.data.data) {
              setSponsorshipItems(response.data.data.results);
            }
          } else {
            showMessage(response.data.error);
          }
        })
        .catch((error) => {
          setLoadingItems(false);
          console.error(error);
        });
    }
  };

  const getSponsorship = function () {
    setLoading(true);
    api.get(`marketing/sponsorship/${selectedSponsorshipId}`)
      .then((response) => {
        setLoading(false);
        if (response.data.success) {
          let status = 'Not Active';
          const startDate = moment(`${response.data.data.startDate}+0000`);
          const endDate = moment(`${response.data.data.endDate}+0000`);
          const currentDate = moment();
          if (currentDate >= startDate && currentDate <= endDate) {
            status = 'Active';
          } else if (currentDate > endDate) {
            status = 'Passed';
          }
          response.data.data.status = status;
          response.data.data.startDateTime = moment(startDate).tz(timezone);
          response.data.data.endDateTime = moment(endDate).tz(timezone);
          setSelectedSponsorship(response.data.data);
          getSponsorshipBusinesses();
          getSponsorshipItems();
        } else {
          showMessage(response.data.error);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  const assignBusiness = function (store) {
    const temp = JSON.parse(JSON.stringify(sponsorshipBusinesses));
    const index = _.findIndex(temp, (b) => b.id === store.id);
    temp[index].loading = true;
    setSponsorshipBusinesses(temp);
    api.post(
      `marketing/sponsorship/businesses/add/${selectedSponsorship.id}/${store.id}`,
      { }
    )
      .then((response) => {
        temp[index].loading = false;
        if (response.data.success) {
          temp[index].isAssigned = true;
        } else {
          showMessage(response.data.error);
        }
        const temp2 = JSON.parse(JSON.stringify(temp));
        setSponsorshipBusinesses(temp2);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const removeBusiness = function (store) {
    const temp = JSON.parse(JSON.stringify(sponsorshipBusinesses));
    const index = _.findIndex(temp, (b) => b.id === store.id);
    if (index >= 0) {
      temp[index].loading = true;
    }
    setSponsorshipBusinesses(temp);
    api.post(`marketing/sponsorship/businesses/remove/${selectedSponsorship.id}/${store.id}`, { })
      .then((response) => {
        temp[index].loading = false;
        if (response.data.success) {
          temp[index].isAssigned = false;
        } else {
          showMessage(response.data.error);
        }
        const temp2 = JSON.parse(JSON.stringify(temp));
        setSponsorshipBusinesses(temp2);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const removeItem = function (item) {
    const temp = JSON.parse(JSON.stringify(sponsorshipItems));
    const index = _.findIndex(temp, (b) => b.id === item.id);
    if (index >= 0) {
      temp[index].loading = true;
    }
    setSponsorshipItems(temp);
    api.post(`marketing/sponsorship/items/remove/${selectedSponsorship.id}/${item.id}`, { })
      .then((response) => {
        temp[index].loading = false;
        if (response.data.success) {
          const newItems = _.filter(temp, (i) => i.id !== temp[index].id);
          setSponsorshipItems(response.data.data.results);
          const tempSearchItems = JSON.parse(JSON.stringify(searchItems));
          for (let i = 0; i < tempSearchItems.length; i++) {
            const tempIndex = _.findIndex(newItems, (b) => b.id
              === tempSearchItems[i].id);
            tempSearchItems[i].isAssigned = tempIndex >= 0;
          }
          setSearchItems(tempSearchItems);
        } else {
          showMessage(response.data.error);
          const temp2 = JSON.parse(JSON.stringify(temp));
          setSponsorshipItems(temp2);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const addItem = function (item) {
    const temp = JSON.parse(JSON.stringify(searchItems));
    const index = _.findIndex(temp, (b) => b.id === item.id);
    if (index >= 0) {
      temp[index].loading = true;
    }
    setSearchItems(temp);
    api.post(
      `marketing/sponsorship/items/add/${selectedSponsorship.id}/${item.id}`,
      { }
    )
      .then((response) => {
        temp[index].loading = false;
        if (response.data.success) {
          temp[index].isAssigned = true;
          setSponsorshipItems(response.data.data.results);
        } else {
          showMessage(response.data.error);
        }
        const temp2 = JSON.parse(JSON.stringify(temp));
        setSearchItems(temp2);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const deleteSponsorship = function () {
    setLoading(true);
    api.delete(`marketing/sponsorship/${selectedSponsorship.id}`)
      .then((response) => {
        setLoading(false);
        if (response.data.success) {
          setRefreshSponsorships(true);
          history.push('/sponsorships');
        } else {
          showMessage(response.data.error);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  const applySponsorship = function () {
    setLoading(true);
    api.post(`marketing/sponsorships/apply/${selectedSponsorship.id}`)
      .then((response) => {
        setLoading(false);
        if (response.data.success) {
          showMessage(response.data.message);
        } else {
          showMessage(response.data.error);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  const cancelSponsorship = function () {
    setLoading(true);
    api.post(`marketing/sponsorship/cancel/${selectedSponsorship.id}`)
      .then((response) => {
        setLoading(false);
        if (response.data.success) {
          showMessage(response.data.message);
        } else {
          showMessage(response.data.error);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  useEffect(() => {
    if (selectedSponsorship) {
      setSelectedSponsorshipId(selectedSponsorship.Id);
    } else {
      setSelectedSponsorshipId(sponsorshipId);
    }
  }, [selectedSponsorship]);

  useEffect(() => {
    if (selectedSponsorshipId) {
      if (!selectedSponsorship ||
        (selectedSponsorship && selectedSponsorshipId !== selectedSponsorship.id)) {
        getSponsorship();
      }
    }
  }, [selectedSponsorshipId]);

  useEffect(() => {
    if (businesses && businesses.state === 'hasValue' && activeSponsorshipBusinesses) {
      const temp = JSON.parse(JSON.stringify(businesses.contents.data.data.results));
      for (let i = 0; i < activeSponsorshipBusinesses.length; i++) {
        const index = _.findIndex(temp, (b) => b.id === activeSponsorshipBusinesses[i].id);
        if (index >= 0) {
          temp[index].isAssigned = true;
        }
      }
      setSponsorshipBusinesses(temp);
    }
  }, [activeSponsorshipBusinesses, businesses]);

  return (
    <>
      <PageHeader
        onBack={() => window.history.back()}
        title={`Sponsorship / ${selectedSponsorship ? selectedSponsorship.name : ''}`}
        extra={[
          <Button
            type="primary"
            size="small"
            loading={loading}
            disabled={!selectedSponsorship}
            key="edit"
            onClick={() => {
              setShowEditSponsorshipDrawer(true);
            }}
          >
            {t('edit')}
          </Button>,
          <Popconfirm
            title={t('sponsorships_apply_dates_content')}
            onConfirm={() => {
              applySponsorship();
            }}
            key="apply"
            okText="Yes"
            cancelText="No"
          >
            <Button
              type="primary"
              size="small"
              loading={loading}
              disabled={!selectedSponsorship}
              key="apply"
            >
              {t('apply_dates')}
            </Button>
          </Popconfirm>,
          <Popconfirm
            title={t('sponsorships_cancel_dates_content')}
            onConfirm={() => {
              cancelSponsorship();
            }}
            key="cancel"
            okText="Yes"
            cancelText="No"
          >
            <Button
              type="default"
              size="small"
              loading={loading}
              disabled={!selectedSponsorship}
              key="cancel"
            >
              {t('cancel_dates')}
            </Button>
          </Popconfirm>,
          <Popconfirm
            title={t('sponsorships_archive_content')}
            onConfirm={() => {
              deleteSponsorship();
            }}
            key="delete"
            okText="Yes"
            cancelText="No"
          >
            <Button
              type="primary"
              danger
              size="small"
              loading={loading}
              disabled={!selectedSponsorship}
              key="delete"
            >
              {t('archive')}
            </Button>
          </Popconfirm>
        ]}
      />
      {selectedSponsorship && (
        <Collapse>
          <Panel header={t('businesses')} key="1">
            <Table
              size="small"
              bordered
              loading={loadingBusinesses || loading}
              dataSource={sponsorshipBusinesses}
              pagination={false}
              rowKey="id"
              columns={[
                {
                  title: t('name'),
                  align: 'center',
                  className: 'text-xs',
                  render: (row) => (
                    <span>{row.name}</span>
                  ),
                },
                {
                  title: '',
                  align: 'center',
                  className: 'text-xs',
                  render: (row) => (
                    <>
                      {!row.isAssigned && (
                        <Popconfirm
                          title={t('sponsorship_assign_business')}
                          onConfirm={() => {
                            assignBusiness(row);
                          }}
                          key="add"
                          okText={t('yes')}
                          cancelText="No"
                        >
                          <Button
                            type="primary"
                            size="small"
                            loading={row.loading}
                          >
                            {t('assign')}
                          </Button>
                        </Popconfirm>
                      )}
                      {row.isAssigned && (
                        <Popconfirm
                          title={t('sponsorship_remove_business')}
                          onConfirm={() => {
                            removeBusiness(row);
                          }}
                          key="delete"
                          okText={t('yes')}
                          cancelText="No"
                        >
                          <Button
                            type="primary"
                            danger
                            size="small"
                            loading={row.loading}
                          >
                            {t('delete')}
                          </Button>
                        </Popconfirm>
                      )}
                    </>
                  ),
                },
              ]}
            />
          </Panel>
          <Panel header={t('items')} key="2">
            <>
              <Table
                size="small"
                bordered
                loading={loadingItems || loading}
                dataSource={sponsorshipItems}
                rowKey="id"
                columns={[
                  {
                    title: t('image'),
                    align: 'center',
                    className: 'text-xs',
                    render: (row) => (
                      <img
                        width={75}
                        src={row.itemImage}
                        alt={`${row.brand} ${row.name} ${row.description}`}
                      />
                    ),
                  },
                  {
                    title: 'GTIN',
                    align: 'center',
                    className: 'text-xs',
                    render: (row) => (
                      <span>{row.gtin}</span>
                    ),
                  },
                  {
                    title: t('brand'),
                    align: 'center',
                    className: 'text-xs',
                    render: (row) => (
                      <span>{row.brand}</span>
                    ),
                  },
                  {
                    title: t('name'),
                    align: 'center',
                    className: 'text-xs',
                    render: (row) => (
                      <span>{row.name}</span>
                    ),
                  },
                  {
                    title: t('description'),
                    align: 'center',
                    className: 'text-xs',
                    render: (row) => (
                      <span>{row.description}</span>
                    ),
                  },
                  {
                    title: '',
                    align: 'center',
                    className: 'text-xs',
                    render: (row) => (
                      <Popconfirm
                        title={t('remove_item_content')}
                        onConfirm={() => {
                          removeItem(row);
                        }}
                        key="delete"
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button
                          type="primary"
                          danger
                          size="small"
                          loading={row.loading}
                        >
                          {t('delete')}
                        </Button>
                      </Popconfirm>
                    ),
                  },
                ]}
              />
              <Divider />
              <Input.Search
                key="itemSearch"
                placeholder={t('item_search_placeholder')}
                allowClear
                loading={isSearching}
                enterButton={t('search')}
                size="small"
                onSearch={onSearch}
              />
              <Table
                size="small"
                bordered
                loading={isSearching}
                pagination={{
                  pageSize: size,
                  showSizeChanger: true,
                  defaultCurrent: 0,
                  current: currentPage + 1,
                  total: totalItemsInSearch,
                  onChange: onTableChange
                }}
                dataSource={searchItems}
                rowKey="id"
                columns={[
                  {
                    title: t('image'),
                    align: 'center',
                    className: 'text-xs',
                    render: (row) => (
                      <img
                        width={75}
                        src={row.itemImage}
                        alt={`${row.brand} ${row.name} ${row.description}`}
                      />
                    ),
                  },
                  {
                    title: 'GTIN',
                    align: 'center',
                    className: 'text-xs',
                    render: (row) => (
                      <span>{row.gtin}</span>
                    ),
                  },
                  {
                    title: t('brand'),
                    align: 'center',
                    className: 'text-xs',
                    render: (row) => (
                      <span>{row.brand}</span>
                    ),
                  },
                  {
                    title: t('name'),
                    align: 'center',
                    className: 'text-xs',
                    render: (row) => (
                      <span>{row.name}</span>
                    ),
                  },
                  {
                    title: t('description'),
                    align: 'center',
                    className: 'text-xs',
                    render: (row) => (
                      <span>{row.description}</span>
                    ),
                  },
                  {
                    title: '',
                    align: 'center',
                    className: 'text-xs',
                    render: (row) => (
                      <Popconfirm
                        title={t('add_item_content')}
                        onConfirm={() => {
                          addItem(row);
                        }}
                        key="primary"
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button
                          type="primary"
                          size="small"
                          loading={row.loading}
                        >
                          {t('add')}
                        </Button>
                      </Popconfirm>
                    ),
                  },
                ]}
              />
            </>
          </Panel>
        </Collapse>
      )}
      {selectedSponsorship && showEditSponsorshipDrawer && (
        <EditSponsorship />
      )}
    </>
  );
}

export default Sponsorship;
