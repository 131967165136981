import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import {
  useRecoilValueLoadable,
  useRecoilState,
  useSetRecoilState
} from 'recoil';
import {
  Drawer,
  Button,
  Select,
  Input,
  InputNumber,
  DatePicker,
  notification,
  Tooltip,
  Avatar,
} from 'antd';
import { Comment } from '@ant-design/compatible';
import moment from 'moment';
import dayjs from 'dayjs';
import {
  selectedPromoCodesAtom,
  refreshPromoCodesAtom,
  showEditPromoCodeAtom
} from '../../../../atoms/Atoms';
import { businessesSelector } from '../../../../atoms/Selectors';
import api from '../../../../api/api';
import Grocefy from '../../../../assets/images/grocefyLogoAlone.png';

function EditBusinessPromoCode() {
  const { Option } = Select;
  const { t } = useTranslation();
  const businesses = useRecoilValueLoadable(businessesSelector);
  const setRefreshPromoCodes = useSetRecoilState(refreshPromoCodesAtom);
  const [showEditPromoCode, setShowEditPromoCode] = useRecoilState(showEditPromoCodeAtom);
  const [selectedPromoCode, setSelectedPromoCode] = useRecoilState(selectedPromoCodesAtom);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);

  function showMessage(message) {
    notification.open({
      message: '',
      description: (
        <Comment
          author={<span>Grocefy</span>}
          avatar={<Avatar src={Grocefy} alt="grocefy" />}
          content={
            <p className="text-sm">
              {message}
            </p>
          }
          datetime={
            <Tooltip title={moment().format('YYYY-MM-DD HH:mm:ss')}>
              <span>{moment().fromNow()}</span>
            </Tooltip>
          }
        />
      ),
    });
  }

  function submit() {
    setLoading(true);
    if (data.id) {
      api.post('businesses/discountcode/edit', {
        id: data.id,
        expiresAt: data.expiresAt
      })
        .then((resposne) => {
          setLoading(false);
          if (resposne.data.error) {
            showMessage(resposne.data.error);
          } else {
            if (resposne.data.message) {
              showMessage(resposne.data.message);
            } else {
              showMessage(t('updated_coupon_successfully'));
            }
            setShowEditPromoCode(0);
            setRefreshPromoCodes(true);
            setSelectedPromoCode(null);
          }
        })
        .catch((err) => {
          setLoading(false);
          showMessage(err.message);
        });
    } else {
      let temp = moment(data.expiresAt).utc();
      if (temp.length < 17) {
        temp = `${temp.replace(' ', 'T')}:00.000`;
      }
      console.log(JSON.stringify({
        ...data,
        expiresAt: temp
      }));
      api.post('businesses/discountcode', {
        ...data,
        expiresAt: temp
      })
        .then((resposne) => {
          setLoading(false);
          if (resposne.data.error) {
            showMessage(resposne.data.error);
          } else {
            if (resposne.data.message) {
              showMessage(resposne.data.message);
            } else {
              showMessage(t('created_coupon_successfully'));
            }
            setShowEditPromoCode(0);
            setRefreshPromoCodes(true);
            setSelectedPromoCode(null);
          }
        })
        .catch((err) => {
          setLoading(false);
          showMessage(err.message);
        });
    }
  }

  useEffect(() => {
    if (showEditPromoCode === 2 && selectedPromoCode) {
      if (selectedPromoCode.id) {
        setData({
          ...selectedPromoCode,
          expiresAt: moment(`${selectedPromoCode.expiresAt}+0000`).add(-1, 'days')
        });
      } else {
        setData({
          businessId: '',
          minimumAmount: 0,
          value: 0,
          type: 4,
          appliesTo: 0,
          applicationType: 2
        });
      }
    }
  }, [selectedPromoCode, showEditPromoCode]);

  return (
    <Drawer
      title={`${data && data?.id ? t('update') : t('create')}`}
      placement="right"
      closable
      onClose={() => setShowEditPromoCode(0)}
      open={showEditPromoCode === 2}
      width={400}
    >
      <div className="mb-4">
        <strong>{t('code')}</strong>
        <Input
          className="uppercase"
          disabled={loading || data?.id}
          onChange={(obj) => {
            setData({
              ...data,
              code: obj.target.value.toUpperCase(),
              matchString: obj.target.value.toUpperCase()
            });
          }}
          value={data?.code}
        />
      </div>
      <div className="mb-4">
        <strong>{t('value')}</strong>
        <br />
        <InputNumber
          className="w-full"
          disabled={loading || data?.id}
          onChange={(obj) => {
            setData({
              ...data,
              value: obj
            });
          }}
          value={data?.value}
        />
      </div>
      <div className="mb-4">
        <strong>{t('min_purchase')}</strong>
        <br />
        <InputNumber
          className="w-full"
          disabled={loading || data?.id}
          onChange={(obj) => {
            setData({
              ...data,
              minimumAmount: obj
            });
          }}
          value={data?.minimumAmount}
        />
      </div>
      <div className="mb-4">
        <strong>{t('expires')}: </strong>
        <DatePicker
          disabled={loading}
          onChange={(date, dateString) => {
            setData({
              ...data,
              expiresAt: dateString
            });
          }}
          allowClear={false}
          value={dayjs(data?.expiresAt)}
        />
      </div>
      <div className="mb-4">
        <strong>{t('store')}: </strong>
        <Select
          defaultValue={data?.businessId}
          value={data?.businessId}
          style={{ width: 180 }}
          disabled={loading || data?.id}
          onChange={(obj) => {
            setData({
              ...data,
              businessId: obj
            });
          }}
        >
          <Option value="">{t('global')}</Option>
          {businesses.state === 'hasValue' && _.map(businesses.contents.data.data.results, (business) => (
            <Option
              value={business.id}
              key={business.id}
            >
              {business.name}
            </Option>
          ))}
        </Select>
      </div>
      <div className="mb-4">
        <strong>{t('applies_to')}: </strong>
        <Select
          defaultValue={data?.appliesTo}
          value={data?.appliesTo}
          style={{ width: 180 }}
          disabled={loading || data?.id}
          onChange={(obj) => {
            setData({
              ...data,
              appliesTo: obj
            });
          }}
        >
          <Option value={0}>{t('pickup')}</Option>
          <Option value={1}>{t('delivery')}</Option>
          <Option value={2}>{t('pickup_and_delivery')}</Option>
        </Select>
      </div>
      <div className="mb-4">
        <strong>{t('type')}: </strong>
        <Select
          defaultValue={data?.applicationType}
          value={data?.applicationType}
          style={{ width: 180 }}
          disabled={loading || data?.id}
          onChange={(obj) => {
            setData({
              ...data,
              applicationType: obj
            });
          }}
        >
          <Option value={0}>{t('one_time')}</Option>
          <Option value={1}>{t('reusable')}</Option>
          <Option value={2}>{t('once_per_user')}</Option>
        </Select>
      </div>
      <div
        style={{
          float: 'right',
          margin: '10px 0'
        }}
      >
        <Button
          type="primary"
          loading={loading}
          disabled={!data?.code || data?.code?.length < 1 || data?.minimumAmount < 0
            || data?.value <= 0}
          onClick={() => submit()}
        >
          {data?.id ? t('update') : t('create')}
        </Button>
      </div>
    </Drawer>
  );
}

export default EditBusinessPromoCode;
