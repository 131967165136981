import { Typography, Table, Row, Col, Tag } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import React, { useState, useEffect } from 'react';
import { useMount } from 'react-use';
import { useTranslation } from 'react-i18next';
import { CheckCircleOutlined, SyncOutlined } from '@ant-design/icons';
import moment from 'moment';
import _ from 'lodash';
import { useRecoilValue } from 'recoil';
import {
  businessAtom,
  timezoneAtom,
} from '../../../atoms/Atoms';
import { logType } from '../../utils/functions';
import api from '../../../api/api';

function BusinessLogs() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(null);
  const [current, setCurrent] = useState(0);
  const [size, setSize] = useState(20);
  const [logs, setLogs] = useState(null);
  const [reload, setReload] = useState(false);
  const timezone = useRecoilValue(timezoneAtom);
  const business = useRecoilValue(businessAtom);
  const [count, setCount] = useState(0);

  function mapLogs(data) {
    const list = _.map(data, (p, index) => ({
      key: index,
      browser: p.browser,
      createdAt: moment(`${p.createdAt}+0000`)
        .tz(timezone)
        .format('MMM Do YYYY, h:mm:ss a'),
      data: p.data,
      description: p.description,
      os: p.os,
      path: p.path,
      type: logType(p.type),
      url: p.url,
      userId: p.userId,
    }));
    return list;
  }

  function getData() {
    setLoading(true);
    api.post(`businesses/logs/${business.id}/${size}/${current}`, { })
      .then((response) => {
        setLoading(false);
        setLogs(mapLogs(response.data.data.results));
        setCount(Number(response.data.message));
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  }

  useMount(() => {
    getData();
  });

  useEffect(() => {
    getData();
  }, [reload]);

  useEffect(() => {
    getData();
  }, [business]);

  return (
    <div>
      <PageHeader
        className="mb-4 px-0"
        title={t('logs')}
        tags={
          <Tag
            color="#2db7f5"
            icon={business ? <CheckCircleOutlined /> : <SyncOutlined spin />}
          >
            {business ? business.name : `${t('loading')}...`}
          </Tag>
        }
      />
      <div className="p-4 border bg-gray-100 rounded-lg mb-4">
        <Row>
          <Col className="pb-4">
            <Typography.Title level={5}>{t('business_logs')}</Typography.Title>
            <Typography.Text type="secondary">
              {t('business_logs_desc')}
            </Typography.Text>
          </Col>
        </Row>
        <Row>
          <Table
            size="small"
            loading={loading}
            bordered
            pagination={{
              current: current + 1,
              hideOnSinglePage: true,
              showSizeChanger: true,
              size: 'small',
              pageSize: size,
              total: count,
              pageSizeOptions: [10, 20, 30, 40, 50],
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} ${t('of')} ${total} ${t('items')}`,
              onShowSizeChange: (page, row) => {
                setReload(!reload);
                setSize(row);
              },
              onChange: (page) => {
                setReload(!reload);
                setCurrent(page - 1);
              },
            }}
            columns={[
              {
                title: t('created_at'),
                dataIndex: 'createdAt',
                key: 'createdAt',
                align: 'center',
                className: 'text-xs',
                render: (text) => (
                  <Typography.Text>
                    <span>{text}</span>
                  </Typography.Text>
                ),
              },
              {
                title: t('type'),
                dataIndex: 'type',
                key: 'type',
                align: 'center',
                className: 'text-xs',
                render: (text) => <span>{text}</span>,
              },
              {
                title: t('description'),
                dataIndex: 'description',
                key: 'description',
                align: 'center',
                className: 'text-xs',
                render: (text) => <span>{text}</span>,
              },
            ]}
            dataSource={logs}
          />
        </Row>
      </div>
    </div>
  );
}

export default BusinessLogs;
