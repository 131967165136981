import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import {
  useRecoilState,
  useRecoilValue,
  useSetRecoilState
} from 'recoil';
import { useTranslation } from 'react-i18next';
import {
  Row,
  Col,
  Input,
  InputNumber,
  notification,
  Switch,
  Avatar,
  Tooltip,
  Drawer,
  Button
} from 'antd';
import { Comment } from '@ant-design/compatible';
import { GoogleMap, Marker, Polygon, useJsApiLoader } from '@react-google-maps/api';
import moment from 'moment';
import {
  businessAtom,
  reloadDeliveryZonesAtom,
  selectedDeliveryZoneAtom,
  showEditDeliveryZoneHoursAtom
} from '../../../../atoms/Atoms';
import envConfig from '../../../../envConfig';
import Grocefy from '../../../../assets/images/grocefyLogoAlone.png';
import api from '../../../../api/api';

function EditDeliveryZoneDrawer() {
  const business = useRecoilValue(businessAtom);
  const setReloadDeliveryZonesAtom = useSetRecoilState(reloadDeliveryZonesAtom);
  const setShowEditDeliveryZoneHours = useSetRecoilState(showEditDeliveryZoneHoursAtom);
  const [selectedDeliveryZone, setSelectedDeliveryZone]
    = useRecoilState(selectedDeliveryZoneAtom);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [valid, setValid] = useState(false);
  const [map, setMap] = useState(null);
  const [newPolygon, setNewPolygon] = useState(null);
  const [storeLl, setStoreLl] = useState(undefined);
  const { t } = useTranslation();

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: envConfig.REACT_APP_GEO_API_KEY,
    language: 'es-US',
    libraries: ['places']
  });

  const apiHasLoaded = (tempMap) => {
    const bounds = new window.google.maps.LatLngBounds({
      lat: 18.2208, lng: 66.5901
    });
    tempMap.fitBounds(bounds);
    setMap(tempMap);
  };

  const onUnmount = React.useCallback((tempMap) => {
    setMap(null);
  }, []);

  const previewMap = function (obj) {
    const reader = new FileReader();
    reader.onload = async (e) => {
      const file = e.target.result;
      const lines = file.split('\n');
      const newPoints = [];
      let readLine = false;
      for (let i = 0; i < lines.length; i++) {
        const line = lines[i];
        if (line.includes('<coordinates>')) {
          readLine = true;
        } else if (line.includes('</coordinates>')) {
          readLine = false;
        } else if (readLine) {
          const elems = line.split(',');
          newPoints.push({ lat: parseFloat(elems[1]), lon: parseFloat(elems[0]) });
        }
      }
      setNewPolygon(newPoints);
    };
    reader.readAsText(obj.target.files[0]);
  };

  const downloadCurrentMap = function () {
    let textValue = '<?xml version="1.0" encoding="UTF-8"?><kml xmlns="http://www.opengis.net/kml/2.2"><Document><name>';
    textValue += selectedDeliveryZone.name;
    textValue += '</name><Style id="icon-1899-0288D1-nodesc-normal"><IconStyle><color>ffd18802</color><scale>1</scale><Icon><href>http://www.gstatic.com/mapspro/images/stock/503-wht-blank_maps.png</href></Icon><hotSpot x="32" xunits="pixels" y="64" yunits="insetPixels"/></IconStyle><LabelStyle><scale>0</scale></LabelStyle><BalloonStyle><text><![CDATA[<h3>$[name]</h3>]]></text></BalloonStyle></Style><Style id="icon-1899-0288D1-nodesc-highlight"><IconStyle><color>ffd18802</color><scale>1</scale><Icon><href>http://www.gstatic.com/mapspro/images/stock/503-wht-blank_maps.png</href></Icon><hotSpot x="32" xunits="pixels" y="64" yunits="insetPixels"/></IconStyle><LabelStyle><scale>1</scale></LabelStyle><BalloonStyle><text><![CDATA[<h3>$[name]</h3>]]></text></BalloonStyle></Style><StyleMap id="icon-1899-0288D1-nodesc"><Pair><key>normal</key><styleUrl>#icon-1899-0288D1-nodesc-normal</styleUrl></Pair><Pair><key>highlight</key><styleUrl>#icon-1899-0288D1-nodesc-highlight</styleUrl></Pair></StyleMap><Style id="poly-000000-2000-77-nodesc-normal"><LineStyle><color>ff000000</color><width>2</width></LineStyle><PolyStyle><color>4d000000</color><fill>1</fill><outline>1</outline></PolyStyle><BalloonStyle><text><![CDATA[<h3>$[name]</h3>]]></text></BalloonStyle></Style><Style id="poly-000000-2000-77-nodesc-highlight"><LineStyle><color>ff000000</color><width>3</width></LineStyle><PolyStyle><color>4d000000</color><fill>1</fill><outline>1</outline></PolyStyle><BalloonStyle><text><![CDATA[<h3>$[name]</h3>]]></text></BalloonStyle></Style><StyleMap id="poly-000000-2000-77-nodesc"><Pair><key>normal</key><styleUrl>#poly-000000-2000-77-nodesc-normal</styleUrl></Pair><Pair><key>highlight</key><styleUrl>#poly-000000-2000-77-nodesc-highlight</styleUrl></Pair></StyleMap><Placemark><name>';
    textValue += business.name;
    textValue += '</name><styleUrl>#poly-000000-2000-77-nodesc</styleUrl>\n<Polygon><outerBoundaryIs><LinearRing><tessellate>1</tessellate>\n<coordinates>\n';
    for (let i = 0; i < selectedDeliveryZone.points.length; i++) {
      textValue += `${selectedDeliveryZone.points[i].lon},${selectedDeliveryZone.points[i].lat},0\n`;
    }
    textValue += '</coordinates>\n</LinearRing></outerBoundaryIs></Polygon></Placemark></Document></kml>';
    const element = document.createElement('a');
    const file = new Blob([textValue], { type: 'text/plain' });
    element.href = URL.createObjectURL(file);
    element.download = `${business.name}_${selectedDeliveryZone.name}_Map.kml`;
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };

  const showMessage = function (message) {
    notification.open({
      message: '',
      description: (
        <Comment
          author={<span>Grocefy</span>}
          avatar={<Avatar src={Grocefy} alt="grocefy" />}
          content={
            <p className="text-sm">
              {message}
            </p>
          }
          datetime={
            <Tooltip title={moment().format('YYYY-MM-DD HH:mm:ss')}>
              <span>{moment().fromNow()}</span>
            </Tooltip>
          }
        />
      ),
    });
  };

  const updateZone = async function () {
    setLoading(true);
    const temp = JSON.parse(JSON.stringify(data));
    if (newPolygon && newPolygon.length > 0) {
      const updatePolygonResponse = await api.put(
        `deliveries/updatePolygon/${selectedDeliveryZone.id}`,
        {
          points: _.map(newPolygon, (ll) => ({
            item1: `${ll.lat}`,
            item2: `${ll.lon}`
          }))
        }
      );
      if (updatePolygonResponse.data.success) {
        temp.points = newPolygon;
        setData(temp);
        setNewPolygon(null);
      } else {
        showMessage(updatePolygonResponse.data.error);
        return;
      }
    }
    const updateResponse = await api.post(
      `deliveries/deliveryzones/${business.locations[0].id}/zone`,
      temp
    );
    setLoading(false);
    if (updateResponse.data.success) {
      setReloadDeliveryZonesAtom(true);
    } else {
      showMessage(updateResponse.data.error);
    }
  };

  const removeZone = function () {
    setLoading(true);
    api.delete(`deliveries/deliveryzones/remove/zone/${selectedDeliveryZone.id}`)
      .then((response) => {
        setLoading(false);
        if (response.data.success) {
          setSelectedDeliveryZone(null);
          setReloadDeliveryZonesAtom(true);
        } else {
          showMessage(response.data.error);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  useEffect(() => {
    if (data) {
      let temp = data?.name?.length > 0;
      temp = temp && `${data.cost}`.length > 0;
      temp = temp && `${data.deliveryFutureDays}`.length > 0;
      temp = temp && `${data.slotRangeMin}`.length > 0;
      setValid(temp);
    }
  }, [data]);

  useEffect(() => {
    if (business) {
      setStoreLl({
        lat: business?.locations[0]?.geo.lat,
        lng: business?.locations[0]?.geo.lon
      });
    }
  }, [business]);

  useEffect(() => {
    if (business) {
      setStoreLl({
        lat: business?.locations[0]?.geo.lat,
        lng: business?.locations[0]?.geo.lon
      });
    }
    if (selectedDeliveryZone) {
      setData(selectedDeliveryZone);
    }
  }, [selectedDeliveryZone]);

  return (
    <>
      <Drawer
        title={t('edit_delivery_zone')}
        placement="right"
        closable
        onClose={() => setSelectedDeliveryZone(null)}
        open={selectedDeliveryZone}
        width={400}
      >
        <div
          style={{ marginBottom: 10 }}
        >
          <strong>{t('is_active')}</strong>
          <Switch
            style={{ float: 'right' }}
            disabled={loading}
            onChange={(obj) => {
              const temp = JSON.parse(JSON.stringify(data));
              temp.isActive = obj;
              setData(temp);
            }}
            checked={data?.isActive}
          />
        </div>
        <div style={{ marginBottom: 10 }}>
          {business && isLoaded && (
            <GoogleMap
              mapContainerStyle={{
                height: '300px'
              }}
              center={{
                lat: 18.2208, lng: 66.5901
              }}
              zoom={20}
              onLoad={apiHasLoaded}
              onUnmount={onUnmount}
            >
              <Polygon
                path={_.map(selectedDeliveryZone?.points, (ll) => ({
                  lng: ll.lon,
                  lat: ll.lat
                }))}
                options={{
                  fillColor: '#00FF0033',
                  fillOpacity: 1,
                  strokeColor: '#22FF22',
                  strokeOpacity: 1,
                  strokeWeight: 2,
                  clickable: false,
                  draggable: false,
                  editable: false,
                  geodesic: false,
                  paths: _.map(selectedDeliveryZone?.points, (ll) => ({
                    lng: ll.lon,
                    lat: ll.lat
                  })),
                  zIndex: 1,
                }}
              />
              {newPolygon && (
                <Polygon
                  path={_.map(newPolygon, (ll) => ({
                    lng: ll.lon,
                    lat: ll.lat
                  }))}
                  options={{
                    fillColor: '#FF550033',
                    fillOpacity: 1,
                    strokeColor: '#FF7700',
                    strokeOpacity: 1,
                    strokeWeight: 2,
                    clickable: false,
                    draggable: false,
                    editable: false,
                    geodesic: false,
                    paths: _.map(newPolygon, (ll) => ({
                      lng: ll.lon,
                      lat: ll.lat
                    })),
                    zIndex: 1,
                  }}
                />
              )}
              <Marker
                position={storeLl}
              />
            </GoogleMap>
          )}
        </div>
        <div
          style={{ marginBottom: 10 }}
        >
          <Button
            type="primary"
            style={{ width: '100%' }}
            onClick={() => {
              document.getElementById('selectFile').click();
            }}
          >
            {t('preview_new_map')}
          </Button>
          <Input
            type="file"
            id="selectFile"
            hidden
            onChange={(e) => previewMap(e)}
          />
        </div>
        {selectedDeliveryZone?.points && selectedDeliveryZone?.points?.length > 0 && (
          <Button
            type="primary"
            style={{ width: '100%', marginBottom: 10 }}
            onClick={() => {
              downloadCurrentMap();
            }}
          >
            {t('download_map')}
          </Button>
        )}
        <div
          style={{ marginBottom: 10 }}
        >
          <Button
            type="primary"
            style={{ width: '100%' }}
            onClick={() => setShowEditDeliveryZoneHours(true)}
          >
            {t('manage_hours')}
          </Button>
        </div>
        <div
          style={{ marginBottom: 10 }}
        >
          <strong>{t('name')}</strong>
          <Input
            disabled={loading}
            onChange={(obj) => {
              const temp = JSON.parse(JSON.stringify(data));
              temp.name = obj.target.value;
              setData(temp);
            }}
            value={data?.name}
          />
        </div>
        <div
          style={{ marginBottom: 10 }}
        >
          <strong>{t('prevent_service_slots')}</strong>
          <Switch
            style={{ float: 'right' }}
            disabled={loading}
            onChange={(obj) => {
              const temp = JSON.parse(JSON.stringify(data));
              temp.preventTodayDeliverySlots = obj;
              setData(temp);
            }}
            checked={data?.preventTodayDeliverySlots}
          />
        </div>
        <Row>
          <Col>
            <div
              style={{ marginBottom: 10 }}
            >
              <strong>{t('cost')}</strong>
              <br />
              <InputNumber
                formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                min={0}
                step="0.01"
                disabled={loading}
                onChange={(obj) => {
                  const temp = JSON.parse(JSON.stringify(data));
                  temp.cost = obj;
                  setData(temp);
                }}
                value={data?.cost}
              />
            </div>
          </Col>
          <Col>
            <div
              style={{ marginBottom: 10 }}
            >
              <strong>{t('slot_range')}</strong>
              <br />
              <InputNumber
                disabled={loading}
                min={0}
                onChange={(obj) => {
                  const temp = JSON.parse(JSON.stringify(data));
                  temp.slotRangeMin = obj;
                  setData(temp);
                }}
                value={data?.slotRangeMin}
              />
            </div>
          </Col>
          <Col>
            <div
              style={{ marginBottom: 10 }}
            >
              <strong>{t('future_days')}</strong>
              <br />
              <InputNumber
                disabled={loading}
                min={0}
                onChange={(obj) => {
                  const temp = JSON.parse(JSON.stringify(data));
                  temp.deliveryFutureDays = obj;
                  setData(temp);
                }}
                value={data?.deliveryFutureDays}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div
              style={{ marginBottom: 10 }}
            >
              <strong>{t('route_ordering')}</strong>
              <br />
              <InputNumber
                disabled={loading}
                min={0}
                onChange={(obj) => {
                  const temp = JSON.parse(JSON.stringify(data));
                  temp.deliveryRouteOrder = obj;
                  setData(temp);
                }}
                value={data?.deliveryRouteOrder}
              />
            </div>
          </Col>
        </Row>
        <div
          style={{
            float: 'right',
            marginTop: '10px'
          }}
        >
          <Button
            style={{
              marginLeft: '10px'
            }}
            loading={loading}
            disabled={!valid}
            type="primary"
            onClick={() => { updateZone(); }}
          >
            {t('update')}
          </Button>
          <Button
            style={{
              marginLeft: '10px'
            }}
            loading={loading}
            disabled={loading}
            type="primary"
            danger
            onClick={() => { removeZone(); }}
          >
            {t('delete')}
          </Button>
        </div>
      </Drawer>
    </>
  );
}
export default EditDeliveryZoneDrawer;
