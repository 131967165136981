import React, { useState, useEffect } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import {
  Button,
  notification,
  Avatar,
  Tooltip,
  Table,
  Modal
} from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import { Comment } from '@ant-design/compatible';
import {
  timezoneAtom,
  selectedDepartmentOrSectionForLogsAtom
} from '../../../../atoms/Atoms';
import Grocefy from '../../../../assets/images/grocefyLogoAlone.png';
import api from '../../../../api/api';
import { renderDate } from '../../../utils/functions';

function LogsModal() {
  const { t } = useTranslation();
  const timezone = useRecoilValue(timezoneAtom);
  const [selectedDepartmentOrSectionForLogs, setSelectedDepartmentOrSectionForLogs] = useRecoilState(selectedDepartmentOrSectionForLogsAtom);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [count, setCount] = useState(0);
  const [size, setSize] = useState(20);
  const [logs, setLogs] = useState([]);

  function showMessage(message) {
    notification.open({
      message: '',
      description: (
        <Comment
          author={<span>Grocefy</span>}
          avatar={<Avatar src={Grocefy} alt="grocefy" />}
          content={
            <p className="text-sm">
              {message}
            </p>
          }
          datetime={
            <Tooltip title={moment().format('YYYY-MM-DD HH:mm:ss')}>
              <span>{moment().fromNow()}</span>
            </Tooltip>
          }
        />
      ),
    });
  }

  function getLogs(page) {
    if (!loading) {
      let url = 'admin/';
      if (selectedDepartmentOrSectionForLogs?.sectionId) {
        url += `sections/${selectedDepartmentOrSectionForLogs?.sectionId}`;
      } else {
        url += `supersections/${selectedDepartmentOrSectionForLogs?.departmentId}`;
      }
      api.get(`${url}?page=${page}&pageSize=${size}`)
        .then((response) => {
          setLoading(false);
          if (!response.data.success) {
            showMessage(response.data.error);
          } else {
            setLogs(response.data?.data.logs);
            setCount(response.data?.data.count);
          }
        })
        .catch((error) => {
          setLoading(false);
          showMessage(error.message);
        });
    }
  }

  useEffect(() => {
    setCurrentPage(0);
    getLogs(0);
  }, [size]);

  useEffect(() => {
    if (selectedDepartmentOrSectionForLogs?.sectionId || selectedDepartmentOrSectionForLogs?.departmentId) {
      setCurrentPage(0);
      getLogs(0);
    }
  }, [selectedDepartmentOrSectionForLogs]);

  return (
    <Modal
      closable="true"
      centered="true"
      onCancel={() => setSelectedDepartmentOrSectionForLogs(null)}
      open={selectedDepartmentOrSectionForLogs?.sectionId || selectedDepartmentOrSectionForLogs?.departmentId}
      footer={[
        <Button
          key="back"
          disabled={loading}
          onClick={() => setSelectedDepartmentOrSectionForLogs(null)}
        >
          {t('close')}
        </Button>
      ]}
    >
      <PageHeader
        title={t('logs')}
      />
      <Table
        bordered
        small
        loading={loading}
        dataSource={logs}
        pagination={{
          pageSize: size,
          showSizeChanger: true,
          defaultCurrent: 0,
          current: currentPage + 1,
          total: count,
          onChange: (page, pageSize) => {
            if (page - 1 !== currentPage) {
              setCurrentPage(page - 1);
              getLogs(page - 1);
            } else {
              setSize(pageSize);
            }
          }
        }}
        columns={[
          {
            title: t('date'),
            key: 'date',
            align: 'center',
            className: 'text-xs',
            render: (row) => (
              <div className="flex flex-col">
                <span>{renderDate(row.createdAt, timezone)}</span>
              </div>
            ),
          },
          {
            title: t('message'),
            key: 'message',
            align: 'center',
            className: 'text-xs',
            render: (row) => (
              <div className="flex flex-col">
                <span>{row.message}</span>
              </div>
            ),
          }
        ]}
      />
    </Modal>
  );
}

export default LogsModal;
