import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import {
  useRecoilState,
  useRecoilValue,
} from 'recoil';
import { useTranslation } from 'react-i18next';
import {
  Drawer,
  Avatar,
  Tooltip,
  Input,
  Button,
  Divider,
  notification,
  Table,
} from 'antd';
import { Comment } from '@ant-design/compatible';
import moment from 'moment';
import Grocefy from '../../../../assets/images/grocefyLogoAlone.png';
import {
  stateAtom,
  tokenAtom,
  setSelectedMasterItemAtom,
  setSelectedBusinessItemAtom,
  manageRequirementsAtom
} from '../../../../atoms/Atoms';
import api from '../../../../api/api';

function ManageRequirementsDrawer() {
  const { Search } = Input;
  const currentState = useRecoilValue(stateAtom);
  const selectedItem = useRecoilValue(setSelectedMasterItemAtom);
  const selectedBusinessItem = useRecoilValue(setSelectedBusinessItemAtom);
  const [manageRequirements, setManageRequirements] = useRecoilState(manageRequirementsAtom);
  const [loading, setLoading] = useState(false);
  const [requirements, setRequirements] = useState([]);
  const [searchRequirements, setSearchRequirements] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const [searchText, setSearchText] = useState('');
  const { t } = useTranslation();

  const showMessage = function (message) {
    notification.open({
      message: '',
      description: (
        <Comment
          author={<span>Grocefy</span>}
          avatar={<Avatar src={Grocefy} alt="grocefy" />}
          content={
            <p className="text-sm">
              {message}
            </p>
          }
          datetime={
            <Tooltip title={moment().format('YYYY-MM-DD HH:mm:ss')}>
              <span>{moment().fromNow()}</span>
            </Tooltip>
          }
        />
      ),
    });
  };

  const filter = function () {
    if (searchText.length > 0) {
      const temp = _.filter(searchRequirements, (row) => row.name.toUpperCase()
        .includes(searchText.toUpperCase()) ||
        row.internalName.toUpperCase().includes(searchText.toUpperCase()));
      setFiltered(temp);
    } else {
      setFiltered(searchRequirements);
    }
  };

  const fetchData = async function () {
    setLoading(true);
    const payload = {
      BusinessItemId: selectedBusinessItem ? selectedBusinessItem.id : null,
      ItemId: selectedItem ? selectedItem.id : null
    };
    const response = await api
      .post(
        'deli/requirements',
        payload
      );
    if (response.data.success) {
      setRequirements(response.data.data.results);
      let url2 = 'deli/requirements';
      if (!currentState.globalReqSideAddons && selectedBusinessItem) {
        url2 = `${selectedBusinessItem.businessId}/${url2}`;
      }
      const getAllReqs = await api
        .get(url2);
      setLoading(false);
      if (getAllReqs.data.success) {
        const diff = _.differenceWith(getAllReqs.data.data.results,
          response.data.data.results, (a, b) => a.id === b.id);
        setSearchRequirements(diff);
      } else {
        showMessage(getAllReqs.data.error);
      }
    } else {
      setLoading(false);
      showMessage(response.data.error);
    }
  };

  const removeAssignemnt = function (obj) {
    setLoading(true);
    let url = 'deli';
    if (!currentState.globalReqSideAddons && selectedBusinessItem) {
      url = `${url}/delete/requirement/${selectedBusinessItem.id}/${obj.id}`;
    } else {
      url = `${url}/global/delete/requirement/${selectedItem.id}/${obj.id}`;
    }
    api
      .post(
        url,
        { }
      )
      .then((response) => {
        if (response.data.success) {
          fetchData();
        } else {
          showMessage(response.data.error);
        }
      })
      .catch((error) => {
        setLoading(false);
        showMessage(error);
      });
  };

  const addAssignemnt = function (obj) {
    setLoading(true);
    let url = 'deli';
    if (!currentState.globalReqSideAddons && selectedBusinessItem) {
      url = `${url}/${selectedBusinessItem.id}/assignreq/${obj.id}`;
    } else {
      url = `${url}/global/${selectedItem.id}/assignreq/${obj.id}`;
    }
    api
      .put(
        url,
        { }
      )
      .then((response) => {
        if (response.data.success) {
          fetchData();
        } else {
          showMessage(response.data.error);
        }
      })
      .catch((error) => {
        setLoading(false);
        showMessage(error);
      });
  };

  useEffect(() => {
    if (searchRequirements) {
      filter();
    }
  }, [searchRequirements, searchText]);

  useEffect(() => {
    if (manageRequirements) {
      fetchData();
    }
  }, [manageRequirements]);

  return (
    <Drawer
      title={t('requirements')}
      placement="right"
      closable
      onClose={() => setManageRequirements(false)}
      open={manageRequirements}
      width={500}
    >
      <>
        <strong>{t('assigned')}</strong>
        <Table
          size="small"
          bordered
          loading={loading}
          rowKey="id"
          dataSource={requirements}
          columns={[
            {
              title: t('internal_name'),
              dataIndex: 'internalName',
              key: 'internalName',
              align: 'center',
              className: 'text-xs',
              render: (text) => (
                <span>{text}</span>
              ),
            },
            {
              title: t('name'),
              dataIndex: 'name',
              key: 'name',
              align: 'center',
              className: 'text-xs',
              render: (text) => (
                <span>{text}</span>
              ),
            },
            {
              title: t('minimum'),
              dataIndex: 'minRequired',
              key: 'minRequired',
              align: 'center',
              className: 'text-xs',
              render: (text) => (
                <span>{text}</span>
              ),
            },
            {
              title: t('maximum'),
              dataIndex: 'maxRequired',
              key: 'maxRequired',
              align: 'center',
              className: 'text-xs',
              render: (text) => (
                <span>{text}</span>
              ),
            },
            {
              title: '',
              align: 'center',
              className: 'text-xs',
              render: (row) => (
                <Button
                  size="small"
                  type="primary"
                  danger
                  onClick={() => {
                    removeAssignemnt(row);
                  }}
                >
                  {t('delete')}
                </Button>
              ),
            }
          ]}
          expandable={{
            expandedRowRender: (record) => (
              <Table
                size="small"
                bordered
                dataSource={record.items}
                pagination={false}
                rowKey="id"
                columns={[
                  {
                    title: t('image'),
                    align: 'center',
                    className: 'text-xs',
                    render: (row) => (
                      <>
                        {row.imageUrl && row.imageUrl.length > 0 && (
                          <img
                            style={{ margin: 'auto' }}
                            width={50}
                            src={row.imageUrl}
                            alt={row.name}
                          />
                        )}
                      </>
                    ),
                  },
                  {
                    title: t('name'),
                    dataIndex: 'name',
                    key: 'name',
                    align: 'center',
                    className: 'text-xs',
                    render: (text) => (
                      <span>{text}</span>
                    ),
                  },
                  {
                    title: t('description'),
                    dataIndex: 'description',
                    key: 'description',
                    align: 'center',
                    className: 'text-xs',
                    render: (text) => (
                      <span>{text}</span>
                    ),
                  }
                ]}
              />
            ),
            rowExpandable: (record) => record.items,
          }}
        />
        <Divider />
        <Search
          placeholder={t('search_requirements')}
          allowClear
          onChange={(obj) => {
            setSearchText(obj.target.value);
          }}
          value={searchText}
        />
        <Table
          size="small"
          bordered
          loading={loading}
          columns={[
            {
              title: t('internal_name'),
              dataIndex: 'internalName',
              key: 'internalName',
              align: 'center',
              className: 'text-xs',
              render: (text) => (
                <span>{text}</span>
              ),
            },
            {
              title: t('name'),
              dataIndex: 'name',
              key: 'name',
              align: 'center',
              className: 'text-xs',
              render: (text) => (
                <span>{text}</span>
              ),
            },
            {
              title: t('minimum'),
              dataIndex: 'minRequired',
              key: 'minRequired',
              align: 'center',
              className: 'text-xs',
              render: (text) => (
                <span>{text}</span>
              ),
            },
            {
              title: t('maximum'),
              dataIndex: 'maxRequired',
              key: 'maxRequired',
              align: 'center',
              className: 'text-xs',
              render: (text) => (
                <span>{text}</span>
              ),
            },
            {
              title: '',
              align: 'center',
              className: 'text-xs',
              render: (row) => (
                <Button
                  size="small"
                  type="primary"
                  onClick={() => {
                    addAssignemnt(row);
                  }}
                >
                  {t('assign')}
                </Button>
              ),
            }
          ]}
          rowKey="id"
          dataSource={filtered}
          expandable={{
            expandedRowRender: (record) => (
              <Table
                size="small"
                bordered
                dataSource={record.items}
                pagination={false}
                rowKey="id"
                columns={[
                  {
                    title: t('image'),
                    align: 'center',
                    className: 'text-xs',
                    render: (row) => (
                      <img
                        style={{ margin: 'auto' }}
                        width={50}
                        src={row.imageUrl}
                        alt={row.name}
                      />
                    ),
                  },
                  {
                    title: t('name'),
                    dataIndex: 'name',
                    key: 'name',
                    align: 'center',
                    className: 'text-xs',
                    render: (text) => (
                      <span>{text}</span>
                    ),
                  },
                  {
                    title: t('description'),
                    dataIndex: 'description',
                    key: 'description',
                    align: 'center',
                    className: 'text-xs',
                    render: (text) => (
                      <span>{text}</span>
                    ),
                  }
                ]}
              />
            ),
            rowExpandable: (record) => record.items,
          }}
        />
      </>
    </Drawer>
  );
}

export default ManageRequirementsDrawer;
