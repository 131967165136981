import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import {
  Drawer,
  Input,
  Avatar,
  notification,
  Tooltip,
  Button,
  Dropdown,
  Menu
} from 'antd';
import { Comment } from '@ant-design/compatible';
import { useRecoilState, useRecoilValue, useSetRecoilState, } from 'recoil';
import {
  editBookingHostAtom,
  selectedBookingHostAtom,
  reloadBookingHostAtom
} from '../../../../../atoms/Atoms';
import Grocefy from '../../../../../assets/images/grocefyLogoAlone.png';
import bookingApi from '../../../../../api/bookingApi';
import api from '../../../../../api/api';
import { isEmpty } from '../../../../utils/functions';

function EditHost() {
  const { t } = useTranslation();
  const { TextArea } = Input;
  const setReload = useSetRecoilState(reloadBookingHostAtom);
  const selectedHost = useRecoilValue(selectedBookingHostAtom);
  const [editHost, setEditHost] = useRecoilState(editBookingHostAtom);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [users, setUsers] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  function showMessage(message) {
    notification.open({
      message: '',
      description: (
        <Comment
          author={<span>Grocefy</span>}
          avatar={<Avatar src={Grocefy} alt="grocefy" />}
          content={
            <p className="text-sm">
              {message}
            </p>
          }
          datetime={
            <Tooltip title={moment().format('YYYY-MM-DD HH:mm:ss')}>
              <span>{moment().fromNow()}</span>
            </Tooltip>
          }
        />
      ),
    });
  }

  function submit() {
    setLoading(true);
    if (selectedHost.id) {
      bookingApi.put('hosts', data)
        .then((response) => {
          setLoading(false);
          if (response.data.error) {
            showMessage(response.data.error);
          } else {
            setReload(true);
            setEditHost(false);
          }
        })
        .catch((error) => {
          setLoading(false);
          showMessage(error.message);
        });
    } else {
      bookingApi.post('hosts', data)
        .then((response) => {
          setLoading(false);
          if (response.data.error) {
            showMessage(response.data.error);
          } else {
            setReload(true);
            setEditHost(false);
          }
        })
        .catch((error) => {
          setLoading(false);
          showMessage(error.message);
        });
    }
  }

  function remove() {
    setLoading(true);
    bookingApi.delete(`hosts/${selectedHost?.id}`)
      .then((response) => {
        setLoading(false);
        if (response.data.error) {
          showMessage(response.data.error);
        } else {
          setReload(true);
          setEditHost(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        showMessage(error.message);
      });
  }

  function searchUsers() {
    api
      .post('users/search', { SearchText: searchText })
      .then((response) => {
        if (response?.data?.success) {
          setUsers(response.data.data.results);
        } else {
          setLoading(false);
          showMessage(response?.data?.error);
        }
      })
      .catch((error) => {
        setLoading(false);
        showMessage(error.message);
      });
  }

  useEffect(() => {
    if (selectedHost && editHost) {
      setSearchText('');
      setUsers([]);
      if (selectedHost.id) {
        setData(selectedHost);
      } else {
        setData({
          description: '',
          userId: ''
        });
      }
    } else if (!editHost) {
      setData(null);
    }
  }, [selectedHost, editHost]);

  return (
    <Drawer
      title={selectedHost?.id ? t('edit_host') : t('create_host')}
      placement="right"
      closable={!loading}
      onClose={() => setEditHost(false)}
      open={editHost}
      width={300}
    >
      <div hidden={!data?.id}>
        <div
          className="float-right"
        >
          <Button
            type="primary"
            danger
            loading={loading}
            onClick={() => { remove(); }}
          >
            {t('delete')}
          </Button>
        </div>
        <br />
      </div>
      <div
        className="mb-4"
      >
        <strong>{t('user')}</strong>
        {isEmpty(data?.userId) && (
          <Dropdown
            trigger="click"
            placement="bottom"
            overlay={
              <Menu>
                {_.map(users, (u) => (
                  <Menu.Item
                    onClick={() => {
                      setData({
                        ...data,
                        userId: u.id,
                        firstName: u.firstName,
                        lastName: u.lastName
                      });
                    }}
                  >
                    {u.completeName}: {u.email}
                  </Menu.Item>
                ))}
              </Menu>
            }
          >
            <Input.Search
              className="w-full"
              allowClear
              disabled={loading}
              value={searchText}
              onChange={(obj) => {
                setSearchText(obj.target.value);
              }}
              onSearch={() => searchUsers()}
              enterButton={t('search')}
            />
          </Dropdown>
        )}
        {!isEmpty(data?.userId) && (
          <>
            <br />
            {data?.firstName} {data?.lastName}
            {isEmpty(data?.id) && (
              <Button
                size="small"
                type="primary"
                danger
                className="ml-2"
                onClick={() => {
                  setData({
                    ...data,
                    userId: '',
                    firstName: '',
                    lastName: '',
                  });
                }}
              >
                {t('remove')}
              </Button>
            )}
          </>
        )}
      </div>
      <div
        className="mb-4"
      >
        <strong>{t('description')}</strong>
        <TextArea
          rows={3}
          className="w-full"
          disabled={loading}
          value={data?.description}
          onChange={(obj) => {
            setData({
              ...data,
              description: obj.target.value
            });
          }}
        />
      </div>
      <div
        className="float-right"
      >
        <Button
          type="primary"
          loading={loading}
          disabled={isEmpty(data?.userId)}
          onClick={() => { submit(); }}
        >
          {data?.id ? t('update') : t('create')}
        </Button>
      </div>
    </Drawer>
  );
}
export default EditHost;
