import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import SignatureCanvas from 'react-signature-canvas';
import { useRecoilValueLoadable, useRecoilValue } from 'recoil';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Table,
  Button,
  Image,
  Popover,
  Upload,
  Input,
  Modal,
  Tabs,
  notification,
  Avatar,
  Switch,
  Select
} from 'antd';
import { Comment } from '@ant-design/compatible';
import { PageHeader } from '@ant-design/pro-layout';
import {
  InboxOutlined,
  CopyOutlined,
  EyeOutlined
} from '@ant-design/icons';
import { profileAtom, timezoneAtom } from '../../../../atoms/Atoms';
import Grocefy from '../../../../assets/images/grocefyLogoAlone.png';
import api from '../../../../api/api';
import {
  renderDate,
  dataURItoBlob,
} from '../../../utils/functions';

function DbDocuments() {
  const [sigPad, setSigPad] = useState(null);
  const timezone = useRecoilValue(timezoneAtom);
  const userProfile = useRecoilValueLoadable(profileAtom);
  const isAdmin =
    userProfile.contents &&
    userProfile?.contents?.roles?.includes('SuperAdmin');
  const isCustomerService =
    isAdmin ||
    (userProfile?.contents &&
      (userProfile?.contents?.roles?.includes('CustomerSupportAdmin') ||
        userProfile?.contents?.roles?.includes('CustomerSupport')));
  const history = useHistory();

  if (!isAdmin && !isCustomerService) {
    history.push('/');
    return null;
  }

  const { Dragger } = Upload;
  const { Option } = Select;
  const [loading, setLoading] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [showUpload, setShowUpload] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [groups, setGroups] = useState([]);
  const [fileData, setFileData] = useState(null);
  const [signedFileData, setSignedFileData] = useState(null);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [selectedExtension, setSelectedExtension] = useState(null);
  const { t } = useTranslation();

  function isImageFile(url) {
    if (url) {
      let toTest = url;
      if (toTest.includes('/')) {
        toTest = toTest.split('/')[toTest.split('/').length - 1];
      }

      if (/\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(toTest)) {
        console.log('was image extension');
        return true;
      }
      const re = /(?:\.([^.]+))?$/;
      const ext = re.exec(toTest)[1];
      console.log('extension found', ext);
      if (ext === undefined) {
        return true;
      }
    }
    return false;
  }

  const draggerProps = {
    name: 'file',
    multiple: false,
    showUploadList: false,
    beforeUpload: (file) => {
      setFileData(file);
      return false;
    }
  };

  const signedDraggerProps = {
    name: 'file',
    multiple: false,
    showUploadList: false,
    beforeUpload: (file) => {
      setSignedFileData(file);
      return false;
    }
  };

  const getDocuments = function () {
    setLoading(true);
    api
      .get('admin/documents')
      .then((response) => {
        setLoading(false);
        setGroups(response.data.data.groups);
        if (response.data.data.groups.length > 0 && selectedGroup) {
          const filteredGroups = _.filter(response.data.data.groups, (g) => g.name === selectedGroup.name);
          if (filteredGroups.length > 0) {
            const filteredExtensions = _.filter(filteredGroups[0].extensions, (g) => g.extension === selectedExtension.extension);
            if (filteredExtensions.length === 0) {
              selectedExtension(filteredGroups[0].extensions[0]);
            }
          } else {
            setSelectedGroup(response.data.data.groups[0]);
            setSelectedExtension(response.data.data.groups[0].extensions[0]);
          }
        } else {
          setSelectedGroup(null);
          setSelectedExtension(null);
        }
      }).catch((err) => {
        setLoading(false);
        console.error(err);
      });
  };

  const uploadFile = async function (temp) {
    const file = temp;
    const boundary = Date.now();
    const data = new FormData();
    data.append('file', file);
    setUploading(true);
    const response = await api
      .post('utility/documents/uploadfile', data);
    setUploading(false);
    if (response.data.success) {
      return response.data.data;
    }
    return null;
  };

  const updateFile = function () {
    setUploading(true);
    api
      .post('admin/documents', selectedDocument)
      .then((response) => {
        setUploading(false);
        setGroups(response.data.data.groups);

        if (response.data.data.groups.length > 0 && selectedGroup) {
          const filteredGroups = _.filter(response.data.data.groups, (g) => g.name === selectedGroup.name);
          if (filteredGroups.length > 0) {
            const filteredExtensions = _.filter(filteredGroups[0].extensions, (g) => g.extension === selectedExtension.extension);
            if (filteredExtensions.length === 0) {
              selectedExtension(filteredGroups[0].extensions[0]);
            }
          } else {
            setSelectedGroup(response.data.data.groups[0]);
            setSelectedExtension(response.data.data.groups[0].extensions[0]);
          }
        } else {
          setSelectedGroup(null);
          setSelectedExtension(null);
        }

        setShowUpload(false);
      }).catch((err) => {
        setUploading(false);
        console.error(err);
      });
  };

  const removeFile = function (fileId) {
    setLoading(true);
    api
      .delete(`admin/documents/${fileId}`)
      .then((response) => {
        setLoading(false);
        setGroups(response.data.data.groups);
        if (response.data.data.groups.length > 0 && selectedGroup) {
          const filteredGroups = _.filter(response.data.data.groups, (g) => g.name === selectedGroup.name);
          if (filteredGroups.length > 0) {
            const filteredExtensions = _.filter(filteredGroups[0].extensions, (g) => g.extension === selectedExtension.extension);
            if (filteredExtensions.length === 0) {
              selectedExtension(filteredGroups[0].extensions[0]);
            }
          } else {
            setSelectedGroup(response.data.data.groups[0]);
            setSelectedExtension(response.data.data.groups[0].extensions[0]);
          }
        } else {
          setSelectedGroup(null);
          setSelectedExtension(null);
        }
      }).catch((err) => {
        setLoading(false);
        console.error(err);
      });
  };

  useEffect(async () => {
    if (fileData) {
      const url = await uploadFile(fileData);
      const temp = JSON.parse(JSON.stringify(selectedDocument));
      temp.fileUrl = url;
      setSelectedDocument(temp);
    }
  }, [fileData]);

  useEffect(async () => {
    if (signedFileData) {
      const url = await uploadFile(signedFileData);
      const temp = JSON.parse(JSON.stringify(selectedDocument));
      temp.signedFileUrl = url;
      setSelectedDocument(temp);
    }
  }, [signedFileData]);

  useEffect(() => {
    getDocuments();
  }, []);

  useEffect(() => {
    if (sigPad) {
      sigPad.clear();
    }
  }, [showUpload]);

  return (
    <div>
      <PageHeader
        title={t('documents')}
        extra={[
          <Select
            style={{ width: 130 }}
            key="name"
            loading={loading}
            disabled={loading || groups?.length === 0}
            value={selectedGroup?.name}
            onChange={(value) => {
              const temp = _.filter(groups, (g) => g.name === value);
              setSelectedGroup(temp[0]);
              setSelectedExtension(temp[0].extensions[0]);
            }}
            defaultValue=""
          >
            {_.map(groups, (group) => (
              <Option key={group.name} value={group.name}>{group.name}</Option>
            ))}
          </Select>,
          <Select
            style={{ width: 100 }}
            loading={loading}
            disabled={loading || groups?.length === 0}
            key="extension"
            value={selectedExtension?.extension}
            onChange={(value) => {
              const temp = _.filter(selectedGroup?.extensions, (g) => g.name === value);
              setSelectedExtension(temp[0]);
            }}
            defaultValue=""
          >
            {_.map(selectedGroup?.extensions, (extension) => (
              <Option key={extension.extension} value={extension.extension}>
                &lsquo;.{extension.extension}&rsquo;
              </Option>
            ))}
          </Select>,
          <Button
            type="primary"
            key="create"
            loading={loading}
            size="small"
            onClick={() => {
              setSelectedDocument({});
              setShowUpload(true);
            }}
          >
            {t('upload')}
          </Button>,
          <Button
            type="primary"
            key="reload"
            size="small"
            onClick={() => {
              getDocuments();
            }}
          >
            {t('reload')}
          </Button>
        ]}
      />
      <Table
        bordered
        loading={loading}
        rowKey="id"
        dataSource={selectedExtension?.documents}
        columns={[
          {
            title: t('name'),
            align: 'center',
            dataIndex: 'name',
            className: 'text-xs',
            render: (text) => <span>{text}</span>,
          },
          {
            title: t('created_on'),
            align: 'center',
            dataIndex: 'createdAt',
            className: 'text-xs',
            render: (text) => <span>{renderDate(text, timezone)}</span>,
          },
          {
            title: t('last_edited'),
            align: 'center',
            dataIndex: 'editedAt',
            className: 'text-xs',
            render: (text) => <span>{renderDate(text, timezone)}</span>,
          },
          {
            title: t('file'),
            dataIndex: 'fileUrl',
            align: 'center',
            className: 'text-xs',
            render: (text) => (
              <div className="space-x-2">
                <Button
                  type="primary"
                  size="small"
                  style={{ marginRight: 10 }}
                  onClick={() => {
                    navigator.clipboard.writeText(text);
                    notification.open({
                      message: 'Alert!',
                      description: (
                        <Comment
                          author={<span>Grocefy</span>}
                          avatar={<Avatar src={Grocefy} alt="grocefy" />}
                          content={
                            <p className="text-sm">{t('url_copied_content')}</p>
                          }
                        />
                      ),
                    });
                  }}
                >
                  <CopyOutlined /> {t('copy_url')}
                </Button>
                <Button
                  type="primary"
                  size="small"
                  target="_blank"
                  href={text}
                >
                  {t('download')}
                </Button>
                {isImageFile(text) && (
                  <Popover content={
                    <Image
                      width={200}
                      src={text}
                    />
                    }
                  >
                    <EyeOutlined />
                  </Popover>
                )}
              </div>
            ),
          },
          {
            title: t('requires_signature'),
            align: 'center',
            className: 'text-xs',
            render: (row) => <span>{row.requiresSignature ? 'Yes' : 'No'}</span>,
          },
          {
            title: t('signed_file'),
            align: 'center',
            className: 'text-xs',
            render: (row) => (
              <div className="space-x-2">
                <Button
                  type="primary"
                  disabled={!row.requiresSignature || !row.signedFileUrl}
                  size="small"
                  onClick={() => {
                    navigator.clipboard.writeText(row.signedFileUrl);
                    notification.open({
                      message: 'Alert!',
                      description: (
                        <Comment
                          author={<span>Grocefy</span>}
                          avatar={<Avatar src={Grocefy} alt="grocefy" />}
                          content={
                            <p className="text-sm">{t('url_copied_content')}</p>
                          }
                        />
                      ),
                    });
                  }}
                >
                  <CopyOutlined /> {t('copy_url')}
                </Button>
                <Button
                  type="primary"
                  size="small"
                  target="_blank"
                  disabled={!row.requiresSignature || !row.signedFileUrl}
                  href={row.signedFileUrl}
                >
                  {t('download')}
                </Button>
                {isImageFile(row.signedFileUrl) && (
                  <Popover content={
                    <Image
                      width={200}
                      src={row.signedFileUrl}
                    />
                    }
                  >
                    <EyeOutlined />
                  </Popover>
                )}
              </div>
            ),
          },
          {
            title: t('actions'),
            align: 'center',
            className: 'text-xs',
            render: (row) => (
              <>
                <Button
                  type="primary"
                  size="small"
                  className="mx-4"
                  onClick={() => {
                    setSelectedDocument(row);
                    setShowUpload(true);
                  }}
                >
                  {t('edit')}
                </Button>
                <Button
                  type="primary"
                  danger
                  size="small"
                  onClick={() => {
                    removeFile(row.id);
                  }}
                >
                  {t('delete')}
                </Button>
              </>
            ),
          }
        ]}
      />
      <Modal
        title={selectedDocument?.id ? 'Update File' : 'Upload File'}
        open={showUpload}
        onCancel={() => setShowUpload(false)}
        footer={[
          <Button
            key="ok"
            type="primary"
            loading={uploading}
            onClick={() => setShowUpload(false)}
          >
            {t('close')}
          </Button>,
          <Button
            key="upload"
            type="primary"
            loading={uploading}
            onClick={() => updateFile()}
          >
            {selectedDocument?.id ? t('update') : t('upload')}
          </Button>
        ]}
      >
        <Input
          className="mt-2"
          addonBefore="Name"
          disabled={uploading}
          loading={uploading}
          value={selectedDocument?.name}
          onChange={(obj) => {
            const temp = JSON.parse(JSON.stringify(selectedDocument));
            temp.name = obj.target.value;
            setSelectedDocument(temp);
          }}
        />
        <Input
          className="mt-2"
          addonBefore="Collection"
          disabled={uploading}
          loading={uploading}
          value={selectedDocument?.groupName}
          onChange={(obj) => {
            const temp = JSON.parse(JSON.stringify(selectedDocument));
            temp.groupName = obj.target.value;
            setSelectedDocument(temp);
          }}
        />
        <div className="my-2">
          <strong>{t('requires_signature')}?</strong>
          <Switch
            disabled={uploading}
            loading={uploading}
            style={{ float: 'right' }}
            checked={selectedDocument?.requiresSignature}
            onChange={(checked) => {
              const temp = JSON.parse(JSON.stringify(selectedDocument));
              temp.requiresSignature = checked;
              setSelectedDocument(temp);
            }}
          />
        </div>
        <Dragger
          {...draggerProps}
          className="mt-4"
          disabled={uploading}
          loading={uploading}
        >
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p
            className="ant-upload-text"
            style={{ margin: 10 }}
          >
            {t('click_or_drag_content')}
          </p>
        </Dragger>
        <Input
          addonBefore={t('file_url')}
          size="small"
          disabled={uploading}
          loading={uploading}
          value={selectedDocument?.fileUrl}
          onChange={(obj) => {
            const temp = JSON.parse(JSON.stringify(selectedDocument));
            temp.fileUrl = obj.target.value;
            setSelectedDocument(temp);
          }}
        />
        <Tabs>
          <Tabs.TabPane tab={t('upload_signed_file')} key="0">
            <Dragger
              {...signedDraggerProps}
              disabled={uploading}
              loading={uploading}
            >
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p
                className="ant-upload-text"
                style={{ margin: 10 }}
              >
                {t('click_or_drag_content')}
              </p>
            </Dragger>
          </Tabs.TabPane>
          <Tabs.TabPane tab={t('upload_digital_signature')} key="1">
            <div style={{ border: '1px solid black' }}>
              <div>
                <SignatureCanvas
                  canvasProps={{ style: { height: '200px' } }}
                  ref={(ref) => { setSigPad(ref); }}
                />
              </div>
              <div className="flex space-x-1 m-1">
                <Button
                  type="primary"
                  className="w-full"
                  danger
                  onClick={() => {
                    sigPad.clear();
                  }}
                >
                  {t('clear')}
                </Button>
                <Button
                  type="primary"
                  className="w-full"
                  onClick={async () => {
                    const sig = sigPad.getTrimmedCanvas().toDataURL('image/png');
                    const url = await uploadFile(dataURItoBlob(sig));
                    const temp = JSON.parse(JSON.stringify(selectedDocument));
                    temp.signedFileUrl = url;
                    setSelectedDocument(temp);
                  }}
                >
                  {t('use')}
                </Button>
              </div>
            </div>
          </Tabs.TabPane>
        </Tabs>
        <Input
          addonBefore="Signed File Url"
          size="small"
          disabled={uploading}
          loading={uploading}
          value={selectedDocument?.signedFileUrl}
          onChange={(obj) => {
            const temp = JSON.parse(JSON.stringify(selectedDocument));
            temp.signedFileUrl = obj.target.value;
            setSelectedDocument(temp);
          }}
        />
      </Modal>
    </div>
  );
}

export default DbDocuments;
