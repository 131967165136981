import React from 'react';

import WeeklyLossHeader from './shared-components/PastLossHeader';
import WeeklyLossBody from './shared-components/PastLossBody';

function PastLoss() {
  return (
    <>
      <WeeklyLossHeader />
      <WeeklyLossBody />
    </>
  );
}

export default PastLoss;
