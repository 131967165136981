import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  useRecoilValue,
  useRecoilState,
  useRecoilValueLoadable
} from 'recoil';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Table
} from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import moment from 'moment';
import {
  timezoneAtom,
  selectedLoyaltyAtom,
  refreshLoyaltiesAtom,
  profileAtom
} from '../../../atoms/Atoms';
import EditLoyaltyDrawer from './shared-components/EditLoyaltyDrawer';
import api from '../../../api/api';

function Loyalty() {
  const timezone = useRecoilValue(timezoneAtom);
  const [loyalties, setLoyalties] = useState([]);
  const [selectedLoyalty, setSelectedLoyalty] = useRecoilState(selectedLoyaltyAtom);
  const [refreshLoyalties, setRefreshLoyalties] = useRecoilState(refreshLoyaltiesAtom);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const userProfile = useRecoilValueLoadable(profileAtom);
  const isAdmin =
    userProfile.contents && userProfile?.contents?.roles?.includes('SuperAdmin');
  const isMarketing =
    isAdmin || (userProfile?.contents && userProfile?.contents?.roles?.includes('Marketing'));
  const history = useHistory();

  if (!isAdmin && !isMarketing) {
    history.push('/');
    return null;
  }

  const getLoyalties = function () {
    setLoading(true);
    api.get('loyalty/rewards')
      .then((response) => {
        setLoading(false);
        if (response.data.success) {
          if (response?.data?.data?.length > 0) {
            const tempList = [...response.data.data];
            for (let i = 0; i < tempList.length; i++) {
              if (!tempList[i].businessId) {
                tempList[i].businessId = '';
              }
              let status = tempList[i].isActive ? t('pending') : t('needs_approval');
              const startDate = moment(`${tempList[i].startDate}+0000`);
              const endDate = moment(`${tempList[i].endDate}+0000`);
              const currentDate = moment();
              if (currentDate >= startDate && currentDate <= endDate) {
                status = tempList[i].isActive ? t('active') : t('needs_approval');
              } else if (currentDate > endDate) {
                status = tempList[i].isActive ? t('completed') : t('passed');
              }
              tempList[i].status = status;
              tempList[i].startDateTime = moment(startDate).tz(timezone);
              tempList[i].endDateTime = moment(endDate).tz(timezone);
            }
            setLoyalties(tempList);
          } else {
            setLoyalties([]);
          }
        } else {
          console.error(response.data.error);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  useEffect(() => {
    setSelectedLoyalty(null);
    getLoyalties();
  }, []);

  useEffect(() => {
    if (refreshLoyalties) {
      setRefreshLoyalties(false);
      getLoyalties();
    }
  }, [refreshLoyalties]);

  return (
    <>
      <PageHeader
        title={t('loyalty_rewards')}
        extra={[
          <Button
            key="create"
            type="primary"
            size="small"
            onClick={() => {
              setSelectedLoyalty({});
            }}
          >
            {t('create')}
          </Button>,
          <Button
            key="reload"
            type="primary"
            size="small"
            onClick={() => {
              setRefreshLoyalties(true);
            }}
          >
            {t('reload')}
          </Button>
        ]}
      />
      <Table
        size="small"
        bordered
        loading={loading}
        pagination={false}
        rowKey="id"
        dataSource={loyalties}
        columns={[
          {
            title: t('internal_name'),
            align: 'center',
            className: 'text-xs',
            render: (row) => (
              <span>{row.internalName}</span>
            ),
          },
          {
            title: t('name'),
            align: 'center',
            className: 'text-xs',
            render: (row) => (
              <span>{row.name}</span>
            ),
          },
          {
            title: t('points_for_redeem'),
            dataIndex: 'pointForRedeem',
            align: 'center',
            className: 'text-xs',
            render: (text) => (
              <span>{text}</span>
            ),
          },
          {
            title: t('start_date'),
            dataIndex: 'startDate',
            align: 'center',
            className: 'text-xs',
            render: (text) => (
              <span>
                {moment(`${text}+0000`)
                  .format('MMMM Do YYYY, h:mm:ss a')}
              </span>
            ),
          },
          {
            title: t('end_date'),
            dataIndex: 'endDate',
            align: 'center',
            className: 'text-xs',
            render: (text) => (
              <span>
                {moment(`${text}+0000`)
                  .format('MMMM Do YYYY, h:mm:ss a')}
              </span>
            ),
          },
          {
            title: t('active'),
            dataIndex: 'isActive',
            align: 'center',
            className: 'text-xs',
            render: (text) => (
              <span>{text ? t('yes') : t('no')}</span>
            ),
          },
          {
            title: t('status'),
            dataIndex: 'status',
            align: 'center',
            className: 'text-xs',
            render: (text) => (
              <span>{text}</span>
            ),
          },
          {
            title: '',
            align: 'center',
            className: 'text-xs',
            render: (row) => (
              <Button
                size="small"
                onClick={() => {
                  setSelectedLoyalty(row);
                }}
              >
                {t('edit')}
              </Button>
            ),
          }
        ]}
      />
      {selectedLoyalty && (
        <EditLoyaltyDrawer />
      )}
    </>
  );
}

export default Loyalty;
