import _ from 'lodash';

function notInitResponse() {
  console.error('GopanzaCouponEngine', 'Package has not been initialized!');
  return { success: false, message: 'Package has not been initialized!' };
}

class GopanzaCouponService {
  constructor(subscriptionKey, clientId, userId) {
    this.initialized = subscriptionKey && clientId && userId && true;
    this.subscriptionKey = subscriptionKey;
    this.clientId = clientId;
    this.userId = userId;
    this.headers = {
      'Ocp-Apim-Subscription-Key': subscriptionKey,
      'gopanza-clientId': clientId,
      'gopanza-userId': userId,
    };
    this.lastIdUsed = 1;
    this.coupons = [{
      id: '1',
      name: 'Test Coupon 1',
      limit: 100,
      status: 1,
      start: '2021-10-01T04:00:00',
      end: '2021-10-01T04:00:00',
      businesses: [],
      itemRequirements: [],
      sectionRequirements: [],
      discounts: [],
    }, {
      id: '2',
      name: 'Test Coupon 2',
      limit: 100,
      status: 1,
      start: '2021-10-01T04:00:00',
      end: '2021-10-01T04:00:00',
      businesses: [],
      itemRequirements: [],
      sectionRequirements: [],
      discounts: [],
    }, {
      id: '3',
      name: 'Test Coupon 3',
      limit: 100,
      status: 1,
      start: '2021-10-01T04:00:00',
      end: '2021-10-01T04:00:00',
      businesses: [],
      itemRequirements: [],
      sectionRequirements: [],
      discounts: [],
    }];
  }

  initialize(subscriptionKey, clientId, userId) {
    this.initialized = subscriptionKey && clientId && userId && true;
    this.subscriptionKey = subscriptionKey;
    this.clientId = clientId;
    this.userId = userId;
    this.headers = {
      'Ocp-Apim-Subscription-Key': subscriptionKey,
      'gopanza-clientId': clientId,
      'gopanza-userId': userId,
    };
  }

  async getCoupons() {
    if (!this.initialized) {
      return notInitResponse();
    }
    return { success: true, data: this.coupons };
  }

  async getCoupon(couponId) {
    if (!this.initialized) {
      return notInitResponse();
    }
    const temp = _.filter(this.coupons, (coupon) => coupon.id === couponId);
    if (temp.length > 0) {
      return { success: true, data: temp[0] };
    }
    return { success: false, message: 'Specified coupon does not exist' };
  }

  async createCoupon(coupon) {
    if (!this.initialized) {
      return notInitResponse();
    }
    const temp = JSON.parse(JSON.stringify(coupon));
    this.lastIdUsed += 1;
    temp.id = `${this.lastIdUsed}`;
    this.coupons.push(temp);
    return { success: true, data: temp };
  }

  async updateCoupon(coupon) {
    if (!this.initialized) {
      return notInitResponse();
    }
    for (let i = 0; i < this.coupons.length; i++) {
      if (this.coupons[i].id === coupon.id) {
        this.coupons[i].name = coupon.name;
        this.coupons[i].limit = coupon.limit;
        this.coupons[i].start = coupon.start;
        this.coupons[i].status = coupon.status;
        this.coupons[i].end = coupon.end;
        this.coupons[i].businesses = coupon.businesses;
        this.coupons[i].itemRequirements = coupon.itemRequirements;
        this.coupons[i].sectionRequirements = coupon.sectionRequirements;
        this.coupons[i].discounts = coupon.discounts;
      }
    }
    const temp = _.filter(this.coupons, (c) => c.id === coupon.id);
    if (temp.length > 0) {
      return { success: true, data: temp[0] };
    }
    return { success: false, message: 'Specified coupon does not exist' };
  }

  async deleteCoupon(couponId) {
    if (!this.initialized) {
      return notInitResponse();
    }
    this.coupons = _.filter(this.coupons, (coupon) => coupon.id !== couponId);
    return { success: true, data: this.coupons };
  }
}

// class GopanzaCouponEngine {

// }

// const engine = new GopanzaCouponEngine();
const service = new GopanzaCouponService();

// export default { engine, service };
export default service;
