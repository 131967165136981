import { Tabs } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Cards from './Cards';
import Customers from './Customers';
import Locations from './Locations';
import Orders from './Orders';

function userAnalytics() {
  const { t } = useTranslation();
  const width = window.innerWidth;

  return (
    <div>
      <PageHeader
        className="-mt-8 p-0"
        title={t('customer_analytics')}
      />
      <Tabs defaultActiveKey="1">
        <Tabs.TabPane tab={t('customers')} key="1">
          <Customers />
        </Tabs.TabPane>
        <Tabs.TabPane tab={t('orders')} key="2">
          <Orders />
        </Tabs.TabPane>
        <Tabs.TabPane tab={t('cards')} key="3">
          <Cards />
        </Tabs.TabPane>
        <Tabs.TabPane className={width < 1024 ? 'hidden' : null} tab={width < 1024 ? '' : t('locations')} key="4">
          <Locations />
        </Tabs.TabPane>
      </Tabs>
    </div>
  );
}

export default userAnalytics;
