import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { useRecoilState, useRecoilValue, useRecoilValueLoadable } from 'recoil';
import { Table, Tag, Button, Avatar, Tabs, Checkbox } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import { useTranslation } from 'react-i18next';
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  SyncOutlined,
} from '@ant-design/icons';
import {
  businessAtom,
  reloadDeliveryZonesAtom,
  showCreateDeliveryZoneAtom,
  showEditDeliveryZoneHoursAtom,
  selectedDriverAtom,
  deliveryZonesAtom,
  showAddDriverDrawerAtom,
  reloadDriversAtom,
  selectedDeliveryZoneAtom,
} from '../../../atoms/Atoms';
import { configSelector } from '../../../atoms/Selectors';
import CreateDeliveryZoneDrawer from './shared-components/CreateDeliveryZoneDrawer';
import EditDeliveryZoneDrawer from './shared-components/EditDeliveryZoneDrawer';
import EditDeliveryZoneHoursModal from './shared-components/EditDeliveryZoneHoursModal';
import AddNewDriverDrawer from './shared-components/AddNewDriverDrawer';
import EditDriverDrawer from './shared-components/EditDriverDrawer';
import api from '../../../api/api';
import GoogleMap from './shared-components/GoogleMap';
import { randomColor } from '../../utils/functions';

function Delivery() {
  const [activeTab, SetActiveTab] = useState('1');
  const config = useRecoilValueLoadable(configSelector);
  const business = useRecoilValue(businessAtom);
  const [reload, setReload] = useRecoilState(reloadDeliveryZonesAtom);
  const [reloadDrivers, setReloadDrivers] = useRecoilState(reloadDriversAtom);
  const showEditDeliveryZoneHours = useRecoilValue(
    showEditDeliveryZoneHoursAtom
  );
  const [showCreateDeliveryZone, setShowCreateDeliveryZone] = useRecoilState(
    showCreateDeliveryZoneAtom
  );
  const [selectedDeliveryZone, setSelectedDeliveryZone] = useRecoilState(
    selectedDeliveryZoneAtom
  );
  const [selectedDriver, setSelectedDriver] = useRecoilState(
    selectedDriverAtom
  );
  const [showAddDriverDrawer, setShowAddDriverDrawer] = useRecoilState(
    showAddDriverDrawerAtom
  );
  const [loading, setLoading] = useState(false);
  const [loadingDrivers, setLoadingDrivers] = useState(false);
  const [zones, setZones] = useRecoilState(deliveryZonesAtom);
  const [drivers, setDrivers] = useState([]);
  const { t, i18n } = useTranslation();
  const [size, setSize] = useState(20);
  const [currentPage, setCurrentPage] = useState(0);
  const [zoneCount, setZoneCount] = useState(0);
  const width = window.innerWidth;

  const getDeliveryZones = function () {
    setLoading(true);
    api
      .get(`deliveries/deliveryzones/${business.locations[0].id}`)
      .then((response) => {
        setLoading(false);
        // setZones(
        //   _.filter(response.data.data.results, function (o) {
        //     return o.isActive;
        //   })
        // );
        const temp = JSON.parse(JSON.stringify(response.data.data.results));
        for (let i = 0; i < temp.length; i++) {
          temp[i].checked = false;
          temp[i].color = randomColor();
        }
        setZones(temp);
        setZoneCount(response.data.data.results.length);
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  const onTableChange = (page, pageSize) => {
    if (page - 1 !== currentPage) {
      setCurrentPage(page - 1);
    } else {
      setSize(pageSize);
    }
  };

  const getDrivers = function () {
    setLoadingDrivers(true);
    api
      .get(`deliveries/driversall/${business.locations[0].id}`)
      .then((response) => {
        setLoadingDrivers(false);
        setDrivers(response.data.data.results);
      })
      .catch((error) => {
        setLoadingDrivers(false);
        console.error(error);
      });
  };

  const toggleZoneChecked = function (zoneId) {
    const temp = JSON.parse(JSON.stringify(zones));
    for (let i = 0; i < temp.length; i++) {
      if (temp[i].id === zoneId) {
        temp[i].checked = !temp[i].checked;
      }
    }
    setZones(temp);
  };

  useEffect(() => {
    if (business) {
      getDeliveryZones();
      getDrivers();
    }
  }, []);

  useEffect(() => {
    if (business) {
      getDeliveryZones();
      getDrivers();
    }
  }, [business]);

  useEffect(() => {
    if (business && reload) {
      setReload(false);
      getDeliveryZones();
    }
  }, [reload]);

  useEffect(() => {
    if (business && reloadDrivers) {
      setReloadDrivers(false);
      getDrivers();
    }
  }, [reloadDrivers]);

  return (
    <>
      <PageHeader
        className="mb-4 px-0"
        title={t('delivery_settings')}
        tags={
          <Tag
            color="#2db7f5"
            icon={business ? <CheckCircleOutlined /> : <SyncOutlined spin />}
          >
            {business ? business.name : `${t('loading')}...`}
          </Tag>
        }
      />
      <div className="flex flex-col" style={{ float: 'right' }}>
        <Button
          className="mb-4"
          size="small"
          type="primary"
          loading={loading || loadingDrivers}
          icon={<SyncOutlined spin={loadingDrivers || loading} />}
          onClick={() => {
            if (activeTab === '2') {
              setReloadDrivers(!reloadDrivers);
            } else {
              setReload(!reload);
            }
          }}
        >
          {t('reload')}
        </Button>
      </div>
      <Tabs
        activeKey={activeTab}
        onChange={(key) => {
          SetActiveTab(key);
        }}
      >
        <Tabs.TabPane tab="Zones" key="1">
          <PageHeader
            title={t('delivery_zones')}
            extra={[
              <Button
                type="primary"
                size="small"
                key="create"
                onClick={() => setShowCreateDeliveryZone(true)}
                loading={loading}
              >
                {t('create')}
              </Button>,
            ]}
          />
          <Table
            bordered
            loading={loading}
            size="small"
            rowKey="id"
            scroll={{ x: 350 }}
            dataSource={zones}
            pagination={{
              pageSize: size,
              showSizeChanger: true,
              defaultCurrent: 0,
              current: currentPage + 1,
              total: zoneCount,
              onChange: onTableChange,
            }}
            columns={[
              {
                title: t('name'),
                dataIndex: 'name',
                key: 'name',
                align: 'left',
                render: (text) => <span>{text}</span>,
              },
              {
                title: t('cost'),
                dataIndex: 'cost',
                key: 'cost',
                align: 'center',
                render: (text) => <span>${text.toFixed(2)}</span>,
              },
              {
                title: t('status'),
                key: 'isActive',
                align: 'left',
                render: (row) => {
                  if (row.isActive) {
                    return (
                      <Tag
                        icon={<CheckCircleOutlined />}
                        color="success"
                        size="small"
                        className="mr-0"
                      >
                        {t('active')}
                      </Tag>
                    );
                  }
                  return (
                    <Tag
                      icon={<CloseCircleOutlined />}
                      color="error"
                      size="small"
                      className="mr-0"
                    >
                      {t('not_active')}
                    </Tag>
                  );
                },
              },
              {
                title: '',
                key: 'edit',
                align: 'center',
                render: (row) => (
                  <Button
                    type="primary"
                    size="small"
                    onClick={() => setSelectedDeliveryZone(row)}
                  >
                    {t('edit')}
                  </Button>
                ),
              },
            ]}
          />
        </Tabs.TabPane>
        <Tabs.TabPane tab={t('drivers')} key="2">
          <PageHeader
            title={t('manage_drivers')}
            extra={[
              <Button
                type="primary"
                size="small"
                key="create"
                onClick={() => setShowAddDriverDrawer(true)}
                loading={loadingDrivers}
              >
                {t('add')}
              </Button>,
            ]}
          />
          <Table
            bordered
            size="small"
            scroll={{ x: 350 }}
            loading={loadingDrivers}
            rowKey="id"
            dataSource={drivers}
            columns={[
              {
                title: '',
                key: 'avatar',
                align: 'left',
                className: 'text-s',
                render: (row) => (
                  <>
                    {row.avatar && row.avatar.length === 0 && (
                      <Avatar
                        style={{
                          background: config?.contents?.data?.primaryColor,
                        }}
                      >
                        {`${row.firstName[0]}${row.lastName[0]}`}
                      </Avatar>
                    )}
                    {row.avatar && row.avatar.length > 0 && (
                      <Avatar
                        style={{
                          background: config?.contents?.data?.primaryColor,
                        }}
                        src={row.avatar}
                      />
                    )}
                  </>
                ),
              },
              {
                title: t('name'),
                key: 'name',
                align: 'center',
                render: (row) => (
                  <span>
                    {row.firstName} {row.lastName}
                  </span>
                ),
              },
              {
                title: t('status'),
                key: 'isActive',
                align: 'left',
                render: (row) => {
                  if (row.isActive) {
                    return (
                      <Tag
                        icon={<CheckCircleOutlined />}
                        color="success"
                        size="small"
                        className="mr-0"
                      >
                        {t('active')}
                      </Tag>
                    );
                  }
                  return (
                    <Tag
                      icon={<CloseCircleOutlined />}
                      color="error"
                      size="small"
                      className="mr-0"
                    >
                      {t('not_active')}
                    </Tag>
                  );
                },
              },
              {
                title: '',
                key: 'edit',
                align: 'center',
                render: (row) => (
                  <Button
                    type="primary"
                    size="small"
                    onClick={() => setSelectedDriver(row)}
                  >
                    {t('edit')}
                  </Button>
                ),
              },
            ]}
          />
        </Tabs.TabPane>
        <Tabs.TabPane tab={width < 1024 ? null : t('map')} key="3" className={width < 1024 ? 'hidden' : null}>
          <div className="flex flex-row-1">
            <div className="p-2 md:hidden w-full">
              <p className="font-bold">Choose Zones</p>
              <div className="flex">
                {_.map(zones, (zone) => (
                  <div className="flex-col">
                    <Checkbox
                      checked={zone.checked}
                      onChange={(e) => toggleZoneChecked(zone.id)}
                    />
                    <p className={zone.isActive ? 'text-sm mx-2' : 'text-sm mx-2 text-red-600'}>{zone.name}</p>
                  </div>
                ))}
              </div>
            </div>
            <div className="pr-2 hidden md:block w-1/3 md:w-1/4 lg:w-1/6">
              <p className="font-bold">Choose Zones</p>
              {_.map(zones, (zone) => (
                <div className="flex flex-row-1">
                  <Checkbox
                    checked={zone.checked}
                    onChange={(e) => toggleZoneChecked(zone.id)}
                  />
                  <p className={zone.isActive ? 'text-sm mx-2' : 'text-sm mx-2 text-red-600'}>{zone.name}</p>
                </div>
              ))}
            </div>
            <div className="w-5/6 md:w-3/4 lg:w-5/6 border rounded-3xl">
              {/* <Map /> */}
              <GoogleMap />
            </div>
          </div>
        </Tabs.TabPane>
      </Tabs>
      {showCreateDeliveryZone && <CreateDeliveryZoneDrawer />}
      {!showCreateDeliveryZone &&
        selectedDeliveryZone &&
        !showEditDeliveryZoneHours && <EditDeliveryZoneDrawer />}
      {!showCreateDeliveryZone &&
        selectedDeliveryZone &&
        showEditDeliveryZoneHours && <EditDeliveryZoneHoursModal />}
      {selectedDriver && !showAddDriverDrawer && <EditDriverDrawer />}
      {!selectedDriver && showAddDriverDrawer && <AddNewDriverDrawer />}
    </>
  );
}
export default Delivery;
