import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  useRecoilValue,
  useRecoilState,
  useRecoilValueLoadable
} from 'recoil';
import {
  Button,
  Table
} from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import moment from 'moment';
import { BuildOutlined, SyncOutlined } from '@ant-design/icons';
import {
  timezoneAtom,
  selectedCampaignAtom,
  refreshCampaignsAtom,
  showCreateCampaignDrawerAtom,
  profileAtom
} from '../../../atoms/Atoms';
import { configSelector } from '../../../atoms/Selectors';
import EditProductCampaign from './shared-components/EditProductCampaign';
import api from '../../../api/api';

function ProductCampaigns() {
  const timezone = useRecoilValue(timezoneAtom);
  const config = useRecoilValueLoadable(configSelector);
  const [campaigns, setCampaigns] = useState([]);
  const [showCreateCampaignDrawer, setShowCreateCampaignDrawer] =
    useRecoilState(showCreateCampaignDrawerAtom);
  const [selectedCampaign, setSelectedCampaign] = useRecoilState(selectedCampaignAtom);
  const [refreshCampaigns, setRefreshCampaigns] = useRecoilState(refreshCampaignsAtom);
  const [loading, setLoading] = useState(false);
  const userProfile = useRecoilValueLoadable(profileAtom);
  const isAdmin =
    userProfile.contents && userProfile?.contents?.roles?.includes('SuperAdmin');
  const isMarketing =
    isAdmin || (userProfile?.contents && userProfile?.contents?.roles?.includes('Marketing'));
  const history = useHistory();
  const { t } = useTranslation();

  if (!isAdmin && !isMarketing) {
    history.push('/');
    return null;
  }

  const getCampaigns = function () {
    setLoading(true);
    api.get('marketing/campaigns/all')
      .then((response) => {
        setLoading(false);
        if (response.data.success) {
          for (let i = 0; i < response.data.data.results.length; i++) {
            if (!response.data.data.results[i].businessId) {
              response.data.data.results[i].businessId = '';
            }
            let status = response.data.data.results[i].isActive ? 'Active' : 'Not Active';
            const startDate = moment(`${response.data.data.results[i].startDate}+0000`);
            const endDate = moment(`${response.data.data.results[i].endDate}+0000`);
            const currentDate = moment();
            if (currentDate >= startDate && currentDate <= endDate) {
              status = response.data.data.results[i].isActive ? 'Active' : 'Not Active';
            } else if (currentDate > endDate) {
              status = response.data.data.results[i].isActive ? 'Completed' : 'Passed';
            }
            response.data.data.results[i].status = status;
            response.data.data.results[i].startDateTime = moment(startDate).tz(timezone);
            response.data.data.results[i].endDateTime = moment(endDate).tz(timezone);
          }
          setCampaigns(response.data.data.results);
        } else {
          console.error(response.data.error);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  useEffect(() => {
    setSelectedCampaign(null);
    getCampaigns();
  }, []);

  useEffect(() => {
    if (refreshCampaigns) {
      setRefreshCampaigns(false);
      getCampaigns();
    }
  }, [refreshCampaigns]);

  return (
    <>
      <PageHeader
        title={t('product_campaign')}
        extra={[
          <Button
            type="primary"
            size="small"
            icon={<BuildOutlined />}
            key="create"
            onClick={() => {
              setShowCreateCampaignDrawer(true);
              setSelectedCampaign({});
            }}
          >
            {t('create')}
          </Button>,
          <Button
            key="reload"
            type="primary"
            icon={<SyncOutlined />}
            size="small"
            loading={loading}
            onClick={() => {
              setRefreshCampaigns(true);
            }}
          >
            {t('reload')}
          </Button>
        ]}
      />
      <Table
        size="small"
        bordered
        loading={loading}
        pagination={false}
        dataSource={campaigns}
        columns={[
          {
            title: t('name'),
            align: 'center',
            className: 'text-xs',
            render: (row) => (
              <span>{row.title}</span>
            ),
          },
          {
            title: t('start_date'),
            dataIndex: 'startDate',
            align: 'center',
            className: 'text-xs',
            render: (text) => (
              <span>
                {moment(`${text}+0000`)
                  .format('MMMM Do YYYY, h:mm:ss a')}
              </span>
            ),
          },
          {
            title: t('end_date'),
            dataIndex: 'endDate',
            align: 'center',
            className: 'text-xs',
            render: (text) => (
              <span>
                {moment(`${text}+0000`)
                  .format('MMMM Do YYYY, h:mm:ss a')}
              </span>
            ),
          },
          {
            title: t('status'),
            dataIndex: 'status',
            align: 'center',
            className: 'text-xs',
            render: (text) => (
              <span>{text}</span>
            ),
          },
          {
            title: '',
            align: 'center',
            className: 'text-xs',
            render: (row) => (
              <div>
                <Button
                  size="small"
                  type="primary"
                  href={`/productcampaign/${row.id}`}
                  onClick={() => {
                    setSelectedCampaign(row);
                  }}
                >
                  {t('status')}
                </Button>
                <Button
                  size="small"
                  hidden={!config?.contents?.data?.data?.productCampaignSite}
                  target="_blank"
                  href={`${config?.contents?.data?.data?.productCampaignSite}/${row.id}`}
                >
                  {t('view')}
                </Button>
              </div>
            ),
          }
        ]}
      />
      {selectedCampaign && showCreateCampaignDrawer && (
        <EditProductCampaign />
      )}
    </>
  );
}

export default ProductCampaigns;
