import React, { useState, useEffect } from 'react';
import { useMount } from 'react-use';
import { useRecoilValue, useRecoilValueLoadable } from 'recoil';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import _ from 'lodash';
import { useJsonToCsv } from 'react-json-csv';
import {
  Tag,
  Badge,
  Button,
  Table,
  Typography,
  Space,
  Select,
  Popover,
  DatePicker,
  Divider,
  Switch
} from 'antd';
import dayjs from 'dayjs';
import { PageHeader } from '@ant-design/pro-layout';
import {
  CheckCircleOutlined,
  CloudDownloadOutlined,
  ReloadOutlined,
  SearchOutlined
} from '@ant-design/icons';
import { businessAtom, timezoneAtom, profileAtom } from '../../../atoms/Atoms';
import { mapOrderType, mapPaymentType } from '../../utils/functions';
import api from '../../../api/api';
import envConfig from '../../../envConfig';
import { dateRangePresets } from '../../utils/utils';

function SalesFinance() {
  const { RangePicker } = DatePicker;
  const { t } = useTranslation();
  const { Option } = Select;
  const { saveAsCsv } = useJsonToCsv();
  const business = useRecoilValue(businessAtom);
  const timezone = useRecoilValue(timezoneAtom);
  const profile = useRecoilValueLoadable(profileAtom);
  const isAdmin =
    profile.contents && profile?.contents?.roles?.includes('SuperAdmin');
  const isStoreManager =
    isAdmin ||
    (profile?.contents &&
      (profile?.contents?.roles?.includes('StoreAdmin') ||
        profile?.contents?.roles?.includes('Owner')));
  const canViewFinance =
    isAdmin ||
    isStoreManager ||
    (profile.contents &&
      (profile.contents.roles.includes('Finance') ||
        profile.contents.roles.includes('TopAnalytics')));
  const [reportData, setReportData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [includeOrders, setIncludeOrders] = useState(false);
  const [loadingPaymentTenders, setLoadingPaymentTenders] = useState(null);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
  const [dateRange, setDateRange] = useState({
    start: moment().startOf('month').format('YYYY-MM-DD'),
    end: moment().endOf('month').format('YYYY-MM-DD')
  });
  const [paymentTenders, setPaymentTenders] = useState([]);

  const width = window.innerWidth;

  const allColumns = [{
    title: t('day'),
    dataIndex: 'date',
    key: 'date',
    align: 'center',
    className: 'text-xs',
    sorter: (a, b) => moment(a.datetime) - moment(b.datetime),
    render: (text) => <span>{text}</span>,
  },
  {
    title: t('count'),
    dataIndex: 'count',
    key: 'count',
    align: 'right',
    className: 'text-xs',
    sorter: (a, b) => a.count - b.count,
    render: (text) => <span>{text}</span>,
  },
  {
    title: t('total'),
    dataIndex: 'orderTotal',
    key: 'orderTotal',
    align: 'right',
    className: 'text-xs',
    sorter: (a, b) => a.total - b.total,
    render: (text) => (
      <span>
        $
        {text
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
      </span>
    ),
  },
  {
    title: t('products'),
    dataIndex: 'productsTotal',
    key: 'productsTotal',
    align: 'right',
    className: 'text-xs',
    sorter: (a, b) => a.productsTotal - b.productsTotal,
    render: (text) => (
      <span>
        $
        {text
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
      </span>
    ),
  },
  {
    title: t('municipal_tax'),
    dataIndex: 'municipalTax',
    key: 'municipalTax',
    align: 'right',
    className: 'text-xs',
    sorter: (a, b) => a.municipalTax - b.municipalTax,
    render: (text) => (
      <span>
        $
        {text
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
      </span>
    ),
  },
  {
    title: t('state_tax'),
    dataIndex: 'stateTax',
    key: 'stateTax',
    align: 'right',
    className: 'text-xs',
    sorter: (a, b) => a.stateTax - b.stateTax,
    render: (text) => (
      <span>
        $
        {text
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
      </span>
    ),
  },
  {
    title: t('tax_total'),
    dataIndex: 'taxTotal',
    key: 'taxTotal',
    align: 'right',
    className: 'text-xs',
    sorter: (a, b) => a.taxTotal - b.taxTotal,
    render: (text) => (
      <span>
        $
        {text
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
      </span>
    ),
  },
  {
    title: t('processor_fee'),
    dataIndex: 'processorFee',
    key: 'processorFee',
    align: 'right',
    className: 'text-xs',
    sorter: (a, b) => a.processorFee - b.processorFee,
    render: (text) => (
      <span>
        $
        {text
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
      </span>
    ),
  },

  {
    title: t('delivery'),
    dataIndex: 'deliveryFee',
    key: 'deliveryFee',
    align: 'right',
    className: 'text-xs',
    sorter: (a, b) => a.deliveryFee - b.deliveryFee,
    render: (text) => (
      <span>
        $
        {text
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
      </span>
    ),
  },
  {
    title: t('pickup'),
    dataIndex: 'pickupFee',
    key: 'pickupFee',
    align: 'right',
    className: 'text-xs',
    sorter: (a, b) => a.pickupFee - b.pickupFee,
    render: (text) => (
      <span>
        $
        {text
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
      </span>
    ),
  },
  {
    title: t('discount'),
    dataIndex: 'discountTotal',
    key: 'discountTotal',
    align: 'right',
    className: 'text-xs',
    sorter: (a, b) => a.discountTotal - b.discountTotal,
    render: (text) => (
      <span>
        $
        {text
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
      </span>
    ),
  },
  {
    title: t('return'),
    dataIndex: 'returnTotal',
    key: 'returnTotal',
    align: 'right',
    className: 'text-xs',
    sorter: (a, b) => a.returnTotal - b.returnTotal,
    render: (text) => (
      <span>
        $
        {text
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
      </span>
    ),
  },
  {
    title: 'ACH Fee',
    dataIndex: 'achFee',
    key: 'achFee',
    align: 'right',
    className: 'text-xs',
    sorter: (a, b) => a.achFee - b.achFee,
    render: (text) => (
      <span>
        $
        {text
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
      </span>
    ),
  },
  {
    title: t('commission'),
    dataIndex: 'salesCommision',
    key: 'salesCommision',
    align: 'right',
    className: 'text-xs',
    sorter: (a, b) => a.salesCommision - b.salesCommision,
    render: (text) => (
      <span>
        $
        {text
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
      </span>
    ),
  },
  {
    title: t('deposit_in_bank'),
    dataIndex: 'transferTotal',
    key: 'transferTotal',
    align: 'right',
    className: 'text-xs',
    sorter: (a, b) => a.transferTotal - b.transferTotal,
    render: (text) => (
      <span>
        $
        {text
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
      </span>
    ),
  }];
  const restrictedColumns = [{
    title: t('day'),
    dataIndex: 'date',
    key: 'date',
    align: 'center',
    className: 'text-xs',
    sorter: (a, b) => moment(a.datetime) - moment(b.datetime),
    render: (text) => <span>{text}</span>,
  },
  {
    title: t('count'),
    dataIndex: 'count',
    key: 'count',
    align: 'right',
    className: 'text-xs',
    sorter: (a, b) => a.count - b.count,
    render: (text) => <span>{text}</span>,
  },
  {
    title: t('total'),
    dataIndex: 'orderTotal',
    key: 'orderTotal',
    align: 'right',
    className: 'text-xs',
    sorter: (a, b) => a.orderTotal - b.orderTotal,
    render: (text) => (
      <span>
        $
        {text
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
      </span>
    ),
  }];

  const allExpandableColumns = [
    {
      title: t('order'),
      dataIndex: 'confirmationCode',
      key: 'code',
      align: 'center',
      className: 'text-xs',
      render: (text) => (
        <Typography.Text copyable>{text}</Typography.Text>
      ),
    },
    {
      title: t('order_total'),
      dataIndex: 'orderTotal',
      key: 'orderTotal',
      align: 'center',
      className: 'text-xs font-medium',
      sorter: (a, b) => a.orderTotal - b.orderTotal,
      render: (text) => (
        <span>
          $
          {text
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
        </span>
      ),
    },
    {
      title: t('deposit_in_bank'),
      key: 'transferTotal',
      align: 'center',
      className: 'text-xs font-medium',
      sorter: (a, b) => a.transferTotal - b.transferTotal,
      render: (text) => (
        <span>
          $
          {text.transferTotal
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
        </span>
      ),
    },
    {
      title: t('payment'),
      dataIndex: 'paymentTender',
      key: 'paymentTender',
      align: 'center',
      className: 'text-xs',
      sorter: (a, b) => a.dispatcher.localeCompare(b.dispatcher),
      render: (text) => text,
    },
    {
      title: t('type'),
      dataIndex: 'orderType',
      key: 'orderType',
      align: 'center',
      className: 'text-xs',
      sorter: (a, b) => a.orderType - b.orderType,
      render: (text) => t(mapOrderType(text)),
    },
    {
      title: t('date'),
      dataIndex: 'dispatchDateTime',
      key: 'dispatchDateTime',
      align: 'center',
      className: 'text-xs',
      sorter: (a, b) => moment(a.dispatchDateTime) - moment(b.dispatchDateTime),
      render: (text) => (
        <span>
          {moment(`${text}+0000`)
            .tz(timezone)
            .format('MMM DD, YYYY - h:mm:ss a')}
        </span>
      ),
    },
    {
      title: t('dispatcher'),
      dataIndex: 'dispatcher',
      key: 'dispatcher',
      align: 'right',
      className: 'text-xs',
      sorter: (a, b) => a.dispatcher.localeCompare(b.dispatcher),
      render: (text) => <span>{text}</span>,
    },
    {
      title: '',
      align: 'center',
      className: 'text-xs',
      render: (text, row) => (
        <Space>
          <Popover
            title={t('details')}
            placement="left"
            content={
              <div>
                <div className="text-xs">
                  {t('code')}:{' '}
                  <span className="font-bold">
                    {row.confirmationCode}
                  </span>
                </div>
                <div className="text-xs">
                  {t('submitted')}:{' '}
                  <span className="font-bold">
                    {moment(`${row.date}+0000`)
                      .tz(timezone)
                      .format('MMM DD, YYYY - h:mm a')}
                  </span>
                </div>
                <div className="text-xs">
                  {t('requested_for')}:{' '}
                  <span className="font-bold">
                    {moment(`${row.desiredDateTime}+0000`)
                      .tz(timezone)
                      .format('MMM DD, YYYY - h:mm a')}
                  </span>
                </div>
                <div className="text-xs">
                  {t('customer')}:{' '}
                  <span className="font-bold">
                    {row.customer}
                  </span>
                </div>
                <div className="text-xs">
                  {t('dispatched_by')}:{' '}
                  <span className="font-bold">
                    N/A
                  </span>
                </div>
                <div className="text-xs">
                  Auth Total: $
                  <span className="font-bold">
                    {row.authTotal
                      .toFixed(2)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  </span>
                </div>
                <Divider />
                <div className="text-xs">
                  Subtotal: $
                  <span className="font-bold">
                    {row.productsTotal
                      .toFixed(2)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  </span>
                </div>
                <div className="text-xs">
                  {t('tax_total')}: $
                  <span className="font-bold">
                    {row.taxTotal
                      .toFixed(2)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  </span>
                </div>
                <div className="text-xs">
                  {t('delivery')}: $
                  <span className="font-bold">
                    {row.deliveryTotal
                      .toFixed(2)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  </span>
                </div>
                <div className="text-xs">
                  {t('pickup')}: $
                  <span className="font-bold">
                    {row.processingFee
                      .toFixed(2)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  </span>
                </div>
                <div className="text-xs">
                  {t('total')}: $
                  <span className="font-bold">
                    {row.orderTotal
                      .toFixed(2)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  </span>
                </div>
                <br />
                <div className="text-xs">
                  {t('processor')}: $
                  <span className="font-bold">
                    {row.processorFee
                      .toFixed(2)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  </span>
                </div>
                <div className="text-xs">
                  {t('returns')}: $
                  <span className="font-bold">
                    {row.returnTotal
                      .toFixed(2)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  </span>
                </div>
                <div className="text-xs">
                  {t('discount')}: $
                  <span className="font-bold">
                    {row.discountAmount
                      .toFixed(2)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  </span>
                </div>{' '}
                <div className="text-xs">
                  Ach Fee: $
                  <span className="font-bold">
                    {row.achFee
                      .toFixed(2)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  </span>
                </div>{' '}
                <div className="text-xs">
                  {t('commission')}: $
                  <span className="font-bold">
                    {row.salesCommision
                      .toFixed(2)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  </span>
                </div>{' '}
                <Divider />
                <div
                  className={
                    row.paymentType === 1
                      ? 'text-xs'
                      : 'hidden'
                  }
                >
                  {t('deposit_in_bank')}: $
                  <span className="font-bold">
                    {(row.authTotal - row.processorFee)
                      .toFixed(2)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  </span>
                </div>
                <div
                  className={
                    row.paymentType === 0 ||
                    row.paymentType === 5
                      ? 'text-xs'
                      : 'hidden'
                  }
                >
                  {t('deposit_in_bank')}: $
                  <span className="font-bold">
                    {row.transferTotal
                      .toFixed(2)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  </span>
                </div>
              </div>
            }
          >
            <Button
              shape="circle"
              size="small"
              icon={<SearchOutlined />}
            />
          </Popover>
        </Space>
      ),
    },
  ];
  const restrictedExpandableColumns = [
    {
      title: t('order'),
      dataIndex: 'confirmationCode',
      key: 'code',
      align: 'center',
      className: 'text-xs',
      render: (text) => (
        <Typography.Text copyable>{text}</Typography.Text>
      ),
    },
    {
      title: t('order_total'),
      dataIndex: 'orderTotal',
      key: 'orderTotal',
      align: 'center',
      className: 'text-xs font-medium',
      sorter: (a, b) => a - b,
      render: (text) => (
        <span>
          $
          {text
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
        </span>
      ),
    },
    {
      title: t('payment'),
      dataIndex: 'paymentTender',
      key: 'paymentTender',
      align: 'center',
      className: 'text-xs',
      sorter: (a, b) => a - b,
      render: (text) => text,
    },
    {
      title: t('type'),
      dataIndex: 'orderType',
      key: 'orderType',
      align: 'center',
      className: 'text-xs',
      sorter: (a, b) => a - b,
      render: (text) => t(mapOrderType(text)),
    },
    {
      title: t('date'),
      dataIndex: 'dispatchDateTime',
      key: 'dispatchDateTime',
      align: 'center',
      className: 'text-xs',
      sorter: (a, b) => a - b,
      render: (text) => (
        <span>
          {moment(`${text}+0000`)
            .tz(timezone)
            .format('MMM DD, YYYY - h:mm:ss a')}
        </span>
      ),
    },
    {
      title: t('dispatcher'),
      dataIndex: 'dispatcher',
      key: 'dispatcher',
      align: 'right',
      className: 'text-xs',
      sorter: (a, b) => a - b,
      render: (text) => <span>{text}</span>,
    }
  ];

  const allListFields = {
    date: t('day'),
    count: t('count'),
    orderTotal: t('total'),
    // productsTotal: t('products'),
    municipalTax: t('municipal_tax'),
    stateTax: t('state_tax'),
    taxTotal: t('tax_total'),
    processorFee: t('processor_fee'),
    deliveryFee: t('delivery'),
    pickupFee: t('pickup'),
    discountTotal: t('discount'),
    returnTotal: t('return'),
    transferTotal: t('deposit_in_bank'),
  };
  const restrictedListFields = {
    date: t('day'),
    count: t('count'),
    orderTotal: t('total'),
  };

  const allTransactionFields = {
    date: t('day'),
    confirmationCode: t('order'),
    orderType: t('order_type'),
    customer: t('customer'),
    paymentType: t('payment_type'),
    // authTotal: t('auth_total'),
    orderTotal: t('order_total'),
    productsTotal: t('products_total'),
    municipalTax: t('municipal_tax'),
    stateTax: t('state_tax'),
    taxTotal: t('tax_total'),
    processorFee: t('processor_fee'),
    processingFee: t('pickup'),
    deliveryTotal: t('delivery'),
    discountAmount: t('discount'),
    returnTotal: t('return'),
    paymentTender: t('payment_tender'),
    // storeTotal: t('store_total'),
    achFee: 'ACH',
    transferTotal: t('deposited_in_bank')
  };
  const restrictedTransactionFields = {
    confirmationCode: t('confirmation_code'),
    orderTotal: t('order_total'),
    paymentType: t('payment_type'),
    paymentTender: t('payment_tender'),
    orderType: t('order_type'),
    date: t('day')
  };

  function mapData(data) {
    const map = _.map(data, (p, index) => ({
      key: index,
      count: p.orderCount,
      datetime: p.date,
      date: moment(p.date).format('dddd MMM Do, YYYY'),
      orderTotal: p.orderTotal,
      productsTotal: p.productsTotal,
      stateTax: p.stateTax,
      achFee: p.achFee,
      salesCommision: p.salesCommision,
      municipalTax: p.municipalTax,
      taxTotal: p.taxTotal,
      deliveryFee: p.deliveryFee,
      pickupFee: p.pickupFee,
      processorFee: p.processorFee,
      processingFee: p.processingFee,
      storeTotal: p.storeTotal,
      transferTotal: p.transferTotal,
      discountTotal: p.discountTotal,
      returnTotal: p.returnTotal,
      paymentType: p.paymentType,
      authTotal: p.authTotal,
      athMovilDeposit: p.authTotal - p.processorFee,
      dateFormatted: moment(p.date).format('YYYY-MM-DD'),
      referenceOrder: p.referenceOrder,
      orders: _.map(p.orders, (v) => ({
        key: v.id,
        ...v,
      })),
    }));

    const sort = map.slice(0).sort((a, b) =>
      a.dateFormatted.localeCompare(b.dateFormatted));
    return sort;
  }

  function getData() {
    setLoading(true);
    const data = {
      StartDate: dayjs(dateRange.start).add(-1 * envConfig.REACT_APP_TIMEZONE_OFFSET, 'hour').format('YYYY-MM-DDTHH:mm:ss.000'),
      EndDate: dayjs(dateRange.end).add(-1 * envConfig.REACT_APP_TIMEZONE_OFFSET, 'hour').format('YYYY-MM-DDTHH:mm:ss.000'),
      BusinessId: business?.id,
      PaymentTender: selectedPaymentMethod,
      IncludeOrderSummary: includeOrders
    };
    api
      .post(
        'analytics/reporting/sales/search',
        data
      )
      .then((response) => {
        setLoading(false);
        setReportData(mapData(response.data.data));
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  }

  function getPaymentTenders() {
    setLoadingPaymentTenders(true);
    api
      .get('analytics/reporting/sales/paymenttenders')
      .then((response) => {
        setLoadingPaymentTenders(false);
        const mappedTenders = _.map(response.data.data, (p, index) => ({
          type: p.toUpperCase(),
          value: p
        }));
        setPaymentTenders(mappedTenders);
      })
      .catch((error) => {
        console.error(error);
        setLoadingPaymentTenders(false);
      });
  }

  useMount(() => {
    getPaymentTenders();
    getData();
  });

  useEffect(() => {
    getData();
  }, [dateRange, business, selectedPaymentMethod, includeOrders]);

  return (
    <div>
      <PageHeader
        className="mb-4 px-0"
        title={t('sales')}
        tags={
          <Tag
            color="#2db7f5"
            icon={<CheckCircleOutlined />}
          >
            {business ? business.name : t('global')}
          </Tag>
        }
        extra={
          [
            <RangePicker
              format="YYYY-MM-DD"
              value={[
                dayjs(dateRange?.start),
                dayjs(dateRange?.end)
              ]}
              presets={dateRangePresets(t)}
              onChange={(date, dateString) => {
                setDateRange({
                  start: dateString[0],
                  end: dateString[1]
                });
              }}
            />,
            <div>
              <br className={width < 1024 ? null : 'hidden'} />
              <Select
                listHeight={500}
                loading={loadingPaymentTenders}
                disabled={loadingPaymentTenders}
                style={{ width: 150 }}
                onChange={(value) => setSelectedPaymentMethod(value)}
                defaultValue={selectedPaymentMethod}
              >
                <Option key="all" value={null}>All</Option>
                {_.map(paymentTenders, (p) => (
                  <Option
                    key={p.value}
                    value={p.value}
                    className="text-xs flex items-center"
                  >
                    {p.type}
                  </Option>
                ))}
              </Select>
            </div>,
            <div
              className="flex space-x-1 border p-1"
            >
              <br className={width < 1024 ? null : 'hidden'} />
              <p>{t('include_orders')}</p>
              <Switch
                disabled={loading}
                className="float-right"
                checked={includeOrders}
                onChange={(checked) => {
                  setIncludeOrders(checked);
                }}
              />
            </div>,
            <div className="space-x-2">
              <br className={width < 1024 ? null : 'hidden'} />
              <Button
                size="small"
                type="primary"
                icon={<CloudDownloadOutlined />}
                onClick={() => {
                  const data = _.map(reportData, (p) => ({
                    date: p.date,
                    count: p.count,
                    orderTotal: p.orderTotal,
                    // productsTotal: p.productsTotal,
                    municipalTax: p.municipalTax,
                    stateTax: p.stateTax,
                    taxTotal: p.taxTotal,
                    processorFee: p.processorFee,
                    deliveryFee: p.deliveryFee,
                    pickupFee: p.pickupFee,
                    discountTotal: p.discountTotal,
                    returnTotal: p.returnTotal,
                    transferTotal: p.transferTotal,
                  }));
                  saveAsCsv({
                    data,
                    fields: canViewFinance ? allListFields : restrictedListFields,
                    filename: `reportData_${dateRange.start}_${dateRange.end}`,
                  });
                }}
              >
                {t('export_list')}
              </Button>
              <Button
                size="small"
                type="primary"
                icon={<ReloadOutlined />}
                onClick={() => getData()}
              >
                {t('refresh')}
              </Button>
            </div>,
            includeOrders && (
              <Button
                type="primary"
                size="small"
                icon={<CloudDownloadOutlined />}
                onClick={() => {
                  let temp = [];
                  reportData.forEach((e) => {
                    temp = temp.concat(e.orders);
                  });
                  const data = _.map(temp, (p) => ({
                    date: moment(`${p.dispatchDateTime}+0000`)
                      .tz(timezone)
                      .format('MM/DD/YYYY h:mm a'),
                    confirmationCode: p.confirmationCode,
                    customer: p.customer,
                    orderType: mapOrderType(p.orderType),
                    paymentType: t(mapPaymentType(p.paymentType)),
                    orderTotal: p.orderTotal,
                    productsTotal: p.productsTotal,
                    municipalTax: p.municipalTax,
                    stateTax: p.stateTax,
                    taxTotal: p.taxTotal,
                    // authTotal: p.authTotal,
                    processorFee: p.processorFee,
                    processingFee: p.processingFee,
                    deliveryTotal: p.deliveryTotal,
                    discountAmount: p.discountAmount,
                    returnTotal: p.returnTotal,
                    // storeTotal: p.storeTotal,
                    achFee: p.achFee,
                    salesCommision: p.salesCommision,
                    transferTotal: p.transferTotal,
                  }));
                  saveAsCsv({
                    data,
                    fields: canViewFinance ?
                      allTransactionFields : restrictedTransactionFields,
                    filename: `orders_${dateRange.start}_${dateRange.end}`,
                  });
                }}
              >
                {t('export_all_orders')}
              </Button>
            )
          ]
        }
      />
      <Table
        size="small"
        loading={loading}
        bordered
        scroll={{ x: 1000 }}
        pagination={false}
        columns={canViewFinance ? allColumns : restrictedColumns}
        dataSource={reportData}
        expandable={!includeOrders ? null : {
          expandRowByClick: true,
          expandedRowRender: (record) => (
            <div className="p-4">
              <Table
                title={() => (
                  <div className="flex items-center justify-between">
                    <div>
                      <Badge
                        className="site-badge-count-109"
                        count={record.orders.length}
                        style={{ backgroundColor: '#52c41a' }}
                      />
                      <span className="font-light uppercase text-xs ml-2">
                        {t('orders')}
                      </span>
                    </div>
                    <Button
                      type="primary"
                      size="small"
                      icon={<CloudDownloadOutlined />}
                      onClick={() => {
                        const data = _.map(record.orders, (p) => ({
                          date: moment(`${p.dispatchDateTime}+0000`)
                            .tz(timezone)
                            .format('MM/DD/YYYY h:mm a'),
                          confirmationCode: p.confirmationCode,
                          customer: p.customer,
                          orderType: t(mapOrderType(p.orderType)),
                          paymentType: t(mapPaymentType(p.paymentType)),
                          orderTotal: p.orderTotal,
                          productsTotal: p.productsTotal,
                          municipalTax: p.municipalTax,
                          stateTax: p.stateTax,
                          taxTotal: p.taxTotal,
                          paymentTender: p.paymentTender,
                          // authTotal: p.authTotal,
                          processorFee: p.processorFee,
                          processingFee: p.processingFee,
                          deliveryTotal: p.deliveryTotal,
                          discountAmount: p.discountAmount,
                          returnTotal: p.returnTotal,
                          // storeTotal: p.storeTotal,
                          achFee: p.achFee,
                          salesCommision: p.salesCommision,
                          transferTotal: p.transferTotal,
                        }));
                        saveAsCsv({
                          data,
                          fields: canViewFinance ?
                            allTransactionFields : restrictedTransactionFields,
                          filename: `orders_${dateRange.start}_${dateRange.end}`,
                        });
                      }}
                    >
                      {t('export_orders')}
                    </Button>
                  </div>
                )}
                bordered
                pagination={false}
                columns={canViewFinance ? allExpandableColumns : restrictedExpandableColumns}
                dataSource={record.orders}
              />
            </div>
          ),
          rowExpandable: (record) => record.orders.length > 0,
        }}
      />
    </div>
  );
}

export default SalesFinance;
