import React, { useEffect, useState } from 'react';
import {
  useRecoilValue,
  useRecoilState,
  useRecoilValueLoadable
} from 'recoil';
import {
  Tag,
  Button,
  Table,
  Row,
  Col,
  Image,
  Popover
} from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import _ from 'lodash';
import { SyncOutlined, BuildOutlined } from '@ant-design/icons';
import {
  timezoneAtom,
  selectedInfoCardAtom,
  refreshInfoCardsAtom,
  profileAtom
} from '../../../atoms/Atoms';
import EditInfoCardDrawer from './shared-components/EditInfoCardDrawer';
import api from '../../../api/api';

function InfoCards() {
  const timezone = useRecoilValue(timezoneAtom);
  const [infoCards, setInfoCards] = useState([]);
  const [selectedInfoCard, setSelectedInfoCard] = useRecoilState(selectedInfoCardAtom);
  const [refreshInfoCards, setRefreshInfoCards] = useRecoilState(refreshInfoCardsAtom);
  const [loading, setLoading] = useState(false);
  let remaining = 0;
  const today = moment();
  const [planned, setPlanned] = useState(0);
  const [completed, setCompleted] = useState(0);
  const [live, setLive] = useState(0);
  const { t } = useTranslation();

  const userProfile = useRecoilValueLoadable(profileAtom);
  const isAdmin =
    userProfile.contents && userProfile?.contents?.roles?.includes('SuperAdmin');
  const isMarketing =
    isAdmin || (userProfile?.contents && userProfile?.contents?.roles?.includes('Marketing'));
  const history = useHistory();

  if (!isAdmin && !isMarketing) {
    history.push('/');
    return null;
  }

  function mapData(data) {
    const mapped = _.map(data, (p, index) => ({
      ...p,
      key: index,
      startDateTime: moment(p.startDateTime).tz(timezone),
      endDateTime: moment(p.endDateTime).tz(timezone),
      start: moment(`${p.startDateTime}+0000`)
        .tz(timezone)
        .format('MMMM Do YYYY, h:mm:ss a'),
      end: moment(`${p.endDateTime}+0000`)
        .tz(timezone)
        .format('MMMM Do YYYY, h:mm:ss a')
    }));
    return mapped;
  }

  const getInfoCards = function () {
    setLoading(true);
    api.get('marketing/infocards')
      .then((response) => {
        setLoading(false);
        let l = 0;
        let c = 0;
        let p = 0;

        if (response.data.success) {
          for (let i = 0; i < response.data.data.results.length; i++) {
            let status = 'Planned';
            let statusColor = '';

            const startDate = moment(
              `${response.data.data.results[i].startDateTime}+0000`
            );
            const endDate = moment(
              `${response.data.data.results[i].endDateTime}+0000`
            );
            const currentDate = moment();
            if (currentDate >= startDate && currentDate <= endDate) {
              l += 1;
              remaining = endDate.diff(today, t('days'));
              statusColor = '#52c41a';
              status = `Live ${remaining} days remaining`;
            } else if (currentDate > endDate) {
              c += 1;
              status = t('completed');
              statusColor = '#2db7f5';
            } else {
              p += 1;
            }
            response.data.data.results[i].status = status;
            response.data.data.results[i].statusColor = statusColor;
          }
          const map = mapData(response.data.data.results);
          setInfoCards(map);
          setLive(l);
          setCompleted(c);
          setPlanned(p);
        } else {
          console.error(response.data.error);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  useEffect(() => {
    setSelectedInfoCard(null);
    getInfoCards();
  }, []);

  useEffect(() => {
    if (refreshInfoCards) {
      setRefreshInfoCards(false);
      getInfoCards();
    }
  }, [refreshInfoCards]);

  return (
    <>
      <PageHeader
        title={t('info_cards')}
        extra={[
          <Button
            key="create"
            type="primary"
            icon={<BuildOutlined />}
            size="small"
            onClick={() => {
              setSelectedInfoCard({});
            }}
          >
            {t('create')}
          </Button>,
          <Button
            key="reload"
            type="primary"
            icon={<SyncOutlined />}
            size="small"
            loading={loading}
            onClick={() => {
              setRefreshInfoCards(true);
            }}
          >
            {t('reload')}
          </Button>
        ]}
      />
      <div className="ml-6 mb-4 text-sm">
        {t('info_card_description')}
      </div>
      <br />
      <div className="ml-6 mb-4 text-sm">
        Total: {infoCards.length} {' '} {t('completed')}: {completed} {' '} {t('live')}: {live} {' '} {t('planned')}: {planned}
      </div>
      <br />
      <Table
        size="small"
        bordered
        scroll={{ x: 1000 }}
        loading={loading}
        pagination={false}
        dataSource={infoCards}
        columns={[
          {
            title: t('name'),
            align: 'left',
            className: 'text-xs',
            render: (row) => (
              <Popover
                trigger="hover"
                title="Images"
                content={(
                  <Row>
                    <Col>
                      <Image
                        style={{ margin: 10 }}
                        src={row.imageUrl}
                        width={150}
                      />
                    </Col>
                    <Col>
                      <Image
                        style={{ margin: 10 }}
                        src={row.imageUrl2}
                        width={150}
                      />
                    </Col>
                  </Row>
                )}
              >
                <span>{row.name}</span>
              </Popover>
            ),
          },
          {
            title: t('start_date'),
            dataIndex: 'start',
            align: 'left',
            className: 'text-xs',
            render: (text) => (
              <span>{text}</span>
            ),
          },
          {
            title: t('end_date'),
            dataIndex: 'end',
            align: 'left',
            className: 'text-xs',
            render: (text) => (
              <span>{text}</span>
            ),
          },
          {
            title: t('status'),
            align: 'center',
            className: 'text-xs',
            render: (row) => <Tag color={row.statusColor}>{row.status}</Tag>
            ,
          },
          {
            title: t('actions'),
            align: 'center',
            className: 'text-xs',
            render: (row) => (
              <div>
                <Button
                  type="primary"
                  disabled={row.status === 'Completed' && !isAdmin}
                  size="small"
                  onClick={() => {
                    setSelectedInfoCard(row);
                  }}
                >
                  {t('edit')}
                </Button>
                {' '}
                <Button
                  disabled
                  type="primary"
                  size="small"
                >
                  {t('analytics')}
                </Button>
              </div>
            ),
          },
        ]}
      />
      {selectedInfoCard && (
        <EditInfoCardDrawer />
      )}
    </>
  );
}

export default InfoCards;
