import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';
import {
  useRecoilValue, useRecoilValueLoadable
} from 'recoil';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import {
  Drawer,
  InputNumber,
  Upload,
  Image,
  Modal,
  DatePicker,
  Descriptions,
  Input,
  Table,
  Switch,
  Avatar,
  Tooltip,
  Select,
  notification,
  Pagination,
  Button
} from 'antd';
import { Comment } from '@ant-design/compatible';
import { PageHeader } from '@ant-design/pro-layout';
import {
  InboxOutlined,
  FormOutlined,
  CloseCircleOutlined
} from '@ant-design/icons';
import moment from 'moment';
import Grocefy from '../../../assets/images/grocefyLogoAlone.png';
import {
  timezoneAtom,
  businessAtom,
  profileAtom
} from '../../../atoms/Atoms';
import api from '../../../api/api';
import {
  convertTaxRule,
  booleanConvert,
  thousand,
  convertPriceMethod
} from '../../utils/functions';

function StoreItemPrices() {
  const { RangePicker } = DatePicker;
  const { Dragger } = Upload;
  const { t } = useTranslation();
  const { Option } = Select;
  const placeholders = [
    t('item_price_search_general'),
    t('item_price_search_gtin'),
    t('item_price_search_item_number')
  ];
  const business = useRecoilValue(businessAtom);
  const timezone = useRecoilValue(timezoneAtom);
  const [loading, setLoading] = useState(false);
  const [showCreatItemRequest, setShowCreatItemRequest] = useState(false);
  const [lastSearch, setLastSearch] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const [searchType, setSearchType] = useState(0);
  const [size, setSize] = useState(20);
  const [products, setProducts] = useState([]);
  const [sections, setSections] = useState([]);
  const [loadingSections, setLoadingSections] = useState(false);
  const [aisles, setAisles] = useState([]);
  const [showAisleEdit, setShowAisleEdit] = useState(false);
  const [totalItemsInSearch, setTotalItemsInSearch] = useState(0);
  const [imageData, setImageData] = useState(null);
  const [thumbnailImageData, setThumbnailImageData] = useState(null);
  const [data, setData] = useState(null);
  const [foundResults, setFoundResults] = useState(null);
  const [showCreatBusinessItemRequest, setShowCreatBusinessItemRequest] = useState(false);
  const history = useHistory();
  const [searchItems, setSearchItems] = useState([]);
  const [searchText, setSearchText] = useState('');

  function showMessage(message) {
    notification.open({
      message: '',
      description: (
        <Comment
          author={<span>Grocefy</span>}
          avatar={<Avatar src={Grocefy} alt="grocefy" />}
          content={
            <p className="text-sm">
              {message}
            </p>
          }
          datetime={
            <Tooltip title={moment().format('YYYY-MM-DD HH:mm:ss')}>
              <span>{moment().fromNow()}</span>
            </Tooltip>
          }
        />
      ),
    });
  }

  function search(query) {
    setSearchText(query);
    setLoading(true);
    api
      .post('businesses/catalog/newproducts/search', { query })
      .then((response) => {
        if (response.data.success) {
          setLoading(false);
          setSearchItems(response.data.data.results);
        } else {
          showMessage(response.data.error);
        }
      })
      .catch((error) => {
        setLoading(false);
        showMessage(error);
      });
  }

  const imageDraggerProps = {
    name: 'file',
    accept: 'image/*',
    multiple: false,
    showUploadList: false,
    beforeUpload: (file) => {
      setImageData(file);
      return false;
    }
  };
  const imageThumbnailDraggerProps = {
    name: 'file',
    accept: 'image/*',
    multiple: false,
    showUploadList: false,
    beforeUpload: (file) => {
      setThumbnailImageData(file);
      return false;
    }
  };
  function normalizeDates(startDate, endDate) {
    let start = startDate;
    let end = endDate;
    if (start && start?.length > 0) {
      start = moment(start).utc();
      end = moment(end).utc();
    }
    if (start && start?.length < 17) {
      start = `${start.replace(' ', 'T')}:00.000`;
      end = `${end.replace(' ', 'T')}:00.000`;
    }
    return { start, end };
  }
  async function uploadImage(tempImageData) {
    const file = tempImageData;
    const boundary = Date.now();
    const formdata = new FormData();
    formdata.append('file', file);
    const response = await api
      .post('utility/itemMedia/uploadfile', formdata);
    if (response.data.success) {
      return response.data.data;
    }
    console.error(response.data.error);
    return null;
  }

  const searchProducts = function (query, page) {
    setLoading(true);
    const payload = {
      ItemNumber: searchType === 2 ? query : '',
      Gtin: searchType === 1 ? query : '',
      Query: searchType === 0 ? query : '',
      Page: page,
      Size: size,
      BusinessId: business?.id
    };
    api
      .post(
        'pos/prices/search',
        payload
      )
      .then((response) => {
        setLoading(false);
        if (response.data.success) {
          setTotalItemsInSearch(response.data.data.totalItems);
          setProducts(response.data.data.items);
        } else {
          showMessage(response.data.error);
        }
      })
      .catch((error) => {
        setLoading(false);
        showMessage(error);
      });
  };
  function createItemRequest() {
    setLoading(true);
    const dates =
      normalizeDates(data.businessItemAssociation.promoStartDate,
        data.businessItemAssociation.promoEndDate);
    api
      .post('businesses/catalog/newproducts/itemRequests/create', {
        ...data,
        businessItemAssociation: {
          ...data.businessItemAssociation,
          businessId: business.id,
          promoStartDate: dates.start,
          promoEndDate: dates.end
        }
      })
      .then((response) => {
        setLoading(false);
        if (response.data.success) {
          showMessage(t('created_successfully'));
          setShowCreatItemRequest(false);
          searchProducts(lastSearch, currentPage);
        } else {
          showMessage(response.data.error);
        }
      })
      .catch((error) => {
        setLoading(false);
        showMessage(error);
      });
  }
  function associateItem() {
    setLoading(true);
    const dates =
      normalizeDates(data.promoStartDate,
        data.promoEndDate);

    api
      .post(`businesses/catalog/newproducts/${!data.itemRequestId ? `item/associate/${data.itemId}` : `itemRequests/associate/${data.itemRequestId}`}`, {
        ...data,
        businessId: business.id,
        promoStartDate: dates.start,
        promoEndDate: dates.end
      })
      .then((response) => {
        setLoading(false);
        if (response.data.success) {
          showMessage(t('created_successfully'));
          setShowCreatItemRequest(false);
          searchProducts(lastSearch, currentPage);
        } else {
          showMessage(response.data.error);
        }
      })
      .catch((error) => {
        setLoading(false);
        showMessage(error);
      });
  }

  const showCreateBusinessItemRequest = function (itemPrice) {
    setShowCreatBusinessItemRequest(true);
    setData({
      itemId: itemPrice.itemId,
      itemRequestId: itemPrice.itemRequestId,
      businessId: business?.id,
      inStoreId: itemPrice.itemNumber,
      upc: itemPrice.upc,
      isVisible: itemPrice.isVisible,
      isAvailable: itemPrice.isAvailable,
      sectionId: itemPrice.sectionId,
      department: itemPrice.department,
      aisle: itemPrice.aisle,
      stateTaxPct: itemPrice.stateTaxPct,
      munTaxPct: itemPrice.munTaxPct,
      isAllNatural: itemPrice.isAllNatural,
      isLactoseFree: itemPrice.isLactoseFree,
      isSugarFree: itemPrice.isSugarFree,
      isKeto: itemPrice.isKeto,
      isOrganic: itemPrice.isOrganic,
      isVegan: itemPrice.isVegan,
      isVegetarian: itemPrice.isVegetarian,
      isGlutenFree: itemPrice.isGlutenFree,
      isGrassFed: itemPrice.isGrassFed,
      isAntioxidant: itemPrice.isAntioxidant,
      isNutFree: itemPrice.isNutFree,
      isNonGmo: itemPrice.isNonGmo,
      hasRandomWeight: itemPrice.hasRandomWeight,
      madeLocally: itemPrice.madeLocally,
      weightType: itemPrice.weightType,
      minQuantity: itemPrice.minQuantity,
      maxQuantity: itemPrice.maxQuantity,
      stepAmount: itemPrice.stepAmount,
      unitPriceFactor: itemPrice.unitPriceFactor,
      taxRule: itemPrice.taxRule,
      basePriceMethod: itemPrice.basePriceMethod,
      basePrice: itemPrice.basePrice,
      baseMultiple: itemPrice.baseMultiple,
      promoPrice: itemPrice.promoPrice,
      promoMultiple: itemPrice.promoMultiple,
      promoPriceMethod: itemPrice.promoPriceMethod,
      promoStartDate: itemPrice.promoStartDate,
      promoEndDate: itemPrice.promoEndDate,
      cost: itemPrice.cost,
      pack: itemPrice.pack,
    });
  };

  const showCreateItemRequest = function (itemPrice) {
    setShowCreatItemRequest(true);
    setData({
      suggestedDepartment: itemPrice.department,
      internalRank: itemPrice.salesRank,
      gtin: itemPrice.gtin,
      brand: itemPrice.brand,
      name: itemPrice.name,
      description: itemPrice.description,
      itemImage: '',
      itemThumbnail: '',
      searchTags: itemPrice.searchTags,
      isPackagingSensitive: itemPrice.isPackagingSensitive,
      isTemperatureSensitive: itemPrice.isTemperatureSensitive,
      isAlcoholicBeverage: itemPrice.isAlcoholicBeverage,
      isPerishable: itemPrice.isPerishable,
      isEbt: itemPrice.isEbt,
      idRequired: itemPrice.idRequired,
      internalId: '',
      businessItemAssociation: {
        businessId: business?.id,
        inStoreId: itemPrice.itemNumber,
        upc: itemPrice.upc,
        isVisible: itemPrice.isVisible,
        isAvailable: itemPrice.isAvailable,
        sectionId: itemPrice.sectionId,
        department: itemPrice.department,
        aisle: itemPrice.aisle,
        stateTaxPct: itemPrice.stateTaxPct,
        munTaxPct: itemPrice.munTaxPct,
        isAllNatural: itemPrice.isAllNatural,
        isLactoseFree: itemPrice.isLactoseFree,
        isSugarFree: itemPrice.isSugarFree,
        isKeto: itemPrice.isKeto,
        isOrganic: itemPrice.isOrganic,
        isVegan: itemPrice.isVegan,
        isVegetarian: itemPrice.isVegetarian,
        isGlutenFree: itemPrice.isGlutenFree,
        isGrassFed: itemPrice.isGrassFed,
        isAntioxidant: itemPrice.isAntioxidant,
        isNutFree: itemPrice.isNutFree,
        isNonGmo: itemPrice.isNonGmo,
        hasRandomWeight: itemPrice.hasRandomWeight,
        madeLocally: itemPrice.madeLocally,
        weightType: itemPrice.weightType,
        minQuantity: itemPrice.minQuantity,
        maxQuantity: itemPrice.maxQuantity,
        stepAmount: itemPrice.stepAmount,
        unitPriceFactor: itemPrice.unitPriceFactor,
        taxRule: itemPrice.taxRule,
        basePriceMethod: itemPrice.basePriceMethod,
        basePrice: itemPrice.basePrice,
        baseMultiple: itemPrice.baseMultiple,
        promoPrice: itemPrice.promoPrice,
        promoMultiple: itemPrice.promoMultiple,
        promoPriceMethod: itemPrice.promoPriceMethod,
        promoStartDate: itemPrice.promoStartDate,
        promoEndDate: itemPrice.promoEndDate,
        cost: itemPrice.cost,
        pack: itemPrice.pack,
      }
    });
  };

  async function getSections() {
    setLoadingSections(true);
    await api
      .get(`businesses/${business?.id}/menusections`)
      .then((response) => {
        setLoadingSections(false);
        if (response.data.success) {
          setSections(response.data.data.results);
        } else {
          showMessage(response.data.error);
        }
      })
      .catch((error) => {
        setLoadingSections(false);
        showMessage(error);
      });
  }

  async function getAisles() {
    await api.get(`businesses/${business?.id}/aisle`)
      .then((response) => {
        if (response.data.success) {
          setAisles(response.data.data.results);
        } else {
          showMessage(response.data.error);
        }
      })
      .catch((error) => {
        showMessage(error);
      });
  }

  function updatePrice(price) {
    setLoading(true);
    api.post(`pos/prices/apply/${price.upc}${business ? `/${business.id}` : ''}`)
      .then((response) => {
        setLoading(false);
        showMessage(t('updated_successfully'));
      })
      .catch((error) => {
        setLoading(false);
        showMessage(error);
      });
  }

  const connectPrice = function (itemPrice) {
    setShowAisleEdit(false);
    setLoading(true);
    setSearchText('');
    const tempPrice = { ...itemPrice };
    api
      .get(`businesses/catalog/newproducts/check/${itemPrice?.id}`)
      .then(async (response) => {
        setLoading(false);
        if (response.data.success && response.data.data.wasConnected) {
          showMessage(t('connected_successfully'));
          searchProducts(lastSearch, currentPage);
        } else if (response.data.success && !response.data.data.wasConnected) {
          await getSections();
          await getAisles();
          setFoundResults({
            itemPrice: tempPrice,
            items: response.data.data.items
          });
        } else {
          showMessage(response.data.error);
        }
      })
      .catch((error) => {
        setLoading(false);
        showMessage(error);
      });
  };

  const onSearch = async (value) => {
    setLastSearch(value);
    setCurrentPage(0);
    searchProducts(value, 0);
  };
  const onTableChange = (page, pageSize) => {
    if (page - 1 !== currentPage) {
      setCurrentPage(page - 1);
      searchProducts(lastSearch, page - 1);
    } else {
      setSize(pageSize);
    }
  };
  useEffect(() => {
    setCurrentPage(0);
    searchProducts(lastSearch, 0);
  }, [size, business]);
  useEffect(() => {
    setCurrentPage(0);
    searchProducts('', 0);
  }, []);

  useEffect(async () => {
    if (imageData) {
      const url = await uploadImage(imageData);
      if (url) {
        setImageData(null);
        setData({
          ...data,
          itemImage: url
        });
      }
    }
  }, [imageData]);

  useEffect(async () => {
    if (thumbnailImageData) {
      const url = await uploadImage(thumbnailImageData);
      if (url) {
        setThumbnailImageData(null);
        setData({
          ...data,
          itemThumbnail: url
        });
      }
    }
  }, [thumbnailImageData]);

  const storeNotSelectedColumns = [
    {
      title: t('item_number'),
      dataIndex: 'itemNumber',
      key: 'itemNumber',
      align: 'center',
      className: 'text-xs font-medium',
      render: (text) => (
        <span>{text}</span>
      ),
    },
    {
      title: 'Gtin',
      dataIndex: 'gtin',
      key: 'gtin',
      align: 'center',
      className: 'text-xs font-medium',
      render: (text) => (
        <span>{text}</span>
      ),
    },
    {
      title: t('item'),
      key: 'brand',
      align: 'center',
      className: 'text-xs font-medium',
      render: (row) => (
        <span>{`${row.brand ?? ''} ${row.name ?? ''} ${row.description ?? ''}`.trim()}</span>
      ),
    },
    {
      title: t('edited_on'),
      dataIndex: 'editedAt',
      key: 'editedAt',
      align: 'center',
      className: 'text-xs font-medium',
      render: (text) => (
        <span>
          {moment(`${text}+0000`)
            .tz(timezone)
            .format('MMMM Do YYYY, h:mm:ss a')}
        </span>
      ),
    },
    {
      title: t('created_on'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      align: 'center',
      className: 'text-xs font-medium',
      render: (text) => (
        <span>
          {moment(`${text}+0000`)
            .tz(timezone)
            .format('MMMM Do YYYY, h:mm:ss a')}
        </span>
      ),
    },
    {
      title: '',
      key: 'update_price',
      align: 'center',
      className: 'text-xs font-medium',
      render: (row) => (
        <Button
          size="small"
          type="primary"
          disabled={loading}
          onClick={() => {
            updatePrice(row);
          }}
        >
          {t('update_price')}
        </Button>
      )
    }
  ];
  const storeSelectedColumns = [...storeNotSelectedColumns, {
    title: t('is_connected'),
    key: 'is_connected',
    align: 'center',
    className: 'text-xs font-medium',
    render: (row) => (
      <Button
        size="small"
        type="primary"
        disabled={row.isConnected || loading}
        onClick={() => {
          connectPrice(row);
        }}
      >
        {t('connect')}
      </Button>
    ),
  }];

  return (
    <>
      <PageHeader
        className="mb-4 px-0"
        title={t('store_item_prices')}
      />
      <div>
        <div>
          <Input.Search
            addonBefore={
              <div>
                <Select
                  style={{
                    margin: '0 5px',
                    width: 150
                  }}
                  defaultValue={searchType}
                  disabled={loading}
                  onChange={(value) => {
                    setSearchType(value);
                  }}
                >
                  <Option
                    key="general"
                    value={0}
                  >
                    {t('general')}
                  </Option>
                  <Option
                    key="gtin"
                    value={1}
                  >
                    GTIN
                  </Option>
                  <Option
                    key="itemNumber"
                    value={2}
                  >
                    {t('item_number')}
                  </Option>
                </Select>
              </div>
            }
            key="itemSearch"
            placeholder={placeholders[searchType]}
            allowClear
            enterButton={t('search')}
            onSearch={onSearch}
          />
          <div className="mx-2">{t('results')}: {totalItemsInSearch}</div>
          <Table
            bordered
            scroll={{ x: 1000 }}
            pagination={false}
            loading={loading}
            dataSource={products}
            rowKey="id"
            columns={business ? storeSelectedColumns : storeNotSelectedColumns}
            expandable={{
              expandedRowRender: (record) => (
                <Descriptions bordered>
                  <Descriptions.Item label={t('details')}>
                    <b>{t('item_number')}: </b>
                    <span>{record.itemNumber}</span>
                    <br />
                    <b>{t('GTIN')}: </b>
                    <span>{record.gtin}</span>
                    <br />
                    <b>{t('brand')}: </b>
                    <span>{record.brand}</span>
                    <br />
                    <b>{t('name')}: </b>
                    <span>{record.name}</span>
                    <br />
                    <b>{t('description')}: </b>
                    <span>{record.description}</span>
                    <br />
                    <b>{t('internal_name')}: </b>
                    <span>{record.internalName}</span>
                    <br />
                    <b>{t('department')}: </b>
                    <span>{record.department}</span>
                  </Descriptions.Item>
                  <Descriptions.Item label={t('pricing')}>
                    <b>{t('tax_rule')}: </b>
                    <span>{t(convertTaxRule(record.taxRule))}</span>
                    <br />
                    <b>{t('base_price')}: </b>
                    <span>${t(thousand(record.basePrice))}</span>
                    <br />
                    <b>{t('base_multiple')}: </b>
                    <span>{t(thousand(record.baseMultiple))}</span>
                    <br />
                    <b>{t('price_method')}: </b>
                    <span>{t(convertPriceMethod(record.basePriceMethod))}</span>
                    <br />
                    <b>{t('cost')}: </b>
                    <span>${t(thousand(record.cost))}</span>
                    <br />
                    <b>{t('pack')}: </b>
                    <span>${t(thousand(record.pack))}</span>
                    <br />
                    <b>{t('state_tax')} %: </b>
                    <span>{t(thousand(record.stateTaxPct))}</span>
                    <br />
                    <b>{t('municipal_tax')} %: </b>
                    <span>{t(thousand(record.munTaxPct))}</span>
                  </Descriptions.Item>
                  <Descriptions.Item label="Promo">
                    <b>{t('promo_price')}: </b>
                    <span>${t(thousand(record.promoPrice))}</span>
                    <br />
                    <b>{t('promo_multiple')}: </b>
                    <span>{t(thousand(record.promoMultiple))}</span>
                    <br />
                    <b>{t('promo_price_method')}: </b>
                    <span>{t(convertPriceMethod(record.promoPriceMethod))}</span>
                    <br />
                    <b>{t('promo_start_date')}: </b>
                    <span>
                      {moment(`${record.promoStartDate}+0000`)
                        .tz(timezone)
                        .format('MMMM Do YYYY, h:mm:ss a')}
                    </span>
                    <br />
                    <b>{t('promo_end_date')}: </b>
                    <span>
                      {moment(`${record.promoEndDate}+0000`)
                        .tz(timezone)
                        .format('MMMM Do YYYY, h:mm:ss a')}
                    </span>
                  </Descriptions.Item>
                  <Descriptions.Item label={t('flags')}>
                    <b>EBT: </b>
                    <span>{t(booleanConvert(record.isEbt))}</span>
                    <br />
                    <b>{t('id_required')}: </b>
                    <span>{t(booleanConvert(record.idRequired))}</span>
                    <br />
                    <b>{t('alcoholic_beverage')}: </b>
                    <span>{t(booleanConvert(record.isAlcoholicBeverage))}</span>
                    <br />
                    <b>{t('is_deleted')}: </b>
                    <span>{booleanConvert(record.isDeleted)}</span>
                  </Descriptions.Item>
                </Descriptions>
              ),
              rowExpandable: (record) => record,
            }}
          />
          <Pagination
            pageSize={size}
            showSizeChanger
            defaultCurrent={0}
            current={currentPage + 1}
            total={totalItemsInSearch}
            onChange={onTableChange}
          />
        </div>
      </div>
      <Modal
        title={t('found_items')}
        open={foundResults?.items}
        onCancel={() => {
          setFoundResults(null);
        }}
        footer={[
          <Button
            key="close"
            type="primary"
            danger
            loading={loading}
            onClick={() => {
              setFoundResults(null);
            }}
          >
            {t('close')}
          </Button>,
          <Button
            key="create"
            type="primary"
            loading={loading}
            onClick={() => {
              showCreateItemRequest(foundResults?.itemPrice);
              setFoundResults(null);
            }}
          >
            {t('create_new')}
          </Button>
        ]}
      >
        <>
          {foundResults?.items?.length === 0 && (
            <div>
              <span>{t('no_potential_match')}</span>
              <Input.Search
                key="itemSearch"
                value={searchText}
                placeholder={t('item_search_placeholder')}
                allowClear
                loading={loading}
                enterButton={t('search')}
                size="small"
                onSearch={(value) => search(value)}
                onChange={(obj) => {
                  setSearchText(obj.target.value);
                }}
              />
              <Table
                dataSource={searchItems}
                loading={loading}
                size="small"
                bordered
                rowKey="id"
                columns={[
                  {
                    title: '',
                    dataIndex: 'itemImage',
                    render: (text) => <Image
                      width={50}
                      height={50}
                      alt={t('item_image')}
                      src={text}
                      className="cursor-pointer"
                    />
                  },
                  {
                    title: t('brand'),
                    dataIndex: 'brand',
                    align: 'left',
                    className: 'text-xs',
                    render: (text) => <span>{text}</span>,
                  },
                  {
                    title: t('name'),
                    align: 'left',
                    className: 'text-xs',
                    dataIndex: 'name',
                    render: (text) => <span>{text}</span>,
                  },
                  {
                    title: t('description'),
                    align: 'left',
                    className: 'text-xs',
                    dataIndex: 'description',
                    render: (text) => <span>{text}</span>,
                  },
                  {
                    title: '',
                    align: 'left',
                    className: 'text-xs',
                    render: (row) => (
                      <Button
                        size="small"
                        type="primary"
                        onClick={() => {
                          const temp = {
                            ...foundResults?.itemPrice,
                            itemId: row.isRequest ? null : row.id,
                            itemRequestId: row.isRequest ? row.itemRequestId : null
                          };
                          showCreateBusinessItemRequest(temp);
                        }}
                      >
                        {t('select')}
                      </Button>
                    ),
                  }
                ]}
              />
            </div>
          )}
          {foundResults?.items?.length > 0 && (
            <>
              <span>{t('found_potential_matches')}</span>
              <Table
                bordered
                small
                pagination={false}
                loading={loading}
                dataSource={foundResults?.items}
                columns={[
                  {
                    title: t('item'),
                    key: 'brand',
                    align: 'center',
                    className: 'text-xs font-medium',
                    render: (row) => (
                      <span>
                        {`${row.brand ?? ''} ${row.name ?? ''} ${row.description ?? ''}`.trim()}
                      </span>
                    ),
                  },
                  {
                    title: t('actions'),
                    key: 'action',
                    align: 'center',
                    className: 'text-xs font-medium',
                    render: (row) => (
                      <Button
                        size="small"
                        type="primary"
                        disabled={loading}
                        onClick={() => {
                          const temp = {
                            ...foundResults?.itemPrice,
                            itemId: row.itemId,
                            itemRequestId: row.itemRequestId
                          };
                          showCreateBusinessItemRequest(temp);
                        }}
                      >
                        {t('select')}
                      </Button>
                    ),
                  }
                ]}
              />
            </>
          )}
        </>
      </Modal>
      <Drawer
        title={t('create_business_item_requests')}
        placement="right"
        closable
        onClose={() => setShowCreatItemRequest(false)}
        open={showCreatItemRequest}
        width={600}
      >
        <div className="mb-2">
          <strong>GTIN</strong>
          <Input
            disabled={loading}
            value={data?.gtin}
            onChange={(obj) => {
              setData({
                ...data,
                gtin: obj.target.value
              });
            }}
          />
        </div>
        <div className="mb-2">
          <strong>{t('brand')}</strong>
          <Input
            disabled={loading}
            value={data?.brand}
            onChange={(obj) => {
              setData({
                ...data,
                brand: obj.target.value
              });
            }}
          />
        </div>
        <div className="mb-2">
          <strong>{t('name')}</strong>
          <Input
            disabled={loading}
            value={data?.name}
            onChange={(obj) => {
              setData({
                ...data,
                name: obj.target.value
              });
            }}
          />
        </div>
        <div className="mb-2">
          <strong>{t('description')}</strong>
          <Input
            disabled={loading}
            value={data?.description}
            onChange={(obj) => {
              setData({
                ...data,
                description: obj.target.value
              });
            }}
          />
        </div>
        <div className="mb-2">
          <strong>{t('item_image')}</strong>
          <Input
            disabled={loading}
            value={data?.itemImage}
            onChange={(obj) => {
              setData({
                ...data,
                itemImage: obj.target.value
              });
            }}
          />
          <div className="flex">
            <Dragger {...imageDraggerProps}>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p
                className="ant-upload-text"
                style={{ margin: 10 }}
              >
                {t('click_or_drag_content')}
              </p>
            </Dragger>
            {data?.itemImage && (
              <Image
                width={100}
                alt={t('item_image')}
                src={data?.itemImage}
                className="cursor-pointer"
              />
            )}
          </div>
        </div>
        <div className="mb-2">
          <strong>{t('thumbnail')}</strong>
          <Input
            disabled={loading}
            value={data?.itemThumbnail}
            onChange={(obj) => {
              setData({
                ...data,
                itemThumbnail: obj.target.value
              });
            }}
          />
          <div className="flex">
            <Dragger {...imageThumbnailDraggerProps}>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p
                className="ant-upload-text"
                style={{ margin: 10 }}
              >
                {t('click_or_drag_content')}
              </p>
            </Dragger>
            {data?.itemThumbnail && (
              <Image
                width={100}
                alt={t('thumbnail')}
                src={data?.itemThumbnail}
                className="cursor-pointer"
              />
            )}
          </div>
        </div>
        <div className="mb-2">
          <strong>{t('search_tags')}</strong>
          <Input
            disabled={loading}
            value={data?.searchTags}
            onChange={(obj) => {
              setData({
                ...data,
                searchTags: obj.target.value
              });
            }}
          />
        </div>
        <div className="mb-2">
          <strong>{t('internal_id')}</strong>
          <Input
            disabled={loading}
            value={data?.internalId}
            onChange={(obj) => {
              setData({
                ...data,
                internalId: obj.target.value
              });
            }}
          />
        </div>
        <div className="mb-2">
          <strong>{t('package_sensitive')}</strong>
          <Switch
            disabled={loading}
            className="float-right"
            checked={data?.isPackagingSensitive}
            onChange={(checked) => {
              setData({
                ...data,
                isPackagingSensitive: checked
              });
            }}
          />
        </div>
        <div className="mb-2">
          <strong>{t('temperature_sensitive')}</strong>
          <Switch
            disabled={loading}
            className="float-right"
            checked={data?.isTemperatureSensitive}
            onChange={(checked) => {
              setData({
                ...data,
                isTemperatureSensitive: checked
              });
            }}
          />
        </div>
        <div className="mb-2">
          <strong>{t('is_alcohol')}</strong>
          <Switch
            disabled={loading}
            className="float-right"
            checked={data?.isAlcoholicBeverage}
            onChange={(checked) => {
              setData({
                ...data,
                isAlcoholicBeverage: checked
              });
            }}
          />
        </div>
        <div className="mb-2">
          <strong>{t('perishable')}</strong>
          <Switch
            disabled={loading}
            className="float-right"
            checked={data?.isPerishable}
            onChange={(checked) => {
              setData({
                ...data,
                isPerishable: checked
              });
            }}
          />
        </div>
        <div className="mb-2">
          <strong>{t('is_ebt')}</strong>
          <Switch
            disabled={loading}
            className="float-right"
            checked={data?.isEbt}
            onChange={(checked) => {
              setData({
                ...data,
                isEbt: checked
              });
            }}
          />
        </div>
        <div className="mb-2">
          <strong>{t('is_id_required')}</strong>
          <Switch
            disabled={loading}
            className="float-right"
            checked={data?.idRequired}
            onChange={(checked) => {
              setData({
                ...data,
                idRequired: checked
              });
            }}
          />
        </div>
        <div className="mb-2">
          <strong>{t('in_store_id')}</strong>
          <Input
            disabled={loading}
            value={data?.businessItemAssociation?.inStoreId}
            onChange={(obj) => {
              setData({
                ...data,
                businessItemAssociation: {
                  ...data.businessItemAssociation,
                  inStoreId: obj.target.value
                }
              });
            }}
          />
        </div>
        <div className="mb-2">
          <strong>UPC</strong>
          <Input
            disabled={loading}
            value={data?.businessItemAssociation?.upc}
            onChange={(obj) => {
              setData({
                ...data,
                businessItemAssociation: {
                  ...data.businessItemAssociation,
                  upc: obj.target.value
                }
              });
            }}
          />
        </div>
        <div className="mb-2">
          <strong>{t('is_visible')}</strong>
          <Switch
            disabled={loading}
            className="float-right"
            checked={data?.businessItemAssociation?.isVisible}
            onChange={(checked) => {
              setData({
                ...data,
                businessItemAssociation: {
                  ...data.businessItemAssociation,
                  isVisible: checked
                }
              });
            }}
          />
        </div>
        <div className="mb-2">
          <strong>{t('is_available')}</strong>
          <Switch
            disabled={loading}
            className="float-right"
            checked={data?.businessItemAssociation?.isAvailable}
            onChange={(checked) => {
              setData({
                ...data,
                businessItemAssociation: {
                  ...data.businessItemAssociation,
                  isAvailable: checked
                }
              });
            }}
          />
        </div>
        <div className="mb-2">
          <strong>{t('category')}</strong>
          <br />
          <Select
            value={data?.businessItemAssociation?.sectionId}
            style={{ width: '100%' }}
            loading={loadingSections}
            disabled={loading}
            showSearch="true"
            optionFilterProp="children"
            filterOption={(input, option) => {
              if (option && option.children) {
                return option.children[0].toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                  option.children[2].toLowerCase().indexOf(input.toLowerCase()) >= 0;
              }
              return false;
            }}
            onChange={(value) => {
              setData({
                ...data,
                businessItemAssociation: {
                  ...data.businessItemAssociation,
                  sectionId: value
                }
              });
            }}
          >
            {sections && _.map(sections, (section) => (
              <Option
                key={section.id}
                value={section.id}
              >
                {section.name} @ {section.superSectionName}
              </Option>
            ))}
          </Select>
        </div>
        <div className="mb-2">
          <strong>{t('department')}</strong>
          <Input
            disabled={loading}
            value={data?.businessItemAssociation?.department}
            onChange={(obj) => {
              setData({
                ...data,
                businessItemAssociation: {
                  ...data.businessItemAssociation,
                  department: obj.target.value
                }
              });
            }}
          />
        </div>
        <div className="mb-2">
          <strong>{t('aisle')}</strong>
          {!showAisleEdit && (
            <>
              <Select
                value={data?.businessItemAssociation?.aisle}
                className="ml-2"
                style={{ width: '80%' }}
                disabled={loading}
                showSearch="true"
                optionFilterProp="children"
                filterOption={(input, option) => {
                  if (option && option.value) {
                    return option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                  }
                  return false;
                }}
                onChange={(value) => {
                  setData({
                    ...data,
                    businessItemAssociation: {
                      ...data.businessItemAssociation,
                      aisle: value
                    }
                  });
                }}
              >
                <Option
                  value=""
                  key="empty"
                />
                {aisles && _.map(aisles, (aisle, index) => (
                  <Option
                    value={aisle}
                    key={`aisle${index}${aisle}`}
                  >
                    {aisle}
                  </Option>
                ))}
              </Select>
              <FormOutlined
                className="ml-2"
                onClick={() => { setShowAisleEdit(true); }}
              />
            </>
          )}
          {showAisleEdit && (
            <>
              <Input
                className="ml-2"
                style={{ width: '80%' }}
                disabled={loading}
                value={data?.businessItemAssociation?.aisle}
                onChange={(obj) => {
                  setData({
                    ...data,
                    businessItemAssociation: {
                      ...data.businessItemAssociation,
                      aisle: obj.target.value
                    }
                  });
                }}
              />
              <CloseCircleOutlined
                className="ml-2"
                onClick={() => { setShowAisleEdit(false); }}
              />
            </>
          )}
        </div>
        <div className="mb-2">
          <strong>{t('state_tax')} %</strong>
          <br />
          <InputNumber
            disabled={loading}
            min={0}
            onChange={(obj) => {
              setData({
                ...data,
                businessItemAssociation: {
                  ...data.businessItemAssociation,
                  stateTaxPct: obj
                }
              });
            }}
            value={data?.businessItemAssociation?.stateTaxPct}
          />
        </div>
        <div className="mb-2">
          <strong>{t('municipal_tax')} %</strong>
          <br />
          <InputNumber
            disabled={loading}
            min={0}
            onChange={(obj) => {
              setData({
                ...data,
                businessItemAssociation: {
                  ...data.businessItemAssociation,
                  munTaxPct: obj
                }
              });
            }}
            value={data?.businessItemAssociation?.munTaxPct}
          />
        </div>
        <div className="mb-2">
          <strong>{t('natural')}</strong>
          <Switch
            disabled={loading}
            className="float-right"
            checked={data?.businessItemAssociation?.isAllNatural}
            onChange={(checked) => {
              setData({
                ...data,
                businessItemAssociation: {
                  ...data.businessItemAssociation,
                  isAllNatural: checked
                }
              });
            }}
          />
        </div>
        <div className="mb-2">
          <strong>{t('lactose_free')}</strong>
          <Switch
            disabled={loading}
            className="float-right"
            checked={data?.businessItemAssociation?.isLactoseFree}
            onChange={(checked) => {
              setData({
                ...data,
                businessItemAssociation: {
                  ...data.businessItemAssociation,
                  isLactoseFree: checked
                }
              });
            }}
          />
        </div>
        <div className="mb-2">
          <strong>{t('sugar_free')}</strong>
          <Switch
            disabled={loading}
            className="float-right"
            checked={data?.businessItemAssociation?.isSugarFree}
            onChange={(checked) => {
              setData({
                ...data,
                businessItemAssociation: {
                  ...data.businessItemAssociation,
                  isSugarFree: checked
                }
              });
            }}
          />
        </div>
        <div className="mb-2">
          <strong>{t('keto')}</strong>
          <Switch
            disabled={loading}
            className="float-right"
            checked={data?.businessItemAssociation?.isKeto}
            onChange={(checked) => {
              setData({
                ...data,
                businessItemAssociation: {
                  ...data.businessItemAssociation,
                  isKeto: checked
                }
              });
            }}
          />
        </div>
        <div className="mb-2">
          <strong>{t('organic')}</strong>
          <Switch
            disabled={loading}
            className="float-right"
            checked={data?.businessItemAssociation?.isOrganic}
            onChange={(checked) => {
              setData({
                ...data,
                businessItemAssociation: {
                  ...data.businessItemAssociation,
                  isOrganic: checked
                }
              });
            }}
          />
        </div>
        <div className="mb-2">
          <strong>{t('vegan')}</strong>
          <Switch
            disabled={loading}
            className="float-right"
            checked={data?.businessItemAssociation?.isVegan}
            onChange={(checked) => {
              setData({
                ...data,
                businessItemAssociation: {
                  ...data.businessItemAssociation,
                  isVegan: checked
                }
              });
            }}
          />
        </div>
        <div className="mb-2">
          <strong>{t('vegetarian')}</strong>
          <Switch
            disabled={loading}
            className="float-right"
            checked={data?.businessItemAssociation?.isVegetarian}
            onChange={(checked) => {
              setData({
                ...data,
                businessItemAssociation: {
                  ...data.businessItemAssociation,
                  isVegetarian: checked
                }
              });
            }}
          />
        </div>
        <div className="mb-2">
          <strong>{t('gluten_free')}</strong>
          <Switch
            disabled={loading}
            className="float-right"
            checked={data?.businessItemAssociation?.isGlutenFree}
            onChange={(checked) => {
              setData({
                ...data,
                businessItemAssociation: {
                  ...data.businessItemAssociation,
                  isGlutenFree: checked
                }
              });
            }}
          />
        </div>
        <div className="mb-2">
          <strong>{t('grass_fed')}</strong>
          <Switch
            disabled={loading}
            className="float-right"
            checked={data?.businessItemAssociation?.isGrassFed}
            onChange={(checked) => {
              setData({
                ...data,
                businessItemAssociation: {
                  ...data.businessItemAssociation,
                  isGrassFed: checked
                }
              });
            }}
          />
        </div>
        <div className="mb-2">
          <strong>{t('antioxidant')}</strong>
          <Switch
            disabled={loading}
            className="float-right"
            checked={data?.businessItemAssociation?.isAntioxidant}
            onChange={(checked) => {
              setData({
                ...data,
                businessItemAssociation: {
                  ...data.businessItemAssociation,
                  isAntioxidant: checked
                }
              });
            }}
          />
        </div>
        <div className="mb-2">
          <strong>{t('nut_free')}</strong>
          <Switch
            disabled={loading}
            className="float-right"
            checked={data?.businessItemAssociation?.isNutFree}
            onChange={(checked) => {
              setData({
                ...data,
                businessItemAssociation: {
                  ...data.businessItemAssociation,
                  isNutFree: checked
                }
              });
            }}
          />
        </div>
        <div className="mb-2">
          <strong>{t('non_gmo')}</strong>
          <Switch
            disabled={loading}
            className="float-right"
            checked={data?.businessItemAssociation?.isNonGmo}
            onChange={(checked) => {
              setData({
                ...data,
                businessItemAssociation: {
                  ...data.businessItemAssociation,
                  isNonGmo: checked
                }
              });
            }}
          />
        </div>
        <div className="mb-2">
          <strong>{t('random_weight')}</strong>
          <Switch
            disabled={loading}
            className="float-right"
            checked={data?.businessItemAssociation?.hasRandomWeight}
            onChange={(checked) => {
              setData({
                ...data,
                businessItemAssociation: {
                  ...data.businessItemAssociation,
                  hasRandomWeight: checked
                }
              });
            }}
          />
        </div>
        <div className="mb-2">
          <strong>{t('made_locally')}</strong>
          <Switch
            disabled={loading}
            className="float-right"
            checked={data?.businessItemAssociation?.madeLocally}
            onChange={(checked) => {
              setData({
                ...data,
                businessItemAssociation: {
                  ...data.businessItemAssociation,
                  madeLocally: checked
                }
              });
            }}
          />
        </div>
        <div className="mb-2">
          <strong>{t('weight_type')}</strong>
          <br />
          <Select
            value={data?.businessItemAssociation?.weightType}
            style={{ width: '100%' }}
            disabled={loading || !data?.businessItemAssociation?.hasRandomWeight}
            onChange={(value) => {
              setData({
                ...data,
                businessItemAssociation: {
                  ...data.businessItemAssociation,
                  weightType: value
                }
              });
            }}
          >
            <Option
              key="lb"
              value={0}
            >
              LB
            </Option>
            <Option
              key="unit"
              value={4}
            >
              {t('unit')}
            </Option>
          </Select>
        </div>
        <div className="mb-2">
          <strong>{t('minimum')}</strong>
          <br />
          <InputNumber
            disabled={loading}
            min={0.1}
            onChange={(obj) => {
              setData({
                ...data,
                businessItemAssociation: {
                  ...data.businessItemAssociation,
                  minQuantity: obj
                }
              });
            }}
            value={data?.businessItemAssociation?.minQuantity}
          />
        </div>
        <div className="mb-2">
          <strong>{t('maximum')}</strong>
          <br />
          <InputNumber
            disabled={loading}
            min={0.1}
            onChange={(obj) => {
              setData({
                ...data,
                businessItemAssociation: {
                  ...data.businessItemAssociation,
                  maxQuantity: obj
                }
              });
            }}
            value={data?.businessItemAssociation?.maxQuantity}
          />
        </div>
        <div className="mb-2">
          <strong>{t('step_amount')}</strong>
          <br />
          <InputNumber
            disabled={loading}
            min={0.1}
            onChange={(obj) => {
              setData({
                ...data,
                businessItemAssociation: {
                  ...data.businessItemAssociation,
                  stepAmount: obj
                }
              });
            }}
            value={data?.businessItemAssociation?.stepAmount}
          />
        </div>
        <div className="mb-2">
          <strong>{t('unit_per_package')}</strong>
          <br />
          <InputNumber
            disabled={loading || !data?.businessItemAssociation?.hasRandomWeight}
            min={0.1}
            onChange={(obj) => {
              setData({
                ...data,
                businessItemAssociation: {
                  ...data.businessItemAssociation,
                  unitPriceFactor: obj
                }
              });
            }}
            value={data?.businessItemAssociation?.unitPriceFactor}
          />
        </div>
        <div className="mb-2">
          <strong>{t('tax_rule')}</strong>
          <br />
          <Select
            value={data?.businessItemAssociation?.taxRule}
            style={{ width: '100%' }}
            disabled={loading}
            onChange={(value) => {
              setData({
                ...data,
                businessItemAssociation: {
                  ...data.businessItemAssociation,
                  taxRule: value
                }
              });
            }}
          >
            <Option
              key="taxnone"
              value={0}
            >
              {t('none')}
            </Option>
            <Option
              key="taxmun"
              value={1}
            >
              {t('municipal')}
            </Option>
            <Option
              key="taxstate"
              value={2}
            >
              {t('state')}
            </Option>
            <Option
              key="taxboth"
              value={3}
            >
              {t('both')}
            </Option>
          </Select>
        </div>
        <div className="mb-2">
          <strong>{t('base_unit_price_method')}</strong>
          <br />
          <Select
            value={data?.businessItemAssociation?.basePriceMethod}
            style={{ width: '100%' }}
            disabled={loading}
            onChange={(value) => {
              setData({
                ...data,
                businessItemAssociation: {
                  ...data.businessItemAssociation,
                  basePriceMethod: value
                }
              });
            }}
          >
            <Option
              key="baseunit"
              value={0}
            >
              {t('unit')}
            </Option>
            <Option
              key="basesplit"
              value={1}
            >
              {t('split')}
            </Option>
          </Select>
        </div>
        <div className="mb-2">
          <strong>{t('base_price')}</strong>
          <br />
          <InputNumber
            disabled={loading}
            min={0}
            onChange={(obj) => {
              setData({
                ...data,
                businessItemAssociation: {
                  ...data.businessItemAssociation,
                  basePrice: obj
                }
              });
            }}
            value={data?.businessItemAssociation?.basePrice}
          />
        </div>
        <div className="mb-2">
          <strong>{t('base_multiple')}</strong>
          <br />
          <InputNumber
            disabled={loading}
            min={1}
            onChange={(obj) => {
              setData({
                ...data,
                businessItemAssociation: {
                  ...data.businessItemAssociation,
                  baseMultiple: obj
                }
              });
            }}
            value={data?.businessItemAssociation?.baseMultiple}
          />
        </div>
        <div className="mb-2">
          <strong>{t('promo_price_method')}</strong>
          <br />
          <Select
            value={data?.businessItemAssociation?.promoPriceMethod}
            style={{ width: '100%' }}
            disabled={loading}
            onChange={(value) => {
              setData({
                ...data,
                businessItemAssociation: {
                  ...data.businessItemAssociation,
                  promoPriceMethod: value
                }
              });
            }}
          >
            <Option
              key="baseunit"
              value={0}
            >
              {t('unit')}
            </Option>
            <Option
              key="basesplit"
              value={1}
            >
              {t('split')}
            </Option>
          </Select>
        </div>
        <div className="mb-2">
          <strong>{t('promo_price')}</strong>
          <br />
          <InputNumber
            disabled={loading}
            min={0}
            onChange={(obj) => {
              setData({
                ...data,
                businessItemAssociation: {
                  ...data.businessItemAssociation,
                  promoPrice: obj
                }
              });
            }}
            value={data?.businessItemAssociation?.promoPrice}
          />
        </div>
        <div className="mb-2">
          <strong>{t('promo_multiple')}</strong>
          <br />
          <InputNumber
            disabled={loading}
            min={1}
            onChange={(obj) => {
              setData({
                ...data,
                businessItemAssociation: {
                  ...data.businessItemAssociation,
                  promoMultiple: obj
                }
              });
            }}
            value={data?.businessItemAssociation?.promoMultiple}
          />
        </div>
        <div className="mb-2">
          <strong>{t('promo_start_date')} - {t('end_date')}</strong>
          <RangePicker
            disabled={loading}
            showTime={{ format: 'HH:mm' }}
            format="YYYY-MM-DD HH:mm"
            value={
              data?.businessItemAssociation?.promoStartDate
                ? [dayjs(data?.businessItemAssociation?.promoStartDate, 'YYYY-MM-DD HH:mm'),
                  dayjs(data?.businessItemAssociation?.promoEndDate, 'YYYY-MM-DD HH:mm')
                ] : null
            }
            onChange={(date, dateString) => {
              const start = dateString[0];
              const end = dateString[1];
              setData({
                ...data,
                businessItemAssociation: {
                  ...data.businessItemAssociation,
                  promoStartDate: start,
                  promoEndDate: end
                }
              });
            }}
          />
        </div>
        <div className="mb-2">
          <strong>{t('cost')}</strong>
          <br />
          <InputNumber
            disabled={loading}
            min={0.00}
            onChange={(obj) => {
              setData({
                ...data,
                businessItemAssociation: {
                  ...data.businessItemAssociation,
                  cost: obj
                }
              });
            }}
            value={data?.businessItemAssociation?.cost}
          />
        </div>
        <div className="mb-2">
          <strong>{t('pack')}</strong>
          <br />
          <InputNumber
            disabled={loading}
            min={0.00}
            onChange={(obj) => {
              setData({
                ...data,
                businessItemAssociation: {
                  ...data.businessItemAssociation,
                  pack: obj
                }
              });
            }}
            value={data?.businessItemAssociation?.pack}
          />
        </div>
        <Button
          className="float-right my-2"
          size="small"
          loading={loading}
          type="primary"
          onClick={() => createItemRequest()}
        >
          {t('create')}
        </Button>
      </Drawer>
      <Drawer
        title={t('edit_business_item_requests')}
        placement="right"
        closable
        onClose={() => setShowCreatBusinessItemRequest(false)}
        open={showCreatBusinessItemRequest}
        width={600}
      >
        <div className="mb-2">
          <strong>{t('in_store_id')}</strong>
          <Input
            disabled={loading}
            value={data?.inStoreId}
            onChange={(obj) => {
              setData({
                ...data,
                inStoreId: obj.target.value
              });
            }}
          />
        </div>
        <div className="mb-2">
          <strong>UPC</strong>
          <Input
            disabled={loading}
            value={data?.upc}
            onChange={(obj) => {
              setData({
                ...data,
                upc: obj.target.value
              });
            }}
          />
        </div>
        <div className="mb-2">
          <strong>{t('is_visible')}</strong>
          <Switch
            disabled={loading}
            className="float-right"
            checked={data?.isVisible}
            onChange={(checked) => {
              setData({
                ...data,
                isVisible: checked
              });
            }}
          />
        </div>
        <div className="mb-2">
          <strong>{t('is_available')}</strong>
          <Switch
            disabled={loading}
            className="float-right"
            checked={data?.isAvailable}
            onChange={(checked) => {
              setData({
                ...data,
                isAvailable: checked
              });
            }}
          />
        </div>
        <div className="mb-2">
          <strong>{t('category')}</strong>
          <br />
          <Select
            value={data?.sectionId}
            style={{ width: '100%' }}
            loading={loadingSections}
            disabled={loading}
            showSearch="true"
            optionFilterProp="children"
            filterOption={(input, option) => {
              if (option && option.children) {
                return option.children[0].toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                  option.children[2].toLowerCase().indexOf(input.toLowerCase()) >= 0;
              }
              return false;
            }}
            onChange={(value) => {
              setData({
                ...data,
                sectionId: value
              });
            }}
          >
            {sections && _.map(sections, (section) => (
              <Option
                key={section.id}
                value={section.id}
              >
                {section.name} @ {section.superSectionName}
              </Option>
            ))}
          </Select>
        </div>
        <div className="mb-2">
          <strong>{t('department')}</strong>
          <Input
            disabled={loading}
            value={data?.department}
            onChange={(obj) => {
              setData({
                ...data,
                department: obj.target.value
              });
            }}
          />
        </div>
        <div className="mb-2">
          <strong>{t('aisle')}</strong>
          {!showAisleEdit && (
            <>
              <Select
                value={data?.aisle}
                className="ml-2"
                style={{ width: '80%' }}
                disabled={loading}
                showSearch="true"
                optionFilterProp="children"
                filterOption={(input, option) => {
                  if (option && option.value) {
                    return option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                  }
                  return false;
                }}
                onChange={(value) => {
                  setData({
                    ...data,
                    aisle: value
                  });
                }}
              >
                <Option
                  value=""
                  key="empty"
                />
                {aisles && _.map(aisles, (aisle, index) => (
                  <Option
                    value={aisle}
                    key={`aisle${index}${aisle}`}
                  >
                    {aisle}
                  </Option>
                ))}
              </Select>
              <FormOutlined
                className="ml-2"
                onClick={() => { setShowAisleEdit(true); }}
              />
            </>
          )}
          {showAisleEdit && (
            <>
              <Input
                style={{ width: '80%' }}
                className="ml-2"
                disabled={loading}
                value={data?.aisle}
                onChange={(obj) => {
                  setData({
                    ...data,
                    aisle: obj.target.value
                  });
                }}
              />
              <CloseCircleOutlined
                className="ml-2"
                onClick={() => { setShowAisleEdit(false); }}
              />
            </>
          )}
        </div>
        <div className="mb-2">
          <strong>{t('state_tax')} %</strong>
          <br />
          <InputNumber
            disabled={loading}
            min={0}
            onChange={(obj) => {
              setData({
                ...data,
                stateTaxPct: obj
              });
            }}
            value={data?.stateTaxPct}
          />
        </div>
        <div className="mb-2">
          <strong>{t('municipal_tax')} %</strong>
          <br />
          <InputNumber
            disabled={loading}
            min={0}
            onChange={(obj) => {
              setData({
                ...data,
                munTaxPct: obj
              });
            }}
            value={data?.munTaxPct}
          />
        </div>
        <div className="mb-2">
          <strong>{t('natural')}</strong>
          <Switch
            disabled={loading}
            className="float-right"
            checked={data?.isAllNatural}
            onChange={(checked) => {
              setData({
                ...data,
                isAllNatural: checked
              });
            }}
          />
        </div>
        <div className="mb-2">
          <strong>{t('lactose_free')}</strong>
          <Switch
            disabled={loading}
            className="float-right"
            checked={data?.isLactoseFree}
            onChange={(checked) => {
              setData({
                ...data,
                isLactoseFree: checked
              });
            }}
          />
        </div>
        <div className="mb-2">
          <strong>{t('sugar_free')}</strong>
          <Switch
            disabled={loading}
            className="float-right"
            checked={data?.isSugarFree}
            onChange={(checked) => {
              setData({
                ...data,
                isSugarFree: checked
              });
            }}
          />
        </div>
        <div className="mb-2">
          <strong>{t('keto')}</strong>
          <Switch
            disabled={loading}
            className="float-right"
            checked={data?.isKeto}
            onChange={(checked) => {
              setData({
                ...data,
                isKeto: checked
              });
            }}
          />
        </div>
        <div className="mb-2">
          <strong>{t('organic')}</strong>
          <Switch
            disabled={loading}
            className="float-right"
            checked={data?.isOrganic}
            onChange={(checked) => {
              setData({
                ...data,
                isOrganic: checked
              });
            }}
          />
        </div>
        <div className="mb-2">
          <strong>{t('vegan')}</strong>
          <Switch
            disabled={loading}
            className="float-right"
            checked={data?.isVegan}
            onChange={(checked) => {
              setData({
                ...data,
                isVegan: checked
              });
            }}
          />
        </div>
        <div className="mb-2">
          <strong>{t('vegetarian')}</strong>
          <Switch
            disabled={loading}
            className="float-right"
            checked={data?.isVegetarian}
            onChange={(checked) => {
              setData({
                ...data,
                isVegetarian: checked
              });
            }}
          />
        </div>
        <div className="mb-2">
          <strong>{t('gluten_free')}</strong>
          <Switch
            disabled={loading}
            className="float-right"
            checked={data?.isGlutenFree}
            onChange={(checked) => {
              setData({
                ...data,
                isGlutenFree: checked
              });
            }}
          />
        </div>
        <div className="mb-2">
          <strong>{t('grass_fed')}</strong>
          <Switch
            disabled={loading}
            className="float-right"
            checked={data?.isGrassFed}
            onChange={(checked) => {
              setData({
                ...data,
                isGrassFed: checked
              });
            }}
          />
        </div>
        <div className="mb-2">
          <strong>{t('antioxidant')}</strong>
          <Switch
            disabled={loading}
            className="float-right"
            checked={data?.isAntioxidant}
            onChange={(checked) => {
              setData({
                ...data,
                isAntioxidant: checked
              });
            }}
          />
        </div>
        <div className="mb-2">
          <strong>{t('nut_free')}</strong>
          <Switch
            disabled={loading}
            className="float-right"
            checked={data?.isNutFree}
            onChange={(checked) => {
              setData({
                ...data,
                isNutFree: checked
              });
            }}
          />
        </div>
        <div className="mb-2">
          <strong>{t('non_gmo')}</strong>
          <Switch
            disabled={loading}
            className="float-right"
            checked={data?.isNonGmo}
            onChange={(checked) => {
              setData({
                ...data,
                isNonGmo: checked
              });
            }}
          />
        </div>
        <div className="mb-2">
          <strong>{t('random_weight')}</strong>
          <Switch
            disabled={loading}
            className="float-right"
            checked={data?.hasRandomWeight}
            onChange={(checked) => {
              setData({
                ...data,
                hasRandomWeight: checked
              });
            }}
          />
        </div>
        <div className="mb-2">
          <strong>{t('made_locally')}</strong>
          <Switch
            disabled={loading}
            className="float-right"
            checked={data?.madeLocally}
            onChange={(checked) => {
              setData({
                ...data,
                madeLocally: checked
              });
            }}
          />
        </div>
        <div className="mb-2">
          <strong>{t('weight_type')}</strong>
          <br />
          <Select
            value={data?.weightType}
            style={{ width: '100%' }}
            disabled={loading || !data?.hasRandomWeight}
            onChange={(value) => {
              setData({
                ...data,
                weightType: value
              });
            }}
          >
            <Option
              key="lb"
              value={0}
            >
              LB
            </Option>
            <Option
              key="unit"
              value={4}
            >
              {t('unit')}
            </Option>
          </Select>
        </div>
        <div className="mb-2">
          <strong>{t('minimum')}</strong>
          <br />
          <InputNumber
            disabled={loading}
            min={0.1}
            onChange={(obj) => {
              setData({
                ...data,
                minQuantity: obj
              });
            }}
            value={data?.minQuantity}
          />
        </div>
        <div className="mb-2">
          <strong>{t('maximum')}</strong>
          <br />
          <InputNumber
            disabled={loading}
            min={0.1}
            onChange={(obj) => {
              setData({
                ...data,
                maxQuantity: obj
              });
            }}
            value={data?.maxQuantity}
          />
        </div>
        <div className="mb-2">
          <strong>{t('step_amount')}</strong>
          <br />
          <InputNumber
            disabled={loading}
            min={0.1}
            onChange={(obj) => {
              setData({
                ...data,
                stepAmount: obj
              });
            }}
            value={data?.stepAmount}
          />
        </div>
        <div className="mb-2">
          <strong>{t('unit_per_package')}</strong>
          <br />
          <InputNumber
            disabled={loading || !data?.hasRandomWeight}
            min={0.1}
            onChange={(obj) => {
              setData({
                ...data,
                unitPriceFactor: obj
              });
            }}
            value={data?.unitPriceFactor}
          />
        </div>
        <div className="mb-2">
          <strong>{t('tax_rule')}</strong>
          <br />
          <Select
            value={data?.taxRule}
            style={{ width: '100%' }}
            disabled={loading}
            onChange={(value) => {
              setData({
                ...data,
                taxRule: value
              });
            }}
          >
            <Option
              key="taxnone"
              value={0}
            >
              {t('none')}
            </Option>
            <Option
              key="taxmun"
              value={1}
            >
              {t('municipal')}
            </Option>
            <Option
              key="taxstate"
              value={2}
            >
              {t('state')}
            </Option>
            <Option
              key="taxboth"
              value={3}
            >
              {t('both')}
            </Option>
          </Select>
        </div>
        <div className="mb-2">
          <strong>{t('base_unit_price_method')}</strong>
          <br />
          <Select
            value={data?.basePriceMethod}
            style={{ width: '100%' }}
            disabled={loading}
            onChange={(value) => {
              setData({
                ...data,
                basePriceMethod: value
              });
            }}
          >
            <Option
              key="baseunit"
              value={0}
            >
              {t('unit')}
            </Option>
            <Option
              key="basesplit"
              value={1}
            >
              {t('split')}
            </Option>
          </Select>
        </div>
        <div className="mb-2">
          <strong>{t('base_price')}</strong>
          <br />
          <InputNumber
            disabled={loading}
            min={0}
            onChange={(obj) => {
              setData({
                ...data,
                basePrice: obj
              });
            }}
            value={data?.basePrice}
          />
        </div>
        <div className="mb-2">
          <strong>{t('base_multiple')}</strong>
          <br />
          <InputNumber
            disabled={loading}
            min={1}
            onChange={(obj) => {
              setData({
                ...data,
                baseMultiple: obj
              });
            }}
            value={data?.baseMultiple}
          />
        </div>
        <div className="mb-2">
          <strong>{t('promo_price_method')}</strong>
          <br />
          <Select
            value={data?.promoPriceMethod}
            style={{ width: '100%' }}
            disabled={loading}
            onChange={(value) => {
              setData({
                ...data,
                promoPriceMethod: value
              });
            }}
          >
            <Option
              key="baseunit"
              value={0}
            >
              {t('unit')}
            </Option>
            <Option
              key="basesplit"
              value={1}
            >
              {t('split')}
            </Option>
          </Select>
        </div>
        <div className="mb-2">
          <strong>{t('promo_price')}</strong>
          <br />
          <InputNumber
            disabled={loading}
            min={0}
            onChange={(obj) => {
              setData({
                ...data,
                promoPrice: obj
              });
            }}
            value={data?.promoPrice}
          />
        </div>
        <div className="mb-2">
          <strong>{t('promo_multiple')}</strong>
          <br />
          <InputNumber
            disabled={loading}
            min={1}
            onChange={(obj) => {
              setData({
                ...data,
                promoMultiple: obj
              });
            }}
            value={data?.promoMultiple}
          />
        </div>
        <div className="mb-2">
          <strong>{t('promo_start_date')} - {t('end_date')}</strong>
          <RangePicker
            disabled={loading}
            showTime={{ format: 'HH:mm' }}
            format="YYYY-MM-DD HH:mm"
            value={
              data?.promoStartDate
                ? [dayjs(data.promoStartDate, 'YYYY-MM-DD HH:mm'),
                  dayjs(data.promoEndDate, 'YYYY-MM-DD HH:mm')
                ] : null
            }
            onChange={(date, dateString) => {
              const start = dateString[0];
              const end = dateString[1];
              setData({
                ...data,
                promoStartDate: start,
                promoEndDate: end
              });
            }}
          />
        </div>
        <div className="mb-2">
          <strong>{t('cost')}</strong>
          <br />
          <InputNumber
            disabled={loading}
            min={0.00}
            onChange={(obj) => {
              setData({
                ...data,
                cost: obj
              });
            }}
            value={data?.cost}
          />
        </div>
        <div className="mb-2">
          <strong>{t('pack')}</strong>
          <br />
          <InputNumber
            disabled={loading}
            min={0.00}
            onChange={(obj) => {
              setData({
                ...data,
                pack: obj
              });
            }}
            value={data?.pack}
          />
        </div>
        <Button
          className="float-right my-2"
          size="small"
          loading={loading}
          type="primary"
          onClick={() => {
            associateItem();
          }}
        >
          {t('associate')}
        </Button>
      </Drawer>
    </>
  );
}
export default StoreItemPrices;
