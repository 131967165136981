import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import {
  useRecoilValue,
} from 'recoil';
import {
  Tag,
  Button,
  Table,
  Drawer,
  Switch,
  Input,
  Select,
  Menu,
  Dropdown,
  Collapse,
  Popconfirm,
  InputNumber,
  Tooltip,
  notification,
  Avatar
} from 'antd';
import { Comment } from '@ant-design/compatible';
import { PageHeader } from '@ant-design/pro-layout';
import { BuildOutlined, CheckCircleOutlined, CloseCircleOutlined, SyncOutlined } from '@ant-design/icons';
import moment from 'moment';
import {
  timezoneAtom,
  showRelatedItemPickAtom,
} from '../../../atoms/Atoms';
import { businessesSelector } from '../../../atoms/Selectors';
import api from '../../../api/api';
import { mapPosDiscountType, posDiscountTypes } from '../../utils/functions';
import Grocefy from '../../../assets/images/grocefyLogoAlone.png';

function PosDiscounts() {
  const { Option } = Select;
  const { Panel } = Collapse;
  const timezone = useRecoilValue(timezoneAtom);
  const [discounts, setDiscounts] = useState([]);
  const [searchItems, setSearchItems] = useState([]);
  const [searching, setSearching] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [excludedItems, setExcludedItems] = useState([]);
  const [selectedDiscount, setSelectedDiscount] = useState(null);
  const [loading, setLoading] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [loadingItems, setLoadingItems] = useState(false);
  const [editDiscount, setEditDiscount] = useState(false);
  const { t, i18n } = useTranslation();

  function showMessage(message) {
    notification.open({
      message: '',
      description: (
        <Comment
          author={<span>Grocefy</span>}
          avatar={<Avatar src={Grocefy} alt="grocefy" />}
          content={
            <p className="text-sm">
              {message}
            </p>
          }
          datetime={
            <Tooltip title={moment().format('YYYY-MM-DD HH:mm:ss')}>
              <span>{moment().fromNow()}</span>
            </Tooltip>
          }
        />
      ),
    });
  }

  function getDiscounts() {
    setLoading(true);
    api.get('pos/discounts')
      .then((response) => {
        setLoading(false);
        if (response.data.success) {
          setDiscounts(response.data.data);
        } else {
          showMessage(response.data.error);
        }
      })
      .catch((error) => {
        setLoading(false);
        showMessage(error.message);
      });
  }

  function getExcludedItems() {
    setLoadingItems(true);
    api.get(`pos/discounts/items/${selectedDiscount?.id}`)
      .then((response) => {
        setLoadingItems(false);
        if (response.data.success) {
          setExcludedItems(response.data.data);
        } else {
          showMessage(response.data.error);
        }
      })
      .catch((error) => {
        setLoadingItems(false);
        showMessage(error.message);
      });
  }

  function deleteDiscount() {
    setUpdating(true);
    api.delete(`pos/discounts/${selectedDiscount?.id}`)
      .then((response) => {
        setUpdating(false);
        if (response.data.success) {
          setSelectedDiscount(null);
          setEditDiscount(false);
          getDiscounts();
          if (response.data.message) {
            showMessage(response.data.message);
          }
        } else {
          showMessage(response.data.error);
        }
      })
      .catch((error) => {
        setUpdating(false);
        showMessage(error.message);
      });
  }

  function update() {
    setUpdating(true);
    api.post('pos/discounts', selectedDiscount)
      .then((response) => {
        setUpdating(false);
        if (response.data.success) {
          getDiscounts();
          if (!selectedDiscount?.id) {
            setEditDiscount(false);
            setSelectedDiscount(null);
          }
          if (response.data.message) {
            showMessage(response.data.message);
          }
        } else {
          showMessage(response.data.error);
        }
      })
      .catch((error) => {
        setUpdating(false);
        showMessage(error.message);
      });
  }

  function addItem(item) {
    setLoadingItems(true);
    api.post('pos/discounts/items', {
      PosDiscountId: selectedDiscount?.id,
      ItemId: item.id,
      IsDelete: false
    })
      .then((response) => {
        if (response.data.success) {
          getExcludedItems();
          if (response.data.message) {
            showMessage(response.data.message);
          }
        } else {
          setLoadingItems(false);
          showMessage(response.data.error);
        }
      })
      .catch((error) => {
        setLoadingItems(false);
        showMessage(error.message);
      });
  }

  function removeItem(itemId) {
    setLoadingItems(true);
    api.post('pos/discounts/items', {
      PosDiscountId: selectedDiscount?.id,
      ItemId: itemId,
      IsDelete: true
    })
      .then((response) => {
        if (response.data.success) {
          getExcludedItems();
          if (response.data.message) {
            showMessage(response.data.message);
          }
        } else {
          setLoadingItems(false);
          showMessage(response.data.error);
        }
      })
      .catch((error) => {
        setLoadingItems(false);
        showMessage(error.message);
      });
  }

  function search() {
    setSearching(true);
    const payload = {
      Query: searchText,
      Page: 0,
      Size: 50,
    };
    api
      .post(
        'businesses/catalog/search',
        payload
      )
      .then((response) => {
        if (response.data.success) {
          setSearching(false);
          setSearchItems(response.data.data.items);
        } else {
          showMessage(response.data.error);
        }
      })
      .catch((error) => {
        setSearching(false);
        showMessage(error.message);
      });
  }

  useEffect(() => {
    setSelectedDiscount(null);
    getDiscounts();
  }, []);

  useEffect(() => {
    setExcludedItems([]);
    setSearchItems([]);
    setSearchText('');
    if (selectedDiscount?.id) {
      getExcludedItems();
    }
  }, [selectedDiscount]);

  return (
    <>
      <PageHeader
        title={t('pos_discounts')}
        extra={[
          <div>
            <Button
              type="primary"
              key="create"
              icon={<BuildOutlined />}
              size="small"
              onClick={() => {
                setSelectedDiscount({
                  discountType: 0,
                  discountValue: 0,
                  canBeAppliedMultipleTimes: false,
                  isActive: false,
                  nameEn: '',
                  nameEs: ''
                });
                setEditDiscount(true);
              }}
            >
              {t('create')}
            </Button>
            {' '}
            <Button
              type="primary"
              key="reload"
              icon={<SyncOutlined />}
              size="small"
              loading={loading}
              onClick={() => getDiscounts()}
            >
              {t('reload')}
            </Button>
          </div>
        ]}
      />
      <Table
        size="small"
        bordered
        loading={loading}
        pagination={false}
        dataSource={discounts}
        columns={[
          {
            title: t('name'),
            align: 'center',
            className: 'text-xs',
            render: (row) => (
              <span>{i18n.language === 'en' ? row.nameEn : row.nameEs}</span>
            ),
          },
          {
            title: t('active'),
            align: 'center',
            className: 'text-xs',
            render: (row) => {
              if (row.isActive) {
                return (
                  <Tag
                    icon={<CheckCircleOutlined />}
                    color="#87d068"
                    size="small"
                    className="mr-0"
                  >
                    {t('active')}
                  </Tag>
                );
              }
              return (
                <Tag
                  icon={<CloseCircleOutlined />}
                  color="#ff0400"
                  size="small"
                  className="mr-0"
                >
                  {t('not_active')}
                </Tag>
              );
            }
          },
          {
            title: t('discount_type'),
            align: 'center',
            className: 'text-xs',
            render: (row) => (
              <span>{t(mapPosDiscountType(row.discountType, row.discountValue).name)}</span>
            ),
          },
          {
            title: t('value'),
            align: 'center',
            className: 'text-xs',
            render: (row) => (
              <span>{mapPosDiscountType(row.discountType, row.discountValue).value}</span>
            ),
          },
          {
            title: t('can_be_applied_more_than_once'),
            align: 'center',
            className: 'text-xs',
            render: (row) => (
              <span>{row.canBeAppliedMultipleTimes ? t('yes') : t('no')}</span>
            ),
          },
          {
            title: '',
            align: 'center',
            className: 'text-xs',
            render: (row) => (
              <Button
                size="small"
                type="primary"
                onClick={() => {
                  setSelectedDiscount(row);
                  setEditDiscount(true);
                }}
              >
                {t('edit')}
              </Button>
            ),
          }
        ]}
      />
      <Drawer
        title={selectedDiscount?.id ? t('edit_discount') : t('create_discount')}
        placement="right"
        closable={!updating}
        onClose={() => setEditDiscount(false)}
        open={editDiscount}
        width={450}
      >
        <div className="space-y-4">
          <div hidden={!selectedDiscount?.id}>
            <div
              className="float-right"
            >
              <Button
                type="primary"
                danger
                loading={loading}
                onClick={() => { deleteDiscount(); }}
              >
                {t('delete')}
              </Button>
            </div>
            <br />
            <br />
          </div>
          <div>
            <strong>{t('is_active')}</strong>
            <Switch
              disabled={loading}
              style={{ float: 'right' }}
              checked={selectedDiscount?.isActive}
              onChange={(checked) => {
                setSelectedDiscount({
                  ...selectedDiscount,
                  isActive: checked
                });
              }}
            />
          </div>
          <div>
            <strong>{t('name')} EN</strong>
            <Input
              disabled={loading}
              className="w-full"
              value={selectedDiscount?.nameEn}
              onChange={(obj) => {
                setSelectedDiscount({
                  ...selectedDiscount,
                  nameEn: obj.target.value
                });
              }}
            />
          </div>
          <div>
            <strong>{t('name')} ES</strong>
            <Input
              disabled={loading}
              className="w-full"
              value={selectedDiscount?.nameEs}
              onChange={(obj) => {
                setSelectedDiscount({
                  ...selectedDiscount,
                  nameEs: obj.target.value
                });
              }}
            />
          </div>
          <div>
            <strong>{t('can_be_applied_more_than_once')}</strong>
            <Switch
              disabled={loading}
              style={{ float: 'right' }}
              checked={selectedDiscount?.CanBeAppliedMultipleTimes}
              onChange={(checked) => {
                setSelectedDiscount({
                  ...selectedDiscount,
                  CanBeAppliedMultipleTimes: checked
                });
              }}
            />
          </div>
          <div>
            <strong>{t('discount_type')}</strong>
            <Select
              className="w-full"
              onChange={(value) => setSelectedDiscount({
                ...selectedDiscount,
                discountType: value
              })}
              value={selectedDiscount?.discountType}
            >
              {_.map(posDiscountTypes, (l) => (
                <Option
                  key={l.type}
                  value={l.type}
                  className="text-xs flex items-center"
                >
                  {t(l.name)}
                </Option>
              ))}
            </Select>
          </div>
          {selectedDiscount?.discountType === 0 && (
            <div>
              <strong>{t('price')}</strong>
              <InputNumber
                disabled={loading}
                className="w-full"
                min={0}
                step="0.01"
                formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                onChange={(obj) => {
                  setSelectedDiscount({
                    ...selectedDiscount,
                    discountValue: obj
                  });
                }}
                value={selectedDiscount?.discountValue}
              />
            </div>
          )}
          {selectedDiscount?.discountType === 1 && (
            <div>
              <strong>{t('value')}</strong>
              <InputNumber
                disabled={loading}
                className="w-full"
                min={0}
                step="0.01"
                formatter={(value) => `${value}%`}
                onChange={(obj) => {
                  // console.log('value', obj / 100.0);
                  setSelectedDiscount({
                    ...selectedDiscount,
                    discountValue: obj / 100.0
                  });
                }}
                value={selectedDiscount?.discountValue * 100.0}
              />
            </div>
          )}
          {selectedDiscount?.id && (
            <Collapse>
              <Panel header={t('excluded_items')} key="itemspanel">
                <div>
                  <Dropdown
                    trigger="click"
                    placement="bottom"
                    overlay={
                      <Menu>
                        {_.map(searchItems, (u) => (
                          <Popconfirm
                            title={t('add_to_excluded_items_prompt')}
                            okText={t('yes')}
                            cancelText={t('no')}
                            onConfirm={() => {
                              addItem(u);
                              setSearchText('');
                              setSearchItems([]);
                            }}
                            onCancel={() => {
                              setSearchText('');
                              setSearchItems([]);
                            }}
                          >
                            <Menu.Item
                              key={u.id}
                            >
                              <div className="space-x-2">
                                <Avatar src={u.itemImage} />
                                <span>{u.gtin}: {u.brand} {u.name} {u.description}</span>
                              </div>
                            </Menu.Item>
                          </Popconfirm>
                        ))}
                      </Menu>
                    }
                  >
                    <Input.Search
                      className="w-full"
                      allowClear
                      disabled={loading}
                      value={searchText}
                      onChange={(obj) => {
                        setSearchText(obj.target.value);
                      }}
                      onSearch={() => search()}
                      enterButton={t('search')}
                    />
                  </Dropdown>
                  <Table
                    size="small"
                    bordered
                    loading={loadingItems}
                    pagination={false}
                    dataSource={excludedItems}
                    columns={[
                      {
                        title: t('item'),
                        align: 'center',
                        className: 'text-xs',
                        render: (u) => (
                          <div className="space-x-2">
                            <Avatar src={u.item.itemImage} />
                            <span>{u.item.gtin}<br />{u.item.brand} {u.item.name} {u.item.description}</span>
                          </div>
                        ),
                      },
                      {
                        title: '',
                        align: 'center',
                        className: 'text-xs',
                        render: (row) => (
                          <Button
                            size="small"
                            type="primary"
                            danger
                            onClick={() => {
                              removeItem(row.item.id);
                            }}
                          >
                            {t('remove')}
                          </Button>
                        ),
                      }
                    ]}
                  />
                </div>
              </Panel>
            </Collapse>
          )}
          <div
            className="float-right"
          >
            <Button
              type="primary"
              loading={loading}
              onClick={() => { update(); }}
            >
              {selectedDiscount?.id ? t('update') : t('create')}
            </Button>
          </div>
        </div>
      </Drawer>
    </>
  );
}

export default PosDiscounts;
