import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Descriptions, Divider, Input, Tag, Switch, Button, Typography, Popconfirm } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useMount } from 'react-use';
import { CheckCircleOutlined, SyncOutlined } from '@ant-design/icons';
import { businessAtom, openDrawerAtom, searchTypeAtom, openAddItemToCustomDeptAtom, reloadCustomDeptAtom } from '../../../atoms/Atoms';
import CustomDepartmentItems from '../../shared-components/drawers/CustomDepartmentItems';
import AddItemToCustomDept from '../../shared-components/modals/AddItemsToCustomDept';
import api from '../../../api/api';

function CustomDepartments() {
  const business = useRecoilValue(businessAtom);
  const [loading, setLoading] = useState(false);
  const [storeData, setStoreData] = useState(null);
  const [searchType, setSearchType] = useRecoilState(searchTypeAtom);
  const [openDrawer, setOpenDrawer] = useRecoilState(openDrawerAtom);
  const [itemCount, setItemCount] = useState(null);
  const { t } = useTranslation();
  const [openAddItem, setOpenAddItem] = useRecoilState(
    openAddItemToCustomDeptAtom
  );
  const [reload, setReload] = useRecoilState(reloadCustomDeptAtom);

  const getCounts = function () {
    setLoading(true);
    api
      .get(`businesses/customdepartment/count/${business.id}`)
      .then((response) => {
        setItemCount(response.data.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  };

  useMount(() => {
    if (business) {
      getCounts();
    }
  });

  const getInfo = function () {
    setLoading(true);
    api
      .get(`businesses/v2/${business.id}`)
      .then((response) => {
        setLoading(false);
        setStoreData(response.data.data);
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  const updateInfo = function () {
    setLoading(true);
    const dataSended = {
      CustomDepartmentName01: storeData.locations[0].customDepartment01Name,
      CustomDepartmentName02: storeData.locations[0].customDepartment02Name,
      CustomDepartmentName03: storeData.locations[0].customDepartment03Name,
      CustomDepartmentName04: storeData.locations[0].customDepartment04Name,
      CustomDepartmentName05: storeData.locations[0].customDepartment05Name,
      CustomDepartmentName06: storeData.locations[0].customDepartment06Name,
      CustomDepartment01: storeData.locations[0].showCustomDepartment01,
      CustomDepartment02: storeData.locations[0].showCustomDepartment02,
      CustomDepartment03: storeData.locations[0].showCustomDepartment03,
      CustomDepartment04: storeData.locations[0].showCustomDepartment04,
      CustomDepartment05: storeData.locations[0].showCustomDepartment05,
      CustomDepartment06: storeData.locations[0].showCustomDepartment06,
    };
    api
      .post(`businesses/customdepartment/update/${business.id}`, dataSended)
      .then((response) => {
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  useEffect(() => {
    if (business) {
      getInfo();
      getCounts();
    }
  }, [business]);

  useEffect(() => {
    if (business && reload) {
      setReload(false);
      getInfo();
      getCounts();
    }
  }, [reload]);

  return (
    <>
      <PageHeader
        title={t('custom_departments')}
        tags={
          <Tag
            color="#2db7f5"
            icon={business ? <CheckCircleOutlined /> : <SyncOutlined spin />}
          >
            {business ? business.name : `${t('loading')}...`}
          </Tag>
        }
      />
      <Divider />
      <Typography.Paragraph>
        {t('custom_department_description')}
      </Typography.Paragraph>
      <br />
      <div>
        <Button
          type="primary"
          loading={loading}
          style={{
            float: 'right',
            margin: '10px',
          }}
          onClick={() => setOpenAddItem(true)}
        >
          Add Items
        </Button>
        <Button
          type="primary"
          loading={loading}
          style={{
            float: 'right',
            margin: '10px'
          }}
          onClick={() => updateInfo()}
        >
          {t('update')}
        </Button>
      </div>
      <br />
      <br />
      <Descriptions bordered>
        <Descriptions.Item label="Custom Department 01">
          <Input
            disabled={loading}
            value={storeData?.locations[0]?.customDepartment01Name}
            onChange={(obj) => {
              const temp = JSON.parse(JSON.stringify(storeData));
              temp.locations[0].customDepartment01Name = obj.target.value;
              setStoreData(temp);
            }}
          />
        </Descriptions.Item>
        <Descriptions.Item label="Visible">
          <Switch
            size="small"
            loading={loading}
            checked={storeData?.locations[0]?.showCustomDepartment01}
            onChange={(obj) => {
              const temp = JSON.parse(JSON.stringify(storeData));
              temp.locations[0].showCustomDepartment01 = obj;
              setStoreData(temp);
            }}
          />
        </Descriptions.Item>
        <Descriptions.Item label={t('actions')}>
          <Popconfirm
            placement="topRight"
            title={t('clear_custom_department_content')}
            onConfirm={() => {
              setLoading(true);
              api
                .post(
                  `businesses/customdepartment/clear/${business.id}`,
                  {
                    CustomDepartment01: true
                  }
                )
                .then((response) => {
                  setReload(true);
                  setLoading(false);
                })
                .catch((error) => {
                  console.error(error);
                });
            }}
            okText={t('yes')}
            cancelText="No"
          >
            <Button
              size="medium"
              type="primary"
              loading={loading}
              disabled={itemCount && itemCount.customDepartment01 === 0}
            >
              {t('clear_items')}
            </Button>
          </Popconfirm>
          {' '}{' '}
          <Button
            size="medium"
            loading={loading}
            disabled={itemCount && itemCount.customDepartment01 === 0}
            type="primary"
            onClick={() => {
              setSearchType(12);
              setOpenDrawer(true);
            }}
          >
            View {itemCount && itemCount.customDepartment01} Items
          </Button>
        </Descriptions.Item>
        <Descriptions.Item label="Custom Department 02">
          <Input
            disabled={loading}
            value={storeData?.locations[0]?.customDepartment02Name}
            onChange={(obj) => {
              const temp = JSON.parse(JSON.stringify(storeData));
              temp.locations[0].customDepartment02Name = obj.target.value;
              setStoreData(temp);
            }}
          />
        </Descriptions.Item>
        <Descriptions.Item label="Visible">
          <Switch
            size="small"
            loading={loading}
            checked={storeData?.locations[0]?.showCustomDepartment02}
            onChange={(obj) => {
              const temp = JSON.parse(JSON.stringify(storeData));
              temp.locations[0].showCustomDepartment02 = obj;
              setStoreData(temp);
            }}
          />
        </Descriptions.Item>
        <Descriptions.Item label={t('actions')}>
          <Popconfirm
            placement="topRight"
            title={t('clear_custom_department_content')}
            onConfirm={() => {
              setLoading(true);
              api
                .post(
                  `businesses/customdepartment/clear/${business.id}`,
                  {
                    CustomDepartment02: true
                  }
                )
                .then((response) => {
                  setReload(true);
                  setLoading(false);
                })
                .catch((error) => {
                  console.error(error);
                });
            }}
            okText={t('yes')}
            cancelText="No"
          >
            <Button
              size="medium"
              type="primary"
              loading={loading}
              disabled={itemCount && itemCount.customDepartment02 === 0}
            >
              {t('clear_items')}
            </Button>
          </Popconfirm>
          {' '}{' '}
          <Button
            size="medium"
            type="primary"
            loading={loading}
            disabled={itemCount && itemCount.customDepartment02 === 0}
            onClick={() => {
              setSearchType(13);
              setOpenDrawer(true);
            }}
          >
            {t('view')} {itemCount && itemCount.customDepartment02} {t('items')}
          </Button>
        </Descriptions.Item>
        <Descriptions.Item label="Custom Department 03">
          <Input
            disabled={loading}
            value={storeData?.locations[0]?.customDepartment03Name}
            onChange={(obj) => {
              const temp = JSON.parse(JSON.stringify(storeData));
              temp.locations[0].customDepartment03Name = obj.target.value;
              setStoreData(temp);
            }}
          />
        </Descriptions.Item>
        <Descriptions.Item label="Visible">
          <Switch
            size="small"
            loading={loading}
            checked={storeData?.locations[0]?.showCustomDepartment03}
            onChange={(obj) => {
              const temp = JSON.parse(JSON.stringify(storeData));
              temp.locations[0].showCustomDepartment03 = obj;
              setStoreData(temp);
            }}
          />
        </Descriptions.Item>
        <Descriptions.Item label={t('actions')}>
          <Popconfirm
            placement="topRight"
            title={t('clear_custom_department_content')}
            onConfirm={() => {
              setLoading(true);
              api
                .post(
                  `businesses/customdepartment/clear/${business.id}`,
                  {
                    CustomDepartment03: true
                  }
                )
                .then((response) => {
                  setReload(true);
                  setLoading(false);
                })
                .catch((error) => {
                  console.error(error);
                });
            }}
            okText={t('yes')}
            cancelText="No"
          >
            <Button
              size="medium"
              type="primary"
              loading={loading}
              disabled={itemCount && itemCount.customDepartment03 === 0}
            >
              {t('clear_items')}
            </Button>
          </Popconfirm>
          {' '}{' '}
          <Button
            size="medium"
            type="primary"
            loading={loading}
            disabled={itemCount && itemCount.customDepartment03 === 0}
            onClick={() => {
              setSearchType(14);
              setOpenDrawer(true);
            }}
          >
            {t('view')} {itemCount && itemCount.customDepartment03} {t('items')}
          </Button>
        </Descriptions.Item>
        <Descriptions.Item label="Custom Department 04">
          <Input
            disabled={loading}
            value={storeData?.locations[0]?.customDepartment04Name}
            onChange={(obj) => {
              const temp = JSON.parse(JSON.stringify(storeData));
              temp.locations[0].customDepartment04Name = obj.target.value;
              setStoreData(temp);
            }}
          />
        </Descriptions.Item>
        <Descriptions.Item label="Visible">
          <Switch
            size="small"
            loading={loading}
            checked={storeData?.locations[0]?.showCustomDepartment04}
            onChange={(obj) => {
              const temp = JSON.parse(JSON.stringify(storeData));
              temp.locations[0].showCustomDepartment04 = obj;
              setStoreData(temp);
            }}
          />
        </Descriptions.Item>
        <Descriptions.Item label={t('actions')}>
          <Popconfirm
            placement="topRight"
            title={t('clear_custom_department_content')}
            onConfirm={() => {
              setLoading(true);
              api
                .post(
                  `businesses/customdepartment/clear/${business.id}`,
                  {
                    CustomDepartment04: true
                  }
                )
                .then((response) => {
                  setReload(true);
                  setLoading(false);
                })
                .catch((error) => {
                  console.error(error);
                });
            }}
            okText={t('yes')}
            cancelText="No"
          >
            <Button
              size="medium"
              type="primary"
              loading={loading}
              disabled={itemCount && itemCount.customDepartment04 === 0}
            >
              {t('clear_items')}
            </Button>
          </Popconfirm>
          {' '}{' '}
          <Button
            size="medium"
            type="primary"
            loading={loading}
            disabled={itemCount && itemCount.customDepartment04 === 0}
            onClick={() => {
              setSearchType(30);
              setOpenDrawer(true);
            }}
          >
            {t('view')} {itemCount && itemCount.customDepartment04} {t('items')}
          </Button>
        </Descriptions.Item>
        <Descriptions.Item label="Custom Department 05">
          <Input
            disabled={loading}
            value={storeData?.locations[0]?.customDepartment05Name}
            onChange={(obj) => {
              const temp = JSON.parse(JSON.stringify(storeData));
              temp.locations[0].customDepartment05Name = obj.target.value;
              setStoreData(temp);
            }}
          />
        </Descriptions.Item>
        <Descriptions.Item label="Visible">
          <Switch
            size="small"
            loading={loading}
            checked={storeData?.locations[0]?.showCustomDepartment05}
            onChange={(obj) => {
              const temp = JSON.parse(JSON.stringify(storeData));
              temp.locations[0].showCustomDepartment05 = obj;
              setStoreData(temp);
            }}
          />
        </Descriptions.Item>
        <Descriptions.Item label={t('actions')}>
          <Popconfirm
            placement="topRight"
            title={t('clear_custom_department_content')}
            onConfirm={() => {
              setLoading(true);
              api
                .post(
                  `businesses/customdepartment/clear/${business.id}`,
                  {
                    CustomDepartment05: true
                  }
                )
                .then((response) => {
                  setReload(true);
                  setLoading(false);
                })
                .catch((error) => {
                  console.error(error);
                });
            }}
            okText={t('yes')}
            cancelText="No"
          >
            <Button
              size="medium"
              type="primary"
              loading={loading}
              disabled={itemCount && itemCount.customDepartment05 === 0}
            >
              {t('clear_items')}
            </Button>
          </Popconfirm>
          {' '}{' '}
          <Button
            size="medium"
            type="primary"
            loading={loading}
            disabled={itemCount && itemCount.customDepartment05 === 0}
            onClick={() => {
              setSearchType(31);
              setOpenDrawer(true);
            }}
          >
            {t('view')} {itemCount && itemCount.customDepartment05} {t('items')}
          </Button>
        </Descriptions.Item>
        <Descriptions.Item label="Custom Department 06">
          <Input
            disabled={loading}
            value={storeData?.locations[0]?.customDepartment06Name}
            onChange={(obj) => {
              const temp = JSON.parse(JSON.stringify(storeData));
              temp.locations[0].customDepartment06Name = obj.target.value;
              setStoreData(temp);
            }}
          />
        </Descriptions.Item>
        <Descriptions.Item label="Visible">
          <Switch
            size="small"
            loading={loading}
            checked={storeData?.locations[0]?.showCustomDepartment06}
            onChange={(obj) => {
              const temp = JSON.parse(JSON.stringify(storeData));
              temp.locations[0].showCustomDepartment06 = obj;
              setStoreData(temp);
            }}
          />
        </Descriptions.Item>
        <Descriptions.Item label={t('actions')}>
          <Popconfirm
            placement="topRight"
            title={t('clear_custom_department_content')}
            onConfirm={() => {
              setLoading(true);
              api
                .post(
                  `businesses/customdepartment/clear/${business.id}`,
                  {
                    CustomDepartment06: true
                  }
                )
                .then((response) => {
                  setReload(true);
                  setLoading(false);
                })
                .catch((error) => {
                  console.error(error);
                });
            }}
            okText={t('yes')}
            cancelText="No"
          >
            <Button
              size="medium"
              type="primary"
              loading={loading}
              disabled={itemCount && itemCount.customDepartment06 === 0}
            >
              {t('clear_items')}
            </Button>
          </Popconfirm>
          {' '}{' '}
          <Button
            size="medium"
            type="primary"
            loading={loading}
            disabled={itemCount && itemCount.customDepartment06 === 0}
            onClick={() => {
              setSearchType(32);
              setOpenDrawer(true);
            }}
          >
            {t('view')} {itemCount && itemCount.customDepartment06} {t('items')}
          </Button>
        </Descriptions.Item>
      </Descriptions>
      {openDrawer ? <CustomDepartmentItems open={openDrawer} /> : null}
      {openAddItem ? <AddItemToCustomDept open={openAddItem} /> : null}
    </>
  );
}

export default CustomDepartments;
