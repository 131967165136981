import React, { useRef, useState, } from 'react';
import { useRecoilState, } from 'recoil';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import ReactToPrint from 'react-to-print';
import moment from 'moment';
import { Button, Modal, Typography } from 'antd';
import { PrinterOutlined } from '@ant-design/icons';
import { prepDateRangeAtom, prepInventoryItemsAtom, selectedStoreAtom, showPrepAtom } from '../../../atoms/Atoms';

function Order({ from }) {
  const ref = useRef(null);
  const { t } = useTranslation();
  const [items, setItems] = useRecoilState(prepInventoryItemsAtom);
  const [dateRange, setDateRange] = useRecoilState(prepDateRangeAtom);
  const [view, setView] = useRecoilState(showPrepAtom);
  const [loading, setLoading] = useState(false);
  const [selectedStore, setSelectedStore] = useRecoilState(selectedStoreAtom);

  function dates() {
    if (dateRange === 'Today') {
      return moment.utc().format('MMMM Do YYYY');
    }
    if (dateRange === 'Tomorrow') {
      return moment.utc().add(1, 'days').format('MMMM Do YYYY');
    }
    if (dateRange === 'Future') {
      return `From ${moment.utc().add(1, 'days').format('MMMM Do YYYY')} to ${moment.utc().add(6, 'days').format('MMMM Do YYYY')} `;
    }
    return 'Unknown';
  }

  return (
    <Modal
      closable="true"
      centered="true"
      open={view}
      width="95%"
      onCancel={() => { setView(false); }}
      footer={[
        <Button
          key="close"
          disabled={loading}
          onClick={() => setView(false)}
        >
          {t('close')}
        </Button>
      ]}
    >
      <div>
        <main ref={ref} className="max-w-7xl mx-auto px-4 py-8 space-y-8">
          <h1 className="text-sm font-medium text-lhf-color">{t('prep_inventory')}</h1>
          <p className="mt-2 text-4xl font-extrabold tracking-tight text-gray-900 sm:text-5xl">
            {selectedStore}
          </p>
          <p className="mt-2 text-base text-gray-500">
            Viewing Data for {dates()}
          </p>
          <section>
            <div>
              <ReactToPrint
                content={() => ref.current}
                trigger={() => (
                  <button
                    type="button"
                    className="flex items-center text-white text-xl justify-center bg-blue-400 hover:bg-blue-200 rounded-full w-10 h-10 ring-0 focus:ring-0 outline-none focus:outline-none transition duration-500 ease-in"
                  >
                    <PrinterOutlined className="h-5 w-5" />
                  </button>
                )}
              />
            </div>
          </section>
          <section className="space-y-4">
            <div className="text-xl font-extrabold tracking-tight">
              {t('products')}: {items && items?.length}
              <br />
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:md:grid-cols-4 gap-4">
              {_.map(items, (product) => (
                <div
                  key={product.id}
                  className="flex items-center border rounded-xl space-x-4 p-4"
                >
                  <div className="flex items-center justify-center w-20 h-20 border rounded-xl p-2">
                    <img
                      src={product.imageUrl}
                      alt={product.name}
                      className="object-center object-cover"
                    />
                  </div>
                  <div className="flex-1">
                    <div className="text-xs font-medium"><Typography.Text copyable>{product.upc}</Typography.Text></div>
                    <div className="text-xs font-medium">{product.name}</div>
                    <div className="text-xs text-gray-500">{product.brand}</div>
                    <div className="text-xs text-gray-500">
                      {product.description}
                    </div>
                  </div>
                  <div className="flex flex-col text-sm font-medium">
                    <div className="flex flex-row">
                      <div className="text-xs text-gray-600">x</div>
                      <div className="text-sm">{product.count}</div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </section>
        </main>
      </div>
    </Modal>
  );
}

export default Order;
