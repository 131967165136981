import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import dayjs from 'dayjs';
import { Button, Card, Col, DatePicker, Row, Spin, Statistic, Table, Tabs, Tag, Typography } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import { CheckCircleOutlined, CloudDownloadOutlined, SyncOutlined } from '@ant-design/icons';
import { useMount } from 'react-use';
import _ from 'lodash';
import { useJsonToCsv } from 'react-json-csv';
import { Pie } from 'react-chartjs-2';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  businessAtom,
  reloadPerformanceDataAtom,
} from '../../../../atoms/Atoms';
import api from '../../../../api/api';

function Performance() {
  const business = useRecoilValue(businessAtom);
  const [reload, setReload] = useRecoilState(reloadPerformanceDataAtom);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const { RangePicker } = DatePicker;
  const [dateRange, setDateRange] = useState({
    start: moment().startOf('month').format('YYYY-MM-DD'),
    end: moment().endOf('month').format('YYYY-MM-DD')
  });
  const [pickupData, setPickupData] = useState(null);
  const [deliveryData, setDeliveryData] = useState(null);
  const [employeeData, setEmployeeData] = useState(null);
  const { Title } = Typography;
  const { saveAsCsv } = useJsonToCsv();

  const dataListFields = {
    name: t('name'),
    orderCount: t('order_count'),
    itemsPerOrder: t('items_per_order'),
    itemsPerMinute: t('items_per_minute'),
    averageTime: t('average_time'),
  };

  function GetDispatchData() {
    setLoading(true);
    api
      .post(`analytics/v2/summary/dispatch/${business?.id}`,
        { StartDate: dateRange.start,
          EndDate: dateRange.end,
        })
      .then((response) => {
        if (response?.data?.success) {
          setLoading(false);
          setPickupData(response.data.data.pickup);
          setDeliveryData(response.data.data.delivery);
        } else {
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  }

  function GetEmployeeData() {
    setLoading(true);
    api
      .post(`analytics/v2/summary/business/performance/${business?.id}`,
        { StartDate: dateRange.start,
          EndDate: dateRange.end,
        })
      .then((response) => {
        if (response?.data?.success) {
          setLoading(false);
          setEmployeeData(response.data.data.results);
        } else {
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  }

  function pickup() {
    return (
      <div className="p-4 border bg-gray-100 rounded-lg mb-4">
        <Row align="middle" gutter={[8, 8]} justify="space-between">
          <Col>
            <Typography.Title level={5}>
              Pickup Dispatch Analytics
            </Typography.Title>
            <Typography.Text type="secondary">
              Below you will find how many minutes after the desired pickup time
              was the order dispatched
            </Typography.Text>
          </Col>
          <Col>
            {pickupData ? (
              <div className="flex flex-row">
                <div className="mr-10">
                  <Statistic
                    title="Order Count"
                    groupSeparator=""
                    value={`${pickupData.orderCount}`}
                  />
                </div>
              </div>
            ) : null}
          </Col>
        </Row>
        <Row />
        <div style={{ align: 'center' }}>
          <Row>
            <Col>
              <Card title="Dispatched On-Time" style={{ margin: 10 }}>
                {pickupData && (
                <>
                  <Title level={4} align="center">
                    {pickupData.early}
                  </Title>
                  <Title level={5} align="center">
                    order(s)
                  </Title>
                </>
                )}
              </Card>
            </Col>
            <Col>
              <Card title="> 15 mins after" style={{ margin: 10 }}>
                {pickupData && (
                <>
                  <Title level={4} align="center">
                    {pickupData.lessThanFifteenMins}
                  </Title>
                  <Title level={5} align="center">
                    order(s)
                  </Title>
                </>
                )}
              </Card>
            </Col>
            <Col>
              <Card title="15 to 30 mins after" style={{ margin: 10 }}>
                {pickupData && (
                  <>
                    <Title level={4} align="center">
                      {pickupData.fifteenMinsToThirtyMins}
                    </Title>
                    <Title level={5} align="center">
                      order(s)
                    </Title>
                  </>
                )}
              </Card>
            </Col>
            <Col>
              <Card title="30 mins to 1 hour after" style={{ margin: 10 }}>
                {pickupData && (
                  <>
                    <Title level={4} align="center">
                      {pickupData.thirtyMinsToSixtyMins}
                    </Title>
                    <Title level={5} align="center">
                      order(s)
                    </Title>
                  </>
                )}
              </Card>
            </Col>
            <Col>
              <Card title="1 to 2 hours after" style={{ margin: 10 }}>
                {pickupData && (
                  <>
                    <Title level={4} align="center">
                      {pickupData.sixtyMinsToTwoHours}
                    </Title>
                    <Title level={5} align="center">
                      order(s)
                    </Title>
                  </>
                )}
              </Card>
            </Col>
            <Col>
              <Card title="+ 2 hours after" style={{ margin: 10 }}>
                {pickupData && (
                  <>
                    <Title level={4} align="center">
                      {pickupData.twoHoursPlus}
                    </Title>
                    <Title level={5} align="center">
                      order(s)
                    </Title>
                  </>
                )}
              </Card>
            </Col>
          </Row>
        </div>
        <br />
        <br />
        {pickupData && (
          <div className="p-4 border bg-gray-100 rounded-lg mb-4">
            <Typography.Title level={4} align="center">
              How many orders are dispatching on-time? (%)
            </Typography.Title>
            <br />
            <br />
            <Pie
              weight={100}
              data={{
                labels: [
                  'On Time %',
                  'Less than 15 mins %',
                  'Between 15 and 30 mins %',
                  'Between 30mins and 1 hour %',
                  'Between 1 and 2 hours %',
                  'More than 2 hours %'
                ],
                datasets: [{
                  fill: true,
                  label: '% Dispatched',
                  data: [
                    pickupData.earlyPercentage,
                    pickupData.lessThanFifteenMinsPercentage,
                    pickupData.fifteenMinsToThirtyMinsPercentage,
                    pickupData.thirtyMinsToSixtyMinsPercentage,
                    pickupData.sixtyMinsToTwoHoursPercentage,
                    pickupData.twoHoursPlusPercentage
                  ],
                  backgroundColor: [
                    'rgba(30, 130, 76, 0.2)',
                    'rgba(63, 195, 128, 0.2)',
                    'rgba(175, 222, 24, 0.2)',
                    'rgba(254, 254, 9, 0.2)',
                    'rgba(254, 135, 15, 0.2)',
                    'rgba(212, 33, 30, 0.2)',
                  ],
                  borderColor: [
                    'rgba(30, 130, 76, 1)',
                    'rgba(63, 195, 128, 1)',
                    'rgba(175, 222, 24, 1)',
                    'rgba(254, 254, 9, 1)',
                    'rgba(254, 135, 15, 1)',
                    'rgba(212, 33, 30, 1)',
                  ],
                  borderWidth: 1
                }]
              }}
            />
          </div>
        )}
      </div>
    );
  }

  function delivery() {
    return (
      <div className="p-4 border bg-gray-100 rounded-lg mb-4">
        <Row align="middle" gutter={[8, 8]} justify="space-between">
          <Col>
            <Typography.Title level={5}>
              Delivery Dispatch Analytics
            </Typography.Title>
            <Typography.Text type="secondary">
              Below you will find how many minutes after the desired delivery time
              was the order dispatched
            </Typography.Text>
          </Col>
          <Col>
            {deliveryData ? (
              <div className="flex flex-row">
                <div className="mr-10">
                  <Statistic
                    title="Order Count"
                    groupSeparator=""
                    value={`${deliveryData.orderCount}`}
                  />
                </div>
              </div>
            ) : null}
          </Col>
        </Row>
        <Row />
        <div style={{ align: 'center' }}>
          <Row>
            <Col>
              <Card title="Dispatched On-Time" style={{ margin: 10 }}>
                {deliveryData && (
                <>
                  <Title level={4} align="center">
                    {deliveryData.early}
                  </Title>
                  <Title level={5} align="center">
                    order(s)
                  </Title>
                </>
                )}
              </Card>
            </Col>
            <Col>
              <Card title="> 15 mins after" style={{ margin: 10 }}>
                {deliveryData && (
                <>
                  <Title level={4} align="center">
                    {deliveryData.lessThanFifteenMins}
                  </Title>
                  <Title level={5} align="center">
                    order(s)
                  </Title>
                </>
                )}
              </Card>
            </Col>
            <Col>
              <Card title="15 to 30 mins after" style={{ margin: 10 }}>
                {deliveryData && (
                  <>
                    <Title level={4} align="center">
                      {deliveryData.fifteenMinsToThirtyMins}
                    </Title>
                    <Title level={5} align="center">
                      order(s)
                    </Title>
                  </>
                )}
              </Card>
            </Col>
            <Col>
              <Card title="30 mins to 1 hour after" style={{ margin: 10 }}>
                {deliveryData && (
                  <>
                    <Title level={4} align="center">
                      {deliveryData.thirtyMinsToSixtyMins}
                    </Title>
                    <Title level={5} align="center">
                      order(s)
                    </Title>
                  </>
                )}
              </Card>
            </Col>
            <Col>
              <Card title="1 to 2 hours after" style={{ margin: 10 }}>
                {deliveryData && (
                  <>
                    <Title level={4} align="center">
                      {deliveryData.sixtyMinsToTwoHours}
                    </Title>
                    <Title level={5} align="center">
                      order(s)
                    </Title>
                  </>
                )}
              </Card>
            </Col>
            <Col>
              <Card title="+ 2 hours after" style={{ margin: 10 }}>
                {deliveryData && (
                  <>
                    <Title level={4} align="center">
                      {deliveryData.twoHoursPlus}
                    </Title>
                    <Title level={5} align="center">
                      order(s)
                    </Title>
                  </>
                )}
              </Card>
            </Col>
          </Row>
        </div>
        <br />
        <br />
        {deliveryData && (
          <div className="p-4 border bg-gray-100 rounded-lg mb-4">
            <Typography.Title level={4} align="center">
              How many orders are dispatching on-time? (%)
            </Typography.Title>
            <br />
            <br />
            <Pie
              weight={100}
              data={{
                labels: [
                  'On Time %',
                  'Less than 15 mins %',
                  'Between 15 and 30 mins %',
                  'Between 30mins and 1 hour %',
                  'Between 1 and 2 hours %',
                  'More than 2 hours %'
                ],
                datasets: [{
                  fill: true,
                  label: '% Dispatched',
                  data: [
                    deliveryData.earlyPercentage,
                    deliveryData.lessThanFifteenMinsPercentage,
                    deliveryData.fifteenMinsToThirtyMinsPercentage,
                    deliveryData.thirtyMinsToSixtyMinsPercentage,
                    deliveryData.sixtyMinsToTwoHoursPercentage,
                    deliveryData.twoHoursPlusPercentage
                  ],
                  backgroundColor: [
                    'rgba(30, 130, 76, 0.2)',
                    'rgba(63, 195, 128, 0.2)',
                    'rgba(175, 222, 24, 0.2)',
                    'rgba(254, 254, 9, 0.2)',
                    'rgba(254, 135, 15, 0.2)',
                    'rgba(212, 33, 30, 0.2)',
                  ],
                  borderColor: [
                    'rgba(30, 130, 76, 1)',
                    'rgba(63, 195, 128, 1)',
                    'rgba(175, 222, 24, 1)',
                    'rgba(254, 254, 9, 1)',
                    'rgba(254, 135, 15, 1)',
                    'rgba(212, 33, 30, 1)',
                  ],
                  borderWidth: 1
                }]
              }}
            />
          </div>
        )}
      </div>
    );
  }

  function employee() {
    return (
      <div className="p-4 border bg-gray-100 rounded-lg mb-4">
        <Typography.Title level={5}>
          Employee Performance Analytics
        </Typography.Title>
        <Tabs defaultActiveKey="1">
          <Tabs.TabPane tab={t('picking')} key="2">
            <br />
            <Button
              style={{ float: 'right' }}
              size="small"
              icon={<CloudDownloadOutlined />}
              onClick={() => {
                const data = _.map(employeeData, (p) => ({
                  name: p.employeeName,
                  orderCount: p.picking.orderCount,
                  itemsPerOrder: p.picking.itemsPerOrder,
                  itemsPerMinute: p.picking.itemsPerMinute,
                  averageTime: p.picking.averageTime,
                }));
                saveAsCsv({
                  data,
                  fields: dataListFields,
                  filename: 'Employee Performance - Picking',
                });
              }}
            >
              {t('export_list')}
            </Button>
            <br />
            <Table
              size="small"
              loading={loading}
              bordered
              pagination={false}
              columns={[
                {
                  title: t('name'),
                  dataIndex: 'employeeName',
                  key: 'empployeeName',
                  align: 'left',
                  className: 'text-xs',
                  render: (text) => <span>{text}</span>,
                },
                {
                  title: t('order_count'),
                  dataIndex: 'count',
                  key: 'count',
                  align: 'left',
                  className: 'text-xs',
                  render: (text, row) => <span>{row.picking.orderCount}</span>,
                  sorter: (a, b) => a.picking.orderCount - b.picking.orderCount,

                },
                {
                  title: t('items_per_order'),
                  dataIndex: 'itemsPerOrder',
                  key: 'itemsPerOrder',
                  align: 'left',
                  className: 'text-xs',
                  render: (text, row) => <span>{row.picking.itemsPerOrder }</span>,
                },
                {
                  title: t('items_per_minute'),
                  dataIndex: 'itemsPerMinute',
                  key: 'itemsPerMinute',
                  align: 'left',
                  className: 'text-xs',
                  render: (text, row) => <span>{row.picking.itemsPerMinute == null ? '-' : row.picking.itemsPerMinute }</span>,
                },
                {
                  title: t('average_time'),
                  dataIndex: 'averageTime',
                  key: 'averageTime',
                  align: 'left',
                  className: 'text-xs',
                  render: (text, row) => <span>{row.picking.averageTime }</span>,
                },
              ]}
              dataSource={employeeData}
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab={t('packing')} key="3">
            <br />
            <Button
              style={{ float: 'right' }}
              size="small"
              icon={<CloudDownloadOutlined />}
              onClick={() => {
                const data = _.map(employeeData, (p) => ({
                  name: p.employeeName,
                  orderCount: p.packing.orderCount,
                  itemsPerOrder: p.packing.itemsPerOrder,
                  itemsPerMinute: p.packing.itemsPerMinute,
                  averageTime: p.packing.averageTime,
                }));
                saveAsCsv({
                  data,
                  fields: dataListFields,
                  filename: 'Employee Performance - Packing',
                });
              }}
            >
              {t('export_list')}
            </Button>
            <br />
            <Table
              size="small"
              loading={loading}
              bordered
              pagination={false}
              columns={[
                {
                  title: t('name'),
                  dataIndex: 'employeeName',
                  key: 'empployeeName',
                  align: 'left',
                  className: 'text-xs',
                  render: (text) => <span>{text}</span>,
                },
                {
                  title: t('order_count'),
                  dataIndex: 'count',
                  key: 'count',
                  align: 'left',
                  className: 'text-xs',
                  render: (text, row) => <span>{row.packing.orderCount}</span>,
                  sorter: (a, b) => a.packing.orderCount - b.packing.orderCount,

                },
                {
                  title: t('items_per_order'),
                  dataIndex: 'itemsPerOrder',
                  key: 'itemsPerOrder',
                  align: 'left',
                  className: 'text-xs',
                  render: (text, row) => <span>{row.packing.itemsPerOrder }</span>,
                },
                {
                  title: t('items_per_minute'),
                  dataIndex: 'itemsPerMinute',
                  key: 'itemsPerMinute',
                  align: 'left',
                  className: 'text-xs',
                  render: (text, row) => <span>{row.packing.itemsPerMinute == null ? '-' : row.packing.itemsPerMinute }</span>,
                },
                {
                  title: t('average_time'),
                  dataIndex: 'averageTime',
                  key: 'averageTime',
                  align: 'left',
                  className: 'text-xs',
                  render: (text, row) => <span>{row.packing.averageTime }</span>,
                },
              ]}
              dataSource={employeeData}
            />
          </Tabs.TabPane>
        </Tabs>
      </div>
    );
  }

  useMount(() => {
    GetDispatchData();
    GetEmployeeData();
  });

  useEffect(() => {
    GetDispatchData();
    GetEmployeeData();
  }, [dateRange, business]);

  return (
    <div>
      <PageHeader
        className="mb-4 px-0"
        title={t('Performance')}
        tags={
          <Tag
            color="#2db7f5"
            icon={business ? <CheckCircleOutlined /> : <SyncOutlined spin />}
          >
            {business ? business.name : `${t('loading')}...`}
          </Tag>
          }
        extra={
          [
            <div>
              <RangePicker
                disabled={loading}
                format="YYYY-MM-DD"
                value={[
                  dayjs(dateRange.start, 'YYYY-MM-DD'),
                  dayjs(dateRange.end, 'YYYY-MM-DD')
                ]}
                onChange={(date, dateString) => {
                  setDateRange({
                    start: dateString[0],
                    end: dateString[1]
                  });
                }}
              />
            </div>,
          ]
          }
      />
      {loading ? <Spin /> : null}
      <Tabs defaultActiveKey="1">
        <Tabs.TabPane tab={t('pickup')} key="2">
          {pickup()}
        </Tabs.TabPane>
        <Tabs.TabPane tab={t('delivery')} key="3">
          {delivery()}
        </Tabs.TabPane>
        <Tabs.TabPane tab={t('employee')} key="4">
          {employee()}
        </Tabs.TabPane>
      </Tabs>
    </div>
  );
}

export default Performance;
