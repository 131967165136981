import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import _ from 'lodash';
import { notification, Modal, Avatar, Button, Select, Typography } from 'antd';
import { Comment } from '@ant-design/compatible';
import api from '../../../api/api';
import { changePaymentMethodAtom, selectedOrderAtom, reloadOrder } from '../../../atoms/Atoms';
import Grocefy from '../../../assets/images/grocefyLogoAlone.png';

function ChangePaymentMethod() {
  const [changePaymentMethod, setchangePaymentMethod] = useRecoilState(changePaymentMethodAtom);
  const [selectedOrder, setSelectedOrder] = useRecoilState(selectedOrderAtom);
  const [reload, setReload] = useRecoilState(reloadOrder);
  const [loading, setLoading] = useState(false);
  const [loadingCards, setLoadingCards] = useState(false);
  const { Option } = Select;
  const [newValue, setNewValue] = useState(null);
  const [cards, setCards] = useState([]);
  const [selectedCard, setSelectedCard] = useState(null);
  const { t } = useTranslation();

  const closeModal = function () {
    if (!loading) {
      setchangePaymentMethod(false);
    }
  };

  const editClick = function () {
    setLoading(true);
    api
      .put(
        `orders/paymentmethod/change/${newValue}`,
        {
          CardId: selectedCard,
          OrderId: selectedOrder.id
        }
      )
      .then((response) => {
        if (response && response.data.error) {
          console.error('Error Message: ', response.data.error);
          notification.open({
            message: 'Alert!',
            description: (
              <Comment
                author={<span>Grocefy</span>}
                avatar={<Avatar src={Grocefy} alt="grocefy" />}
                content={
                  <p className="text-sm">{response.data.error}</p>
                }
              />
            ),
          });
        } else if (
          response &&
          response.data.success &&
          response.data.message
        ) {
          notification.open({
            message: 'Success!',
            description: (
              <Comment
                author={<span>Grocefy</span>}
                avatar={<Avatar src={Grocefy} alt="grocefy" />}
                content={
                  <p className="text-sm">{response.data.message}</p>
                }
              />
            ),
          });
        }
        setchangePaymentMethod(false);
        setReload(true);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const getCards = function () {
    setLoadingCards(true);
    api
      .get(`users/cards/${selectedOrder.customerId}`)
      .then((response) => {
        setLoadingCards(false);
        setCards(response.data.data.results);
      })
      .catch((error) => {
        setLoadingCards(false);
        console.error(error);
      });
  };

  useEffect(() => {
    setSelectedCard(null);
    if (newValue === 0) {
      getCards();
    }
  }, [newValue]);

  return (
    <>
      {changePaymentMethod && (
        <Modal
          closable="true"
          centered="true"
          width={300}
          open={changePaymentMethod}
          onCancel={() => { setchangePaymentMethod(false); }}
          footer={[
            <Button
              key="close"
              type="primary"
              danger
              disabled={loading}
              onClick={() => closeModal()}
            >
              {t('close')}
            </Button>,
            <Button
              key="create"
              disabled={loading}
              type="primary"
              onClick={() => editClick()}
            >
              {t('change')}
            </Button>
          ]}
        >
          <div>
            <Typography.Title level={5}>{t('change_payment_method')}</Typography.Title>
            <Select
              style={{ width: 175 }}
              defaultValue={`${t('select_new_payment')}...`}
              onChange={(value) => setNewValue(value)}
            >
              <Option value={0}>Stripe</Option>
              <Option value={4}>EBT</Option>
              <Option value={1}>ATH Movil</Option>
              <Option value={10}>MCS</Option>
              <Option value={11}>MMM</Option>
              <Option value={16}>SSS</Option>
              <Option value={17}>Humana</Option>
              <Option value={5}>PayPal</Option>
              <Option value={7}>ATH</Option>
              <Option value={3}>{t('other')}</Option>
            </Select>
          </div>
          {newValue === 0 && (
            <div className="mt-4">
              <Typography.Title level={5}>{t('change_card')}</Typography.Title>
              <Select
                loading={loadingCards}
                style={{ width: 175 }}
                defaultValue={`${t('select_card')}...`}
                onChange={(value) => setSelectedCard(value)}
              >
                {_.map(cards, (b) => (
                  <Option
                    key={b.id}
                    value={b.id}
                    className="text-sm flex items-center"
                  >
                    {b.nickname}
                  </Option>
                ))}
              </Select>
            </div>
          )}
        </Modal>
      )}
    </>
  );
}

export default ChangePaymentMethod;
