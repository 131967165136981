import { Divider, Spin, Tabs, Typography, Button, Row, Col } from 'antd';
import {
  ArrowUpOutlined,
  ArrowDownOutlined,
  SyncOutlined,
  MinusOutlined
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { useRecoilValue, } from 'recoil';
import AdoptionChart from './AdoptionChart';
import SalesChart from './SalesChart';
import { mapPaymentType, thousand } from '../../../utils/functions';
import { timezoneAtom, } from '../../../../atoms/Atoms';
import api from '../../../../api/api';

function Trends() {
  const [mom, setMom] = useState(null);
  const [wow, setWow] = useState(null);
  const [yoy, setYoy] = useState(null);
  const [loadingMom, setLoadingMom] = useState(false);
  const [loadingWow, setLoadingWow] = useState(false);
  const [loadingYoy, setLoadingYoy] = useState(false);
  const timezone = useRecoilValue(timezoneAtom);
  const [reload, setReload] = useState(false);
  const { t } = useTranslation();
  const width = window.innerWidth;

  function getWow() {
    setLoadingWow(true);
    api
      .get('analytics/wow')
      .then((response) => {
        setLoadingWow(false);
        setWow(response.data.data);
      })
      .catch((error) => {
        console.error(error);
        setLoadingWow(false);
      });
  }

  function getMom() {
    setLoadingMom(true);
    api
      .get('analytics/mom')
      .then((response) => {
        setLoadingMom(false);
        setMom(response.data.data);
      })
      .catch((error) => {
        console.error(error);
        setLoadingMom(false);
      });
  }

  function getYoy() {
    setLoadingYoy(true);
    api
      .get('analytics/mom/year')
      .then((response) => {
        setLoadingYoy(false);
        setYoy(response.data.data);
      })
      .catch((error) => {
        console.error(error);
        setLoadingYoy(false);
      });
  }

  function datesStringComparision(date1, date2) {
    const sameYear = moment(`${date1}`).tz(timezone).format('YYYY') === moment(`${date2}`).tz(timezone).format('YYYY');
    const sameMonth = moment(`${date1}`).tz(timezone).format('MMM') === moment(`${date2}`).tz(timezone).format('MMM');
    if (sameYear && sameMonth) {
      return `${moment(`${date1}`).tz(timezone).format('MMM D')}-${moment(`${date2}`).tz(timezone).format('D, YYYY')}`;
    } if (sameYear && !sameMonth) {
      return `${moment(`${date1}`).tz(timezone).format('MMM D')} - ${moment(`${date2}`).tz(timezone).format('MMM D, YYYY')}`;
    } if (!sameYear && !sameMonth) { return moment(`${date1}`).tz(timezone).format('MMM'); }
    return 'Yes';
  }

  function devicesPercentage(last, current) {
    return Math.abs((current - last) / last);
  }

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
  }

  useEffect(() => {
    getWow();
    getMom();
    getYoy();
  }, []);

  useEffect(() => {
    if (reload) {
      getWow();
      getMom();
      getYoy();
    }
  }, [reload]);

  return (
    <div>
      <div className="flex flex-col" style={{ float: 'left' }}>
        <Button
          className="mb-4"
          size="small"
          type="primary"
          loading={loadingMom || loadingWow}
          icon={<SyncOutlined spin={loadingMom || loadingWow} />}
          onClick={() => {
            setReload(!reload);
          }}
        >
          {t('reload')}
        </Button>
      </div>
      <br />
      <br />
      <Tabs defaultActiveKey="0">
        <Tabs.TabPane tab={t('week_over_week')} key="0">
          <div>
            <h3 className="text-lg leading-6 font-medium text-gray-900">{t('sales')}</h3>
            <Typography.Text type="secondary">
              {t('trends_compare_content')}{' ('}
              {wow && datesStringComparision(wow.rollingCurrentMonthStartDate,
                wow.rollingCurrentMonthEndDate)}{')'} vs  {' ('}{wow && datesStringComparision(wow.rollingLastMonthStartDate,
                  wow.rollingLastMonthEndDate)}{')'}
            </Typography.Text>
            <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-4">
              <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
                <Spin spinning={loadingWow}>
                  <dt className="text-sm font-medium text-gray-500 truncate">{t('revenue')}</dt>
                  <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
                    <div className="flex items-baseline text-2xl font-semibold text-gray-900">
                      ${wow && thousand(wow.rollingCurrentMonthSales)}
                      <span className="ml-2 text-sm font-medium text-gray-500">{t('from')} ${wow && thousand(wow.rollingLastMonthSale)}</span>
                    </div>
                    <div
                      className={classNames(
                        wow && wow.rollingMoMGrowth === 0 ? 'text-black' : (wow && wow.rollingMoMGrowth > 0 ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800',
                        'inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium md:mt-2 lg:mt-0'
                        )
                      )}
                    >
                      {wow && wow.rollingMoMGrowth === 0 ? (
                        <MinusOutlined
                          className="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-black"
                          aria-hidden="true"
                        />
                      ) :
                        (wow && wow.rollingMoMGrowth > 0 ? (
                          <ArrowUpOutlined
                            className="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-green-500"
                            aria-hidden="true"
                          />
                        ) : (
                          <ArrowDownOutlined
                            className="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-red-500"
                            aria-hidden="true"
                          />
                        ))}
                      <span className="sr-only">{wow && wow.rollingMoMGrowth === 0 ? 'No growth' : (wow && wow.rollingMoMGrowth > 0 ? t('increased_by') : t('decreased_by'))}</span>
                      {wow && wow.rollingMoMGrowth}%
                    </div>
                  </dd>
                  <br />
                  <dd>
                    <div hidden className="text-sm">
                      <a href="/" className="font-medium text-indigo-600 hover:text-indigo-500">
                        {' '}
                        {t('view_all')}
                      </a>
                    </div>
                  </dd>
                </Spin>
              </div>
              <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
                <Spin spinning={loadingWow}>
                  <dt className="text-sm font-medium text-gray-500 truncate">{t('orders')}</dt>
                  <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
                    <div className="flex items-baseline text-2xl font-semibold text-gray-900">
                      {wow && wow.rollingCurrentMonthCount.toLocaleString()}
                      <span className="ml-2 text-sm font-medium text-gray-500">{t('from')} {wow && wow.rollingLastMonthCount.toLocaleString()}</span>
                    </div>
                    <div
                      className={classNames(
                        wow && wow.rollingMoMOrderGrowth === 0 ? 'text-black' : (wow && wow.rollingMoMOrderGrowth > 0 ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800',
                        'inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium md:mt-2 lg:mt-0'
                        )
                      )}
                    >
                      {wow && wow.rollingMoMOrderGrowth === 0 ? (
                        <MinusOutlined
                          className="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-black"
                          aria-hidden="true"
                        />
                      ) :
                        (wow && wow.rollingMoMOrderGrowth > 0 ? (
                          <ArrowUpOutlined
                            className="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-green-500"
                            aria-hidden="true"
                          />
                        ) : (
                          <ArrowDownOutlined
                            className="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-red-500"
                            aria-hidden="true"
                          />
                        ))}
                      <span className="sr-only">{wow && wow.rollingMoMOrderGrowth === 0 ? t('no_growth') : (wow && wow.rollingMoMOrderGrowth > 0 ? t('increased_by') : t('decreased_by'))}</span>
                      {wow && wow.rollingMoMOrderGrowth}%
                    </div>
                  </dd>
                  <br />
                  <dd>
                    <div hidden className="text-sm">
                      <a href="/" className="font-medium text-indigo-600 hover:text-indigo-500">
                        {' '}
                        {t('view_all')}
                      </a>
                    </div>
                  </dd>
                </Spin>
              </div>
              <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
                <Spin spinning={loadingWow}>
                  <dt className="text-sm font-medium text-gray-500 truncate">{t('customers')}</dt>
                  <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
                    <div className="flex items-baseline text-2xl font-semibold text-gray-900">
                      {wow?.rollingCurrentMonthCustomerCount?.toLocaleString()}
                      <span className="ml-2 text-sm font-medium text-gray-500">{t('from')} {wow?.rollingLastMonthCustomerCount?.toLocaleString()}</span>
                    </div>
                    <div
                      className={classNames(
                        wow?.rollingLastMonthCustomerGrowth === 0 ? 'text-black' : (wow?.rollingLastMonthCustomerGrowth > 0 ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800',
                        'inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium md:mt-2 lg:mt-0'
                        )
                      )}
                    >
                      {wow?.rollingLastMonthCustomerGrowth === 0 ? (
                        <MinusOutlined
                          className="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-black"
                          aria-hidden="true"
                        />
                      ) :
                        (wow?.rollingLastMonthCustomerGrowth > 0 ?
                          (
                            <ArrowUpOutlined
                              className="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-green-500"
                              aria-hidden="true"
                            />
                          ) : (
                            <ArrowDownOutlined
                              className="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-red-500"
                              aria-hidden="true"
                            />
                          )
                        )}
                      <span className="sr-only">{wow?.rollingCurrentMonthCustomerCount === wow?.rollingLastMonthCustomerCount ? t('no_growth') : (wow?.rollingCurrentMonthCustomerCount > wow?.rollingLastMonthCustomerCount ? t('increased_by') : t('decreased_by'))}</span>
                      {wow?.rollingLastMonthCustomerGrowth}%
                    </div>
                  </dd>
                  <br />
                  <dd>
                    <div hidden className="text-sm">
                      <a href="/" className="font-medium text-indigo-600 hover:text-indigo-500">
                        {' '}
                        {t('view_all')}
                      </a>
                    </div>
                  </dd>
                </Spin>
              </div>
            </dl>
          </div>
          <br />
          <div>
            <h3 className="text-lg leading-6 font-medium text-gray-900">{t('devices')}</h3>
            <Typography.Text type="secondary">
              {t('trends_compare_devices')}{' ('}
              {wow && datesStringComparision(wow.rollingCurrentMonthStartDate,
                wow.rollingCurrentMonthEndDate)}{')'} vs  {' ('}{wow && datesStringComparision(wow.rollingLastMonthStartDate,
                  wow.rollingLastMonthEndDate)}{')'}
            </Typography.Text>
            <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-4">
              <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
                <Spin spinning={loadingWow}>
                  <dt className="text-sm font-medium text-gray-500 truncate">{t('mobile_orders')}</dt>
                  <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
                    <div className="flex items-baseline text-2xl font-semibold text-gray-900">
                      {wow && wow.currentOrderDeviceAnalytics?.mobileOrdersCount}
                      <span className="ml-2 text-sm font-medium text-gray-500">{t('from')} {wow && wow.lastOrderDeviceAnalytics?.mobileOrdersCount}</span>
                    </div>
                    <div
                      className={classNames(
                        wow && devicesPercentage(wow.currentOrderDeviceAnalytics?.mobileOrdersCount, wow.lastOrderDeviceAnalytics?.mobileOrdersCount) === 0 ? 'text-black' : (wow && devicesPercentage(wow.currentOrderDeviceAnalytics?.mobileOrdersCount, wow.lastOrderDeviceAnalytics?.mobileOrdersCount) > 0 ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800',
                        'inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium md:mt-2 lg:mt-0'
                        )
                      )}
                    >
                      {wow && devicesPercentage(wow.currentOrderDeviceAnalytics?.mobileOrdersCount,
                        wow.lastOrderDeviceAnalytics?.mobileOrdersCount) === 0 ? (
                          <MinusOutlined
                            className="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-black"
                            aria-hidden="true"
                          />
                        ) :
                        (wow && devicesPercentage(
                          wow.currentOrderDeviceAnalytics?.mobileOrdersCount,
                          wow.lastOrderDeviceAnalytics?.mobileOrdersCount
                        ) > 0 ? (
                          <ArrowUpOutlined
                            className="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-green-500"
                            aria-hidden="true"
                          />
                          ) : (
                            <ArrowDownOutlined
                              className="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-red-500"
                              aria-hidden="true"
                            />
                          ))}
                      <span className="sr-only">{wow && devicesPercentage(wow.currentOrderDeviceAnalytics?.mobileOrdersCount, wow.lastOrderDeviceAnalytics?.mobileOrdersCount) > 0 ? t('increased_by') : t('decreased_by')}</span>
                      {wow && (devicesPercentage(wow.currentOrderDeviceAnalytics?.mobileOrdersCount,
                        wow.lastOrderDeviceAnalytics?.mobileOrdersCount) * 100).toFixed(2)}%
                    </div>
                  </dd>
                  <br />
                  <dd>
                    <div hidden className="text-sm">
                      <a href="/" className="font-medium text-indigo-600 hover:text-indigo-500">
                        {' '}
                        {t('view_all')}
                      </a>
                    </div>
                  </dd>
                </Spin>
              </div>
              <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
                <Spin spinning={loadingWow}>
                  <dt className="text-sm font-medium text-gray-500 truncate">{t('mobile_average')}</dt>
                  <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
                    <div className="flex items-baseline text-2xl font-semibold text-gray-900">
                      ${wow && (wow.currentOrderDeviceAnalytics?.mobileOrderAverage).toFixed(2)}
                      <span className="ml-2 text-sm font-medium text-gray-500">{t('from')} ${wow && (wow.lastOrderDeviceAnalytics?.mobileOrderAverage).toFixed(2)}</span>
                    </div>
                    <div
                      className={classNames(
                        wow && (wow.currentOrderDeviceAnalytics?.mobileOrderAverage / wow.lastOrderDeviceAnalytics?.mobileOrderAverage) === 0 ? 'text-black' : ((wow && wow.currentOrderDeviceAnalytics?.mobileOrderAverage / wow.lastOrderDeviceAnalytics?.mobileOrderAverage) > 0 ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800',
                        'inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium md:mt-2 lg:mt-0'
                        )
                      )}
                    >
                      {wow && wow.currentOrderDeviceAnalytics?.mobileOrderAverage === 0 ? (
                        <MinusOutlined
                          className="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-black"
                          aria-hidden="true"
                        />
                      ) :
                        (wow && (wow.currentOrderDeviceAnalytics?.mobileOrderAverage >
                        wow.lastOrderDeviceAnalytics?.mobileOrderAverage) ? (
                          <ArrowUpOutlined
                            className="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-green-500"
                            aria-hidden="true"
                          />
                          ) : (
                            <ArrowDownOutlined
                              className="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-red-500"
                              aria-hidden="true"
                            />
                          ))}
                      {wow && (devicesPercentage(
                        wow.currentOrderDeviceAnalytics?.mobileOrderAverage,
                        wow.lastOrderDeviceAnalytics?.mobileOrderAverage
                      ) * 100).toFixed(2)}%
                    </div>
                  </dd>
                  <br />
                  <dd>
                    <div hidden className="text-sm">
                      <a href="/" className="font-medium text-indigo-600 hover:text-indigo-500">
                        {' '}
                        {t('view_all')}
                      </a>
                    </div>
                  </dd>
                </Spin>
              </div>
              <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
                <Spin spinning={loadingWow}>
                  <dt className="text-sm font-medium text-gray-500 truncate">{t('web_orders')}</dt>
                  <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
                    <div className="flex items-baseline text-2xl font-semibold text-gray-900">
                      {wow && wow.currentOrderDeviceAnalytics?.webOrdersCount}
                      <span className="ml-2 text-sm font-medium text-gray-500">{t('from')} {wow && wow.lastOrderDeviceAnalytics?.webOrdersCount}</span>
                    </div>
                    <div
                      className={classNames(
                        wow && wow.currentOrderDeviceAnalytics?.webOrdersCount / wow.lastOrderDeviceAnalytics?.webOrdersCount === 0 ? 'text-black' : (wow && wow.currentOrderDeviceAnalytics?.webOrdersCount / wow.lastOrderDeviceAnalytics?.webOrdersCount > 0 ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800',
                        'inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium md:mt-2 lg:mt-0'
                        )
                      )}
                    >
                      {wow && wow.currentOrderDeviceAnalytics?.webOrdersCount /
                      wow.lastOrderDeviceAnalytics?.webOrdersCount === 0 ? (
                        <MinusOutlined
                          className="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-black"
                          aria-hidden="true"
                        />
                        ) :
                        (wow && wow.currentOrderDeviceAnalytics?.webOrdersCount /
                        wow.lastOrderDeviceAnalytics?.webOrdersCount > 0 ? (
                          <ArrowUpOutlined
                            className="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-green-500"
                            aria-hidden="true"
                          />
                          ) : (
                            <ArrowDownOutlined
                              className="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-red-500"
                              aria-hidden="true"
                            />
                          ))}
                      <span className="sr-only">{wow && wow.currentOrderDeviceAnalytics?.webOrdersCount / wow.lastOrderDeviceAnalytics?.webOrdersCount === 0 ? 'No growth' : (wow && wow.currentOrderDeviceAnalytics?.webOrdersCount / wow.lastOrderDeviceAnalytics?.webOrdersCount > 0 ? t('increased_by') : t('decreased_by'))}</span>
                      {wow && (wow.currentOrderDeviceAnalytics?.webOrdersCount /
                      wow.lastOrderDeviceAnalytics?.webOrdersCount).toFixed(2)}%
                    </div>
                  </dd>
                  <br />
                  <dd>
                    <div hidden className="text-sm">
                      <a href="/" className="font-medium text-indigo-600 hover:text-indigo-500">
                        {' '}
                        {t('view_all')}
                      </a>
                    </div>
                  </dd>
                </Spin>
              </div>
              <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
                <Spin spinning={loadingWow}>
                  <dt className="text-sm font-medium text-gray-500 truncate">{t('web_average')}</dt>
                  <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
                    <div className="flex items-baseline text-2xl font-semibold text-gray-900">
                      ${wow && (wow.currentOrderDeviceAnalytics?.webOrderAverage).toFixed(2)}
                      <span className="ml-2 text-sm font-medium text-gray-500">{t('from')} ${wow && (wow.lastOrderDeviceAnalytics?.webOrderAverage).toFixed(2)}</span>
                    </div>
                    <div
                      className={classNames(
                        wow && (wow.currentOrderDeviceAnalytics?.webOrderAverage / wow.lastOrderDeviceAnalytics?.webOrderAverage) === 0 ? 'text-black' : ((wow && wow.currentOrderDeviceAnalytics?.webOrderAverage / wow.lastOrderDeviceAnalytics?.webOrderAverage) > 0 ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800',
                        'inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium md:mt-2 lg:mt-0'
                        )
                      )}
                    >
                      {wow && wow.currentOrderDeviceAnalytics?.webOrderAverage === 0 ? (
                        <MinusOutlined
                          className="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-black"
                          aria-hidden="true"
                        />
                      ) :
                        (wow && (wow.currentOrderDeviceAnalytics?.webOrderAverage >
                        wow.lastOrderDeviceAnalytics?.webOrderAverage) ? (
                          <ArrowUpOutlined
                            className="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-green-500"
                            aria-hidden="true"
                          />
                          ) : (
                            <ArrowDownOutlined
                              className="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-red-500"
                              aria-hidden="true"
                            />
                          ))}
                      {wow && (devicesPercentage(
                        wow.currentOrderDeviceAnalytics?.webOrderAverage,
                        wow.lastOrderDeviceAnalytics?.webOrderAverage
                      ) * 100).toFixed(2)}%
                    </div>
                  </dd>
                  <br />
                  <dd>
                    <div hidden className="text-sm">
                      <a href="/" className="font-medium text-indigo-600 hover:text-indigo-500">
                        {' '}
                        {t('view_all')}
                      </a>
                    </div>
                  </dd>
                </Spin>
              </div>
            </dl>
          </div>
          <Divider />
          <div style={{ align: 'center' }} className="mb-12">
            <div>
              <h3 className="text-lg leading-6 font-medium text-gray-900">{t('payments')}</h3>
              <Typography.Text type="secondary">
                {t('payment_content')}
              </Typography.Text>
              <Spin spinning={loadingWow}>
                <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-4">
                  {wow && _.map(_.orderBy(wow?.payments, ['currentCount'], ['desc']), (item) => (
                    <div key={item.type} className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
                      <dd className="mt-1 flex items-baseline md:block lg:flex">
                        <dt className="text-sm font-medium text-gray-500 truncate">
                          {t(mapPaymentType(item.paymentType))}
                        </dt>
                        <div className="bg-blue-100 px-2.5 py-0.5 rounded-full self-center mt-1 ml-2 w-12 mr-0.5 flex-shrink-0">
                          <dt className="font-normal text-xs ml-1 h-5 w-5 text-blue-500">
                            {item.adoptionRate.toFixed(2)}%
                          </dt>
                        </div>
                      </dd>
                      <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
                        <div className="flex items-baseline text-2xl font-semibold text-gray-900">
                          {item?.currentCount?.toLocaleString()}
                          <span className="ml-2 text-sm font-medium text-gray-500">
                            from {item?.previousCount?.toLocaleString()}
                          </span>
                        </div>
                        <div
                          className={classNames(
                            item?.growth === 0 ? 'txt-black' : (item?.growth > 0 ? 'bg-green-100 text-green-800' : 'inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium md:mt-2 lg:mt-0'
                            )
                          )}
                        >
                          {item?.growth === 0 ? (
                            <MinusOutlined
                              className="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-black"
                              aria-hidden="true"
                            />
                          ) : (item?.growth > 0 ?
                            (
                              <ArrowUpOutlined
                                className="-ml-1 mr-0.5 flex-shrink-0 self-center
                                h-5 w-5 text-green-500"
                                aria-hidden="true"
                              />
                            ) : (
                              <ArrowDownOutlined
                                className="-ml-1 mr-0.5 flex-shrink-0 self-center
                                h-5 w-5 text-red-500"
                                aria-hidden="true"
                              />
                            ))}
                          <span className="sr-only">{item?.currentCount === item?.previousCount ? t('no_growth') : (item?.currentCount > item?.previousCount ?
                            t('increased_by') : t('decreased_by'))}
                          </span>
                          {item?.growth}%
                        </div>
                      </dd>
                    </div>
                  ))}
                </dl>
              </Spin>
            </div>
          </div>
          <Divider className={width < 1024 ? 'hidden' : null} />
          <div className={width < 1024 ? 'hidden' : null}>
            <div>
              <Row>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <SalesChart />
                </Col>
              </Row>
            </div>
            <Divider />
            <div>
              <Row>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <AdoptionChart />
                </Col>
              </Row>
            </div>
          </div>
          <Divider />
          <div style={{ align: 'center' }} className="mb-12">
            <div className={wow && wow.sections ? 'hidden' : 'hidden'}>
              <h3 className="text-lg leading-6 font-medium text-gray-900">{t('categories')}</h3>
              <Typography.Text type="secondary">
                {t('trends_category_description')}
              </Typography.Text>
              <Spin spinning={loadingWow}>
                <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-4">
                  {wow && _.map(_.orderBy(wow?.sections, ['currentSales'], ['desc']), (item) => (
                    <div key={item.type} className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
                      <dt className="text-sm font-medium text-gray-500 truncate">{item.name}</dt>
                      <div className="flex items-baseline text-2xl font-semibold text-gray-900">
                        {item?.currentSales?.toLocaleString()}
                        <span className="ml-2 text-sm font-medium text-gray-500">{t('from')} {item?.previousSales?.toLocaleString()}</span>
                      </div>
                      <div
                        className={classNames(
                          item?.growth === 0 ? 'text-black' : (item?.growth > 0 ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800',
                          'inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium md:mt-2 lg:mt-0'
                          )
                        )}
                      >
                        {item?.growth === 0 ? (
                          <MinusOutlined
                            className="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-black"
                            aria-hidden="true"
                          />
                        ) : (item?.growth > 0 ?
                          (
                            <ArrowUpOutlined
                              className="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-green-500"
                              aria-hidden="true"
                            />
                          ) : (
                            <ArrowDownOutlined
                              className="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-red-500"
                              aria-hidden="true"
                            />
                          ))}
                        <span className="sr-only">{item?.currentSales === item?.previousSales ? t('no_growth') : (item?.currentSales > item?.previousSales ? t('increased_by') : t('decreased_by'))}</span>
                        {item?.growth}%
                      </div>
                    </div>
                  ))}
                </dl>
              </Spin>
            </div>
          </div>
        </Tabs.TabPane>
        <Tabs.TabPane tab={t('month_over_month')} key="1">
          <div>
            <h3 className="text-lg leading-6 font-medium text-gray-900">{t('month_over_month')}</h3>
            <Typography.Text type="secondary">
              {t('trends_compare_mom')}{' ('}
              {mom && datesStringComparision(mom.startDate1,
                mom.endDate1)}{')'} vs  {' ('}{mom && datesStringComparision(mom.startDate,
                  mom.endDate)}{')'}
            </Typography.Text>
            <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
              <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
                <Spin spinning={loadingMom}>
                  <dt className="text-sm font-medium text-gray-500 truncate">{t('revenue')}</dt>
                  <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
                    <div className="flex items-baseline text-2xl font-semibold text-gray-900">
                      ${mom && thousand(mom.currentMonthSales)}
                      <span className="ml-2 text-sm font-medium text-gray-500">{t('from')} ${mom && thousand(mom.lastMonthSales)}</span>
                    </div>
                    <div
                      className={classNames(
                        mom && mom.growth === 0 ? 'text-black' : (mom && mom.growth > 0 ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800',
                        'inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium md:mt-2 lg:mt-0'
                        )
                      )}
                    >
                      {mom && mom.growth === 0 ? (
                        <MinusOutlined
                          className="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-black"
                          aria-hidden="true"
                        />
                      ) : (mom && mom.growth > 0 ? (
                        <ArrowUpOutlined
                          className="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-green-500"
                          aria-hidden="true"
                        />
                      ) : (
                        <ArrowDownOutlined
                          className="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-red-500"
                          aria-hidden="true"
                        />
                      ))}
                      <span className="sr-only">{mom && mom.growth === 0 ? t('no_growth') : (mom && mom.growth > 0 ? t('increased_by') : t('decreased_by'))}</span>
                      {mom && mom.growth}%
                    </div>
                  </dd>
                  <br />
                  <dd>
                    <div hidden className="text-sm">
                      <a href="/" className="font-medium text-indigo-600 hover:text-indigo-500">
                        {' '}
                        {t('view_more')}
                      </a>
                    </div>
                  </dd>
                </Spin>
              </div>
              <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
                <Spin spinning={loadingMom}>
                  <dt className="text-sm font-medium text-gray-500 truncate">{t('orders')}</dt>
                  <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
                    <div className="flex items-baseline text-2xl font-semibold text-gray-900">
                      {mom && mom.currentMonthOrderCount.toLocaleString()}
                      <span className="ml-2 text-sm font-medium text-gray-500">{t('from')} {mom && mom.lastMonthOrderCount.toLocaleString()}</span>
                    </div>
                    <div
                      className={classNames(
                        mom && mom.orderGrowth === 0 ? 'text-black' : (mom && mom.orderGrowth > 0 ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800',
                        'inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium md:mt-2 lg:mt-0'
                        )
                      )}
                    >
                      {mom && mom.orderGrowth === 0 ? (
                        <MinusOutlined
                          className="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-black"
                          aria-hidden="true"
                        />
                      ) : (mom && mom.orderGrowth > 0 ?
                        (
                          <ArrowUpOutlined
                            className="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-green-500"
                            aria-hidden="true"
                          />
                        ) : (
                          <ArrowDownOutlined
                            className="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-red-500"
                            aria-hidden="true"
                          />
                        ))}
                      <span className="sr-only">{mom && mom.orderGrowth === 0 ? t('no_growth') : (mom && mom.orderGrowth > 0 ? t('increased_by') : t('decreased_by'))}</span>
                      {mom && mom.orderGrowth}%
                    </div>
                  </dd>
                  <br />
                  <dd>
                    <div hidden className="text-sm">
                      <a href="/" className="font-medium text-indigo-600 hover:text-indigo-500">
                        {' '}
                        {t('view_more')}
                      </a>
                    </div>
                  </dd>
                </Spin>
              </div>
              <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
                <Spin spinning={loadingMom}>
                  <dt className="text-sm font-medium text-gray-500 truncate">{t('customers')}</dt>
                  <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
                    <div className="flex items-baseline text-2xl font-semibold text-gray-900">
                      {mom?.rollingCurrentMonthCustomerCount?.toLocaleString()}
                      <span className="ml-2 text-sm font-medium text-gray-500">{t('from')} {mom?.rollingLastMonthCustomerCount?.toLocaleString()}</span>
                    </div>
                    <div
                      className={classNames(
                        mom?.rollingLastMonthCustomerGrowth === 0 ? 'text-black' : (mom?.rollingLastMonthCustomerGrowth > 0 ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800',
                        'inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium md:mt-2 lg:mt-0'
                        )
                      )}
                    >
                      {mom?.rollingLastMonthCustomerGrowth === 0 ? (
                        <MinusOutlined
                          className="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-black"
                          aria-hidden="true"
                        />
                      ) : (mom?.rollingLastMonthCustomerGrowth > 0 ?
                        (
                          <ArrowUpOutlined
                            className="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-green-500"
                            aria-hidden="true"
                          />
                        ) : (
                          <ArrowDownOutlined
                            className="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-red-500"
                            aria-hidden="true"
                          />
                        ))}
                      <span className="sr-only">{mom?.rollingCurrentMonthCustomerCount === mom?.rollingLastMonthCustomerCount ? t('no_growth') : (mom?.rollingCurrentMonthCustomerCount > mom?.rollingLastMonthCustomerCount ? t('increased_by') : t('decreased_by'))}</span>
                      {mom?.rollingLastMonthCustomerGrowth}%
                    </div>
                  </dd>
                  <br />
                  <dd>
                    <div hidden className="text-sm">
                      <a href="/" className="font-medium text-indigo-600 hover:text-indigo-500">
                        {' '}
                        {t('view_more')}
                      </a>
                    </div>
                  </dd>
                </Spin>
              </div>
            </dl>
          </div>
          <br />
          <div>
            <h3 className="text-lg leading-6 font-medium text-gray-900">{t('devices')}</h3>
            <Typography.Text type="secondary">
              {t('trends_compare_devices_mom')}{' ('}
              {mom && datesStringComparision(mom.rollingCurrentMonthStartDate,
                mom.rollingCurrentMonthEndDate)}{')'} vs  {' ('}{mom && datesStringComparision(mom.rollingLastMonthStartDate,
                  mom.rollingLastMonthEndDate)}{')'}
            </Typography.Text>
            <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-4">
              <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
                <Spin spinning={loadingMom}>
                  <dt className="text-sm font-medium text-gray-500 truncate">{t('mobile_orders')}</dt>
                  <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
                    <div className="flex items-baseline text-2xl font-semibold text-gray-900">
                      {mom && mom.currentOrderDeviceAnalytics?.mobileOrdersCount}
                      <span className="ml-2 text-sm font-medium text-gray-500">{t('from')} {mom && mom.lastOrderDeviceAnalytics?.mobileOrdersCount}</span>
                    </div>
                    <div
                      className={classNames(
                        mom && (mom.currentOrderDeviceAnalytics?.mobileOrdersCount / mom.lastOrderDeviceAnalytics?.mobileOrdersCount) === 0 ? 'text-black' : ((mom && mom.currentOrderDeviceAnalytics?.mobileOrdersCount / mom.lastOrderDeviceAnalytics?.mobileOrdersCount) > 0 ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800',
                        'inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium md:mt-2 lg:mt-0'
                        )
                      )}
                    >
                      {mom && mom.currentOrderDeviceAnalytics?.mobileOrdersCount === 0 ? (
                        <MinusOutlined
                          className="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-black"
                          aria-hidden="true"
                        />
                      ) :
                        (mom && (mom.currentOrderDeviceAnalytics?.mobileOrdersCount >
                        mom.lastOrderDeviceAnalytics?.mobileOrdersCount) ? (
                          <ArrowUpOutlined
                            className="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-green-500"
                            aria-hidden="true"
                          />
                          ) : (
                            <ArrowDownOutlined
                              className="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-red-500"
                              aria-hidden="true"
                            />
                          ))}
                      {mom && (devicesPercentage(
                        mom.currentOrderDeviceAnalytics?.mobileOrdersCount,
                        mom.lastOrderDeviceAnalytics?.mobileOrdersCount
                      ) * 100).toFixed(2)}%
                    </div>
                  </dd>
                  <br />
                  <dd>
                    <div hidden className="text-sm">
                      <a href="/" className="font-medium text-indigo-600 hover:text-indigo-500">
                        {' '}
                        {t('view_all')}
                      </a>
                    </div>
                  </dd>
                </Spin>
              </div>
              <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
                <Spin spinning={loadingMom}>
                  <dt className="text-sm font-medium text-gray-500 truncate">{t('mobile_average')}</dt>
                  <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
                    <div className="flex items-baseline text-2xl font-semibold text-gray-900">
                      ${mom && (mom.currentOrderDeviceAnalytics?.mobileOrderAverage).toFixed(2)}
                      <span className="ml-2 text-sm font-medium text-gray-500">{t('from')} ${mom && (mom.lastOrderDeviceAnalytics?.mobileOrderAverage).toFixed(2)}</span>
                    </div>
                    <div
                      className={classNames(
                        mom && (mom.currentOrderDeviceAnalytics?.mobileOrderAverage / mom.lastOrderDeviceAnalytics?.mobileOrderAverage) === 0 ? 'text-black' : ((mom && mom.currentOrderDeviceAnalytics?.mobileOrderAverage / mom.lastOrderDeviceAnalytics?.mobileOrderAverage) > 0 ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800',
                        'inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium md:mt-2 lg:mt-0'
                        )
                      )}
                    >
                      {mom && mom.currentOrderDeviceAnalytics?.mobileOrderAverage === 0 ? (
                        <MinusOutlined
                          className="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-black"
                          aria-hidden="true"
                        />
                      ) :
                        (mom && (mom.currentOrderDeviceAnalytics?.mobileOrderAverage >
                        mom.lastOrderDeviceAnalytics?.mobileOrderAverage) ? (
                          <ArrowUpOutlined
                            className="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-green-500"
                            aria-hidden="true"
                          />
                          ) : (
                            <ArrowDownOutlined
                              className="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-red-500"
                              aria-hidden="true"
                            />
                          ))}
                      {mom && (devicesPercentage(
                        mom.currentOrderDeviceAnalytics?.mobileOrderAverage,
                        mom.lastOrderDeviceAnalytics?.mobileOrderAverage
                      ) * 100).toFixed(2)}%
                    </div>
                  </dd>
                  <br />
                  <dd>
                    <div hidden className="text-sm">
                      <a href="/" className="font-medium text-indigo-600 hover:text-indigo-500">
                        {' '}
                        {t('view_all')}
                      </a>
                    </div>
                  </dd>
                </Spin>
              </div>
              <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
                <Spin spinning={loadingWow}>
                  <dt className="text-sm font-medium text-gray-500 truncate">{t('web_orders')}</dt>
                  <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
                    <div className="flex items-baseline text-2xl font-semibold text-gray-900">
                      {mom && mom.currentOrderDeviceAnalytics?.webOrdersCount}
                      <span className="ml-2 text-sm font-medium text-gray-500">{t('from')} {mom && mom.lastOrderDeviceAnalytics?.webOrdersCount}</span>
                    </div>
                    <div
                      className={classNames(
                        mom && mom.currentOrderDeviceAnalytics?.webOrdersCount / mom.lastOrderDeviceAnalytics?.webOrdersCount === 0 ? 'text-black' : (mom && mom.currentOrderDeviceAnalytics?.webOrdersCount / mom.lastOrderDeviceAnalytics?.webOrdersCount > 0 ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800',
                        'inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium md:mt-2 lg:mt-0'
                        )
                      )}
                    >
                      {mom && mom.currentOrderDeviceAnalytics?.webOrdersCount === 0 ? (
                        <MinusOutlined
                          className="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-black"
                          aria-hidden="true"
                        />
                      ) :
                        (mom && mom.currentOrderDeviceAnalytics?.webOrdersCount >
                        mom.lastOrderDeviceAnalytics?.webOrdersCount ? (
                          <ArrowUpOutlined
                            className="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-green-500"
                            aria-hidden="true"
                          />
                          ) : (
                            <ArrowDownOutlined
                              className="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-red-500"
                              aria-hidden="true"
                            />
                          ))}
                      {mom && (devicesPercentage(mom.currentOrderDeviceAnalytics?.webOrdersCount,
                        mom.lastOrderDeviceAnalytics?.webOrdersCount) * 100).toFixed(2)}%
                    </div>
                  </dd>
                  <br />
                  <dd>
                    <div hidden className="text-sm">
                      <a href="/" className="font-medium text-indigo-600 hover:text-indigo-500">
                        {' '}
                        {t('view_all')}
                      </a>
                    </div>
                  </dd>
                </Spin>
              </div>
              <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
                <Spin spinning={loadingMom}>
                  <dt className="text-sm font-medium text-gray-500 truncate">{t('web_average')}</dt>
                  <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
                    <div className="flex items-baseline text-2xl font-semibold text-gray-900">
                      ${mom && (mom.currentOrderDeviceAnalytics?.webOrderAverage).toFixed(2)}
                      <span className="ml-2 text-sm font-medium text-gray-500">{t('from')} ${mom && (mom.lastOrderDeviceAnalytics?.webOrderAverage).toFixed(2)}</span>
                    </div>
                    <div
                      className={classNames(
                        mom && (mom.currentOrderDeviceAnalytics?.webOrderAverage / mom.lastOrderDeviceAnalytics?.webOrderAverage) === 0 ? 'text-black' : ((mom && mom.currentOrderDeviceAnalytics?.webOrderAverage / mom.lastOrderDeviceAnalytics?.webOrderAverage) > 0 ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800',
                        'inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium md:mt-2 lg:mt-0'
                        )
                      )}
                    >
                      {mom && mom.currentOrderDeviceAnalytics?.webOrderAverage === 0 ? (
                        <MinusOutlined
                          className="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-black"
                          aria-hidden="true"
                        />
                      ) :
                        (mom && (mom.currentOrderDeviceAnalytics?.webOrderAverage >
                        mom.lastOrderDeviceAnalytics?.webOrderAverage) ? (
                          <ArrowUpOutlined
                            className="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-green-500"
                            aria-hidden="true"
                          />
                          ) : (
                            <ArrowDownOutlined
                              className="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-red-500"
                              aria-hidden="true"
                            />
                          ))}
                      {mom && (devicesPercentage(
                        mom.currentOrderDeviceAnalytics?.webOrderAverage,
                        mom.lastOrderDeviceAnalytics?.webOrderAverage
                      ) * 100).toFixed(2)}%
                    </div>
                  </dd>
                  <br />
                  <dd>
                    <div hidden className="text-sm">
                      <a href="/" className="font-medium text-indigo-600 hover:text-indigo-500">
                        {' '}
                        {t('view_all')}
                      </a>
                    </div>
                  </dd>
                </Spin>
              </div>
            </dl>
          </div>
          <Divider />
          <div style={{ align: 'center' }} className="mb-12">
            <div>
              <h3 className="text-lg leading-6 font-medium text-gray-900">{t('payments')}</h3>
              <Typography.Text type="secondary">
                {t('payment_content')}
              </Typography.Text>
              <Spin spinning={loadingMom}>
                <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
                  {mom && _.map(_.orderBy(mom?.payments, ['currentCount'], ['desc']), (item) => (
                    <div key={item.type} className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
                      <dd className="mt-1 flex items-baseline md:block lg:flex">
                        <dt className="text-sm font-medium text-gray-500 truncate">
                          {t(mapPaymentType(item.paymentType))}
                        </dt>
                        <div className="bg-blue-100 px-2.5 py-0.5 rounded-full self-center mt-1 ml-2 w-12 mr-0.5 flex-shrink-0">
                          <dt className="font-normal text-xs ml-1 h-5 w-5 text-blue-500">
                            {item.adoptionRate.toFixed(2)}%
                          </dt>
                        </div>
                      </dd>
                      <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
                        <div className="flex items-baseline text-2xl font-semibold text-gray-900">
                          {item?.currentCount?.toLocaleString()}
                          <span className="ml-2 text-sm font-medium text-gray-500">
                            from {item?.previousCount?.toLocaleString()}
                          </span>
                        </div>
                        <div
                          className={classNames(
                            item?.growth === 0 ? 'text-black' : (item?.growth > 0 ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800', 'inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium md:mt-2 lg:mt-0'
                            )
                          )}
                        >
                          {item?.growth === 0 ? (
                            <MinusOutlined
                              className="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-black"
                              aria-hidden="true"
                            />
                          ) : (item?.growth > 0 ?
                            (
                              <ArrowUpOutlined
                                className="-ml-1 mr-0.5 flex-shrink-0 self-
                                center h-5 w-5 text-green-500"
                                aria-hidden="true"
                              />
                            ) : (
                              <ArrowDownOutlined
                                className="-ml-1 mr-0.5 flex-shrink-0 self-center
                                h-5 w-5 text-red-500"
                                aria-hidden="true"
                              />
                            ))}
                          <span className="sr-only">{item?.currentCount > item?.previousCount
                            ? t('increased_by') : t('decreased_by')}
                          </span>
                          {item?.growth}%
                        </div>
                      </dd>
                    </div>
                  ))}
                </dl>
              </Spin>
            </div>
          </div>
          <div style={{ align: 'center' }} className="mb-12">
            <div className={wow && wow.sections ? 'hidden' : 'hidden'}>
              <h3 className="text-lg leading-6 font-medium text-gray-900">{t('categories')}</h3>
              <Typography.Text type="secondary">
                {t('trends_category_description')}
              </Typography.Text>
              <Spin spinning={loadingMom}>
                <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
                  {mom && _.map(_.orderBy(mom?.sections, ['currentSales'], ['desc']), (item) => (
                    <div key={item.type} className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
                      <dt className="text-sm font-medium text-gray-500 truncate">{item.name}</dt>
                      <div className="flex items-baseline text-2xl font-semibold text-gray-900">
                        ${item?.currentSales?.toLocaleString()}
                        <span className="ml-2 text-sm font-medium text-gray-500">{t('from')} ${item?.previousSales?.toLocaleString()}</span>
                      </div>
                      <div
                        className={classNames(
                          item?.growth === 0 ? 'text-black' : (item?.growth > 0 ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800',
                          'inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium md:mt-2 lg:mt-0'
                          )
                        )}
                      >
                        {item?.growth === 0 ? (
                          <MinusOutlined
                            className="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-black"
                            aria-hidden="true"
                          />
                        ) : (item?.growth > 0 ?
                          (
                            <ArrowUpOutlined
                              className="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-green-500"
                              aria-hidden="true"
                            />
                          ) : (
                            <ArrowDownOutlined
                              className="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-red-500"
                              aria-hidden="true"
                            />
                          ))}
                        <span className="sr-only">{item?.currentSales === item?.previousSales ? t('no_growth') : (item?.currentSales > item?.previousSales ? t('increased_by') : t('decreased_by'))}</span>
                        {item?.growth}%
                      </div>
                    </div>
                  ))}
                </dl>
              </Spin>
            </div>
          </div>
        </Tabs.TabPane>
        <Tabs.TabPane tab={t('last_year')} key="2">
          <div>
            <h3 className="text-lg leading-6 font-medium text-gray-900">{t('last_year')}</h3>
            <Typography.Text type="secondary">
              {t('trends_compare_year')}{' ('}
              {yoy && datesStringComparision(yoy.startDate1,
                yoy.endDate1)}{')'} vs  {' ('}{yoy && datesStringComparision(yoy.startDate,
                  yoy.endDate)}{')'}
            </Typography.Text>
            <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
              <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
                <Spin spinning={loadingYoy}>
                  <dt className="text-sm font-medium text-gray-500 truncate">{t('revenue')}</dt>
                  <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
                    <div className="flex items-baseline text-2xl font-semibold text-gray-900">
                      ${yoy && thousand(yoy.currentMonthSales)}
                      <span className="ml-2 text-sm font-medium text-gray-500">{t('from')} ${yoy && thousand(yoy.lastMonthSales)}</span>
                    </div>
                    <div
                      className={classNames(
                        yoy && yoy.growth === 0 ? 'text-black' : (yoy && yoy.growth > 0 ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800',
                        'inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium md:mt-2 lg:mt-0'
                        )
                      )}
                    >
                      {yoy && yoy.growth === 0 ? (
                        <MinusOutlined
                          className="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-black"
                          aria-hidden="true"
                        />
                      ) : (yoy && yoy.growth > 0 ? (
                        <ArrowUpOutlined
                          className="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-green-500"
                          aria-hidden="true"
                        />
                      ) : (
                        <ArrowDownOutlined
                          className="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-red-500"
                          aria-hidden="true"
                        />
                      ))}
                      <span className="sr-only">{yoy && yoy.growth === 0 ? t('no_growth') : (yoy && yoy.growth > 0 ? t('increased_by') : t('decreased_by'))}</span>
                      {yoy && yoy.growth}%
                    </div>
                  </dd>
                  <br />
                  <dd>
                    <div hidden className="text-sm">
                      <a href="/" className="font-medium text-indigo-600 hover:text-indigo-500">
                        {' '}
                        {t('view_more')}
                      </a>
                    </div>
                  </dd>
                </Spin>
              </div>
              <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
                <Spin spinning={loadingYoy}>
                  <dt className="text-sm font-medium text-gray-500 truncate">{t('orders')}</dt>
                  <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
                    <div className="flex items-baseline text-2xl font-semibold text-gray-900">
                      {yoy && yoy.currentMonthOrderCount.toLocaleString()}
                      <span className="ml-2 text-sm font-medium text-gray-500">{t('from')} {yoy && yoy.lastMonthOrderCount.toLocaleString()}</span>
                    </div>
                    <div
                      className={classNames(
                        yoy && yoy.orderGrowth === 0 ? 'text-black' : (yoy && yoy.orderGrowth > 0 ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800',
                        'inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium md:mt-2 lg:mt-0'
                        )
                      )}
                    >
                      {yoy && yoy.orderGrowth === 0 ? (
                        <MinusOutlined
                          className="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-black"
                          aria-hidden="true"
                        />
                      ) : (yoy && yoy.orderGrowth > 0 ?
                        (
                          <ArrowUpOutlined
                            className="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-green-500"
                            aria-hidden="true"
                          />
                        ) : (
                          <ArrowDownOutlined
                            className="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-red-500"
                            aria-hidden="true"
                          />
                        ))}
                      <span className="sr-only">{yoy && yoy.orderGrowth === 0 ? t('no_growth') : (yoy && yoy.orderGrowth > 0 ? t('increased_by') : t('decreased_by'))}</span>
                      {yoy && yoy.orderGrowth}%
                    </div>
                  </dd>
                  <br />
                  <dd>
                    <div hidden className="text-sm">
                      <a href="/" className="font-medium text-indigo-600 hover:text-indigo-500">
                        {' '}
                        {t('view_more')}
                      </a>
                    </div>
                  </dd>
                </Spin>
              </div>
              <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
                <Spin spinning={loadingYoy}>
                  <dt className="text-sm font-medium text-gray-500 truncate">{t('customers')}</dt>
                  <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
                    <div className="flex items-baseline text-2xl font-semibold text-gray-900">
                      {yoy?.rollingCurrentMonthCustomerCount?.toLocaleString()}
                      <span className="ml-2 text-sm font-medium text-gray-500">{t('from')} {yoy?.rollingLastMonthCustomerCount?.toLocaleString()}</span>
                    </div>
                    <div
                      className={classNames(
                        yoy?.rollingLastMonthCustomerGrowth === 0 ? 'text-black' : (yoy?.rollingLastMonthCustomerGrowth > 0 ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800',
                        'inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium md:mt-2 lg:mt-0'
                        )
                      )}
                    >
                      {yoy?.rollingLastMonthCustomerGrowth === 0 ? (
                        <MinusOutlined
                          className="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-black"
                          aria-hidden="true"
                        />
                      ) : (yoy?.rollingLastMonthCustomerGrowth > 0 ?
                        (
                          <ArrowUpOutlined
                            className="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-green-500"
                            aria-hidden="true"
                          />
                        ) : (
                          <ArrowDownOutlined
                            className="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-red-500"
                            aria-hidden="true"
                          />
                        ))}
                      <span className="sr-only">{yoy?.rollingCurrentMonthCustomerCount === yoy?.rollingLastMonthCustomerCount ? t('no_growth') : (yoy?.rollingCurrentMonthCustomerCount > yoy?.rollingLastMonthCustomerCount ? t('increased_by') : t('decreased_by'))}</span>
                      {yoy?.rollingLastMonthCustomerGrowth}%
                    </div>
                  </dd>
                  <br />
                  <dd>
                    <div hidden className="text-sm">
                      <a href="/" className="font-medium text-indigo-600 hover:text-indigo-500">
                        {' '}
                        {t('view_more')}
                      </a>
                    </div>
                  </dd>
                </Spin>
              </div>
            </dl>
          </div>
          <br />
          <div>
            <h3 className="text-lg leading-6 font-medium text-gray-900">{t('devices')}</h3>
            <Typography.Text type="secondary">
              {t('trends_compare_devices_yoy')}{' ('}
              {yoy && datesStringComparision(yoy.rollingCurrentMonthStartDate,
                yoy.rollingCurrentMonthEndDate)}{')'} vs  {' ('}{yoy && datesStringComparision(yoy.rollingLastMonthStartDate,
                  yoy.rollingLastMonthEndDate)}{')'}
            </Typography.Text>
            <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-4">
              <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
                <Spin spinning={loadingWow}>
                  <dt className="text-sm font-medium text-gray-500 truncate">{t('mobile_orders')}</dt>
                  <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
                    <div className="flex items-baseline text-2xl font-semibold text-gray-900">
                      {yoy && yoy.currentOrderDeviceAnalytics?.mobileOrdersCount}
                      <span className="ml-2 text-sm font-medium text-gray-500">{t('from')} {yoy && yoy.lastOrderDeviceAnalytics?.mobileOrdersCount}</span>
                    </div>
                    <div
                      className={classNames(
                        yoy && devicesPercentage(yoy.currentOrderDeviceAnalytics?.mobileOrdersCount, yoy.lastOrderDeviceAnalytics?.mobileOrdersCount) === 0 ? 'text-black' : (yoy && devicesPercentage(yoy.currentOrderDeviceAnalytics?.mobileOrdersCount, yoy.lastOrderDeviceAnalytics?.mobileOrdersCount) > 0 ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800',
                        'inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium md:mt-2 lg:mt-0'
                        )
                      )}
                    >
                      {yoy && devicesPercentage(yoy.currentOrderDeviceAnalytics?.mobileOrdersCount,
                        yoy.lastOrderDeviceAnalytics?.mobileOrdersCount) === 0 ? (
                          <MinusOutlined
                            className="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-black"
                            aria-hidden="true"
                          />
                        ) :
                        (yoy && devicesPercentage(
                          yoy.currentOrderDeviceAnalytics?.mobileOrdersCount,
                          yoy.lastOrderDeviceAnalytics?.mobileOrdersCount
                        ) > 0 ? (
                          <ArrowUpOutlined
                            className="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-green-500"
                            aria-hidden="true"
                          />
                          ) : (
                            <ArrowDownOutlined
                              className="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-red-500"
                              aria-hidden="true"
                            />
                          ))}
                      <span className="sr-only">{yoy && devicesPercentage(yoy.currentOrderDeviceAnalytics?.mobileOrdersCount, yoy.lastOrderDeviceAnalytics?.mobileOrdersCount) > 0 ? t('increased_by') : t('decreased_by')}</span>
                      {yoy && (devicesPercentage(yoy.currentOrderDeviceAnalytics?.mobileOrdersCount,
                        yoy.lastOrderDeviceAnalytics?.mobileOrdersCount) * 100).toFixed(2)}%
                    </div>
                  </dd>
                  <br />
                  <dd>
                    <div hidden className="text-sm">
                      <a href="/" className="font-medium text-indigo-600 hover:text-indigo-500">
                        {' '}
                        {t('view_all')}
                      </a>
                    </div>
                  </dd>
                </Spin>
              </div>
              <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
                <Spin spinning={loadingYoy}>
                  <dt className="text-sm font-medium text-gray-500 truncate">{t('mobile_average')}</dt>
                  <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
                    <div className="flex items-baseline text-2xl font-semibold text-gray-900">
                      ${yoy && (yoy.currentOrderDeviceAnalytics?.mobileOrderAverage).toFixed(2)}
                      <span className="ml-2 text-sm font-medium text-gray-500">{t('from')} ${yoy && (yoy.lastOrderDeviceAnalytics?.mobileOrderAverage).toFixed(2)}</span>
                    </div>
                    <div
                      className={classNames(
                        yoy && (yoy.currentOrderDeviceAnalytics?.mobileOrderAverage / yoy.lastOrderDeviceAnalytics?.mobileOrderAverage) === 0 ? 'text-black' : ((yoy && yoy.currentOrderDeviceAnalytics?.mobileOrderAverage / yoy.lastOrderDeviceAnalytics?.mobileOrderAverage) > 0 ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800',
                        'inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium md:mt-2 lg:mt-0'
                        )
                      )}
                    >
                      {yoy && yoy.currentOrderDeviceAnalytics?.mobileOrderAverage === 0 ? (
                        <MinusOutlined
                          className="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-black"
                          aria-hidden="true"
                        />
                      ) :
                        (yoy && (yoy.currentOrderDeviceAnalytics?.mobileOrderAverage >
                        yoy.lastOrderDeviceAnalytics?.mobileOrderAverage) ? (
                          <ArrowUpOutlined
                            className="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-green-500"
                            aria-hidden="true"
                          />
                          ) : (
                            <ArrowDownOutlined
                              className="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-red-500"
                              aria-hidden="true"
                            />
                          ))}
                      {yoy && (devicesPercentage(
                        yoy.currentOrderDeviceAnalytics?.mobileOrderAverage,
                        yoy.lastOrderDeviceAnalytics?.mobileOrderAverage
                      ) * 100).toFixed(2)}%
                    </div>
                  </dd>
                  <br />
                  <dd>
                    <div hidden className="text-sm">
                      <a href="/" className="font-medium text-indigo-600 hover:text-indigo-500">
                        {' '}
                        {t('view_all')}
                      </a>
                    </div>
                  </dd>
                </Spin>
              </div>
              <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
                <Spin spinning={loadingYoy}>
                  <dt className="text-sm font-medium text-gray-500 truncate">{t('web_orders')}</dt>
                  <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
                    <div className="flex items-baseline text-2xl font-semibold text-gray-900">
                      {yoy && yoy.currentOrderDeviceAnalytics?.webOrdersCount}
                      <span className="ml-2 text-sm font-medium text-gray-500">{t('from')} {yoy && yoy.lastOrderDeviceAnalytics?.webOrdersCount}</span>
                    </div>
                    <div
                      className={classNames(
                        yoy && yoy.currentOrderDeviceAnalytics?.webOrdersCount / yoy.lastOrderDeviceAnalytics?.webOrdersCount === 0 ? 'text-black' : (yoy && yoy.currentOrderDeviceAnalytics?.webOrdersCount / yoy.lastOrderDeviceAnalytics?.webOrdersCount > 0 ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800',
                        'inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium md:mt-2 lg:mt-0'
                        )
                      )}
                    >
                      {yoy && yoy.currentOrderDeviceAnalytics?.webOrdersCount /
                      yoy.lastOrderDeviceAnalytics?.webOrdersCount === 0 ? (
                        <MinusOutlined
                          className="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-black"
                          aria-hidden="true"
                        />
                        ) :
                        (yoy && yoy.currentOrderDeviceAnalytics?.webOrdersCount /
                        yoy.lastOrderDeviceAnalytics?.webOrdersCount > 0 ? (
                          <ArrowUpOutlined
                            className="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-green-500"
                            aria-hidden="true"
                          />
                          ) : (
                            <ArrowDownOutlined
                              className="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-red-500"
                              aria-hidden="true"
                            />
                          ))}
                      <span className="sr-only">{yoy && yoy.currentOrderDeviceAnalytics?.webOrdersCount / yoy.lastOrderDeviceAnalytics?.webOrdersCount === 0 ? 'No growth' : (yoy && yoy.currentOrderDeviceAnalytics?.webOrdersCount / yoy.lastOrderDeviceAnalytics?.webOrdersCount > 0 ? t('increased_by') : t('decreased_by'))}</span>
                      {yoy && (devicesPercentage(yoy.currentOrderDeviceAnalytics?.webOrdersCount,
                        yoy.lastOrderDeviceAnalytics?.webOrdersCount) * 100).toFixed(2)}%
                    </div>
                  </dd>
                  <br />
                  <dd>
                    <div hidden className="text-sm">
                      <a href="/" className="font-medium text-indigo-600 hover:text-indigo-500">
                        {' '}
                        {t('view_all')}
                      </a>
                    </div>
                  </dd>
                </Spin>
              </div>
              <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
                <Spin spinning={loadingYoy}>
                  <dt className="text-sm font-medium text-gray-500 truncate">{t('web_average')}</dt>
                  <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
                    <div className="flex items-baseline text-2xl font-semibold text-gray-900">
                      ${yoy && (yoy.currentOrderDeviceAnalytics?.webOrderAverage).toFixed(2)}
                      <span className="ml-2 text-sm font-medium text-gray-500">{t('from')} ${yoy && (yoy.lastOrderDeviceAnalytics?.webOrderAverage).toFixed(2)}</span>
                    </div>
                    <div
                      className={classNames(
                        yoy && (yoy.currentOrderDeviceAnalytics?.webOrderAverage / yoy.lastOrderDeviceAnalytics?.webOrderAverage) === 0 ? 'text-black' : ((yoy && yoy.currentOrderDeviceAnalytics?.webOrderAverage / yoy.lastOrderDeviceAnalytics?.webOrderAverage) > 0 ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800',
                        'inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium md:mt-2 lg:mt-0'
                        )
                      )}
                    >
                      {yoy && yoy.currentOrderDeviceAnalytics?.webOrderAverage === 0 ? (
                        <MinusOutlined
                          className="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-black"
                          aria-hidden="true"
                        />
                      ) :
                        (yoy && (yoy.currentOrderDeviceAnalytics?.webOrderAverage >
                        yoy.lastOrderDeviceAnalytics?.webOrderAverage) ? (
                          <ArrowUpOutlined
                            className="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-green-500"
                            aria-hidden="true"
                          />
                          ) : (
                            <ArrowDownOutlined
                              className="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-red-500"
                              aria-hidden="true"
                            />
                          ))}
                      {yoy && (devicesPercentage(
                        yoy.currentOrderDeviceAnalytics?.webOrderAverage,
                        yoy.lastOrderDeviceAnalytics?.webOrderAverage
                      ) * 100).toFixed(2)}%
                    </div>
                  </dd>
                  <br />
                  <dd>
                    <div hidden className="text-sm">
                      <a href="/" className="font-medium text-indigo-600 hover:text-indigo-500">
                        {' '}
                        {t('view_all')}
                      </a>
                    </div>
                  </dd>
                </Spin>
              </div>
            </dl>
          </div>
          <Divider />
          <div style={{ align: 'center' }} className="mb-12">
            <div>
              <h3 className="text-lg leading-6 font-medium text-gray-900">{t('payments')}</h3>
              <Typography.Text type="secondary">
                {t('payment_content')}
              </Typography.Text>
              <Spin spinning={loadingYoy}>
                <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
                  {yoy && _.map(_.orderBy(yoy?.payments, ['currentCount'], ['desc']), (item) => (
                    <div key={item.type} className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
                      <dd className="mt-1 flex items-baseline md:block lg:flex">
                        <dt className="text-sm font-medium text-gray-500 truncate">
                          {t(mapPaymentType(item.paymentType))}
                        </dt>
                        <div className="bg-blue-100 px-2.5 py-0.5 rounded-full self-center mt-1 ml-2 w-12 mr-0.5 flex-shrink-0">
                          <dt className="font-normal text-xs ml-1 h-5 w-5 text-blue-500">
                            {item.adoptionRate.toFixed(2)}%
                          </dt>
                        </div>
                      </dd>
                      <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
                        <div className="flex items-baseline text-2xl font-semibold text-gray-900">
                          {item?.currentCount?.toLocaleString()}
                          <span className="ml-2 text-sm font-medium text-gray-500">
                            from {item?.previousCount?.toLocaleString()}
                          </span>
                        </div>
                        <div
                          className={classNames(
                            item?.growth === 0 ? 'text-black' : (item?.growth > 0 ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800', 'inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium md:mt-2 lg:mt-0'
                            )
                          )}
                        >
                          {item?.growth === 0 ? (
                            <MinusOutlined
                              className="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-black"
                              aria-hidden="true"
                            />
                          ) : (item?.growth > 0 ?
                            (
                              <ArrowUpOutlined
                                className="-ml-1 mr-0.5 flex-shrink-0 self-
                                center h-5 w-5 text-green-500"
                                aria-hidden="true"
                              />
                            ) : (
                              <ArrowDownOutlined
                                className="-ml-1 mr-0.5 flex-shrink-0 self-center
                                h-5 w-5 text-red-500"
                                aria-hidden="true"
                              />
                            ))}
                          <span className="sr-only">{item?.currentCount === item?.previousCount ? t('no_growth') : (item?.currentCount > item?.previousCount
                            ? t('increased_by') : t('decreased_by'))}
                          </span>
                          {item?.growth}%
                        </div>
                      </dd>
                    </div>
                  ))}
                </dl>
              </Spin>
            </div>
          </div>
          <div style={{ align: 'center' }} className="mb-12">
            <div className={wow && wow.sections ? 'hidden' : 'hidden'}>
              <h3 className="text-lg leading-6 font-medium text-gray-900">{t('categories')}</h3>
              <Typography.Text type="secondary">
                {t('trends_category_description')}
              </Typography.Text>
              <Spin spinning={loadingYoy}>
                <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
                  {yoy && _.map(_.orderBy(yoy?.sections, ['currentSales'], ['desc']), (item) => (
                    <div key={item.type} className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
                      <dt className="text-sm font-medium text-gray-500 truncate">{item.name}</dt>
                      <div className="flex items-baseline text-2xl font-semibold text-gray-900">
                        ${item?.currentSales?.toLocaleString()}
                        <span className="ml-2 text-sm font-medium text-gray-500">{t('from')} ${item?.previousSales?.toLocaleString()}</span>
                      </div>
                      <div
                        className={classNames(
                          item?.growth === 0 ? 'text-black' : (item?.growth > 0 ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800',
                          'inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium md:mt-2 lg:mt-0'
                          )
                        )}
                      >
                        {item?.growth === 0 ? (
                          <MinusOutlined
                            className="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-black"
                            aria-hidden="true"
                          />
                        ) : (item?.growth > 0 ?
                          (
                            <ArrowUpOutlined
                              className="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-green-500"
                              aria-hidden="true"
                            />
                          ) : (
                            <ArrowDownOutlined
                              className="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-red-500"
                              aria-hidden="true"
                            />
                          ))}
                        <span className="sr-only">{item?.currentSales === item?.previousSales ? t('no_growth') : (item?.currentSales > item?.previousSales ? t('increased_by') : t('decreased_by'))}</span>
                        {item?.growth}%
                      </div>
                    </div>
                  ))}
                </dl>
              </Spin>
            </div>
          </div>
        </Tabs.TabPane>
      </Tabs>
      <br />
    </div>
  );
}

export default Trends;
