import axios from 'axios';
import envConfig from '../envConfig';

function newAbortSignal(timeout) {
  const abortController = new AbortController();
  setTimeout(() => abortController.abort(), timeout || 0);

  return abortController.signal;
}

const api = axios.create({
  baseURL: envConfig.REACT_APP_API_URL,
  headers: {
    'Ocp-Apim-Subscription-Key': envConfig.REACT_APP_API_TOKEN,
    'x-api-utility-key': envConfig.REACT_APP_UTILITY_KEY,
    'x-api-payments-key': envConfig.REACT_APP_PAYMENTS_KEY
  },
  timeout: 2700000,
  signal: newAbortSignal(2700000)
});

api.interceptors.request.use(function (config) {
  const tempLang = `${window.localStorage.getItem(`${envConfig.REACT_APP_PREFIX}-lang`)}-US`.replaceAll('"', '');
  config.headers['Accept-Language'] = tempLang !== 'null-US' ? tempLang : 'en-US';
  config.headers.Authorization = `Bearer ${window.localStorage.getItem(`${envConfig.REACT_APP_PREFIX}-admin-token`)}`;
  return config;
}, function (error) {
  return Promise.reject(error);
});

api.interceptors.response.use(
  (response) => response,
  (error) => {
    console.log(error);
    if (error?.response?.status === 401) {
      window.localStorage.removeItem(`${envConfig.REACT_APP_PREFIX}-admin-currentstate`);
      window.localStorage.removeItem(`${envConfig.REACT_APP_PREFIX}-admin-token`);
      window.localStorage.removeItem(`${envConfig.REACT_APP_PREFIX}-admin-expire`);
      window.localStorage.removeItem(`${envConfig.REACT_APP_PREFIX}-admin-profile`);
      window.location = '/';
      return null;
    }
    return Promise.reject(error);
  }
);

export default api;
