import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import {
  useRecoilValue,
  useSetRecoilState,
  useRecoilState,
  useRecoilValueLoadable,
} from 'recoil';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import {
  Input,
  Table,
  Typography,
  Descriptions,
  Avatar,
  Tooltip,
  Button,
  Space,
  Popover,
  Tabs,
  Select,
  Switch,
  Row,
  Col,
  Spin,
  Divider,
  Image,
  notification,
  Tag,
} from 'antd';
import { Comment } from '@ant-design/compatible';
import { useTranslation } from 'react-i18next';
import {
  AimOutlined,
  BellTwoTone,
  ClockCircleOutlined,
  ClockCircleTwoTone,
  FileSearchOutlined,
  FilterOutlined,
  MailTwoTone,
  OrderedListOutlined,
  PhoneOutlined,
  RocketTwoTone,
  SearchOutlined,
  SortAscendingOutlined,
  SyncOutlined,
} from '@ant-design/icons';
import {
  orderEditAtom,
  ordersAtom,
  orderItemsAtom,
  businessAtom,
  timezoneAtom,
  profileAtom,
  selectedOrderAtom,
  reloadOrder,
} from '../../../../atoms/Atoms';
import {
  renderDate,
  renderShortDateTime,
  renderShortDateHour,
  formatPhoneNumber,
  mapPaymentType,
  mapOrderType,
  mapOrderStatus,
  thousand,
  convertTaxRule,
} from '../../../utils/functions';
import { paymentOptionsSelector } from '../../../../atoms/Selectors';
import Grocefy from '../../../../assets/images/grocefyLogoAlone.png';
import OrderActions from '../../../shared-components/drawers/OrderActions';
import api from '../../../../api/api';
import Panel from '../../../shared-components/panel/Panel';

function Orders() {
  const [reload, setReload] = useRecoilState(reloadOrder);
  const [orderHeader, setOrderHeader] = useState(null);
  const [inStoreOrderHeader, setInStoreOrderHeader] = useState(null);
  const [sorting, setSorting] = useState('desc');
  const [current, setCurrent] = useState(0);
  const [loadingOrderHeader, setLoadingOrderHeader] = useState(false);
  const { Option } = Select;
  const { t } = useTranslation();
  const statusFilters = [
    { name: t('all'), tag: '' },
    { name: t('active'), tag: 'ACTIVE' },
    { name: t('placed'), tag: 'PLACED' },
    { name: t('processing'), tag: 'INPROCESS' },
    { name: t('processing_picking'), tag: 'PICKING' },
    { name: t('processing_packing'), tag: 'PACKING' },
    { name: t('waiting'), tag: 'WAITING' },
    { name: t('in_transit'), tag: 'INTRANSIT' },
    { name: t('completed'), tag: 'DISPATCHED' },
    { name: t('cancelled'), tag: 'CANCELLED' },
    { name: t('payment_failed'), tag: 'PAYMENTFAILED' },
    { name: t('pending'), tag: 'PENDING' },
    // { name: t('approved'), tag: 'APPROVED' }
  ];
  const timeFilters = [
    { name: t('any'), tag: '' },
    { name: t('past'), tag: 'PAST' },
    { name: t('yesterday'), tag: 'YESTERDAY' },
    { name: t('today'), tag: 'TODAY' },
    { name: t('tomorrow'), tag: 'TOMORROW' },
    { name: t('future'), tag: 'FUTURE' },
  ];
  const typeFilters = [
    { name: t('any'), tag: '' },
    { name: t('pickup'), tag: 'PICKUP' },
    { name: t('delivery'), tag: 'DELIVERY' },
    { name: t('in-Store'), tag: 'INSTORE' },
  ];

  const store = useRecoilValue(businessAtom);
  const timezone = useRecoilValue(timezoneAtom);
  const paymentOptions = useRecoilValueLoadable(paymentOptionsSelector);
  const [editOrder, setEditOrder] = useRecoilState(orderEditAtom);
  const [selectedOrder, setSelectedOrder] = useRecoilState(selectedOrderAtom);
  const setOrder = useSetRecoilState(ordersAtom);
  const [statusFilter, setStatusFilter] = useState('');
  const [typeFilter, setTypeFilter] = useState('');
  const [paymentFilter, setPaymentFilter] = useState('');
  const [timeFilter, setTimeFilter] = useState('');
  const [loadingItems, setLoadingItems] = useState(false);
  const [orderItems, setOrderItems] = useRecoilState(orderItemsAtom);
  const [loadingLogs, setLoadingLogs] = useState(false);
  const [logs, setLogs] = useState(null);
  const [orders, setOrders] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [lastSearch, setLastSearch] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const [currentLogPage, setCurrentLogPage] = useState(0);
  const [size, setSize] = useState(20);
  const [totalItemsInSearch, setTotalItemsInSearch] = useState(0);
  const [totalRevenueInSearch, setTotalRevenueInSearch] = useState(0);
  const [onScreenTotal, setOnScreenTotal] = useState(0);

  const userProfile = useRecoilValueLoadable(profileAtom);
  const isAdmin =
    userProfile.contents &&
    userProfile?.contents?.roles?.includes('SuperAdmin');
  const isCustomerService =
    isAdmin ||
    (userProfile?.contents &&
      (userProfile?.contents?.roles?.includes('CustomerSupportAdmin') ||
        userProfile?.contents?.roles?.includes('CustomerSupport')));
  const isStoreManager =
    isAdmin ||
    isCustomerService ||
    (userProfile?.contents &&
      (userProfile?.contents?.roles?.includes('StoreManager') ||
        userProfile?.contents?.roles?.includes('StoreAdmin')));
  const history = useHistory();
  const [visibleSignature, setVisibleSignature] = useState(false);
  const [visibleSignatureImg, setVisibleSignatureImage] = useState(null);
  const [visibleEbt, setVisibleEbt] = useState(false);
  const [visibleEbtImg, setVisibleEbtImage] = useState(null);
  const [scaleStep, setScaleStep] = useState(0.5);
  const [inStoreOrders, setInstoreOrders] = useState(null);
  const [onlineOrders, setOnlineOrders] = useState(null);

  if (!isAdmin && !isCustomerService && !isStoreManager) {
    let hasProfile = false;
    for (let i = 0; i < userProfile?.contents?.businessProfiles?.length; i++) {
      if (store.id === userProfile?.contents?.businessProfiles[i].businessId) {
        hasProfile = true;
      }
    }
    if (!hasProfile) {
      history.push('/');
      return null;
    }
  }

  const showMessage = function (message) {
    notification.open({
      message: '',
      description: (
        <Comment
          author={<span>Grocefy</span>}
          avatar={<Avatar src={Grocefy} alt="grocefy" />}
          content={<p className="text-sm">{message}</p>}
          datetime={
            <Tooltip title={moment().format('YYYY-MM-DD HH:mm:ss')}>
              <span>{moment().fromNow()}</span>
            </Tooltip>
          }
        />
      ),
    });
  };

  function mapData(data) {
    let instore = null;
    let online = null;
    const mapped = _.map(data, (p, index) => ({
      key: index,
      id: p.id,
      itemCount: p.itemCount,
      storeId: p.businessId,
      locationId: p.locationId,
      orderType: p.type,
      deliveryZone: p.deliveryZoneName,
      createdAt: renderShortDateTime(p.actualTime, timezone),
      cancelledAt: renderShortDateTime(p.cancellationDate, timezone),
      createdDiff: moment(`${p.createdAt}+0000`).tz(timezone).fromNow(),
      dispatchDiff:
        p.dispatchDateTime == null
          ? 'N/A'
          : moment(`${p.dispatchDateTime}+0000`).tz(timezone).fromNow(),
      businessName: p.businessName,
      type: t(mapOrderType(p.type)),
      typeEnum: p.type,
      confirmationCode: p.confirmationCode,
      customer: p.customerName,
      customerId: p.customerId,
      customerPhone: formatPhoneNumber(p.customerPhone),
      customerLocationId: p.customerLocationId,
      ivuCN: p.ivuControlNumber === null ? 'N/A' : p.ivuControlNumber,
      statusText: mapOrderStatus(p.status),
      cardNickname: p.cardNickname,
      nickName: p.nickName,
      cardholderName: p.cardholderName,
      status: p.status,
      paymentTypeText: t(mapPaymentType(p.paymentType)),
      paymentType: p.paymentType,
      desiredDateTime: renderShortDateHour(p.desiredDateTime, timezone),
      date: renderShortDateHour(p.date, timezone),
      dDateTime: p.desiredDateTime,
      dispatchDateTime: renderDate(p.dispatchDateTime, timezone),
      total: p.orderTotal.toFixed(2),
      orderTotal: p.orderTotal,
      subTotal: thousand(p.productsTotal),
      munTax: p.municipalTax.toFixed(2),
      stateTax: p.stateTax.toFixed(2),
      deliveryFee: p.deliveryTotal.toFixed(2),
      pickupFee: p.processingFee.toFixed(2),
      transferTotal: p.transferTotal.toFixed(2),
      processingFee: p.processingFee.toFixed(2),
      transferCompletedText: p.transferCompleted ? 'Yes' : 'No',
      transferCompleted: p.transferCompleted,
      authTotal: p.authTotal.toFixed(2),
      returnTotal: p.returnTotal.toFixed(2),
      credit:
        p.authTotal - p.orderTotal
          ? (p.authTotal - p.orderTotal).toFixed(2)
          : '0.00',
      referenceNumber:
        p.referenceNumber || p.referenceNumber !== ''
          ? p.referenceNumber
          : 'n/a',
      refNumAvailable: p.referenceNumber !== '' && p.referenceNumber !== null,
      discountAmount: p.discountAmount.toFixed(2),
      businessDescription: p.businessDescription,
      returnNeeded: p.returnNeeded ? 'Yes' : 'No',
      returnCompleted: p.returnCompleted ? 'Yes' : 'No',
      athReturnError: p.athReturnError ? 'Yes' : 'No',
      wasAlreadyCharged: p.hasBeenCharged ? 'Yes' : 'No',
      wasAlreadyAuthed: p.hasBeenAuthed ? 'Yes' : 'No',
      hasBeenCharged: p.hasBeenCharged,
      hasBeenAuthed: p.hasBeenAuthed,
      items: p.items,
      signatureImageUrl: p.signatureImageUrl,
      ebtReceiptUrl: p.ebtReceiptUrl,
      pickUpName: p.pickUpName,
      customerLocation: p.customerLocation,
      paymentTender: p.paymentTender,
      posLaneTag: p.posLaneTag,
    }));
    instore = _.filter(mapped, (o) => o.typeEnum === 5);
    online = _.filter(mapped, (o) => o.typeEnum === 0 || o.typeEnum === 1);

    setInstoreOrders(instore);
    setOnlineOrders(online);

    return mapped;
  }

  function addValues(arr) {
    const result = _.sumBy(arr, function (o) {
      return o.orderTotal;
    });
    setOnScreenTotal(result.toFixed(2));
  }

  function mapLogs(data) {
    return _.map(data, (p) => ({
      key: p.id,
      approvedId: p.approvedId,
      createdAt: moment(`${p.createdAt}+0000`)
        .tz(timezone)
        .format('MMMM Do YYYY, h:mm:ss a'),
      description: p.description,
      id: p.id,
      modifiedDate: moment(`${p.modifiedDate}+0000`)
        .tz(timezone)
        .format('MMMM Do YYYY, h:mm:ss a'),
      orderId: p.orderId,
      postStatus: mapOrderStatus(p.postStatus),
      preStatus: mapOrderStatus(p.preStatus),
    }));
  }

  function mapOrderItems(data) {
    const sorted = _.map(data, (p) => ({
      key: p.id,
      id: p.id,
      completeName: p.completeName,
      name: p.name,
      brand: p.brand,
      amount: p.amount,
      upc: p.upc,
      aisle: p.aisle,
      price: p.price,
      description: p.description,
      extendedPrice: p.extendedPrice,
      itemImage: p.itemImage,
      hasRandomWeight: p.hasRandomWeight,
      sectionName: p.sectionName,
      isAlcoholicBeverage: p.isAlcoholicBeverage,
      isPacked: p.isPacked,
      isPerishable: p.isPerishable,
      isVoided: p.isVoided,
      manuallyAdded: p.manuallyAdded,
      acceptsSubstitutes: p.acceptsSubstitutes,
      taxRule: t(convertTaxRule(p.taxRule)),
    }));
    return _.sortBy(sorted, [(o) => o.isVoided]);
  }

  function loadLogs(row) {
    setLoadingLogs(true);
    api
      .get(`orders/${row.id}/logs`)
      .then((response) => {
        const map = mapLogs(response.data.data.results);
        setLogs(map);
        setLoadingLogs(false);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  function loadItems(record) {
    api
      .get(`orders/${record.id}/items/all`)
      .then((response) => {
        const map = mapOrderItems(response.data.data.results);
        setOrderItems(map);
        setLoadingItems(false);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  function load(record) {
    loadItems(record);
    loadLogs(record);
  }

  // function setStatusColor(status) {
  //   if (
  //     status === 'Cancelled by Store' ||
  //     status === 'Cancelled by Customer' ||
  //     status === 'Pending Payment'
  //   ) {
  //     return 'danger';
  //   }
  //   if (status === 'Ready for Picking' || status === 'Delivery Picked Up') {
  //     return 'warning';
  //   }
  //   if (status === 'Delivered' || status === 'Customer Picked Up') {
  //     return 'success';
  //   }
  //   return 'default';
  // }

  function getOrderHeader() {
    if (!loadingOrderHeader) {
      const data = {
        filterString: '',
        ecommerceOrders: true,
      };
      data.filterString = `${data.filterString.trim()} ${statusFilter}`;
      data.filterString = `${data.filterString.trim()} ${timeFilter}`;
      data.filterString = `${data.filterString.trim()} ${typeFilter}`;
      data.filterString = `${data.filterString.trim()} ${paymentFilter}`;
      setLoadingOrderHeader(true);
      api
        .post(`businesses/orderheader/${store?.id}`, data)
        .then((response) => {
          setOrderHeader(response.data.data);
          setLoadingOrderHeader(false);
        })
        .catch((error) => {
          console.error(error);
          setLoadingOrderHeader(false);
        });
    }
  }

  function getInStoreOrderHeader() {
    if (!loadingOrderHeader) {
      const data = {
        filterString: '',
        inStoreOrders: true,
      };
      data.filterString = `${data.filterString.trim()} ${statusFilter}`;
      data.filterString = `${data.filterString.trim()} ${timeFilter}`;
      data.filterString = `${data.filterString.trim()} ${typeFilter}`;
      data.filterString = `${data.filterString.trim()} ${paymentFilter}`;
      setLoadingOrderHeader(true);
      api
        .post(`businesses/orderheader/${store?.id}`, data)
        .then((response) => {
          setInStoreOrderHeader(response.data.data);
          setLoadingOrderHeader(false);
        })
        .catch((error) => {
          console.error(error);
          setLoadingOrderHeader(false);
        });
    }
  }

  const searchOrders = function (query, page) {
    if (!isSearching) {
      getOrderHeader();
      getInStoreOrderHeader();
      setIsSearching(true);
      setOrders(null);
      setTotalItemsInSearch(0);
      setTotalRevenueInSearch(0);
      let filterString = '';
      filterString = `${filterString.trim()} ${statusFilter}`;
      filterString = `${filterString.trim()} ${timeFilter}`;
      filterString = `${filterString.trim()} ${typeFilter}`;
      filterString = `${filterString.trim()} ${paymentFilter}`;
      const payload = {
        SearchString: query,
        FilterString: filterString.trim(),
        OrderAsc: sorting === 'asc',
        OrderDesc: sorting === 'desc',
      };
      api
        .post(`admin/orders/${store?.id}/${size}/${page}`, payload)
        .then((response) => {
          console.log(response);
          if (response.data.success) {
            setIsSearching(false);
            setTotalItemsInSearch(response.data.data.count);
            setTotalRevenueInSearch(response.data.data.sales);
            const mapped = mapData(response.data.data.orders);
            setOrders(mapped);
            addValues(mapped);
          } else {
            showMessage(response.data.error);
          }
        })
        .catch((error) => {
          setIsSearching(false);
          showMessage(error);
        });
    }
  };

  const onSearch = async (value) => {
    setLastSearch(value);
    setCurrentPage(0);
    searchOrders(value, 0);
  };

  const onTableChange = (page, pageSize) => {
    if (page - 1 !== currentPage) {
      setCurrentPage(page - 1);
      searchOrders(lastSearch, page - 1);
    } else {
      setSize(pageSize);
    }
  };

  const onLogTableChange = (page, pageSize) => {
    if (page - 1 !== currentLogPage) {
      setCurrentLogPage(page - 1);
    } else {
      setSize(pageSize);
    }
  };

  useEffect(() => {
    if (reload) {
      setCurrentPage(0);
      searchOrders(lastSearch, 0);
    }
  }, [reload]);

  // useEffect(() => {
  //   setCurrentPage(0);
  //   searchOrders(lastSearch, 0);
  // }, [size]);

  // useEffect(() => {
  //   setTotalItemsInSearch(0);
  //   setCurrentPage(0);
  //   searchOrders(lastSearch, 0);
  // }, [store]);

  useEffect(() => {
    setTotalItemsInSearch(0);
    setCurrentPage(0);
    searchOrders(lastSearch, 0);
  }, [store, size, statusFilter, typeFilter, timeFilter, paymentFilter]);

  // useEffect(() => {
  //   setCurrentPage(0);
  //   searchOrders('', 0);
  // }, []);

  return (
    <div>
      <Tabs>
        <Tabs.TabPane tab={t('online')} key={1}>
          <div>
            <div className="mb-0">
              <Panel key="panel">
                <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-4 pt-4">
                  <Spin spinning={loadingOrderHeader}>
                    <div className="shadow rounded-xl flex h-20">
                      <div className="flex flex-shrink-0 items-center justify-center text-white text-3xl bg-rose-500 w-1/4 rounded-l-xl">
                        <BellTwoTone
                          twoToneColor="#eb2f96"
                          className="-ml-1 flex-shrink-0 self-center h-5 w-5"
                        />
                      </div>
                      <div className="flex flex-col text-xl mt-2 justify-center p-2 text-xs text-gray-800">
                        <div>{orderHeader && orderHeader.pendingOrders}</div>
                        <div className="justify-start">
                          <Button
                            type="text"
                            className="p-0 -mt-2 text-sm text-gray-800 font-semibold"
                            onClick={() => {
                              setStatusFilter('PLACED');
                              setTimeFilter('');
                            }}
                          >
                            {t('placed')}
                          </Button>
                        </div>
                      </div>
                      <div className="flex flex-col ml-2 justify-center">
                        <div className="flex flex-row">
                          <div className="text-xs mt-1 text-gray-600">
                            <Button
                              type="text"
                              className="p-0 -mt-2"
                              onClick={() => {
                                setStatusFilter('PLACED');
                                setTimeFilter('PAST');
                              }}
                            >
                              <BellTwoTone
                                twoToneColor="#d2222d"
                                className="-ml-1 flex-shrink-0 self-center h-3 w-3"
                              />
                              {orderHeader && orderHeader.pendingPastOrders}{' '}
                              Past
                            </Button>
                          </div>
                        </div>
                        <div className="flex flex-row">
                          <div className="text-xs mt-1 text-gray-600">
                            <Button
                              type="text"
                              className="p-0 -mt-2"
                              onClick={() => {
                                setStatusFilter('PLACED');
                                setTimeFilter('TODAY');
                              }}
                            >
                              <BellTwoTone
                                twoToneColor="#ffbf00"
                                className="-ml-1 flex-shrink-0 self-center h-3 w-3"
                              />
                              {orderHeader && orderHeader.pendingCurrentOrders}{' '}
                              Today
                            </Button>
                          </div>
                        </div>
                        <div className="flex flex-row">
                          <div className="text-xs mt-1 text-gray-600">
                            <Button
                              type="text"
                              className="p-0 -mt-2"
                              onClick={() => {
                                setStatusFilter('PLACED');
                                setTimeFilter('FUTURE');
                              }}
                            >
                              <BellTwoTone
                                twoToneColor="#23bb23"
                                className="-ml-1 flex-shrink-0 self-center h-3 w-3"
                              />
                              {orderHeader && orderHeader.pendingFutureOrders}{' '}
                              Future
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Spin>
                  <Spin spinning={loadingOrderHeader}>
                    <div className="shadow rounded-xl flex h-20">
                      <div className="flex flex-shrink-0 items-center justify-center text-white text-3xl bg-rose-500 w-1/4 rounded-l-xl">
                        <RocketTwoTone
                          twoToneColor="#A117F2"
                          className="-ml-1 flex-shrink-0 self-center h-5 w-5"
                        />
                      </div>
                      <div className="flex flex-col text-xl mt-2 mx-2 justify-center p-0 text-xs text-gray-800">
                        <div>{orderHeader && orderHeader.activeOrders}</div>
                        <div className="justify-start">
                          <Button
                            type="text"
                            className="p-0 -mt-2 text-sm text-gray-800 font-semibold"
                            onClick={() => {
                              setStatusFilter('INPROCESS');
                              setTimeFilter('');
                            }}
                          >
                            {t('processing')}
                          </Button>
                        </div>
                      </div>
                      <div className="flex flex-col ml-2 justify-center">
                        <div className="flex flex-row">
                          <div className="text-xs mt-1 text-gray-600">
                            <Button
                              type="text"
                              className="p-0 -mt-2"
                              onClick={() => {
                                setStatusFilter('PACKING');
                                setTimeFilter('PAST');
                              }}
                            >
                              <RocketTwoTone
                                twoToneColor="#d2222d"
                                className="-ml-1 flex-shrink-0 self-center h-3 w-3"
                              />
                              {orderHeader && orderHeader.activePastOrders} Past
                            </Button>
                          </div>
                        </div>
                        <div className="flex flex-row">
                          <div className="text-xs mt-1 text-gray-600">
                            <Button
                              type="text"
                              className="p-0 -mt-2"
                              onClick={() => {
                                setStatusFilter('PACKING');
                                setTimeFilter('TODAY');
                              }}
                            >
                              <RocketTwoTone
                                twoToneColor="#ffbf00"
                                className="-ml-1 flex-shrink-0 self-center h-3 w-3"
                              />
                              {orderHeader && orderHeader.activeCurrentOrders}{' '}
                              Today
                            </Button>
                          </div>
                        </div>
                        <div className="flex flex-row">
                          <div className="text-xs mt-1 text-gray-600">
                            <Button
                              type="text"
                              className="p-0 -mt-2"
                              onClick={() => {
                                setStatusFilter('PACKING');
                                setTimeFilter('FUTURE');
                              }}
                            >
                              <RocketTwoTone
                                twoToneColor="#23bb23"
                                className="-ml-1 flex-shrink-0 self-center h-3 w-3"
                              />
                              {orderHeader && orderHeader.activeFutureOrders}{' '}
                              Future
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Spin>
                  <Spin spinning={loadingOrderHeader}>
                    <div className="shadow rounded-xl flex h-20">
                      <div className="flex flex-shrink-0 items-center justify-center text-white text-3xl bg-rose-500 w-1/4 rounded-l-xl">
                        <ClockCircleTwoTone
                          twoToneColor="#ff711f"
                          className="-ml-1 flex-shrink-0 self-center h-5 w-5"
                        />
                      </div>
                      <div className="flex flex-col text-xl mt-2 mx-2 justify-center p-2 text-xs text-gray-800">
                        <div>{orderHeader && orderHeader.waitingOrders}</div>
                        <div className="justify-start">
                          <Button
                            type="text"
                            className="p-0 -mt-2 text-sm text-gray-800 font-semibold"
                            onClick={() => {
                              setStatusFilter('WAITING');
                              setTimeFilter('');
                            }}
                          >
                            {t('waiting')}
                          </Button>
                        </div>
                      </div>
                      <div className="flex flex-col ml-2 justify-center">
                        <div className="flex flex-row">
                          <div className="text-xs mt-1 text-gray-600">
                            <Button
                              type="text"
                              className="p-0 -mt-2"
                              onClick={() => {
                                setStatusFilter('WAITING');
                                setTimeFilter('PAST');
                              }}
                            >
                              <ClockCircleTwoTone
                                twoToneColor="#d2222d"
                                className="-ml-1 flex-shrink-0 self-center h-3 w-3"
                              />
                              {orderHeader && orderHeader.waitingPastOrders}{' '}
                              Past
                            </Button>
                          </div>
                        </div>
                        <div className="flex flex-row">
                          <div className="text-xs mt-1 text-gray-600">
                            <Button
                              type="text"
                              className="p-0 -mt-2"
                              onClick={() => {
                                setStatusFilter('WAITING');
                                setTimeFilter('TODAY');
                              }}
                            >
                              <ClockCircleTwoTone
                                twoToneColor="#ffbf00"
                                className="-ml-1 flex-shrink-0 self-center h-3 w-3"
                              />
                              {orderHeader && orderHeader.waitingCurrentOrders}{' '}
                              Today
                            </Button>
                          </div>
                        </div>
                        <div className="flex flex-row">
                          <div className="text-xs mt-1 text-gray-600">
                            <Button
                              type="text"
                              className="p-0 -mt-2"
                              onClick={() => {
                                setStatusFilter('WAITING');
                                setTimeFilter('FUTURE');
                              }}
                            >
                              <ClockCircleTwoTone
                                twoToneColor="#23bb23"
                                className="-ml-1 flex-shrink-0 self-center h-3 w-3"
                              />
                              {orderHeader && orderHeader.waitingFutureOrders}{' '}
                              Future
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Spin>
                  <Spin spinning={loadingOrderHeader}>
                    <div className="shadow rounded-xl flex h-20">
                      <div className="flex flex-shrink-0 items-center justify-center text-white text-3xl bg-rose-500 w-1/4 rounded-l-xl">
                        <MailTwoTone className="-ml-1 flex-shrink-0 self-center h-5 w-5" />
                      </div>
                      <div className="flex flex-col text-xl mt-2 mx-2 justify-center p-1 text-xs text-gray-800">
                        <div>{orderHeader && orderHeader.inTransitOrders}</div>
                        <div className="justify-start">
                          <Button
                            type="text"
                            className="p-0 -mt-2 text-sm text-gray-800 font-semibold"
                            onClick={() => {
                              setStatusFilter('INTRANSIT');
                              setTimeFilter('');
                            }}
                          >
                            {t('in_transit')}
                          </Button>
                        </div>
                      </div>
                      <div className="flex flex-col ml-2 justify-center">
                        <div className="flex flex-row">
                          <div className="text-xs mt-1 text-gray-600">
                            <Button
                              type="text"
                              className="p-0 -mt-2"
                              onClick={() => {
                                setStatusFilter('INTRANSIT');
                                setTimeFilter('PAST');
                              }}
                            >
                              <MailTwoTone
                                twoToneColor="#d2222d"
                                className="-ml-1 flex-shrink-0 self-center h-3 w-3"
                              />
                              {orderHeader && orderHeader.inTransitPastOrders}{' '}
                              Past
                            </Button>
                          </div>
                        </div>
                        <div className="flex flex-row">
                          <div className="text-xs mt-1 text-gray-600">
                            <Button
                              type="text"
                              className="p-0 -mt-2"
                              onClick={() => {
                                setStatusFilter('INTRANSIT');
                                setTimeFilter('TODAY');
                              }}
                            >
                              <MailTwoTone
                                twoToneColor="#ffbf00"
                                className="-ml-1 flex-shrink-0 self-center h-3 w-3"
                              />
                              {orderHeader &&
                                orderHeader.inTransitCurrentOrders}{' '}
                              Today
                            </Button>
                          </div>
                        </div>
                        <div className="flex flex-row">
                          <div className="text-xs mt-1 text-gray-600">
                            <Button
                              type="text"
                              className="p-0 -mt-2"
                              onClick={() => {
                                setStatusFilter('INTRANSIT');
                                setTimeFilter('FUTURE');
                              }}
                            >
                              <MailTwoTone
                                twoToneColor="#23bb23"
                                className="-ml-1 flex-shrink-0 self-center h-3 w-3"
                              />
                              {orderHeader && orderHeader.inTransitFutureOrders}{' '}
                              Future
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Spin>
                </div>
              </Panel>
            </div>
            <Divider />
            <Input.Search
              key="orderSearch"
              placeholder={t('order_search_placeholder')}
              allowClear
              disabled={!store}
              loading={isSearching}
              enterButton={t('search')}
              onSearch={onSearch}
            />
            <div className="flex flex-row justify-between">
              <Row className="my-4 flex space-between">
                <Col>
                  <div className="mr-2">
                    <FilterOutlined className="mr-2" />
                    <strong>{t('status')}</strong>
                    <br />
                    <Select
                      style={{ width: '150px' }}
                      value={statusFilter}
                      onChange={(value) => setStatusFilter(value)}
                    >
                      {_.map(statusFilters, (filter) => (
                        <Option
                          key={filter.tag}
                          value={filter.tag}
                          className="text-xs flex items-center"
                        >
                          {filter.name}
                        </Option>
                      ))}
                    </Select>
                  </div>
                </Col>
                <Col>
                  <div className="mr-2">
                    <FilterOutlined className="mr-2" />
                    <strong>{t('time')}</strong>
                    <br />
                    <Select
                      style={{ width: '150px' }}
                      value={timeFilter}
                      onChange={(value) => setTimeFilter(value)}
                    >
                      {_.map(timeFilters, (filter) => (
                        <Option
                          key={filter.tag}
                          value={filter.tag}
                          className="text-xs flex items-center"
                        >
                          {filter.name}
                        </Option>
                      ))}
                    </Select>
                  </div>
                </Col>
                <Col>
                  <div className="mr-2">
                    <FilterOutlined className="mr-2" />
                    <strong>{t('type')}</strong>
                    <br />
                    <Select
                      style={{ width: '150px' }}
                      value={typeFilter}
                      onChange={(value) => setTypeFilter(value)}
                    >
                      {_.map(typeFilters, (filter) => (
                        <Option
                          key={filter.tag}
                          value={filter.tag}
                          className="text-xs flex items-center"
                        >
                          {filter.name}
                        </Option>
                      ))}
                    </Select>
                  </div>
                </Col>
                <Col>
                  <div className="mr-2">
                    <FilterOutlined className="mr-2" />
                    <strong>{t('payment')}</strong>
                    <br />
                    <Select
                      style={{ width: '150px' }}
                      value={paymentFilter}
                      onChange={(value) => setPaymentFilter(value)}
                    >
                      <Option
                        key="all"
                        value=""
                        className="text-xs flex items-center"
                      >
                        {t('all')}
                      </Option>
                      <Option
                        key="cc"
                        value="CREDITCARD"
                        className="text-xs flex items-center"
                      >
                        {t('credit_card')}
                      </Option>
                      {_.map(
                        paymentOptions?.contents?.data?.data?.results,
                        (paymentOption) => (
                          <Option
                            key={paymentOption.id}
                            value={`PAYMENTOPTION-${paymentOption.id}`}
                            className="text-xs flex items-center"
                          >
                            {paymentOption.name}
                          </Option>
                        )
                      )}
                    </Select>
                  </div>
                </Col>
                <Col>
                  <div className="ml-12">
                    <SortAscendingOutlined className="mr-2 text-blue-500" />
                    <strong className="text-blue-500">{t('sort')}</strong>
                    <br />
                    <Select
                      style={{ width: '120px' }}
                      value={sorting}
                      onChange={(value) => setSorting(value)}
                    >
                      <Option
                        key="ascending"
                        value="asc"
                        className="text-xs flex items-center"
                      >
                        {t('oldest')}
                      </Option>
                      <Option
                        key="descending"
                        value="desc"
                        className="text-xs flex items-center"
                      >
                        {t('newest')}
                      </Option>
                    </Select>
                  </div>
                </Col>
              </Row>
              <div
                className="flex flex-row items-center mt-2"
                style={{ float: 'right' }}
              >
                <div className="mr-4 text-xs mt-2 flex flex-col">
                  <div>
                    {t('showing')}:{' '}
                    {totalItemsInSearch > size ? size : totalItemsInSearch}/
                    {totalItemsInSearch}
                  </div>
                  {totalItemsInSearch > size ? (
                    <div>
                      ${onScreenTotal}/${totalRevenueInSearch.toFixed(2)}
                    </div>
                  ) : (
                    <div>Total: ${onScreenTotal}</div>
                  )}
                </div>
                <Button
                  hidden
                  className="mb-0"
                  size="medium"
                  type="primary"
                  icon={<SyncOutlined spin={isSearching} />}
                  onClick={() => {
                    setCurrentPage(currentPage);
                    searchOrders(lastSearch, currentPage);
                  }}
                >
                  {t('reload')}
                </Button>
              </div>
            </div>
            <br />
            {onlineOrders?.length === 0 ? (
              <p className="text-lg text-center">{t('no_online_order')}</p>
            ) : (
              <div>
                <Table
                  className="pt-4"
                  loading={isSearching}
                  bordered
                  pagination={{
                    pageSize: size,
                    showSizeChanger: true,
                    disabled: !store,
                    defaultCurrent: 0,
                    current: currentPage + 1,
                    total: totalItemsInSearch,
                    onChange: onTableChange,
                  }}
                  dataSource={onlineOrders}
                  columns={[
                    {
                      title: t('code'),
                      dataIndex: 'confirmationCode',
                      key: 'confirmationCode',
                      align: 'left',
                      width: 140,
                      className: 'text-xs',
                      render: (text) => (
                        <Typography.Text copyable>{text}</Typography.Text>
                      ),
                    },
                    {
                      title: t('type'),
                      key: 'type',
                      align: 'left',
                      width: 120,
                      className: 'text-xs',
                      render: (text) => {
                        if (text.type === 'Pickup') {
                          return <Tag color="#FF6600">{text.type}</Tag>;
                        }
                        if (text.type === 'Delivery') {
                          return (
                            <div>
                              <Tag color="#87d068">{text.type}</Tag>
                              <br />
                              <span className="mt-2">{text.deliveryZone}</span>
                            </div>
                          );
                        }
                        return <Tag>{text.type}</Tag>;
                      },
                    },
                    {
                      title: t('name'),
                      dataIndex: 'customer',
                      key: 'customer',
                      align: 'left',
                      className: 'text-xs',
                      render: (text) => <span>{text}</span>,
                    },
                    {
                      title: t('total'),
                      dataIndex: 'total',
                      key: 'total',
                      align: 'right',
                      className: 'text-xs',
                      render: (text) => <span>${text}</span>,
                      sorter: (a, b) => a.total - b.total,
                    },
                    {
                      title: t('payment'),
                      dataIndex: 'paymentTypeText',
                      key: 'paymentTypeText',
                      align: 'center',
                      className: 'text-xs',
                      render: (text) => <span>{text}</span>,
                    },
                    {
                      title: t('status'),
                      dataIndex: 'statusText',
                      key: 'statusText',
                      align: 'left',
                      className: 'text-xs',
                      render: (text) => (
                        <Typography.Text>{t(text)}</Typography.Text>
                      ),
                    },
                    {
                      title: t('desired_date'),
                      dataIndex: 'desiredDateTime',
                      key: 'desiredDateTime',
                      align: 'left',
                      className: 'text-xs',
                      render: (text) => <span>{text}</span>,
                    },
                    {
                      title: t('expected'),
                      dataIndex: 'dDateTime',
                      key: 'dDateTime',
                      align: 'left',
                      className: 'text-xs',
                      render: (text) => (
                        <span className="capitalize">
                          {moment(text).fromNow()}
                        </span>
                      ),
                    },
                    {
                      title: t('signature'),
                      key: 'signatureImageUrl',
                      align: 'center',
                      className: 'text-xs',
                      render: (text, x) => (
                        <span>
                          <Button
                            shape="circle"
                            size="small"
                            disabled={
                              x.signatureImageUrl === '' ||
                              x.signatureImageUrl == null
                            }
                            icon={<SearchOutlined />}
                            onClick={() => {
                              setVisibleSignature(true);
                              setVisibleSignatureImage(x.signatureImageUrl);
                            }}
                          />
                        </span>
                      ),
                    },
                    {
                      title: 'EBT',
                      key: 'ebtReceiptUrl',
                      align: 'center',
                      className: 'text-xs',
                      render: (text, x) => (
                        <span>
                          <Button
                            shape="circle"
                            size="small"
                            disabled={
                              x.ebtReceiptUrl === '' || x.ebtReceiptUrl == null
                            }
                            icon={<SearchOutlined />}
                            onClick={() => {
                              setVisibleEbt(true);
                              setVisibleEbtImage(x.ebtReceiptUrl);
                            }}
                          />
                        </span>
                      ),
                    },
                    {
                      title: t('details'),
                      align: 'center',
                      className: 'text-xs hidden',
                      render: (text, row) => (
                        <Space>
                          <Button
                            disabled={row.favStoreId === 'Unknown'}
                            shape="circle"
                            size="small"
                            icon={<FileSearchOutlined />}
                            onClick={() => {
                              setOrder(row);
                              history.push('/orderDetails');
                            }}
                          />
                        </Space>
                      ),
                    },
                    {
                      title: t('items'),
                      align: 'center',
                      className: 'text-xs hidden',
                      render: (text, row) => (
                        <Space>
                          <Popover
                            key="pitems"
                            title={
                              loadingItems
                                ? `${t('loading')}`
                                : `${t('order_items')} (${
                                  orderItems && orderItems.length
                                })`
                            }
                            placement="left"
                            className="h-32 md:h-64 w-72 md:w-72"
                            trigger="click"
                            width="100%"
                            content={
                              <div>
                                <Table
                                  size="small"
                                  bordered
                                  loading={loadingItems}
                                  columns={[
                                    {
                                      title: t('brand'),
                                      dataIndex: 'brand',
                                      key: 'brand',
                                      align: 'left',
                                      className: 'text-xs',
                                      render: (info) => (
                                        <Typography.Text>
                                          {info}
                                        </Typography.Text>
                                      ),
                                    },
                                    {
                                      title: t('name'),
                                      dataIndex: 'name',
                                      key: 'name',
                                      align: 'left',
                                      className: 'text-xs',
                                      render: (info) => (
                                        <Typography.Text>
                                          {info}
                                        </Typography.Text>
                                      ),
                                    },
                                    {
                                      title: t('description'),
                                      dataIndex: 'description',
                                      key: 'description',
                                      align: 'left',
                                      className: 'text-xs',
                                      render: (info) => (
                                        <Typography.Text>
                                          {info}
                                        </Typography.Text>
                                      ),
                                    },
                                    {
                                      title: 'UPC',
                                      dataIndex: 'upc',
                                      key: 'upc',
                                      align: 'left',
                                      className: 'text-xs',
                                      render: (info) => (
                                        <Typography.Text copyable>
                                          {info}
                                        </Typography.Text>
                                      ),
                                    },
                                    {
                                      title: t('amount'),
                                      dataIndex: 'amount',
                                      key: 'amount',
                                      align: 'center',
                                      className: 'text-xs',
                                      render: (info) => (
                                        <Typography.Text>
                                          {info.toFixed(2)}
                                        </Typography.Text>
                                      ),
                                    },
                                    {
                                      title: t('price'),
                                      dataIndex: 'price',
                                      key: 'price',
                                      align: 'center',
                                      className: 'text-xs',
                                      render: (info) => (
                                        <Typography.Text>
                                          ${info.toFixed(2)}
                                        </Typography.Text>
                                      ),
                                    },
                                    {
                                      title: t('included'),
                                      dataIndex: 'isVoided',
                                      key: 'isVoided',
                                      align: 'center',
                                      className: 'text-xs',
                                      render: (info) => (
                                        <Switch
                                          size="small"
                                          checked={!info}
                                          disabled
                                        />
                                      ),
                                    },
                                    {
                                      title: 'Tax Rule',
                                      dataIndex: 'taxRule',
                                      key: 'traxRule',
                                      align: 'center',
                                      className: 'text-xs',
                                      render: (info) => (
                                        <Typography.Text>
                                          {info}
                                        </Typography.Text>
                                      ),
                                    },
                                  ]}
                                  dataSource={
                                    orderItems && orderItems.length > 0
                                      ? orderItems
                                      : null
                                  }
                                  expandable={{
                                    expandRowByClick: false,
                                    expandedRowRender: (record) => (
                                      <div>
                                        <Tabs>
                                          <Tabs.TabPane
                                            key="1"
                                            tab={t('details')}
                                          >
                                            <Row className="flex">
                                              <Col span={12} className="flex">
                                                <Descriptions>
                                                  <Descriptions.Item
                                                    label={t('section_name')}
                                                  />
                                                  <br />
                                                  <Descriptions.Item>
                                                    <Col span={24}>
                                                      <Typography.Text>
                                                        {record.sectionName}
                                                      </Typography.Text>
                                                    </Col>
                                                  </Descriptions.Item>
                                                  <Descriptions.Item
                                                    label={t('aisle')}
                                                  />
                                                  <br />
                                                  <Descriptions.Item>
                                                    <Col span={24}>
                                                      <Typography.Text>
                                                        {record.aisle}
                                                      </Typography.Text>
                                                    </Col>
                                                  </Descriptions.Item>
                                                </Descriptions>
                                              </Col>
                                              <Col span={12} className="flex">
                                                <Descriptions bordered>
                                                  <Descriptions.Item
                                                    label={t('item_image')}
                                                  />
                                                  <br />
                                                  <Descriptions.Item>
                                                    <Col span={24}>
                                                      <Image
                                                        alt="Item Image"
                                                        width={150}
                                                        src={record.itemImage}
                                                      />
                                                    </Col>
                                                  </Descriptions.Item>
                                                </Descriptions>
                                              </Col>
                                            </Row>
                                          </Tabs.TabPane>
                                          <Tabs.TabPane key="2" tab="Flags">
                                            <Row className="flex">
                                              <Col span={12} className="flex">
                                                <Descriptions>
                                                  <Descriptions.Item
                                                    className="py-0"
                                                    label={t('weighted')}
                                                  >
                                                    <Switch
                                                      disabled
                                                      size="small"
                                                      checked={
                                                        record.hasRandomWeight
                                                      }
                                                    />
                                                  </Descriptions.Item>
                                                </Descriptions>
                                              </Col>
                                              <Col span={10} offset={2}>
                                                <Descriptions>
                                                  <Descriptions.Item
                                                    label={t(
                                                      'alcoholic_beverage'
                                                    )}
                                                  >
                                                    <Switch
                                                      disabled
                                                      size="small"
                                                      checked={
                                                        record.isAlcoholicBeverage
                                                      }
                                                    />
                                                  </Descriptions.Item>
                                                </Descriptions>
                                              </Col>
                                            </Row>
                                          </Tabs.TabPane>
                                        </Tabs>
                                      </div>
                                    ),
                                    rowExpandable: (record) => record,
                                  }}
                                />
                              </div>
                            }
                          >
                            <Button
                              disabled={row.favStoreId === 'Unknown'}
                              shape="circle"
                              size="small"
                              icon={<SearchOutlined />}
                              onClick={() => {
                                setLoadingItems(true);
                                api
                                  .get(`orders/${row.id}/items/all`)
                                  .then((response) => {
                                    const map = mapOrderItems(
                                      response.data.data.results
                                    );
                                    setOrderItems(map);
                                    setLoadingItems(false);
                                  })
                                  .catch((error) => {
                                    console.error(error);
                                  });
                              }}
                            />
                          </Popover>
                        </Space>
                      ),
                    },
                    {
                      title: 'Logs',
                      align: 'center',
                      className: 'text-xs',

                      render: (row) => (
                        <Space>
                          <Popover
                            key="plogs"
                            title={
                              loadingLogs
                                ? 'Loading...'
                                : `Logs (${logs && logs.length})`
                            }
                            placement="left"
                            trigger="click"
                            content={
                              <div>
                                <Table
                                  pagination={{
                                    pageSize: 5,
                                    showSizeChanger: true,
                                    disabled: !store,
                                    defaultCurrent: 0,
                                    current: currentLogPage + 1,
                                    showTotal: (total, range) =>
                                      `${range[0]}-${range[1]} ${t('out_of')} ${
                                        logs && logs.length
                                      } ${t('items')}`,
                                    total: logs && logs.length,
                                    onChange: onLogTableChange,
                                  }}
                                  className="flex flex-column h-auto md:h-auto"
                                  loading={loadingLogs}
                                  dataSource={
                                    logs && logs.length > 0 ? logs : null
                                  }
                                  columns={[
                                    {
                                      title: t('modified_date'),
                                      dataIndex: 'modifiedDate',
                                      key: 'modifiedDate',
                                      align: 'left',
                                      className: 'text-xs font-medium',
                                      render: (txt) => <span>{txt}</span>,
                                    },
                                    {
                                      title: t('description'),
                                      dataIndex: 'description',
                                      key: 'description',
                                      align: 'left',
                                      className:
                                        'text-xs font-medium width-100 max-w-md',
                                      tooltip: 'description',
                                      render: (txt) => (
                                        <span className="max-w-md">{txt}</span>
                                      ),
                                    },
                                    {
                                      title: t('status'),
                                      dataIndex: 'preStatus',
                                      key: 'preStatus',
                                      align: 'left',
                                      className:
                                        'text-xs font-medium width-100',
                                      render: (txt) => <span>{t(txt)}</span>,
                                    },
                                  ]}
                                />
                              </div>
                            }
                          >
                            <Button
                              shape="circle"
                              size="small"
                              onClick={() => {
                                setLoadingLogs(true);
                                setCurrentLogPage(0);
                                api
                                  .get(`orders/${row.id}/logs`)
                                  .then((response) => {
                                    const map = mapLogs(
                                      response.data.data.results
                                    );
                                    setLogs(map);
                                    setLoadingLogs(false);
                                  })
                                  .catch((error) => {
                                    console.error(error);
                                  });
                              }}
                              icon={<OrderedListOutlined />}
                            />
                          </Popover>
                        </Space>
                      ),
                    },
                    {
                      title: t('actions'),
                      align: 'center',
                      className: 'text-xs',
                      render: (row) => (
                        <Space>
                          <Button
                            size="small"
                            type="primary"
                            onClick={() => {
                              setSelectedOrder(row);
                              loadItems(row);
                              setEditOrder(true);
                            }}
                          >
                            {t('edit')}
                          </Button>
                        </Space>
                      ),
                    },
                  ]}
                  expandable={{
                    expandRowByClick: false,
                    expandedRowRender: (record) => (
                      <Tabs
                        onTabClick={() => {
                          loadLogs(record);
                          loadItems(record);
                          setOrder(record);
                        }}
                        onChange={() => {
                          setOrder(record);
                        }}
                      >
                        <Tabs.TabPane key="1" tab="General">
                          <div className="flex flex-columns justify-content bg-white">
                            <div className="p-6">
                              <Descriptions size="small" title="Customer">
                                <Descriptions.Item>
                                  <Typography.Text copyable>
                                    {record.customer}
                                  </Typography.Text>
                                </Descriptions.Item>
                                <br />
                                <br />
                                <Descriptions.Item>
                                  <PhoneOutlined className="mr-2" />
                                  <Typography.Text copyable>
                                    {record.customerPhone}
                                  </Typography.Text>
                                </Descriptions.Item>
                              </Descriptions>
                              <Descriptions
                                size="small"
                                title={t('pickup_name')}
                              >
                                <Descriptions.Item>
                                  <Typography.Text copyable>
                                    {record.pickUpName}
                                  </Typography.Text>
                                </Descriptions.Item>
                              </Descriptions>
                              <Descriptions
                                size="small"
                                title="Dates"
                                className="mt-4"
                              >
                                <Descriptions.Item label="Created">
                                  <Typography.Text>
                                    {record.createdDiff}
                                  </Typography.Text>
                                </Descriptions.Item>
                                <br />
                                <br />
                                <Descriptions.Item>
                                  <ClockCircleOutlined className="mr-2" />
                                  <Typography.Text>
                                    {record.createdAt}
                                  </Typography.Text>
                                </Descriptions.Item>
                                <br />
                                <br />
                                <Descriptions.Item label="Dispatched">
                                  <Typography.Text>
                                    {record.dispatchDiff}
                                  </Typography.Text>
                                </Descriptions.Item>
                                <br />
                                <br />
                                <Descriptions.Item>
                                  <ClockCircleOutlined className="mr-2" />
                                  <Typography.Text>
                                    {record.dispatchDateTime}
                                  </Typography.Text>
                                </Descriptions.Item>
                              </Descriptions>
                            </div>
                            <div className="p-6">
                              <Descriptions size="small" title="Payment">
                                <Descriptions.Item label={t('payment')}>
                                  <Typography.Text>
                                    {record.paymentTypeText}
                                  </Typography.Text>
                                </Descriptions.Item>
                                <br />
                                <br />
                                <Descriptions.Item label={t('card')}>
                                  <Typography.Text>
                                    {record.nickName}
                                  </Typography.Text>
                                </Descriptions.Item>
                                <br />
                                <br />
                                <Descriptions.Item label={t('card_holder')}>
                                  <Typography.Text>
                                    {record.cardholderName}
                                  </Typography.Text>
                                </Descriptions.Item>
                                <br />
                                <br />
                                <Descriptions.Item label={t('authorized')}>
                                  <Typography.Text>{`${record.wasAlreadyAuthed}`}</Typography.Text>
                                </Descriptions.Item>
                                <br />
                                <br />
                                <Descriptions.Item label={t('charged')}>
                                  <Typography.Text>{`${record.wasAlreadyCharged}`}</Typography.Text>
                                </Descriptions.Item>
                              </Descriptions>
                            </div>
                            <div className="p-6">
                              <Descriptions
                                Descriptions
                                size="small"
                                title="Totals"
                              >
                                <Descriptions.Item label="Subtotal">
                                  <Typography.Text>
                                    ${record.subTotal}
                                  </Typography.Text>
                                </Descriptions.Item>
                                <br />
                                <br />
                                <Descriptions.Item label="Municipal Tax">
                                  <Typography.Text>
                                    ${record.stateTax}
                                  </Typography.Text>
                                </Descriptions.Item>
                                <br />
                                <br />
                                <Descriptions.Item label="State Tax">
                                  <Typography.Text>
                                    ${record.munTax}
                                  </Typography.Text>
                                </Descriptions.Item>
                                <br />
                                <br />
                                <Descriptions.Item label="Delivery Fee">
                                  <Typography.Text>
                                    ${record.deliveryFee}
                                  </Typography.Text>
                                </Descriptions.Item>
                                <br />
                                <br />
                                <Descriptions.Item label="Processing Fee">
                                  <Typography.Text>
                                    ${record.processingFee}
                                  </Typography.Text>
                                </Descriptions.Item>
                                <br />
                                <br />
                                <Descriptions.Item label="Total">
                                  <Typography.Text>
                                    ${record.total}
                                  </Typography.Text>
                                </Descriptions.Item>
                              </Descriptions>
                            </div>
                            <div className="p-6">
                              <Descriptions
                                Descriptions
                                size="small"
                                title="Other"
                              >
                                <Descriptions.Item label="Ivu Control ">
                                  <Typography.Text>
                                    {record.ivuCN}
                                  </Typography.Text>
                                </Descriptions.Item>
                                <br />
                                <br />
                                <Descriptions.Item label="(Required/Completed)">
                                  <Typography.Text>{`${record.returnNeeded}/${record.returnCompleted}`}</Typography.Text>
                                </Descriptions.Item>
                                <br />
                                <br />
                                <Descriptions.Item label={t('discount')}>
                                  <Typography.Text>
                                    ${record.discountAmount}
                                  </Typography.Text>
                                </Descriptions.Item>
                                <br />
                                <br />
                                <Descriptions.Item label={t('returned_amount')}>
                                  <Typography.Text>
                                    ${record.returnTotal}
                                  </Typography.Text>
                                </Descriptions.Item>
                                <br />
                                <br />
                                <Descriptions.Item label="Credit">
                                  <Typography.Text>
                                    ${record.credit}
                                  </Typography.Text>
                                </Descriptions.Item>
                              </Descriptions>
                            </div>
                          </div>
                        </Tabs.TabPane>
                        <Tabs.TabPane key="2" tab="Details">
                          <div>
                            <Descriptions
                              Descriptions
                              size="small"
                              title="Delivery"
                            >
                              <Descriptions.Item
                                className={
                                  record.orderType === 0 ? 'hidden' : ''
                                }
                                label="Zone"
                              >
                                <Typography.Text>
                                  {record.deliveryZone}
                                </Typography.Text>
                              </Descriptions.Item>
                              <br
                                className={
                                  record.orderType === 0 ? 'hidden' : ''
                                }
                              />
                              <br
                                className={
                                  record.orderType === 0 ? 'hidden' : ''
                                }
                              />
                              <div>
                                <AimOutlined className="mr-2" />{' '}
                                {record.customerLocation?.name}
                                <br />
                                {record.customerLocation?.addressLine}
                                <br />
                                {`${record.customerLocation?.city}, ${record.customerLocation?.zipcode}`}
                              </div>
                            </Descriptions>
                          </div>
                        </Tabs.TabPane>
                      </Tabs>
                    ),
                    rowExpandable: (record) => record,
                  }}
                />
                {editOrder ? <OrderActions open={selectedOrder} /> : null}
                <Image
                  width={200}
                  style={{ display: 'none' }}
                  src={visibleSignatureImg}
                  preview={{
                    visible: visibleSignature,
                    scaleStep,
                    src: visibleSignatureImg,
                    onVisibleChange: (value) => {
                      setVisibleSignature(value);
                      setVisibleSignatureImage(null);
                    },
                  }}
                />
                <Image
                  width={200}
                  style={{ display: 'none' }}
                  src={visibleEbtImg}
                  preview={{
                    visible: visibleEbt,
                    scaleStep,
                    src: visibleEbtImg,
                    onVisibleChange: (value) => {
                      setVisibleEbt(value);
                      setVisibleEbtImage(null);
                    },
                  }}
                />
              </div>
            )}
          </div>
        </Tabs.TabPane>
        <Tabs.TabPane tab={t('in-store')} key={2}>
          <div>
            <div className="mb-0">
              <Panel key="panel">
                <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-4 pt-4">
                  <Spin spinning={loadingOrderHeader}>
                    <div className="shadow rounded-xl flex h-20">
                      <div className="flex flex-shrink-0 items-center justify-center text-white text-3xl bg-rose-500 w-1/4 rounded-l-xl">
                        <BellTwoTone
                          twoToneColor="#eb2f96"
                          className="-ml-1 flex-shrink-0 self-center h-5 w-5"
                        />
                      </div>
                      <div className="flex flex-col text-xl mt-2 justify-center p-2 text-xs text-gray-800">
                        <div>{inStoreOrderHeader && inStoreOrderHeader.completedOrders}</div>
                        <div className="justify-start">
                          <Button
                            type="text"
                            className="p-0 -mt-2 text-sm text-gray-800 font-semibold"
                            onClick={() => {
                              setStatusFilter('');
                              setTimeFilter('');
                            }}
                          >
                            {t('completed')}
                          </Button>
                        </div>
                      </div>
                      <div className="flex flex-col ml-2 justify-center">
                        <div className="flex flex-row">
                          <div className="text-xs mt-1 text-gray-600">
                            <Button
                              type="text"
                              className="p-0 -mt-2"
                              onClick={() => {
                                setStatusFilter('');
                                setTimeFilter('PAST');
                              }}
                            >
                              <BellTwoTone
                                twoToneColor="#d2222d"
                                className="-ml-1 flex-shrink-0 self-center h-3 w-3"
                              />
                              {inStoreOrderHeader && inStoreOrderHeader.completedPastOrders}{' '}
                              Past
                            </Button>
                          </div>
                        </div>
                        <div className="flex flex-row">
                          <div className="text-xs mt-1 text-gray-600">
                            <Button
                              type="text"
                              className="p-0 -mt-2"
                              onClick={() => {
                                setStatusFilter('');
                                setTimeFilter('TODAY');
                              }}
                            >
                              <BellTwoTone
                                twoToneColor="#ffbf00"
                                className="-ml-1 flex-shrink-0 self-center h-3 w-3"
                              />
                              {inStoreOrderHeader && inStoreOrderHeader.completedCurrentOrders}{' '}
                              Today
                            </Button>
                          </div>
                        </div>
                        <div className="flex flex-row">
                          <div className="text-xs mt-1 text-gray-600">
                            <Button
                              type="text"
                              className="p-0 -mt-2"
                              onClick={() => {
                                setStatusFilter('');
                                setTimeFilter('YESTERDAY');
                              }}
                            >
                              <BellTwoTone
                                twoToneColor="#23bb23"
                                className="-ml-1 flex-shrink-0 self-center h-3 w-3"
                              />
                              {inStoreOrderHeader && inStoreOrderHeader.completedYesterdayOrders}{' '}
                              Yesterday
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Spin>
                </div>
              </Panel>
            </div>
            <Divider />
            <Input.Search
              key="orderSearch"
              placeholder={t('order_search_placeholder')}
              allowClear
              disabled={!store}
              loading={isSearching}
              enterButton={t('search')}
              onSearch={onSearch}
            />
            <div className="flex flex-row justify-between">
              <Row className="my-4 flex space-between">
                <Col>
                  <div className="mr-2">
                    <FilterOutlined className="mr-2" />
                    <strong>{t('status')}</strong>
                    <br />
                    <Select
                      style={{ width: '150px' }}
                      value={statusFilter}
                      onChange={(value) => setStatusFilter(value)}
                    >
                      <Option
                        key=""
                        value=""
                        className="text-xs flex items-center"
                      >
                        All
                      </Option>
                    </Select>
                  </div>
                </Col>
                <Col>
                  <div className="mr-2">
                    <FilterOutlined className="mr-2" />
                    <strong>{t('time')}</strong>
                    <br />
                    <Select
                      style={{ width: '150px' }}
                      value={timeFilter}
                      onChange={(value) => setTimeFilter(value)}
                    >
                      {_.map(timeFilters, (filter) => (
                        <Option
                          key={filter.tag}
                          value={filter.tag}
                          className="text-xs flex items-center"
                        >
                          {filter.name}
                        </Option>
                      ))}
                    </Select>
                  </div>
                </Col>
                <Col>
                  <div className="mr-2">
                    <FilterOutlined className="mr-2" />
                    <strong>{t('payment')}</strong>
                    <br />
                    <Select
                      style={{ width: '150px' }}
                      value={paymentFilter}
                      onChange={(value) => setPaymentFilter(value)}
                    >
                      <Option
                        key="all"
                        value=""
                        className="text-xs flex items-center"
                      >
                        {t('all')}
                      </Option>
                      <Option
                        key="cc"
                        value="CREDITCARD"
                        className="text-xs flex items-center"
                      >
                        {t('credit_card')}
                      </Option>
                      {_.map(
                        paymentOptions?.contents?.data?.data?.results,
                        (paymentOption) => (
                          <Option
                            key={paymentOption.id}
                            value={`PAYMENTOPTION-${paymentOption.id}`}
                            className="text-xs flex items-center"
                          >
                            {paymentOption.name}
                          </Option>
                        )
                      )}
                    </Select>
                  </div>
                </Col>
                <Col>
                  <div className="ml-12">
                    <SortAscendingOutlined className="mr-2 text-blue-500" />
                    <strong className="text-blue-500">{t('sort')}</strong>
                    <br />
                    <Select
                      style={{ width: '120px' }}
                      value={sorting}
                      onChange={(value) => setSorting(value)}
                    >
                      <Option
                        key="ascending"
                        value="asc"
                        className="text-xs flex items-center"
                      >
                        {t('oldest')}
                      </Option>
                      <Option
                        key="descending"
                        value="desc"
                        className="text-xs flex items-center"
                      >
                        {t('newest')}
                      </Option>
                    </Select>
                  </div>
                </Col>
              </Row>
              <div
                className="flex flex-row items-center mt-2"
                style={{ float: 'right' }}
              >
                <div className="mr-4 text-xs mt-2 flex flex-col">
                  <div>
                    {t('showing')}:{' '}
                    {totalItemsInSearch > size ? size : totalItemsInSearch}/
                    {totalItemsInSearch}
                  </div>
                  {totalItemsInSearch > size ? (
                    <div>
                      ${onScreenTotal}/${totalRevenueInSearch.toFixed(2)}
                    </div>
                  ) : (
                    <div>Total: ${onScreenTotal}</div>
                  )}
                </div>
                <Button
                  hidden
                  className="mb-0"
                  size="medium"
                  type="primary"
                  icon={<SyncOutlined spin={isSearching} />}
                  onClick={() => {
                    setCurrentPage(currentPage);
                    searchOrders(lastSearch, currentPage);
                  }}
                >
                  {t('reload')}
                </Button>
              </div>
            </div>
            <br />
            {inStoreOrders?.length === 0 ? (
              <p className="text-lg text-center">{t('no_instore_order')}.</p>
            ) : (
              <div>
                <Table
                  className="pt-4"
                  loading={isSearching}
                  bordered
                  pagination={{
                    pageSize: size,
                    showSizeChanger: true,
                    disabled: !store,
                    defaultCurrent: 0,
                    current: currentPage + 1,
                    total: totalItemsInSearch,
                    onChange: onTableChange,
                  }}
                  dataSource={inStoreOrders}
                  columns={[
                    {
                      title: t('code'),
                      dataIndex: 'confirmationCode',
                      key: 'confirmationCode',
                      align: 'left',
                      width: 160,
                      className: 'text-xs',
                      render: (text) => (
                        <Typography.Text copyable>{text}</Typography.Text>
                      ),
                    },
                    {
                      title: t('date'),
                      dataIndex: 'date',
                      key: 'date',
                      align: 'left',
                      className: 'text-xs',
                      render: (text) => <span>{text}</span>,
                    },
                    {
                      title: t('status'),
                      dataIndex: 'statusText',
                      key: 'status',
                      align: 'left',
                      className: 'text-xs',
                      render: (text) => <span>{t(text)}</span>,
                    },
                    {
                      title: t('store'),
                      dataIndex: 'businessName',
                      key: 'businessName',
                      align: 'left',
                      className: 'text-xs',
                      render: (text) => <span>{text}</span>,
                    },
                    {
                      title: t('pos_lane'),
                      dataIndex: 'posLaneTag',
                      key: 'posLaneTag',
                      align: 'left',
                      className: 'text-xs',
                      render: (text) => <span>{text}</span>,
                    },
                    {
                      title: t('municipal_tax'),
                      dataIndex: 'munTax',
                      key: 'munTax',
                      align: 'left',
                      className: 'text-xs',
                      render: (text) => <span>${text}</span>,
                    },
                    {
                      title: t('state_tax'),
                      dataIndex: 'stateTax',
                      key: 'stateTax',
                      align: 'left',
                      className: 'text-xs',
                      render: (text) => <span>${text}</span>,
                    },
                    {
                      title: t('total'),
                      dataIndex: 'total',
                      key: 'total',
                      align: 'right',
                      className: 'text-xs',
                      render: (text) => <span>${text}</span>,
                      sorter: (a, b) => a.total - b.total,
                    },
                    {
                      title: t('payment'),
                      dataIndex: 'paymentTender',
                      key: 'paymentTender',
                      align: 'center',
                      className: 'text-xs',
                      render: (text) => <span>{text}</span>,
                    },
                    {
                      title: t('details'),
                      align: 'center',
                      className: 'text-xs hidden',
                      render: (text, row) => (
                        <Space>
                          <Button
                            disabled={row.favStoreId === 'Unknown'}
                            shape="circle"
                            size="small"
                            icon={<FileSearchOutlined />}
                            onClick={() => {
                              setOrder(row);
                              history.push('/orderDetails');
                            }}
                          />
                        </Space>
                      ),
                    },
                    {
                      title: t('items'),
                      align: 'center',
                      className: 'text-xs hidden',
                      render: (text, row) => (
                        <Space>
                          <Popover
                            key="pitems"
                            title={
                              loadingItems
                                ? `${t('loading')}`
                                : `${t('order_items')} (${
                                  orderItems && orderItems.length
                                })`
                            }
                            placement="left"
                            className="h-32 md:h-64 w-72 md:w-72"
                            trigger="click"
                            width="100%"
                            content={
                              <div>
                                <Table
                                  size="small"
                                  bordered
                                  loading={loadingItems}
                                  columns={[
                                    {
                                      title: t('brand'),
                                      dataIndex: 'brand',
                                      key: 'brand',
                                      align: 'left',
                                      className: 'text-xs',
                                      render: (info) => (
                                        <Typography.Text>
                                          {info}
                                        </Typography.Text>
                                      ),
                                    },
                                    {
                                      title: t('name'),
                                      dataIndex: 'name',
                                      key: 'name',
                                      align: 'left',
                                      className: 'text-xs',
                                      render: (info) => (
                                        <Typography.Text>
                                          {info}
                                        </Typography.Text>
                                      ),
                                    },
                                    {
                                      title: t('description'),
                                      dataIndex: 'description',
                                      key: 'description',
                                      align: 'left',
                                      className: 'text-xs',
                                      render: (info) => (
                                        <Typography.Text>
                                          {info}
                                        </Typography.Text>
                                      ),
                                    },
                                    {
                                      title: 'UPC',
                                      dataIndex: 'upc',
                                      key: 'upc',
                                      align: 'left',
                                      className: 'text-xs',
                                      render: (info) => (
                                        <Typography.Text copyable>
                                          {info}
                                        </Typography.Text>
                                      ),
                                    },
                                    {
                                      title: t('amount'),
                                      dataIndex: 'amount',
                                      key: 'amount',
                                      align: 'center',
                                      className: 'text-xs',
                                      render: (info) => (
                                        <Typography.Text>
                                          {info.toFixed(2)}
                                        </Typography.Text>
                                      ),
                                    },
                                    {
                                      title: t('price'),
                                      dataIndex: 'price',
                                      key: 'price',
                                      align: 'center',
                                      className: 'text-xs',
                                      render: (info) => (
                                        <Typography.Text>
                                          ${info.toFixed(2)}
                                        </Typography.Text>
                                      ),
                                    },
                                    {
                                      title: t('included'),
                                      dataIndex: 'isVoided',
                                      key: 'isVoided',
                                      align: 'center',
                                      className: 'text-xs',
                                      render: (info) => (
                                        <Switch
                                          size="small"
                                          checked={!info}
                                          disabled
                                        />
                                      ),
                                    },
                                    {
                                      title: 'Tax Rule',
                                      dataIndex: 'taxRule',
                                      key: 'traxRule',
                                      align: 'center',
                                      className: 'text-xs',
                                      render: (info) => (
                                        <Typography.Text>
                                          {info}
                                        </Typography.Text>
                                      ),
                                    },
                                  ]}
                                  dataSource={
                                    orderItems && orderItems.length > 0
                                      ? orderItems
                                      : null
                                  }
                                  expandable={{
                                    expandRowByClick: false,
                                    expandedRowRender: (record) => (
                                      <div>
                                        <Tabs>
                                          <Tabs.TabPane
                                            key="1"
                                            tab={t('details')}
                                          >
                                            <Row className="flex">
                                              <Col span={12} className="flex">
                                                <Descriptions>
                                                  <Descriptions.Item
                                                    label={t('section_name')}
                                                  />
                                                  <br />
                                                  <Descriptions.Item>
                                                    <Col span={24}>
                                                      <Typography.Text>
                                                        {record.sectionName}
                                                      </Typography.Text>
                                                    </Col>
                                                  </Descriptions.Item>
                                                  <Descriptions.Item
                                                    label={t('aisle')}
                                                  />
                                                  <br />
                                                  <Descriptions.Item>
                                                    <Col span={24}>
                                                      <Typography.Text>
                                                        {record.aisle}
                                                      </Typography.Text>
                                                    </Col>
                                                  </Descriptions.Item>
                                                </Descriptions>
                                              </Col>
                                              <Col span={12} className="flex">
                                                <Descriptions bordered>
                                                  <Descriptions.Item
                                                    label={t('item_image')}
                                                  />
                                                  <br />
                                                  <Descriptions.Item>
                                                    <Col span={24}>
                                                      <Image
                                                        alt="Item Image"
                                                        width={150}
                                                        src={record.itemImage}
                                                      />
                                                    </Col>
                                                  </Descriptions.Item>
                                                </Descriptions>
                                              </Col>
                                            </Row>
                                          </Tabs.TabPane>
                                          <Tabs.TabPane key="2" tab="Flags">
                                            <Row className="flex">
                                              <Col span={12} className="flex">
                                                <Descriptions>
                                                  <Descriptions.Item
                                                    className="py-0"
                                                    label={t('weighted')}
                                                  >
                                                    <Switch
                                                      disabled
                                                      size="small"
                                                      checked={
                                                        record.hasRandomWeight
                                                      }
                                                    />
                                                  </Descriptions.Item>
                                                </Descriptions>
                                              </Col>
                                              <Col span={10} offset={2}>
                                                <Descriptions>
                                                  <Descriptions.Item
                                                    label={t(
                                                      'alcoholic_beverage'
                                                    )}
                                                  >
                                                    <Switch
                                                      disabled
                                                      size="small"
                                                      checked={
                                                        record.isAlcoholicBeverage
                                                      }
                                                    />
                                                  </Descriptions.Item>
                                                </Descriptions>
                                              </Col>
                                            </Row>
                                          </Tabs.TabPane>
                                        </Tabs>
                                      </div>
                                    ),
                                    rowExpandable: (record) => record,
                                  }}
                                />
                              </div>
                            }
                          >
                            <Button
                              disabled={row.favStoreId === 'Unknown'}
                              shape="circle"
                              size="small"
                              icon={<SearchOutlined />}
                              onClick={() => {
                                setLoadingItems(true);
                                api
                                  .get(`orders/${row.id}/items/all`)
                                  .then((response) => {
                                    const map = mapOrderItems(
                                      response.data.data.results
                                    );
                                    setOrderItems(map);
                                    setLoadingItems(false);
                                  })
                                  .catch((error) => {
                                    console.error(error);
                                  });
                              }}
                            />
                          </Popover>
                        </Space>
                      ),
                    },
                    {
                      title: t('actions'),
                      align: 'center',
                      className: 'text-xs',
                      render: (row) => (
                        <Space>
                          <Button
                            size="small"
                            type="primary"
                            onClick={() => {
                              setSelectedOrder(row);
                              loadItems(row);
                              setEditOrder(true);
                            }}
                          >
                            {t('edit')}
                          </Button>
                        </Space>
                      ),
                    },
                  ]}
                  expandable={{
                    expandRowByClick: false,
                    expandedRowRender: (record) => (
                      <Tabs
                        onTabClick={() => {
                          loadLogs(record);
                          loadItems(record);
                          setOrder(record);
                        }}
                        onChange={() => {
                          setOrder(record);
                        }}
                      >
                        <Tabs.TabPane key="1" tab="Details">
                          <div className="flex flex-columns justify-content bg-white">
                            <div className="p-6">
                              <Descriptions size="small" title="Payment">
                                <Descriptions.Item label={t('payment')}>
                                  <Typography.Text>
                                    {record.paymentTypeText}
                                  </Typography.Text>
                                </Descriptions.Item>
                                <br />
                                <br />
                                <Descriptions.Item label={t('card')}>
                                  <Typography.Text>
                                    {record.nickName}
                                  </Typography.Text>
                                </Descriptions.Item>
                                <br />
                                <br />
                                <Descriptions.Item label={t('card_holder')}>
                                  <Typography.Text>
                                    {record.cardholderName}
                                  </Typography.Text>
                                </Descriptions.Item>
                                <br />
                                <br />
                                <Descriptions.Item label={t('authorized')}>
                                  <Typography.Text>{`${record.wasAlreadyAuthed}`}</Typography.Text>
                                </Descriptions.Item>
                                <br />
                                <br />
                                <Descriptions.Item label={t('charged')}>
                                  <Typography.Text>{`${record.wasAlreadyCharged}`}</Typography.Text>
                                </Descriptions.Item>
                              </Descriptions>
                            </div>
                            <div className="p-6">
                              <Descriptions
                                Descriptions
                                size="small"
                                title="Totals"
                              >
                                <Descriptions.Item label="Subtotal">
                                  <Typography.Text>
                                    ${record.subTotal}
                                  </Typography.Text>
                                </Descriptions.Item>
                                <br />
                                <br />
                                <Descriptions.Item label="Municipal Tax">
                                  <Typography.Text>
                                    ${record.stateTax}
                                  </Typography.Text>
                                </Descriptions.Item>
                                <br />
                                <br />
                                <Descriptions.Item label="State Tax">
                                  <Typography.Text>
                                    ${record.munTax}
                                  </Typography.Text>
                                </Descriptions.Item>
                                <br />
                                {/* <br />
                                <Descriptions.Item label="Processing Fee">
                                  <Typography.Text>
                                    ${record.processingFee}
                                  </Typography.Text>
                                </Descriptions.Item>
                                <br /> */}
                                <br />
                                <Descriptions.Item label="Total">
                                  <Typography.Text>
                                    ${record.total}
                                  </Typography.Text>
                                </Descriptions.Item>
                              </Descriptions>
                            </div>
                            <div className="p-6">
                              <Descriptions
                                Descriptions
                                size="small"
                                title="Other"
                              >
                                <Descriptions.Item label="Ivu Control ">
                                  <Typography.Text>
                                    {record.ivuCN}
                                  </Typography.Text>
                                </Descriptions.Item>
                                <br />
                                {/* <br />
                                <Descriptions.Item label="(Required/Completed)">
                                  <Typography.Text>{`${record.returnNeeded}/${record.returnCompleted}`}</Typography.Text>
                                </Descriptions.Item>
                                <br /> */}
                                <br />
                                <Descriptions.Item label={t('discount')}>
                                  <Typography.Text>
                                    ${record.discountAmount}
                                  </Typography.Text>
                                </Descriptions.Item>
                                <br />
                                <br />
                                <Descriptions.Item label={t('refund')}>
                                  <Typography.Text>
                                    ${record.returnTotal}
                                  </Typography.Text>
                                </Descriptions.Item>
                                <br />
                                <br />
                                <Descriptions.Item label="Credit">
                                  <Typography.Text>
                                    ${record.credit}
                                  </Typography.Text>
                                </Descriptions.Item>
                              </Descriptions>
                            </div>
                          </div>
                        </Tabs.TabPane>
                      </Tabs>
                    ),
                    rowExpandable: (record) => record,
                  }}
                />
                {editOrder ? <OrderActions open={selectedOrder} /> : null}
                <Image
                  width={200}
                  style={{ display: 'none' }}
                  src={visibleSignatureImg}
                  preview={{
                    visible: visibleSignature,
                    scaleStep,
                    src: visibleSignatureImg,
                    onVisibleChange: (value) => {
                      setVisibleSignature(value);
                      setVisibleSignatureImage(null);
                    },
                  }}
                />
                <Image
                  width={200}
                  style={{ display: 'none' }}
                  src={visibleEbtImg}
                  preview={{
                    visible: visibleEbt,
                    scaleStep,
                    src: visibleEbtImg,
                    onVisibleChange: (value) => {
                      setVisibleEbt(value);
                      setVisibleEbtImage(null);
                    },
                  }}
                />
              </div>
            )}
          </div>
        </Tabs.TabPane>
      </Tabs>
    </div>
  );
}
export default Orders;
