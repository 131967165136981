import React from 'react';
import { useTranslation } from 'react-i18next';
import { Tag, Button } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import {
  CheckCircleOutlined,
  SyncOutlined,
} from '@ant-design/icons';
import { useRecoilValue, useRecoilState, useRecoilValueLoadable, useSetRecoilState } from 'recoil';
import {
  businessAtom,
  paymentCreateAtom,
  selectedPaymentAtom,
  reloadPaymentOptionsAtom,
  profileAtom,
  createDefaultPaymentOptionsAtom
} from '../../../../atoms/Atoms';

function Header() {
  const profile = useRecoilValueLoadable(profileAtom);
  const isAdmin =
    profile.contents && profile?.contents?.roles?.includes('SuperAdmin');
  const business = useRecoilValue(businessAtom);
  const [paymentCreate, setPaymentCreate] = useRecoilState(paymentCreateAtom);
  const setCreateDefaults = useSetRecoilState(createDefaultPaymentOptionsAtom);
  const [reload, setReload] = useRecoilState(reloadPaymentOptionsAtom);
  const [selectedPayment, setSelectedPayment] = useRecoilState(selectedPaymentAtom);
  const { t } = useTranslation();

  return (
    <PageHeader
      className="mb-4 px-0"
      title={t('payment_options')}
      tags={
        <Tag
          color="#2db7f5"
          icon={business ? <CheckCircleOutlined /> : <SyncOutlined spin />}
        >
          {business ? business.name : `${t('loading')}...`}
        </Tag>
      }
      extra={[
        <div className="flex">
          <Button
            key="reload"
            className="mr-2"
            size="small"
            type="primary"
            onClick={() => {
              setReload(!reload);
            }}
          >
            {
            t('reload')
            }
          </Button>
          {isAdmin && (
            <div className="flex">
              <Button
                type="primary"
                className="mr-2"
                key="create"
                size="small"
                onClick={() => {
                  setPaymentCreate(true);
                  setSelectedPayment({
                    selector: 0,
                    chargeType: 0,
                    provider: 0,
                    minCharge: 0,
                    paymentType: 3,
                  });
                }}
              >
                {t('create_new')}
              </Button>
              <Button
                type="primary"
                key="createdefault"
                size="small"
                onClick={() => {
                  setCreateDefaults(true);
                }}
              >
                {t('create_defaults')}
              </Button>
            </div>
          )}
        </div>
      ]}
    />
  );
}

export default Header;
