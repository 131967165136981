import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import _ from 'lodash';
import { Col, Row, Statistic, Typography, Select, Table, Button, Spin } from 'antd';
import { SyncOutlined } from '@ant-design/icons';
import { useRecoilValue } from 'recoil';
import { convertTimeFactor } from '../../../utils/functions';
import { timezoneAtom, businessAtom } from '../../../../atoms/Atoms';
import api from '../../../../api/api';

function Orders() {
  const store = useRecoilValue(businessAtom);
  const [loading, setLoading] = useState(false);
  const timezone = useRecoilValue(timezoneAtom);
  const [data, setData] = useState(null);
  const [timeFactor, setTimeFactor] = useState(0);
  const [top, setTop] = useState(1);
  const days = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 15,
    16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31
  ];
  const weeks = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 15,
    16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31,
    32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47,
    48, 49, 50, 51, 52
  ];
  const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  const { Option, OptGroup } = Select;
  const [usersData, setUsersData] = useState(null);
  const [reload, setReload] = useState(false);
  const { t } = useTranslation();

  function mapData(users) {
    const mapped = _.map(users, (p, index) => ({
      key: index,
      count: p.count,
      startDate: moment(`${p.startDate}`)
        .tz(timezone)
        .format('MMMM Do YYYY'),
      endDate: moment(`${p.endDate}`)
        .tz(timezone)
        .format('MMMM Do YYYY'),
    }));

    return mapped;
  }

  function getData() {
    setLoading(true);
    api
      .get(`analytics/orders/${store.id}/${timeFactor}/${top}`)
      .then((response) => {
        setLoading(false);
        setData(response.data.data);
        const map = mapData(response.data.data.ordersCreated);
        setUsersData(map);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  }

  useEffect(() => {
    if (store) {
      getData();
    }
  }, []);

  useEffect(() => {
    if (store) {
      getData();
    }
  }, [timeFactor, top, store]);

  useEffect(() => {
    if (reload && store) {
      if (store) {
        getData();
      }
    }
  }, [reload]);

  return (
    <div className="p-4 border bg-gray-100 rounded-lg mb-4">
      <div className="flex flex-col" style={{ float: 'right' }}>
        <Button
          className="mb-4"
          size="small"
          type="primary"
          loading={loading}
          icon={<SyncOutlined spin={loading} />}
          onClick={() => {
            setReload(!reload);
          }}
        >
          {t('reload')}
        </Button>
      </div>
      <Row align="middle" gutter={[12, 12]} justify="space-between">
        <Col span={10}>
          <Typography.Title level={5}>{t('order_analytics')}</Typography.Title>
          <Typography.Text type="secondary">
            {t('order_analytics_timespan')}
          </Typography.Text>
        </Col>
        <Col span={6} gutter={2}>
          <div className="flex flex-row">
            {' '}
            <div className="mr-10">
              <Statistic title={t('timespan')} value={`${top} ${t(convertTimeFactor(timeFactor))}(s)`} />
            </div>
          </div>
        </Col>
        <Col span={4}>
          <div>
            <Select
              listHeight={500}
              style={{ width: 150 }}
              disabled={loading}
              onChange={(value) => setTimeFactor(value)}
              defaultValue={0}
            >
              <Option disabled>{t('timeFactor_selector_description')}</Option>
              <Option value={0}>{t('days')}</Option>
              <Option value={1}>{t('weeks')}</Option>
              <Option value={2}>{t('months')}</Option>
              <Option value={3} hidden>{t('year')}</Option>
            </Select>
          </div>
        </Col>
        <Col span={4}>
          {timeFactor === 0 ?
            <div>
              <Select
                listHeight={500}
                disabled={loading}
                style={{ width: 150 }}
                onChange={(value) => setTop(value)}
                defaultValue={top}
              >
                <OptGroup className="text-xs flex items-center">
                  {_.map(days, (num) => (
                    <Option
                      key={num}
                      value={num}
                      className="text-xs flex items-center"
                    >
                      {num}
                    </Option>
                  ))}
                </OptGroup>
              </Select>
            </div>
            : null}
          {timeFactor === 1 ?
            <div>
              <Select
                listHeight={500}
                disabled={loading}
                style={{ width: 150 }}
                onChange={(value) => setTop(value)}
                defaultValue={top}
              >
                <OptGroup className="text-xs flex items-center">
                  {_.map(weeks, (num) => (
                    <Option
                      key={num}
                      value={num}
                      className="text-xs flex items-center"
                    >
                      {num}
                    </Option>
                  ))}
                </OptGroup>
              </Select>
            </div>
            : null}
          {timeFactor === 2 ?
            <div>
              <Select
                listHeight={500}
                disabled={loading}
                style={{ width: 150 }}
                onChange={(value) => setTop(value)}
                defaultValue={top}
              >
                <OptGroup className="text-xs flex items-center">
                  {_.map(months, (num) => (
                    <Option
                      key={num}
                      value={num}
                      className="text-xs flex items-center"
                    >
                      {num}
                    </Option>
                  ))}
                </OptGroup>
              </Select>
            </div>
            : null}
        </Col>
        <Col>
          {' '}
        </Col>
      </Row>
      <br />
      <br />
      <div style={{ align: 'center' }}>
        <div>
          <h3 className="text-lg leading-6 font-medium text-gray-900">{t('orders')}</h3>
          <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
            <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
              <Spin spinning={loading}>
                <dt className="text-sm font-medium text-gray-500 truncate">{t('total_orders')}</dt>
                <dd className="mt-1 text-3xl font-semibold text-gray-900">{data && data.totalOrders}</dd>
              </Spin>
            </div>
            <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
              <Spin spinning={loading}>
                <dt className="text-sm font-medium text-gray-500 truncate">{t('total_pickup')}</dt>
                <dd className="mt-1 text-3xl font-semibold text-gray-900">{data && data.totalPickups}</dd>
              </Spin>
            </div>
            <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
              <Spin spinning={loading}>
                <dt className="text-sm font-medium text-gray-500 truncate">{t('total_deliveries')}</dt>
                <dd className="mt-1 text-3xl font-semibold text-gray-900">{data && data.totalDeliveries}</dd>
              </Spin>
            </div>
          </dl>
        </div>
      </div>
      <br />
      <br />
      <Table
        size="small"
        loading={loading}
        bordered
        pagination={false}
        columns={[
          {
            title: t('start_date'),
            dataIndex: 'startDate',
            key: 'startDate',
            align: 'left',
            className: 'text-xs',
            render: (text) => <span>{text}</span>,
          },
          {
            title: t('end_date'),
            dataIndex: 'endDate',
            key: 'endDate',
            align: 'left',
            className: 'text-xs',
            render: (text) => <span>{text}</span>,
          },
          {
            title: t('count'),
            dataIndex: 'count',
            key: 'count',
            align: 'left',
            className: 'text-xs',
            render: (text) => <span>{text}</span>,
          },
        ]}
        dataSource={usersData}
      />
    </div>
  );
}

export default Orders;
