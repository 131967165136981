import React from 'react';
import HomeHeader from '../../shared-components/header/HomeHeader';
import RegisterForm from './shared-components/RegisterForm';

function Register() {
  return (
    <div>
      <HomeHeader />
      <RegisterForm />
    </div>
  );
}

export default Register;
