import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import _ from 'lodash';
import {
  useRecoilState,
  useRecoilValue,
  useRecoilValueLoadable,
  useSetRecoilState
} from 'recoil';
import {
  Row,
  Col,
  Card,
  Avatar,
  Tooltip,
  Image,
  notification,
  Dropdown,
  Tag,
  Menu } from 'antd';
import { Comment } from '@ant-design/compatible';
import { PageHeader } from '@ant-design/pro-layout';
import {
  FormOutlined,
  TeamOutlined,
  PlusOutlined,
  DownOutlined,
  CopyOutlined
} from '@ant-design/icons';
import moment from 'moment';
import Grocefy from '../../../assets/images/grocefyLogoAlone.png';
import BoardTeam from './shared-components/BoardTeam';
import AssignUserModal from './shared-components/drawers/AssignUserModal';
import EditBoardModal from './shared-components/EditBoardModal';
import { configSelector } from '../../../atoms/Selectors';
import BoardLogs from './shared-components/drawers/BoardLogs';
import ManageBoardTeam from './shared-components/drawers/ManageBoardTeam';
import TicketLogs from './shared-components/drawers/TicketLogs';
import TicketModal from './shared-components/TicketModal';
import EditTicketModal from './shared-components/EditTicketModal';
import AddTicketLogModal from './shared-components/AddTicketLogModal';
import api from '../../../api/api';

import {
  tokenAtom,
  timezoneAtom,
  showBoardLogsAtom,
  showManageBoardTeamAtom,
  selectedTicketBoardAtom,
  refreshTicketsAtom,
  editTicketAtom,
  showTicketUserAssignAtom,
  showTicketLogsAtom,
  editBoardAtom,
  selectedTicketAtom } from '../../../atoms/Atoms';

function Tickets() {
  const config = useRecoilValueLoadable(configSelector);
  const DEFAULT_FILTER = 0;
  const PENDING_FILTER = 1;
  const STARTED_FILTER = 2;
  const COMPLETED_FILTER = 3;
  const REJECTED_FILTER = 4;
  const filterList = [0, 1, 2, 3, 4];
  const { boardId } = useParams();
  const history = useHistory();
  const timezone = useRecoilValue(timezoneAtom);
  const token = useRecoilValue(tokenAtom);
  const [editTicket, setEditTicket] = useRecoilState(editTicketAtom);
  const viewTicketLogs = useRecoilValue(showTicketLogsAtom);
  const showTicketUserAssign = useRecoilValue(showTicketUserAssignAtom);
  const setEditBoard = useSetRecoilState(editBoardAtom);
  const setSelectedTicket = useSetRecoilState(selectedTicketAtom);
  const setViewBoardLogs = useSetRecoilState(showBoardLogsAtom);
  const setViewMangeBoardTeam = useSetRecoilState(showManageBoardTeamAtom);
  const [refreshTickets, setRefreshTickets] = useRecoilState(refreshTicketsAtom);
  const [selectedBoard, setSelectedBoard] = useRecoilState(selectedTicketBoardAtom);
  const [selectedBoardId, setSelectedBoardId] = useState(null);
  const [board, setBoard] = useState(null);
  const [ticketId, setTicketId] = useState(null);
  const [tickets, setTickets] = useState([]);
  const [filteredTickets, setfilteredTickets] = useState([]);
  const [filterToUse, setFilterToUse] = useState(0);
  // let ticketId = '';
  const { t } = useTranslation();

  const getTicketColor = function (status) {
    let bgColor = '';
    let color = '';
    if (status === 0) {
      bgColor = '#dddddd';
      color = '#000000';
    } else if (status === 1) {
      bgColor = '#EED202';
      color = '#000000';
    } else if (status === 2) {
      bgColor = '#008000';
      color = '#ffffff';
    } else if (status === 3) {
      bgColor = '#ff0033';
      color = '#ffffff';
    }
    return { bg: bgColor, color };
  };

  const ticketClick = function (clickedTicket) {
    setSelectedTicket(clickedTicket);
  };

  const showMessage = function (errorMessage) {
    notification.open({
      message: '',
      description: (
        <Comment
          author={<span>Grocefy</span>}
          avatar={<Avatar src={Grocefy} alt="grocefy" />}
          content={
            <p className="text-sm">
              {errorMessage}
            </p>
          }
          datetime={
            <Tooltip title={moment().format('YYYY-MM-DD HH:mm:ss')}>
              <span>{moment().fromNow()}</span>
            </Tooltip>
          }
        />
      ),
    });
  };

  const filterTickets = function () {
    let newTicketList = [];
    if (filterToUse === DEFAULT_FILTER) {
      newTicketList = _.filter(tickets, (x) => x.status === 0 || x.status === 1);
    } else if (filterToUse === STARTED_FILTER) {
      newTicketList = _.filter(tickets, (x) => x.status === 1);
    } else if (filterToUse === PENDING_FILTER) {
      newTicketList = _.filter(tickets, (x) => x.status === 0);
    } else if (filterToUse === COMPLETED_FILTER) {
      newTicketList = _.filter(tickets, (x) => x.status === 2);
    } else if (filterToUse === REJECTED_FILTER) {
      newTicketList = _.filter(tickets, (x) => x.status === 3);
    }
    setfilteredTickets(newTicketList);
  };

  const viewBoardLogsClick = function () {
    setViewBoardLogs(true);
  };

  const viewBoardMembers = function () {
    setViewMangeBoardTeam(true);
  };

  const getFilterName = function (filter) {
    let filterName = 'Default';
    if (filter === 1) {
      filterName = 'Pending';
    } else if (filter === 2) {
      filterName = 'Started';
    } else if (filter === 3) {
      filterName = 'Completed';
    } else if (filter === 4) {
      filterName = 'Rejected';
    }
    return filterName;
  };

  const getTickets = function (id) {
    api.get(`ticketboards/board/${id}`)
      .then((response) => {
        if (response.data.success) {
          setSelectedBoard(response.data.data);
          setBoard(response.data.data);
        } else {
          showMessage(response.data.error);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const newTicketClick = function () {
    const tempTicket = {
      suggestedPriority: 0
    };
    setEditTicket(tempTicket);
  };

  const shareBoardClick = function () {
    const url = `${window.location.origin}/ticketboard/${selectedBoard.id}`;
    navigator.clipboard.writeText(url);
    showMessage('Copied board URL to clipboard');
  };

  useEffect(() => {
    const elems = window.location.pathname.split('/');
    if (elems.length === 4) {
      setTicketId(elems[3]);
    }
    setViewBoardLogs(false);
    setViewMangeBoardTeam(false);
    setRefreshTickets(false);
  }, []);

  useEffect(() => {
    if (selectedBoard) {
      setSelectedBoardId(selectedBoard.Id);
    } else {
      setSelectedBoardId(boardId);
    }
  }, [selectedBoard]);

  useEffect(() => {
    if (refreshTickets) {
      setRefreshTickets(false);
      getTickets(selectedBoard.id);
    }
  }, [refreshTickets]);

  useEffect(() => {
    if (selectedBoardId) {
      if (selectedBoard && selectedBoardId === selectedBoard.id) {
        setBoard(selectedBoard);
      } else {
        getTickets(selectedBoardId);
      }
    }
  }, [selectedBoardId]);

  useEffect(() => {
    if (tickets) {
      filterTickets();
    }
  }, [filterToUse, tickets]);

  useEffect(() => {
    if (board) {
      api.get(`ticketboards/board/tickets/${board.id}`)
        .then((response) => {
          if (response.data.success) {
            setTickets(response.data.data.results);
          } else {
            showMessage(response.data.error);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [board]);

  useEffect(() => {
    if (tickets && ticketId) {
      const temp = _.filter(tickets, { id: ticketId });
      if (temp.length === 1) {
        setSelectedTicket(temp[0]);
      }
    }
  }, [tickets, ticketId]);

  return (
    <>
      <PageHeader
        onBack={() => window.history.back()}
        title={`Ticket Boards / ${selectedBoard ? selectedBoard.name : ''}`}
        extra={[
          <Tag
            key="boardLogs"
            onClick={() => viewBoardLogsClick()}
            color="#2db7f5"
            icon={<FormOutlined />}
          >
            {t('logs')}
          </Tag>,
          <Tag
            key="boardMembers"
            onClick={() => viewBoardMembers()}
            color="#2db7f5"
            icon={<TeamOutlined />}
          >
            {t('members')}
          </Tag>,
          <Tag
            key="newTicket"
            onClick={() => newTicketClick()}
            color="#008000"
            icon={<PlusOutlined />}
          >
            {t('new_ticket')}
          </Tag>,
          <Tag
            key="editBoard"
            onClick={() => {
              setEditBoard(selectedBoard);
            }}
          >
            {t('edit')}
          </Tag>,
          <Tag
            key="shareBoard"
            onClick={() => shareBoardClick()}
          >
            <CopyOutlined />
            {t('share')}
          </Tag>,
        ]}
      />
      {board && (
        <>
          <BoardTeam
            maxCount={6}
            defaultMaleAvatar={
              config &&
              config.contents &&
              config.contents.data &&
              config.contents.data.defaultMaleAvatar
            }
            defaultFemaleAvatar={
              config &&
              config.contents &&
              config.contents.data &&
              config.contents.data.defaultFemaleAvatar
            }
            primaryColor={
              config &&
              config.contents &&
              config.contents.data &&
              config.contents.data.primaryColor
            }
            secondaryColor={
              config &&
              config.contents &&
              config.contents.data &&
              config.contents.data.secondaryColor
            }
          />
          <br />
          <Dropdown
            overlay={
              <Menu selectedKeys={filterToUse ? [filterToUse] : [0]}>
                {_.map(filterList, (b, index) => (
                  <Menu.Item
                    key={`filter${index}${b}`}
                    className="text-xs flex items-center"
                    onClick={() => {
                      setFilterToUse(b);
                    }}
                  >
                    {getFilterName(b)}
                  </Menu.Item>
                ))}
              </Menu>
            }
            arrow="true"
          >
            <button
              type="button"
              className="text-xs truncate px-3 hover:bg-gray-800 text-black-100 hover:text-white font-medium h-16 transition duration-500 ease-in-out outline-none focus:outline-none"
            >
              Filter: {getFilterName(filterToUse)}
              <DownOutlined
                style={{ marginLeft: 10 }}
              />
            </button>
          </Dropdown>
          <Row style={{ background: `#${selectedBoard.colorHex}` }}>
            {filteredTickets && _.map(filteredTickets, (ticket, index) => (
              <Col
                key={`ticket${index}${ticket.id}`}
                className="board-spacing"
                xs={12}
                sm={12}
                md={6}
                lg={4}
              >
                <Card
                  hoverable
                  title={ticket.name}
                  onClick={() => { ticketClick(ticket); }}
                  cover={ticket.imageUrl && (<img alt={ticket.name} src={ticket.imageUrl} />)}
                  headStyle={{
                    backgroundColor: getTicketColor(ticket.status).bg,
                    color: getTicketColor(ticket.status).color,
                    border: 0
                  }}
                >
                  <div>
                    <strong className="bold">{t('created_by')}: </strong>
                    <Avatar
                      src={
                        <Image
                          src={
                            ticket.createdBy.avatar ? ticket.createdBy.avatar : null
                          }
                        />
                      }
                      style={{ marginRight: '10px' }}
                    >
                      {ticket.createdBy.firstName[0]}{ticket.createdBy.lastName[0]}
                    </Avatar>
                    {ticket.createdBy.completeName}
                  </div>
                  <br />
                  {ticket.assignedTo && (
                    <>
                      <div>
                        <strong className="bold">{t('assigned_to')}: </strong>
                        <Avatar
                          src={
                            <Image
                              src={
                                ticket.assignedTo.avatar ? ticket.assignedTo.avatar : null
                              }
                            />
                          }
                          style={{ marginRight: '10px' }}
                        >
                          {ticket.assignedTo.firstName[0]}{ticket.assignedTo.lastName[0]}
                        </Avatar>
                        {ticket.assignedTo.completeName}
                      </div>
                      <br />
                    </>
                  )}
                  <small>
                    <strong className="bold">{t('created_on')}:</strong>
                    {` ${moment(`${ticket.createdAt}+0000`)
                      .tz(timezone)
                      .format('MMM dd YYYY, h:mm:ss a')}`}
                  </small>
                </Card>
              </Col>
            ))}
          </Row>
        </>
      )}
      <BoardLogs />
      <ManageBoardTeam />
      <EditBoardModal />
      {!viewTicketLogs && !editTicket && !showTicketUserAssign && (
        <TicketModal />
      )}
      {viewTicketLogs && (
        <TicketLogs />
      )}
      {showTicketUserAssign && (
        <AssignUserModal />
      )}
      {editTicket && (
        <EditTicketModal />
      )}
      <AddTicketLogModal />
    </>
  );
}

export default Tickets;
