import React from 'react';
import { useRecoilValue } from 'recoil';
import { businessAtom } from '../../../atoms/Atoms';
import Header from './shared-components/Header';
import Options from './shared-components/Options';

function StoreOptions() {
  const business = useRecoilValue(businessAtom);

  return (
    <div>
      <Header />
      <Options />
    </div>
  );
}
export default StoreOptions;
