import React, { useRef, useState } from 'react';
import { useRecoilState, useRecoilValue, useRecoilValueLoadable } from 'recoil';
import { useTranslation } from 'react-i18next';
import ReactToPrint from 'react-to-print';
import moment from 'moment';
import { Button, Col, Row, Modal, Table, Image } from 'antd';
import { PrinterOutlined } from '@ant-design/icons';
import { viewDetailsAtom } from '../../../atoms/Atoms';
import { formatPhoneNumber, getEventBookingStatus, percent } from '../../utils/functions';

import envConfig from '../../../envConfig';
import { configSelector } from '../../../atoms/Selectors';

function EventDetails({ from }) {
  const ref = useRef(null);
  const { t } = useTranslation();
  const [viewDetails, setViewDetails] = useRecoilState(viewDetailsAtom);
  const [loading, setLoading] = useState(false);
  const [details, setDetails] = useState(null);
  const config = useRecoilValueLoadable(configSelector);

  return (
    <Modal
      closable="true"
      centered="true"
      open={viewDetails}
      width="95%"
      onCancel={() => { setViewDetails(false); setDetails(null); }}
      footer={[
        <Button
          key="close"
          disabled={loading}
          onClick={() => setViewDetails(false)}
        >
          {t('close')}
        </Button>
      ]}
    >
      <div>
        <main ref={ref} className="max-w-7xl mx-auto px-4 py-8 space-y-8">
          <div style={{ display: 'flex', alignContent: 'center', justifyContent: 'center', }}>
            <Image
              width="25%"
              height="25%"
              alt="Logo"
              preview="false"
              className="md:ml-3 h-6"
              src={
                config.state === 'hasValue' &&
                config.contents.data.adminHeaderLogoCombo
              }
            />
          </div>
          <section className="text-3xl font-extrabold tracking-tight">
            {viewDetails.eventName}
          </section>
          <section className="flex items-center justify-between text-sm border-b border-gray-200 pb-5 sm:flex sm:justify-between">
            <Col>
              <Row>
                <div className="text-gray-500 mr-1">{t('total_slots')}:</div>
                <div className="font-medium">{viewDetails?.totalSlots}</div>
              </Row>
              <Row>
                <div className="text-gray-500 mr-1">{t('total_bookings')}:</div>
                <div className="font-medium">{viewDetails?.totalBookings}</div>
              </Row>
              <Row>
                <div className="text-gray-500 mr-1">{t('total_payments')}:</div>
                <div className="font-medium">{viewDetails?.totalPayments}</div>
              </Row>
              <Row>
                <div className="text-gray-500 mr-1">{t('total_sales')}:</div>
                <div className="font-medium">${viewDetails?.totalSales}</div>
              </Row>
              {/* <Row>
                <div className="text-gray-500 mr-1">{t('fill_rate')}:</div>
                <div className="font-medium">{((viewDetails?.fillPercentage) * 100).toFixed}%</div>
              </Row> */}
              <Row>
                <div className="text-gray-500 mr-1">{t('vacancy_rate')}:</div>
                <div className="font-medium">{((viewDetails?.vacancyPercentage) * 100).toFixed(2)}%</div>
              </Row>
            </Col>
            <div>
              <ReactToPrint
                content={() => ref.current}
                trigger={() => (
                  <button
                    type="button"
                    className="flex items-center text-white text-xl justify-center bg-blue-400 hover:bg-blue-200 rounded-full w-10 h-10 ring-0 focus:ring-0 outline-none focus:outline-none transition duration-500 ease-in"
                  >
                    <PrinterOutlined className="h-5 w-5" />
                  </button>
                )}
              />
            </div>
          </section>
          <section className="space-y-4">
            <div className="text-xl font-extrabold tracking-tight">
              {t('time_slots')}: {viewDetails && viewDetails?.timeslots?.length}
              <br />
            </div>
            <div>
              <Table
                bordered
                pagination="false"
                key={viewDetails.timeslots.key}
                size="small"
                dataSource={viewDetails.timeslots}
                columns={[
                  {
                    title: t('start_date'),
                    key: 'startTime',
                    dataIndex: 'startTime',
                    align: 'center',
                    className: 'text-xs',
                    render: (row) => (
                      <div className="flex flex-col">
                        <span>{moment(row).add(envConfig.REACT_APP_TIMEZONE_OFFSET, 'hours').format('ddd')}</span>
                        <span>{moment(row).add(envConfig.REACT_APP_TIMEZONE_OFFSET, 'hours').format('MMM D')}</span>
                        <span>{moment(row).add(envConfig.REACT_APP_TIMEZONE_OFFSET, 'hours').format('h:mm A')}</span>
                      </div>
                    ),
                  },
                  {
                    title: t('end_date'),
                    key: 'endTime',
                    dataIndex: 'endTime',
                    align: 'center',
                    className: 'text-xs',
                    render: (row) => (
                      <div className="flex flex-col">
                        <span>{moment(row).add(envConfig.REACT_APP_TIMEZONE_OFFSET, 'hours').format('ddd')}</span>
                        <span>{moment(row).add(envConfig.REACT_APP_TIMEZONE_OFFSET, 'hours').format('MMM D')}</span>
                        <span>{moment(row).add(envConfig.REACT_APP_TIMEZONE_OFFSET, 'hours').format('h:mm A')}</span>
                      </div>
                    ),
                  },
                  {
                    title: t('capacity'),
                    key: 'maxCapacity',
                    dataIndex: 'maxCapacity',
                    align: 'center',
                    className: 'text-xs',
                    render: (row) => (
                      <span>{row.toFixed().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</span>
                    ),
                  },
                  {
                    title: t('total_bookings'),
                    key: 'totalBookings',
                    dataIndex: 'totalBookings',
                    align: 'center',
                    className: 'text-xs',
                    render: (row) => (
                      <span>{row.toFixed().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</span>
                    ),
                  },
                  {
                    title: t('total_payments'),
                    key: 'totalPayments',
                    dataIndex: 'totalPayments',
                    align: 'center',
                    className: 'text-xs',
                    render: (row) => (
                      <span>{row.toFixed().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</span>
                    ),
                  },
                  {
                    title: t('total_sales'),
                    key: 'totalSales',
                    dataIndex: 'totalSales',
                    align: 'center',
                    className: 'text-xs',
                    render: (row) => (
                      <span>{row.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</span>
                    ),
                  },
                  {
                    title: t('fill_rate'),
                    key: 'fillPercentage',
                    dataIndex: 'fillPercentage',
                    align: 'center',
                    className: 'text-xs',
                    render: (row) => (
                      <span>{percent(row)}%</span>
                    ),
                  },
                  {
                    title: t('vacancy_rate'),
                    key: 'vacancyPercentage',
                    dataIndex: 'vacancyPercentage',
                    align: 'center',
                    className: 'text-xs',
                    render: (row) => (
                      <span>{percent(row)}%</span>
                    ),
                  },
                ]}
                expandable={{
                  expandRowByClick: false,
                  defaultExpandAllRows: true,
                  rowExpandable: (record) => record?.bookings?.length > 0,
                  expandedRowRender: (record) => (
                    <div>
                      <br />
                      <Table
                        rowKey="id"
                        size="small"
                        pagination="false"
                        dataSource={record.bookings}
                        columns={[
                          {
                            title: t('name'),
                            key: 'userFirstName',
                            align: 'center',
                            className: 'text-xs',
                            render: (row) => (
                              <span>{`${row.userFirstName} ${row.userLastName}`.trim()}</span>
                            ),
                          },
                          {
                            title: t('phone'),
                            key: 'userPhone',
                            align: 'center',
                            className: 'text-xs',
                            render: (row) => (
                              <span>{`${formatPhoneNumber(row.userPhone)}`}</span>
                            ),
                          },
                          {
                            title: t('email'),
                            key: 'userEmail',
                            align: 'center',
                            className: 'text-xs',
                            render: (row) => (
                              <span>{`${row.userEmail}`}</span>
                            ),
                          },
                          {
                            title: t('status'),
                            key: 'status',
                            dataIndex: 'status',
                            align: 'center',
                            className: 'text-xs',
                            render: (row) => (
                              <span>{t(getEventBookingStatus(row))}</span>
                            ),
                          },
                        ]}
                      />
                    </div>
                  )
                }}
              />
            </div>
          </section>
        </main>
      </div>
    </Modal>
  );
}

export default EventDetails;
