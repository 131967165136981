import { Alert, Button, Drawer, Image, Table, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import { useMount } from 'react-use';
import _ from 'lodash';
import { useRecoilState, useRecoilValue, } from 'recoil';
import { businessAtom, openDrawerAtom, searchTypeAtom, reloadCustomDeptAtom } from '../../../atoms/Atoms';
import Grocefy from '../../../assets/images/grocefyLogoAlone.png';
import api from '../../../api/api';

function CustomDepartmentItems() {
  const [searchType, setSearchType] = useRecoilState(searchTypeAtom);
  const business = useRecoilValue(businessAtom);
  const [loading, setLoading] = useState(null);
  const [reload, setReload] = useRecoilState(reloadCustomDeptAtom);
  const [items, setItems] = useState(null);
  const [openDrawer, setOpenDrawer] = useRecoilState(openDrawerAtom);
  const { t } = useTranslation();

  function mapItems(data) {
    return _.map(data, (p) => ({
      key: p.id,
      brand: p.brand,
      name: p.name,
      description: p.description,
      imageUrl: p.imageUrl,
      upc: p.upc,
      sectionName: p.sectionName,
      price: p.activePrice,
      customDepartment01: p.customBooleanProperty01,
      customDepartment02: p.customBooleanProperty02,
      customDepartment03: p.customBooleanProperty03,
      customDepartment04: p.customBooleanProperty04,
      customDepartment05: p.customBooleanProperty05,
      customDepartment06: p.customBooleanProperty06
    }));
  }

  function updateItem(item) {
    const payload = {
      MenuItemId: item.key,
      CustomDepartment01: searchType === 12 ? !item.customDepartment01 : item.customDepartment01,
      CustomDepartment02: searchType === 13 ? !item.customDepartment02 : item.customDepartment02,
      CustomDepartment03: searchType === 14 ? !item.customDepartment03 : item.customDepartment03,
      CustomDepartment04: searchType === 30 ? !item.customDepartment04 : item.customDepartment04,
      CustomDepartment05: searchType === 31 ? !item.customDepartment05 : item.customDepartment05,
      CustomDepartment06: searchType === 32 ? !item.customDepartment06 : item.customDepartment06
    };
    setLoading(true);
    api
      .post(
        `businesses/customdepartment/batch/update/${business.id}`,
        [payload],
      )
      .then((response) => {
        setLoading(false);
        setReload(true);
        setOpenDrawer(null);
      })
      .catch((err) => {
        setLoading(false);
        console.error(err.message);
      });
  }

  useMount(() => {
    setLoading(true);
    api
      .get(`businesses/customdepartments/items/${business.id}/${searchType}`)
      .then((response) => {
        setLoading(false);
        const map = mapItems(response.data.data.results);
        setItems(map);
      })
      .catch((error) => {
        console.error(error);
      });
  });

  return (
    <Drawer
      title={t('custom_department_items')}
      placement="right"
      closable
      onClose={() => setOpenDrawer(null)}
      open={openDrawer}
      width={600}
    >
      <div>
        {items && items.length === 0 ? (
          <div>
            <Alert message={t('empty_custom_dept_content')} type="error" />
          </div>
        ) : (
          <div>
            <Table
              size="small"
              bordered
              loading={loading}
              pagination={false}
              columns={[
                {
                  title: t('image'),
                  dataIndex: 'imageUrl',
                  key: 'imageUrl',
                  align: 'center',
                  className: 'text-xs',
                  render: (info) => (
                    <Image
                      style={{ margin: 10 }}
                      src={info}
                      width={75}
                    />
                  )
                },
                {
                  title: t('brand'),
                  dataIndex: 'brand',
                  key: 'brand',
                  align: 'center',
                  className: 'text-xs',
                  render: (info) => (
                    <Typography.Text>{info}</Typography.Text>
                  )
                },
                {
                  title: t('name'),
                  dataIndex: 'name',
                  key: 'name',
                  align: 'center',
                  className: 'text-xs',
                  render: (info) => (
                    <Typography.Text>{info}</Typography.Text>
                  )
                },
                {
                  title: t('description'),
                  dataIndex: 'description',
                  key: 'description',
                  align: 'center',
                  className: 'text-xs',
                  render: (info) => (
                    <Typography.Text>{info}</Typography.Text>
                  )
                },
                {
                  title: 'UPC',
                  dataIndex: 'upc',
                  key: 'upc',
                  align: 'center',
                  className: 'text-xs',
                  render: (info) => (
                    <Typography.Text copyable>{info}</Typography.Text>
                  )
                },
                {
                  title: t('price'),
                  dataIndex: 'price',
                  key: 'price',
                  align: 'center',
                  className: 'text-xs',
                  render: (info) => (
                    <Typography.Text>${info.toFixed(2)}</Typography.Text>
                  )
                },
                {
                  title: '',
                  key: 'remove',
                  align: 'center',
                  className: 'text-xs',
                  render: (text, record) => (
                    <Button
                      size="small"
                      type="primary"
                      danger
                      onClick={() => { updateItem(record); }}
                    >
                      {t('remove')}
                    </Button>
                  ),
                },
              ]}
              dataSource={items && items.length > 0 ? items : null}
            />
          </div>
        )}
      </div>
    </Drawer>
  );
}

export default CustomDepartmentItems;
