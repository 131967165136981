import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import { Col, Row, Statistic, Typography, Select, Table, } from 'antd';
import { useRecoilState } from 'recoil';
import { useMount } from 'react-use';
import { convertTimeFactor } from '../../../utils/functions';
import { loadingUserAnalyticsAtom, refreshUserAnalyticsAtom } from '../../../../atoms/Atoms';
import api from '../../../../api/api';
import envConfig from '../../../../envConfig';

function Locations() {
  const [loading, setLoading] = useRecoilState(loadingUserAnalyticsAtom);
  const [map, setMap] = useState(null);
  const [userLocations, setUserLocations] = useState([]);
  const { t, i18n } = useTranslation();
  const [timeFactor, setTimeFactor] = useState(0);
  const [top, setTop] = useState(1);
  const days = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 15,
    16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31
  ];
  const weeks = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 15,
    16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31,
    32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47,
    48, 49, 50, 51, 52
  ];
  const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  const { Option, OptGroup } = Select;
  const [locations, setLocations] = useState(null);
  const [refresh, setRefresh] = useRecoilState(refreshUserAnalyticsAtom);

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: envConfig.REACT_APP_GEO_API_KEY,
    language: 'es-US',
    libraries: ['places']
  });

  const apiHasLoaded = (tempMap) => {
    const bounds = new window.google.maps.LatLngBounds({
      lat: 18.2178311,
      lng: -65.9660781
    });
    tempMap.fitBounds(bounds);
    setMap(tempMap);
  };

  const onUnmount = React.useCallback((tempMap) => {
    setMap(null);
  }, []);

  function mapData(loc) {
    const mapped = _.map(loc, (p, index) => ({
      key: index,
      count: p.count,
      city: p.city
    }));
    return _.sortBy(mapped, (s) => s.startDate, 'desc');
  }

  function getData() {
    setLoading(true);
    api
      .get(`analytics/customers/${timeFactor}/${top}`)
      .then((response) => {
        setLoading(false);
        const mapped = mapData(response.data.data.userLocationsCreated[0].cities);
        setLocations(mapped);
        setUserLocations(response.data.data.allLocationsCreated);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  }

  useMount(() => {
    getData();
  });

  useEffect(() => {
    getData();
  }, [timeFactor, top, refresh]);

  return (
    <div className="p-4 border bg-gray-100 rounded-lg mb-4">
      <Row align="middle" gutter={[12, 12]} justify="space-between">
        <Col span={10}>
          <Typography.Title level={5}>{t('locations_analytics')}</Typography.Title>
          <Typography.Text type="secondary">
            {t('analytics_locations_description')}
          </Typography.Text>
        </Col>
        <Col span={6}>
          <div className="flex flex-row">
            {' '}
            <div className="mr-10">
              <Statistic title="Timespan" value={`${top} ${t(convertTimeFactor(timeFactor))}(s)`} />
            </div>
          </div>
        </Col>
        <Col span={4}>
          <div>
            <Select
              listHeight={500}
              style={{ width: 150 }}
              onChange={(value) => setTimeFactor(value)}
              defaultValue={0}
            >
              <Option disabled>{t('timeFactor_selector_description')}</Option>
              <Option value={0}>{t('days')}</Option>
              <Option value={1}>{t('weeks')}</Option>
              <Option value={2}>{t('months')}</Option>
              <Option value={3} hidden>{t('years')}</Option>
            </Select>
          </div>
        </Col>
        <Col span={4}>
          {timeFactor === 0 ?
            <div>
              <Select
                listHeight={500}
                style={{ width: 150 }}
                onChange={(value) => setTop(value)}
                defaultValue={top}
              >
                <OptGroup className="text-xs flex items-center">
                  {_.map(days, (num) => (
                    <Option
                      key={num}
                      value={num}
                      className="text-xs flex items-center"
                    >
                      {num}
                    </Option>
                  ))}
                </OptGroup>
              </Select>
            </div>
            : null}
          {timeFactor === 1 ?
            <div>
              <Select
                listHeight={500}
                style={{ width: 150 }}
                onChange={(value) => setTop(value)}
                defaultValue={top}
              >
                <OptGroup className="text-xs flex items-center">
                  {_.map(weeks, (num) => (
                    <Option
                      key={num}
                      value={num}
                      className="text-xs flex items-center"
                    >
                      {num}
                    </Option>
                  ))}
                </OptGroup>
              </Select>
            </div>
            : null}
          {timeFactor === 2 ?
            <div>
              <Select
                listHeight={500}
                style={{ width: 150 }}
                onChange={(value) => setTop(value)}
                defaultValue={top}
              >
                <OptGroup className="text-xs flex items-center">
                  {_.map(months, (num) => (
                    <Option
                      key={num}
                      value={num}
                      className="text-xs flex items-center"
                    >
                      {num}
                    </Option>
                  ))}
                </OptGroup>
              </Select>
            </div>
            : null}
        </Col>
        <Col>
          {' '}
        </Col>
      </Row>
      <br />
      <br />
      {isLoaded && (
        <GoogleMap
          mapContainerStyle={{
            height: '400px',
            width: '100%'
          }}
          zoom={20}
          onLoad={apiHasLoaded}
          onUnmount={onUnmount}
          center={{
            lat: 18.2178311,
            lng: -65.9660781
          }}
        >
          {_.map(userLocations, (loc) => (
            <Marker
              position={{ lat: loc.lat, lng: loc.lon }}
            />
          ))}
        </GoogleMap>
      )}
      <br />
      <br />
      <Table
        size="small"
        loading={loading}
        bordered
        pagination={false}
        columns={[
          {
            title: t('city'),
            dataIndex: 'city',
            key: 'city',
            align: 'left',
            className: 'text-xs',
            render: (text) => <span>{text}</span>,
          },
          {
            title: t('count'),
            dataIndex: 'count',
            key: 'count',
            align: 'left',
            className: 'text-xs',
            render: (text) => <span>{text}</span>,
          },
        ]}
        dataSource={locations}
      />
    </div>
  );
}

export default Locations;
