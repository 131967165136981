import React from 'react';
import { useTranslation } from 'react-i18next';
import { Avatar, Dropdown, Menu } from 'antd';
import { useHistory } from 'react-router-dom';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { LoginOutlined, UserOutlined } from '@ant-design/icons';
import { profileAtom, tokenAtom, impersonationAtom } from '../../../atoms/Atoms';
import envConfig from '../../../envConfig';

function UserDropdown() {
  const [profile, setProfile] = useRecoilState(profileAtom);
  const setToken = useSetRecoilState(tokenAtom);
  const setImpersonation = useSetRecoilState(impersonationAtom);
  const { t } = useTranslation();
  const history = useHistory();

  function signOut() {
    localStorage.removeItem(`${envConfig.REACT_APP_PREFIX}-admin-expire`);
    localStorage.removeItem(`${envConfig.REACT_APP_PREFIX}-admin-token`);
    localStorage.removeItem(`${envConfig.REACT_APP_PREFIX}-admin-profile`);
    setProfile(null);
    setToken(null);
    setImpersonation(null);
    window.location.href = '/';
  }

  function show() {
    history.push('/profile');
  }

  return (
    <>
      <Dropdown
        overlay={
          <Menu mode="inline" className="p-0">
            <div className="flex items-center text-gray-500 py-2 px-3 text-xs">
              {profile.email}
            </div>
            <Menu.Item
              id="profile"
              icon={<UserOutlined />}
              className="text-xs flex items-center"
              onClick={() => {
                show();
              }}
            >
              {t('profile')}
            </Menu.Item>
            <Menu.Item
              id="logout"
              icon={<LoginOutlined />}
              className="text-xs flex items-center"
              onClick={() => {
                signOut();
              }}
            >
              {t('sign_out')}
            </Menu.Item>
          </Menu>
        }
        arrow="true"
      >
        <button
          type="button"
          className="flex items-center px-3 text-xs text-gray-100 hover:text-white font-medium h-16 transition duration-500 ease-in-out outline-none focus:outline-none"
        >
          <span className="mr-3 truncate hidden md:flex">
            {profile && profile.firstName}
          </span>
          <Avatar
            src={
              profile.avatar
                ? profile.avatar
                : 'https://gw.alipayobjects.com/zos/rmsportal/BiazfanxmamNRoxxVxka.png'
            }
          />
        </button>
      </Dropdown>
    </>
  );
}

export default UserDropdown;
