import React, { useEffect } from 'react';
import { useRecoilState, useRecoilValueLoadable } from 'recoil';
import { dashboardOptionAtom, profileAtom } from '../../../atoms/Atoms';
import Header from './shared-components/Header';
import Trends from './shared-components/Trends';
import UserAnalytics from './shared-components/UserAnalytics';
import Lifetime from './shared-components/Lifetime';
import YearToDate from './shared-components/YearToDate';
import Insights from './shared-components/Insights';

function StoreDashboard() {
  const [dashboardOption, setDashboardOption] = useRecoilState(dashboardOptionAtom);
  const profile = useRecoilValueLoadable(profileAtom);
  const isAdmin =
    profile.contents && (profile.contents.roles.includes('SuperAdmin') ||
    profile.contents.email.includes('gopanza.com'));
  const isStoreManager =
    isAdmin ||
    (profile.contents &&
      (profile.contents.roles.includes('StoreManager') ||
        profile.contents.roles.includes('StoreAdmin')));
  const isTopAnalytics =
    isAdmin ||
    (profile.contents &&
      (profile.contents.roles.includes('TopAnalytics')));
  const isFinance =
      isAdmin ||
      (profile.contents &&
        (profile.contents.roles.includes('Finance')));

  useEffect(() => {
    if (profile) {
      if (!isStoreManager && !isTopAnalytics && !isFinance && !isAdmin) {
        window.location.href = '/';
      } else {
        setDashboardOption(0);
      }
    }
  }, [profile]);

  return (
    <div>
      <Header />
      {dashboardOption === 0 ?
        <Insights />
        : null}
      {dashboardOption === 1 ?
        <Trends />
        : null}
      {dashboardOption === 2 ?
        <YearToDate />
        : null}
      {dashboardOption === 3 ?
        <Lifetime />
        : null}
      {dashboardOption === 4 ?
        <div>
          <br />
          <UserAnalytics />
        </div>
        : null}
    </div>
  );
}

export default StoreDashboard;
