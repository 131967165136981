import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { TwitterPicker } from 'react-color';
import {
  useRecoilState,
  useRecoilValueLoadable,
  useSetRecoilState
} from 'recoil';
import dayjs from 'dayjs';
import {
  Drawer,
  Avatar,
  Tooltip,
  DatePicker,
  Upload,
  InputNumber,
  Collapse,
  Image,
  Switch,
  Button,
  Select,
  Input,
  notification
} from 'antd';
import { Comment } from '@ant-design/compatible';
import {
  InboxOutlined,
  CloseOutlined
} from '@ant-design/icons';
import moment from 'moment';
import Grocefy from '../../../../assets/images/grocefyLogoAlone.png';
import {
  selectedBannerAtom,
  refreshBannersAtom
} from '../../../../atoms/Atoms';
import { businessesSelector } from '../../../../atoms/Selectors';
import api from '../../../../api/api';

function EditBannerDrawer() {
  const { RangePicker } = DatePicker;
  const { Dragger } = Upload;
  const { Panel } = Collapse;
  const { Option } = Select;
  const businesses = useRecoilValueLoadable(businessesSelector);
  const setRefreshBanners = useSetRecoilState(refreshBannersAtom);
  const [selectedBanner, setSelectedBanner] = useRecoilState(selectedBannerAtom);
  const [loading, setLoading] = useState(false);
  const [banner, setBanner] = useState({});
  const [imageEnglishData, setImageEnglishData] = useState(null);
  const [imageSpanishData, setImageSpanishData] = useState(null);
  const englishDraggerProps = {
    name: 'file',
    accept: 'image/*',
    multiple: false,
    showUploadList: false,
    beforeUpload: (file) => {
      setImageEnglishData(file);
      return false;
    }
  };
  const spanishDraggerProps = {
    name: 'file',
    accept: 'image/*',
    multiple: false,
    showUploadList: false,
    beforeUpload: (file) => {
      setImageSpanishData(file);
      return false;
    }
  };
  const { t } = useTranslation();
  const showMessage = function (message) {
    notification.open({
      message: '',
      description: (
        <Comment
          author={<span>Grocefy</span>}
          avatar={<Avatar src={Grocefy} alt="grocefy" />}
          content={
            <p className="text-sm">
              {message}
            </p>
          }
          datetime={
            <Tooltip title={moment().format('YYYY-MM-DD HH:mm:ss')}>
              <span>{moment().fromNow()}</span>
            </Tooltip>
          }
        />
      ),
    });
  };

  const removeEnlgishImage = function () {
    const temp = JSON.parse(JSON.stringify(banner));
    temp.imageUrl = '';
    setBanner(temp);
  };

  const removeSpanishImage = function () {
    const temp = JSON.parse(JSON.stringify(banner));
    temp.imageUrlSpa = '';
    setBanner(temp);
  };

  const onChangeItemName = function (obj) {
    const temp = JSON.parse(JSON.stringify(banner));
    temp.name = obj.target.value;
    setBanner(temp);
  };

  const onChangeDate = function (date, dateString) {
    const temp = JSON.parse(JSON.stringify(banner));
    const start = dateString[0];
    const end = dateString[1];
    temp.startDate = start;
    temp.endDate = end;
    setBanner(temp);
  };

  const onChangeApproved = function (obj) {
    const temp = JSON.parse(JSON.stringify(banner));
    temp.approved = obj;
    setBanner(temp);
  };

  const onChangeShowInWeb = function (obj) {
    setBanner({
      ...banner,
      showInWeb: obj
    });
  };

  const onChangeShowInApp = function (obj) {
    setBanner({
      ...banner,
      showInApp: obj
    });
  };

  const onChangePlacement = function (obj) {
    const temp = JSON.parse(JSON.stringify(banner));
    temp.placement = obj;
    setBanner(temp);
  };

  const onChangeBusiness = function (obj) {
    const temp = JSON.parse(JSON.stringify(banner));
    temp.businessId = obj;
    setBanner(temp);
  };

  const onChangeAction = function (obj) {
    const temp = JSON.parse(JSON.stringify(banner));
    temp.actionType = obj;
    setBanner(temp);
  };

  const onChangeActionLink = function (obj) {
    const temp = JSON.parse(JSON.stringify(banner));
    temp.actionValue = obj.target.value;
    setBanner(temp);
  };

  const submitBanner = function () {
    setLoading(true);
    const temp = JSON.parse(JSON.stringify(banner));
    temp.startDate = moment(temp.startDate).utc();
    temp.endDate = moment(temp.endDate).utc();
    if (temp.startDate.length < 17) {
      temp.startDate = `${temp.startDate.replace(' ', 'T')}:00.000`;
      temp.endDate = `${temp.endDate.replace(' ', 'T')}:00.000`;
    }
    let url = 'businesses/bannerad/';
    url += (temp.id ? `update/${temp.id}` : 'create');
    api.post(
      url,
      temp
    )
      .then((response) => {
        setLoading(false);
        if (response.data.success) {
          setRefreshBanners(true);
          setSelectedBanner(null);
        } else {
          showMessage(response.data.error);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  const archiveClick = function () {
    setLoading(true);
    api.post(
      `businesses/bannerad/delete/${banner.id}`,
      { }
    )
      .then((response) => {
        setLoading(false);
        if (response.data.success) {
          setSelectedBanner(null);
          setRefreshBanners(true);
        } else {
          console.error(response.data.error);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  useEffect(() => {
    if (selectedBanner) {
      const temp = JSON.parse(JSON.stringify(selectedBanner));

      if (!temp.id) {
        temp.actionType = 0;
        temp.placement = 0;
        temp.businessId = '';
        temp.backgroundColor = '';
        temp.ordering = 1;
        temp.showInApp = false;
        temp.showInWeb = false;
      } else {
        temp.startDate = moment(temp.startDateTime).add(-4, 'hours');
        temp.endDate = moment(temp.endDateTime).add(-4, 'hours');
      }
      if (!temp.startDate || temp.startDate.length === 0) {
        temp.startDate = moment();
      }
      if (!temp.endDate || temp.endDate.length === 0) {
        temp.endDate = moment();
      }
      setBanner(temp);
    }
  }, [selectedBanner]);

  useEffect(() => {
    if (imageEnglishData) {
      const file = imageEnglishData;
      const boundary = Date.now();
      const data = new FormData();
      data.append('file', file);
      setLoading(true);
      api
        .post('utility/banner/uploadfile', data)
        .then((response) => {
          setLoading(false);
          setImageEnglishData(null);
          if (response.data.success) {
            const temp = JSON.parse(JSON.stringify(banner));
            temp.imageUrl = response.data.data;
            setBanner(temp);
          } else {
            showMessage(response.data.error);
          }
        })
        .catch((error) => {
          setLoading(false);
          setImageEnglishData(null);
          console.error(error);
        });
    }
  }, [imageEnglishData]);

  useEffect(() => {
    if (imageSpanishData) {
      const file = imageSpanishData;
      const boundary = Date.now();
      const data = new FormData();
      data.append('file', file);
      setLoading(true);
      api
        .post('utility/banner/uploadfile', data)
        .then((response) => {
          setLoading(false);
          setImageSpanishData(null);
          if (response.data.success) {
            const temp = JSON.parse(JSON.stringify(banner));
            temp.imageUrlSpa = response.data.data;
            setBanner(temp);
          } else {
            showMessage(response.data.error);
          }
        })
        .catch((error) => {
          setLoading(false);
          setImageSpanishData(null);
          console.error(error);
        });
    }
  }, [imageSpanishData]);

  return (
    <Drawer
      title={`${banner && banner.id ? t('update') : t('create')} Banner`}
      placement="right"
      closable
      onClose={() => setSelectedBanner(null)}
      open={selectedBanner}
      width={400}
    >
      {selectedBanner && (
        <>
          <div
            style={{
              float: 'right',
              marginBottom: '10px'
            }}
          >
            <Button
              type="primary"
              danger
              disabled={loading}
              hidden={!banner.id}
              onClick={() => { archiveClick(); }}
            >
              {t('archive')}
            </Button>
          </div>
          <div style={{ marginBottom: 10 }}>
            <strong>{t('name')}:</strong>
            <Input
              disabled={loading}
              placeholder="Name"
              onChange={(obj) => onChangeItemName(obj)}
              value={banner.name}
            />
          </div>

          <Collapse defaultActiveKey={['1']}>
            <Panel header="General Data" key="1">
              <div style={{ marginBottom: 10 }}>
                <strong>Placement: </strong>
                <Select
                  defaultValue={banner.placement}
                  value={banner.placement}
                  style={{ width: 120 }}
                  disabled={loading}
                  onChange={(obj) => onChangePlacement(obj)}
                >
                  <Option value={0}>{t('top')}</Option>
                  <Option value={1}>{t('bottom')}</Option>
                  <Option value={2}>{t('left')}</Option>
                  <Option value={3}>{t('right')}</Option>
                  <Option value={4}>Popup</Option>
                </Select>
              </div>
              <div style={{ marginBottom: 10 }}>
                <strong>{t('business')}: </strong>
                <Select
                  defaultValue={banner.businessId}
                  value={banner.businessId}
                  style={{ width: 120 }}
                  disabled={loading}
                  onChange={(obj) => onChangeBusiness(obj)}
                >
                  <Option value="">None</Option>
                  {businesses.state === 'hasValue' && _.map(businesses.contents.data.data.results, (business) => (
                    <Option
                      value={business.id}
                      key={business.id}
                    >
                      {business.name}
                    </Option>
                  ))}
                </Select>
              </div>
              <div style={{ marginBottom: 10 }}>
                <strong>{t('approved')}:</strong>
                <Switch
                  checked={banner.approved}
                  disabled={loading}
                  onChange={(checked) => onChangeApproved(checked)}
                />
              </div>
              <div style={{ marginBottom: 10 }}>
                <strong>{t('ordering')}</strong>
                <br />
                <InputNumber
                  disabled={loading}
                  min={1}
                  onChange={(obj) => {
                    const temp = JSON.parse(JSON.stringify(banner));
                    temp.ordering = obj;
                    setBanner(temp);
                  }}
                  value={banner.ordering}
                />
              </div>
              <div style={{ marginBottom: 10 }}>
                <strong>{t('show_in_app')}:</strong>
                <Switch
                  checked={banner.showInApp}
                  disabled={loading}
                  onChange={(checked) => onChangeShowInApp(checked)}
                />
              </div>
              <div style={{ marginBottom: 10 }}>
                <strong>{t('show_in_web')}:</strong>
                <Switch
                  checked={banner.showInWeb}
                  disabled={loading}
                  onChange={(checked) => onChangeShowInWeb(checked)}
                />
              </div>
            </Panel>
            <Panel header={t('media')} key="2">
              <div style={{ marginBottom: 10 }}>
                <Tooltip title={t('image_requirements')}>
                  <strong>{t('english_image')}:</strong>
                </Tooltip>
                <Input
                  className="w-full"
                  disabled={loading}
                  loading={loading}
                  value={banner.imageUrl}
                  onChange={(obj) => {
                    const temp = JSON.parse(JSON.stringify(banner));
                    temp.imageUrl = obj.target.value;
                    setBanner(temp);
                  }}
                />
                <Dragger {...englishDraggerProps}>
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p
                    className="ant-upload-text"
                    style={{ margin: 10 }}
                  >
                    {t('click_or_drag_content')}
                  </p>
                </Dragger>
                {banner.imageUrl && (
                  <>
                    <Image
                      alt={banner.imageUrl}
                      src={banner.imageUrl}
                      height={75}
                    />
                    <Button
                      disabled={loading}
                      type="primary"
                      danger
                      onClick={() => { removeEnlgishImage(); }}
                    >
                      <CloseOutlined />
                    </Button>
                  </>
                )}
              </div>
              <div style={{ marginBottom: 10 }}>
                <Tooltip title={t('image_requirements')}>
                  <strong>{t('spanish_image')}:</strong>
                </Tooltip>
                <Input
                  className="w-full"
                  disabled={loading}
                  loading={loading}
                  value={banner.imageUrlSpa}
                  onChange={(obj) => {
                    const temp = JSON.parse(JSON.stringify(banner));
                    temp.imageUrlSpa = obj.target.value;
                    setBanner(temp);
                  }}
                />
                <Dragger {...spanishDraggerProps}>
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p
                    className="ant-upload-text"
                    style={{ margin: 10 }}
                  >
                    {t('click_or_drag_content')}
                  </p>
                </Dragger>
                {banner.imageUrlSpa && (
                  <>
                    <Image
                      alt={banner.imageUrlSpa}
                      src={banner.imageUrlSpa}
                      height={75}
                    />
                    <Button
                      disabled={loading}
                      type="primary"
                      danger
                      onClick={() => { removeSpanishImage(); }}
                    >
                      <CloseOutlined />
                    </Button>
                  </>
                )}
              </div>
              <div className="mb-4">
                <strong>{t('background_color')}</strong>
                <Input
                  onChange={(obj) => {
                    setBanner({
                      ...banner,
                      backgroundColor: obj.target.value
                    });
                  }}
                  value={banner?.backgroundColor}
                />
                <TwitterPicker
                  color={banner?.backgroundColor == null ? 'fffff' : banner?.backgroundColor}
                  onChangeComplete={(obj) => {
                    let hex = '';
                    if (obj.hex) {
                      hex = obj.hex;
                    } else {
                      hex = obj.target.value;
                    }
                    setBanner({
                      ...banner,
                      backgroundColor: hex
                    });
                  }}
                />
              </div>
            </Panel>
            <Panel header={t('date')} key="3">
              <div style={{ marginBottom: 10 }}>
                {/* <strong>{'Start Date -> End Date:'}</strong> */}
                <RangePicker
                  showTime={{ format: 'HH:mm' }}
                  defaultValue={[
                    dayjs(banner?.startDate, 'YYYY-MM-DD HH:mm'),
                    dayjs(banner?.endDate, 'YYYY-MM-DD HH:mm')
                  ]}
                  onChange={(date, dateString) => onChangeDate(date, dateString)}
                />
              </div>
            </Panel>
            <Panel header={t('action')} key="4">
              <div style={{ marginBottom: 10 }}>
                <strong>{t('action')}:</strong>
                <Select
                  defaultValue={banner.actionType}
                  value={banner.actionType}
                  style={{ width: 120 }}
                  disabled={loading}
                  onChange={(obj) => onChangeAction(obj)}
                >
                  <Option value={0}>{t('none')}</Option>
                  <Option value={1}>{t('link')}</Option>
                  <Option value={2}>{t('search')}</Option>
                </Select>
                <div
                  style={{ marginBottom: 10 }}
                  hidden={!banner.actionType || banner.actionType === 0}
                >
                  <strong>{t('action')}:</strong>
                  <Input
                    disabled={loading}
                    onChange={(obj) => onChangeActionLink(obj)}
                    value={banner.actionValue}
                  />
                </div>
              </div>
            </Panel>
          </Collapse>
          <div
            style={{
              float: 'right',
              marginTop: '10px'
            }}
          >
            <Button
              style={{
                marginLeft: '10px'
              }}
              disabled={loading}
              type="primary"
              onClick={() => { submitBanner(); }}
            >
              {banner.id ? t('update') : t('create')}
            </Button>
          </div>
        </>
      )}
    </Drawer>
  );
}

export default EditBannerDrawer;
