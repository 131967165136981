import React from 'react';
import { Tag, Tabs } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { CheckCircleOutlined, SyncOutlined } from '@ant-design/icons';
import { businessAtom } from '../../../atoms/Atoms';
import Redemptions from './shared-components/Redemptions';
import Coupons from './shared-components/Coupons';

function StoreCoupons() {
  const business = useRecoilValue(businessAtom);
  const { t } = useTranslation();

  return (
    <>
      <div>
        <PageHeader
          title={t('coupons')}
          tags={
            <Tag
              color="#2db7f5"
              icon={business ? <CheckCircleOutlined /> : <SyncOutlined spin />}
            >
              {business ? business.name : `${t('loading')}...`}
            </Tag>
          }
        />
        <Tabs defaultActiveKey="1">
          <Tabs.TabPane tab={t('coupons')} key="1">
            <Coupons />
          </Tabs.TabPane>{' '}
          <Tabs.TabPane tab={t('redemptions')} key="2">
            <Redemptions />
          </Tabs.TabPane>
        </Tabs>
      </div>
    </>
  );
}

export default StoreCoupons;
