import React, { useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import { useRecoilState, useRecoilValue } from 'recoil';
import { PrinterOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { Button, Col, Row, Modal } from 'antd';
import ReactToPrint from 'react-to-print';
import { timezoneAtom, selectedTerminalDataAtom } from '../../../atoms/Atoms';
import { renderShortDateHour } from '../../utils/functions';
import { IFrame } from '../IFrame';

function TerminalDataModal() {
  const ref = useRef(null);
  const receiptRef = useRef(null);
  const { t } = useTranslation();
  const [selectedTerminalData, setSelectedTerminalData] = useRecoilState(
    selectedTerminalDataAtom
  );
  const [loading, setLoading] = useState(false);
  const timezone = useRecoilValue(timezoneAtom);
  const [settlementReceipt, setSettlementReceipt] = useState(null);
  const [receiptVisible, setReceiptVisible] = useState(false);
  const [isContentLoaded, setIsContentLoaded] = useState(false); // Track whether content is loaded
  const [isLoading, setIsLoading] = useState(true);

  function showReceipt(settlementHtml) {
    let tempHtml = settlementHtml;
    tempHtml = tempHtml.replace(
      '</title><script',
      '</title><link rel="stylesheet" type="text/css" href="https://grocefystorage.blob.core.windows.net/evertecterminal/ReceiptStyle.css"><script'
    );
    tempHtml = tempHtml.replace(
      'ATHLogo.png',
      'https://grocefystorage.blob.core.windows.net/evertecterminal/ath-logo-02.png'
    );
    tempHtml = tempHtml.replace(
      '<div class="CTLS_CONTENT"></div>',
      '<div class="CTLS_CONTENT">&nbsp;</div>'
    );
    tempHtml = tempHtml.replace(
      '<div class="CTLS_LABEL"></div>',
      '<div class="CTLS_LABEL">&nbsp;</div>'
    );
    tempHtml = tempHtml.replace(
      '<div class="CTLS_CONTENT">         </div>',
      '<div class="CTLS_CONTENT">&nbsp;</div>'
    );
    tempHtml = tempHtml.replace(
      '<div class="CTLS_LABEL">         </div>',
      '<div class="CTLS_LABEL">&nbsp;</div>'
    );
    setSettlementReceipt(tempHtml);
    setReceiptVisible(true);
  }

  // Callback function to set isContentLoaded to true when IFrame content is loaded
  function handleContentLoad() {
    setIsContentLoaded(true);
  }

  const handleMessage = (event) => {
    if (event.data.action === 'receipt-loaded') {
      setIsLoading(false);
    }
  };

  const printIframe = (id) => {
    const iframe = document.frames
      ? document.frames[id]
      : document.getElementById(id);
    const iframeWindow = iframe.contentWindow || iframe;

    iframe.focus();
    iframeWindow.print();

    return false;
  };

  return (
    <>
      <Modal
        closable
        centered
        open={selectedTerminalData}
        width="25%"
        onCancel={() => {
          setSelectedTerminalData(null);
        }}
        footer={[
          <Button
            key="close"
            type="primary"
            danger
            disabled={loading}
            onClick={() => setSelectedTerminalData(null)}
          >
            {t('close')}
          </Button>,
        ]}
      >
        <div>
          <main ref={ref} className="max-w-7xl mx-auto px-4 py-8 space-y-8">
            <section className="text-2xl font-extrabold tracking-tight">
              {t('terminal_data')}
            </section>
            <div className="max-h-64 overflow-y-auto">
              {_.map(selectedTerminalData, (tData) => (
                <section
                  key={tData.id}
                  className="flex items-center justify-between text-sm border-b border-gray-200 py-5 sm:flex sm:justify-between"
                >
                  <Col>
                    <Row>
                      <div className="font-medium">{tData?.type}</div>
                    </Row>
                    <Row>
                      <div className="font-medium">{tData?.ivuControl}</div>
                    </Row>
                    <Row>
                      <div className="font-medium">
                        {renderShortDateHour(tData?.createdAt, timezone)}
                      </div>
                    </Row>
                  </Col>
                  <Col>
                    <Row style={{ marginBottom: '10px' }}>
                      <Button
                        type="primary"
                        disabled={!tData?.customerReceipt}
                        size="small"
                        onClick={() => showReceipt(tData?.customerReceipt)}
                      >
                        {t('customer_receipt')}
                      </Button>
                    </Row>
                    <Row>
                      <Button
                        type="primary"
                        disabled={!tData?.merchantReceipt}
                        size="small"
                        onClick={() => {
                          showReceipt(tData?.merchantReceipt);
                        }}
                      >
                        {t('merchant_receipt')}
                      </Button>
                    </Row>
                  </Col>
                </section>
              ))}
            </div>
          </main>
        </div>
      </Modal>
      <Modal
        closable="true"
        centered="true"
        height={650}
        width={430}
        onCancel={() => setSettlementReceipt(null)}
        open={settlementReceipt}
        footer={[
          <div key="footer">
            <Button
              type="primary"
              onClick={() => printIframe('receipt')}
            >
              <PrinterOutlined className="h-5 w-5" />
            </Button>
            <Button
              key="back"
              type="primary"
              danger
              onClick={() => setSettlementReceipt(null)}
            >
              {t('close')}
            </Button>
          </div>,
        ]}
      >
        <IFrame
          ref={receiptRef}
          id="receipt"
          className="mx-auto mt-6"
          height={650}
          width={400}
          onLoad={handleContentLoad} // Call handleContentLoad when content is loaded
        >
          <div className="mx-auto">
            <div dangerouslySetInnerHTML={{ __html: settlementReceipt }} />
          </div>
        </IFrame>
      </Modal>
    </>
  );
}

export default TerminalDataModal;
