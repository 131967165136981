import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import {
  Input,
  Button,
  Select,
  Typography,
  Tooltip,
  notification,
  Avatar
} from 'antd';
import { Comment } from '@ant-design/compatible';
import api from '../../../../api/api';
import Grocefy from '../../../../assets/images/grocefyLogoAlone.png';

function RegisterForm() {
  const { Title } = Typography;
  const { t } = useTranslation();
  const { Option } = Select;
  const [submitting, setSubmitting] = useState(false);
  const [allowSubmit, setAllowSubmit] = useState(false);
  const [data, setData] = useState({
    language: 0,
    gender: 0,
    firstNameValid: '',
    lastNameValid: '',
    phoneNumberValid: '',
    emailValid: '',
    passwordValid: '',
    confirmPasswordValid: '',
  });

  useEffect(() => {
    if (data) {
      let valid = true;
      valid = valid && data.firstNameValid === 'warning';
      valid = valid && data.lastNameValid === 'warning';
      valid = valid && data.phoneNumberValid === 'warning';
      valid = valid && data.emailValid === 'warning';
      valid = valid && data.passwordValid === 'warning';
      valid = valid && data.confirmPasswordValid === 'warning';
      setAllowSubmit(valid);
    }
  }, [data]);

  const showMessage = function (message) {
    notification.open({
      message: '',
      description: (
        <Comment
          author={<span>Grocefy</span>}
          avatar={<Avatar src={Grocefy} alt="grocefy" />}
          content={
            <p className="text-sm">
              {message}
            </p>
          }
          datetime={
            <Tooltip title={moment().format('YYYY-MM-DD HH:mm:ss')}>
              <span>{moment().fromNow()}</span>
            </Tooltip>
          }
        />
      ),
    });
  };

  const submit = () => {
    setSubmitting(true);
    api
      .post('oauth/local/register', data, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        setSubmitting(false);
        if (response.status === 200 && response.data.error) {
          showMessage(response.data.error);
        } else if (response.status === 200) {
          window.location.href = '/';
        }
      })
      .catch((error) => {
        setSubmitting(false);
        showMessage(error.message);
      });
  };

  return (
    <div className="mx-64">
      <Title className="mx-2 mt-4">{t('register')}</Title>
      <div className="grid grid-cols-2">
        <div className="mx-2 mt-4">
          <strong>{t('first_name')}:</strong>
          <Input
            status={data.firstNameValid}
            disabled={submitting}
            loading={submitting}
            placeholder={t('first_name')}
            onChange={(obj) => setData({
              ...data,
              firstName: obj.target.value,
              firstNameValid: obj.target.value.trim().length > 0 ? 'warning' : 'error'
            })}
            value={data.firstName}
          />
        </div>
        <div className="mx-2 mt-4">
          <strong>{t('last_name')}:</strong>
          <Input
            status={data.lastNameValid}
            disabled={submitting}
            loading={submitting}
            placeholder={t('last_name')}
            onChange={(obj) => setData({
              ...data,
              lastName: obj.target.value,
              lastNameValid: obj.target.value.trim().length > 0 ? 'warning' : 'error'
            })}
            value={data.lastName}
          />
        </div>
        <div className="mx-2 mt-4">
          <strong>{t('email')}:</strong>
          <Input
            status={data.emailValid}
            disabled={submitting}
            loading={submitting}
            placeholder={t('email')}
            onChange={(obj) => {
              const pattern = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/;
              setData({
                ...data,
                email: obj.target.value,
                emailValid: obj.target.value.trim().length > 0 && pattern.test(obj.target.value.trim()) ? 'warning' : 'error'
              });
            }}
            value={data.email}
          />
        </div>
        <div className="mx-2 mt-4">
          <strong>{t('phone')}:</strong>
          <Input
            status={data.phoneNumberValid}
            disabled={submitting}
            loading={submitting}
            placeholder={t('phone')}
            onChange={(obj) => {
              const pattern = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im;
              setData({
                ...data,
                phoneNumber: obj.target.value,
                phoneNumberValid: obj.target.value.trim().length > 0 && pattern.test(obj.target.value.trim()) ? 'warning' : 'error'
              });
            }}
            value={data.phoneNumber}
          />
        </div>
        <div className="mx-2 mt-4">
          <strong>{t('gender')}:</strong>
          <Select
            value={data.gender}
            className="w-full"
            disabled={submitting}
            loading={submitting}
            onChange={(obj) => setData({
              ...data,
              gender: obj
            })}
          >
            <Option value={0}>{t('male')}</Option>
            <Option value={1}>{t('female')}</Option>
            <Option value={1}>{t('not_say')}</Option>
          </Select>
        </div>
        <div className="mx-2 mt-4">
          <strong>{t('preferred_language')}:</strong>
          <br />
          <Select
            className="w-full"
            value={data.language}
            disabled={submitting}
            loading={submitting}
            onChange={(obj) => setData({
              ...data,
              language: obj
            })}
          >
            <Option value={0}>{t('english')}</Option>
            <Option value={1}>{t('spanish')}</Option>
          </Select>
        </div>
        <div className="mx-2 mt-4">
          <strong>{t('password')}:</strong>
          <Input.Password
            status={data.passwordValid}
            disabled={submitting}
            loading={submitting}
            placeholder={t('password')}
            onChange={(obj) => setData({
              ...data,
              password: obj.target.value,
              passwordValid: obj.target.value.trim().length > 0 ? 'warning' : 'error',
              confirmPasswordValid:
                obj.target.value.trim().length > 0 && obj.target.value === data.confirmPassword ? 'warning' : 'error'
            })}
            value={data.password}
          />
        </div>
        <div className="mx-2 mt-4">
          <strong>{t('confirm_password')}:</strong>
          <Input.Password
            status={data.confirmPasswordValid}
            disabled={submitting}
            loading={submitting}
            placeholder={t('confirm_password')}
            onChange={(obj) => setData({
              ...data,
              confirmPassword: obj.target.value,
              confirmPasswordValid:
                obj.target.value.trim().length > 0 && obj.target.value === data.password ? 'warning' : 'error'
            })}
            value={data.confirmPassword}
          />
        </div>
      </div>
      <div className="mx-2 mt-4 items-center">
        <Button
          className="w-full"
          loading={submitting}
          disabled={submitting || !allowSubmit}
          onClick={() => submit()}
          type="primary"
        >
          {t('submit')}
        </Button>
      </div>
    </div>
  );
}

export default RegisterForm;
