import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useSetRecoilState, useRecoilState, useRecoilValue } from 'recoil';
import _ from 'lodash';
import moment from 'moment';
import {
  Badge,
  Button,
  Col,
  Row,
  Table,
  Tag,
  Typography,
  Space,
  Popover,
  notification,
  Tooltip,
  Avatar
} from 'antd';
import { Comment } from '@ant-design/compatible';
import {
  CheckCircleOutlined,
  CreditCardOutlined,
  FileExcelOutlined,
  BankOutlined,
  SyncOutlined,
  CloseCircleOutlined,
  SearchOutlined,
  CloudDownloadOutlined,
} from '@ant-design/icons';
import { useJsonToCsv } from 'react-json-csv';
import {
  paypalPageAtom,
  paypalRowsAtom,
  timezoneAtom,
  businessAtom,
  paypalPayoutsAtom,
  loadingPaypalPayoutsAtom,
  refreshPaypalPayoutsAtom
} from '../../../../../atoms/Atoms';
import Grocefy from '../../../../../assets/images/grocefyLogoAlone.png';
import api from '../../../../../api/api';

function Activities() {
  const { t } = useTranslation();
  const timezone = useRecoilValue(timezoneAtom);
  const payouts = useRecoilValue(paypalPayoutsAtom);
  const setRefreshPayPalPayouts = useSetRecoilState(refreshPaypalPayoutsAtom);
  const loading = useRecoilValue(loadingPaypalPayoutsAtom);
  const [rows, setRows] = useRecoilState(paypalRowsAtom);
  const [current, setCurrent] = useRecoilState(paypalPageAtom);
  const [payoutsExportData, setPayoutsExportData] = useState(null);
  const { saveAsCsv } = useJsonToCsv();
  const [tableData, setTableData] = useState([]);
  const store = useRecoilValue(businessAtom);
  const history = useHistory();

  if (store == null || store?.id === -1 || store?.id === -2) {
    history.push('/');
    return null;
  }

  const fields = {
    amount: t('amount'),
    status: t('status'),
    description: t('description'),
    createdAt: t('date'),
    transfers: t('transfers'),
  };

  const transactionFields = {
    createdAt: t('transaction_date'),
    amount: t('amount'),
    orderDate: t('order_date'),
    orderConfirmationCode: t('confirmation_code'),
    customerName: t('customer'),
    orderTotal: t('order_total'),
  };

  const showMessage = function (message) {
    notification.open({
      message: '',
      description: (
        <Comment
          author={<span>Grocefy</span>}
          avatar={<Avatar src={Grocefy} alt="grocefy" />}
          content={
            <p className="text-sm">
              {message}
            </p>
          }
          datetime={
            <Tooltip title={moment().format('YYYY-MM-DD HH:mm:ss')}>
              <span>{moment().fromNow()}</span>
            </Tooltip>
          }
        />
      ),
    });
  };

  function retryFailedPayout(payout) {
    const temp = JSON.parse(JSON.stringify(tableData));
    const tempPayout = payout;
    for (let i = 0; i < temp.length; i++) {
      if (temp[i].key === payout.key) {
        temp[i].loading = true;
      }
    }
    setTableData(temp);
    api
      .post(
        `orders/payout/retry/${payout.key}`,
        { }
      )
      .then((response) => {
        if (response.data.success) {
          setRefreshPayPalPayouts(true);
        } else {
          for (let i = 0; i < temp.length; i++) {
            if (temp[i].key === tempPayout.key) {
              temp[i].loading = false;
            }
          }
          setTableData(temp);
          showMessage(response.data.error);
        }
      })
      .catch((error) => {
        for (let i = 0; i < temp.length; i++) {
          if (temp[i].key === tempPayout.key) {
            temp[i].loading = false;
          }
        }
        setTableData(temp);
      });
  }

  function mapPayoutStatus(status) {
    switch (status) {
      case 0:
        return t('processing');
      case 1:
        return t('succeeded');
      case 2:
        return t('failed');
      default:
        return t('Canceled');
    }
  }

  function mapPayouts(data) {
    return _.map(data, (p) => ({
      key: p.id,
      amount: p.amount,
      status: p.status,
      description: p.description,
      createdAt: moment(`${p.createdAt}+0000`)
        .tz(timezone)
        .format('MMMM Do YYYY, h:mm:ss a'),
      payments: _.map(p.transfers, (v) => ({
        key: v.id,
        ...v,
      })),
    }));
  }

  useEffect(() => {
    if (payouts) {
      const data = _.map(payouts?.payouts, (p) => ({
        amount: p.amount,
        status: mapPayoutStatus(p.status),
        description: p.description,
        createdAt: moment(`${p.createdAt}+0000`)
          .tz(timezone)
          .format('MMMM Do YYYY, h:mm:ss a'),
        transfers: p.transfers.length,
      }));
      setPayoutsExportData(data);
      setTableData(mapPayouts(payouts.payouts));
    }
  }, [payouts]);

  return (
    <div className="p-4 border bg-gray-100 rounded-lg mb-4">
      <Row align="middle" gutter={[8, 8]}>
        <Col xs={24} md={12}>
          <Typography.Title level={5}>{t('payouts')}</Typography.Title>
          <Typography.Text type="secondary">
            {t('payouts_content')}
          </Typography.Text>
        </Col>
        <Col xs={24} md={12} className="text-left md:text-right">
          <Button
            id="reload"
            type="primary"
            key="reload"
            size="small"
            onClick={() => setRefreshPayPalPayouts(true)}
          >
            {t('reload')}
          </Button>
          {' '}
          <Button
            size="small"
            type="primary"
            icon={<FileExcelOutlined />}
            onClick={() => {
              saveAsCsv({
                data: payoutsExportData,
                fields,
                filename: `paypal_payouts_${new Date().toLocaleDateString()}`,
              });
            }}
          >
            {t('export_payouts')}
          </Button>
        </Col>
      </Row>
      <Row>
        <Col xs={24}>
          <Table
            size="small"
            loading={loading}
            bordered
            pagination={{
              current,
              hideOnSinglePage: true,
              showSizeChanger: true,
              size: 'small',
              pageSize: rows,
              total: payouts?.count,
              pageSizeOptions: [10, 20, 30, 40, 50],
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} ${t('out_of')} ${total} ${t('items')}`,
              onShowSizeChange: (page, size) => {
                setRows(size);
              },
              onChange: (page) => {
                setCurrent(page);
              },
            }}
            columns={[
              {
                title: t('amount'),
                dataIndex: 'amount',
                key: 'amount',
                align: 'center',
                className: 'text-xs font-medium',
                render: (text) => (
                  <span>
                    $
                    {text
                      .toFixed(2)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  </span>
                ),
              },
              {
                title: t('type'),
                dataIndex: 'id',
                key: 'id',
                align: 'center',
                className: 'text-xs',
                render: () => (
                  <div className="flex items-center justify-center">
                    <CreditCardOutlined /> <span className="ml-2">Payout</span>
                  </div>
                ),
              },
              {
                title: t('status'),
                dataIndex: 'failed',
                key: 'failed',
                align: 'center',
                className: 'text-xs font-medium',
                render: (text, record) => {
                  if (record.status === 0) {
                    return (
                      <Tag
                        icon={<SyncOutlined spin />}
                        color="processing"
                        size="small"
                        className="mr-0"
                      >
                        {t('processing')}
                      </Tag>
                    );
                  }
                  if (record.status === 1) {
                    return (
                      <Tag
                        icon={<CheckCircleOutlined />}
                        color="success"
                        size="small"
                        className="mr-0"
                      >
                        {t('succeeded')}
                      </Tag>
                    );
                  }

                  return (
                    <>
                      <Tag
                        icon={<CloseCircleOutlined />}
                        color="error"
                        size="small"
                        className="mr-0"
                      >
                        {t('failed')}
                      </Tag>
                      <Button
                        loading={record.loading}
                        onClick={() => retryFailedPayout(record)}
                        className="mx-2"
                        type="primary"
                        size="small"
                      >
                        {t('retry')}
                      </Button>
                    </>
                  );
                },
              },
              {
                title: t('description'),
                dataIndex: 'description',
                key: 'description',
                align: 'center',
                className: 'text-xs',
                render: (text) => <span>{text}</span>,
              },
              {
                title: t('date'),
                dataIndex: 'createdAt',
                key: 'createdAt',
                align: 'center',
                className: 'text-xs',
                render: (text) => <span>{text}</span>,
              },
            ]}
            dataSource={tableData}
            expandable={{
              expandedRowRender: (record) => (
                <div className="p-4">
                  <Table
                    title={() => (
                      <div className="flex items-center justify-between">
                        <div>
                          <Badge
                            className="site-badge-count-109"
                            count={record.payments.length}
                            style={{ backgroundColor: '#52c41a' }}
                          />
                          <span className="font-light uppercase text-xs ml-2">
                            {t('payout_transactions')}
                          </span>
                        </div>
                        <Button
                          size="small"
                          icon={<CloudDownloadOutlined />}
                          onClick={() => {
                            const data = _.map(record.payments, (p) => ({
                              createdAt: moment(`${p.createdAt}+0000`)
                                .tz(timezone)
                                .format('MM/DD/YYYY h:mm:ss a'),
                              amount: p.amount
                                .toFixed(2)
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                              orderDate: moment(`${p.orderDate}+0000`)
                                .tz(timezone)
                                .format('MM/DD/YYYY h:mm:ss a'),
                              orderConfirmationCode: p.orderConfirmationCode,
                              customerName: p.customerName,
                              orderTotal: p.orderTotal,
                            }));
                            saveAsCsv({
                              data,
                              fields: transactionFields,
                              filename: `transactions_${new Date().toLocaleDateString()}`,
                            });
                          }}
                        >
                          {t('export')}
                        </Button>
                      </div>
                    )}
                    bordered
                    pagination={false}
                    columns={[
                      {
                        title: t('amount'),
                        dataIndex: 'amount',
                        key: 'amount',
                        align: 'center',
                        className: 'text-xs font-medium',
                        render: (text) => (
                          <span>
                            $
                            {text
                              .toFixed(2)
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                          </span>
                        ),
                      },
                      {
                        title: t('type'),
                        dataIndex: 'id',
                        key: 'id',
                        align: 'center',
                        className: 'text-xs',
                        render: () => (
                          <div className="flex items-center justify-center">
                            <BankOutlined />{' '}
                            <span className="ml-2">Payment</span>
                          </div>
                        ),
                      },
                      {
                        title: t('order'),
                        dataIndex: 'orderConfirmationCode',
                        key: 'orderConfirmationCode',
                        align: 'center',
                        className: 'text-xs',
                        render: (text) => (
                          <Typography.Text copyable>{text}</Typography.Text>
                        ),
                      },
                      {
                        title: t('date'),
                        dataIndex: 'createdAt',
                        key: 'createdAt',
                        align: 'center',
                        className: 'text-xs',
                        render: (text) => (
                          <span>
                            {moment(`${text}+0000`)
                              .tz(timezone)
                              .format('MMMM Do YYYY, h:mm:ss a')}
                          </span>
                        ),
                      },
                      {
                        title: '',
                        align: 'center',
                        className: 'text-xs',
                        render: (text, row) => (
                          <Space>
                            <Popover
                              title={t('details')}
                              placement="left"
                              content={
                                <div>
                                  <div className="text-xs">
                                    {t('customer')}:{' '}
                                    <span className="font-bold">
                                      {row.customerName}
                                    </span>
                                  </div>
                                  {/* <div className="text-xs">
                                    {t('payment')}:{' '}
                                    <span className="font-bold">
                                      {row.paymentId}
                                    </span>
                                  </div> */}
                                  <div className="text-xs">
                                    {t('destination')}:{' '}
                                    <span className="font-bold">
                                      {row.destination}
                                    </span>
                                  </div>
                                </div>
                              }
                            >
                              <Button
                                shape="circle"
                                size="small"
                                icon={<SearchOutlined />}
                              />
                            </Popover>
                          </Space>
                        ),
                      },
                    ]}
                    dataSource={record.payments}
                  />
                </div>
              ),
              rowExpandable: (record) => record.payments.length > 0,
            }}
          />
        </Col>
      </Row>
    </div>
  );
}

export default Activities;
