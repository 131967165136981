import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import _ from 'lodash';
import {
  Drawer,
  Button,
  Divider,
  notification,
  Typography,
  Table,
  Space,
  Popover,
  Descriptions,
  Select,
  Tabs,
  Switch,
  Col,
  Image,
  Row,
  Popconfirm,
  Modal,
  Tooltip,
  Input,
} from 'antd';
import { Comment } from '@ant-design/compatible';
import { PageHeader } from '@ant-design/pro-layout';
import Avatar from 'antd/lib/avatar/avatar';
import { FileSearchOutlined } from '@ant-design/icons';
import {
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
  useRecoilValueLoadable,
} from 'recoil';
import { useMount } from 'react-use';
import {
  orderEditAtom,
  orderItemsVisible,
  profileAtom,
  businessAtom,
  reloadOrder,
  selectedOrderAtom,
  addLogAtom,
  changePaymentMethodAtom,
  changeOrderStatusAtom,
  changeCardAtom,
  customCreditAtom,
  orderItemsAtom,
  timezoneAtom,
  viewDetailsAtom,
  securityCodeAtom,
  cancelOrderAtom,
  openSecurityCodeModalAtom,
  selectedTerminalDataAtom
} from '../../../atoms/Atoms';
import { businessesSelector } from '../../../atoms/Selectors';
import {
  convertTaxRule,
} from '../../utils/functions';
import Grocefy from '../../../assets/images/grocefyLogoAlone.png';
import ItemRefundModal from '../../pages/store/shared-components/ItemRefundModal';
import AddLog from '../modals/AddLog';
import ChangePaymentMethod from '../modals/ChangePaymentMethod';
import ChangeOrderStatus from '../modals/ChangeOrderStatus';
import ChangeCard from '../modals/ChangeCard';
import CustomCredit from '../modals/ProvideCustomCredit';
import api from '../../../api/api';
import Panel from '../panel/Panel';
import OrderDetails from '../modals/OrderDetailsModal';
import SecurityCodeModal from '../modals/CancelOrderSecurity';

function OrderActions() {
  const { t } = useTranslation();
  const { Option } = Select;
  const businesses = useRecoilValueLoadable(businessesSelector);
  const store = useRecoilValue(businessAtom);
  const selectedOrder = useRecoilValue(selectedOrderAtom);
  const [loadingItems, setLoadingItems] = useState(false);
  const [loadingHours, setLoadingHours] = useState(false);
  const [reload, setReload] = useRecoilState(reloadOrder);
  const [editOrder, setEditOrder] = useRecoilState(orderEditAtom);
  const [day, setDay] = useState(null);
  const [time, setTime] = useState(null);
  const profile = useRecoilValueLoadable(profileAtom);
  const setChangePaymentMethod = useSetRecoilState(changePaymentMethodAtom);
  const setchangeOrderStatus = useSetRecoilState(changeOrderStatusAtom);
  const setChangeCard = useSetRecoilState(changeCardAtom);
  const setProvideCustomCredit = useSetRecoilState(customCreditAtom);
  const [modalVisible, setModalVisible] = useRecoilState(orderItemsVisible);
  const setAddLog = useSetRecoilState(addLogAtom);
  const [showDriversModal, setShowDriversModal] = useState(false);
  const [drivers, setDrivers] = useState([]);
  const [loadingDrivers, setLoadingDrivers] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showDriverInstructions, setShowDriverInstructions] = useState(false);
  const [driverInstructions, setDriverInstructions] = useState('');
  const [originalDriverInstructions, setOriginalDriverInstructions] = useState('');
  const [orderItems, setOrderItems] = useRecoilState(orderItemsAtom);
  const [viewDetails, setViewDetails] = useRecoilState(viewDetailsAtom);
  const [days, setDays] = useState([]);
  const [times, setTimes] = useState([]);
  const [changingHours, setChangingHours] = useState(false);
  const timezone = useRecoilValue(timezoneAtom);
  const [showChangeToDeliveryModal, setShowChangeToDeliveryModal] = useState(false);
  const [userLocations, setUserLocations] = useState([]);
  const [loadingUserLocations, setLoadingUserLocations] = useState(false);
  const [selectedBusinessForDelivery, setSelectedBusinessForDelivery] = useState('');
  const [securityPin, setSecurityPin] = useRecoilState(securityCodeAtom);
  const [cancelOrder, setCancelOrder] = useRecoilState(cancelOrderAtom);
  const [open, setOpen] = useRecoilState(openSecurityCodeModalAtom);
  const setSelectedTerminalData = useSetRecoilState(selectedTerminalDataAtom);

  const isAdmin =
    profile.contents && profile?.contents?.roles?.includes('SuperAdmin');
  const isCustomerService =
    isAdmin ||
    (profile?.contents &&
      (profile?.contents?.roles?.includes('CustomerSupportAdmin') ||
        profile?.contents?.roles?.includes('CustomerSupport')));

  function businessProfile() {
    for (let i = 0; i < profile.contents.businessProfiles.length; i++) {
      if (profile.contents.businessProfiles[i].businessId === store.id) {
        setSecurityPin(profile.contents.businessProfiles[i].securityPassCode);
      }
    }
  }

  function showMessage(message) {
    notification.open({
      message: '',
      description: (
        <Comment
          author={<span>Grocefy</span>}
          avatar={<Avatar src={Grocefy} alt="grocefy" />}
          content={
            <p className="text-sm">
              {message}
            </p>
          }
          datetime={
            <Tooltip title={moment().format('YYYY-MM-DD HH:mm:ss')}>
              <span>{moment().fromNow()}</span>
            </Tooltip>
          }
        />
      ),
    });
  }

  function getLocations() {
    const temp = _.filter((businesses.contents.data.data.results),
      (s) => s.id === selectedBusinessForDelivery);
    setLoadingUserLocations(true);
    api.get(`orders/getuserlocationsforDelivery/${selectedOrder.customerId}/${temp[0].locations[0].id}`)
      .then((response) => {
        setLoadingUserLocations(false);
        if (response.data.error) {
          showMessage(response.data.error);
        } else {
          setUserLocations(response.data.data.results);
        }
      })
      .catch((err) => {
        setLoadingUserLocations(false);
        showMessage(err.message);
      });
  }

  function getValidDeliveryLocations() {
    setUserLocations([]);
    setSelectedBusinessForDelivery(selectedOrder.storeId);
  }

  function changeToDelivery(deliveryLocationId) {
    setLoading(true);
    api.put(`orders/changetodelivery/${selectedOrder.id}/${deliveryLocationId}`, { })
      .then((response) => {
        setLoading(false);
        if (response.data.error) {
          showMessage(response.data.error);
        } else if (response.data.message) {
          showMessage(response.data.message);
          setEditOrder(false);
          setReload(reload + 1);
        } else {
          showMessage(t('succeeded'));
        }
      })
      .catch((err) => {
        setLoading(false);
        showMessage(err.message);
      });
  }

  function changeServiceType() {
    if (selectedOrder.orderType === 0) {
      getValidDeliveryLocations();
      setShowChangeToDeliveryModal(true);
    } else if (selectedOrder.orderType === 1) {
      setLoading(true);
      api.put(`orders/changetopickup/${selectedOrder.id}`, { })
        .then((response) => {
          setLoading(false);
          if (response.data.error) {
            showMessage(response.data.error);
          } else if (response.data.message) {
            showMessage(response.data.message);
            setEditOrder(false);
            setReload(reload + 1);
          } else {
            showMessage(t('succeeded'));
          }
        })
        .catch((err) => {
          setLoading(false);
          showMessage(err.message);
        });
    } else {
      showMessage(t('cannot_change_service'));
    }
  }

  function submitDriverInstructions() {
    setLoading(true);
    api
      .post('orders/setdriverinstructions', {
        orderId: selectedOrder.id,
        instructions: driverInstructions,
      })
      .then((response) => {
        setLoading(false);
        if (response && response.data.error) {
          console.error('Error Message: ', response.data.error);
          notification.open({
            message: 'Alert!',
            description: (
              <Comment
                author={<span>Grocefy</span>}
                avatar={<Avatar src={Grocefy} alt="grocefy" />}
                content={<p className="text-sm">{response.data.error}</p>}
              />
            ),
          });
        } else {
          setOriginalDriverInstructions(driverInstructions);
          setReload(reload + 1);
          setShowDriverInstructions(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  }

  function getDrivers() {
    setLoadingDrivers(true);
    api
      .get(`deliveries/drivers/${store.locations[0].id}`)
      .then((response) => {
        setLoadingDrivers(false);
        if (response && response.data.error) {
          console.error('Error Message: ', response.data.error);
          notification.open({
            message: 'Alert!',
            description: (
              <Comment
                author={<span>Grocefy</span>}
                avatar={<Avatar src={Grocefy} alt="grocefy" />}
                content={<p className="text-sm">{response.data.error}</p>}
              />
            ),
          });
        } else {
          setDrivers(response.data.data.results);
        }
      })
      .catch((error) => {
        setLoadingDrivers(false);
        console.error(error);
      });
  }

  function mapOrderItems(data) {
    const sorted = _.map(data, (p) => ({
      key: p.id,
      id: p.id,
      completeName: p.completeName,
      name: p.name,
      brand: p.brand,
      amount: p.amount,
      upc: p.upc,
      aisle: p.aisle,
      price: p.price,
      description: p.description,
      extendedPrice: p.extendedPrice,
      itemImage: p.itemImage,
      hasRandomWeight: p.hasRandomWeight,
      sectionName: p.sectionName,
      isAlcoholicBeverage: p.isAlcoholicBeverage,
      isPacked: p.isPacked,
      isPerishable: p.isPerishable,
      isVoided: p.isVoided,
      manuallyAdded: p.manuallyAdded,
      acceptsSubstitutes: p.acceptsSubstitutes,
      taxRule: t(convertTaxRule(p.taxRule)),
    }));
    return _.sortBy(sorted, [(o) => o.isVoided]);
  }

  function forcePayout(orderId) {
    setLoading(true);
    api
      .post(`orders/stripe/transfers/singleorder/${orderId}`)
      .then((response) => {
        setLoading(false);
        if (response && response.data.error) {
          console.error('Error Message: ', response.data.error);
          notification.open({
            message: 'Alert!',
            description: (
              <Comment
                author={<span>Grocefy</span>}
                avatar={<Avatar src={Grocefy} alt="grocefy" />}
                content={<p className="text-sm">{response.data.error}</p>}
              />
            ),
          });
        } else if (response.data.success && response.data.message) {
          notification.open({
            message: 'Success!',
            description: (
              <Comment
                author={<span>Grocefy</span>}
                avatar={<Avatar src={Grocefy} alt="grocefy" />}
                content={<p className="text-sm">{response.data.message}</p>}
              />
            ),
          });
        }
        setReload(reload + 1);
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  }

  function updateTotals(code) {
    setLoading(true);
    api
      .put(`orders/totalsupdate/${code}`)
      .then((response) => {
        setLoading(false);
        if (response && response.data.error) {
          console.error('Error Message: ', response.data.error);
          notification.open({
            message: 'Alert!',
            description: (
              <Comment
                author={<span>Grocefy</span>}
                avatar={<Avatar src={Grocefy} alt="grocefy" />}
                content={<p className="text-sm">{response.data.error}</p>}
              />
            ),
          });
        } else if (response.data.success && response.data.message) {
          notification.open({
            message: 'Success!',
            description: (
              <Comment
                author={<span>Grocefy</span>}
                avatar={<Avatar src={Grocefy} alt="grocefy" />}
                content={<p className="text-sm">{response.data.message}</p>}
              />
            ),
          });
        }
        setReload(reload + 1);
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  }

  function updateOrder(data) {
    setLoading(true);
    api
      .put('orders', data)
      .then((response) => {
        setLoading(false);
        if (response && response.data.error) {
          console.error('Error Message: ', response.data.error);
          notification.open({
            message: 'Alert!',
            description: (
              <Comment
                author={<span>Grocefy</span>}
                avatar={<Avatar src={Grocefy} alt="grocefy" />}
                content={<p className="text-sm">{response.data.error}</p>}
              />
            ),
          });
        } else if (response.data.success && response.data.message) {
          notification.open({
            message: 'Success!',
            description: (
              <Comment
                author={<span>Grocefy</span>}
                avatar={<Avatar src={Grocefy} alt="grocefy" />}
                content={<p className="text-sm">{response.data.message}</p>}
              />
            ),
          });
        }
        setEditOrder(false);
        setReload(reload + 1);
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  }

  useEffect(() => {
    if (reload) {
      api
        .put('orders', {
          orderId: `${selectedOrder.id}`,
        })
        .then((response) => {
          setReload(false);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [reload]);

  useEffect(() => {
    if (cancelOrder) {
      const data = {
        OrderId: selectedOrder.id,
        ConfirmationCode: selectedOrder.confirmationCode,
        Status: 8,
        ApproverId: profile.contents.id,
        Reason: 7,
        SilentMode: false,
      };
      updateOrder(data);
      setCancelOrder(false);
    }
  }, [cancelOrder]);

  useEffect(() => {
    if (day) {
      const hours = _.filter(days, (d) => d.date === day);
      setTimes(hours[0].times);
      setTime(null);
    }
  }, [day]);

  useEffect(() => {
    if (showDriversModal) {
      getDrivers();
      setDriverInstructions(originalDriverInstructions);
    } else {
      setDrivers([]);
    }
  }, [showDriversModal]);

  useEffect(() => {
    if (selectedOrder) {
      setDriverInstructions(selectedOrder.driverInstructions);
      setOriginalDriverInstructions(selectedOrder.driverInstructions);
    }
  }, [selectedOrder]);

  useEffect(() => {
    if (selectedBusinessForDelivery.length > 0) {
      getLocations();
    }
  }, [selectedBusinessForDelivery]);

  useMount(() => {
    if (profile && store) {
      businessProfile();
    }
  });
  function Operations() {
    return (
      <div>
        <div hidden className="text-xs">You may edit an existing order based on its current status.</div>
        <div className="font-semibold">{selectedOrder.confirmationCode}</div>
        <div className="font-semibold">{t(selectedOrder.statusText)}</div>
        <div className="font-semibold">{t('items')}:
          <Space>
            <Popover
              title={loadingItems ? `${t('loading')}...` : `${t('order_items')} (${orderItems && orderItems.length})`}
              placement="left"
              trigger="click"
              width="100%"
              content={
                <div>
                  <Table
                    key="itemsTable"
                    size="small"
                    bordered
                    loading={loadingItems}
                    columns={[
                      {
                        title: t('brand'),
                        dataIndex: 'brand',
                        key: 'brand',
                        align: 'left',
                        className: 'text-xs',
                        render: (info) => (
                          <Typography.Text>{info}</Typography.Text>
                        ),
                      },
                      {
                        title: t('name'),
                        dataIndex: 'name',
                        key: 'name',
                        align: 'left',
                        className: 'text-xs',
                        render: (info) => (
                          <Typography.Text>{info}</Typography.Text>
                        ),
                      },
                      {
                        title: t('description'),
                        dataIndex: 'description',
                        key: 'description',
                        align: 'left',
                        className: 'text-xs',
                        render: (info) => (
                          <Typography.Text>{info}</Typography.Text>
                        ),
                      },
                      {
                        title: 'UPC',
                        dataIndex: 'upc',
                        key: 'upc',
                        align: 'left',
                        className: 'text-xs',
                        render: (info) => (
                          <Typography.Text copyable>{info}</Typography.Text>
                        ),
                      },
                      {
                        title: t('amount'),
                        dataIndex: 'amount',
                        key: 'amount',
                        align: 'center',
                        className: 'text-xs',
                        render: (info) => (
                          <Typography.Text>
                            {info.toFixed(2)}
                          </Typography.Text>
                        ),
                      },
                      {
                        title: t('price'),
                        dataIndex: 'price',
                        key: 'price',
                        align: 'center',
                        className: 'text-xs',
                        render: (info) => (
                          <Typography.Text>
                            ${info.toFixed(2)}
                          </Typography.Text>
                        ),
                      },
                      {
                        title: t('included'),
                        dataIndex: 'isVoided',
                        key: 'isVoided',
                        align: 'center',
                        className: 'text-xs',
                        render: (info) => (
                          <Switch size="small" checked={!info} disabled />
                        ),
                      },
                      {
                        title: 'Tax Rule',
                        dataIndex: 'taxRule',
                        key: 'traxRule',
                        align: 'center',
                        className: 'text-xs',
                        render: (info) => (
                          <Typography.Text>{info}</Typography.Text>
                        ),
                      },
                    ]}
                    dataSource={
                      orderItems && orderItems.length > 0
                        ? orderItems
                        : null
                    }
                    expandable={{
                      expandRowByClick: false,
                      expandedRowRender: (record) => (
                        <div>
                          <Tabs>
                            <Tabs.TabPane key="1" tab={t('details')}>
                              <Row className="flex">
                                <Col span={12} className="flex">
                                  <Descriptions>
                                    <Descriptions.Item label={t('section_name')} />
                                    <br />
                                    <Descriptions.Item>
                                      <Col span={24}>
                                        <Typography.Text>
                                          {record.sectionName}
                                        </Typography.Text>
                                      </Col>
                                    </Descriptions.Item>
                                    <Descriptions.Item label={t('aisle')} />
                                    <br />
                                    <Descriptions.Item>
                                      <Col span={24}>
                                        <Typography.Text>
                                          {record.aisle}
                                        </Typography.Text>
                                      </Col>
                                    </Descriptions.Item>
                                  </Descriptions>
                                </Col>
                                <Col span={12} className="flex">
                                  <Descriptions bordered>
                                    <Descriptions.Item label={t('item_image')} />
                                    <br />
                                    <Descriptions.Item>
                                      <Col span={24}>
                                        <Image
                                          alt="Item Image"
                                          width={150}
                                          src={record.itemImage}
                                        />
                                      </Col>
                                    </Descriptions.Item>
                                  </Descriptions>
                                </Col>
                              </Row>
                            </Tabs.TabPane>
                            <Tabs.TabPane key="2" tab="Flags">
                              <Row className="flex">
                                <Col span={12} className="flex">
                                  <Descriptions>
                                    <Descriptions.Item
                                      className="py-0"
                                      label={t('weighted')}
                                    >
                                      <Switch
                                        disabled
                                        size="small"
                                        checked={record.hasRandomWeight}
                                      />
                                    </Descriptions.Item>
                                  </Descriptions>
                                </Col>
                                <Col span={10} offset={2}>
                                  <Descriptions>
                                    <Descriptions.Item label={t('alcoholic_beverage')}>
                                      <Switch
                                        disabled
                                        size="small"
                                        checked={record.isAlcoholicBeverage}
                                      />
                                    </Descriptions.Item>
                                  </Descriptions>
                                </Col>
                              </Row>
                            </Tabs.TabPane>
                          </Tabs>
                        </div>
                      ),
                      rowExpandable: (record) => record,
                    }}
                  />
                </div>
              }
            >
              <Button
                shape="none"
                size="small"
                type="link"
                className="w-full"
                onClick={() => {
                  setLoadingItems(true);
                  api
                    .get(`orders/${selectedOrder?.id}/items/all`)
                    .then((response) => {
                      const map = mapOrderItems(response.data.data.results);
                      setOrderItems(map);
                      setLoadingItems(false);
                    })
                    .catch((error) => {
                      console.error(error);
                    });
                }}
              >
                {selectedOrder.itemCount}
              </Button>
            </Popover>
          </Space>
        </div>
        <Divider />
        <div className="flex flex-col items-center">
          <Button
            size="medium"
            type="primary"
            className="w-full"
            icon={<FileSearchOutlined />}
            onClick={() => {
              setViewDetails(true);
            }}
          >
            {t('receipt')}
          </Button>
        </div>
        <br />
        <div className="flex flex-col">
          <Button
            disabled={selectedOrder?.terminalData?.length === 0}
            className="mb-4 bg-green-400 text-white border-green-400"
            icon={<FileSearchOutlined />}
            onClick={() => {
              setSelectedTerminalData(selectedOrder?.terminalData);
            }}
          >
            {t('terminal_data')}
          </Button>
        </div>
        <Divider />
        <div className="flex flex-col">
          <Button
            loading={loading}
            disabled={
              selectedOrder.status === 7 ||
              selectedOrder.status === 6 ||
              selectedOrder.status === 8 ||
              selectedOrder.status === 9 ||
              selectedOrder.status === 3 ||
              selectedOrder.status === 5 ||
              selectedOrder.status === 16 ||
              selectedOrder.status === 17 ||
              selectedOrder.status === 18 ||
              selectedOrder.status === 21 ||
              selectedOrder.statusText === 'Unknown'
            }
            className="mb-4 bg-green-400 text-white border-green-400"
            onClick={() => {
              const status = selectedOrder.type === 'Delivery' ? 5 : 6;
              const data = {
                OrderId: selectedOrder.id,
                ConfirmationCode: selectedOrder.confirmationCode,
                Status: status,
                ApproverId: profile.contents.id,
                Reason: 7,
              };
              updateOrder(data);
            }}
          >
            {t('dispatch')}
          </Button>
          <Button
            loading={loading}
            disabled={
              selectedOrder.status !== 2 &&
              selectedOrder.status !== 5 &&
              selectedOrder.status !== 21
            }
            className={
              selectedOrder.type === 'Delivery'
                ? 'mb-4 bg-green-400 text-white border-green-400'
                : 'hidden'
            }
            onClick={() => {
              setShowDriversModal(true);
            }}
          >
            Assign Delivery
          </Button>
          <Button
            loading={loading}
            className={
              selectedOrder.type === 'Delivery'
                ? 'mb-4 bg-green-400 text-white border-green-400'
                : 'hidden'
            }
            onClick={() => {
              setShowDriverInstructions(true);
            }}
          >
            {t('driver_instructions')}
          </Button>
          <Button
            loading={loading}
            disabled={
              selectedOrder.type !== 'Delivery' || selectedOrder.status !== 5
            }
            className={
              selectedOrder.type === 'Delivery'
                ? 'mb-4 bg-green-400 text-white border-green-400'
                : 'hidden'
            }
            onClick={() => {
              const status = 7;
              const data = {
                OrderId: selectedOrder.id,
                ConfirmationCode: selectedOrder.confirmationCode,
                Status: status,
                ApproverId: profile.contents.id,
                Reason: 7,
              };
              updateOrder(data);
            }}
          >
            {t('mark_as_delivered')}
          </Button>
          <Button
            loading={loading}
            disabled={
            !isCustomerService && (selectedOrder.hasBeenCharged ||
            selectedOrder.status === 6 ||
            selectedOrder.status === 7 ||
             selectedOrder.status === 8 ||
             selectedOrder.status === 9)
            }
            className="mb-4 bg-red-600 text-white border-red-600"
            onClick={() => {
              if (isAdmin || isCustomerService) {
                setCancelOrder(true);
              } else {
                setOpen(true);
              }
            }}
          >
            {t('cancel')}
          </Button>
          <Button
            loading={loading}
            disabled={selectedOrder.status === 6 || selectedOrder.status === 7}
            type="primary"
            className="mb-4 bg-green-400 text-white border-green-400"
            onClick={() => {
              setChangePaymentMethod(true);
            }}
          >
            {t('change_payment_method')}
          </Button>
          <Button
            loading={loading}
            disabled={
              selectedOrder.status === 6 ||
              selectedOrder.status === 7 ||
              selectedOrder.status === 8 ||
              selectedOrder.status === 9
            }
            type="primary"
            className="mb-4 bg-green-400 text-white border-green-400"
            onClick={() => {
              setchangeOrderStatus(true);
            }}
          >
            {t('change_order_status')}
          </Button>
          <Button
            loading={loading}
            disabled={
              selectedOrder.status === 6 ||
              selectedOrder.status === 7
            }
            type="primary"
            className="mb-4 bg-green-400 text-white border-green-400"
            onClick={() => changeServiceType()}
          >
            {selectedOrder.orderType === 0 ? t('change_to_delivery') : t('change_to_pickup')}
          </Button>
          <Button
            loading={loading}
            disabled={
              selectedOrder.status === 6 ||
              selectedOrder.status === 7 ||
              selectedOrder.paymentType !== 0
            }
            type="primary"
            className="mb-4 bg-green-400 text-white border-green-400"
            onClick={() => {
              setChangeCard(true);
            }}
          >
            {t('change_card')}
          </Button>
          <Button
            loading={loading}
            disabled={
              selectedOrder.hasBeenAuthed || selectedOrder.hasBeenCharged
            }
            className="mb-4 bg-orange-400 text-white border-orange-400"
            onClick={() => {
              setLoading(true);
              api
                .put(`orders/servicefee/toggle/${selectedOrder.id}`, {})
                .then((response) => {
                  setLoading(false);
                  if (response && response.data.error) {
                    console.error('Error Message: ', response.data.error);
                    notification.open({
                      message: 'Alert!',
                      description: (
                        <Comment
                          author={<span>Grocefy</span>}
                          avatar={<Avatar src={Grocefy} alt="grocefy" />}
                          content={
                            <p className="text-sm">{response.data.error}</p>
                          }
                        />
                      ),
                    });
                  } else if (response && response.data.success) {
                    notification.open({
                      message: 'Success!',
                      description: (
                        <Comment
                          author={<span>Grocefy</span>}
                          avatar={<Avatar src={Grocefy} alt="grocefy" />}
                          content={
                            <p className="text-sm">
                              {response.data.internalMessage}
                            </p>
                          }
                        />
                      ),
                    });
                  }
                });
            }}
          >
            {t('toggle_service_fee')}
          </Button>
          <Popover
            title={loadingHours ? `${t('loading_hours')}...` : `${t('service_hour')}`}
            placement="right"
            trigger="click"
            width="100%"
            content={
              <Panel className="w-72">
                <div className="flex flex-col items-center justify-center">
                  <Select
                    loading={loadingHours}
                    placeholder={t('select_date_placeholder')}
                    listHeight={500}
                    style={{ width: 250 }}
                    onChange={(value) => setDay(value)}
                  >
                    {_.map(days, (d) => (
                      <Option
                        key={d.date}
                        value={d.date}
                        className="text-xs flex items-center"
                      >
                        {moment(`${d.date.replace('Z', '')}+0000`)
                          .tz(timezone)
                          .format('ddd MMM D, y')}
                      </Option>
                    ))}
                  </Select>
                  <Select
                    className="py-4"
                    disabled={!day}
                    placeholder={t('select_hour_placeholder')}
                    listHeight={500}
                    style={{ width: 250 }}
                    onChange={(value) => setTime(value)}
                  >
                    {_.map(times, (y) => (
                      <Option
                        key={y.time}
                        value={y.time}
                        className="text-xs flex items-center"
                      >
                        {selectedOrder.orderType === 1 ? `${moment(`${y.time.replace('Z', '')}+0000`)
                          .tz(timezone)
                          .format('LT')} - ${moment(`${y.endTime.replace('Z', '')}+0000`)
                          .tz(timezone)
                          .format('LT')}` :
                          `${moment(`${y.time.replace('Z', '')}+0000`)
                            .tz(timezone)
                            .format('LT')} - ${moment(`${y.endTime.replace('Z', '')}+0000`)
                            .tz(timezone)
                            .format('LT')}`}
                      </Option>
                    ))}
                  </Select>
                  <Button
                    disabled={!day && !time}
                    loading={changingHours}
                    type="primary"
                    size="small"
                    onClick={() => {
                      setChangingHours(true);
                      const selectedTime = _.filter(times, (ti) => ti.time === time);
                      const payload = {
                        StartSlotTime: selectedTime[0].time,
                        EndSlotTime: selectedTime[0].endTime,
                        OrderId: selectedOrder.id
                      };
                      api
                        .post(
                          'orders/desiredtime/change',
                          payload,
                        )
                        .then((response) => {
                          setChangingHours(false);
                          setEditOrder(false);
                          setReload(reload + 1);
                        })
                        .catch((error) => console.error(error));
                    }}
                  >
                    {t('submit')}
                  </Button>
                </div>
              </Panel>
            }
          >
            <Button
              loading={loadingHours}
              disabled={
                selectedOrder.status === 6 || selectedOrder.status === 7 || loading
              }
              className="mb-4 bg-orange-400 text-white border-orange-400"
              onClick={() => {
                setLoadingHours(true);
                const payload = {
                  OrderTotal: selectedOrder.orderTotal,
                  ItemCount: selectedOrder.itemCount,
                  OrderType: selectedOrder.orderType,
                  DeliveryLocationId: selectedOrder.orderType === 1 ?
                    selectedOrder.customerLocationId : null,
                };
                api
                  .post(
                    `businesses/hours/v2/${selectedOrder.locationId}`,
                    payload,
                  )
                  .then((response) => {
                    setLoadingHours(false);
                    setDays(response.data.data.results);
                    if (response.data.data.results.length > 0) {
                      setTimes(response.data.data.results[0].times);
                    }
                  })
                  .catch((error) => console.error(error));
              }}
            >
              {t('change_date')}
            </Button>
          </Popover>
          <Button
            loading={loading}
            disabled={
              selectedOrder.hasBeenCharged || selectedOrder.paymentType !== 0
            }
            className="mb-4 bg-blue-400 text-white border-blue-400"
            onClick={() => {
              setLoading(true);
              api
                .post(`admin/forcecapture/${selectedOrder.confirmationCode}`, {})
                .then((response) => {
                  setLoading(false);
                  if (response && response.data.error) {
                    console.error('Error Message: ', response.data.error);
                    notification.open({
                      message: 'Alert!',
                      description: (
                        <Comment
                          author={<span>Grocefy</span>}
                          avatar={<Avatar src={Grocefy} alt="grocefy" />}
                          content={
                            <p className="text-sm">{response.data.error}</p>
                          }
                        />
                      ),
                    });
                  } else if (response && response.data.success) {
                    notification.open({
                      message: 'Success!',
                      description: (
                        <Comment
                          author={<span>Grocefy</span>}
                          avatar={<Avatar src={Grocefy} alt="grocefy" />}
                          content={
                            <p className="text-sm">
                              A charge was made to the credit card with
                              id: {response.data.internalMessage}
                            </p>
                          }
                        />
                      ),
                    });
                  }
                });
            }}
          >
            {t('force_charge')}
          </Button>
          <Divider />
          <div className="mb-2">Credits</div>
          <Button
            loading={loading}
            disabled={
              (selectedOrder.status !== 6 && selectedOrder.status !== 7) ||
              (selectedOrder.paymentType !== 0 && selectedOrder.paymentType !== 5) ||
              selectedOrder.transferCompleted
            }
            className="mb-4 bg-green-400 text-white border-green-400"
            onClick={() => {
              setProvideCustomCredit(true);
            }}
          >
            {t('provide_custom_credit')}
          </Button>
          <Button
            loading={loading}
            disabled={
              (selectedOrder.status !== 6 && selectedOrder.status !== 7) ||
              (selectedOrder.paymentType !== 0 && selectedOrder.paymentType !== 5) ||
              selectedOrder.transferCompleted
            }
            className="mb-4 bg-blue-400 text-white border-blue-400"
            onClick={() => {
              setModalVisible(true);
            }}
          >
            {t('provide_item_credit')}
          </Button>
          <Divider />
          <div className="mb-2">Other</div>
          <Button
            loading={loading}
            disabled={selectedOrder.transferCompleted || !isAdmin}
            type="primary"
            className="mb-4 bg-blue-400 text-white border-blue-400"
            onClick={() => {
              updateTotals(selectedOrder.confirmationCode);
            }}
          >
            {t('update_totals')}
          </Button>
          <Button
            loading={loading}
            type="primary"
            className="mb-4 bg-green-400 text-white border-green-400"
            onClick={() => {
              setAddLog(true);
            }}
          >
            {t('add_log')}
          </Button>
          <Button
            loading={loading}
            disabled={
              selectedOrder.hasBeenCharged
            }
            className="mb-4 bg-green-400 text-white border-green-400"
            onClick={() => {
              setLoading(true);
              api
                .put(
                  `orders/recoveritems/${selectedOrder.confirmationCode}`,
                  {}
                )
                .then((response) => {
                  setLoading(false);
                  if (response && response.data.error) {
                    console.error('Error Message: ', response.data.error);
                    notification.open({
                      message: 'Alert!',
                      description: (
                        <Comment
                          author={<span>Grocefy</span>}
                          avatar={<Avatar src={Grocefy} alt="grocefy" />}
                          content={
                            <p className="text-sm">{response.data.error}</p>
                          }
                        />
                      ),
                    });
                  } else if (
                    response &&
                    response.data.success &&
                    response.data.message
                  ) {
                    notification.open({
                      message: 'Success!',
                      description: (
                        <Comment
                          author={<span>Grocefy</span>}
                          avatar={<Avatar src={Grocefy} alt="grocefy" />}
                          content={
                            <p className="text-sm">{response.data.message}</p>
                          }
                        />
                      ),
                    });
                  }
                  setEditOrder(false);
                  setReload(reload + 1);
                })
                .catch((error) => {
                  setLoading(false);
                  console.error(error);
                });
            }}
          >
            {t('recover_items')}
          </Button>
          <Button
            loading={loading}
            disabled={(selectedOrder.status !== 6 && selectedOrder.status !== 7)
               || selectedOrder.paymentType !== 0 || selectedOrder.transferCompleted
               || !isAdmin}
            type="primary"
            className="mb-4 bg-blue-400 text-white border-blue-400"
            onClick={() => {
              forcePayout(selectedOrder.id);
            }}
          >
            {`${t('payout')} ($${parseFloat(selectedOrder.estimatedStoreTransferTotal).toFixed(2)})`}
          </Button>
          <Button
            loading={loading}
            disabled={!isAdmin}
            type="primary"
            className="mb-4 bg-blue-400 text-white border-blue-400"
            onClick={() => {
              navigator.clipboard.writeText(JSON.stringify(selectedOrder));
              notification.open({
                message: '',
                description: (
                  <Comment
                    author={<span>Grocefy</span>}
                    avatar={<Avatar src={Grocefy} alt="grocefy" />}
                    content={
                      <p className="text-sm">
                        JSON copied to clipboard
                      </p>
                    }
                  />
                ),
              });
            }}
          >
            {t('copy')}
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div>
      <Drawer
        title={t('operations')}
        placement="right"
        closable
        onClose={() => setEditOrder(false)}
        open={selectedOrder && editOrder}
        width={300}
      >
        <div>{Operations()} </div>
      </Drawer>
      <AddLog />
      <ChangePaymentMethod />
      <ChangeOrderStatus />
      <ChangeCard />
      <CustomCredit />
      <ItemRefundModal />
      <Modal
        closable="true"
        centered="true"
        open={showDriversModal}
        onCancel={() => setShowDriversModal(false)}
        footer={[
          <Button
            key="close"
            disabled={loadingDrivers}
            onClick={() => setShowDriversModal(false)}
          >
            {t('close')}
          </Button>,
        ]}
      >
        <PageHeader
          title={`${t('assign_driver')}${
            selectedOrder?.deliveryZoneName
              ? `: ${selectedOrder?.deliveryZoneName}`
              : ''
          }`}
        />
        <Table
          loading={loadingDrivers}
          dataSource={drivers}
          rowKey="id"
          columns={[
            {
              title: t('name'),
              align: 'center',
              className: 'text-xs',
              render: (row) => (
                <Typography.Text>
                  {row.firstName} {row.lastName}
                </Typography.Text>
              ),
            },
            {
              title: t('action'),
              align: 'center',
              className: 'text-xs',
              render: (row) => (
                <Popconfirm
                  title={t('assign_to_driver_content')}
                  onConfirm={() => {
                    const data = {
                      Status: 2,
                      Reason: 10,
                      OrderId: selectedOrder.id,
                      ConfirmationCode: selectedOrder.confirmationCode,
                      DriverId: row.id,
                      ApproverId: profile.contents.id,
                    };
                    setShowDriversModal(false);
                    updateOrder(data);
                  }}
                  okText={t('yes')}
                  cancelText="No"
                >
                  <Button type="primary" size="small">
                    {t('assign')}
                  </Button>
                </Popconfirm>
              ),
            },
          ]}
        />
      </Modal>
      <Modal
        closable="true"
        centered="true"
        open={showDriverInstructions}
        onCancel={() => setShowDriverInstructions(false)}
        footer={[
          <Button
            key="close"
            disabled={loading}
            onClick={() => {
              setDriverInstructions(originalDriverInstructions);
              setShowDriverInstructions(false);
            }}
          >
            {t('close')}
          </Button>,
          <Button
            key="submit"
            disabled={loading}
            onClick={() => submitDriverInstructions()}
          >
            {t('submit')}
          </Button>,
        ]}
      >
        <PageHeader title="Driver Instructions" />
        <Input
          placeholder=""
          value={driverInstructions}
          onChange={(obj) => {
            setDriverInstructions(obj.target.value);
          }}
        />
      </Modal>
      <Modal
        closable="true"
        centered="true"
        open={showChangeToDeliveryModal}
        onCancel={() => setShowChangeToDeliveryModal(false)}
        footer={[
          <Button
            key="close"
            disabled={loading}
            onClick={() => {
              setShowChangeToDeliveryModal(false);
            }}
          >
            {t('close')}
          </Button>
        ]}
      >
        <PageHeader
          title="Change to Delivery"
          extra={[
            <Select
              style={{ width: 150 }}
              onChange={(value) => setSelectedBusinessForDelivery(value)}
              value={selectedBusinessForDelivery}
            >
              {businesses.state === 'hasValue' && _.map(businesses.contents.data.data.results, (business) => (
                <Option
                  value={business.id}
                  key={business.id}
                >
                  {business.name}
                </Option>
              ))}
            </Select>
          ]}
        />
        <Table
          loading={loadingUserLocations || loading}
          bordered
          rowKey="id"
          dataSource={userLocations}
          columns={[
            {
              title: t('name'),
              dataIndex: 'name',
              key: 'name',
              align: 'left',
              className: 'text-xs',
              render: (text) => <span>{text}</span>,
            },
            {
              title: t('address'),
              dataIndex: 'addressLine',
              key: 'addressLine',
              align: 'left',
              className: 'text-xs',
              render: (text) => <span>{text}</span>,
            },
            {
              title: t('delivery_cost'),
              key: 'deliveryCost',
              align: 'left',
              className: 'text-xs',
              render: (record) => <span>{!record.isDeliveryAvailable ? 'N/A' : `$${record.deliveryCost}`}</span>,
            },
            {
              title: t('actions'),
              align: 'center',
              className: 'text-xs',
              render: (record) => (
                <Popconfirm
                  title={t('select_location_for_delivery')}
                  onConfirm={() => {
                    changeToDelivery(record.id);
                  }}
                  key="apply"
                  okText={t('yes')}
                  cancelText="No"
                >
                  <Button
                    type="primary"
                    size="small"
                    disabled={!record.isDeliveryAvailable}
                  >
                    {t('assign')}
                  </Button>
                </Popconfirm>
              ),
            }
          ]}
        />
      </Modal>
      {viewDetails ? <OrderDetails /> : null }
      {open ? <SecurityCodeModal /> : null }
    </div>
  );
}

export default OrderActions;
