import React from 'react';
import { Tabs } from 'antd';
import { useTranslation } from 'react-i18next';
import DeliveriesHeader from './shared-components/DeliveriesHeader';
import DeliveriesSearch from './shared-components/DeliveriesSearch';
import DeliveriesSort from './shared-components/DeliveriesSort';

function Orders() {
  const { t } = useTranslation();

  return (
    <div>
      <DeliveriesHeader />
      <Tabs defaultActiveKey="1">
        <Tabs.TabPane tab={t('search')} key="1">
          <DeliveriesSearch />
        </Tabs.TabPane>
        <Tabs.TabPane tab={t('sorting')} key="2">
          <DeliveriesSort />
        </Tabs.TabPane>
      </Tabs>
    </div>
  );
}

export default Orders;
