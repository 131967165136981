import React from 'react';
import { Tag } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';

import { CheckCircleOutlined, SyncOutlined } from '@ant-design/icons';
import Team from './shared-components/TeamV2';
import { businessAtom } from '../../../atoms/Atoms';

function Staff() {
  const business = useRecoilValue(businessAtom);
  const { t } = useTranslation();

  return (
    <>
      <PageHeader
        title={t('staff')}
        tags={
          <Tag
            color="#2db7f5"
            icon={business ? <CheckCircleOutlined /> : <SyncOutlined spin />}
          >
            {business ? business.name : `${t('loading')}...`}
          </Tag>
        }
      />
      <Team />
    </>
  );
}

export default Staff;
