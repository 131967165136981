import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import {
  Drawer,
  Avatar,
  notification,
  Tooltip,
  Table,
  Button,
  Image
} from 'antd';
import { Comment } from '@ant-design/compatible';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { useRecoilState, useRecoilValue, useSetRecoilState, } from 'recoil';
import {
  selectedSubscriptionAtom,
  editSubscriptionAtom,
  reloadSubscriptionBenefitsAtom,
  selectedBenefitAtom,
  editBenefitAtom
} from '../../../../../atoms/Atoms';
import Grocefy from '../../../../../assets/images/grocefyLogoAlone.png';
import bookingApi from '../../../../../api/bookingApi';
import { mapSubscriptionBenefitType } from '../../../../utils/functions';
import EditSubscriptionBenefit from './EditSubscriptionBenefit';

function Subscription() {
  const { t } = useTranslation();
  const editSubscription = useRecoilValue(editSubscriptionAtom);
  const [selectedSubscription, setSelectedSubscription] = useRecoilState(selectedSubscriptionAtom);
  const setSelectedBenefit = useSetRecoilState(selectedBenefitAtom);
  const setEditBenefit = useSetRecoilState(editBenefitAtom);
  const [reload, setReload] = useRecoilState(reloadSubscriptionBenefitsAtom);
  const [loading, setLoading] = useState(false);
  const [benefits, setBenefits] = useState([]);

  function showMessage(message) {
    notification.open({
      message: '',
      description: (
        <Comment
          author={<span>Grocefy</span>}
          avatar={<Avatar src={Grocefy} alt="grocefy" />}
          content={
            <p className="text-sm">
              {message}
            </p>
          }
          datetime={
            <Tooltip title={moment().format('YYYY-MM-DD HH:mm:ss')}>
              <span>{moment().fromNow()}</span>
            </Tooltip>
          }
        />
      ),
    });
  }

  function getBenefits() {
    setLoading(true);
    setReload(false);
    bookingApi
      .get(`subscriptions/${selectedSubscription?.id}/benefits/all`)
      .then((response) => {
        setLoading(false);
        setBenefits(response.data.data);
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  }

  useEffect(() => {
    getBenefits();
  }, [selectedSubscription]);

  useEffect(() => {
    if (reload) {
      getBenefits();
    }
  }, [reload]);

  return (
    <>
      <Drawer
        title={selectedSubscription?.internalName}
        placement="right"
        closable={!loading}
        onClose={() => setSelectedSubscription(null)}
        open={!editSubscription && selectedSubscription}
        width={450}
      >
        {selectedSubscription?.imageUrl && (
          <Image
            alt="locationimage"
            width={400}
            src={selectedSubscription?.imageUrl}
            className="cursor-pointer mx-auto"
          />
        )}
        <Button
          size="small"
          type="primary"
          className="my-2 float-right"
          onClick={() => {
            setSelectedBenefit({});
            setEditBenefit(true);
          }}
        >
          {t('add_benefit')}
        </Button>
        <Table
          dataSource={benefits}
          size="small"
          bordered
          columns={[
            {
              title: t('name'),
              key: 'name',
              align: 'left',
              dataIndex: 'internalName',
              className: 'text-xs',
              render: (row) => (
                <span>{row}</span>
              ),
            },
            {
              title: t('is_active'),
              key: 'is_active',
              align: 'center',
              dataIndex: 'isActive',
              className: 'text-xs',
              render: (row) => (
                <div>{row ? <CheckCircleOutlined className="text-green-600" /> : <CloseCircleOutlined className="text-red-600" />}</div>
              ),
            },
            {
              title: t('type'),
              key: 'type',
              align: 'center',
              dataIndex: 'type',
              className: 'text-xs',
              render: (row) => (
                <span>{t(mapSubscriptionBenefitType(row))}</span>
              ),
            },
            {
              title: t('is_promo_benefit'),
              key: 'isPromoBenefit',
              align: 'center',
              dataIndex: 'isPromoBenefit',
              className: 'text-xs',
              render: (row) => (
                <div>{row ? <CheckCircleOutlined className="text-green-600" /> : <CloseCircleOutlined className="text-red-600" />}</div>
              ),
            },
            {
              title: t('actions'),
              key: 'actions',
              align: 'center',
              className: 'text-xs',
              render: (row) => (
                <Button
                  type="primary"
                  size="small"
                  onClick={() => {
                    setSelectedBenefit(row);
                    setEditBenefit(true);
                  }}
                >
                  {t('edit')}
                </Button>
              ),
            }
          //   {
          //     title: t('amount'),
          //     key: 'amount',
          //     align: 'center',
          //     dataIndex: 'amount',
          //     className: 'text-xs',
          //     render: (row) => (
          //       <span>{thousand(row)}</span>
          //     ),
          //   }
          ]}
        />
      </Drawer>
      <EditSubscriptionBenefit />
    </>
  );
}
export default Subscription;
