import React from 'react';
import { useTranslation } from 'react-i18next';
import { Tag } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  EyeFilled,
  SyncOutlined,
} from '@ant-design/icons';
import { useRecoilValue } from 'recoil';
import { businessAtom } from '../../../../atoms/Atoms';

function MessagingHeader() {
  const business = useRecoilValue(businessAtom);
  const { t } = useTranslation();

  return (
    <PageHeader
      className="mb-4 px-0 text-lg"
      title={t('customer_chats')}
      tags={
        <Tag
          color="#2db7f5"
          icon={business ? <CheckCircleOutlined /> : <SyncOutlined spin />}
        >
          {business ? business.name : `${t('loading')}...`}
        </Tag>
      }
      extra={
        business && [
          <Tag
            className="text-lg bx-4 hidden"
            key="assigned"
            icon={<EyeFilled />}
            color="#87d068"
          >
            Active 12,000
          </Tag>,
          <Tag
            className="text-lg bx-4 hidden"
            key="sold"
            icon={<CloseCircleOutlined />}
            color="#108ee9"
          >
            Sold 7500
          </Tag>,
          <Tag
            className="text-lg bx-4 hidden"
            key="hidden"
            icon={<CloseCircleOutlined />}
            color="#ff0000"
          >
            Hidden 500
          </Tag>,
        ]
      }
    />
  );
}

export default MessagingHeader;
