import { Tabs } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import React from 'react';
import { useRecoilValueLoadable } from 'recoil';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  profileAtom
} from '../../../atoms/Atoms';
import AuditLogs from './shared-components/AuditLogs';
import ErrorLogs from './shared-components/ErrorLogs';
import GenericLogs from './shared-components/GenericLogs';

function GlobalLogs() {
  const { t } = useTranslation();
  const history = useHistory();
  const userProfile = useRecoilValueLoadable(profileAtom);
  const isAdmin =
    userProfile.contents && userProfile?.contents?.roles?.includes('SuperAdmin');

  if (!isAdmin) {
    history.push('/');
    return null;
  }

  return (
    <div>
      <PageHeader
        className="mb-4 px-0"
        title={t('logs')}
      />
      <Tabs defaultActiveKey="1">
        <Tabs.TabPane tab={t('audit_logs')} key="1">
          <AuditLogs />
        </Tabs.TabPane>
        <Tabs.TabPane tab={t('error_logs')} key="2">
          <ErrorLogs />
        </Tabs.TabPane>
        <Tabs.TabPane tab={t('generic_logs')} key="3">
          <GenericLogs />
        </Tabs.TabPane>
      </Tabs>
    </div>
  );
}

export default GlobalLogs;
