import { Typography, Table } from 'antd';
import React, { useState, useEffect } from 'react';
import { useMount } from 'react-use';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import moment from 'moment';
import { useRecoilValue } from 'recoil';
import { timezoneAtom } from '../../../../atoms/Atoms';
import { logType } from '../../../utils/functions';
import api from '../../../../api/api';

function AuditLogs() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(null);
  const [current, setCurrent] = useState(0);
  const [size, setSize] = useState(10);
  const [logs, setLogs] = useState(null);
  const [reload, setReload] = useState(false);
  const timezone = useRecoilValue(timezoneAtom);
  const [count, setCount] = useState(0);

  function mapLogs(data) {
    const list = _.map(data, (p, index) => ({
      key: index,
      createdAt: moment(`${p.createdAt}+0000`)
        .tz(timezone)
        .format('MMM Do YYYY, h:mm:ss a'),
      location: p.path,
      details: p.description,
      type: logType(p.type)
    }));
    return list;
  }

  function getData() {
    setLoading(true);
    api.get(`logs/audit/${current}/${size}`)
      .then((response) => {
        setLogs(mapLogs(response.data.data.results));
        setCount(Number(response.data.message));
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  }

  useMount(() => {
    getData();
  });

  useEffect(() => {
    getData();
  }, [reload]);

  return (
    <div className="p-4 border bg-gray-100 rounded-lg mb-4">
      <Typography.Title level={5}>{t('audit_logs')}</Typography.Title>
      <br />
      <Table
        size="small"
        loading={loading}
        bordered
        pagination={{
          current: current + 1,
          hideOnSinglePage: true,
          showSizeChanger: true,
          size: 'small',
          pageSize: size,
          total: count,
          pageSizeOptions: [10, 20, 30, 40, 50],
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} ${t('of')} ${total} ${t('items')}`,
          onShowSizeChange: (page, row) => {
            setReload(!reload);
            setSize(row);
          },
          onChange: (page) => {
            setReload(!reload);
            setCurrent(page - 1);
          },
        }}
        columns={[
          {
            title: t('created_at'),
            dataIndex: 'createdAt',
            key: 'createdAt',
            align: 'center',
            className: 'text-xs',
            render: (text) => (
              <Typography.Text>
                <span>{text}</span>
              </Typography.Text>
            ),
          },
          {
            title: t('type'),
            dataIndex: 'type',
            key: 'type',
            align: 'center',
            className: 'text-xs',
            render: (text) => <span>{t(text)}</span>,
          },
          {
            title: t('details'),
            dataIndex: 'details',
            key: 'details',
            align: 'center',
            className: 'text-xs',
            render: (text) => <span>{text}</span>,
          },
          {
            title: t('location'),
            dataIndex: 'location',
            key: 'location',
            align: 'center',
            className: 'text-xs',
            render: (text) => <span>{text}</span>,
          }
        ]}
        dataSource={logs}
      />
    </div>
  );
}

export default AuditLogs;
