import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  useRecoilValue,
  useRecoilState,
  useRecoilValueLoadable
} from 'recoil';
import {
  Button,
  Table
} from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import moment from 'moment';
import { SyncOutlined, BuildOutlined } from '@ant-design/icons';
import {
  timezoneAtom,
  selectedSponsorshipAtom,
  refreshSponsorshipsAtom,
  showCreateSponsorshipDrawerAtom,
  profileAtom
} from '../../../atoms/Atoms';
import { configSelector } from '../../../atoms/Selectors';
import EditSponsorship from './shared-components/EditSponsorship';
import api from '../../../api/api';

function Sponsorships() {
  const timezone = useRecoilValue(timezoneAtom);
  const config = useRecoilValueLoadable(configSelector);
  const [sponsorships, setSponsorships] = useState([]);
  const [showCreateSponsorshipDrawer, setShowCreateSponsorshipDrawer] =
    useRecoilState(showCreateSponsorshipDrawerAtom);
  const [selectedSponsorship, setSelectedSponsorship] = useRecoilState(selectedSponsorshipAtom);
  const [refreshSponsorships, setRefreshSponsorships] = useRecoilState(refreshSponsorshipsAtom);
  const [loading, setLoading] = useState(false);
  const userProfile = useRecoilValueLoadable(profileAtom);
  const isAdmin =
    userProfile.contents && userProfile?.contents?.roles?.includes('SuperAdmin');
  const isMarketing =
    isAdmin || (userProfile?.contents && userProfile?.contents?.roles?.includes('Marketing'));
  const history = useHistory();
  const { t } = useTranslation();

  if (!isAdmin && !isMarketing) {
    history.push('/');
    return null;
  }

  const getSponsorships = function () {
    setLoading(true);
    api.get('marketing/sponsorships')
      .then((response) => {
        setLoading(false);
        if (response.data.success) {
          for (let i = 0; i < response.data.data.results.length; i++) {
            let status = 'Not Active';
            const startDate = moment(`${response.data.data.results[i].startDate}+0000`);
            const endDate = moment(`${response.data.data.results[i].endDate}+0000`);
            const currentDate = moment();
            if (currentDate >= startDate && currentDate <= endDate) {
              status = 'Active';
            } else if (currentDate > endDate) {
              status = 'Completed';
            }
            response.data.data.results[i].status = status;
            response.data.data.results[i].startDateTime = moment(startDate).tz(timezone);
            response.data.data.results[i].endDateTime = moment(endDate).tz(timezone);
          }
          setSponsorships(response.data.data.results);
        } else {
          console.error(response.data.error);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  useEffect(() => {
    setSelectedSponsorship(null);
    getSponsorships();
  }, []);

  useEffect(() => {
    if (refreshSponsorships) {
      setRefreshSponsorships(false);
      getSponsorships();
    }
  }, [refreshSponsorships]);

  return (
    <>
      <PageHeader
        title={t('sponsorships')}
        extra={[
          <Button
            type="primary"
            size="small"
            icon={<BuildOutlined />}
            key="create"
            onClick={() => {
              setShowCreateSponsorshipDrawer(true);
              setSelectedSponsorship({});
            }}
          >
            {t('create')}
          </Button>,
          <Button
            key="reload"
            type="primary"
            icon={<SyncOutlined />}
            size="small"
            onClick={() => {
              setRefreshSponsorships(true);
            }}
          >
            {t('reload')}
          </Button>
        ]}
      />
      <Table
        size="small"
        bordered
        loading={loading}
        pagination={false}
        dataSource={sponsorships}
        columns={[
          {
            title: t('name'),
            align: 'center',
            className: 'text-xs',
            render: (row) => (
              <span>{row.name}</span>
            ),
          },
          {
            title: t('start_date'),
            dataIndex: 'startDate',
            align: 'center',
            className: 'text-xs',
            render: (text) => (
              <span>
                {moment(`${text}+0000`)
                  .format('MMMM Do YYYY, h:mm:ss a')}
              </span>
            ),
          },
          {
            title: t('end_date'),
            dataIndex: 'endDate',
            align: 'center',
            className: 'text-xs',
            render: (text) => (
              <span>
                {moment(`${text}+0000`)
                  .format('MMMM Do YYYY, h:mm:ss a')}
              </span>
            ),
          },
          {
            title: t('status'),
            dataIndex: 'status',
            align: 'center',
            className: 'text-xs',
            render: (text) => (
              <span>{text}</span>
            ),
          },
          {
            title: '',
            align: 'center',
            className: 'text-xs',
            render: (row) => (
              <div>
                <Button
                  size="small"
                  type="primary"
                  href={`/sponsorship/${row.id}`}
                  onClick={() => {
                    setSelectedSponsorship(row);
                  }}
                >
                  {t('edit')}
                </Button>
              </div>
            ),
          }
        ]}
      />
      {selectedSponsorship && showCreateSponsorshipDrawer && (
        <EditSponsorship />
      )}
    </>
  );
}

export default Sponsorships;
