import React, { useEffect, useState } from 'react';
import {
  Button,
  Table,
  Typography,
  Image,
  Select
} from 'antd';
import moment from 'moment';
import _ from 'lodash';
import { useRecoilValue, useRecoilState } from 'recoil';
import { useJsonToCsv } from 'react-json-csv';
import {
  CloudDownloadOutlined, ReloadOutlined
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import api from '../../../../api/api';
import { dashboardSelectedStoreAtom, dashboardPayloadAtom } from '../../../../atoms/Atoms';

function TopProducts() {
  const { t } = useTranslation();
  const { Option } = Select;
  const [amount, setAmount] = useState(10);
  const [products, setProducts] = useState([]);
  const { saveAsCsv } = useJsonToCsv();
  const selectedStoreId = useRecoilValue(dashboardSelectedStoreAtom);
  const [dashboardPayload, setDashboardPayload] = useRecoilState(dashboardPayloadAtom);
  const [loading, setLoading] = useState(false);
  const listFields = {
    name: t('name'),
    price: t('price'),
    description: t('description'),
    count: t('count'),
  };

  function getData() {
    setLoading(true);
    api.post(`analytics/v2/topproducts/${amount}/${selectedStoreId}`, {
      Lifetime: dashboardPayload.isLifetime,
      StartDate: moment(dashboardPayload.start).utc(),
      EndDate: moment(dashboardPayload.end).utc().add(1, 'days')
    }).then((response) => {
      setLoading(false);
      setProducts(response.data.data.results);
    }).catch((error) => {
      console.error(error);
      setLoading(false);
    });
  }

  useEffect(() => {
    getData();
  }, [dashboardPayload.isLifetime, amount, selectedStoreId]);

  useEffect(() => {
    if (dashboardPayload.load) {
      getData();
    }
  }, [dashboardPayload.load]);

  return (
    <div>
      <div className="justify-between mt-4">
        <div className="grid md:flex float-right space-x-0 md:space-x-2">
          <div>
            <span className="mt-2 mr-2">{t('amount_to_show')}</span>
            <Select
              value={amount}
              style={{ width: 60 }}
              onChange={(obj) => setAmount(obj)}
            >
              <Option value={10}>10</Option>
              <Option value={15}>15</Option>
              <Option value={20}>20</Option>
              <Option value={25}>25</Option>
              <Option value={30}>30</Option>
            </Select>
          </div>
          <div className="mt-2 md:mt-0 flex space-x-2">
            <Button
              icon={<CloudDownloadOutlined />}
              onClick={() => {
                const data = products;
                saveAsCsv({
                  data,
                  fields: listFields,
                  filename: `top_products_${new Date().toLocaleDateString()}`,
                });
              }}
            >
              {t('export')}
            </Button>
            <Button
              icon={<ReloadOutlined />}
              onClick={() => {
                getData();
              }}
            >
              {t('reload')}
            </Button>
          </div>
        </div>
        <Table
          className="pt-2"
          loading={loading}
          size="small"
          rowKey="orderId"
          scroll={{ x: 100 }}
          pagination={false}
          dataSource={products}
          columns={[
            {
              title: t('image'),
              key: 'image',
              align: 'center',
              dataIndex: 'imageUrl',
              className: 'text-xs',
              render: (row) => (
                <Image
                  alt="logo"
                  width={60}
                  src={row}
                  className="cursor-pointer"
                />
              ),
            },
            {
              title: t('name'),
              key: 'name',
              dataIndex: 'name',
              align: 'left',
              className: 'text-xs',
              render: (row) => <Typography.Text copyable>{row}</Typography.Text>,
            },
            {
              title: t('description'),
              dataIndex: 'description',
              key: 'description',
              align: 'left',
              className: 'text-xs',
              render: (text) => <Typography.Text>{text}</Typography.Text>,
            },
            {
              title: t('count'),
              dataIndex: 'orderCount',
              key: 'orderCount',
              align: 'left',
              className: 'text-xs',
              render: (text) => <Typography.Text>{text}</Typography.Text>,
            },
            {
              title: t('sale'),
              dataIndex: 'sales',
              key: 'sales',
              align: 'left',
              className: 'text-xs',
              render: (text) => <span>${text.toFixed(2)}</span>,
            },
          ]}
        />
      </div>
    </div>
  );
}

export default TopProducts;
