import React from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import {
  Tabs
} from 'antd';
import BookingsHistory from './BookingsHistory';
import BookingPurchaseHistory from './BookingPurchaseHistory';
import PendingPayment from './PendingPayment';

function BookingsHistoryReport() {
  const { t } = useTranslation();

  return (
    <Tabs defaultActiveKey="1">
      <Tabs.TabPane tab={t('bookings_history')} key="1">
        <BookingsHistory />
      </Tabs.TabPane>
      <Tabs.TabPane tab={t('payments_history')} key="2">
        <BookingPurchaseHistory />
      </Tabs.TabPane>
      <Tabs.TabPane tab={t('pending_payment')} key="3">
        <PendingPayment />
      </Tabs.TabPane>
    </Tabs>
  );
}
export default BookingsHistoryReport;
