import React, { useState, useEffect } from 'react';
import { useRecoilValue, useRecoilValueLoadable } from 'recoil';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import _ from 'lodash';
import { useJsonToCsv } from 'react-json-csv';
import {
  Tag,
  Table,
  Select,
  DatePicker,
  Descriptions,
  Spin,
  Button,
  Modal
} from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import {
  CheckCircleOutlined,
} from '@ant-design/icons';
import dayjs from 'dayjs';
import { businessAtom, timezoneAtom, profileAtom } from '../../../atoms/Atoms';
import { thousand, thousandv2, renderDate } from '../../utils/functions';
import api from '../../../api/api';
import envConfig from '../../../envConfig';
import { IFrame } from '../../shared-components/IFrame';

function DepartmentsFinance() {
  const { RangePicker } = DatePicker;
  const { t } = useTranslation();
  const { Option } = Select;
  const { saveAsCsv } = useJsonToCsv();
  const business = useRecoilValue(businessAtom);
  const timezone = useRecoilValue(timezoneAtom);
  const profile = useRecoilValueLoadable(profileAtom);
  const isAdmin =
    profile.contents && profile?.contents?.roles?.includes('SuperAdmin');
  const isStoreManager =
    isAdmin ||
    (profile?.contents &&
      (profile?.contents?.roles?.includes('StoreAdmin') ||
        profile?.contents?.roles?.includes('Owner')));
  const canViewFinance =
    isAdmin ||
    isStoreManager ||
    (profile.contents &&
      (profile.contents.roles.includes('Finance') ||
        profile.contents.roles.includes('TopAnalytics')));
  const [reportData, setReportData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [dateRange, setDateRange] = useState({
    start: moment().startOf('month').format('YYYY-MM-DD'),
    end: moment().endOf('month').format('YYYY-MM-DD')
  });
  const [settlementReceipt, setSettlementReceipt] = useState(null);
  const width = window.innerWidth;

  function showReceipt(settementHtml) {
    let tempHtml = settementHtml;
    tempHtml = tempHtml.replace('</title><script', '</title><link rel="stylesheet" type="text/css" href="https://grocefystorage.blob.core.windows.net/evertecterminal/ReceiptStyle.css"><script');
    tempHtml = tempHtml.replace('ATHLogo.png', 'https://grocefystorage.blob.core.windows.net/evertecterminal/ath-logo-02.png');
    tempHtml = tempHtml.replace('<div class="CTLS_CONTENT"></div>', '<div class="CTLS_CONTENT">&nbsp;</div>');
    tempHtml = tempHtml.replace('<div class="CTLS_LABEL"></div>', '<div class="CTLS_LABEL">&nbsp;</div>');
    tempHtml = tempHtml.replace('<div class="CTLS_CONTENT">         </div>', '<div class="CTLS_CONTENT">&nbsp;</div>');
    tempHtml = tempHtml.replace('<div class="CTLS_LABEL">         </div>', '<div class="CTLS_LABEL">&nbsp;</div>');
    setSettlementReceipt(tempHtml);
  }

  function getData() {
    setLoading(true);
    const data = {
      StartDate: dateRange.start,
      EndDate: dateRange.end,
      BusinessId: business?.id
    };
    api
      .post('pos/terminalsettlement/report', data)
      .then((response) => {
        setLoading(false);
        setReportData(response.data.data);
        // console.log(response.data.data);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  }

  useEffect(() => {
    getData();
  }, [dateRange, business]);

  return (
    <div>
      <PageHeader
        className="mb-4 px-0"
        title={t('terminal_settlements')}
        tags={
          <Tag
            color="#2db7f5"
            icon={<CheckCircleOutlined />}
          >
            {business ? business.name : t('global')}
          </Tag>
        }
        extra={
          [
            <RangePicker
              disabled={loading}
              format="YYYY-MM-DD"
              value={[
                dayjs(dateRange.start, 'YYYY-MM-DD'),
                dayjs(dateRange.end, 'YYYY-MM-DD')
              ]}
              onChange={(date, dateString) => {
                setDateRange({
                  start: dateString[0],
                  end: dateString[1]
                });
              }}
            />,
            <Button
              type="primary"
              size="small"
              onClick={() => getData()}
            >
              {t('reload')}
            </Button>
          ]
        }
      />
      <div>
        <dl className="mt-5 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3">
          <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
            <Spin spinning={loading}>
              <dt className="text-sm font-medium text-gray-500 truncate">{t('total')}</dt>
              <dd className="mt-1 text-3xl font-semibold text-gray-900">${thousand(reportData?.totalSales)}
                <div className="inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium md:mt-2 lg:mt-0" style={{ float: 'right', marginTop: '30px' }}>
                  {thousand(reportData?.totalCount)}
                </div>
              </dd>
            </Spin>
          </div>
          <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
            <Spin spinning={loading}>
              <dt className="text-sm font-medium text-gray-500 truncate">{t('credit_debit')}</dt>
              <dd className="mt-1 text-3xl font-semibold text-gray-900">${thousand(reportData?.totalCreditDebitSales)}
                <div className="inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium md:mt-2 lg:mt-0" style={{ float: 'right', marginTop: '30px' }}>
                  {thousand(reportData?.totalCreditDebitCount)}
                </div>
              </dd>
            </Spin>
          </div>
          <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
            <Spin spinning={loading}>
              <dt className="text-sm font-medium text-gray-500 truncate">{t('cash')}</dt>
              <dd className="mt-1 text-3xl font-semibold text-gray-900">${thousand(reportData?.salesCash)}
                <div className="inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium md:mt-2 lg:mt-0" style={{ float: 'right', marginTop: '30px' }}>
                  {thousand(reportData?.countCash)}
                </div>
              </dd>
            </Spin>
          </div>
          <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
            <Spin spinning={loading}>
              <dt className="text-sm font-medium text-gray-500 truncate">{t('credit')}</dt>
              <dd className="mt-1 text-3xl font-semibold text-gray-900">${thousand(reportData?.salesCredit)}
                <div className="inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium md:mt-2 lg:mt-0" style={{ float: 'right', marginTop: '30px' }}>
                  {thousand(reportData?.countCredit)}
                </div>
              </dd>
            </Spin>
          </div>
          <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
            <Spin spinning={loading}>
              <dt className="text-sm font-medium text-gray-500 truncate">{t('debit')}</dt>
              <dd className="mt-1 text-3xl font-semibold text-gray-900">${thousand(reportData?.salesDebit)}
                <div className="inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium md:mt-2 lg:mt-0" style={{ float: 'right', marginTop: '30px' }}>
                  {thousand(reportData?.countDebit)}
                </div>
              </dd>
            </Spin>
          </div>
          <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
            <Spin spinning={loading}>
              <dt className="text-sm font-medium text-gray-500 truncate">{t('refund')}</dt>
              <dd className="mt-1 text-3xl font-semibold text-gray-900">${thousand(reportData?.totalRefund)}
                <div className="inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium md:mt-2 lg:mt-0" style={{ float: 'right', marginTop: '30px' }}>
                  {thousand(reportData?.totalRefundCount)}
                </div>
              </dd>
            </Spin>
          </div>
        </dl>
        <Table
          className="mt-4"
          size="small"
          loading={loading}
          bordered
          dataSource={reportData?.settlements}
          pagination={false}
          rowKey="id"
          columns={[
            {
              title: t('date'),
              key: 'createdAt',
              align: 'left',
              className: 'text-xs',
              sorter: (a, b) => moment(a.createdAt) - moment(b.createdAt),
              render: (text) =>
                <span>
                  {renderDate(text.createdAt, timezone)}
                </span>,
            },
            {
              title: t('store'),
              dataIndex: 'businessName',
              key: 'businessName',
              align: 'left',
              className: 'text-xs',
              sorter: (a, b) => a.businessName.localeCompare(b.businessName),
              render: (text) => <span>{text}</span>,
            },
            {
              title: t('POS'),
              dataIndex: 'posTag',
              key: 'posTag',
              align: 'left',
              className: 'text-xs',
              sorter: (a, b) => a.posTag.localeCompare(b.posTag),
              render: (text) => <span>{text}</span>,
            },
            {
              title: t('sales'),
              dataIndex: 'totalSales',
              key: 'totalSales',
              align: 'right',
              className: 'text-xs',
              sorter: (a, b) => a.totalSales - b.totalSales,
              render: (text) => <span>${thousandv2(text)}</span>,
            },
            {
              title: t('orders'),
              dataIndex: 'totalCount',
              key: 'totalCount',
              align: 'right',
              className: 'text-xs',
              sorter: (a, b) => a.totalCount - b.totalCount,
              render: (text) => <span>{thousand(text)}</span>,
            },
            {
              title: t('refunds'),
              dataIndex: 'totalRefund',
              key: 'totalRefund',
              align: 'right',
              className: 'text-xs',
              sorter: (a, b) => a.totalRefund - b.totalRefund,
              render: (text) => <span>${thousandv2(text)}</span>,
            },
            {
              title: t('refund_count'),
              dataIndex: 'totalRefundCount',
              key: 'totalRefundCount',
              width: 100,
              align: 'right',
              className: 'text-xs',
              sorter: (a, b) => a.totalRefundCount - b.totalRefundCount,
              render: (text) => <span>{thousand(text)}</span>,
            },
            {
              title: t('actions'),
              key: 'receipt',
              align: 'left',
              className: 'text-xs',
              render: (row) => (
                <Button
                  type="primary"
                  disabled={!row.settleReceipt}
                  size="small"
                  onClick={() => showReceipt(row.settleReceipt)}
                >
                  {t('receipt')}
                </Button>
              ),
            },
          ]}
          expandable={{
            expandedRowRender: (record) => (
              <Descriptions bordered>
                <Descriptions.Item label={t('credit')}>
                  <b>{t('sales')}: </b>
                  <span>${thousand(record.salesCredit)}</span>
                  <br />
                  <b>{t('count')}: </b>
                  <span>{thousand(record.countCredit)}</span>
                  <br />
                  <b>{t('refund_total')}: </b>
                  <span>${thousand(record.refundCredit)}</span>
                  <br />
                  <b>{t('refund_count')}: </b>
                  <span>{thousand(record.refundCreditCount)}</span>
                </Descriptions.Item>
                <Descriptions.Item label={t('debit')}>
                  <b>{t('sales')}: </b>
                  <span>${thousand(record.salesDebit)}</span>
                  <br />
                  <b>{t('count')}: </b>
                  <span>{thousand(record.countDebit)}</span>
                  <br />
                  <b>{t('refund_total')}: </b>
                  <span>${thousand(record.refundDebit)}</span>
                  <br />
                  <b>{t('refund_count')}: </b>
                  <span>{thousand(record.refundDebitCount)}</span>
                </Descriptions.Item>
                <Descriptions.Item label={t('cash')}>
                  <b>{t('sales')}: </b>
                  <span>${thousand(record.salesCash)}</span>
                  <br />
                  <b>{t('count')}: </b>
                  <span>{thousand(record.countCash)}</span>
                  <br />
                  <b>{t('refund_total')}: </b>
                  <span>${thousand(record.refundCash)}</span>
                  <br />
                  <b>{t('refund_count')}: </b>
                  <span>{thousand(record.refundCashCount)}</span>
                </Descriptions.Item>
              </Descriptions>
            ),
            rowExpandable: (record) => record,
          }}
        />
      </div>
      <Modal
        closable="true"
        centered="true"
        height={650}
        width={430}
        onCancel={() => setSettlementReceipt(null)}
        open={settlementReceipt}
        footer={[
          <Button
            key="back"
            onClick={() => setSettlementReceipt(null)}
          >
            {t('close')}
          </Button>
        ]}
      >
        <IFrame className="mx-auto mt-6" height={650} width={400}>
          <div className="mx-auto">
            <div dangerouslySetInnerHTML={{ __html: settlementReceipt }} />
          </div>
        </IFrame>
      </Modal>
    </div>
  );
}
export default DepartmentsFinance;
