import React from 'react';
import { Tabs } from 'antd';
import Stripe from './stripe/Stripe';
import Paypal from './paypal/Paypal';

function Payouts() {
  return (
    <div>
      <Tabs defaultActiveKey="0">
        <Tabs.TabPane tab="Stripe" key="0">
          <Stripe />
        </Tabs.TabPane>
        <Tabs.TabPane tab="PayPal" key="1">
          <Paypal />
        </Tabs.TabPane>
      </Tabs>
    </div>
  );
}

export default Payouts;
