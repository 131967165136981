import React, { useEffect, useState } from 'react';
import {
  useRecoilState,
  useRecoilValueLoadable
} from 'recoil';
import { useTranslation } from 'react-i18next';
import {
  Tag,
  Button,
  Table
} from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import { useHistory } from 'react-router-dom';
import {
  selectedBusinessAccountAtom,
  refreshBusinessAcountsAtom,
  profileAtom
} from '../../../atoms/Atoms';
import EditBusinessAccount from './shared-components/EditBusinessAccount';
import api from '../../../api/api';

function BusinessAccounts() {
  const [businessAccounts, setBusinessAccounts] = useState([]);
  const [selectedBusinessAccount, setSelectedBusinessAccount]
    = useRecoilState(selectedBusinessAccountAtom);
  const [refreshBusinessAccounts, setRefreshBusinessAccounts]
    = useRecoilState(refreshBusinessAcountsAtom);
  const [loading, setLoading] = useState(false);
  const profile = useRecoilValueLoadable(profileAtom);
  const isAdmin =
    profile.contents && profile?.contents?.roles?.includes('SuperAdmin');
  const history = useHistory();
  const { t } = useTranslation();

  if (!isAdmin) {
    history.push('/');
    return null;
  }

  const getBusinessAccounts = function () {
    setLoading(true);
    api.get('businesses/accounts')
      .then((response) => {
        setLoading(false);
        if (response.data.success) {
          setBusinessAccounts(response.data.data.results);
        } else {
          console.error(response.data.error);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  useEffect(() => {
    setSelectedBusinessAccount(null);
    getBusinessAccounts();
  }, []);

  useEffect(() => {
    if (refreshBusinessAccounts) {
      setRefreshBusinessAccounts(false);
      getBusinessAccounts();
    }
  }, [refreshBusinessAccounts]);

  return (
    <>
      <PageHeader
        title={t('business_accounts')}
        extra={
          <div>
            <Button
              className="mr-4"
              key="create"
              size="small"
              type="primary"
              onClick={() => {
                setSelectedBusinessAccount({});
              }}
            >
              {t('create')}
            </Button>
            <Button
              key="reload"
              size="small"
              type="primary"
              onClick={() => {
                setRefreshBusinessAccounts(true);
              }}
            >
              {
              t('reload')
              }
            </Button>
          </div>
          }
      />
      <Table
        size="small"
        bordered
        loading={loading}
        pagination={false}
        dataSource={businessAccounts}
        rowKey="id"
        columns={[
          {
            title: t('active'),
            align: 'center',
            className: 'text-xs',
            render: (row) => (
              <span>{row.isActive ? <Tag color="green">Yes</Tag> : <Tag color="red">No</Tag>}</span>
            ),
          },
          {
            title: t('business_name'),
            dataIndex: 'businessName',
            align: 'left',
            className: 'text-xs',
            render: (text) => (
              <span>{text}</span>
            ),
          },
          {
            title: t('account_holder'),
            dataIndex: 'accountHolderName',
            align: 'left',
            className: 'text-xs',
            render: (text) => (
              <span>{text}</span>
            ),
          },
          {
            title: t('account_number'),
            dataIndex: 'accountNumber',
            align: 'left',
            className: 'text-xs',
            render: (text) => (
              <span>{text}</span>
            ),
          },
          {
            title: t('routing_number'),
            dataIndex: 'routingNumber',
            align: 'left',
            className: 'text-xs',
            render: (text) => (
              <span>{text}</span>
            ),
          },
          {
            title: '',
            align: 'center',
            className: 'text-xs',
            render: (row) => (
              <Button
                type="primary"
                size="small"
                onClick={() => {
                  setSelectedBusinessAccount(row);
                }}
              >
                {t('edit')}
              </Button>
            ),
          }
        ]}
      />
      {selectedBusinessAccount && (
        <EditBusinessAccount />
      )}
    </>
  );
}

export default BusinessAccounts;
