import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import is from 'is_js';
import {
  Drawer,
  Form,
  Input,
  Upload
} from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  tokenAtom,
  selectedPushAtom,
  reloadPushAtom,
  showPushCreateAtom
} from '../../../../atoms/Atoms';
import envConfig from '../../../../envConfig';
import api from '../../../../api/api';

function CreatePushDrawer() {
  const { Dragger } = Upload;
  const [showCreate, setShowCreate] = useRecoilState(showPushCreateAtom);
  const selectedPush = useRecoilValue(selectedPushAtom);
  const [reload, setReload] = useRecoilState(reloadPushAtom);
  const [loading, setLoading] = useState(false);
  const token = useRecoilValue(tokenAtom);
  const [imageEnglishData, setImageEnglishData] = useState(null);
  const [imageSpanishData, setImageSpanishData] = useState(null);
  const [imageSpa, setImageSpa] = useState(null);
  const [imageEng, setImageEng] = useState(null);
  const { t } = useTranslation();

  const englishDraggerProps = {
    name: 'file',
    accept: 'image/*',
    multiple: false,
    showUploadList: false,
    beforeUpload: (file) => {
      setImageEnglishData(file);
      return false;
    }
  };
  const spanishDraggerProps = {
    name: 'file',
    accept: 'image/*',
    multiple: false,
    showUploadList: false,
    beforeUpload: (file) => {
      setImageSpanishData(file);
      return false;
    }
  };
  const uploadImage = async function (imageData) {
    const file = imageData;
    const boundary = Date.now();
    const data = new FormData();
    data.append('file', file);
    setLoading(true);
    const response = await api
      .post('utility/digitalimages/uploadfile', data);
    setLoading(false);
    if (response.data.success) {
      return response.data.data;
    }
    return null;
  };

  useEffect(async () => {
    if (imageEnglishData) {
      const url = await uploadImage(imageEnglishData);
      if (url) {
        setImageEnglishData(null);
        setImageEng(url);
      }
    }
  }, [imageEnglishData]);

  useEffect(async () => {
    if (imageSpanishData) {
      const url = await uploadImage(imageSpanishData);
      if (url) {
        setImageSpanishData(null);
        setImageSpa(url);
      }
    }
  }, [imageSpanishData]);

  function prepareForm() {
    const [form] = Form.useForm();
    return (
      <div>
        {' '}
        <Formik
          initialValues={{
            id: '',
            name: '',
            messageENG: '',
            messageSPA: '',
            mediaUrl: '',
            mediaUrlEng: ''
          }}
          validate={(values) => {
            const errors = {};
            if (is.empty(values.messageSPA)) {
              errors.name = t('name_required');
            }
            if (is.empty(values.messageSPA)) {
              errors.messageSPA = 'Mensaje is a required field.';
            }
            if (is.empty(values.messageENG)) {
              errors.messageENG = 'Message is a required field.';
            }
            return errors;
          }}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(true);
            api
              .post(
                'marketing/push/create',
                {
                  MessageENG: values.messageENG,
                  MessageSPA: values.messageSPA,
                  Status: 0,
                  Type: 0,
                  Name: values.name,
                  AutoApproved: false,
                  MediaUrl: imageSpa,
                  MediaUrlEng: imageEng,
                  IsVisible: values.visible,
                  DeliveryDate: values.deliveryDate,
                  // values['Publish Date'].format('YYYY-MM-DDTHH:mm:ss'),
                }
              )
              .then((response) => {
                if (
                  response &&
                  response.data.success &&
                  response.data.message
                ) {
                  setReload(true);
                } else if (response && response.data.error) {
                  console.error('Error Message: ', response.data.error);
                }
                setSubmitting(false);
                setShowCreate(null);
              })
              .catch((error) => {
                console.error(error);
                setSubmitting(false);
              });
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            isSubmitting,
          }) => (
            <form noValidate onSubmit={handleSubmit} className="text-sm">
              <div className="mb-3">
                <div className="font-bold tracking-tight mb-1">
                  {t('name')} <span className="text-cyan-500">*</span>
                </div>
                <input
                  type="text"
                  name="name"
                  id="name"
                  className="text-gray-900 text-xs rounded p-4 w-full appearance-none ring-0 focus:ring-0 border border-gray-200 focus:border-gray-300 outline-none focus:outline-none transition ease-in-out duration-500"
                  value={values.name}
                  onChange={handleChange}
                />
                {errors.name && touched.name && (
                  <div className="w-full font-semibold text-xs text-red-400 mt-1">
                    {errors.name}
                  </div>
                )}
              </div>
              <div className="mb-3">
                <div className="font-bold tracking-tight mb-1">
                  Mensaje (Español) <span className="text-cyan-500">*</span>
                </div>
                <input
                  type="text"
                  name="messageSPA"
                  id="messageSPA"
                  placeholder="i.e. Colgate"
                  className="text-gray-900 text-xs rounded p-4 w-full appearance-none ring-0 focus:ring-0 border border-gray-200 focus:border-gray-300 outline-none focus:outline-none transition ease-in-out duration-500"
                  value={values.messageSPA}
                  onChange={handleChange}
                />
                {errors.messageSPA && touched.messageSPA && (
                  <div className="w-full font-semibold text-xs text-red-400 mt-1">
                    {errors.messageSPA}
                  </div>
                )}
              </div>
              <div className="mb-3">
                <div className="font-bold tracking-tight mb-1">
                  Message (English) <span className="text-cyan-500">*</span>
                </div>
                <input
                  type="text"
                  name="messageENG"
                  id="messageENG"
                  placeholder="i.e. Toothbrush"
                  className="text-gray-900 text-xs rounded p-4 w-full appearance-none ring-0 focus:ring-0 border border-gray-200 focus:border-gray-300 outline-none focus:outline-none transition ease-in-out duration-500"
                  value={values.messageENG}
                  onChange={handleChange}
                />
                {errors.messageENG && touched.messageENG && (
                  <div className="w-full font-semibold text-xs text-red-400 mt-1">
                    {errors.messageENG}
                  </div>
                )}
              </div>
              <div className="mb-3">
                <div className="font-bold tracking-tight mb-1">Image (SPA)</div>
                <Dragger {...spanishDraggerProps}>
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p
                    className="ant-upload-text"
                    style={{ margin: 10 }}
                  >
                    {t('click_or_drag_content')}
                  </p>
                </Dragger>
                <Input value={imageSpa} loading={loading} />
                {errors.imageSpa && touched.imageSpa && (
                  <div className="w-full font-semibold text-xs text-red-400 mt-1">
                    {errors.imageSpa}
                  </div>
                )}
              </div>
              <div className="mb-3">
                <div className="font-bold tracking-tight mb-1">Image (ENG)</div>
                <Dragger {...englishDraggerProps}>
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p
                    className="ant-upload-text"
                    style={{ margin: 10 }}
                  >
                    {t('click_or_drag_content')}
                  </p>
                </Dragger>
                <Input value={imageEng} loading={loading} />
                {errors.imageEng && touched.imageEng && (
                  <div className="w-full font-semibold text-xs text-red-400 mt-1">
                    {errors.imageEng}
                  </div>
                )}
              </div>
              <div className="mb-3">
                <div className="font-bold tracking-tight mb-1">{t('delivery_date')}</div>
                <input
                  type="date"
                  name="deliveryDate"
                  id="deliveryDate"
                  placeholder="01/01/2025"
                  className="text-gray-900 text-xs rounded p-4 w-full appearance-none
                  ring-0 focus:ring-0 border border-gray-200 focus:border-gray-300 outline-none
                   focus:outline-none transition ease-in-out duration-500"
                  value={values.DeliveryDate}
                  onChange={handleChange}
                />
                {errors.DeliveryDate && touched.DeliveryDate && (
                  <div className="w-full font-semibold text-xs text-red-400 mt-1">
                    {errors.DeliveryDate}
                  </div>
                )}
              </div>
              <div className="flex space-x-3 mt-10">
                <button
                  className="w-full md:w-1/2 text-xs transition delay-150 duration-300 ease-in-out border border-gray-700 hover:border-red-600 text-gray-500 hover:text-red-400 font-semibold px-6 py-3 rounded-full"
                  type="button"
                  onClick={(e) => {
                    e.preventDefault();
                    setShowCreate(null);
                  }}
                >
                  {t('cancel')}
                </button>
                <button
                  className="w-full md:w-1/2 flex items-center justify-center text-center text-xs transition delay-150 duration-300 ease-in-out bg-blue-500 hover:bg-blue-600 text-white font-semibold px-6 py-3 rounded-full disabled:cursor-not-allowed disabled:opacity-50"
                  type="submit"
                  disabled={isSubmitting}
                >
                  {' '}
                  {isSubmitting && (
                    <div>
                      <svg
                        className="animate-spin -ml-1 mr-3 h-4 w-4 text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        />
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        />
                      </svg>
                    </div>
                  )}
                  {t('save')}
                </button>
              </div>
            </form>
          )}
        </Formik>
      </div>
    );
  }

  return (
    <Drawer
      title={t('create_push')}
      placement="right"
      closable={false}
      onClose={() => setShowCreate(false)}
      open={showCreate}
      width={300}
    >
      <div>
        {' '}
        <div>{prepareForm()}</div>
      </div>
    </Drawer>
  );
}
export default CreatePushDrawer;
