import React, { useEffect, useState } from 'react';
import {
  DatePicker,
  Button,
  Table,
  Typography,
  Image,
} from 'antd';
import moment from 'moment';
import dayjs from 'dayjs';
import _ from 'lodash';
import { useRecoilValue } from 'recoil';
import { useJsonToCsv } from 'react-json-csv';
import {
  CloudDownloadOutlined, ReloadOutlined
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import api from '../../../../api/api';
import { businessAtom } from '../../../../atoms/Atoms';

function TopProducts() {
  const { RangePicker } = DatePicker;
  const { t } = useTranslation();
  const [currentPage, setCurrentPage] = useState(0);
  const [size, setSize] = useState(20);
  const [products, setProducts] = useState([]);
  const [lastSearch, setLastSearch] = useState('');
  const [isSearching, setIsSearching] = useState(false);
  const [totalItemsInSearch, setTotalItemsInSearch] = useState(0);
  const { saveAsCsv } = useJsonToCsv();
  const [dateRange, setDateRange] = useState({
    start: moment().startOf('month').format('YYYY-MM-DD'),
    end: moment().endOf('month').format('YYYY-MM-DD')
  });
  const [reload, setReload] = useState(false);
  const business = useRecoilValue(businessAtom);

  function search() {
    setIsSearching(true);
    setTotalItemsInSearch(0);
    const data = {
      startDate: dateRange.start,
      endDate: dateRange.end,
    };
    api.post('analytics/topProducts', data)
      .then((response) => {
        setIsSearching(false);
        setProducts(response.data.data.results);
        setTotalItemsInSearch(response.data.data.results.length);
      })
      .catch((err) => {
        setIsSearching(false);
        console.error(err);
      });
  }

  const onTableChange = (page, pageSize) => {
    if (page - 1 !== currentPage) {
      setCurrentPage(page - 1);
      search(lastSearch, page - 1);
    } else {
      setSize(pageSize);
    }
  };

  useEffect(() => {
    setTotalItemsInSearch(0);
    setCurrentPage(0);
    search(lastSearch, 0);
    setProducts(null);
  }, [size, dateRange]);

  useEffect(() => {
    setTotalItemsInSearch(0);
    setCurrentPage(0);
    search(lastSearch, 0);
    setProducts(null);
  }, [reload]);

  useEffect(() => {
    setProducts(null);
    search(lastSearch, 0);
  }, [dateRange, business]);

  const listFields = {
    name: t('name'),
    price: t('price'),
    description: t('description'),
    count: t('count'),
  };

  return (
    <div>
      <div className="flex">
        <h3 className="flex-col text-lg leading-6 font-medium text-gray-900">{t('top_products')}</h3>
        <div className="ml-auto flex-col">
          <RangePicker
            disabled={isSearching}
            format="YYYY-MM-DD"
            value={[
              dayjs(dateRange.start, 'YYYY-MM-DD'),
              dayjs(dateRange.end, 'YYYY-MM-DD')
            ]}
            onChange={(date, dateString) => {
              setDateRange({
                start: dateString[0],
                end: dateString[1]
              });
            }}
          />
        </div>
      </div>
      <div className="justify-between mt-4">
        <Typography.Text>Showing: {totalItemsInSearch?.toLocaleString()}</Typography.Text>
        <Button
          style={{ float: 'right' }}
          size="small"
          icon={<CloudDownloadOutlined />}
          onClick={() => {
            const data = products;
            saveAsCsv({
              data,
              fields: listFields,
              filename: `top_products_${new Date().toLocaleDateString()}`,
            });
          }}
        >
          {t('export')}
        </Button>
        <Button
          style={{ float: 'right', marginRight: '15px' }}
          size="small"
          icon={<ReloadOutlined />}
          onClick={() => {
            setReload(!reload);
          }}
        >
          {t('refresh')}
        </Button>
        <Table
          className="pt-2"
          loading={isSearching}
          size="small"
          rowKey="orderId"
          scroll={{ x: 100 }}
          pagination={{
            pageSize: size,
            showSizeChanger: true,
            defaultCurrent: 0,
            current: currentPage + 1,
            total: totalItemsInSearch,
            onChange: onTableChange
          }}
          dataSource={products}
          columns={[
            {
              title: t('image'),
              key: 'image',
              align: 'center',
              dataIndex: 'imageUrl',
              className: 'text-xs',
              render: (row) => (
                <Image
                  alt="logo"
                  width={60}
                  src={row}
                  className="cursor-pointer"
                />
              ),
            },
            {
              title: t('name'),
              key: 'name',
              dataIndex: 'name',
              align: 'left',
              className: 'text-xs',
              render: (row) => <Typography.Text copyable>{row}</Typography.Text>,
            },
            {
              title: t('description'),
              dataIndex: 'description',
              key: 'description',
              align: 'left',
              className: 'text-xs',
              render: (text) => <Typography.Text>{text}</Typography.Text>,
            },
            {
              title: t('count'),
              dataIndex: 'orderCount',
              key: 'orderCount',
              align: 'left',
              className: 'text-xs',
              render: (text) => <Typography.Text>{text}</Typography.Text>,
            },
            {
              title: t('price'),
              dataIndex: 'sales',
              key: 'sales',
              align: 'left',
              className: 'text-xs',
              render: (text) => <span>${text.toFixed(2)}</span>,
            },
          ]}
        />
      </div>
    </div>
  );
}

export default TopProducts;
