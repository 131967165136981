import React from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import moment from 'moment';
import { Table, Typography } from 'antd';
import { useRecoilValueLoadable, useRecoilValue, useRecoilState } from 'recoil';
import { pageAtom, rowsAtom, timezoneAtom } from '../../../../atoms/Atoms';
import { couponsSelector } from '../../../../atoms/Selectors';

function Coupons() {
  const reportData = useRecoilValueLoadable(couponsSelector);
  const timezone = useRecoilValue(timezoneAtom);
  const [rows, setRows] = useRecoilState(rowsAtom);
  const [current, setCurrent] = useRecoilState(pageAtom);
  const { t } = useTranslation();

  function mapData(data) {
    const mapped = _.map(data, (p) => ({
      key: p.id,
      minimumAmount: p.minimumAmount,
      value: p.value,
      code: p.code,
      confirmationCode: p.confirmationCode,
      createdAt: p.createdAt,
      isExpired: p.isExpired,
      expiresAt: p.expiresAt,
    }));

    return mapped;
  }

  function toShortDate(date) {
    if (date) {
      return moment(`${date}+0000`).tz(timezone).format('MMMM DD, YYYY');
    }
    return '';
  }

  return (
    <div className="p-4 border bg-gray-100 rounded-lg mb-4">
      <Table
        size="small"
        loading={reportData.state === 'loading'}
        bordered
        scroll={{ x: 500 }}
        pagination={{
          current,
          hideOnSinglePage: true,
          showSizeChanger: true,
          size: 'small',
          pageSize: rows,
          total:
          reportData.state === 'hasValue' &&
          reportData.contents.data.data.total,
          pageSizeOptions: [10, 20, 30, 40, 50],
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} ${t('out_of')} ${total} ${t('items')}`,
          onShowSizeChange: (page, size) => {
            setRows(size);
          },
          onChange: (page) => {
            setCurrent(page);
          },
        }}
        columns={[
          {
            title: t('created_at'),
            dataIndex: 'createdAt',
            key: 'createdAt',
            align: 'right',
            className: 'text-xs',
            render: (text) => <span>{toShortDate(text)}</span>,
          },
          {
            title: t('value'),
            dataIndex: 'value',
            key: 'value',
            align: 'right',
            className: 'text-xs',
            render: (text) => (
              <span>
                {' '}
                $
                {text
                  .toFixed(2)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              </span>
            ),
            sorter: (a, b) => a.value - b.value,
          },
          {
            title: t('discount_code'),
            dataIndex: 'code',
            key: 'code',
            align: 'right',
            className: 'text-xs',
            render: (text) => (
              <Typography.Text copyable>
                <span> {text}</span>{' '}
              </Typography.Text>
            ),
            sorter: (a, b) => a.code - b.code,
          },
          {
            title: t('valid_thru'),
            dataIndex: 'expiresAt',
            key: 'expiresAt',
            align: 'right',
            className: 'text-xs',
            render: (text) => <span>{toShortDate(text)}</span>,
          },
        ]}
        dataSource={
          reportData.state === 'hasValue' &&
          mapData(reportData.contents.data.data.results)
          }
      />
    </div>
  );
}

export default Coupons;
