import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import {
  useRecoilState,
  useRecoilValue,
  useRecoilValueLoadable
} from 'recoil';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Modal,
  Select,
  Table,
  Tag,
  Avatar,
  notification,
  Tooltip
} from 'antd';
import { Comment } from '@ant-design/compatible';
import moment from 'moment';
import {
  CheckCircleOutlined,
  CloseCircleOutlined
} from '@ant-design/icons';
import Grocefy from '../../../../assets/images/grocefyLogoAlone.png';
import {
  selectedDeliveryProviderAtom,
  manageDeliveryProviderUsersAtom,
  manageDeliveryProviderZonesAtom
} from '../../../../atoms/Atoms';
import { businessesSelector } from '../../../../atoms/Selectors';
import api from '../../../../api/api';

function ManageDeliveryProviderZones() {
  const { Option } = Select;
  const businesses = useRecoilValueLoadable(businessesSelector);
  const selectedProvider = useRecoilValue(selectedDeliveryProviderAtom);
  const manageProviderUsers = useRecoilValue(manageDeliveryProviderUsersAtom);
  const [manageProviderZones, setManageProviderZones] =
    useRecoilState(manageDeliveryProviderZonesAtom);
  const [business, setBusiness] = useState(null);
  const [loading, setLoading] = useState(false);
  const [zones, setZones] = useState([]);
  const { t } = useTranslation();

  const showMessage = function (message) {
    notification.open({
      message: '',
      description: (
        <Comment
          author={<span>Grocefy</span>}
          avatar={<Avatar src={Grocefy} alt="grocefy" />}
          content={
            <p className="text-sm">
              {message}
            </p>
          }
          datetime={
            <Tooltip title={moment().format('YYYY-MM-DD HH:mm:ss')}>
              <span>{moment().fromNow()}</span>
            </Tooltip>
          }
        />
      ),
    });
  };

  const setZoneLoading = function (zoneId, isLoading) {
    const temp = JSON.parse(JSON.stringify(zones));
    for (let i = 0; i < temp.length; i++) {
      if (temp[i].id === zoneId) {
        temp[i].loading = isLoading;
      }
    }
    setZones(temp);
  };

  const getZones = async function () {
    if (business) {
      setLoading(true);
      const zonesResponse = await api.get(`deliveries/deliveryzones/${business.locations[0].id}`);
      if (zonesResponse.data.success) {
        const providerZonesResponse = await api.get(`tpd/providers/zones/${selectedProvider?.id}`);
        setLoading(false);
        if (providerZonesResponse.data.success) {
          const temp = JSON.parse(JSON.stringify(zonesResponse.data.data.results));
          for (let i = 0; i < temp.length; i++) {
            for (let j = 0; j < providerZonesResponse.data.data.results.length; j++) {
              if (temp[i].id === providerZonesResponse.data.data.results[j].id) {
                temp[i].applied = true;
              }
            }
          }
          setZones(temp);
        } else {
          showMessage(providerZonesResponse.data.error);
        }
      } else {
        setLoading(false);
        showMessage(zonesResponse.data.error);
      }
    }
  };

  const addZone = function (zone) {
    const zoneId = zone.id;
    setZoneLoading(zoneId, true);
    api.post(`tpd/providers/zones/${selectedProvider?.id}/${zone.id}`, { })
      .then((response) => {
        setLoading(false);
        if (response.data.success) {
          const temp = JSON.parse(JSON.stringify(zones));
          for (let i = 0; i < temp.length; i++) {
            if (temp[i].id === zoneId) {
              temp[i].applied = true;
              temp[i].loading = false;
            }
          }
          setZones(temp);
        } else {
          showMessage(response.data.error);
        }
      })
      .catch((error) => {
        setZoneLoading(zoneId, false);
        setLoading(false);
        console.error(error);
      });
  };

  const removeZone = function (zone) {
    const zoneId = zone.id;
    setZoneLoading(zoneId, true);
    api.delete(`tpd/providers/zones/${selectedProvider?.id}/${zone.id}`, { })
      .then((response) => {
        setLoading(false);
        if (response.data.success) {
          const temp = JSON.parse(JSON.stringify(zones));
          for (let i = 0; i < temp.length; i++) {
            if (temp[i].id === zoneId) {
              temp[i].applied = false;
              temp[i].loading = false;
            }
          }
          setZones(temp);
        } else {
          showMessage(response.data.error);
        }
      })
      .catch((error) => {
        setZoneLoading(zoneId, false);
        setLoading(false);
        console.error(error);
      });
  };

  const toggleZone = function (zone) {
    if (zone.applied) {
      removeZone(zone);
    } else {
      addZone(zone);
    }
  };

  const onChangeBusiness = function (obj) {
    if (obj === '') {
      setBusiness(null);
      setZones([]);
    } else {
      const filter = _.filter(businesses.contents.data.data.results, (b) => b.id === obj);
      setBusiness(filter[0]);
    }
  };

  useEffect(() => {
    if (manageProviderZones && business) {
      getZones();
    }
  }, [manageProviderZones, business]);

  return (
    <Modal
      title={t('delivery_zones')}
      open={manageProviderZones && !manageProviderUsers}
      onCancel={() => setManageProviderZones(false)}
      footer={[
        <Button
          key="ok"
          type="primary"
          loading={loading}
          onClick={() => setManageProviderZones(false)}
        >
          {t('close')}
        </Button>
      ]}
    >
      <Select
        defaultValue=""
        value={business?.id}
        style={{ width: '100%' }}
        disabled={loading}
        onChange={(obj) => onChangeBusiness(obj)}
      >
        <Option value="">{t('select_business')}</Option>
        {businesses.state === 'hasValue' && _.map(businesses.contents.data.data.results, (b) => (
          <Option
            value={b.id}
            key={b.id}
          >
            {b.name}
          </Option>
        ))}
      </Select>
      <Table
        bordered
        loading={loading}
        rowKey="id"
        dataSource={zones}
        columns={[
          {
            title: t('name'),
            dataIndex: 'name',
            key: 'name',
            align: 'left',
            render: (text) => <span>{text}</span>
          },
          {
            title: t('status'),
            key: 'isActive',
            align: 'center',
            render: (row) => {
              if (row.isActive) {
                return (
                  <Tag
                    icon={<CheckCircleOutlined />}
                    color="success"
                    size="small"
                    className="mr-0"
                  >
                    {t('active')}
                  </Tag>
                );
              }
              return (
                <Tag
                  icon={<CloseCircleOutlined />}
                  color="error"
                  size="small"
                  className="mr-0"
                >
                  {t('not_active')}
                </Tag>
              );
            }
          },
          {
            title: '',
            align: 'center',
            className: 'text-xs',
            render: (row) => (
              <Button
                size="small"
                loading={row.loading}
                type={row.applied ? 'danger' : 'primary'}
                onClick={() => {
                  toggleZone(row);
                }}
              >
                {row.applied ? t('remove') : t('add')}
              </Button>
            ),
          }
        ]}
      />
    </Modal>
  );
}

export default ManageDeliveryProviderZones;
