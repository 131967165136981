import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  useRecoilValueLoadable,
  useRecoilState
} from 'recoil';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Spin
} from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import {
  TagsOutlined,
} from '@ant-design/icons';
import { profileAtom, selectedKnowledgebase } from '../../../atoms/Atoms';
import Knowledgebase from './shared-components/Knowledgebase';
import api from '../../../api/api';

function Faq() {
  const [selectedKb, setSelectedKb] = useRecoilState(selectedKnowledgebase);
  const profile = useRecoilValueLoadable(profileAtom);
  const isAdmin =
    profile.contents && profile.contents.roles.includes('SuperAdmin');
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [knowledgeBases, setKnowledgebases] = useState([]);

  const defaultKnowledgeBases = [{
    name: t('promo_codes'),
    description: t('dashboard_description'),
    icon: TagsOutlined,
    file: 'promocodes'
  }];

  useEffect(async () => {
    setSelectedKb(null);
    setLoading(true);
    const temp = [];
    for (let i = 0; i < defaultKnowledgeBases.length; i++) {
      const response = await api
        .get(
          `utility/file/helpinfo/${defaultKnowledgeBases[i].file}`
        );
      if (!response.data.error) {
        temp.push(defaultKnowledgeBases[i]);
      }
    }
    setKnowledgebases(temp);
    setLoading(false);
  }, []);

  return (
    <Spin spinning={loading}>
      <PageHeader
        title={t('help_center')}
        extra={[
          <Button
            hidden={!isAdmin}
            key="editkb"
            size="small"
            type="primary"
          >
            <Link
              hidden={!isAdmin}
              to="/help/KnowledgeBases"
              key="editkb"
            >
              {t('edit')}
            </Link>
          </Button>
        ]}
      />
      {!selectedKb && (
        <div className="mt-4 md:mt-10">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
            {knowledgeBases.map((knowledgeBase) => (
              <button
                type="button"
                onClick={() => setSelectedKb(knowledgeBase)}
                key={knowledgeBase.name}
                className="flex border mx-1 p-2 rounded-lg border-2 border-gray-400 hover:border-blue-400"
              >
                <div className="w-full">
                  {knowledgeBase.icon && (
                    <div className="absolute flex items-center justify-center mt-1 h-8 w-8 rounded-md bg-blue-400 text-white">
                      <knowledgeBase.icon
                        style={{ fontSize: '18px' }}
                        aria-hidden="true"
                      />
                    </div>
                  )}
                  <div className="items-center justify-center mx-auto">
                    <p className="ml-10 text-md md:text-lg font-medium text-gray-900">
                      {knowledgeBase.name}
                    </p>
                    {knowledgeBase.description && (
                      <div className="ml-10 -mt-2 text-xs md:text-sm text-gray-600">
                        {knowledgeBase.description}
                      </div>
                    )}
                  </div>
                </div>
              </button>
            ))}
          </div>
        </div>
      )}
      {selectedKb && (
        <Knowledgebase />
      )}
    </Spin>
  );
}

export default Faq;
