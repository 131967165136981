import React from 'react';
import { useTranslation } from 'react-i18next';
import { Statistic, Card, Typography, Tag, Spin } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import { CheckCircleOutlined } from '@ant-design/icons';
import { useRecoilValueLoadable, useRecoilValue } from 'recoil';
import {
  lostSalesPastAnalyticsSelector,
  pastLossOosSelector,
  pastLossOverSubsSelector,
  pastLossSubsSelector,
  pastLossUnderweightsSelector,
} from '../../../../atoms/Selectors';
import { businessAtom } from '../../../../atoms/Atoms';
import {
  calculateContribution
} from '../../../utils/functions';

function PastLossHeader() {
  const { t } = useTranslation();
  const loss = useRecoilValueLoadable(lostSalesPastAnalyticsSelector);
  const oos = useRecoilValueLoadable(pastLossOosSelector);
  const under = useRecoilValueLoadable(pastLossUnderweightsSelector);
  const subs = useRecoilValueLoadable(pastLossSubsSelector);
  const subsOver = useRecoilValueLoadable(pastLossOverSubsSelector);
  const business = useRecoilValue(businessAtom);

  return (
    <Spin spinning={loss.state === 'loading' ||
      oos.state === 'loading' ||
      under.state === 'loading' ||
      subs.state === 'loading' ||
      subsOver.state === 'loading'}
    >
      <PageHeader
        className="px-0"
        title={t('yesterday')}
        subTitle={t('lost_sales')}
        tags={
          <Tag
            color="#2db7f5"
            icon={<CheckCircleOutlined />}
          >
            {business ? business.name : t('global')}
          </Tag>
        }
      />
      <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5">
        {' '}
        <Card className="mr-10">
          <div>
            <Typography>
              <b>{t('total_loss')}</b>
            </Typography>
          </div>
          <Statistic
            title={t('orders')}
            value={`${
              loss?.contents?.data?.data?.length > 0
                ? loss?.contents?.data?.data?.length > 0?.orderCount
                : 0
            }`}
          />
          <Statistic
            title={t('final_products_total')}
            value={`$${
              loss?.contents?.data?.data?.length > 0
                ? loss?.contents?.data?.data?.length > 0?.finalTotal.toFixed(2)
                : 0
            }`}
          />{' '}
          <Statistic
            title={t('original_products_total')}
            value={`$${
              loss?.contents?.data?.data?.length > 0
                ? loss?.contents?.data?.data?.length > 0?.originalTotal.toFixed(2)
                : 0
            }`}
          />{' '}
          <Statistic
            title={t('loss')}
            value={`$${
              loss?.contents?.data?.data?.length > 0
                ? loss?.contents?.data?.data?.length > 0?.diff.toFixed(2)
                : 0
            }`}
          />{' '}
          <Statistic
            title={`${t('loss')} %`}
            value={`${
              loss?.contents?.data?.data?.length > 0
                ? loss?.contents?.data?.data?.length > 0?.diffPct.toFixed(2)
                : 0
            }%`}
          />{' '}
        </Card>
        <Card className="mr-10">
          <div>
            <Typography>
              <b>{t('out_of_stock')}</b>
            </Typography>
          </div>
          <Statistic
            title={t('orders')}
            value={
              oos?.contents?.data?.data?.length > 0
                ? oos.contents.data.data[0].orderCount
                : 0
            }
          />
          <Statistic
            title={t('sales')}
            value={`$${
              oos?.contents?.data?.data?.length > 0
                ? oos.contents.data.data[0].ordersTotal.toFixed(2)
                : 0
            }`}
          />{' '}
          <Statistic
            title={`${t('loss')} $`}
            value={`$${
              oos?.contents?.data?.data?.length > 0
                ? oos.contents.data.data[0].total.toFixed(2)
                : 0
            }`}
          />
          <Statistic
            title={`${t('loss')} %`}
            value={
              (oos?.contents?.data?.data?.length > 0 &&
                loss?.contents?.data?.data?.length > 0) ?
                calculateContribution(
                  loss?.contents?.data?.data?.length > 0?.originalTotal,
                  oos.contents.data.data[0].total
                ) : 0
            }
          />
          <Statistic
            title={t('items')}
            value={`${
              oos?.contents?.data?.data?.length > 0
                ? oos.contents.data.data[0].itemCount
                : 0
            }`}
          />
        </Card>{' '}
        <Card className="mr-10">
          <div>
            <Typography>
              <b>{t('underweights')}</b>
            </Typography>
          </div>
          <Statistic
            title={t('orders')}
            value={
              under?.contents?.data?.data?.length > 0
                ? under.contents.data.data[0].orderCount
                : 0
            }
          />
          <Statistic
            title={t('sales')}
            value={`$${
              under?.contents?.data?.data?.length > 0
                ? under.contents.data.data[0].originalTotal.toFixed(2)
                : 0
            }`}
          />{' '}
          <Statistic
            title={t('loss')}
            value={`$${
              under?.contents?.data?.data?.length > 0
                ? under.contents.data.data[0].diff.toFixed(2)
                : 0
            }`}
          />
          <Statistic
            title={`${t('loss')} %`}
            value={
              (under?.contents?.data?.data?.length > 0 &&
                loss?.contents?.data?.data?.length > 0) ?
                calculateContribution(
                  loss?.contents?.data?.data?.length > 0?.originalTotal,
                  under.contents.data.data[0].diff
                ) : 0
            }
          />
          <Statistic
            title={t('items')}
            value={`${
              under?.contents?.data?.data?.length > 0
                ? under.contents.data.data[0].itemCount
                : 0
            }`}
          />
        </Card>{' '}
        <Card className="mr-10">
          <div>
            <Typography>
              <b>{t('substitutes')} ({t('under')})</b>
            </Typography>
          </div>
          <Statistic
            title={t('orders')}
            value={
              subs?.contents?.data?.data?.length > 0
                ? subs.contents.data.data[0].orderCount
                : 0
            }
          />
          <Statistic
            title={t('sales')}
            value={`$${
              subs?.contents?.data?.data?.length > 0
                ? subs.contents.data.data[0].originalTotal.toFixed(2)
                : 0
            }`}
          />{' '}
          <Statistic
            title={`${t('loss')} $`}
            value={`$${
              subs?.contents?.data?.data?.length > 0
                ? subs.contents.data.data[0].diff.toFixed(2)
                : 0
            }`}
          />
          <Statistic
            title={`${t('loss')} %`}
            value={
              (subs?.contents?.data?.data?.length > 0 &&
                loss?.contents?.data?.data?.length > 0) ?
                calculateContribution(
                  loss?.contents?.data?.data?.length > 0?.originalTotal,
                  subs.contents.data.data[0].diff
                ) : 0
            }
          />
          <Statistic
            title={t('items')}
            value={`${
              subs?.contents?.data?.data?.length > 0
                ? subs.contents.data.data[0].itemCount
                : 0
            }`}
          />
        </Card>{' '}
        <Card className="mr-10">
          <div>
            <Typography>
              <b>{t('substitutes')} ({t('over')})</b>
            </Typography>
          </div>
          <Statistic
            title={t('orders')}
            value={
              subsOver?.contents?.data?.data?.length > 0
                ? subsOver.contents.data.data[0].orderCount
                : 0
            }
          />
          <Statistic
            title={t('sales')}
            value={`$${
              subsOver?.contents?.data?.data?.length > 0
                ? subsOver.contents.data.data[0].originalTotal.toFixed(2)
                : 0
            }`}
          />{' '}
          <Statistic
            title={`${t('loss')} $`}
            value={`$${
              subsOver?.contents?.data?.data?.length > 0
                ? subsOver.contents.data.data[0].diff.toFixed(2)
                : 0
            }`}
          />
          <Statistic
            title={`${t('loss')} %`}
            value={
              (subsOver?.contents?.data?.data?.length > 0 &&
                loss?.contents?.data?.data?.length > 0) ?
                calculateContribution(
                  loss?.contents?.data?.data?.length > 0?.originalTotal,
                  subsOver.contents.data.data[0].diff
                ) : 0
            }
          />
          <Statistic
            title={t('items')}
            value={`${
              subsOver?.contents?.data?.data?.length > 0
                ? subsOver.contents.data.data[0].itemCount
                : 0
            }`}
          />
        </Card>
      </div>
    </Spin>
  );
}

export default PastLossHeader;
