import React, { useState, useEffect } from 'react';
import { useMount } from 'react-use';
import moment from 'moment';
import dayjs from 'dayjs';
import _ from 'lodash';
import Chart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';
import {
  Select,
  DatePicker,
  Avatar,
  notification,
  Tooltip,
  Button
} from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import { Comment } from '@ant-design/compatible';
import bookingApi from '../../../../api/bookingApi';
import envConfig from '../../../../envConfig';
import {
  eventTypes,
  getEventTypeName,
  percent,
  getEventBookingStatus,
  formatPhoneNumber,
  thousand
} from '../../../utils/functions';
import Grocefy from '../../../../assets/images/grocefyLogoAlone.png';
import { dateRangePresets } from '../../../utils/utils';

function BookingEventsHeatMap() {
  const { RangePicker } = DatePicker;
  const { Option } = Select;
  const { t } = useTranslation();
  const [typeFilter, setTypeFilter] = useState(null);
  const [dates, setDates] = useState(null);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  function showMessage(message) {
    notification.open({
      message: '',
      description: (
        <Comment
          author={<span>Grocefy</span>}
          avatar={<Avatar src={Grocefy} alt="grocefy" />}
          content={
            <p className="text-sm">
              {message}
            </p>
          }
          datetime={
            <Tooltip title={moment().format('YYYY-MM-DD HH:mm:ss')}>
              <span>{moment().fromNow()}</span>
            </Tooltip>
          }
        />
      ),
    });
  }

  function getData() {
    setLoading(true);
    const payload = {
      EventType: typeFilter,
      StartDate: moment(dates?.start).add(-1 * envConfig.REACT_APP_TIMEZONE_OFFSET, 'hours').format('YYYY-MM-DDTHH:mm:00.000'),
      EndDate: moment(dates?.end).add(-1 * envConfig.REACT_APP_TIMEZONE_OFFSET, 'hours').add(1, 'days').format('YYYY-MM-DDTHH:mm:00.000'),
    };
    bookingApi.post('admin/hours/heatmap/bookings', payload)
      .then((response) => {
        setLoading(false);
        if (!response.data.success) {
          showMessage(response.data.error);
        } else {
          setData(response.data?.data);
          // console.log(response.data?.data);
        }
      })
      .catch((error) => {
        setLoading(false);
        showMessage(error.message);
      });
  }

  useEffect(() => {
    if (dates) {
      getData();
    }
  }, dates, typeFilter);

  useMount(() => {
    setDates({
      start: moment().startOf('week'),
      end: moment().endOf('week'),
    });
  });

  return (
    <>
      <PageHeader
        className="mb-4 px-0"
        title={`${t('heatmaps')} - ${t('bookings')}`}
        extra={[
          <div key="types">
            <strong>{t('event_type')}:</strong>
            <br />
            <Select
              disabled={loading}
              style={{ width: '140px' }}
              value={typeFilter}
              onChange={(value) => setTypeFilter(value)}
            >
              <Option
                key="any"
                value={null}
                className="text-xs flex items-center"
              >
                {t('any')}
              </Option>
              {_.map(eventTypes, (eventType) => (
                <Option
                  key={eventType.type}
                  value={eventType.type}
                  className="text-xs flex items-center"
                >
                  {t(eventType.name)}
                </Option>
              ))}
            </Select>
          </div>,
          <RangePicker
            key="dates"
            disabled={loading}
            format="MMM, DD YYYY"
            className="w-full mt-5"
            presets={dateRangePresets(t)}
            value={[
              dayjs(dates?.start),
              dayjs(dates?.end)
            ]}
            onChange={(date, dateString) => {
              setDates({
                start: dateString[0],
                end: dateString[1]
              });
            }}
          />,
          <Button
            key="reload"
            className="mt-5"
            type="primary"
            loading={loading}
            disabled={loading}
            size="small"
            onClick={() => getData()}
          >
            {t('reload')}
          </Button>
        ]}
      />
      <Chart
        options={{
          chart: {
            height: 350,
            type: 'heatmap',
          },
          dataLabels: {
            enabled: false
          },
          colors: ['#008FFB'],
        }}
        series={_.map(data?.days, (p) => ({
          name: p.dateString,
          data: _.map(p.hours, (h) => ({
            x: h.name,
            y: thousand(h.count)
          }))
        }))}
        type="heatmap"
      />
    </>
  );
}
export default BookingEventsHeatMap;
