import React, { useState, useEffect } from 'react';
import { useRecoilValue, useRecoilValueLoadable } from 'recoil';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import {
  Avatar,
  notification,
  Tooltip,
  Table,
  Typography,
  Descriptions,
  Dropdown,
  Menu,
  Button,
  Space,
  Popover,
  Tabs,
  Select,
  Switch,
  Row,
  Tag,
  Col,
  Image,
} from 'antd';
import { Comment } from '@ant-design/compatible';
import { DndContext } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import {
  DownSquareOutlined,
  SyncOutlined,
  FlagOutlined,
  MenuOutlined,
  CheckCircleOutlined,
  ShoppingOutlined,
  ClockCircleOutlined
} from '@ant-design/icons';
import {
  businessAtom,
  timezoneAtom,
  profileAtom
} from '../../../../atoms/Atoms';
import {
  renderDate,
  formatPhoneNumber,
  mapPaymentType,
  mapOrderType,
  mapOrderStatus,
  thousand,
  convertTaxRule,
} from '../../../utils/functions';
import api from '../../../../api/api';
import Grocefy from '../../../../assets/images/grocefyLogoAlone.png';
import { DraggableBodyRow, DragHandle } from '../../../utils/utils';

function DeliveriesSort() {
  const { Option } = Select;
  const history = useHistory();
  const { t } = useTranslation();
  const profile = useRecoilValueLoadable(profileAtom);
  const store = useRecoilValue(businessAtom);
  const timezone = useRecoilValue(timezoneAtom);
  const [selectedZone, setSelectedZone] = useState(null);
  const [deliveryZones, setDeliveryZones] = useState([]);
  const [loadingDeliveryZones, setLoadingDeliveryZones] = useState(false);
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [orderItems, setOrderItems] = useState([]);
  const [loadingItems, setLoadingItems] = useState(false);
  const [providers, setProviders] = useState([]);
  const [selectedProvider, setSelectedProvider] = useState(null);
  const [loadingProviders, setLoadingProviders] = useState(false);

  if (!store) {
    history.push('/');
    return null;
  }

  if (store && !store.allowDelivery) {
    history.push('/');
    return null;
  }

  function getProviders() {
    setLoadingProviders(true);
    api.get(`tpd/providers/${profile?.contents?.id}`)
      .then((response) => {
        setLoadingProviders(false);
        if (response.data.success) {
          setProviders(response.data.data.results);
          setSelectedProvider(response.data.data.results[0]);
        } else {
          console.error(response.data.error);
        }
      })
      .catch((error) => {
        setLoadingProviders(false);
        console.error(error);
      });
  }

  function showMessage(message) {
    notification.open({
      message: '',
      description: (
        <Comment
          author={<span>Grocefy</span>}
          avatar={<Avatar src={Grocefy} alt="grocefy" />}
          content={
            <p className="text-sm">
              {message}
            </p>
          }
          datetime={
            <Tooltip title={moment().format('YYYY-MM-DD HH:mm:ss')}>
              <span>{moment().fromNow()}</span>
            </Tooltip>
          }
        />
      ),
    });
  }

  function mapData(data) {
    return _.map(data, (p, index) => ({
      key: index,
      id: p.id,
      type: t(mapOrderType(p.type)),
      typeEnum: p.type,
      confirmationCode: p.confirmationCode,
      customer: p.customerName,
      customerId: p.customerId,
      businessName: p.businessName,
      total: p.orderTotal,
      bags: p.bags,
      subTotal: thousand(p.productsTotal),
      munTax: thousand(p.municipalTax),
      stateTax: thousand(p.stateTax),
      deliveryFee: thousand(p.deliveryTotal),
      pickupFee: thousand(p.processingFee),
      phone: formatPhoneNumber(p.customerPhone),
      ivuCN: p.ivuControlNumber,
      itemCount: p.itemCount,
      statusText: mapOrderStatus(p.status),
      cardNickname: p.cardNickname,
      nickName: p.nickName,
      status: p.status,
      startSlotTime: p.startSlotTime,
      endSlotTime: p.endSlotTime,
      specialInstruction: p.specialInstruction.replace(/,/g, ''),
      paymentTypeText: t(mapPaymentType(p.paymentType)),
      paymentType: p.paymentType,
      dispatchDateTime: renderDate(p.dispatchDateTime, timezone),
      createdAt: renderDate(p.createdAt, timezone),
      desiredDateTime: renderDate(p.desiredDateTime, timezone),
      desiredDate: moment(`${p.desiredDateTime}+0000`)
        .tz(timezone)
        .format('MMM Do, YYYY'),
      deliveryDate: moment(`${p.desiredDateTime}+0000`)
        .tz(timezone)
        .format('MMM Do'),
      desiredTime: moment(`${p.desiredDateTime}+0000`)
        .tz(timezone)
        .format('h:mm a'),
      transferTotal: p.transferTotal,
      processingFee: p.processingFee,
      transferCompleted: p.transferCompleted ? 'Yes' : 'No',
      authTotal: p.authTotal.toFixed(2),
      returnTotal: p.returnTotal.toFixed(2),
      credit:
        p.authTotal - p.orderTotal
          ? (p.authTotal - p.orderTotal).toFixed(2)
          : '0.00',
      referenceNumber:
        p.referenceNumber || p.referenceNumber !== ''
          ? p.referenceNumber
          : 'n/a',
      refNumAvailable: p.referenceNumber !== '' && p.referenceNumber !== null,
      discountAmount: p.discountAmount,
      businessDescription: p.businessDescription,
      returnNeeded: p.returnNeeded ? 'Yes' : 'No',
      returnCompleted: p.returnCompleted ? 'Yes' : 'No',
      athReturnError: p.athReturnError ? 'Yes' : 'No',
      wasAlreadyCharged: p.hasBeenCharged ? 'Yes' : 'No',
      wasAlreadyAuthed: p.hasBeenAuthed ? 'Yes' : 'No',
      brand: p.brand,
      cardNumber: p.cardNumber,
      address: p.customerLocation.addressLine,
      city: p.customerLocation.city,
      cardholderName: p.cardholderName,
      customerLocationName: p.customerLocationName,
      customerLocationId: p.customerLocationId,
      deliveryZoneId: p.deliveryZoneId,
      deliveryZoneName: p.deliveryZoneName,
      driverName: p.driverName ? p.driverName : '',
      driverPic: p.driverPic,
      driverPhone: p.driverPhone,
      deliveryTotal: p.deliveryTotal,
      items: p.items,
      driverInstructions: p.driverInstructions,
      customerLocationLat: p.customerLocation?.geo.lat,
      customerLocationLon: p.customerLocation?.geo.lon,
      customerLocationAddress: p.customerLocation?.addressLine.replace(
        /,/g,
        ''
      ),
      customerLocationCity: p.customerLocation?.city,
      customerLocationZipcode: p.customerLocation?.zipcode,
      customerLocationInstructions: p.customerLocation?.instructions.replace(
        /,/g,
        ''
      ),
    }));
  }

  function mapOrderItems(data) {
    const sorted = _.map(data, (p) => ({
      key: p.id,
      id: p.id,
      completeName: p.completeName,
      name: p.name,
      brand: p.brand,
      amount: p.amount,
      upc: p.upc,
      aisle: p.aisle,
      price: p.price,
      description: p.description,
      extendedPrice: p.extendedPrice,
      itemImage: p.itemImage,
      hasRandomWeight: p.hasRandomWeight,
      sectionName: p.sectionName,
      isAlcoholicBeverage: p.isAlcoholicBeverage,
      isPacked: p.isPacked,
      isPerishable: p.isPerishable,
      isVoided: p.isVoided,
      manuallyAdded: p.manuallyAdded,
      acceptsSubstitutes: p.acceptsSubstitutes,
      taxRule: t(convertTaxRule(p.taxRule)),
    }));
    return _.sortBy(sorted, [(o) => o.isVoided]);
  }

  function getOrders() {
    if (selectedZone) {
      setLoading(true);
      api.get(`deliveries/orders/sorting/${selectedZone.id}`)
        .then((response) => {
          setLoading(false);
          if (response.data.error) {
            showMessage(response.data.error);
          } else if (response.data.data) {
            setOrders(mapData(response.data.data.results));
          } else {
            setOrders([]);
          }
        })
        .catch((err) => {
          setLoading(false);
          showMessage(err.message);
        });
    }
  }

  function update() {
    if (selectedZone && orders.length > 0) {
      const payload = _.map(orders, (p, index) => ({
        orderId: p.id,
        routeOrder: index
      }));
      setUpdating(true);
      api.post('deliveries/orders/sorting/update', payload)
        .then((response) => {
          setUpdating(false);
          if (response.data.error) {
            showMessage(response.data.error);
          } else {
            showMessage(t('updated'));
          }
        })
        .catch((err) => {
          setUpdating(false);
          showMessage(err.message);
        });
    }
  }

  function getDelvieryZones() {
    setLoadingDeliveryZones(true);
    api
      .get(`deliveries/deliveryzones/active/${store?.locations[0]?.id}/${selectedProvider?.id}`)
      .then((response) => {
        setDeliveryZones(response.data.data.results);
        setLoadingDeliveryZones(false);
      })
      .catch((error) => {
        console.error(error);
        setLoadingDeliveryZones(false);
      });
  }

  useEffect(() => {
    getProviders();
  }, []);

  useEffect(() => {
    if (store && selectedProvider) {
      getDelvieryZones();
    }
  }, [store, selectedProvider]);

  useEffect(() => {
    if (store) {
      getDelvieryZones();
    }
  }, [store]);

  useEffect(() => {
    if (deliveryZones.length > 0) {
      setSelectedZone(deliveryZones[0]);
    }
  }, [deliveryZones]);

  useEffect(() => {
    if (selectedZone) {
      getOrders();
    }
  }, [selectedZone]);

  const onDragEnd = ({ active, over }) => {
    if (active.id !== over?.id) {
      setOrders((prevState) => {
        const activeIndex = prevState.findIndex((record) => record.id === active?.id);
        const overIndex = prevState.findIndex((record) => record.id === over?.id);
        return arrayMove(prevState, activeIndex, overIndex);
      });
    }
  };

  return (
    <div>
      <div>
        <Select
          hidden={providers.length < 2}
          value={selectedProvider?.id}
          style={{ width: 120 }}
          className="mr-2"
          loading={loadingProviders}
          onChange={(obj) => {
            const prov = _.filter(providers, (b) => b.id === obj);
            setSelectedProvider(prov[0]);
          }}
        >
          {_.map(providers, (b) => (
            <Option
              value={b.id}
              key={b.id}
            >
              {b.name}
            </Option>
          ))}
        </Select>
        <Dropdown
          trigger="click"
          className="mr-5"
          disabled={updating || loading}
          overlay={
            <Menu>
              {_.map(deliveryZones, (zone) => (
                <Menu.Item
                  onClick={() => {
                    setSelectedZone(zone);
                  }}
                >
                  {zone.name}
                </Menu.Item>
              ))}
            </Menu>
          }
          arrow="true"
        >
          <Button loading={loadingDeliveryZones}>
            {t('zone')}: {selectedZone?.name}
            <DownSquareOutlined />
          </Button>
        </Dropdown>
        <Button
          size="small"
          type="primary"
          loading={loading}
          disabled={updating}
          className="float-right"
          onClick={() => getOrders()}
        >
          {t('reload')}
        </Button>
        <Button
          size="small"
          type="primary"
          disabled={loading}
          loading={updating}
          className="float-right mr-2"
          onClick={() => update()}
        >
          {t('update')}
        </Button>
      </div>
      <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
        <SortableContext items={orders.map((i) => i.id)} strategy={verticalListSortingStrategy}>
          <Table
            size="small"
            className="mt-2"
            loading={loading || updating}
            bordered
            dataSource={orders}
            rowKey="id"
            pagination="none"
            columns={[
              {
                dataIndex: 'sort',
                width: 30,
                className: 'drag-visible',
                render: () => <DragHandle />,
              },
              {
                title: t('zone'),
                dataIndex: 'deliveryZoneName',
                key: 'zone',
                align: 'left',
                className: 'text-xs',
                render: (text) => <Typography.Text>{text}</Typography.Text>,
              },
              {
                title: t('city'),
                dataIndex: 'city',
                key: 'city',
                align: 'left',
                className: 'text-xs',
                render: (text) => <Typography.Text>{text}</Typography.Text>,
              },
              {
                key: 'details',
                align: 'left',
                className: 'text-xs',
                render: (text) =>
                  <div>
                    <span>{text.customer}</span>
                    <br />
                    <span>{text.confirmationCode}</span>
                  </div>,
              },
              {
                title: t('driver'),
                dataIndex: 'driverName',
                key: 'driver',
                align: 'left',
                className: 'text-xs',
                render: (text) => {
                  if (!text) {
                    return (
                      <Tag
                        color="processing"
                        size="small"
                        className="mr-0"
                      >
                        {t('pending')}
                      </Tag>
                    );
                  }
                  return (
                    <>
                      <Tag
                        color="success"
                        size="small"
                        className="mr-0"
                      >
                        {text}
                      </Tag>
                    </>
                  );
                }
              },
              {
                title: t('total'),
                dataIndex: 'total',
                key: 'total',
                align: 'right',
                className: 'text-xs',
                render: (text) => <span>${text ? text.toFixed(2) : '0.00'}</span>,
                sorter: (a, b) => a.total - b.total,
              },
              {
                title: t('status'),
                key: 'status',
                align: 'left',
                className: 'text-xs',
                render: (text) => {
                  if (text.status === 0) {
                    return (
                      <Tag
                        icon={<SyncOutlined spin />}
                        color="processing"
                        size="small"
                        className="mr-0"
                      >
                        {t('processing')}
                      </Tag>
                    );
                  }
                  if (text.status === 2) {
                    return (
                      <Tag
                        icon={<ClockCircleOutlined />}
                        color="success"
                        size="small"
                        className="mr-0"
                      >
                        {t(mapOrderStatus(text.status))}
                      </Tag>
                    );
                  }
                  if (text.status === 5) {
                    return (
                      <Tag
                        icon={<SyncOutlined spin />}
                        color="success"
                        size="small"
                        className="mr-0"
                      >
                        {t(mapOrderStatus(text.status))}
                      </Tag>
                    );
                  }
                  if (text.status === 7) {
                    return (
                      <Tag
                        icon={<CheckCircleOutlined />}
                        color="success"
                        size="small"
                        className="mr-0"
                      >
                        {t(mapOrderStatus(text.status))}
                      </Tag>
                    );
                  }
                  if (text.status === 8 || text.status === 9) {
                    return (
                      <Tag
                        icon={<FlagOutlined />}
                        color="error"
                        size="small"
                        className="mr-0"
                      >
                        {t(mapOrderStatus(text.status))}
                      </Tag>
                    );
                  }
                  return (
                    <>
                      <Tag
                        icon={<FlagOutlined />}
                        color="processing"
                        size="small"
                        className="mr-0"
                      >
                        {t(mapOrderStatus(text.status))}
                      </Tag>
                    </>
                  );
                },
              },
              {
                title: t('date'),
                key: 'desiredDate',
                align: 'left',
                className: 'text-xs',
                width: 80,
                render: (text) => <span>{text.deliveryDate}</span>,
              },
              {
                title: t('expected'),
                key: 'desiredDate',
                align: 'left',
                className: 'text-xs',
                width: 100,
                render: (text) =>
                  <span>{`${moment(`${text.startSlotTime}+0000`)
                    .tz(timezone)
                    .format('h a')}-${moment(`${text.endSlotTime}+0000`)
                    .tz(timezone)
                    .format('h a')}`}
                  </span>,
              },
              {
                title: `${t('bags')} | ${t('items')}`,
                align: 'center',
                className: 'text-xs',
                render: (text, row) => (
                  <Space>
                    <Popover
                      title={t('items')}
                      placement="left"
                      trigger="click"
                      width="100%"
                      content={
                        <div>
                          <Typography.Text>
                            {t('item_count')}: {orderItems.length}
                          </Typography.Text>
                          <br />
                          <br />
                          <Table
                            size="small"
                            bordered
                            loading={loadingItems}
                            columns={[
                              {
                                title: t('brand'),
                                dataIndex: 'brand',
                                key: 'brand',
                                align: 'center',
                                className: 'text-xs',
                                render: (info) => (
                                  <Typography.Text>{info}</Typography.Text>
                                ),
                              },
                              {
                                title: t('name'),
                                dataIndex: 'name',
                                key: 'name',
                                align: 'center',
                                className: 'text-xs',
                                render: (info) => (
                                  <Typography.Text>{info}</Typography.Text>
                                ),
                              },
                              {
                                title: t('description'),
                                dataIndex: 'description',
                                key: 'description',
                                align: 'center',
                                className: 'text-xs',
                                render: (info) => (
                                  <Typography.Text>{info}</Typography.Text>
                                ),
                              },
                              {
                                title: 'UPC',
                                dataIndex: 'upc',
                                key: 'upc',
                                align: 'center',
                                className: 'text-xs',
                                render: (info) => (
                                  <Typography.Text copyable>{info}</Typography.Text>
                                ),
                              },
                              {
                                title: t('amount'),
                                dataIndex: 'amount',
                                key: 'amount',
                                align: 'center',
                                className: 'text-xs',
                                render: (info) => (
                                  <Typography.Text>
                                    {info.toFixed(2)}
                                  </Typography.Text>
                                ),
                              },
                              {
                                title: t('price'),
                                dataIndex: 'price',
                                key: 'price',
                                align: 'center',
                                className: 'text-xs',
                                render: (info) => (
                                  <Typography.Text>
                                    ${info.toFixed(2)}
                                  </Typography.Text>
                                ),
                              },
                              {
                                title: t('included'),
                                dataIndex: 'isVoided',
                                key: 'isVoided',
                                align: 'center',
                                className: 'text-xs',
                                render: (info) => (
                                  <Switch size="small" checked={!info} disabled />
                                ),
                              },
                              {
                                title: 'Tax Rule',
                                dataIndex: 'taxRule',
                                key: 'traxRule',
                                align: 'center',
                                className: 'text-xs',
                                render: (info) => (
                                  <Typography.Text>{info}</Typography.Text>
                                ),
                              },
                            ]}
                            dataSource={
                              orderItems && orderItems.length > 0
                                ? orderItems
                                : null
                            }
                            expandable={{
                              expandRowByClick: false,
                              expandedRowRender: (record) => (
                                <div>
                                  <Tabs>
                                    <Tabs.TabPane key="1" tab={t('details')}>
                                      <Row className="flex">
                                        <Col span={12} className="flex">
                                          <Descriptions>
                                            <Descriptions.Item label={t('section_name')} />
                                            <br />
                                            <Descriptions.Item>
                                              <Col span={24}>
                                                <Typography.Text>
                                                  {record.sectionName}
                                                </Typography.Text>
                                              </Col>
                                            </Descriptions.Item>
                                            <Descriptions.Item label={t('aisle')} />
                                            <br />
                                            <Descriptions.Item>
                                              <Col span={24}>
                                                <Typography.Text>
                                                  {record.aisle}
                                                </Typography.Text>
                                              </Col>
                                            </Descriptions.Item>
                                          </Descriptions>
                                        </Col>
                                        <Col span={12} className="flex">
                                          <Descriptions bordered>
                                            <Descriptions.Item label={t('item_image')} />
                                            <br />
                                            <Descriptions.Item>
                                              <Col span={24}>
                                                <Image
                                                  alt="Item Image"
                                                  width={150}
                                                  src={record.itemImage}
                                                />
                                              </Col>
                                            </Descriptions.Item>
                                          </Descriptions>
                                        </Col>
                                      </Row>
                                    </Tabs.TabPane>
                                    <Tabs.TabPane key="2" tab="Flags">
                                      <Row className="flex">
                                        <Col span={12} className="flex">
                                          <Descriptions>
                                            <Descriptions.Item
                                              className="py-0"
                                              label={t('weighted')}
                                            >
                                              <Switch
                                                disabled
                                                size="small"
                                                checked={record.hasRandomWeight}
                                              />
                                            </Descriptions.Item>
                                          </Descriptions>
                                        </Col>
                                        <Col span={10} offset={2}>
                                          <Descriptions>
                                            <Descriptions.Item label={t('alcoholic_beverages')}>
                                              <Switch
                                                disabled
                                                size="small"
                                                checked={record.isAlcoholicBeverage}
                                              />
                                            </Descriptions.Item>
                                          </Descriptions>
                                        </Col>
                                      </Row>
                                    </Tabs.TabPane>
                                  </Tabs>
                                </div>
                              ),
                              rowExpandable: (record) => record,
                            }}
                          />
                        </div>
                      }
                    >
                      <Button
                        disabled={row.favStoreId === 'Unknown'}
                        size="small"
                        className="w-14"
                        onClick={() => {
                          setLoadingItems(true);
                          api
                            .get(`orders/${row.id}/items/all`)
                            .then((response) => {
                              const map = mapOrderItems(response.data.data.results);
                              setOrderItems(map);
                              setLoadingItems(false);
                            })
                            .catch((error) => {
                              console.error(error);
                            });
                        }}
                      >
                        <ShoppingOutlined className="" /> {text.bags} | {text.itemCount}
                      </Button>
                    </Popover>
                  </Space>
                ),
              },
            ]}
            components={{
              body: {
                row: DraggableBodyRow,
              },
            }}
          />
        </SortableContext>
      </DndContext>
    </div>
  );
}

export default DeliveriesSort;
