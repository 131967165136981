import React, { useEffect, useState } from 'react';
import {
  useRecoilState,
  useSetRecoilState
} from 'recoil';
import MDEditor from '@uiw/react-md-editor';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import {
  Input,
  Button,
  notification,
  Avatar,
  Tooltip,
  Drawer
} from 'antd';
import { Comment } from '@ant-design/compatible';
import {
  selectedKnowledgebase,
  refreshKnowledgebasesAtom
} from '../../../../atoms/Atoms';
import Grocefy from '../../../../assets/images/grocefyLogoAlone.png';
import api from '../../../../api/api';

function EditKnowledgebase() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [newName, setNewName] = useState('');
  const [newEngContent, setNewEngContent] = useState('');
  const [newSpaContent, setNewSpaContent] = useState('');
  const [knowledgebase, setKnowledgebase] = useRecoilState(selectedKnowledgebase);
  const setRefreshKnowledgebases
    = useSetRecoilState(refreshKnowledgebasesAtom);

  const showMessage = function (message) {
    notification.open({
      message: '',
      description: (
        <Comment
          author={<span>Grocefy</span>}
          avatar={<Avatar src={Grocefy} alt="grocefy" />}
          content={
            <p className="text-sm">
              {message}
            </p>
          }
          datetime={
            <Tooltip title={moment().format('YYYY-MM-DD HH:mm:ss')}>
              <span>{moment().fromNow()}</span>
            </Tooltip>
          }
        />
      ),
    });
  };

  const onChangeEnglishContent = function (obj) {
    if (!loading) {
      setNewEngContent(obj);
    }
  };

  const onChangeSpanishContent = function (obj) {
    if (!loading) {
      setNewSpaContent(obj);
    }
  };

  const submit = function () {
    setLoading(true);
    api
      .post(
        'utility/upload',
        {
          containerName: 'helpinfo',
          fileName: newName,
          text: JSON.stringify({ eng: newEngContent, spa: newSpaContent, name: newName })
        }
      )
      .then((response) => {
        setLoading(false);
        if (response.data.error) {
          showMessage(response.data.error);
        } else {
          setKnowledgebase(null);
          setRefreshKnowledgebases(true);
        }
      }).catch((err) => {
        setLoading(false);
        showMessage(err.message);
      });
  };

  const readFile = function () {
    setLoading(true);
    api
      .get(
        `utility/file/helpinfo/${knowledgebase.name}`
      )
      .then((response) => {
        setLoading(false);
        if (response.data.error) {
          showMessage(response.data.error);
        } else {
          const temp = JSON.parse(response.data.data);
          setNewName(temp.name);
          setNewEngContent(temp.eng);
          setNewSpaContent(temp.spa);
        }
      }).catch((err) => {
        setLoading(false);
        showMessage(err.message);
      });
  };

  useEffect(() => {
    if (knowledgebase) {
      readFile();
    }
  }, [knowledgebase]);

  return (
    <Drawer
      title={t('edit_knowledgebase')}
      placement="right"
      closable
      onClose={() => setKnowledgebase(null)}
      open={knowledgebase}
      width={450}
    >
      <div className="mb-2">
        <strong>{t('name')}:</strong>
        <Input
          disabled
          placeholder="Name"
          onChange={(obj) => {
            setNewName(obj.target.value);
          }}
          value={newName}
        />
      </div>
      <div className="mb-2">
        <strong>{t('english_content')}:</strong>
        <MDEditor
          preview="edit"
          value={newEngContent}
          onChange={(obj) => onChangeEnglishContent(obj)}
        />
      </div>
      <div className="mb-2">
        <strong>{t('spanish_content')}:</strong>
        <MDEditor
          preview="edit"
          value={newSpaContent}
          onChange={(obj) => onChangeSpanishContent(obj)}
        />
      </div>
      <div className="float-right">
        <Button
          loading={loading}
          type="primary"
          onClick={() => { submit(); }}
        >
          {t('update')}
        </Button>
      </div>
    </Drawer>
  );
}

export default EditKnowledgebase;
