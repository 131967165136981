import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { useRecoilState } from 'recoil';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import {
  Button,
  notification,
  Avatar,
  Tooltip,
  Modal,
  Card,
  Spin
} from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import { Comment } from '@ant-design/compatible';
import {
  showDepartmentsSectionsModalAtom
} from '../../../../atoms/Atoms';
import Grocefy from '../../../../assets/images/grocefyLogoAlone.png';
import api from '../../../../api/api';

function DepartmentCategoriesBreakdownModal() {
  const SEPARATOR = '$----$';
  const { t, i18n } = useTranslation();
  const [showDepartmentsSectionsModal, setShowDepartmentsSectionsModal] = useRecoilState(showDepartmentsSectionsModalAtom);
  const [loading, setLoading] = useState(false);
  const [departments, setDepartments] = useState([]);

  function showMessage(message) {
    notification.open({
      message: '',
      description: (
        <Comment
          author={<span>Grocefy</span>}
          avatar={<Avatar src={Grocefy} alt="grocefy" />}
          content={
            <p className="text-sm">
              {message}
            </p>
          }
          datetime={
            <Tooltip title={moment().format('YYYY-MM-DD HH:mm:ss')}>
              <span>{moment().fromNow()}</span>
            </Tooltip>
          }
        />
      ),
    });
  }

  function getDepartmentInfo(tempName) {
    if (tempName) {
      const nameElements = tempName.split(SEPARATOR);
      return {
        eng: nameElements[0],
        spa: nameElements[1],
        img: nameElements[2]
      };
    }
    return {
      eng: '',
      spa: ''
    };
  }

  function getDepartments() {
    if (!loading) {
      api.get('businesses/menusections')
        .then((response) => {
          setLoading(false);
          if (!response.data.success) {
            showMessage(response.data.error);
          } else {
            const grouped = _(response.data.data.results).groupBy((s) =>
              `${s.superSectionNameEng}${SEPARATOR}${s.superSectionName}${SEPARATOR}${s.superSectionImageUrl}`)
              .map((items, key) => ({
                superSectionName: getDepartmentInfo(key).spa,
                superSectionNameEng: getDepartmentInfo(key).eng,
                superSectionImageUrl: getDepartmentInfo(key).img,
                sections: items
              })).value();
            setDepartments(grouped);
            console.log(grouped);
          }
        })
        .catch((error) => {
          setLoading(false);
          showMessage(error.message);
        });
    }
  }

  useEffect(() => {
    if (showDepartmentsSectionsModal) {
      getDepartments();
    } else {
      setDepartments([]);
    }
  }, [showDepartmentsSectionsModal]);

  return (
    <Modal
      closable="true"
      centered="true"
      height="90%"
      width="100%"
      onCancel={() => setShowDepartmentsSectionsModal(false)}
      open={showDepartmentsSectionsModal}
      footer={[
        <Button
          key="back"
          disabled={loading}
          onClick={() => setShowDepartmentsSectionsModal(false)}
        >
          {t('close')}
        </Button>
      ]}
    >
      <PageHeader
        title={t('departments')}
      />
      <Spin spinning={loading}>
        <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 gap-4">
          {_.map(departments, (d) => (
            <Card
              title={i18n.language === 'en' ? d.superSectionNameEng : d.superSectionName}
              className="rounded-xl h-fit bg-gradient-to-br from-blue-200 to-white"
              hoverable
            >
              <div className="space-y-2">
                {(_.map(d.sections, (s) => (
                  <div className="flex space-x-2">
                    {s.imageUrl && (<img className="h-8 w-8" alt={i18n.language === 'en' ? s.nameEng : s.name} src={s.imageUrl} />)}
                    {!s.imageUrl && (<div className="h-8 w-8" />)}
                    <span>{i18n.language === 'en' ? s.nameEng : s.name}</span>
                  </div>
                )))}
              </div>
            </Card>
          ))}
        </div>
      </Spin>
    </Modal>
  );
}

export default DepartmentCategoriesBreakdownModal;
