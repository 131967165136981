import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Button, Table, Modal, Input } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import { useRecoilValue } from 'recoil';
import {
  businessAtom,
  timezoneAtom
} from '../../../atoms/Atoms';
import api from '../../../api/api';
import { renderDate } from '../../utils/functions';

function Pos() {
  const { t } = useTranslation();
  const history = useHistory();
  const timezone = useRecoilValue(timezoneAtom);
  const business = useRecoilValue(businessAtom);
  const [loading, setLoading] = useState(false);
  const [posLanes, setPosLanes] = useState([]);
  const [selectedPos, setSelectedPos] = useState(null);
  const [createNew, setCreateNew] = useState(false);
  const [updateExisting, setUpdateExisting] = useState(false);

  if (!business) {
    history.push('/');
    return null;
  }

  function getPosLanes() {
    setLoading(true);
    api
      .get(`pos/manage/${business?.id}`)
      .then((response) => {
        setLoading(false);
        setPosLanes(response.data.data.results);
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  }

  function create() {
    setLoading(true);
    api
      .post('pos/manage', {
        ...selectedPos,
        businessId: business?.id
      })
      .then((response) => {
        setLoading(false);
        setCreateNew(false);
        getPosLanes();
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  }

  function update() {
    setLoading(true);
    api
      .put('pos/manage', selectedPos)
      .then((response) => {
        setLoading(false);
        setUpdateExisting(false);
        getPosLanes();
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  }

  function remove() {
    setLoading(true);
    api
      .delete(`pos/manage/${selectedPos?.id}`)
      .then((response) => {
        setLoading(false);
        setUpdateExisting(false);
        getPosLanes();
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  }

  useEffect(() => {
    getPosLanes();
  }, [business]);

  useEffect(() => {
    getPosLanes();
  }, []);

  useEffect(() => {
    if (createNew) {
      setSelectedPos({
        tag: ''
      });
    }
  }, [createNew]);

  return (
    <>
      <PageHeader
        className="mb-4 px-0"
        title={t('pos_lanes')}
        extra={[
          <Button
            id="reload"
            type="primary"
            key="reload"
            size="small"
            onClick={() => getPosLanes()}
          >
            {t('reload')}
          </Button>,
          <Button
            type="primary"
            key="create"
            size="small"
            onClick={() => {
              setCreateNew(true);
            }}
          >
            {t('create_new')}
          </Button>
        ]}
      />
      <Table
        pagination={false}
        size="small"
        bordered
        rowKey="id"
        loading={loading}
        columns={[
          {
            title: t('tag'),
            key: 'tag',
            align: 'center',
            dataIndex: 'tag',
            className: 'text-xs',
            render: (row) => (
              <span>{row}</span>
            ),
          },
          {
            title: t('pos_version'),
            key: 'lastReportedVersion',
            align: 'center',
            dataIndex: 'lastReportedVersion',
            className: 'text-xs',
            render: (row) => (
              <span>{row ?? '-'}</span>
            ),
          },
          {
            title: t('device_manufacturer'),
            key: 'lastReportedDeviceManufacturer',
            align: 'center',
            dataIndex: 'lastReportedDeviceManufacturer',
            className: 'text-xs',
            render: (row) => (
              <span>{row ?? '-'}</span>
            ),
          },
          {
            title: t('device_model'),
            key: 'lastReportedDeviceModel',
            align: 'center',
            dataIndex: 'lastReportedDeviceModel',
            className: 'text-xs',
            render: (row) => (
              <span>{row ?? '-'}</span>
            ),
          },
          {
            title: t('device_name'),
            key: 'lastReportedDeviceName',
            align: 'center',
            dataIndex: 'lastReportedDeviceName',
            className: 'text-xs',
            render: (row) => (
              <span>{row ?? '-'}</span>
            ),
          },
          {
            title: t('last_transaction_date'),
            key: 'lastTransactionOn',
            align: 'center',
            dataIndex: 'lastTransactionOn',
            className: 'text-xs',
            render: (row) => (
              <span>{row ? renderDate(row, timezone) : '-'}</span>
            ),
          },
          {
            title: t('edit'),
            key: 'edit',
            align: 'center',
            className: 'text-xs',
            render: (text, record) => (
              <Button
                size="small"
                onClick={() => {
                  setSelectedPos(record);
                  setUpdateExisting(true);
                }}
              >
                {t('edit')}
              </Button>
            ),
          },
        ]}
        dataSource={posLanes}
      />
      <Modal
        closable="true"
        centered="true"
        open={createNew}
        onCancel={() => setCreateNew(false)}
        footer={[
          <Button
            key="createButton"
            disabled={loading}
            type="primary"
            onClick={() => create()}
          >
            {t('create')}
          </Button>,
          <Button
            key="back"
            disabled={loading}
            onClick={() => setCreateNew(false)}
          >
            {t('close')}
          </Button>
        ]}
      >
        <PageHeader
          title={t('create_pos_lane')}
        />
        <div
          className="mb-4"
        >
          <strong>{t('tag')}</strong>
          <Input
            disabled={loading}
            className="w-full"
            value={selectedPos?.tag}
            onChange={(obj) => {
              setSelectedPos({
                ...selectedPos,
                tag: obj.target.value
              });
            }}
          />
        </div>
      </Modal>
      <Modal
        closable="true"
        centered="true"
        open={updateExisting}
        onCancel={() => setUpdateExisting(false)}
        footer={[
          <Button
            key="updateButton"
            type="primary"
            disabled={loading}
            onClick={() => update()}
          >
            {t('update')}
          </Button>,
          <Button
            key="removeButton"
            disabled={loading}
            type="primary"
            danger
            onClick={() => remove()}
          >
            {t('remove')}
          </Button>,
          <Button
            key="back"
            disabled={loading}
            onClick={() => setUpdateExisting(false)}
          >
            {t('close')}
          </Button>
        ]}
      >
        <PageHeader
          title={t('update_pos_lane')}
        />
        <div
          className="mb-4"
        >
          <strong>{t('tag')}</strong>
          <Input
            disabled={loading}
            className="w-full"
            value={selectedPos?.tag}
            onChange={(obj) => {
              setSelectedPos({
                ...selectedPos,
                tag: obj.target.value
              });
            }}
          />
        </div>
      </Modal>
    </>
  );
}
export default Pos;
