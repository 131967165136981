import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import ReactPlayer from 'react-player';
import { useTranslation } from 'react-i18next';
import {
  Table,
  Tabs,
  Image,
  Drawer,
  Button,
  Tooltip,
  notification,
  Avatar
} from 'antd';
import { Comment } from '@ant-design/compatible';
import moment from 'moment';
import { DndContext } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import Grocefy from '../../../../assets/images/grocefyLogoAlone.png';
import {
  showItemRegistrationMediaAtom,
  showAddRegistrationItemMediaAtom,
  refreshRegistrationItemMediaAtom
} from '../../../../atoms/Atoms';
import api from '../../../../api/api';
import { DraggableBodyRow, DragHandle } from '../../../utils/utils';

function ManageRegistrationItemMediaDrawer() {
  const { TabPane } = Tabs;
  const [selectedItem, setSelectedItem] = useRecoilState(showItemRegistrationMediaAtom);
  const setShowAddItemMedia = useSetRecoilState(showAddRegistrationItemMediaAtom);
  const [refreshItemMedia, setRefreshItemMedia] = useRecoilState(refreshRegistrationItemMediaAtom);
  const [loading, setLoading] = useState(false);
  const [itemMedia, setItemMedia] = useState([]);
  const [pictures, setPictures] = useState([]);
  const [videos, setVideos] = useState([]);
  const { t } = useTranslation();

  const showMessage = function (message) {
    notification.open({
      message: '',
      description: (
        <Comment
          author={<span>Grocefy</span>}
          avatar={<Avatar src={Grocefy} alt="grocefy" />}
          content={
            <p className="text-sm">
              {message}
            </p>
          }
          datetime={
            <Tooltip title={moment().format('YYYY-MM-DD HH:mm:ss')}>
              <span>{moment().fromNow()}</span>
            </Tooltip>
          }
        />
      ),
    });
  };

  const getItemMedia = function () {
    setLoading(true);
    api
      .get(`itemregistration/catalog/media/${selectedItem.id}`)
      .then((response) => {
        setLoading(false);
        if (response.data.success) {
          setItemMedia(response.data.data.results);
        } else {
          showMessage(response.data.error);
        }
      })
      .catch((error) => {
        setLoading(false);
        showMessage(error);
      });
  };

  const updatePictures = function () {
    setLoading(true);
    const tempList = JSON.parse(JSON.stringify(pictures));
    for (let i = 0; i < tempList.length; i++) {
      api
        .post(
          `itemregistration/catalog/media/${tempList[i].id}`,
          {
            Ordering: i,
            Type: tempList[i].type,
            Url: tempList[i].url,
            ThumbnailUrl: tempList[i].thumbnailUrl,
          }
        )
        .then((response) => {
          if (response.data.success) {
            if (i === tempList.length - 1) {
              setLoading(false);
            }
          } else {
            showMessage(response.data.error);
          }
        })
        .catch((error) => {
          setLoading(false);
          showMessage(error);
        });
    }
  };

  const updateVideos = function () {
    setLoading(true);
    const tempList = JSON.parse(JSON.stringify(videos));
    for (let i = 0; i < tempList.length; i++) {
      api
        .post(
          `itemregistration/catalog/media/${tempList[i].id}`,
          {
            Ordering: i,
            Type: tempList[i].type,
            Url: tempList[i].url,
            ThumbnailUrl: tempList[i].thumbnailUrl,
          }
        )
        .then((response) => {
          if (response.data.success) {
            if (i === tempList.length - 1) {
              setLoading(false);
            }
          } else {
            showMessage(response.data.error);
          }
        })
        .catch((error) => {
          setLoading(false);
          showMessage(error);
        });
    }
  };

  const removePictureClick = function (picture) {
    setLoading(true);
    const idToRemove = picture.id;
    api
      .post(
        `itemregistration/catalog/removemedia/${picture.id}`,
        { }
      )
      .then((response) => {
        setLoading(false);
        if (response.data.success) {
          const temp = _.filter(pictures, (r) => r.id !== idToRemove);
          setPictures(temp);
        } else {
          showMessage(response.data.error);
        }
      })
      .catch((error) => {
        setLoading(false);
        showMessage(error);
      });
  };

  const removeVideoClick = function (video) {
    setLoading(true);
    const idToRemove = video.id;
    api
      .post(
        `itemregistration/catalog/removemedia/${video.id}`,
        { }
      )
      .then((response) => {
        setLoading(false);
        if (response.data.success) {
          const temp = _.filter(videos, (r) => r.id !== idToRemove);
          setVideos(temp);
        } else {
          showMessage(response.data.error);
        }
      })
      .catch((error) => {
        setLoading(false);
        showMessage(error);
      });
  };

  const onDragEndPictures = ({ active, over }) => {
    if (active.id !== over?.id) {
      setPictures((prevState) => {
        const activeIndex = prevState.findIndex((record) => record.id === active?.id);
        const overIndex = prevState.findIndex((record) => record.id === over?.id);
        return arrayMove(prevState, activeIndex, overIndex);
      });
    }
  };

  const onDragEndVideos = ({ active, over }) => {
    if (active.id !== over?.id) {
      setVideos((prevState) => {
        const activeIndex = prevState.findIndex((record) => record.id === active?.id);
        const overIndex = prevState.findIndex((record) => record.id === over?.id);
        return arrayMove(prevState, activeIndex, overIndex);
      });
    }
  };

  useEffect(() => {
    if (selectedItem) {
      getItemMedia();
    }
  }, [selectedItem]);

  useEffect(() => {
    if (refreshItemMedia) {
      getItemMedia();
      setRefreshItemMedia(false);
    }
  }, [refreshItemMedia]);

  useEffect(() => {
    if (itemMedia) {
      if (itemMedia.length === 0) {
        setPictures([]);
        setVideos([]);
      } else {
        setPictures(_.filter(itemMedia, (m) => m.type === 0));
        setVideos(_.filter(itemMedia, (m) => m.type === 1));
      }
    }
  }, [itemMedia]);

  return (
    <Drawer
      title={t('item_media')}
      placement="right"
      closable
      onClose={() => setSelectedItem(null)}
      open={selectedItem}
      width={400}
    >
      <Tabs defaultActiveKey="1">
        <TabPane tab="Pictures" key="1">
          <Button
            onClick={() => {
              setShowAddItemMedia({
                type: 0
              });
            }}
            style={{ marginBottom: 10 }}
          >
            {t('add_new_picture')}
          </Button>
          <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEndPictures}>
            <SortableContext items={pictures.map((i) => i.id)} strategy={verticalListSortingStrategy}>
              <Table
                size="small"
                bordered
                loading={loading}
                pagination={false}
                dataSource={pictures}
                columns={[
                  {
                    title: t('sort'),
                    dataIndex: 'sort',
                    width: 30,
                    className: 'drag-visible',
                    render: () => <DragHandle />,
                  },
                  {
                    title: t('image'),
                    align: 'center',
                    className: 'drag-visible',
                    render: (row) => (
                      <Image
                        height={100}
                        width={100}
                        src={row.url}
                        alt={row.url}
                      />
                    ),
                  },
                  {
                    title: t('actions'),
                    align: 'center',
                    className: 'drag-visible',
                    render: (row) => (
                      <Button
                        size="small"
                        type="primary"
                        danger
                        onClick={() => {
                          removePictureClick(row);
                        }}
                      >
                        {t('delete')}
                      </Button>
                    ),
                  }
                ]}
                rowKey="ordering"
                components={{
                  body: {
                    row: DraggableBodyRow,
                  },
                }}
              />
            </SortableContext>
          </DndContext>
          <Button
            onClick={() => { updatePictures(); }}
            type="primary"
            style={{
              float: 'right',
              marginTop: 10
            }}
          >
            {t('update')}
          </Button>
        </TabPane>
        <TabPane tab="Videos" key="2">
          <Button
            onClick={() => {
              setShowAddItemMedia({
                type: 1
              });
            }}
            style={{
              marginBottom: 10
            }}
          >
            {t('add_new_video')}
          </Button>
          <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEndVideos}>
            <SortableContext items={videos.map((i) => i.id)} strategy={verticalListSortingStrategy}>
              <Table
                size="small"
                bordered
                loading={loading}
                pagination={false}
                dataSource={videos}
                columns={[
                  {
                    title: t('sort'),
                    dataIndex: 'sort',
                    width: 30,
                    className: 'drag-visible',
                    render: () => <DragHandle />,
                  },
                  {
                    title: 'Video',
                    align: 'center',
                    className: 'drag-visible',
                    render: (row) => (
                      <ReactPlayer
                        controls
                        height={100}
                        width={100}
                        url={row.url}
                      />
                    ),
                  },
                  {
                    title: 'Thumbnail',
                    align: 'center',
                    className: 'drag-visible',
                    render: (row) => (
                      <Image
                        height={100}
                        width={100}
                        src={row.thumbnailUrl}
                        alt={row.thumbnailUrl}
                      />
                    ),
                  },
                  {
                    title: t('actions'),
                    align: 'center',
                    className: 'drag-visible',
                    render: (row) => (
                      <Button
                        type="primary"
                        danger
                        onClick={() => {
                          removeVideoClick(row);
                        }}
                      >
                        {t('delete')}
                      </Button>
                    ),
                  }
                ]}
                rowKey="id"
                components={{
                  body: {
                    row: DraggableBodyRow,
                  },
                }}
              />
            </SortableContext>
          </DndContext>
          <Button
            onClick={() => { updateVideos(); }}
            type="primary"
            style={{
              float: 'right',
              marginTop: 10
            }}
          >
            {t('update')}
          </Button>
        </TabPane>
      </Tabs>
    </Drawer>
  );
}

export default ManageRegistrationItemMediaDrawer;
