import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { useMount } from 'react-use';
import moment from 'moment';
import { Dropdown, Menu, notification, Tooltip, Avatar } from 'antd';
import { Comment } from '@ant-design/compatible';
import { ShopOutlined, DownOutlined } from '@ant-design/icons';
import {
  useRecoilState,
  useRecoilValueLoadable,
  useSetRecoilState,
} from 'recoil';
import { businessesSelector } from '../../../atoms/Selectors';
import {
  businessAtom,
  pageAtom,
  profileAtom,
  rowsAtom,
} from '../../../atoms/Atoms';
import SidebarSpinner from './SidebarSpinner';
import Grocefy from '../../../assets/images/grocefyLogoAlone.png';

function SidebarDropdown() {
  const businesses = useRecoilValueLoadable(businessesSelector);
  const [business, setBusiness] = useRecoilState(businessAtom);
  const setPage = useSetRecoilState(pageAtom);
  const setRows = useSetRecoilState(rowsAtom);
  const { state } = businesses;
  const profile = useRecoilValueLoadable(profileAtom);
  const isAdmin =
    profile?.contents && profile?.contents?.roles?.includes('SuperAdmin');
  const isCustomerService =
    isAdmin || (profile?.contents && (profile?.contents?.roles?.includes('CustomerServiceAdmin') || profile?.contents?.roles?.includes('CustomerService')));
  const { t } = useTranslation();
  const [mappedBusiness, setMappedBusiness] = useState([]);

  const openNotification = (b) => {
    notification.open({
      message: 'Switched store...',
      description: (
        <Comment
          author={<span>Grocefy</span>}
          avatar={<Avatar src={Grocefy} alt="grocefy" />}
          content={
            <p className="text-sm">
              {t('viewing_from_content')} {b.name}
            </p>
          }
          datetime={
            <Tooltip title={moment().format('YYYY-MM-DD HH:mm:ss')}>
              <span>{moment().fromNow()}</span>
            </Tooltip>
          }
        />
      ),
    });
  };

  function mapBusiness() {
    const mapped = _.map(businesses?.contents?.data?.data?.results, (p, index) => ({
      key: index,
      id: p.id,
      name: p.name,
      activeMenuId: p.activeMenuId,
      allowDelivery: p.allowDelivery,
      allowPaypalTransfer: p.allowPaypalTransfer,
      allowPromoDateUpdates: p.allowPromoDateUpdates,
      allowStripePayouts: p.allowStripePayouts,
      allowTakeOut: p.allowTakeOut,
      backgroundImage: p.backgroundImage,
      createdAt: p.createdAt,
      deliMenuId: p.deliMenuId,
      description: p.description,
      hasSelfDelivery: p.hasSelfDelivery,
      isActive: p.isActive,
      isAlpha: p.isAlpha,
      isBeta: p.isBeta,
      locations: p.locations,
      logoImage: p.logoImage,
      menuHeaderImage: p.menuHeaderImage,
      minimumDeliveryOrderAmount: p.minimumDeliveryOrderAmount,
      minimumOrderAmount: p.minimumOrderAmount,
      minimumPickupOrderAmount: p.minimumPickupOrderAmount,
      payPalDailyTransfers: p.payPalDailyTransfers,
      pricesIncludeTax: p.pricesIncludeTax,
      reportEmails: p.reportEmails,
      requiresFullCatalogUpdate: p.requiresFullCatalogUpdate,
      sendDailyReport: p.sendDailyReport,
      shortName: p.shortName,
      stripeDailyPayouts: p.stripeDailyPayouts,
      stripeDailyTransfers: p.stripeDailyTransfers,
      verifiedAt: p.verifiedAt,
      visibleInBeta: p.visibleInBeta,
      weMissYouActivated: p.weMissYouActivated,
      webHeaderImage: p.webHeaderImage
    }));

    return _.sortBy(mapped, [(o) => o.name]);
  }

  useEffect(() => {
    if (state === 'hasValue') {
      // setBusiness(businesses.contents.data.data.results[0]);
      setMappedBusiness(mapBusiness());
    }
  }, [businesses]);

  useMount(() => {
    setBusiness(null);
    if (state === 'hasValue') {
      setMappedBusiness(mapBusiness());
    }
  });

  return (
    <Dropdown
      disabled={state === 'loading'}
      overlay={
        state === 'hasValue' && (
          <Menu
            selectedKeys={
              business ? [business.id] :
                ((isAdmin || isCustomerService) ? [''] : [businesses?.contents?.data?.data?.results[0]?.id])
            }
          >
            {(isAdmin || isCustomerService) && (
              <Menu.Item
                key=""
                icon={<ShopOutlined />}
                className="text-xs flex items-center"
                onClick={() => {
                  setBusiness(null);
                }}
              >
                {t('store_not_selected')}
              </Menu.Item>
            )}
            {state && mappedBusiness && _.map(mappedBusiness, (b) => (
              <Menu.Item
                key={b.id}
                icon={<ShopOutlined />}
                className="text-xs flex items-center"
                onClick={() => {
                  setBusiness(b);
                  setPage(1);
                  setRows(10);
                  // openNotification(b);
                }}
              >
                {b.name}
              </Menu.Item>
            ))}
          </Menu>
        )
      }
      arrow="true"
    >
      <button
        type="button"
        disabled={state === 'loading'}
        className="text-xs truncate px-3 w-full bg-blue-900 text-gray-100 font-medium h-16 transition duration-500 ease-in-out outline-none focus:outline-none"
      >
        {state === 'loading' && <SidebarSpinner />}
        {state === 'hasValue' && business && business.name}
        {state === 'hasValue' && !business && 'Global'}
        <DownOutlined
          style={{ marginLeft: 10 }}
        />
      </button>
    </Dropdown>
  );
}

export default SidebarDropdown;
