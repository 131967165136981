import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import { notification, Modal, Avatar, Button, Select, Typography } from 'antd';
import { Comment } from '@ant-design/compatible';
import { changeOrderStatusAtom, selectedOrderAtom, reloadOrder } from '../../../atoms/Atoms';
import api from '../../../api/api';
import Grocefy from '../../../assets/images/grocefyLogoAlone.png';

function ChangeOrderStatus() {
  const [changeOrderStatus, setchangeOrderStatus] = useRecoilState(changeOrderStatusAtom);
  const [selectedOrder, setSelectedOrder] = useRecoilState(selectedOrderAtom);
  const [reload, setReload] = useRecoilState(reloadOrder);
  const [loading, setLoading] = useState(false);
  const { Option } = Select;
  const [newValue, setNewValue] = useState(null);
  const { t } = useTranslation();

  const closeModal = function () {
    if (!loading) {
      setchangeOrderStatus(false);
    }
  };

  const editClick = function () {
    setLoading(true);
    api
      .put(
        `cs/order/changestatus/${newValue}/${selectedOrder.id}`,
        null
      )
      .then((response) => {
        if (response && response.data.error) {
          console.error('Error Message: ', response.data.error);
          notification.open({
            message: 'Alert!',
            description: (
              <Comment
                author={<span>Grocefy</span>}
                avatar={<Avatar src={Grocefy} alt="grocefy" />}
                content={
                  <p className="text-sm">{response.data.error}</p>
                }
              />
            ),
          });
        } else if (
          response &&
          response.data.success &&
          response.data.message
        ) {
          notification.open({
            message: 'Success!',
            description: (
              <Comment
                author={<span>Grocefy</span>}
                avatar={<Avatar src={Grocefy} alt="grocefy" />}
                content={
                  <p className="text-sm">{response.data.message}</p>
                }
              />
            ),
          });
        }
        setchangeOrderStatus(false);
        setReload(true);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <>
      {changeOrderStatus && (
        <Modal
          closable="true"
          centered="true"
          width={300}
          open={changeOrderStatus}
          onCancel={() => { setchangeOrderStatus(false); }}
          footer={[
            <Button
              key="close"
              type="primary"
              danger
              disabled={loading}
              onClick={() => closeModal()}
            >
              {t('close')}
            </Button>,
            <Button
              key="create"
              disabled={loading}
              type="primary"
              onClick={() => editClick()}
            >
              {t('change')}
            </Button>
          ]}
        >
          <div>
            <Typography.Title level={5}>{t('change_order_status')}</Typography.Title>
            <br />
            <Select
              style={{ width: 175 }}
              defaultValue="Select new order status..."
              onChange={(value) => setNewValue(value)}
            >
              <Option value={6}>{t('status_customer_picked_up')}</Option>
              <Option value={3}>{t('status_accepted')}</Option>
              <Option value={7}>{t('status_delivered')}</Option>
              <Option value={20}>{t('status_waiting_for_customer')}</Option>
              <Option value={21}>{t('status_waiting_delivery_assignment')}</Option>
              <Option value={2}>{t('status_driver_assigned')}</Option>
              <Option value={30}>{t('status_pending_payment')}</Option>
              <Option value={27}>{t('status_pending_paypal_payment')}</Option>
              <Option value={36}>{t('status_waiting_for_payment')}</Option>
              <Option value={22}>{t('status_pending_athmovil_payment')}</Option>
            </Select>
          </div>
        </Modal>
      )}
    </>
  );
}

export default ChangeOrderStatus;
