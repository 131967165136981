import React from 'react';
import { Tabs } from 'antd';
import { useTranslation } from 'react-i18next';
import EmployeeInStoreSalesFinance from './shared-components/EmployeeInStoreSalesFinance';
import EmployeeInStoreSummary from './shared-components/EmployeeInStoreSummary';

function EmployeeInStore() {
  const { t } = useTranslation();

  return (
    <>
      <Tabs defaultActiveKey="1">
        <Tabs.TabPane tab={t('in_store_employees')} key="1">
          <EmployeeInStoreSalesFinance />
        </Tabs.TabPane>
        <Tabs.TabPane tab={t('summary')} key="2">
          <EmployeeInStoreSummary />
        </Tabs.TabPane>
      </Tabs>
    </>
  );
}

export default EmployeeInStore;
