import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import {
  useRecoilState,
  useRecoilValueLoadable,
  useSetRecoilState
} from 'recoil';
import {
  Drawer,
  Avatar,
  Tooltip,
  DatePicker,
  Upload,
  InputNumber,
  Switch,
  Collapse,
  Image,
  Button,
  Select,
  Input,
  notification
} from 'antd';
import { Comment } from '@ant-design/compatible';
import {
  InboxOutlined,
  CloseOutlined
} from '@ant-design/icons';
import moment from 'moment';
import dayjs from 'dayjs';
import Grocefy from '../../../../assets/images/grocefyLogoAlone.png';
import {
  refreshPromoSmsAtom,
  selectedPromoSmsAtom,
  showRelatedItemPickAtom,
  selectedPickingItemAtom,
  profileAtom
} from '../../../../atoms/Atoms';
import { businessesSelector } from '../../../../atoms/Selectors';
import api from '../../../../api/api';

function EditPromoSmsDrawer() {
  const { Dragger } = Upload;
  const { Panel } = Collapse;
  const { TextArea } = Input;
  const { RangePicker } = DatePicker;
  const { Option } = Select;
  const profile = useRecoilValueLoadable(profileAtom);
  const businesses = useRecoilValueLoadable(businessesSelector);
  const setShowRelatedItemPick = useSetRecoilState(showRelatedItemPickAtom);
  const setRefreshPromoEmails = useSetRecoilState(refreshPromoSmsAtom);
  const [selectedPickingItem, setSelectedPickingItem] =
    useRecoilState(selectedPickingItemAtom);
  const [selectedPromoSms, setSelectedPromoSms] = useRecoilState(selectedPromoSmsAtom);
  const [loading, setLoading] = useState(false);
  const [promoSms, setPromoSms] = useState({});
  const [mainImageEnglishData, setMainImageEnglishData] = useState(null);
  const [mainImageSpanishData, setMainImageSpanishData] = useState(null);
  const [relatedItem, setRelatedItem] = useState(null);
  const { t } = useTranslation();
  const isAdmin =
    profile.contents && profile?.contents?.roles?.includes('SuperAdmin');

  const englishMainDraggerProps = {
    name: 'file',
    accept: 'image/*',
    multiple: false,
    showUploadList: false,
    beforeUpload: (file) => {
      setMainImageEnglishData(file);
      return false;
    }
  };
  const spanishMainDraggerProps = {
    name: 'file',
    accept: 'image/*',
    multiple: false,
    showUploadList: false,
    beforeUpload: (file) => {
      setMainImageSpanishData(file);
      return false;
    }
  };

  const showMessage = function (message) {
    notification.open({
      message: '',
      description: (
        <Comment
          author={<span>Grocefy</span>}
          avatar={<Avatar src={Grocefy} alt="grocefy" />}
          content={
            <p className="text-sm">
              {message}
            </p>
          }
          datetime={
            <Tooltip title={moment().format('YYYY-MM-DD HH:mm:ss')}>
              <span>{moment().fromNow()}</span>
            </Tooltip>
          }
        />
      ),
    });
  };

  const uploadImage = async function (imageData) {
    const file = imageData;
    const boundary = Date.now();
    const data = new FormData();
    data.append('file', file);
    setLoading(true);
    const response = await api
      .post('utility/promoSms/uploadfile', data);
    setLoading(false);
    if (response.data.success) {
      return response.data.data;
    }
    showMessage(response.data.error);
    return null;
  };

  const getItem = function (itemId) {
    api.get(`businesses/catalog/item/${itemId}`)
      .then((response) => {
        if (response.data.success) {
          setRelatedItem(response.data.data);
        } else {
          console.error(response.data.error);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const removeEnlgishMainImage = function () {
    const temp = JSON.parse(JSON.stringify(promoSms));
    temp.imageUrlEng = '';
    setPromoSms(temp);
  };
  const removeSpanishMainImage = function () {
    const temp = JSON.parse(JSON.stringify(promoSms));
    temp.imageUrlSpa = '';
    setPromoSms(temp);
  };

  const removeRelatedItem = function () {
    const temp = JSON.parse(JSON.stringify(promoSms));
    setRelatedItem(null);
    temp.relatedItemId = null;
    setPromoSms(temp);
  };

  const onChangeMaxToSend = function (obj) {
    const temp = JSON.parse(JSON.stringify(promoSms));
    temp.maxToSend = obj === 0 ? null : obj;
    setPromoSms(temp);
  };

  const onChangeSendDate = function (date, dateString) {
    const temp = JSON.parse(JSON.stringify(promoSms));
    temp.sendDate = dateString;
    setPromoSms(temp);
  };

  const onChangeDate = function (date, dateString) {
    const temp = JSON.parse(JSON.stringify(promoSms));
    const start = dateString[0];
    const end = dateString[1];
    temp.startDate = start;
    temp.endDate = end;
    setPromoSms(temp);
  };

  const onChangeMaxPerUser = function (obj) {
    const temp = JSON.parse(JSON.stringify(promoSms));
    temp.maxPerUser = obj === 0 ? null : obj;
    setPromoSms(temp);
  };

  const onChangeStatus = function (obj) {
    const temp = JSON.parse(JSON.stringify(promoSms));
    temp.status = obj;
    setPromoSms(temp);
  };

  const onChangeIsActive = function (obj) {
    const temp = JSON.parse(JSON.stringify(promoSms));
    temp.isActive = obj;
    setPromoSms(temp);
  };

  const onChangeMessageEng = function (obj) {
    const temp = JSON.parse(JSON.stringify(promoSms));
    temp.messageEng = obj.target.value;
    setPromoSms(temp);
  };

  const onChangeMessageSpa = function (obj) {
    const temp = JSON.parse(JSON.stringify(promoSms));
    temp.messageSpa = obj.target.value;
    setPromoSms(temp);
  };

  const onChangeItemName = function (obj) {
    const temp = JSON.parse(JSON.stringify(promoSms));
    temp.name = obj.target.value;
    setPromoSms(temp);
  };

  const onChangeBusiness = function (obj) {
    const temp = JSON.parse(JSON.stringify(promoSms));
    temp.relatedBusinessId = obj;
    setPromoSms(temp);
  };

  const onChangeOrderType = function (obj) {
    const temp = JSON.parse(JSON.stringify(promoSms));
    temp.orderTypes = obj;
    setPromoSms(temp);
  };

  const onChangeSmsType = function (obj) {
    const temp = JSON.parse(JSON.stringify(promoSms));
    temp.types = obj;
    setPromoSms(temp);
  };

  const onChangeOrderStatus = function (obj) {
    const temp = JSON.parse(JSON.stringify(promoSms));
    temp.orderStatusTrigger = obj;
    setPromoSms(temp);
  };

  const submitPromoSms = function () {
    setLoading(true);
    const temp = JSON.parse(JSON.stringify(promoSms));
    temp.type = 0;
    temp.orderType = 0;
    for (let i = 0; i < temp.types.length; i++) {
      temp.type += temp.types[i];
    }
    for (let i = 0; i < temp.orderTypes.length; i++) {
      temp.orderType += temp.orderTypes[i];
    }
    if (temp.sendDate && temp.sendDate.length > 0) {
      temp.sendDate = moment(temp.sendDate);
    }
    if (temp.startDate && temp.startDate.length > 0) {
      temp.startDate = moment(temp.startDate);
    }
    if (temp.endDate && temp.endDate.length > 0) {
      temp.endDate = moment(temp.endDate);
    }
    if (temp.sendDate && temp.sendDate.length > 0 && temp.sendDate.length < 17) {
      temp.sendDate = `${temp.sendDate.replace(' ', 'T')}:00.000`;
    }
    if (temp.startDate && temp.startDate.length > 0 && temp.startDate.length < 17) {
      temp.startDate = `${temp.startDate.replace(' ', 'T')}:00.000`;
    }
    if (temp.endDate && temp.endDate.length > 0 && temp.endDate.length < 17) {
      temp.endDate = `${temp.endDate.replace(' ', 'T')}:00.000`;
    }
    if (temp.types && !temp.types.includes(2) && !temp.types.includes(8)) {
      temp.orderStatusTrigger = undefined;
    }
    if (temp.types && !temp.types.includes(4)) {
      temp.relatedItemId = undefined;
    }
    if (temp.template === 0) {
      temp.action = '';
      temp.actionButtonTextEng = '';
      temp.actionButtonTextSpa = '';
    }
    let url = 'marketing/promotion/sms';
    if (temp.id) {
      url += `/${temp.id}`;
    }
    api.post(
      url,
      temp
    )
      .then((response) => {
        setLoading(false);
        if (response.data.success) {
          setRefreshPromoEmails(true);
          setSelectedPromoSms(null);
        } else {
          showMessage(response.data.error);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  const archiveClick = function () {
    setLoading(true);
    api.delete(`marketing/promotion/sms/${promoSms.id}`)
      .then((response) => {
        setLoading(false);
        if (response.data.success) {
          setRefreshPromoEmails(true);
          setSelectedPromoSms(null);
        } else {
          console.error(response.data.error);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  useEffect(() => {
    if (selectedPromoSms) {
      const temp = JSON.parse(JSON.stringify(selectedPromoSms));
      if (!temp.id) {
        temp.types = [1];
        temp.orderTypes = [1];
        temp.relatedBusinessId = '';
        temp.orderStatusTrigger = 0;
        temp.isActive = false;
        temp.status = 0;
      }
      if (temp.relatedItemId) {
        getItem(temp.relatedItemId);
      }
      setPromoSms(temp);
    }
  }, [selectedPromoSms]);

  useEffect(() => {
    if (selectedPickingItem && promoSms) {
      const temp = JSON.parse(JSON.stringify(promoSms));
      setRelatedItem(selectedPickingItem);
      temp.relatedItemId = selectedPickingItem.id;
      setPromoSms(temp);
      setSelectedPickingItem(null);
    }
  }, [selectedPickingItem]);

  useEffect(async () => {
    if (mainImageEnglishData) {
      const url = await uploadImage(mainImageEnglishData);
      if (url) {
        setMainImageEnglishData(null);
        const temp = JSON.parse(JSON.stringify(promoSms));
        temp.imageUrlEng = url;
        setPromoSms(temp);
      }
    }
  }, [mainImageEnglishData]);

  useEffect(async () => {
    if (mainImageSpanishData) {
      const url = await uploadImage(mainImageSpanishData);
      if (url) {
        setMainImageSpanishData(null);
        const temp = JSON.parse(JSON.stringify(promoSms));
        temp.imageUrlSpa = url;
        setPromoSms(temp);
      }
    }
  }, [mainImageSpanishData]);

  return (
    <Drawer
      title={`${promoSms && promoSms.id ? t('update') : t('create')} ${t('promo_sms')}`}
      placement="right"
      closable
      onClose={() => setSelectedPromoSms(null)}
      open={selectedPromoSms}
      width={400}
    >
      {promoSms && (
        <>
          <div
            style={{
              float: 'right',
              marginBottom: '10px'
            }}
          >
            <Button
              type="primary"
              danger
              disabled={loading}
              hidden={!promoSms.id}
              onClick={() => { archiveClick(); }}
            >
              {t('archive')}
            </Button>
          </div>
          <div style={{ marginBottom: 10 }}>
            <strong>{t('name')}:</strong>
            <Input
              disabled={loading}
              placeholder="Name"
              onChange={(obj) => onChangeItemName(obj)}
              value={promoSms.name}
            />
          </div>
          {promoSms && promoSms.id && (
            <>
              <div style={{ marginBottom: 10 }}>
                <strong>{t('active')}?</strong>
                <Switch
                  style={{ float: 'right' }}
                  disabled={!isAdmin}
                  checked={promoSms.isActive}
                  onChange={(checked) => {
                    onChangeIsActive(checked);
                  }}
                />
              </div>
              <div style={{ marginBottom: 10 }}>
                <strong>{t('status')}: </strong>
                <Select
                  hidden={promoSms.status === 3}
                  defaultValue={promoSms.status}
                  value={promoSms.status}
                  style={{ width: 120 }}
                  disabled={loading}
                  onChange={(obj) => onChangeStatus(obj)}
                >
                  <Option value={0}>{t('pending')}</Option>
                  <Option value={1}>{t('approved')}</Option>
                  <Option value={2}>{t('declined')}</Option>
                </Select>
                <Select
                  hidden={promoSms.status !== 3}
                  defaultValue={3}
                  value={3}
                  style={{ width: 120 }}
                  disabled={loading}
                  onChange={(obj) => onChangeStatus(obj)}
                >
                  <Option value={3}>{t('sent')}</Option>
                </Select>
              </div>
            </>
          )}
          <Collapse defaultActiveKey={['1']}>
            <Panel header="Configuration" key="1">
              <div style={{ marginBottom: 10 }}>
                <strong>{t('email_type')}: </strong>
                <br />
                <Select
                  mode="multiple"
                  allowClear
                  defaultValue={promoSms.types}
                  value={promoSms.types}
                  style={{ width: '100%' }}
                  disabled={loading}
                  onChange={(obj) => onChangeSmsType(obj)}
                >
                  <Option
                    value={1}
                    disabled={
                      promoSms.types &&
                        (promoSms.types.includes(2) ||
                        promoSms.types.includes(4) ||
                        promoSms.types.includes(8))
                    }
                  >
                    {t('broadcast')}
                  </Option>
                  <Option
                    value={2}
                    disabled={!promoSms.types ||
                      (promoSms.types && promoSms.types.includes(1))}
                  >
                    {t('order_submit_specific')}
                  </Option>
                  <Option
                    value={4}
                    disabled={!promoSms.types ||
                      (promoSms.types && promoSms.types.includes(1))}
                  >
                    {t('item_purchase_specific')}
                  </Option>
                  <Option
                    value={8}
                    disabled={!promoSms.types ||
                      (promoSms.types && promoSms.types.includes(1))}
                  >
                    {t('order_status_specific')}
                  </Option>
                </Select>
              </div>
              <div
                style={{ marginBottom: 10 }}
                hidden={!promoSms.types || !promoSms.types.includes(2)}
              >
                <strong>{t('order_types')}: </strong>
                <br />
                <Select
                  mode="multiple"
                  allowClear
                  defaultValue={promoSms.orderTypes}
                  value={promoSms.orderTypes}
                  style={{ width: '100%' }}
                  disabled={loading}
                  onChange={(obj) => onChangeOrderType(obj)}
                >
                  <Option value={1}>{t('any')}</Option>
                  <Option value={2}>{t('pickup')}</Option>
                  <Option value={4}>{t('delivety')}</Option>
                  <Option value={64}>{t('in_store')}</Option>
                </Select>
              </div>
              <div
                hidden={!promoSms.types || !promoSms.types.includes(8)}
                style={{ marginBottom: 10 }}
              >
                <strong>{t('order_status_trigger')}: </strong>
                <Select
                  defaultValue={promoSms.orderStatusTrigger}
                  value={promoSms.orderStatusTrigger}
                  style={{ width: 120 }}
                  disabled={loading}
                  onChange={(obj) => onChangeOrderStatus(obj)}
                >
                  <Option value={0}>Placed</Option>
                  <Option value={3}>Accepted</Option>
                  <Option value={16}>Picking Started</Option>
                  <Option value={17}>Picking Finished</Option>
                  <Option value={18}>Packing Started</Option>
                  <Option value={19}>Packing Finished</Option>
                  <Option value={20}>Waiting For Customer Pickup</Option>
                  <Option value={5}>Delivery Started</Option>
                  <Option value={6}>Customer Picked Up</Option>
                  <Option value={7}>Delivered</Option>
                  <Option value={8}>Business Cancelled</Option>
                  <Option value={9}>User Cancelled</Option>
                </Select>
              </div>
              <div
                style={{ marginBottom: 10 }}
                hidden={!promoSms.types || (promoSms.types && promoSms.types.includes(1)) ||
                  promoSms.types.length === 0}
              >
                <strong>{t('business')}: </strong>
                <Select
                  defaultValue={promoSms.relatedBusinessId}
                  value={promoSms.relatedBusinessId}
                  style={{ width: 120 }}
                  disabled={loading}
                  onChange={(obj) => onChangeBusiness(obj)}
                >
                  <Option value="">Any</Option>
                  {businesses.state === 'hasValue' && _.map(businesses.contents.data.data.results, (business) => (
                    <Option
                      value={business.id}
                      key={business.id}
                    >
                      {business.name}
                    </Option>
                  ))}
                </Select>
              </div>
              <div
                style={{ marginBottom: 10 }}
                hidden={!promoSms.types || (promoSms.types && promoSms.types.includes(1)) ||
                  promoSms.types.length === 0}
              >
                <strong>{t('max_to_sent')}</strong>
                <InputNumber
                  disabled={loading}
                  onChange={(obj) => onChangeMaxToSend(obj)}
                  value={promoSms.maxToSend}
                />
              </div>
              <div
                style={{ marginBottom: 10 }}
                hidden={!promoSms.types || (promoSms.types && promoSms.types.includes(1)) ||
                  promoSms.types.length === 0}
              >
                <strong>{t('max_per_user')}</strong>
                <InputNumber
                  disabled={loading}
                  onChange={(obj) => onChangeMaxPerUser(obj)}
                  value={promoSms.maxPerUser}
                />
              </div>
              <div
                style={{ marginBottom: 10 }}
                hidden={!promoSms.types || !promoSms.types.includes(1)}
              >
                <strong>{t('send_date')}</strong>
                <br />
                <DatePicker
                  showTime={{ format: 'HH:mm' }}
                  onChange={(date, dateString) => onChangeSendDate(date, dateString)}
                  value={
                    promoSms.sendDate ?
                      dayjs(promoSms?.sendDate) : ''
                  }
                />
              </div>
              <div
                style={{ marginBottom: 10 }}
                hidden={!promoSms.types || promoSms.types.includes(1)}
              >
                <strong>{t('start_end_date')}</strong>
                <br />
                <RangePicker
                  showTime={{ format: 'HH:mm' }}
                  onChange={
                    (date, dateString) =>
                      onChangeDate(date, dateString)
                  }
                  format="YYYY-MM-DD HH:mm"
                  defaultValue={[
                    dayjs(selectedPromoSms?.startDate),
                    dayjs(selectedPromoSms?.endDate)
                  ]}
                />
              </div>
              <div
                hidden={!promoSms.types || !promoSms.types.includes(4)}
                style={{ marginBottom: 10 }}
              >
                <strong>{t('related_item_id')}</strong>
                <br />
                {!promoSms.relatedItemId && (
                  <Button
                    onClick={() => { setShowRelatedItemPick(true); }}
                  >
                    {t('select_item')}
                  </Button>
                )}
                {promoSms.relatedItemId && (
                  <>
                    {relatedItem && (
                      <>
                        <img
                          height={50}
                          width={50}
                          alt={`${relatedItem.brand} ${relatedItem.name} ${relatedItem.description}`}
                          src={relatedItem.itemImage}
                        />
                        <span>
                          {relatedItem.brand} {relatedItem.name} {relatedItem.description}
                        </span>
                      </>
                    )}
                    <Button
                      onClick={() => removeRelatedItem()}
                    >
                      {t('delete')}
                    </Button>
                  </>
                )}
              </div>
            </Panel>
            <Panel header="Content" key="2">
              <div style={{ marginBottom: 10 }}>
                <strong>{t('english_message')}</strong>
                <TextArea
                  onChange={(obj) => onChangeMessageEng(obj)}
                  rows={4}
                  value={promoSms.messageEng}
                />
              </div>
              <div style={{ marginBottom: 10 }}>
                <strong>{t('spanish_message')}</strong>
                <TextArea
                  onChange={(obj) => onChangeMessageSpa(obj)}
                  rows={4}
                  value={promoSms.messageSpa}
                />
              </div>
            </Panel>
            <Panel header="Images" key="3">
              <div style={{ marginBottom: 10 }}>
                <Tooltip title={t('image_requirements')}>
                  <strong>{t('english_image')}:</strong>
                </Tooltip>
                <Input
                  className="w-full"
                  disabled={loading}
                  loading={loading}
                  value={promoSms.imageUrlEng}
                  onChange={(obj) => {
                    const temp = JSON.parse(JSON.stringify(promoSms));
                    temp.imageUrlEng = obj.target.value;
                    setPromoSms(temp);
                  }}
                />
                <Dragger {...englishMainDraggerProps}>
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p
                    className="ant-upload-text"
                    style={{ margin: 10 }}
                  >
                    {t('click_or_drag_content')}
                  </p>
                </Dragger>
                {promoSms.imageUrlEng && (
                  <>
                    <Image
                      alt={promoSms.imageUrlEng}
                      src={promoSms.imageUrlEng}
                      height={75}
                    />
                    <Button
                      disabled={loading}
                      type="primary"
                      danger
                      onClick={() => { removeEnlgishMainImage(); }}
                    >
                      <CloseOutlined />
                    </Button>
                  </>
                )}
              </div>
              <div style={{ marginBottom: 10 }}>
                <Tooltip title={t('image_requirements')}>
                  <strong>{t('spanish_image')}:</strong>
                </Tooltip>
                <Input
                  className="w-full"
                  disabled={loading}
                  loading={loading}
                  value={promoSms.imageUrlSpa}
                  onChange={(obj) => {
                    const temp = JSON.parse(JSON.stringify(promoSms));
                    temp.imageUrlSpa = obj.target.value;
                    setPromoSms(temp);
                  }}
                />
                <Dragger {...spanishMainDraggerProps}>
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p
                    className="ant-upload-text"
                    style={{ margin: 10 }}
                  >
                    {t('click_or_drag_content')}
                  </p>
                </Dragger>
                {promoSms.imageUrlSpa && (
                  <>
                    <Image
                      alt={promoSms.imageUrlSpa}
                      src={promoSms.imageUrlSpa}
                      height={75}
                    />
                    <Button
                      disabled={loading}
                      type="primary"
                      danger
                      onClick={() => { removeSpanishMainImage(); }}
                    >
                      <CloseOutlined />
                    </Button>
                  </>
                )}
              </div>
            </Panel>
          </Collapse>
          <div
            style={{
              float: 'right',
              marginTop: '10px'
            }}
          >
            <Button
              style={{
                marginLeft: '10px'
              }}
              disabled={loading}
              type="primary"
              onClick={() => { submitPromoSms(); }}
            >
              {promoSms.id ? t('update') : t('create')}
            </Button>
          </div>
        </>
      )}
    </Drawer>
  );
}

export default EditPromoSmsDrawer;
