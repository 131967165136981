import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import dayjs from 'dayjs';
import {
  useRecoilState,
  useSetRecoilState
} from 'recoil';
import { TwitterPicker } from 'react-color';
import {
  Drawer,
  Avatar,
  Tooltip,
  DatePicker,
  Upload,
  Collapse,
  Switch,
  Button,
  Select,
  Input,
  notification
} from 'antd';
import { Comment } from '@ant-design/compatible';
import {
  InboxOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import Grocefy from '../../../../assets/images/grocefyLogoAlone.png';
import {
  selectedSponsorshipAtom,
  refreshSponsorshipsAtom,
  showEditSponsorshipDrawerAtom,
  showCreateSponsorshipDrawerAtom
} from '../../../../atoms/Atoms';
import api from '../../../../api/api';

function EditSponsorship() {
  const { RangePicker } = DatePicker;
  const { Dragger } = Upload;
  const { Panel } = Collapse;
  const setRefreshSponsorships = useSetRecoilState(refreshSponsorshipsAtom);
  const setShowEditSponsorshipDrawer = useSetRecoilState(showEditSponsorshipDrawerAtom);
  const setShowCreateSponsorshipDrawer = useSetRecoilState(showCreateSponsorshipDrawerAtom);
  const [selectedSponsorship, setSelectedSponsorship] = useRecoilState(selectedSponsorshipAtom);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const { t } = useTranslation();

  const showMessage = function (message) {
    notification.open({
      message: '',
      description: (
        <Comment
          author={<span>Grocefy</span>}
          avatar={<Avatar src={Grocefy} alt="grocefy" />}
          content={
            <p className="text-sm">
              {message}
            </p>
          }
          datetime={
            <Tooltip title={moment().format('YYYY-MM-DD HH:mm:ss')}>
              <span>{moment().fromNow()}</span>
            </Tooltip>
          }
        />
      ),
    });
  };

  const submit = function () {
    setLoading(true);
    const temp = JSON.parse(JSON.stringify(data));
    temp.startDate = moment(temp.startDate).utc();
    temp.endDate = moment(temp.endDate).utc();
    if (temp.startDate.length < 17) {
      temp.startDate = `${temp.startDate.replace(' ', 'T')}:00.000`;
      temp.endDate = `${temp.endDate.replace(' ', 'T')}:00.000`;
    }
    api.post('marketing/sponsorships', temp)
      .then((response) => {
        setLoading(false);
        if (response.data.success) {
          setRefreshSponsorships(true);
          setShowCreateSponsorshipDrawer(false);
          setShowEditSponsorshipDrawer(false);
          if (selectedSponsorship.id) {
            const [updatedCampaign] = _.filter(response.data.data.results, (c) => c.id
              === selectedSponsorship.id);
            setSelectedSponsorship(updatedCampaign);
          }
        } else {
          showMessage(response.data.error);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  useEffect(() => {
    if (selectedSponsorship) {
      const temp = JSON.parse(JSON.stringify(selectedSponsorship));
      if (!temp.startDate || temp.startDate.length === 0) {
        temp.startDate = moment().startOf('day');
      }
      if (!temp.endDate || temp.endDate.length === 0) {
        temp.endDate = moment().endOf('day');
      }
      setData(temp);
    }
  }, [selectedSponsorship]);

  return (
    <Drawer
      title={`${data && data.id ? t('update') : t('create')} ${t('sponsorship')}`}
      placement="right"
      closable
      onClose={() => {
        setShowCreateSponsorshipDrawer(false);
        setShowEditSponsorshipDrawer(false);
      }}
      open={selectedSponsorship}
      width={400}
    >
      <>
        <div style={{ marginBottom: 10 }}>
          <strong>{t('name')}:</strong>
          <Input
            disabled={loading}
            placeholder={t('name')}
            onChange={(obj) => {
              const temp = JSON.parse(JSON.stringify(data));
              temp.name = obj.target.value;
              temp.title = obj.target.value;
              setData(temp);
            }}
            value={data?.name}
          />
        </div>
        <div style={{ marginBottom: 10 }}>
          <strong>{'Start Date -> End Date:'}</strong>
          <RangePicker
            showTime={{ format: 'HH:mm' }}
            format="MMM, DD YYYY hh:mm A"
            value={[
              dayjs(data?.startDate),
              dayjs(data?.endDate)
            ]}
            onChange={(date, dateString) => {
              const temp = JSON.parse(JSON.stringify(data));
              const start = dateString[0];
              const end = dateString[1];
              temp.startDate = start;
              temp.endDate = end;
              setData(temp);
            }}
          />
        </div>
        <div
          style={{
            float: 'right',
            marginTop: '10px'
          }}
        >
          <Button
            style={{
              marginLeft: '10px'
            }}
            disabled={loading}
            type="primary"
            onClick={() => { submit(); }}
          >
            {data?.id ? t('update') : t('create')}
          </Button>
        </div>
      </>
    </Drawer>
  );
}

export default EditSponsorship;
