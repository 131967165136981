import React from 'react';
import { Tag, Tabs } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { CheckCircleOutlined, SyncOutlined } from '@ant-design/icons';
import Hours from './shared-components/Hours';
import Holidays from './shared-components/Holidays';
import { businessAtom } from '../../../atoms/Atoms';

function OperationHours() {
  const business = useRecoilValue(businessAtom);
  const { t } = useTranslation();

  return (
    <>
      <PageHeader
        title={t('operation_hours')}
        tags={
          <Tag
            color="#2db7f5"
            icon={business ? <CheckCircleOutlined /> : <SyncOutlined spin />}
          >
            {business ? business.name : `${t('loading')}...`}
          </Tag>
        }
      />
      <Tabs className="mx-4" defaultActiveKey="1">
        <Tabs.TabPane tab={t('hours')} key="1">
          <Hours />
        </Tabs.TabPane>
        <Tabs.TabPane tab={t('holidays')} key="2">
          <Holidays />
        </Tabs.TabPane>
      </Tabs>
    </>
  );
}

export default OperationHours;
