import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import {
  Drawer,
  Button,
  Switch,
  Input,
  InputNumber,
  notification,
  Tooltip,
  Avatar
} from 'antd';
import { Comment } from '@ant-design/compatible';
import moment from 'moment';
import Grocefy from '../../../assets/images/grocefyLogoAlone.png';
import {
  createNewBusinessAtom
} from '../../../atoms/Atoms';
import api from '../../../api/api';

function CreateNewBusiness() {
  const [createNewBusiness, setCreateNewBusiness] = useRecoilState(createNewBusinessAtom);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const { t } = useTranslation();

  const showMessage = function (message) {
    notification.open({
      message: '',
      description: (
        <Comment
          author={<span>Grocefy</span>}
          avatar={<Avatar src={Grocefy} alt="grocefy" />}
          content={
            <p className="text-sm">
              {message}
            </p>
          }
          datetime={
            <Tooltip title={moment().format('YYYY-MM-DD HH:mm:ss')}>
              <span>{moment().fromNow()}</span>
            </Tooltip>
          }
        />
      ),
    });
  };

  const submitBusiness = function () {
    setLoading(true);
    api.post(
      'businesses/new',
      data
    )
      .then((response) => {
        setLoading(false);
        if (response.data.success) {
          setCreateNewBusiness(false);
        } else {
          showMessage(response.data.error);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  useEffect(() => {
    setData({
      minimumDeliveryOrderAmount: 0,
      minimumPickupOrderAmount: 0,
      allowPickup: false,
      allowDelivery: false
    });
  }, []);

  return (
    <Drawer
      title={t('create_new_business')}
      placement="right"
      closable
      onClose={() => setCreateNewBusiness(false)}
      open={createNewBusiness}
      width={400}
    >
      <div style={{ marginBottom: 10 }}>
        <strong>{t('owner_email')}:</strong>
        <Input
          disabled={loading}
          onChange={(obj) => {
            const temp = JSON.parse(JSON.stringify(data));
            temp.ownerEmail = obj.target.value;
            setData(temp);
          }}
          value={data?.ownerEmail}
        />
      </div>
      <div style={{ marginBottom: 10 }}>
        <strong>{t('store_name')}:</strong>
        <Input
          disabled={loading}
          onChange={(obj) => {
            const temp = JSON.parse(JSON.stringify(data));
            temp.name = obj.target.value;
            setData(temp);
          }}
          value={data?.name}
        />
      </div>
      <div style={{ marginBottom: 10 }}>
        <strong>{t('description')}:</strong>
        <Input
          disabled={loading}
          onChange={(obj) => {
            const temp = JSON.parse(JSON.stringify(data));
            temp.description = obj.target.value;
            setData(temp);
          }}
          value={data?.description}
        />
      </div>
      <div style={{ marginBottom: 10 }}>
        <strong>{t('address_line_1')}</strong>
        <Input
          disabled={loading}
          onChange={(obj) => {
            const temp = JSON.parse(JSON.stringify(data));
            temp.address1 = obj.target.value;
            setData(temp);
          }}
          value={data?.address1}
        />
      </div>
      <div style={{ marginBottom: 10 }}>
        <strong>{t('address_line_2')}:</strong>
        <Input
          disabled={loading}
          onChange={(obj) => {
            const temp = JSON.parse(JSON.stringify(data));
            temp.address2 = obj.target.value;
            setData(temp);
          }}
          value={data?.address2}
        />
      </div>
      <div style={{ marginBottom: 10 }}>
        <strong>{t('city')}:</strong>
        <Input
          disabled={loading}
          onChange={(obj) => {
            const temp = JSON.parse(JSON.stringify(data));
            temp.city = obj.target.value;
            setData(temp);
          }}
          value={data?.city}
        />
      </div>
      <div style={{ marginBottom: 10 }}>
        <strong>{t('lat')}:</strong>
        <Input
          disabled={loading}
          onChange={(obj) => {
            const temp = JSON.parse(JSON.stringify(data));
            temp.lat = obj.target.value;
            setData(temp);
          }}
          value={data?.lat}
        />
      </div>
      <div style={{ marginBottom: 10 }}>
        <strong>{t('lon')}:</strong>
        <Input
          disabled={loading}
          onChange={(obj) => {
            const temp = JSON.parse(JSON.stringify(data));
            temp.lon = obj.target.value;
            setData(temp);
          }}
          value={data?.lon}
        />
      </div>
      <div style={{ marginBottom: 10 }}>
        <strong>{t('is_beta')}:</strong>
        <Switch
          style={{ marginLeft: 10 }}
          checked={data?.isBeta}
          disabled={loading}
          onChange={(checked) => {
            const temp = JSON.parse(JSON.stringify(data));
            temp.isBeta = checked;
            setData(temp);
          }}
        />
      </div>
      <div style={{ marginBottom: 10 }}>
        <strong>{t('allow_pickup')}?:</strong>
        <Switch
          style={{ marginLeft: 10 }}
          checked={data?.allowPickup}
          disabled={loading}
          onChange={(checked) => {
            const temp = JSON.parse(JSON.stringify(data));
            temp.allowPickup = checked;
            setData(temp);
          }}
        />
      </div>
      <div
        style={{ marginBottom: 10 }}
        hidden={!data?.allowPickup}
      >
        <strong>{t('minimum_pickup_allowed')}:</strong>
        <br />
        <InputNumber
          disabled={loading}
          min={0}
          onChange={(obj) => {
            const temp = JSON.parse(JSON.stringify(data));
            temp.minimumPickupOrderAmount = obj;
            setData(temp);
          }}
          value={data?.minimumPickupOrderAmount}
        />
      </div>
      <div style={{ marginBottom: 10 }}>
        <strong>{t('allow_delivery')}?:</strong>
        <Switch
          style={{ marginLeft: 10 }}
          checked={data?.allowDelivery}
          disabled={loading}
          onChange={(checked) => {
            const temp = JSON.parse(JSON.stringify(data));
            temp.allowDelivery = checked;
            setData(temp);
          }}
        />
      </div>
      <div
        style={{ marginBottom: 10 }}
        hidden={!data?.allowDelivery}
      >
        <strong>{t('minimum_delivery_allowed')}:</strong>
        <br />
        <InputNumber
          disabled={loading}
          min={0}
          onChange={(obj) => {
            const temp = JSON.parse(JSON.stringify(data));
            temp.minimumDeliveryOrderAmount = obj;
            setData(temp);
          }}
          value={data?.minimumDeliveryOrderAmount}
        />
      </div>
      <div
        style={{
          float: 'right',
          marginTop: '10px'
        }}
      >
        <Button
          style={{
            marginLeft: '10px'
          }}
          disabled={loading}
          type="primary"
          onClick={() => { submitBusiness(); }}
        >
          {t('create')}
        </Button>
      </div>
    </Drawer>
  );
}

export default CreateNewBusiness;
