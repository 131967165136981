import React from 'react';
import { useTranslation } from 'react-i18next';
import { Tag, Select } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import { CheckCircleOutlined, SyncOutlined } from '@ant-design/icons';
import { useRecoilState, useRecoilValue, } from 'recoil';
import {
  businessAtom, dashboardOptionAtom,
} from '../../../../atoms/Atoms';

function Header() {
  const store = useRecoilValue(businessAtom);
  const { Option } = Select;
  const [dashboardOption, setDashboardOption] = useRecoilState(dashboardOptionAtom);
  const { t } = useTranslation();

  return (
    <PageHeader
      className="mb-4 px-0"
      title={t('store_dashboard')}
      tags={
        <Tag
          color="#2db7f5"
          icon={store ? <CheckCircleOutlined /> : <SyncOutlined spin />}
        >
          {store ? store.name : `${t('loading')}...`}
        </Tag>
      }
      extra={
        <Select
          defaultValue={0}
          style={{ width: 120 }}
          onChange={(obj) => setDashboardOption(obj)}
        >
          <Option value={0}>{t('insights')}</Option>
          <Option value={1}>{t('trends')}</Option>
          <Option value={2}>{t('year_to_date')}</Option>
          <Option value={3}>{t('lifetime')}</Option>
          <Option value={4}>{t('customers')}</Option>
        </Select>
      }
    />
  );
}

export default Header;
