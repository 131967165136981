import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Table, Image } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import { useSetRecoilState, useRecoilState } from 'recoil';
import { useMount } from 'react-use';
import {
  editCardRestrictionAtom,
  selectedCardRestrictionAtom,
  reloadCardRestrictionsAtom
} from '../../../atoms/Atoms';
import EditCardRestriction from './shared-components/EditCardRestriction';
import api from '../../../api/api';

function CardRestrictions() {
  const { t } = useTranslation();
  const setSelectedCardRestriction = useSetRecoilState(selectedCardRestrictionAtom);
  const setEditCardRestriction = useSetRecoilState(editCardRestrictionAtom);
  const [reload, setReload] = useRecoilState(reloadCardRestrictionsAtom);
  const [loading, setLoading] = useState(false);
  const [cardRestrictions, setCardRestrictions] = useState([]);

  function getCardRestrictions() {
    setLoading(true);
    api
      .get('admin/card/restrictions')
      .then((response) => {
        setLoading(false);
        setCardRestrictions(response.data.data.results);
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  }

  useMount(() => {
    getCardRestrictions();
  });

  useEffect(() => {
    if (reload) {
      getCardRestrictions();
      setReload(false);
    }
  }, [reload]);

  return (
    <>
      <PageHeader
        className="mb-4 px-0"
        title={t('card_restrictions')}
        extra={[
          <Button
            id="reload"
            type="primary"
            key="reload"
            size="small"
            onClick={() => setReload(true)}
          >
            {t('reload')}
          </Button>,
          <Button
            type="primary"
            key="create"
            size="small"
            onClick={() => {
              setSelectedCardRestriction({});
              setEditCardRestriction(true);
            }}
          >
            {t('create_new')}
          </Button>
        ]}
      />
      <Table
        pagination={false}
        size="small"
        bordered
        rowKey="id"
        loading={loading}
        columns={[
          {
            title: t('image'),
            key: 'image',
            align: 'center',
            dataIndex: 'imageUrl',
            className: 'text-xs',
            render: (row) => (
              <Image
                alt="logo"
                width={60}
                src={row}
                className="cursor-pointer"
              />
            ),
          },
          {
            title: t('name'),
            key: 'name',
            align: 'center',
            dataIndex: 'name',
            className: 'text-xs',
            render: (row) => (
              <span>{row}</span>
            ),
          },
          {
            title: t('is_active'),
            key: 'isActive',
            align: 'center',
            dataIndex: 'isActive',
            className: 'text-xs',
            render: (row) => (
              <span>{row ? t('yes') : 'No'}</span>
            ),
          },
          {
            title: t('block_register'),
            key: 'blockRegister',
            align: 'center',
            dataIndex: 'blockRegister',
            className: 'text-xs',
            render: (row) => (
              <span>{row ? t('yes') : 'No'}</span>
            ),
          },
          {
            title: t('starts_with'),
            key: 'active',
            align: 'center',
            dataIndex: 'startsWith',
            className: 'text-xs',
            render: (row) => (
              <span>{row}</span>
            ),
          },
          {
            title: t('tax_excluded'),
            key: 'name',
            align: 'center',
            dataIndex: 'taxExcluded',
            className: 'text-xs',
            render: (row) => (
              <span>{row ? t('yes') : 'No'}</span>
            ),
          },
          {
            title: t('allow_non_food'),
            key: 'name',
            align: 'center',
            dataIndex: 'allowNonFoodItems',
            className: 'text-xs',
            render: (row) => (
              <span>{row ? t('yes') : 'No'}</span>
            ),
          },
          {
            title: t('edit'),
            key: 'edit',
            align: 'center',
            className: 'text-xs',
            render: (text, record) => (
              <Button
                size="small"
                onClick={() => {
                  setSelectedCardRestriction(record);
                  setEditCardRestriction(true);
                }}
              >
                {t('edit')}
              </Button>
            ),
          },
        ]}
        dataSource={cardRestrictions}
      />
      <EditCardRestriction />
    </>
  );
}
export default CardRestrictions;
