import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { useRecoilValueLoadable, useRecoilValue } from 'recoil';
import { useHistory } from 'react-router-dom';
import {
  Row,
  Col,
  Select,
  Button,
  Input,
  notification,
  Avatar,
  Tooltip,
  Table,
  Modal
} from 'antd';
import { Comment } from '@ant-design/compatible';
import { PageHeader } from '@ant-design/pro-layout';
import {
  FilterOutlined
} from '@ant-design/icons';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import Grocefy from '../../../assets/images/grocefyLogoAlone.png';
import { profileAtom, timezoneAtom } from '../../../atoms/Atoms';
import api from '../../../api/api';
import { renderShortDateTime } from '../../utils/functions';

function ExternalContacts() {
  const { t } = useTranslation();
  const { Option } = Select;
  const history = useHistory();
  const timezone = useRecoilValue(timezoneAtom);
  const userProfile = useRecoilValueLoadable(profileAtom);
  const [channels, setChannels] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [contactsCount, setContactsCount] = useState(0);
  const [loadingContacts, setLoadingContacts] = useState(false);
  const [loadingChannels, setLoadingChannels] = useState(false);
  const [selectedChannel, setSelectedChannel] = useState('');
  const [selectedStatus, setSelectedStatus] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const [size, setSize] = useState(20);
  const [selectedContact, setSelectedContact] = useState(null);
  const [updatingContact, setUpdatingContact] = useState(false);

  const statuses = [
    { name: t('any'), tag: 0 },
    { name: t('pending'), tag: 1 },
    { name: t('in_progress'), tag: 2 },
    { name: t('waiting_on_customer'), tag: 3 },
    { name: t('resolved'), tag: 4 }
  ];

  const isAdmin =
    userProfile.contents &&
    userProfile?.contents?.roles?.includes('SuperAdmin');
  const isCustomerService =
    isAdmin ||
    (userProfile?.contents &&
      (userProfile?.contents?.roles?.includes('CustomerSupportAdmin') ||
        userProfile?.contents?.roles?.includes('CustomerSupport')));
  const isStoreManager =
        isAdmin ||
        isCustomerService ||
        (userProfile?.contents &&
          (userProfile?.contents?.roles?.includes('StoreManager') ||
          userProfile?.contents?.roles?.includes('StoreAdmin')));

  if (!isAdmin && !isCustomerService && !isStoreManager) {
    history.push('/');
    return null;
  }

  function showMessage(message) {
    notification.open({
      message: '',
      description: (
        <Comment
          author={<span>Grocefy</span>}
          avatar={<Avatar src={Grocefy} alt="grocefy" />}
          content={
            <p className="text-sm">
              {message}
            </p>
          }
          datetime={
            <Tooltip title={moment().format('YYYY-MM-DD HH:mm:ss')}>
              <span>{moment().fromNow()}</span>
            </Tooltip>
          }
        />
      ),
    });
  }

  function mapStatus(status) {
    if (status === 0) {
      return t('pending');
    }
    if (status === 1) {
      return t('in_progrgess');
    }
    if (status === 1) {
      return t('waiting_on_customer');
    }
    return t('resolved');
  }

  function onTableChange(page, pageSize) {
    if (page - 1 !== currentPage) {
      setCurrentPage(page - 1);
    } else {
      setSize(pageSize);
    }
  }

  function getChannels() {
    setLoadingChannels(true);
    api
      .get('admin/externalcontacts/channels')
      .then((response) => {
        setChannels(response.data.data.results);
        setLoadingChannels(false);
      })
      .catch((error) => {
        console.error(error);
        setLoadingChannels(false);
      });
  }

  function search(q, p) {
    setLoadingContacts(true);
    const data = {
      Channel: selectedChannel,
      Query: q,
      Status: selectedStatus,
      Page: p,
      Size: size
    };
    api.post('admin/externalcontacts/search', data)
      .then((response) => {
        setContactsCount(response.data.data.count);
        setContacts(response.data.data.contacts);
        setLoadingContacts(false);
      })
      .catch((error) => {
        console.error(error);
        setLoadingContacts(false);
      });
  }

  function updateContact(status) {
    setUpdatingContact(true);
    api
      .post('admin/externalcontacts/update', {
        ExternalContactId: selectedContact?.id,
        Status: status
      })
      .then((response) => {
        setUpdatingContact(false);
        if (response.data.error) {
          showMessage(response.data.error);
        } else {
          setSelectedContact(null);
          search(searchTerm, currentPage);
        }
      })
      .catch((error) => {
        console.error(error);
        setUpdatingContact(false);
      });
  }

  const onSearch = async (value) => {
    setSearchTerm(value);
    setCurrentPage(0);
    search(value, 0);
  };

  useEffect(() => {
    getChannels();
  }, []);

  useEffect(() => {
    setCurrentPage(0);
  }, [size]);

  useEffect(() => {
    search(searchTerm, currentPage);
  }, [selectedChannel, selectedStatus, currentPage]);

  return (
    <div>
      <PageHeader title={t('external_contacts')} />
      <Input.Search
        key="search"
        allowClear
        disabled={loadingChannels}
        loading={loadingContacts}
        enterButton={t('search')}
        onSearch={onSearch}
      />
      <div className="flex flex-row justify-between">
        <Row className="my-4 flex space-between">
          <Col>
            <div className="mr-2">
              <FilterOutlined className="mr-2" />
              <strong>{t('channels')}</strong>
              <br />
              <Select
                style={{ width: '160px' }}
                value={selectedChannel}
                disabled={loadingChannels || loadingContacts}
                onChange={(value) => setSelectedChannel(value)}
              >
                <Option
                  key=""
                  value=""
                  className="text-xs flex items-center"
                >
                  {t('any')}
                </Option>
                {_.map(channels, (channel) => (
                  <Option
                    key={channel}
                    value={channel}
                    className="text-xs flex items-center"
                  >
                    {channel}
                  </Option>
                ))}
              </Select>
            </div>
          </Col>
          <Col>
            <div className="mr-2">
              <FilterOutlined className="mr-2" />
              <strong>{t('status')}</strong>
              <br />
              <Select
                style={{ width: '160px' }}
                value={selectedStatus}
                disabled={loadingChannels || loadingContacts}
                onChange={(value) => setSelectedStatus(value)}
              >
                {_.map(statuses, (filter) => (
                  <Option
                    key={filter.tag}
                    value={filter.tag}
                    className="text-xs flex items-center"
                  >
                    {filter.name}
                  </Option>
                ))}
              </Select>
            </div>
          </Col>
        </Row>
      </div>
      <Table
        className="pt-4"
        loading={loadingContacts}
        bordered
        scroll={{ x: 500 }}
        pagination={{
          pageSize: size,
          showSizeChanger: true,
          defaultCurrent: 0,
          current: currentPage + 1,
          total: contactsCount,
          onChange: (p, s) => onTableChange(p, s)
        }}
        dataSource={contacts}
        columns={[
          {
            title: t('contacted_on'),
            dataIndex: 'createdAt',
            key: 'createdAt',
            align: 'left',
            className: 'text-xs',
            render: (text) => (
              <span>{renderShortDateTime(text, timezone)}</span>
            ),
          },
          {
            title: t('first_name'),
            dataIndex: 'firstName',
            key: 'firstName',
            align: 'left',
            className: 'text-xs',
            render: (text) => (
              <span>{text}</span>
            ),
          },
          {
            title: t('last_name'),
            dataIndex: 'lastName',
            key: 'lastName',
            align: 'left',
            className: 'text-xs',
            render: (text) => (
              <span>{text}</span>
            ),
          },
          {
            title: t('phone'),
            dataIndex: 'phoneNumber',
            key: 'phoneNumber',
            align: 'left',
            className: 'text-xs',
            render: (text) => (
              <span>{text}</span>
            ),
          },
          {
            title: t('channel'),
            dataIndex: 'channel',
            key: 'channel',
            align: 'left',
            className: 'text-xs',
            render: (text) => (
              <span>{text}</span>
            ),
          },
          {
            title: t('status'),
            dataIndex: 'status',
            key: 'status',
            align: 'left',
            className: 'text-xs',
            render: (text) => (
              <span>{mapStatus(text)}</span>
            ),
          },
          {
            title: t('action'),
            key: 'action',
            align: 'left',
            className: 'text-xs',
            render: (row) => (
              <Button
                size="small"
                type="primary"
                onClick={() => setSelectedContact(row)}
              >
                {t('view')}
              </Button>
            ),
          }
        ]}
      />
      <Modal
        title={t('external_contact')}
        open={selectedContact}
        onCancel={() => setSelectedContact(null)}
        footer={[
          <Button
            key="ok"
            type="primary"
            disabled={updatingContact}
            onClick={() => setSelectedContact(null)}
          >
            {t('close')}
          </Button>
        ]}
      >
        <div>
          <Row>
            <Col span={24}>
              <p><strong>{t('channel')}: </strong>{selectedContact?.channel}</p>
            </Col>
            <Col span={12}>
              <p><strong>{t('first_name')}: </strong>{selectedContact?.firstName}</p>
              <p><strong>{t('email')}: </strong>{selectedContact?.email}</p>
            </Col>
            <Col span={12}>
              <p><strong>{t('last_name')}: </strong>{selectedContact?.lastName}</p>
              <p><strong>{t('phone')}: </strong>{selectedContact?.phoneNumber}</p>
            </Col>
            <Col span={24}>
              <p><strong>{t('contacted_on')}: </strong>{renderShortDateTime(selectedContact?.createdAt, timezone)}</p>
            </Col>
            <Col span={24}>
              <p>
                <strong>{t('registered_on')}: </strong>
                {selectedContact?.registeredOn &&
                  renderShortDateTime(selectedContact?.registeredOn, timezone)}
                {!selectedContact?.registeredOn && 'N/A'}
              </p>
            </Col>
            <Col span={24}>
              <p><strong>{t('reason')}: </strong>{selectedContact?.reason}</p>
            </Col>
          </Row>
          <Row className="pt-4">
            <Col span={24}>
              <strong>{t('change_status')}</strong>
            </Col>
            <Col
              span={8}
              className={`${selectedContact?.status !== 0 ? '' : 'hidden'}`}
            >
              <Button
                size="small"
                type="primary"
                loading={updatingContact}
                style={{ width: '98%' }}
                onClick={() => updateContact(0)}
              >
                {t('pending')}
              </Button>
            </Col>
            <Col
              span={8}
              className={`${selectedContact?.status !== 1 ? '' : 'hidden'}`}
            >
              <Button
                size="small"
                type="primary"
                loading={updatingContact}
                style={{ width: '98%' }}
                onClick={() => updateContact(1)}
              >
                {t('in_progress')}
              </Button>
            </Col>
            <Col
              span={8}
              className={`${selectedContact?.status !== 2 ? '' : 'hidden'}`}
            >
              <Button
                size="small"
                style={{ width: '98%' }}
                loading={updatingContact}
                type="primary"
                onClick={() => updateContact(2)}
              >
                {t('waiting_on_customer')}
              </Button>
            </Col>
            <Col
              span={8}
              className={`${selectedContact?.status !== 3 ? '' : 'hidden'}`}
            >
              <Button
                style={{ width: '98%' }}
                size="small"
                loading={updatingContact}
                type="primary"
                onClick={() => updateContact(3)}
              >
                {t('resolved')}
              </Button>
            </Col>
          </Row>
        </div>
      </Modal>
    </div>
  );
}

export default ExternalContacts;
