import { Divider, Typography, Spin, Button, Row, Col } from 'antd';
import { useRecoilValue } from 'recoil';
import moment from 'moment';
import { SyncOutlined } from '@ant-design/icons';
import React, { useState, useEffect } from 'react';
import _ from 'lodash';

import { Bar, Line } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
import { mapPaymentType, thousand } from '../../../utils/functions';
import api from '../../../../api/api';
import { timezoneAtom } from '../../../../atoms/Atoms';

function YearToDate() {
  const [loadingCustomers, setLoadingCustomers] = useState(false);
  const [loadingYtd, setLoadingYtd] = useState(false);
  const [loadingOrders, setLoadingOrders] = useState(false);
  const [ytd, setYtd] = useState(null);
  const [data, setData] = useState(null);
  const [customerData, setCustomerData] = useState(null);
  const [monthly, setMonthly] = useState(null);
  const [reload, setReload] = useState(false);
  let cacheMessage = 'Data Cache 1 hour(s)';
  const { t } = useTranslation();
  const timezone = useRecoilValue(timezoneAtom);

  function mapData(monthData) {
    const mapped = _.map(monthData, (p) => ({
      x: moment(`${p.date}`)
        .tz(timezone)
        .format('MMM'),
      y: p.orderCount,
      z: moment(`${p.date}`)
        .tz(timezone)
        .format('M'),
    }));
    return _.sortBy(mapped, (s) => s.z, 'asc');
  }

  function getCustomerData() {
    setLoadingCustomers(true);
    api
      .get('analytics/customers/5/1')
      .then((response) => {
        setLoadingCustomers(false);
        setCustomerData(response.data.data);
      })
      .catch((error) => {
        console.error(error);
        setLoadingCustomers(false);
      });
  }

  function getYtd() {
    setLoadingYtd(true);
    api
      .get('analytics/ytd')
      .then((response) => {
        setLoadingYtd(false);
        setYtd(response.data.data);
        setMonthly(mapData(response.data.data.ordersByMonth));
      })
      .catch((error) => {
        console.error(error);
        setLoadingYtd(false);
      });
  }

  function getData() {
    setLoadingOrders(true);
    api
      .get('analytics/orders/5/1')
      .then((response) => {
        cacheMessage = response.data.message;
        setLoadingOrders(false);
        setData(response.data.data);
      })
      .catch((error) => {
        console.error(error);
        setLoadingOrders(false);
      });
  }

  const labels = _.map(monthly, (s) => s.x);

  const graphData = {
    labels,
    datasets: [
      {
        label: t('order_count'),
        data: _.map(monthly, (s) => s.y),
        backgroundColor: 'rgba(53, 162, 235, 0.2)',
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Monthly Sales',
      },
      plugins: {
        datalabels: {
          anchor: 'end',
          color: 'white',
          backgroundColor: '#a78bfa',
          borderRadius: '5',
          labels: {
            value: {}
          }
        },
      },
    }
  };

  useEffect(() => {
    getYtd();
    getData();
    getCustomerData();
  }, []);

  useEffect(() => {
    if (reload) {
      getYtd();
      getData();
      getCustomerData();
    }
  }, [reload]);

  return (
    <div>
      <div className="flex flex-col" style={{ float: 'right' }}>
        <Button
          className="mb-4"
          size="small"
          type="primary"
          loading={loadingCustomers || loadingOrders || loadingYtd}
          icon={<SyncOutlined spin={loadingCustomers || loadingOrders || loadingYtd} />}
          onClick={() => {
            setReload(!reload);
          }}
        >
          {t('reload')}
        </Button>
      </div>

      <div>
        <h3 className="text-lg leading-6 font-medium text-gray-900">{t('sales')}</h3>
        <Typography.Text type="secondary">
          {t('to_date_description')}
        </Typography.Text>
        <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-5">
          <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
            <Spin spinning={loadingYtd}>
              <dt className="text-sm font-medium text-gray-500 truncate">{t('revenue')}</dt>
              <dd className="mt-1 text-3xl font-semibold text-gray-900">{ytd && `$${thousand(ytd.ytdSales)}`}</dd>
            </Spin>
          </div>
          <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
            <Spin spinning={loadingOrders}>
              <dt className="text-sm font-medium text-gray-500 truncate">{t('total_pickup_sales')}</dt>
              <dd className="mt-1 text-3xl font-semibold text-gray-900">${data && data.totalPickupSales.toFixed().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                <div className="inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium md:mt-2 lg:mt-0" style={{ float: 'right', marginTop: '30px' }}>
                  {data && ytd && ((data.totalPickupSales / ytd?.ytdSales) * 100).toFixed(2) }%
                </div>
              </dd>
            </Spin>
          </div>
          <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
            <Spin spinning={loadingOrders}>
              <dt className="text-sm font-medium text-gray-500 truncate">{t('total_delivery_sales')}</dt>
              <dd className="mt-1 text-3xl font-semibold text-gray-900">${data && data.totalDeliverySales.toFixed().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                <div className="inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium md:mt-2 lg:mt-0" style={{ float: 'right', marginTop: '30px' }}>
                  {data && ytd && ((data.totalDeliverySales / ytd?.ytdSales) * 100).toFixed(2) }%
                </div>
              </dd>
            </Spin>
          </div>
          <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
            <Spin spinning={loadingYtd}>
              <dt className="text-sm font-medium text-gray-500 truncate">{t('pickup_average')}</dt>
              <dd className="mt-1 text-3xl font-semibold text-gray-900">${ytd && `${thousand(ytd.pickupAverage)}`}</dd>
            </Spin>
          </div>
          <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
            <Spin spinning={loadingYtd}>
              <dt className="text-sm font-medium text-gray-500 truncate">{t('delivery_average')}</dt>
              <dd className="mt-1 text-3xl font-semibold text-gray-900">${ytd && `${thousand(ytd.deliveryAverage)}`}</dd>
            </Spin>
          </div>
        </dl>
      </div>
      <br />
      <div>
        <h3 className="text-lg leading-6 font-medium text-gray-900">{t('orders')}</h3>
        <Typography.Text type="secondary">
          {t('to_date_description')}
        </Typography.Text>
        <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
          <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
            <Spin spinning={loadingYtd}>
              <dt className="text-sm font-medium text-gray-500 truncate">{t('orders')}</dt>
              <dd className="mt-1 text-3xl font-semibold text-gray-900">{ytd && `${thousand(ytd.ytdCount)}`}</dd>
            </Spin>
          </div>
          <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
            <Spin spinning={loadingOrders}>
              <dt className="text-sm font-medium text-gray-500 truncate">{t('pickup_orders')}</dt>
              <dd className="mt-1 text-3xl font-semibold text-gray-900">{data && data.totalPickups.toLocaleString()}
                <div className="inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium md:mt-2 lg:mt-0" style={{ float: 'right', marginTop: '30px' }}>
                  {data && ytd && ((data.totalPickups / ytd?.ytdCount) * 100).toFixed(2) }%
                </div>
              </dd>
            </Spin>
          </div>
          <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
            <Spin spinning={loadingOrders}>
              <dt className="text-sm font-medium text-gray-500 truncate">{t('delivery_orders')}</dt>
              <dd className="mt-1 text-3xl font-semibold text-gray-900">{data && data.totalDeliveries.toLocaleString()}
                <div className="inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium md:mt-2 lg:mt-0" style={{ float: 'right', marginTop: '30px' }}>
                  {data && ytd && ((data.totalDeliveries / ytd?.ytdCount) * 100).toFixed(2) }%
                </div>
              </dd>
            </Spin>
          </div>
        </dl>
      </div>
      <br />
      <div>
        <h3 className="text-lg leading-6 font-medium text-gray-900">{t('devices')}</h3>
        <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-4">
          <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
            <Spin spinning={loadingOrders}>
              <dt className="text-sm font-medium text-gray-500 truncate">{t('mobile_orders')}</dt>
              <dd className="mt-1 text-3xl font-semibold text-gray-900">{data && data?.orderDeviceAnalytics?.mobileOrdersCount?.toLocaleString()}
                <div className="inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium md:mt-2 lg:mt-0" style={{ float: 'right', marginTop: '30px' }}>
                  {data && (data.orderDeviceAnalytics.mobileOrdersPercentage * 100).toFixed(2) }%
                </div>
              </dd>
            </Spin>
          </div>
          <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
            <Spin spinning={loadingOrders}>
              <dt className="text-sm font-medium text-gray-500 truncate">{t('mobile_average')}</dt>
              <dd className="mt-1 text-3xl font-semibold text-gray-900">
                ${data && data.orderDeviceAnalytics.mobileOrderAverage.toFixed(2) }
              </dd>
            </Spin>
          </div>
          <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
            <Spin spinning={loadingOrders}>
              <dt className="text-sm font-medium text-gray-500 truncate">{t('web_orders')}</dt>
              <dd className="mt-1 text-3xl font-semibold text-gray-900">{data && data?.orderDeviceAnalytics?.webOrdersCount?.toLocaleString()}
                <div className="inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium md:mt-2 lg:mt-0" style={{ float: 'right', marginTop: '30px' }}>
                  {data && (data.orderDeviceAnalytics.webOrdersPercentage * 100).toFixed(2) }%
                </div>
              </dd>
            </Spin>
          </div>
          <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
            <Spin spinning={loadingOrders}>
              <dt className="text-sm font-medium text-gray-500 truncate">{t('web_average')}</dt>
              <dd className="mt-1 text-3xl font-semibold text-gray-900">
                ${data && data.orderDeviceAnalytics.webOrderAverage.toFixed(2) }
              </dd>
            </Spin>
          </div>
        </dl>
      </div>
      <br />
      <div>
        <h3 className="text-lg leading-6 font-medium text-gray-900">{t('customers')}</h3>
        <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-4">
          <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
            <Spin spinning={loadingCustomers}>
              <dt className="text-sm font-medium text-gray-500 truncate">{t('registered')}</dt>
              <dd className="mt-1 text-3xl font-semibold text-gray-900">{customerData && customerData.totalUsers.toFixed().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</dd>
            </Spin>
          </div>
          <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
            <Spin spinning={loadingOrders}>
              <dt className="text-sm font-medium text-gray-500 truncate">{t('at_least_one_card')}</dt>
              <dd className="mt-1 text-3xl font-semibold text-gray-900">{customerData && customerData?.totalUsersWithAtleastOneCard?.toLocaleString()}
                <div className="inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium md:mt-2 lg:mt-0" style={{ float: 'right', marginTop: '30px' }}>
                  {data &&
                    ((customerData?.totalUsersWithAtleastOneCard /
                    customerData?.totalUsers) * 100).toFixed(2)}%
                </div>
              </dd>
            </Spin>
          </div>
          <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
            <Spin spinning={loadingOrders}>
              <dt className="text-sm font-medium text-gray-500 truncate">{t('at_least_one_card')}</dt>
              <dd className="mt-1 text-3xl font-semibold text-gray-900">{customerData && customerData?.totalUsersWithAtleastOneLocation?.toLocaleString()}
                <div className="inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium md:mt-2 lg:mt-0" style={{ float: 'right', marginTop: '30px' }}>
                  {data &&
                    ((customerData?.totalUsersWithAtleastOneLocation /
                    customerData?.totalUsers) * 100).toFixed(2)}%
                </div>
              </dd>
            </Spin>
          </div>
          <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
            <Spin spinning={loadingOrders}>
              <dt className="text-sm font-medium text-gray-500 truncate">{t('at_least_one_card')}</dt>
              <dd className="mt-1 text-3xl font-semibold text-gray-900">{customerData && customerData?.totalUsersWithAtleastOneOrder?.toLocaleString()}
                <div className="inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium md:mt-2 lg:mt-0" style={{ float: 'right', marginTop: '30px' }}>
                  {data &&
                    ((customerData?.totalUsersWithAtleastOneOrder /
                    customerData?.totalUsers) * 100).toFixed(2)}%
                </div>
              </dd>
            </Spin>
          </div>
        </dl>
      </div>
      <Divider hidden />
      <div hidden style={{ align: 'center' }} className="mb-12">
        <div>
          <h3 className="text-lg leading-6 font-medium text-gray-900">{t('payments')}</h3>
          <Typography.Text type="secondary">
            {t('payment_content')}
          </Typography.Text>
          <Spin spinning={loadingOrders}>
            <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-7">
              {data && data.ordersCreated?.length > 0 && _.map(_.orderBy(data.ordersCreated[0].paymentTypes, ['count'], ['desc']), (item) => (
                <div key={item.type} className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
                  <dt className="text-sm font-medium text-gray-500 truncate">{t(mapPaymentType(item.type))}</dt>
                  <dd className="mt-1 text-2xl font-semibold text-gray-900">{item.count.toLocaleString()}
                  </dd>
                </div>
              ))}
            </dl>
          </Spin>
        </div>
      </div>
      <br />
      <div>
        <Row>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Bar
              height={250}
              data={graphData}
              options={options}
            />
          </Col>
        </Row>
      </div>
      <Divider hidden />
      <div hidden className="text-xs font-light text-gray-400 truncate">* {data && cacheMessage}</div>
    </div>
  );
}

export default YearToDate;
