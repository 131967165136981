import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Table, Image } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import { useSetRecoilState, useRecoilState } from 'recoil';
import { useMount } from 'react-use';
import {
  editBookingHostAtom,
  selectedBookingHostAtom,
  reloadBookingHostAtom
} from '../../../../atoms/Atoms';
import EditHosts from './shared-components/EditHosts';
import bookingApi from '../../../../api/bookingApi';

function Hosts() {
  const { t } = useTranslation();
  const setSelectedHost = useSetRecoilState(selectedBookingHostAtom);
  const setEditHost = useSetRecoilState(editBookingHostAtom);
  const [reload, setReload] = useRecoilState(reloadBookingHostAtom);
  const [loading, setLoading] = useState(false);
  const [hosts, setHosts] = useState([]);

  function getHosts() {
    setLoading(true);
    bookingApi
      .get('hosts')
      .then((response) => {
        setLoading(false);
        setHosts(response.data.data);
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  }

  useMount(() => {
    getHosts();
  });

  useEffect(() => {
    if (reload) {
      getHosts();
      setReload(false);
    }
  }, [reload]);

  return (
    <>
      <PageHeader
        className="mb-4 px-0"
        title={t('Hosts')}
        extra={[
          <Button
            id="reload"
            type="primary"
            key="reload"
            size="small"
            onClick={() => setReload(true)}
          >
            {t('reload')}
          </Button>,
          <Button
            type="primary"
            key="create"
            size="small"
            onClick={() => {
              setSelectedHost({});
              setEditHost(true);
            }}
          >
            {t('create_new')}
          </Button>
        ]}
      />
      <Table
        pagination={false}
        size="small"
        bordered
        rowKey="id"
        scroll={{ x: 500 }}
        loading={loading}
        columns={[
          {
            title: t('image'),
            key: 'image',
            align: 'center',
            dataIndex: 'avatar',
            className: 'text-xs',
            render: (row) => (
              <>
                {row && (
                  <Image
                    alt="logo"
                    width={60}
                    src={row}
                    className="cursor-pointer"
                  />
                )}
              </>
            ),
          },
          {
            title: t('first_name'),
            key: 'firstName',
            align: 'center',
            dataIndex: 'firstName',
            className: 'text-xs',
            render: (row) => (
              <span>{row}</span>
            ),
          },
          {
            title: t('last_name'),
            key: 'lastName',
            align: 'center',
            dataIndex: 'lastName',
            className: 'text-xs',
            render: (row) => (
              <span>{row}</span>
            ),
          },
          {
            title: t('description'),
            key: 'description',
            align: 'center',
            dataIndex: 'description',
            className: 'text-xs',
            render: (row) => (
              <span>{row}</span>
            ),
          },
          {
            title: t('edit'),
            key: 'edit',
            align: 'center',
            className: 'text-xs',
            render: (text, record) => (
              <Button
                size="small"
                onClick={() => {
                  setSelectedHost(record);
                  setEditHost(true);
                }}
              >
                {t('edit')}
              </Button>
            ),
          },
        ]}
        dataSource={hosts}
      />
      <EditHosts />
    </>
  );
}
export default Hosts;
