import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { useRecoilState } from 'recoil';
import { notification, Modal, Avatar, Button, Select, Typography } from 'antd';
import { Comment } from '@ant-design/compatible';
import { changeCardAtom, selectedOrderAtom, reloadOrder } from '../../../atoms/Atoms';
import api from '../../../api/api';
import Grocefy from '../../../assets/images/grocefyLogoAlone.png';

function ChangeCard() {
  const [changeCard, setchangeCard] = useRecoilState(changeCardAtom);
  const [selectedOrder] = useRecoilState(selectedOrderAtom);
  const [reload, setReload] = useRecoilState(reloadOrder);
  const [loading, setLoading] = useState(false);
  const { Option } = Select;
  const [newValue, setNewValue] = useState(null);
  const [cards, setCards] = useState([]);
  const { t } = useTranslation();

  const closeModal = function () {
    if (!loading) {
      setchangeCard(false);
    }
  };

  const editClick = function () {
    setLoading(true);
    api
      .post(
        'orders/updatecard',
        {
          CardId: newValue,
          OrderId: selectedOrder.id
        }
      )
      .then((response) => {
        if (response && response.data.error) {
          console.error('Error Message: ', response.data.error);
          notification.open({
            message: 'Alert!',
            description: (
              <Comment
                author={<span>Grocefy</span>}
                avatar={<Avatar src={Grocefy} alt="grocefy" />}
                content={
                  <p className="text-sm">{response.data.error}</p>
                }
              />
            ),
          });
        } else if (
          response &&
          response.data.success &&
          response.data.message
        ) {
          notification.open({
            message: 'Success!',
            description: (
              <Comment
                author={<span>Grocefy</span>}
                avatar={<Avatar src={Grocefy} alt="grocefy" />}
                content={
                  <p className="text-sm">{response.data.message}</p>
                }
              />
            ),
          });
        }
        setchangeCard(false);
        setReload(true);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    if (changeCard && selectedOrder) {
      setCards([]);
      setLoading(true);
      api
        .get(`users/cards/${selectedOrder.customerId}`)
        .then((response) => {
          setLoading(false);
          setCards(response.data.data.results);
        })
        .catch((error) => {
          setLoading(false);
          console.error(error);
        });
    }
  }, [changeCard, selectedOrder]);

  return (
    <>
      {changeCard && (
        <Modal
          closable="true"
          centered="true"
          width={300}
          open={changeCard}
          onCancel={() => { setchangeCard(false); }}
          footer={[
            <Button
              key="close"
              type="primary"
              danger
              disabled={loading}
              onClick={() => closeModal()}
            >
              {t('close')}
            </Button>,
            <Button
              key="create"
              disabled={loading}
              type="primary"
              onClick={() => editClick()}
            >
              {t('change')}
            </Button>
          ]}
        >
          <div>
            <Typography.Title level={5}>{t('change_card')}</Typography.Title>
            <br />
            <Select
              style={{ width: 175 }}
              disabled={loading}
              defaultValue={`${t('select_card')}...`}
              onChange={(value) => setNewValue(value)}
            >
              {_.map(cards, (b) => (
                <Option
                  key={b.id}
                  value={b.id}
                  className="text-sm flex items-center"
                >
                  {b.nickname}
                </Option>
              ))}
            </Select>
          </div>
        </Modal>
      )}
    </>
  );
}

export default ChangeCard;
