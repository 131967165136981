import { Avatar, notification, Table, Tooltip, Typography } from 'antd';
import { Comment } from '@ant-design/compatible';
import { PageHeader } from '@ant-design/pro-layout';
import React, { useState } from 'react';
import moment from 'moment';
import { useMount } from 'react-use';
import { useTranslation } from 'react-i18next';
import api from '../../../api/api';
import Grocefy from '../../../assets/images/grocefyLogoAlone.png';

function CheckHealth() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [healthData, setHealthData] = useState(null);

  const showMessage = function (message) {
    notification.open({
      message: '',
      description: (
        <Comment
          author={<span>Grocefy</span>}
          avatar={<Avatar src={Grocefy} alt="grocefy" />}
          content={
            <p className="text-sm">
              {`${message}`}
            </p>
          }
          datetime={
            <Tooltip title={moment().format('YYYY-MM-DD HH:mm:ss')}>
              <span>{moment().fromNow()}</span>
            </Tooltip>
          }
        />
      ),
    });
  };

  const getHealth = function () {
    setLoading(true);
    api
      .get(
        'admin/health'
      )
      .then((response) => {
        if (response.data.success) {
          setLoading(false);
          setHealthData(response.data.data.results);
        } else {
          showMessage(response.data.error);
        }
      })
      .catch((error) => {
        setLoading(false);
        showMessage(error);
      });
  };

  useMount(() => {
    getHealth();
  });

  return (
    <>
      <PageHeader
        className="text-lg"
        title={t('check_health')}
      />
      <br />
      <Table
        size="small"
        bordered
        loading={loading}
        pagination={false}
        dataSource={healthData}
        columns={[
          {
            title: t('key'),
            dataIndex: 'key',
            key: 'key',
            align: 'left',
            className: 'text-xs',
            render: (row) => (
              <span>{row}</span>
            ),
          },
          {
            title: t('count'),
            dataIndex: 'count',
            key: 'count',
            align: 'center',
            className: 'text-xs',
            render: (text) => (
              <span>
                {text}
              </span>
            ),
          },
        ]}
        expandable={{
          expandRowByClick: false,
          expandedRowRender: (record) => (
            <div>
              <br />
              {record.key === 'FailedPayouts' ?
                <Table
                  style={{ margin: '10px' }}
                  size="small"
                  loading={loading}
                  dataSource={record.data}
                  columns={[
                    {
                      title: 'Id  ',
                      dataIndex: 'id',
                      key: 'id',
                      align: 'left',
                      className: 'text-xs',
                      render: (row) => (
                        <span>{row}</span>
                      ),
                    },
                    {
                      title: t('description'),
                      dataIndex: 'description',
                      key: 'description',
                      align: 'left',
                      className: 'text-xs',
                      render: (text) => (
                        <span>
                          {text}
                        </span>
                      ),
                    },
                  ]}
                />
                : null}
              {record.key === 'StoresWithNoBusinessAccount' ?
                <Table
                  style={{ margin: '10px' }}
                  size="small"
                  loading={loading}
                  dataSource={record.data}
                  columns={[
                    {
                      title: 'Id',
                      dataIndex: 'id',
                      key: 'id',
                      align: 'left',
                      className: 'text-xs',
                      render: (row) => (
                        <span>{row}</span>
                      ),
                    },
                  ]}
                />
                : null}
              {record.key === 'OrdersWithoutClosing' ?
                <Table
                  style={{ margin: '10px' }}
                  size="small"
                  loading={loading}
                  dataSource={record.data}
                  columns={[
                    {
                      title: t('store'),
                      dataIndex: 'name',
                      key: 'name',
                      align: 'left',
                      className: 'text-xs',
                      render: (row) => (
                        <span>{row}</span>
                      ),
                    },
                    {
                      title: t('confirmation_code'),
                      dataIndex: 'confirmationCode',
                      key: 'confirmationCode',
                      align: 'center',
                      className: 'text-xs',
                      render: (text) => (
                        <span>
                          <Typography.Text copyable>{text}</Typography.Text>
                        </span>
                      ),
                    },
                  ]}
                />
                : null}
              {record.key === 'StoresWithNoOpenHours' ||
              record.key === 'StoresWithNoService' ||
              record.key === 'DeliveryStoresWithoutActiveZones' ?
                <Table
                  style={{ margin: '10px' }}
                  size="small"
                  loading={loading}
                  dataSource={record.data}
                  columns={[
                    {
                      title: 'Id',
                      dataIndex: 'id',
                      key: 'id',
                      align: 'center',
                      className: 'text-xs',
                      render: (row) => (
                        <span>{row}</span>
                      ),
                    },
                    {
                      title: t('name'),
                      dataIndex: 'name',
                      key: 'name',
                      align: 'center',
                      className: 'text-xs',
                      render: (text) => (
                        <span>
                          {text}
                        </span>
                      ),
                    },
                  ]}
                />
                : null}
              <br />
            </div>
          ),
          rowExpandable: (record) => record.data,
        }}
      />
    </>
  );
}

export default CheckHealth;
