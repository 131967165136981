import React from 'react';
import { useSetRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import { InfoCircleFilled } from '@ant-design/icons';
import {
  showHelperInfoAtom
} from '../../../atoms/Atoms';
import envConfig from '../../../envConfig';
import Configurations from './shared-components/Configurations';

function AppConfigurations() {
  const { t } = useTranslation();
  const setShowHelperInfo = useSetRecoilState(showHelperInfoAtom);
  return (
    <>
      <Button
        className="float-right mt-4 mr-6"
        type="primary"
        onClick={() => setShowHelperInfo('appconfig')}
      >
        <InfoCircleFilled /> {t('help')}
      </Button>
      <br />
      <br />
      <Configurations
        title={t('app_config')}
        connectionString={envConfig.REACT_APP_APP_CONFIG}
      />
    </>
  );
}

export default AppConfigurations;
