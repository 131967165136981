import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { useRecoilValue, useSetRecoilState, useRecoilState, useRecoilValueLoadable } from 'recoil';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import {
  Input,
  Table,
  Typography,
  Descriptions,
  Avatar,
  Tooltip,
  Button,
  Space,
  Popover,
  Tabs,
  Select,
  Switch,
  Row,
  Col,
  Image,
  notification,
  Spin,
  Tag
} from 'antd';
import { Comment } from '@ant-design/compatible';
import { useTranslation } from 'react-i18next';
import {
  AimOutlined,
  BellTwoTone,
  CheckSquareTwoTone,
  ClockCircleTwoTone,
  FilterOutlined,
  MailTwoTone,
  OrderedListOutlined,
  RocketTwoTone,
  SearchOutlined,
  SortAscendingOutlined,
} from '@ant-design/icons';
import {
  orderEditAtom,
  ordersAtom,
  orderItemsAtom,
  timezoneAtom,
  profileAtom,
  selectedOrderAtom,
  reloadOrder,
} from '../../../../atoms/Atoms';
import {
  renderShortDateTime,
  renderDate,
  renderShortDate,
  renderShortDateHour,
  formatPhoneNumber,
  mapPaymentType,
  mapOrderType,
  mapOrderStatus,
  thousand,
  convertTaxRule,
} from '../../../utils/functions';
import { paymentOptionsSelector, businessesSelector } from '../../../../atoms/Selectors';
import Grocefy from '../../../../assets/images/grocefyLogoAlone.png';
import OrderActions from '../../../shared-components/drawers/OrderActions';
import api from '../../../../api/api';
import Panel from '../../../shared-components/panel/Panel';

function Orders() {
  const businesses = useRecoilValueLoadable(businessesSelector);
  const [orderHeader, setOrderHeader] = useState(null);
  const [loadingOrderHeader, setLoadingOrderHeader] = useState(false);
  const [selectedStore, setSelectedStore] = useState('');
  const { Option } = Select;
  const { t } = useTranslation();
  const [mappedBusiness, setMappedBusiness] = useState([]);

  const statusFilters = [
    { name: t('any'), tag: '' },
    { name: t('active'), tag: 'ACTIVE' },
    { name: t('placed'), tag: 'PLACED' },
    { name: t('processing'), tag: 'INPROCESS' },
    { name: t('processing_picking'), tag: 'PICKING' },
    { name: t('processing_packing'), tag: 'PACKING' },
    { name: t('waiting'), tag: 'WAITING' },
    { name: t('in_transit'), tag: 'INTRANSIT' },
    { name: t('completed'), tag: 'DISPATCHED' },
    { name: t('cancelled'), tag: 'CANCELLED' },
    { name: t('payment_failed'), tag: 'PAYMENTFAILED' },
    { name: t('pending'), tag: 'PENDING' },
    // { name: t('approved'), tag: 'APPROVED' }
  ];
  const timeFilters = [
    { name: t('any'), tag: '' },
    { name: t('past'), tag: 'PAST' },
    { name: t('yesterday'), tag: 'YESTERDAY' },
    { name: t('today'), tag: 'TODAY' },
    { name: t('tomorrow'), tag: 'TOMORROW' },
    { name: t('future'), tag: 'FUTURE' }
  ];
  const typeFilters = [
    { name: t('any'), tag: '' },
    { name: t('pickup'), tag: 'PICKUP' },
    { name: t('delivery'), tag: 'DELIVERY' },
    { name: t('in-Store'), tag: 'INSTORE' }
  ];

  const [reload, setReload] = useRecoilState(reloadOrder);
  const timezone = useRecoilValue(timezoneAtom);
  const paymentOptions = useRecoilValueLoadable(paymentOptionsSelector);
  const [editOrder, setEditOrder] = useRecoilState(orderEditAtom);
  const [selectedOrder, setSelectedOrder] = useRecoilState(selectedOrderAtom);
  const setOrder = useSetRecoilState(ordersAtom);
  const [statusFilter, setStatusFilter] = useState('ACTIVE');
  const [typeFilter, setTypeFilter] = useState('');
  const [paymentFilter, setPaymentFilter] = useState('');
  const [sorting, setSorting] = useState('asc');
  const [timeFilter, setTimeFilter] = useState('');
  const [loadingItems, setLoadingItems] = useState(false);
  const [orderItems, setOrderItems] = useRecoilState(orderItemsAtom);
  const [loadingLogs, setLoadingLogs] = useState(false);
  const [logs, setLogs] = useState(null);
  const [orders, setOrders] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [lastSearch, setLastSearch] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const [currentLogPage, setCurrentLogPage] = useState(0);
  const [size, setSize] = useState(20);
  const [totalItemsInSearch, setTotalItemsInSearch] = useState(0);
  const [totalRevenueInSearch, setTotalRevenueInSearch] = useState(0);
  const [onScreenTotal, setOnScreenTotal] = useState(0);

  const userProfile = useRecoilValueLoadable(profileAtom);
  const isAdmin =
    userProfile.contents &&
    userProfile?.contents?.roles?.includes('SuperAdmin');
  const isCustomerService =
    isAdmin ||
    (userProfile?.contents &&
      (userProfile?.contents?.roles?.includes('CustomerSupportAdmin') ||
        userProfile?.contents?.roles?.includes('CustomerSupport')));
  const isStoreManager =
        isAdmin ||
        isCustomerService ||
        (userProfile?.contents &&
          (userProfile?.contents?.roles?.includes('StoreManager') ||
          userProfile?.contents?.roles?.includes('StoreAdmin')));
  const history = useHistory();
  const [visibleSignature, setVisibleSignature] = useState(false);
  const [visibleSignatureImg, setVisibleSignatureImage] = useState(null);
  const [visibleEbt, setVisibleEbt] = useState(false);
  const [visibleEbtImg, setVisibleEbtImage] = useState(null);
  const [scaleStep, setScaleStep] = useState(0.5);

  if (!isAdmin && !isCustomerService && !isStoreManager) {
    history.push('/');
    return null;
  }

  function mapBusiness() {
    const mapped = _.map(businesses?.contents?.data?.data?.results, (p, index) => ({
      key: index,
      id: p.id,
      name: p.name,
      activeMenuId: p.activeMenuId,
      allowDelivery: p.allowDelivery,
      allowPaypalTransfer: p.allowPaypalTransfer,
      allowPromoDateUpdates: p.allowPromoDateUpdates,
      allowStripePayouts: p.allowStripePayouts,
      allowTakeOut: p.allowTakeOut,
      backgroundImage: p.backgroundImage,
      createdAt: p.createdAt,
      deliMenuId: p.deliMenuId,
      description: p.description,
      hasSelfDelivery: p.hasSelfDelivery,
      isActive: p.isActive,
      isAlpha: p.isAlpha,
      isBeta: p.isBeta,
      locations: p.locations,
      logoImage: p.logoImage,
      menuHeaderImage: p.menuHeaderImage,
      minimumDeliveryOrderAmount: p.minimumDeliveryOrderAmount,
      minimumOrderAmount: p.minimumOrderAmount,
      minimumPickupOrderAmount: p.minimumPickupOrderAmount,
      payPalDailyTransfers: p.payPalDailyTransfers,
      pricesIncludeTax: p.pricesIncludeTax,
      reportEmails: p.reportEmails,
      requiresFullCatalogUpdate: p.requiresFullCatalogUpdate,
      sendDailyReport: p.sendDailyReport,
      shortName: p.shortName,
      stripeDailyPayouts: p.stripeDailyPayouts,
      stripeDailyTransfers: p.stripeDailyTransfers,
      verifiedAt: p.verifiedAt,
      visibleInBeta: p.visibleInBeta,
      weMissYouActivated: p.weMissYouActivated,
      webHeaderImage: p.webHeaderImage
    }));

    return _.sortBy(mapped, [(o) => o.name]);
  }

  const showMessage = function (message) {
    notification.open({
      message: '',
      description: (
        <Comment
          author={<span>Grocefy</span>}
          avatar={<Avatar src={Grocefy} alt="grocefy" />}
          content={
            <p className="text-sm">
              {message}
            </p>
          }
          datetime={
            <Tooltip title={moment().format('YYYY-MM-DD HH:mm:ss')}>
              <span>{moment().fromNow()}</span>
            </Tooltip>
          }
        />
      ),
    });
  };

  function mapData(data) {
    return _.map(data, (p, index) => ({
      key: index,
      id: p.id,
      itemCount: p.itemCount,
      storeId: p.businessId,
      locationId: p.locationId,
      orderType: p.type,
      deliveryZone: p.deliveryZoneName,
      createdAt: renderShortDateTime(p.actualTime, timezone),
      cancelledAt: renderShortDateTime(p.cancellationDate, timezone),
      createdDiff: moment(`${p.createdAt}+0000`).tz(timezone).fromNow(),
      window: (moment(p.desiredDateTime).diff(p.actualTime, 'hours')),
      dispatchDiff: p.dispatchDateTime == null ? 'N/A' : moment(`${p.dispatchDateTime}+0000`).tz(timezone).fromNow(),
      expectedDiff: moment(`${p.desiredDateTime}+0000`).tz(timezone).fromNow(),
      businessName: p.businessName,
      type: t(mapOrderType(p.type)),
      typeEnum: p.type,
      confirmationCode: p.confirmationCode,
      customer: p.customerName,
      customerId: p.customerId,
      slotStartTime: p.startSlotTime,
      slotEndTime: p.endSlotTime,
      customerPhone: formatPhoneNumber(p.customerPhone),
      customerLocationName: p.customerLocationName,
      customerLocation: p.customerLocation,
      customerLocationId: p.customerLocationId,
      deliveryZoneId: p.deliveryZoneId,
      deliveryZoneName: p.deliveryZoneName,
      driverName: p.driverName ? p.driverName : 'Not Assigned',
      driverPic: p.driverPic,
      driverPhone: p.driverPhone,
      deliveryTotal: p.deliveryTotal,
      driverInstructions: p.driverInstructions,
      customerLocationLat: p.customerLocation?.geo.lat,
      customerLocationLon: p.customerLocation?.geo.lon,
      customerLocationAddress: p.customerLocation?.addressLine?.replace(
        /,/g,
        ''
      ),
      customerLocationCity: p.customerLocation?.city,
      customerLocationZipcode: p.customerLocation?.zipcode,
      customerLocationInstructions: p.customerLocation?.instructions?.replace(
        /,/g,
        ''
      ),
      ivuCN: p.ivuControlNumber === null ? 'N/A' : p.ivuControlNumber,
      statusText: mapOrderStatus(p.status),
      cardNickname: p.cardNickname,
      nickName: p.nickName,
      cardholderName: p.cardholderName,
      status: p.status,
      paymentTypeText: t(mapPaymentType(p.paymentType)),
      paymentType: p.paymentType,
      desiredDateTime: renderShortDateHour(p.desiredDateTime, timezone),
      dDateTime: p.desiredDateTime,
      dispatchDateTime: renderDate(p.dispatchDateTime, timezone),
      total: p.orderTotal.toFixed(2),
      orderTotal: p.orderTotal,
      subTotal: thousand(p.productsTotal),
      munTax: p.municipalTax.toFixed(2),
      stateTax: p.stateTax.toFixed(2),
      deliveryFee: p.deliveryTotal.toFixed(2),
      transferTotal: p.transferTotal,
      processingFee: p.processingFee.toFixed(2),
      estimatedStoreTransferTotal: p.estimatedStoreTransferTotal,
      transferCompletedText: p.transferCompleted ? 'Yes' : 'No',
      transferCompleted: p.transferCompleted,
      authTotal: p.authTotal.toFixed(2),
      returnTotal: p.returnTotal.toFixed(2),
      credit:
        p.authTotal - p.orderTotal
          ? (p.authTotal - p.orderTotal).toFixed(2)
          : '0.00',
      referenceNumber:
        p.referenceNumber || p.referenceNumber !== ''
          ? p.referenceNumber
          : 'n/a',
      refNumAvailable: p.referenceNumber !== '' && p.referenceNumber !== null,
      discountAmount: p.discountAmount.toFixed(2),
      businessDescription: p.businessDescription,
      returnNeeded: p.returnNeeded ? 'Yes' : 'No',
      returnCompleted: p.returnCompleted ? 'Yes' : 'No',
      athReturnError: p.athReturnError ? 'Yes' : 'No',
      wasAlreadyCharged: p.hasBeenCharged ? 'Yes' : 'No',
      wasAlreadyAuthed: p.hasBeenAuthed ? 'Yes' : 'No',
      hasBeenCharged: p.hasBeenCharged,
      hasBeenAuthed: p.hasBeenAuthed,
      signatureImageUrl: p.signatureImageUrl,
      ebtReceiptUrl: p.ebtReceiptUrl,
      pickUpName: p.pickUpName,
      items: p.items,
      terminalData: p.terminalData
    }));
  }

  function addValues(arr) {
    const result = _.sumBy(arr, function (o) { return o.orderTotal; });
    setOnScreenTotal(result.toFixed(2));
  }

  function mapLogs(data) {
    return _.map(data, (p) => ({
      key: p.id,
      approvedId: p.approvedId,
      createdAt: moment(`${p.createdAt}+0000`)
        .tz(timezone)
        .format('MMMM Do YYYY, h:mm:ss a'),
      description: p.description,
      id: p.id,
      modifiedDate: moment(`${p.modifiedDate}+0000`)
        .tz(timezone)
        .format('MMMM Do YYYY, h:mm:ss a'),
      orderId: p.orderId,
      postStatus: mapOrderStatus(p.postStatus),
      preStatus: mapOrderStatus(p.preStatus),
    }));
  }

  function mapOrderItems(data) {
    const sorted = _.map(data, (p) => ({
      key: p.id,
      id: p.id,
      completeName: p.completeName,
      name: p.name,
      brand: p.brand,
      amount: p.amount,
      upc: p.upc,
      aisle: p.aisle,
      price: p.price,
      description: p.description,
      extendedPrice: p.extendedPrice,
      itemImage: p.itemImage,
      hasRandomWeight: p.hasRandomWeight,
      sectionName: p.sectionName,
      isAlcoholicBeverage: p.isAlcoholicBeverage,
      isPacked: p.isPacked,
      isPerishable: p.isPerishable,
      isVoided: p.isVoided,
      manuallyAdded: p.manuallyAdded,
      acceptsSubstitutes: p.acceptsSubstitutes,
      taxRule: t(convertTaxRule(p.taxRule)),
    }));
    return _.sortBy(sorted, [(o) => o.isVoided]);
  }

  function loadLogs(row) {
    setLoadingLogs(true);
    api
      .get(`orders/${row.id}/logs`)
      .then((response) => {
        const map = mapLogs(response.data.data.results);
        setLogs(map);
        setLoadingLogs(false);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  function loadItems(record) {
    api
      .get(`orders/${record.id}/items/all`)
      .then((response) => {
        const map = mapOrderItems(response.data.data.results);
        setOrderItems(map);
        setLoadingItems(false);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  function load(record) {
    loadItems(record);
    loadLogs(record);
  }

  function getOrderHeader() {
    if (!loadingOrderHeader) {
      const data = {
        filterString: '',
      };
      data.filterString = `${data.filterString.trim()} ${statusFilter}`;
      data.filterString = `${data.filterString.trim()} ${timeFilter}`;
      data.filterString = `${data.filterString.trim()} ${typeFilter}`;
      data.filterString = `${data.filterString.trim()} ${paymentFilter}`;
      if (selectedStore.length > 0) {
        data.filterString = `${data.filterString.trim()} BUSINESS-${selectedStore}`;
      }
      setLoadingOrderHeader(true);
      api
        .post('businesses/orderheader', data)
        .then((response) => {
          setOrderHeader(response.data.data);
          setLoadingOrderHeader(false);
        })
        .catch((error) => {
          console.error(error);
          setLoadingOrderHeader(false);
        });
    }
  }

  const searchOrders = function (query, page) {
    if (!isSearching) {
      getOrderHeader();
      setIsSearching(true);
      setTotalRevenueInSearch(0);
      setTotalItemsInSearch(0);
      let filterString = '';
      filterString = `${filterString.trim()} ${statusFilter}`;
      filterString = `${filterString.trim()} ${timeFilter}`;
      filterString = `${filterString.trim()} ${typeFilter}`;
      filterString = `${filterString.trim()} ${paymentFilter}`;
      if (selectedStore.length > 0) {
        filterString = `${filterString.trim()} BUSINESS-${selectedStore}`;
      }
      const payload = {
        SearchString: query,
        FilterString: filterString.trim(),
        OrderAsc: sorting === 'asc',
        OrderDesc: sorting === 'desc',
      };
      api
        .post(
          `admin/orders/${size}/${page}`,
          payload
        )
        .then((response) => {
          if (response.data.success) {
            setIsSearching(false);
            setTotalRevenueInSearch(response.data.data.sales);
            setTotalItemsInSearch(response.data.data.count);
            const mapped = mapData(response.data.data.orders);
            setOrders(mapped);
            addValues(mapped);
          } else {
            showMessage(response.data.error);
          }
        })
        .catch((error) => {
          setIsSearching(false);
          showMessage(error);
        });
    }
  };

  const onSearch = async (value) => {
    setLastSearch(value);
    setCurrentPage(0);
    searchOrders(value, 0);
  };

  const onTableChange = (page, pageSize) => {
    if (page - 1 !== currentPage) {
      setCurrentPage(page - 1);
      searchOrders(lastSearch, page - 1);
    } else {
      setSize(pageSize);
    }
  };

  const onLogTableChange = (page, pageSize) => {
    if (page - 1 !== currentLogPage) {
      setCurrentLogPage(page - 1);
    } else {
      setSize(pageSize);
    }
  };

  useEffect(() => {
    if (reload) {
      setCurrentPage(0);
      searchOrders(lastSearch, 0);
    }
  }, [reload]);

  useEffect(() => {
    setTotalItemsInSearch(0);
    setCurrentPage(0);
    searchOrders(lastSearch, 0);
  }, [size, statusFilter, typeFilter, selectedStore, timeFilter, paymentFilter]);

  useEffect(() => {
    if (businesses.state === 'hasValue') {
      setMappedBusiness(mapBusiness());
    }
  }, [businesses]);

  return (
    <div>
      <div className="mb-0">
        <Panel key="panel">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 pt-4">
            <Spin spinning={loadingOrderHeader}>
              <div className="shadow rounded-xl flex h-20">
                <div className="flex flex-shrink-0 items-center justify-center text-white text-3xl bg-rose-500 w-1/4 rounded-l-xl">
                  <BellTwoTone
                    twoToneColor="#eb2f96"
                    className="-ml-1 flex-shrink-0 self-center h-5 w-5"
                  />
                </div>
                <div className="flex flex-col text-xl mt-2 justify-center p-2 text-xs text-gray-800">
                  <div>
                    {orderHeader && orderHeader.pendingOrders}
                  </div>
                  <div className="justify-start">
                    <Button
                      type="text"
                      className="p-0 -mt-2 text-sm text-gray-800 font-semibold"
                      onClick={() => {
                        setStatusFilter('PLACED');
                        setTimeFilter('');
                      }}
                    >
                      {t('placed')}
                    </Button>
                  </div>
                </div>
                <div className="flex flex-col ml-2 justify-center">
                  <div className="flex flex-row">
                    <div className="text-xs mt-1 text-gray-600">
                      <Button
                        type="text"
                        className="p-0 -mt-2"
                        onClick={() => {
                          setStatusFilter('PLACED');
                          setTimeFilter('PAST');
                        }}
                      >
                        <BellTwoTone
                          twoToneColor="#d2222d"
                          className="-ml-1 flex-shrink-0 self-center h-3 w-3"
                        />
                        {orderHeader && orderHeader.pendingPastOrders} {t('past')}
                      </Button>
                    </div>
                  </div>
                  <div className="flex flex-row">
                    <div className="text-xs mt-1 text-gray-600">
                      <Button
                        type="text"
                        className="p-0 -mt-2"
                        onClick={() => {
                          setStatusFilter('PLACED');
                          setTimeFilter('TODAY');
                        }}
                      >
                        <BellTwoTone
                          twoToneColor="#ffbf00"
                          className="-ml-1 flex-shrink-0 self-center h-3 w-3"
                        />
                        {orderHeader && orderHeader.pendingCurrentOrders} {t('today')}
                      </Button>
                    </div>
                  </div>
                  <div className="flex flex-row">
                    <div className="text-xs mt-1 text-gray-600">
                      <Button
                        type="text"
                        className="p-0 -mt-2"
                        onClick={() => {
                          setStatusFilter('PLACED');
                          setTimeFilter('FUTURE');
                        }}
                      >
                        <BellTwoTone
                          twoToneColor="#23bb23"
                          className="-ml-1 flex-shrink-0 self-center h-3 w-3"
                        />
                        {orderHeader && orderHeader.pendingFutureOrders} {t('future')}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </Spin>
            <Spin spinning={loadingOrderHeader}>
              <div className="shadow rounded-xl flex h-20">
                <div className="flex flex-shrink-0 items-center justify-center text-white text-3xl bg-rose-500 w-1/4 rounded-l-xl">
                  <RocketTwoTone
                    twoToneColor="#A117F2"
                    className="-ml-1 flex-shrink-0 self-center h-5 w-5"
                  />
                </div>
                <div className="flex flex-col text-xl mt-2 mx-2 justify-center p-0 text-xs text-gray-800">
                  <div>
                    {orderHeader && orderHeader.activeOrders}
                  </div>
                  <div className="justify-start">
                    <Button
                      type="text"
                      className="p-0 -mt-2 text-sm text-gray-800 font-semibold"
                      onClick={() => {
                        setStatusFilter('INPROCESS');
                        setTimeFilter('');
                      }}
                    >
                      {t('processing')}
                    </Button>
                  </div>
                </div>
                <div className="flex flex-col ml-2 justify-center">
                  <div className="flex flex-row">
                    <div className="text-xs mt-1 text-gray-600">
                      <Button
                        type="text"
                        className="p-0 -mt-2"
                        onClick={() => {
                          setStatusFilter('INPROCESS');
                          setTimeFilter('PAST');
                        }}
                      >
                        <RocketTwoTone
                          twoToneColor="#d2222d"
                          className="-ml-1 flex-shrink-0 self-center h-3 w-3"
                        />
                        {orderHeader && orderHeader.activePastOrders} {t('past')}
                      </Button>
                    </div>
                  </div>
                  <div className="flex flex-row">
                    <div className="text-xs mt-1 text-gray-600">
                      <Button
                        type="text"
                        className="p-0 -mt-2"
                        onClick={() => {
                          setStatusFilter('INPROCESS');
                          setTimeFilter('TODAY');
                        }}
                      >
                        <RocketTwoTone
                          twoToneColor="#ffbf00"
                          className="-ml-1 flex-shrink-0 self-center h-3 w-3"
                        />
                        {orderHeader && orderHeader.activeCurrentOrders} {t('today')}
                      </Button>
                    </div>
                  </div>
                  <div className="flex flex-row">
                    <div className="text-xs mt-1 text-gray-600">
                      <Button
                        type="text"
                        className="p-0 -mt-2"
                        onClick={() => {
                          setStatusFilter('INPROCESS');
                          setTimeFilter('FUTURE');
                        }}
                      >
                        <RocketTwoTone
                          twoToneColor="#23bb23"
                          className="-ml-1 flex-shrink-0 self-center h-3 w-3"
                        />
                        {orderHeader && orderHeader.activeFutureOrders} {t('future')}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </Spin>
            <Spin hidden spinning={loadingOrderHeader}>
              <div className="shadow rounded-xl flex h-20">
                <div className="flex flex-shrink-0 items-center justify-center text-white text-3xl bg-rose-500 w-1/4 rounded-l-xl">
                  <RocketTwoTone
                    twoToneColor="#A117F2"
                    className="-ml-1 flex-shrink-0 self-center h-5 w-5"
                  />
                </div>
                <div className="flex flex-col text-4xl mt-2 mx-2 justify-center p-2 text-xs text-gray-800">
                  <div>
                    {orderHeader && orderHeader.waitingOrders}
                  </div>
                </div>
                <div className="flex flex-col justify-end p-4 text-lg text-gray-800 font-semibold">
                  <div>
                    {t('processing')}
                  </div>
                </div>
              </div>
            </Spin>
            <Spin spinning={loadingOrderHeader}>
              <div className="shadow rounded-xl flex h-20">
                <div className="flex flex-shrink-0 items-center justify-center text-white text-3xl bg-rose-500 w-1/4 rounded-l-xl">
                  <ClockCircleTwoTone
                    twoToneColor="#ff711f"
                    className="-ml-1 flex-shrink-0 self-center h-5 w-5"
                  />
                </div>
                <div className="flex flex-col text-xl mt-2 mx-2 justify-center p-2 text-xs text-gray-800">
                  <div>
                    {orderHeader && orderHeader.waitingOrders}
                  </div>
                  <div className="justify-start">
                    <Button
                      type="text"
                      className="p-0 -mt-2 text-sm text-gray-800 font-semibold"
                      onClick={() => {
                        setStatusFilter('WAITING');
                        setTimeFilter('');
                      }}
                    >
                      {t('waiting')}
                    </Button>
                  </div>
                </div>
                <div className="flex flex-col ml-2 justify-center">
                  <div className="flex flex-row">
                    <div className="text-xs mt-1 text-gray-600">
                      <Button
                        type="text"
                        className="p-0 -mt-2"
                        onClick={() => {
                          setStatusFilter('WAITING');
                          setTimeFilter('PAST');
                        }}
                      >
                        <ClockCircleTwoTone
                          twoToneColor="#d2222d"
                          className="-ml-1 flex-shrink-0 self-center h-3 w-3"
                        />
                        {orderHeader && orderHeader.waitingPastOrders} {t('past')}
                      </Button>
                    </div>
                  </div>
                  <div className="flex flex-row">
                    <div className="text-xs mt-1 text-gray-600">
                      <Button
                        type="text"
                        className="p-0 -mt-2"
                        onClick={() => {
                          setStatusFilter('WAITING');
                          setTimeFilter('TODAY');
                        }}
                      >
                        <ClockCircleTwoTone
                          twoToneColor="#ffbf00"
                          className="-ml-1 flex-shrink-0 self-center h-3 w-3"
                        />
                        {orderHeader && orderHeader.waitingCurrentOrders} {t('today')}
                      </Button>
                    </div>
                  </div>
                  <div className="flex flex-row">
                    <div className="text-xs mt-1 text-gray-600">
                      <Button
                        type="text"
                        className="p-0 -mt-2"
                        onClick={() => {
                          setStatusFilter('WAITING');
                          setTimeFilter('FUTURE');
                        }}
                      >
                        <ClockCircleTwoTone
                          twoToneColor="#23bb23"
                          className="-ml-1 flex-shrink-0 self-center h-3 w-3"
                        />
                        {orderHeader && orderHeader.waitingFutureOrders} {t('future')}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </Spin>
            <Spin spinning={loadingOrderHeader}>
              <div className="shadow rounded-xl flex h-20">
                <div className="flex flex-shrink-0 items-center justify-center text-white text-3xl bg-rose-500 w-1/4 rounded-l-xl">
                  <MailTwoTone
                    className="-ml-1 flex-shrink-0 self-center h-5 w-5"
                  />
                </div>
                <div className="flex flex-col text-xl mt-2 mx-2 justify-center p-1 text-xs text-gray-800">
                  <div>
                    {orderHeader && orderHeader.inTransitOrders}
                  </div>
                  <div className="justify-start">
                    <Button
                      type="text"
                      className="p-0 -mt-2 text-sm text-gray-800 font-semibold"
                      onClick={() => {
                        setStatusFilter('INTRANSIT');
                        setTimeFilter('');
                      }}
                    >
                      {t('in_transit')}
                    </Button>
                  </div>
                </div>
                <div className="flex flex-col ml-2 justify-center">
                  <div className="flex flex-row">
                    <div className="text-xs mt-1 text-gray-600">
                      <Button
                        type="text"
                        className="p-0 -mt-2"
                        onClick={() => {
                          setStatusFilter('INTRANSIT');
                          setTimeFilter('PAST');
                        }}
                      >
                        <MailTwoTone
                          twoToneColor="#d2222d"
                          className="-ml-1 flex-shrink-0 self-center h-3 w-3"
                        />
                        {orderHeader && orderHeader.inTransitPastOrders} {t('past')}
                      </Button>
                    </div>
                  </div>
                  <div className="flex flex-row">
                    <div className="text-xs mt-1 text-gray-600">
                      <Button
                        type="text"
                        className="p-0 -mt-2"
                        onClick={() => {
                          setStatusFilter('INTRANSIT');
                          setTimeFilter('TODAY');
                        }}
                      >
                        <MailTwoTone
                          twoToneColor="#ffbf00"
                          className="-ml-1 flex-shrink-0 self-center h-3 w-3"
                        />
                        {orderHeader && orderHeader.inTransitCurrentOrders} {t('today')}
                      </Button>
                    </div>
                  </div>
                  <div className="flex flex-row">
                    <div className="text-xs mt-1 text-gray-600">
                      <Button
                        type="text"
                        className="p-0 -mt-2"
                        onClick={() => {
                          setStatusFilter('INTRANSIT');
                          setTimeFilter('FUTURE');
                        }}
                      >
                        <MailTwoTone
                          twoToneColor="#23bb23"
                          className="-ml-1 flex-shrink-0 self-center h-3 w-3"
                        />
                        {orderHeader && orderHeader.inTransitFutureOrders} {t('future')}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </Spin>
            <Spin hidden spinning={loadingOrderHeader}>
              <div className="shadow rounded-xl flex h-20">
                <div className="flex flex-shrink-0 items-center justify-center text-white text-3xl bg-rose-500 w-1/4 rounded-l-xl">
                  <CheckSquareTwoTone
                    twoToneColor="#52c41a"
                    className="-ml-1 flex-shrink-0 self-center h-5 w-5"
                  />
                </div>
                <div className="flex flex-col text-xl mt-2 justify-center p-2 text-xs text-gray-800">
                  <div>
                    {orderHeader && thousand(orderHeader.completedOrders)}
                  </div>
                  <div className="justify-start text-sm text-gray-800 font-semibold">
                    {t('completed')}
                  </div>
                </div>
              </div>
            </Spin>
          </div>
        </Panel>
      </div>
      <div className="flex flex-row justify-between">
        <Row className="my-4 flex space-between">
          <Col>
            <div className="mr-2">
              <FilterOutlined className="mr-2" />
              <strong className="text-xs">{t('store')}</strong>
              <br />
              <Select
                style={{ width: '140px' }}
                value={selectedStore}
                onChange={(value) => setSelectedStore(value)}
              >
                <Option
                  key=""
                  value=""
                  className="text-xs flex items-center"
                >
                  {t('any')}
                </Option>
                {mappedBusiness && _.map(mappedBusiness, (store) => (
                  <Option
                    key={store.id}
                    value={store.id}
                    className="text-xs flex items-center"
                  >
                    {store.name}
                  </Option>
                ))}
              </Select>
            </div>
          </Col>
          <Col>
            <div className="mr-2">
              <FilterOutlined className="mr-2" />
              <strong className="text-xs">{t('status')}</strong>
              <br />
              <Select
                style={{ width: '140px' }}
                value={statusFilter}
                onChange={(value) => setStatusFilter(value)}
              >
                {_.map(statusFilters, (filter) => (
                  <Option
                    key={filter.tag}
                    value={filter.tag}
                    className="text-xs flex items-center"
                  >
                    {filter.name}
                  </Option>
                ))}
              </Select>
            </div>
          </Col>
          <Col>
            <div className="mr-2">
              <FilterOutlined className="mr-2" />
              <strong className="text-xs">{t('time')}</strong>
              <br />
              <Select
                style={{ width: '120px' }}
                value={timeFilter}
                onChange={(value) => setTimeFilter(value)}
              >
                {_.map(timeFilters, (filter) => (
                  <Option
                    key={filter.tag}
                    value={filter.tag}
                    className="text-xs flex items-center"
                  >
                    {filter.name}
                  </Option>
                ))}
              </Select>
            </div>
          </Col>
          <Col>
            <div className="mr-2">
              <FilterOutlined className="mr-2" />
              <strong className="text-xs">{t('type')}</strong>
              <br />
              <Select
                style={{ width: '120px' }}
                value={typeFilter}
                onChange={(value) => setTypeFilter(value)}
              >
                {_.map(typeFilters, (filter) => (
                  <Option
                    key={filter.tag}
                    value={filter.tag}
                    className="text-xs flex items-center"
                  >
                    {filter.name}
                  </Option>
                ))}
              </Select>
            </div>
          </Col>
          <Col>
            <div className="mr-2">
              <FilterOutlined className="mr-2" />
              <strong className="text-xs">{t('payment')}</strong>
              <br />
              <Select
                style={{ width: '100px' }}
                value={paymentFilter}
                onChange={(value) => setPaymentFilter(value)}
              >
                <Option
                  key="all"
                  value=""
                  className="text-xs flex items-center"
                >
                  {t('any')}
                </Option>
                <Option
                  key="cc"
                  value="CREDITCARD"
                  className="text-xs flex items-center"
                >
                  {t('credit_card')}
                </Option>
                {_.map(paymentOptions?.contents?.data?.data?.results, (paymentOption) => (
                  <Option
                    key={paymentOption.id}
                    value={`PAYMENTOPTION-${paymentOption.id}`}
                    className="text-xs flex items-center"
                  >
                    {paymentOption.name}
                  </Option>
                ))}
              </Select>
            </div>
          </Col>
          <Col className="mr-4">
            <div>
              <SortAscendingOutlined className="mr-2" />
              <strong className="text-xs">{t('sort')}</strong>
              <br />
              <Select
                style={{ width: '90px' }}
                value={sorting}
                onChange={(value) => setSorting(value)}
              >
                <Option
                  key="ascending"
                  value="asc"
                  className="text-xs flex items-center"
                >
                  {t('oldest')}
                </Option>
                <Option
                  key="descending"
                  value="desc"
                  className="text-xs flex items-center"
                >
                  {t('newest')}
                </Option>
              </Select>
            </div>
          </Col>
          <Col className={timeFilter === '' &&
                          statusFilter === '' &&
                          typeFilter === '' &&
                          !lastSearch ? 'hidden' : 'mt-8 w-32 flex justify-end'}
          >
            <div>
              {isSearching ? <div>...</div> :
              <div className="font-semibold text-blue-800 text-xs flex flex-row justify-end">
                <div className="mr-1">{totalItemsInSearch > size ? size : totalItemsInSearch}/
                  {totalItemsInSearch}
                </div>
                ({totalItemsInSearch > size ?
                  <div>${thousand(onScreenTotal)} out of ${thousand(totalRevenueInSearch)}
                  </div> :
                  <div>${thousand(onScreenTotal)}
                  </div>})
              </div>}
            </div>
          </Col>
        </Row>
      </div>
      <div>
        <Input.Search
          className="w-full mb-1"
          key="orderSearch"
          placeholder={t('order_search_placeholder')}
          allowClear
          loading={isSearching}
          enterButton={t('search')}
          onSearch={onSearch}
        />
      </div>
      <Table
        className="pt-2"
        loading={isSearching}
        size="small"
        scroll={{ x: 500 }}
        pagination={{
          pageSize: size,
          showSizeChanger: true,
          defaultCurrent: 0,
          current: currentPage + 1,
          total: totalItemsInSearch,
          onChange: onTableChange
        }}
        dataSource={orders}
        columns={[
          {
            key: 'businessName',
            align: 'left',
            width: 140,
            className: 'text-xs',
            render: (text) => (
              <div>
                <Typography.Text>{text.businessName}</Typography.Text>
                <br />
                <Typography.Text copyable>{text.confirmationCode}</Typography.Text>
              </div>
            ),
          },
          {
            title: t('type'),
            key: 'type',
            align: 'left',
            width: 120,
            className: 'text-xs',
            render: (text) => {
              if (text.type === 'Pickup') return <Tag color="#FF6600">{text.type}</Tag>;
              if (text.type === 'Delivery') {
                return <div><Tag color="#87d068">{text.type}</Tag><br /><span className="mt-2">{text.deliveryZoneName}</span></div>;
              }
              return <Tag>{text.type}</Tag>;
            }
          },
          {
            title: t('customer'),
            dataIndex: 'customer',
            key: 'customer',
            align: 'left',
            className: 'text-xs',
            render: (text) => <span>{text}</span>,
          },
          {
            title: t('total'),
            key: 'total',
            align: 'right',
            width: 70,
            className: 'text-xs',
            render: (text) => <div className="flex flex-col"><span>${text.total}</span><span>{text.itemCount} item(s)</span></div>,
            sorter: (a, b) => a.total - b.total,
          },
          {
            title: t('payment'),
            dataIndex: 'paymentTypeText',
            key: 'paymentTypeText',
            align: 'center',
            className: 'text-xs',
            render: (text) => <span>{text}</span>,
          },
          {
            title: t('status'),
            dataIndex: 'statusText',
            key: 'statusText',
            align: 'left',
            className: 'text-xs',
            render: (text) => (
              <div className="flex flex-row">
                <Typography.Text>
                  {t(text)}
                </Typography.Text>
                <Tag className={text === 'status_placed' ? 'text-xs ml-2' : 'hidden'} color="green">new</Tag>
              </div>
            ),
          },
          {
            title: t('service_date'),
            key: 'desiredDateTime',
            align: 'left',
            width: 100,
            className: 'text-xs',
            render: (text) => <span>{renderShortDate(text.desiredDateTime, timezone)}</span>,
          },
          {
            title: t('service_time'),
            key: 'slotStartTime',
            align: 'left',
            width: 100,
            className: 'text-xs',
            render: (text) =>
              <span>{`${moment(`${text.slotStartTime.replace('Z', '')}+0000`)
                .tz(timezone)
                .format('h A')} - ${moment(`${text.slotEndTime.replace('Z', '')}+0000`)
                .tz(timezone).format('h A')}`}
              </span>,
          },
          {
            title: t('expected'),
            key: 'dDateTime',
            align: 'left',
            className: 'text-xs',
            render: (text) => <span className="capitalize">{moment(`${text.slotEndTime}+0000`).tz(timezone).fromNow()}</span>,
          },
          {
            title: t('signature'),
            key: 'signatureImageUrl',
            align: 'center',
            className: 'text-xs',
            render: (text, x) =>
              <span>
                <Button
                  shape="circle"
                  size="small"
                  disabled={x.signatureImageUrl === '' || x.signatureImageUrl == null}
                  icon={<SearchOutlined />}
                  onClick={() => {
                    setVisibleSignature(true);
                    setVisibleSignatureImage(x.signatureImageUrl);
                  }}
                />
              </span>,
          },
          {
            title: 'EBT',
            key: 'ebtReceiptUrl',
            align: 'center',
            className: 'text-xs',
            render: (text, x) =>
              <span>
                <Button
                  shape="circle"
                  size="small"
                  disabled={x.ebtReceiptUrl === '' || x.ebtReceiptUrl == null}
                  icon={<SearchOutlined />}
                  onClick={() => {
                    setVisibleEbt(true);
                    setVisibleEbtImage(x.ebtReceiptUrl);
                  }}
                />
              </span>,
          },
          {
            title: t('items'),
            align: 'center',
            className: 'text-xs hidden',
            render: (text, row) => (
              <Space>
                <Popover
                  key="pitems"
                  className="h-32 md:h-64"
                  title={loadingItems ? `${t('loading')}...` : `${t('order_items')} (${orderItems && orderItems.length})`}
                  placement="left"
                  trigger="click"
                  width="100%"
                  content={
                    <div>
                      <Table
                        key="itemsTable"
                        size="small"
                        bordered
                        loading={loadingItems}
                        columns={[
                          {
                            title: t('brand'),
                            dataIndex: 'brand',
                            key: 'brand',
                            align: 'left',
                            className: 'text-xs',
                            render: (info) => (
                              <Typography.Text>{info}</Typography.Text>
                            ),
                          },
                          {
                            title: t('name'),
                            dataIndex: 'name',
                            key: 'name',
                            align: 'left',
                            className: 'text-xs',
                            render: (info) => (
                              <Typography.Text>{info}</Typography.Text>
                            ),
                          },
                          {
                            title: t('description'),
                            dataIndex: 'description',
                            key: 'description',
                            align: 'left',
                            className: 'text-xs',
                            render: (info) => (
                              <Typography.Text>{info}</Typography.Text>
                            ),
                          },
                          {
                            title: 'UPC',
                            dataIndex: 'upc',
                            key: 'upc',
                            align: 'left',
                            className: 'text-xs',
                            render: (info) => (
                              <Typography.Text copyable>{info}</Typography.Text>
                            ),
                          },
                          {
                            title: t('amount'),
                            dataIndex: 'amount',
                            key: 'amount',
                            align: 'center',
                            className: 'text-xs',
                            render: (info) => (
                              <Typography.Text>
                                {info.toFixed(2)}
                              </Typography.Text>
                            ),
                          },
                          {
                            title: t('price'),
                            dataIndex: 'price',
                            key: 'price',
                            align: 'center',
                            className: 'text-xs',
                            render: (info) => (
                              <Typography.Text>
                                ${info.toFixed(2)}
                              </Typography.Text>
                            ),
                          },
                          {
                            title: 'Included',
                            dataIndex: 'isVoided',
                            key: 'isVoided',
                            align: 'center',
                            className: 'text-xs',
                            render: (info) => (
                              <Switch size="small" checked={!info} disabled />
                            ),
                          },
                          {
                            title: 'Tax Rule',
                            dataIndex: 'taxRule',
                            key: 'traxRule',
                            align: 'center',
                            className: 'text-xs',
                            render: (info) => (
                              <Typography.Text>{info}</Typography.Text>
                            ),
                          },
                        ]}
                        dataSource={
                          orderItems && orderItems.length > 0
                            ? orderItems
                            : null
                        }
                        expandable={{
                          expandRowByClick: false,
                          expandedRowRender: (record) => (
                            <div>
                              <Tabs>
                                <Tabs.TabPane key="1" tab={t('details')}>
                                  <Row className="flex">
                                    <Col span={12} className="flex">EbtreceiptUrl
                                      <Descriptions>
                                        <Descriptions.Item label="Section Name" />
                                        <br />
                                        <Descriptions.Item>
                                          <Col span={24}>
                                            <Typography.Text>
                                              {record.sectionName}
                                            </Typography.Text>
                                          </Col>
                                        </Descriptions.Item>
                                        <Descriptions.Item label={t('aisle')} />
                                        <br />
                                        <Descriptions.Item>
                                          <Col span={24}>
                                            <Typography.Text>
                                              {record.aisle}
                                            </Typography.Text>
                                          </Col>
                                        </Descriptions.Item>
                                      </Descriptions>
                                    </Col>
                                    <Col span={12} className="flex">
                                      <Descriptions bordered>
                                        <Descriptions.Item label={t('item_image')} />
                                        <br />
                                        <Descriptions.Item>
                                          <Col span={24}>
                                            <Image
                                              alt="Item Image"
                                              width={150}
                                              src={record.itemImage}
                                            />
                                          </Col>
                                        </Descriptions.Item>
                                      </Descriptions>
                                    </Col>
                                  </Row>
                                </Tabs.TabPane>
                                <Tabs.TabPane key="2" tab={t('flags')}>
                                  <Row className="flex">
                                    <Col span={12} className="flex">
                                      <Descriptions>
                                        <Descriptions.Item
                                          className="py-0"
                                          label="Weighted"
                                        >
                                          <Switch
                                            disabled
                                            size="small"
                                            checked={record.hasRandomWeight}
                                          />
                                        </Descriptions.Item>
                                      </Descriptions>
                                    </Col>
                                    <Col span={10} offset={2}>
                                      <Descriptions>
                                        <Descriptions.Item label={t('alcoholic_beverages')}>
                                          <Switch
                                            disabled
                                            size="small"
                                            checked={record.isAlcoholicBeverage}
                                          />
                                        </Descriptions.Item>
                                      </Descriptions>
                                    </Col>
                                  </Row>
                                </Tabs.TabPane>
                              </Tabs>
                            </div>
                          ),
                          rowExpandable: (record) => record,
                        }}
                      />
                    </div>
                  }
                >
                  <Button
                    disabled={row.favStoreId === 'Unknown'}
                    shape="circle"
                    size="small"
                    icon={<SearchOutlined />}
                    onClick={() => {
                      setLoadingItems(true);
                      api
                        .get(`orders/${row.id}/items/all`)
                        .then((response) => {
                          const map = mapOrderItems(response.data.data.results);
                          setOrderItems(map);
                          setLoadingItems(false);
                        })
                        .catch((error) => {
                          console.error(error);
                        });
                    }}
                  />
                </Popover>
              </Space>
            ),
          },
          {
            title: 'Logs',
            align: 'center',
            className: 'text-xs',

            render: (row) => (
              <Space>
                <Popover
                  key="plogs"
                  title={loadingLogs ? `${t('loading')}...` : `Logs (${logs && logs.length})`}
                  placement="left"
                  trigger="click"
                  content={
                    <div>
                      <Table
                        key="logsTable"
                        pagination={{
                          pageSize: 5,
                          showSizeChanger: true,
                          defaultCurrent: 0,
                          current: currentLogPage + 1,
                          showTotal: (total, range) =>
                            `${range[0]}-${range[1]} ${t('out_of')} ${logs && logs.length} ${t('items')}`,
                          total: logs && logs.length,
                          onChange: onLogTableChange
                        }}
                        className="flex flex-column h-auto md:h-auto"
                        loading={loadingLogs}
                        dataSource={logs && logs.length > 0 ? logs : null}
                        columns={[
                          {
                            title: t('modified_date'),
                            dataIndex: 'modifiedDate',
                            key: 'modifiedDate',
                            align: 'left',
                            className: 'text-xs font-medium',
                            render: (txt) => <span>{txt}</span>,
                          },
                          {
                            title: t('description'),
                            dataIndex: 'description',
                            key: 'description',
                            align: 'left',
                            className: 'text-xs font-medium width-100 max-w-md',
                            tooltip: 'description',
                            render: (txt) => <span className="max-w-md">{txt}</span>,
                          },
                          {
                            title: t('status'),
                            dataIndex: 'preStatus',
                            key: 'preStatus',
                            align: 'left',
                            className: 'text-xs font-medium width-100',
                            render: (txt) => <span>{t(txt)}</span>,
                          },
                        ]}
                      />
                    </div>
                  }
                >
                  <Button
                    shape="circle"
                    size="small"
                    onClick={() => {
                      setLoadingLogs(true);
                      setCurrentLogPage(0);
                      api
                        .get(`orders/${row.id}/logs`)
                        .then((response) => {
                          const map = mapLogs(response.data.data.results);
                          setLogs(map);
                          setLoadingLogs(false);
                        })
                        .catch((error) => {
                          console.error(error);
                        });
                    }}
                    icon={<OrderedListOutlined />}
                  />
                </Popover>
              </Space>
            ),
          },
          {
            align: 'center',
            className: 'text-xs',
            render: (row) => (
              <Space>
                <Button
                  size="small"
                  type="primary"
                  onClick={() => {
                    setSelectedOrder(row);
                    loadItems(row);
                    setEditOrder(true);
                  }}
                >
                  {t('actions')}
                </Button>
              </Space>
            ),
          },
        ]}
        expandable={{
          expandRowByClick: false,
          expandedRowRender: (record) => (
            <Tabs
              onTabClick={() => {
                loadLogs(record);
                loadItems(record);
                setOrder(record);
              }}
              onChange={() => { setOrder(record); }}
            >
              <Tabs.TabPane key="1" tab="General">
                <div className="flex flex-columns justify-content bg-white">
                  <div className="p-4 flex flex-col justify-content">
                    <p className="text-md font-semibold">{t('customer')}</p>
                    <div>
                      <Typography.Text copyable>{record.customer}</Typography.Text>
                      <br />
                      <Typography.Text copyable>{record.customerPhone}</Typography.Text>
                      <br />
                    </div>
                    <p className="text-md font-semibold mt-4">{t('pickup_name')}</p>
                    <div className="text-md">
                      <Typography.Text>{record.pickUpName}</Typography.Text>
                      <br />
                    </div>
                    <p className="text-md font-semibold mt-4">{t('dates')}</p>
                    <div className="text-md">
                      {`${t('created')}: ${record.createdDiff}`}
                      <br />
                      {`(${record.createdAt})`}
                      <br />
                      {`${t('expected')}: ${record.expectedDiff}`}
                      <br />
                      {`(${record.desiredDateTime})`}
                      <br />
                      {`${t('lead_time')}: ${record.window} hour(s)`}
                      <br />
                      <br />
                      {`${t('dispatched')}: ${record.dispatchDateTime === '' ? 'No' : record.dispatchDateTime}`}
                      <br />
                    </div>
                  </div>
                  <div className="p-4">
                    <p className="text-md font-semibold">{t('payment')}</p>
                    <div className="text-md">
                      {`${record.paymentTypeText}`}
                      <br />
                      {`${t('card')}: ${record.nickName}`}
                      <br />
                      {`${t('card_holder')}: ${record.cardholderName}`}
                      <br />
                      {`${t('authorized')}: ${record.wasAlreadyAuthed}`}
                      <br />
                      {`${t('charged')}: ${record.wasAlreadyCharged}`}
                      <br />
                      {`${t('Ivu Control')}: ${record.ivuCN}`}
                      <br />
                    </div>
                  </div>
                  <div className="p-4">
                    <p className="text-md font-semibold">{t('totals')}</p>
                    <div className="text-md flex flex-grow">
                      {`${t('subtotal')}:`}  {`$${parseFloat(record.subTotal).toFixed(2)}`}
                      <br />
                      {`${t('municipal_tax')}: $${parseFloat(record.stateTax).toFixed(2)}`}
                      <br />
                      {`${t('state_tax')}: $${parseFloat(record.munTax).toFixed(2)}`}
                      <br />
                      {`${t('service_fee')}: $${parseFloat(record.processingFee).toFixed(2)}`}
                      <br />
                      {`${t('delivery')}: $${parseFloat(record.deliveryFee).toFixed(2)}`}
                      <br />
                      {`${t('total')}: $${parseFloat(record.total).toFixed(2)}`}
                      <br />
                      <br />
                      {`${t('discount')}: $${parseFloat(record.discountAmount).toFixed(2)}`}
                      <br />
                      {`${t('return')}: $${parseFloat(record.returnTotal).toFixed(2)}`}
                      <br />
                    </div>
                  </div>
                  <div className="p-4">
                    <p className="text-md font-semibold">{t('transfers')}</p>
                    <div>
                      {`Pending: $${record.estimatedStoreTransferTotal.toFixed(2)}`}
                      <br />
                      {`Paid Out: ${record.transferCompletedText}`}
                      <br />
                      {`Paid Amount: $${record.transferTotal.toFixed(2)}`}
                    </div>
                  </div>
                </div>
              </Tabs.TabPane>
              <Tabs.TabPane className={record.orderType === 0 ? 'hidden' : ''} tab="Details" key="2">
                <div>
                  <Descriptions Descriptions size="small" title="Delivery">
                    <Descriptions.Item className={record.orderType === 0 ? 'hidden' : ''} label="Zone">
                      <Typography.Text>{record.deliveryZone}</Typography.Text>
                    </Descriptions.Item>
                    <br className={record.orderType === 0 ? 'hidden' : ''} />
                    <br className={record.orderType === 0 ? 'hidden' : ''} />
                    <div>
                      <AimOutlined className="mr-2" /> {record.customerLocation?.name}
                      <br />
                      {record.customerLocation?.addressLine}
                      <br />
                      {`${record.customerLocation?.city}, ${record.customerLocation?.zipcode}`}
                    </div>
                  </Descriptions>
                </div>
              </Tabs.TabPane>
            </Tabs>
          ),
          rowExpandable: (record) => record,
        }}
      />
      {editOrder ? <OrderActions open={selectedOrder} /> : null}
      <Image
        width={200}
        style={{ display: 'none' }}
        src={visibleSignatureImg}
        preview={{
          visible: visibleSignature,
          scaleStep,
          src: visibleSignatureImg,
          onVisibleChange: (value) => {
            setVisibleSignature(value);
            setVisibleSignatureImage(null);
          },
        }}
      />
      <Image
        width={200}
        style={{ display: 'none' }}
        src={visibleEbtImg}
        preview={{
          visible: visibleEbt,
          scaleStep,
          src: visibleEbtImg,
          onVisibleChange: (value) => {
            setVisibleEbt(value);
            setVisibleEbtImage(null);
          },
        }}
      />
    </div>
  );
}
export default Orders;
