import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import {
  useRecoilState,
  useRecoilValueLoadable
} from 'recoil';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import {
  Drawer,
  Select,
  Button,
  Avatar,
  Tooltip,
  notification
} from 'antd';
import { Comment } from '@ant-design/compatible';
import {
  showCopyToDrawerAtom
} from '../../../../atoms/Atoms';
import { businessesSelector } from '../../../../atoms/Selectors';
import Grocefy from '../../../../assets/images/grocefyLogoAlone.png';
import api from '../../../../api/api';

function CopyToDrawer() {
  const { Option } = Select;
  const businesses = useRecoilValueLoadable(businessesSelector);
  const [showCopyToDrawer, setShowCopyToDrawerAtom] = useRecoilState(showCopyToDrawerAtom);
  const [sourceStore, setSourceStore] = useState(null);
  const [destinationStore, setDestinationStore] = useState(null);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const showMessage = function (message) {
    notification.open({
      message: '',
      description: (
        <Comment
          author={<span>Grocefy</span>}
          avatar={<Avatar src={Grocefy} alt="grocefy" />}
          content={
            <p className="text-sm">
              {message}
            </p>
          }
          datetime={
            <Tooltip title={moment().format('YYYY-MM-DD HH:mm:ss')}>
              <span>{moment().fromNow()}</span>
            </Tooltip>
          }
        />
      ),
    });
  };

  const submit = function () {
    setLoading(true);
    api.post(
      `businesses/catalog/copyto/${sourceStore}`,
      [destinationStore],
    )
      .then((response) => {
        setLoading(false);
        showMessage(response.data.message);
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
        showMessage(error);
      });
  };

  useEffect(() => {
    if (sourceStore) {
      const temp = _.filter(businesses?.contents?.data?.data?.results,
        (s) => s.id !== sourceStore);
      if (temp && temp.length > 0) {
        setDestinationStore(temp[0]?.id);
      }
    }
  }, [sourceStore]);

  useEffect(() => {
    if (businesses?.contents?.data?.data?.results) {
      setSourceStore(businesses?.contents?.data?.data?.results[0].id);
    }
  }, []);

  useEffect(() => {
    if (businesses?.contents?.data?.data?.results) {
      setSourceStore(businesses?.contents?.data?.data?.results[0].id);
    }
  }, [businesses]);

  return (
    <Drawer
      title={t('copy_catalog')}
      placement="right"
      closable
      onClose={() => setShowCopyToDrawerAtom(false)}
      open={showCopyToDrawer}
      width={350}
    >
      <>
        <div>
          <strong>{t('source_store')}: </strong>
          <br />
          <Select
            value={sourceStore}
            disabled={loading}
            style={{ width: '100%' }}
            onChange={(obj) => setSourceStore(obj)}
          >
            {_.map(businesses?.contents?.data?.data?.results, (business) => (
              <Option
                value={business.id}
                key={business.id}
              >
                {business.name}
              </Option>
            ))}
          </Select>
        </div>
        <br />
        <div>
          <strong>{t('destination_store')}: </strong>
          <br />
          <Select
            value={destinationStore}
            disabled={loading}
            style={{ width: '100%' }}
            onChange={(obj) => setDestinationStore(obj)}
          >
            {_.map(_.filter(businesses?.contents?.data?.data?.results,
              (s) => s.id !== sourceStore), (business) => (
                <Option
                  value={business.id}
                  key={business.id}
                >
                  {business.name}
                </Option>
            ))}
          </Select>
        </div>
        <div
          style={{
            float: 'right',
            margin: '10px 0'
          }}
        >
          <Button
            type="primary"
            loading={loading}
            disabled={!sourceStore && !destinationStore}
            onClick={() => { submit(); }}
          >
            {t('submit')}
          </Button>
        </div>
      </>
    </Drawer>
  );
}
export default CopyToDrawer;
