import React, { useState } from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import {
  Button,
} from 'antd';
import envConfig from '../../envConfig';

function LanguageSwitcher({ className, small }) {
  const smallProp = small === undefined ? false : small;
  const { i18n, t } = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);
  const changeLanguage = function (lang) {
    setSelectedLanguage(lang);
    i18n.changeLanguage(lang).then(() => {
      window.localStorage.setItem(
        `${envConfig.REACT_APP_PREFIX}-admin-lang`,
        JSON.stringify(lang)
      );
    });
  };
  return (
    <div className={className} id="first-step">
      <Button
        size={smallProp ? 'small' : 'middle'}
        type={selectedLanguage === 'en' ? 'primary' : 'default'}
        onClick={() => changeLanguage('en')}
        style={{
          borderTopLeftRadius: '0.75em',
          borderBottomLeftRadius: '0.75em',
          borderTopRightRadius: '0px',
          borderBottomRightRadius: '0px'
        }}
        className="w-36 border-none text-xs"
      >
        English
      </Button>
      <Button
        size={smallProp ? 'small' : 'middle'}
        type={selectedLanguage === 'es' ? 'primary' : 'default'}
        onClick={() => changeLanguage('es')}
        style={{
          marginLeft: '-1px',
          borderTopLeftRadius: '0px',
          borderBottomLeftRadius: '0px',
          borderTopRightRadius: '0.75em',
          borderBottomRightRadius: '0.75em'
        }}
        className="w-36 border-none text-xs"
      >
        Español
      </Button>
    </div>
  );
}

export default LanguageSwitcher;
