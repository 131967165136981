import React from 'react';
import OrderHeader from './shared-components/OrderHearder';
import OrderSearch from './shared-components/OrderSearchV2';

function Orders() {
  return (
    <div>
      <OrderHeader />
      <OrderSearch />
    </div>
  );
}

export default Orders;
