import React from 'react';
import { useRecoilValueLoadable } from 'recoil';
import { List, Button, notification, Avatar } from 'antd';
import { Comment } from '@ant-design/compatible';
import { PageHeader } from '@ant-design/pro-layout';
import { useTranslation } from 'react-i18next';
import { DownloadOutlined, CopyOutlined } from '@ant-design/icons';
import download from '../../../assets/images/download.png';
import Grocefy from '../../../assets/images/grocefyLogoAlone.png';
import { profileAtom } from '../../../atoms/Atoms';
import { configSelector } from '../../../atoms/Selectors';

function Downloads() {
  const configData = useRecoilValueLoadable(configSelector);
  const profile = useRecoilValueLoadable(profileAtom);
  const isAdmin =
    profile.contents && profile?.contents?.roles?.includes('SuperAdmin');
  const { t } = useTranslation();

  return (
    <div>
      <PageHeader title={t('downloads')} avatar={{ src: download }} />
      <List
        size="small"
        header={
          <div>
            <b>Software</b>
          </div>
        }
        bordered
        loading={configData.state === 'loading'}
        dataSource={
          configData.state === 'hasValue' && configData.contents.data.downloads
        }
        renderItem={(item) => (
          <List.Item className="flex flex-column">
            <div>
              <div className="font-bold">{item.name}</div>
              <div className="w-44 sm:w-80">{item.description}</div>
            </div>
            <div className="md:mx-2">
              <Button
                className="ml-0 md:ml-2 mt-2 w-26"
                type="primary"
                size="small"
                target="_blank"
                href={item.url}
                icon={<DownloadOutlined />}
              >
                {t('download')}
              </Button>
              {' '}
              <Button
                size="small"
                type="primary"
                className="ml-0 md:ml-2 mt-2 w-26"
                icon={<CopyOutlined />}
                onClick={() => {
                  navigator.clipboard.writeText(item.url);
                  notification.open({
                    message: 'Alert!',
                    description: (
                      <Comment
                        author={<span>Grocefy</span>}
                        avatar={<Avatar src={Grocefy} alt="grocefy" />}
                        content={
                          <p className="text-sm">{t('url_copied_content')}</p>
                        }
                      />
                    ),
                  });
                }}
              >
                {t('copy_url')}
              </Button>
            </div>
          </List.Item>
        )}
      />
      <br /> <br />
      {isAdmin && (
        <List
          size="small"
          header={
            <div>
              <b>{t('internal_software')}</b>
            </div>
          }
          bordered
          loading={configData.state === 'loading'}
          dataSource={
            configData.state === 'hasValue' &&
            configData.contents.data.internal_downloads
          }
          renderItem={(item) => (
            <List.Item className="flex flex-column">
              <div>
                <div className="font-bold">{item.name}</div>
                <div className="w-44 sm:w-80">{item.description}</div>
              </div>
              <div>
                <Button
                  size="small"
                  className="ml-2 md:ml-0 mt-2 w-26"
                  type="primary"
                  icon={<DownloadOutlined />}
                  onClick={() => {
                    window.location.href = `${item.url}`;
                  }}
                >
                  {t('download')}
                </Button>
                {' '}
                <Button
                  size="small"
                  className="ml-2 md:ml-2 mt-2 w-26"
                  type="primary"
                  icon={<CopyOutlined />}
                  onClick={() => {
                    navigator.clipboard.writeText(item.url);
                    notification.open({
                      message: 'Alert!',
                      description: (
                        <Comment
                          author={<span>Grocefy</span>}
                          avatar={<Avatar src={Grocefy} alt="grocefy" />}
                          content={
                            <p className="text-sm">{t('url_copied_content')}</p>
                          }
                        />
                      ),
                    });
                  }}
                >
                  {t('copy_url')}
                </Button>
              </div>
            </List.Item>
          )}
        />
      )}
    </div>
  );
}

export default Downloads;
