import React, { useEffect } from 'react';
import dayjs from 'dayjs';
import { useMount } from 'react-use';
import _ from 'lodash';
import moment from 'moment';
import { Button, Select, DatePicker, Switch } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValueLoadable } from 'recoil';
import {
  dashboardOptionAtom,
  dashboardSelectedStoreAtom,
  dashboardPayloadAtom
} from '../../../../atoms/Atoms';
import { businessesSelector } from '../../../../atoms/Selectors';
import { dateRangePresets } from '../../../utils/utils';

function Header() {
  const { t } = useTranslation();
  const { RangePicker } = DatePicker;
  const { Option } = Select;
  const history = useHistory();
  const [dashboardOption, setDashboardOption] = useRecoilState(dashboardOptionAtom);
  const [selectedStoreId, setSelectedStoreId] = useRecoilState(dashboardSelectedStoreAtom);
  const businesses = useRecoilValueLoadable(businessesSelector);
  const [dashboardPayload, setDashboardPayload] = useRecoilState(dashboardPayloadAtom);

  function isValidOptionForDatepicker() {
    return dashboardOption === 0 || dashboardOption === 6 || dashboardOption === 8;
  }

  useEffect(() => {
    setDashboardPayload({
      start: moment().startOf('week').format('YYYY-MM-DD'),
      end: moment().endOf('week').format('YYYY-MM-DD'),
      isLifetime: false,
      load: false
    });
  }, []);

  return (
    <PageHeader
      className="mb-4 px-0"
      title="Global Dashboard"
      extra={
        <div className="grid ml-8 md:ml-0 md:flex md:space-x-2 space-y-2">
          {isValidOptionForDatepicker() && (
            <div className="grid md:flex space-y-2">
              {!dashboardPayload?.isLifetime && (
                <RangePicker
                  format="YYYY-MM-DD"
                  value={[
                    dayjs(dashboardPayload?.start),
                    dayjs(dashboardPayload?.end)
                  ]}
                  presets={dateRangePresets(t)}
                  onChange={(date, dateString) => {
                    setDashboardPayload({
                      ...dashboardPayload,
                      start: dateString[0],
                      end: dateString[1]
                    });
                  }}
                />
              )}
              <div className="mt-1 mx-auto md:mx-1">
                <span className="mt-2 md:ml-1">{t('show_lifetime')}</span>
                <Switch
                  className="md:ml-1"
                  checked={dashboardPayload?.isLifetime}
                  onChange={(checked) => {
                    setDashboardPayload({
                      ...dashboardPayload,
                      isLifetime: checked
                    });
                  }}
                />
              </div>
            </div>
          )}
          <div className="grid md:flex mx-auto space-y-2 md:space-y-0 md:space-x-2">
            <Select
              value={dashboardOption}
              style={{ width: 160 }}
              onChange={(obj) => setDashboardOption(obj)}
            >
              <Option value={0}>{t('sales')}</Option>
              <Option value={6}>{t('customers')}</Option>
              <Option value={4}>{t('trends')}</Option>
              <Option value={7}>{t('prep_inventory')}</Option>
              <Option value={8}>{t('top_products')}</Option>
            </Select>
            {/* <br className="hidden md:grid" /> */}
            <Select
              value={selectedStoreId}
              style={{ width: 160 }}
              onChange={(obj) => setSelectedStoreId(obj)}
            >
              <Option value="">{t('all_stores')}</Option>
              {_.map(businesses?.contents?.data?.data?.results, (b) => (
                <Option key={b.id} value={b.id}>{b.name}</Option>
              ))}
            </Select>
            {/* <br className="hidden md:block" /> */}
            <Button
              type="primary"
              onClick={() => {
                history.push('/dashboardlegacy');
              }}
            >
              {t('go_to_legacy')}
            </Button>
          </div>
        </div>
      }
    />
  );
}

export default Header;
