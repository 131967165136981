import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import _ from 'lodash';
import { useJsonToCsv } from 'react-json-csv';
import {
  Badge,
  Button,
  Col,
  Row,
  Table,
  Statistic,
  Typography,
  Tag,
  Space,
  Popover,
} from 'antd';
import { SearchOutlined, CloudDownloadOutlined } from '@ant-design/icons';
import { useRecoilValue, useRecoilValueLoadable } from 'recoil';
import {
  todayLossAnalyticsSelector,
  todayLossOverSubsSelector,
} from '../../../../atoms/Selectors';
import { timezoneAtom } from '../../../../atoms/Atoms';
import {
  calculateContribution
} from '../../../utils/functions';

function TodaySubsOverBody() {
  const { t } = useTranslation();
  const showBeta = false;
  const [current, setCurrent] = useState(1);
  const { saveAsCsv } = useJsonToCsv();
  const timezone = useRecoilValue(timezoneAtom);
  const under = useRecoilValueLoadable(todayLossOverSubsSelector);
  const totals = useRecoilValueLoadable(todayLossAnalyticsSelector);
  const [rows, setRows] = useState(10);

  function mapData(data) {
    return _.map(data, (p, index) => ({
      key: index,
      originalAmount: p.originalAmount,
      finalAmount: p.amount,
      originalPrice: p.originalPrice,
      price: p.price,
      profit: p.profit,
      scan: p.weightUpc,
      upc: p.upc,
      code: p.confirmationCode,
      subName: p.subName,
      subAmount: p.subAmount,
      subPrice: p.subPrice,
      name: `${p.brand} ${p.name} ${p.description}`,
      subUpc: p.subUpc,
      realPrice: p.realPrice,
      diffPct: (
        -100 *
        ((p.originalAmount - p.amount) / p.originalAmount)
      ).toFixed(2),
      diff: p.diff,
    }));
  }

  const listFields = {
    count: t('count'),
    upc: 'UPC',
    price: t('price'),
    name: t('name'),
    diff: t('loss'),
    profit: t('profit'),
  };

  return (
    <div className="p-4 border bg-gray-100 rounded-lg mb-4">
      <Row align="middle" gutter={[8, 8]} justify="space-between">
        <Col>
          <Typography.Title level={5}>{t('substitutes')}</Typography.Title>
          <Typography.Text type="secondary">
            {t('subs_over_today_desc')}
          </Typography.Text>
        </Col>
        <Col>
          {under.state === 'hasValue' && totals.state === 'hasValue' ? (
            <div className="flex flex-row">
              {' '}
              <div className="mr-10">
                <Statistic
                  title={t('orders')}
                  value={`${
                    under.contents && under.contents.data.data.length > 0
                      ? under.contents.data.data[0].orderCount
                      : 0
                  }`}
                />
              </div>
              <div className="mr-10">
                <Statistic
                  title={t('items')}
                  value={`${
                    under.contents &&
                    under.contents.data.data.length > 0 &&
                    under.contents.data.data[0].items
                      ? under.contents.data.data[0].items.length
                      : 0
                  }`}
                />
              </div>
              <div className="mr-10">
                <Statistic
                  title={`${t('loss')} $`}
                  value={`$${
                    under.contents && under.contents.data.data.length > 0
                      ? under.contents.data.data[0].diff.toFixed(2)
                      : 0
                  }`}
                />
              </div>
              <div className="mr-10">
                {' '}
                <Statistic
                  title={`${t('loss')} %`}
                  value={
                    under.contents && totals.contents
                      ? calculateContribution(totals?.contents?.data?.data[0]?.originalTotal,
                        under?.contents?.data?.data[0]?.diff)
                      : 0
                  }
                />
              </div>
            </div>
          ) : null}
        </Col>
        <Col>
          {showBeta === false && (
            <Button
              size="small"
              icon={<CloudDownloadOutlined />}
              onClick={() => {
                const data = mapData(under.contents.data.data[0].items);
                saveAsCsv({
                  data,
                  fields: listFields,
                  filename: `subs_over${new Date().toLocaleDateString()}`,
                });
              }}
            >
              {t('export_list')}
            </Button>
          )}
        </Col>
      </Row>
      <Row />
      <Row>
        <Col xs={24}>
          <Table
            size="small"
            loading={under.state === 'loading'}
            bordered
            scroll={{ x: 500 }}
            pagination={{
              current,
              hideOnSinglePage: true,
              showSizeChanger: true,
              size: 'small',
              pageSize: rows,
              total:
                under.state === 'hasValue' && under.contents.data.data.count,
              pageSizeOptions: [10, 20, 30, 40, 50],
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} ${t('out_of')} ${total} ${t('items')}`,
              onShowSizeChange: (page, size) => {
                setRows(size);
              },
              onChange: (page) => {
                setCurrent(page);
              },
            }}
            columns={[
              {
                title: t('requested_upc'),
                dataIndex: 'upc',
                key: 'upc',
                align: 'center',
                className: 'text-xs',
                render: (text) => (
                  <Typography.Text copyable>{text}</Typography.Text>
                ),
              },
              //   {
              //     title: 'Requested Amount',
              //     dataIndex: 'originalAmount',
              //     key: 'originalAmount',
              //     align: 'center',
              //     className: 'text-xs',
              //     render: (text) => <span>{text.toFixed(2)}</span>,
              //   },
              {
                title: t('requested_price'),
                dataIndex: 'originalPrice',
                key: 'originalPrice',
                align: 'center',
                className: 'text-xs',
                render: (text) => <span>${text.toFixed(2)}</span>,
              },
              {
                title: t('name'),
                dataIndex: 'name',
                key: 'name',
                align: 'left',
                className: 'text-xs font-medium',
                render: (text) => <span>{text}</span>,
              },
              {
                title: t('sub_upc'),
                dataIndex: 'subUpc',
                key: 'subUpc',
                align: 'left',
                className: 'text-xs font-medium',
                render: (text) => (
                  <Typography.Text copyable>{text}</Typography.Text>
                ),
              },
              {
                title: t('sub_price'),
                dataIndex: 'subPrice',
                key: 'subPrice',
                align: 'center',
                className: 'text-xs',
                render: (text) => <span>${text.toFixed(2)}</span>,
              },
              {
                title: t('sub_name'),
                dataIndex: 'subName',
                key: 'subName',
                align: 'left',
                className: 'text-xs font-medium',
                render: (text) => <span>{text}</span>,
              },

              {
                title: t('order'),
                dataIndex: 'code',
                key: 'code',
                align: 'left',
                className: 'text-xs font-medium',
                render: (text) => (
                  <Typography.Text copyable>{text}</Typography.Text>
                ),
              },
              {
                title: `${t('loss')} ($)`,
                dataIndex: 'diff',
                key: 'diff',
                align: 'left',
                className: 'text-xs font-medium',
                render: (text) => (
                  <Tag color={text < 0 ? 'red' : 'green'}>
                    {' '}
                    <span>{text.toFixed(2)}</span>
                  </Tag>
                ),
                sorter: (a, b) => a.diff - b.diff,
              },
              {
                title: `${t('profit')} ($)`,
                dataIndex: 'profit',
                key: 'profit',
                align: 'right',
                className: 'text-xs font-medium',
                render: (text) => (
                  <Tag color={text < 0 ? 'red' : ''}>
                    <span>{text.toFixed(2)}</span>
                  </Tag>
                ),
              },
              {
                title: t('regular_price'),
                dataIndex: 'realPrice',
                key: 'realPrice',
                align: 'left',
                className: 'text-xs font-medium',
                render: (text) => <span>${text.toFixed(2)}</span>,
              },
            ]}
            dataSource={
              under.state === 'hasValue' && under.contents.data.data[0]
                ? mapData(under.contents.data.data[0].items)
                : null
            }
            expandable={{
              expandRowByClick: true,
              expandedRowRender: (record) => (
                <div className="p-4">
                  <Table
                    title={() => (
                      <div className="flex items-center justify-between">
                        <div>
                          <Badge
                            className="site-badge-count-109"
                            count={record.length}
                            style={{ backgroundColor: '#52c41a' }}
                          />
                          <span className="font-light uppercase text-xs ml-2">
                            {t('orders')}
                          </span>
                        </div>
                      </div>
                    )}
                    bordered
                    pagination={false}
                    columns={[
                      {
                        title: t('amount'),
                        dataIndex: 'amount',
                        key: 'amount',
                        align: 'center',
                        className: 'text-xs font-medium',
                        render: (text) => (
                          <span>
                            {text
                              .toFixed()
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                          </span>
                        ),
                      },
                      {
                        title: t('order'),
                        dataIndex: 'code',
                        key: 'code',
                        align: 'center',
                        className: 'text-xs',
                        render: (text) => (
                          <Typography.Text copyable>{text}</Typography.Text>
                        ),
                      },
                      {
                        title: t('total'),
                        dataIndex: 'total',
                        key: 'total',
                        align: 'center',
                        className: 'text-xs font-medium',
                        render: (text) => (
                          <span>
                            $
                            {text
                              .toFixed(2)
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                          </span>
                        ),
                      },
                      {
                        title: t('margin'),
                        dataIndex: 'pct',
                        key: 'pct',
                        align: 'center',
                        className: 'text-xs font-medium',
                        render: (text) => (
                          <span>
                            {text
                              .toFixed(1)
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            %
                          </span>
                        ),
                      },
                      {
                        title: t('date'),
                        dataIndex: 'date',
                        key: 'date',
                        align: 'center',
                        className: 'text-xs',
                        render: (text) => (
                          <span>
                            {moment(`${text}+0000`)
                              .tz(timezone)
                              .format('MMMM Do YYYY, h:mm:ss a')}
                          </span>
                        ),
                      },
                      {
                        title: '',
                        align: 'center',
                        className: 'text-xs',
                        render: (text, row) => (
                          <Space>
                            <Popover
                              title={t('details')}
                              placement="left"
                              content={
                                <div>
                                  <div className="text-xs">
                                    {t('code')}:{' '}
                                    <span className="font-bold">
                                      {row.code}
                                    </span>
                                  </div>
                                  <div className="text-xs">
                                    {t('Date')}:{' '}
                                    <span className="font-bold">
                                      {moment(`${row.date}+0000`)
                                        .tz(timezone)
                                        .format('MMMM Do YYYY, h:mm:ss a')}
                                    </span>
                                  </div>
                                  <div className="text-xs">
                                    {t('order_total')}: $
                                    <span className="font-bold">
                                      {row.total}
                                    </span>
                                  </div>
                                </div>
                              }
                            >
                              <Button
                                shape="circle"
                                size="small"
                                icon={<SearchOutlined />}
                                onClick={() => console.log(row)}
                              />
                            </Popover>
                          </Space>
                        ),
                      },
                    ]}
                    dataSource={record.items}
                  />
                </div>
              ),
              rowExpandable: (record) => record.length < -1,
            }}
          />
        </Col>
      </Row>
    </div>
  );
}

export default TodaySubsOverBody;
