import React, { useEffect, useState } from 'react';
import { useMount } from 'react-use';
import {
  useRecoilState,
} from 'recoil';
import { useTranslation } from 'react-i18next';
import {
  Drawer,
  Avatar,
  Input,
  Button,
  notification,
} from 'antd';
import { Comment } from '@ant-design/compatible';
import Grocefy from '../../../../assets/images/grocefyLogoAlone.png';
import {
  reloadSearchTagsAtom,
  setSelectedItemAtom,
  showEditSearchTermAtom
} from '../../../../atoms/Atoms';
import api from '../../../../api/api';

function EditItemDrawer() {
  const [selectedItem, setSelectedItem] = useRecoilState(setSelectedItemAtom);
  const [loading, setLoading] = useState(false);
  const [itemData, setItemData] = useState(null);
  const [showEditSearchTerm, setShowEditSearchTerm] = useRecoilState(showEditSearchTermAtom);
  const [reload, setReload] = useRecoilState(reloadSearchTagsAtom);
  const { t } = useTranslation();
  const width = window.innerWidth;

  const showMessage = function (message) {
    notification.open({
      message: '',
      description: (
        <Comment
          author={<span>Grocefy</span>}
          avatar={<Avatar src={Grocefy} alt="grocefy" />}
          content={
            <p className="text-sm">
              {message}
            </p>
          }
        />
      ),
    });
  };

  const updateItemClick = function () {
    setLoading(true);
    api.post(
      `businesses/catalog/${selectedItem.id}/update`,
      itemData
    )
      .then((response) => {
        setLoading(false);
        setShowEditSearchTerm(false);
        setReload(true);
        showMessage(t('search_tag_updated'));
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  const onChangeSearchTags = function (obj) {
    const temp = JSON.parse(JSON.stringify(itemData));
    temp.searchTags = obj.target.value;
    setItemData(temp);
  };

  useMount(() => {
    setItemData(selectedItem);
  });

  useEffect(() => {
    setItemData(selectedItem);
  }, [selectedItem]);

  return (
    <Drawer
      title={t('edit_item')}
      placement="right"
      closable
      onClose={() => setShowEditSearchTerm(false)}
      open={showEditSearchTerm}
      width={width < 1024 ? width : 500}
    >
      {selectedItem && (
        <>
          <div
            style={{
              float: 'right',
              marginBottom: '10px'
            }}
          >
            <Button
              style={{
                marginLeft: '10px'
              }}
              loading={loading}
              type="primary"
              onClick={() => { updateItemClick(); }}
            >
              {t('update')}
            </Button>
          </div>
          <div
            style={{ marginBottom: '10px' }}
          >
            <strong>{t('custom_search_tag')}</strong> <small>{t('custom_search_tag_note')}</small>
            <Input
              disabled={loading}
              placeholder={t('custom_search_tag')}
              onChange={(obj) => onChangeSearchTags(obj)}
              value={itemData?.searchTags}
            />
          </div>
          <br />
        </>
      )}
    </Drawer>
  );
}

export default EditItemDrawer;
