import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import {
  useRecoilState,
  useRecoilValue,
  useSetRecoilState
} from 'recoil';
import moment from 'moment';
import dayjs from 'dayjs';
import {
  InboxOutlined,
  CloseOutlined,
  PlusOutlined,
  DownOutlined
} from '@ant-design/icons';
import {
  Input,
  Modal,
  Button,
  Upload,
  Image,
  DatePicker,
  Dropdown,
  Menu,
  Tag,
  Avatar,
  Tooltip,
  notification,
  Divider
} from 'antd';
import { Comment } from '@ant-design/compatible';
import { PageHeader } from '@ant-design/pro-layout';
import MDEditor from '@uiw/react-md-editor';
import Grocefy from '../../../../assets/images/grocefyLogoAlone.png';
import {
  refreshTicketsAtom,
  selectedTicketAtom,
  selectedTicketBoardAtom,
  editTicketAtom,
  showTicketUserAssignAtom,
  ticketAssignedUserAtom
} from '../../../../atoms/Atoms';
import api from '../../../../api/api';

function EditTicketModal() {
  const statusList = [0, 1, 2, 3];
  const priorityList = [0, 1, 2, 3];
  const { TextArea } = Input;
  const { Dragger } = Upload;
  const setRefreshTickets = useSetRecoilState(refreshTicketsAtom);
  const setSelectedTicket = useSetRecoilState(selectedTicketAtom);
  const [showTicketUserAssign, setShowTicketUserAssign] = useRecoilState(showTicketUserAssignAtom);
  const [ticketAssignedUser, setTicketAssignedUser] = useRecoilState(ticketAssignedUserAtom);
  const [editTicket, setEditTicket] = useRecoilState(editTicketAtom);
  const selectedBoard = useRecoilValue(selectedTicketBoardAtom);
  const [loading, setLoading] = useState(false);
  const [ticketData, setTicketData] = useState(null);
  const [imageData, setImageData] = useState(null);
  const { t } = useTranslation();

  const showErrorMessage = function (errorMessage) {
    notification.open({
      message: '',
      description: (
        <Comment
          author={<span>Grocefy</span>}
          avatar={<Avatar src={Grocefy} alt="grocefy" />}
          content={
            <p className="text-sm">
              {errorMessage}
            </p>
          }
          datetime={
            <Tooltip title={moment().format('YYYY-MM-DD HH:mm:ss')}>
              <span>{moment().fromNow()}</span>
            </Tooltip>
          }
        />
      ),
    });
  };
  const getStatusColor = function (status) {
    let bgColor = '';
    let color = '';
    if (status === 0) {
      bgColor = '#dddddd';
      color = '#000000';
    } else if (status === 1) {
      bgColor = '#EED202';
      color = '#000000';
    } else if (status === 2) {
      bgColor = '#008000';
      color = '#ffffff';
    } else if (status === 3) {
      bgColor = '#ff0033';
      color = '#ffffff';
    }
    return { bg: bgColor, color };
  };
  const getStatusName = function (status) {
    let name = 'Pending';
    if (status === 1) {
      name = 'Started';
    } else if (status === 2) {
      name = 'Completed';
    } else if (status === 3) {
      name = 'Rejected';
    }
    return name;
  };
  const getPriorityName = function (priority) {
    let name = 'Not set';
    if (priority === 0) {
      name = 'Low';
    } else if (priority === 1) {
      name = 'Medium';
    } else if (priority === 2) {
      name = 'High';
    } else if (priority === 3) {
      name = 'Critical';
    }
    return name;
  };
  const closeTicketModel = function () {
    if (!loading) {
      setEditTicket(null);
    }
  };
  const onChangeName = function (obj) {
    setTicketData({
      ...ticketData,
      name: obj.target.value
    });
  };
  const removeImage = function () {
    setTicketData({
      ...ticketData,
      imageUrl: '',
      removeImage: true
    });
  };
  const onChangeDescription = function (obj) {
    setTicketData({
      ...ticketData,
      description: obj
    });
  };
  const onChangeDueDate = function (date, dateString) {
    setTicketData({
      ...ticketData,
      dueDate: dateString
    });
  };
  const onChangeSuggestedPriority = function (priority) {
    setTicketData({
      ...ticketData,
      suggestedPriority: priority
    });
  };
  const onChangeAssignedPriority = function (priority) {
    setTicketData({
      ...ticketData,
      assignedPriority: priority
    });
  };
  const onChangeStatus = function (status) {
    setTicketData({
      ...ticketData,
      status
    });
  };
  const removeAssignedUserClick = function () {
    setTicketData({
      ...ticketData,
      assignedTo: null,
      assignedToUserId: null
    });
  };
  const createTicketRequest = function () {
    setLoading(true);
    const data = {
      Priority: ticketData.suggestedPriority,
      Name: ticketData.name,
      Description: ticketData.description,
      DueDate: ticketData.dueDate === '' ? null : ticketData.dueDate,
    };
    api.post(
      `ticketboards/board/${selectedBoard.id}`,
      data
    )
      .then((response) => {
        setLoading(false);
        if (response.data.success) {
          const newTicket = response.data.data;
          setSelectedTicket(newTicket);
          setEditTicket(null);
          setRefreshTickets(true);
        } else {
          showErrorMessage(response.data.error);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };
  const updateTicketRequest = function (ticket) {
    setLoading(true);
    api.put(
      `ticketboards/ticket/${ticketData.id}`,
      ticket
    )
      .then((response) => {
        setLoading(false);
        if (response.data.success) {
          setRefreshTickets(true);
          const newTicket = response.data.data;
          setEditTicket(null);
          if (ticket.IsDeleted) {
            setSelectedTicket(null);
          } else {
            setSelectedTicket(newTicket);
          }
        } else {
          showErrorMessage(response.data.error);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };
  const updateTicketClick = function () {
    const data = {
      id: ticketData.id,
      SuggestedPriority: ticketData.suggestedPriority,
      AssignedPriority: ticketData.assignedPriority,
      Status: ticketData.status,
      Name: ticketData.name,
      Description: ticketData.description,
      DueDate: ticketData.dueDate === '' ? null : ticketData.dueDate,
      AssignedToUserId: ticketData.assignedToUserId,
      UpdateDueDate: true,
      UpdateAssignedUser: true,
      RemoveImage: ticketData.removeImage ? ticketData.removeImage : false
    };
    updateTicketRequest(data);
  };
  const deleteTicketClick = function () {
    const data = {
      id: ticketData.id,
      IsDeleted: true
    };
    updateTicketRequest(data);
  };
  useEffect(() => {
    setTicketData(editTicket);
  }, [editTicket]);

  const draggerProps = {
    name: 'file',
    multiple: false,
    showUploadList: false,
    beforeUpload: (file) => {
      setImageData(file);
      return false;
    }
  };

  useEffect(() => {
    if (imageData) {
      const file = imageData;
      const boundary = Date.now();
      const data = new FormData();
      data.append('file', file);
      setLoading(true);
      api
        .post(`ticketboards/ticket/${ticketData.id}/image`, data)
        .then((response) => {
          setLoading(false);
          setImageData(null);
          if (response.data.success) {
            const temp = JSON.parse(JSON.stringify(ticketData));
            temp.imageUrl = response.data.data;
            setTicketData(temp);
          } else {
            showErrorMessage(response.data.error);
          }
        })
        .catch((error) => {
          setLoading(false);
          setImageData(null);
          console.error(error);
        });
    }
  }, [imageData]);

  useEffect(() => {
    if (showTicketUserAssign && ticketAssignedUser) {
      const temp = JSON.parse(JSON.stringify(ticketData));
      temp.assignedTo = ticketAssignedUser;
      temp.assignedToUserId = ticketAssignedUser.id;
      setTicketData(temp);
      setShowTicketUserAssign(false);
      setTicketAssignedUser(null);
    }
  }, [showTicketUserAssign, ticketAssignedUser]);

  return (
    <>
      {ticketData && (
        <Modal
          closable="true"
          centered="true"
          open={editTicket}
          onCancel={() => closeTicketModel()}
          footer={[
            <Button
              key="updateButton"
              hidden={!ticketData.id}
              disabled={loading}
              onClick={() => updateTicketClick()}
            >
              {t('update')}
            </Button>,
            <Button
              key="createButton"
              hidden={ticketData.id}
              disabled={loading}
              onClick={() => createTicketRequest()}
            >
              {t('create')}
            </Button>,
            <Button
              key="back"
              disabled={loading}
              onClick={() => closeTicketModel()}
            >
              {t('close')}
            </Button>
          ]}
        >
          <PageHeader
            title={ticketData.id ? t('edit_ticket') : t('create_ticket')}
            extra={[
              <Dropdown
                key="selectedTicketModelStaus"
                overlay={
                  <Menu selectedKeys={ticketData ? [ticketData.status] : [0]}>
                    {_.map(statusList, (b, index) => (
                      <Menu.Item
                        key={`status${index}${b}`}
                        className="text-xs flex items-center"
                        onClick={() => {
                          onChangeStatus(b);
                        }}
                      >
                        {getStatusName(b)}
                      </Menu.Item>
                    ))}
                  </Menu>
                }
                arrow="true"
              >
                <Tag
                  hidden={!ticketData.id}
                  color={getStatusColor(ticketData.status).bg}
                  style={{ color: getStatusColor(ticketData.status).color }}
                >
                  {getStatusName(ticketData.status)}
                  <DownOutlined
                    style={{ marginLeft: 10 }}
                  />
                </Tag>
              </Dropdown>,
              <Tag
                key="deleteTicketButton"
                hidden={!ticketData.id}
                onClick={() => deleteTicketClick()}
                color="#ff0033"
                style={{ color: '#ffffff' }}
              >
                {t('delete')}
              </Tag>
            ]}
          />
          <PageHeader
            title=""
            extra={[
              <Dropdown
                key="assignedPriority"
                overlay={
                  <Menu selectedKeys={ticketData &&
                    ticketData.assignedPriority ?
                    [ticketData.assignedPriority] : [null]}
                  >
                    <Menu.Item
                      className="text-xs flex items-center"
                      onClick={() => {
                        onChangeAssignedPriority(null);
                      }}
                    >
                      {getPriorityName(null)}
                    </Menu.Item>
                    {_.map(priorityList, (b, index) => (
                      <Menu.Item
                        key={`priority${index}${b}`}
                        className="text-xs flex items-center"
                        onClick={() => {
                          onChangeAssignedPriority(b);
                        }}
                      >
                        {getPriorityName(b)}
                      </Menu.Item>
                    ))}
                  </Menu>
                }
                arrow="true"
              >
                <Tag hidden={!ticketData.id}>
                  {t('assigned')}: {getPriorityName(ticketData.assignedPriority)}
                  <DownOutlined
                    style={{ marginLeft: 10 }}
                  />
                </Tag>
              </Dropdown>,
              <Dropdown
                key="suggestedPriority"
                overlay={
                  <Menu selectedKeys={ticketData ? [ticketData.suggestedPriority] : [0]}>
                    {_.map(priorityList, (b, index) => (
                      <Menu.Item
                        key={`priority${index}${b}`}
                        className="text-xs flex items-center"
                        onClick={() => {
                          onChangeSuggestedPriority(b);
                        }}
                      >
                        {getPriorityName(b)}
                      </Menu.Item>
                    ))}
                  </Menu>
                }
                arrow="true"
              >
                <Tag>
                  Suggested: {getPriorityName(ticketData.suggestedPriority)}
                  <DownOutlined
                    style={{ marginLeft: 10 }}
                  />
                </Tag>
              </Dropdown>,
            ]}
          />
          {ticketData.id && (
            <>
              <Dragger {...draggerProps}>
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">
                  {t('click_or_drag_content')}
                </p>
              </Dragger>
              {ticketData.imageUrl && (
                <>
                  <Image
                    alt={ticketData.name}
                    src={ticketData.imageUrl}
                    height={75}
                  />
                  <br />
                  <Button onClick={() => removeImage()}>
                    {t('remove')}
                  </Button>
                </>
              )}
              <Divider />
            </>
          )}
          <Input
            placeholder={t('name')}
            onChange={(obj) => onChangeName(obj)}
            value={ticketData.name}
          />
          <MDEditor
            preview="edit"
            value={ticketData.description}
            onChange={(obj) => onChangeDescription(obj)}
          />
          <Divider />
          <strong>{t('due_date')}: </strong>
          <DatePicker
            onChange={(date, dateString) => onChangeDueDate(date, dateString)}
            defaultValue={
              !ticketData.dueDate
              || ticketData.dueDate === ''
              || ticketData.dueDate === null ?
                '' : dayjs(ticketData.dueDate)
            }
          />
          <Divider />
          {ticketData.id && (
            <>
              <div>
                <strong className="bold">{t('assigned_to')}: </strong>
                {ticketData.assignedTo && (
                  <>
                    <Tag
                      onClick={() => setShowTicketUserAssign(true)}
                    >
                      <Avatar
                        src={
                          <Image
                            src={
                              ticketData.assignedTo.avatar ? ticketData.assignedTo.avatar : null
                            }
                          />
                        }
                        style={{ marginRight: '10px' }}
                      >
                        {ticketData.assignedTo.firstName[0]}{ticketData.assignedTo.lastName[0]}
                      </Avatar>
                      {ticketData.assignedTo.completeName}
                    </Tag>
                    <CloseOutlined
                      onClick={() => removeAssignedUserClick()}
                    />
                  </>
                )}
                {!ticketData.assignedTo && (
                  <Tag
                    onClick={() => setShowTicketUserAssign(true)}
                  >
                    {t('assign')} <PlusOutlined />
                  </Tag>
                )}
              </div>
            </>
          )}
        </Modal>
      )}
    </>
  );
}

export default EditTicketModal;
