import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import {
  useRecoilState,
  useRecoilValueLoadable
} from 'recoil';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory } from 'react-router-dom';
import {
  Button,
  Table,
  Descriptions,
  Divider,
  DatePicker,
  InputNumber,
  Popconfirm,
  Pagination,
  Tabs,
  Collapse,
  Input,
  Select,
  Avatar,
  notification,
  Tooltip,
} from 'antd';
import { Comment } from '@ant-design/compatible';
import { PageHeader } from '@ant-design/pro-layout';
import DescriptionsItem from 'antd/lib/descriptions/Item';
import moment from 'moment';
import {
  selectedCouponAtom,
  profileAtom
} from '../../../atoms/Atoms';
// import couponEngine from '../../utils/GopanzaCouponService';
import couponEngine from '../../utils/GopanzaCouponServiceTest';
import api from '../../../api/api';
import Grocefy from '../../../assets/images/grocefyLogoAlone.png';

function Coupon() {
  const { RangePicker } = DatePicker;
  const { Option } = Select;
  const { Panel } = Collapse;
  const { TabPane } = Tabs;
  const { couponId } = useParams();
  const [selectedCoupon, setSelectedCoupon] = useRecoilState(selectedCouponAtom);
  const [loading, setLoading] = useState(false);
  const [selectedItemRequirement, setSelectedItemRequirement] = useState(null);
  const [sections, setSections] = useState([]);

  const [loadingSections, setLoadingSections] = useState(false);
  const [searchDiscountItems, setSearchDiscountItems] = useState([]);
  const [isSearchingDiscounts, setIsSearchingDiscounts] = useState(false);
  const [lastDiscountSearch, setLastDiscountSearch] = useState('');
  const [currentDiscountPage, setCurrentDiscountPage] = useState(0);
  const [discountSize, setDiscountSize] = useState(20);
  const [totalItemsInDiscountSearch, setTotalItemsInDiscountSearch] = useState(0);

  const [searchItems, setSearchItems] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [lastSearch, setLastSearch] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const [size, setSize] = useState(20);
  const [totalItemsInSearch, setTotalItemsInSearch] = useState(0);

  const profile = useRecoilValueLoadable(profileAtom);
  const isAdmin =
    profile.contents && profile?.contents?.roles?.includes('SuperAdmin');
  const history = useHistory();

  const { t } = useTranslation();

  if (!isAdmin) {
    history.push('/');
    return null;
  }

  const showMessage = function (message) {
    notification.open({
      message: '',
      description: (
        <Comment
          author={<span>Grocefy</span>}
          avatar={<Avatar src={Grocefy} alt="grocefy" />}
          content={
            <p className="text-sm">
              {`${message}`}
            </p>
          }
          datetime={
            <Tooltip title={moment().format('YYYY-MM-DD HH:mm:ss')}>
              <span>{moment().fromNow()}</span>
            </Tooltip>
          }
        />
      ),
    });
  };

  const addItem = function (item) {
    const temp = JSON.parse(JSON.stringify(selectedCoupon));

    if (_.filter(temp.itemRequirements[selectedItemRequirement].items,
      (i) => i.id === item.id).length > 0) {
      showMessage('Item is already added to this list');
    } else {
      temp.itemRequirements[selectedItemRequirement].items.push({
        id: item.id,
        image: item.itemImage,
        name: item.name,
        brand: item.brand,
        description: item.description,
      });
      setSelectedCoupon(temp);
    }
  };

  const addDiscountItem = function (item) {
    const temp = JSON.parse(JSON.stringify(selectedCoupon));

    if (_.filter(temp.discounts,
      (i) => i.id === item.id).length > 0) {
      showMessage('Item is already added to this list');
    } else {
      temp.discounts.push({
        id: item.id,
        image: item.itemImage,
        name: item.name,
        brand: item.brand,
        description: item.description,
        value: 0,
        discountType: 0
      });
      setSelectedCoupon(temp);
    }
  };

  const searchProducts = function (query, page) {
    setIsSearching(true);
    const payload = {
      Query: query,
      Page: page,
      Size: size
    };
    api
      .post(
        'businesses/catalog/search',
        payload
      )
      .then((response) => {
        if (response.data.success) {
          setIsSearching(false);
          setTotalItemsInSearch(response.data.data.count);
          setSearchItems(response.data.data.items);
        } else {
          showMessage(response.data.error);
        }
      })
      .catch((error) => {
        setIsSearching(false);
        showMessage(error);
      });
  };

  const searchDiscountProducts = function (query, page) {
    setIsSearchingDiscounts(true);
    const payload = {
      Query: query,
      Page: page,
      Size: size
    };
    api
      .post(
        'businesses/catalog/search',
        payload
      )
      .then((response) => {
        if (response.data.success) {
          setIsSearchingDiscounts(false);
          setTotalItemsInDiscountSearch(response.data.data.count);
          setSearchDiscountItems(response.data.data.items);
        } else {
          showMessage(response.data.error);
        }
      })
      .catch((error) => {
        setIsSearchingDiscounts(false);
        showMessage(error);
      });
  };

  const onTableChange = (page, pageSize) => {
    if (page - 1 !== currentPage) {
      setCurrentPage(page - 1);
      searchProducts(lastSearch, page - 1);
    } else {
      setSize(pageSize);
    }
  };

  const onTableChangeDiscount = (page, pageSize) => {
    if (page - 1 !== currentDiscountPage) {
      setCurrentDiscountPage(page - 1);
      searchDiscountProducts(lastDiscountSearch, page - 1);
    } else {
      setDiscountSize(pageSize);
    }
  };

  const onSearch = async (value) => {
    setLastSearch(value);
    setCurrentPage(0);
    searchProducts(value, 0);
  };

  const onSearchDiscount = async (value) => {
    setLastDiscountSearch(value);
    setCurrentDiscountPage(0);
    searchDiscountProducts(value, 0);
  };

  const getSections = function () {
    setLoadingSections(true);
    api
      .get('businesses/menusections')
      .then((response) => {
        setLoadingSections(false);
        if (response.data.success) {
          const sectionsTemp = JSON.parse(JSON.stringify(response.data.data.results));
          if (selectedCoupon?.sectionRequirements?.length > 0) {
            for (let j = 0; j < selectedCoupon?.sectionRequirements?.length; j++) {
              for (let i = 0; i < sectionsTemp.length; i++) {
                if (selectedCoupon.sectionRequirements[j].id === sectionsTemp[i].id) {
                  sectionsTemp[i].added = true;
                  sectionsTemp[i].minimumPrice =
                    selectedCoupon.sectionRequirements[j].minimumPrice;
                }
              }
            }
          } else {
            for (let i = 0; i < sectionsTemp.length; i++) {
              sectionsTemp[i].added = false;
              sectionsTemp[i].minimumPrice = 0;
            }
          }
          setSections(sectionsTemp);
        } else {
          showMessage(response.data.error);
        }
      })
      .catch((error) => {
        setLoadingSections(false);
        showMessage(error);
      });
  };

  const getCoupon = async function (id) {
    setLoading(true);
    const response = await couponEngine.getCoupon(id);
    setLoading(false);
    if (response.success) {
      setSelectedCoupon(response.data);
      getSections();
    }
  };

  const updateSections = function (sectionId) {
    const sectionsTemp = JSON.parse(JSON.stringify(sections));
    const couponTemp = JSON.parse(JSON.stringify(selectedCoupon));
    for (let i = 0; i < sectionsTemp.length; i++) {
      if (sectionsTemp[i].id === sectionId) {
        sectionsTemp[i].added = !sectionsTemp[i].added;
      }
    }
    setSections(sectionsTemp);
    const filtered = _.filter(sectionsTemp, (s) => s.added);
    couponTemp.sectionRequirements =
      _.map(filtered, (s) => ({ id: s.id, name: s.name, minimumPrice: s.minimumPrice }));
    setSelectedCoupon(couponTemp);
  };

  useEffect(() => {
    couponEngine.initialize('couponEngineSubscriptionKey', 'clientId', 'userId');
    if (couponId === 'new') {
      const newCoupon = {
        name: 'New Coupon',
        limit: 100,
        status: 0,
        start: moment().toISOString(),
        end: moment().toISOString(),
        businesses: [],
        itemRequirements: [],
        sectionRequirements: [],
        discounts: [],
      };
      setSelectedCoupon(newCoupon);
      getSections();
    } else {
      getCoupon(couponId);
    }
  }, []);

  return (
    <>
      <PageHeader
        onBack={() => window.history.back()}
        title={`Coupon / ${selectedCoupon ? selectedCoupon.name : ''}`}
        extra={[
          <Button
            type="primary"
            size="small"
            key="save"
            onClick={async () => {
              if (selectedCoupon.id) {
                setLoading(true);
                const response = await couponEngine.updateCoupon(selectedCoupon);
                setLoading(false);
                if (response.success) {
                  setSelectedCoupon(response.data);
                }
              } else {
                setLoading(true);
                const response = await couponEngine.createCoupon(selectedCoupon);
                setLoading(false);
                if (response.success) {
                  setSelectedCoupon(response.data);
                }
              }
            }}
          >
            {selectedCoupon?.id ? t('save') : t('create')}
          </Button>,
          <Popconfirm
            title={t('remove_coupon_content')}
            onConfirm={async () => {
              setLoading(true);
              const response = await couponEngine.deleteCoupon(selectedCoupon.id);
              setLoading(false);
              if (response.success) {
                setSelectedCoupon(response.data);
              }
            }}
            okText="Yes"
            cancelText="No"
          >
            <Button
              type="primary"
              danger
              size="small"
              key="delete"
              hidden={!selectedCoupon?.id}
            >
              {t('delete')}
            </Button>
          </Popconfirm>
        ]}
      />
      <Descriptions bordered>
        <DescriptionsItem label={t('name')}>
          <Input
            disabled={loading}
            value={selectedCoupon?.name}
            onChange={(obj) => {
              const temp = JSON.parse(JSON.stringify(selectedCoupon));
              temp.name = obj.target.value;
              setSelectedCoupon(temp);
            }}
          />
        </DescriptionsItem>
        <DescriptionsItem label={t('limit')}>
          <InputNumber
            min={0}
            disabled={loading}
            value={selectedCoupon?.limit}
            onChange={(obj) => {
              const temp = JSON.parse(JSON.stringify(selectedCoupon));
              temp.limit = obj;
              setSelectedCoupon(temp);
            }}
          />
        </DescriptionsItem>
        <br />
        <DescriptionsItem label={t('date_range')}>
          <RangePicker
            disabled={loading}
            showTime={{ format: 'HH:mm' }}
            format="YYYY-MM-DD HH:mm"
            value={[
              dayjs(selectedCoupon?.start, 'YYYY-MM-DD HH:mm'),
              dayjs(selectedCoupon?.end, 'YYYY-MM-DD HH:mm')
            ]}
            onChange={(date, dateString) => {
              const temp = JSON.parse(JSON.stringify(selectedCoupon));
              const start = dateString[0];
              const end = dateString[1];
              temp.start = start;
              temp.end = end;
              setSelectedCoupon(temp);
            }}
          />
        </DescriptionsItem>
        <DescriptionsItem label={t('status')}>
          <Select
            disabled={loading || !selectedCoupon?.id}
            style={{ width: 150 }}
            defaultValue={0}
            value={selectedCoupon?.status}
            onChange={(obj) => {
              const temp = JSON.parse(JSON.stringify(selectedCoupon));
              temp.status = obj;
              setSelectedCoupon(temp);
            }}
          >
            <Option value={0}>{t('pending_review')}</Option>
            <Option value={1}>{t('approved')}</Option>
            <Option value={2}>{t('rejected')}</Option>
          </Select>
        </DescriptionsItem>
      </Descriptions>
      <Collapse>
        <Panel header={t('item_requirements')} key="2">
          <>
            <Tabs
              activeKey={selectedItemRequirement}
              onTabClick={(key, mouseEvent) => {
                setSelectedItemRequirement(key);
              }}
              tabBarExtraContent={{
                right: (
                  <Button
                    type="primary"
                    size="small"
                    onClick={() => {
                      const newList = {
                        quantity: 1,
                        items: []
                      };
                      const temp = JSON.parse(JSON.stringify(selectedCoupon));
                      temp.itemRequirements.push(newList);
                      setSelectedCoupon(temp);
                    }}
                  >
                    + {t('new_list')}
                  </Button>
                )
              }}
            >
              {selectedCoupon && _.map(selectedCoupon.itemRequirements, (req, index) => (
                <TabPane tab={`List ${index + 1}`} key={index}>
                  <div>
                    <div style={{ float: 'left' }}>
                      <strong>Matches Needed: </strong>
                      <InputNumber
                        min={1}
                        value={req.quantity}
                        onChange={(obj) => {
                          const temp = JSON.parse(JSON.stringify(selectedCoupon));
                          temp.itemRequirements[selectedItemRequirement].quantity = obj;
                          setSelectedCoupon(temp);
                        }}
                        size="small"
                      />
                    </div>
                    <Popconfirm
                      title={t('remove_coupon_content')}
                      onConfirm={() => {
                        const temp = JSON.parse(JSON.stringify(selectedCoupon));
                        temp.itemRequirements.splice(selectedItemRequirement, 1);
                        setSelectedCoupon(temp);
                      }}
                      okText="Yes"
                      cancelText="No"
                    >
                      <Button
                        style={{ float: 'right' }}
                        type="primary"
                        danger
                        size="small"
                      >
                        {t('delete')}
                      </Button>
                    </Popconfirm>
                    <Table
                      size="small"
                      bordered
                      loading={loading}
                      dataSource={req.items}
                      rowKey="id"
                      columns={[
                        {
                          title: t('image'),
                          align: 'center',
                          className: 'text-xs',
                          render: (row) => (
                            <img
                              width={75}
                              src={row.image}
                              alt={`${row.brand} ${row.name} ${row.description}`}
                            />
                          ),
                        },
                        {
                          title: 'GTIN',
                          align: 'center',
                          className: 'text-xs',
                          render: (row) => (
                            <span>{row.gtin}</span>
                          ),
                        },
                        {
                          title: t('complete_name'),
                          align: 'center',
                          className: 'text-xs',
                          render: (row) => (
                            <span>{row.CompleteName}</span>
                          ),
                        },
                        {
                          title: '',
                          align: 'center',
                          className: 'text-xs',
                          render: (row) => (
                            <Popconfirm
                              title={t('remove_coupon_content')}
                              onConfirm={() => {
                                const temp = JSON.parse(JSON.stringify(selectedCoupon));
                                const tempItems =
                                  _.filter(temp.itemRequirements[selectedItemRequirement].items,
                                    (item) => item.id !== row.id);
                                temp.itemRequirements[selectedItemRequirement].items = tempItems;
                                setSelectedCoupon(temp);
                              }}
                              okText={t('yes')}
                              cancelText="No"
                            >
                              <Button
                                size="small"
                                type="primary"
                                danger
                              >
                                {t('delete')}
                              </Button>
                            </Popconfirm>
                          ),
                        },
                      ]}
                    />
                  </div>
                </TabPane>
              ))}
            </Tabs>
            <Divider />
            <Collapse>
              <Panel header={t('item_search')} key="search">
                <Input.Search
                  key="itemSearch"
                  placeholder={t('item_search_placeholder')}
                  allowClear
                  loading={isSearching}
                  disabled={!selectedItemRequirement ||
                    selectedItemRequirement > (selectedCoupon.itemRequirements.length - 1)}
                  enterButton={t('search')}
                  size="small"
                  onSearch={onSearch}
                />
                <Table
                  size="small"
                  bordered
                  loading={isSearching}
                  pagination={false}
                  dataSource={searchItems}
                  rowKey="id"
                  columns={[
                    {
                      title: t('image'),
                      align: 'center',
                      className: 'text-xs',
                      render: (row) => (
                        <img
                          width={75}
                          src={row.itemImage}
                          alt={`${row.brand} ${row.name} ${row.description}`}
                        />
                      ),
                    },
                    {
                      title: 'GTIN',
                      align: 'center',
                      className: 'text-xs',
                      render: (row) => (
                        <span>{row.gtin}</span>
                      ),
                    },
                    {
                      title: t('complete_name'),
                      align: 'center',
                      className: 'text-xs',
                      render: (row) => (
                        <span>{row.brand} {row.name} {row.description}</span>
                      ),
                    },
                    {
                      title: '',
                      align: 'center',
                      className: 'text-xs',
                      render: (row) => (
                        <Button
                          size="small"
                          type="primary"
                          disabled={row.isAssigned}
                          loading={row.loading}
                          onClick={() => addItem(row)}
                        >
                          {t('add')}
                        </Button>
                      ),
                    },
                  ]}
                />
                <Pagination
                  pageSize={size}
                  showSizeChanger
                  defaultCurrent={0}
                  current={currentPage + 1}
                  total={totalItemsInSearch}
                  onChange={onTableChange}
                />
              </Panel>
            </Collapse>
          </>
        </Panel>
        <Panel header={t('section_requirements')} key="3">
          <Table
            size="small"
            bordered
            loading={loadingSections}
            dataSource={sections}
            rowKey="id"
            columns={[
              {
                title: t('name'),
                align: 'center',
                className: 'text-xs',
                render: (row) => (
                  <span>{row.name}</span>
                ),
              },
              {
                title: 'Name Eng',
                align: 'center',
                className: 'text-xs',
                render: (row) => (
                  <span>{row.nameEng}</span>
                ),
              },
              {
                title: t('min_purchase'),
                align: 'center',
                className: 'text-xs',
                render: (row) => (
                  <InputNumber
                    placeholder="0.0"
                    formatter={(value) =>
                      `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    value={row.minimumPrice}
                    onChange={(obj) => {
                      const sectionsTemp = JSON.parse(JSON.stringify(sections));
                      const couponTemp = JSON.parse(JSON.stringify(selectedCoupon));
                      for (let i = 0; i < sectionsTemp.length; i++) {
                        if (sectionsTemp[i].id === row.id) {
                          sectionsTemp[i].minimumPrice = obj;
                        }
                      }
                      setSections(sectionsTemp);
                      const filtered = _.filter(sectionsTemp, (s) => s.added);
                      couponTemp.sectionRequirements =
                        _.map(filtered, (s) =>
                          ({ id: s.id, name: s.name, minimumPrice: s.minimumPrice }));
                      setSelectedCoupon(couponTemp);
                    }}
                  />
                ),
              },
              {
                title: '',
                align: 'center',
                className: 'text-xs',
                render: (row) => (
                  <>
                    {row.added && (
                      <Popconfirm
                        title="Are you sure to remove this section?"
                        onConfirm={() => {
                          updateSections(row.id);
                        }}
                        okText={t('yes')}
                        cancelText="No"
                      >
                        <Button
                          size="small"
                          type="primary"
                          danger
                        >
                          {t('delete')}
                        </Button>
                      </Popconfirm>
                    )}
                    {!row.added && (
                      <Button
                        size="small"
                        type="primary"
                        onClick={() => {
                          updateSections(row.id);
                        }}
                      >
                        {t('add')}
                      </Button>
                    )}
                  </>
                ),
              },
            ]}
          />
        </Panel>
        <Panel header={t('discounts')} key="4">
          <>
            <Table
              size="small"
              bordered
              loading={loading}
              dataSource={selectedCoupon?.discounts}
              rowKey="id"
              columns={[
                {
                  title: t('image'),
                  align: 'center',
                  className: 'text-xs',
                  render: (row) => (
                    <img
                      width={75}
                      src={row.image}
                      alt={`${row.brand} ${row.name} ${row.description}`}
                    />
                  ),
                },
                {
                  title: 'GTIN',
                  align: 'center',
                  className: 'text-xs',
                  render: (row) => (
                    <span>{row.gtin}</span>
                  ),
                },
                {
                  title: t('complete_name'),
                  align: 'center',
                  className: 'text-xs',
                  render: (row) => (
                    <span>{row.brand} {row.name} {row.description}</span>
                  ),
                },
                {
                  title: t('discount_type'),
                  align: 'center',
                  className: 'text-xs',
                  render: (row) => (
                    <Select
                      style={{ width: 150 }}
                      defaultValue={0}
                      value={row.discountType}
                      onChange={(obj) => {
                        const temp = JSON.parse(JSON.stringify(selectedCoupon));
                        for (let i = 0; i < temp.discounts.length; i++) {
                          if (temp.discounts[i].id === row.id) {
                            temp.discounts[i].discountType = obj;
                          }
                        }
                        setSelectedCoupon(temp);
                      }}
                    >
                      <Option value={0}>{t('fixed_value')}</Option>
                      <Option value={1}>{t('percentage_value')}</Option>
                    </Select>
                  ),
                },
                {
                  title: t('discount'),
                  align: 'center',
                  className: 'text-xs',
                  render: (row) => (
                    <InputNumber
                      placeholder="0.0"
                      formatter={(value) =>
                        `${row.discountType === 0 ? '$ ' : ''} ${value}${row.discountType === 0 ? '' : ' %'}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                      value={row.value}
                      onChange={(obj) => {
                        const temp = JSON.parse(JSON.stringify(selectedCoupon));
                        for (let i = 0; i < temp.discounts.length; i++) {
                          if (temp.discounts[i].id === row.id) {
                            temp.discounts[i].value = obj;
                          }
                        }
                        setSelectedCoupon(temp);
                      }}
                    />
                  ),
                },
                {
                  title: '',
                  align: 'center',
                  className: 'text-xs',
                  render: (row) => (
                    <Popconfirm
                      title="Are you sure to remove this item?"
                      onConfirm={() => {
                        const temp = JSON.parse(JSON.stringify(selectedCoupon));
                        const tempItems =
                          _.filter(temp.itemRequirements[selectedItemRequirement].items,
                            (item) => item.id !== row.id);
                        temp.itemRequirements[selectedItemRequirement].items = tempItems;
                        setSelectedCoupon(temp);
                      }}
                      okText={t('yes')}
                      cancelText="No"
                    >
                      <Button
                        size="small"
                        type="primary"
                        danger
                      >
                        {t('delete')}
                      </Button>
                    </Popconfirm>
                  ),
                },
              ]}
            />
            <Divider />
            <Collapse>
              <Panel header={t('item_search')} key="search">
                <Input.Search
                  key="itemSearch"
                  placeholder={t('item_search_placeholder')}
                  allowClear
                  loading={isSearchingDiscounts}
                  enterButton={t('search')}
                  size="small"
                  onSearch={onSearchDiscount}
                />
                <Table
                  size="small"
                  bordered
                  loading={isSearchingDiscounts}
                  pagination={false}
                  dataSource={searchDiscountItems}
                  rowKey="id"
                  columns={[
                    {
                      title: t('image'),
                      align: 'center',
                      className: 'text-xs',
                      render: (row) => (
                        <img
                          width={75}
                          src={row.itemImage}
                          alt={`${row.brand} ${row.name} ${row.description}`}
                        />
                      ),
                    },
                    {
                      title: 'GTIN',
                      align: 'center',
                      className: 'text-xs',
                      render: (row) => (
                        <span>{row.gtin}</span>
                      ),
                    },
                    {
                      title: t('complete_name'),
                      align: 'center',
                      className: 'text-xs',
                      render: (row) => (
                        <span>{row.brand} {row.name} {row.description}</span>
                      ),
                    },
                    {
                      title: '',
                      align: 'center',
                      className: 'text-xs',
                      render: (row) => (
                        <Button
                          size="small"
                          type="primary"
                          disabled={row.isAssigned}
                          loading={row.loading}
                          onClick={() => addDiscountItem(row)}
                        >
                          {t('add')}
                        </Button>
                      ),
                    },
                  ]}
                />
                <Pagination
                  pageSize={discountSize}
                  showSizeChanger
                  defaultCurrent={0}
                  current={currentDiscountPage + 1}
                  total={totalItemsInDiscountSearch}
                  onChange={onTableChangeDiscount}
                />
              </Panel>
            </Collapse>
          </>
        </Panel>
      </Collapse>
    </>
  );
}

export default Coupon;
