import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useRecoilState, } from 'recoil';
import {
  Modal,
  Button,
  Avatar,
  notification,
  Tooltip
} from 'antd';
import { Comment } from '@ant-design/compatible';
import { GoogleMap, Marker, Polygon, useJsApiLoader } from '@react-google-maps/api';
import moment from 'moment';
import { mapZoomAtom, openCustomerLocationMapAtom, customerLocationAtom } from '../../../atoms/Atoms';
import envConfig from '../../../envConfig';
import api from '../../../api/api';
import Grocefy from '../../../assets/images/grocefyLogoAlone.png';

function CustomerLocationMap() {
  const { i18n } = useTranslation();
  const [open, setOpen] = useRecoilState(openCustomerLocationMapAtom);
  const [location, setLocation] = useRecoilState(customerLocationAtom);
  const [validZones, setValidZones] = useState([]);
  const [zoomLevel, setZoomLevel] = useRecoilState(mapZoomAtom);
  const [map, setMap] = useState(null);
  const [loadingZones, setLoadingZones] = useState(false);
  const { t } = useTranslation();

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: envConfig.REACT_APP_GEO_API_KEY,
    language: 'es-US',
    libraries: ['places']
  });

  const showMessage = function (message) {
    notification.open({
      message: '',
      description: (
        <Comment
          author={<span>Grocefy</span>}
          avatar={<Avatar src={Grocefy} alt="grocefy" />}
          content={
            <p className="text-sm">
              {message}
            </p>
          }
          datetime={
            <Tooltip title={moment().format('YYYY-MM-DD HH:mm:ss')}>
              <span>{moment().fromNow()}</span>
            </Tooltip>
          }
        />
      ),
    });
  };

  const onLoad = React.useCallback((tempMap) => {
    const bounds = new window.google.maps.LatLngBounds();
    tempMap.fitBounds(bounds);
    setMap(tempMap);
  }, []);

  const onUnmount = React.useCallback((tempMap) => {
    setMap(null);
  }, []);

  useEffect(() => {
    setValidZones([]);
  }, []);

  return (
    <Modal
      closable="true"
      centered="true"
      open={open}
      onCancel={() => { setOpen(false); }}
      footer={[
        <Button
          key="close"
          onClick={() => { setOpen(false); }}
        >
          {t('close')}
        </Button>
      ]}
    >
      <p className="text-lg font-bold"> {t('customer_location')} </p>
      <p className="text-xs text-gray-600">
        {t('lat')}: {location.latitude}
      </p>
      <p className="text-xs text-gray-600">
        {t('lon')}: {location.longitude}
      </p>
      <Button
        type="primary"
        size="small"
        loading={loadingZones}
        className="my-2 float-right"
        onClick={() => {
          setLoadingZones(true);
          api.get(`deliveries/validzones/${location.key}`)
            .then((response) => {
              setLoadingZones(false);
              if (response.data.error) {
                showMessage(response.data.error);
              } else if (response.data.data.results.length === 0) {
                showMessage('no valid zones');
              } else {
                setValidZones(response.data.data.results);
              }
            })
            .catch((err) => {
              setLoadingZones(false);
              showMessage(err.message);
              console.error(err.message);
            });
        }}
      >
        {t('get_valid_delivery_zones')}
      </Button>
      {isLoaded && (
        <GoogleMap
          mapContainerStyle={{
            height: '400px',
            width: '100%'
          }}
          center={{
            lat: location.latitude,
            lng: location.longitude
          }}
          zoom={12}
        >
          <Marker
            position={{ lat: location.latitude, lng: location.longitude }}
          />
          {_.map(validZones, (zone) => (
            <>
              <Polygon
                path={_.map(zone?.points, (ll) => ({
                  lng: ll.lon,
                  lat: ll.lat
                }))}
                options={{
                  fillColor: '#00FF0033',
                  fillOpacity: 1,
                  strokeColor: '#22FF22',
                  strokeOpacity: 1,
                  strokeWeight: 2,
                  clickable: false,
                  draggable: false,
                  editable: false,
                  geodesic: false,
                  paths: _.map(zone?.points, (ll) => ({
                    lng: ll.lon,
                    lat: ll.lat
                  })),
                  zIndex: 1,
                }}
              />
              <Marker
                label={zone.name}
                position={{ lat: zone.center?.lat, lng: zone.center?.lon }}
              />
            </>
          ))}
        </GoogleMap>
      )}
    </Modal>
  );
}

export default CustomerLocationMap;
