import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import {
  useRecoilState,
  useRecoilValue,
  useSetRecoilState
} from 'recoil';
import { useTranslation } from 'react-i18next';
import {
  Input,
  Row,
  Col,
  Switch,
  InputNumber,
  notification,
  Avatar,
  Tooltip,
  Drawer,
  Button
} from 'antd';
import { Comment } from '@ant-design/compatible';
import moment from 'moment';
import {
  businessAtom,
  reloadDeliveryZonesAtom,
  showCreateDeliveryZoneAtom,
  selectedDeliveryZoneAtom
} from '../../../../atoms/Atoms';
import Grocefy from '../../../../assets/images/grocefyLogoAlone.png';
import api from '../../../../api/api';

function CreateDeliveryZoneDrawer() {
  const business = useRecoilValue(businessAtom);
  const [showCreateDeliveryZone, setShowCreateDeliveryZone]
    = useRecoilState(showCreateDeliveryZoneAtom);
  const selectedDeliveryZone
    = useRecoilValue(selectedDeliveryZoneAtom);
  const setReloadDeliveryZonesAtom = useSetRecoilState(reloadDeliveryZonesAtom);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [valid, setValid] = useState(false);
  const { t } = useTranslation();

  const showMessage = function (message) {
    notification.open({
      message: '',
      description: (
        <Comment
          author={<span>Grocefy</span>}
          avatar={<Avatar src={Grocefy} alt="grocefy" />}
          content={
            <p className="text-sm">
              {message}
            </p>
          }
          datetime={
            <Tooltip title={moment().format('YYYY-MM-DD HH:mm:ss')}>
              <span>{moment().fromNow()}</span>
            </Tooltip>
          }
        />
      ),
    });
  };

  const createZone = function () {
    setLoading(true);
    api.post(
      `deliveries/deliveryzones/${business.locations[0].id}/zone`,
      data
    )
      .then((response) => {
        setLoading(false);
        if (response.data.success) {
          setReloadDeliveryZonesAtom(true);
          setShowCreateDeliveryZone(false);
        } else {
          showMessage(response.data.error);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  useEffect(() => {
    if (data) {
      let temp = data?.name?.length > 0;
      temp = temp && `${data.cost}`.length > 0;
      temp = temp && `${data.deliveryFutureDays}`.length > 0;
      temp = temp && `${data.slotRangeMin}`.length > 0;
      setValid(temp);
    }
  }, [data]);

  useEffect(() => {
    if (showCreateDeliveryZone) {
      setData({
        name: '',
        deliveryFutureDays: 0,
        cost: 0,
        deliveryRouteOrder: 0,
        preventTodayDeliverySlots: false,
        slotRangeMin: 60
      });
    }
  }, [showCreateDeliveryZone]);

  return (
    <>
      <Drawer
        title={t('create_new_zone')}
        placement="right"
        closable
        onClose={() => setShowCreateDeliveryZone(false)}
        open={showCreateDeliveryZone}
        width={400}
      >
        <div
          style={{ marginBottom: 10 }}
        >
          <strong>{t('name')}</strong>
          <Input
            disabled={loading}
            onChange={(obj) => {
              const temp = JSON.parse(JSON.stringify(data));
              temp.name = obj.target.value;
              setData(temp);
            }}
            value={data?.name}
          />
        </div>
        <div
          style={{ marginBottom: 10 }}
        >
          <strong>{t('prevent_service_slots')}</strong>
          <Switch
            style={{ float: 'right' }}
            disabled={loading}
            onChange={(obj) => {
              const temp = JSON.parse(JSON.stringify(data));
              temp.preventTodayDeliverySlots = obj;
              setData(temp);
            }}
            checked={data?.preventTodayDeliverySlots}
          />
        </div>
        <Row>
          <Col>
            <div
              style={{ marginBottom: 10 }}
            >
              <strong>{t('cost')}</strong>
              <br />
              <InputNumber
                formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                min={0}
                step="0.01"
                disabled={loading}
                onChange={(obj) => {
                  const temp = JSON.parse(JSON.stringify(data));
                  temp.cost = obj;
                  setData(temp);
                }}
                value={data?.cost}
              />
            </div>
          </Col>
          <Col>
            <div
              style={{ marginBottom: 10 }}
            >
              <strong>{t('slot_range')}</strong>
              <br />
              <InputNumber
                disabled={loading}
                min={0}
                onChange={(obj) => {
                  const temp = JSON.parse(JSON.stringify(data));
                  temp.slotRangeMin = obj;
                  setData(temp);
                }}
                value={data?.slotRangeMin}
              />
            </div>
          </Col>
          <Col>
            <div
              style={{ marginBottom: 10 }}
            >
              <strong>{t('future_days')}</strong>
              <br />
              <InputNumber
                disabled={loading}
                min={0}
                onChange={(obj) => {
                  const temp = JSON.parse(JSON.stringify(data));
                  temp.deliveryFutureDays = obj;
                  setData(temp);
                }}
                value={data?.deliveryFutureDays}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div
              style={{ marginBottom: 10 }}
            >
              <strong>{t('route_ordering')}</strong>
              <br />
              <InputNumber
                disabled={loading}
                min={0}
                onChange={(obj) => {
                  const temp = JSON.parse(JSON.stringify(data));
                  temp.deliveryRouteOrder = obj;
                  setData(temp);
                }}
                value={data?.deliveryRouteOrder}
              />
            </div>
          </Col>
        </Row>
        <div
          style={{
            float: 'right',
            marginTop: '10px'
          }}
        >
          <Button
            style={{
              marginLeft: '10px'
            }}
            loading={loading}
            disabled={!valid}
            type="primary"
            onClick={() => { createZone(); }}
          >
            {t('create')}
          </Button>
        </div>
      </Drawer>
    </>
  );
}
export default CreateDeliveryZoneDrawer;
