import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { useMount } from 'react-use';

import { Bar } from 'react-chartjs-2';
import { Select } from 'antd';
import api from '../../../../api/api';

function SalesChart() {
  const { Option } = Select;
  const { t } = useTranslation();
  const [data, setData] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [xData, setXData] = useState([1, 2, 3]);
  const [yData, setYData] = useState([2, 3, 4]);
  const [timeSpan, setTimeSpan] = useState(7);
  const [loading, setLoading] = useState(false);
  const colors = useState(['#2B3240', '#8596A6', '#F2DE77', '#F2BF80', '#F2994B']);

  const timeFactor = useState(0);
  // Day = 0
  // Week = 1
  // Month = 2
  // Year = 3
  // Lifetime = 4
  const top = useState(30);

  function getSales() {
    setLoading(true);
    api
      .get(`analytics/sales/0/${timeSpan}`)
      .then((response) => {
        setLoading(false);
        setData(response.data.data.results);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  }

  useMount(() => {
    getSales();
  });

  useEffect(() => {
    getSales();
  }, [timeSpan]);

  useEffect(() => {
    if (data) {
      setLoaded(false);
      const tempX = [];
      const tempY = [];
      data[0].sales.forEach((element) => {
        tempX.push(element.dateString);
      });
      setXData(tempX);
      setLoaded(true);
    }
  }, [data]);

  const legend = {
    display: true,
    position: 'bottom',
    labels: {
      fontColor: '#424A61',
      fontSize: 14
    }
  };
  const options = {
    title: {
      display: true,
      text: 'Sales by Store'
    },
    plugins: {
      datalabels: {
        anchor: 'end',
        color: 'white',
        backgroundColor: '#a78bfa',
        borderRadius: '5',
        labels: {
          value: {}
        }
      },
    },
    scales: {
      yAxes: [
        {
          ticks: {
            suggestedMin: 0,
          }
        }
      ]
    }
  };
  function mapSales(sales) {
    if (sales) {
      const tempSales = [];
      const sorted = _.sortBy(sales, (s) => s.dateString, 'asc');
      sorted.forEach((element) => {
        tempSales.push(element.sales);
      });
      return tempSales;
    }
    return null;
  }

  function mapData(rawData) {
    const mapped = _.map(rawData, (p, index) => ({
      key: index,
      label: p.businessName,
      data: mapSales(p.sales),
      fill: true,
      backgroundColor: colors[0][index],
      borderColor: colors[0][index],
    }));
    return _.sortBy(mapped, (s) => s.label, 'asc');
  }

  return (
    <div key="sales" className="h-68 mb-24">
      <div className="font-semibold text-lg">{t('sales')} </div>
      <div className="flex justify-end">
        <Select
          loading={loading}
          className="border rounded-lg border-blue mb-12 px-0"
          defaultValue={7}
          style={{ width: 140 }}
          onChange={(obj) => setTimeSpan(obj)}
        >
          <Option value={7}>{t('last_week')}</Option>
          <Option value={28}>{t('last_month')}</Option>
          <Option value={365}>{t('last_year')}</Option>
          <Option value={1365}>{t('lifetimne')}</Option>
        </Select>
      </div>
      <div>
        {data && <Bar
          data={
            {
              labels: xData,
              datasets: mapData(data && data),
            }
          }
          legend={legend}
          options={options}
        />}
      </div>
    </div>
  );
}

export default SalesChart;
