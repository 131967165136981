import React, { useEffect, useState } from 'react';
import {
  useRecoilState,
  useSetRecoilState
} from 'recoil';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import {
  Drawer,
  Avatar,
  Tooltip,
  DatePicker,
  InputNumber,
  Switch,
  Button,
  Select,
  Input,
  notification
} from 'antd';
import { Comment } from '@ant-design/compatible';
import moment from 'moment';
import Grocefy from '../../../../assets/images/grocefyLogoAlone.png';
import {
  selectedLoyaltyAtom,
  refreshLoyaltiesAtom,
  selectedPickingItemAtom
} from '../../../../atoms/Atoms';
import api from '../../../../api/api';
import Search from '../../searchoptimization/shared-components/Search';

function EditLoyaltyDrawer() {
  const { RangePicker } = DatePicker;
  const { Option } = Select;
  const setRefreshLoyalties = useSetRecoilState(refreshLoyaltiesAtom);
  const [selectedPickingItem, setSelectedPickingItem] =
    useRecoilState(selectedPickingItemAtom);
  const [selectedLoyalty, setSelectedLoyalty] = useRecoilState(selectedLoyaltyAtom);
  const [loading, setLoading] = useState(false);
  const [loyalty, setLoyalty] = useState({});
  const { t } = useTranslation();

  const showMessage = function (message) {
    notification.open({
      message: '',
      description: (
        <Comment
          author={<span>Grocefy</span>}
          avatar={<Avatar src={Grocefy} alt="grocefy" />}
          content={
            <p className="text-sm">
              {message}
            </p>
          }
          datetime={
            <Tooltip title={moment().format('YYYY-MM-DD HH:mm:ss')}>
              <span>{moment().fromNow()}</span>
            </Tooltip>
          }
        />
      ),
    });
  };

  const submit = function () {
    setLoading(true);
    const temp = JSON.parse(JSON.stringify(loyalty));
    temp.startDate = moment(temp.startDate).utc();
    temp.endDate = moment(temp.endDate).utc();
    if (temp.startDate?.length < 17) {
      temp.startDate = `${temp.startDate.replace(' ', 'T')}:00.000`;
      temp.endDate = `${temp.endDate.replace(' ', 'T')}:00.000`;
    }
    api.post(
      'loyalty/rewards',
      temp
    )
      .then((response) => {
        setLoading(false);
        if (response.data.success) {
          setRefreshLoyalties(true);
          setSelectedLoyalty(null);
        } else {
          showMessage(response.data.error);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  const archiveClick = function () {
    setLoading(true);
    api.post(
      `loyalty/rewards/${loyalty.id}`,
      { }
    )
      .then((response) => {
        setLoading(false);
        if (response.data.success) {
          setSelectedLoyalty(null);
          setRefreshLoyalties(true);
        } else {
          console.error(response.data.error);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  useEffect(() => {
    if (selectedLoyalty) {
      const temp = JSON.parse(JSON.stringify(selectedLoyalty));
      setSelectedPickingItem(null);
      if (!temp.id) {
        temp.itemId = '';
        temp.internalName = '';
        temp.name = '';
        temp.ordering = 0;
        temp.type = 0;
        temp.isActive = false;
        temp.value = 0;
        temp.maxValue = 0;
        temp.minimumOrderTotal = 0;
        temp.pointForRedeem = 0;
        temp.startDate = moment();
        temp.endDate = moment().add(1, 'day');
      }
      setLoyalty(temp);
    }
  }, [selectedLoyalty]);

  useEffect(() => {
    if (loyalty) {
      loyalty.itemId = selectedPickingItem?.id;
    }
  }, [selectedPickingItem]);

  return (
    <Drawer
      title={`${loyalty && loyalty.id ? t('update') : t('create')} Loyalty Reward`}
      placement="right"
      closable
      onClose={() => setSelectedLoyalty(null)}
      open={selectedLoyalty}
      width={400}
    >
      {selectedLoyalty && (
        <>
          <div
            style={{
              float: 'right',
              marginBottom: '10px'
            }}
          >
            <Button
              type="primary"
              danger
              disabled={loading}
              hidden={!loyalty?.id}
              onClick={() => { archiveClick(); }}
            >
              {t('archive')}
            </Button>
          </div>
          <div style={{ marginBottom: 10 }}>
            <strong>{t('active')}:</strong>
            <Switch
              checked={loyalty?.isActive}
              disabled={loading}
              onChange={(checked) => {
                const temp = JSON.parse(JSON.stringify(loyalty));
                temp.isActive = checked;
                setLoyalty(temp);
              }}
            />
          </div>
          <div style={{ marginBottom: 10 }}>
            <strong>{t('internal_name')}:</strong>
            <Input
              disabled={loading}
              placeholder={t('internal_name')}
              onChange={(obj) => {
                const temp = JSON.parse(JSON.stringify(loyalty));
                temp.internalName = obj.target.value;
                setLoyalty(temp);
              }}
              value={loyalty?.internalName}
            />
          </div>
          <div style={{ marginBottom: 10 }}>
            <strong>{t('name')}:</strong>
            <Input
              disabled={loading}
              placeholder={t('name')}
              onChange={(obj) => {
                const temp = JSON.parse(JSON.stringify(loyalty));
                temp.name = obj.target.value;
                setLoyalty(temp);
              }}
              value={loyalty?.name}
            />
          </div>
          <div style={{ marginBottom: 10 }}>
            <strong>{t('points_for_redeem')}</strong>
            <br />
            <InputNumber
              disabled={loading}
              min={0}
              onChange={(obj) => {
                const temp = JSON.parse(JSON.stringify(loyalty));
                temp.pointForRedeem = obj;
                setLoyalty(temp);
              }}
              value={loyalty?.pointForRedeem}
            />
          </div>
          <div style={{ marginBottom: 10 }}>
            <strong>{t('type')}: </strong>
            <Select
              value={loyalty?.type}
              style={{ width: 120 }}
              disabled={loading}
              onChange={(obj) => {
                const temp = JSON.parse(JSON.stringify(loyalty));
                temp.type = obj;
                setLoyalty(temp);
              }}
            >
              <Option value={0}>{t('order_discount')}</Option>
              <Option value={1}>{t('order')} %</Option>
              <Option value={2}>{t('item_discount')}</Option>
              <Option value={3}>{t('item')} %</Option>
            </Select>
          </div>
          <div
            style={{ margin: '5px 0' }}
            hidden={loyalty?.type !== 2 && loyalty?.type !== 3}
          >
            <strong>{t('search_item')}:</strong>
            <Search
              itemPicking
              size="small"
            />
          </div>
          <div style={{ marginBottom: 10 }}>
            <strong>
              {t('value')} {loyalty?.type === 0 || loyalty?.type === 2 ? '(Ex: $3.00)' : '(Ex: 0.15 => 15%)'}
            </strong>
            <br />
            <InputNumber
              disabled={loading}
              min={0}
              onChange={(obj) => {
                const temp = JSON.parse(JSON.stringify(loyalty));
                temp.value = obj;
                setLoyalty(temp);
              }}
              value={loyalty?.value}
            />
          </div>
          <div style={{ marginBottom: 10 }}>
            <strong>{t('maximum')} (Ex: $3.00)</strong>
            <br />
            <InputNumber
              disabled={loading}
              min={0}
              onChange={(obj) => {
                const temp = JSON.parse(JSON.stringify(loyalty));
                temp.maxValue = obj;
                setLoyalty(temp);
              }}
              value={loyalty?.maxValue}
            />
          </div>
          <div style={{ marginBottom: 10 }}>
            <strong>{t('min_order_total')}</strong>
            <br />
            <InputNumber
              disabled={loading}
              min={0}
              onChange={(obj) => {
                const temp = JSON.parse(JSON.stringify(loyalty));
                temp.minimumOrderTotal = obj;
                setLoyalty(temp);
              }}
              value={loyalty?.minimumOrderTotal}
            />
          </div>
          <div style={{ marginBottom: 10 }}>
            <strong>{'Start Date -> End Date:'}</strong>
            <RangePicker
              showTime={{ format: 'HH:mm' }}
              format="YYYY-MM-DD HH:mm"
              value={[
                dayjs(loyalty?.startDate, 'YYYY-MM-DD HH:mm'),
                dayjs(loyalty.endDate, 'YYYY-MM-DD HH:mm')
              ]}
              onChange={(date, dateString) => {
                const temp = JSON.parse(JSON.stringify(loyalty));
                const start = dateString[0];
                const end = dateString[1];
                temp.startDate = start;
                temp.endDate = end;
                setLoyalty(temp);
              }}
            />
          </div>
          <div style={{ marginBottom: 10 }}>
            <strong>{t('ordering')}</strong>
            <br />
            <InputNumber
              disabled={loading}
              min={1}
              onChange={(obj) => {
                const temp = JSON.parse(JSON.stringify(loyalty));
                temp.ordering = obj;
                setLoyalty(temp);
              }}
              value={loyalty?.ordering}
            />
          </div>
          <div
            style={{
              float: 'right',
              marginTop: '10px'
            }}
          >
            <Button
              style={{
                marginLeft: '10px'
              }}
              disabled={loading ||
                ((loyalty.type === 2 || loyalty.type === 3) && !selectedPickingItem)}
              loading={loading}
              type="primary"
              onClick={() => { submit(); }}
            >
              {loyalty?.id ? 'Update' : 'Create'}
            </Button>
          </div>
        </>
      )}
    </Drawer>
  );
}

export default EditLoyaltyDrawer;
