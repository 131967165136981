import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import {
  useRecoilState,
  useSetRecoilState
} from 'recoil';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { InboxOutlined } from '@ant-design/icons';
import { TwitterPicker } from 'react-color';
import {
  Input,
  Modal,
  Button,
  Upload,
  Image,
  Tag,
  Avatar,
  Tooltip,
  notification,
  Divider
} from 'antd';
import { Comment } from '@ant-design/compatible';
import { PageHeader } from '@ant-design/pro-layout';
import Grocefy from '../../../../assets/images/grocefyLogoAlone.png';
import {
  selectedTicketBoardAtom,
  editBoardAtom,
  refreshBoardsAtom,
} from '../../../../atoms/Atoms';
import api from '../../../../api/api';

function EditTicketModal() {
  const { Dragger } = Upload;
  const history = useHistory();
  const setRefreshBoards = useSetRecoilState(refreshBoardsAtom);
  const setSelectedBoard = useSetRecoilState(selectedTicketBoardAtom);
  const [editBoard, setEditBoard] = useRecoilState(editBoardAtom);
  const [loading, setLoading] = useState(false);
  const [boardData, setBoardData] = useState(null);
  const [imageData, setImageData] = useState(null);
  const { t } = useTranslation();

  const showErrorMessage = function (errorMessage) {
    notification.open({
      message: '',
      description: (
        <Comment
          author={<span>Grocefy</span>}
          avatar={<Avatar src={Grocefy} alt="grocefy" />}
          content={
            <p className="text-sm">
              {errorMessage}
            </p>
          }
          datetime={
            <Tooltip title={moment().format('YYYY-MM-DD HH:mm:ss')}>
              <span>{moment().fromNow()}</span>
            </Tooltip>
          }
        />
      ),
    });
  };
  const closeBoardModel = function () {
    if (!loading) {
      setEditBoard(null);
    }
  };
  const onChangeName = function (obj) {
    const temp = JSON.parse(JSON.stringify(boardData));
    temp.name = obj.target.value;
    setBoardData(temp);
  };
  const onChangeColor = function (obj) {
    const temp = JSON.parse(JSON.stringify(boardData));
    if (obj.hex) {
      temp.colorHex = obj.hex;
    } else {
      temp.colorHex = obj.target.value;
    }
    setBoardData(temp);
  };
  const removeImage = function () {
    const temp = JSON.parse(JSON.stringify(boardData));
    temp.imageUrl = '';
    temp.removeImage = true;
    setBoardData(temp);
  };
  const createBoardRequest = function () {
    setLoading(true);
    const data = {
      Name: boardData.name,
      ColorHex: boardData.colorHex
    };
    api.post(
      'ticketboards/board',
      data
    )
      .then((response) => {
        setLoading(false);
        if (response.data.success) {
          setRefreshBoards(true);
          setEditBoard(null);
        } else {
          showErrorMessage(response.data.error);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };
  const updateBoardRequest = function (board) {
    setLoading(true);
    api.put(
      `ticketboards/board/${boardData.id}`,
      board
    )
      .then((response) => {
        setLoading(false);
        if (response.data.success) {
          const newBoard = response.data.data;
          setEditBoard(null);
          if (board.IsDeleted) {
            history.push('/ticketboards');
          } else {
            setSelectedBoard(newBoard);
          }
        } else {
          showErrorMessage(response.data.error);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };
  const updateBoardClick = function () {
    const data = {
      Name: boardData.name,
      ColorHex: boardData.colorHex,
      RemoveImage: boardData.removeImage ? boardData.removeImage : false
    };
    updateBoardRequest(data);
  };
  const deleteBoardClick = function () {
    const data = {
      id: boardData.id,
      IsDeleted: true
    };
    updateBoardRequest(data);
  };

  useEffect(() => {
    if (editBoard) {
      const temp = JSON.parse(JSON.stringify(editBoard));
      if (!temp.colorHex) {
        temp.colorHex = '#ffffff';
      } else if (!temp.colorHex.includes('#')) {
        temp.colorHex = `#${temp.colorHex}`;
      }
      setBoardData(temp);
    } else {
      setBoardData(editBoard);
    }
  }, [editBoard]);

  const draggerProps = {
    name: 'file',
    multiple: false,
    showUploadList: false,
    beforeUpload: (file) => {
      setImageData(file);
      return false;
    }
  };

  useEffect(() => {
    if (imageData) {
      const file = imageData;
      const boundary = Date.now();
      const data = new FormData();
      data.append('file', file);
      setLoading(true);
      api
        .post(`ticketboards/board/${boardData.id}/image`, data)
        .then((response) => {
          setLoading(false);
          setImageData(null);
          if (response.data.success) {
            const temp = JSON.parse(JSON.stringify(boardData));
            temp.imageUrl = response.data.data;
            setBoardData(temp);
          } else {
            showErrorMessage(response.data.error);
          }
        })
        .catch((error) => {
          setLoading(false);
          setImageData(null);
          console.error(error);
        });
    }
  }, [imageData]);

  return (
    <div>
      {boardData && (
        <Modal
          closable="true"
          centered="true"
          open={editBoard}
          onCancel={() => closeBoardModel()}
          footer={[
            <Button
              key="updateButton"
              hidden={!boardData.id}
              disabled={loading}
              onClick={() => updateBoardClick()}
            >
              {t('update')}
            </Button>,
            <Button
              key="createButton"
              hidden={boardData.id}
              disabled={loading}
              onClick={() => createBoardRequest()}
            >
              {t('create')}
            </Button>,
            <Button
              key="back"
              disabled={loading}
              onClick={() => closeBoardModel()}
            >
              {t('close')}
            </Button>
          ]}
        >
          <PageHeader
            title={boardData.id ? 'Edit Board' : 'Create Board'}
            extra={[
              <Tag
                key="deleteBoardButton"
                hidden={!boardData.id}
                onClick={() => deleteBoardClick()}
                color="#ff0033"
                style={{ color: '#ffffff' }}
              >
                {t('delete')}
              </Tag>
            ]}
          />
          {boardData.id && (
            <>
              <Dragger {...draggerProps}>
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">
                  {t('click_or_drag_content')}
                </p>
              </Dragger>
              {boardData.imageUrl && (
                <>
                  <Image
                    alt={boardData.name}
                    src={boardData.imageUrl}
                    height={75}
                  />
                  <br />
                  <Button onClick={() => removeImage()}>
                    {t('remove')}
                  </Button>
                </>
              )}
              <Divider />
            </>
          )}
          <Input
            placeholder={t('name')}
            onChange={(obj) => onChangeName(obj)}
            value={boardData.name}
          />
          <Input
            placeholder="Color Hex"
            onChange={(obj) => onChangeColor(obj)}
            value={boardData.colorHex}
            style={{ background: boardData.colorHex }}
          />
          <TwitterPicker
            color={boardData.colorHex}
            onChangeComplete={(obj) => onChangeColor(obj)}
          />
        </Modal>
      )}
    </div>
  );
}

export default EditTicketModal;
