import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  Avatar,
  Tooltip,
  Modal,
  Button,
  notification,
  Table
} from 'antd';
import { Comment } from '@ant-design/compatible';
import { PageHeader } from '@ant-design/pro-layout';
import moment from 'moment';
import Grocefy from '../../../../../assets/images/grocefyLogoAlone.png';
import {
  timezoneAtom,
  selectedTicketAtom,
  showTicketLogsAtom } from '../../../../../atoms/Atoms';
import api from '../../../../../api/api';

function TicketLogs() {
  const timezone = useRecoilValue(timezoneAtom);
  const selectedTicket = useRecoilValue(selectedTicketAtom);
  const [viewTicketLogs, setViewTicketLogs] = useRecoilState(showTicketLogsAtom);
  const [logs, setLogs] = useState([]);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const showErrorMessage = function (errorMessage) {
    notification.open({
      message: '',
      description: (
        <Comment
          author={<span>Grocefy</span>}
          avatar={<Avatar src={Grocefy} alt="grocefy" />}
          content={
            <p className="text-sm">
              {errorMessage}
            </p>
          }
          datetime={
            <Tooltip title={moment().format('YYYY-MM-DD HH:mm:ss')}>
              <span>{moment().fromNow()}</span>
            </Tooltip>
          }
        />
      ),
    });
  };

  useEffect(() => {
    if (viewTicketLogs && selectedTicket) {
      setLoading(true);
      api.get(`ticketboards/ticket/logs/${selectedTicket.id}`)
        .then((response) => {
          setLoading(false);
          if (response.data.success) {
            setLogs(response.data.data.results);
          } else {
            showErrorMessage(response.data.error);
          }
        })
        .catch((error) => {
          setLoading(false);
          console.error(error);
        });
    } else {
      setLogs([]);
    }
  }, [viewTicketLogs, selectedTicket]);

  return (
    <>
      {selectedTicket && (
        <Modal
          closable="true"
          centered="true"
          open={viewTicketLogs}
          onCancel={() => setViewTicketLogs(false)}
          footer={[
            <Button
              key="back"
              onClick={() => setViewTicketLogs(false)}
            >
              {t('close')}
            </Button>
          ]}
        >
          <PageHeader
            title={t('ticket_logs')}
          />
          <Table
            size="small"
            bordered
            hideOnSinglePage="true"
            loading={loading}
            columns={[
              {
                title: t('description'),
                dataIndex: 'description',
                key: 'code',
                align: 'center',
                className: 'text-xs',
                render: (text) => (
                  <span>{text}</span>
                ),
              },
              {
                title: t('date'),
                dataIndex: 'createdAt',
                key: 'createdAt',
                align: 'left',
                className: 'text-xs',
                render: (text) => (
                  <span>
                    {moment(`${text}+0000`)
                      .tz(timezone)
                      .format('MMM dd YYYY, h:mm:ss a')}
                  </span>
                )
              }
            ]}
            dataSource={logs}
          />
        </Modal>
      )}
    </>
  );
}

export default TicketLogs;
