import React, { useState } from 'react';
import _ from 'lodash';
import { useRecoilValue, useRecoilValueLoadable } from 'recoil';
import { PageHeader } from '@ant-design/pro-layout';
import { useTranslation } from 'react-i18next';
import {
  Input,
  Avatar,
  Tag,
  Tooltip,
  InputNumber,
  Spin,
  Typography,
  notification,
  Button,
  Table,
  Image,
  Pagination,
  Card,
  List,
  Select
} from 'antd';
import { Comment } from '@ant-design/compatible';
import moment from 'moment';
import Grocefy from '../../../assets/images/grocefyLogoAlone.png';
import {
  showEditSearchTermAtom,
  profileAtom,
} from '../../../atoms/Atoms';
import api from '../../../api/api';

function TestConsumerSearch() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [timeTake, setTimeTaken] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [items, setItems] = useState([]);
  const [searchSize, setSearchSize] = useState(20);
  const [searchScore, setSearchScore] = useState(0);

  const gridSizes = {
    gutter: 16,
    xs: 4,
    sm: 4,
    md: 4,
    lg: 4,
    xl: 4,
    xxl: 4,
  };

  function showMessage(message) {
    notification.open({
      message: '',
      description: (
        <Comment
          author={<span>Grocefy</span>}
          avatar={<Avatar src={Grocefy} alt="grocefy" />}
          content={
            <p className="text-sm">
              {message}
            </p>
          }
          datetime={
            <Tooltip title={moment().format('YYYY-MM-DD HH:mm:ss')}>
              <span>{moment().fromNow()}</span>
            </Tooltip>
          }
        />
      ),
    });
  }

  function search() {
    setLoading(true);
    api.post(`businesses/searchwithscores/jE/${searchSize}/0`, {
      SearchString: searchTerm,
      BusinessId: 'jE'
    })
      .then((response) => {
        setLoading(false);
        setTimeTaken(response.data.data.time);
        setItems(_.filter(response.data.data.items, (i) => i.score >= searchScore));
      })
      .catch((error) => {
        setLoading(false);
        showMessage(error);
      });
  }

  return (
    <>
      <PageHeader
        className="mb-4 px-0"
        title={t('consumer_search')}
      />
      <div className="space-y-2">
        <div className="flex space-x-2">
          <Input.Search
            className="mt-5"
            key="itemSearch"
            placeholder={t('item_search_placeholder')}
            allowClear
            loading={loading}
            onChange={(obj) => setSearchTerm(obj.target.value)}
            enterButton={t('search')}
            onSearch={(value) => search()}
          />
          <div>
            <strong>{t('search_size')}</strong>
            <InputNumber
              className="w-full"
              disabled={loading}
              min={1}
              step="1"
              onChange={(obj) => setSearchSize(obj)}
              value={searchSize}
            />
          </div>
          <div>
            <strong>{t('minimum_score')}</strong>
            <InputNumber
              className="w-full"
              disabled={loading}
              min={0}
              step="1"
              onChange={(obj) => setSearchScore(obj)}
              value={searchScore}
            />
          </div>
        </div>
        <List
          pagination={false}
          loading={loading}
          dataSource={items}
          grid={gridSizes}
          renderItem={(i) => (
            <List.Item
              key={i.item.id}
            >
              <Card
                key={i.item.id}
              >
                <strong>{i.item.gtin}</strong>
                <img
                  style={{ maxHeight: 125 }}
                  src={i.item.imageUrl}
                  alt={i.item.completeName}
                />
                <div className="ellipsis-container">
                  {i.item.brand && i.item.brand !== '' && (
                    <p>{i.item.brand}</p>
                  )}
                  {(!i.item.brand || i.item.brand === '') && (
                    <p style={{ opacity: 0 }}>.</p>
                  )}
                  {i.item.name && i.item.name !== '' && (
                    <p>{i.item.name}</p>
                  )}
                  {(!i.item.name || i.item.name === '') && (
                    <p style={{ opacity: 0 }}>.</p>
                  )}
                  {i.item.description && i.item.description !== '' && (
                    <p>{i.item.description}</p>
                  )}
                  {(!i.item.description || i.item.description === '') && (
                    <p style={{ opacity: 0 }}>.</p>
                  )}
                  <p>
                    <strong>{t('score')}: </strong> {i.score}
                  </p>
                </div>
              </Card>
            </List.Item>
          )}
        />
      </div>
    </>
  );
}
export default TestConsumerSearch;
