import React, { useEffect } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import Activities from './shared-components/Activities';
import Header from './shared-components/Header';
import {
  pageAtom,
  rowsAtom,
  selectedBusinessAccountPayoutsAtom,
  stripePayoutsAtom,
  loadingStripePayoutsAtom,
  refreshStripePayoutsAtom
} from '../../../../atoms/Atoms';
import api from '../../../../api/api';

function Stripe() {
  const selectedBusinessAccountPayouts = useRecoilValue(selectedBusinessAccountPayoutsAtom);
  const [page, setPage] = useRecoilState(pageAtom);
  const [rows, setRows] = useRecoilState(rowsAtom);
  const setStripePayouts = useSetRecoilState(stripePayoutsAtom);
  const setLoadingStripePayouts = useSetRecoilState(loadingStripePayoutsAtom);
  const [refreshStripePayouts, setRefreshStripePayouts] = useRecoilState(refreshStripePayoutsAtom);
  function getData() {
    // console.log('selectedBusinessAccountPayouts', selectedBusinessAccountPayouts);
    setLoadingStripePayouts(true);
    setRefreshStripePayouts(false);
    let currentPage = page;
    if (currentPage === 0) {
      currentPage = 1;
    }
    let url = `payments/global/payoutswithcountbytype/0/${currentPage - 1}/${rows}`;
    if (selectedBusinessAccountPayouts) {
      url += `/${selectedBusinessAccountPayouts}`;
    }
    api
      .get(url)
      .then((response) => {
        setLoadingStripePayouts(false);
        if (response.data.success) {
          setStripePayouts(response.data.data);
        } else {
          setStripePayouts(null);
        }
      })
      .catch(() => {
        setLoadingStripePayouts(false);
      });
  }
  useEffect(async () => {
    await setPage(1);
    await setRows(10);
    getData();
  }, []);
  useEffect(() => {
    getData();
  }, [selectedBusinessAccountPayouts, page, refreshStripePayouts, rows]);
  useEffect(() => {
    if (refreshStripePayouts) {
      getData();
    }
  }, [refreshStripePayouts]);
  return (
    <>
      <Header />
      {/* <Balances /> */}
      {/* <Divider /> */}
      <Activities />
    </>
  );
}

export default Stripe;
