import React, { useEffect } from 'react';
import { useRecoilState, } from 'recoil';
import { dashboardOptionAtom } from '../../../atoms/Atoms';
import Header from './shared-components/Header';
import UserAnalytics from './shared-components/UserAnalytics';
import Lifetime from './shared-components/Lifetime';
import Trends from './shared-components/Trends';
import YearToDate from './shared-components/YearToDate';
import MonthToDate from './shared-components/MonthToDate';
import PrepInventory from './shared-components/PrepInventory';
import Insights from './shared-components/Insights';
import TopProducts from './shared-components/TopProducts';
import WeekToDate from './shared-components/WeekToDate';

function Dashboard() {
  const [dashboardOption, setDashboardOption] = useRecoilState(dashboardOptionAtom);

  useEffect(() => {
    setDashboardOption(0);
  }, []);

  return (
    <div>
      <Header />
      {dashboardOption === 0 ?
        <Insights />
        : null}
      {dashboardOption === 1 ?
        <WeekToDate />
        : null}
      {dashboardOption === 2 ?
        <MonthToDate />
        : null}
      {dashboardOption === 3 ?
        <YearToDate />
        : null}
      {dashboardOption === 4 ?
        <Trends />
        : null}
      {dashboardOption === 5 ?
        <Lifetime />
        : null}
      {dashboardOption === 6 ?
        <div>
          <br />
          <UserAnalytics />
        </div>
        : null}
      {dashboardOption === 7 ?
        <div>
          <br />
          <PrepInventory />
        </div>
        : null}
      {dashboardOption === 8 ?
        <div>
          <br />
          <TopProducts />
        </div>
        : null}
      <br />
    </div>
  );
}

export default Dashboard;
