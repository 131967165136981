import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Spin, Tabs } from 'antd';
import { useMount } from 'react-use';
import { useSetRecoilState } from 'recoil';
import TopCustomers from './TopCustomers';
import RepeatCustomers from './RepeatCustomers';
import FirstTimeCustomers from './FirstTimeCustomers';
import RecentCustomers from './RecentCustomers';
import { customerInsightsTabAtom } from '../../../../../atoms/Atoms';
import NewCustomers from '../NewCustomers';
import api from '../../../../../api/api';

function Customers() {
  const { t } = useTranslation();
  const setCustomerInsightsTab = useSetRecoilState(customerInsightsTabAtom);
  const [loadingCustomers, setLoadingCustomers] = useState(false);
  const [totalCustomers, setTotalCustomers] = useState(null);

  function getCustomers() {
    setLoadingCustomers(true);
    api
      .get('analytics/customers/4/6')
      .then((response) => {
        setLoadingCustomers(false);
        setTotalCustomers(response.data.data.totalUsers);
      })
      .catch((error) => {
        console.error(error);
        setLoadingCustomers(false);
      });
  }

  useMount(() => {
    getCustomers();
  });

  return (
    <div>
      <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-4">
        <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
          <Spin spinning={loadingCustomers}>
            <dt className="text-sm font-medium text-gray-500 truncate">{t('total_customers')}</dt>
            <dd className="mt-1 text-3xl font-semibold text-gray-900">{totalCustomers?.toLocaleString()}</dd>
          </Spin>
        </div>
      </dl>
      <br />
      <Tabs defaultActiveKey="0" onChange={(key) => setCustomerInsightsTab(key)}>
        <Tabs.TabPane tab={t('top_customers')} key="0">
          <TopCustomers />
        </Tabs.TabPane>
        <Tabs.TabPane tab={t('repeat_customers')} key="1">
          <RepeatCustomers />
        </Tabs.TabPane>
        <Tabs.TabPane tab={t('first_time_customers')} key="2">
          <FirstTimeCustomers />
        </Tabs.TabPane>
        <Tabs.TabPane tab={t('recent_customers')} key="3">
          <RecentCustomers />
        </Tabs.TabPane>
        <Tabs.TabPane tab={t('new_customers')} key="4">
          <NewCustomers />
        </Tabs.TabPane>
      </Tabs>
    </div>
  );
}

export default Customers;
