import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import {
  Input,
  Table,
  Select,
  Popover,
  Typography,
  Button
} from 'antd';
import { LinkOutlined } from '@ant-design/icons';
import { PageHeader } from '@ant-design/pro-layout';
import { useMount } from 'react-use';
import bookingApi from '../../../../api/bookingApi';
import envConfig from '../../../../envConfig';
import {
  eventTypes,
  bookingStatus,
  getEventBookingStatus,
  formatPhoneNumber
} from '../../../utils/functions';

function PendingPayment() {
  const { Option } = Select;
  const { t } = useTranslation();
  const [query, setQuery] = useState('');
  const [size, setSize] = useState(10);
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);
  const [typeFilter, setTypeFilter] = useState(null);
  const [loading, setLoading] = useState(false);
  const [bookings, setBookings] = useState([]);

  function search(queryString, tempPage) {
    if (!loading) {
      setLoading(true);
      setTotal(0);
      bookingApi
        .post('admin/bookings/history', {
          searchTerm: queryString,
          size,
          page: tempPage,
          eventType: typeFilter,
          status: 0
        })
        .then((response) => {
          setLoading(false);
          setTotal(response.data.data.total);
          setBookings(response.data.data.bookings);
        })
        .catch((error) => {
          setLoading(false);
          console.error(error);
        });
    }
  }

  function onTableChange(tempPage, pageSize) {
    if (tempPage - 1 !== page) {
      setPage(tempPage - 1);
      search(query, tempPage - 1);
    } else {
      setSize(pageSize);
    }
  }

  useMount(() => {
    search(query, 0);
  });

  useEffect(() => {
    setTotal(0);
    setPage(0);
    search(query, 0);
  }, [size, typeFilter]);

  return (
    <>
      <PageHeader
        className="mb-4 px-0"
        title={t('pending_payment')}
      />
      <div className="flex space-x-2">
        <Input.Search
          className="w-full mb-1"
          key="bookingSearch"
          allowClear
          loading={loading}
          enterButton={t('search')}
          onSearch={(value) => {
            setPage(0);
            setQuery(value);
            search(value, 0);
          }}
        />
        <Select
          style={{ width: '140px' }}
          value={typeFilter}
          onChange={(value) => setTypeFilter(value)}
        >
          <Option
            key="any"
            value={null}
            className="text-xs flex items-center"
          >
            {t('any')}
          </Option>
          {_.map(eventTypes, (eventType) => (
            <Option
              key={eventType.type}
              value={eventType.type}
              className="text-xs flex items-center"
            >
              {t(eventType.name)}
            </Option>
          ))}
        </Select>
      </div>
      <Table
        pagination={{
          pageSize: size,
          showSizeChanger: true,
          defaultCurrent: 0,
          current: page + 1,
          total,
          onChange: onTableChange
        }}
        size="small"
        bordered
        rowKey="id"
        loading={loading}
        columns={[
          {
            title: t('created_on'),
            key: 'createdAt',
            dataIndex: 'createdAt',
            align: 'center',
            className: 'text-xs',
            render: (row) => (
              <div className="flex flex-col">
                <span>{moment(row).add(envConfig.REACT_APP_TIMEZONE_OFFSET, 'hours').format('ddd')}</span>
                <span>{moment(row).add(envConfig.REACT_APP_TIMEZONE_OFFSET, 'hours').format('MMM D')}</span>
                <span>{moment(row).add(envConfig.REACT_APP_TIMEZONE_OFFSET, 'hours').format('h:mm A')}</span>
              </div>
            ),
          },
          {
            title: t('event'),
            key: 'event',
            align: 'center',
            className: 'text-xs',
            render: (row) => (
              <span>{row.eventName}</span>
            ),
          },
          {
            title: t('time_slot'),
            key: 'timeSlot',
            align: 'center',
            className: 'text-xs',
            render: (row) => (
              <div className="flex">
                <div className="flex flex-col mx-auto">
                  <span>{moment(row.timeslotStartTime).add(envConfig.REACT_APP_TIMEZONE_OFFSET, 'hours').format('ddd')}</span>
                  <span>{moment(row.timeslotStartTime).add(envConfig.REACT_APP_TIMEZONE_OFFSET, 'hours').format('MMM D')}</span>
                  <span>{moment(row.timeslotStartTime).add(envConfig.REACT_APP_TIMEZONE_OFFSET, 'hours').format('h:mm A')}</span>
                </div>
                <div className="flex flex-col mx-auto">
                  <span>{moment(row.timeslotEndTime).add(envConfig.REACT_APP_TIMEZONE_OFFSET, 'hours').format('ddd')}</span>
                  <span>{moment(row.timeslotEndTime).add(envConfig.REACT_APP_TIMEZONE_OFFSET, 'hours').format('MMM D')}</span>
                  <span>{moment(row.timeslotEndTime).add(envConfig.REACT_APP_TIMEZONE_OFFSET, 'hours').format('h:mm A')}</span>
                </div>
              </div>
            ),
          },
          {
            title: t('name'),
            key: 'name',
            align: 'center',
            className: 'text-xs',
            render: (row) => (
              <span>{`${row.firstName} ${row.lastName}`}</span>
            ),
          },
          {
            title: t('phone'),
            key: 'phoneNumber',
            align: 'center',
            className: 'text-xs',
            render: (row) => (
              <span><Typography.Text copyable>{formatPhoneNumber(row.phoneNumber)}</Typography.Text></span>
            ),
          },
          {
            title: t('email'),
            key: 'email',
            align: 'center',
            className: 'text-xs',
            render: (row) => (
              <span><Typography.Text copyable>{formatPhoneNumber(row.email)}</Typography.Text></span>
            ),
          },
          {
            title: 'Payment Link',
            key: '',
            align: 'center',
            className: 'text-xs',
            render: (text, record) => (
              <>
                <Button
                  size="small"
                  type="primary"
                  onClick={() => {
                    navigator.clipboard.writeText(`${envConfig.WebsiteUrl}profile/reservations/${record.id}`);
                  }}
                >
                  <LinkOutlined />
                </Button>
              </>
            ),
          },
        ]}
        dataSource={bookings}
        expandable={{
          expandRowByClick: false,
          rowExpandable: (record) => record?.purchases.length > 0,
          expandedRowRender: (record) => (
            <Table
              bordered
              rowKey="id"
              key={record.id}
              size="small"
              dataSource={record.purchases}
              columns={[
                {
                  title: t('created_on'),
                  key: 'createdAt',
                  dataIndex: 'createdAt',
                  align: 'center',
                  className: 'text-xs',
                  render: (row) => (
                    <div className="flex flex-col">
                      <span>{moment(row).add(envConfig.REACT_APP_TIMEZONE_OFFSET, 'hours').format('ddd')}</span>
                      <span>{moment(row).add(envConfig.REACT_APP_TIMEZONE_OFFSET, 'hours').format('MMM D')}</span>
                      <span>{moment(row).add(envConfig.REACT_APP_TIMEZONE_OFFSET, 'hours').format('h:mm A')}</span>
                    </div>
                  ),
                },
                {
                  title: t('amount'),
                  key: 'amount',
                  align: 'center',
                  className: 'text-xs',
                  render: (row) => (
                    <span>${row.amount.toFixed(2)}</span>
                  ),
                },
                {
                  title: t('cancelled'),
                  key: 'cancelled',
                  align: 'center',
                  className: 'text-xs',
                  render: (row) => (
                    <span>{row.cancelled ? t('yes') : t('no')}</span>
                  ),
                }
              ]}
            />
          )
        }}
      />
    </>
  );
}
export default PendingPayment;
