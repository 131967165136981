import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useJsonToCsv } from 'react-json-csv';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { Col, Row, Typography, Table, Button, Tabs, Tooltip } from 'antd';
import { CloudDownloadOutlined, SyncOutlined, FileSearchOutlined } from '@ant-design/icons';
import { useRecoilState, useRecoilValue } from 'recoil';
import { prepInventoryItemsAtom, showPrepAtom, prepDateRangeAtom, dashboardSelectedStoreAtom } from '../../../../atoms/Atoms';
import api from '../../../../api/api';
import PrepData from '../../../shared-components/modals/TodayPrepInventoryData';

function PrepInventory() {
  const [activeKey, setActiveKey] = useState('1');
  const [loading, setLoading] = useState(false);
  const [todayData, setTodayData] = useState(null);
  const [tomorrowData, setTomorrowData] = useState(null);
  const [futureData, setFutureData] = useState(null);
  const [items, setItems] = useRecoilState(prepInventoryItemsAtom);
  const [showModal, setShowModal] = useRecoilState(showPrepAtom);
  const [prepDateRange, setPrepDateRange] = useRecoilState(prepDateRangeAtom);
  const { t } = useTranslation();
  const { saveAsCsv } = useJsonToCsv();
  const [size, setSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [todayDataLength, setTodayDataLength] = useState(0);
  const [tomorrowDataLength, setTomorrowDataLength] = useState(0);
  const [futureDataLength, setFutureDataLength] = useState(0);
  const selectedStoreId = useRecoilValue(dashboardSelectedStoreAtom);

  const listFields = {
    upc: 'UPC',
    brand: t('brand'),
    name: t('name'),
    description: t('description'),
    count: t('amount'),
  };

  const columns = [
    {
      title: t('brand'),
      key: 'name',
      align: 'left',
      className: 'text-xs',
      render: (text) => <span>{text.brand}</span>,
    },
    {
      title: t('product'),
      key: 'name',
      align: 'left',
      className: 'text-xs',
      render: (text) => <span>{text.name} {text.description}</span>,
    },
    {
      title: t('amount'),
      dataIndex: 'count',
      key: 'count',
      align: 'left',
      className: 'text-xs',
      render: (text) => <span>{text}</span>,
      sorter: (a, b) => a.count - b.count,
    },
    {
      title: t('type'),
      dataIndex: 'weightType',
      key: 'type',
      align: 'left',
      className: 'text-xs',
      render: (text) => <span>{text}</span>,
      sorter: (a, b) => a.count - b.count,
    }
  ];

  const onTableChange = (page, pageSize) => {
    if (page - 1 !== currentPage) {
      setCurrentPage(page - 1);
    } else {
      setSize(pageSize);
    }
  };

  function expandedRowRenderer(record) {
    return (
      <Table
        size="small"
        bordered
        pagination={false}
        columns={[
          {
            title: t('amount'),
            key: 'amount',
            align: 'left',
            className: 'text-xs',
            render: (text) => <span>{text}</span>,
          }
        ]}
        dataSource={record.packages}
      />
    );
  }

  function getWeightType(item) {
    if (item.hasRandomWeight && item.weightType === 0) return 'lb(s)';
    if (item.hasRandomWeight && item.weightType === 4) return 'unit(s)';
    if (!item.hasRandomWeight) return 'unit(s)';
    return 'unknown';
  }

  function mapData(data) {
    const mapped = _.map(data, (p, index) => ({
      key: index,
      count: p.count,
      name: p.name,
      brand: p.brand,
      upc: p.upc,
      description: p.description,
      imageUrl: p.imageUrl,
      packages: p.packages,
      hasRandomWeight: p.hasRandomWeight,
      weightType: getWeightType(p),
    }));

    return mapped;
  }

  function getTodayData() {
    setLoading(true);
    const start = moment().startOf('day').utc();
    const end = moment().startOf('day').add(1, 'days').utc();

    const dateRange = {
      StartDate: start,
      EndDate: end
    };
    api
      .post(
        `admin/orderitems/${selectedStoreId}`,
        dateRange
      )
      .then((response) => {
        setLoading(false);
        setTodayData(mapData(response.data.data.results));
        setTodayDataLength(response.data.data.results.length);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  }

  function getTomorrowData() {
    setLoading(true);
    const start = moment().startOf('day').add(1, 'days').utc();
    const end = moment().startOf('day').add(2, 'days').utc();
    const dateRange = {
      StartDate: start,
      EndDate: end
    };
    api
      .post(
        `admin/orderitems/${selectedStoreId}`,
        dateRange
      )
      .then((response) => {
        setLoading(false);
        setTomorrowData(mapData(response.data.data.results));
        setTomorrowDataLength(response.data.data.results.length);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  }

  function getFutureData() {
    setLoading(true);
    const start = moment().startOf('day').add(2, 'days').utc();
    const end = moment().startOf('day').add(10, 'days').utc();
    const dateRange = {
      StartDate: start,
      EndDate: end
    };
    api
      .post(
        `admin/orderitems/${selectedStoreId}`,
        dateRange
      )
      .then((response) => {
        setLoading(false);
        setFutureData(mapData(response.data.data.results));
        setFutureDataLength(response.data.data.results.length);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  }

  function getData() {
    if (activeKey === '1') {
      getTodayData();
    } else if (activeKey === '2') {
      getTomorrowData();
    } else if (activeKey === '3') {
      getFutureData();
    }
  }

  useEffect(() => {
    if (activeKey === '1') {
      getTodayData();
    } else if (activeKey === '2') {
      getTomorrowData();
    } else if (activeKey === '3') {
      getFutureData();
    }
  }, [activeKey, selectedStoreId]);

  return (
    <div className="p-4 border bg-gray-100 rounded-lg mb-4">
      <div className="flex flex-col" style={{ float: 'right' }}>
        <Button
          className="mb-4"
          size="small"
          type="primary"
          loading={loading}
          icon={<SyncOutlined spin={loading} />}
          onClick={() => getData()}
        >
          {t('reload')}
        </Button>
      </div>
      <Row align="middle" gutter={[12, 12]} justify="space-between">
        <Col span={10}>
          <Typography.Title level={5}>{t('prep_inventory')}</Typography.Title>
          <Typography.Text type="secondary">
            {t('prep_inventory_desc')}
          </Typography.Text>
        </Col>
      </Row>
      <br />
      <div style={{ align: 'center' }}>
        <Tabs
          activeKey={activeKey}
          onChange={(key) => {
            setActiveKey(key);
          }}
        >
          <Tabs.TabPane
            tab={(
              <Tooltip title={t('current_day')}>
                <span>{t('today')}</span>
              </Tooltip>
            )}
            key="1"
          >
            <div>
              <div>
                <button
                  style={{ float: 'left' }}
                  type="button"
                  className="flex items-center text-white text-lg justify-center bg-blue-400 hover:bg-blue-200 rounded-full w-10 h-10 ring-0 focus:ring-0 outline-none focus:outline-none transition duration-500 ease-in"
                  onClick={() => { setItems(todayData); setShowModal(true); setPrepDateRange('Today'); }}
                >
                  <FileSearchOutlined className="h-5 w-5" />
                </button>
                <Button
                  style={{ float: 'right' }}
                  size="small"
                  icon={<CloudDownloadOutlined />}
                  onClick={() => {
                    const data = todayData;
                    saveAsCsv({
                      data,
                      fields: listFields,
                      filename: `prepInventory_${new Date().toLocaleDateString()}`,
                    });
                  }}
                >
                  {t('export_list')}
                </Button>
              </div>
              <br />
              <br />
              <br />
              <Table
                size="small"
                loading={loading}
                bordered
                pagination={{
                  pageSize: size,
                  showSizeChanger: true,
                  defaultCurrent: 0,
                  current: currentPage + 1,
                  total: todayDataLength,
                  onChange: onTableChange
                }}
                columns={columns}
                dataSource={todayData}
                expandable={{
                  rowExpandable: (record) => record.hasRandomWeight,
                  expandRowByClick: false,
                  expandedRowRender: (record) => expandedRowRenderer(record)
                }}
              />
            </div>
          </Tabs.TabPane>
          <Tabs.TabPane
            tab={(
              <Tooltip title={t('next_day')}>
                <span>{t('tomorrow')}</span>
              </Tooltip>
            )}
            key="2"
          >
            <div>
              <div>
                <button
                  style={{ float: 'left' }}
                  type="button"
                  className="flex items-center text-white text-lg justify-center bg-blue-400 hover:bg-blue-200 rounded-full w-10 h-10 ring-0 focus:ring-0 outline-none focus:outline-none transition duration-500 ease-in"
                  onClick={() => { setItems(tomorrowData); setShowModal(true); setPrepDateRange('Tomorrow'); }}
                >
                  <FileSearchOutlined className="h-5 w-5" />
                </button>
                <Button
                  style={{ float: 'right' }}
                  size="small"
                  icon={<CloudDownloadOutlined />}
                  onClick={() => {
                    const data = tomorrowData;
                    saveAsCsv({
                      data,
                      fields: listFields,
                      filename: `prepInventory_${new Date().toLocaleDateString()}`,
                    });
                  }}
                >
                  {t('export_list')}
                </Button>
              </div>
              <br />
              <br />
              <br />
              <Table
                size="small"
                loading={loading}
                bordered
                pagination={{
                  pageSize: size,
                  showSizeChanger: true,
                  defaultCurrent: 0,
                  current: currentPage + 1,
                  total: tomorrowDataLength,
                  onChange: onTableChange
                }}
                columns={columns}
                dataSource={tomorrowData}
                expandable={{
                  rowExpandable: (record) => record.hasRandomWeight,
                  expandRowByClick: false,
                  expandedRowRender: (record) => expandedRowRenderer(record)
                }}
              />
            </div>
          </Tabs.TabPane>
          <Tabs.TabPane
            tab={(
              <Tooltip title={t('from_two_to_ten_days_from_now')}>
                <span>{t('future_orders')}</span>
              </Tooltip>
            )}
            key="3"
          >
            <div>
              <div>
                <button
                  style={{ float: 'left' }}
                  type="button"
                  className="flex items-center text-white text-lg justify-center bg-blue-400 hover:bg-blue-200 rounded-full w-10 h-10 ring-0 focus:ring-0 outline-none focus:outline-none transition duration-500 ease-in"
                  onClick={() => { setItems(futureData); setShowModal(true); setPrepDateRange('Future'); }}
                >
                  <FileSearchOutlined className="h-5 w-5" />
                </button>
                <Button
                  style={{ float: 'right' }}
                  size="small"
                  icon={<CloudDownloadOutlined />}
                  onClick={() => {
                    const data = futureData;
                    saveAsCsv({
                      data,
                      fields: listFields,
                      filename: `prepInventory_${new Date().toLocaleDateString()}`,
                    });
                  }}
                >
                  {t('export_list')}
                </Button>
              </div>
              <br />
              <br />
              <br />
              <Table
                size="small"
                loading={loading}
                bordered
                pagination={{
                  pageSize: size,
                  showSizeChanger: true,
                  defaultCurrent: 0,
                  current: currentPage + 1,
                  total: futureDataLength,
                  onChange: onTableChange
                }}
                columns={columns}
                dataSource={futureData}
                expandable={{
                  rowExpandable: (record) => record.hasRandomWeight,
                  expandRowByClick: false,
                  expandedRowRender: (record) => expandedRowRenderer(record)
                }}
              />
            </div>
          </Tabs.TabPane>
        </Tabs>
      </div>
      {showModal ? <PrepData /> : null }
    </div>
  );
}

export default PrepInventory;
