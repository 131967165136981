import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import {
  useRecoilState,
  useRecoilValue,
} from 'recoil';
import moment from 'moment';
import {
  Input,
  Modal,
  Button,
  Avatar,
  Tooltip,
  notification,
} from 'antd';
import { Comment } from '@ant-design/compatible';
import { PageHeader } from '@ant-design/pro-layout';
import Grocefy from '../../../../assets/images/grocefyLogoAlone.png';
import {
  tokenAtom,
  selectedTicketAtom,
  showAddTicketLogAtom
} from '../../../../atoms/Atoms';
import api from '../../../../api/api';

function AddTicketLogModal() {
  const { TextArea } = Input;
  const token = useRecoilValue(tokenAtom);
  const selectedTicket = useRecoilValue(selectedTicketAtom);
  const [message, setMessage] = useState('');
  const [showAddTicketLog, setShowAddTicketLog] = useRecoilState(showAddTicketLogAtom);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const showErrorMessage = function (errorMessage) {
    notification.open({
      message: '',
      description: (
        <Comment
          author={<span>Grocefy</span>}
          avatar={<Avatar src={Grocefy} alt="grocefy" />}
          content={
            <p className="text-sm">
              {errorMessage}
            </p>
          }
          datetime={
            <Tooltip title={moment().format('YYYY-MM-DD HH:mm:ss')}>
              <span>{moment().fromNow()}</span>
            </Tooltip>
          }
        />
      ),
    });
  };
  const closeTicketModel = function () {
    if (!loading) {
      setShowAddTicketLog(false);
    }
  };
  const onChangeMessage = function (obj) {
    setMessage(obj.target.value);
  };

  const createLogClick = function () {
    setLoading(true);
    const data = {
      Message: message
    };
    api.post(
      `ticketboards/ticket/log/${selectedTicket.id}`,
      data
    )
      .then((response) => {
        setLoading(false);
        if (response.data.success) {
          const newTicket = response.data.data;
          setShowAddTicketLog(false);
        } else {
          showErrorMessage(response.data.error);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  useEffect(() => {
    if (showAddTicketLog) {
      setMessage('');
    }
  }, [showAddTicketLog]);

  return (
    <>
      {showAddTicketLog && (
        <Modal
          closable="true"
          centered="true"
          open={showAddTicketLog}
          onCancel={() => closeTicketModel()}
          footer={[
            <Button
              key="createButton"
              disabled={loading}
              onClick={() => createLogClick()}
            >
              {t('add')}
            </Button>,
            <Button
              key="back"
              disabled={loading}
              onClick={() => closeTicketModel()}
            >
              {t('close')}
            </Button>
          ]}
        >
          <PageHeader
            title={t('create_log')}
          />
          <TextArea
            placeholder="Message"
            onChange={(obj) => onChangeMessage(obj)}
            rows={4}
            value={message}
          />
        </Modal>
      )}
    </>
  );
}

export default AddTicketLogModal;
