import React from 'react';
import { useTranslation } from 'react-i18next';
import { Tabs } from 'antd';
import OutOfStock from './shared-components/CurrentOutOfStockBody';
import UnderweightsBody from './shared-components/CurrentUnderweightsBody';
import Header from './shared-components/CurrentHeader';
import SubstitutesBody from './shared-components/CurrentSubstitutesBody';
import SubstitutesOverBody from './shared-components/CurrentSubstitutesOverBody';

function LostSales() {
  const { t } = useTranslation();
  return (
    <div>
      <Header />
      <Tabs defaultActiveKey="1">
        <Tabs.TabPane tab={t('out_of_stock')} key="1">
          <OutOfStock />
        </Tabs.TabPane>
        <Tabs.TabPane tab={t('underweights')} key="2">
          <UnderweightsBody />
        </Tabs.TabPane>

        <Tabs.TabPane tab={`${t('substitutes')} (${t('under')})`} key="4">
          <SubstitutesBody />
        </Tabs.TabPane>
        <Tabs.TabPane tab={`${t('substitutes')} (${t('over')})`} key="5">
          <SubstitutesOverBody />
        </Tabs.TabPane>
      </Tabs>
    </div>
  );
}

export default LostSales;
