import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import { notification, Modal, Avatar, Input, Button } from 'antd';
import { Comment } from '@ant-design/compatible';
import { PageHeader } from '@ant-design/pro-layout';
import { selectedOrderAtom, reloadOrder, securityCodeAtom, cancelOrderAtom, openSecurityCodeModalAtom } from '../../../atoms/Atoms';
import Grocefy from '../../../assets/images/grocefyLogoAlone.png';

function CancelOrderSecurity() {
  const [open, setOpen] = useRecoilState(openSecurityCodeModalAtom);
  const [selectedOrder, setSelectedOrder] = useRecoilState(selectedOrderAtom);
  const [reload, setReload] = useRecoilState(reloadOrder);
  const [loading, setLoading] = useState(false);
  const [pin, setPin] = useState('');
  const { TextArea } = Input;
  const { t } = useTranslation();
  const [securityPin, setSecurityPin] = useRecoilState(securityCodeAtom);
  const [cancelOrder, setCancelOrder] = useRecoilState(cancelOrderAtom);

  const onChangeMessage = function (obj) {
    setPin(obj.target.value);
  };

  const closeTicketModal = function () {
    if (!loading) {
      setOpen(false);
    }
  };

  const compareCodes = function () {
    setLoading(true);
    if (pin === securityPin) {
      setCancelOrder(true);
      setOpen(false);
    } else {
      setOpen(false);
      notification.open({
        pin: '',
        description: (
          <Comment
            author={<span>Grocefy</span>}
            avatar={<Avatar src={Grocefy} alt="grocefy" />}
            content={
              <p className="text-sm">
                Invalid Code
              </p>
            }
          />
        ),
      });
    }
  };

  return (
    <>
      {open && (
        <Modal
          closable="true"
          centered="true"
          open={open}
          onCancel={() => { setOpen(false); }}
          footer={[
            <Button
              key="create"
              disabled={loading}
              loading={loading}
              onClick={() => compareCodes()}
            >
              Verify
            </Button>,
            <Button
              key="close"
              disabled={loading}
              onClick={() => closeTicketModal()}
            >
              {t('close')}
            </Button>
          ]}
        >
          <PageHeader title="Security Code" />
          <TextArea
            placeholder="Security Code"
            onChange={(obj) => onChangeMessage(obj)}
            rows={1}
          />
        </Modal>
      )}
    </>
  );
}

export default CancelOrderSecurity;
