import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import {
  useRecoilState,
  useSetRecoilState,
  useRecoilValueLoadable
} from 'recoil';
import { useTranslation } from 'react-i18next';
import {
  Input,
  Switch,
  Select,
  notification,
  Avatar,
  Tooltip,
  Drawer,
  Button
} from 'antd';
import { Comment } from '@ant-design/compatible';
import moment from 'moment';
import {
  selectedDriverAtom,
  reloadDriversAtom
} from '../../../../atoms/Atoms';
import { configSelector } from '../../../../atoms/Selectors';
import Grocefy from '../../../../assets/images/grocefyLogoAlone.png';
import api from '../../../../api/api';

function EditDriverDrawer() {
  const { Option, OptGroup } = Select;
  const config = useRecoilValueLoadable(configSelector);
  const [selectedDriver, setSelectedDriver]
    = useRecoilState(selectedDriverAtom);
  const setReloadDrivers = useSetRecoilState(reloadDriversAtom);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const { t } = useTranslation();

  const showMessage = function (message) {
    notification.open({
      message: '',
      description: (
        <Comment
          author={<span>Grocefy</span>}
          avatar={<Avatar src={Grocefy} alt="grocefy" />}
          content={
            <p className="text-sm">
              {message}
            </p>
          }
          datetime={
            <Tooltip title={moment().format('YYYY-MM-DD HH:mm:ss')}>
              <span>{moment().fromNow()}</span>
            </Tooltip>
          }
        />
      ),
    });
  };

  const updateDriver = function () {
    setLoading(true);
    api
      .post(
        'users/runner',
        data
      )
      .then((response) => {
        setReloadDrivers(true);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  const removeDriver = function () {
    setLoading(true);
    api
      .delete(`users/deliveryprofile/${data.id}`)
      .then((response) => {
        setReloadDrivers(true);
        setSelectedDriver(null);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  useEffect(() => {
    if (selectedDriver) {
      setData(selectedDriver);
    }
  }, [selectedDriver]);

  return (
    <>
      <Drawer
        title={t('edit_drivers')}
        placement="right"
        closable
        onClose={() => setSelectedDriver(null)}
        open={selectedDriver}
        width={400}
      >
        <div
          style={{ marginBottom: 10 }}
        >
          <strong>{t('is_active')}</strong>
          <Switch
            style={{ float: 'right' }}
            disabled={loading}
            onChange={(obj) => {
              const temp = JSON.parse(JSON.stringify(data));
              temp.isActive = obj;
              setData(temp);
            }}
            checked={data?.isActive}
          />
        </div>
        <div
          style={{ marginBottom: 10 }}
        >
          <strong>{t('is_third_party_driver')}</strong>
          <Switch
            style={{ float: 'right' }}
            disabled={loading}
            onChange={(obj) => {
              const temp = JSON.parse(JSON.stringify(data));
              temp.isThirdPartyDriver = obj;
              setData(temp);
            }}
            checked={data?.isThirdPartyDriver}
          />
        </div>
        <div
          hidden={!data?.isThirdPartyDriver}
          style={{ marginBottom: 10 }}
        >
          <strong>{t('third_party_service')}</strong>
          <Select
            loading={loading}
            disabled={loading}
            value={data?.thirdPartyDriverService}
            onChange={(value) => {
              const temp = JSON.parse(JSON.stringify(data));
              temp.thirdPartyDriverService = value;
              setData(temp);
            }}
          >
            <OptGroup className="text-xs flex items-center">
              {_.map(config?.contents?.data?.thirdPartyServices, (service) => (
                <Option
                  key={service.id}
                  value={service.id}
                  className="text-xs flex items-center"
                >
                  {service.name}
                </Option>
              ))}
            </OptGroup>
          </Select>
        </div>
        <div
          hidden={!data?.isThirdPartyDriver}
          style={{ marginBottom: 10 }}
        >
          <strong>{t('third_party_api_key')}</strong>
          <Input
            disabled={loading}
            onChange={(obj) => {
              const temp = JSON.parse(JSON.stringify(data));
              temp.thirdPartyApiKey = obj.target.value;
              setData(temp);
            }}
            value={data?.thirdPartyApiKey}
          />
        </div>
        <div
          style={{
            float: 'right',
            marginTop: '10px'
          }}
        >
          <Button
            style={{
              marginLeft: '10px'
            }}
            loading={loading}
            type="primary"
            onClick={() => { updateDriver(); }}
          >
            {t('update')}
          </Button>
          <Button
            style={{
              marginLeft: '10px'
            }}
            loading={loading}
            disabled={loading}
            type="primary"
            danger
            onClick={() => { removeDriver(); }}
          >
            {t('delete')}
          </Button>
        </div>
      </Drawer>
    </>
  );
}
export default EditDriverDrawer;
