import React, { useEffect, useState } from 'react';
import moment from 'moment';
import _ from 'lodash';
import {
  Col,
  Row,
  Statistic,
  Typography,
  Select,
  Table,
  Button,
  Spin
} from 'antd';
import { useTranslation } from 'react-i18next';
import { SyncOutlined } from '@ant-design/icons';
import { useRecoilValue } from 'recoil';
import { convertTimeFactor } from '../../../utils/functions';
import { timezoneAtom, } from '../../../../atoms/Atoms';
import api from '../../../../api/api';

function Customers() {
  const [loading, setLoading] = useState(false);
  const timezone = useRecoilValue(timezoneAtom);
  const [data, setData] = useState(null);
  const [timeFactor, setTimeFactor] = useState(0);
  const [top, setTop] = useState(1);
  const days = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15,
    16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31
  ];
  const weeks = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15,
    16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31,
    32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47,
    48, 49, 50, 51, 52
  ];
  const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  const { Option, OptGroup } = Select;
  const [usersData, setUsersData] = useState(null);
  const [reload, setReload] = useState(false);
  const { t } = useTranslation();
  const width = window.innerWidth;

  function percent(value) {
    return ((value / data.totalUsers) * 100).toFixed(2);
  }

  function mapData(users) {
    const mapped = _.map(users, (p, index) => ({
      key: index,
      count: p.count,
      startDate: moment(`${p.startDate}`)
        .tz(timezone)
        .format('MMMM Do YYYY'),
      endDate: moment(`${p.endDate}`)
        .tz(timezone)
        .format('MMMM Do YYYY'),
    }));
    // return _.sortBy(mapped, (s) => s.startDate, 'desc');
    return mapped;
  }

  function getData() {
    setLoading(true);
    api
      .get(`analytics/customers/${timeFactor}/${top}`)
      .then((response) => {
        setLoading(false);
        setData(response.data.data);
        const mappedUsers = mapData(response.data.data.usersCreated);
        setUsersData(mappedUsers);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  }

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    getData();
  }, [timeFactor, top]);

  useEffect(() => {
    if (reload) {
      getData();
    }
  }, [reload]);

  return (
    <div className="p-4 border bg-gray-100 rounded-lg mb-4">
      <div className="flex flex-col" style={{ float: 'right' }}>
        <Button
          className="mb-4"
          size="small"
          type="primary"
          loading={loading}
          icon={<SyncOutlined spin={loading} />}
          onClick={() => {
            setReload(!reload);
          }}
        >
          {t('reload')}
        </Button>
      </div>
      <Row align="middle" gutter={[12, 12]} justify="space-between">
        <Col xs={24} sm={24} md={24} lg={10} xl={10}>
          <Typography.Title level={5}>{t('customer_analytics')}</Typography.Title>
          <Typography.Text type="secondary">
            {t('analytics_customers_description')}
          </Typography.Text>
        </Col>
        <Col xs={24} sm={24} md={24} lg={6} xl={6}>
          <div className="flex flex-row">
            {' '}
            <div className="mr-10">
              <Statistic title="Timespan" value={`${top} ${t(convertTimeFactor(timeFactor))}(s)`} />
            </div>
          </div>
        </Col>
        <Col xs={12} sm={12} md={12} lg={4} xl={4}>
          <div>
            <Select
              listHeight={500}
              style={{ width: 150 }}
              disabled={loading}
              onChange={(value) => setTimeFactor(value)}
              defaultValue={0}
            >
              <Option disabled>{t('timeFactor_selector_description')}</Option>
              <Option value={0}>{t('days')}</Option>
              <Option value={1}>{t('weeks')}</Option>
              <Option value={2}>{t('months')}</Option>
              <Option value={3} hidden>{t('years')}</Option>
            </Select>
          </div>
        </Col>
        <Col xs={12} sm={12} md={12} lg={4} xl={4}>
          {timeFactor === 0 ?
            <div>
              <Select
                listHeight={500}
                style={{ width: 150 }}
                disabled={loading}
                onChange={(value) => setTop(value)}
                defaultValue={top}
              >
                <OptGroup className="text-xs flex items-center">
                  {_.map(days, (num) => (
                    <Option
                      key={num}
                      value={num}
                      className="text-xs flex items-center"
                    >
                      {num}
                    </Option>
                  ))}
                </OptGroup>
              </Select>
            </div>
            : null}
          {timeFactor === 1 ?
            <div>
              <Select
                listHeight={500}
                disabled={loading}
                style={{ width: 150 }}
                onChange={(value) => setTop(value)}
                defaultValue={top}
              >
                <OptGroup className="text-xs flex items-center">
                  {_.map(weeks, (num) => (
                    <Option
                      key={num}
                      value={num}
                      className="text-xs flex items-center"
                    >
                      {num}
                    </Option>
                  ))}
                </OptGroup>
              </Select>
            </div>
            : null}
          {timeFactor === 2 ?
            <div>
              <Select
                listHeight={500}
                disabled={loading}
                style={{ width: 150 }}
                onChange={(value) => setTop(value)}
                defaultValue={top}
              >
                <OptGroup className="text-xs flex items-center">
                  {_.map(months, (num) => (
                    <Option
                      key={num}
                      value={num}
                      className="text-xs flex items-center"
                    >
                      {num}
                    </Option>
                  ))}
                </OptGroup>
              </Select>
            </div>
            : null}
        </Col>
        <Col>
          {' '}
        </Col>
      </Row>
      <br />
      <br />
      <div style={{ align: 'center' }}>
        <div>
          <h3 className="text-lg leading-6 font-medium text-gray-900">{t('customers')}</h3>
          <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-4">
            <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
              <Spin spinning={loading}>
                <dt className="text-sm font-medium text-gray-500 truncate">{t('registered')}</dt>
                <dd className="mt-1 text-3xl font-semibold text-gray-900">{data && data.totalUsers.toFixed().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</dd>
              </Spin>
            </div>
            <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
              <Spin spinning={loading}>
                <dt className="text-sm font-medium text-gray-500 truncate">{t('at_least_one_card')}</dt>
                <dd className="mt-1 text-3xl font-semibold text-gray-900">{data && data?.totalUsersWithAtleastOneCard.toFixed().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  <div className="inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium md:mt-2 lg:mt-0" style={{ float: 'right', marginTop: '30px' }}>
                    {data &&
                      ((data?.totalUsersWithAtleastOneCard /
                      data.totalUsers) * 100).toFixed(2)}%
                  </div>
                </dd>
              </Spin>
            </div>
            <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
              <Spin spinning={loading}>
                <dt className="text-sm font-medium text-gray-500 truncate">{t('at_least_one_location')}</dt>
                <dd className="mt-1 text-3xl font-semibold text-gray-900">{data && data?.totalUsersWithAtleastOneLocation.toFixed().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  <div className="inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium md:mt-2 lg:mt-0" style={{ float: 'right', marginTop: '30px' }}>
                    {data &&
                      ((data?.totalUsersWithAtleastOneLocation /
                      data.totalUsers) * 100).toFixed(2)}%
                  </div>
                </dd>
              </Spin>
            </div>
            <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
              <Spin spinning={loading}>
                <dt className="text-sm font-medium text-gray-500 truncate">{t('at_least_one_order')}</dt>
                <dd className="mt-1 text-3xl font-semibold text-gray-900">{data && data?.totalUsersWithAtleastOneOrder.toFixed().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  <div className="inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium md:mt-2 lg:mt-0" style={{ float: 'right', marginTop: '30px' }}>
                    {data &&
                      ((data?.totalUsersWithAtleastOneOrder /
                      data.totalUsers) * 100).toFixed(2)}%
                  </div>
                </dd>
              </Spin>
            </div>
          </dl>
        </div>
      </div>
      <br />
      <br />
      <div style={{ align: 'center' }}>
        <div>
          <h3 className="text-lg leading-6 font-medium text-gray-900">{t('demographics')}</h3>
          <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-5">
            <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
              <Spin spinning={loading}>
                <dt className="text-sm font-medium text-gray-500 truncate">{t('age_unknown')}</dt>
                <dd className="mt-1 text-3xl font-semibold text-gray-900">
                  {data && data.usersAge17Lower.toLocaleString()}
                  <br />
                  <div
                    className="inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium md:mt-2 lg:mt-0"
                  >
                    {data && percent(data.usersAge17Lower)} {t('demografics_content')}
                  </div>
                </dd>
              </Spin>
            </div>
            <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
              <Spin spinning={loading}>
                <dt className="text-sm font-medium text-gray-500 truncate">Age 18 to 29</dt>
                <dd className="mt-1 text-3xl font-semibold text-gray-900">
                  {data && data.usersAge18to29.toLocaleString()}
                  <br />
                  <div
                    className="inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium md:mt-2 lg:mt-0"
                  >
                    {data && percent(data.usersAge18to29)}{t('demografics_content')}
                  </div>
                </dd>
              </Spin>
            </div>
            <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
              <Spin spinning={loading}>
                <dt className="text-sm font-medium text-gray-500 truncate">Age 30 to 39</dt>
                <dd className="mt-1 text-3xl font-semibold text-gray-900">
                  {data && data.usersAge30to39.toLocaleString()}
                  <br />
                  <div
                    className="inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium md:mt-2 lg:mt-0"
                  >
                    {data && percent(data.usersAge30to39)}{t('demografics_content')}
                  </div>
                </dd>
              </Spin>
            </div>
            <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
              <Spin spinning={loading}>
                <dt className="text-sm font-medium text-gray-500 truncate">Age 40 to 59</dt>
                <dd className="mt-1 text-3xl font-semibold text-gray-900">
                  {data && data.usersAge40to59.toLocaleString()}
                  <br />
                  <div
                    className="inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium md:mt-2 lg:mt-0"
                  >
                    {data && percent(data.usersAge40to59)}{t('demografics_content')}
                  </div>
                </dd>
              </Spin>
            </div>
            <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
              <Spin spinning={loading}>
                <dt className="text-sm font-medium text-gray-500 truncate">Age 60+</dt>
                <dd className="mt-1 text-3xl font-semibold text-gray-900">
                  {data && data.usersAge60Plus.toLocaleString()}
                  <br />
                  <div
                    className="inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium md:mt-2 lg:mt-0"
                  >
                    {data && percent(data.usersAge60Plus)}{t('demografics_content')}
                  </div>
                </dd>
              </Spin>
            </div>
          </dl>
        </div>
      </div>
      <br />
      <div style={{ align: 'center' }}>
        <div>
          <Spin spinning={loading}>
            <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-4">
              {data && _.map(data.genders, (item) => (
                <div key={item.gender} className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
                  <dt className="text-sm font-medium text-gray-500 truncate">{item.gender}</dt>
                  <dd className="mt-1 text-3xl font-semibold text-gray-900">
                    {item.count.toLocaleString()}
                    <br />
                    <div
                      className="inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium md:mt-2 lg:mt-0"
                    >
                      {data && percent(item.count)}{t('demografics_content')}
                    </div>
                  </dd>
                </div>
              ))}
            </dl>
          </Spin>
        </div>
      </div>
      <br />
      <br />
      <Table
        className={width < 1024 ? 'hidden' : null}
        size="small"
        loading={loading}
        bordered
        pagination={false}
        columns={[
          {
            title: t('start_date'),
            dataIndex: 'startDate',
            key: 'startDate',
            align: 'left',
            className: 'text-xs',
            render: (text) => <span>{text}</span>,
          },
          {
            title: t('end_date'),
            dataIndex: 'endDate',
            key: 'endDate',
            align: 'left',
            className: 'text-xs',
            render: (text) => <span>{text}</span>,
          },
          {
            title: t('registered'),
            dataIndex: 'count',
            key: 'count',
            align: 'left',
            className: 'text-xs',
            render: (text) => <span>{text}</span>,
          },
        ]}
        dataSource={usersData}
      />
    </div>
  );
}

export default Customers;
