import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import {
  Drawer,
  Input,
  Upload,
  Avatar,
  Select,
  notification,
  Tooltip,
  Switch,
  InputNumber,
  Button,
  Image
} from 'antd';
import { Comment } from '@ant-design/compatible';
import {
  InboxOutlined,
} from '@ant-design/icons';
import { useRecoilState, useRecoilValue, useSetRecoilState, } from 'recoil';
import {
  editBenefitAtom,
  selectedBenefitAtom,
  reloadSubscriptionBenefitsAtom,
  selectedSubscriptionAtom
} from '../../../../../atoms/Atoms';
import Grocefy from '../../../../../assets/images/grocefyLogoAlone.png';
import bookingApi from '../../../../../api/bookingApi';
import api from '../../../../../api/api';
import { isEmpty, mapSubscriptionBenefitType, mapSubscriptionRecurrence } from '../../../../utils/functions';

function EditSubscriptionBenefit() {
  const { t, i18n } = useTranslation();
  const { Option } = Select;
  const { Dragger } = Upload;
  const { TextArea } = Input;
  const setReload = useSetRecoilState(reloadSubscriptionBenefitsAtom);
  const selectedSubscription = useRecoilValue(selectedSubscriptionAtom);
  const [selectedBenefit, setSelectedBenefit] = useRecoilState(selectedBenefitAtom);
  const [editBenefit, setEditBenefit] = useRecoilState(editBenefitAtom);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [imageData, setImageData] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [serviceTypes, setServiceTypes] = useState([]);

  function getServiceTypes() {
    setLoading(true);
    bookingApi
      .get('servicetypes/list')
      .then((response) => {
        setLoading(false);
        setServiceTypes(response.data.data);
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  }

  const imageDraggerProps = {
    name: 'file',
    accept: 'image/*',
    multiple: false,
    showUploadList: false,
    beforeUpload: (file) => {
      setImageData(file);
      return false;
    }
  };

  async function uploadImage(tempImageData) {
    const file = tempImageData;
    const formdata = new FormData();
    formdata.append('file', file);
    setIsSubmitting(true);
    const response = await api
      .post('utility/benefits/uploadfile', formdata);
    setIsSubmitting(false);
    if (response.data.success) {
      return response.data.data;
    }
    console.error(response.data.error);
    return null;
  }

  function showMessage(message) {
    notification.open({
      message: '',
      description: (
        <Comment
          author={<span>Grocefy</span>}
          avatar={<Avatar src={Grocefy} alt="grocefy" />}
          content={
            <p className="text-sm">
              {message}
            </p>
          }
          datetime={
            <Tooltip title={moment().format('YYYY-MM-DD HH:mm:ss')}>
              <span>{moment().fromNow()}</span>
            </Tooltip>
          }
        />
      ),
    });
  }

  function submit() {
    setLoading(true);
    if (selectedBenefit.id) {
      bookingApi.put('subscriptions/benefit', data)
        .then((response) => {
          setLoading(false);
          if (response.data.error) {
            showMessage(response.data.error);
          } else {
            setReload(true);
            setEditBenefit(false);
            setSelectedBenefit(null);
          }
        })
        .catch((error) => {
          setLoading(false);
          showMessage(error.message);
        });
    } else {
      bookingApi.post('subscriptions/benefit', data)
        .then((response) => {
          setLoading(false);
          if (response.data.error) {
            showMessage(response.data.error);
          } else {
            setReload(true);
            setEditBenefit(false);
            setSelectedBenefit(null);
          }
        })
        .catch((error) => {
          setLoading(false);
          showMessage(error.message);
        });
    }
  }

  function remove() {
    setLoading(true);
    bookingApi.delete(`subscriptions/benefit/${selectedBenefit?.id}`)
      .then((response) => {
        setLoading(false);
        if (response.data.error) {
          showMessage(response.data.error);
        } else {
          setReload(true);
          setEditBenefit(false);
          setSelectedBenefit(null);
        }
      })
      .catch((error) => {
        setLoading(false);
        showMessage(error.message);
      });
  }

  useEffect(async () => {
    if (imageData) {
      const url = await uploadImage(imageData);
      if (url) {
        setImageData(null);
        setData({
          ...data,
          imageUrl: url
        });
      }
    }
  }, [imageData]);

  useEffect(() => {
    if (selectedBenefit && editBenefit) {
      getServiceTypes();
      if (selectedBenefit.id) {
        setData(selectedBenefit);
      } else {
        setData({
          imageUrl: '',
          descriptionEn: '',
          descriptionEs: '',
          internalName: '',
          nameEs: '',
          nameEn: '',
          type: 0,
          recurrence: 0,
          applicableServiceTypeId: '',
          ordering: 0,
          amount: 0,
          limit: null,
          isActive: false,
          subscriptionId: selectedSubscription?.id,
          isPromoBenefit: false
        });
      }
    } else if (!editBenefit) {
      setData(null);
    }
  }, [selectedBenefit, editBenefit]);

  return (
    <Drawer
      title={selectedBenefit?.id ? t('edit_benefit') : t('create_benefit')}
      placement="right"
      closable={!loading}
      onClose={() => {
        setEditBenefit(false);
        setSelectedBenefit(null);
      }}
      open={editBenefit}
      width={300}
    >
      <div hidden={!data?.id}>
        <div
          className="float-right"
        >
          <Button
            type="primary"
            danger
            loading={loading}
            onClick={() => { remove(); }}
          >
            {t('delete')}
          </Button>
        </div>
        <br />
      </div>
      <div
        className="mb-4"
      >
        <strong>{t('is_active')}</strong>
        <br />
        <Switch
          disabled={loading}
          checked={data?.isActive}
          onChange={(checked) => {
            setData({
              ...data,
              isActive: checked
            });
          }}
        />
      </div>
      <div
        className="mb-4"
      >
        <strong>{t('is_promo_benefit')}</strong>
        <br />
        <Switch
          disabled={loading}
          checked={data?.isPromoBenefit}
          onChange={(checked) => {
            setData({
              ...data,
              isPromoBenefit: checked
            });
          }}
        />
      </div>
      <div
        className="mb-4"
      >
        <strong>{t('ordering')}</strong>
        <InputNumber
          disabled={loading}
          className="w-full"
          min={0}
          step="1"
          onChange={(obj) => {
            setData({
              ...data,
              ordering: obj
            });
          }}
          value={data?.ordering}
        />
      </div>
      <div
        className="mb-4"
      >
        <strong>{t('internal_name')}</strong>
        <Input
          disabled={loading}
          className="w-full"
          value={data?.internalName}
          onChange={(obj) => {
            setData({
              ...data,
              internalName: obj.target.value
            });
          }}
        />
      </div>
      <div
        className="mb-4"
      >
        <strong>{t('name')} (EN)</strong>
        <Input
          disabled={loading}
          className="w-full"
          value={data?.nameEn}
          onChange={(obj) => {
            setData({
              ...data,
              nameEn: obj.target.value
            });
          }}
        />
      </div>
      <div
        className="mb-4"
      >
        <strong>{t('name')} (ES)</strong>
        <Input
          disabled={loading}
          className="w-full"
          value={data?.nameEs}
          onChange={(obj) => {
            setData({
              ...data,
              nameEs: obj.target.value
            });
          }}
        />
      </div>
      <div
        className="mb-4"
      >
        <strong>{t('description')} (EN)</strong>
        <TextArea
          rows={3}
          className="w-full"
          disabled={loading}
          value={data?.descriptionEn}
          onChange={(obj) => {
            setData({
              ...data,
              descriptionEn: obj.target.value
            });
          }}
        />
      </div>
      <div
        className="mb-4"
      >
        <strong>{t('description')} (ES)</strong>
        <TextArea
          rows={3}
          className="w-full"
          disabled={loading}
          value={data?.descriptionEs}
          onChange={(obj) => {
            setData({
              ...data,
              descriptionEs: obj.target.value
            });
          }}
        />
      </div>
      <div
        className="mb-4"
      >
        <strong>{t('applicable_service_type')}</strong>
        <Select
          listHeight={300}
          className="w-full"
          onChange={(value) => {
            // console.log(value);
            setData({
              ...data,
              applicableServiceTypeId: value
            });
          }}
          value={data?.applicableServiceTypeId}
        >
          {_.map(serviceTypes, (s) => (
            <Option
              value={s.id}
              key={s.id}
              className="text-xs flex items-center"
            >
              {i18n.language === 'en' ? s.nameEn : s.nameEs}
            </Option>
          ))}
        </Select>
      </div>
      <div
        className="mb-4"
      >
        <strong>{t('recurrence')}</strong>
        <Select
          listHeight={300}
          className="w-full"
          onChange={(value) => setData({
            ...data,
            recurrence: value
          })}
          value={data?.recurrence}
        >
          <Option value={0} className="text-xs flex items-center">{t(mapSubscriptionRecurrence(0))}</Option>
          <Option value={1} className="text-xs flex items-center">{t(mapSubscriptionRecurrence(1))}</Option>
          <Option value={2} className="text-xs flex items-center">{t(mapSubscriptionRecurrence(2))}</Option>
          <Option value={3} className="text-xs flex items-center">{t(mapSubscriptionRecurrence(3))}</Option>
          <Option value={4} className="text-xs flex items-center">{t(mapSubscriptionRecurrence(4))}</Option>
          <Option value={5} className="text-xs flex items-center">{t(mapSubscriptionRecurrence(5))}</Option>
          <Option value={6} className="text-xs flex items-center">{t(mapSubscriptionRecurrence(6))}</Option>
          <Option value={7} className="text-xs flex items-center">{t(mapSubscriptionRecurrence(7))}</Option>
          <Option value={8} className="text-xs flex items-center">{t(mapSubscriptionRecurrence(8))}</Option>
        </Select>
      </div>
      <div
        className="mb-4"
      >
        <strong>{t('type')}</strong>
        <Select
          listHeight={300}
          className="w-full"
          onChange={(value) => {
            // console.log(value);
            setData({
              ...data,
              type: value
            });
          }}
          value={data?.type}
        >
          <Option value={0} className="text-xs flex items-center">{t(mapSubscriptionBenefitType(0))}</Option>
          <Option value={1} className="text-xs flex items-center">{t(mapSubscriptionBenefitType(1))}</Option>
          <Option value={2} className="text-xs flex items-center">{t(mapSubscriptionBenefitType(2))}</Option>
          <Option value={3} className="text-xs flex items-center">{t(mapSubscriptionBenefitType(3))}</Option>
        </Select>
      </div>
      {data?.type !== 3 && (
        <div
          className="mb-4"
        >
          <strong>{t('amount')} ({data?.type === 0 || data?.type === 2 ? '$' : '%'})</strong>
          <InputNumber
            disabled={loading}
            className="w-full"
            min={0}
            step="0.01"
            formatter={(value) =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={(value) => value.replace('%', '').replace(/\$\s?|(,*)/g, '')}
            onChange={(obj) => {
              setData({
                ...data,
                amount: obj
              });
            }}
            value={data?.amount}
          />
        </div>
      )}
      <div
        className="mb-4"
      >
        <strong>{t('limit')}</strong>
        <InputNumber
          disabled={loading}
          className="w-full"
          step="1"
          onChange={(obj) => {
            setData({
              ...data,
              limit: obj
            });
          }}
          value={data?.limit}
        />
      </div>
      <div
        className="mb-4"
      >
        <strong>{t('image')}</strong>
        <Input
          className="w-full"
          disabled={loading}
          loading={isSubmitting}
          value={data?.imageUrl}
          onChange={(obj) => {
            setData({
              ...data,
              imageUrl: obj.target.value
            });
          }}
        />
        <Dragger {...imageDraggerProps}>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p
            className="ant-upload-text"
            style={{ margin: 10 }}
          >
            {t('click_or_drag_content')}
          </p>
        </Dragger>
        {data?.imageUrl && (
          <Image
            alt="locationimage"
            width={120}
            src={data?.imageUrl}
            className="cursor-pointer"
          />
        )}
      </div>
      <div
        className="float-right"
      >
        <Button
          type="primary"
          loading={loading}
          disabled={isEmpty(data?.internalName) && isEmpty(data?.nameEn) && isEmpty(data?.nameEs)}
          onClick={() => { submit(); }}
        >
          {data?.id ? t('update') : t('create')}
        </Button>
      </div>
    </Drawer>
  );
}
export default EditSubscriptionBenefit;
