import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import {
  Drawer,
  Input,
  Switch,
  Upload,
  Avatar,
  notification,
  Tooltip,
  Button,
} from 'antd';
import { Comment } from '@ant-design/compatible';
import {
  InboxOutlined,
} from '@ant-design/icons';
import { useRecoilState, useRecoilValue, useSetRecoilState, } from 'recoil';
import {
  editCardRestrictionAtom,
  selectedCardRestrictionAtom,
  reloadCardRestrictionsAtom
} from '../../../../atoms/Atoms';
import Grocefy from '../../../../assets/images/grocefyLogoAlone.png';
import api from '../../../../api/api';

function EditCardRestriction() {
  const { t } = useTranslation();
  const { Dragger } = Upload;
  const setReload = useSetRecoilState(reloadCardRestrictionsAtom);
  const selectedCardRestriction = useRecoilValue(selectedCardRestrictionAtom);
  const [editCardRestriction, setEditCardRestriction] = useRecoilState(editCardRestrictionAtom);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [imageData, setImageData] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const imageDraggerProps = {
    name: 'file',
    accept: 'image/*',
    multiple: false,
    showUploadList: false,
    beforeUpload: (file) => {
      setImageData(file);
      return false;
    }
  };

  async function uploadImage(tempImageData) {
    const file = tempImageData;
    const boundary = Date.now();
    const formdata = new FormData();
    formdata.append('file', file);
    setIsSubmitting(true);
    const response = await api
      .post('utility/cardrestrictions/uploadfile', formdata);
    setIsSubmitting(false);
    if (response.data.success) {
      return response.data.data;
    }
    console.error(response.data.error);
    return null;
  }

  function showMessage(message) {
    notification.open({
      message: '',
      description: (
        <Comment
          author={<span>Grocefy</span>}
          avatar={<Avatar src={Grocefy} alt="grocefy" />}
          content={
            <p className="text-sm">
              {message}
            </p>
          }
          datetime={
            <Tooltip title={moment().format('YYYY-MM-DD HH:mm:ss')}>
              <span>{moment().fromNow()}</span>
            </Tooltip>
          }
        />
      ),
    });
  }

  function submit() {
    setLoading(true);
    api.post('admin/card/restrictions', data)
      .then((response) => {
        setLoading(false);
        if (response.data.error) {
          showMessage(response.data.error);
        } else {
          setReload(true);
          setEditCardRestriction(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        showMessage(error.message);
      });
  }

  function remove() {
    setLoading(true);
    api.delete(`admin/card/restriction/${selectedCardRestriction?.id}`)
      .then((response) => {
        setLoading(false);
        if (response.data.error) {
          showMessage(response.data.error);
        } else {
          setReload(true);
          setEditCardRestriction(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        showMessage(error.message);
      });
  }

  useEffect(async () => {
    if (imageData) {
      const url = await uploadImage(imageData);
      if (url) {
        setImageData(null);
        const temp = JSON.parse(JSON.stringify(data));
        temp.imageUrl = url;
        setData(temp);
      }
    }
  }, [imageData]);

  useEffect(() => {
    if (selectedCardRestriction && editCardRestriction) {
      if (selectedCardRestriction.id) {
        setData(selectedCardRestriction);
      } else {
        setData({
          isActive: false,
          taxExcluded: false,
          blockRegister: false,
          allowNonFoodItems: false,
          allowAlcoholicItems: false,
          startsWith: '',
          name: ''
        });
      }
    } else if (!editCardRestriction) {
      setData(null);
    }
  }, [selectedCardRestriction, editCardRestriction]);

  return (
    <Drawer
      title={selectedCardRestriction?.id ? t('edit_card_restriction') : t('create_card_restriction')}
      placement="right"
      closable={!loading}
      onClose={() => setEditCardRestriction(false)}
      open={editCardRestriction}
      width={300}
    >
      <div hidden={!data?.id}>
        <div
          className="float-right"
        >
          <Button
            type="primary"
            danger
            loading={loading}
            onClick={() => { remove(); }}
          >
            {t('delete')}
          </Button>
        </div>
        <br />
        <br />
        <div
          className="mb-4"
        >
          <strong>{t('is_active')}</strong>
          <Switch
            disabled={loading}
            className="float-right"
            checked={data?.isActive}
            onChange={(checked) => {
              const temp = JSON.parse(JSON.stringify(data));
              temp.isActive = checked;
              setData(temp);
            }}
          />
        </div>
      </div>
      <div
        className="mb-4"
      >
        <strong>{t('name')}</strong>
        <Input
          disabled={loading}
          className="w-full"
          value={data?.name}
          onChange={(obj) => {
            const temp = JSON.parse(JSON.stringify(data));
            temp.name = obj.target.value;
            setData(temp);
          }}
        />
      </div>
      <div
        className="mb-4"
      >
        <Tooltip title={t('image_requirements')}>
          <strong>{t('image')}</strong>
        </Tooltip>
        <Input
          className="w-full"
          disabled={loading}
          loading={isSubmitting}
          value={data?.imageUrl}
          onChange={(obj) => {
            const temp = JSON.parse(JSON.stringify(data));
            temp.imageUrl = obj.target.value;
            setData(temp);
          }}
        />
        <Dragger {...imageDraggerProps}>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p
            className="ant-upload-text"
            style={{ margin: 10 }}
          >
            {t('click_or_drag_content')}
          </p>
        </Dragger>
      </div>
      <div
        className="mb-4"
      >
        <strong>{t('starts_with')}</strong>
        <Input
          className="w-full"
          disabled={data?.id || loading}
          value={data?.startsWith}
          onChange={(obj) => {
            const temp = JSON.parse(JSON.stringify(data));
            temp.startsWith = obj.target.value;
            setData(temp);
          }}
        />
      </div>
      <div
        className="mb-4"
      >
        <strong>{t('block_register')}</strong>
        <Switch
          disabled={loading}
          className="float-right"
          checked={data?.blockRegister}
          onChange={(checked) => {
            const temp = JSON.parse(JSON.stringify(data));
            temp.blockRegister = checked;
            setData(temp);
          }}
        />
      </div>
      <div
        className="mb-4"
      >
        <strong>{t('tax_excluded')}</strong>
        <Switch
          disabled={loading}
          className="float-right"
          checked={data?.taxExcluded}
          onChange={(checked) => {
            const temp = JSON.parse(JSON.stringify(data));
            temp.taxExcluded = checked;
            setData(temp);
          }}
        />
      </div>
      <div
        className="mb-4"
      >
        <strong>{t('allow_non_food')}</strong>
        <Switch
          disabled={loading}
          className="float-right"
          checked={data?.allowNonFoodItems}
          onChange={(checked) => {
            const temp = JSON.parse(JSON.stringify(data));
            temp.allowNonFoodItems = checked;
            setData(temp);
          }}
        />
      </div>
      <div
        className="float-right"
      >
        <Button
          type="primary"
          loading={loading}
          disabled={data?.name?.length <= 0 || data?.startsWith?.length <= 0}
          onClick={() => { submit(); }}
        >
          {data?.id ? t('update') : t('create')}
        </Button>
      </div>
    </Drawer>
  );
}
export default EditCardRestriction;
