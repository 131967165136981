import React, { useState, useEffect, useRef } from 'react';
import _ from 'lodash';
import {
  Descriptions,
  Switch,
  Tabs,
  Avatar,
  notification,
  Input,
  InputNumber,
  Select,
  Upload,
  Button
} from 'antd';
import { Comment } from '@ant-design/compatible';
import {
  InboxOutlined,
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import DescriptionsItem from 'antd/lib/descriptions/Item';
import { useRecoilValue, useRecoilValueLoadable } from 'recoil';
import {
  businessAtom,
  stateAtom,
  profileAtom
} from '../../../../atoms/Atoms';
import { formatPhoneNumber } from '../../../utils/functions';
import Grocefy from '../../../../assets/images/grocefyLogoAlone.png';
import api from '../../../../api/api';
import envConfig from '../../../../envConfig';

function locationInfo() {
  const markerRef = useRef();
  const { Option } = Select;
  const hourArray = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11,
    12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24];
  const profile = useRecoilValueLoadable(profileAtom);
  const isAdmin =
    profile.contents && profile?.contents?.roles?.includes('SuperAdmin');
  const store = useRecoilValue(businessAtom);
  const state = useRecoilValue(stateAtom);
  const [storeData, setStoreData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [map, setMap] = useState(null);
  const [storeLl, setStoreLl] = useState(undefined);
  const [imageData, setImageData] = useState(null);
  const [isUploadingImage, setIsUploadingImage] = useState(false);

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: envConfig.REACT_APP_GEO_API_KEY,
    language: 'es-US',
    libraries: ['places']
  });

  const imageDraggerProps = {
    name: 'file',
    accept: 'image/*',
    multiple: false,
    showUploadList: false,
    beforeUpload: (file) => {
      setImageData(file);
      return false;
    }
  };

  async function uploadImage(tempImageData) {
    const file = tempImageData;
    const boundary = Date.now();
    const formdata = new FormData();
    formdata.append('file', file);
    setIsUploadingImage(true);
    const response = await api
      .post('utility/backgroundimages/uploadfile', formdata);
    setIsUploadingImage(false);
    if (response.data.success) {
      return response.data.data;
    }
    console.error(response.data.error);
    return null;
  }

  const apiHasLoaded = (tempMap) => {
    const bounds = new window.google.maps.LatLngBounds(storeLl);
    tempMap.fitBounds(bounds);
    setMap(tempMap);
  };
  const { t } = useTranslation();

  const onUnmount = React.useCallback((tempMap) => {
    setMap(null);
  }, []);

  const triggerMarkerUpdate = function () {
    const latLonStrings = markerRef.current.marker.getPosition().toString()
      .replace('(', '')
      .replace(')', '')
      .replace(' ', '')
      .split(',');
    const latLon = {
      lat: parseFloat(latLonStrings[0]),
      lng: parseFloat(latLonStrings[1])
    };
    setStoreLl(latLon);
  };

  function tConvert(x) {
    let temp = '';
    if (x < 12) {
      temp = `${x}:00 AM`;
    } else if (x === 12) {
      temp = `${x}:00 PM`;
    } else if (x === 24) {
      temp = '12:00 AM';
    } else {
      temp = `${x - 12}:00 PM`;
    }
    return temp;
  }

  const getInfo = function () {
    setLoading(true);
    api
      .get(`businesses/v2/${store.id}`)
      .then((response) => {
        setLoading(false);
        const temp = JSON.parse(JSON.stringify(response.data.data));
        temp.locations[0].lat = `${temp.locations[0].geo.lat}`;
        temp.locations[0].lon = `${temp.locations[0].geo.lon}`;
        setStoreLl({
          lat: temp?.locations[0]?.geo.lat,
          lng: temp?.locations[0]?.geo.lon
        });
        setStoreData(temp);
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  const updateInfo = function () {
    setLoading(true);
    api
      .put(
        `businesses/${storeData.id}`,
        storeData,
      )
      .then((response) => {
        setLoading(false);
        if (!response.data.success) {
          notification.open({
            message: 'Alert!',
            description: (
              <Comment
                author={<span>Grocefy</span>}
                avatar={<Avatar src={Grocefy} alt="grocefy" />}
                content={
                  <p className="text-sm">{response.data.error}</p>
                }
              />
            ),
          });
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  useEffect(async () => {
    if (imageData) {
      const url = await uploadImage(imageData);
      if (url) {
        setImageData(null);
        const temp = JSON.parse(JSON.stringify(storeData));
        temp.backgroundImage = url;
        setStoreData(temp);
      }
    }
  }, [imageData]);

  useEffect(() => {
    if (store) {
      getInfo();
    }
  }, [store]);

  useEffect(() => {
    if (storeLl && storeData) {
      const temp = JSON.parse(JSON.stringify(storeData));
      temp.locations[0].geo.lat = storeLl.lat;
      temp.locations[0].lat = `${storeLl.lat}`;
      temp.locations[0].geo.lon = storeLl.lng;
      temp.locations[0].lon = `${storeLl.lng}`;
      setStoreData(temp);
    }
  }, [storeLl]);

  useEffect(() => {
    if (store) {
      getInfo();
    }
  }, []);

  return (
    <>
      <div>
        <Button
          type="primary"
          loading={loading}
          style={{
            float: 'right',
          }}
          onClick={() => updateInfo()}
        >
          {t('update')}
        </Button>
      </div>
      <div>
        <Descriptions bordered title={t('details')}>
          <DescriptionsItem label={t('image')} span={3}>
            <div className="flex">
              <Input
                disabled={loading || isUploadingImage}
                value={storeData?.backgroundImage}
                onChange={(obj) => {
                  const temp = JSON.parse(JSON.stringify(storeData));
                  temp.backgroundImage = obj.target.value;
                  setStoreData(temp);
                }}
              />
              <div className="disabled:cursor-not-allowed disabled:opacity-50">
                <Upload
                  {...imageDraggerProps}
                  disabled={loading || isUploadingImage}
                >
                  <Button icon={<InboxOutlined />}>
                    {t('click_to_upload')}
                  </Button>
                </Upload>
              </div>
            </div>
          </DescriptionsItem>
          <DescriptionsItem label={t('business_name')} span={2}>
            <Input
              disabled={loading}
              value={storeData?.name}
              onChange={(obj) => {
                const temp = JSON.parse(JSON.stringify(storeData));
                temp.name = obj.target.value;
                setStoreData(temp);
              }}
            />
          </DescriptionsItem>
          <DescriptionsItem label={t('is_alpha')} span={1}>
            <Switch
              loading={loading}
              size="small"
              checked={storeData?.isAlpha}
              onChange={(obj) => {
                const temp = JSON.parse(JSON.stringify(storeData));
                temp.isAlpha = obj;
                setStoreData(temp);
              }}
            />
          </DescriptionsItem>
          <DescriptionsItem label={t('store_phone')} span={2}>
            <Input
              disabled={loading}
              value={storeData?.locations[0]?.orderPhone}
              onChange={(obj) => {
                const temp = JSON.parse(JSON.stringify(storeData));
                temp.locations[0].orderPhone = obj.target.value;
                setStoreData(temp);
              }}
            />
          </DescriptionsItem>
          <DescriptionsItem label={t('is_beta')}>
            <Switch
              loading={loading}
              size="small"
              checked={storeData?.isBeta}
              onChange={(obj) => {
                const temp = JSON.parse(JSON.stringify(storeData));
                temp.isBeta = obj;
                setStoreData(temp);
              }}
            />
          </DescriptionsItem>
          <DescriptionsItem label={t('location_name')} span={2}>
            <Input
              disabled={loading}
              value={storeData?.locations[0]?.name}
              onChange={(obj) => {
                const temp = JSON.parse(JSON.stringify(storeData));
                temp.locations[0].name = obj.target.value;
                setStoreData(temp);
              }}
            />
          </DescriptionsItem>
          <DescriptionsItem label={t('allow_delivery')}>
            <Switch
              loading={loading}
              size="small"
              checked={storeData?.locations[0]?.isDeliveryAvailable}
              onChange={(obj) => {
                const temp = JSON.parse(JSON.stringify(storeData));
                temp.locations[0].isDeliveryAvailable = obj;
                setStoreData(temp);
              }}
            />
          </DescriptionsItem>
          <DescriptionsItem label={t('address')} span={2}>
            <Input
              disabled={loading}
              value={storeData?.locations[0]?.address1}
              onChange={(obj) => {
                const temp = JSON.parse(JSON.stringify(storeData));
                temp.locations[0].address1 = obj.target.value;
                setStoreData(temp);
              }}
            />
            <Input
              className="mt-2"
              disabled={loading}
              value={storeData?.locations[0]?.address2}
              onChange={(obj) => {
                const temp = JSON.parse(JSON.stringify(storeData));
                temp.locations[0].address2 = obj.target.value;
                setStoreData(temp);
              }}
            />
          </DescriptionsItem>
          <DescriptionsItem label={t('allow_pickup')}>
            <Switch
              loading={loading}
              size="small"
              checked={storeData?.locations[0]?.isPickUpAvailable}
              onChange={(obj) => {
                const temp = JSON.parse(JSON.stringify(storeData));
                temp.locations[0].isPickUpAvailable = obj;
                setStoreData(temp);
              }}
            />
          </DescriptionsItem>
          <DescriptionsItem label={t('city')} span={2}>
            <Input
              disabled={loading}
              span={2}
              value={storeData?.locations[0]?.city}
              onChange={(obj) => {
                const temp = JSON.parse(JSON.stringify(storeData));
                temp.locations[0].city = obj.target.value;
                setStoreData(temp);
              }}
            />
          </DescriptionsItem>
          <DescriptionsItem label={t('visible_in_beta')}>
            <Switch
              loading={loading}
              size="small"
              checked={storeData?.visibleInBeta}
              onChange={(obj) => {
                const temp = JSON.parse(JSON.stringify(storeData));
                temp.visibleInBeta = obj;
                setStoreData(temp);
              }}
            />
          </DescriptionsItem>
          <DescriptionsItem label={t('email')} span={2}>
            <Input
              disabled={loading}
              span={2}
              value={storeData?.locations[0]?.orderEmail}
              onChange={(obj) => {
                const temp = JSON.parse(JSON.stringify(storeData));
                temp.locations[0].orderEmail = obj.target.value;
                setStoreData(temp);
              }}
            />
          </DescriptionsItem>
          <DescriptionsItem label={t('pos_integration_active')}>
            <Switch
              loading={loading}
              size="small"
              disabled={!isAdmin}
              checked={storeData?.locations[0]?.posintegrationActive}
              onChange={(obj) => {
                const temp = JSON.parse(JSON.stringify(storeData));
                temp.locations[0].posintegrationActive = obj;
                setStoreData(temp);
              }}
            />
          </DescriptionsItem>
        </Descriptions>
        <br />
        <Tabs>
          <Tabs.TabPane tab={t('pickup')} key="1">
            <Descriptions bordered>
              <Descriptions.Item label="Pickup Buffer (minutes)">
                <InputNumber
                  disabled={loading}
                  value={storeData?.locations[0]?.pickupBuffer}
                  onChange={(obj) => {
                    const temp = JSON.parse(JSON.stringify(storeData));
                    temp.locations[0].pickupBuffer = obj;
                    setStoreData(temp);
                  }}
                />
              </Descriptions.Item>
              <Descriptions.Item label={t('pickup_start_hour')}>
                <Select
                  className={storeData?.locations[0]?.pickupStartHour >= storeData?.locations[0]?.pickupEndHour ? 'border border-red-600 text-red-600' : ''}
                  loading={loading}
                  disabled={loading}
                  value={
                    storeData?.locations[0]?.pickupStartHour
                  }
                  onChange={(value) => {
                    const temp = JSON.parse(JSON.stringify(storeData));
                    temp.locations[0].pickupStartHour = value;
                    setStoreData(temp);
                  }}
                >
                  {_.map(hourArray, (num) => (
                    <Option
                      key={num}
                      value={num}
                      className="text-xs flex items-center"
                    >
                      {tConvert(num)}
                    </Option>
                  ))}
                </Select>
              </Descriptions.Item>
              <Descriptions.Item label={t('pickup_end_hour')}>
                <Select
                  className={storeData?.locations[0]?.pickupEndHour <= storeData?.locations[0]?.pickupStartHour ? ' border border-red-600 text-red-600' : ''}
                  loading={loading}
                  disabled={loading}
                  value={
                    storeData?.locations[0]?.pickupEndHour
                  }
                  onChange={(value) => {
                    const temp = JSON.parse(JSON.stringify(storeData));
                    temp.locations[0].pickupEndHour = value;
                    setStoreData(temp);
                  }}
                >
                  {_.map(hourArray, (num) => (
                    <Option
                      key={num}
                      value={num}
                      className="text-xs flex items-center"
                    >
                      {tConvert(num)}
                    </Option>
                  ))}
                </Select>
              </Descriptions.Item>
              <Descriptions.Item label={t('slot_range_min')}>
                <InputNumber
                  disabled={loading}
                  value={storeData?.locations[0]?.slotRange}
                  onChange={(obj) => {
                    const temp = JSON.parse(JSON.stringify(storeData));
                    temp.locations[0].slotRange = obj;
                    setStoreData(temp);
                  }}
                />
              </Descriptions.Item>
              <Descriptions.Item label={t('limit_per_hour')}>
                <InputNumber
                  disabled={loading}
                  value={storeData?.locations[0]?.pickupLimit}
                  onChange={(obj) => {
                    const temp = JSON.parse(JSON.stringify(storeData));
                    temp.locations[0].pickupLimit = obj;
                    setStoreData(temp);
                  }}
                />
              </Descriptions.Item>
              <Descriptions.Item label={t('future_days')}>
                <InputNumber
                  disabled={loading}
                  value={storeData?.locations[0]?.futureDaysForPickup}
                  onChange={(obj) => {
                    const temp = JSON.parse(JSON.stringify(storeData));
                    temp.locations[0].futureDaysForPickup = obj;
                    setStoreData(temp);
                  }}
                />
              </Descriptions.Item>
              <Descriptions.Item label={t('prevent_same_day_service_slot')}>
                <Switch
                  style={{ float: 'right' }}
                  disabled={loading}
                  onChange={(obj) => {
                    const temp = JSON.parse(JSON.stringify(storeData));
                    temp.locations[0].preventTodayPickupSlots = obj;
                    setStoreData(temp);
                  }}
                  checked={storeData?.locations[0]?.preventTodayPickupSlots}
                />
              </Descriptions.Item>
              <Descriptions.Item label={t('store_fee')}>
                <InputNumber
                  placeholder="0.0"
                  loading={loading}
                  disabled={storeData?.locations[0]?.pickupFeeType === 0}
                  formatter={(value) =>
                    `${storeData?.locations[0]?.pickupFeeType === 1 ? '$ ' : ''} ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                  value={storeData?.locations[0]?.pickupFee}
                  onChange={(obj) => {
                    const temp = JSON.parse(JSON.stringify(storeData));
                    temp.locations[0].pickupFee = obj;
                    setStoreData(temp);
                  }}
                />
              </Descriptions.Item>
              <Descriptions.Item label={t('store_fee_type')}>
                <Select
                  loading={loading}
                  disabled={loading}
                  value={
                    storeData?.locations[0]?.pickupFeeType
                  }
                  onChange={(value) => {
                    const temp = JSON.parse(JSON.stringify(storeData));
                    temp.locations[0].pickupFeeType = value;
                    setStoreData(temp);
                  }}
                >
                  <Option
                    key="0"
                    value={0}
                    className="text-xs flex items-center"
                  >
                    {t('none')}
                  </Option>
                  <Option
                    key="1"
                    value={1}
                    className="text-xs flex items-center"
                  >
                    {t('fixed')}
                  </Option>
                  <Option
                    key="2"
                    value={2}
                    className="text-xs flex items-center"
                  >
                    {t('percentage')}
                  </Option>
                </Select>
              </Descriptions.Item>
              <Descriptions.Item label={t('global_fee')}>
                <InputNumber
                  disabled
                  formatter={(value) =>
                    `${state.isOrderServiceFeeFixed ? '$ ' : ''} ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                  value={state.orderServiceFee}
                />
              </Descriptions.Item>
              <Descriptions.Item label={t('global_fee_type')}>
                <Select
                  loading={loading}
                  disabled
                  value={state.isOrderServiceFeeFixed ? 0 : 1}
                >
                  <Option
                    key="0"
                    value={0}
                    className="text-xs flex items-center"
                  >
                    {t('fixed')}
                  </Option>
                  <Option
                    key="1"
                    value={1}
                    className="text-xs flex items-center"
                  >
                    {t('percentage')}
                  </Option>
                </Select>
              </Descriptions.Item>
            </Descriptions>
          </Tabs.TabPane>
          <Tabs.TabPane tab={t('additional_details')} key="3">
            <Descriptions bordered>
              <Descriptions.Item label={t('map')} span={3}>
                {isLoaded && (
                  <GoogleMap
                    mapContainerStyle={{
                      height: '300px'
                    }}
                    center={storeLl}
                    zoom={15}
                    onLoad={apiHasLoaded}
                    onUnmount={onUnmount}
                  >
                    <Marker
                      position={storeLl}
                      draggable
                      ref={markerRef}
                      onDragEnd={(e) => { triggerMarkerUpdate(); }}
                    />
                  </GoogleMap>
                )}
              </Descriptions.Item>
              <Descriptions.Item label={t('lat')}>
                <InputNumber
                  disabled={loading}
                  value={storeLl?.lat}
                  onChange={(obj) => {
                    const temp = JSON.parse(JSON.stringify(storeLl));
                    temp.lat = obj;
                    setStoreLl(temp);
                  }}
                />
              </Descriptions.Item>
              <Descriptions.Item label={t('lon')}>
                <InputNumber
                  disabled={loading}
                  value={storeLl?.lng}
                  onChange={(obj) => {
                    const temp = JSON.parse(JSON.stringify(storeLl));
                    temp.lng = obj;
                    setStoreLl(temp);
                  }}
                />
              </Descriptions.Item>
            </Descriptions>
          </Tabs.TabPane>
        </Tabs>
      </div>
    </>
  );
}

export default locationInfo;
