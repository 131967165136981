import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import {
  useRecoilValue,
  useRecoilState,
  useRecoilValueLoadable
} from 'recoil';
import {
  Button,
  Table,
  Col,
  Row,
  Image,
  Popover,
  Spin
} from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import moment from 'moment';
import Text from 'antd/lib/typography/Text';
import { SyncOutlined, BuildOutlined } from '@ant-design/icons';
import {
  timezoneAtom,
  selectedBannerAtom,
  refreshBannersAtom,
  profileAtom
} from '../../../atoms/Atoms';
import EditBannerDrawer from './shared-components/EditBannerDrawer';
import api from '../../../api/api';

function PromoBanners() {
  const timezone = useRecoilValue(timezoneAtom);
  const [banners, setBanners] = useState([]);
  const [selectedBanner, setSelectedBanner] = useRecoilState(selectedBannerAtom);
  const [refreshBanners, setRefreshBanners] = useRecoilState(refreshBannersAtom);
  const [loading, setLoading] = useState(false);

  const userProfile = useRecoilValueLoadable(profileAtom);
  const isAdmin =
    userProfile.contents && userProfile?.contents?.roles?.includes('SuperAdmin');
  const isMarketing =
    isAdmin || (userProfile?.contents && userProfile?.contents?.roles?.includes('Marketing'));
  const history = useHistory();
  const { t } = useTranslation();
  const [needsApproval, setNeedsApproval] = useState(0);
  const [completed, setCompleted] = useState(0);
  const [active, setActive] = useState(0);

  if (!isAdmin && !isMarketing) {
    history.push('/');
    return null;
  }

  const getBanners = function () {
    setLoading(true);
    api.get('businesses/bannerads/all')
      .then((response) => {
        setLoading(false);
        let completedCount = 0;
        let activeCount = 0;
        let needsApprovalCount = 0;
        if (response.data.success) {
          for (let i = 0; i < response.data.data.results.length; i++) {
            if (!response.data.data.results[i].businessId) {
              response.data.data.results[i].businessId = '';
            }
            let status = response.data.data.results[i].approved ? 'Pending' : 'Needs Approval';
            const startDate = moment(`${response.data.data.results[i].startDate}+0000`);
            const endDate = moment(`${response.data.data.results[i].endDate}+0000`);
            const currentDate = moment();
            if (currentDate >= startDate && currentDate <= endDate) {
              status = response.data.data.results[i].approved ? 'Active' : 'Needs Approval';
            } else if (currentDate > endDate) {
              status = response.data.data.results[i].approved ? 'Completed' : 'Passed';
            }

            if (currentDate >= startDate && currentDate <= endDate &&
              response.data.data.results[i].approved) {
              activeCount += 1;
            } else if (currentDate > endDate &&
              response.data.data.results[i].approved) {
              completedCount += 1;
            } else {
              needsApprovalCount += 1;
            }
            response.data.data.results[i].status = status;
            response.data.data.results[i].startDateTime = moment(startDate).tz(timezone);
            response.data.data.results[i].endDateTime = moment(endDate).tz(timezone);
          }
          setBanners(response.data.data.results);
          setActive(activeCount);
          setCompleted(completedCount);
          setNeedsApproval(needsApprovalCount);
        } else {
          console.error(response.data.error);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  useEffect(() => {
    setSelectedBanner(null);
    getBanners();
  }, []);

  useEffect(() => {
    if (refreshBanners) {
      setRefreshBanners(false);
      getBanners();
    }
  }, [refreshBanners]);

  return (
    <>
      <PageHeader
        title={t('promo_banners')}
        extra={[
          <Button
            key="create"
            type="primary"
            icon={<BuildOutlined />}
            size="small"
            onClick={() => {
              setSelectedBanner({});
            }}
          >
            {t('create')}
          </Button>,
          <Button
            key="reload"
            icon={<SyncOutlined />}
            type="primary"
            loading={loading}
            size="small"
            onClick={() => {
              setRefreshBanners(true);
            }}
          >
            {t('reload')}
          </Button>
        ]}
      />
      <br />
      <div className="mb-4">
        <dl className="grid grid-cols-1 gap-5 sm:grid-cols-3">
          <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
            <Spin spinning={loading}>
              <dt className="text-sm font-medium text-gray-500 truncate">{t('need_approval')}</dt>
              <dd className="mt-1 text-3xl font-semibold text-gray-900">{banners && needsApproval}</dd>
            </Spin>
          </div>
          <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
            <Spin spinning={loading}>
              <dt className="text-sm font-medium text-gray-500 truncate">{t('active')}</dt>
              <dd className="mt-1 text-3xl font-semibold text-gray-900">{banners && active}</dd>
            </Spin>
          </div>
          <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
            <Spin spinning={loading}>
              <dt className="text-sm font-medium text-gray-500 truncate">{t('completed')}</dt>
              <dd className="mt-1 text-3xl font-semibold text-gray-900">{banners && completed}</dd>
            </Spin>
          </div>
        </dl>
      </div>
      <Table
        size="small"
        bordered
        scroll={{ x: 1000 }}
        loading={loading}
        pagination={false}
        rowKey="id"
        dataSource={banners}
        columns={[
          {
            title: t('name'),
            align: 'center',
            className: 'text-xs',
            render: (row) => (
              <span>
                <Image
                  style={{ margin: 10 }}
                  src={row.imageUrl}
                  width={150}
                  preview
                />
              </span>
            ),
          },
          {
            title: t('name'),
            align: 'center',
            className: 'text-xs',
            render: (row) => (
              <Popover
                trigger="hover"
                title="Images"
                content={(
                  <Row>
                    <Col>
                      <Image
                        style={{ margin: 10 }}
                        src={row.imageUrl}
                        width={150}
                      />
                    </Col>
                    <Col>
                      <Image
                        style={{ margin: 10 }}
                        src={row.imageUrlSpa}
                        width={150}
                      />
                    </Col>
                  </Row>
                )}
              >
                <span>{row.name}</span>
              </Popover>
            ),
          },
          {
            title: t('start_date'),
            dataIndex: 'startDate',
            align: 'center',
            className: 'text-xs',
            render: (text) => (
              <span>
                {moment(`${text}+0000`)
                  .format('MMMM Do YYYY, h:mm:ss a')}
              </span>
            ),
          },
          {
            title: t('end_date'),
            dataIndex: 'endDate',
            align: 'center',
            className: 'text-xs',
            render: (text) => (
              <span>
                {moment(`${text}+0000`)
                  .format('MMMM Do YYYY, h:mm:ss a')}
              </span>
            ),
          },
          {
            title: t('status'),
            dataIndex: 'status',
            align: 'center',
            className: 'text-xs',
            render: (text, row) => (
              <span>{row.status === 'Active' ? <Text type="success">{text}</Text> : (
                row.status === 'Completed' ? <Text>{text}</Text> : (row.status === 'Needs Approval' ? <Text type="warning">{text}</Text> :
                  (row.status === 'Pending' ? <Text type="warning">{text}</Text> : <Text>{text}</Text>))
              )}
              </span>
            ),
          },
          {
            title: '',
            align: 'center',
            className: 'text-xs',
            render: (row) => (
              <Button
                size="small"
                type="primary"
                onClick={() => {
                  setSelectedBanner(row);
                }}
              >
                {t('edit')}
              </Button>
            ),
          }
        ]}
      />
      {selectedBanner && (
        <EditBannerDrawer />
      )}
    </>
  );
}

export default PromoBanners;
