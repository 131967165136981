import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import {
  useRecoilState,
  useSetRecoilState,
  useRecoilValue
} from 'recoil';
import { useTranslation } from 'react-i18next';
import {
  Avatar,
  Tooltip,
  Input,
  Image,
  notification,
  Popconfirm,
  Button,
  Upload,
  Select,
  Drawer,
} from 'antd';
import { Comment } from '@ant-design/compatible';
import {
  FormOutlined,
  CloseCircleOutlined,
  InboxOutlined
} from '@ant-design/icons';
import moment from 'moment';
import Grocefy from '../../../../assets/images/grocefyLogoAlone.png';
import {
  refreshImagesAtom,
  selectedImageAtom,
  imageCategoriesAtom,
  imageDepartmentsAtom,
  imageTypesAtom
} from '../../../../atoms/Atoms';
import envConfig from '../../../../envConfig';
import imagesApi from '../../../../api/imagesApi';

function EditImageDrawer() {
  const { t } = useTranslation();
  const { Option } = Select;
  const { Dragger } = Upload;

  const setRefreshImages = useSetRecoilState(refreshImagesAtom);
  const categories = useRecoilValue(imageCategoriesAtom);
  const departments = useRecoilValue(imageDepartmentsAtom);
  const types = useRecoilValue(imageTypesAtom);
  const [selectedImage, setSelectedImage] = useRecoilState(selectedImageAtom);
  const [loading, setLoading] = useState(false);
  const [showEditDepartment, setShowEditDepartment] = useState(false);
  const [showEditCategory, setShowEditCategory] = useState(false);
  const [showEditType, setShowEditType] = useState(false);
  const [data, setData] = useState(null);
  const [fileList, setFileList] = useState([]);
  const [imageFile, setImageFile] = useState(null);
  const [imageFilePreview, setImageFilePreview] = useState(null);

  const draggerProps = {
    name: 'file',
    multiple: false,
    fileList,
    showUploadList: true,
    onChange: ({ fileList: newFileList }) => {
      setFileList(newFileList);
      if (newFileList.length === 0) {
        setImageFile(null);
        setImageFilePreview(null);
      }
    },
    beforeUpload: (file) => {
      setImageFile(file);
      return false;
    },
    onRemove: (file) => {
      setFileList(_.filter(fileList, (f) => f.uid !== file.uid));
    }
  };

  const showMessage = function (message) {
    notification.open({
      message: '',
      description: (
        <Comment
          author={<span>Grocefy</span>}
          avatar={<Avatar src={Grocefy} alt="grocefy" />}
          content={
            <p className="text-sm">
              {message}
            </p>
          }
          datetime={
            <Tooltip title={moment().format('YYYY-MM-DD HH:mm:ss')}>
              <span>{moment().fromNow()}</span>
            </Tooltip>
          }
        />
      ),
    });
  };

  const create = function () {
    setLoading(true);
    const formData = new FormData();
    formData.append('Photo', imageFile);
    formData.append('Gtin', data?.itemGtin);
    formData.append('Brand', data?.itemBrand);
    formData.append('Name', data?.itemName);
    formData.append('Description', data?.itemDescription);
    formData.append('Category', data?.itemCategory);
    formData.append('Department', data?.itemDepartment);
    formData.append('ImageType', data?.imageType);
    formData.append('SearchTerms', data?.itemSearchTerms);

    imagesApi.post('Storage', formData)
      .then((response) => {
        setLoading(false);
        setSelectedImage(null);
        showMessage(t('created_image_correctly'));
        setRefreshImages(true);
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
        showMessage(error.message);
      });
  };

  const update = function () {
    setLoading(true);
    imagesApi.put('Update', data)
      .then((response) => {
        setLoading(false);
        showMessage(t('updated_image_correctly'));
        setRefreshImages(true);
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
        showMessage(error.message);
      });
  };

  useEffect(() => {
    if (selectedImage) {
      setFileList([]);
      setImageFile(null);
      setImageFilePreview(null);
      if (!selectedImage.id) {
        setData({
          ...selectedImage,
          itemGtin: '',
          imageType: types[0],
          itemCategory: categories[0],
          itemDepartment: departments[0]
        });
      } else {
        setData({
          ...selectedImage
        });
      }
    }
  }, [selectedImage]);

  useEffect(() => {
    if (imageFile) {
      const reader = new FileReader();
      reader.onload = function (e) {
        setImageFilePreview(e.target.result);
      };
      reader.readAsDataURL(imageFile);
    } else {
      setImageFilePreview(null);
    }
  }, [imageFile]);

  return (
    <Drawer
      title={data?.id ? t('edit_image') : t('add_image')}
      placement="right"
      closable
      onClose={() => setSelectedImage(null)}
      open={selectedImage}
      width={400}
    >
      {data?.id && (
        <div>
          <div>
            <Popconfirm
              onConfirm={() => {
                setSelectedImage({
                  ...selectedImage,
                  id: null
                });
              }}
              title={t('image_clone_prompt')}
              okText={t('yes')}
              cancelText="No"
            >
              <Button
                className="float-right"
              >
                {t('clone')}
              </Button>
            </Popconfirm>
          </div>
          <div>
            <Image
              src={`${envConfig.REACT_APP_IMAGES_URL}/query/photos-md/${data?.id}?subscription-key=${envConfig.REACT_APP_IMAGES_KEY}`}
              alt={`${data?.itemBrand} ${data?.itemName} ${data?.itemDescription}`}
            />
          </div>
        </div>
      )}
      {!data?.id && (
        <>
          <div hidden={!imageFilePreview}>
            <Image
              src={imageFilePreview}
              alt={t('image_preview')}
            />
          </div>
          <div
            className="h-30 mb-4"
          >
            <Tooltip title="File Requirement: JPG or PNG up to 10mb. Minimun pixels required: 800 for width and height">
              <Dragger
                disabled={loading}
                {...draggerProps}
              >
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p
                  className="ant-upload-text"
                  style={{ margin: 10 }}
                >
                  {t('click_or_drag_content')}
                </p>
              </Dragger>
            </Tooltip>
          </div>
        </>
      )}
      <div className="mb-2">
        <strong>
          GTIN
          <span className="ml-1 text-gray-500">({data?.itemGtin?.length}/13)</span>
        </strong>
        <Input
          disabled={loading}
          placeholder="GTIN"
          onChange={(obj) => {
            const isInteger = /^[0-9]+$/;
            if (isInteger.test(obj.target.value) || obj.target.value.length === 0) {
              setData({
                ...data,
                itemGtin: obj.target.value
              });
            }
          }}
          value={data?.itemGtin}
        />
      </div>
      <div className="mb-2">
        <strong>{t('brand')}</strong>
        <Input
          disabled={loading}
          placeholder={t('brand')}
          onChange={(obj) => {
            setData({
              ...data,
              itemBrand: obj.target.value
            });
          }}
          value={data?.itemBrand}
        />
      </div>
      <div className="mb-2">
        <strong>{t('name')}</strong>
        <Input
          disabled={loading}
          placeholder={t('name')}
          onChange={(obj) => {
            setData({
              ...data,
              itemName: obj.target.value
            });
          }}
          value={data?.itemName}
        />
      </div>
      <div className="mb-2">
        <strong>{t('description')}</strong>
        <Input
          disabled={loading}
          placeholder={t('description')}
          onChange={(obj) => {
            setData({
              ...data,
              itemDescription: obj.target.value
            });
          }}
          value={data?.itemDescription}
        />
      </div>
      <div className="mb-2">
        <strong>{t('type')}</strong>
        <br />
        {!showEditType && (
          <>
            <Select
              value={data?.imageType}
              style={{ width: '90%' }}
              disabled={loading}
              showSearch="true"
              onChange={(value) => {
                setData({
                  ...data,
                  imageType: value
                });
              }}
            >
              {_.map(types, (type) => <Option value={type}>{type}</Option>)}
            </Select>
            <FormOutlined
              style={{ marginLeft: 10 }}
              onClick={() => { setShowEditType(true); }}
            />
          </>
        )}
        {showEditType && (
          <>
            <Input
              disabled={loading}
              style={{ width: '90%' }}
              placeholder={t('type')}
              onChange={(obj) => {
                setData({
                  ...data,
                  imageType: obj.target.value
                });
              }}
              value={data?.imageType}
            />
            <CloseCircleOutlined
              style={{ marginLeft: 10 }}
              onClick={() => { setShowEditType(false); }}
            />
          </>
        )}
      </div>
      <div className="mb-2">
        <strong>{t('department')}</strong>
        <br />
        {!showEditDepartment && (
          <>
            <Select
              value={data?.itemDepartment}
              style={{ width: '90%' }}
              disabled={loading}
              showSearch="true"
              onChange={(value) => {
                setData({
                  ...data,
                  itemDepartment: value
                });
              }}
            >
              {_.map(departments, (cat) => <Option value={cat}>{cat}</Option>)}
            </Select>
            <FormOutlined
              style={{ marginLeft: 10 }}
              onClick={() => { setShowEditDepartment(true); }}
            />
          </>
        )}
        {showEditDepartment && (
          <>
            <Input
              disabled={loading}
              style={{ width: '90%' }}
              placeholder={t('department')}
              onChange={(obj) => {
                setData({
                  ...data,
                  itemDepartment: obj.target.value
                });
              }}
              value={data?.itemDepartment}
            />
            <CloseCircleOutlined
              style={{ marginLeft: 10 }}
              onClick={() => { setShowEditDepartment(false); }}
            />
          </>
        )}
      </div>
      <div className="mb-2">
        <strong>{t('category')}</strong>
        <br />
        {!showEditCategory && (
          <>
            <Select
              value={data?.itemCategory}
              style={{ width: '90%' }}
              disabled={loading}
              showSearch="true"
              onChange={(value) => {
                setData({
                  ...data,
                  itemCategory: value
                });
              }}
            >
              {_.map(categories, (cat) => <Option value={cat}>{cat}</Option>)}
            </Select>
            <FormOutlined
              style={{ marginLeft: 10 }}
              onClick={() => { setShowEditCategory(true); }}
            />
          </>
        )}
        {showEditCategory && (
          <>
            <Input
              disabled={loading}
              style={{ width: '90%' }}
              placeholder={t('category')}
              onChange={(obj) => {
                setData({
                  ...data,
                  itemCategory: obj.target.value
                });
              }}
              value={data?.itemCategory}
            />
            <CloseCircleOutlined
              style={{ marginLeft: 10 }}
              onClick={() => { setShowEditCategory(false); }}
            />
          </>
        )}
      </div>
      <div className="mb-2">
        <strong>{t('search_tags')}</strong>
        <Input
          disabled={loading}
          placeholder={t('search_tags')}
          onChange={(obj) => {
            setData({
              ...data,
              itemSearchTerms: obj.target.value
            });
          }}
          value={data?.itemSearchTerms}
        />
      </div>
      <Button
        className="float-right"
        type="primary"
        loading={loading}
        disabled={(!data?.id && !imageFile) ||
          !(data?.itemGtin?.length > 0)}
        onClick={() => {
          if (data?.id) {
            update();
          } else {
            create();
          }
        }}
      >
        {data?.id ? t('update') : t('create')}
      </Button>
    </Drawer>
  );
}

export default EditImageDrawer;
