import React, { useState, useEffect, useRef } from 'react';
import { useMount } from 'react-use';
import { useRecoilValue, useRecoilValueLoadable } from 'recoil';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import ReactToPrint from 'react-to-print';
import _ from 'lodash';
import {
  Tag,
  Badge,
  Button,
  Typography,
  Table,
  List,
  Divider,
  DatePicker,
  Switch
} from 'antd';
import dayjs from 'dayjs';
import { PageHeader } from '@ant-design/pro-layout';
import {
  CheckCircleOutlined,
  CloudDownloadOutlined,
  ReloadOutlined,
  PrinterOutlined
} from '@ant-design/icons';
import { businessAtom, timezoneAtom, profileAtom } from '../../../atoms/Atoms';
import { renderDate, thousand, toCurrency } from '../../utils/functions';
import api from '../../../api/api';
import envConfig from '../../../envConfig';
import { dateRangePresets } from '../../utils/utils';

function PosSalesSummary() {
  const { RangePicker } = DatePicker;
  const { t } = useTranslation();
  const business = useRecoilValue(businessAtom);
  const timezone = useRecoilValue(timezoneAtom);
  const profile = useRecoilValueLoadable(profileAtom);
  const isAdmin =
    profile.contents && profile?.contents?.roles?.includes('SuperAdmin');
  const isStoreManager =
    isAdmin ||
    (profile?.contents &&
      (profile?.contents?.roles?.includes('StoreAdmin') ||
        profile?.contents?.roles?.includes('Owner')));
  const canViewFinance =
    isAdmin ||
    isStoreManager ||
    (profile.contents &&
      (profile.contents.roles.includes('Finance') ||
        profile.contents.roles.includes('TopAnalytics')));
  const [reportData, setReportData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [dateRange, setDateRange] = useState({
    start: moment().startOf('month').format('YYYY-MM-DD'),
    end: moment().endOf('month').format('YYYY-MM-DD')
  });
  const width = window.innerWidth;
  const ref = useRef(null);

  const marginTop = '25px';
  const marginRight = '20px';
  const marginBottom = '25px';
  const marginLeft = '20px';
  const getPageMargins = () => `@page { margin: ${marginTop} ${marginRight} ${marginBottom} ${marginLeft} !important; }`;

  function getData() {
    setLoading(true);
    const data = {
      StartDate: dayjs(dateRange.start).add(-1 * envConfig.REACT_APP_TIMEZONE_OFFSET, 'hour').format('YYYY-MM-DDTHH:mm:ss.000'),
      EndDate: dayjs(dateRange.end).add(-1 * envConfig.REACT_APP_TIMEZONE_OFFSET, 'hour').format('YYYY-MM-DDTHH:mm:ss.000')
    };
    api
      .post(
        `pos/sale/report${business ? `?businessId=${business?.id}` : ''}`,
        data
      )
      .then((response) => {
        setLoading(false);
        const headerList = [];
        const dropsHeaderList = [];
        headerList.push({ name: t('orders'), bold: false, size: 'regular', value: response.data.data.orderCount, isNumber: true });
        headerList.push({ name: t('gross_sales'), bold: true, size: 'large', value: response.data.data.grossSales });
        headerList.push({ name: t('discounts'), bold: false, size: 'regular', value: response.data.data.discounts });
        headerList.push({ name: t('refunds'), bold: false, size: 'regular', value: response.data.data.refunds });
        headerList.push({ name: t('net_sales'), bold: true, size: 'large', value: response.data.data.netSales });
        headerList.push({ name: t('taxes'), bold: true, size: 'regular', value: response.data.data.taxesAndFees });
        headerList.push({ name: t('amount_collected'), bold: true, size: 'x-large', value: response.data.data.amountCollected });

        dropsHeaderList.push({ name: t('amount'), bold: true, size: 'large', value: response.data.data.dropsAmount });
        dropsHeaderList.push({ name: t('count'), bold: false, size: 'large', value: response.data.data.totalDrops, isNumber: true });
        dropsHeaderList.push({ showTable: true });

        setReportData({
          ...response.data.data,
          headerList,
          dropsHeaderList
        });
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  }

  useMount(() => {
    getData();
  });

  useEffect(() => {
    getData();
  }, [dateRange, business]);

  return (
    <div style={{ margin: '20px' }}> {/* Added margin */}
      <div ref={ref}>
        <PageHeader
          className="mb-4 px-0"
          title={t('pos_summary')}
          tags={
            <Tag
              color="#2db7f5"
              icon={<CheckCircleOutlined />}
            >
              {business ? business.name : t('global')}
            </Tag>
          }
          extra={
            [
              <RangePicker
                format="YYYY-MM-DD"
                value={[
                  dayjs(dateRange?.start),
                  dayjs(dateRange?.end)
                ]}
                presets={dateRangePresets(t)}
                onChange={(date, dateString) => {
                  setDateRange({
                    start: dateString[0],
                    end: dateString[1]
                  });
                }}
              />,
              <Button
                type="primary"
                size="small"
                icon={<ReloadOutlined />}
                onClick={() => getData()}
              >
                {t('refresh')}
              </Button>,
              <ReactToPrint
                content={() => ref.current}
                pageStyle={getPageMargins()}
                trigger={() => (
                  <button
                    type="button"
                    className="flex items-center text-white text-xl justify-center bg-blue-400 hover:bg-blue-200 rounded-full w-10 h-10 ring-0 focus:ring-0 outline-none focus:outline-none transition duration-500 ease-in"
                  >
                    <PrinterOutlined className="h-5 w-5" />
                  </button>
                )}
              />
            ]
          }
        />
        <Divider orientation="left">{t('sales')}</Divider>
        <List
          loading={loading}
          bordered
          dataSource={reportData?.headerList}
          renderItem={(item) => (
            <List.Item>
              <Typography.Text
                className={`w-full ${item.bold ? 'font-bold' : ''} ${item.size === 'large' ? 'text-lg' : (item.size === 'x-large' ? 'text-xl' : '')}`}
              >
                <span>{item.name}</span>
                <span className="float-right ml-auto">{item.isNumber ? thousand(item.value) : toCurrency(item.value)}</span>
              </Typography.Text>
            </List.Item>
          )}
        />
        <Divider className="mt-6 pt-4" orientation="left">{t('tender_types')}</Divider>
        <Table
          loading={loading}
          bordered
          pagination={false}
          rowKey="name"
          dataSource={reportData?.tenderTypes}
          columns={[{
            title: t('tender_type'),
            key: 'name',
            align: 'left',
            className: 'text-xs',
            render: (text) => <span className={text.isSummarized ? 'font-bold' : ''}>{text.name}</span>,
          },
          {
            title: t('order_count'),
            key: 'ordercount',
            align: 'left',
            className: 'text-xs',
            render: (text) => <span className={text.isSummarized ? 'font-bold' : ''}>{thousand(text.orders)}</span>,
          },
          {
            title: t('gross_sales'),
            key: 'amountCollected',
            align: 'left',
            className: 'text-xs',
            render: (text) => <span className={text.isSummarized ? 'font-bold' : ''}>{toCurrency(text.amountCollected)}</span>,
          },
          {
            title: t('refund_total'),
            key: 'refundTotal',
            align: 'left',
            className: 'text-xs',
            render: (text) => <span className={text.isSummarized ? 'font-bold' : ''}>{toCurrency(text.refundTotal)}</span>,
          },
          {
            title: t('net_sales'),
            key: 'salesTotal',
            align: 'left',
            className: 'text-xs',
            render: (text) => <span className={text.isSummarized ? 'font-bold' : ''}>{toCurrency(text.salesTotal)}</span>,
          }]}
        />
        <Divider className="mt-6 pt-4" orientation="left">{t('sales_by_card')}</Divider>
        <Table
          loading={loading}
          bordered
          pagination={false}
          rowKey="name"
          dataSource={reportData?.salesByCardType}
          columns={[{
            title: t('tender_type'),
            key: 'name',
            dataIndex: 'name',
            align: 'left',
            className: 'text-xs',
            render: (text) => <span>{text}</span>,
          },
          {
            title: t('order_count'),
            key: 'ordercount',
            dataIndex: 'orders',
            align: 'left',
            className: 'text-xs',
            render: (text) => <span>{thousand(text)}</span>,
          },
          {
            title: t('gross_sales'),
            key: 'amountCollected',
            dataIndex: 'amountCollected',
            align: 'left',
            className: 'text-xs',
            render: (text) => <span>{toCurrency(text)}</span>,
          },
          {
            title: t('refund_total'),
            key: 'refundTotal',
            dataIndex: 'refundTotal',
            align: 'left',
            className: 'text-xs',
            render: (text) => <span>{toCurrency(text)}</span>,
          },
          {
            title: t('net_sales'),
            key: 'salesTotal',
            dataIndex: 'salesTotal',
            align: 'left',
            className: 'text-xs',
            render: (text) => <span>{toCurrency(text)}</span>,
          }]}
        />
        <Divider className="mt-6 pt-4" orientation="left">{t('taxes')}</Divider>
        <Table
          loading={loading}
          bordered
          pagination={false}
          rowKey="name"
          dataSource={reportData?.taxes}
          columns={[{
            title: t('tax'),
            key: 'taxName',
            align: 'left',
            className: 'text-xs',
            render: (text) => <span className={text.isSummarized ? 'font-bold' : ''}>{text.taxName}</span>,
          },
          {
            title: t('applicable_sales'),
            key: 'applicableSales',
            align: 'left',
            className: 'text-xs',
            render: (text) => <span className={text.isSummarized ? 'font-bold' : ''}>{toCurrency(text.applicableSales)}</span>,
          },
          {
            title: t('gross_sales'),
            key: 'amountCollected',
            align: 'left',
            className: 'text-xs',
            render: (text) => <span className={text.isSummarized ? 'font-bold' : ''}>{toCurrency(text.amountCollected)}</span>,
          },
          {
            title: t('amount_refunded'),
            key: 'amountRefunded',
            align: 'left',
            className: 'text-xs',
            render: (text) => <span className={text.isSummarized ? 'font-bold' : ''}>{toCurrency(text.amountRefunded)}</span>,
          },
          {
            title: t('net_sales'),
            key: 'netAmount',
            align: 'left',
            className: 'text-xs',
            render: (text) => <span className={text.isSummarized ? 'font-bold' : ''}>{toCurrency(text.netAmount)}</span>,
          }]}
        />

        <Divider className="mt-6 pt-4" orientation="left">{t('drops')}</Divider>
        <List
          loading={loading}
          bordered
          dataSource={reportData?.dropsHeaderList}
          renderItem={(item) => (
            <List.Item>
              {!item.showTable && (
                <Typography.Text
                  className={`w-full ${item.bold ? 'font-bold' : ''} ${item.size === 'large' ? 'text-lg' : (item.size === 'x-large' ? 'text-xl' : '')}`}
                >
                  <span>{item.name}</span>
                  <span className="float-right ml-auto">{item.isNumber ? thousand(item.value) : toCurrency(item.value)}</span>
                </Typography.Text>)}
              {item.showTable && (
                <Table
                  loading={loading}
                  bordered
                  pagination={false}
                  rowKey="name"
                  className="w-full"
                  dataSource={reportData?.drops}
                  columns={[{
                    title: t('employee'),
                    key: 'employee',
                    align: 'left',
                    className: 'text-xs',
                    render: (text) => <span className={text.isSummarized ? 'font-bold' : ''}>{text.employee}</span>,
                  },
                  {
                    title: t('amount'),
                    key: 'amount',
                    align: 'left',
                    className: 'text-xs',
                    render: (text) => <span className={text.isSummarized ? 'font-bold' : ''}>{toCurrency(text.amount)}</span>,
                  },
                  {
                    title: t('date'),
                    key: 'date',
                    align: 'left',
                    className: 'text-xs',
                    render: (text) => <span className={text.isSummarized ? 'font-bold' : ''}>{renderDate(text.createdAt, timezone)}</span>,
                  }]}
                />
              )}
            </List.Item>
          )}
        />
      </div>
    </div>
  );
}

export default PosSalesSummary;
