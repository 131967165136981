import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import { Modal, Button } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import { jsonPreviewAtom } from '../../../atoms/Atoms';

function JsonPreviewModal() {
  const { t } = useTranslation();
  const [jsonPreview, setJsonPreview] = useRecoilState(jsonPreviewAtom);

  return (
    <Modal
      closable="true"
      centered="true"
      open={jsonPreview}
      onCancel={() => setJsonPreview(null)}
      footer={[
        <Button
          key="close"
          onClick={() => setJsonPreview(null)}
        >
          {t('close')}
        </Button>
      ]}
    >
      <PageHeader title="JSON" />
      <div className="overflow-y-auto">
        {jsonPreview && <pre>{JSON.stringify(JSON.parse(jsonPreview), null, 2)}</pre>}
      </div>
    </Modal>
  );
}

export default JsonPreviewModal;
