import React, { useEffect, useState, useContext, useMemo } from 'react';
import {
  useRecoilState,
  useSetRecoilState
} from 'recoil';
import { useTranslation } from 'react-i18next';
import {
  Drawer,
  Avatar,
  Tooltip,
  Button,
  notification,
  Table
} from 'antd';
import { Comment } from '@ant-design/compatible';
import { DndContext } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import moment from 'moment';
import Grocefy from '../../../../assets/images/grocefyLogoAlone.png';
import {
  showManageFiltersAtom,
  refreshSearchFiltersAtom,
  showAddFilterAtom
} from '../../../../atoms/Atoms';
import api from '../../../../api/api';
import { DraggableBodyRow, DragHandle } from '../../../utils/utils';

function ManageFiltersDrawer() {
  const setShowAddFilter = useSetRecoilState(showAddFilterAtom);
  const [refreshSearchFilters, setRefreshSearchFilters] = useRecoilState(refreshSearchFiltersAtom);
  const [showManageFilters, setShowManageFilters] = useRecoilState(showManageFiltersAtom);
  const [filters, setFilters] = useState([]);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const showMessage = function (message) {
    notification.open({
      message: '',
      description: (
        <Comment
          author={<span>Grocefy</span>}
          avatar={<Avatar src={Grocefy} alt="grocefy" />}
          content={
            <p className="text-sm">
              {message}
            </p>
          }
          datetime={
            <Tooltip title={moment().format('YYYY-MM-DD HH:mm:ss')}>
              <span>{moment().fromNow()}</span>
            </Tooltip>
          }
        />
      ),
    });
  };

  const updateFilters = function () {
    setLoading(true);
    const tempFilters = JSON.parse(JSON.stringify(filters));
    setFilters([]);
    for (let i = 0; i < tempFilters.length; i++) {
      api
        .post(
          `businesses/catalog/filters/${tempFilters[i].id}`,
          {
            Name: tempFilters[i].name,
            Ordering: i
          }
        )
        .then((response) => {
          if (response.data.success) {
            if (i === tempFilters.length - 1) {
              setLoading(false);
              setRefreshSearchFilters(true);
            }
          } else {
            showMessage(response.data.error);
          }
        })
        .catch((error) => {
          setLoading(false);
          showMessage(error);
        });
    }
  };

  const removeFilterClick = function (filter) {
    setLoading(true);
    api
      .delete(`businesses/catalog/filters/${filter.id}`)
      .then((response) => {
        if (response.data.success) {
          setLoading(false);
          setRefreshSearchFilters(true);
        } else {
          showMessage(response.data.error);
        }
      })
      .catch((error) => {
        setLoading(false);
        showMessage(error);
      });
  };

  const onDragEnd = ({ active, over }) => {
    if (active.id !== over?.id) {
      setFilters((prevState) => {
        const activeIndex = prevState.findIndex((record) => record.id === active?.id);
        const overIndex = prevState.findIndex((record) => record.id === over?.id);
        return arrayMove(prevState, activeIndex, overIndex);
      });
    }
  };

  const columns = [
    {
      title: t('sort'),
      dataIndex: 'sort',
      key: 'sort',
      width: 30,
      className: 'drag-visible',
      render: () => <DragHandle />,
    },
    {
      title: t('name'),
      dataIndex: 'name',
      align: 'center',
      className: 'drag-visible',
      render: (text) => (
        <span>{text}</span>
      ),
    },
    {
      title: t('actions'),
      align: 'center',
      className: 'drag-visible',
      render: (row) => (
        <Button
          size="small"
          type="primary"
          danger
          onClick={() => {
            removeFilterClick(row);
          }}
        >
          {t('delete')}
        </Button>
      ),
    }
  ];

  useEffect(() => {
    if (showManageFilters) {
      setLoading(true);
      api
        .get('businesses/catalog/filters')
        .then((response) => {
          if (response.data.success) {
            setLoading(false);
            setFilters(response.data.data.results);
          } else {
            showMessage(response.data.error);
          }
          if (refreshSearchFilters) {
            setRefreshSearchFilters(false);
          }
        })
        .catch((error) => {
          setLoading(false);
          showMessage(error);
        });
    }
  }, [showManageFilters, refreshSearchFilters]);

  return (
    <Drawer
      title={t('manage_filters')}
      placement="right"
      closable
      onClose={() => setShowManageFilters(false)}
      open={showManageFilters}
      width={400}
    >
      <>
        <Button
          onClick={() => { setShowAddFilter(true); }}
          style={{ marginBottom: 10 }}
        >
          {t('add_new_filters')}
        </Button>
        <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
          <SortableContext items={filters.map((i) => i.id)} strategy={verticalListSortingStrategy}>
            <Table
              size="small"
              bordered
              loading={loading}
              pagination={false}
              dataSource={filters}
              columns={columns}
              rowKey="id"
              components={{
                body: {
                  row: DraggableBodyRow,
                },
              }}
            />
          </SortableContext>
        </DndContext>
        <Button
          onClick={() => { updateFilters(); }}
          type="primary"
          style={{
            float: 'right',
            marginTop: 10
          }}
        >
          {t('update')}
        </Button>
      </>
    </Drawer>
  );
}

export default ManageFiltersDrawer;
