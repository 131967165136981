import React from 'react';
import { useTranslation } from 'react-i18next';
import { Tabs } from 'antd';
import PastLossHeader from './shared-components/YesterdayLossHeader';
import PastLossBody from './shared-components/YesterdayLossBody';
import PastUnderweightsBody from './shared-components/YesterdayUnderweightsBody';
import PastSubstitutesBody from './shared-components/YesterdaySubstitutesBody';
import PastSubsOverBody from './shared-components/YesterdaySubsOverBody';

function PastLoss() {
  function callback() {}
  const { t } = useTranslation();

  return (
    <div>
      <div>
        <PastLossHeader />
      </div>
      <Tabs defaultActiveKey="1" onChange={callback}>
        <Tabs.TabPane tab={t('out_of_stock')} key="1">
          <PastLossBody />
        </Tabs.TabPane>
        <Tabs.TabPane tab={t('underweights')} key="2">
          <PastUnderweightsBody />
        </Tabs.TabPane>
        <Tabs.TabPane tab={`${t('substitutes')} (${t('under')})`} key="3">
          <PastSubstitutesBody />
        </Tabs.TabPane>
        <Tabs.TabPane tab={`${t('substitutes')} (${t('over')})`} key="4">
          <PastSubsOverBody />
        </Tabs.TabPane>
      </Tabs>
    </div>
  );
}

export default PastLoss;
