import React, { useState, useEffect } from 'react';
import { useRecoilState } from 'recoil';
import _ from 'lodash';
import { useMount } from 'react-use';
import { Button, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { SyncOutlined } from '@ant-design/icons';
import api from '../../../../api/api';
import { reloadCustomerCards } from '../../../../atoms/Atoms';

function CustomersCards() {
  const [loadingCustomers, setLoadingCustomers] = useState(false);
  const [customersData, setCustomersData] = useState(null);
  const [loadingTodayCustomers, setLoadingTodayCustomers] = useState(false);
  const [todayCustomersData, setTodayCustomersData] = useState(null);
  const [timeSpan, setTimeSpan] = useState(6);
  const { t } = useTranslation();
  const [reload, setReload] = useRecoilState(reloadCustomerCards);

  function mapData(users) {
    const mapped = _.map(users, (p, index) => ({
      key: index,
      date: p.date,
      count: p.count,
    }));

    return mapped;
  }

  function getCustomers() {
    setLoadingCustomers(true);
    api
      .get('analytics/customers/0/1')
      .then((response) => {
        setLoadingTodayCustomers(false);
        setTodayCustomersData(response.data.data);
      })
      .catch((error) => {
        console.error(error);
        setLoadingCustomers(false);
      });
  }

  function getTodayCustomers() {
    setLoadingCustomers(true);
    api
      .get(`analytics/customers/4/${timeSpan}`)
      .then((response) => {
        setLoadingCustomers(false);
        setCustomersData(response.data.data);
      })
      .catch((error) => {
        console.error(error);
        setLoadingCustomers(false);
      });
  }

  useEffect(() => {
    if (reload) {
      getCustomers();
      getTodayCustomers();
    }
  }, [reload]);

  useMount(() => {
    getCustomers();
    getTodayCustomers();
  });

  return (
    <div>
      {/* <div className="flex flex-col" style={{ float: 'right' }}>
        <Button
          className="mb-4"
          size="small"
          type="primary"
          loading={loadingCustomers || loadingTodayCustomers}
          icon={<SyncOutlined spin={loadingCustomers || loadingTodayCustomers} />}
          onClick={() => {
            setReload(!reload);
          }}
        >
          {t('reload')}
        </Button>
      </div> */}
      <br />
      <div>
        <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-4">
          <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
            <Spin spinning={loadingCustomers}>
              <dt className="text-sm font-medium text-gray-500 truncate">{t('registered')}</dt>
              <dd className="mt-1 text-3xl font-semibold text-gray-900">{customersData && customersData.totalUsers.toFixed().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</dd>
            </Spin>
          </div>
          <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
            <Spin spinning={loadingTodayCustomers}>
              <dt className="text-sm font-medium text-gray-500 truncate">{t('registered_today')}</dt>
              <dd className="mt-1 text-3xl font-semibold text-gray-900">{todayCustomersData && todayCustomersData.totalUsers.toFixed().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</dd>
            </Spin>
          </div>
          <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
            <Spin spinning={loadingCustomers}>
              <dt className="text-sm font-medium text-gray-500 truncate">{t('at_least_one_order')}</dt>
              <dd className="mt-1 text-3xl font-semibold text-gray-900">{customersData && customersData.totalUsersWithAtleastOneOrder.toFixed().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                <div className="inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium md:mt-2 lg:mt-0" style={{ float: 'right', marginTop: '30px' }}>
                  {customersData &&
                  ((customersData.totalUsersWithAtleastOneOrder /
                  customersData?.totalUsers) * 100).toFixed(2) }%
                </div>
              </dd>
            </Spin>
          </div>
        </dl>
      </div>
      <br />
    </div>
  );
}

export default CustomersCards;
