import React from 'react';
import ReactDOM from 'react-dom';
import { RecoilRoot } from 'recoil';
import {
  BrowserRouter as Router
} from 'react-router-dom';
// import 'antd/dist/antd.css';
import './assets/main.css';
import './assets/override.css';
import App from './components/App';
import './i18n/config';

ReactDOM.render(
  <Router>
    <RecoilRoot>
      <App />
    </RecoilRoot>
  </Router>,
  document.getElementById('root')
);
