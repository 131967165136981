import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  useRecoilState,
  useRecoilValueLoadable
} from 'recoil';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Table
} from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import {
  selectedDeliveryProviderAtom,
  refreshDeliveryProviderAtom,
  profileAtom
} from '../../../atoms/Atoms';
import EditDeliveryProvider from './shared-components/EditDeliveryProvider';
import ManageDeliveryProviderUsers from './shared-components/ManageDeliveryProviderUsers';
import ManageDeliveryProviderZones from './shared-components/ManageDeliveryProviderZones';
import api from '../../../api/api';

function Loyalty() {
  const [providers, setProviders] = useState([]);
  const [selectedProvider, setSelectedProvider] = useRecoilState(selectedDeliveryProviderAtom);
  const [refreshProviders, setRefreshProviders] = useRecoilState(refreshDeliveryProviderAtom);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const userProfile = useRecoilValueLoadable(profileAtom);
  const isAdmin =
    userProfile.contents && userProfile?.contents?.roles?.includes('SuperAdmin');
  const history = useHistory();

  if (!isAdmin) {
    history.push('/');
    return null;
  }

  const mapRateType = function (type) {
    if (type === 0) {
      return t('none');
    }
    if (type === 1) {
      return t('fixed');
    }
    return t('percentage');
  };

  const getProviders = function () {
    setLoading(true);
    api.get('tpd/providers')
      .then((response) => {
        setLoading(false);
        if (response.data.success) {
          setProviders(response.data.data.results);
        } else {
          console.error(response.data.error);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  useEffect(() => {
    setSelectedProvider(null);
    getProviders();
  }, []);

  useEffect(() => {
    if (refreshProviders) {
      setRefreshProviders(false);
      getProviders();
    }
  }, [refreshProviders]);

  return (
    <>
      <PageHeader
        title={t('delivery_providers')}
        extra={[
          <Button
            key="create"
            type="primary"
            size="small"
            onClick={() => {
              setSelectedProvider({});
            }}
          >
            {t('create')}
          </Button>
        ]}
      />
      <br />
      <div className="ml-6 mb-4 text-sm">
        {t('delivery_providers_desc')}
      </div>
      <Table
        size="small"
        bordered
        loading={loading}
        pagination={false}
        rowKey="id"
        dataSource={providers}
        columns={[
          {
            title: t('name'),
            align: 'center',
            className: 'text-xs',
            render: (row) => (
              <span>{row.name}</span>
            ),
          },
          {
            title: t('rate_type'),
            align: 'center',
            className: 'text-xs',
            render: (row) => (
              <span>{mapRateType(row.rateType)}</span>
            ),
          },
          {
            title: t('rate'),
            align: 'center',
            className: 'text-xs',
            render: (row) => (
              <span>{row.rate}</span>
            ),
          },
          {
            title: t('delivery_rate_type'),
            align: 'center',
            className: 'text-xs',
            render: (row) => (
              <span>{mapRateType(row.deliveryFeeRateType)}</span>
            ),
          },
          {
            title: t('delivery_rate'),
            align: 'center',
            className: 'text-xs',
            render: (row) => (
              <span>{row.deliveryFeeRate}</span>
            ),
          },
          {
            title: '',
            align: 'center',
            className: 'text-xs',
            render: (row) => (
              <Button
                size="small"
                onClick={() => {
                  setSelectedProvider(row);
                }}
              >
                {t('edit')}
              </Button>
            ),
          }
        ]}
      />
      <EditDeliveryProvider />
      <ManageDeliveryProviderUsers />
      <ManageDeliveryProviderZones />
    </>
  );
}

export default Loyalty;
