import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import {
  useRecoilState,
  useSetRecoilState,
  useRecoilValueLoadable,
} from 'recoil';
import moment from 'moment';
import {
  Button,
  Table,
  Select,
  Image,
  Input,
  Spin,
  notification,
  Avatar,
  Tooltip,
  Popconfirm
} from 'antd';
import { Comment } from '@ant-design/compatible';
import { PageHeader } from '@ant-design/pro-layout';
import { InfoCircleOutlined } from '@ant-design/icons';
import {
  profileAtom,
  refreshImagesAtom,
  selectedImageAtom,
  imageCategoriesAtom,
  imageDepartmentsAtom,
  imageTypesAtom,
  openHelpDrawerAtom
} from '../../../atoms/Atoms';
import Grocefy from '../../../assets/images/grocefyLogoAlone.png';
import imagesApi from '../../../api/imagesApi';
import envConfig from '../../../envConfig';
import EditImageDrawer from './shared-components/EditImageDrawer';
import HelpDrawer from './shared-components/HelpDrawer';

function Images() {
  const { Search } = Input;
  const { Option } = Select;

  const setSelectedImage = useSetRecoilState(selectedImageAtom);
  const [refreshImages, setRefreshImages] = useRecoilState(refreshImagesAtom);
  const [departments, setDepartments] = useRecoilState(imageDepartmentsAtom);
  const [categories, setCategories] = useRecoilState(imageCategoriesAtom);
  const [types, setTypes] = useRecoilState(imageTypesAtom);

  const [lastSearch, setLastSearch] = useState('');
  const [imageType, setImageType] = useState('');
  const [category, setCategory] = useState('');
  const [department, setDepartment] = useState('');
  const [images, setImages] = useState([]);
  const [info, setInfo] = useState(null);

  const [loadingInfo, setLoadingInfo] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingTypes, setLoadingTypes] = useState(false);
  const [loadingCategories, setLoadingCategories] = useState(false);
  const [loadingDepartments, setLoadingDepartments] = useState(false);

  const userProfile = useRecoilValueLoadable(profileAtom);
  const isAdmin =
    userProfile.contents && userProfile?.contents?.roles?.includes('SuperAdmin');
  const history = useHistory();
  const { t } = useTranslation();
  const [open, setOpen] = useRecoilState(openHelpDrawerAtom);

  if (!isAdmin) {
    history.push('/');
    return null;
  }

  const showMessage = function (message) {
    notification.open({
      message: '',
      description: (
        <Comment
          author={<span>Grocefy</span>}
          avatar={<Avatar src={Grocefy} alt="grocefy" />}
          content={
            <p className="text-sm">
              {message}
            </p>
          }
          datetime={
            <Tooltip title={moment().format('YYYY-MM-DD HH:mm:ss')}>
              <span>{moment().fromNow()}</span>
            </Tooltip>
          }
        />
      ),
    });
  };

  const getImageTypes = function () {
    setLoadingTypes(true);
    imagesApi.get('types')
      .then((response) => {
        setLoadingTypes(false);
        setTypes(response.data);
      })
      .catch((error) => {
        setLoadingTypes(false);
        showMessage(error.message);
        console.error(error);
      });
  };

  const getDepartments = function () {
    setLoadingDepartments(true);
    imagesApi.get('departments')
      .then((response) => {
        setLoadingDepartments(false);
        setDepartments(response.data);
      })
      .catch((error) => {
        setLoadingDepartments(false);
        showMessage(error.message);
        console.error(error);
      });
  };

  const getCategories = function () {
    setLoadingCategories(true);
    imagesApi.get('categories')
      .then((response) => {
        setLoadingCategories(false);
        setCategories(response.data);
      })
      .catch((error) => {
        setLoadingCategories(false);
        showMessage(error.message);
        console.error(error);
      });
  };

  const search = function (value) {
    setLastSearch(value);
    setLoading(true);
    let params = '';
    if (value?.length > 0) {
      params += `${params.length > 0 ? '&' : ''}searchTerm=${value}`;
    }
    if (imageType.length > 0) {
      params += `${params.length > 0 ? '&' : ''}imageType=${imageType}`;
    }
    if (category.length > 0) {
      params += `${params.length > 0 ? '&' : ''}category=${category}`;
    }
    if (department.length > 0) {
      params += `${params.length > 0 ? '&' : ''}department=${department}`;
    }
    imagesApi.get(`search?${params}`)
      .then((response) => {
        setLoading(false);
        setImages(response.data);
      })
      .catch((error) => {
        setLoading(false);
        showMessage(error.message);
        console.error(error);
      });
  };

  const getInfo = function () {
    getCategories();
    getDepartments();
    getImageTypes();
    setLoadingInfo(true);
    imagesApi.get('info')
      .then((response) => {
        setLoadingInfo(false);
        setInfo({
          ...response.data,
          departmentTotal: response.data.departments.length,
          categoriesTotal: response.data.categories.length
        });
      })
      .catch((error) => {
        setLoadingInfo(false);
        showMessage(error.message);
        console.error(error);
      });
  };

  useEffect(() => {
    getInfo();
  }, []);

  useEffect(() => {
    if (refreshImages) {
      setRefreshImages(false);
      getInfo();
      search(lastSearch);
    }
  }, [refreshImages]);

  return (
    <>
      <PageHeader
        title={t('images_service')}
        extra={[
          <div>
            <Button
              key="create"
              type="primary"
              size="small"
              onClick={() => setSelectedImage({})}
            >
              {t('create')}
            </Button>
            <Tooltip title="Help">
              <Button
                style={{ marginLeft: '10px' }}
                shape="circle"
                type="primary"
                icon={<InfoCircleOutlined />}
                size="middle"
                onClick={() => setOpen(true)}
              />
            </Tooltip>
          </div>
        ]}
      />
      <div className="mb-4">
        <dl className="grid grid-cols-1 gap-5 sm:grid-cols-3">
          <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
            <Spin spinning={loadingInfo}>
              <dt className="text-sm font-medium text-gray-500 truncate">{t('total_images')}</dt>
              <dd className="mt-1 text-3xl font-semibold text-gray-900">{info?.total.toLocaleString()}</dd>
            </Spin>
          </div>
          <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
            <Spin spinning={loadingInfo}>
              <dt className="text-sm font-medium text-gray-500 truncate">{t('images_departments_total')}</dt>
              <dd className="mt-1 text-3xl font-semibold text-gray-900">{info?.departmentTotal}</dd>
            </Spin>
          </div>
          <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
            <Spin spinning={loadingInfo}>
              <dt className="text-sm font-medium text-gray-500 truncate">{t('images_categories_total')}</dt>
              <dd className="mt-1 text-3xl font-semibold text-gray-900">{info?.categoriesTotal}</dd>
            </Spin>
          </div>
        </dl>
      </div>
      <div className="mb-4">
        <div className="grid grid-cols-1 gap-5 sm:grid-cols-3">
          <div>
            <strong>{t('department')}</strong>
            <Select
              className="ml-1 w-full"
              value={department}
              disabled={loadingDepartments}
              onChange={(obj) => {
                setDepartment(obj);
              }}
            >
              <Option value="">{t('any')}</Option>
              {_.map(departments, (cat) => <Option value={cat}>{cat}</Option>)}
            </Select>
          </div>
          <div className="ml-3">
            <strong>{t('category')}</strong>
            <Select
              className="ml-1 w-full"
              value={category}
              disabled={loadingCategories}
              onChange={(obj) => {
                setCategory(obj);
              }}
            >
              <Option value="">{t('any')}</Option>
              {_.map(categories, (cat) => <Option value={cat}>{cat}</Option>)}
            </Select>
          </div>
          <div className="ml-3">
            <strong>{t('type')}</strong>
            <Select
              className="ml-1 w-full"
              value={imageType}
              disabled={loadingTypes}
              onChange={(obj) => {
                setImageType(obj);
              }}
            >
              <Option value="">{t('any')}</Option>
              {_.map(types, (type) => <Option value={type}>{type}</Option>)}
            </Select>
          </div>
        </div>
        <Search
          className="mt-5"
          key="itemSearch"
          placeholder={t('item_search_placeholder')}
          allowClear
          loading={loading}
          enterButton={t('search')}
          disabled={loadingCategories || loadingTypes || loadingDepartments}
          onSearch={(e) => search(e)}
        />
      </div>
      <Table
        size="small"
        bordered
        scroll={{ x: 500 }}
        loading={loading}
        pagination={false}
        rowKey="id"
        dataSource={images}
        columns={[
          {
            title: t('image'),
            align: 'center',
            className: 'text-xs',
            render: (row) => (
              <Image
                width={100}
                src={`${envConfig.REACT_APP_IMAGES_URL}/query/photos-sm/${row.id}?subscription-key=${envConfig.REACT_APP_IMAGES_KEY}`}
                alt={`${row.itemBrand} ${row.itemName} ${row.itemDescription}`}
              />
            ),
          },
          {
            title: 'GTIN',
            align: 'center',
            className: 'text-xs',
            render: (row) => (
              <span>{row.itemGtin}</span>
            ),
          },
          {
            title: t('brand'),
            align: 'center',
            className: 'text-xs',
            render: (row) => (
              <span>{row.itemBrand}</span>
            ),
          },
          {
            title: t('name'),
            align: 'center',
            className: 'text-xs',
            render: (row) => (
              <span>{row.itemName}</span>
            ),
          },
          {
            title: t('description'),
            align: 'center',
            className: 'text-xs',
            render: (row) => (
              <span>{row.itemDescription}</span>
            ),
          },
          {
            title: t('department'),
            align: 'center',
            className: 'text-xs',
            render: (row) => (
              <span>{row.itemDepartment}</span>
            ),
          },
          {
            title: t('category'),
            align: 'center',
            className: 'text-xs',
            render: (row) => (
              <span>{row.itemCategory}</span>
            ),
          },
          {
            title: '',
            align: 'center',
            className: 'text-xs',
            render: (row) => (
              <Button
                type="primary"
                size="small"
                onClick={() => setSelectedImage(row)}
              >
                {t('edit')}
              </Button>
            ),
          },
          {
            title: '',
            align: 'center',
            className: 'text-xs',
            render: (row) => (
              <Popconfirm
                placement="topRight"
                title={t('image_confirmation_delete')}
                onConfirm={() => {
                  setLoading(true);
                  // console.log(row);
                  imagesApi
                    .delete(`delete/${row.id}`)
                    .then((response) => {
                      setLoading(false);
                      // console.log(response);
                    })
                    .catch((error) => {
                      setLoading(false);
                      showMessage(error.message);
                      console.error(error);
                    });
                }}
                okText={t('yes')}
                cancelText="No"
              >
                <Button
                  type="primary"
                  danger
                  size="small"
                >
                  {t('delete')}
                </Button>
              </Popconfirm>
            ),
          },
          {
            title: 'URL',
            align: 'center',
            className: 'text-xs',
            render: (row) => (
              <>
                <Button
                  type="primary"
                  size="small"
                  onClick={() => {
                    navigator.clipboard.writeText(
                      `${envConfig.REACT_APP_IMAGES_URL}/query/photos-sm/${row.id}?subscription-key=${envConfig.REACT_APP_IMAGES_KEY}`
                    );
                    showMessage(t('url_copied_content'));
                  }}
                >
                  SM
                </Button>
                <Button
                  type="primary"
                  className="m-1"
                  size="small"
                  onClick={() => {
                    navigator.clipboard.writeText(
                      `${envConfig.REACT_APP_IMAGES_URL}/query/photos-md/${row.id}?subscription-key=${envConfig.REACT_APP_IMAGES_KEY}`
                    );
                    showMessage(t('url_copied_content'));
                  }}
                >
                  MD
                </Button>
                <Button
                  type="primary"
                  size="small"
                  onClick={() => {
                    navigator.clipboard.writeText(
                      `${envConfig.REACT_APP_IMAGES_URL}/query/photos/${row.id}?subscription-key=${envConfig.REACT_APP_IMAGES_KEY}`
                    );
                    showMessage(t('url_copied_content'));
                  }}
                >
                  LG
                </Button>
              </>
            ),
          }
        ]}
      />
      <EditImageDrawer />
      {open ? <HelpDrawer /> : null}
    </>
  );
}

export default Images;
