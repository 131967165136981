import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import {
  Drawer,
  Input,
  Avatar,
  notification,
  Tooltip,
  Button,
  Dropdown,
  Menu
} from 'antd';
import { Comment } from '@ant-design/compatible';
import { useRecoilState, useRecoilValue, useSetRecoilState, } from 'recoil';
import {
  editBookingServiceTypeAtom,
  selectedBookingServiceTypeAtom,
  reloadBookingServiceTypeAtom
} from '../../../../../atoms/Atoms';
import Grocefy from '../../../../../assets/images/grocefyLogoAlone.png';
import bookingApi from '../../../../../api/bookingApi';
import api from '../../../../../api/api';
import { isEmpty } from '../../../../utils/functions';

function EditHost() {
  const { t } = useTranslation();
  const setReload = useSetRecoilState(reloadBookingServiceTypeAtom);
  const selectedServiceType = useRecoilValue(selectedBookingServiceTypeAtom);
  const [editServiceType, setEditServiceType] = useRecoilState(editBookingServiceTypeAtom);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);

  function showMessage(message) {
    notification.open({
      message: '',
      description: (
        <Comment
          author={<span>Grocefy</span>}
          avatar={<Avatar src={Grocefy} alt="grocefy" />}
          content={
            <p className="text-sm">
              {message}
            </p>
          }
          datetime={
            <Tooltip title={moment().format('YYYY-MM-DD HH:mm:ss')}>
              <span>{moment().fromNow()}</span>
            </Tooltip>
          }
        />
      ),
    });
  }

  function submit() {
    setLoading(true);
    if (selectedServiceType.id) {
      bookingApi.put('servicetypes', data)
        .then((response) => {
          setLoading(false);
          if (response.data.error) {
            showMessage(response.data.error);
          } else {
            setReload(true);
            setEditServiceType(false);
          }
        })
        .catch((error) => {
          setLoading(false);
          showMessage(error.message);
        });
    } else {
      bookingApi.post('servicetypes', data)
        .then((response) => {
          setLoading(false);
          if (response.data.error) {
            showMessage(response.data.error);
          } else {
            setReload(true);
            setEditServiceType(false);
          }
        })
        .catch((error) => {
          setLoading(false);
          showMessage(error.message);
        });
    }
  }

  function remove() {
    setLoading(true);
    bookingApi.delete(`servicetypes/${selectedServiceType?.id}`)
      .then((response) => {
        setLoading(false);
        if (response.data.error) {
          showMessage(response.data.error);
        } else {
          setReload(true);
          setEditServiceType(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        showMessage(error.message);
      });
  }

  useEffect(() => {
    if (selectedServiceType && editServiceType) {
      if (selectedServiceType.id) {
        setData(selectedServiceType);
      } else {
        setData({
          nameEn: '',
          nameEs: ''
        });
      }
    } else if (!editServiceType) {
      setData(null);
    }
  }, [selectedServiceType, editServiceType]);

  return (
    <Drawer
      title={selectedServiceType?.id ? t('edit_service_type') : t('create_service_type')}
      placement="right"
      closable={!loading}
      onClose={() => setEditServiceType(false)}
      open={editServiceType}
      width={300}
    >
      <div hidden={!data?.id}>
        <div
          className="float-right"
        >
          <Button
            type="primary"
            danger
            loading={loading}
            onClick={() => { remove(); }}
          >
            {t('delete')}
          </Button>
        </div>
        <br />
      </div>
      <div
        className="mb-4"
      >
        <strong>{t('name')} (EN)</strong>
        <Input
          disabled={loading}
          className="w-full"
          value={data?.nameEn}
          onChange={(obj) => {
            setData({
              ...data,
              nameEn: obj.target.value
            });
          }}
        />
      </div>
      <div
        className="mb-4"
      >
        <strong>{t('name')} (Es)</strong>
        <Input
          disabled={loading}
          className="w-full"
          value={data?.nameEs}
          onChange={(obj) => {
            setData({
              ...data,
              nameEs: obj.target.value
            });
          }}
        />
      </div>
      <div
        className="float-right"
      >
        <Button
          type="primary"
          loading={loading}
          onClick={() => { submit(); }}
        >
          {data?.id ? t('update') : t('create')}
        </Button>
      </div>
    </Drawer>
  );
}
export default EditHost;
