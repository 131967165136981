import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  Drawer,
  Avatar,
  Tooltip,
  notification,
  Table
} from 'antd';
import { Comment } from '@ant-design/compatible';
import moment from 'moment';
import Grocefy from '../../../../../assets/images/grocefyLogoAlone.png';
import {
  timezoneAtom,
  selectedTicketBoardAtom,
  showBoardLogsAtom } from '../../../../../atoms/Atoms';
import api from '../../../../../api/api';

function BoardLogs() {
  const timezone = useRecoilValue(timezoneAtom);
  const selectedBoard = useRecoilValue(selectedTicketBoardAtom);
  const [viewBoardLogs, setViewBoardLogs] = useRecoilState(showBoardLogsAtom);
  const [logs, setLogs] = useState([]);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const showErrorMessage = function (errorMessage) {
    notification.open({
      message: '',
      description: (
        <Comment
          author={<span>Grocefy</span>}
          avatar={<Avatar src={Grocefy} alt="grocefy" />}
          content={
            <p className="text-sm">
              {errorMessage}
            </p>
          }
          datetime={
            <Tooltip title={moment().format('YYYY-MM-DD HH:mm:ss')}>
              <span>{moment().fromNow()}</span>
            </Tooltip>
          }
        />
      ),
    });
  };

  useEffect(() => {
    if (viewBoardLogs && selectedBoard) {
      setLoading(true);
      api.get(`ticketboards/board/logs/${selectedBoard.id}`)
        .then((response) => {
          setLoading(false);
          if (response.data.success) {
            setLogs(response.data.data.results);
          } else {
            showErrorMessage(response.data.error);
          }
        })
        .catch((error) => {
          setLoading(false);
          console.error(error);
        });
    } else {
      setLogs([]);
    }
  }, [viewBoardLogs, selectedBoard]);

  return (
    <Drawer
      title={t('board_log')}
      placement="right"
      closable
      onClose={() => setViewBoardLogs(false)}
      open={viewBoardLogs}
      width={400}
    >
      <Table
        size="small"
        bordered
        hideOnSinglePage="true"
        loading={loading}
        columns={[
          {
            title: t('description'),
            dataIndex: 'description',
            key: 'code',
            align: 'center',
            className: 'text-xs',
            render: (text) => (
              <span>{text}</span>
            ),
          },
          {
            title: t('date'),
            dataIndex: 'createdAt',
            key: 'createdAt',
            align: 'left',
            className: 'text-xs',
            render: (text) => (
              <span>
                {moment(`${text}+0000`)
                  .tz(timezone)
                  .format('MMM dd YYYY, h:mm:ss a')}
              </span>
            )
          }
        ]}
        dataSource={logs}
      />
    </Drawer>
  );
}

export default BoardLogs;
