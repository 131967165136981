import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import Form from 'antd/lib/form/Form';
import { notification, Modal, Avatar, Input, Button } from 'antd';
import { Comment } from '@ant-design/compatible';
import { PageHeader } from '@ant-design/pro-layout';
import { customCreditAtom, tokenAtom, selectedOrderAtom, reloadOrder } from '../../../atoms/Atoms';
import api from '../../../api/api';
import Grocefy from '../../../assets/images/grocefyLogoAlone.png';

function CustomCredit() {
  const [customCredit, setcustomCredit] = useRecoilState(customCreditAtom);
  const token = useRecoilValue(tokenAtom);
  const [selectedOrder, setSelectedOrder] = useRecoilState(selectedOrderAtom);
  const [reload, setReload] = useRecoilState(reloadOrder);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const { TextArea } = Input;
  const { t } = useTranslation();

  const onChangeMessage = function (obj) {
    setMessage(obj.target.value);
  };

  const closeTicketModal = function () {
    if (!loading) {
      setcustomCredit(false);
    }
  };

  const createLogClick = function () {
    setLoading(true);
    const data = {
      Message: message,
      OrderId: selectedOrder.id
    };
    if (selectedOrder.total < message) {
      notification.open({
        message: 'Alert!',
        description: (
          <Comment
            author={<span>Grocefy</span>}
            avatar={<Avatar src={Grocefy} alt="grocefy" />}
            content={
              <p className="text-sm">{t('refund_content')} ${selectedOrder.total}</p>
            }
          />
        ),
      });
      setcustomCredit(false);
    } else {
      api
        .put(
          `$orders/${selectedOrder.id}/credit/false`,
          {
            Amount: message,
            OrderId: selectedOrder.id,
            Silent: false
          }
        )
        .then((response) => {
          if (response && response.data.error) {
            console.error('Error Message: ', response.data.error);
            notification.open({
              message: 'Alert!',
              description: (
                <Comment
                  author={<span>Grocefy</span>}
                  avatar={<Avatar src={Grocefy} alt="grocefy" />}
                  content={
                    <p className="text-sm">{response.data.error}</p>
                  }
                />
              ),
            });
          } else if (
            response &&
            response.data.success &&
            response.data.message
          ) {
            notification.open({
              message: 'Success!',
              description: (
                <Comment
                  author={<span>Grocefy</span>}
                  avatar={<Avatar src={Grocefy} alt="grocefy" />}
                  content={
                    <p className="text-sm">{response.data.message}</p>
                  }
                />
              ),
            });
          }
          setcustomCredit(false);
          setReload(true);
          setLoading(false);
          setMessage('');
        })
        .catch((error) => {
          console.error(error);
          setLoading(false);
        });
    }
  };

  return (
    <>
      {customCredit && (
        <Modal
          closable="true"
          centered="true"
          open={customCredit}
          onCancel={() => { setcustomCredit(false); }}
          footer={[
            <Button
              key="create"
              disabled={loading}
              type="primary"
              onClick={() => createLogClick()}
            >
              {t('credit')} ${message}
            </Button>,
            <Button
              key="close"
              type="primary"
              danger
              disabled={loading}
              onClick={() => closeTicketModal()}
            >
              {t('close')}
            </Button>
          ]}
        >
          <PageHeader title={t('provide_custom_credit')} />
          {t('custom_credit_content')}
          <TextArea
            placeholder={t('amount')}
            onChange={(obj) => onChangeMessage(obj)}
            rows={1}
            value={message}
          />
        </Modal>
      )}
    </>
  );
}

export default CustomCredit;
