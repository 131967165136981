import React, { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  Table,
  Drawer,
  Switch,
  Tooltip,
  notification,
  Avatar
} from 'antd';
import { Comment } from '@ant-design/compatible';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import Grocefy from '../../../../assets/images/grocefyLogoAlone.png';
import {
  setSelectedMasterItemAtom,
  showItemFiltersAtom
} from '../../../../atoms/Atoms';
import api from '../../../../api/api';

function ManageItemFiltersDrawer() {
  const selectedMasterItem = useRecoilValue(setSelectedMasterItemAtom);
  const [showItemFilters, setShowItemFilters] = useRecoilState(showItemFiltersAtom);
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState([]);
  const { t } = useTranslation();

  const showMessage = function (message) {
    notification.open({
      message: '',
      description: (
        <Comment
          author={<span>Grocefy</span>}
          avatar={<Avatar src={Grocefy} alt="grocefy" />}
          content={
            <p className="text-sm">
              {message}
            </p>
          }
          datetime={
            <Tooltip title={moment().format('YYYY-MM-DD HH:mm:ss')}>
              <span>{moment().fromNow()}</span>
            </Tooltip>
          }
        />
      ),
    });
  };

  const getItemFilters = function (allFilters) {
    const temp = allFilters;
    setLoading(true);
    api
      .get(`businesses/catalog/${selectedMasterItem.id}/filters`)
      .then((response) => {
        setLoading(false);
        if (response.data.success) {
          const tempFilters = response.data.data.results;
          for (let i = 0; i < tempFilters.length; i++) {
            for (let index = 0; index < temp.length; index++) {
              if (tempFilters[i].id === temp[index].id) {
                temp[index].active = true;
              }
            }
          }
          setFilters(temp);
        } else {
          showMessage(response.data.error);
        }
      })
      .catch((error) => {
        setLoading(false);
        showMessage(error);
      });
  };

  const getFilters = function () {
    setLoading(true);
    api
      .get('businesses/catalog/filters')
      .then((response) => {
        if (response.data.success) {
          getItemFilters(response.data.data.results);
        } else {
          setLoading(false);
          showMessage(response.data.error);
        }
      })
      .catch((error) => {
        setLoading(false);
        showMessage(error);
      });
  };

  const updateFilterActive = function (filterId) {
    const tempFilters = JSON.parse(JSON.stringify(filters));
    let index = -1;
    for (let i = 0; i < tempFilters.length; i++) {
      if (tempFilters[i].id === filterId) {
        index = i;
      }
    }
    if (index !== -1) {
      tempFilters[index].active = !tempFilters[index].active;
      setFilters(tempFilters);
      api
        .post(
          `businesses/catalog/filters/${filterId}/${selectedMasterItem.id}`,
          { }
        )
        .then((response) => {
          setLoading(false);
          if (!response.data.success) {
            showMessage(response.data.error);
          }
        })
        .catch((error) => {
          setLoading(false);
          showMessage(error);
        });
    }
  };

  useEffect(() => {
    if (useEffect) {
      getFilters();
    }
  }, [showItemFilters]);

  return (
    <Drawer
      title={t('item_filters')}
      placement="right"
      closable
      onClose={() => setShowItemFilters(false)}
      open={showItemFilters}
      width={400}
    >
      <Table
        size="small"
        bordered
        loading={loading}
        pagination={false}
        dataSource={filters}
        columns={[
          {
            title: t('actions'),
            align: 'center',
            render: (row) => (
              <Switch
                checked={row.active}
                onChange={(checked) => {
                  updateFilterActive(row.id);
                }}
              />
            ),
          },
          {
            title: t('name'),
            dataIndex: 'name',
            align: 'center',
            render: (text) => (
              <span>{text}</span>
            ),
          }
        ]}
      />
    </Drawer>
  );
}

export default ManageItemFiltersDrawer;
