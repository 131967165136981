import React, { useEffect, useState } from 'react';
import {
  useRecoilState,
  useRecoilValue
} from 'recoil';
import { useTranslation } from 'react-i18next';
import {
  Modal,
  Spin,
  Tooltip,
  Avatar,
  notification,
  Button
} from 'antd';
import { Comment } from '@ant-design/compatible';
import { PageHeader } from '@ant-design/pro-layout';
import moment from 'moment';
import Grocefy from '../../../../assets/images/grocefyLogoAlone.png';
import {
  setSelectedMasterItemAtom,
  selectedPickingItemAtom,
  showAddItemSubstituteAtom
} from '../../../../atoms/Atoms';
import Search from './Search';
import api from '../../../../api/api';

function AddSubstituteModal() {
  const selectedMasterItem = useRecoilValue(setSelectedMasterItemAtom);
  const [showAddItemSubstitutes, setShowAddItemSubstitutes] =
    useRecoilState(showAddItemSubstituteAtom);
  const [selectedPickingItem, setSelectedPickingItem] =
    useRecoilState(selectedPickingItemAtom);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const showMessage = function (message) {
    notification.open({
      message: '',
      description: (
        <Comment
          author={<span>Grocefy</span>}
          avatar={<Avatar src={Grocefy} alt="grocefy" />}
          content={
            <p className="text-sm">
              {message}
            </p>
          }
          datetime={
            <Tooltip title={moment().format('YYYY-MM-DD HH:mm:ss')}>
              <span>{moment().fromNow()}</span>
            </Tooltip>
          }
        />
      ),
    });
  };

  useEffect(() => {
    if (selectedPickingItem) {
      setLoading(true);
      api
        .post(
          'businesses/catalog/substitutes',
          {
            ParentId: selectedMasterItem.id,
            ItemId: selectedPickingItem.id,
            Ordering: 99
          }
        )
        .then((response) => {
          setLoading(false);
          if (response.data.success) {
            setSelectedPickingItem(null);
            setShowAddItemSubstitutes(false);
          } else {
            showMessage(response.data.error);
          }
        })
        .catch((error) => {
          setLoading(false);
          showMessage(error);
        });
    }
  }, [selectedPickingItem]);

  useEffect(() => {
    setLoading(false);
    setSelectedPickingItem(null);
  }, []);

  return (
    <Modal
      closable="true"
      centered="true"
      onCancel={() => setShowAddItemSubstitutes(false)}
      open={showAddItemSubstitutes}
      footer={[
        <Button
          key="back"
          disabled={loading}
          onClick={() => setShowAddItemSubstitutes(false)}
        >
          {t('close')}
        </Button>
      ]}
    >
      <PageHeader>{t('add_substitutes')}</PageHeader>
      {!loading && (
        <Search
          itemPicking
          size="small"
        />
      )}
      {loading && (
        <div style={{ textAlign: 'center' }}>
          <Spin />
        </div>
      )}
    </Modal>
  );
}

export default AddSubstituteModal;
