import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import dayjs from 'dayjs';
import { DatePicker, Drawer, Spin, Tabs, Tag } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import { useMount } from 'react-use';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  businessAtom,
  openPerformanceDrawerAtom,
  reloadPerformanceDataAtom,
  selectedEmployeeAtom,
} from '../../../atoms/Atoms';
import api from '../../../api/api';

function EmployeePerformance() {
  const [openDrawer, setOpenDrawer] = useRecoilState(openPerformanceDrawerAtom);
  const [selectedEmployee, setSelectedEmployee] = useRecoilState(selectedEmployeeAtom);
  const [reload, setReload] = useRecoilState(reloadPerformanceDataAtom);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const { RangePicker } = DatePicker;
  const [dateRange, setDateRange] = useState({
    start: moment().startOf('month').format('YYYY-MM-DD'),
    end: moment().endOf('month').format('YYYY-MM-DD')
  });
  const [packingData, setPackingData] = useState(null);
  const [pickingData, setPickingData] = useState(null);

  function GetPickingPackingData() {
    setLoading(true);
    api
      .post(`analytics/v2/summary/performance/${selectedEmployee.id}`,
        { StartDate: dateRange.start,
          EndDate: dateRange.end,
        })
      .then((response) => {
        if (response?.data?.success) {
          setLoading(false);
          setPackingData(response.data.data.packing);
          setPickingData(response.data.data.picking);
        } else {
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  }

  useMount(() => {
    GetPickingPackingData();
  });

  useEffect(() => {
    GetPickingPackingData();
  }, [dateRange]);

  function picking() {
    return (
      <div>
        <p><b>{t('order_count')}</b>: {pickingData?.orderCount}</p>
        <p><b>{t('items_per_order')}</b>: {pickingData?.itemsPerOrder}</p>
        <p><b>{t('items_per_minute')}</b>: {pickingData?.itemsPerMinute == null ? t('all_items') : pickingData?.itemsPerMinute}</p>
        <p><b>{t('average_time')}</b>: {pickingData?.averageTime} {t('minutes')}</p>
      </div>
    );
  }
  function packing() {
    return (
      <div>
        <p><b>{t('order_count')}</b>: {packingData?.orderCount}</p>
        <p><b>{t('items_per_order')}</b>: {packingData?.itemsPerOrder}</p>
        <p><b>{t('items_per_minute')}</b>: {packingData?.itemsPerMinute == null ? t('all_items') : packingData?.itemsPerMinute}</p>
        <p><b>{t('average_time')}</b>: {packingData?.averageTime} {t('minutes')}</p>
      </div>
    );
  }

  function drawerData() {
    return (
      <div>
        <PageHeader
          className="mb-4 px-0"
          title={selectedEmployee.completeName}
          extra={
            [
              <div>
                <RangePicker
                  disabled={loading}
                  format="YYYY-MM-DD"
                  value={[
                    dayjs(dateRange.start, 'YYYY-MM-DD'),
                    dayjs(dateRange.end, 'YYYY-MM-DD')
                  ]}
                  onChange={(date, dateString) => {
                    setDateRange({
                      start: dateString[0],
                      end: dateString[1]
                    });
                  }}
                />
              </div>,
            ]
          }
        />
        {loading ? <Spin /> : null}
        <Tabs defaultActiveKey="1">
          <Tabs.TabPane tab={t('picking')} key="2">
            {picking()}
          </Tabs.TabPane>
          <Tabs.TabPane tab={t('packing')} key="3">
            {packing()}
          </Tabs.TabPane>
        </Tabs>
      </div>
    );
  }

  return (
    <div>
      <Drawer
        title={t('employee_performance')}
        placement="right"
        closable
        onClose={() => setOpenDrawer(false)}
        open={setOpenDrawer && selectedEmployee}
        width={500}
      >
        <div> </div>
        <div>{drawerData()}</div>
      </Drawer>
    </div>
  );
}

export default EmployeePerformance;
