import React, { useState, useEffect } from 'react';
import { useMount } from 'react-use';
import { useRecoilValue, useRecoilValueLoadable } from 'recoil';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import _ from 'lodash';
import { useJsonToCsv } from 'react-json-csv';
import {
  Tag,
  Badge,
  Button,
  Table,
  Typography,
  Space,
  Select,
  Popover,
  DatePicker,
  Divider,
  Switch,
  Avatar,
  Card
} from 'antd';
import dayjs from 'dayjs';
import { PageHeader } from '@ant-design/pro-layout';
import {
  CheckCircleOutlined,
  CloudDownloadOutlined,
  ReloadOutlined,
  SearchOutlined
} from '@ant-design/icons';
import { businessAtom, timezoneAtom, profileAtom } from '../../../atoms/Atoms';
import { mapPosDiscountType, toCurrency, thousand, renderDate } from '../../utils/functions';
import api from '../../../api/api';
import envConfig from '../../../envConfig';
import { dateRangePresets } from '../../utils/utils';

function PosDiscountsReport() {
  const bottomRowGridStyle = { width: '100%', textAlign: 'center' };
  const { RangePicker } = DatePicker;
  const { t, i18n } = useTranslation();
  const { Option } = Select;
  const { saveAsCsv } = useJsonToCsv();
  const business = useRecoilValue(businessAtom);
  const timezone = useRecoilValue(timezoneAtom);
  const profile = useRecoilValueLoadable(profileAtom);
  const isAdmin =
    profile.contents && profile?.contents?.roles?.includes('SuperAdmin');
  const isStoreManager =
    isAdmin ||
    (profile?.contents &&
      (profile?.contents?.roles?.includes('StoreAdmin') ||
        profile?.contents?.roles?.includes('Owner')));
  const canViewFinance =
    isAdmin ||
    isStoreManager ||
    (profile.contents &&
      (profile.contents.roles.includes('Finance') ||
        profile.contents.roles.includes('TopAnalytics')));
  const [reportData, setReportData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isLifeTime, setIsLifetime] = useState(false);
  const [loadingPosDiscounts, setLoadingPosDiscounts] = useState(false);
  const [selectedPosDiscounts, setSelectedPosDiscounts] = useState(['']);
  const [dateRange, setDateRange] = useState({
    start: moment().startOf('month').format('YYYY-MM-DD'),
    end: moment().endOf('month').format('YYYY-MM-DD')
  });
  const [posDiscounts, setPosDiscounts] = useState([]);

  const width = window.innerWidth;

  function getData() {
    setLoading(true);
    const data = {
      StartDate: dayjs(dateRange.start).add(-1 * envConfig.REACT_APP_TIMEZONE_OFFSET, 'hour').format('YYYY-MM-DDTHH:mm:ss.000'),
      EndDate: dayjs(dateRange.end).add(-1 * envConfig.REACT_APP_TIMEZONE_OFFSET, 'hour').format('YYYY-MM-DDTHH:mm:ss.000'),
      DiscountIds: _.filter(selectedPosDiscounts, (p) => p !== ''),
      Lifetime: isLifeTime
    };
    api
      .post(
        `analytics/reporting/discounts/orders${(business?.id ? `/${business?.id}` : '')}`,
        data
      )
      .then((response) => {
        setLoading(false);
        setReportData(response.data.data);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  }

  function getPosDiscounts() {
    setLoadingPosDiscounts(true);
    api
      .get('pos/discounts')
      .then((response) => {
        setLoadingPosDiscounts(false);
        const mappedDiscounts = _.map(response.data.data, (p, index) => ({
          nameEn: p.nameEn,
          nameEs: p.nameEs,
          id: p.id
        }));
        setPosDiscounts(mappedDiscounts);
      })
      .catch((error) => {
        console.error(error);
        setLoadingPosDiscounts(false);
      });
  }

  useMount(() => {
    getPosDiscounts();
    getData();
  });

  useEffect(() => {
    getData();
  }, [dateRange, business, selectedPosDiscounts, isLifeTime]);

  return (
    <div>
      <PageHeader
        className="mb-4 px-0"
        title={t('sales')}
        tags={
          <Tag
            color="#2db7f5"
            icon={<CheckCircleOutlined />}
          >
            {business ? business.name : t('global')}
          </Tag>
        }
        extra={
          [
            <RangePicker
              className={isLifeTime ? 'hidden' : ''}
              format="YYYY-MM-DD"
              value={[
                dayjs(dateRange?.start),
                dayjs(dateRange?.end)
              ]}
              presets={dateRangePresets(t)}
              onChange={(date, dateString) => {
                setDateRange({
                  start: dateString[0],
                  end: dateString[1]
                });
              }}
            />,
            <div>
              <Select
                mode="multiple"
                listHeight={500}
                loading={loadingPosDiscounts}
                disabled={loadingPosDiscounts}
                style={{ width: 150 }}
                onChange={(value) => {
                  if (value[value.length - 1] === '') {
                    setSelectedPosDiscounts(['']);
                  } else {
                    const check = _.filter(value, (p) => p !== '');
                    if (value.length === 0) {
                      setSelectedPosDiscounts(['']);
                    } else if (check.length > 0) {
                      setSelectedPosDiscounts(check);
                    } else {
                      setSelectedPosDiscounts(value);
                    }
                  }
                }}
                value={selectedPosDiscounts}
              >
                <Option key="all" value="">{t('all_discounts')}</Option>
                {_.map(posDiscounts, (p) => (
                  <Option
                    key={p.id}
                    value={p.id}
                    className="text-xs flex items-center"
                  >
                    {(i18n.language === 'en' ? p.nameEn : p.nameEs)}
                  </Option>
                ))}
              </Select>
            </div>,
            <div
              className="flex space-x-1 border p-1"
            >
              <p>{t('show_lifetime')}</p>
              <Switch
                disabled={loading}
                className="float-right"
                checked={isLifeTime}
                onChange={(checked) => {
                  setIsLifetime(checked);
                }}
              />
            </div>,
            <div className="space-x-2">
              <br className={width < 1024 ? null : 'hidden'} />
              <Button
                size="small"
                type="primary"
                icon={<ReloadOutlined />}
                onClick={() => getData()}
              >
                {t('refresh')}
              </Button>
            </div>
          ]
        }
      />
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-4">
        <Card
          type="inner"
          title={t('orders')}
          className="rounded-xl bg-gradient-to-br from-blue-200 to-white"
          hoverable
          loading={loading}
        >
          <Card.Grid
            className="bg-white"
            hoverable={false}
            style={bottomRowGridStyle}
          >
            <span>{thousand(reportData?.total)}</span>
          </Card.Grid>
        </Card>
        <Card
          type="inner"
          title={t('discounts')}
          className="rounded-xl bg-gradient-to-br from-blue-200 to-white"
          hoverable
          loading={loading}
        >
          <Card.Grid
            className="bg-white"
            hoverable={false}
            style={bottomRowGridStyle}
          >
            <span>{toCurrency(reportData?.totalAmount)}</span>
          </Card.Grid>
        </Card>
        <Card
          type="inner"
          title={t('customers')}
          className="rounded-xl bg-gradient-to-br from-blue-200 to-white"
          hoverable
          loading={loading}
        >
          <Card.Grid
            className="bg-white"
            hoverable={false}
            style={bottomRowGridStyle}
          >
            <span>{thousand(reportData?.totalCustomers)}</span>
          </Card.Grid>
        </Card>
      </div>
      <Table
        size="small"
        loading={loading}
        bordered
        rowKey="id"
        scroll={{ x: 1000 }}
        pagination={false}
        columns={[
          {
            title: t('discount'),
            key: 'discount',
            align: 'center',
            className: 'text-xs',
            render: (row) => <span>{i18n.language === 'en' ? row.nameEn : row.nameEs}</span>,
          },
          {
            title: t('discount_type'),
            align: 'center',
            className: 'text-xs',
            render: (row) => (
              <span>{t(mapPosDiscountType(row.discountType, row.discountValue).name)}</span>
            ),
          },
          {
            title: t('discount_value'),
            align: 'center',
            className: 'text-xs',
            render: (row) => (
              <span>{mapPosDiscountType(row.discountType, row.discountValue).value}</span>
            ),
          },
          {
            title: t('amount'),
            align: 'center',
            className: 'text-xs',
            render: (row) => (
              <span>{toCurrency(row.appliedAmount)}</span>
            ),
          },
          {
            title: t('orders'),
            align: 'center',
            className: 'text-xs',
            render: (row) => (
              <span>{thousand(row.orderCount)}</span>
            ),
          },
          {
            title: t('order_total'),
            align: 'center',
            className: 'text-xs',
            render: (row) => (
              <span>{toCurrency(row.orderTotal)}</span>
            ),
          }
        ]}
        dataSource={reportData?.discounts}
        expandable={{
          expandRowByClick: true,
          expandedRowRender: (record) => (
            <div className="p-4">
              <Table
                size="small"
                bordered
                title={() => (
                  <div className="flex items-center justify-between">
                    <div>
                      <Badge
                        className="site-badge-count-109"
                        count={record.orders.length}
                        style={{ backgroundColor: '#52c41a' }}
                      />
                      <span className="font-light uppercase text-xs ml-2">
                        {t('orders')}
                      </span>
                    </div>
                  </div>
                )}
                pagination={false}
                columns={[
                  {
                    title: t('confirmation_code'),
                    key: 'confirmationcode',
                    align: 'center',
                    className: 'text-xs',
                    render: (row) => <span>{row.confirmationCode}</span>,
                  },
                  {
                    title: t('employee'),
                    key: 'employee',
                    align: 'center',
                    className: 'text-xs',
                    render: (row) => (
                      <div className="space-x-2">
                        <Avatar src={row.employeeAvatar} />
                        <span>{row.employeeName}</span>
                      </div>
                    ),
                  },
                  {
                    title: t('customer'),
                    key: 'employee',
                    align: 'center',
                    className: 'text-xs',
                    render: (row) => (
                      <div className="space-x-2">
                        <Avatar src={row.customerAvatar} />
                        <span>{row.customerName}</span>
                      </div>
                    ),
                  },
                  {
                    title: t('applied_amount'),
                    align: 'center',
                    className: 'text-xs',
                    render: (row) => (
                      <span>{toCurrency(row.amountApplied)}</span>
                    ),
                  },
                  {
                    title: t('order_total'),
                    align: 'center',
                    className: 'text-xs',
                    render: (row) => (
                      <span>{toCurrency(row.orderTotal)}</span>
                    ),
                  }
                ]}
                dataSource={record.orders}
              />
            </div>
          ),
          rowExpandable: (record) => record.orders.length > 0,
        }}
      />
    </div>
  );
}

export default PosDiscountsReport;
