import React from 'react';
import { Select, Button } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useRecoilState, } from 'recoil';
import { dashboardOptionAtom } from '../../../../atoms/Atoms';

function Header() {
  const { t } = useTranslation();
  const { Option } = Select;
  const history = useHistory();
  const [dashboardOption, setDashboardOption] = useRecoilState(dashboardOptionAtom);
  const width = window.innerWidth;

  return (
    <PageHeader
      className="mb-4 px-0"
      title="Global Dashboard (Legacy)"
      extra={
        <div className="flex space-x-2">
          <Select
            defaultValue={0}
            style={{ width: 160 }}
            onChange={(obj) => setDashboardOption(obj)}
          >
            <Option value={0}>{t('insights')}</Option>
            <Option value={1}>{t('week_to_date')}</Option>
            <Option value={2}>{t('month_to_date')}</Option>
            <Option value={3}>{t('year_to_date')}</Option>
            <Option value={4}>{t('trends')}</Option>
            <Option value={5}>{t('lifetime')}</Option>
            <Option value={6}>{t('customers')}</Option>
            <Option value={7} className={width < 1024 ? 'hidden' : null}>{t('prep_inventory')}</Option>
            <Option value={8} className={width < 1024 ? 'hidden' : null}>{t('top_products')}</Option>
          </Select>
          <Button
            type="primary"
            onClick={() => {
              history.push('/dashboard');
            }}
          >
            {t('go_to_new')}
          </Button>
        </div>
      }
    />
  );
}

export default Header;
