import React, { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { Avatar, Tooltip, Image } from 'antd';
import _ from 'lodash';
import {
  selectedTicketBoardAtom,
} from '../../../../atoms/Atoms';

function BoardTeam(props) {
  const [propMembers, setPropMembers] = useState(null);
  const [members, setMembers] = useState([]);
  const [maxCount, setMaxCount] = useState(2);
  const [primaryColor, setPrimaryColor] = useState('#f56a00');
  const [secondaryColor, setSecondaryColor] = useState('#fde3cf');
  const [defaultMaleAvatar, setDefaultMaleAvatar] = useState('');
  const [defaultFemaleAvatar, setDefaultFemaleAvatar] = useState('');
  const selectedBoard = useRecoilValue(selectedTicketBoardAtom);

  const updateMemberList = function (initialList) {
    const temp = JSON.parse(JSON.stringify(initialList));
    for (let i = 0; i < temp.length; i++) {
      temp[i].badge = `${temp[i].firstName[0]}${temp[i].lastName[0]}`;
    }
    setMembers(temp);
  };

  useEffect(() => {
    setPropMembers(props.members);
    setPrimaryColor(props.primaryColor);
    setSecondaryColor(props.secondaryColor);
    if (props.maxCount) {
      setMaxCount(props.maxCount);
    }
    setDefaultMaleAvatar(props.defaultMaleAvatar);
    setDefaultFemaleAvatar(props.defaultFemaleAvatar);
  }, []);

  useEffect(() => {
    if (selectedBoard) {
      updateMemberList(selectedBoard.members);
    }
  }, [selectedBoard]);

  useEffect(() => {
    if (propMembers) {
      updateMemberList(propMembers);
    }
  }, [propMembers]);

  return (
    <Avatar.Group
      maxCount={maxCount}
      maxStyle={{ color: primaryColor, backgroundColor: secondaryColor }}
    >
      {_.map(members, (member, index) => (
        <Tooltip key={`toolmember${index}`} title={member.completeName} placement="top">
          {(!member.avatar ||
            member.avatar === defaultMaleAvatar ||
            member.avatar === defaultFemaleAvatar) && (
              <Avatar key={`avimgmember${index}`} style={{ backgroundColor: primaryColor }}>{member.badge}</Avatar>
          )}
          {!(!member.avatar ||
            member.avatar === defaultMaleAvatar ||
            member.avatar === defaultFemaleAvatar) && (
              <Avatar key={`avmember${index}`} src={<Image src={member.avatar} />} />
          )}
        </Tooltip>
      ))}
    </Avatar.Group>
  );
}

export default BoardTeam;
