import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import { useRecoilState } from 'recoil';
import { notification, Avatar, Input, Button, Typography } from 'antd';
import { Comment } from '@ant-design/compatible';
import { PageHeader } from '@ant-design/pro-layout';
import envConfig from '../../../envConfig';
import { addLogAtom, selectedOrderAtom, reloadOrder, referralEmailAtom } from '../../../atoms/Atoms';
import api from '../../../api/api';
import Grocefy from '../../../assets/images/grocefyLogoAlone.png';

function ReferralEmail() {
  const { t } = useTranslation();
  const [reload, setReload] = useRecoilState(reloadOrder);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');

  const onChangeMessage = function (obj) {
    setMessage(obj.target.value);
  };

  const sendEmail = function () {
    setLoading(true);
    const data = {
      UserId: null,
      Email: message,
      Language: 0
    };
    api
      .post('marketing/referralemail', data)
      .then((response) => {
        if (response && response.data.error) {
          notification.open({
            message: 'Alert!',
            description: (
              <Comment
                author={<span>Grocefy</span>}
                avatar={<Avatar src={Grocefy} alt="grocefy" />}
                content={
                  <p className="text-sm">{response.data.error}</p>
                }
              />
            ),
          });
        } else if (
          response &&
          response.data.success &&
          response.data.message
        ) {
          notification.open({
            message: 'Success!',
            description: (
              <Comment
                author={<span>Grocefy</span>}
                avatar={<Avatar src={Grocefy} alt="grocefy" />}
                content={
                  <p className="text-sm">{response.data.message}</p>
                }
              />
            ),
          });
        }
        setReload(true);
        setLoading(false);
        setMessage('');
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <div className="mt-2 m-4">
      <PageHeader
        title={t('referral_email')}
      />
      <br />
      <div className="p-4 border bg-gray-100 rounded-lg mb-4">
        <Typography.Title level={5}>{t('referral_message')}</Typography.Title>
        <br />
        <Input
          placeholder={t('email')}
          onChange={(obj) => onChangeMessage(obj)}
          value={message}
        />
        <br />
        <br />
        <Button
          key="create"
          disabled={loading}
          loading={loading}
          type="primary"
          size="large"
          style={{ float: 'right' }}
          onClick={() => sendEmail()}
        >
          {t('send')}
        </Button>
        <br />
        <br />
      </div>
    </div>
  );
}

export default ReferralEmail;
