import React, { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import {
  Table,
  Avatar,
  Tooltip,
  notification
} from 'antd';
import { Comment } from '@ant-design/compatible';
import { customerInsightsTabAtom, timezoneAtom } from '../../../../../atoms/Atoms';
import api from '../../../../../api/api';
import Grocefy from '../../../../../assets/images/grocefyLogoAlone.png';

function RecentCustomers() {
  const { t } = useTranslation();
  const timezone = useRecoilValue(timezoneAtom);
  const customerInsightsTab = useRecoilValue(customerInsightsTabAtom);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(20);
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);

  function showMessage(message) {
    notification.open({
      message: '',
      description: (
        <Comment
          author={<span>Grocefy</span>}
          avatar={<Avatar src={Grocefy} alt="grocefy" />}
          content={
            <p className="text-sm">
              {message}
            </p>
          }
          datetime={
            <Tooltip title={moment().format('YYYY-MM-DD HH:mm:ss')}>
              <span>{moment().fromNow()}</span>
            </Tooltip>
          }
        />
      ),
    });
  }

  function getData() {
    setLoading(true);
    api.get(`analytics/insights/recentcustomers/${page}/${size}`)
      .then((response) => {
        setLoading(false);
        if (response.data.success) {
          setTotal(response.data.data.total);
          setUsers(response.data.data.users);
        } else {
          showMessage(response.data.message);
        }
      }).catch((err) => {
        setLoading(false);
        showMessage(err.message);
      });
  }

  async function reload() {
    await setPage(0);
    await setSize(20);
    getData();
  }

  useEffect(async () => {
    if (customerInsightsTab === 3) {
      reload();
    }
  }, [customerInsightsTab]);

  useEffect(async () => {
    getData();
  }, [page, size]);

  return (
    <div>
      <Table
        loading={loading}
        bordered
        scroll={{ x: 1000 }}
        columns={[
          {
            title: t('customer'),
            dataIndex: 'fullName',
            key: 'fullName',
            align: 'left',
            className: 'text-xs',
            render: (text) => <span>{text}</span>,
          },
          {
            title: t('email'),
            dataIndex: 'email',
            key: 'email',
            align: 'left',
            className: 'text-xs',
            render: (text) => <span>{text}</span>,
          },
          {
            title: t('phone'),
            dataIndex: 'phone',
            key: 'phone',
            align: 'left',
            className: 'text-xs',
            render: (text) => <span>{text}</span>,
          },
          {
            title: t('order_count'),
            dataIndex: 'orders',
            key: 'orders',
            align: 'center',
            className: 'text-xs',
            render: (text) => <span>{text.toLocaleString()}</span>,
          },
          {
            title: t('total_spend'),
            dataIndex: 'totalSpend',
            key: 'totalSpend',
            align: 'center',
            className: 'text-xs',
            render: (text) => <span>${text.toLocaleString()}</span>,
          },
          {
            title: t('first_purchase_date'),
            dataIndex: 'firstPurchaseDate',
            key: 'firstPurchaseDate',
            align: 'left',
            className: 'text-xs',
            render: (text) =>
              <span>
                {moment(`${text}+0000`)
                  .tz(timezone)
                  .format('MMM Do, YYYY')}
              </span>,
          },
          {
            title: t('last_purchase_date'),
            dataIndex: 'lastPurchaseDate',
            key: 'lastPurchaseDate',
            align: 'left',
            className: 'text-xs',
            render: (text) =>
              <span>
                {moment(`${text}+0000`)
                  .tz(timezone)
                  .format('MMM Do, YYYY')}
              </span>,
          },
        ]}
        dataSource={users}
        pagination={{
          pageSize: size,
          showSizeChanger: true,
          defaultCurrent: 0,
          current: page + 1,
          total,
          onChange: async (p, pageSize) => {
            if (p - 1 !== page) {
              await setPage(p - 1);
              getData();
            } else {
              setSize(pageSize);
            }
          }
        }}
      />
    </div>
  );
}

export default RecentCustomers;
