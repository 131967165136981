import React, { useEffect, useState } from 'react';
import {
  useRecoilState,
  useRecoilValue,
} from 'recoil';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import {
  Drawer,
  Typography,
  DatePicker,
  Avatar,
  Tooltip,
  Button,
  Switch,
  Divider,
  Row,
  Select,
  InputNumber,
  Input,
  notification,
  Collapse,
  Col
} from 'antd';
import { Comment } from '@ant-design/compatible';
import moment from 'moment';
import Grocefy from '../../../../assets/images/grocefyLogoAlone.png';
import {
  setSelectedBusinessItemAtom,
  showEditItemPriceAtom,
  selectedItemPriceAtom,
} from '../../../../atoms/Atoms';
import api from '../../../../api/api';

function EditItemPriceDrawer() {
  const { RangePicker } = DatePicker;
  const { Title } = Typography;
  const { Panel } = Collapse;
  const { Option } = Select;
  const [selectedBusinessItem, setSelectedBusinessItem] =
    useRecoilState(setSelectedBusinessItemAtom);
  const [showEditItemPrice, setShowEditItemPrice] = useRecoilState(showEditItemPriceAtom);
  const [selectedItemPrice, setSelectedItemPrice] = useRecoilState(selectedItemPriceAtom);
  const [itemPrice, setItemPrice] = useState(null);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const showMessage = function (message) {
    notification.open({
      message: '',
      description: (
        <Comment
          author={<span>Grocefy</span>}
          avatar={<Avatar src={Grocefy} alt="grocefy" />}
          content={
            <p className="text-sm">
              {message}
            </p>
          }
          datetime={
            <Tooltip title={moment().format('YYYY-MM-DD HH:mm:ss')}>
              <span>{moment().fromNow()}</span>
            </Tooltip>
          }
        />
      ),
    });
  };

  const archivePrice = function () {
    setLoading(true);
    const tempData = JSON.parse(JSON.stringify(itemPrice));
    tempData.isDeleted = true;
    tempData.isActive = false;
    api
      .post(
        `businesses/catalog/price/${tempData.id}/update`,
        tempData
      )
      .then((response) => {
        setLoading(false);
        if (!response.data.success) {
          showMessage(response.data.error);
        } else {
          const tempBusinessItem = JSON.parse(JSON.stringify(selectedBusinessItem));
          tempBusinessItem.prices = response.data.data.prices;
          setSelectedBusinessItem(tempBusinessItem);
          setSelectedItemPrice(null);
          setShowEditItemPrice(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        showMessage(error);
      });
  };

  const updatePriceClick = function () {
    setLoading(true);
    const tempData = JSON.parse(JSON.stringify(itemPrice));
    if (tempData.promoStartDate && tempData.promoStartDate.length > 0) {
      tempData.promoStartDate = moment(tempData.promoStartDate).utc();
      tempData.promoEndDate = moment(tempData.promoEndDate).utc();
    }
    if (tempData.promoStartDate && tempData.promoStartDate.length > 0 &&
        tempData.promoStartDate.length < 17) {
      tempData.promoStartDate = `${tempData.promoStartDate.replace(' ', 'T')}:00.000`;
      tempData.promoEndDate = `${tempData.promoEndDate.replace(' ', 'T')}:00.000`;
    }
    const url = tempData.id ? `catalog/price/${tempData.id}/update` :
      `catalog/price/${selectedBusinessItem.id}/new`;
    api
      .post(
        `businesses/${url}`,
        tempData
      )
      .then((response) => {
        setLoading(false);
        if (!response.data.success) {
          showMessage(response.data.error);
        } else {
          const tempBusinessItem = JSON.parse(JSON.stringify(selectedBusinessItem));
          tempBusinessItem.prices = response.data.data.prices;
          setSelectedBusinessItem(tempBusinessItem);
          setSelectedItemPrice(null);
          setShowEditItemPrice(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        showMessage(error);
      });
  };

  useEffect(() => {
    if (selectedItemPrice) {
      const temp = JSON.parse(JSON.stringify(selectedItemPrice));
      if (!temp.id) {
        temp.taxRule = 0;
        temp.cost = 1;
        temp.pack = 1;
        temp.basePrice = 0;
        temp.promoPrice = 0;
        temp.basePriceMethod = 0;
        temp.promoPriceMethod = 0;
        temp.baseMultiple = 1;
        temp.ordering = 99;
        temp.promoMultiple = 1;
      }
      setItemPrice(temp);
    }
  }, [selectedItemPrice]);

  return (
    <Drawer
      title={t('edit_item_price')}
      placement="right"
      closable
      onClose={() => setShowEditItemPrice(false)}
      open={showEditItemPrice}
      width={400}
    >
      {itemPrice && (
        <>
          <div
            style={{ marginBottom: '10px' }}
          >
            <strong>{t('active')}?</strong>
            <Switch
              disabled={loading}
              style={{ float: 'right' }}
              checked={itemPrice.isActive}
              onChange={(checked) => {
                const temp = JSON.parse(JSON.stringify(itemPrice));
                temp.isActive = checked;
                setItemPrice(temp);
              }}
            />
          </div>
          <div
            style={{ marginBottom: '10px' }}
          >
            <strong>{t('name')}</strong>
            <Input
              disabled={loading}
              placeholder="Name"
              onChange={(obj) => {
                const temp = JSON.parse(JSON.stringify(itemPrice));
                temp.name = obj.target.value;
                setItemPrice(temp);
              }}
              value={itemPrice.name}
            />
          </div>
          <div
            style={{ marginBottom: '10px' }}
          >
            <strong>{t('description')}</strong>
            <Input
              disabled={loading}
              placeholder="Description"
              onChange={(obj) => {
                const temp = JSON.parse(JSON.stringify(itemPrice));
                temp.description = obj.target.value;
                setItemPrice(temp);
              }}
              value={itemPrice.description}
            />
          </div>
          <div
            style={{ marginBottom: '10px' }}
          >
            <strong>{t('ordering')}</strong>
            <br />
            <InputNumber
              disabled={loading}
              min={0}
              onChange={(obj) => {
                const temp = JSON.parse(JSON.stringify(itemPrice));
                temp.ordering = obj;
                setItemPrice(temp);
              }}
              value={itemPrice.ordering}
            />
          </div>
          <Divider />
          <Title level={4}>
            {t('base_price')}
          </Title>
          <Row>
            <Col
              style={{
                marginRight: '5px'
              }}
            >
              <div>
                <strong>{t('price')}</strong>
                <br />
                <InputNumber
                  disabled={loading}
                  min={0}
                  onChange={(obj) => {
                    const temp = JSON.parse(JSON.stringify(itemPrice));
                    temp.basePrice = obj;
                    setItemPrice(temp);
                  }}
                  value={itemPrice.basePrice}
                />
              </div>
            </Col>
            <Col
              style={{
                marginLeft: '5px'
              }}
            >
              <div>
                <strong>{t('multiple')}</strong>
                <br />
                <InputNumber
                  disabled={loading}
                  min={1}
                  onChange={(obj) => {
                    const temp = JSON.parse(JSON.stringify(itemPrice));
                    temp.baseMultiple = obj;
                    setItemPrice(temp);
                  }}
                  value={itemPrice.baseMultiple}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col
              style={{
                marginRight: '5px'
              }}
            >
              <div>
                <strong>{t('cost')}</strong>
                <br />
                <InputNumber
                  disabled={loading}
                  min={0}
                  onChange={(obj) => {
                    const temp = JSON.parse(JSON.stringify(itemPrice));
                    temp.cost = obj;
                    setItemPrice(temp);
                  }}
                  value={itemPrice.cost}
                />
              </div>
            </Col>
            <Col
              style={{
                marginLeft: '5px'
              }}
            >
              <div>
                <strong>{t('pack')}</strong>
                <br />
                <InputNumber
                  disabled={loading}
                  min={1}
                  onChange={(obj) => {
                    const temp = JSON.parse(JSON.stringify(itemPrice));
                    temp.pack = obj;
                    setItemPrice(temp);
                  }}
                  value={itemPrice.pack}
                />
              </div>
            </Col>
          </Row>
          <div
            style={{ marginBottom: '10px' }}
          >
            <strong>{t('price_method')}</strong>
            <br />
            <Select
              defaultValue={itemPrice.basePriceMethod}
              style={{ width: '100%' }}
              disabled={loading}
              onChange={(value) => {
                const temp = JSON.parse(JSON.stringify(itemPrice));
                temp.basePriceMethod = value;
                setItemPrice(temp);
              }}
            >
              <Option
                key="baseunit"
                value={0}
              >
                {t('unit')}
              </Option>
              <Option
                key="basesplit"
                value={1}
              >
                {t('split')}
              </Option>
            </Select>
          </div>
          <div
            style={{ marginBottom: '10px' }}
          >
            <strong>{t('tax_rule')}</strong>
            <br />
            <Select
              defaultValue={itemPrice.taxRule}
              style={{ width: '100%' }}
              disabled={loading}
              onChange={(value) => {
                const temp = JSON.parse(JSON.stringify(itemPrice));
                temp.taxRule = value;
                setItemPrice(temp);
              }}
            >
              <Option
                key="taxnone"
                value={0}
              >
                {t('none')}
              </Option>
              <Option
                key="taxmun"
                value={1}
              >
                {t('municipal')}
              </Option>
              <Option
                key="taxstate"
                value={2}
              >
                {t('state')}
              </Option>
              <Option
                key="taxboth"
                value={3}
              >
                {t('both')}
              </Option>
            </Select>
          </div>
          <Divider />
          <Title level={4}>
            {t('promo_price')}
          </Title>
          <Row>
            <Col
              style={{
                marginRight: '5px'
              }}
            >
              <div>
                <strong>{t('price')}</strong>
                <br />
                <InputNumber
                  disabled={loading}
                  min={0}
                  onChange={(obj) => {
                    const temp = JSON.parse(JSON.stringify(itemPrice));
                    temp.promoPrice = obj;
                    setItemPrice(temp);
                  }}
                  value={itemPrice.promoPrice}
                />
              </div>
            </Col>
            <Col
              style={{
                marginLeft: '5px'
              }}
            >
              <div>
                <strong>{t('multiple')}</strong>
                <br />
                <InputNumber
                  disabled={loading}
                  min={1}
                  onChange={(obj) => {
                    const temp = JSON.parse(JSON.stringify(itemPrice));
                    temp.promoMultiple = obj;
                    setItemPrice(temp);
                  }}
                  value={itemPrice.promoMultiple}
                />
              </div>
            </Col>
          </Row>
          <div
            style={{ marginBottom: '10px' }}
          >
            <strong>{t('price_method')}</strong>
            <br />
            <Select
              defaultValue={itemPrice.promoPriceMethod}
              style={{ width: '100%' }}
              disabled={loading}
              onChange={(value) => {
                const temp = JSON.parse(JSON.stringify(itemPrice));
                temp.promoPriceMethod = value;
                setItemPrice(temp);
              }}
            >
              <Option
                key="promounit"
                value={0}
              >
                {t('unit')}
              </Option>
              <Option
                key="promosplit"
                value={1}
              >
                {t('split')}
              </Option>
            </Select>
          </div>
          <div style={{ marginBottom: 10 }}>
            <strong>{t('start_date')} {t('end_date')}</strong>
            <RangePicker
              showTime={{ format: 'HH:mm' }}
              format="YYYY-MM-DD HH:mm"
              defaultValue={
                itemPrice.promoStartDate
                  ? [dayjs(itemPrice.promoStartDate, 'YYYY-MM-DD HH:mm'),
                    dayjs(itemPrice.promoEndDate, 'YYYY-MM-DD HH:mm')
                  ] : null
              }
              onChange={(date, dateString) => {
                const temp = JSON.parse(JSON.stringify(itemPrice));
                const start = dateString[0];
                const end = dateString[1];
                temp.promoStartDate = start;
                temp.promoEndDate = end;
                setItemPrice(temp);
              }}
            />
          </div>
          {itemPrice.id && (
            <Button
              style={{
                float: 'right',
                margin: '10px'
              }}
              type="primary"
              danger
              loading={loading}
              onClick={() => {
                archivePrice();
              }}
            >
              {t('archive')}
            </Button>
          )}
          <Button
            type="primary"
            loading={loading}
            onClick={() => { updatePriceClick(); }}
            style={{
              float: 'right',
              marginTop: '10px'
            }}
          >
            {!itemPrice.id ? t('create') : t('update')}
          </Button>
        </>
      )}
    </Drawer>
  );
}

export default EditItemPriceDrawer;
