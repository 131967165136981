import React, { useState, useEffect } from 'react';
import moment from 'moment';
import dayjs from 'dayjs';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { LinkOutlined } from '@ant-design/icons';
import {
  Drawer,
  DatePicker,
  TimePicker,
  Avatar,
  notification,
  Tooltip,
  Button,
  Table,
  Divider,
  Input,
  Menu,
  InputNumber,
  Popconfirm,
  Modal,
  Descriptions,
  Tabs,
  Switch,
  Dropdown
} from 'antd';
import { Comment } from '@ant-design/compatible';
import DescriptionsItem from 'antd/lib/descriptions/Item';
import { useRecoilState, useSetRecoilState, } from 'recoil';
import { faClone, faGenderless, faInfoCircle, faPencilAlt, faTrash, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  selectedEventForTimeSlotsAtom,
  selectedEventTimeSlotAtom
} from '../../../../../atoms/Atoms';
import Grocefy from '../../../../../assets/images/grocefyLogoAlone.png';
import bookingApi from '../../../../../api/bookingApi';
import api from '../../../../../api/api';
import envConfig from '../../../../../envConfig';
import { convertMinutesToDuration } from '../../../../utils/functions';

function EditEventTimeSlots() {
  const { t } = useTranslation();
  const { RangePicker } = DatePicker;
  const setSelectedTimeSlot = useSetRecoilState(selectedEventTimeSlotAtom);
  const [event, setEvent] = useRecoilState(selectedEventForTimeSlotsAtom);
  const [loading, setLoading] = useState(false);
  const [maxCapacity, setMaxCapacity] = useState(6);
  const [dateRange, setDateRange] = useState(null);
  const [advancedTimeSlotData, setAdvancedTimeSlotData] = useState(null);
  const [previewTimeSlots, setPreviewTimeSlots] = useState(false);
  const [timeSlotsToCreate, setTimeSlotsToCreate] = useState([]);
  const [timeSlots, setTimeSlots] = useState([]);
  const [conflictingEvents, setConflictingEvents] = useState([]);
  const [tabs, setTabs] = useState([]);
  const [showTimeSlotUpdate, setShowTimeSlotUpdate] = useState(false);
  const [timeSlotToUpdate, setTimeSlotToUpdate] = useState(null);
  const [createActive, setCreateActive] = useState(true);
  const width = window.innerWidth;
  const { TabPane } = Tabs;
  const [activeTab, setActiveTab] = useState('tab1');
  const [users, setUsers] = useState([]);
  const [searching, setSearching] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [selectedUser, setSelectedUser] = useState(null);

  const handleTabChange = (activeKey) => {
    setActiveTab(activeKey);
  };

  function showMessage(message) {
    notification.open({
      message: '',
      description: (
        <Comment
          author={<span>Grocefy</span>}
          avatar={<Avatar src={Grocefy} alt="grocefy" />}
          content={
            <p className="text-sm">
              {message}
            </p>
          }
          datetime={
            <Tooltip title={moment().format('YYYY-MM-DD HH:mm:ss')}>
              <span>{moment().fromNow()}</span>
            </Tooltip>
          }
        />
      ),
    });
  }

  function generateTimeslotList() {
    if (advancedTimeSlotData?.startDate && advancedTimeSlotData?.endDate && advancedTimeSlotData?.timeslotMinutes > 0) {
      const slots = [];
      const days = advancedTimeSlotData.endDate.startOf('day').diff(advancedTimeSlotData.startDate.startOf('day'), 'days');

      for (let i = 0; i <= days; i++) {
        let current = advancedTimeSlotData.startDate.startOf('day')
          .add(advancedTimeSlotData?.startTime.hour(), 'hour')
          .add(advancedTimeSlotData?.startTime.minute(), 'minute')
          .add(i, 'day');

        const end = advancedTimeSlotData.startDate.startOf('day')
          .add(advancedTimeSlotData?.endTime.hour(), 'hour')
          .add(advancedTimeSlotData?.endTime.minute(), 'minute')
          .add(i, 'day');

        while (current < end) {
          const newSlot = {
            start: current,
            end: current.add(advancedTimeSlotData.timeslotMinutes, 'minute'),
            capacity: advancedTimeSlotData.capacity
          };
          slots.push(newSlot);
          current = current.add(advancedTimeSlotData.timeslotMinutes + advancedTimeSlotData.timeslotBuffer, 'minute');
        }
      }
      setTimeSlotsToCreate(slots);
    }
  }

  function mapData(data) {
    const mapped = _.map(data, (p) => ({
      ...p,
      key: p.id,
      duplicate: (_.filter(data, (o) => o.startTime === p.startTime)).length > 1,
    }));
    return mapped;
  }

  function getTimeSlots() {
    setLoading(true);
    bookingApi
      .get(`events/timeSlots/${event?.id}`)
      .then((response) => {
        setLoading(false);
        if (!response.data.success) {
          showMessage(response.data.error);
        } else {
          const orderedTimeSlots = _.sortBy(response.data.data, 'startTime');
          setTimeSlots(mapData(orderedTimeSlots));
        }
      })
      .catch((error) => {
        setLoading(false);
        showMessage(error.message);
      });
  }

  function createAdvancedTimeSlots(slots) {
    const tempSlots = JSON.parse(JSON.stringify(slots));
    const temp = tempSlots.shift();
    const newSlots = JSON.parse(JSON.stringify(tempSlots));
    setLoading(true);
    bookingApi
      .post('events/timeSlots', {
        StartTime: moment(temp.start).add(-1 * envConfig.REACT_APP_TIMEZONE_OFFSET, 'hours').format('YYYY-MM-DDTHH:mm:00.000'),
        EndTime: moment(temp.end).add(-1 * envConfig.REACT_APP_TIMEZONE_OFFSET, 'hours').format('YYYY-MM-DDTHH:mm:00.000'),
        MaxCapacity: advancedTimeSlotData?.capacity,
        EventId: event.id,
        IsActive: advancedTimeSlotData?.createActive,
        Force: true,
        TimeSlotHostId: advancedTimeSlotData?.selectedHost?.id
      })
      .then((response) => {
        if (!response.data.success) {
          setLoading(false);
          showMessage(response.data.error);
        } else if (response.data?.data?.length > 0) {
          setLoading(false);
          setConflictingEvents(response.data?.data);
        } else if (newSlots.length > 0) {
          createAdvancedTimeSlots(newSlots);
        } else {
          setLoading(false);
          getTimeSlots();
          setPreviewTimeSlots(false);
          setAdvancedTimeSlotData({
            ...advancedTimeSlotData,
            selectedHost: null
          });
        }
      })
      .catch((error) => {
        setLoading(false);
        showMessage(error.message);
      });
  }

  function createTimeSlot(force) {
    setLoading(true);
    bookingApi
      .post('events/timeSlots', {
        StartTime: moment(dateRange.start).add(-1 * envConfig.REACT_APP_TIMEZONE_OFFSET, 'hours').format('YYYY-MM-DDTHH:mm:00.000'),
        EndTime: moment(dateRange.end).add(-1 * envConfig.REACT_APP_TIMEZONE_OFFSET, 'hours').format('YYYY-MM-DDTHH:mm:00.000'),
        MaxCapacity: maxCapacity,
        EventId: event.id,
        IsActive: createActive,
        Force: force,
        TimeSlotHostId: selectedUser?.id
      })
      .then((response) => {
        setLoading(false);
        if (!response.data.success) {
          showMessage(response.data.error);
        } else if (response.data?.data?.length > 0) {
          setConflictingEvents(response.data?.data);
        } else {
          getTimeSlots();
        }
      })
      .catch((error) => {
        setLoading(false);
        showMessage(error.message);
      });
  }

  function updateTimeSlot() {
    setLoading(true);
    bookingApi
      .put('events/timeSlots', {
        StartTime: moment(timeSlotToUpdate.startTime).format('YYYY-MM-DDTHH:mm:00.000'),
        EndTime: moment(timeSlotToUpdate.endTime).format('YYYY-MM-DDTHH:mm:00.000'),
        MaxCapacity: timeSlotToUpdate.maxCapacity,
        Comment: timeSlotToUpdate.comment,
        TimeSlotId: timeSlotToUpdate.id,
        IsActive: timeSlotToUpdate.isActive,
        TimeSlotHostId: timeSlotToUpdate.timeSlotHost?.id
      })
      .then((response) => {
        setLoading(false);
        if (!response.data.success) {
          showMessage(response.data.error);
        } else {
          setTimeSlotToUpdate(null);
          setShowTimeSlotUpdate(false);
          getTimeSlots();
        }
      })
      .catch((error) => {
        setLoading(false);
        showMessage(error.message);
      });
  }

  function removeTimeSlot(r) {
    setLoading(true);
    bookingApi
      .delete(`events/timeSlots/${r.id}`)
      .then((response) => {
        setLoading(false);
        if (!response.data.success) {
          showMessage(response.data.error);
        } else {
          getTimeSlots();
        }
      })
      .catch((error) => {
        setLoading(false);
        showMessage(error.message);
      });
  }

  function cloneTimeSlot(r) {
    setLoading(true);
    bookingApi
      .post('events/timeSlots', {
        StartTime: moment(r.startTime).format('YYYY-MM-DDTHH:mm:00.000'),
        EndTime: moment(r.endTime).format('YYYY-MM-DDTHH:mm:00.000'),
        MaxCapacity: r.maxCapacity,
        EventId: event.id,
        IsActive: r.isActive
      })
      .then((response) => {
        setLoading(false);
        if (!response.data.success) {
          showMessage(response.data.error);
        } else {
          getTimeSlots();
        }
      })
      .catch((error) => {
        setLoading(false);
        showMessage(error.message);
      });
  }

  function search() {
    setSearching(true);
    api
      .post(
        'users/search',
        {
          SearchText: searchText,
        }
      )
      .then((response) => {
        if (response.data.success) {
          setSearching(false);
          setUsers(response.data.data.results);
        } else {
          showMessage(response.data.error);
        }
      })
      .catch((error) => {
        setSearching(false);
        showMessage(error.message);
      });
  }

  useEffect(() => {
    if (event) {
      setDateRange({
        start: moment().startOf('day'),
        end: moment().endOf('day')
      });
      setSelectedUser(null);
      setMaxCapacity(6);
      setAdvancedTimeSlotData({
        ...advancedTimeSlotData,
        startDate: dayjs().startOf('day'),
        endDate: dayjs().startOf('day'),
        capacity: 6,
        timeslotBuffer: 0,
        timeslotMinutes: 60,
        createActive: true,
        startTime: dayjs().startOf('day').add(8, 'hour'),
        endTime: dayjs().startOf('day').add(18, 'hour')
      });
      setTimeSlotsToCreate([]);
      getTimeSlots();
    }
  }, [event]);

  useEffect(() => {
    if (timeSlots) {
      const temp1 = _.filter(timeSlots, (o) => o.isActive && moment(o.startTime).isAfter(moment()));
      const temp2 = _.filter(timeSlots,
        (o) => o.isActive && moment(o.startTime).isBefore(moment()));
      const temp3 =
        _.filter(timeSlots, (o) => !o.isActive && moment(o.startTime).isAfter(moment()));
      const temp4 = _.filter(timeSlots,
        (o) => !o.isActive && moment(o.startTime).isBefore(moment()));
      const tempTabs = [];
      if (temp1.length > 0) {
        tempTabs.push({ key: 'tab1', name: `${t('active_upcoming')} (${temp1.length})`, timeslots: temp1 });
      }
      if (temp2.length > 0) {
        tempTabs.push({ key: 'tab2', name: `${t('active_past')} (${temp2.length})`, timeslots: temp2 });
      }
      if (temp3.length > 0) {
        tempTabs.push({ key: 'tab3', name: `${t('inactive_upcoming')} (${temp3.length})`, timeslots: temp3 });
      }
      if (temp4.length > 0) {
        tempTabs.push({ key: 'tab4', name: `${t('inactive_past')} (${temp4.length})`, timeslots: temp4 });
      }
      setTabs(tempTabs);
      setActiveTab('tab1');
      if (activeTab === 'tab1' && temp1.length === 0) {
        setActiveTab('tab2');
      }
      if (activeTab === 'tab2' && temp2.length === 0) {
        setActiveTab('tab3');
      }
      if (activeTab === 'tab3' && temp3.length === 0) {
        setActiveTab('tab4');
      }
    }
  }, [timeSlots]);

  useEffect(() => {
    generateTimeslotList();
  }, [advancedTimeSlotData]);

  return (
    <>
      <Drawer
        title={event && <div>{`${t('time_slots')} for`} <span className="text-blue-600 underline">{`${event.name}`}</span></div>}
        placement="right"
        closable={!loading}
        onClose={() => setEvent(null)}
        open={event}
        width={width < 1024 ? width : 650}
      >
        <strong className="mb-2 ml-2">{t('AddTimeSlot')}</strong>
        <Tabs>
          <TabPane tab={t('basic')} key="basic">
            <div className="border-xl border border-blue-600 p-4 rounded-lg shadow-lg">
              <strong>{t('date')}</strong>
              <RangePicker
                disabled={loading}
                format="MMM, DD YYYY hh:mm A"
                showTime={{ format: 'hh:mm A' }}
                className="w-full"
                value={[
                  dayjs(dateRange?.start),
                  dayjs(dateRange?.end)
                ]}
                onChange={(date, dateString) => {
                  setDateRange({
                    start: dateString[0],
                    end: dateString[1]
                  });
                }}
              />
              <strong className="mt-4">{t('capacity')}</strong>
              <InputNumber
                className="w-full"
                disabled={loading}
                min={1}
                step="1"
                onChange={(obj) => {
                  setMaxCapacity(obj);
                }}
                value={maxCapacity}
              />
              <div className="mt-4">
                <strong>{t('should_timeslot_be_active')}</strong>
                <Switch
                  disabled={loading}
                  style={{ float: 'right' }}
                  checked={createActive}
                  onChange={(checked) => {
                    setCreateActive(checked);
                  }}
                />
              </div>
              <div className="mt-2 flex">
                <strong>{t('selected_host')}:</strong>
                {selectedUser && (
                  <div className="ml-2 -mt-1 mb-2 space-x-2">
                    <Avatar src={selectedUser?.avatar} />
                    <span>{selectedUser?.completeName}</span>
                    <button
                      type="button"
                      onClick={() => setSelectedUser(null)}
                      className="bg-transparent hover:bg-transparent text-red-400 hover:text-red-600 px-1 py-0 mx-0 mb-0 mt-2"
                    >
                      <FontAwesomeIcon icon={faTimes} className="text-xl" />
                    </button>
                  </div>
                )}
              </div>
              <Dropdown
                trigger="click"
                placement="bottom"
                overlay={
                  <Menu>
                    {_.map(users, (u) => (
                      <Popconfirm
                        title={t('select_user_for_host')}
                        okText={t('yes')}
                        cancelText={t('no')}
                        onConfirm={() => {
                          setSelectedUser(u);
                          setSearchText('');
                          setUsers([]);
                        }}
                        onCancel={() => {
                          setSearchText('');
                          setUsers([]);
                        }}
                      >
                        <Menu.Item
                          key={u.id}
                        >
                          <div className="space-x-2">
                            <Avatar src={u.avatar} />
                            <span>{u.completeName}</span>
                          </div>
                        </Menu.Item>
                      </Popconfirm>
                    ))}
                  </Menu>
                }
              >
                <Input.Search
                  className="w-full"
                  allowClear
                  disabled={loading}
                  value={searchText}
                  placeholder={t('search_customer_placeholder')}
                  onChange={(obj) => {
                    setSearchText(obj.target.value);
                  }}
                  onSearch={() => search()}
                  enterButton={t('search')}
                />
              </Dropdown>
              <Button
                type="primary"
                className="w-full mt-4"
                loading={loading}
                onClick={() => { createTimeSlot(false); }}
              >
                {t('create')}
              </Button>
            </div>
          </TabPane>
          <TabPane tab={t('advanced')} key="advanced">
            <div className="border-xl border border-blue-600 p-4 rounded-lg shadow-lg">
              <strong>{t('date_range')}</strong>
              <RangePicker
                disabled={loading}
                format="MMM,DD YYYY"
                className="w-full"
                value={[
                  dayjs(advancedTimeSlotData?.startDate),
                  dayjs(advancedTimeSlotData?.endDate)
                ]}
                onChange={(date, dateString) => {
                  setAdvancedTimeSlotData({
                    ...advancedTimeSlotData,
                    startDate: dayjs(dateString[0]),
                    endDate: dayjs(dateString[1])
                  });
                }}
              />
              <div className="flex space-x-2">
                <div className="w-full">
                  <strong>{t('start_time')}</strong>
                  <br />
                  <TimePicker
                    format="hh mm A"
                    value={advancedTimeSlotData?.startTime}
                    onChange={(val) => {
                      let temp = advancedTimeSlotData?.startDate.startOf('day').add(val.hour(), 'hour');
                      temp = temp.add(val.minute(), 'minute');
                      setAdvancedTimeSlotData({
                        ...advancedTimeSlotData,
                        startTime: temp
                      });
                    }}
                  />
                </div>
                <div className="w-full">
                  <strong>{t('end_time')}</strong>
                  <br />
                  <TimePicker
                    format="hh mm A"
                    value={advancedTimeSlotData?.endTime}
                    onChange={(val) => {
                      let temp = advancedTimeSlotData?.endDate.startOf('day').add(val.hour(), 'hour');
                      temp = temp.add(val.minute(), 'minute');
                      setAdvancedTimeSlotData({
                        ...advancedTimeSlotData,
                        endTime: temp
                      });
                    }}
                  />
                </div>
              </div>
              <div className="flex space-x-2">
                <div className="w-full">
                  <strong>
                    {t('timeslot_minutes')}
                    <Tooltip title={t('timeslot_minutes_desc')} arrow>
                      <FontAwesomeIcon icon={faInfoCircle} className="ml-1 text-xs" />
                    </Tooltip>
                  </strong>
                  <InputNumber
                    className="w-full"
                    disabled={loading}
                    min={1}
                    step="1"
                    onChange={(obj) => {
                      setAdvancedTimeSlotData({
                        ...advancedTimeSlotData,
                        timeslotMinutes: obj
                      });
                    }}
                    value={advancedTimeSlotData?.timeslotMinutes}
                  />
                </div>
                <div className="w-full">
                  <strong>
                    {t('timeslot_buffer')}
                    <Tooltip title={t('timeslot_buffer_desc')} arrow>
                      <FontAwesomeIcon icon={faInfoCircle} className="ml-1 text-xs" />
                    </Tooltip>
                  </strong>
                  <InputNumber
                    className="w-full"
                    disabled={loading}
                    min={0}
                    step="1"
                    onChange={(obj) => {
                      setAdvancedTimeSlotData({
                        ...advancedTimeSlotData,
                        timeslotBuffer: obj
                      });
                    }}
                    value={advancedTimeSlotData?.timeslotBuffer}
                  />
                </div>
                <div className="w-full">
                  <strong>{t('capacity')}</strong>
                  <InputNumber
                    className="w-full"
                    disabled={loading}
                    min={1}
                    step="1"
                    onChange={(obj) => {
                      setAdvancedTimeSlotData({
                        ...advancedTimeSlotData,
                        capacity: obj
                      });
                    }}
                    value={advancedTimeSlotData?.capacity}
                  />
                </div>
              </div>
              <div className="mt-4">
                <strong>{t('should_timeslot_be_active')}</strong>
                <Switch
                  disabled={loading}
                  style={{ float: 'right' }}
                  checked={advancedTimeSlotData?.createActive}
                  onChange={(checked) => {
                    setAdvancedTimeSlotData({
                      ...advancedTimeSlotData,
                      createActive: checked
                    });
                  }}
                />
              </div>
              <div className="mt-2 flex">
                <strong>{t('selected_host')}:</strong>
                {advancedTimeSlotData?.selectedHost && (
                  <div className="ml-2 -mt-1 mb-2 space-x-2">
                    <Avatar src={advancedTimeSlotData?.selectedHost?.avatar} />
                    <span>{advancedTimeSlotData?.selectedHost?.completeName}</span>
                    <button
                      type="button"
                      onClick={() => setAdvancedTimeSlotData({
                        ...advancedTimeSlotData,
                        selectedHost: null
                      })}
                      className="bg-transparent hover:bg-transparent text-red-400 hover:text-red-600 px-1 py-0 mx-0 mb-0 mt-2"
                    >
                      <FontAwesomeIcon icon={faTimes} className="text-xl" />
                    </button>
                  </div>
                )}
              </div>
              <Dropdown
                trigger="click"
                placement="bottom"
                overlay={
                  <Menu>
                    {_.map(users, (u) => (
                      <Popconfirm
                        title={t('select_user_for_host')}
                        okText={t('yes')}
                        cancelText={t('no')}
                        onConfirm={() => {
                          setSearchText('');
                          setAdvancedTimeSlotData({
                            ...advancedTimeSlotData,
                            selectedHost: u
                          });
                          setUsers([]);
                        }}
                        onCancel={() => {
                          setSearchText('');
                          setUsers([]);
                        }}
                      >
                        <Menu.Item
                          key={u.id}
                        >
                          <div className="space-x-2">
                            <Avatar src={u.avatar} />
                            <span>{u.completeName}</span>
                          </div>
                        </Menu.Item>
                      </Popconfirm>
                    ))}
                  </Menu>
                }
              >
                <Input.Search
                  className="w-full"
                  allowClear
                  disabled={loading}
                  value={searchText}
                  placeholder={t('search_customer_placeholder')}
                  onChange={(obj) => {
                    setSearchText(obj.target.value);
                  }}
                  onSearch={() => search()}
                  enterButton={t('search')}
                />
              </Dropdown>
              <Button
                type="primary"
                className="w-full mt-4"
                loading={loading}
                onClick={() => { setPreviewTimeSlots(true); }}
              >
                {t('preview')} ({timeSlotsToCreate?.length})
              </Button>
            </div>
          </TabPane>
        </Tabs>
        <Divider />
        <div className="flex w-full">
          <span>{`Total Slots: ${timeSlots.length}`}</span>
          <Button
            size="small"
            type="primary"
            className="right ml-auto"
            onClick={() => {
              getTimeSlots();
            }}
          >
            {t('reload')}
          </Button>
        </div>
        <Tabs activeKey={activeTab} onChange={handleTabChange}>
          {_.map(tabs, (tab) => (
            <TabPane tab={tab.name} key={tab.key}>
              <div>
                {/* <span>{`${t('slots')}: ${tab.timeslots.length}`}</span> */}
                <Table
                  className="mt-6"
                  pagination={false}
                  size="small"
                  bordered
                  rowKey="id"
                  scroll={{ x: 500 }}
                  loading={loading}
                  columns={[
                    {
                      title: t('start'),
                      key: 'startdate',
                      align: 'center',
                      dataIndex: 'startTime',
                      className: 'text-xs',
                      width: 80,
                      render: (row) => (
                        <div className="flex flex-col">
                          <span>{moment(row).add(envConfig.REACT_APP_TIMEZONE_OFFSET, 'hours').format('dddd')}</span>
                          <span>{moment(row).add(envConfig.REACT_APP_TIMEZONE_OFFSET, 'hours').format('MMM D')}</span>
                          <span>{moment(row).add(envConfig.REACT_APP_TIMEZONE_OFFSET, 'hours').format('h:mm A')}</span>
                        </div>
                      ),
                    },
                    {
                      title: t('end'),
                      key: 'endDate',
                      align: 'center',
                      dataIndex: 'endTime',
                      className: 'text-xs',
                      width: 80,
                      render: (row) => (
                        <div className="flex flex-col">
                          <span>{moment(row).add(envConfig.REACT_APP_TIMEZONE_OFFSET, 'hours').format('dddd')}</span>
                          <span>{moment(row).add(envConfig.REACT_APP_TIMEZONE_OFFSET, 'hours').format('MMM D')}</span>
                          <span>{moment(row).add(envConfig.REACT_APP_TIMEZONE_OFFSET, 'hours').format('h:mm A')}</span>
                        </div>
                      ),
                    },
                    {
                      title: t('duration'),
                      align: 'center',
                      className: 'text-xs',
                      render: (row) => (
                        <span>{convertMinutesToDuration(moment(row.endTime).diff(row.startTime, 'minutes'))}</span>
                      ),
                    },
                    {
                      title: t('capacity'),
                      key: 'capacity',
                      align: 'center',
                      className: 'text-xs',
                      render: (text, record) => (
                        <div>
                          <div className={`${record.currentCapacity >= record.maxCapacity ? 'text-red-600' : 'text-green-600'}`}>{`${record.currentCapacity > record.maxCapacity ? 'Over Booked' : (record.currentCapacity === record.maxCapacity ? 'Booked' : 'Available')}`}</div>
                          <span>{record.currentCapacity}/{record.maxCapacity}</span>
                        </div>
                      ),
                    },
                    {
                      title: t('duplicate'),
                      key: 'duplicate',
                      align: 'center',
                      className: 'text-xs',
                      render: (text, record) => (
                        <div>
                          <div className={`${record.duplicate ? 'text-red-600 text-semibold' : 'hidden'}`}>{t('yes')}</div>
                        </div>
                      ),
                    },
                    {
                      title: 'DL',
                      key: '',
                      align: 'center',
                      className: 'text-xs',
                      render: (text, record) => (
                        <>
                          <Button
                            size="small"
                            type="primary"
                            onClick={() => {
                              navigator.clipboard.writeText(`${envConfig.BookingEventUrl}/timeslot/${event.id}/${record.startTime}`);
                            }}
                          >
                            <LinkOutlined />
                          </Button>
                        </>
                      ),
                    },
                    {
                      title: '',
                      key: 'edit',
                      align: 'center',
                      className: 'text-xs',
                      width: 155,
                      render: (text, record) => (
                        <>
                          <Button
                            size="small"
                            type="primary"
                            className="w-full"
                            onClick={() => {
                              setSelectedTimeSlot(record);
                            }}
                          >
                            <div>
                              <FontAwesomeIcon icon={faGenderless} className="text-xs mr-2" />
                              {t('bookings')}
                            </div>
                          </Button>
                          <div className="my-2 flex flex-row justify-between w-full">
                            <Button
                              size="small"
                              type="primary"
                              className="ml-2"
                              onClick={() => {
                                setSearchText('');
                                setTimeSlotToUpdate(record);
                                setShowTimeSlotUpdate(true);
                              }}
                            >
                              <FontAwesomeIcon icon={faPencilAlt} className="text-xs" />
                            </Button>
                            <Popconfirm
                              key="remove"
                              title={t('remove_time_slot')}
                              okText={t('yes')}
                              onConfirm={() => removeTimeSlot(record)}
                            >
                              <Button
                                size="small"
                                type="primary"
                                danger
                                className="ml-2"
                              >
                                <div className="">
                                  <FontAwesomeIcon icon={faTrash} className="text-xs" />
                                  {/* {t('remove')} */}
                                </div>
                              </Button>
                            </Popconfirm>
                            <Popconfirm
                              key="clone"
                              title={t('clone_time_slot')}
                              okText={t('yes')}
                              onConfirm={() => cloneTimeSlot(record)}
                            >
                              <Button
                                size="small"
                                type="primary"
                                className="ml-2"
                              >
                                <FontAwesomeIcon icon={faClone} className="text-xs" />
                              </Button>
                            </Popconfirm>
                            {/* <Popover
                              placement="topRight"
                              content={popoverContent}
                              title="Title"
                              trigger="click"
                            >
                              <Button
                                size="small"
                                type="primary"
                                className="ml-2"
                              >
                                <div>
                                  <FontAwesomeIcon icon={faMailBulk} className="text-xs" />
                                </div>
                              </Button>
                            </Popover> */}
                          </div>
                        </>
                      ),
                    },
                  ]}
                  dataSource={tab.timeslots}
                  expandable={{
                    expandRowByClick: false,
                    rowExpandable: (record) => record.comment,
                    expandedRowRender: (record) => (
                      <Descriptions bordered column={2}>
                        <DescriptionsItem label={t('comments')} span={2}>
                          <span>{record?.comment}</span>
                        </DescriptionsItem>
                      </Descriptions>
                    )
                  }}
                />
              </div>
            </TabPane>
          ))}
        </Tabs>
      </Drawer>
      {showTimeSlotUpdate && (
        <Modal
          title={t('edit_timeslot')}
          open={showTimeSlotUpdate}
          onCancel={() => {
            setTimeSlotToUpdate(null);
            setShowTimeSlotUpdate(false);
          }}
          footer={[
            <Button
              key="close"
              type="primary"
              danger
              loading={loading}
              onClick={() => {
                setTimeSlotToUpdate(null);
                setShowTimeSlotUpdate(false);
              }}
            >
              {t('close')}
            </Button>,
            <Button
              key="ok"
              type="primary"
              loading={loading}
              onClick={() => updateTimeSlot()}
            >
              {t('submit')}
            </Button>
          ]}
        >
          <>
            <Descriptions size="small" bordered column={1}>
              <DescriptionsItem label={t('is_active')} span={1}>
                <Switch
                  disabled={loading}
                  style={{ float: 'right' }}
                  checked={timeSlotToUpdate?.isActive}
                  onChange={(checked) => {
                    setTimeSlotToUpdate({
                      ...timeSlotToUpdate,
                      isActive: checked
                    });
                  }}
                />
              </DescriptionsItem>
              <DescriptionsItem label={t('date')} span={1}>
                <RangePicker
                  disabled={loading}
                  format="MMM,DD YYYY hh:mm A"
                  showTime={{ format: 'hh:mm A' }}
                  className="w-full"
                  value={[
                    dayjs(timeSlotToUpdate?.startTime).add(envConfig.REACT_APP_TIMEZONE_OFFSET, 'hours'),
                    dayjs(timeSlotToUpdate?.endTime).add(envConfig.REACT_APP_TIMEZONE_OFFSET, 'hours')
                  ]}
                  onChange={(date, dateString) => {
                    setTimeSlotToUpdate({
                      ...timeSlotToUpdate,
                      startTime: moment(dateString[0]).add(-1 * envConfig.REACT_APP_TIMEZONE_OFFSET, 'hours'),
                      endTime: moment(dateString[1]).add(-1 * envConfig.REACT_APP_TIMEZONE_OFFSET, 'hours')
                    });
                  }}
                />
              </DescriptionsItem>
              <DescriptionsItem label={t('capacity')} span={1}>
                <InputNumber
                  className="w-full"
                  disabled={loading}
                  min={1}
                  step="1"
                  onChange={(obj) => {
                    setTimeSlotToUpdate({
                      ...timeSlotToUpdate,
                      maxCapacity: obj
                    });
                  }}
                  value={timeSlotToUpdate?.maxCapacity}
                />
              </DescriptionsItem>
              <DescriptionsItem label={t('comments')} span={1}>
                <Input
                  className="w-full"
                  disabled={loading}
                  onChange={(obj) => {
                    setTimeSlotToUpdate({
                      ...timeSlotToUpdate,
                      comment: obj.target.value
                    });
                  }}
                  value={timeSlotToUpdate?.comment}
                />
              </DescriptionsItem>
              <DescriptionsItem label={t('host')} span={1}>
                <div className="mt-2 flex">
                  {timeSlotToUpdate?.timeSlotHost && (
                    <div className="ml-2 -mt-1 mb-2 space-x-2">
                      <Avatar src={timeSlotToUpdate?.timeSlotHost?.avatar} />
                      <span>{timeSlotToUpdate?.timeSlotHost?.completeName}</span>
                      <button
                        type="button"
                        onClick={() => setTimeSlotToUpdate({
                          ...timeSlotToUpdate,
                          timeSlotHost: null
                        })}
                        className="bg-transparent hover:bg-transparent text-red-400 hover:text-red-600 px-1 py-0 mx-0 mb-0 mt-2"
                      >
                        <FontAwesomeIcon icon={faTimes} className="text-xl" />
                      </button>
                    </div>
                  )}
                </div>
                <Dropdown
                  trigger="click"
                  placement="bottom"
                  overlay={
                    <Menu>
                      {_.map(users, (u) => (
                        <Popconfirm
                          title={t('select_user_for_host')}
                          okText={t('yes')}
                          cancelText={t('no')}
                          onConfirm={() => {
                            setSearchText('');
                            setTimeSlotToUpdate({
                              ...timeSlotToUpdate,
                              timeSlotHost: u
                            });
                            setUsers([]);
                          }}
                          onCancel={() => {
                            setSearchText('');
                            setUsers([]);
                          }}
                        >
                          <Menu.Item
                            key={u.id}
                          >
                            <div className="space-x-2">
                              <Avatar src={u.avatar} />
                              <span>{u.completeName}</span>
                            </div>
                          </Menu.Item>
                        </Popconfirm>
                      ))}
                    </Menu>
                  }
                >
                  <Input.Search
                    className="w-full"
                    allowClear
                    disabled={loading}
                    value={searchText}
                    placeholder={t('search_customer_placeholder')}
                    onChange={(obj) => {
                      setSearchText(obj.target.value);
                    }}
                    onSearch={() => search()}
                    enterButton={t('search')}
                  />
                </Dropdown>
              </DescriptionsItem>
            </Descriptions>
          </>
        </Modal>
      )}
      {conflictingEvents?.length > 0 && (
        <Modal
          title={t('conflicting_events')}
          open={conflictingEvents?.length > 0}
          onCancel={() => {
            setConflictingEvents([]);
          }}
          footer={[
            <Button
              key="close"
              type="primary"
              danger
              loading={loading}
              onClick={() => {
                setConflictingEvents([]);
              }}
            >
              {t('close')}
            </Button>,
            <Button
              key="ok"
              type="primary"
              loading={loading}
              onClick={() => createTimeSlot(true)}
            >
              {t('create')}
            </Button>
          ]}
        >
          <>
            <strong>{t('following_events_conflict_with_timeslot')}</strong>
            <Table
              size="small"
              bordered
              dataSource={conflictingEvents}
              columns={[
                {
                  title: t('event'),
                  align: 'center',
                  className: 'text-xs',
                  render: (row) => (
                    <span>{row.eventName}</span>
                  ),
                },
                {
                  title: t('start'),
                  key: 'startdate',
                  align: 'center',
                  dataIndex: 'startTime',
                  className: 'text-xs',
                  width: 80,
                  render: (row) => (
                    <div className="flex flex-col">
                      <span>{moment(row).add(envConfig.REACT_APP_TIMEZONE_OFFSET, 'hours').format('dddd')}</span>
                      <span>{moment(row).add(envConfig.REACT_APP_TIMEZONE_OFFSET, 'hours').format('MMM D')}</span>
                      <span>{moment(row).add(envConfig.REACT_APP_TIMEZONE_OFFSET, 'hours').format('h:mm A')}</span>
                    </div>
                  ),
                },
                {
                  title: t('end'),
                  key: 'endDate',
                  align: 'center',
                  dataIndex: 'endTime',
                  className: 'text-xs',
                  width: 80,
                  render: (row) => (
                    <div className="flex flex-col">
                      <span>{moment(row).add(envConfig.REACT_APP_TIMEZONE_OFFSET, 'hours').format('dddd')}</span>
                      <span>{moment(row).add(envConfig.REACT_APP_TIMEZONE_OFFSET, 'hours').format('MMM D')}</span>
                      <span>{moment(row).add(envConfig.REACT_APP_TIMEZONE_OFFSET, 'hours').format('h:mm A')}</span>
                    </div>
                  ),
                },
                {
                  title: t('duration'),
                  align: 'center',
                  className: 'text-xs',
                  render: (row) => (
                    <span>{convertMinutesToDuration(moment(row.endTime).diff(row.startTime, 'minutes'))}</span>
                  ),
                }
              ]}
            />
          </>
        </Modal>
      )}
      <Modal
        title={t('timeslots_to_create')}
        open={previewTimeSlots}
        onCancel={() => setPreviewTimeSlots(false)}
        footer={[
          <Button
            key="closepreview"
            type="primary"
            danger
            loading={loading}
            onClick={() => setPreviewTimeSlots(false)}
          >
            {t('close')}
          </Button>,
          <Button
            key="okpreview"
            type="primary"
            loading={loading}
            onClick={() => createAdvancedTimeSlots(timeSlotsToCreate)}
          >
            {t('create')}
          </Button>
        ]}
      >
        <Table
          columns={[
            {
              title: t('start'),
              key: 'startdate',
              align: 'center',
              dataIndex: 'start',
              className: 'text-xs',
              width: 80,
              render: (row) => (
                <div className="flex flex-col">
                  <span>{row.format('dddd')}</span>
                  <span>{row.format('MMM D')}</span>
                  <span>{row.format('h:mm A')}</span>
                </div>
              ),
            },
            {
              title: t('end'),
              key: 'endDate',
              align: 'center',
              dataIndex: 'end',
              className: 'text-xs',
              width: 80,
              render: (row) => (
                <div className="flex flex-col">
                  <span>{row.format('dddd')}</span>
                  <span>{row.format('MMM D')}</span>
                  <span>{row.format('h:mm A')}</span>
                </div>
              ),
            },
            {
              title: t('duration'),
              align: 'center',
              className: 'text-xs',
              render: (row) => (
                <span>{convertMinutesToDuration(row.end.diff(row.start, 'minutes'))}</span>
              ),
            }
          ]}
          dataSource={timeSlotsToCreate}
        />
      </Modal>
    </>
  );
}
export default EditEventTimeSlots;
