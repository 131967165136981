import React, { useEffect, useState } from 'react';
import {
  useRecoilValueLoadable,
  useSetRecoilState,
  useRecoilState
} from 'recoil';
import { useTranslation } from 'react-i18next';
import {
  Table,
  Input,
  notification,
  Tooltip,
  Avatar,
  Button
} from 'antd';
import { Comment } from '@ant-design/compatible';
import { PageHeader } from '@ant-design/pro-layout';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import Grocefy from '../../../assets/images/grocefyLogoAlone.png';
import {
  impersonationAtom,
  tokenAtom,
  profileAtom,
  stateAtom
} from '../../../atoms/Atoms';
import envConfig from '../../../envConfig';
import api from '../../../api/api';

function UserImpersonation() {
  const setProfile = useSetRecoilState(profileAtom);
  const setState = useSetRecoilState(stateAtom);
  const [token, setToken] = useRecoilState(tokenAtom);
  const setImpersonation = useSetRecoilState(impersonationAtom);
  const [isSearching, setIsSearching] = useState(false);
  const [users, setUsers] = useState([]);
  const profile = useRecoilValueLoadable(profileAtom);
  const isAdmin =
    profile.contents && profile?.contents?.roles?.includes('SuperAdmin');
  const history = useHistory();
  const { t } = useTranslation();

  if (!isAdmin) {
    history.push('/');
    return null;
  }

  const showMessage = function (message) {
    notification.open({
      message: '',
      description: (
        <Comment
          author={<span>Grocefy</span>}
          avatar={<Avatar src={Grocefy} alt="grocefy" />}
          content={
            <p className="text-sm">
              {message}
            </p>
          }
          datetime={
            <Tooltip title={moment().format('YYYY-MM-DD HH:mm:ss')}>
              <span>{moment().fromNow()}</span>
            </Tooltip>
          }
        />
      ),
    });
  };

  const onSearch = (value) => {
    setUsers([]);
    setIsSearching(true);
    api.post(
      'users/search',
      {
        SearchText: `${value}`,
      }
    )
      .then((response) => {
        setUsers(response.data.data.results);
        setIsSearching(false);
      })
      .catch(() => {
        setIsSearching(false);
      });
  };

  const impersonateUser = (user) => {
    setIsSearching(true);
    api.post(
      'oauth/impersonate',
      {
        UserId: user.id,
      }
    )
      .then((response) => {
        setIsSearching(false);
        if (response.data.success) {
          const oldToken = localStorage.getItem(
            `${envConfig.REACT_APP_PREFIX}-admin-token`
          );
          const oldState = localStorage.getItem(
            `${envConfig.REACT_APP_PREFIX}-admin-currentstate`
          );
          const oldProfile = localStorage.getItem(
            `${envConfig.REACT_APP_PREFIX}-admin-profile`
          );
          const imper = {
            state: JSON.parse(oldState),
            profile: JSON.parse(oldProfile),
            accessToken: oldToken
          };
          localStorage.setItem(
            `${envConfig.REACT_APP_PREFIX}-admin-token`,
            response.data.data.accessToken
          );
          localStorage.setItem(
            `${envConfig.REACT_APP_PREFIX}-admin-currentstate`,
            JSON.stringify(response.data.data.state)
          );
          localStorage.setItem(
            `${envConfig.REACT_APP_PREFIX}-admin-profile`,
            JSON.stringify(response.data.data.state.profile)
          );
          localStorage.setItem(
            `${envConfig.REACT_APP_PREFIX}-admin-imper`,
            JSON.stringify(imper)
          );
          setImpersonation(imper);
          setToken(response.data.data.accessToken);
          setProfile(response.data.data.state.profile);
          setState(response.data.data.state);
        } else {
          showMessage(response.error);
        }
      })
      .catch(() => {
      });
  };

  useEffect(() => {
    setIsSearching(false);
    setUsers([]);
  }, []);

  return (
    <div>
      <PageHeader
        title={t('user_impersonation')}
      />
      <Input.Search
        placeholder={t('search_user')}
        allowClear
        loading={isSearching}
        enterButton={t('search')}
        onSearch={onSearch}
      />
      <Table
        bordered
        loading={isSearching}
        dataSource={users}
        rowKey="id"
        columns={[
          {
            title: t('name'),
            align: 'center',
            className: 'text-xs',
            render: (row) => (
              <span>{row.completeName}</span>
            ),
          },
          {
            title: t('email'),
            dataIndex: 'email',
            align: 'center',
            className: 'text-xs',
            render: (text) => (
              <span>{text}</span>
            ),
          },
          {
            title: '',
            align: 'center',
            className: 'text-xs',
            render: (row) => (
              <div>
                <Button
                  size="small"
                  type="primary"
                  onClick={() => {
                    impersonateUser(row);
                  }}
                >
                  {t('select')}
                </Button>
              </div>
            ),
          }
        ]}
      />
    </div>
  );
}

export default UserImpersonation;
