import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import _ from 'lodash';
import {
  Button,
  Avatar,
  Image,
  notification,
  Modal,
  Tooltip,
  Descriptions
} from 'antd';
import { Comment } from '@ant-design/compatible';
import { PageHeader } from '@ant-design/pro-layout';
import DescriptionsItem from 'antd/lib/descriptions/Item';
import api from '../../../api/api';
import Form from '../../shared-components/form/Form';
import Input from '../../shared-components/form/Input';
import Select from '../../shared-components/form/Select';
import { H3 } from '../../shared-components/typography/Title';
import Subtitle from '../../shared-components/typography/Subtitle';
import Panel from '../../shared-components/panel/Panel';
import { profileAtom, tokenAtom } from '../../../atoms/Atoms';
import Grocefy from '../../../assets/images/grocefyLogoAlone.png';

function Profile() {
  const { i18n, t } = useTranslation();
  const [user, setUser] = useRecoilState(profileAtom);
  const token = useRecoilValue(tokenAtom);
  const [loading, setLoading] = useState(false);
  const [showPinReset, setShowPinReset] = useState(false);
  const [pin, setPin] = useState('');

  function showMessage(title, message) {
    notification.open({
      message: title,
      description: (
        <Comment
          author={<span>Grocefy</span>}
          avatar={<Avatar src={Grocefy} alt="grocefy" />}
          content={
            <p className="text-sm">
              {message}
            </p>
          }
          datetime={
            <Tooltip title={moment().format('YYYY-MM-DD HH:mm:ss')}>
              <span>{moment().fromNow()}</span>
            </Tooltip>
          }
        />
      ),
    });
  }

  const formik = useFormik({
    initialValues: {
      fname: user ? user.firstName : '',
      lname: user ? user.lastName : '',
      email: user ? user.email : '',
      phone: user ? user.phoneNumber : '',
      gender: user ? user.gender : '',
      language: user ? user.preferredLanguage : '',
      birthdate: user ? user.birthdate : '',
    },
    validationSchema: Yup.object().shape({
      fname: Yup.string().required('Este campo es requerido.'),
      lname: Yup.string().required('Este campo es requerido.'),
      email: Yup.string()
        .email('Favor de ingresar un email valido.')
        .required('Este campo es requerido.'),
      phone: Yup.string().required('Este campo es requerido.'),
    }),
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true);

      const json = {
        Email: values.email,
        FirstName: values.fname,
        LastName: values.lname,
        PhoneNumber: values.phone,
        Password: values.password,
        Birthdate: values.birthdate,
        Gender: parseInt(values.gender, 10),
        PreferredLanguage: parseInt(values.language, 10),
        AllowPush: true,
        AllowSms: true,
        AllowEmails: true,
      };
      api
        .post(`cs/update/user/${user.id}`, json, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          if (response.data.error) {
            showMessage(t('alertexclaim'), response.data.error);
          } else {
            const profile = JSON.parse(localStorage.getItem('lhf-user'));
            profile.firstName = response.data.data.firstName;
            profile.lastName = response.data.data.lastName;
            profile.email = response.data.data.email;
            profile.phoneNumber = response.data.data.phoneNumber;
            profile.gender = response.data.data.gender;
            profile.birthdate = response.data.data.birthdate;
            profile.preferredLanguage = response.data.data.preferredLanguage;
            profile.completeName = `${response.data.data.firstName} ${response.data.data.lastName}`;
            localStorage.setItem('lhf-user', JSON.stringify(profile));
            setUser(profile);
            showMessage(t('successexclaim'), response.data.message);
          }
          setSubmitting(false);
        })
        .catch((error) => {
          showMessage(t('error_profile_update'), error.message);
          setSubmitting(false);
        });
    },
  });

  const handleChange = async (e) => {
    e.preventDefault();

    if (e.target.files.length) {
      const data = new FormData();
      data.append('imageFile', e.target.files[0]);
      await api
        .post('user/avatar', data, {
          headers: {
            'Content-Type': 'multipart/form-data; boundary=236676',
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          if (response.data.error) {
            console.log(response.data.error);
          } else {
            setUser(response.data.data);
            window.localStorage.setItem(
              'lhf-user',
              JSON.stringify(response.data.data)
            );
          }
        })
        .catch((error) => console.log(error));
    }
  };

  function updatePin() {
    setLoading(true);
    api.post('users/securtiypin/update', {
      NewPin: pin
    })
      .then((response) => {
        setLoading(false);
        if (response.data.success) {
          showMessage(t('profile_updates_success'), response.data.message);
          setShowPinReset(false);
        } else {
          showMessage(t('error_profile_update'), response.data.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        showMessage(t('error_profile_update'), error.message);
      });
  }

  return (
    <div className="flex flex-col flex-1 px-4 mb-4 overflow-auto space-y-4">
      <PageHeader
        title={t('profile')}
        extra={[
          <Button
            size="small"
            type="primary"
            disabled={loading}
            loading={loading}
            onClick={() => {
              setPin('');
              setShowPinReset(true);
            }}
          >
            {t('change_pin')}
          </Button>
        ]}
      />
      <Panel>
        <H3 text={t('edit_profile')} />
        <Subtitle
          text={t('edit_profile_description')}
        />
        <br />
        <div>
          <Image
            src={user && user.avatar}
            width={200}
            height={200}
            style={{ backgroundColor: '#87d068' }}
            shape="square"
          />
        </div>
        <Form>
          <Input
            label={t('name')}
            required
            type="text"
            name="fname"
            id="fname"
            placeholder={t('sample_first_name')}
            autoComplete="given-name"
            autoCapitalize="true"
            value={formik.values.fname}
            onChange={formik.handleChange}
            error={
              formik.errors.fname && formik.touched.fname && formik.errors.fname
            }
          />
          <Input
            label={t('last_name')}
            required
            type="text"
            name="lname"
            id="lname"
            autoComplete="family-name"
            placeholder={t('sample_last_name')}
            autoCapitalize="true"
            value={formik.values.lname}
            onChange={formik.handleChange}
            error={
              formik.errors.lname && formik.touched.lname && formik.errors.lname
            }
          />
          <Input
            label={t('email')}
            required
            type="email"
            name="email"
            id="email"
            placeholder="juan@gmail.com"
            autoComplete="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            error={
              formik.errors.email && formik.touched.email && formik.errors.email
            }
          />
          <Input
            label={t('Phone')}
            required
            id="phone"
            name="phone"
            type="text"
            autoComplete="tel"
            options={{
              phone: true,
              phoneRegionCode: 'PR',
            }}
            value={formik.values.phone}
            onChange={(e) => {
              formik.setFieldValue('phone', e.target.rawValue);
            }}
            placeholder="787 777 7777"
            error={
              formik.errors.phone && formik.touched.phone && formik.errors.phone
            }
          />
          <Select
            label={t('gender')}
            id="gender"
            name="gender"
            value={formik.values.gender}
            onChange={formik.handleChange}
            options={[
              {
                key: '5',
                value: 5,
                text: `${t('select_gender')}`,
                disabled: true,
              },
              {
                key: '0',
                value: 0,
                text: `${t('male')}`,
              },
              {
                key: '1',
                value: 1,
                text: `${t('female')}`,
              },
              {
                key: '2',
                value: 2,
                text: `${t('unknown_gender')}`,
              },
            ]}
          />
          <Select
            label={t('language')}
            id="language"
            name="language"
            value={formik.values.language}
            onChange={formik.handleChange}
            options={[
              {
                key: '0',
                value: 0,
                text: `${t('english')}`,
              },
              {
                key: '1',
                value: 1,
                text: `${t('spanish')}`,
              },
            ]}
          />
        </Form>
        <Button
          style={{ float: 'right' }}
          size="medium"
          type="primary"
          disabled={formik.isSubmitting}
          loading={formik.isSubmitting}
          onClick={formik.submitForm}
        >
          {t('save')}
        </Button>
      </Panel>

      <Modal
        title={t('change_pin')}
        open={showPinReset}
        onCancel={() => {
          setShowPinReset(false);
        }}
        footer={[
          <Button
            key="close"
            type="primary"
            danger
            loading={loading}
            onClick={() => {
              setShowPinReset(false);
            }}
          >
            {t('close')}
          </Button>,
          <Button
            key="ok"
            type="primary"
            loading={loading}
            disabled={loading || pin.length < 4}
            onClick={() => updatePin()}
          >
            {t('submit')}
          </Button>
        ]}
      >
        <>
          <span>{t('new_pin_requirements')}</span>
          <Descriptions size="small" bordered column={1}>
            <DescriptionsItem label={t('new_pin')} span={1}>
              <Input
                className="w-full"
                disabled={loading}
                onChange={(obj) => {
                  const reg = /^-?\d*(\.\d*)?$/;
                  if (reg.test(obj.target.value) || obj.target.value === '') {
                    setPin(obj.target.value);
                  }
                }}
                value={pin}
              />
            </DescriptionsItem>
          </Descriptions>
        </>
      </Modal>
    </div>
  );
}

export default Profile;
