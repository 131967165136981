import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  useRecoilValue,
  useRecoilState,
  useRecoilValueLoadable
} from 'recoil';
import {
  Tag,
  Button,
  Table
} from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import { BuildOutlined, CheckCircleOutlined, CloseCircleOutlined, SyncOutlined } from '@ant-design/icons';
import moment from 'moment';
import {
  timezoneAtom,
  selectedPromoEmailAtom,
  refreshPromoEmailsAtom,
  showRelatedItemPickAtom,
  businessAtom,
  profileAtom
} from '../../../atoms/Atoms';
import EditPromoEmailDrawer from './shared-components/EditPromoEmailDrawer';
import PickItemModel from './shared-components/PickItemModal';
import api from '../../../api/api';

function PromoEmails() {
  const timezone = useRecoilValue(timezoneAtom);
  const business = useRecoilValue(businessAtom);
  const [promoEmails, setPromoEmails] = useState([]);
  const showRelatedItemPick = useRecoilValue(showRelatedItemPickAtom);
  const [selectedPromoEmail, setSelectedPromoEmail] = useRecoilState(selectedPromoEmailAtom);
  const [refreshPromoEmails, setRefreshPromoEmails] = useRecoilState(refreshPromoEmailsAtom);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const userProfile = useRecoilValueLoadable(profileAtom);
  const isAdmin =
    userProfile.contents && userProfile?.contents?.roles?.includes('SuperAdmin');
  const isMarketing =
    isAdmin || (userProfile?.contents && userProfile?.contents?.roles?.includes('Marketing'));
  const history = useHistory();

  if (!isAdmin && !isMarketing) {
    history.push('/');
    return null;
  }

  const getPromoEmails = function () {
    setLoading(true);
    let url = 'marketing/promotion/emails';
    if (business) {
      url += `/store/${business.id}`;
    }
    api.get(url)
      .then((response) => {
        setLoading(false);
        if (response.data.success) {
          for (let i = 0; i < response.data.data.results.length; i++) {
            if (response.data.data.results[i].sendDate) {
              const sendDate = moment(
                `${response.data.data.results[i].sendDate}+0000`
              );
              response.data.data.results[i].sendDate = moment(sendDate).tz(timezone);
            }
            if (response.data.data.results[i].startDate) {
              const startDate = moment(
                `${response.data.data.results[i].startDate}+0000`
              );
              response.data.data.results[i].startDate = moment(startDate).tz(timezone);
            }
            if (response.data.data.results[i].endDate) {
              const endDate = moment(
                `${response.data.data.results[i].endDate}+0000`
              );
              response.data.data.results[i].endDate = moment(endDate).tz(timezone);
            }
          }
          setPromoEmails(response.data.data.results);
        } else {
          console.error(response.data.error);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  useEffect(() => {
    setSelectedPromoEmail(null);
    getPromoEmails();
  }, []);

  useEffect(() => {
    if (refreshPromoEmails) {
      setRefreshPromoEmails(false);
      getPromoEmails();
    }
  }, [refreshPromoEmails]);

  return (
    <>
      <PageHeader
        title={t('promo_emails')}
        extra={[
          <Button
            type="primary"
            key="create"
            icon={<BuildOutlined />}
            size="small"
            onClick={() => {
              setSelectedPromoEmail({});
            }}
          >
            {t('create')}
          </Button>,
          <Button
            key="reload"
            type="primary"
            icon={<SyncOutlined />}
            size="small"
            loading={loading}
            onClick={() => {
              setRefreshPromoEmails(true);
            }}
          >
            {t('reload')}
          </Button>
        ]}
      />
      <Table
        size="small"
        bordered
        loading={loading}
        pagination={false}
        dataSource={promoEmails}
        columns={[
          {
            title: t('name'),
            dataIndex: 'name',
            align: 'center',
            className: 'text-xs',
            render: (text) => (
              <span>{text}</span>
            ),
          },
          {
            title: t('approved_on'),
            align: 'center',
            className: 'text-xs',
            render: (row) => (
              <>
                {row.approvedAt && (
                  <span>
                    {moment(`${row.approvedAt}+0000`)
                      .tz(timezone)
                      .format('MMMM Do YYYY, h:mm:ss a')}
                  </span>
                )}
                {!row.approvedAt && (
                  <span>N/A</span>
                )}
              </>
            ),
          },
          {
            title: t('active'),
            align: 'center',
            className: 'text-xs',
            render: (row) => {
              if (row.isActive && !row.isCompleted) {
                return (
                  <Tag
                    icon={<CheckCircleOutlined />}
                    color="#87d068"
                    size="small"
                    className="mr-0"
                  >
                    {t('active')}
                  </Tag>
                );
              }
              if (row.isActive && row.isCompleted) {
                return (
                  <Tag
                    icon={<CheckCircleOutlined />}
                    color="#87d068"
                    size="small"
                    className="mr-0"
                  >
                    {t('completed')}
                  </Tag>
                );
              }
              return (
                <Tag
                  icon={<CloseCircleOutlined />}
                  color="#ff0400"
                  size="small"
                  className="mr-0"
                >
                  {t('not_active')}
                </Tag>
              );
            }
          },
          {
            title: t('num_sent'),
            align: 'center',
            className: 'text-xs',
            render: (row) => (
              <span>{row.sent}</span>
            ),
          },
          {
            title: '',
            align: 'center',
            className: 'text-xs',
            render: (row) => (
              <Button
                size="small"
                type="primary"
                onClick={() => {
                  setSelectedPromoEmail(row);
                }}
              >
                {t('edit')}
              </Button>
            ),
          }
        ]}
      />
      {selectedPromoEmail && (
        <EditPromoEmailDrawer />
      )}
      {showRelatedItemPick && (
        <PickItemModel />
      )}
    </>
  );
}

export default PromoEmails;
