import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { Button, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { SyncOutlined } from '@ant-design/icons';
import api from '../../../../api/api';

function Lifetime() {
  const [sales, setSales] = useState(null);
  const [loadingSales, setLoadingSales] = useState(false);
  const [loadingCustomers, setLoadingCustomers] = useState(false);
  const [customersData, setCustomersData] = useState(null);
  const [reload, setReload] = useState(false);
  const [timeSpan, setTimeSpan] = useState(6);
  const { t } = useTranslation();

  const [monthlyUsers, setMonthlyUsers] = useState(null);

  function mapData(users) {
    const mapped = _.map(users, (p, index) => ({
      key: index,
      date: p.date,
      count: p.count,
    }));

    return mapped;
  }

  function getSales() {
    setLoadingSales(true);
    api
      .get('analytics/orders/4/1')
      .then((response) => {
        setLoadingSales(false);
        setSales(response.data.data);
      })
      .catch((error) => {
        console.error(error);
        setLoadingSales(false);
      });
  }

  function getCustomers() {
    setLoadingCustomers(true);
    api
      .get(`analytics/customers/4/${timeSpan}`)
      .then((response) => {
        setLoadingCustomers(false);
        setCustomersData(response.data.data);
        setMonthlyUsers(mapData(response.data.data.usersCreated));
      })
      .catch((error) => {
        console.error(error);
        setLoadingCustomers(false);
      });
  }

  useEffect(() => {
    if (reload) {
      getCustomers();
      getSales();
    }
  }, [reload]);

  useEffect(() => {
    getCustomers();
    getSales();
  }, [timeSpan]);

  return (
    <div>
      <div className="flex flex-col" style={{ float: 'right' }}>
        <Button
          className="mb-4"
          size="small"
          type="primary"
          loading={loadingCustomers || loadingSales}
          icon={<SyncOutlined spin={loadingCustomers || loadingSales} />}
          onClick={() => {
            setReload(!reload);
          }}
        >
          {t('reload')}
        </Button>
      </div>
      <div>
        <h3 className="text-lg leading-6 font-medium text-gray-900">{t('sales')}</h3>
        <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
          <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
            <Spin spinning={loadingSales}>
              <dt className="text-sm font-medium text-gray-500 truncate">{t('total_sales')}</dt>
              <dd className="mt-1 text-3xl font-semibold text-gray-900">${sales && sales.totalSales.toFixed().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</dd>
            </Spin>
          </div>
          <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
            <Spin spinning={loadingSales}>
              <dt className="text-sm font-medium text-gray-500 truncate">{t('total_pickup_sales')}</dt>
              <dd className="mt-1 text-3xl font-semibold text-gray-900">${sales && sales.totalPickupSales.toFixed().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                <div className="inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium md:mt-2 lg:mt-0" style={{ float: 'right', marginTop: '30px' }}>
                  {sales && ((sales.totalPickupSales / sales?.totalSales) * 100).toFixed(2) }%
                </div>
              </dd>
            </Spin>
          </div>
          <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
            <Spin spinning={loadingSales}>
              <dt className="text-sm font-medium text-gray-500 truncate">{t('total_delivery_sales')}</dt>
              <dd className="mt-1 text-3xl font-semibold text-gray-900">${sales && sales.totalDeliverySales.toFixed().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                <div className="inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium md:mt-2 lg:mt-0" style={{ float: 'right', marginTop: '30px' }}>
                  {sales && ((sales.totalDeliverySales / sales?.totalSales) * 100).toFixed(2) }%
                </div>
              </dd>
            </Spin>
          </div>
        </dl>
      </div>
      <br />
      <div>
        <h3 className="text-lg leading-6 font-medium text-gray-900">{t('orders')}</h3>
        <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
          <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
            <Spin spinning={loadingSales}>
              <dt className="text-sm font-medium text-gray-500 truncate">{t('total_orders')}</dt>
              <dd className="mt-1 text-3xl font-semibold text-gray-900">{sales && sales.totalOrders.toLocaleString()}</dd>
            </Spin>
          </div>
          <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
            <Spin spinning={loadingSales}>
              <dt className="text-sm font-medium text-gray-500 truncate">{t('total_pickup_orders')}</dt>
              <dd className="mt-1 text-3xl font-semibold text-gray-900">{sales && sales.totalPickups.toLocaleString()}
                <div className="inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium md:mt-2 lg:mt-0" style={{ float: 'right', marginTop: '30px' }}>
                  {sales && ((sales.totalPickups / sales?.totalOrders) * 100).toFixed(2) }%
                </div>
              </dd>
            </Spin>
          </div>
          <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
            <Spin spinning={loadingSales}>
              <dt className="text-sm font-medium text-gray-500 truncate">{t('total_delivery_orders')}</dt>
              <dd className="mt-1 text-3xl font-semibold text-gray-900">{sales && sales.totalDeliveries.toLocaleString()}
                <div className="inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium md:mt-2 lg:mt-0" style={{ float: 'right', marginTop: '30px' }}>
                  {sales && ((sales.totalDeliveries / sales?.totalOrders) * 100).toFixed(2) }%
                </div>
              </dd>
            </Spin>
          </div>
        </dl>
      </div>
      <br />
      <div>
        <h3 className="text-lg leading-6 font-medium text-gray-900">{t('devices')}</h3>
        <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-4">
          <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
            <Spin spinning={loadingSales}>
              <dt className="text-sm font-medium text-gray-500 truncate">{t('mobile_orders')}</dt>
              <dd className="mt-1 text-3xl font-semibold text-gray-900">{sales && sales?.orderDeviceAnalytics?.mobileOrdersCount?.toLocaleString()}
                <div className="inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium md:mt-2 lg:mt-0" style={{ float: 'right', marginTop: '30px' }}>
                  {sales && (sales.orderDeviceAnalytics.mobileOrdersPercentage * 100).toFixed(2) }%
                </div>
              </dd>
            </Spin>
          </div>
          <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
            <Spin spinning={loadingSales}>
              <dt className="text-sm font-medium text-gray-500 truncate">{t('mobile_average')}</dt>
              <dd className="mt-1 text-3xl font-semibold text-gray-900">
                ${sales && sales.orderDeviceAnalytics.mobileOrderAverage.toFixed(2) }
              </dd>
            </Spin>
          </div>
          <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
            <Spin spinning={loadingSales}>
              <dt className="text-sm font-medium text-gray-500 truncate">{t('web_orders')}</dt>
              <dd className="mt-1 text-3xl font-semibold text-gray-900">{sales && sales?.orderDeviceAnalytics?.webOrdersCount?.toLocaleString()}
                <div className="inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium md:mt-2 lg:mt-0" style={{ float: 'right', marginTop: '30px' }}>
                  {sales && (sales.orderDeviceAnalytics.webOrdersPercentage * 100).toFixed(2) }%
                </div>
              </dd>
            </Spin>
          </div>
          <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
            <Spin spinning={loadingSales}>
              <dt className="text-sm font-medium text-gray-500 truncate">{t('web_average')}</dt>
              <dd className="mt-1 text-3xl font-semibold text-gray-900">
                ${sales && sales.orderDeviceAnalytics.webOrderAverage.toFixed(2) }
              </dd>
            </Spin>
          </div>
        </dl>
      </div>
      <br />
      <div>
        <h3 className="text-lg leading-6 font-medium text-gray-900">{t('customers')}</h3>
        <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-4">
          <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
            <Spin spinning={loadingCustomers}>
              <dt className="text-sm font-medium text-gray-500 truncate">{t('registered')}</dt>
              <dd className="mt-1 text-3xl font-semibold text-gray-900">{customersData && customersData.totalUsers.toFixed().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</dd>
            </Spin>
          </div>
          <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
            <Spin spinning={loadingCustomers}>
              <dt className="text-sm font-medium text-gray-500 truncate">{t('at_least_one_card')}</dt>
              <dd className="mt-1 text-3xl font-semibold text-gray-900">{customersData && customersData.totalUsersWithAtleastOneCard.toFixed().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                <div className="inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium md:mt-2 lg:mt-0" style={{ float: 'right', marginTop: '30px' }}>
                  {customersData &&
                  ((customersData.totalUsersWithAtleastOneCard /
                  customersData?.totalUsers) * 100).toFixed(2) }%
                </div>
              </dd>
            </Spin>
          </div>
          <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
            <Spin spinning={loadingCustomers}>
              <dt className="text-sm font-medium text-gray-500 truncate">{t('at_least_one_location')}</dt>
              <dd className="mt-1 text-3xl font-semibold text-gray-900">{customersData && customersData.totalUsersWithAtleastOneLocation.toFixed().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                <div className="inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium md:mt-2 lg:mt-0" style={{ float: 'right', marginTop: '30px' }}>
                  {customersData &&
                  ((customersData.totalUsersWithAtleastOneLocation /
                  customersData?.totalUsers) * 100).toFixed(2) }%
                </div>
              </dd>
            </Spin>
          </div>
          <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
            <Spin spinning={loadingCustomers}>
              <dt className="text-sm font-medium text-gray-500 truncate">{t('at_least_one_order')}</dt>
              <dd className="mt-1 text-3xl font-semibold text-gray-900">{customersData && customersData.totalUsersWithAtleastOneOrder.toFixed().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                <div className="inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium md:mt-2 lg:mt-0" style={{ float: 'right', marginTop: '30px' }}>
                  {customersData &&
                  ((customersData.totalUsersWithAtleastOneOrder /
                  customersData?.totalUsers) * 100).toFixed(2) }%
                </div>
              </dd>
            </Spin>
          </div>
        </dl>
      </div>
      <br />
      <br />
      {/* <div>
        <Row>
          <Col span={4}>
            <Typography.Title level={5}>Timespan</Typography.Title>
            <Select
              listHeight={500}
              placeholder="Select a timespan"
              defaultValue={6}
              style={{ width: 150, float: 'right' }}
              onChange={(value) => {
                setTimeSpan(value);
              }}
            >
              <Option value={6}>6 months</Option>
              <Option value={12}>12 months</Option>
              <Option value={18}>18 months</Option>
              <Option value={24}>24 months</Option>
              <Option value={36}>36 months</Option>
            </Select>
          </Col>
        </Row>
      </div> */}
    </div>
  );
}

export default Lifetime;
