import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import {
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
  useRecoilValueLoadable
} from 'recoil';
import { useTranslation } from 'react-i18next';
import {
  Table,
  notification,
  Avatar,
  Tooltip,
  Drawer,
  Button
} from 'antd';
import { Comment } from '@ant-design/compatible';
import moment from 'moment';
import {
  businessAtom,
  selectedDriverAtom,
  showAddDriverDrawerAtom,
  reloadDriversAtom
} from '../../../../atoms/Atoms';
import { configSelector } from '../../../../atoms/Selectors';
import Grocefy from '../../../../assets/images/grocefyLogoAlone.png';
import api from '../../../../api/api';

function AddNewDriverDrawer() {
  const config = useRecoilValueLoadable(configSelector);
  const business = useRecoilValue(businessAtom);
  const [showAddDriverDrawer, setShowAddDriverDrawer]
    = useRecoilState(showAddDriverDrawerAtom);
  const selectedDriver
    = useRecoilValue(selectedDriverAtom);
  const setReloadDrivers = useSetRecoilState(reloadDriversAtom);
  const [loading, setLoading] = useState(false);
  const [staff, setStaff] = useState([]);
  const { t } = useTranslation();

  const showMessage = function (message) {
    notification.open({
      message: '',
      description: (
        <Comment
          author={<span>Grocefy</span>}
          avatar={<Avatar src={Grocefy} alt="grocefy" />}
          content={
            <p className="text-sm">
              {message}
            </p>
          }
          datetime={
            <Tooltip title={moment().format('YYYY-MM-DD HH:mm:ss')}>
              <span>{moment().fromNow()}</span>
            </Tooltip>
          }
        />
      ),
    });
  };

  const getStaff = function () {
    if (!loading) {
      setLoading(true);
      api
        .get(`users/drivers/avail/${business.id}`)
        .then((response) => {
          setStaff(response.data.data.results);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          console.error(error);
        });
    }
  };

  const assignDriver = function (user) {
    if (!loading) {
      setLoading(true);
      api
        .post(
          `users/deliveryprofile/${user.id}/${business.locations[0].id}`,
          { }
        )
        .then((response) => {
          setReloadDrivers(true);
          setShowAddDriverDrawer(false);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          console.error(error);
        });
    }
  };

  useEffect(() => {
    if (showAddDriverDrawer) {
      getStaff();
    }
  }, [showAddDriverDrawer]);

  return (
    <>
      <Drawer
        title={t('add_new_driver')}
        placement="right"
        closable
        onClose={() => setShowAddDriverDrawer(false)}
        open={showAddDriverDrawer}
        width={400}
      >
        <Table
          size="small"
          bordered
          rowKey="id"
          loading={loading}
          columns={[
            {
              title: '',
              key: 'avatar',
              align: 'left',
              className: 'text-s',
              render: (row) => (
                <>
                  {row.avatar && row.avatar.length === 0 && (
                    <Avatar
                      style={{ background: config?.contents?.data?.primaryColor }}
                    >
                      {`${row.firstName[0]}${row.lastName[0]}`}
                    </Avatar>
                  )}
                  {row.avatar && row.avatar.length > 0 && (
                    <Avatar
                      style={{ background: config?.contents?.data?.primaryColor }}
                      src={row.avatar}
                    />
                  )}
                </>
              ),
            },
            {
              title: t('name'),
              dataIndex: 'completeName',
              key: 'completeName',
              align: 'left',
              className: 'text-s',
              render: (text) => <span>{text}</span>,
            },
            {
              title: t('email'),
              dataIndex: 'email',
              key: 'email',
              align: 'left',
              className: 'text-s',
              render: (text) => <span>{text}</span>,
            },
            {
              title: '',
              key: 'add',
              align: 'left',
              className: 'text-s',
              render: (row) => (
                <>
                  <Button
                    size="small"
                    type="primary"
                    onClick={() => assignDriver(row)}
                  >
                    {t('add')}
                  </Button>
                </>
              ),
            }
          ]}
          dataSource={staff}
        />
      </Drawer>
    </>
  );
}
export default AddNewDriverDrawer;
