import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import {
  useRecoilValueLoadable,
  useRecoilState,
  useSetRecoilState
} from 'recoil';
import {
  Modal,
  Drawer,
  Button,
  Select,
  Input,
  InputNumber,
  DatePicker,
  Table,
  notification,
  Switch,
  Tooltip,
  Avatar
} from 'antd';
import { Comment } from '@ant-design/compatible';
import { PageHeader } from '@ant-design/pro-layout';
import moment from 'moment';
import dayjs from 'dayjs';
import {
  selectedPromoCodesAtom,
  refreshPromoCodesAtom,
  showEditPromoCodeAtom
} from '../../../../atoms/Atoms';
import { businessesSelector } from '../../../../atoms/Selectors';
import api from '../../../../api/api';
import Grocefy from '../../../../assets/images/grocefyLogoAlone.png';

function EditUserPromoCode() {
  const { Option } = Select;
  const { TextArea } = Input;
  const { t } = useTranslation();
  const businesses = useRecoilValueLoadable(businessesSelector);
  const setRefreshPromoCodes = useSetRecoilState(refreshPromoCodesAtom);
  const [showEditPromoCode, setShowEditPromoCode] = useRecoilState(showEditPromoCodeAtom);
  const [selectedPromoCode, setSelectedPromoCode] = useRecoilState(selectedPromoCodesAtom);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showAssignUserModal, setShowAssignUserModal] = useState(false);
  const [users, setUsers] = useState([]);
  const [userSearchQuery, setUserSearchQuery] = useState('');
  const [loadingUsers, setLoadingUsers] = useState(false);

  function showMessage(message) {
    notification.open({
      message: '',
      description: (
        <Comment
          author={<span>Grocefy</span>}
          avatar={<Avatar src={Grocefy} alt="grocefy" />}
          content={
            <p className="text-sm">
              {message}
            </p>
          }
          datetime={
            <Tooltip title={moment().format('YYYY-MM-DD HH:mm:ss')}>
              <span>{moment().fromNow()}</span>
            </Tooltip>
          }
        />
      ),
    });
  }

  function submit() {
    setLoading(true);
    if (data.id) {
      api.post('businesses/discountcode/edit', {
        id: data.id,
        expiresAt: data.expiresAt
      })
        .then((resposne) => {
          setLoading(false);
          if (resposne.data.error) {
            showMessage(resposne.data.error);
          } else {
            if (resposne.data.message) {
              showMessage(resposne.data.message);
            } else {
              showMessage(t('updated_coupon_successfully'));
            }
            setShowEditPromoCode(0);
            setRefreshPromoCodes(true);
            setSelectedPromoCode(null);
          }
        })
        .catch((err) => {
          setLoading(false);
          showMessage(err.message);
        });
    } else {
      let temp = moment(data.expiresAt).utc();
      if (temp.length < 17) {
        temp = `${temp.replace(' ', 'T')}:00.000`;
      }
      const payload = {
        ...data,
        silentMode: !data.silentMode,
        expiresAt: temp
      };
      api.post('businesses/discountcode', payload)
        .then((resposne) => {
          setLoading(false);
          if (resposne.data.error) {
            showMessage(resposne.data.error);
          } else {
            if (resposne.data.message) {
              showMessage(resposne.data.message);
            } else {
              showMessage(t('created_coupon_successfully'));
            }
            setShowEditPromoCode(0);
            setRefreshPromoCodes(true);
            setSelectedPromoCode(null);
          }
        })
        .catch((err) => {
          setLoading(false);
          showMessage(err.message);
        });
    }
  }

  function searchUsers() {
    setLoadingUsers(true);
    api.post('users/search', { SearchText: userSearchQuery })
      .then((response) => {
        setLoadingUsers(false);
        if (response.data.error) {
          showMessage(response.data.error);
        } else {
          setUsers(response.data.data.results);
        }
      })
      .catch((err) => {
        setLoadingUsers(false);
        showMessage(err.message);
      });
  }

  useEffect(() => {
    if (showEditPromoCode === 1 && selectedPromoCode) {
      if (selectedPromoCode.id) {
        setData({
          ...selectedPromoCode,
          expiresAt: moment(`${selectedPromoCode.expiresAt}+0000`).add(-1, 'days'),
          silentMode: false,
          message: ''
        });
      } else {
        setData({
          businessId: '',
          minimumAmount: 0,
          value: 0,
          type: 4,
          appliesTo: 0,
          applicationType: 0,
          silentMode: false,
          message: ''
        });
      }
    }
  }, [selectedPromoCode, showEditPromoCode]);

  useEffect(() => {
    if (showAssignUserModal) {
      setUsers([]);
      setUserSearchQuery('');
    }
  }, [showAssignUserModal]);

  return (
    <>
      <Drawer
        title={`${data && data?.id ? t('update') : t('create')}`}
        placement="right"
        closable
        onClose={() => setShowEditPromoCode(0)}
        open={showEditPromoCode === 1}
        width={400}
      >
        <div className="mb-4">
          <strong>{t('customer')}</strong>
          {data?.userId && (
            <div className="flex">
              {data?.customerName}
              {!data?.id && (
                <Button
                  className="ml-1"
                  type="primary"
                  danger
                  size="small"
                  onClick={() => {
                    setData({
                      ...data,
                      userId: null,
                      customerName: null
                    });
                  }}
                >
                  {t('remove')}
                </Button>
              )}
            </div>
          )}
          {!data?.userId && (
            <Button
              className="ml-2"
              type="primary"
              size="small"
              onClick={() => setShowAssignUserModal(true)}
            >
              {t('select_user')}
            </Button>
          )}
        </div>
        <div className="mb-4">
          <strong>{t('code')}</strong>
          <br />
          <small>{t('promo_code_description')}</small>
          <Input
            className="uppercase"
            disabled={loading || data?.id}
            onChange={(obj) => {
              setData({
                ...data,
                code: obj.target.value.toUpperCase(),
                matchString: obj.target.value.toUpperCase()
              });
            }}
            value={data?.code}
          />
        </div>
        <div className="mb-4">
          <strong>{t('value')}</strong>
          <br />
          <InputNumber
            className="w-full"
            disabled={loading || data?.id}
            onChange={(obj) => {
              setData({
                ...data,
                value: obj
              });
            }}
            value={data?.value}
          />
        </div>
        <div className="mb-4">
          <strong>{t('min_purchase')}</strong>
          <br />
          <InputNumber
            className="w-full"
            disabled={loading || data?.id}
            onChange={(obj) => {
              setData({
                ...data,
                minimumAmount: obj
              });
            }}
            value={data?.minimumAmount}
          />
        </div>
        <div className="mb-4">
          <strong>{t('expires')}: </strong>
          <DatePicker
            disabled={loading}
            onChange={(date, dateString) => {
              setData({
                ...data,
                expiresAt: dateString
              });
            }}
            allowClear={false}
            value={dayjs(data?.expiresAt)}
          />
        </div>
        <div className="mb-4">
          <strong>{t('store')}: </strong>
          <Select
            defaultValue={data?.businessId}
            value={data?.businessId}
            style={{ width: 180 }}
            disabled={loading || data?.id}
            onChange={(obj) => {
              setData({
                ...data,
                businessId: obj
              });
            }}
          >
            <Option value="">{t('global')}</Option>
            {businesses.state === 'hasValue' && _.map(businesses.contents.data.data.results, (business) => (
              <Option
                value={business.id}
                key={business.id}
              >
                {business.name}
              </Option>
            ))}
          </Select>
        </div>
        <div className="mb-4">
          <strong>{t('applies_to')}: </strong>
          <Select
            defaultValue={data?.appliesTo}
            value={data?.appliesTo}
            style={{ width: 180 }}
            disabled={loading || data?.id}
            onChange={(obj) => {
              setData({
                ...data,
                appliesTo: obj
              });
            }}
          >
            <Option value={0}>{t('pickup')}</Option>
            <Option value={1}>{t('delivery')}</Option>
            <Option value={2}>{t('pickup_and_delivery')}</Option>
          </Select>
        </div>
        <div className="mb-4">
          <strong>{t('type')}: </strong>
          <Select
            defaultValue={data?.applicationType}
            value={data?.applicationType}
            style={{ width: 180 }}
            disabled={loading || data?.id}
            onChange={(obj) => {
              setData({
                ...data,
                applicationType: obj
              });
            }}
          >
            <Option value={0}>{t('one_time')}</Option>
            <Option value={1}>{t('reusable')}</Option>
          </Select>
        </div>
        <div hidden={data?.id} className="mb-4">
          <strong>{t('notify_customer')}: </strong>
          <Switch
            disabled={loading}
            style={{ float: 'right' }}
            checked={data?.silentMode}
            onChange={(checked) => {
              setData({
                ...data,
                silentMode: checked
              });
            }}
          />
        </div>
        <div hidden={!data?.silentMode} className="mb-4">
          <strong>{t('message')}: </strong>
          <TextArea
            disabled={loading}
            rows={3}
            onChange={(obj) => {
              setData({
                ...data,
                message: obj.target.value
              });
            }}
            value={data?.message}
          />
        </div>
        <div
          style={{
            float: 'right',
            margin: '10px 0'
          }}
        >
          <Button
            type="primary"
            loading={loading}
            disabled={(data?.code && data?.code?.length > 0 && data?.code?.length < 5)
              || data?.minimumAmount < 0
              || data?.value <= 0 || !data?.userId}
            onClick={() => submit()}
          >
            {data?.id ? t('update') : t('create')}
          </Button>
        </div>
      </Drawer>
      <Modal
        closable="true"
        centered="true"
        onCancel={() => setShowAssignUserModal(false)}
        open={showAssignUserModal}
        footer={null}
      >
        <PageHeader
          title={t('assign_user')}
        />
        <Input.Search
          placeholder={`${t('search')}...`}
          allowClear
          onChange={(obj) => {
            setUserSearchQuery(obj.target.value);
          }}
          onSearch={() => searchUsers()}
          value={userSearchQuery}
        />
        <Table
          size="small"
          bordered
          rowKey="id"
          loading={loadingUsers}
          hideOnSinglePage="true"
          columns={[
            {
              title: t('name'),
              align: 'left',
              className: 'text-xs',
              render: (row) => (
                <div>
                  {row.completeName}
                </div>
              )
            },
            {
              title: t('email'),
              align: 'left',
              className: 'text-xs',
              render: (row) => (
                <div>
                  {row.email}
                </div>
              )
            },
            {
              title: t('actions'),
              align: 'center',
              className: 'text-xs',
              render: (row) => (
                <Button
                  size="small"
                  type="primary"
                  onClick={() => {
                    setData({
                      ...data,
                      userId: row.id,
                      customerName: row.completeName
                    });
                    setShowAssignUserModal(false);
                  }}
                >
                  {t('select')}
                </Button>
              ),
            }
          ]}
          dataSource={users}
        />
      </Modal>
    </>
  );
}

export default EditUserPromoCode;
