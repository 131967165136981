import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { Button, Col, Popconfirm, Row, Space, Table, Tabs, Tag } from 'antd';
import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import { useRecoilValue, useRecoilState } from 'recoil';
import {
  businessAtom,
  createBin,
  reloadBin,
} from '../../../../atoms/Atoms';
import Create from '../../../shared-components/drawers/CreateBin';
import api from '../../../../api/api';

function Bin() {
  const [reload, setReload] = useRecoilState(reloadBin);
  const [bins, setBins] = useState(null);
  const [create, setCreate] = useRecoilState(createBin);
  const business = useRecoilValue(businessAtom);
  const [loading, setLoading] = useState(false);
  const [dry, setDry] = useState(null);
  const [cool, setCool] = useState(null);
  const [icecream, setIceCream] = useState(null);
  const [freezer, setFreezer] = useState(null);
  const [count, setCount] = useState(null);
  const { t } = useTranslation();

  function binType(b) {
    if (b === 0) return 'Dry';
    if (b === 1) return 'Cool';
    if (b === 2) return 'Ice Cream';
    if (b === 3) return 'Freezer';
    if (b === 4) return 'Cart';
    if (b === 5) return 'Pallet';
    return 'Unknown';
  }

  function binState(b) {
    if (b === 0) return 'Free';
    if (b === 1) return 'Blocked';
    if (b === 2) return 'Occupied';
    return 'Unknown';
  }

  function mapBins(data) {
    const map = _.map(data, (b) => ({
      key: b.id,
      colTag: b.colTag,
      createdAt: b.createdAt,
      locationId: b.locationId,
      ordering: b.ordering,
      rowTag: b.rowTag,
      stateConverted: binState(b.state),
      state: b.state,
      tag: b.tag,
      type: binType(b.type),
      typeNumber: b.type,
      orderConfirmationCode: b.orderConfirmationCode,
    }));
    setDry(
      _.filter(map, {
        type: 'Dry',
      })
    );
    setCool(
      _.filter(map, {
        type: 'Cool',
      })
    );
    setIceCream(
      _.filter(map, {
        type: 'Ice Cream',
      })
    );
    setFreezer(
      _.filter(map, {
        type: 'Freezer',
      })
    );
  }

  function BinsTable(type) {
    let temp;
    if (type === 0) {
      temp = dry;
    }
    if (type === 1) {
      temp = cool;
    }
    if (type === 2) {
      temp = icecream;
    }
    if (type === 3) {
      temp = freezer;
    }
    return (
      <div>
        <Table
          size="small"
          bordered
          pagination={false}
          loading={loading}
          columns={[
            {
              title: 'Tag',
              dataIndex: 'tag',
              key: 'tag',
              align: 'center',
              className: 'text-xs font-medium',
              render: (text) => <span>{text}</span>,
            },
            {
              title: t('type'),
              dataIndex: 'type',
              key: 'type',
              align: 'center',
              className: 'text-xs font-medium',
              filtered: 'true',
              render: (text) => <span>{text}</span>,
            },
            {
              title: t('state'),
              dataIndex: 'state',
              key: 'state',
              align: 'center',
              render: (text, record) => {
                if (
                  record.stateConverted === 'Occupied' &&
                  record.orderConfirmationCode === null
                ) {
                  return (
                    <Tag color="#f50" size="small" className="mr-0">
                      {t('occupied')}
                    </Tag>
                  );
                }
                if (record.stateConverted === 'Occupied') {
                  return (
                    <Tag color="error" size="small" className="mr-0">
                      {record.orderConfirmationCode}
                    </Tag>
                  );
                }
                if (record.stateConverted === 'Free') {
                  return (
                    <Tag
                      icon={<CheckCircleOutlined />}
                      color="success"
                      size="small"
                      className="mr-0"
                    >
                      {t('free')}
                    </Tag>
                  );
                }
                if (record.stateConverted === 'Blocked') {
                  return (
                    <Tag
                      icon={<CloseCircleOutlined />}
                      color="error"
                      size="small"
                      className="mr-0"
                    >
                      {t('blocked')}
                    </Tag>
                  );
                }
                return (
                  <Tag
                    icon={<CloseCircleOutlined />}
                    color="error"
                    size="small"
                    className="mr-0"
                  >
                    {t('unknown')}
                  </Tag>
                );
              },
            },
            {
              title: t('actions'),
              dataIndex: 'key',
              key: 'key',
              align: 'center',
              className: 'text-xs',
              render: (text, row) => (
                <Space>
                  <Popconfirm
                    placement="topRight"
                    title={t('update_bin_content')}
                    onConfirm={() => {
                      api
                        .put(
                          'businesses/bloc',
                          { Id: `${row.key}`, State: 0, Type: row.typeNumber }
                        )
                        .then((response) => {
                          setReload(reload + 1);
                        })
                        .catch((error) => {
                          console.error(error);
                        });
                    }}
                    okText={t('yes')}
                    cancelText="No"
                  >
                    <Button type="primary" size="small">
                      {t('free')}
                    </Button>
                  </Popconfirm>
                  <Popconfirm
                    placement="topRight"
                    title={t('update_bin_content')}
                    onConfirm={() => {
                      api
                        .put(
                          'businesses/bloc',
                          {
                            Id: `${row.key}`,
                            State: 1,
                            Type: row.typeNumber,
                          }
                        )
                        .then((response) => {
                          setReload(reload + 1);
                        })
                        .catch((error) => {
                          console.error(error);
                        });
                    }}
                    okText={t('yes')}
                    cancelText="No"
                  >
                    <Button type="primary" size="small">
                      {t('blocked')}
                    </Button>
                  </Popconfirm>
                  <Popconfirm
                    placement="topRight"
                    title={t('update_bin_content')}
                    onConfirm={() => {
                      api
                        .put(
                          'businesses/bloc',
                          {
                            Id: `${row.key}`,
                            State: 2,
                            Type: row.typeNumber,
                          }
                        )
                        .then((response) => {
                          setReload(reload + 1);
                        })
                        .catch((error) => {
                          console.error(error);
                        });
                    }}
                    okText={t('yes')}
                    cancelText="No"
                  >
                    <Button type="primary" size="small">
                      {t('occupied')}
                    </Button>
                  </Popconfirm>
                </Space>
              ),
            },
          ]}
          dataSource={temp}
        />
      </div>
    );
  }
  useEffect(() => {
    setLoading(true);
    api
      .get(`businesses/bins/${business.locations[0].id}`)
      .then((response) => {
        setLoading(false);
        setBins(mapBins(response.data.data.results));
        setCount(response.data.data.results);
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  }, [reload]);

  return (
    <>
      <div>
        <Row align="middle" gutter={[8, 8]}>
          <Col xs={24} md={12}>
            Total bins: {count && count.length}
          </Col>
          <Col xs={24} md={12} className="text-left md:text-right">
            <Button
              className="mb-4"
              type="primary"
              onClick={() => {
                setCreate(true);
                setReload(false);
              }}
            >
              {t('create')}
            </Button>
            {create ? <Create open={create} /> : null}
          </Col>
        </Row>
        <br />
      </div>
      <Tabs>
        <Tabs.TabPane key="0" tab="Dry">
          {t('total_bins')}: {dry && dry.length}
          <br />
          {BinsTable(0)};
        </Tabs.TabPane>
        <Tabs.TabPane key="1" tab="Cool">
          {t('total_bins')}: {cool && cool.length}
          <br />
          {BinsTable(1)}
        </Tabs.TabPane>
        <Tabs.TabPane key="3" tab="Ice Cream">
          {t('total_bins')}: {icecream && icecream.length}
          <br />
          {BinsTable(2)}
        </Tabs.TabPane>
        <Tabs.TabPane key="4" tab="Freezer">
          {t('total_bins')}: {freezer && freezer.length}
          <br />
          {BinsTable(3)}
        </Tabs.TabPane>
      </Tabs>
    </>
  );
}

export default Bin;
