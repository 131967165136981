import { Button, Checkbox, notification } from 'antd';
import { Comment } from '@ant-design/compatible';
import Modal from 'antd/lib/modal/Modal';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import Avatar from 'antd/lib/avatar/avatar';
import React, { useState, useEffect } from 'react';
import { orderItemsAtom, orderItemsVisible, reloadOrder, selectedOrderAtom, } from '../../../../atoms/Atoms';
import Grocefy from '../../../../assets/images/grocefyLogoAlone.png';
import api from '../../../../api/api';

function ItemRefund() {
  const [selectedOrder, setSelectedOrder] = useRecoilState(selectedOrderAtom);
  const [visible, setVisible] = useRecoilState(orderItemsVisible);
  const [itemsForCredit, setItemsForCredit] = useState(null);
  const [items, setItems] = useRecoilState(orderItemsAtom);
  const [reload, setReload] = useRecoilState(reloadOrder);
  const [itemList, setItemList] = useState([]);
  const [credit, setCredit] = useState(0);
  const { t } = useTranslation();

  useEffect(() => {
    if (items) {
      setItemList(items.map((r) => ({ label: r.name,
        value: r.id,
        price: r.price,
        stateTax: r.stateTax,
        municipalTax: r.municipalTax })));
    }
  }, [items]);

  function calculateCredit() {
    let total = 0;
    setCredit(0);
    if (itemsForCredit !== null) {
      for (let i = 0; i < itemsForCredit.length; i++) {
        for (let j = 0; j < itemList.length; j++) {
          if (itemList[j].value === itemsForCredit[i]) {
            total += itemList[j].price;
          }
        }
      }
      setCredit(total);
    }
  }

  function onChange(checked) {
    setItemsForCredit(checked);
  }

  useEffect(() => {
    calculateCredit();
  }, [itemsForCredit]);

  return (
    <>
      {items && visible && (
        <Modal
          closable="true"
          centered="true"
          open={visible}
          onCancel={() => { setVisible(false); setCredit(0); }}
          footer={
            <Button
              type="primary"
              onClick={() => {
                api
                  .put(
                    `orders/${selectedOrder.id}/productcredit/estimate/true`,
                    {
                      ItemIdList: itemsForCredit
                    }
                  )
                  .then((response) => {
                    if (response && response.data.error) {
                      console.error('Error Message: ', response.data.error);
                      notification.open({
                        message: 'Alert!',
                        description: (
                          <Comment
                            author={<span>Grocefy</span>}
                            avatar={<Avatar src={Grocefy} alt="grocefy" />}
                            content={
                              <p className="text-sm">{response.data.error}</p>
                          }
                          />
                        ),
                      });
                    } else if (
                      response &&
                      response.data.success &&
                      response.data.message
                    ) {
                      notification.open({
                        message: 'Success!',
                        description: (
                          <Comment
                            author={<span>Grocefy</span>}
                            avatar={<Avatar src={Grocefy} alt="grocefy" />}
                            content={
                              <p className="text-sm">{response.data.message}</p>
                            }
                          />
                        ),
                      });
                    }
                    setVisible(false);
                    setReload(true);
                  })
                  .catch((error) => {
                    console.error(error);
                  });
              }}
            >
              {t('return')} ${credit.toFixed(2)}
            </Button>
        }
        >
          {
            items && (
              <div>
                <Checkbox.Group options={itemList} onChange={onChange} />
              </div>
            )
        }
        </Modal>
      )}
    </>
  );
}

export default ItemRefund;
