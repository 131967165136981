import {
  Button,
  Popconfirm,
  Table,
  Modal,
  Tooltip,
  notification,
  Avatar
} from 'antd';
import { Comment } from '@ant-design/compatible';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import moment from 'moment';
import { useRecoilState, useRecoilValue, useRecoilValueLoadable, useSetRecoilState } from 'recoil';
import { reloadSharedStoresAtom, showCreateSharedStoreAtom, businessAtom } from '../../../../atoms/Atoms';
import { businessesSelector } from '../../../../atoms/Selectors';
import api from '../../../../api/api';
import Grocefy from '../../../../assets/images/grocefyLogoAlone.png';

function AssignSharedStoreModal() {
  const { t } = useTranslation();
  const business = useRecoilValue(businessAtom);
  const [showCreateSharedStore, setShowCreateSharedStore]
    = useRecoilState(showCreateSharedStoreAtom);
  const setReloadSharedStores = useSetRecoilState(reloadSharedStoresAtom);
  const businesses = useRecoilValueLoadable(businessesSelector);
  const [loading, setLoading] = useState(false);

  function showMessage(message) {
    notification.open({
      message: '',
      description: (
        <Comment
          author={<span>Grocefy</span>}
          avatar={<Avatar src={Grocefy} alt="grocefy" />}
          content={
            <p className="text-sm">
              {message}
            </p>
          }
          datetime={
            <Tooltip title={moment().format('YYYY-MM-DD HH:mm:ss')}>
              <span>{moment().fromNow()}</span>
            </Tooltip>
          }
        />
      ),
    });
  }

  function createSharedStore(storeId) {
    setLoading(true);
    api
      .post('inventory/shared/create', {
        BusinessId: business?.id,
        SharedBusinessId: storeId
      })
      .then((response) => {
        setLoading(false);
        if (!response.data.success) {
          showMessage(response.data.error);
        } else {
          showMessage(t('store_added_successfully'));
          setReloadSharedStores(true);
          setShowCreateSharedStore(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        showMessage(error);
      });
  }

  return (
    <Modal
      title={t('assign_shared_store')}
      open={showCreateSharedStore}
      onCancel={() => {
        setShowCreateSharedStore(false);
      }}
      footer={[
        <Button
          key="close"
          type="primary"
          danger
          loading={loading}
          onClick={() => {
            setShowCreateSharedStore(false);
          }}
        >
          {t('close')}
        </Button>
      ]}
    >
      <Table
        size="small"
        className="mt-2"
        loading={loading}
        bordered
        pagination="none"
        columns={[
          {
            title: t('name'),
            key: 'name',
            align: 'left',
            className: 'text-xs',
            render: (text) =>
              <span>{text.name}</span>,
          },
          {
            title: t('actions'),
            key: 'manage',
            align: 'center',
            width: 30,
            className: 'text-xs',
            render: (text, item) => (
              <div className="flex">
                <Popconfirm
                  key="shared"
                  title={t('add_shared_store_inventory_prompt')}
                  okText={t('yes')}
                  onConfirm={() => {
                    createSharedStore(item.id);
                  }}
                >
                  <Button
                    className="mr-2"
                    loading={loading}
                    size="small"
                    type="primary"
                  >
                    {t('select')}
                  </Button>
                </Popconfirm>
              </div>
            ),
          },
        ]}
        dataSource={_.filter(businesses?.contents?.data?.data?.results,
          (b) => b.id !== business?.id)}
      />
    </Modal>
  );
}

export default AssignSharedStoreModal;
