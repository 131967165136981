import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Table, Image } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import { useSetRecoilState, useRecoilState } from 'recoil';
import { useMount } from 'react-use';
import {
  editBookingLocationAtom,
  selectedBookingLocationAtom,
  reloadBookingLocationAtom
} from '../../../../atoms/Atoms';
import EditLocations from './shared-components/EditLocations';
import bookingApi from '../../../../api/bookingApi';

function Locations() {
  const { t } = useTranslation();
  const setSelectedLocation = useSetRecoilState(selectedBookingLocationAtom);
  const setEditLocation = useSetRecoilState(editBookingLocationAtom);
  const [reload, setReload] = useRecoilState(reloadBookingLocationAtom);
  const [loading, setLoading] = useState(false);
  const [locations, setLocations] = useState([]);

  function getLocations() {
    setLoading(true);
    bookingApi
      .get('locations')
      .then((response) => {
        setLoading(false);
        setLocations(response.data.data);
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  }

  useMount(() => {
    getLocations();
  });

  useEffect(() => {
    if (reload) {
      getLocations();
      setReload(false);
    }
  }, [reload]);

  return (
    <>
      <PageHeader
        className="mb-4 px-0"
        title={t('locations')}
        extra={[
          <Button
            id="reload"
            type="primary"
            key="reload"
            size="small"
            onClick={() => setReload(true)}
          >
            {t('reload')}
          </Button>,
          <Button
            type="primary"
            key="create"
            size="small"
            onClick={() => {
              setSelectedLocation({});
              setEditLocation(true);
            }}
          >
            {t('create_new')}
          </Button>
        ]}
      />
      <Table
        pagination={false}
        size="small"
        bordered
        rowKey="id"
        loading={loading}
        columns={[
          {
            title: t('image'),
            key: 'image',
            align: 'center',
            dataIndex: 'imageUrl',
            className: 'text-xs',
            render: (row) => (
              <>
                {row && (
                  <Image
                    alt="logo"
                    width={60}
                    src={row}
                    className="cursor-pointer"
                  />
                )}
              </>
            ),
          },
          {
            title: t('name'),
            key: 'name',
            align: 'center',
            dataIndex: 'name',
            className: 'text-xs',
            render: (row) => (
              <span>{row}</span>
            ),
          },
          {
            title: t('description'),
            key: 'description',
            align: 'center',
            dataIndex: 'description',
            className: 'text-xs',
            render: (row) => (
              <span>{row}</span>
            ),
          },
          {
            title: t('edit'),
            key: 'edit',
            align: 'center',
            className: 'text-xs',
            render: (text, record) => (
              <Button
                size="small"
                onClick={() => {
                  setSelectedLocation(record);
                  setEditLocation(true);
                }}
              >
                {t('edit')}
              </Button>
            ),
          },
        ]}
        dataSource={locations}
      />
      <EditLocations />
    </>
  );
}
export default Locations;
