import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import {
  Drawer,
  Avatar,
  Tooltip,
  Button,
  Image,
  Input,
  notification,
  Table
} from 'antd';
import { Comment } from '@ant-design/compatible';
import moment from 'moment';
import Grocefy from '../../../../../assets/images/grocefyLogoAlone.png';
import {
  showManageBoardTeamAtom,
  selectedTicketBoardAtom
} from '../../../../../atoms/Atoms';
import api from '../../../../../api/api';

function ManageBoardTeam() {
  const [viewMangeBoardTeam, setViewMangeBoardTeam] = useRecoilState(showManageBoardTeamAtom);
  const [selectedBoard, setSelectedBoard] = useRecoilState(selectedTicketBoardAtom);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const [members, setMembers] = useState([]);
  const showErrorMessage = function (errorMessage) {
    notification.open({
      message: '',
      description: (
        <Comment
          author={<span>Grocefy</span>}
          avatar={<Avatar src={Grocefy} alt="grocefy" />}
          content={
            <p className="text-sm">
              {errorMessage}
            </p>
          }
          datetime={
            <Tooltip title={moment().format('YYYY-MM-DD HH:mm:ss')}>
              <span>{moment().fromNow()}</span>
            </Tooltip>
          }
        />
      ),
    });
  };
  const updateMemberList = function () {
    const temp = JSON.parse(JSON.stringify(selectedBoard));
    temp.members = members;
    setSelectedBoard(temp);
  };
  const removeBoardMember = function (user) {
    setLoading(true);
    api
      .post(
        `ticketboards/board/${selectedBoard.id}/remove/${user.id}`,
        { }
      )
      .then((response) => {
        setLoading(false);
        if (response.data.success) {
          setMembers(response.data.data.results);
        } else {
          showErrorMessage(response.data.error);
        }
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  };
  const onSearch = async (value) => {
    if (value === '' || value === null) {
      return;
    }
    setLoading(true);
    api
      .post(
        'ticketboards/board/assign',
        {
          BoardId: selectedBoard.id,
          Email: value,
        }
      )
      .then((response) => {
        setLoading(false);
        if (response.data.success) {
          setMembers(response.data.data.results);
        } else {
          showErrorMessage(response.data.error);
        }
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (viewMangeBoardTeam && selectedBoard) {
      setMembers(selectedBoard.members);
    }
  }, [viewMangeBoardTeam, selectedBoard]);

  useEffect(() => {
    if (members && members.length > 0) {
      updateMemberList();
    }
  }, [members]);

  return (
    <Drawer
      title={t('members')}
      placement="right"
      closable
      onClose={() => setViewMangeBoardTeam(false)}
      open={viewMangeBoardTeam}
      width={400}
    >
      <>
        <Input.Search
          placeholder={t('enter_user_email')}
          allowClear
          enterButton="Add"
          size="large"
          onSearch={onSearch}
        />
        <Table
          size="small"
          bordered
          hideOnSinglePage="true"
          loading={loading}
          columns={[
            {
              title: t('name'),
              align: 'left',
              className: 'text-xs',
              render: (row) => (
                <div>
                  <Avatar
                    src={<Image src={row.avatar ? row.avatar : null} />}
                    style={{ marginRight: '10px' }}
                  >
                    {row.firstName[0]}{row.lastName[0]}
                  </Avatar>
                  {row.completeName}
                </div>
              )
            },
            {
              title: t('actions'),
              align: 'center',
              className: 'text-xs',
              render: (row) => (
                <Button
                  size="small"
                  type="primary"
                  danger
                  onClick={() => {
                    removeBoardMember(row);
                  }}
                >
                  {t('remove')}
                </Button>
              ),
            }
          ]}
          dataSource={members}
        />
      </>
    </Drawer>
  );
}

export default ManageBoardTeam;
