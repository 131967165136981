import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import {
  useRecoilValue,
  useRecoilState,
  useRecoilValueLoadable
} from 'recoil';
import { useHistory } from 'react-router-dom';
import {
  Button,
  Avatar,
  Input,
  Table,
  notification,
  Tag,
  Tooltip
} from 'antd';
import { Comment } from '@ant-design/compatible';
import { PageHeader } from '@ant-design/pro-layout';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import {
  PlusOutlined
} from '@ant-design/icons';
import Grocefy from '../../../assets/images/grocefyLogoAlone.png';
import {
  businessAtom,
  tokenAtom,
  selectedAddonAtom,
  refreshAddonsAtom,
  selectedOptionAtom,
  stateAtom,
  profileAtom
} from '../../../atoms/Atoms';
import EditItemAddon from './shared-components/EditItemAddon';
import EditAddonOption from './shared-components/EditAddonOption';
import envConfig from '../../../envConfig';
import api from '../../../api/api';

function ItemAddons() {
  const { Search } = Input;
  const currentState = useRecoilValue(stateAtom);
  const store = useRecoilValue(businessAtom);
  const token = useRecoilValue(tokenAtom);
  const selectedOption = useRecoilValue(selectedOptionAtom);
  const [selectedAddon, setSelectedAddon] = useRecoilState(selectedAddonAtom);
  const [refreshAddons, setRefreshAddons] = useRecoilState(refreshAddonsAtom);
  const [loading, setLoading] = useState(false);
  const [addons, setAddons] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const [searchText, setSearchText] = useState('');
  const { t } = useTranslation();

  const userProfile = useRecoilValueLoadable(profileAtom);
  const isAdmin =
    userProfile.contents && userProfile?.contents?.roles?.includes('SuperAdmin');
  const isCustomerService =
    isAdmin || (userProfile?.contents && (userProfile?.contents?.roles?.includes('CustomerServiceAdmin') || userProfile?.contents?.roles?.includes('CustomerService')));
  const isStoreManager =
    isAdmin || isCustomerService || (userProfile?.contents && (userProfile?.contents?.roles?.includes('StoreManager') || userProfile?.contents?.roles?.includes('StoreAdmin')));

  const history = useHistory();

  if (!isAdmin && !isCustomerService && !isStoreManager) {
    history.push('/');
    return null;
  }

  const showMessage = function (message) {
    notification.open({
      message: '',
      description: (
        <Comment
          author={<span>Grocefy</span>}
          avatar={<Avatar src={Grocefy} alt="grocefy" />}
          content={
            <p className="text-sm">
              {message}
            </p>
          }
          datetime={
            <Tooltip title={moment().format('YYYY-MM-DD HH:mm:ss')}>
              <span>{moment().fromNow()}</span>
            </Tooltip>
          }
        />
      ),
    });
  };

  const filter = function () {
    if (searchText.length > 0) {
      const temp = _.filter(addons, (row) => row.name.toUpperCase()
        .includes(searchText.toUpperCase()) ||
        row.internalName.toUpperCase().includes(searchText.toUpperCase()));
      setFiltered(temp);
    } else {
      setFiltered(addons);
    }
  };

  const getAddons = function () {
    setLoading(true);
    let url = 'deli/addons';
    if (!currentState.globalReqSideAddons && store) {
      url = `${store.id}/${url}`;
    }
    api
      .get(url)
      .then((response) => {
        if (response.data.success) {
          setLoading(false);
          setRefreshAddons(false);
          setAddons(response.data.data.results);
        } else {
          showMessage(response.data.error);
        }
      })
      .catch((error) => {
        setLoading(false);
        showMessage(error);
      });
  };

  useEffect(() => {
    getAddons();
  }, []);

  useEffect(() => {
    if (refreshAddons) {
      getAddons();
    }
  }, [refreshAddons]);

  useEffect(() => {
    if (addons) {
      filter();
    }
  }, [addons, searchText]);

  return (
    <>
      <PageHeader
        title={t('add-ons')}
        extra={[
          <Tag
            key="create"
            color="#1890ff"
            onClick={() => {
              setSelectedAddon({});
            }}
          >
            <PlusOutlined /> {t('create')}
          </Tag>
        ]}
      />
      <Search
        placeholder={t('search_addons')}
        allowClear
        onChange={(obj) => {
          setSearchText(obj.target.value);
        }}
        value={searchText}
      />
      <Table
        size="small"
        bordered
        loading={loading}
        pagination={false}
        columns={[
          {
            title: t('internal_name'),
            dataIndex: 'internalName',
            key: 'internalName',
            align: 'center',
            className: 'text-xs',
            render: (text) => (
              <span>{text}</span>
            ),
          },
          {
            title: t('name'),
            dataIndex: 'name',
            key: 'name',
            align: 'center',
            className: 'text-xs',
            render: (text) => (
              <span>{text}</span>
            ),
          },
          {
            title: t('minimum'),
            dataIndex: 'minRequired',
            key: 'minRequired',
            align: 'center',
            className: 'text-xs',
            render: (text) => (
              <span>{text}</span>
            ),
          },
          {
            title: t('maximum'),
            dataIndex: 'maxRequired',
            key: 'maxRequired',
            align: 'center',
            className: 'text-xs',
            render: (text) => (
              <span>{text}</span>
            ),
          },
          {
            title: '',
            align: 'center',
            className: 'text-xs',
            render: (row) => (
              <Button
                size="small"
                type="primary"
                onClick={() => {
                  setSelectedAddon(row);
                }}
              >
                {t('edit')}
              </Button>
            ),
          }
        ]}
        rowKey="id"
        dataSource={filtered}
        expandable={{
          expandedRowRender: (record) => (
            <Table
              size="small"
              bordered
              dataSource={record.items}
              pagination={false}
              rowKey="id"
              columns={[
                {
                  title: t('image'),
                  align: 'center',
                  className: 'text-xs',
                  render: (row) => (
                    <>
                      {row.imageUrl && row.imageUrl.length > 0 && (
                        <img
                          style={{ margin: 'auto' }}
                          width={50}
                          src={row.imageUrl}
                          alt={row.name}
                        />
                      )}
                    </>
                  ),
                },
                {
                  title: t('name'),
                  dataIndex: 'name',
                  key: 'name',
                  align: 'center',
                  className: 'text-xs',
                  render: (text) => (
                    <span>{text}</span>
                  ),
                },
                {
                  title: t('description'),
                  dataIndex: 'description',
                  key: 'description',
                  align: 'center',
                  className: 'text-xs',
                  render: (text) => (
                    <span>{text}</span>
                  ),
                },
                {
                  title: t('price'),
                  dataIndex: 'price',
                  key: 'price',
                  align: 'center',
                  className: 'text-xs',
                  render: (text) => (
                    <span>${text.toFixed(2)}</span>
                  ),
                }
              ]}
            />
          ),
          rowExpandable: (record) => record.items,
        }}
      />
      {selectedAddon && !selectedOption && (
        <EditItemAddon />
      )}
      {selectedAddon && selectedOption && (
        <EditAddonOption />
      )}
    </>
  );
}

export default ItemAddons;
