import React from 'react';
import MessagingHeader from './shared-components/Messaging-Header';
import MessagingBody from './shared-components/MessagingBody';

function Messaging() {
  return (
    <div>
      <MessagingHeader />
      <MessagingBody />
    </div>
  );
}

export default Messaging;
