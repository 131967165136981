import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import dayjs from 'dayjs';
import { useJsonToCsv } from 'react-json-csv';
import { useRecoilValue, useRecoilState } from 'recoil';
import {
  Input,
  Table,
  Select,
  DatePicker,
  Popover,
  Spin,
  Switch,
  Avatar,
  notification,
  Tooltip,
  Button
} from 'antd';
import { Comment } from '@ant-design/compatible';
import { PageHeader } from '@ant-design/pro-layout';
import { CloudDownloadOutlined, SearchOutlined } from '@ant-design/icons';
import { useMount } from 'react-use';
import bookingApi from '../../../../api/bookingApi';
import envConfig from '../../../../envConfig';
import {
  eventTypes,
  getEventTypeName,
  percent,
  getEventBookingStatus,
  formatPhoneNumber,
  thousand,
  renderDate
} from '../../../utils/functions';
import Grocefy from '../../../../assets/images/grocefyLogoAlone.png';
import { timezoneAtom } from '../../../../atoms/Atoms';
import { dateRangePresets } from '../../../utils/utils';

function TimeSlotHostsReport() {
  const { RangePicker } = DatePicker;
  const { Option } = Select;
  const { t } = useTranslation();
  const [dates, setDates] = useState(null);
  const [loading, setLoading] = useState(true);
  const [reportData, setReportData] = useState(null);
  const [firstLoad, setFirstLoad] = useState(true);
  const [groupByEvent, setGroupByEvent] = useState(false);
  const [searchText, setSearchText] = useState('');
  const timezone = useRecoilValue(timezoneAtom);

  const eventsColumns = [
    {
      title: t('host'),
      key: 'host',
      align: 'left',
      className: 'text-xs',
      render: (row) => (
        <div className="space-x-2">
          <Avatar src={row?.avatar} />
          <span>{row?.completeName}</span>
        </div>
      ),
    },
    {
      title: t('event_name'),
      key: 'eventName',
      dataIndex: 'eventName',
      align: 'left',
      className: 'text-xs',
      render: (row) => (
        <span>{row}</span>
      ),
    },
    {
      title: t('time_slots'),
      key: 'timeslots',
      align: 'left',
      className: 'text-xs',
      render: (row) => (
        <span>{thousand(row?.timeSlots?.length)}</span>
      ),
    }
  ];
  const eventHostsColumns = [
    {
      title: t('start_time'),
      key: 'startTime',
      dataIndex: 'startTime',
      align: 'left',
      className: 'text-xs',
      render: (row) => (
        <span>{renderDate(row, timezone)}</span>
      ),
    },
    {
      title: t('end_time'),
      key: 'endTime',
      dataIndex: 'endTime',
      align: 'left',
      className: 'text-xs',
      render: (row) => (
        <span>{renderDate(row, timezone)}</span>
      ),
    },
    {
      title: t('capacity'),
      key: 'capacity',
      align: 'left',
      className: 'text-xs',
      render: (row) => (
        <span>{row.currentCapacity}/{row.maxCapacity}</span>
      ),
    }
  ];

  const hostsColumns = [
    {
      title: t('host'),
      key: 'host',
      align: 'left',
      className: 'text-xs',
      render: (row) => (
        <div className="space-x-2">
          <Avatar src={row?.avatar} />
          <span>{row?.completeName}</span>
        </div>
      ),
    },
    {
      title: t('time_slots'),
      key: 'timeslots',
      align: 'left',
      className: 'text-xs',
      render: (row) => (
        <span>{thousand(row?.events?.length)}</span>
      ),
    }
  ];
  const hostEventsColumns = [
    {
      title: t('event_name'),
      key: 'eventName',
      dataIndex: 'eventName',
      align: 'left',
      className: 'text-xs',
      render: (row) => (
        <span>{row}</span>
      ),
    },
    {
      title: t('start_time'),
      key: 'startTime',
      dataIndex: 'startTime',
      align: 'left',
      className: 'text-xs',
      render: (row) => (
        <span>{renderDate(row, timezone)}</span>
      ),
    },
    {
      title: t('end_time'),
      key: 'endTime',
      dataIndex: 'endTime',
      align: 'left',
      className: 'text-xs',
      render: (row) => (
        <span>{renderDate(row, timezone)}</span>
      ),
    },
    {
      title: t('capacity'),
      key: 'capacity',
      align: 'left',
      className: 'text-xs',
      render: (row) => (
        <span>{row.currentCapacity}/{row.maxCapacity}</span>
      ),
    }
  ];

  function showMessage(message) {
    notification.open({
      message: '',
      description: (
        <Comment
          author={<span>Grocefy</span>}
          avatar={<Avatar src={Grocefy} alt="grocefy" />}
          content={
            <p className="text-sm">
              {message}
            </p>
          }
          datetime={
            <Tooltip title={moment().format('YYYY-MM-DD HH:mm:ss')}>
              <span>{moment().fromNow()}</span>
            </Tooltip>
          }
        />
      ),
    });
  }

  function getData(force, query) {
    if (!loading || force) {
      setLoading(true);
      const payload = {
        Query: query,
        StartDate: moment(dates?.start).add(-1 * envConfig.REACT_APP_TIMEZONE_OFFSET, 'hours').format('YYYY-MM-DDTHH:mm:00.000'),
        EndDate: moment(dates?.end).add(-1 * envConfig.REACT_APP_TIMEZONE_OFFSET, 'hours').add(1, 'days').format('YYYY-MM-DDTHH:mm:00.000'),
      };
      bookingApi.post('admin/timeslots/hosts/report', payload)
        .then((response) => {
          setLoading(false);
          if (!response.data.success) {
            showMessage(response.data.error);
          } else {
            setReportData(response.data?.data);
          }
        })
        .catch((error) => {
          setLoading(false);
          showMessage(error.message);
        });
    }
  }

  useEffect(() => {
    if (firstLoad && dates) {
      setFirstLoad(false);
      getData(true, searchText);
    } else if (!firstLoad && dates) {
      getData(false, searchText);
    }
  }, [dates]);

  useEffect(() => {
    getData(false, searchText);
  }, [groupByEvent]);

  useMount(() => {
    setDates({
      start: moment().startOf('month'),
      end: moment().endOf('month'),
    });
  });

  return (
    <>
      <PageHeader
        className="mb-4 px-0"
        title={t('timeslot_hosts_report')}
      />
      <div className="flex space-x-2">
        <div>
          <strong>{t('dates')}:</strong>
          <br />
          <RangePicker
            disabled={loading}
            format="MMM, DD YYYY"
            className="w-full"
            presets={dateRangePresets(t)}
            value={[
              dayjs(dates?.start),
              dayjs(dates?.end)
            ]}
            onChange={(date, dateString) => {
              setDates({
                start: dateString[0],
                end: dateString[1]
              });
            }}
          />
        </div>
        <div className="w-48">
          <strong>{t('group_by_event')}:</strong>
          <br />
          <Switch
            disabled={loading}
            checked={groupByEvent}
            onChange={(checked) => {
              setGroupByEvent(checked);
            }}
          />
        </div>
        <div className="w-full">
          <br />
          <Input.Search
            className="w-full"
            key="bookingSearch"
            allowClear
            loading={loading}
            enterButton={t('search')}
            onSearch={(value) => {
              setSearchText(value);
              getData(false, value);
            }}
          />
        </div>
      </div>
      <br />
      <Table
        className="mt-2"
        pagination="none"
        size="small"
        bordered
        rowKey={groupByEvent ? 'eventName' : 'completeName'}
        loading={loading}
        columns={groupByEvent ? eventsColumns : hostsColumns}
        dataSource={groupByEvent ? reportData?.events : reportData?.hosts}
        expandable={{
          expandRowByClick: false,
          rowExpandable: (record) => (groupByEvent ? record.timeSlots?.length > 0 : record.events?.length > 0),
          expandedRowRender: (record, index) => (
            <Table
              bordered
              key={index}
              size="small"
              dataSource={groupByEvent ? record.timeSlots : record.events}
              columns={groupByEvent ? eventHostsColumns : hostEventsColumns}
            />
          )
        }}
      />
    </>
  );
}
export default TimeSlotHostsReport;
