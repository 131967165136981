import React from 'react';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import {
  Drawer,
  Typography,
  notification,
  Avatar,
  Row,
  Col,
} from 'antd';
import { Comment } from '@ant-design/compatible';
import {
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
} from 'recoil';
import Grocefy from '../../../assets/images/grocefyLogoAlone.png';
import {
  addItemToCustomDeptAtom,
  businessAtom,
  productToAddAtom,
  reloadCustomDeptAtom,
  searchTermAtom,
} from '../../../atoms/Atoms';
import '../../../css/checkbox.css';
import api from '../../../api/api';

function AddToCustomDept() {
  const [product, setProduct] = useRecoilState(productToAddAtom);
  const setSearchTermAtom = useSetRecoilState(searchTermAtom);
  const [reload, setReload] = useRecoilState(reloadCustomDeptAtom);
  const store = useRecoilValue(businessAtom);
  const [addItem, setAddItem] = useRecoilState(addItemToCustomDeptAtom);
  const { t } = useTranslation();

  function prepareFlagsForm() {
    if (product == null) {
      return <div>No Data</div>;
    }

    return (
      <div>
        <Formik
          initialValues={{
            name: product.name,
            isEbt: product.ebt,
            promoted: product.promoted,
            idRequired: product.idRequired,
            catalog: product.catalog,
            oosTriggerBlock: product.oosTriggerBlock,
            organic: product.organic,
            vegetarian: product.vegetarian,
            antioxidant: product.antioxidant,
            sugarFree: product.sugarFree,
            nonGmo: product.nonGmo,
            grassFed: product.grassFed,
            alcoholicBeverage: product.alcoholicBeverage,
            madeInPR: product.madeInPR,
            blockPricing: product.blockPricing,
            perishable: product.perishable,
            vegan: product.vegan,
            glutenFree: product.glutenFree,
            keto: product.keto,
            lactoseFree: product.lactoseFree,
            nutFree: product.nutFree,
            customDepartment01: product.customDepartment01,
            customDepartment02: product.customDepartment02,
            customDepartment03: product.customDepartment03,
            customDepartment04: product.customDepartment04,
            customDepartment05: product.customDepartment05,
            customDepartment06: product.customDepartment06,
          }}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(true);
            api
              .post(
                `businesses/customdepartment/batch/update/${store.id}`,
                [{
                  MenuItemId: product.id,
                  CustomDepartment01: values.customDepartment01,
                  CustomDepartment02: values.customDepartment02,
                  CustomDepartment03: values.customDepartment03,
                  CustomDepartment04: values.customDepartment04,
                  CustomDepartment05: values.customDepartment05,
                  CustomDepartment06: values.customDepartment06
                }],
              )
              .then((response) => {
                if (response && response.data.error) {
                  console.log('Error Message: ', response.data.error);
                  notification.open({
                    message: 'Alert!',
                    description: (
                      <Comment
                        author={<span>Grocefy</span>}
                        avatar={<Avatar src={Grocefy} alt="grocefy" />}
                        content={
                          <p className="text-sm">{response.data.error}</p>
                        }
                      />
                    ),
                  });
                } else if (
                  response &&
                  response.data.success &&
                  response.data.message
                ) {
                  notification.open({
                    message: 'Success!',
                    description: (
                      <Comment
                        author={<span>Grocefy</span>}
                        avatar={<Avatar src={Grocefy} alt="grocefy" />}
                        content={
                          <p className="text-sm">{response.data.message}</p>
                        }
                      />
                    ),
                  });
                }
                setSubmitting(false);
                setProduct(null);
                setAddItem(false);
                setSearchTermAtom(product.upc);
                setReload(true);
              })
              .catch((error) => {
                console.log(error);
                setSubmitting(false);
              });
          }}
        >
          {({
            handleChange,
            handleSubmit,
            isSubmitting,
          }) => (
            <form noValidate onSubmit={handleSubmit} className="text-md">
              <div>
                <div className="mb-3 flex flex-column">
                  <input
                    id="customDepartment01"
                    type="checkbox"
                    name="customDepartment01"
                    className="switch"
                    defaultChecked={product.customDepartment01}
                    value={product.customDepartment01}
                    onChange={handleChange}
                  />
                  <Typography className="text-base">
                    {store.locations[0].customDepartment01Name !== null ? store.locations[0].customDepartment01Name : 'Custom Department 01' }
                  </Typography>
                </div>
                <div className="mb-3 flex flex-column">
                  <input
                    id="customDepartment02"
                    type="checkbox"
                    name="customDepartment02"
                    className="switch"
                    defaultChecked={product.customDepartment02}
                    value={product.customDepartment02}
                    onChange={handleChange}
                  />
                  <Typography className="text-base">
                    {store.locations[0].customDepartment02Name !== null ? store.locations[0].customDepartment02Name : 'Custom Department 02' }
                  </Typography>
                </div>
                <div className="mb-3 flex flex-column">
                  <input
                    id="customDepartment03"
                    type="checkbox"
                    name="customDepartment03"
                    className="switch"
                    defaultChecked={product.customDepartment03}
                    value={product.customDepartment03}
                    onChange={handleChange}
                  />
                  <Typography className="text-base">
                    {store.locations[0].customDepartment03Name !== null ? store.locations[0].customDepartment03Name : 'Custom Department 03' }
                  </Typography>
                </div>
                <div className="mb-3 flex flex-column">
                  <input
                    id="customDepartment04"
                    type="checkbox"
                    name="customDepartment04"
                    className="switch"
                    defaultChecked={product.customDepartment04}
                    value={product.customDepartment04}
                    onChange={handleChange}
                  />
                  <Typography className="text-base">
                    {store.locations[0].customDepartment04Name !== null ? store.locations[0].customDepartment04Name : 'Custom Department 04' }
                  </Typography>
                </div>
                <div className="mb-3 flex flex-column">
                  <input
                    id="customDepartment05"
                    type="checkbox"
                    name="customDepartment05"
                    className="switch"
                    defaultChecked={product.customDepartment05}
                    value={product.customDepartment05}
                    onChange={handleChange}
                  />
                  <Typography className="text-base">
                    {store.locations[0].customDepartment05Name !== null ? store.locations[0].customDepartment05Name : 'Custom Department 05' }
                  </Typography>
                </div>
                <div className="mb-3 flex flex-column">
                  <input
                    id="customDepartment06"
                    type="checkbox"
                    name="customDepartment06"
                    className="switch"
                    defaultChecked={product.customDepartment06}
                    value={product.customDepartment06}
                    onChange={handleChange}
                  />
                  <Typography className="text-base">
                    {store.locations[0].customDepartment06Name !== null ? store.locations[0].customDepartment06Name : 'Custom Department 06' }
                  </Typography>
                </div>
              </div>
              <div className="flex space-x-3 mt-10">
                <button
                  className="w-full md:w-1/2 text-xs transition delay-150 duration-300 ease-in-out border border-gray-700 hover:border-red-600 text-gray-500 hover:text-red-400 font-semibold px-6 py-3 rounded-full"
                  type="button"
                  size="small"
                  onClick={(e) => {
                    e.preventDefault();
                    setProduct(null);
                  }}
                >
                  {t('cancel')}
                </button>
                <button
                  size="small"
                  className="w-full md:w-1/2 flex items-center justify-center text-center text-xs transition delay-150 duration-300 ease-in-out bg-blue-500 hover:bg-blue-600 text-white font-semibold px-6 py-3 rounded-full"
                  type="submit"
                  disabled={isSubmitting}
                >
                  {' '}
                  {isSubmitting && (
                    <div>
                      <svg
                        className="animate-spin -ml-1 mr-3 h-4 w-4 text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        />
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        />
                      </svg>
                    </div>
                  )}
                  {t('save')}
                </button>
              </div>
            </form>
          )}
        </Formik>
      </div>
    );
  }

  return (
    <Drawer
      title="Add product to Custom Department"
      placement="right"
      closable={false}
      onClose={() => setAddItem(false)}
      open={!!addItem}
      width={500}
    >
      <div>
        <Typography.Text>
          {t('choose_department')}
        </Typography.Text>
        <div className="mb-3">
          <div className="tracking-tight mb-2 font-semibold text-lg">
            {product && product.completeName}
          </div>
          <Row>
            <Col>
              <div className="tracking-tight">
                UPC:
                <Typography.Text className="ml-2 font-normal" copyable>
                  {product && product.upc}
                </Typography.Text>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <div>{prepareFlagsForm()}</div>
    </Drawer>
  );
}

export default AddToCustomDept;
