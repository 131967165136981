import React from 'react';
import Header from './shared-components/Header';
import Search from './shared-components/Search';

function ProductSearch() {
  return (
    <div>
      <Header />
      <Search />
    </div>
  );
}
export default ProductSearch;
