import React from 'react';

function Panel({ children, dark, className }) {
  return (
    <div className={dark && 'dark'}>
      <div className={`${className} text-gray-800 bg-white dark:bg-gray-800 dark:text-gray-200 dark:border-gray-700`}>
        {children}
      </div>
    </div>
  );
}

export default Panel;
