import React from 'react';
import { useTranslation } from 'react-i18next';
import { Tabs } from 'antd';
import TodayLossHeader from './shared-components/TodayHeader';
import TodayLossBody from './shared-components/TodayLossBody';
import TodayUnderweightsBody from './shared-components/TodayUnderweightsBody';
import TodaySubsUnderBody from './shared-components/TodaySubsUnderBody';
import TodaySubsOverBody from './shared-components/TodaySubsOverBody';

function TodayLoss() {
  function callback() {}
  const { t } = useTranslation();
  return (
    <div>
      <div>
        <TodayLossHeader />
      </div>
      <Tabs defaultActiveKey="1" onChange={callback}>
        <Tabs.TabPane tab={t('out_of_stock')} key="1">
          <TodayLossBody />
        </Tabs.TabPane>
        <Tabs.TabPane tab={t('underweights')} key="2">
          <TodayUnderweightsBody />
        </Tabs.TabPane>
        <Tabs.TabPane tab={`${t('substitutes')} (${t('under')})`} key="3">
          <TodaySubsUnderBody />
        </Tabs.TabPane>
        <Tabs.TabPane tab={`${t('substitutes')} (${t('over')})`} key="4">
          <TodaySubsOverBody />
        </Tabs.TabPane>
      </Tabs>
    </div>
  );
}

export default TodayLoss;
