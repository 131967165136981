import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import {
  useRecoilState,
  useRecoilValue
} from 'recoil';
import { useTranslation } from 'react-i18next';
import {
  Select,
  notification,
  Avatar,
  InputNumber,
  Tooltip,
  Modal,
  Table,
  Typography,
  Switch,
  Button,
} from 'antd';
import { Comment } from '@ant-design/compatible';
import { PageHeader } from '@ant-design/pro-layout';
import moment from 'moment';
import {
  showEditDeliveryZoneHoursAtom,
  selectedDeliveryZoneAtom
} from '../../../../atoms/Atoms';
import { weekday } from '../../../utils/functions';
import Grocefy from '../../../../assets/images/grocefyLogoAlone.png';
import api from '../../../../api/api';

function EditDeliveryZoneHoursModal() {
  const { Option, OptGroup } = Select;
  const hourArray = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11,
    12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24];
  const [showEditDeliveryZoneHours, setShowEditDeliveryZoneHours]
    = useRecoilState(showEditDeliveryZoneHoursAtom);
  const selectedDeliveryZone
    = useRecoilValue(selectedDeliveryZoneAtom);
  const [loading, setLoading] = useState(false);
  const [hours, setHours] = useState([]);
  const { t } = useTranslation();

  function tConvert(z) {
    let temp = '';
    if (z < 12) {
      temp = `${z}:00 AM`;
    } else if (z === 12) {
      temp = `${z}:00 PM`;
    } else if (t === 24) {
      temp = '12:00 AM';
    } else {
      temp = `${z - 12}:00 PM`;
    }
    return temp;
  }

  const showMessage = function (message) {
    notification.open({
      message: '',
      description: (
        <Comment
          author={<span>Grocefy</span>}
          avatar={<Avatar src={Grocefy} alt="grocefy" />}
          content={
            <p className="text-sm">
              {message}
            </p>
          }
          datetime={
            <Tooltip title={moment().format('YYYY-MM-DD HH:mm:ss')}>
              <span>{moment().fromNow()}</span>
            </Tooltip>
          }
        />
      ),
    });
  };

  const getHours = function () {
    setLoading(true);
    api.get(`deliveries/deliveryzones/hours/${selectedDeliveryZone.id}`)
      .then((response) => {
        setLoading(false);
        if (response.data.success) {
          setHours(response.data.data.results);
        } else {
          showMessage(response.data.error);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  const setHour = function (hour) {
    const tempHours = JSON.parse(JSON.stringify(hours));
    for (let i = 0; i < hours.length; i++) {
      if (tempHours[i].id === hour.id) {
        tempHours[i].loading = hour.loading;
        tempHours[i].isBlocked = hour.isBlocked;
        tempHours[i].openHour = hour.openHour;
        tempHours[i].closeHour = hour.closeHour;
        tempHours[i].deliveryLimit = hour.deliveryLimit;
        tempHours[i].minuteBuffer = hour.minuteBuffer;
        tempHours[i].cutoffHour = hour.cutoffHour;
      }
    }
    setHours(tempHours);
  };

  const updateHour = function (hour) {
    const tempHour = JSON.parse(JSON.stringify(hour));
    tempHour.loading = true;
    setHour(tempHour);
    const temp = tempHour.cutoffHour;
    if (tempHour.cutoffHour?.hours === -1) {
      tempHour.cutoffHour = null;
    }
    api
      .post(
        'deliveries/deliveryzones/hours',
        tempHour
      )
      .then((response) => {
        setLoading(false);
        if (response.data.success) {
          tempHour.cutoffHour = temp;
          tempHour.loading = false;
          setHour(tempHour);
        } else {
          notification.open({
            message: 'Alert!',
            description: (
              <Comment
                author={<span>Grocefy</span>}
                avatar={<Avatar src={Grocefy} alt="grocefy" />}
                content={
                  <p className="text-sm">{response.data.error}</p>
                }
              />
            ),
          });
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  useEffect(() => {
    if (showEditDeliveryZoneHours && selectedDeliveryZone) {
      getHours();
    }
  }, [showEditDeliveryZoneHours]);

  useEffect(() => {
    if (showEditDeliveryZoneHours && selectedDeliveryZone) {
      getHours();
    }
  }, [selectedDeliveryZone]);

  return (
    <>
      <Modal
        placement="right"
        closable="true"
        centered="true"
        width={850}
        onCancel={() => setShowEditDeliveryZoneHours(false)}
        open={showEditDeliveryZoneHours}
        footer={[
          <Button
            key="back"
            disabled={loading}
            onClick={() => setShowEditDeliveryZoneHours(false)}
          >
            {t('close')}
          </Button>
        ]}
      >
        <PageHeader title={`Edit ${selectedDeliveryZone?.name} Hours`} />
        <Table
          size="small"
          bordered
          pagination={false}
          loading={loading}
          columns={[
            {
              title: t('weekday'),
              dataIndex: 'weekDay',
              key: 'weekDay',
              align: 'center',
              render: (text) => <Typography.Text>{t(weekday(text))}</Typography.Text>,
            },
            {
              title: t('open_hour'),
              key: 'openHour',
              align: 'center',
              render: (row) => (
                <Select
                  className={row.openHour.hours >= row.closeHour.hours ? ' border border-red-600 text-red-600' : ''}
                  loading={row.loading}
                  disabled={row.loading}
                  defaultValue={
                    row.openHour.hours
                  }
                  onChange={(value) => {
                    const temp = JSON.parse(JSON.stringify(row));
                    temp.openHour.hours = value;
                    temp.openHour.totalHours = value;
                    setHour(temp);
                  }}
                >
                  {_.map(hourArray, (num) => (
                    <Option
                      key={num}
                      value={num}
                      className="text-xs flex items-center"
                    >
                      {tConvert(num)}
                    </Option>
                  ))}
                </Select>
              )
            },
            {
              title: t('close_hour'),
              key: 'closeHour',
              align: 'center',
              render: (row) => (
                <Select
                  className={row.closeHour.hours <= row.openHour.hours ? ' border border-red-600 text-red-600' : ''}
                  loading={row.loading}
                  disabled={row.loading}
                  defaultValue={
                    row.closeHour.hours
                  }
                  onChange={(value) => {
                    const temp = JSON.parse(JSON.stringify(row));
                    temp.closeHour.hours = value;
                    temp.closeHour.totalHours = value;
                    setHour(temp);
                  }}
                >
                  {_.map(hourArray, (num) => (
                    <Option
                      key={num}
                      value={num}
                      className="text-xs flex items-center"
                    >
                      {tConvert(num)}
                    </Option>
                  ))}
                </Select>
              )
            },
            {
              title: t('cutoff_hour'),
              key: 'cutoffHour',
              align: 'center',
              render: (row) => (
                <Select
                  loading={row.loading}
                  disabled={row.loading}
                  defaultValue={-1}
                  value={
                    row.cutoffHour?.hours
                  }
                  onChange={(value) => {
                    const temp = JSON.parse(JSON.stringify(row));
                    if (!temp.cutoffHour) {
                      temp.cutoffHour = { };
                    }
                    temp.cutoffHour.hours = value;
                    temp.cutoffHour.totalHours = value;
                    setHour(temp);
                  }}
                >
                  <Option
                    key={-1}
                    value={-1}
                    className="text-xs flex items-center"
                  >
                    {t('no_cutoff')}
                  </Option>
                  {_.map(hourArray, (num) => (
                    <Option
                      key={num}
                      value={num}
                      className="text-xs flex items-center"
                    >
                      {tConvert(num)}
                    </Option>
                  ))}
                </Select>
              )
            },
            {
              title: t('delivery_limit_per_slot'),
              key: 'deliveryLimit',
              align: 'center',
              className: 'text-xs',
              render: (row) => (
                <span>
                  <InputNumber
                    size="small"
                    min={0}
                    value={row.deliveryLimit}
                    disabled={row.loading}
                    loading={row.isBlocked}
                    onChange={(obj) => {
                      const temp = JSON.parse(JSON.stringify(row));
                      temp.deliveryLimit = obj;
                      setHour(temp);
                    }}
                  />
                </span>
              ),
            },
            {
              title: 'Buffer (minutes)',
              key: 'minuteBuffer',
              align: 'center',
              className: 'text-xs',
              render: (row) => (
                <span>
                  <InputNumber
                    size="small"
                    min={0}
                    value={row.minuteBuffer}
                    disabled={row.loading}
                    loading={row.isBlocked}
                    onChange={(obj) => {
                      const temp = JSON.parse(JSON.stringify(row));
                      temp.minuteBuffer = obj;
                      setHour(temp);
                    }}
                  />
                </span>
              ),
            },
            {
              title: t('is_blocked'),
              key: 'isBlocked',
              align: 'center',
              className: 'text-xs',
              render: (row) => (
                <span>
                  <Switch
                    size="small"
                    disabled={row.loading}
                    checked={row.isBlocked}
                    onChange={(obj) => {
                      const temp = JSON.parse(JSON.stringify(row));
                      temp.isBlocked = obj;
                      setHour(temp);
                    }}
                  />
                </span>
              ),
            },
            {
              title: '',
              key: 'update',
              align: 'center',
              render: (row) => (
                <Button
                  disabled={row.closeHour.hours <= row.openHour.hours
                    || row.openHour.hours >= row.closeHour.hours}
                  loading={row.loading}
                  type="primary"
                  size="small"
                  onClick={() => updateHour(row)}
                >
                  {t('update')}
                </Button>
              )
            }
          ]}
          dataSource={hours && hours.length > 0 ? hours : null}
        />
      </Modal>
    </>
  );
}
export default EditDeliveryZoneHoursModal;
