import React, { useEffect, useState } from 'react';
import {
  useRecoilState,
  useRecoilValue,
  useSetRecoilState
} from 'recoil';
import { useTranslation } from 'react-i18next';
import {
  Modal,
  Avatar,
  Tooltip,
  Input,
  notification,
  Button
} from 'antd';
import { Comment } from '@ant-design/compatible';
import { PageHeader } from '@ant-design/pro-layout';
import moment from 'moment';
import Grocefy from '../../../../assets/images/grocefyLogoAlone.png';
import {
  showAddFilterAtom,
  refreshSearchFiltersAtom
} from '../../../../atoms/Atoms';
import api from '../../../../api/api';

function AddFilterModal() {
  const setRefreshSearchFilters = useSetRecoilState(refreshSearchFiltersAtom);
  const [showAddFilter, setShowAddFilter] = useRecoilState(showAddFilterAtom);
  const [loading, setLoading] = useState(false);
  const [filterName, setFilterName] = useState('');
  const { t } = useTranslation();

  const showMessage = function (message) {
    notification.open({
      message: '',
      description: (
        <Comment
          author={<span>Grocefy</span>}
          avatar={<Avatar src={Grocefy} alt="grocefy" />}
          content={
            <p className="text-sm">
              {message}
            </p>
          }
          datetime={
            <Tooltip title={moment().format('YYYY-MM-DD HH:mm:ss')}>
              <span>{moment().fromNow()}</span>
            </Tooltip>
          }
        />
      ),
    });
  };

  const createClick = function () {
    if (filterName.length === 0) {
      showMessage(t('filter_content'));
    } else {
      setLoading(true);
      api
        .post(
          'businesses/catalog/filters',
          {
            Name: filterName,
            Ordering: 999
          }
        )
        .then((response) => {
          if (response.data.success) {
            setRefreshSearchFilters(true);
            setLoading(false);
            setShowAddFilter(false);
          } else {
            showMessage(response.data.error);
          }
        })
        .catch((error) => {
          setLoading(false);
          showMessage(error);
        });
    }
  };

  useEffect(() => {
    if (showAddFilterAtom) {
      setFilterName('');
    }
  }, [showAddFilterAtom]);

  return (
    <Modal
      closable="true"
      centered="true"
      onCancel={() => setShowAddFilter(false)}
      open={showAddFilter}
      footer={[
        <Button
          key="createButton"
          disabled={loading}
          onClick={() => createClick()}
        >
          {t('create')}
        </Button>,
        <Button
          key="back"
          disabled={loading}
          onClick={() => setShowAddFilter(false)}
        >
          {t('close')}
        </Button>
      ]}
    >
      <PageHeader
        title={t('create_filter')}
      />
      <Input
        placeholder={t('filter_name')}
        onChange={(obj) => setFilterName(obj.target.value)}
        value={filterName}
      />
    </Modal>
  );
}

export default AddFilterModal;
