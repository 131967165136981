import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import {
  useRecoilState,
  useRecoilValueLoadable,
  useSetRecoilState
} from 'recoil';
import dayjs from 'dayjs';
import { TwitterPicker } from 'react-color';
import {
  Drawer,
  Avatar,
  Tooltip,
  DatePicker,
  Upload,
  Collapse,
  Switch,
  Button,
  Input,
  notification
} from 'antd';
import { Comment } from '@ant-design/compatible';
import {
  InboxOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import Grocefy from '../../../../assets/images/grocefyLogoAlone.png';
import {
  profileAtom,
  selectedCampaignAtom,
  refreshCampaignsAtom,
  showEditCampaignDrawerAtom,
  showCreateCampaignDrawerAtom
} from '../../../../atoms/Atoms';
import api from '../../../../api/api';

function EditProductCampaign() {
  const { RangePicker } = DatePicker;
  const { Dragger } = Upload;
  const { Panel } = Collapse;
  const profile = useRecoilValueLoadable(profileAtom);
  const setRefreshCampaigns = useSetRecoilState(refreshCampaignsAtom);
  const setShowEditCampaignDrawer = useSetRecoilState(showEditCampaignDrawerAtom);
  const setShowCreateCampaignDrawer = useSetRecoilState(showCreateCampaignDrawerAtom);
  const [selectedCampaign, setSelectedCampaign] = useRecoilState(selectedCampaignAtom);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [brandImageEnglishData, setBrandImageEnglishData] = useState(null);
  const [brandImageSpanishData, setBrandImageSpanishData] = useState(null);
  const [headerImageEnglishData, setHeaderImageEnglishData] = useState(null);
  const [headerImageSpanishData, setHeaderImageSpanishData] = useState(null);
  const [bannerImageEnglishData, setBannerImageEnglishData] = useState(null);
  const [bannerImageSpanishData, setBannerImageSpanishData] = useState(null);
  const [backgroundImageEnglishData, setBackgroundImageEnglishData] = useState(null);
  const [backgroundImageSpanishData, setBackgroundImageSpanishData] = useState(null);
  const [footerImageEnglishData, setFooterImageEnglishData] = useState(null);
  const [footerImageSpanishData, setFooterImageSpanishData] = useState(null);
  const [videoEnglishData, setVideoEnglishData] = useState(null);
  const [videoSpanishData, setVideoSpanishData] = useState(null);
  const [videoThumbnailEnglishData, setVideoThumbnailEnglishData] = useState(null);
  const [videoThumbnailSpanishData, setVideoThumbnailSpanishData] = useState(null);
  const { t } = useTranslation();

  const brandEnglishDraggerProps = {
    name: 'file',
    accept: 'image/*',
    multiple: false,
    showUploadList: false,
    beforeUpload: (file) => {
      setBrandImageEnglishData(file);
      return false;
    }
  };
  const brandSpanishDraggerProps = {
    name: 'file',
    accept: 'image/*',
    multiple: false,
    showUploadList: false,
    beforeUpload: (file) => {
      setBrandImageSpanishData(file);
      return false;
    }
  };
  const headerEnglishDraggerProps = {
    name: 'file',
    accept: 'image/*',
    multiple: false,
    showUploadList: false,
    beforeUpload: (file) => {
      setHeaderImageEnglishData(file);
      return false;
    }
  };
  const headerSpanishDraggerProps = {
    name: 'file',
    accept: 'image/*',
    multiple: false,
    showUploadList: false,
    beforeUpload: (file) => {
      setHeaderImageSpanishData(file);
      return false;
    }
  };
  const bannerEnglishDraggerProps = {
    name: 'file',
    accept: 'image/*',
    multiple: false,
    showUploadList: false,
    beforeUpload: (file) => {
      setBannerImageEnglishData(file);
      return false;
    }
  };
  const bannerSpanishDraggerProps = {
    name: 'file',
    accept: 'image/*',
    multiple: false,
    showUploadList: false,
    beforeUpload: (file) => {
      setBannerImageSpanishData(file);
      return false;
    }
  };
  const backgroundEnglishDraggerProps = {
    name: 'file',
    accept: 'image/*',
    multiple: false,
    showUploadList: false,
    beforeUpload: (file) => {
      setBackgroundImageEnglishData(file);
      return false;
    }
  };
  const backgroundSpanishDraggerProps = {
    name: 'file',
    accept: 'image/*',
    multiple: false,
    showUploadList: false,
    beforeUpload: (file) => {
      setBackgroundImageSpanishData(file);
      return false;
    }
  };
  const footerEnglishDraggerProps = {
    name: 'file',
    accept: 'image/*',
    multiple: false,
    showUploadList: false,
    beforeUpload: (file) => {
      setFooterImageEnglishData(file);
      return false;
    }
  };
  const footerSpanishDraggerProps = {
    name: 'file',
    accept: 'image/*',
    multiple: false,
    showUploadList: false,
    beforeUpload: (file) => {
      setFooterImageSpanishData(file);
      return false;
    }
  };
  const videoEnglishDraggerProps = {
    name: 'file',
    accept: 'video/*',
    multiple: false,
    showUploadList: false,
    beforeUpload: (file) => {
      setVideoEnglishData(file);
      return false;
    }
  };
  const videoSpanishDraggerProps = {
    name: 'file',
    accept: 'video/*',
    multiple: false,
    showUploadList: false,
    beforeUpload: (file) => {
      setVideoSpanishData(file);
      return false;
    }
  };
  const videoThumbnailEnglishDraggerProps = {
    name: 'file',
    accept: 'image/*',
    multiple: false,
    showUploadList: false,
    beforeUpload: (file) => {
      setVideoThumbnailEnglishData(file);
      return false;
    }
  };
  const videoThumbnailSpanishDraggerProps = {
    name: 'file',
    accept: 'image/*',
    multiple: false,
    showUploadList: false,
    beforeUpload: (file) => {
      setVideoThumbnailSpanishData(file);
      return false;
    }
  };

  const showMessage = function (message) {
    notification.open({
      message: '',
      description: (
        <Comment
          author={<span>Grocefy</span>}
          avatar={<Avatar src={Grocefy} alt="grocefy" />}
          content={
            <p className="text-sm">
              {message}
            </p>
          }
          datetime={
            <Tooltip title={moment().format('YYYY-MM-DD HH:mm:ss')}>
              <span>{moment().fromNow()}</span>
            </Tooltip>
          }
        />
      ),
    });
  };

  const uploadImage = async function (imagePayload) {
    const boundary = Date.now();
    const response = await api
      .post('utility/productcampaign/uploadfile', imagePayload);
    setLoading(false);
    if (response.data.success) {
      const temp = JSON.parse(JSON.stringify(data));
      return response.data.data;
    }
    showMessage(response.data.error);
    return null;
  };

  const submit = function () {
    setLoading(true);
    const temp = JSON.parse(JSON.stringify(data));
    temp.startDate = moment(temp.startDate).utc();
    temp.endDate = moment(temp.endDate).utc();
    if (temp.startDate.length < 17) {
      temp.startDate = `${temp.startDate.replace(' ', 'T')}:00.000`;
      temp.endDate = `${temp.endDate.replace(' ', 'T')}:00.000`;
    }
    let url = 'marketing/campaign';
    if (temp.id) {
      url = `${url}/${temp.id}`;
    }
    api.post(
      url,
      temp
    )
      .then((response) => {
        setLoading(false);
        if (response.data.success) {
          setRefreshCampaigns(true);
          setShowCreateCampaignDrawer(false);
          setShowEditCampaignDrawer(false);
          if (selectedCampaign.id) {
            const [updatedCampaign] = _.filter(response.data.data.results, (c) => c.id
              === selectedCampaign.id);
            setSelectedCampaign(updatedCampaign);
          }
        } else {
          showMessage(response.data.error);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  const onChangePrimaryColor = function (obj) {
    const temp = JSON.parse(JSON.stringify(data));
    if (obj.hex) {
      temp.primaryColor = obj.hex;
    } else {
      temp.primaryColor = obj.target.value;
    }
    setData(temp);
  };

  const onChangePrimaryTextColor = function (obj) {
    const temp = JSON.parse(JSON.stringify(data));
    if (obj.hex) {
      temp.primaryTextColor = obj.hex;
    } else {
      temp.primaryTextColor = obj.target.value;
    }
    setData(temp);
  };

  const onChangeSecondaryColor = function (obj) {
    const temp = JSON.parse(JSON.stringify(data));
    if (obj.hex) {
      temp.secondaryColor = obj.hex;
    } else {
      temp.secondaryColor = obj.target.value;
    }
    setData(temp);
  };

  const onChangeSecondaryTextColor = function (obj) {
    const temp = JSON.parse(JSON.stringify(data));
    if (obj.hex) {
      temp.secondaryTextColor = obj.hex;
    } else {
      temp.secondaryTextColor = obj.target.value;
    }
    setData(temp);
  };

  const onChangeAccentColor = function (obj) {
    const temp = JSON.parse(JSON.stringify(data));
    if (obj.hex) {
      temp.accentColor = obj.hex;
    } else {
      temp.accentColor = obj.target.value;
    }
    setData(temp);
  };

  const onChangeAccentTextColor = function (obj) {
    const temp = JSON.parse(JSON.stringify(data));
    if (obj.hex) {
      temp.accentTextColor = obj.hex;
    } else {
      temp.accentTextColor = obj.target.value;
    }
    setData(temp);
  };

  const onChangeBackgroundColor = function (obj) {
    const temp = JSON.parse(JSON.stringify(data));
    if (obj.hex) {
      temp.backgroundColor = obj.hex;
    } else {
      temp.backgroundColor = obj.target.value;
    }
    setData(temp);
  };

  const onChangeBackgroundTextColor = function (obj) {
    const temp = JSON.parse(JSON.stringify(data));
    if (obj.hex) {
      temp.backgroundTextColor = obj.hex;
    } else {
      temp.backgroundTextColor = obj.target.value;
    }
    setData(temp);
  };

  useEffect(() => {
    if (selectedCampaign) {
      const temp = JSON.parse(JSON.stringify(selectedCampaign));
      temp.lastEditedBy = profile.contents.id;
      if (!temp.id) {
        temp.isActive = false;
      }
      if (!temp.primaryColor) {
        temp.primaryColor = '';
      }
      if (!temp.primaryTextColor) {
        temp.primaryTextColor = '';
      }
      if (!temp.secondaryColor) {
        temp.secondaryColor = '';
      }
      if (!temp.secondaryTextColor) {
        temp.secondaryTextColor = '';
      }
      if (!temp.accentColor) {
        temp.accentColor = '';
      }
      if (!temp.accentTextColor) {
        temp.accentTextColor = '';
      }
      if (!temp.backgroundColor) {
        temp.backgroundColor = '';
      }
      if (!temp.backgroundTextColor) {
        temp.backgroundTextColor = '';
      }
      if (!temp.startDate || temp.startDate.length === 0) {
        temp.startDate = moment();
      }
      if (!temp.endDate || temp.endDate.length === 0) {
        temp.endDate = moment();
      }
      setData(temp);
    }
  }, [selectedCampaign]);

  useEffect(async () => {
    if (bannerImageEnglishData) {
      const file = bannerImageEnglishData;
      const imagePayload = new FormData();
      imagePayload.append('file', file);
      setLoading(true);
      const imageUrl = await uploadImage(imagePayload);
      setHeaderImageEnglishData(null);
      if (imageUrl) {
        const temp = JSON.parse(JSON.stringify(data));
        temp.bannerImageUrlEng = imageUrl;
        setData(temp);
      }
    }
  }, [bannerImageEnglishData]);

  useEffect(async () => {
    if (bannerImageSpanishData) {
      const file = bannerImageSpanishData;
      const imagePayload = new FormData();
      imagePayload.append('file', file);
      setLoading(true);
      const imageUrl = await uploadImage(imagePayload);
      setBannerImageSpanishData(null);
      if (imageUrl) {
        const temp = JSON.parse(JSON.stringify(data));
        temp.bannerImageUrlSpa = imageUrl;
        setData(temp);
      }
    }
  }, [bannerImageSpanishData]);

  useEffect(async () => {
    if (headerImageEnglishData) {
      const file = headerImageEnglishData;
      const imagePayload = new FormData();
      imagePayload.append('file', file);
      setLoading(true);
      const imageUrl = await uploadImage(imagePayload);
      setHeaderImageEnglishData(null);
      if (imageUrl) {
        const temp = JSON.parse(JSON.stringify(data));
        temp.headerImageUrlEng = imageUrl;
        setData(temp);
      }
    }
  }, [headerImageEnglishData]);

  useEffect(async () => {
    if (headerImageSpanishData) {
      const file = headerImageSpanishData;
      const imagePayload = new FormData();
      imagePayload.append('file', file);
      setLoading(true);
      const imageUrl = await uploadImage(imagePayload);
      setHeaderImageSpanishData(null);
      if (imageUrl) {
        const temp = JSON.parse(JSON.stringify(data));
        temp.headerImageUrlSpa = imageUrl;
        setData(temp);
      }
    }
  }, [headerImageSpanishData]);

  useEffect(async () => {
    if (brandImageEnglishData) {
      const file = brandImageEnglishData;
      const imagePayload = new FormData();
      imagePayload.append('file', file);
      setLoading(true);
      const imageUrl = await uploadImage(imagePayload);
      setBrandImageEnglishData(null);
      if (imageUrl) {
        const temp = JSON.parse(JSON.stringify(data));
        temp.brandLogoUrlEng = imageUrl;
        setData(temp);
      }
    }
  }, [brandImageEnglishData]);

  useEffect(async () => {
    if (brandImageSpanishData) {
      const file = brandImageSpanishData;
      const imagePayload = new FormData();
      imagePayload.append('file', file);
      setLoading(true);
      const imageUrl = await uploadImage(imagePayload);
      setBrandImageSpanishData(null);
      if (imageUrl) {
        const temp = JSON.parse(JSON.stringify(data));
        temp.brandLogoUrlSpa = imageUrl;
        setData(temp);
      }
    }
  }, [brandImageSpanishData]);

  useEffect(async () => {
    if (footerImageEnglishData) {
      const file = footerImageEnglishData;
      const imagePayload = new FormData();
      imagePayload.append('file', file);
      setLoading(true);
      const imageUrl = await uploadImage(imagePayload);
      setFooterImageEnglishData(null);
      if (imageUrl) {
        const temp = JSON.parse(JSON.stringify(data));
        temp.footerImageUrlEng = imageUrl;
        setData(temp);
      }
    }
  }, [footerImageEnglishData]);

  useEffect(async () => {
    if (footerImageSpanishData) {
      const file = footerImageSpanishData;
      const imagePayload = new FormData();
      imagePayload.append('file', file);
      setLoading(true);
      const imageUrl = await uploadImage(imagePayload);
      setFooterImageSpanishData(null);
      if (imageUrl) {
        const temp = JSON.parse(JSON.stringify(data));
        temp.footerImageUrlSpa = imageUrl;
        setData(temp);
      }
    }
  }, [footerImageSpanishData]);

  useEffect(async () => {
    if (backgroundImageEnglishData) {
      const file = backgroundImageEnglishData;
      const imagePayload = new FormData();
      imagePayload.append('file', file);
      setLoading(true);
      const imageUrl = await uploadImage(imagePayload);
      setBackgroundImageEnglishData(null);
      if (imageUrl) {
        const temp = JSON.parse(JSON.stringify(data));
        temp.backgroundImageUrlEng = imageUrl;
        setData(temp);
      }
    }
  }, [backgroundImageEnglishData]);

  useEffect(async () => {
    if (backgroundImageSpanishData) {
      const file = backgroundImageSpanishData;
      const imagePayload = new FormData();
      imagePayload.append('file', file);
      setLoading(true);
      const imageUrl = await uploadImage(imagePayload);
      setBackgroundImageSpanishData(null);
      if (imageUrl) {
        const temp = JSON.parse(JSON.stringify(data));
        temp.backgroundImageUrlSpa = imageUrl;
        setData(temp);
      }
    }
  }, [backgroundImageSpanishData]);

  useEffect(async () => {
    if (videoThumbnailEnglishData) {
      const file = videoThumbnailEnglishData;
      const imagePayload = new FormData();
      imagePayload.append('file', file);
      setLoading(true);
      const imageUrl = await uploadImage(imagePayload);
      setVideoThumbnailEnglishData(null);
      if (imageUrl) {
        const temp = JSON.parse(JSON.stringify(data));
        temp.videoThumbnailImageUrlEng = imageUrl;
        setData(temp);
      }
    }
  }, [videoThumbnailEnglishData]);

  useEffect(async () => {
    if (videoThumbnailSpanishData) {
      const file = videoThumbnailSpanishData;
      const imagePayload = new FormData();
      imagePayload.append('file', file);
      setLoading(true);
      const imageUrl = await uploadImage(imagePayload);
      setVideoThumbnailSpanishData(null);
      if (imageUrl) {
        const temp = JSON.parse(JSON.stringify(data));
        temp.videoThumbnailImageUrlSpa = imageUrl;
        setData(temp);
      }
    }
  }, [videoThumbnailSpanishData]);

  useEffect(async () => {
    if (videoEnglishData) {
      const file = videoEnglishData;
      const imagePayload = new FormData();
      imagePayload.append('file', file);
      setLoading(true);
      const imageUrl = await uploadImage(imagePayload);
      setVideoEnglishData(null);
      if (imageUrl) {
        const temp = JSON.parse(JSON.stringify(data));
        temp.videoImageUrlEng = imageUrl;
        setData(temp);
      }
    }
  }, [videoEnglishData]);

  useEffect(async () => {
    if (videoSpanishData) {
      const file = videoSpanishData;
      const imagePayload = new FormData();
      imagePayload.append('file', file);
      setLoading(true);
      const imageUrl = await uploadImage(imagePayload);
      setVideoSpanishData(null);
      if (imageUrl) {
        const temp = JSON.parse(JSON.stringify(data));
        temp.videoImageUrlSpa = imageUrl;
        setData(temp);
      }
    }
  }, [videoSpanishData]);

  return (
    <Drawer
      title={`${data && data.id ? t('update') : t('create')} Campaign`}
      placement="right"
      closable
      onClose={() => {
        setShowCreateCampaignDrawer(false);
        setShowEditCampaignDrawer(false);
      }}
      open={selectedCampaign}
      width={400}
    >
      {data && (
        <>
          <div style={{ marginBottom: 10 }}>
            <strong>{t('title')}:</strong>
            <Input
              disabled={loading}
              placeholder="Title"
              onChange={(obj) => {
                const temp = JSON.parse(JSON.stringify(data));
                temp.title = obj.target.value;
                setData(temp);
              }}
              value={data.title}
            />
          </div>
          <Collapse defaultActiveKey={['1']}>
            <Panel header="General Data" key="1">
              {data.id && (
                <div style={{ marginBottom: 10 }}>
                  <strong>{t('active')}:</strong>
                  <Switch
                    checked={data.isActive}
                    disabled={loading}
                    onChange={(checked) => {
                      const temp = JSON.parse(JSON.stringify(data));
                      temp.isActive = checked;
                      setData(temp);
                    }}
                  />
                </div>
              )}
              <div style={{ marginBottom: 10 }}>
                <strong>{t('name_english')}:</strong>
                <Input
                  disabled={loading}
                  placeholder={t('name_english')}
                  onChange={(obj) => {
                    const temp = JSON.parse(JSON.stringify(data));
                    temp.nameEng = obj.target.value;
                    setData(temp);
                  }}
                  value={data.nameEng}
                />
              </div>
              <div style={{ marginBottom: 10 }}>
                <strong>{t('name_spanish')}:</strong>
                <Input
                  disabled={loading}
                  placeholder={t('name_spanish')}
                  onChange={(obj) => {
                    const temp = JSON.parse(JSON.stringify(data));
                    temp.nameSpa = obj.target.value;
                    setData(temp);
                  }}
                  value={data.nameSpa}
                />
              </div>
              <div style={{ marginBottom: 10 }}>
                <strong>{t('description_english')}:</strong>
                <Input
                  disabled={loading}
                  placeholder={t('description_english')}
                  onChange={(obj) => {
                    const temp = JSON.parse(JSON.stringify(data));
                    temp.descriptionEng = obj.target.value;
                    setData(temp);
                  }}
                  value={data.descriptionEng}
                />
              </div>
              <div style={{ marginBottom: 10 }}>
                <strong>{t('description_spanish')}:</strong>
                <Input
                  disabled={loading}
                  placeholder={t('description_spanish')}
                  onChange={(obj) => {
                    const temp = JSON.parse(JSON.stringify(data));
                    temp.descriptionSpa = obj.target.value;
                    setData(temp);
                  }}
                  value={data.descriptionSpa}
                />
              </div>
              <div style={{ marginBottom: 10 }}>
                <strong>{'Start Date -> End Date:'}</strong>
                <RangePicker
                  showTime={{ format: 'HH:mm' }}
                  format="YYYY-MM-DD HH:mm"
                  defaultValue={[
                    dayjs(data.startDate, 'YYYY-MM-DD HH:mm'),
                    dayjs(data.endDate, 'YYYY-MM-DD HH:mm')
                  ]}
                  onChange={(date, dateString) => {
                    const temp = JSON.parse(JSON.stringify(data));
                    const start = dateString[0];
                    const end = dateString[1];
                    temp.startDate = start;
                    temp.endDate = end;
                    setData(temp);
                  }}
                />
              </div>
            </Panel>
            <Panel header={t('colors')} key="2">
              <Collapse>
                <Panel header="Primary" key="PrimaryColor">
                  <div style={{ marginBottom: 10 }}>
                    <strong>Base</strong>
                    <Input
                      placeholder="Color Hex"
                      style={{
                        background: data.primaryColor,
                        color: data.primaryTextColor
                      }}
                      onChange={(obj) => onChangePrimaryColor(obj)}
                      value={data.primaryColor}
                    />
                    <TwitterPicker
                      color={data.primaryColor}
                      onChangeComplete={(obj) => onChangePrimaryColor(obj)}
                    />
                  </div>
                  <div style={{ marginBottom: 10 }}>
                    <strong>{t('text_color')}</strong>
                    <Input
                      placeholder="Color Hex"
                      style={{
                        background: data.primaryColor,
                        color: data.primaryTextColor
                      }}
                      onChange={(obj) => onChangePrimaryTextColor(obj)}
                      value={data.primaryTextColor}
                    />
                    <TwitterPicker
                      color={data.primaryTextColor}
                      onChangeComplete={(obj) => onChangePrimaryTextColor(obj)}
                    />
                  </div>
                </Panel>
                <Panel header="Secondary" key="SecondaryColor">
                  <div style={{ marginBottom: 10 }}>
                    <strong>Base</strong>
                    <Input
                      placeholder="Color Hex"
                      style={{
                        background: data.secondaryColor,
                        color: data.secondaryTextColor
                      }}
                      onChange={(obj) => onChangeSecondaryColor(obj)}
                      value={data.secondaryColor}
                    />
                    <TwitterPicker
                      color={data.secondaryColor}
                      onChangeComplete={(obj) => onChangeSecondaryColor(obj)}
                    />
                  </div>
                  <div style={{ marginBottom: 10 }}>
                    <strong>{t('text_color')}</strong>
                    <Input
                      placeholder="Color Hex"
                      style={{
                        background: data.secondaryColor,
                        color: data.secondaryTextColor
                      }}
                      onChange={(obj) => onChangeSecondaryTextColor(obj)}
                      value={data.secondaryTextColor}
                    />
                    <TwitterPicker
                      color={data.secondaryTextColor}
                      onChangeComplete={(obj) => onChangeSecondaryTextColor(obj)}
                    />
                  </div>
                </Panel>
                <Panel header="Accent" key="AccentColor">
                  <div style={{ marginBottom: 10 }}>
                    <strong>Base</strong>
                    <Input
                      placeholder="Color Hex"
                      style={{
                        background: data.accentColor,
                        color: data.accentTextColor
                      }}
                      onChange={(obj) => onChangeAccentColor(obj)}
                      value={data.accentColor}
                    />
                    <TwitterPicker
                      color={data.accentColor}
                      onChangeComplete={(obj) => onChangeAccentColor(obj)}
                    />
                  </div>
                  <div style={{ marginBottom: 10 }}>
                    <strong>{t('text_color')}</strong>
                    <Input
                      placeholder="Color Hex"
                      style={{
                        background: data.accentColor,
                        color: data.accentTextColor
                      }}
                      onChange={(obj) => onChangeAccentTextColor(obj)}
                      value={data.accentTextColor}
                    />
                    <TwitterPicker
                      color={data.accentTextColor}
                      onChangeComplete={(obj) => onChangeAccentTextColor(obj)}
                    />
                  </div>
                </Panel>
                <Panel header="Background" key="BackgroundColor">
                  <div style={{ marginBottom: 10 }}>
                    <strong>Base</strong>
                    <Input
                      placeholder="Color Hex"
                      style={{
                        background: data.backgroundColor,
                        color: data.backgroundTextColor
                      }}
                      onChange={(obj) => onChangeBackgroundColor(obj)}
                      value={data.backgroundColor}
                    />
                    <TwitterPicker
                      color={data.backgroundColor}
                      onChangeComplete={(obj) => onChangeBackgroundColor(obj)}
                    />
                  </div>
                  <div style={{ marginBottom: 10 }}>
                    <strong>{t('text_color')}</strong>
                    <Input
                      placeholder="Color Hex"
                      style={{
                        background: data.backgroundColor,
                        color: data.backgroundTextColor
                      }}
                      onChange={(obj) => onChangeBackgroundTextColor(obj)}
                      value={data.backgroundTextColor}
                    />
                    <TwitterPicker
                      color={data.backgroundTextColor}
                      onChangeComplete={(obj) => onChangeBackgroundTextColor(obj)}
                    />
                  </div>
                </Panel>
              </Collapse>
            </Panel>
            <Panel header="Media" key="3">
              <Collapse>
                <Panel header={t('brand')} key="BrandImages">
                  <div style={{ marginBottom: 10 }}>
                    <Tooltip title={t('image_requirements')}>
                      <strong>{t('english')}:</strong>
                    </Tooltip>
                    <Input
                      disabled={loading}
                      placeholder="Url"
                      onChange={(obj) => {
                        const temp = JSON.parse(JSON.stringify(data));
                        temp.brandLogoUrlEng = obj.target.value;
                        setData(temp);
                      }}
                      value={data.brandLogoUrlEng}
                    />
                    <Dragger {...brandEnglishDraggerProps}>
                      <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                      </p>
                      <p
                        className="ant-upload-text"
                        style={{ margin: 10 }}
                      >
                        {t('click_or_drag_content')}
                      </p>
                    </Dragger>
                  </div>
                  <div style={{ marginBottom: 10 }}>
                    <Tooltip title={t('image_requirements')}>
                      <strong>{t('spanish')}:</strong>
                    </Tooltip>
                    <Input
                      disabled={loading}
                      placeholder="Url"
                      onChange={(obj) => {
                        const temp = JSON.parse(JSON.stringify(data));
                        temp.brandLogoUrlSpa = obj.target.value;
                        setData(temp);
                      }}
                      value={data.brandLogoUrlSpa}
                    />
                    <Dragger {...brandSpanishDraggerProps}>
                      <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                      </p>
                      <p
                        className="ant-upload-text"
                        style={{ margin: 10 }}
                      >
                        {t('click_or_drag_content')}
                      </p>
                    </Dragger>
                  </div>
                </Panel>
                <Panel header="Header" key="HeaderImages">
                  <div style={{ marginBottom: 10 }}>
                    <Tooltip title={t('image_requirements')}>
                      <strong>{t('english')}:</strong>
                    </Tooltip>
                    <Input
                      disabled={loading}
                      placeholder="Url"
                      onChange={(obj) => {
                        const temp = JSON.parse(JSON.stringify(data));
                        temp.headerImageUrlEng = obj.target.value;
                        setData(temp);
                      }}
                      value={data.headerImageUrlEng}
                    />
                    <Dragger {...headerEnglishDraggerProps}>
                      <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                      </p>
                      <p
                        className="ant-upload-text"
                        style={{ margin: 10 }}
                      >
                        {t('click_or_drag_content')}
                      </p>
                    </Dragger>
                  </div>
                  <div style={{ marginBottom: 10 }}>
                    <Tooltip title={t('image_requirements')}>
                      <strong>{t('spanish')}:</strong>
                    </Tooltip>
                    <Input
                      disabled={loading}
                      placeholder="Url"
                      onChange={(obj) => {
                        const temp = JSON.parse(JSON.stringify(data));
                        temp.headerImageUrlSpa = obj.target.value;
                        setData(temp);
                      }}
                      value={data.headerImageUrlSpa}
                    />
                    <Dragger {...headerSpanishDraggerProps}>
                      <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                      </p>
                      <p
                        className="ant-upload-text"
                        style={{ margin: 10 }}
                      >
                        {t('click_or_drag_content')}
                      </p>
                    </Dragger>
                  </div>
                </Panel>
                <Panel header="Banner" key="BannerImages">
                  <div style={{ marginBottom: 10 }}>
                    <Tooltip title={t('image_requirements')}>
                      <strong>{t('english')}:</strong>
                    </Tooltip>
                    <Input
                      disabled={loading}
                      placeholder="Url"
                      onChange={(obj) => {
                        const temp = JSON.parse(JSON.stringify(data));
                        temp.bannerImageUrlEng = obj.target.value;
                        setData(temp);
                      }}
                      value={data.bannerImageUrlEng}
                    />
                    <Dragger {...bannerEnglishDraggerProps}>
                      <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                      </p>
                      <p
                        className="ant-upload-text"
                        style={{ margin: 10 }}
                      >
                        {t('click_or_drag_content')}
                      </p>
                    </Dragger>
                  </div>
                  <div style={{ marginBottom: 10 }}>
                    <Tooltip title={t('image_requirements')}>
                      <strong>{t('spanish')}:</strong>
                    </Tooltip>
                    <Input
                      disabled={loading}
                      placeholder="Url"
                      onChange={(obj) => {
                        const temp = JSON.parse(JSON.stringify(data));
                        temp.bannerImageUrlSpa = obj.target.value;
                        setData(temp);
                      }}
                      value={data.bannerImageUrlSpa}
                    />
                    <Dragger {...bannerSpanishDraggerProps}>
                      <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                      </p>
                      <p
                        className="ant-upload-text"
                        style={{ margin: 10 }}
                      >
                        {t('click_or_drag_content')}
                      </p>
                    </Dragger>
                  </div>
                </Panel>
                <Panel header="Footer" key="FooterImages">
                  <div style={{ marginBottom: 10 }}>
                    <Tooltip title={t('image_requirements')}>
                      <strong>{t('english')}:</strong>
                    </Tooltip>
                    <Input
                      disabled={loading}
                      placeholder="Url"
                      onChange={(obj) => {
                        const temp = JSON.parse(JSON.stringify(data));
                        temp.footerImageUrlEng = obj.target.value;
                        setData(temp);
                      }}
                      value={data.footerImageUrlEng}
                    />
                    <Dragger {...footerEnglishDraggerProps}>
                      <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                      </p>
                      <p
                        className="ant-upload-text"
                        style={{ margin: 10 }}
                      >
                        {t('click_or_drag_content')}
                      </p>
                    </Dragger>
                  </div>
                  <div style={{ marginBottom: 10 }}>
                    <Tooltip title={t('image_requirements')}>
                      <strong>{t('spanish')}:</strong>
                    </Tooltip>
                    <Input
                      disabled={loading}
                      placeholder="Url"
                      onChange={(obj) => {
                        const temp = JSON.parse(JSON.stringify(data));
                        temp.footerImageUrlSpa = obj.target.value;
                        setData(temp);
                      }}
                      value={data.footerImageUrlSpa}
                    />
                    <Dragger {...footerSpanishDraggerProps}>
                      <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                      </p>
                      <p
                        className="ant-upload-text"
                        style={{ margin: 10 }}
                      >
                        {t('click_or_drag_content')}
                      </p>
                    </Dragger>
                  </div>
                </Panel>
                <Panel header="Background" key="BackgroundImages">
                  <div style={{ marginBottom: 10 }}>
                    <Tooltip title={t('image_requirements')}>
                      <strong>{t('english')}:</strong>
                    </Tooltip>
                    <Input
                      disabled={loading}
                      placeholder="Url"
                      onChange={(obj) => {
                        const temp = JSON.parse(JSON.stringify(data));
                        temp.backgroundImageUrlEng = obj.target.value;
                        setData(temp);
                      }}
                      value={data.backgroundImageUrlEng}
                    />
                    <Dragger {...backgroundEnglishDraggerProps}>
                      <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                      </p>
                      <p
                        className="ant-upload-text"
                        style={{ margin: 10 }}
                      >
                        {t('click_or_drag_content')}
                      </p>
                    </Dragger>
                  </div>
                  <div style={{ marginBottom: 10 }}>
                    <Tooltip title={t('image_requirements')}>
                      <strong>{t('spanish')}:</strong>
                    </Tooltip>
                    <Input
                      disabled={loading}
                      placeholder="Url"
                      onChange={(obj) => {
                        const temp = JSON.parse(JSON.stringify(data));
                        temp.backgroundImageUrlSpa = obj.target.value;
                        setData(temp);
                      }}
                      value={data.backgroundImageUrlSpa}
                    />
                    <Dragger {...backgroundSpanishDraggerProps}>
                      <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                      </p>
                      <p
                        className="ant-upload-text"
                        style={{ margin: 10 }}
                      >
                        {t('click_or_drag_content')}
                      </p>
                    </Dragger>
                  </div>
                </Panel>
                <Panel header="Video" key="VideoImages">
                  <div style={{ marginBottom: 10 }}>
                    <Tooltip title={t('image_requirements')}>
                      <strong>{t('english')}:</strong>
                    </Tooltip>
                    <Input
                      disabled={loading}
                      placeholder="Url"
                      onChange={(obj) => {
                        const temp = JSON.parse(JSON.stringify(data));
                        temp.videoImageUrlEng = obj.target.value;
                        setData(temp);
                      }}
                      value={data.videoImageUrlEng}
                    />
                    <Dragger {...videoEnglishDraggerProps}>
                      <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                      </p>
                      <p
                        className="ant-upload-text"
                        style={{ margin: 10 }}
                      >
                        {t('click_or_drag_content')}
                      </p>
                    </Dragger>
                  </div>
                  <div style={{ marginBottom: 10 }}>
                    <Tooltip title={t('image_requirements')}>
                      <strong>{t('spanish')}:</strong>
                    </Tooltip>
                    <Input
                      disabled={loading}
                      placeholder="Url"
                      onChange={(obj) => {
                        const temp = JSON.parse(JSON.stringify(data));
                        temp.videoImageUrlSpa = obj.target.value;
                        setData(temp);
                      }}
                      value={data.videoImageUrlSpa}
                    />
                    <Dragger {...videoSpanishDraggerProps}>
                      <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                      </p>
                      <p
                        className="ant-upload-text"
                        style={{ margin: 10 }}
                      >
                        {t('click_or_drag_content')}
                      </p>
                    </Dragger>
                  </div>
                </Panel>
                <Panel header="Video Thumbnail" key="VideoThumb">
                  <div style={{ marginBottom: 10 }}>
                    <Tooltip title={t('image_requirements')}>
                      <strong>{t('english')}:</strong>
                    </Tooltip>
                    <Input
                      disabled={loading}
                      placeholder="Url"
                      onChange={(obj) => {
                        const temp = JSON.parse(JSON.stringify(data));
                        temp.videoThumbnailImageUrlEng = obj.target.value;
                        setData(temp);
                      }}
                      value={data.videoThumbnailImageUrlEng}
                    />
                    <Dragger {...videoThumbnailEnglishDraggerProps}>
                      <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                      </p>
                      <p
                        className="ant-upload-text"
                        style={{ margin: 10 }}
                      >
                        {t('click_or_drag_content')}
                      </p>
                    </Dragger>
                  </div>
                  <div style={{ marginBottom: 10 }}>
                    <Tooltip title={t('image_requirements')}>
                      <strong>{t('spanish')}:</strong>
                    </Tooltip>
                    <Input
                      disabled={loading}
                      placeholder="Url"
                      onChange={(obj) => {
                        const temp = JSON.parse(JSON.stringify(data));
                        temp.videoThumbnailImageUrlSpa = obj.target.value;
                        setData(temp);
                      }}
                      value={data.videoThumbnailImageUrlSpa}
                    />
                    <Dragger {...videoThumbnailSpanishDraggerProps}>
                      <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                      </p>
                      <p
                        className="ant-upload-text"
                        style={{ margin: 10 }}
                      >
                        {t('click_or_drag_content')}
                      </p>
                    </Dragger>
                  </div>
                </Panel>
              </Collapse>
            </Panel>
            <Panel header="Analytics" key="4">
              <div style={{ marginBottom: 10 }}>
                <strong>Google Analytics Tracking Id:</strong>
                <Input
                  disabled={loading}
                  placeholder=""
                  onChange={(obj) => {
                    const temp = JSON.parse(JSON.stringify(data));
                    temp.googleAnalyticsTrackingId = obj.target.value;
                    setData(temp);
                  }}
                  value={data.googleAnalyticsTrackingId}
                />
              </div>
              <div style={{ marginBottom: 10 }}>
                <strong>Google Tag Manager Id:</strong>
                <Input
                  disabled={loading}
                  placeholder=""
                  onChange={(obj) => {
                    const temp = JSON.parse(JSON.stringify(data));
                    temp.googleTagManagerId = obj.target.value;
                    setData(temp);
                  }}
                  value={data.googleTagManagerId}
                />
              </div>
              <div style={{ marginBottom: 10 }}>
                <strong>Facebook Pixel Id:</strong>
                <Input
                  disabled={loading}
                  placeholder=""
                  onChange={(obj) => {
                    const temp = JSON.parse(JSON.stringify(data));
                    temp.facebookPixelId = obj.target.value;
                    setData(temp);
                  }}
                  value={data.facebookPixelId}
                />
              </div>
            </Panel>
          </Collapse>
          <div
            style={{
              float: 'right',
              marginTop: '10px'
            }}
          >
            <Button
              style={{
                marginLeft: '10px'
              }}
              disabled={loading}
              type="primary"
              onClick={() => { submit(); }}
            >
              {data.id ? 'Update' : 'Create'}
            </Button>
          </div>
        </>
      )}
    </Drawer>
  );
}

export default EditProductCampaign;
