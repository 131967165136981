import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useRecoilValueLoadable,
  useRecoilState,
  useSetRecoilState,
  useRecoilValue
} from 'recoil';
import {
  Tabs,
  Dropdown,
  Menu,
  Button,
  Input,
  Table,
  Popconfirm,
  notification,
  Tooltip,
  Avatar,
  Drawer,
  Typography
} from 'antd';
import { Comment } from '@ant-design/compatible';
import { PageHeader } from '@ant-design/pro-layout';
import moment from 'moment';
import { BuildOutlined, SyncOutlined } from '@ant-design/icons';
import {
  profileAtom,
  selectedPromoCodesAtom,
  refreshPromoCodesAtom,
  showEditPromoCodeAtom,
  timezoneAtom,
  showHelperInfoAtom
} from '../../../atoms/Atoms';
import api from '../../../api/api';
import Grocefy from '../../../assets/images/grocefyLogoAlone.png';
import EditBusinessPromoCode from './shared-components/EditBusinessPromoCode';
import EditUserPromoCode from './shared-components/EditUserPromoCode';

function PromoCodes() {
  const { t } = useTranslation();
  const { Search } = Input;
  const timezone = useRecoilValue(timezoneAtom);
  const profile = useRecoilValueLoadable(profileAtom);
  const setShowEditPromoCode = useSetRecoilState(showEditPromoCodeAtom);
  const setSelectedPromoCode = useSetRecoilState(selectedPromoCodesAtom);
  const [refreshPromoCodes, setRefreshPromoCodes] = useRecoilState(refreshPromoCodesAtom);
  const [userCodes, setUserCodes] = useState([]);
  const [loadingHistory, setLoadingHistory] = useState(false);
  const [loadingUserCodes, setLoadingUserCodes] = useState(false);
  const [businessCodes, setBusinessCodes] = useState([]);
  const [loadingBusinessCodes, setLoadingBusinessCodes] = useState(false);
  const [selectedTab, setSelectedTab] = useState('0');
  const [businessCodeSearchString, setBusinessCodeSearchString] = useState('');
  const [userCodeSearchString, setUserCodeSearchString] = useState('');
  const setShowHelperInfo = useSetRecoilState(showHelperInfoAtom);
  const [businessCodePageSize, setBusinessCodePageSize] = useState(20);
  const [userCodePageSize, setUserCodePageSize] = useState(20);
  const [businessCodeCurrentPage, setBusinessCodeCurrentPage] = useState(0);
  const [userCodeCurrentPage, setUserCodeCurrentPage] = useState(0);
  const [businessCodesCount, setBusinessCodesCount] = useState(0);
  const [userCodesCount, setUserCodesCount] = useState(0);
  const [redemptions, setRedemptions] = useState(null);
  const { Paragraph } = Typography;

  const isAdmin =
    profile.contents && profile?.contents?.roles?.includes('SuperAdmin');
  const isMarketing =
    isAdmin ||
    (profile?.contents && profile?.contents?.roles?.includes('Marketing'));

  if (profile && !isMarketing) {
    window.location.href = '/';
  }

  function businessCodeOnTableChange(page, pageSize) {
    if (page - 1 !== businessCodeCurrentPage) {
      setBusinessCodeCurrentPage(page - 1);
    } else {
      setBusinessCodePageSize(pageSize);
    }
  }

  function userCodeOnTableChange(page, pageSize) {
    if (page - 1 !== userCodeCurrentPage) {
      setUserCodeCurrentPage(page - 1);
    } else {
      setUserCodePageSize(pageSize);
    }
  }

  function showMessage(message) {
    notification.open({
      message: '',
      description: (
        <Comment
          author={<span>Grocefy</span>}
          avatar={<Avatar src={Grocefy} alt="grocefy" />}
          content={
            <p className="text-sm">
              {message}
            </p>
          }
          datetime={
            <Tooltip title={moment().format('YYYY-MM-DD HH:mm:ss')}>
              <span>{moment().fromNow()}</span>
            </Tooltip>
          }
        />
      ),
    });
  }

  function getBusinessCodes() {
    setLoadingBusinessCodes(true);
    api.post('businesses/search/discountcode/stores', {
      page: 0,
      size: 10,
      businessId: '',
      query: businessCodeSearchString
    })
      .then((response) => {
        setLoadingBusinessCodes(false);
        if (response.data.error) {
          showMessage(response.data.error);
        } else {
          setBusinessCodes(response.data.data.coupons);
          setBusinessCodesCount(response.data.data.count);
        }
      })
      .catch((err) => {
        setLoadingBusinessCodes(false);
        showMessage(err.message);
      });
  }

  function getHistory(id) {
    setLoadingHistory(true);
    api.get(`businesses/discountcode/redemptions/${id}`)
      .then((response) => {
        setLoadingHistory(false);
        if (response.data.error) {
          showMessage(response.data.error);
        } else {
          setRedemptions({
            ...redemptions,
            history: response.data.data,
            loaded: true
          });
        }
      })
      .catch((err) => {
        setLoadingHistory(false);
        showMessage(err.message);
      });
  }

  function getUserCodes() {
    setLoadingUserCodes(true);
    api.post('businesses/search/discountcode/users', {
      page: 0,
      size: 10,
      query: businessCodeSearchString
    })
      .then((response) => {
        setLoadingUserCodes(false);
        if (response.data.error) {
          showMessage(response.data.error);
        } else {
          setUserCodes(response.data.data.coupons);
          setUserCodesCount(response.data.data.count);
        }
      })
      .catch((err) => {
        setLoadingUserCodes(false);
        showMessage(err.message);
      });
  }

  function getCodes() {
    if (selectedTab === '0') {
      getBusinessCodes();
    } else {
      getUserCodes();
    }
  }

  function archiveCode(coupon) {
    if (selectedTab === '0') {
      setLoadingBusinessCodes(true);
    } else {
      setLoadingUserCodes(true);
    }
    api.post(`businesses/discountcode/archive/${coupon.id}`, { })
      .then((response) => {
        if (selectedTab === '0') {
          setLoadingBusinessCodes(true);
        } else {
          setLoadingUserCodes(true);
        }
        if (response.data.error) {
          showMessage(response.data.error);
        } else {
          setRefreshPromoCodes(true);
          if (response.data.message) {
            showMessage(response.data.message);
          } else {
            showMessage(t('coupon_archived'));
          }
        }
      })
      .catch((err) => {
        if (selectedTab === '0') {
          setLoadingBusinessCodes(true);
        } else {
          setLoadingUserCodes(true);
        }
        showMessage(err.message);
      });
  }

  useEffect(() => {
    if (profile) {
      if (!isMarketing) {
        window.location.href = '/';
      }
    }
  }, [profile]);

  useEffect(() => {
    if (refreshPromoCodes) {
      setRefreshPromoCodes(false);
      getCodes();
    }
  }, [refreshPromoCodes]);

  useEffect(() => {
    if (redemptions?.promoCode && !redemptions?.loaded) {
      getHistory(redemptions?.promoCode?.id);
    }
  }, [redemptions]);

  useEffect(async () => {
    await setBusinessCodeSearchString('');
    await setUserCodeSearchString('');
    getCodes();
  }, []);

  return (
    <>
      <PageHeader
        title={t('promo_codes')}
        extra={[
          <Dropdown
            key="createdropdown"
            overlay={
              <Menu>
                <Menu.Item
                  key="create_user_code"
                  disabled={loadingBusinessCodes || loadingUserCodes}
                  className="text-xs flex items-center"
                  onClick={() => {
                    setSelectedPromoCode({});
                    setShowEditPromoCode(1);
                  }}
                >
                  {t('create_user_code')}
                </Menu.Item>
                <Menu.Item
                  key="create_business_code"
                  disabled={loadingBusinessCodes || loadingUserCodes}
                  className="text-xs flex items-center"
                  onClick={() => {
                    setSelectedPromoCode({});
                    setShowEditPromoCode(2);
                  }}
                >
                  {t('create_business_code')}
                </Menu.Item>
              </Menu>
            }
            arrow="true"
          >
            <Button
              type="primary"
              size="small"
              icon={<BuildOutlined />}
              disabled={loadingBusinessCodes || loadingUserCodes}
            >
              {t('create')}
            </Button>
          </Dropdown>,
          <Button
            type="primary"
            size="small"
            icon={<SyncOutlined />}
            key="reload"
            loading={loadingBusinessCodes || loadingUserCodes}
            onClick={() => setRefreshPromoCodes(true)}
          >
            {t('reload')}
          </Button>
        ]}
      />
      <div className="flex flex-row">
        <p className="ml-6 text-gray-600 text-xs w-3/4">
          {t('promo_codes_help')}
          <Button
            className="lowercase text-xs p-0 ml-1"
            type="link"
            size="small"
            onClick={() => {
              setShowHelperInfo('promocodes');
            }}
          >
            {t('more')}...
          </Button>
        </p>
      </div>
      <Tabs defaultActiveKey={0} activeKey={selectedTab} onChange={(key) => setSelectedTab(key)}>
        <Tabs.TabPane key={0} tab={t('business_codes')}>
          <Search
            placeholder={`${t('search')}...`}
            allowClear
            className="mb-4"
            onChange={(obj) => {
              setBusinessCodeSearchString(obj.target.value);
            }}
            onSearch={() => getCodes()}
            value={businessCodeSearchString}
          />
          <Table
            bordered
            size="small"
            loading={loadingBusinessCodes}
            dataSource={businessCodes}
            rowKey="id"
            columns={[
              {
                title: t('code'),
                dataIndex: 'code',
                align: 'left',
                className: 'text-xs',
                render: (text) => (
                  <span>{text}</span>
                ),
              },
              {
                title: t('store'),
                dataIndex: 'businessName',
                align: 'left',
                className: 'text-xs',
                render: (text) => (
                  <span>{text}</span>
                ),
              },
              {
                title: t('value'),
                dataIndex: 'value',
                align: 'center',
                className: 'text-xs',
                render: (text) => (
                  <span>${text.toFixed(2)}</span>
                ),
              },
              {
                title: t('expires'),
                dataIndex: 'expiresAt',
                align: 'left',
                className: 'text-xs',
                render: (text) => (
                  <span>
                    <div>
                      <div>
                        {text ? moment(`${text}+0000`).add(-1, 'days')
                          .format('MMMM Do YYYY')
                          : 'N/A'}
                      </div>
                      <div className="text-blue">
                        ({moment(`${text}+0000`).tz(timezone).fromNow()})
                      </div>
                    </div>
                  </span>
                ),
              },
              {
                title: '',
                align: 'center',
                className: 'text-xs',
                render: (row) => (
                  <div className="flex">
                    <Button
                      size="small"
                      type="primary"
                      onClick={() => {
                        setSelectedPromoCode(row);
                        setShowEditPromoCode(2);
                      }}
                    >
                      {t('edit')}
                    </Button>
                    <Popconfirm
                      title={t('archive_coupon_prompt')}
                      onConfirm={() => archiveCode(row)}
                      okText={t('yes')}
                      cancelText="No"
                    >
                      <Button
                        className="ml-4"
                        type="primary"
                        danger
                        size="small"
                      >
                        {t('archive')}
                      </Button>
                    </Popconfirm>
                    <Button
                      className="ml-4"
                      size="small"
                      type="primary"
                      onClick={() => setRedemptions({ ...redemptions, promoCode: row })}
                    >
                      {t('history')}
                    </Button>
                  </div>
                ),
              }
            ]}
            pagination={{
              pageSize: businessCodePageSize,
              showSizeChanger: true,
              defaultCurrent: 0,
              current: businessCodeCurrentPage + 1,
              total: businessCodesCount,
              onChange: businessCodeOnTableChange,
            }}
          />
        </Tabs.TabPane>
        <Tabs.TabPane key={1} tab={t('user_codes')}>
          <Search
            placeholder={`${t('search')}...`}
            allowClear
            className="mb-4"
            onChange={(obj) => {
              setUserCodeSearchString(obj.target.value);
            }}
            onSearch={() => getCodes()}
            value={userCodeSearchString}
          />
          <Table
            bordered
            size="small"
            scroll={{ x: 1000 }}
            loading={loadingUserCodes}
            dataSource={userCodes}
            rowKey="id"
            columns={[
              {
                title: t('code'),
                dataIndex: 'code',
                align: 'left',
                className: 'text-xs',
                render: (text) => (
                  <Typography.Text copyable>{text}</Typography.Text>
                ),
              },
              {
                title: t('customer'),
                dataIndex: 'customerName',
                align: 'left',
                className: 'text-xs',
                render: (text) => (
                  <span>{text}</span>
                ),
              },
              {
                title: t('store'),
                align: 'left',
                className: 'text-xs',
                render: (row) => (
                  <span>{row.businessId ? row.businessName : 'N/A'}</span>
                ),
              },
              {
                title: t('value'),
                dataIndex: 'value',
                align: 'center',
                className: 'text-xs',
                render: (text) => (
                  <span>${text.toFixed(2)}</span>
                ),
              },
              {
                title: t('expires'),
                dataIndex: 'expiresAt',
                align: 'left',
                className: 'text-xs',
                render: (text) => (
                  <span>
                    <div>
                      <div>
                        {text ? moment(`${text}+0000`).add(-1, 'days')
                          .format('MMMM Do YYYY')
                          : 'N/A'}
                      </div>
                      <div className="text-blue">
                        ({moment(`${text}+0000`).tz(timezone).fromNow()})
                      </div>
                    </div>
                  </span>
                ),
              },
              {
                title: '',
                align: 'center',
                className: 'text-xs',
                render: (row) => (
                  <div className="flex">
                    <Button
                      size="small"
                      type="primary"
                      onClick={() => {
                        setSelectedPromoCode(row);
                        setShowEditPromoCode(2);
                      }}
                    >
                      {t('edit')}
                    </Button>
                    <Popconfirm
                      title={t('archive_coupon_prompt')}
                      onConfirm={() => archiveCode(row)}
                      okText={t('yes')}
                      cancelText="No"
                    >
                      <Button
                        className="ml-4"
                        type="primary"
                        danger
                        size="small"
                      >
                        {t('archive')}
                      </Button>
                    </Popconfirm>
                    <Button
                      className="ml-4"
                      size="small"
                      type="primary"
                      onClick={() => setRedemptions({ ...redemptions, promoCode: row })}
                    >
                      {t('history')}
                    </Button>
                  </div>
                ),
              }
            ]}
            pagination={{
              pageSize: userCodePageSize,
              showSizeChanger: true,
              defaultCurrent: 0,
              current: userCodeCurrentPage + 1,
              total: userCodesCount,
              onChange: userCodeOnTableChange,
            }}
          />
        </Tabs.TabPane>
      </Tabs>
      <EditBusinessPromoCode />
      <EditUserPromoCode />
      <Drawer
        title={redemptions?.promoCode?.code}
        placement="right"
        closable
        onClose={() => setRedemptions({ history: [], promoCode: null, loaded: false })}
        open={redemptions?.promoCode}
        width={850}
      >
        <Typography>{t('coupons_used')}: {redemptions?.history?.length}</Typography>
        <Typography>{t('value')}: ${redemptions?.promoCode?.value * redemptions?.history?.length}</Typography>
        <br />
        <Table
          loading={loadingHistory}
          bordered
          size="middle"
          rowKey="createdAt"
          dataSource={redemptions?.history}
          columns={[
            {
              title: t('business'),
              align: 'left',
              className: 'text-sm',
              render: (row) => (
                <span>{row.businessName}</span>
              ),
            },
            {
              title: t('code'),
              align: 'left',
              width: 135,
              className: 'text-xs',
              render: (row) => (
                <div>
                  <Typography.Text copyable>{row.confirmationCode}</Typography.Text>
                </div>
              ),
            },
            {
              title: t('customer'),
              align: 'left',
              className: 'text-sm',
              render: (row) => (
                <span>{row.customerFirstName} {row.customerLastName}</span>
              ),
            },
            {
              title: t('email'),
              align: 'left',
              width: 220,
              className: 'text-xs',
              render: (row) => (
                <Typography.Text copyable>{row.customerEmail}</Typography.Text>
              ),
            },
            {
              title: t('date'),
              align: 'left',
              className: 'text-sm',
              render: (row) => (
                <span>
                  {moment(`${row.createdAt}+0000`)
                    .tz(timezone)
                    .format('MMM Do YYYY, h:mm:ss a')}
                </span>
              ),
            }
          ]}
        />
      </Drawer>
    </>
  );
}

export default PromoCodes;
