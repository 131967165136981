import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import {
  useRecoilState,
  useRecoilValue,
} from 'recoil';
import { useTranslation } from 'react-i18next';
import {
  Drawer,
  Table,
  Button,
  Popover,
  Input,
  Avatar,
  Tooltip,
  notification,
  Popconfirm
} from 'antd';
import moment from 'moment';
import { Comment } from '@ant-design/compatible';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faTrash } from '@fortawesome/free-solid-svg-icons';
import {
  setSelectedMasterItemAtom,
  setSelectedBusinessItemAtom,
  showItemUpcsAtom,
} from '../../../../atoms/Atoms';
import Grocefy from '../../../../assets/images/grocefyLogoAlone.png';
import api from '../../../../api/api';

function BusitnessItemUpcsDrawer() {
  const selectedMasterItem = useRecoilValue(setSelectedMasterItemAtom);
  const [selectedBusinessItem, setSelectedBusinessItem] = useRecoilState(setSelectedBusinessItemAtom);
  const [showItemUpcs, setShowItemUpcs] = useRecoilState(showItemUpcsAtom);
  const { t } = useTranslation();
  const [upcs, setUpcs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [allowCreate, setAllowCreate] = useState(false);
  const [newUpc, setNewUpc] = useState('');

  const showMessage = function (message) {
    notification.open({
      message: '',
      description: (
        <Comment
          author={<span>Grocefy</span>}
          avatar={<Avatar src={Grocefy} alt="grocefy" />}
          content={
            <p className="text-sm">
              {message}
            </p>
          }
          datetime={
            <Tooltip title={moment().format('YYYY-MM-DD HH:mm:ss')}>
              <span>{moment().fromNow()}</span>
            </Tooltip>
          }
        />
      ),
    });
  };

  function getUpcs() {
    setLoading(true);
    api
      .get(`businesses/upcs/${selectedBusinessItem.id}`)
      .then((response) => {
        setLoading(false);
        if (response.data.success) {
          setUpcs(_.map(response.data.data, (g) => ({
            ...g,
            originalUpc: g.upc
          })));
        } else {
          showMessage(response.data.error);
        }
      })
      .catch((error) => {
        setLoading(false);
        showMessage(error.message);
      });
  }

  function deleteUpcs(id) {
    setLoading(true);
    api
      .delete(`businesses/upcs/${id}`)
      .then((response) => {
        setLoading(false);
        if (response.data.success) {
          showMessage(response.data.message);
          getUpcs();
        } else {
          showMessage(response.data.error);
        }
      })
      .catch((error) => {
        setLoading(false);
        showMessage(error.message);
      });
  }

  function setPrimaryUpc(id, upc) {
    setLoading(true);
    api
      .post(`businesses/upcs/${selectedBusinessItem.id}/${id}/primary`, { })
      .then((response) => {
        setLoading(false);
        if (response.data.success) {
          showMessage(response.data.message);
          setSelectedBusinessItem({
            ...selectedBusinessItem,
            upc
          });
          getUpcs();
        } else {
          showMessage(response.data.error);
        }
      })
      .catch((error) => {
        setLoading(false);
        showMessage(error.message);
      });
  }

  function updateUpc(id, upc, isPrimary) {
    setLoading(true);
    api
      .post('businesses/upcs/update', {
        UpcId: id,
        Upc: upc.trim()
      })
      .then((response) => {
        setLoading(false);
        if (response.data.success) {
          showMessage(response.data.message);
          if (isPrimary) {
            setSelectedBusinessItem({
              ...selectedBusinessItem,
              upc
            });
          }
          getUpcs();
        } else {
          showMessage(response.data.error);
        }
      })
      .catch((error) => {
        setLoading(false);
        showMessage(error.message);
      });
  }

  function createUpc() {
    setLoading(true);
    api
      .post('businesses/upcs/create', {
        BusinessItemId: selectedBusinessItem.id,
        Upc: newUpc.trim()
      })
      .then((response) => {
        setLoading(false);
        if (response.data.success) {
          showMessage(response.data.message);
          setNewUpc('');
          getUpcs();
        } else {
          showMessage(response.data.error);
        }
      })
      .catch((error) => {
        setLoading(false);
        showMessage(error.message);
      });
  }

  function pullPrimaryGtin() {
    setLoading(true);
    api
      .post(`businesses/upcs/${selectedBusinessItem.id}/getprimary`, { })
      .then((response) => {
        setLoading(false);
        if (response.data.success) {
          showMessage(response.data.message);
          setSelectedBusinessItem({
            ...selectedBusinessItem,
            upc: selectedMasterItem.gtin
          });
          getUpcs();
        } else {
          showMessage(response.data.error);
        }
      })
      .catch((error) => {
        setLoading(false);
        showMessage(error.message);
      });
  }

  useEffect(() => {
    if (showItemUpcs) {
      setUpcs([]);
      getUpcs();
    }
    setNewUpc('');
  }, [showItemUpcs]);

  useEffect(() => {
    const found = _.filter(upcs, (g) => g.upc.trim() === newUpc.trim());
    setAllowCreate(found.length === 0 && newUpc?.length > 0);
  }, [newUpc]);

  return (
    <Drawer
      title="UPCs"
      placement="right"
      closable
      onClose={() => setShowItemUpcs(null)}
      open={showItemUpcs}
      width={500}
    >
      <>
        {selectedBusinessItem && (
          <div className="space-y-2">
            <div className="flex mb-4">
              <strong className="mr-1">{t('primary_upc')}:</strong> {selectedBusinessItem.upc}
              <Popconfirm
                title={t('are_you_sure_pull_primary_gtin')}
                okText={t('yes')}
                cancelText={t('no')}
                placement="bottom"
                onConfirm={() => pullPrimaryGtin()}
              >
                <Popover content={t('pull_primary_gtin_info_desc')} placement="top">
                  <Button
                    size="small"
                    type="primary"
                    className="ml-auto"
                  >
                    {t('pull_primary_gtin')}
                  </Button>
                </Popover>
              </Popconfirm>
            </div>
            <div className="flex space-x-2">
              <Input
                value={newUpc}
                onChange={(obj) => setNewUpc(obj.target.value.trim())}
              />
              <Popconfirm
                title={t('are_you_sure_create_upc')}
                okText={t('yes')}
                cancelText={t('no')}
                onConfirm={() => createUpc()}
              >
                <Button
                  type="primary"
                  disabled={loading || !allowCreate}
                >
                  {t('create')}
                </Button>
              </Popconfirm>
            </div>
            <Table
              size="small"
              bordered
              loading={loading}
              pagination={false}
              dataSource={upcs}
              columns={[
                {
                  title: t('primary'),
                  align: 'center',
                  width: 100,
                  fixed: true,
                  className: 'text-xs',
                  render: (text) => (text.isPrimary ? <FontAwesomeIcon icon={faCheckCircle} className="text-lg text-green-400" /> : null),
                },
                {
                  title: 'UPC',
                  align: 'center',
                  className: 'text-xs',
                  render: (text) => (
                    <div>
                      <Input.Search
                        value={text.upc}
                        onChange={(obj) => {
                          const tempUpcs = [...upcs];
                          tempUpcs.forEach((g) => {
                            if (g.id === text.id) {
                              g.upc = obj.target.value;
                            }
                          });
                          setUpcs(tempUpcs);
                        }}
                        enterButton={t('update')}
                        onSearch={(value) => updateUpc(text.id, value, text.isPrimary)}
                      />
                      {text.upc !== text.originalUpc ? (
                        <div className="italic text-gray-600">*{t('pending_changes')}*</div>
                      ) : null}
                    </div>
                  ),
                },
                {
                  title: t('actions'),
                  align: 'center',
                  className: 'text-xs',
                  width: 100,
                  fixed: true,
                  render: (text) => (
                    <div className="flex space-x-2 justify-center">
                      <Popconfirm
                        title={t('are_you_sure_archive_upc')}
                        okText={t('yes')}
                        cancelText={t('no')}
                        onConfirm={() => deleteUpcs(text.id)}
                      >
                        <Popover content={t('archive')} placement="bottom">
                          <Button
                            size="small"
                            danger
                            type="primary"
                          >
                            <FontAwesomeIcon icon={faTrash} />
                          </Button>
                        </Popover>
                      </Popconfirm>
                      <Popconfirm
                        title={t('are_you_sure_set_primary_upc')}
                        okText={t('yes')}
                        cancelText={t('no')}
                        onConfirm={() => setPrimaryUpc(text.id, text.upc)}
                      >
                        <Popover content={t('set_primary')} placement="bottom">
                          <Button
                            size="small"
                            type="primary"
                          >
                            <FontAwesomeIcon icon={faCheckCircle} />
                          </Button>
                        </Popover>
                      </Popconfirm>
                    </div>
                  ),
                }
              ]}
            />
          </div>
        )}
      </>
    </Drawer>
  );
}
export default BusitnessItemUpcsDrawer;
