import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import {
  useSetRecoilState,
  useRecoilState
} from 'recoil';
import MDEditor from '@uiw/react-md-editor';
import { useTranslation } from 'react-i18next';
import {
  Input,
  Button,
  Table,
  notification,
  Popconfirm,
  Avatar,
  Tooltip,
  Drawer
} from 'antd';
import { Comment } from '@ant-design/compatible';
import { PageHeader } from '@ant-design/pro-layout';
import moment from 'moment';
import Grocefy from '../../../assets/images/grocefyLogoAlone.png';
import {
  selectedKnowledgebase,
  refreshKnowledgebasesAtom
} from '../../../atoms/Atoms';
import api from '../../../api/api';
import EditKnowledgebase from './shared-components/EditKnowledgebase';

function KnowledgeBases() {
  const [loading, setLoading] = useState(true);
  const [showCreate, setShowCreate] = useState(false);
  const [knowledgebases, setKnowledgebases] = useState([]);
  const setKnowledgebase = useSetRecoilState(selectedKnowledgebase);
  const [refreshKnowledgebases, setRefreshKnowledgebases]
    = useRecoilState(refreshKnowledgebasesAtom);

  const [newName, setNewName] = useState('');
  const [newEngContent, setNewEngContent] = useState('');
  const [newSpaContent, setNewSpaContent] = useState('');

  const { t } = useTranslation();

  const showMessage = function (message) {
    notification.open({
      message: '',
      description: (
        <Comment
          author={<span>Grocefy</span>}
          avatar={<Avatar src={Grocefy} alt="grocefy" />}
          content={
            <p className="text-sm">
              {message}
            </p>
          }
          datetime={
            <Tooltip title={moment().format('YYYY-MM-DD HH:mm:ss')}>
              <span>{moment().fromNow()}</span>
            </Tooltip>
          }
        />
      ),
    });
  };

  const onChangeEnglishContent = function (obj) {
    if (!loading) {
      setNewEngContent(obj);
    }
  };

  const onChangeSpanishContent = function (obj) {
    if (!loading) {
      setNewSpaContent(obj);
    }
  };

  const getKnowledgebases = function () {
    setLoading(true);
    api
      .post(
        'utility/global/documents',
        {
          containerName: 'helpinfo'
        }
      )
      .then((response) => {
        setLoading(false);
        if (response.data.error) {
          showMessage(response.data.error);
        } else {
          const temp = _.map(response.data.data.results, (u, index) => ({
            url: u.url,
            name: u.name,
            id: index
          }));
          setKnowledgebases(temp);
        }
      }).catch((err) => {
        setLoading(false);
        showMessage(err.message);
      });
  };

  const createKb = function () {
    setLoading(true);
    api
      .post(
        'utility/upload',
        {
          containerName: 'helpinfo',
          fileName: newName,
          text: JSON.stringify({ eng: newEngContent, spa: newSpaContent, name: newName })
        }
      )
      .then((response) => {
        setLoading(false);
        if (response.data.error) {
          showMessage(response.data.error);
        } else {
          setShowCreate(false);
          getKnowledgebases();
        }
      }).catch((err) => {
        setLoading(false);
        showMessage(err.message);
      });
  };

  const removeKb = function (kb) {
    setLoading(true);
    api
      .delete(
        `utility/file/helpinfo/${kb.name}`
      )
      .then((response) => {
        setLoading(false);
        if (response.data.error) {
          showMessage(response.data.error);
        } else {
          getKnowledgebases();
        }
      }).catch((err) => {
        setLoading(false);
        showMessage(err.message);
      });
  };

  useEffect(() => {
    setKnowledgebase(null);
    getKnowledgebases();
  }, []);

  useEffect(() => {
    if (showCreate) {
      setNewName('');
      setNewSpaContent('');
      setNewEngContent('');
    }
  }, [showCreate]);

  useEffect(() => {
    if (refreshKnowledgebases) {
      setRefreshKnowledgebases(false);
      getKnowledgebases();
    }
  }, [refreshKnowledgebases]);

  return (
    <>
      <PageHeader
        onBack={() => window.history.back()}
        title={t('knowledgebases')}
        extra={[
          <Button
            key="create"
            size="small"
            type="primary"
            onClick={() => setShowCreate(true)}
          >
            {t('create')}
          </Button>
        ]}
      />
      <Table
        bordered
        loading={loading}
        dataSource={knowledgebases}
        rowKey="id"
        columns={[
          {
            title: t('name'),
            align: 'center',
            className: 'text-xs',
            render: (row) => (
              <span>{row.name}</span>
            ),
          },
          {
            title: '',
            align: 'center',
            className: 'text-xs',
            render: (row) => (
              <div>
                <Button
                  size="small"
                  type="primary"
                  onClick={() => {
                    setKnowledgebase(row);
                  }}
                >
                  {t('edit')}
                </Button>
                <Popconfirm
                  title={t('remove_file_prompt')}
                  onConfirm={() => removeKb(row)}
                  okText={t('yes')}
                  cancelText="No"
                >
                  <Button
                    className="mx-2"
                    size="small"
                    type="primary"
                    danger
                  >
                    {t('delete')}
                  </Button>
                </Popconfirm>
                <Button
                  size="small"
                  onClick={() => {
                    navigator.clipboard.writeText(row.id);
                    showMessage('Copied id to clipboard');
                  }}
                >
                  {t('copy_url')}
                </Button>
              </div>
            ),
          }
        ]}
      />
      <Drawer
        title={t('create_knowledgebase')}
        placement="right"
        closable
        onClose={() => setShowCreate(false)}
        open={showCreate}
        width={450}
      >
        <div className="mb-2">
          <strong>{t('name')}:</strong>
          <Input
            disabled={loading}
            placeholder="Name"
            onChange={(obj) => {
              setNewName(obj.target.value);
            }}
            value={newName}
          />
        </div>
        <div className="mb-2">
          <strong>{t('english_content')}:</strong>
          <MDEditor
            preview="edit"
            value={newEngContent}
            onChange={(obj) => onChangeEnglishContent(obj)}
          />
        </div>
        <div className="mb-2">
          <strong>{t('spanish_content')}:</strong>
          <MDEditor
            preview="edit"
            value={newSpaContent}
            onChange={(obj) => onChangeSpanishContent(obj)}
          />
        </div>
        <div className="float-right">
          <Button
            loading={loading}
            type="primary"
            onClick={() => { createKb(); }}
          >
            {t('create')}
          </Button>
        </div>
      </Drawer>
      <EditKnowledgebase />
    </>
  );
}

export default KnowledgeBases;
