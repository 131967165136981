import React, { useEffect, useState } from 'react';
import {
  useRecoilState,
  useRecoilValue
} from 'recoil';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Modal,
  Table,
  Avatar,
  notification,
  Tooltip,
  Input,
  Divider
} from 'antd';
import { Comment } from '@ant-design/compatible';
import moment from 'moment';
import {
  selectedDeliveryProviderAtom,
  manageDeliveryProviderUsersAtom,
  manageDeliveryProviderZonesAtom
} from '../../../../atoms/Atoms';
import api from '../../../../api/api';
import Grocefy from '../../../../assets/images/grocefyLogoAlone.png';

function ManageDeliveryProviderUsers() {
  const selectedProvider = useRecoilValue(selectedDeliveryProviderAtom);
  const [manageProviderUsers, setManageProviderUsers] =
    useRecoilState(manageDeliveryProviderUsersAtom);
  const manageProviderZones = useRecoilValue(manageDeliveryProviderZonesAtom);
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [query, setQuery] = useState('');
  const [searchingUser, setSearchingUser] = useState(false);
  const { t } = useTranslation();

  const showMessage = function (message) {
    notification.open({
      message: '',
      description: (
        <Comment
          author={<span>Grocefy</span>}
          avatar={<Avatar src={Grocefy} alt="grocefy" />}
          content={
            <p className="text-sm">
              {message}
            </p>
          }
          datetime={
            <Tooltip title={moment().format('YYYY-MM-DD HH:mm:ss')}>
              <span>{moment().fromNow()}</span>
            </Tooltip>
          }
        />
      ),
    });
  };

  const getUsers = function () {
    setLoading(true);
    api.get(`tpd/providers/users/${selectedProvider?.id}`)
      .then((response) => {
        setLoading(false);
        if (response.data.success) {
          setUsers(response.data.data.results);
        } else {
          showMessage(response.data.error);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  const addUser = function () {
    if (!searchingUser && query.length > 0) {
      setSearchingUser(true);
      api.post(
        'users/search',
        { SearchText: query }
      )
        .then((response) => {
          if (response?.data?.success) {
            if (response?.data?.data?.results?.length > 0) {
              const userToAdd = response?.data?.data?.results[0];
              if (response?.data?.data?.results?.length > 1) {
                showMessage(`Found multiple users, attempting to add ${userToAdd.email}`);
              }
              api
                .post(
                  `tpd/providers/users/${selectedProvider?.id}/${userToAdd.id}`,
                  { }
                )
                .then((addResponse) => {
                  if (addResponse.data.success) {
                    setSearchingUser(false);
                    getUsers();
                  } else {
                    setSearchingUser(false);
                    showMessage(addResponse.data.error);
                  }
                })
                .catch((error) => {
                  console.error(error);
                });
            } else {
              setSearchingUser(false);
              setQuery('');
              showMessage('User not found');
            }
          } else {
            setSearchingUser(false);
            setQuery('');
            showMessage(response.data.error);
          }
        })
        .catch((error) => {
          setSearchingUser(false);
          console.error(error);
        });
    }
  };

  const removeUser = function (user) {
    setLoading(true);
    api.delete(`tpd/providers/users/${selectedProvider?.id}/${user.id}`, { })
      .then((response) => {
        if (response.data.success) {
          getUsers();
        } else {
          setLoading(false);
          showMessage(response.data.error);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  useEffect(() => {
    if (manageProviderUsers) {
      getUsers();
    }
  }, [manageProviderUsers]);

  return (
    <Modal
      title={t('users')}
      open={!manageProviderZones && manageProviderUsers}
      onCancel={() => setManageProviderUsers(false)}
      footer={[
        <Button
          key="ok"
          type="primary"
          loading={loading}
          onClick={() => setManageProviderUsers(false)}
        >
          {t('close')}
        </Button>
      ]}
    >
      <Input.Search
        key="search"
        placeholder={t('search_user')}
        allowClear
        loading={searchingUser}
        disabled={searchingUser}
        value={query}
        onChange={(obj) => {
          setQuery(obj.target.value);
        }}
        onSearch={() => addUser()}
        enterButton={t('add')}
      />
      <Divider />
      <Table
        bordered
        loading={loading}
        rowKey="id"
        dataSource={users}
        columns={[
          {
            title: t('email'),
            align: 'left',
            render: (row) => <span>{row.user.email}</span>
          },
          {
            title: t('name'),
            align: 'left',
            render: (row) => <span>{row.user.completeName}</span>
          },
          {
            title: '',
            align: 'center',
            className: 'text-xs',
            render: (row) => (
              <Button
                size="small"
                type="primary"
                danger
                onClick={() => {
                  removeUser(row.user);
                }}
              >
                {t('remove')}
              </Button>
            ),
          }
        ]}
      />
    </Modal>
  );
}

export default ManageDeliveryProviderUsers;
