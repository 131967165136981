import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import {
  useRecoilState,
  useRecoilValue,
  useSetRecoilState
} from 'recoil';
import { useTranslation } from 'react-i18next';
import {
  Drawer,
  Table,
  Button,
  Popover,
  Input,
  Avatar,
  Tooltip,
  notification,
  Popconfirm,
  Select,
  Switch
} from 'antd';
import moment from 'moment';
import { Comment } from '@ant-design/compatible';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faTrash } from '@fortawesome/free-solid-svg-icons';
import {
  setSelectedMasterItemAtom,
  showItemGtinsAtom,
  otherBusinessItemsAtom,
  reloadBusinessItemsAtom
} from '../../../../atoms/Atoms';
import Grocefy from '../../../../assets/images/grocefyLogoAlone.png';
import api from '../../../../api/api';

function ItemGtinsDrawer() {
  const { Option } = Select;
  const [selectedMasterItem, setSelectedMasterItem] = useRecoilState(setSelectedMasterItemAtom);
  const otherBusinessItems = useRecoilValue(otherBusinessItemsAtom);
  const setReloadBusinessItems = useSetRecoilState(reloadBusinessItemsAtom);
  const [showItemGtins, setShowItemGtins] = useRecoilState(showItemGtinsAtom);
  const { t } = useTranslation();
  const [gtins, setGtins] = useState([]);
  const [applyToMultipleStores, setApplyToMultipleStores] = useState(false);
  const [selectedBusinessItemIds, setSelectedBusinessItemIds] = useState([]);
  const [loading, setLoading] = useState(false);
  const [allowCreate, setAllowCreate] = useState(false);
  const [newGtin, setNewGtin] = useState('');

  function showMessage(message) {
    notification.open({
      message: '',
      description: (
        <Comment
          author={<span>Grocefy</span>}
          avatar={<Avatar src={Grocefy} alt="grocefy" />}
          content={
            <p className="text-sm">
              {message}
            </p>
          }
          datetime={
            <Tooltip title={moment().format('YYYY-MM-DD HH:mm:ss')}>
              <span>{moment().fromNow()}</span>
            </Tooltip>
          }
        />
      ),
    });
  }

  function getGtins() {
    setLoading(true);
    api
      .get(`businesses/gtins/${selectedMasterItem.id}`)
      .then((response) => {
        setLoading(false);
        if (response.data.success) {
          setGtins(_.map(response.data.data, (g) => ({
            ...g,
            originalGtin: g.gtin
          })));
        } else {
          showMessage(response.data.error);
        }
      })
      .catch((error) => {
        setLoading(false);
        showMessage(error.message);
      });
  }

  function deleteGtin(id) {
    setLoading(true);
    api
      .delete(`businesses/gtins/${id}`)
      .then((response) => {
        setLoading(false);
        if (response.data.success) {
          showMessage(response.data.message);
          getGtins();
        } else {
          showMessage(response.data.error);
        }
      })
      .catch((error) => {
        setLoading(false);
        showMessage(error.message);
      });
  }

  function setPrimaryGtin(id, gtin) {
    setLoading(true);
    api
      .post(`businesses/gtins/${selectedMasterItem.id}/${id}/primary`, { })
      .then((response) => {
        setLoading(false);
        if (response.data.success) {
          showMessage(response.data.message);
          setSelectedMasterItem({
            ...selectedMasterItem,
            gtin
          });
          getGtins();
        } else {
          showMessage(response.data.error);
        }
      })
      .catch((error) => {
        setLoading(false);
        showMessage(error.message);
      });
  }

  function updateGtin(id, gtin, isPrimary) {
    setLoading(true);
    api
      .post('businesses/gtins/update', {
        GtinId: id,
        Gtin: gtin.trim()
      })
      .then((response) => {
        setLoading(false);
        if (response.data.success) {
          showMessage(response.data.message);
          if (isPrimary) {
            setSelectedMasterItem({
              ...selectedMasterItem,
              gtin
            });
          }
          getGtins();
        } else {
          showMessage(response.data.error);
        }
      })
      .catch((error) => {
        setLoading(false);
        showMessage(error.message);
      });
  }

  function createGtin() {
    setLoading(true);
    api
      .post('businesses/gtins/create', {
        ItemId: selectedMasterItem.id,
        Gtin: newGtin.trim()
      })
      .then((response) => {
        setLoading(false);
        if (response.data.success) {
          showMessage(response.data.message);
          setNewGtin('');
          getGtins();
        } else {
          showMessage(response.data.error);
        }
      })
      .catch((error) => {
        setLoading(false);
        showMessage(error.message);
      });
  }

  function forceGtinsToStores() {
    setLoading(true);
    api
      .post('businesses/gtins/forcetostores', {
        ItemId: selectedMasterItem.id,
        BusinessItemIds: selectedBusinessItemIds
      })
      .then((response) => {
        setLoading(false);
        if (response.data.success) {
          showMessage(response.data.message);
          getGtins();
          setReloadBusinessItems(true);
          setSelectedBusinessItemIds([]);
          setApplyToMultipleStores(false);
        } else {
          showMessage(response.data.error);
        }
      })
      .catch((error) => {
        setLoading(false);
        showMessage(error.message);
      });
  }

  useEffect(() => {
    if (showItemGtins) {
      setGtins([]);
      setSelectedBusinessItemIds([]);
      setApplyToMultipleStores(false);
      getGtins();
      console.log(otherBusinessItems);
    }
    setNewGtin('');
  }, [showItemGtins]);

  useEffect(() => {
    const found = _.filter(gtins, (g) => g.gtin.trim() === newGtin.trim());
    setAllowCreate(found.length === 0 && newGtin?.length > 0);
  }, [newGtin]);

  return (
    <Drawer
      title="GTINs"
      placement="right"
      closable
      onClose={() => setShowItemGtins(null)}
      open={showItemGtins}
      width={500}
    >
      <>
        {selectedMasterItem && (
          <div className="space-y-2">
            <div className="flex mb-4">
              <strong className="mr-1">{t('primary_gtin')}:</strong> {selectedMasterItem.gtin}
            </div>
            <div>
              {otherBusinessItems.length > 0 && (
                <>
                  <div
                    style={{ margin: '10px 0' }}
                  >
                    <strong>{t('apply_gtin_to_stores')}</strong>
                    <Switch
                      disabled={loading}
                      style={{ float: 'right' }}
                      checked={applyToMultipleStores}
                      onChange={(checked) => {
                        setSelectedBusinessItemIds([]);
                        setApplyToMultipleStores(checked);
                      }}
                    />
                  </div>
                  <div
                    hidden={!applyToMultipleStores}
                    style={{ marginBottom: '10px' }}
                  >
                    <div className="mt-2 mb-1 space-x-2">
                      <strong>{t('selected_stores')}</strong>
                      <Button
                        size="small"
                        type="primary"
                        onClick={() => {
                          setSelectedBusinessItemIds(_.map(otherBusinessItems, (b) => b.id));
                        }}
                      >
                        {t('select_all')}
                      </Button>
                    </div>
                    <div className="flex space-x-2">
                      <Select
                        mode="multiple"
                        value={selectedBusinessItemIds}
                        className="w-full mb-8"
                        disabled={loading}
                        onChange={(value) => {
                          setSelectedBusinessItemIds(value);
                        }}
                      >
                        {otherBusinessItems && _.map(otherBusinessItems, (otherBusinessItem) => (
                          <Option
                            key={otherBusinessItem.id}
                            value={otherBusinessItem.id}
                          >
                            {otherBusinessItem.businessName}
                          </Option>
                        ))}
                      </Select>
                      <Popconfirm
                        title={t('are_you_sure_apply_gtin_to_selected_stores')}
                        okText={t('yes')}
                        cancelText={t('no')}
                        placement="bottom"
                        onConfirm={() => forceGtinsToStores()}
                      >
                        <Popover content={t('apply_gtins_to_selected_stores_info_desc')} placement="top">
                          <Button
                            size="small"
                            type="primary"
                            loading={loading}
                            disabled={selectedBusinessItemIds.length === 0}
                            className="h-8"
                          >
                            {t('apply')}
                          </Button>
                        </Popover>
                      </Popconfirm>
                    </div>
                  </div>
                </>
              )}
            </div>

            <div className="flex space-x-2">
              <Input
                value={newGtin}
                onChange={(obj) => setNewGtin(obj.target.value.trim())}
              />
              <Popconfirm
                title={t('are_you_sure_create_gtin')}
                okText={t('yes')}
                cancelText={t('no')}
                onConfirm={() => createGtin()}
              >
                <Button
                  type="primary"
                  disabled={loading || !allowCreate}
                >
                  {t('create')}
                </Button>
              </Popconfirm>
            </div>
            <Table
              size="small"
              bordered
              loading={loading}
              pagination={false}
              dataSource={gtins}
              columns={[
                {
                  title: t('primary'),
                  align: 'center',
                  width: 100,
                  fixed: true,
                  className: 'text-xs',
                  render: (text) => (text.isPrimary ? <FontAwesomeIcon icon={faCheckCircle} className="text-lg text-green-400" /> : null),
                },
                {
                  title: 'GTIN',
                  align: 'center',
                  className: 'text-xs',
                  render: (text) => (
                    <div>
                      <Input.Search
                        value={text.gtin}
                        onChange={(obj) => {
                          const tempGtins = [...gtins];
                          tempGtins.forEach((g) => {
                            if (g.id === text.id) {
                              g.gtin = obj.target.value;
                            }
                          });
                          setGtins(tempGtins);
                        }}
                        enterButton={t('update')}
                        onSearch={(value) => updateGtin(text.id, value, text.isPrimary)}
                      />
                      {text.gtin !== text.originalGtin ? (
                        <div className="italic text-gray-600">*{t('pending_changes')}*</div>
                      ) : null}
                    </div>
                  ),
                },
                {
                  title: t('actions'),
                  align: 'center',
                  className: 'text-xs',
                  width: 100,
                  fixed: true,
                  render: (text) => (
                    <div className="flex space-x-2 justify-center">
                      <Popconfirm
                        title={t('are_you_sure_archive_gtin')}
                        okText={t('yes')}
                        cancelText={t('no')}
                        onConfirm={() => deleteGtin(text.id)}
                      >
                        <Popover content={t('archive')} placement="bottom">
                          <Button
                            size="small"
                            danger
                            type="primary"
                          >
                            <FontAwesomeIcon icon={faTrash} />
                          </Button>
                        </Popover>
                      </Popconfirm>
                      <Popconfirm
                        title={t('are_you_sure_set_primary_gtin')}
                        okText={t('yes')}
                        cancelText={t('no')}
                        onConfirm={() => setPrimaryGtin(text.id, text.gtin)}
                      >
                        <Popover content={t('set_primary')} placement="bottom">
                          <Button
                            size="small"
                            type="primary"
                          >
                            <FontAwesomeIcon icon={faCheckCircle} />
                          </Button>
                        </Popover>
                      </Popconfirm>
                    </div>
                  ),
                }
              ]}
            />
          </div>
        )}
      </>
    </Drawer>
  );
}
export default ItemGtinsDrawer;
