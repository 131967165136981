import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
  Modal,
  Avatar,
  Button,
  Image,
  Table
} from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import {
  showTicketUserAssignAtom,
  selectedTicketBoardAtom,
  ticketAssignedUserAtom
} from '../../../../../atoms/Atoms';

function AssignUserModal() {
  const selectedBoard = useRecoilValue(selectedTicketBoardAtom);
  const setTicketAssignedUser = useSetRecoilState(ticketAssignedUserAtom);
  const [showTicketUserAssign, setShowTicketUserAssign] = useRecoilState(showTicketUserAssignAtom);
  const [members, setMembers] = useState([]);
  const { t } = useTranslation();

  const closeModal = function () {
    setShowTicketUserAssign(false);
    setTicketAssignedUser(null);
  };

  useEffect(() => {
    if (showTicketUserAssign) {
      setTicketAssignedUser(null);
      setMembers(selectedBoard.members);
    }
  }, [showTicketUserAssign]);

  return (
    <Modal
      closable="true"
      centered="true"
      onCancel={() => closeModal()}
      open={showTicketUserAssign}
      footer={null}
    >
      <>
        <PageHeader
          title={t('assign_user')}
        />
        <Table
          size="small"
          bordered
          hideOnSinglePage="true"
          columns={[
            {
              title: t('name'),
              align: 'left',
              className: 'text-xs',
              render: (row) => (
                <div>
                  <Avatar
                    src={<Image src={row.avatar ? row.avatar : null} />}
                    style={{ marginRight: '10px' }}
                  >
                    {row.firstName[0]}{row.lastName[0]}
                  </Avatar>
                  {row.completeName}
                </div>
              )
            },
            {
              title: t('actions'),
              align: 'center',
              className: 'text-xs',
              render: (row) => (
                <Button
                  size="small"
                  type="primary"
                  onClick={() => {
                    setTicketAssignedUser(row);
                  }}
                >
                  {t('select')}
                </Button>
              ),
            }
          ]}
          dataSource={members}
        />
      </>
    </Modal>
  );
}

export default AssignUserModal;
